import { useDebouncedEffect } from "@/utils/Hooks";
import { HTTP } from "@/utils/Http";
import axios from "axios";
import { useState } from "react";

export type ObjectImage = {
  url: string;
  key: string;
  bucket: string;
};

export const useObjectImages = (objectId: string) => {
  const [data, setData] = useState<ObjectImage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);

  useDebouncedEffect(() => {
    setData([]);
    setIsError(false);

    if (objectId.length === 0) {
      return;
    }

    const cancelToken = axios.CancelToken.source();

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = (await HTTP.get({
          url: `/asset/object/${objectId}/images`,
          cancelToken: cancelToken.token,
        })) as {
          images: ObjectImage[];
        };

        setData(response.images);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setIsError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Cleanup function to abort fetch if component unmounts
    return () => cancelToken.cancel();
  }, [objectId]);

  return { data, isLoading, isError };
};

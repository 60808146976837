import NumberUtils from "../../../../utils/ NumberUtils";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import {
  AbstractStructSelectors,
  DataByUnitType,
} from "../AbstractStructSelectors";

interface CurrencyStructData {
  _id: string;
  base: string;
  date: Date;
  rates: {
    [currency: string]: number;
  };
}
class CurrencyStructClass extends AbstractStructSelectors<CurrencyStructData> {
  getStructType(): StructType {
    return "currency";
  }
  loadStructData(): Promise<DataByUnitType<CurrencyStructData>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: `config/getCurrencyConvert`,
          target: "API",
        })) as CurrencyStructData;

        const result: DataByUnitType<CurrencyStructData> = {
          common: data,
        };

        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }
  getConversionDate = () => {
    return this.getData("common").date;
  };
  getConversionRate(from: string, to: string) {
    return this.useCache("getConversionRate", arguments, () => {
      const currencies = this.getData("common");
      let fromCurrencyRate = currencies.rates[from];
      let toCurrencyRate = currencies.rates[to];

      return toCurrencyRate / fromCurrencyRate;
    });
  }
  convert(fromValue: number, fromCurrency: string, toCurrency: string): number {
    let convertedValue =
      this.getConversionRate(fromCurrency, toCurrency) * fromValue;

    return NumberUtils.normalizeDecimal(convertedValue);
  }
}
const CurrencyStruct = new CurrencyStructClass();
export default CurrencyStruct;

import { useActivityConstants } from "@/apps/tatar/activityApp/ActivityHooks";
import { AP_FIELDS } from "@/apps/tatar/activityApp/views/list/APList";
import { ObjectVacancyActivityConfig } from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";
import i18n from "@/i18n";
import { ColumnConfig } from "@/modules/abstract-ui/data/virtualized-table/BFVirtualizedTable";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import LabeledInput from "@/modules/abstract-ui/forms/LabeledInput";
import EZTags from "@/modules/ez-form/form-elements/ez-tags/EZTags";
import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import React, { useEffect, useMemo } from "react";

type VacancyActivityBlockCheckboxListProps = {
  value: ObjectVacancyActivityConfig;
  onChange: <T extends keyof ObjectVacancyActivityConfig>(
    key: T,
    value: ObjectVacancyActivityConfig[T]
  ) => void;
  structToUse: ActivityAbstractStructClass<any>;
};

const convertCheckboxToValue = (
  keys: string[],
  fields: Record<string, ColumnConfig>
) => {
  return keys.map((key) => {
    const exportConfig = fields[key].export ?? fields[key];

    if (typeof exportConfig === "function") {
      const config = exportConfig({});

      return {
        path: key,
        label: config.label,
        format: fields[key].fieldType ?? config.type,
        aggregation: config.totalFunction,
      };
    }

    const configType = "type" in exportConfig ? exportConfig.type : "string";
    const totalFunction =
      "totalFunction" in exportConfig ? exportConfig.totalFunction : undefined;

    return {
      path: key,
      label: exportConfig.label.toString(),
      format: fields[key].fieldType ?? configType,
      aggregation: totalFunction,
    };
  });
};

const HIDE_COLUMNS = ["data.type", "data.entity", "data.objectId"];

const VacancyActivityBlockCheckboxList: React.FC<VacancyActivityBlockCheckboxListProps> =
  ({ value, onChange, structToUse }) => {
    const constants = useActivityConstants();

    const fields = useMemo(() => {
      const fields = AP_FIELDS(constants, structToUse);
      HIDE_COLUMNS.forEach((column) => {
        delete fields[column];
      });

      return fields;
    }, []);

    const fieldValues = useMemo(() => {
      const options: {
        value: string;
        label: string;
      }[] = [];

      for (const key in fields) {
        options.push({
          value: key,
          label: fields[key].label as string,
        });
      }

      return options;
    }, [fields]);

    useEffect(() => {
      onChange(
        "fields",
        convertCheckboxToValue(
          fieldValues.map((value) => value.value),
          fields
        )
      );
      onChange("columnsInRow", fieldValues.length);
    }, [fieldValues, fields]);

    return (
      <>
        <LabeledInput
          label={i18n.t("Component.PDFConstructor.Fields", "Felder")}
        >
          <EZTags
            data={fieldValues}
            value={fieldValues
              .filter((fieldValue) =>
                value.fields.some(
                  (selectedValue) => selectedValue.path === fieldValue.value
                )
              )
              .map((field) => field.value)}
            onChange={(values) => {
              onChange("fields", convertCheckboxToValue(values, fields));
            }}
          />
        </LabeledInput>

        <BFInput
          label={i18n.t(
            "Component.PDFConstructor.Block.RowColumns",
            "Spalten in einer Zeile"
          )}
          type="number"
          value={value.columnsInRow}
          onChange={(value) => onChange("columnsInRow", Number(value))}
          validation={
            value.columnsInRow > fieldValues.length
              ? {
                  message: "You have chosen too many columns",
                  level: "error",
                }
              : null
          }
        />
      </>
    );
  };

export { VacancyActivityBlockCheckboxList };

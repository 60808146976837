import classNames from "classnames";
import BFTag from "../../../abstract-ui/data/tag/BFTag";
import { BfIconProps } from "../../../abstract-ui/icon/BfIcon";
import { RSDateTagStatus } from "../../RSInterfaces";
import "./RSDateTag.scss";

interface RSDateTagProps {
  text: string;
  status: RSDateTagStatus;
}

const RSDateTag = (props: RSDateTagProps) => {
  const iconProps: BfIconProps | undefined =
    props.status === "overdue"
      ? { type: "light", size: "xs", data: "alert-diamond" }
      : undefined;

  return (
    <div className={classNames("rs-date-tag", props.status)}>
      <BFTag textKey={props.text} icon={iconProps} size="sm" />
    </div>
  );
};

export default RSDateTag;

import {
  UpcomingVacancyFields,
  ObjectStackingPlanTableFields,
  ObjectTenantListFields,
  VacancyChart,
  ObjectRiskManagementChart,
  ObjectVacancyActivityFields,
  ObjectDamageClaimsFields,
  ObjectTableActivityFilter,
  ObjectMaintenanceContractsFields,
  ObjectLoanChart,
  ObjectLoanTableFields,
  ObjectLoanTableFilter,
} from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";
import i18n from "@/i18n";

type TranslatedOption<T = string> = {
  label: () => string;
  value: T;
};

export const OBJECT_DETAILS_VIEW: TranslatedOption[] = [
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.ObjectDetails.List", "Liste"),
    value: "list",
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.ObjectDetails.Full", "Voll"),
    value: "full",
  },
];

export const OBJECT_VACANCY_CHART: TranslatedOption<VacancyChart>[] = [
  {
    label: () => i18n.t("Component.PDFConstructor.Block.VacancyChart.NKM"),
    value: VacancyChart.RENT_NET,
  },
  {
    label: () => i18n.t("Component.PDFConstructor.Block.VacancyChart.Area"),
    value: VacancyChart.AREA,
  },
  {
    label: () => i18n.t("Component.PDFConstructor.Block.VacancyChart.Count"),
    value: VacancyChart.RATE,
  },
];

export const OBJECT_UPCOMING_VACANCY: TranslatedOption<number>[] = [
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.TimeRange.SixMonth", "6 Monate"),
    value: 6,
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.TimeRange.OneYear", "1 Jahr"),
    value: 12,
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.TimeRange.TwoYears", "2 Jahre"),
    value: 24,
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.TimeRange.FiveYears", "5 Jahre"),
    value: 60,
  },
];

export const OBJECT_UPCOMING_VACANCY_VALUES: Record<
  UpcomingVacancyFields,
  boolean
> = {
  [UpcomingVacancyFields.ID]: false,
  [UpcomingVacancyFields.NAME]: false,
  [UpcomingVacancyFields.TENANT_NAME]: false,
  [UpcomingVacancyFields.CONTRACT_TYPE]: false,
  [UpcomingVacancyFields.UNIT_TYPE]: false,
  [UpcomingVacancyFields.USAGE_TYPE]: false,
  [UpcomingVacancyFields.ACCOUNT_BALANCE]: false,
  [UpcomingVacancyFields.RENT_NET]: false,
  [UpcomingVacancyFields.RENT_GROSS]: false,
  [UpcomingVacancyFields.COST_NET]: false,
  [UpcomingVacancyFields.COST_GROSS]: false,
  [UpcomingVacancyFields.DEPOSIT]: false,
  [UpcomingVacancyFields.UNIT_AREA]: false,
  [UpcomingVacancyFields.MOVE_IN]: false,
  [UpcomingVacancyFields.MOVE_OUT]: false,
  [UpcomingVacancyFields.AGREEMENT_EXPIRATION]: false,
  [UpcomingVacancyFields.AUTOMATIC_PULL_OUT]: false,
  [UpcomingVacancyFields.AUTOMATIC_PULL_OUT_EXTENSION]: false,
  [UpcomingVacancyFields.STAFF_RENT]: false,
  [UpcomingVacancyFields.STATUS]: false,
  [UpcomingVacancyFields.RENT_ADJUSTMENT_FACTOR]: false,
  [UpcomingVacancyFields.LAST_PRICE_INDEX_CHANGE]: false,
  [UpcomingVacancyFields.LAST_PRICE_INDEX]: false,
  [UpcomingVacancyFields.STITCH_MONTH]: false,
  [UpcomingVacancyFields.INDEX_FACTOR]: false,
  [UpcomingVacancyFields.INDEX_TYPE]: false,
  [UpcomingVacancyFields.CURRENT_DUNNING_ACTIVITY]: false,
  [UpcomingVacancyFields.STOP_DUNNING_RUN]: false,
};

export const OBJECT_UPCOMING_VACANCY_FIELDS: TranslatedOption<UpcomingVacancyFields>[] =
  [
    {
      label: () =>
        i18n.t("Component.PDFConstructor.Block.UpcomingVacancyFields.ID", "ID"),
      value: UpcomingVacancyFields.ID,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.DisplayName",
          "Anzeigename"
        ),
      value: UpcomingVacancyFields.NAME,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.TenantName",
          "Mietername"
        ),
      value: UpcomingVacancyFields.TENANT_NAME,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.ContractType",
          "Vertragstyp"
        ),
      value: UpcomingVacancyFields.CONTRACT_TYPE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.UnitType",
          "Einheitstyp"
        ),
      value: UpcomingVacancyFields.UNIT_TYPE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.UsageType",
          "Nutzungsart"
        ),
      value: UpcomingVacancyFields.USAGE_TYPE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.AccountBalance",
          "Kontostand"
        ),
      value: UpcomingVacancyFields.ACCOUNT_BALANCE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.RentNet",
          "Miete netto"
        ),
      value: UpcomingVacancyFields.RENT_NET,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.RentGross",
          "Miete brutto"
        ),
      value: UpcomingVacancyFields.RENT_GROSS,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.CostNet",
          "Kosten netto"
        ),
      value: UpcomingVacancyFields.COST_NET,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.CostGross",
          "Kosten brutto"
        ),
      value: UpcomingVacancyFields.COST_GROSS,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.Deposit",
          "Kaution"
        ),
      value: UpcomingVacancyFields.DEPOSIT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.UnitArea",
          "Einheitsfläche"
        ),
      value: UpcomingVacancyFields.UNIT_AREA,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.MoveIn",
          "Einzug"
        ),
      value: UpcomingVacancyFields.MOVE_IN,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.MoveOut",
          "Auszug"
        ),
      value: UpcomingVacancyFields.MOVE_OUT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.AgreementExpiration",
          "Vertragsablauf"
        ),
      value: UpcomingVacancyFields.AGREEMENT_EXPIRATION,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.AutomaticPullOutBeforePullOut",
          "Automatischer Auszug vor dem Auszug"
        ),
      value: UpcomingVacancyFields.AUTOMATIC_PULL_OUT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.AutomaticPullOutExtension",
          "Automatische Verlängerung des Auszugs"
        ),
      value: UpcomingVacancyFields.AUTOMATIC_PULL_OUT_EXTENSION,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.StaffRent",
          "Miete für Mitarbeiter"
        ),
      value: UpcomingVacancyFields.STAFF_RENT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.CurrentStatus",
          "Aktueller Status"
        ),
      value: UpcomingVacancyFields.STATUS,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.RentAdjustmentFactor",
          "Mietanpassungsfaktor"
        ),
      value: UpcomingVacancyFields.RENT_ADJUSTMENT_FACTOR,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.LastChange",
          "Letzte Änderung"
        ),
      value: UpcomingVacancyFields.LAST_PRICE_INDEX_CHANGE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.LastIndex",
          "Letzter Index"
        ),
      value: UpcomingVacancyFields.LAST_PRICE_INDEX,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.Stichmonat",
          "Stichmonat"
        ),
      value: UpcomingVacancyFields.STITCH_MONTH,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.IndexFactor",
          "Indexfaktor"
        ),
      value: UpcomingVacancyFields.INDEX_FACTOR,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.UpcomingVacancyFields.Indexing",
          "Indexierung"
        ),
      value: UpcomingVacancyFields.INDEX_TYPE,
    },
  ];

export const OBJECT_STACKING_PLAN_VALUES: Record<
  ObjectStackingPlanTableFields,
  boolean
> = {
  [ObjectStackingPlanTableFields.ID]: false,
  [ObjectStackingPlanTableFields.NAME]: false,
  [ObjectStackingPlanTableFields.STATUS]: false,
  [ObjectStackingPlanTableFields.USAGE_TYPE]: false,
  [ObjectStackingPlanTableFields.UNIT_TYPE]: false,
  [ObjectStackingPlanTableFields.FLOOR]: false,
  [ObjectStackingPlanTableFields.SURFACE_AREA]: false,
  [ObjectStackingPlanTableFields.PITCHERS]: false,
  [ObjectStackingPlanTableFields.NKM_TARGET]: false,
  [ObjectStackingPlanTableFields.GROSS_RENT]: false,
  [ObjectStackingPlanTableFields.GROSS_PLANNED]: false,
  [ObjectStackingPlanTableFields.MANAGED_BY]: false,
  [ObjectStackingPlanTableFields.MANAGED_UNTIL]: false,
  [ObjectStackingPlanTableFields.NKM_ACTUAL]: false,
  [ObjectStackingPlanTableFields.IST]: false,
  [ObjectStackingPlanTableFields.NKM_DIFFERENCE]: false,
  [ObjectStackingPlanTableFields.UGLY_IST]: false,
  [ObjectStackingPlanTableFields.GROSS_RENT_DIFFERENCE]: false,
  [ObjectStackingPlanTableFields.SOLL]: false,
};

export const OBJECT_STACKING_PLAN: TranslatedOption<ObjectStackingPlanTableFields>[] =
  [
    {
      label: () =>
        i18n.t("Component.PDFConstructor.Block.StackingPlanFields.ID", "ID"),
      value: ObjectStackingPlanTableFields.ID,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.Name",
          "Name"
        ),
      value: ObjectStackingPlanTableFields.NAME,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.UsageType",
          "Nutzungsart"
        ),
      value: ObjectStackingPlanTableFields.STATUS,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.UsageType",
          "Einheitstyp"
        ),
      value: ObjectStackingPlanTableFields.USAGE_TYPE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.UnitType",
          "Einheitstyp"
        ),
      value: ObjectStackingPlanTableFields.UNIT_TYPE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.Floor",
          "Etage"
        ),
      value: ObjectStackingPlanTableFields.FLOOR,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.SurfaceArea",
          "Fläche"
        ),
      value: ObjectStackingPlanTableFields.SURFACE_AREA,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.PitchersOthers",
          "Werfer/Andere"
        ),
      value: ObjectStackingPlanTableFields.PITCHERS,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.NKMTarget",
          "NKM-Ziel"
        ),
      value: ObjectStackingPlanTableFields.NKM_TARGET,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.GrossRent",
          "Bruttomiete"
        ),
      value: ObjectStackingPlanTableFields.GROSS_RENT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.GrossPlanned",
          "Geplante Bruttomiete"
        ),
      value: ObjectStackingPlanTableFields.GROSS_PLANNED,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.ManagedBy",
          "Verwaltet von"
        ),
      value: ObjectStackingPlanTableFields.MANAGED_BY,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.ManagedUntil",
          "Verwaltet bis"
        ),
      value: ObjectStackingPlanTableFields.MANAGED_UNTIL,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.NKMActual",
          "Aktuelle NKM"
        ),
      value: ObjectStackingPlanTableFields.NKM_ACTUAL,
    },
    {
      label: () =>
        i18n.t("Component.PDFConstructor.Block.StackingPlanFields.IST", "IST"),
      value: ObjectStackingPlanTableFields.IST,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.SOLLfor",
          "SOLL für (m²|Stk)"
        ),
      value: ObjectStackingPlanTableFields.SOLL,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.NKMDifference",
          "NKM-Differenz"
        ),
      value: ObjectStackingPlanTableFields.NKM_DIFFERENCE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.UglyIST",
          "Ugly IST"
        ),
      value: ObjectStackingPlanTableFields.UGLY_IST,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.StackingPlanFields.GrossRentDifference",
          "Bruttomieten-Differenz"
        ),
      value: ObjectStackingPlanTableFields.GROSS_RENT_DIFFERENCE,
    },
  ];

export const OBJECT_TENANT_LIST_VALUES: Record<
  ObjectTenantListFields,
  boolean
> = {
  [ObjectTenantListFields.RENTAL_AGREEMENT]: false,
  [ObjectTenantListFields.TENANT]: false,
  [ObjectTenantListFields.SURFACE_AREA]: false,
  [ObjectTenantListFields.NUMBER_PITCHES]: false,
  [ObjectTenantListFields.NKM_ACTUAL]: false,
  [ObjectTenantListFields.NKM_PER_SQUARE]: false,
  [ObjectTenantListFields.NKM_TARGET]: false,
  [ObjectTenantListFields.NKM_TARGET_PER_SQUARE]: false,
  [ObjectTenantListFields.NKM_DIFF]: false,
  [ObjectTenantListFields.RENT_GROSS]: false,
  [ObjectTenantListFields.RENT_GROSS_PER_SQUARE]: false,
  [ObjectTenantListFields.PLAN_RENT_GROSS]: false,
  [ObjectTenantListFields.PLAN_RENT_GROSS_PER_SQUARE]: false,
  [ObjectTenantListFields.RENT_GROSS_DIFF]: false,
};

export const OBJECT_TENANT_LIST: TranslatedOption<ObjectTenantListFields>[] = [
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.RentalAgreement",
        "Mietvertrag"
      ),
    value: ObjectTenantListFields.RENTAL_AGREEMENT,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.Tenant",
        "Mieter"
      ),
    value: ObjectTenantListFields.TENANT,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.SurfaceArea",
        "Fläche"
      ),
    value: ObjectTenantListFields.SURFACE_AREA,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.PitchersOthers",
        "Anzahl/Stellplätze"
      ),
    value: ObjectTenantListFields.NUMBER_PITCHES,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.NKMActual",
        "NKM IST"
      ),
    value: ObjectTenantListFields.NKM_ACTUAL,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.NKMActualPerSquare",
        "NKM IST / m²"
      ),
    value: ObjectTenantListFields.NKM_PER_SQUARE,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.NKMTarget",
        "NKM SOLL"
      ),
    value: ObjectTenantListFields.NKM_TARGET,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.NKMTargetPerSquare",
        "NKM SOLL / m²"
      ),
    value: ObjectTenantListFields.NKM_TARGET_PER_SQUARE,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.NKMDifference",
        "NKM Diff"
      ),
    value: ObjectTenantListFields.NKM_DIFF,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.GrossRent",
        "Bruttomiete IST"
      ),
    value: ObjectTenantListFields.RENT_GROSS,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.GrossRentPerSquare",
        "Bruttomiete IST / m²"
      ),
    value: ObjectTenantListFields.RENT_GROSS_PER_SQUARE,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.GrossPlanned",
        "Bruttomiete SOLL"
      ),
    value: ObjectTenantListFields.PLAN_RENT_GROSS,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.GrossPlannedPerSquare",
        "Bruttomiete SOLL / m²"
      ),
    value: ObjectTenantListFields.PLAN_RENT_GROSS_PER_SQUARE,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.StackingPlanFields.GrossRentDiff",
        "Bruttomiete Diff"
      ),
    value: ObjectTenantListFields.RENT_GROSS_DIFF,
  },
];

export const OBJECT_VACANCY_ACTIVITY_FIELDS: Record<
  ObjectVacancyActivityFields,
  boolean
> = {
  [ObjectVacancyActivityFields.TICKET_NUMBER]: false,
  [ObjectVacancyActivityFields.NAME]: false,
  [ObjectVacancyActivityFields.ASSIGNMENTS]: false,
  [ObjectVacancyActivityFields.STATUS]: false,
  [ObjectVacancyActivityFields.PRIORITY]: false,
  [ObjectVacancyActivityFields.RECORD]: false,
  [ObjectVacancyActivityFields.PLANNED_RENT]: false,
  [ObjectVacancyActivityFields.BEFORE_RENT]: false,
  [ObjectVacancyActivityFields.AREA]: false,
  [ObjectVacancyActivityFields.SQUARE_METER_RENT]: false,
  [ObjectVacancyActivityFields.MARKET_RENT]: false,
  [ObjectVacancyActivityFields.HIGHEST_RENT_BY_NOW]: false,
  [ObjectVacancyActivityFields.TAGS]: false,
  [ObjectVacancyActivityFields.DESCRIPTION]: false,
  [ObjectVacancyActivityFields.ASSIGNED_TO]: false,
  [ObjectVacancyActivityFields.RESPONSIBLE]: false,
  [ObjectVacancyActivityFields.EVALUATION]: false,
};

export const OBJECT_VACANCY_ACTIVITY: TranslatedOption<ObjectVacancyActivityFields>[] =
  [
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.TicketNumber",
          "Ticketnummer"
        ),
      value: ObjectVacancyActivityFields.TICKET_NUMBER,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.Name",
          "Name"
        ),
      value: ObjectVacancyActivityFields.NAME,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.Assignments",
          "Zuweisungen"
        ),
      value: ObjectVacancyActivityFields.ASSIGNMENTS,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.Status",
          "Status"
        ),
      value: ObjectVacancyActivityFields.STATUS,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.Priority",
          "Priorität"
        ),
      value: ObjectVacancyActivityFields.PRIORITY,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.Record",
          "Aufzeichnung"
        ),
      value: ObjectVacancyActivityFields.RECORD,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.PlannedRent",
          "Geplante Miete"
        ),
      value: ObjectVacancyActivityFields.PLANNED_RENT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.BeforeRent",
          "Vorherige Miete"
        ),
      value: ObjectVacancyActivityFields.BEFORE_RENT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.Area",
          "Fläche"
        ),
      value: ObjectVacancyActivityFields.AREA,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.SquareMeterRent",
          "Miete pro Quadratmeter"
        ),
      value: ObjectVacancyActivityFields.SQUARE_METER_RENT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.MarketRent",
          "Marktmiete"
        ),
      value: ObjectVacancyActivityFields.MARKET_RENT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.HighestRentByNow",
          "Höchste Miete bisher"
        ),
      value: ObjectVacancyActivityFields.HIGHEST_RENT_BY_NOW,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.Tags",
          "Tags"
        ),
      value: ObjectVacancyActivityFields.TAGS,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.Description",
          "Beschreibung"
        ),
      value: ObjectVacancyActivityFields.DESCRIPTION,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.AssignedTo",
          "Zugewiesen an"
        ),
      value: ObjectVacancyActivityFields.ASSIGNED_TO,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.Responsible",
          "Verantwortlich"
        ),
      value: ObjectVacancyActivityFields.RESPONSIBLE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ActivityVacancyFields.Evaluation",
          "Bewertung"
        ),
      value: ObjectVacancyActivityFields.EVALUATION,
    },
  ];

export const OBJECT_DAMAGE_CLAIM_FIELDS: Record<
  ObjectDamageClaimsFields,
  boolean
> = {
  [ObjectDamageClaimsFields.TICKET_NUMBER]: false,
  [ObjectDamageClaimsFields.NAME]: false,
  [ObjectDamageClaimsFields.ASSIGNMENTS]: false,
  [ObjectDamageClaimsFields.STATUS]: false,
  [ObjectDamageClaimsFields.PRIORITY]: false,
  [ObjectDamageClaimsFields.CREATED_AT]: false,
  [ObjectDamageClaimsFields.DAMAGE_DATE]: false,
  [ObjectDamageClaimsFields.DUE_DATE]: false,
  [ObjectDamageClaimsFields.ESTIMATED_COST]: false,
  [ObjectDamageClaimsFields.SUPPLY_COST]: false,
  [ObjectDamageClaimsFields.TAGS]: false,
  [ObjectDamageClaimsFields.DESCRIPTION]: false,
  [ObjectDamageClaimsFields.ASSIGNED_TO]: false,
  [ObjectDamageClaimsFields.RESPONSIBLE]: false,
  [ObjectDamageClaimsFields.INSURANCE]: false,
};

export const OBJECT_DAMAGE_CLAIM: TranslatedOption<ObjectDamageClaimsFields>[] =
  [
    {
      value: ObjectDamageClaimsFields.TICKET_NUMBER,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.TicketNumber",
          "Ticketnummer"
        ),
    },
    {
      value: ObjectDamageClaimsFields.NAME,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.DisplayName",
          "Name"
        ),
    },
    {
      value: ObjectDamageClaimsFields.ASSIGNMENTS,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.Assignments",
          "Zuordnungen"
        ),
    },
    {
      value: ObjectDamageClaimsFields.STATUS,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.Status",
          "Status"
        ),
    },
    {
      value: ObjectDamageClaimsFields.PRIORITY,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.Priority",
          "Priorität"
        ),
    },
    {
      value: ObjectDamageClaimsFields.CREATED_AT,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.CreatedAt",
          "Aufnahme"
        ),
    },
    {
      value: ObjectDamageClaimsFields.DAMAGE_DATE,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.DamageDate",
          "Schadensdatum"
        ),
    },
    {
      value: ObjectDamageClaimsFields.DUE_DATE,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.DueDate",
          "Fälligkeit"
        ),
    },
    {
      value: ObjectDamageClaimsFields.ESTIMATED_COST,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.EstimatedCost",
          "Geschätzte Kosten"
        ),
    },
    {
      value: ObjectDamageClaimsFields.SUPPLY_COST,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.SupplyCost",
          "Angebotskosten"
        ),
    },
    {
      value: ObjectDamageClaimsFields.TAGS,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.Tags",
          "Tags"
        ),
    },
    {
      value: ObjectDamageClaimsFields.DESCRIPTION,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.Description",
          "Beschreibung"
        ),
    },
    {
      value: ObjectDamageClaimsFields.ASSIGNED_TO,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.AssignedTo",
          "Zugewiesen an"
        ),
    },
    {
      value: ObjectDamageClaimsFields.RESPONSIBLE,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.Responsible",
          "Verantwortliche"
        ),
    },
    {
      value: ObjectDamageClaimsFields.INSURANCE,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectDamageClaims.InsuranceClaim",
          "Versicherung"
        ),
    },
  ];

export const OBJECT_DAMAGE_CLAIM_FILTER: {
  label: () => string;
  value: ObjectTableActivityFilter;
}[] = [
  {
    value: ObjectTableActivityFilter.TOTAL,
    label: () => "In Total",
  },
  {
    value: ObjectTableActivityFilter.ACTIVE,
    label: () => "Active",
  },
  {
    value: ObjectTableActivityFilter.INACTIVE,
    label: () => "Inactive",
  },
  {
    value: ObjectTableActivityFilter.ON_HOLD,
    label: () => "On Hold",
  },
];

export const OBJECT_RENTAL_INCOME_MONTHS: TranslatedOption<number>[] = [
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.TimeRange.Monthly", "Monatlich"),
    value: 1,
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.TimeRange.Annual", "Jährlich"),
    value: 12,
  },
];

export const OBJECT_RISK_MANAGEMENT_CHART_TYPES: TranslatedOption<ObjectRiskManagementChart>[] =
  [
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.RiskManagementChart.Planned",
          "Geplante Miete"
        ),
      value: ObjectRiskManagementChart.PLANNED,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.RiskManagementChart.Current",
          "Aktuelle Miete"
        ),
      value: ObjectRiskManagementChart.CURRENT,
    },
  ];

export const OBJECT_MAINTENANCE_CONTRACTS_FIELDS: Record<
  ObjectMaintenanceContractsFields,
  boolean
> = {
  [ObjectMaintenanceContractsFields.TICKET_NUMBER]: false,
  [ObjectMaintenanceContractsFields.NAME]: false,
  [ObjectMaintenanceContractsFields.ASSIGNMENTS]: false,
  [ObjectMaintenanceContractsFields.STATUS]: false,
  [ObjectMaintenanceContractsFields.PRIORITY]: false,
  [ObjectMaintenanceContractsFields.CREATED_AT]: false,
  [ObjectMaintenanceContractsFields.CONTRACT_CATEGORY]: false,
  [ObjectMaintenanceContractsFields.CONTRACT_ACCOUNT]: false,
  [ObjectMaintenanceContractsFields.CONTRACT_PARTNER]: false,
  [ObjectMaintenanceContractsFields.DUE_DATE]: false,
  [ObjectMaintenanceContractsFields.CONTRACT_TYPE]: false,
  [ObjectMaintenanceContractsFields.CONTRACT_UNTIL]: false,
  [ObjectMaintenanceContractsFields.NOTICE_PERIOD]: false,
  [ObjectMaintenanceContractsFields.TERMINATION_BY]: false,
  [ObjectMaintenanceContractsFields.RENEWAL]: false,
  [ObjectMaintenanceContractsFields.CONTRACT_COST]: false,
  [ObjectMaintenanceContractsFields.REALLOCABLE]: false,
  [ObjectMaintenanceContractsFields.ALLOCABLE_COSTS]: false,
  [ObjectMaintenanceContractsFields.OWN_COST]: false,
  [ObjectMaintenanceContractsFields.TAGS]: false,
  [ObjectMaintenanceContractsFields.DESCRIPTION]: false,
  [ObjectMaintenanceContractsFields.ASSIGNED_TO]: false,
};

export const OBJECT_MAINTENANCE_CONTRACTS: TranslatedOption<ObjectMaintenanceContractsFields>[] =
  [
    {
      value: ObjectMaintenanceContractsFields.TICKET_NUMBER,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.TicketNumber"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.NAME,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.DisplayName"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.ASSIGNMENTS,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.Assignments"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.STATUS,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.Status"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.PRIORITY,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.Priority"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.CREATED_AT,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.CreatedAt"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.CONTRACT_CATEGORY,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.ContractCategory"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.CONTRACT_ACCOUNT,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.ContractAccount"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.CONTRACT_PARTNER,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.ContractPartner"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.DUE_DATE,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.DueDate"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.CONTRACT_TYPE,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.ContractType"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.CONTRACT_UNTIL,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.ContractUntil"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.NOTICE_PERIOD,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.NoticePeriod"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.TERMINATION_BY,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.TerminationBy"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.RENEWAL,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.Renewal"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.CONTRACT_COST,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.ContractCost"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.REALLOCABLE,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.Reallocable"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.ALLOCABLE_COSTS,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.AllocableCosts"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.OWN_COST,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.OwnCost"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.TAGS,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.Tags"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.DESCRIPTION,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.Description"
        ),
    },
    {
      value: ObjectMaintenanceContractsFields.ASSIGNED_TO,
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectMaintenanceContracts.AssignedTo"
        ),
    },
  ];

export const OBJECT_PLAN_CHART_VALUES: TranslatedOption<ObjectLoanChart>[] = [
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.LoanChartValues.Current",
        "Aktuell"
      ),
    value: ObjectLoanChart.CURRENT,
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.LoanChartValues.Total", "Gesamt"),
    value: ObjectLoanChart.TOTAL,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.LoanChartValues.Future",
        "Zukünftig"
      ),
    value: ObjectLoanChart.FUTURE,
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.LoanChartValues.Finished",
        "Abgeschlossen"
      ),
    value: ObjectLoanChart.FINISHED,
  },
];

export const OBJECT_LOAN_TABLE_FIELDS: Record<ObjectLoanTableFields, boolean> =
  {
    [ObjectLoanTableFields.DISPLAY_NAME]: false,
    [ObjectLoanTableFields.LOAN_AMOUNT]: false,
    [ObjectLoanTableFields.INTEREST_RATE]: false,
    [ObjectLoanTableFields.FINAL_AMOUNT]: false,
    [ObjectLoanTableFields.START_DATE]: false,
    [ObjectLoanTableFields.END_DATE]: false,
  };

export const OBJECT_LOAN_TABLE_VALUES: TranslatedOption<ObjectLoanTableFields>[] =
  [
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectLoanTable.DisplayName",
          "Anzeigename"
        ),
      value: ObjectLoanTableFields.DISPLAY_NAME,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectLoanTable.LoanAmount",
          "Darlehensbetrag"
        ),
      value: ObjectLoanTableFields.LOAN_AMOUNT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectLoanTable.InterestsRate",
          "Zinssatz"
        ),
      value: ObjectLoanTableFields.INTEREST_RATE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectLoanTable.FinalAmount",
          "Endbetrag"
        ),
      value: ObjectLoanTableFields.FINAL_AMOUNT,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectLoanTable.StartDate",
          "Startdatum"
        ),
      value: ObjectLoanTableFields.START_DATE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectLoanTable.EndDate",
          "Enddatum"
        ),
      value: ObjectLoanTableFields.END_DATE,
    },
  ];

export const OBJECT_LOAN_TABLE_FILTERS: TranslatedOption<ObjectLoanTableFilter>[] =
  [
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectLoanTableFilter.Total",
          "Gesamt"
        ),
      value: ObjectLoanTableFilter.TOTAL,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectLoanTableFilter.Ongoing",
          "Laufend"
        ),
      value: ObjectLoanTableFilter.ONGOING,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectLoanTableFilter.Future",
          "Zukünftig"
        ),
      value: ObjectLoanTableFilter.FUTURE,
    },
    {
      label: () =>
        i18n.t(
          "Component.PDFConstructor.Block.ObjectLoanTableFilter.Completed",
          "Abgeschlossen"
        ),
      value: ObjectLoanTableFilter.COMPLETED,
    },
  ];

import classNames from "classnames";
import _ from "lodash";
import LoadPage from "../../../../../../../../components/LoadPage/LoadPage";
import ModalManager from "../../../../../../../../components/ModalComponent/ModalManager";
import WrappedText from "../../../../../../../../components/WrappedText/WrappedText";
import i18n from "../../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../../model/AssetTypes";
import BFValueDisplay from "../../../../../../../../modules/abstract-ui/data/value-display/BFValueDisplay";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";
import { useStatisticQuery } from "../../../../../../../../redux/hooks";
import AssetService from "../../../../../../../../services/AssetService";
import DataBusDefaults from "../../../../../../../../services/DataBusDefaults";
import StringUtils from "../../../../../../../../utils/StringUtils";
import "./RiskScenarioCard.scss";
import RiskScenarioChart from "./RiskScenarioChart";
import RiskScenarioForm from "./RiskScenarioForm";
import {
  RiskScenario,
  RiskScenarioLoanRiskResponse,
} from "./RiskScenarioInterfaces";
import { RISK_SCENARIO_TABLE } from "./RiskScenarioService";

interface RiskScenarioCardProps {
  riskScenario: RiskScenario;
  className?: string;
  editable?: boolean;
}
const RiskScenarioCard = (props: RiskScenarioCardProps) => {
  const loanRiskData = useStatisticQuery<RiskScenarioLoanRiskResponse>(
    "LOAN_RISK",
    null,
    {
      objectIds: props.riskScenario.data.objectData.map((obj) => obj.objectId),
    }
  );

  if (loanRiskData?.loading) {
    return (
      <div className={`risk-scenario-card __card ${props.className || ""}`}>
        <LoadPage />
      </div>
    );
  }
  if (loanRiskData?.error) {
    return (
      <div className={`risk-scenario-card __card ${props.className || ""}`}>
        <div className={`error`}>Error loading loan risk data</div>
      </div>
    );
  }
  const singleValue = props.riskScenario.data.objectData.length === 1;
  const multipleLoans = loanRiskData?.data?.loans.length > 1;

  const allLoans = props.riskScenario.data.objectData
    .map((obj) => obj.loanData)
    .flat();
  const aggregations = {
    rentNet: _.sumBy(loanRiskData.data.objects, (obj) => obj.rentNet),
    rentNetPlan: _.sumBy(loanRiskData.data.objects, (obj) => obj.rentNetPlan),
    interestRate:
      _.sumBy(allLoans, (loan) => loan.interestRate) / allLoans.length,
    repaymentRate:
      _.sumBy(allLoans, (loan) => loan.repaymentRate) / allLoans.length,
    duration: _.sumBy(allLoans, (loan) => loan.duration) / allLoans.length,
    vacancyRate:
      _.sumBy(
        props.riskScenario.data.objectData,
        (obj) => obj.estimatedVacancyRate
      ) / props.riskScenario.data.objectData.length,
    otherCostRate:
      _.sumBy(
        props.riskScenario.data.objectData,
        (obj) => obj.estimatedCostFactor
      ) / props.riskScenario.data.objectData.length,
    rentGrowthEstimation:
      _.sumBy(
        props.riskScenario.data.objectData,
        (obj) => obj.estimatedRentNetGrowth
      ) / props.riskScenario.data.objectData.length,
  };
  return (
    <div
      className={classNames(
        `risk-scenario-card __card ${props.className || ""}`
      )}
    >
      <div className={`head-line`}>
        <div className={`title`}>{props.riskScenario.data.name}</div>
        <div className={`actions`}>
          {props.editable && (
            <BFButton
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  backdrop: "static",
                  size: "xxl",
                  noPadding: true,
                  content: (states, setStates, closeModal) => (
                    <RiskScenarioForm
                      type="edit"
                      onClose={closeModal}
                      onDelete={() => {
                        AssetService.deleteAsset({
                          assetId: props.riskScenario._id,
                          assetType: AssetTypes.Portfolio.RiskScenario,
                          tablesToReload: [RISK_SCENARIO_TABLE],
                        });
                        setTimeout(() => {
                          DataBusDefaults.closeAllModals();
                        });
                      }}
                      onError={(error) => {
                        DataBusDefaults.toast({
                          type: "error",
                          text: i18n.t(
                            "cb:RiskScenarioView.errorEdit",
                            "Bearbeiten des Szenarios fehlgeschlagen"
                          ),
                        });
                      }}
                      onSuccess={(data) => {
                        DataBusDefaults.toast({
                          type: "success",
                          text: i18n.t(
                            "cb:RiskScenarioView.successEdit",
                            "Szenario erfolgreich bearteitet"
                          ),
                        });
                        DataBusDefaults.reload({
                          identifiers: [RISK_SCENARIO_TABLE],
                        });
                      }}
                      riskScenario={props.riskScenario}
                    />
                  ),
                });
              }}
            >
              {i18n.t("Global.Buttons.edit")}
            </BFButton>
          )}
        </div>
      </div>
      {props.riskScenario.data.description && (
        <div className={`description`}>
          <WrappedText text={props.riskScenario.data.description} />
        </div>
      )}

      <div className={`data`}>
        <BFValueDisplay
          label={i18n.t("cb:RiscScenarioCard.planNKMPerMonth", "Plan NKM p.m.")}
          value={StringUtils.formatCurrency(aggregations.rentNetPlan / 12)}
        />
        <BFValueDisplay
          label={i18n.t("cb:RiscScenarioCard.planNKMPerYear", "Plan NKM p.a.")}
          value={StringUtils.formatCurrency(aggregations.rentNetPlan)}
        />
        <BFValueDisplay
          label={i18n.t(
            "cb:RiscScenarioCard.currentNKMPerMonth",
            "Ist NKM p.m."
          )}
          value={StringUtils.formatCurrency(aggregations.rentNet / 12)}
        />
        <BFValueDisplay
          label={i18n.t(
            "cb:RiscScenarioCard.currentNKMPerYear",
            "Ist NKM p.a."
          )}
          value={StringUtils.formatCurrency(aggregations.rentNet)}
        />
        <BFValueDisplay
          label={
            (singleValue ? "" : "Ø ") +
            i18n.t("cb:RiscScenarioCard.planGrowthNKM", "NKM Anpassung")
          }
          value={StringUtils.formatPercent(aggregations.rentGrowthEstimation)}
        />
        <BFValueDisplay
          label={
            (singleValue ? "" : "Ø ") +
            i18n.t("cb:RiscScenarioCard.planVacancyRate", "Leerstandsquote")
          }
          value={StringUtils.formatPercent(aggregations.vacancyRate)}
        />
        <BFValueDisplay
          label={
            (singleValue ? "" : "Ø ") +
            i18n.t("cb:RiscScenarioCard.planOtherCostRate", "Weitere Kosten")
          }
          value={StringUtils.formatPercent(aggregations.otherCostRate)}
        />

        <BFValueDisplay
          label={
            (!multipleLoans ? "" : "Ø ") +
            i18n.t("cb:RiscScenarioCard.planInterestRate", "Sollzins")
          }
          value={StringUtils.formatPercent(aggregations.interestRate)}
        />
        <BFValueDisplay
          label={
            (!multipleLoans ? "" : "Ø ") +
            i18n.t("cb:RiscScenarioCard.planRepaymentRate", "Tilgungsrate")
          }
          value={StringUtils.formatPercent(aggregations.repaymentRate)}
        />
        <BFValueDisplay
          label={
            (!multipleLoans ? "" : "Ø ") +
            i18n.t("cb:RiscScenarioCard.planDuration", "Laufzeit")
          }
          value={
            StringUtils.formatNumber(
              aggregations.duration,
              false,
              undefined,
              0
            ) +
            " " +
            i18n.t("Globals.Label.Years", "Jahre")
          }
        />
      </div>
      <RiskScenarioChart
        chartIdentifier={`risk-chart-view-${props.riskScenario._id}`}
        objectData={props.riskScenario.data.objectData}
      />
    </div>
  );
};

export default RiskScenarioCard;

import { AssetTypes } from "../../../../../model/AssetTypes";
import BaseAsset from "../../../../../model/general-assets/BaseAsset";
import LabeledInput from "../../../../abstract-ui/forms/LabeledInput";
import { RSLinkedAssetFieldIdentifier } from "../../../RSConstants";
import { Resubmission } from "../../../RSInterfaces";
import RSDocumentsDetails from "../document-details/RSDocumentsDetails";
import RSMailDetails from "../mail-details/RSMailDetails";

interface RSSubAssetMapperProps {
  asset: BaseAsset;
  resubmission: Resubmission;
}

const RSSubAssetMapper = (props: RSSubAssetMapperProps) => {
  const { resubmission, asset } = props;
  if (
    !resubmission.data.linkedAsset ||
    (!resubmission.data.linkedAsset.subAsset &&
      !resubmission.data.linkedAsset.assetField)
  ) {
    return null;
  }

  if (
    resubmission.data.linkedAsset.assetField?.identifier ===
    RSLinkedAssetFieldIdentifier.DSDocument
  ) {
    return (
      <RSDocumentsDetails
        assetParams={{
          asset: asset,
          type: asset?.["data"]?.["type"],
          assetType: resubmission.data.linkedAsset.assetType,
          documentsFieldPath:
            resubmission.data.linkedAsset.assetField?.fieldPath,
        }}
        documentLinkToCdn={resubmission.data.linkedAsset.assetField.id}
      />
    );
  }

  if (
    resubmission.data.linkedAsset.subAsset &&
    resubmission.data.linkedAsset.subAsset.assetType === AssetTypes.Comment
  ) {
    return (
      <LabeledInput
        label={resubmission.data.linkedAsset.assetDescription}
        type="sub"
      >
        <RSMailDetails resubmission={resubmission} />
      </LabeledInput>
    );
  }

  return null;
};

export default RSSubAssetMapper;

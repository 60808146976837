import { nanoid } from "nanoid";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import BaseAsset from "../../../../model/general-assets/BaseAsset";
import CDNService from "../../../../services/CDNService";
import CacheService from "../../../../services/CacheService";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import SubmitService from "../../../../services/SubmitService";
import { HTTP } from "../../../../utils/Http";
import { AssetCashBudgetEntry } from "../model/CashBudgetEntry";
import CashBudgetService from "./CashBudgetService";

class BookingServiceClass {
  async submitCategory(bookingId: string, categoryId: string) {
    const result = await HTTP.post({
      url: `/liquiplanservice/${bookingId}/setBookingCategory`,
      target: "EMPTY",
      bodyParams: {
        category: categoryId,
      },
    });

    CacheService.updateDataInCaches(result._id, result);

    CashBudgetService.clearFlexCachesPlan();
    CashBudgetService.clearFlexCaches();

    return result;
  }
  async validateBooking(bookingId: string) {
    const result = await HTTP.post({
      url: `/liquiplanservice/${bookingId}/validateBooking`,
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(bookingId, result);

    return result;
  }

  async validateBookings(bookingIds: string[]) {
    const result = await HTTP.post({
      url: `/liquiplanservice/validateBookings`,
      target: "EMPTY",
      bodyParams: {
        ids: bookingIds,
      },
    });
    for (const booking of result) {
      CacheService.update(booking);
    }
    return result;
  }

  async invalidateBooking(bookingId: string) {
    const result = await HTTP.post({
      url: `/liquiplanservice/${bookingId}/invalidateBooking`,
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(bookingId, result);
    return result;
  }
  async deleteBooking(bookingId: string) {
    const result = await HTTP.delete({
      url: `/asset/${AssetTypes.CashBudget.Booking}/${bookingId}`,
      target: "API",
    });

    CashBudgetService.clearFlexCachesPlan();
    CashBudgetService.clearFlexCaches();
  }

  async uploadBookingAttachments(booking: AssetCashBudgetEntry, files: File[]) {
    try {
      const promises = files.map((file) =>
        this.uploadBookingAttachment(booking, file, nanoid())
      );
      try {
        const result = await Promise.allSettled(promises);

        result.forEach((e, index) => {
          if (e.status === "rejected") {
            DataBusDefaults.toast({
              type: "error",
              text: i18n.t(
                "cb:BookingService.uploadAttachmentFailed",
                "Hochladen der Datei {{fileName}} ist fehlgeschlagen. Bitte versuchen Sie es erneut",
                {
                  fileName: files[index].name,
                }
              ),
            });
          }
        });
      } catch (err) {
        if (Array.isArray(err)) {
          err.forEach((e, index) => {
            if (e.status === "rejected") {
              DataBusDefaults.toast({
                type: "error",
                text: i18n.t(
                  "cb:BookingService.uploadAttachmentFailed",
                  "Hochladen der Datei {{fileName}} ist fehlgeschlagen. Bitte versuchen Sie es erneut",
                  {
                    fileName: files[index].name,
                  }
                ),
              });
            }
          });
        } else {
          DataBusDefaults.toast({
            type: "error",
            text: i18n.t(
              "cb:BookingService.uploadAttachmentFailedAll",
              "Upload war fehlerhaft, bitte versuchen Sie es erneut."
            ),
          });
        }
      }
    } finally {
      // reloads cache
      await CacheService.getData({
        id: booking._id,
        oType: "asset",
        assetType: AssetTypes.CashBudget.Booking,
        forceReload: true,
        silentReload: true,
        ignoreDelay: true,
      });
    }
  }

  async uploadBookingAttachment(
    booking: AssetCashBudgetEntry,
    file: File,
    tempId: string
  ) {
    const { cdnID } = await CDNService.uploadFile({
      tempID: "tst",
      assetType: AssetTypes.CashBudget.Attachment,
      assetField: "data.files",
      filename: file.name,
      file: file,
    });

    const submittedData = (await SubmitService.submitDataAsync({
      id: tempId,
      type: "asset",
      assetType: AssetTypes.CashBudget.Attachment,
      data: {
        data: {
          type: booking.data.type,
          uploadType: "manual",
          files: {
            [cdnID]: {},
          },
        },
      },
      ignorePropChecks: true,
    })) as BaseAsset;
    const data = await HTTP.post({
      url: `/liquiplanservice/${booking._id}/linkAsset`,
      target: "EMPTY",
      bodyParams: {
        assetType: AssetTypes.CashBudget.Attachment,
        assetId: submittedData._id,
      },
    });
    CacheService.update(data);

    return data;
  }
}
const BookingService = new BookingServiceClass();
export default BookingService;

import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Loader } from "rsuite";
import ActionComponent from "../../configurable/components/ActionComponent/ActionComponent";
import Log from "../../debug/Log";
import { User } from "../../model/db/User";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import GenericForms, { FormDefinition } from "../../modules/generic-forms/GenericForms";
import { AppState } from "../../redux/store";
import { loginUser } from "../../services/AuthenticationService";
import DataBus from "../../services/DataBus";
import { SubmitMessage } from "../../services/SubmitService";
import { DataBusSubKeys } from "../../utils/Constants";
import { getErrorLocalized } from "../../utils/ErrorCodes";
import { HTTP } from "../../utils/Http";
import "./CompleteRegistrationForm.scss";

type Props = {
	loginUser: (
		email: string,
		password: string,
		mandator: string,
		onSuccess: (data: User) => void,
		onError: (err: any) => void
	) => void;
	formDefinition: FormDefinition;
} & RouteComponentProps &
	WithTranslation;

type States = {
	state: "loading" | "success" | "error" | "login";
	dataObj?: any;
};

const actionIDSubmit = "set-new-password-submit";
class CompleteRegistrationForm extends Component<Props, States> {
	static defaultProps = {};
	readonly state: States = {
		state: "loading"
	};

	componentDidMount() {
		HTTP.get({
			url: "user/registerToken",
			queryParams: {
				param: {
					mandator: this.props.match.params["mandator"],
					token: this.props.match.params["token"]
				}
			},
			headers: {
				"Content-Type": "application/json"
			}
		})
			.then(data => {
				this.setState({
					state: "success",
					dataObj: data
				});
			})
			.catch(err => {
				this.setState({
					state: "error",
					dataObj: {
						context: "completeRegistration",
						error: err
					}
				});
			});
	}
	render() {
		const { dataObj, state } = this.state;

		if (state === "loading") {
		}

		if (state === "error") {
		}

		return (
			<div className="complete-registration-form">
				{state === "loading" || state === "login" ? (
					<div>
						<Loader />
					</div>
				) : state === "error" ? (
					<>
						<div className={`error-container`}>
							<div className={`icon`}>
								<BfIcon type="bf" data="alert-triangle" size="2x" />
							</div>
							<div className={`message`}>{getErrorLocalized(this.state.dataObj.error, this.state.dataObj.context)}</div>
						</div>

						<div className="action-row">
							<div className="fill" />
							<BFButton
								textKey={"views.completeRegistration.backToLogin"}
								onClick={() => DataBus.emit("ROUTE", { route: "/login" })}
							/>
							<div className="fill" />
						</div>
					</>
				) : (
					<>
						<GenericForms
							translateFunc={this.props.i18n.t}
							formValue={this.state.dataObj}
							actionIdMapping={{
								submit: actionIDSubmit
							}}
							formDefinition={this.props.formDefinition}
							onFormSubmit={data => {
								return new Promise((resolve, reject) => {
									DataBus.emit(DataBusSubKeys.SUBMIT, {
										id: "set-new-password",
										type: "user",
										initialData: this.state.dataObj,
										data : {
											password: data.password,
											mandator: this.props.match.params["mandator"],
											token: this.props.match.params["token"]
										},
										ignorePropChecks: true,
										pushToCache: true,
										properties: this.props.formDefinition.properties,
										overwriteUrl: "user/setPassword",
										overwriteMethod: "post",
										onSuccess: result => {
											// this.handleEvents(this.props.onSubmitSuccess, { value: data })
											this.setState({
												state: "login"
											});
											DataBus.emit("TOAST", {
												type: "success",
												textKey:
													data.type === "reset_password"
														? "views.completeRegistration.Success.reset_password"
														: "views.completeRegistration.Success.user_creation"
											});

											this.props.loginUser(
												dataObj.email,
												data.password,
												this.props.match.params["mandator"],
												data => {
													resolve(data);
												},
												err => {
													this.setState({
														state: "error",
														dataObj: {
															context: "default",
															error: err
														}
													});
													reject();
												}
											);
										},
										onError: err => {
											this.setState({
												state: "error",
												dataObj: {
													context: "completeRegistrationUserSubmit",
													error: err
												}
											});
											reject();
										}
									} as SubmitMessage);
								});
							}}
							onFormCancel={() => Log.debug("canceled")}
						/>

						<div className="action-row">
							<BFButton
								textKey={"views.completeRegistration.backToLogin"}
								onClick={() => DataBus.emit("ROUTE", { route: "/login" })}
							/>
							<div className="fill" />
							<ActionComponent actionId={actionIDSubmit} appearance={"primary"} textKey={"Passwort Setzen"} />
						</div>
					</>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: AppState) => ({
	// user: state.global.user
	formDefinition: state.global.config.standardForms["setNewPassword"]
});

export default withTranslation()(connect(mapStateToProps, { loginUser })(withRouter(CompleteRegistrationForm)));

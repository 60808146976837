import { ActivityAbstractStructClass } from "@/redux/actions/struct/implemented/ActivityAbstractStruct";
import { useRouteMatch } from "react-router-dom";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../../../i18n";
import BFDropdown from "../../../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import { MailIncomingComment } from "../../../../../../modules/comments-module/Comments";
import CommentsService from "../../../../../../modules/comments-module/CommentsService";
import DataBusDefaults from "../../../../../../services/DataBusDefaults";
import { useActivityConstants } from "../../../ActivityHooks";
import {
  AP_LIST_TABLE_IDENTIFIER,
  AP_UNASSGINED_MESSAGES,
} from "../../../ActivityUtils";
import APCreationComponent from "../../../modals/APCreationComponent";
import APAssignMessageToActivity from "../APAssignMessageToActivity";

type APAssignMessageToActivityActionsProps = {
  units: string[];
  mail: MailIncomingComment;
  activityStruct: ActivityAbstractStructClass<any>;
};

const APAssignMessageToActivityActions = (
  props: APAssignMessageToActivityActionsProps
) => {
  const { units, mail } = props;
  const constants = useActivityConstants();
  const match = useRouteMatch();

  return (
    <div>
      {units && units.length > 0 && (
        <div className={`action-row`}>
          <div className={`fill`} />
          <BFDropdown
            className={`margin-left-10`}
            placement="bottomEnd"
            appearance="primary"
            label={i18n.t(
              "apTemplate:UnassignedMessages.AssignAsActivity",
              "Aktivität zuordnen",
              {
                ns: [constants?.assetType, "apTemplate"],
              }
            )}
            items={[
              {
                type: "button",
                text: i18n.t(
                  "apTemplate:UnassignedMessages.AssignToExistingActivity",
                  "Aktivität suchen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                ),
                onSelect: () => {
                  ModalManager.show({
                    backdrop: "static",
                    size: "xxl",
                    noPadding: true,
                    content: (states, setStates, closeModal) => (
                      <APAssignMessageToActivity
                        activityStruct={props.activityStruct}
                        onClose={closeModal}
                        mail={mail}
                        onAssign={async (damage) => {
                          try {
                            await CommentsService.assignCommentToActivity(
                              props.mail._id,
                              constants?.assetType,
                              damage._id
                            );
                            DataBusDefaults.toast({
                              type: "success",
                              text: i18n.t(
                                "apTemplate:UnassignedMessages.AssignToExistingActivitySuccess",
                                "Email erfolgreich zur Aktivität zugeordnet",
                                {
                                  ns: [constants?.assetType, "apTemplate"],
                                }
                              ),
                            });
                            DataBusDefaults.reload({
                              identifiers: [
                                AP_LIST_TABLE_IDENTIFIER + constants?.assetType,
                                AP_UNASSGINED_MESSAGES,
                              ],
                            });
                            closeModal();
                            DataBusDefaults.route({
                              route: match.path.replace("/:id", ""),
                            });
                          } catch (err) {
                            DataBusDefaults.toast({
                              type: "error",
                              text: i18n.t(
                                "apTemplate:UnassignedMessages.AssignToExistingActivityError",
                                "Fehler beim Zuordnen zur Aktivität",
                                {
                                  ns: [constants?.assetType, "apTemplate"],
                                }
                              ),
                            });
                          }
                        }}
                      />
                    ),
                  });
                },
              },
              {
                type: "button",
                text: i18n.t(
                  "apTemplate:UnassignedMessages.aCreateNewActivity",
                  "Neue Aktivität erstellen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                ),
                onSelect: () => {
                  ModalManager.show({
                    size: "lg",
                    backdrop: "static",
                    noPadding: true,
                    content: (states, setStates, closeModal) => (
                      <APCreationComponent
                        constants={constants}
                        onClose={closeModal}
                        mail={props.mail}
                        onError={(error) => {
                          DataBusDefaults.toast({
                            type: "error",
                            text: i18n.t(
                              "apTemplate:List.Activities.createDamageError",
                              "Fehler beim Erstellen der Aktivität",
                              {
                                ns: [constants?.assetType, "apTemplate"],
                              }
                            ),
                          });
                        }}
                        onSuccess={() => {
                          DataBusDefaults.toast({
                            type: "success",
                            text: i18n.t(
                              "apTemplate:List.Activities.createDamageSuccess",
                              "Aktivität erfolgreich angelegt",
                              {
                                ns: [constants?.assetType, "apTemplate"],
                              }
                            ),
                          });
                          DataBusDefaults.reload({
                            identifiers: [
                              AP_LIST_TABLE_IDENTIFIER + constants?.assetType,
                              AP_UNASSGINED_MESSAGES,
                            ],
                          });
                          DataBusDefaults.route({
                            route: match.path.replace("/:id", ""),
                          });
                        }}
                      />
                    ),
                  });
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default APAssignMessageToActivityActions;

import classNames from "classnames";
import moment from "moment";
import React, { useRef } from "react";
import i18n from "../../../../../i18n";
import ObjectIdService from "../../../../../utils/ObjectIdUtils";
import BFButton from "../../../../abstract-ui/general/Button/BFButton";
import { BarTask } from "../../types/bar-task";
import { Task } from "../../types/public-types";

export type TaskListProps = {
  headerHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  rowHeight: number;
  ganttHeight: number;
  locale: string;
  tasks: Task[];
  taskListRef: React.RefObject<HTMLDivElement>;
  horizontalContainerClass?: string;
  selectedTask: BarTask | undefined;
  setSelectedTask: (task: string) => void;
  onExpanderClick: (task: Task) => void;
  onTaskUpdate: (task: Task, newIndex?: number) => void;
  onTaskDelete: (taskId: string) => void;
  onTaskCreate: (task: Task, indexToPush: number) => void;
  progressChange: "manual" | "check";
  readonly?: boolean;
  includeSaturday?: boolean;
  includeSunday?: boolean;
  TaskListHeader: React.FC<{
    headerHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
    readonly?: boolean;
    progressChange?: "manual" | "check";
  }>;
  TaskListTable: React.FC<{
    rowHeight: number;
    rowWidth: string;
    fontFamily: string;
    fontSize: string;
    locale: string;
    tasks: Task[];
    selectedTaskId: string;
    onTaskDelete: (taskId: string) => void;
    setSelectedTask: (taskId: string) => void;
    onExpanderClick: (task: Task) => void;
    onTaskUpdate: (task: Task, newIndex?: number) => void;
    onTaskCreate: (task: Task, indexToPush: number) => void;
    readonly?: boolean;
    progressChange?: "manual" | "check";
    includeSaturday?: boolean;
    includeSunday?: boolean;
  }>;
};

const TaskListCP: React.FC<TaskListProps> = ({
  headerHeight,
  fontFamily,
  fontSize,
  rowWidth,
  rowHeight,
  tasks,
  selectedTask,
  setSelectedTask,
  onExpanderClick,
  locale,
  ganttHeight,
  taskListRef,
  horizontalContainerClass,
  TaskListHeader,
  TaskListTable,
  onTaskUpdate,
  onTaskDelete,
  onTaskCreate,
  progressChange,
  readonly,
  includeSaturday,
  includeSunday,
}) => {
  const horizontalContainerRef = useRef<HTMLDivElement>(null);

  const headerProps = {
    headerHeight,
    fontFamily,
    fontSize,
    rowWidth,
    progressChange,
    readonly,
  };
  const selectedTaskId = selectedTask ? selectedTask.id : "";
  const tableProps = {
    rowHeight,
    rowWidth,
    fontFamily,
    fontSize,
    tasks,
    locale,
    selectedTaskId: selectedTaskId,
    setSelectedTask,
    onExpanderClick,
    onTaskUpdate,
    onTaskDelete,
    onTaskCreate,
    progressChange,
    readonly,
    includeSaturday,
    includeSunday,
  };

  return (
    <div ref={taskListRef}>
      <TaskListHeader {...headerProps} />
      <div
        ref={horizontalContainerRef}
        className={classNames(
          horizontalContainerClass,
          "task-list",
          "vertical"
        )}
        style={ganttHeight ? { height: ganttHeight } : {}}
      >
        {tasks.length === 0 && (
          <div className={`no-task-yet`}>
            {!readonly && onTaskCreate ? (
              <BFButton
                appearance="link"
                onClick={() => {
                  onTaskCreate(
                    {
                      id: ObjectIdService.createNewObjectIdInstance().toString(),
                      name: "",
                      start: moment().utc(true).startOf("day").toDate(),
                      end: moment()
                        .utc(true)
                        .add(1, "day")
                        .startOf("day")
                        .toDate(),
                      type: "task",
                      progress: 0,
                    },
                    0
                  );
                }}
              >
                {i18n.t("GanttChart.AddTask", "Aufgabe hinzufügen")}
              </BFButton>
            ) : (
              <div className={`info-text`}>
                {i18n.t("GanttChart.NoTaskYet", "Keine Aufgabe vorhanden")}
              </div>
            )}
          </div>
        )}
        {tasks.length > 0 && <TaskListTable {...tableProps} />}
      </div>
    </div>
  );
};
export const TaskList = React.memo(TaskListCP);

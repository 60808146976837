import CDNImageGallery, {
  CDNImagePropsWithContent,
} from "@/components/CDNImageGallery/CDNImageGallery";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import BFDropzone from "@/modules/abstract-ui/dropzone/BFDropzone";
import { hasValue } from "@/utils/Helpers";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import {
  DocumentStoreAssetParams,
  DocumentStoreDocument,
} from "../../DSInterfaces";
import DSService from "../../DSService";
import DSUploadDialog from "../DSUploadDialog/DSUploadDialog";
import "./DSImageGallery.scss";

interface DSImageGalleryProps {
  id?: string;
  directory?: string;
  assetParams: DocumentStoreAssetParams;
  usePagination?: boolean;
  dimension?: "thumb" | "sl" | "lg" | "md" | "sm" | "xs";
}
const DSImageGallery = (props: DSImageGalleryProps) => {
  const [id] = useState(props.id || _.uniqueId("_ds-image-gallery-"));

  const directory = DSService.getDirectoryById(
    props.assetParams.type,
    props.assetParams.assetType,
    props.assetParams.documentsFieldPath,
    props.directory,
    true
  );

  const documents: (DocumentStoreDocument & {
    content_type: string;
    fileKey: string;
  })[] = (
    (_.property(props.assetParams.documentsFieldPath)(
      props.assetParams.asset
    ) as DocumentStoreDocument[]) || []
  ).map((e) => {
    const cdnFile = props.assetParams.asset.cdn.find(
      (a) => a._id === e.linkToCdn
    );
    return { ...e, fileKey: cdnFile.key, content_type: cdnFile.content_type };
  });

  const useDocuments = documents
    .filter((doc) => doc.status !== "archived")
    .filter((doc) => doc.content_type?.startsWith("image/"))
    .filter((e) => (props.directory ? e.directoryId === props.directory : true))
    .map(
      (e) =>
        ({
          assetField: props.assetParams.documentsFieldPath,
          assetId: props.assetParams.asset._id,
          assetType: props.assetParams.assetType,
          cdnId: e.linkToCdn,
          fileKey: e.fileKey,
          filename: e.name,
          hasFolderReadPermissions: true,
          renderContent: e.name,
        } as CDNImagePropsWithContent)
    );

  return (
    <div className={classNames(`ds-image-gallery`)}>
      <BFDropzone
        onDrop={(accepted, rejected, event) => {
          if (accepted.length > 0 && rejected.length === 0) {
            ModalManager.show({
              title: i18n.t(
                "ds:DocumentStore.UploadDocuments.Title",
                "Dokumente hochladen"
              ),
              buttons: [],
              backdrop: "static",
              size: "lg",
              modalClassName: "ap-activity-upload-attachment-dialog",
              content: (states, setStates, closeModal) => (
                <DSUploadDialog
                  directory={directory}
                  files={accepted}
                  assetParams={props.assetParams}
                  onSuccess={(data) => {
                    closeModal();
                  }}
                  onAbort={() => {
                    closeModal();
                  }}
                />
              ),
            });
          }
        }}
        multipe
        accept={{
          "image/*": [".heic", ".heif", ".webp"],
        }}
        render={(open) => (
          <CDNImageGallery
            id={id}
            openable
            usePagination={
              hasValue(props.usePagination) ? props.usePagination : true
            }
            useMousewheel
            useNavigationButtons
            cdnImages={useDocuments}
            dimension={props.dimension || "lg"}
            // additionalPages={additionalPages}
            emptyPage={
              <div>
                <div>
                  {i18n.t("CDNImageGallery.NoImages", "Keine Bilder vorhanden")}
                </div>
              </div>
            }
          />
        )}
      />
    </div>
  );
};

export default DSImageGallery;

import classNames from "classnames";
import _ from "lodash";
import { Field } from "react-final-form";
import { Trans } from "react-i18next";
import FormStruct from "../../../components/Form/FormStruct/FormStruct";
import i18n from "../../../i18n";
import { CommunicationSubActivity } from "../../../model/general-assets/BaseAsset";
import EZAutocomplete from "../../ez-form/form-elements/ez-autocomplete/EZAutocomplete";
import CommentsService from "../CommentsService";
import "./CommentEmailUploadDialog.scss";

interface CommentEmailUploadDialogProps {
  files: File[];
  assetType: string;
  assetId: string;
  possibleContexts?: CommunicationSubActivity[];

  onClose: () => void;

  onSuccess: (data: any) => void;
  onError?: (error: any) => void;
}
const CommentEmailUploadDialog = (props: CommentEmailUploadDialogProps) => {
  return (
    <FormStruct<any>
      submitText={i18n.t("Global.Buttons.upload")}
      className={classNames(`comment-email-upload-dialog`)}
      title={i18n.t("CommentModule.UploadEmail.Title", "E-Mail hochladen")}
      description={
        <>
          <p>
            {i18n.t(
              "CommentModule.UploadEmail.Description1",
              "Wollen Sie folgende E-Mails wirklich hochladen? "
            )}
          </p>
          <div className={`files-description`}>
            <ul>
              {props.files.map((file, index) => {
                return <li key={index}>{file.name}</li>;
              })}
            </ul>
          </div>
          <p>
            {i18n.t(
              "CommentModule.UploadEmail.Description",
              "Geben Sie optional einen Kontext an, um die E-Mails einem bestimmten Vorgang zuzuordnen."
            )}
          </p>
        </>
      }
      onSubmit={async (values) => {
        try {
          await CommentsService.uploadEmails(
            props.files,
            props.assetType,
            props.assetId,
            values.context
          );
          props.onSuccess(null);
          props.onClose();
        } catch (err) {
          props.onError?.(err);
        }
      }}
      onAbort={props.onClose}
      render={({ form }) => (
        <div>
          <div className={`__field`}>
            <Field name="context">
              {({ input, meta }) => (
                <EZAutocomplete
                  appearance="bf"
                  error={meta.error && meta.touched ? meta.error : undefined}
                  maxLength={30}
                  newEntryLabel={(search) => (
                    <Trans i18nKey="CommentInput.createContext">
                      Kontext <strong>{{ search }}</strong> erstellen
                    </Trans>
                  )}
                  value={input.value}
                  onChange={input.onChange}
                  options={_.uniq(
                    (props.possibleContexts || []).map(
                      (context) => context.displayName
                    )
                  )}
                  placeholder={i18n.t(
                    "CommentInput.selectSubActivity",
                    "Kontext setzen"
                  )}
                  onBlur={() => {}}
                />
              )}
            </Field>
          </div>
        </div>
      )}
    />
  );
};

export default CommentEmailUploadDialog;

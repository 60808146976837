import classNames from "classnames";
import BaseAsset from "../../../../../model/general-assets/BaseAsset";
import { Comment } from "../../../../comments-module/Comments";
import CommentEntry from "../../../../comments-module/components/CommentEntry";
import { Resubmission } from "../../../RSInterfaces";
import RSSubAssetLoader from "../sub-asset-loader/RSSubAssetLoader";
import "./RSMailDetails.scss";

interface RSMailDetailsProps {
  resubmission: Resubmission;
  hideFullScreenButton?: boolean;
}

const RSMailDetails = (props: RSMailDetailsProps) => {
  const renderMailDetails = (asset: BaseAsset) => {
    const comment = asset as Comment;
    return (
      <CommentEntry
        comment={comment}
        hideResubmissionActionForMail
        allowFullscreen={!props.hideFullScreenButton}
        hideAllActions={true}
      />
    );
  };

  return (
    <div className={classNames("rs-mail-details")}>
      <RSSubAssetLoader
        resubmission={props.resubmission}
        renderSubAsset={renderMailDetails}
      />
    </div>
  );
};

export default RSMailDetails;

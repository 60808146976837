import classNames from "classnames";
import { Animation } from "rsuite";
import BFButton from "../../general/Button/BFButton";
import BFDropdown from "../../general/Dropdown/BFDropdown";
import "./BFTableFilterEntry.scss";
import { FilterOption, FilterValue } from "./BFTableFilterModel";

interface BFTableFilterEntryProps {
  filterOptions: FilterOption;
  value: FilterValue;
  onChange: (value: FilterValue) => void;
  allValues: FilterValue[];
}
const BFTableFilterEntry = (props: BFTableFilterEntryProps) => {
  const labelData = props.filterOptions?.renderValueData(
    props.value.value,
    props.allValues
  );

  return (
    <Animation.Bounce in={true}>
      <div className={classNames(`bf-table-filter-entry`)}>
        <div className="button-wrapper">
          <BFDropdown
            className={`filter-entry-dropdown ${props.filterOptions?.key.replaceAll(
              ".",
              "-"
            )}`}
            items={[
              {
                type: "panel",
                children: props.filterOptions?.renderOverlay(
                  props.value?.value,
                  (value) =>
                    value
                      ? props.onChange({
                          filterKey: props.filterOptions?.key,
                          value,
                        })
                      : props.onChange(null),
                  props.allValues
                ),
              },
            ]}
            toggleAs={BFButton}
            //   appearance="outline"
            label={
              <div className={`filter-entry-label`}>
                <div className={`type-label`}>{labelData?.typeLabel}</div>
                {labelData?.valueLabel && (
                  <div
                    className={`value-label`}
                    style={{ maxWidth: props.filterOptions?.maxWidth }}
                  >
                    {labelData?.valueLabel}
                  </div>
                )}
              </div>
            }
          />
          <BFButton
            className={`delete-button`}
            apperance="default"
            icon={{ type: "light", data: "close", size: "xs" }}
            onClick={() => props.onChange(null)}
          />
        </div>
      </div>
    </Animation.Bounce>
  );
};

export default BFTableFilterEntry;

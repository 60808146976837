import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import i18n from "../../i18n";
import { useSocket, useTypedSelector } from "../../redux/hooks";
import { AppState } from "../../redux/store";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BfIcon from "../abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../abstract-ui/icon/DefaultIcons";
import { openChangelogModal } from "../changelog/ChangelogModal";
import {
  hasNewChangelogEntry,
  updateLastChangelogEntry,
} from "../changelog/changelog.utils";
import {
  clearNotifications,
  queryNotifications,
} from "./../../redux/actions/notifications/notifications-actions";
import NotificationEntry from "./NotificationEntry";
import "./NotificationsMenu.scss";

interface NotificationsMenuProps {
  embedded?: boolean;
}
const NotificationsMenu = (props: NotificationsMenuProps) => {
  const [showChangelogModal, setShowChangelogModal] = useState(
    hasNewChangelogEntry()
  );
  const dispatch = useDispatch();
  const notifications = useTypedSelector(
    (state: AppState) => state.notifications.entries
  );
  const { embedded } = props;
  useEffect(() => {
    dispatch(queryNotifications());
  }, []);
  useSocket(
    "newNotification",
    () => {
      dispatch(queryNotifications());
    },
    []
  );

  return (
    <div className={`notifications-menu ${embedded ? "embedded" : ""}`}>
      <div className={`header`}>
        <div className={`title`}>{i18n.t("Notifications.Title")}</div>
        {/* <BFButton
          onClick={async () => await dispatch(seenAllNotifications())}
          disabled={!notifications || notifications.length === 0}
          appearance={"clear-on-white"}
          icon={{ type: "light", data: "check-circle", size: "xs" }}
        /> */}
        <BFButton
          onClick={async () => await dispatch(clearNotifications())}
          disabled={!notifications || notifications.length === 0}
          appearance={"clear-on-white"}
          icon={{ type: "bf", data: "bin-1" }}
        />
      </div>

      {showChangelogModal && (
        <div className={`changelog-notification`}>
          <div className={`changelog-text`}>
            {i18n.t("Notifictions.newChangelogEntry", "Neues Update verfügbar")}
          </div>
          <BFButton
            appearance="clear-on-white"
            onClick={() => {
              setShowChangelogModal(false);
              openChangelogModal();
            }}
            size={"xs"}
          >
            {i18n.t("Global.Buttons.show")}
          </BFButton>
          <BFButton
            appearance="clear-on-white"
            onClick={() => {
              updateLastChangelogEntry();
              setShowChangelogModal(false);
            }}
            size={"xs"}
          >
            <BfIcon {...DefaultIcons.CLOSE} size="xxs" />
          </BFButton>
        </div>
      )}
      <div className={`content`}>
        {notifications && notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationEntry
              key={notification.id}
              notification={notification}
            />
          ))
        ) : (
          <div className={`empty-label`}>
            {i18n.t("Notifications.EmptyText")}
          </div>
        )}
      </div>
      <div className={`footer`}></div>
    </div>
  );
};
export default NotificationsMenu;

import classNames from "classnames";
import Color from "color";
import { customAlphabet } from "nanoid";
import { useEffect, useState } from "react";
import { InputPicker } from "rsuite";
import ArrayUtils from "../../../../utils/ArrayUtils";
import BFStatus from "../../../abstract-ui/data/status/BFStatus";
import BfIcon, { BfIconProps } from "../../../abstract-ui/icon/BfIcon";
import { IDefaultIcon } from "@/types/common";
import "./EZSelect.scss";

interface EZSelectOption {
  label: string;
  value: string | number;
  color?: string;
  icon?: IDefaultIcon;
}
interface EZSelectProps {
  ignoreDataSort?: boolean;
  className?: string;
  value: string | number;
  onChange?: (value: string) => void;
  onBlur?: (ev, value: string) => void;

  error?: string;
  disabled?: boolean;
  data: EZSelectOption[];
  dataType?: "default" | "tag";
  prefixIcon?: BfIconProps;
  readonly?: boolean;
}
const EZSelect = (props: EZSelectProps) => {
  const [nanoId, setNanoId] = useState(
    customAlphabet("abcdefghijklmnopqrstuvwxyz", 10)
  );
  const [data, setData] = useState(props.data);

  useEffect(() => {
    let dataToUse = props.data;
    if (!props.ignoreDataSort) {
      dataToUse = ArrayUtils.sortData(props.data, {
        dir: "asc",
        key: "label",
      });
    }
    setData(dataToUse);
  }, [props.data]);
  const selectedOption = data.find((option) => option.value === props.value);

  let color;
  if (selectedOption && selectedOption.color) {
    color = Color(selectedOption.color);
  }

  const renderReadonly = () => {
    const item = props.data.find((item) => item.value === props.value);

    if (item) {
      return (
        <BFStatus
          color={item.color}
          label={item.label}
          icon={(item as any)?.icon}
          showIcon={!!(item as any)?.icon}
        />
      );
    } else {
      return null;
    }
  };

  const renderSelect = () => {
    return (
      <>
        {color && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .ez-select#${nanoId} .rs-picker-toggle.rs-btn {
              background: ${selectedOption.color} !important;
              
            }
            .ez-select#${nanoId} .rs-picker-toggle.rs-btn .render-value {
              color: ${
                color.isDark() ? "#fff" : color.darken(0.7).hex()
              } !important;
            } 
            .ez-select#${nanoId} .rs-picker-toggle.rs-btn svg.rs-picker-toggle-caret {
              color: ${
                color.isDark() ? "#fff" : color.darken(0.7).hex()
              } !important;
            }
          `,
            }}
          />
        )}

        <InputPicker
          readOnly={props.readonly}
          menuClassName={classNames("ez-select-select", {
            tags: props.dataType === "tag",
          })}
          style={{ width: "auto" }}
          data={data}
          cleanable={false}
          disabled={props.disabled}
          value={props.value}
          onChange={(value, ev) => {
            props.onChange(value);
            props.onBlur(ev, value);
          }}
          renderMenuItem={
            props.dataType === "tag"
              ? (label, item) => {
                  const isSelected = item.value === props.value;
                  let color;
                  if (item.color) {
                    color = Color(item.color);
                  }
                  return (
                    <div className={`ez-select-select-tag-item`}>
                      <div className={`content`}>
                        <div
                          className={`label`}
                          style={{
                            color: color
                              ? color.isDark()
                                ? "#fff"
                                : color.darken(0.7).hex()
                              : undefined,
                            backgroundColor: color ? color.hex() : undefined,
                          }}
                        >
                          {label}
                        </div>
                      </div>

                      {isSelected && (
                        <div className={`selected-icon`}>
                          <BfIcon type="light" data="check-1" size="xs" />
                        </div>
                      )}
                    </div>
                  );
                }
              : undefined
          }
          renderValue={(value, item, selectedElement) => {
            return (
              <div className={`render-value`}>
                {(item as any)?.icon ? (
                  <div className={`prefix-icon`}>
                    <BfIcon {...(item as any)?.icon} size="xs" />
                  </div>
                ) : (
                  props.prefixIcon && (
                    <div className={`prefix-icon`}>
                      <BfIcon {...props.prefixIcon} />
                    </div>
                  )
                )}

                <div className={`label`}>{selectedElement || "-"}</div>
              </div>
            );
          }}
        />
      </>
    );
  };

  return (
    <div
      className={classNames(`ez-select`, props.className, {
        error: props.error,
        tags: props.dataType === "tag",
        readonly: props.readonly,
      })}
      id={nanoId}
    >
      {props.readonly && renderReadonly()}
      {!props.readonly && renderSelect()}
    </div>
  );
};

export default EZSelect;

import moment from "moment";
import { Field } from "react-final-form";
import FormStruct from "../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../components/Form/Validation/FormValidators";
import i18n from "../../i18n";
import BFDateDeprecated from "../abstract-ui/forms/date/BFDate";
import "./PlanTableTimespanManage.scss";

interface PlanTableTimespanManageProps {
  from: Date;
  to: Date;
  minDate?: Date;
  maxDate?: Date;
  warnMinDate?: Date;
  warnMaxDate?: Date;
  onClose: () => void;
  onUpdate: (dateFrom: Date, dateTo: Date) => void;
}
const PlanTableTimespanManage = (props: PlanTableTimespanManageProps) => {
  const initial = {
    from: props.from,
    to: props.to,
  };
  return (
    <FormStruct<any>
      initialValues={initial}
      title={i18n.t("PlanTable.ManageTimespan.Title", "Zeitraum bearbeiten")}
      description={i18n.t(
        "PlanTable.ManageTimespan.Description",
        "Bearbeiten Sie hier den Zeitraum des Budgetplans."
      )}
      onAbort={props.onClose}
      submitText={i18n.t("Global.Buttons.save")}
      onSubmit={async (values) => {
        // const groups = values.groups;
        // const data: PlainTableGroup[] = groups.map((group) => ({
        //   id: group.id,
        //   name: group.name,
        //   children: group.items.map((e) => ({
        //     id: e.id,
        //     name: e.data.name,
        //   })),
        // }));
        // props.onUpdate(data);
        props.onUpdate(
          moment(values.from).utc(true).startOf("month").add(1, "day").toDate(),
          moment(values.to).utc(true).endOf("month").subtract(1, "day").toDate()
        );
        props.onClose();
      }}
      render={(formProps) => (
        <PlanTableTimespanFields
          maxDate={props.maxDate}
          minDate={props.minDate}
          warnMaxDate={props.warnMaxDate}
          warnMinDate={props.warnMinDate}
        />
      )}
    />
  );
};

export default PlanTableTimespanManage;

export const PlanTableTimespanFields = (props: {
  minDate?: Date;
  maxDate?: Date;
  warnMinDate?: Date;
  warnMaxDate?: Date;
}) => {
  const minDate = props.minDate ? moment(props.minDate) : null;
  const maxDate = props.maxDate ? moment(props.maxDate) : null;
  return (
    <div className={`plan-table-group-timespan`}>
      <Field
        name="from"
        validate={FormValidators.compose(FormValidators.required())}
      >
        {({ input: fromInput, meta }) => (
          <>
            <div className={`__field`}>
              <BFDateDeprecated
                format={"yyyy-MM"}
                cleanable={false}
                label={i18n.t("PlanTable.ManageTimespan.BudgetStart", "Start")}
                oneTap
                disabledDate={(date) =>
                  (minDate && moment(date).isSameOrAfter(minDate, "month")) ||
                  (maxDate && moment(date).isSameOrBefore(maxDate, "month"))
                }
                {...fromInput}
                {...FormValidators.getValidation(meta)}
              />
            </div>
            {props.warnMinDate &&
              moment(fromInput.value).isAfter(moment(props.warnMinDate)) && (
                <div className={`warning-box`}>
                  {i18n.t(
                    "PlanTable.ManageTimespan.WarningMinDate",
                    "Sie entfernen bereits geplante Budgetwerte, wenn Sie diesen Startzeitpunkt auswählen."
                  )}
                </div>
              )}
            <Field
              name="to"
              validate={FormValidators.compose(
                FormValidators.required(),
                fromInput.value
                  ? FormValidators.dateAfter(new Date(fromInput.value))
                  : undefined
              )}
            >
              {({ input: toInput, meta }) => (
                <>
                  <div className={`__field`}>
                    <BFDateDeprecated
                      format={"yyyy-MM"}
                      cleanable={false}
                      oneTap
                      label={i18n.t(
                        "PlanTable.ManageTimespan.BudgetEnd",
                        "Ende"
                      )}
                      {...toInput}
                      {...FormValidators.getValidation(meta)}
                    />
                  </div>
                  {props.warnMaxDate &&
                    moment(toInput.value).isBefore(
                      moment(props.warnMaxDate)
                    ) && (
                      <div className={`warning-box`}>
                        {i18n.t(
                          "PlanTable.ManageTimespan.WarningMaxDate",
                          "Sie entfernen bereits geplante Budgetwerte, wenn Sie diesen Endzeitpunkt auswählen."
                        )}
                      </div>
                    )}
                </>
              )}
            </Field>
          </>
        )}
      </Field>
    </div>
  );
};

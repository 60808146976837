import { AssetTypes } from "../../../../../../model/AssetTypes";
import CacheService from "../../../../../../services/CacheService";
import SubmitService from "../../../../../../services/SubmitService";
import { HTTP } from "../../../../../../utils/Http";
import {
  CBPortfolioTechnicalUnit,
  CBPortoflioTechnicalUnitFormData,
} from "../../portfolio/interfaces/CBPortfolioAsset";
import {
  FieldDataDto,
  FieldDataForm,
  TechnicalUnitCategoryData,
  TechnicalUnitCategoryFormData,
  TechnicalUnitCategoryGroup,
} from "./CBTechnicalUnitConfigInterfaces";

class CBTechnicalUnitServiceClass {
  convertTechnicalUnitConfigFormDataToDto(
    formData: TechnicalUnitCategoryFormData,
    prevDto?: TechnicalUnitCategoryData
  ) {
    const output = { ...(prevDto || {}) };

    output.type = formData.type;
    output.displayName = formData.displayName;
    output.icon = { type: formData.icon.type, data: formData.icon.data };
    output.color = formData.icon.color;
    output.hasLocation = formData.hasLocation;

    const mapFields = (
      fieldsForm: FieldDataForm[],
      fieldsDto: FieldDataDto[]
    ) => {
      const fieldIds = (fieldsForm || []).map((e) => e.id);
      const newFields = [
        ...(fieldsForm || []).map((e) => ({ ...e, status: "active" })),
        ...(fieldsDto || [])
          .filter((e) => !fieldIds.includes(e.id))
          .map((e) => ({ ...e, status: "archived" })),
      ];
      return newFields as FieldDataDto[];
    };

    output.fields = mapFields(formData.fields, output?.fields || []);

    const categoryIds = formData.categories.map((e) => e.id);
    const newCategories = [
      ...formData.categories.map((e) => ({
        ...e,
        status: "active",
        fields: mapFields(
          e.fields,
          (output?.categories || []).find((a) => a.id === e.id)?.fields || []
        ),
      })),
      ...(output.categories || [])
        .filter((e) => !categoryIds.includes(e.id))
        .map((e) => ({ ...e, status: "archived" })),
    ];
    output.categories = newCategories as any;

    output.status = "active";
    return output;
  }
  convertTechnicalUnitConfigDtoToFormData(dto: TechnicalUnitCategoryData) {
    const output = {
      type: dto.type,
      displayName: dto.displayName,
      icon: {
        ...(dto.icon || {}),
        color: dto.color,
      },
      hasLocation: dto.hasLocation,
      fields: dto.fields.filter((e) => e.status === "active"),
      categories: dto.categories
        .filter((e) => e.status === "active")
        .map((e) => ({
          ...e,
          fields: e.fields.filter((f) => f.status === "active"),
        })),
    };
    return output;
  }

  async submitTechnicalUnitConfigFormData(
    formData: TechnicalUnitCategoryFormData,
    prevDto?: TechnicalUnitCategoryGroup
  ) {
    const converted = this.convertTechnicalUnitConfigFormDataToDto(
      formData,
      prevDto?.data
    );

    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Portfolio.TechnicalUnitCategoryGroup,
      data: {
        _id: prevDto?._id,
        data: converted,
      },
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as TechnicalUnitCategoryGroup;
    CacheService.updateDataInCaches(result._id, result);

    return result;
  }
  async disableTechnicalUnit(technicalUnit: CBPortfolioTechnicalUnit) {
    const result = (await HTTP.post({
      url: `/maintenance/${technicalUnit._id}/setTechnicalUnitStatus`,
      target: "EMPTY",
      bodyParams: {
        status: "archived",
      },
    })) as CBPortfolioTechnicalUnit;
    CacheService.updateDataInCaches(result._id, result);
  }
  async submitTechnicalUnitFormData(
    formData: CBPortoflioTechnicalUnitFormData,
    _id?: string
  ) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Portfolio.TechnicalUnit,
      data: {
        _id: _id,
        data: {
          ...formData,
          status: "active",
          note: formData.note || "",
          customFields: Object.entries(formData.customFields || {}).map(
            ([key, value]) => ({
              fieldId: key,
              value,
            })
          ),
          attachments: undefined,
          images: undefined,
          migrationId: undefined,
        },
      },
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as CBPortfolioTechnicalUnit;
    CacheService.updateDataInCaches(result._id, result);
  }

  convertTechnicalUnitDtoToForm(asset: CBPortfolioTechnicalUnit) {
    const formData: CBPortoflioTechnicalUnitFormData = {
      rentalUnits: [],
      ...asset.data,
      //@ts-ignore
      attachments: undefined,
      images: undefined,
      status: undefined,
      migrationId: undefined,
      customFields: Object.fromEntries(
        (asset.data.customFields || []).map((e) => [e.fieldId, e.value])
      ),
    };
    return formData;
  }
}
const CBTechnicalUnitService = new CBTechnicalUnitServiceClass();
export default CBTechnicalUnitService;

import { EAppTheme } from "./types";
import { APP_THEME_STORAGE_KEY, DEFAULT_APP_THEME } from "./config";

export const generateThemeClass = (themeValue: EAppTheme): string => {
  return `${themeValue}-theme`;
};

export const initAppTheme = (): EAppTheme => {
  let currentTheme = localStorage.getItem(APP_THEME_STORAGE_KEY) as EAppTheme;

  if (!currentTheme) currentTheme = DEFAULT_APP_THEME;

  localStorage.setItem(APP_THEME_STORAGE_KEY, currentTheme);
  document.body.classList.add(generateThemeClass(currentTheme));

  return currentTheme;
};

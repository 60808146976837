import { useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import FormFieldValues from "../../../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../i18n";
import BFInput from "../../../../../modules/abstract-ui/forms/input/BFInput";
import LanguageService from "../../../../../services/LanguageService";
import ACInvoiceUtils from "../../../../utils/ACInvoiceUtils";
import { RAInvoice } from "../../RAInterfaces";

export const RAInvoiceInfoForm = (props: {
  invoice: RAInvoice;
  withDescription: boolean;
}) => {
  const allCustomFields = ACInvoiceUtils.getCustomFields(
    props.invoice.data.type
  );
  const [defaultValue] = useState(
    allCustomFields.map((e) => ({
      identifier: e.id,
      value: "",
    }))
  );

  return (
    <div className={`ra-invoice-info-form`}>
      {props.withDescription && (
        <div className={`description`}>
          {i18n.t(
            "ra:InvoiceForm.InfoFields.Description",
            "Geben Sie die weiteren Informationen zu der Rechnung an. Diese haben keinen Einfluss auf den Rechnugnsprozess."
          )}
        </div>
      )}
      <div className={`custom-fields`}>
        <FieldArray name="custom" defaultValue={defaultValue}>
          {({ fields }) => (
            <div>
              <div className="entries">
                {fields.map((name, index) => (
                  <FormFieldValues
                    key={name}
                    names={[`${name}.identifier`, `${name}.value`]}
                    allProps
                  >
                    {([identifier, value]) => (
                      <div className={`entry`}>
                        <BFInput
                          label={LanguageService.translateLabel(
                            allCustomFields.find(
                              (e) => e.id === identifier.input.value
                            )?.displayName
                          )}
                          {...value.input}
                          {...FormValidators.getValidation(value.meta)}
                        />
                      </div>
                    )}
                  </FormFieldValues>
                ))}
              </div>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

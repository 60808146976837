import { css } from "emotion";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";

type Props = {
  avatar: string;
  displayName: string;
  renderEmpty: boolean;
  size: "xs" | "sm" | "md" | "lg" | "xl" | "flex" | number;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class ConfAvatarComponent extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  //componentDidMount() {
  //}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  //

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render() {
    const { style, ...rest } = this.props;

    return (
      <AvatarComponent
        className={`${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
        {...rest}
      />
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ConfAvatarComponent))
);

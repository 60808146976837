import { CSSProperties } from "react";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { CustomFeatureInfo, FeatureItemWithSvg } from "../types";
import { DEFAULT_ICON_COLOR } from "../config";
import { Style, Fill, Circle as CircleStyle, Stroke, Icon } from "ol/style";

// ! FEATURE STYLES
const setDefaultFeatureStyle = (
  feature: Feature,
  color: CSSProperties["color"]
) => {
  feature.setStyle(
    new Style({
      image: new CircleStyle({
        radius: 8,
        fill: new Fill({ color: color || DEFAULT_ICON_COLOR }),
        stroke: new Stroke({
          color: "white",
          width: 2,
        }),
      }),
    })
  );
};

// set the style for the features
const setFeatureStyle = (
  feature: Feature,
  svg: string | null,
  color?: CSSProperties["color"]
) => {
  if (!svg) return setDefaultFeatureStyle(feature, color);

  feature.setStyle(
    new Style({
      image: new Icon({
        src: svg,
        // size: [32, 32], // original image size
        // scale: 1, // Scale, can be adjusted if necessary
        // anchor: [0.5, 0.5], //Anchor so that the point is at the bottom of the image
        // anchorXUnits: "fraction", // meaning that 0.5 is the middle of the image along the X axis
        // anchorYUnits: "fraction", // meaning that 0.5 is the middle of the image along the Y axis
      }),
    })
  );
};

// ! CREATE FEATURES FUNCTION
export const createFeatures = (
  featuresDescriptions: FeatureItemWithSvg[]
): Feature[] => {
  return featuresDescriptions.map((featureDescriptionItem) => {
    const { coordinates, svg, color } = featureDescriptionItem;

    // create info to recognize the feature
    const customFeatureInfo = getFeatureCustomInfo(featureDescriptionItem);

    // create the feature
    const pointFeature = new Feature({
      geometry: new Point(coordinates),
      customFeatureInfo,
    });

    // apply custom style
    setFeatureStyle(pointFeature, svg, color);

    return pointFeature;
  });
};

// ! CREATE FEATURES HELPERS
function getFeatureCustomInfo({
  icon,
  color,
}: FeatureItemWithSvg): CustomFeatureInfo {
  const customFeatureInfo: CustomFeatureInfo = {};

  if (icon) customFeatureInfo.icon = icon;
  if (color) customFeatureInfo.color = color;

  return customFeatureInfo;
}

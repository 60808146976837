import { CheckTree } from "rsuite";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import "./BFCheckTree.scss";

export type TreeData = {
  value: string;
  label: React.ReactNode;
  children?: TreeData[];
};
export type BFCheckTreeProps = {
  cascade?: boolean;
  data: any[];
  className?: string;
  defaultExpandAll?: boolean;
  defaultValue?: any[];
  defaultExpandItemValues?: any[];
  disabledItemValues?: string[];
  onSelect?: (node: any, value: any, e) => void;
  onChange?: (values: string[]) => void;
  searchKeyword?: string;
  uncheckableItemValues?: string[];
  value?: string[];
  virtualized?: boolean;
  listProps?: any; //https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md#prop-types
  height?: number;
  renderTreeNode?: (node: any) => React.ReactNode;
  renderTreeIcon?: (node: any) => React.ReactNode;
  showIndentLine?: boolean;
  itemFullWidth?: boolean;
  striped?: boolean;

  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
};

export const resolveTreeDataToEndNodes = (
  values: string[],
  treeData: TreeData[]
): string[] => {
  const result: string[] = [];

  const mapValueToValues = (value: string, treeData: TreeData) => {
    let resultValues: string[] = [];
    if (treeData.value === value) {
      // found the matching node
      if (treeData.children) {
        // children existing, so add all children to the values
        treeData.children.forEach((child) => {
          resultValues.push(...mapValueToValues(child.value, child));
        });
      } else {
        // no children, add node to the values
        resultValues.push(treeData.value);
      }
    } else {
      // node is not matching, traverse to children if existing to search for result
      if (treeData.children) {
        treeData.children.forEach((child) => {
          resultValues.push(...mapValueToValues(value, child));
        });
      }
    }

    return resultValues;
  };

  // go for each value through all tree datas and search for matches
  values.forEach((value) => {
    treeData.forEach((treeData) => {
      result.push(...mapValueToValues(value, treeData));
    });
  });

  return result;
};

const BFCheckTree: React.FC<BFCheckTreeProps> = ({
  striped,
  className,
  itemFullWidth,
  validatorStyle,
  validation,
  ...treeProps
}) => {
  return (
    <div
      className={`bf-checktree ${itemFullWidth ? "item-full-width" : ""} ${
        striped ? "striped" : ""
      } ${className || ""}`}
    >
      <ValidationPopover
        validatorStyle={validatorStyle}
        level={validation ? validation.level : "error"}
        message={validation ? validation.message : null}
        marginTop={0}
      >
        <CheckTree {...treeProps} />
      </ValidationPopover>
    </div>
  );
};

export default BFCheckTree;

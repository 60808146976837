import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import { Contact } from "@/model/db/Contact";
import BFSection from "@/modules/abstract-ui/data/section/BFSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import classNames from "classnames";
import ContactForm from "../../ContactForm";
import ContactBaseAddress from "./ContactBaseAddress";
import ContactBaseBankaccounts from "./ContactBaseBankaccounts";
import ContactBaseContactPersons from "./ContactBaseContactPersons";
import "./ContactBaseDataView.scss";
import ContactBaseEmail from "./ContactBaseEmails";
import ContactBaseNotes from "./ContactBaseNotes";
import ContactBasePhone from "./ContactBasePhones";

interface ContactBaseDataViewProps {
  contact: Contact;
}
const ContactBaseDataView = (props: ContactBaseDataViewProps) => {
  const address = props.contact.data.address;
  const accounts = props.contact.data.bankAccount;
  const emails = props.contact.data.emails;
  return (
    <div className={classNames(`contact-basedata-view`)}>
      <div className={`action-row`}>
        {/* <BFButton
          appearance="outline"
          onClick={() => {
            // ModalManager.show({
            //   noPadding: true,
            //   size: "sm",
            //   content: (state, setState, close) => (
            //     <ContactForm
            //       noPadding
            //       onClose={close}
            //       businessUnits={[props.contact.data.type]}
            //       onSuccessfulSubmit={() => {
            //         close();
            //       }}
            //       contact={props.contact}
            //     />
            //   ),
            // });
          }}
        >
          {i18n.t("ContactBaseDataView.findDuplicates", "Duplikate")}
        </BFButton> */}
        <BFButton
          appearance="outline"
          onClick={() => {
            ModalManager.show({
              noPadding: true,
              size: "sm",
              content: (state, setState, close) => (
                <ContactForm
                  noPadding
                  onClose={close}
                  businessUnits={[props.contact.data.type]}
                  onSuccessfulSubmit={() => {
                    close();
                  }}
                  contact={props.contact}
                />
              ),
            });
          }}
        >
          {i18n.t("Global.Buttons.edit")}
        </BFButton>
      </div>
      <div className={`basedata-content`}>
        <div className={`col`}>
          <BFSection
            collapsible
            title={i18n.t("ContactBaseDataView.address", "Adressen")}
            className={`margin-bottom-30`}
          >
            <ContactBaseAddress address={address || []} />
          </BFSection>
          <BFSection
            collapsible
            title={i18n.t("ContactBaseDataView.bankAccounts", "Bankkonten")}
            className={`margin-bottom-30`}
          >
            <ContactBaseBankaccounts accounts={accounts || []} />
          </BFSection>
          <BFSection
            collapsible
            title={i18n.t("ContactBaseDataView.emails", "E-Mails")}
            className={`margin-bottom-30`}
          >
            <ContactBaseEmail emails={emails} />
          </BFSection>
        </div>
        <div className={`col`}>
          <BFSection
            collapsible
            title={i18n.t("ContactBaseDataView.phone", "Telefonnummern")}
            className={`margin-bottom-30`}
          >
            <ContactBasePhone phones={props.contact.data.phone || []} />
          </BFSection>
          <BFSection
            collapsible
            title={i18n.t(
              "ContactBaseDataView.contactPersons",
              "Kontaktpersonen"
            )}
            className={`margin-bottom-30`}
          >
            <ContactBaseContactPersons
              contactPersons={props.contact.data.contactPersons || []}
            />
          </BFSection>
        </div>
        <div className={`col`}>
          <ContactBaseNotes contact={props.contact} />
        </div>
      </div>
    </div>
  );
};

export default ContactBaseDataView;

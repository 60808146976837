import i18n from "@/i18n";
import ArrayUtils from "@/utils/ArrayUtils";
import { isNotDefined } from "@/utils/Helpers";
import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import Collapse from "rsuite/esm/Animation/Collapse";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import BFInput from "../input/BFInput";
import "./BFChooser.scss";

export type BFChooserOption<T> = {
  label: string;
  group?: string;
  subLabel?: string;
  value: T;
  bold?: boolean;
};

interface BFChooserProps {
  options: BFChooserOption<string | Date | number>[];
  value?: string | number | Date;
  onChange: (value: string | number | Date) => void | Promise<void>;
  description?: string;
  focusOnMount?: boolean;
  maxHeight?: string | number;
  searchPlaceholder?: string;
  hideSearch?: boolean;
  groupSort?: (a: string, b: string) => number;
}
type GroupedOption = {
  type: "group";
  label: string;
  options: BFChooserOption<string | Date | number>[];
};

const calculateData = (
  options: BFChooserOption<string | Date | number>[],
  search?: string,
  groupSort?: (a: string, b: string) => number
) => {
  const entries: (GroupedOption | BFChooserOption<string | Date | number>)[] =
    [];
  if (!search) {
    const groups = _.uniq(
      options.map((entry) => entry.group).filter((e) => e)
    ).sort((a, b) => {
      return groupSort?.(a, b) || 0;
    });

    entries.push(...options.filter((e) => isNotDefined(e.group)));

    groups.forEach((group) => {
      entries.push({
        type: "group",
        label: group,
        options: options.filter((e) => e.group === group),
      });
    });

    return entries;
  } else {
    entries.push(
      ...ArrayUtils.fuzzyFilter(search, options, {
        keys: ["label", "subLabel", "group"],
      }).map((e) => e.item)
    );
  }
  return entries;
};
const BFChooser = (props: BFChooserProps) => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState(
    calculateData(props.options, search, props.groupSort)
  );

  useEffect(() => {
    setData(calculateData(props.options, search, props.groupSort));
  }, [search, props.options]);
  return (
    <div
      className={classNames(`bf-chooser`)}
      style={{ maxHeight: props.maxHeight }}
    >
      {props.description && (
        <div className={`description`}>{props.description}</div>
      )}
      {!props.hideSearch && (
        <div className={`search`}>
          <BFInput
            focusOnMount={props.focusOnMount}
            value={search}
            placeholder={props.searchPlaceholder}
            onChange={(value: string) => setSearch(value)}
            prefix={<BfIcon size="xs" {...DefaultIcons.SEARCH} />}
          />
        </div>
      )}
      <div
        className={classNames(`search-content`, {
          "no-search": props.hideSearch,
        })}
      >
        {data.length === 0 && (
          <div className={`no-entries`}>
            {i18n.t("BFChooser.noEntries", "Keine Einträge vorhanden")}
          </div>
        )}
        {data.map((entry, index) =>
          (entry as GroupedOption).type === "group" ? (
            <BFChooserGroup
              key={index}
              group={entry as GroupedOption}
              selected={props.value}
              onSelect={props.onChange}
            />
          ) : (
            <BFChooserEntry
              key={index}
              entry={entry as BFChooserOption<string | Date | number>}
              selected={props.value}
              onSelect={props.onChange}
            />
          )
        )}
      </div>
    </div>
  );
};

const BFChooserGroup = (props: {
  group: GroupedOption;
  selected?: string | number | Date;
  onSelect: (value: string | number | Date) => void | Promise<void>;
}) => {
  const [show, setShow] = useState(true);
  return (
    <div className={classNames(`bf-chooser-group`)}>
      <BFButton
        className={classNames(`bf-chooser-group-button`)}
        onClick={() => setShow(!show)}
      >
        <div className={`bf-chooser-group-label`}>{props.group.label}</div>

        <BfIcon
          className={classNames(`bf-chooser-group-icon`, { open: show })}
          size="xs"
          {...DefaultIcons.CHEVRON}
        />
      </BFButton>

      <Collapse in={show}>
        <div className={classNames(`bf-chooser-group-content`)}>
          {props.group.options.map((entry) => (
            <BFChooserEntry
              entry={entry}
              selected={props.selected}
              onSelect={props.onSelect}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};
const BFChooserEntry = (props: {
  entry: BFChooserOption<string | Date | number>;
  selected?: string | number | Date;
  onSelect: (value: string | number | Date) => void | Promise<void>;
}) => {
  return (
    <BFButton
      className={classNames(`bf-chooser-entry`, {
        selected: props.selected === props.entry.value,
        bold: props.entry.bold,
      })}
      onClick={async () => await props.onSelect(props.entry.value)}
    >
      <div className={`bf-chooser-entry-label`}>{props.entry.label}</div>
      {props.entry.subLabel && (
        <div className={`bf-chooser-entry-sub-label`}>
          {props.entry.subLabel}
        </div>
      )}
    </BFButton>
  );
};

export default BFChooser;

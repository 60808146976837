import moment from "moment";
import { ThunkDispatch } from "redux-thunk";
import i18n from "../../../i18n";
import {
  Notification,
  NotificationAsset,
} from "../../../model/notifications/Notification";
import DataBusDefaults from "../../../services/DataBusDefaults";
import { HTTP } from "../../../utils/Http";
import { AppState, store } from "../../store";
import { mapNotificationAssetEntryToNotifcationEntry } from "./notifcations-mapper";
import {
  REGISTER_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SEEN_ALL_NOTIFICATIONS,
  SET_ALL_NOTIFICATION,
  SET_SEEN_NOTIFICATION,
  TOGGLE_EXPAND_STATE,
} from "./notifications-actions-types";

export const NOTIFICATION_DELETE_TRANSITION = 300;
// TypeScript infers that this function is returning SendMessageAction

export function setNotificationSeen(id: string, seen: boolean) {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await HTTP.post({
      url: `/notification/${id}/${seen ? "read" : "unread"}`,
      target: "API",
    })
      .then((response) => {
        dispatch({
          type: SET_SEEN_NOTIFICATION,
          id,
          seen,
        });
      })
      .catch((err) => {
        DataBusDefaults.toast({
          text: i18n.t(
            "Notifications.ErrorAtSeenChange",
            "Fehler beim Ändern des Status der Benachrichtigung"
          ),
          type: "error",
        });
      });
  };
}
export function seenAllNotifications() {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    await HTTP.post({
      url: `/notification/readAll`,
      target: "API",
    })
      .then((response) => {
        dispatch({
          type: SEEN_ALL_NOTIFICATIONS,
        });
      })
      .catch((err) => {
        DataBusDefaults.toast({
          text: i18n.t(
            "Notifications.ErrorAtSeenAll",
            "Fehler beim Ändern des Status der Benachrichtigungen"
          ),
          type: "error",
        });
      });
  };
}
export function clearNotifications() {
  return async (dispatch: ThunkDispatch<{}, {}, any>) => {
    const entries = store.getState().notifications.entries;
    entries?.forEach((entry) => {
      if (entry.deletable !== false) {
        dispatch(removeNotification(entry.id));
      }
    });

    await HTTP.post({
      url: `/notification/deleteAll`,
      target: "API",
    }).catch((err) => {
      DataBusDefaults.toast({
        text: i18n.t(
          "Notifications.ErrorAtDeletionAll",
          "Fehler beim Löschen der Benachrichtigungen"
        ),
        type: "error",
      });
    });
  };
}
export function removeNotification(id: string) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    (async () => {
      dispatch({
        type: REGISTER_NOTIFICATION,
        notification: {
          id: id,
          isDeleting: true,
        },
      });
      HTTP.post({
        url: `/notification/${id}/delete`,
        target: "API",
      }).catch((err) => {
        DataBusDefaults.toast({
          text: i18n.t(
            "Notifications.ErrorAtDeletion",
            "Fehler beim Löschen der Benachrichtigung"
          ),
          type: "error",
        });
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_NOTIFICATION,
          id,
        });
      }, NOTIFICATION_DELETE_TRANSITION);
    })();
  };
}
export function registerNotification(
  notification: Notification,
  showNotification?: boolean
) {
  //TODO implement showNotification functionality
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    (async () => {
      dispatch({
        type: REGISTER_NOTIFICATION,
        notification,
      });
    })();
  };
}

export function toggleExpandState(id: string, toggled: boolean) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    (async () => {
      dispatch({
        type: TOGGLE_EXPAND_STATE,
        id,
        toggled,
      });
    })();
  };
}

export function queryNotifications() {
  return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
    (async () => {
      const currentNotifications = getState().notifications.entries;
      const notificationAsset = (await HTTP.get({
        url: "/notification/getNotifications",
        target: "API",
      })) as NotificationAsset;

      if (notificationAsset) {
        const notifications = notificationAsset?.data?.notifications
          .map((notification) =>
            mapNotificationAssetEntryToNotifcationEntry(
              notification,
              currentNotifications,
              (id: string) => {
                dispatch(removeNotification(id));
              }
            )
          )
          .filter((notification) => notification !== null)
          .sort((a, b) => moment(b.created).diff(moment(a.created)));
        dispatch({
          type: SET_ALL_NOTIFICATION,
          notifications,
        });
      }
    })();
  };
}

import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { useTypedSelector } from "@/redux/hooks";
import DataBusDefaults from "@/services/DataBusDefaults";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import moment from "moment";
import UserReplacementForm from "./UserReplacementForm";
import "./UserReplacementRoot.scss";
import UserReplacementsList from "./UserReplacementsList";

interface UserReplacementRootProps {}
const UserReplacementRoot = (props: UserReplacementRootProps) => {
  const userId = useTypedSelector((state) => state.global.user._id);
  return (
    <div className={classNames(`user-replacement-root`)}>
      <div className={`action-row padding-bottom-20`}>
        <BFButton
          onClick={() => {
            ModalManager.show({
              noPadding: true,
              content: (state, setState, close) => (
                <UserReplacementForm
                  onClose={close}
                  onSuccess={() => {
                    DataBusDefaults.reload({
                      identifiers: ["my-replacments-list"],
                    });
                  }}
                />
              ),
            });
          }}
          appearance="outline"
        >
          {i18n.t(
            "UserProfile.Replacement.CreateNewReplacement",
            "Neue Vertretung beantragen"
          )}
        </BFButton>
      </div>
      <div className={`section`}>
        <div className={`title __h1`}>
          {i18n.t(
            "UserProfile.Replacement.MyReplacementsTitle",
            "Meine Abwesenheiten"
          )}
        </div>
        <div className={`section-description`}>
          {i18n.t(
            "UserProfile.Replacement.MyReplacementsDescription",
            "Hier sind die zukünftig geplanten und aktuelle Abwesenheiten gelistet. Fügen Sie eine neue Abwesenheit hinzu und geben Sie an, wer Sie in dieser Zeit vertreten soll."
          )}
        </div>
        <div className={`replacements-list-container`}>
          <UserReplacementsList
            identifier="my-replacments-list"
            emptyText={i18n.t(
              "UserProfile.Replacement.MyReplacementsEmptyText",
              "Keine Abwesenheiten eingetragen"
            )}
            matchQuery={MQ.and(
              MQ.gt("data.to", moment().startOf("day").toDate()),
              MQ.eq("data.replacedUser", userId)
            )}
          />
        </div>
      </div>
      <div className={`section`}>
        <div className={`title __h1`}>
          {i18n.t(
            "UserProfile.Replacement.ReplacementsForOthersTitle",
            "Meine Vertretungen"
          )}
        </div>
        <div className={`section-description`}>
          {i18n.t(
            "UserProfile.Replacement.ReplacementsForOthersDescription",
            "Hier sind die aktuellen und zukünftigen Vertretungen, die Sie für Ihre Kollegen durchführen."
          )}
        </div>
        <div className={`replacements-list-container`}>
          <UserReplacementsList
            identifier="other-replacments-list"
            emptyText={i18n.t(
              "UserProfile.Replacement.ReplacementsForOthersEmptyText",
              "Keine Vertretungen eingetragen"
            )}
            matchQuery={MQ.and(
              MQ.gt("data.to", moment().startOf("day").toDate()),
              MQ.eq("data.replacementUser", userId)
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default UserReplacementRoot;

export type MobilePhoneLocale =
  | "ar-AE"
  | "ar-DZ"
  | "ar-EG"
  | "ar-JO"
  | "ar-SA"
  | "ar-SY"
  | "be-BY"
  | "bg-BG"
  | "cs-CZ"
  | "de-DE"
  | "da-DK"
  | "el-GR"
  | "en-AU"
  | "en-GB"
  | "en-HK"
  | "en-IN"
  | "en-KE"
  | "en-NG"
  | "en-NZ"
  | "en-UG"
  | "en-RW"
  | "en-SG"
  | "en-TZ"
  | "en-PK"
  | "en-US"
  | "en-CA"
  | "en-ZA"
  | "en-ZM"
  | "es-ES"
  | "fa-IR"
  | "fi-FI"
  | "fo-FO"
  | "fr-FR"
  | "he-IL"
  | "hu-HU"
  | "id-ID"
  | "it-IT"
  | "ja-JP"
  | "kk-KZ"
  | "kl-GL"
  | "ko-KR"
  | "lt-LT"
  | "ms-MY"
  | "nb-NO"
  | "nn-NO"
  | "pl-PL"
  | "pt-PT"
  | "ro-RO"
  | "ru-RU"
  | "sk-SK"
  | "sr-RS"
  | "th-TH"
  | "tr-TR"
  | "uk-UA"
  | "vi-VN"
  | "zh-CN"
  | "zh-HK"
  | "zh-TW"
  | "any";

export const possiblePostalCodeLocales: PostalCodeLocale[] = [
  "AT",
  "AU",
  "BE",
  "BG",
  "CA",
  "CH",
  "CZ",
  "DE",
  "DK",
  "DZ",
  "ES",
  "FI",
  "FR",
  "GB",
  "GR",
  "IL",
  "IN",
  "IS",
  "IT",
  "JP",
  "KE",
  "LI",
  "MX",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "RU",
  "SA",
  "SE",
  "TW",
  "US",
  "ZA",
  "ZM",
];

export type PostalCodeLocale =
  | "AT"
  | "AU"
  | "BE"
  | "BG"
  | "CA"
  | "CH"
  | "CZ"
  | "DE"
  | "DK"
  | "DZ"
  | "ES"
  | "FI"
  | "FR"
  | "GB"
  | "GR"
  | "IL"
  | "IN"
  | "IS"
  | "IT"
  | "JP"
  | "KE"
  | "LI"
  | "MX"
  | "NL"
  | "NO"
  | "PL"
  | "PT"
  | "RO"
  | "RU"
  | "SA"
  | "SE"
  | "TW"
  | "US"
  | "ZA"
  | "ZM"
  | "any";

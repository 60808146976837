import classNames from "classnames";
import i18n from "../../../i18n";
import BFButton, {
  BFButtonProps,
} from "../../../modules/abstract-ui/general/Button/BFButton";
import { valueOrDefault } from "../../../utils/Helpers";
import "./FormActionRow.scss";

interface FormActionRowProps {
  onAbort?: () => void | Promise<void>;
  cancelText?: string;
  submitText?: string;
  additionalContent?: React.ReactNode;
  submitting?: boolean;
  hideSubmit?: boolean;

  abortBtnProps?: Partial<BFButtonProps>;
  submitBtnProps?: Partial<BFButtonProps>;

  additionalSubmit?: {
    submitText: string;
    props?: Partial<BFButtonProps>;
    dataActionid: string;
  };
}

const FormActionRow = (props: FormActionRowProps) => {
  const hideSubmit = props.hideSubmit || false;

  return (
    <div className={classNames(`form-action-row`)}>
      {props.additionalContent ? props.additionalContent : null}
      <div className={`fill`} />
      {props.onAbort && (
        <BFButton
          className={`margin-left-10`}
          type="button"
          onClick={props.onAbort}
          appearance="outline"
          {...valueOrDefault(props.abortBtnProps, {})}
        >
          {props.cancelText || i18n.t("Global.Buttons.cancel")}
        </BFButton>
      )}
      {!hideSubmit && (
        <BFButton
          data-action-id="default-submit"
          className={`margin-left-10`}
          type="submit"
          appearance="primary"
          loading={props.submitting}
          {...valueOrDefault(props.submitBtnProps, {})}
        >
          {props.submitText || i18n.t("Global.Buttons.submit")}
        </BFButton>
      )}
      {props.additionalSubmit && (
        <BFButton
          data-action-id={props.additionalSubmit.dataActionid}
          className={`margin-left-10`}
          type="submit"
          appearance="primary"
          loading={props.submitting}
          {...valueOrDefault(props.additionalSubmit.props, {})}
        >
          {props.additionalSubmit.submitText || i18n.t("Global.Buttons.submit")}
        </BFButton>
      )}
    </div>
  );
};

export default FormActionRow;

class FormUtilsClass {
	getPropertiesOf(items) {
		let fields = [];

		for (const item of items) {
			if (item.type === "property") {
				fields.push(item.name);
			} else if (item.type === "field-table-view" && item.editable) {
				item.editable.forEach(field => fields.push(field));
			} else {
				if (item.items) {
					fields = fields.concat(Object.values(this.getPropertiesOf(Object.values(item.items))));
				}
			}
		}
		return fields;
	}
}

const FormUtils = new FormUtilsClass();

export default FormUtils;

import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { withPDFBlock } from "../../PDFBlock";

import {
  UpcomingVacancyFields,
  UpcomingVacancyTableConfig,
} from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";

import { useState } from "react";

import { PDFBlockFooter } from "../../PDFBlockFooter";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import {
  OBJECT_UPCOMING_VACANCY,
  OBJECT_UPCOMING_VACANCY_FIELDS,
  OBJECT_UPCOMING_VACANCY_VALUES,
} from "./PDFObjectInputConstants";
import { useBlockChanges } from "../../PDFBlockHooks";
import i18n from "@/i18n";
import { PDFTableFooterInput } from "./Components/PDFTableFooterInput";
import { PDFCommonInput } from "../../Common/PDFCommonInput";
import EZTags from "@/modules/ez-form/form-elements/ez-tags/EZTags";
import LabeledInput from "@/modules/abstract-ui/forms/LabeledInput";

const PDFUpcomingVacancyBlock = withPDFBlock<UpcomingVacancyTableConfig>(
  ({ value, parentId }) => {
    const [{ asset }, { deleteBlock, saveBlock }] = usePDFConstructor();
    const [form, setForm] = useState({ ...value, assetId: asset._id });

    useBlockChanges(value, form);

    return (
      <div>
        <BFSelect
          data={OBJECT_UPCOMING_VACANCY.map((option) => ({
            ...option,
            label: option.label(),
          }))}
          value={form.month}
          onSelect={(value) =>
            setForm((form) => ({
              ...form,
              month: value,
            }))
          }
          cleanable={false}
        />

        <LabeledInput
          label={i18n.t("Component.PDFConstructor.Fields", "Felder")}
        >
          <EZTags
            data={OBJECT_UPCOMING_VACANCY_FIELDS.map((option) => ({
              ...option,
              label: option.label(),
            }))}
            value={form.fields}
            onChange={(values) =>
              setForm((form) => {
                const fields = Object.keys(
                  OBJECT_UPCOMING_VACANCY_VALUES
                ).filter((key) => values.includes(key));

                return {
                  ...form,
                  fields: fields as UpcomingVacancyFields[],
                };
              })
            }
          />
        </LabeledInput>

        <PDFTableFooterInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
          columnsAmount={OBJECT_UPCOMING_VACANCY_FIELDS.length}
        />

        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(form, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.UpcomingVacancyTable",
      "Tabelle für bevorstehende Leerstände"
    )
);

export { PDFUpcomingVacancyBlock };

import _ from "lodash";
import moment from "moment";
import React, { CSSProperties } from "react";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import Ratings from "react-star-ratings";
import AvatarComponent from "../../../components/AvatarComponent/AvatarComponent";
import RestrictComponent, {
  RestrictValue,
} from "../../../configurable/components/RestrictComponent/RestrictComponent";
import Santizer from "../../../configurable/components/Santizer/Santizer";
import Log from "../../../debug/Log";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState, store } from "../../../redux/store";
import { ComponentsMapper } from "../../../utils/ComponentsMapper";
import { translateNonGenerate } from "../../../utils/Helpers";
import BFCheckGroup from "../../abstract-ui/forms/checkbox-group/BFCheckGroup";
import BFCheckbox, {
  CheckboxAppearance,
} from "../../abstract-ui/forms/checkbox/BFCheckbox";
import BfDate from "../../abstract-ui/forms/date/BFDate";
import BFInput from "../../abstract-ui/forms/input/BFInput";
import BFPinInput from "../../abstract-ui/forms/pin-input/BFPinInput";
import BFRadioGroup from "../../abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "../../abstract-ui/forms/radio/BfRadio";
import BfSelect from "../../abstract-ui/forms/select/BFSelect";
import BFToggle from "../../abstract-ui/forms/toggle/BFToggle";
import BFUpload from "../../abstract-ui/forms/upload/BFUpload";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import { ValidatorPopoverStyle } from "../../abstract-ui/general/ValidationPopover/ValidationPopover";
import BfIcon, { BfIconProps } from "../../abstract-ui/icon/BfIcon";
import { GenericFormsLayoutProps } from "../../generic-forms/GFBaseElement";
import GenericFormField from "../../generic-forms/GenericFormField";
import ExpressionHelper from "../../generic-forms/util/ExpressionHelper";
import {
  JsonPropertyCheckbox,
  JsonPropertyCheckboxGroup,
  JsonPropertyCommon,
  JsonPropertyDate,
  JsonPropertyNumber,
  JsonPropertyRadioGroup,
  JsonPropertyRestriction,
  JsonPropertySelect,
  JsonPropertyText,
  JsonPropertyTextArea,
  JsonPropertyToggle,
  JsonPropertyUpload,
  OptionConditionGroup,
  OptionEntry,
} from "../../generic-forms/util/JsonValidation";
import Validators from "../../generic-forms/util/Validatos";
import "./GenericFormFieldsImpl.scss";

type WrapperProps = CSSProperties; //{ padding: 10};
export const FormFieldWrapper: React.FC<WrapperProps> = (props) => {
  return <div style={{ ...props }}>{props.children}</div>;
};

/***
 * ############ Rating ####################
 */
export interface JsonRatingProps extends JsonPropertyCommon {
  _component: "rating";
  numberOfStars?: number;
  colorRated?: string;
  colorEmpty?: string;
  colorHover?: string;
  starDimension?: string;
  starSpacing?: string;
  svgIconPath?: string;
  svgIconViewBox?: string;
  align: "center" | "left" | "right";
  minIcon?: BfIconProps;
  maxIcon?: BfIconProps;
}
type GFRatingProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonRatingProps;
export const GFRating: React.FC<GFRatingProps> = (props) => {
  const { name } = props;

  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props as any}
      render={(input, meta, name1, jsonProperty) => {
        return (
          <div
            className={"gf-rating"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: props.align
                ? props.align === "center"
                  ? "center"
                  : props.align === "right"
                  ? "flex-end"
                  : "flex-end"
                : "center",
            }}
          >
            {props.minIcon ? <BfIcon {...props.minIcon} /> : null}
            <Ratings
              rating={input.value ? input.value : 0}
              changeRating={(newRating, name) => {
                input.onChange(newRating);
              }}
              starRatedColor={props.colorRated}
              starEmptyColor={props.colorEmpty}
              starHoverColor={props.colorHover}
              starDimension={props.starDimension}
              starSpacing={props.starSpacing}
              svgIconPath={props.svgIconPath}
              svgIconViewBox={props.svgIconViewBox}
              numberOfStars={
                props.numberOfStars ? props.numberOfStars : undefined
              }
            />
            {props.maxIcon ? <BfIcon {...props.maxIcon} /> : null}
          </div>
        );
      }}
    />
  );
};

/***
 * ############ GridCheck ####################
 */
interface GridCheckOptionEntry extends OptionEntry {
  icon: BfIconProps;
}

export interface JsonGridCheckProps extends JsonPropertyCommon {
  _component: "gridcheck";
  maxCheckedCount?: number;
  options: GridCheckOptionEntry[];
  columns?:
    | number
    | {
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
      };
}
type GFGridCheckProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonGridCheckProps;
export const GFGridCheck: React.FC<GFGridCheckProps> = (props) => {
  const viewportWidthSelector = useSelector(
    (state: AppState) =>
      state.uiConfig.general[DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR]
  );

  const { name } = props;
  let columnCount = 3;
  if (props.columns) {
    if (typeof props.columns === "number") {
      columnCount = props.columns;
    } else {
      columnCount = props.columns[viewportWidthSelector];
    }
  }
  const rows = [];
  let current = -1;
  props.options.forEach((option, index) => {
    if (index % columnCount === 0) {
      current++;
      rows.push([]);
    }

    rows[current].push(option);
  });

  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props as any}
      render={(input, meta, name1, jsonProperty) => {
        return (
          <div className={"gf-grid-check"}>
            {rows.map((row) => {
              return (
                <div className={`grid-row`}>
                  {row.map((option) => {
                    const checked = input.value.indexOf(option.value) !== -1;
                    return (
                      <div className={`grid-option`}>
                        <div
                          className={`checked-over ${checked ? "active" : ""}`}
                        >
                          <BfIcon type={"bf"} data={"check-1"} size={"2x"} />
                        </div>
                        <BFButton
                          icon={option.icon}
                          iconPosition={"top"}
                          text={option.label}
                          onClick={() => {
                            if (checked) {
                              input.onChange(
                                input.value.filter(
                                  (entry) => entry !== option.value
                                )
                              );
                            } else {
                              if (
                                props.maxCheckedCount === undefined ||
                                input.value.length < props.maxCheckedCount
                              ) {
                                input.onChange([...input.value, option.value]);
                              }
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      }}
    />
  );
};
/***
 * ############ AVATAR ####################
 */
export interface JsonAvatarProps extends JsonPropertyCommon {
  _component: "avatar";
  displaynameField: string;
  size: "xs" | "sm" | "md" | "lg" | "xl" | "flex" | number;
}
type GFAvatarProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonAvatarProps;
export const GFAvatar: React.FC<GFAvatarProps> = (props) => {
  const { name } = props;

  return (
    <Field
      name={props.displaynameField}
      render={(displayNameProps) => {
        return (
          <GenericFormField
            allProperties={props.params.allProperties}
            formRoot={props.params.formRoot}
            name={name}
            prefix={props.params.prefix}
            jsonProperty={props as any}
            render={(input, meta, name1, jsonProperty) => {
              return (
                <div className={"gf-avatar"}>
                  <AvatarComponent
                    avatar={input.value}
                    displayName={displayNameProps.input.value}
                    size={props.size}
                  />
                  <div className={"options"}>
                    <BfIcon data="upload-bottom" type="bf" size="3x" />
                  </div>
                </div>
              );
            }}
          />
        );
      }}
    />
  );
};

/***
 * ############ CHECKBOX ####################
 */
type GFCheckboxProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonPropertyCheckbox;
export const GFCheckbox: React.FC<GFCheckboxProps> = (props) => {
  const { name } = props;

  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props}
      render={(input, meta, name1, jsonProperty) => {
        return (
          <FormFieldWrapper {...props.containerStyleProps}>
            <BFCheckbox
              size={props.size}
              checked={input.value}
              appearance={props.appearance as CheckboxAppearance}
              validatorStyle={props.validatorStyle as ValidatorPopoverStyle}
              onChange={(value, checked, event) => input.onChange(checked)}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined,
              }}
            >
              {translateNonGenerate(props.label)}
            </BFCheckbox>
          </FormFieldWrapper>
        );
      }}
    />
  );
};

/***
 * ############ TOGGLE ####################
 */
type GFToggleProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonPropertyToggle;
export const GFToggle: React.FC<GFToggleProps> = (props) => {
  const { name } = props;
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props}
      render={(input, meta, name1, jsonProperty) => {
        if (input.value === "") {
          input.onChange(
            props.defaultValue !== undefined ? props.defaultValue : false
          );
        }

        return (
          <FormFieldWrapper {...props.containerStyleProps}>
            <BFToggle
              {...input}
              validatorStyle={props.validatorStyle as ValidatorPopoverStyle}
              checked={input.value}
              onChange={(checked, event) => input.onChange(checked)}
              label={props.label}
              labelPosition={props.labelPosition}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined,
              }}
            />
          </FormFieldWrapper>
        );
      }}
    />
  );
};

/***
 * ############ RADIO ####################
 */
type GFRadioGroupProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonPropertyRadioGroup;
export const GFRadioGroup: React.FC<GFRadioGroupProps> = (props) => {
  const { name } = props;

  let forceFormSpy =
    props.options.filter(
      (option) => (option as OptionConditionGroup).condition !== undefined
    ).length !== 0;
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props}
      forceFormSpy={forceFormSpy}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        const options = Validators.getValidOptions(
          props.options,
          currentValues
        );
        const value =
          options.find((option) => option.value === input.value) === -1
            ? null
            : input.value;
        if (value !== input.value) {
          input.onChange(value);
        }
        return (
          <FormFieldWrapper {...props.containerStyleProps}>
            <BFRadioGroup
              validatorStyle={props.validatorStyle as ValidatorPopoverStyle}
              onChange={(checked, event) => input.onChange(checked)}
              label={props.label}
              value={value}
              inline={props.inline}
              appearance={props.appearance}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined,
              }}
            >
              {options.map((option) => (
                <BfRadio key={option.value} value={option.value}>
                  {option.label}
                </BfRadio>
              ))}
            </BFRadioGroup>
          </FormFieldWrapper>
        );
      }}
    />
  );
};

/***
 * ############ CHECKBOX-GROUP ####################
 */
type GFCheckboxGroupProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonPropertyCheckboxGroup;
export const GFCheckboxGroup: React.FC<GFCheckboxGroupProps> = (props) => {
  const { name } = props;

  let forceFormSpy =
    props.options.filter(
      (option) => (option as OptionConditionGroup).condition !== undefined
    ).length !== 0;

  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props}
      forceFormSpy={forceFormSpy}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        const options = Validators.getValidOptions(
          props.options,
          currentValues
        );

        let value = input.value
          ? input.value.filter(
              (selVal) =>
                options.find((option) => option.value === selVal) !== undefined
            )
          : [];

        if (!_.isEqual(value, input.value)) {
          input.onChange(value);
        }
        Log.debug("##BFCheckboxGroup", input.value, value);
        return (
          <FormFieldWrapper {...props.containerStyleProps}>
            <BFCheckGroup
              defaultValue={[]}
              validatorStyle={props.validatorStyle as ValidatorPopoverStyle}
              onChange={(checked, event) => input.onChange(checked)}
              value={value}
              inline={props.inline}
              validation={{
                level: "error",
                message: meta.dirty ? meta.error : undefined,
              }}
            >
              {options.map((option) => (
                <BFCheckbox
                  key={option.value}
                  value={option.value}
                  appearance={props.appearance as CheckboxAppearance}
                >
                  <Santizer html={option.label} />
                </BFCheckbox>
              ))}
            </BFCheckGroup>
          </FormFieldWrapper>
        );
      }}
    />
  );
};

/***
 * ############ INPUT ####################
 */
type GFInputProps = { _component: string; name: string; params?: any } & {
  mask?: string;
} & (JsonPropertyText & JsonPropertyNumber & JsonPropertyTextArea);
const GFInput: React.FC<GFInputProps> = (props: any) => {
  const { _component } = props;
  const additionalProps: any = {};

  if (_component === "number") {
    additionalProps.decimalFixed = (props as JsonPropertyNumber).decimalFixed;
    additionalProps.min = (props as JsonPropertyNumber).min;
    additionalProps.max = (props as JsonPropertyNumber).max;
  }

  const prefixSuffixOpts: any = {};

  if (_component !== "textarea") {
    prefixSuffixOpts.prefix =
      props.prefixComp &&
      typeof props.prefixComp === "string" &&
      props.prefixComp.indexOf("icon:") === 0 ? (
        <BfIcon data={props.prefixComp.split(":")[1] as any} />
      ) : (
        props.prefixComp
      );

    prefixSuffixOpts.suffix =
      props.suffixComp &&
      typeof props.suffixComp === "string" &&
      props.suffixComp.indexOf("icon:") === 0 ? (
        <BfIcon data={props.suffixComp.split(":")[1] as any} />
      ) : (
        props.suffixComp
      );
  }
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={props.name}
      prefix={props.params.prefix}
      jsonProperty={props}
      render={(input, meta, name1, jsonProperty) => {
        return (
          <>
            <FormFieldWrapper {...props.containerStyleProps}>
              <BFInput
                {...input}
                identifier={props.identifier}
                type={_component}
                max={props.max}
                min={props.min}
                step={props.step}
                textAlign={props.textAlign}
                appearance={props.appearance}
                style={props.style}
                validatorStyle={props.validatorStyle}
                autoComplete={(props as JsonPropertyText).autoComplete}
                autoCapitalize={(props as JsonPropertyText).autoCapitalize}
                autoCorrect={(props as JsonPropertyText).autoCorrect}
                autoResize={(props as JsonPropertyTextArea).autoResize}
                label={props.label}
                mask={props.mask}
                labelPosition={props.labelPosition}
                value={input.value}
                disabled={props.disabled}
                onChange={(value, ev) => {
                  input.onChange(value);
                  if (props._changeActions) {
                    ExpressionHelper.handleEvents(props._changeActions, value);
                  }
                }}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
                params={props.params}
                validation={{
                  level: meta.error ? "error" : "warning",
                  message:
                    meta.error &&
                    !meta.active &&
                    (meta.touched || meta.submitError || meta.submitFailed)
                      ? meta.error
                      : meta.data.warning,
                }}
                onBlur={() => {
                  input.onBlur();
                  if (props._blurActions) {
                    ExpressionHelper.handleEvents(
                      props._blurActions,
                      input.value
                    );
                  }
                }}
                {...prefixSuffixOpts}
                {...additionalProps}
              />
            </FormFieldWrapper>
          </>
        );
      }}
    />
  );
};

export const GFText: React.FC<any> = (props) => {
  return GFInput(props);
};
export const GFIban: React.FC<any> = (props) => {
  return GFInput(props);
};
export const GFTextarea: React.FC<any> = (props) => {
  return GFInput(props);
};
export const GFNumber: React.FC<any> = (props) => {
  return GFInput(props);
};
export const GFMail: React.FC<any> = (props) => {
  return GFInput(props);
};
export const GFPriceInput: React.FC<any> = (props) => {
  return GFInput(props);
};

/***
 * ############ SELECT ####################
 */
type GFSelectProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonPropertySelect;
export const GFSelect: React.FC<GFSelectProps> = (props) => {
  const { name } = props;

  let forceFormSpy =
    props._options !== undefined ||
    props.options.filter(
      (option) => (option as OptionConditionGroup).condition !== undefined
    ).length !== 0;

  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props}
      forceFormSpy={forceFormSpy}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        let useOptions = [];
        let prefixValues = currentValues;
        if (props.params?.prefix) {
          const steps = props.params.prefix.split(".");
          let i = 0;
          while (i < steps.length) {
            prefixValues = prefixValues[steps[i]];
            i++;
          }
        }
        if (props.options) {
          useOptions = props.options;
        } else if (props._options) {
          useOptions = ExpressionHelper.evaluateExpression(
            props._options,
            { "!current": prefixValues, ...currentValues },
            {
              ...(props.params.additionalData || {}),
              params: props.params,
            }
          );
        }

        let useExtraFooter = null;
        if (props.renderExtraFooter) {
          useExtraFooter = ComponentsMapper.createElement(
            props.renderExtraFooter,
            props.params
          );
        }

        const options = Validators.getValidOptions(useOptions, currentValues);

        let value = Validators.getValidValue(
          props.multiple ? input.value || [] : input.value,
          options,
          currentValues
        );

        if (!_.isEqual(value, input.value)) {
          input.onChange(value);
        }
        let hasChildrenProps = options.find((e) => e.children !== undefined);

        return (
          <FormFieldWrapper {...props.containerStyleProps}>
            <BfSelect
              {...input}
              disabled={props.disabled}
              renderExtraFooter={
                useExtraFooter ? () => useExtraFooter : undefined
              }
              renderValuePath={props.renderValuePath}
              disabledItemValues={props.disabledItemValues}
              expandItemValues={props.expandItemValues}
              validatorStyle={props.validatorStyle}
              renderMultipleValueCount={!!props.multiple}
              renderSelectAllFooter={!!props.multiple}
              type={
                props.multiple
                  ? "multiple"
                  : hasChildrenProps
                  ? "tree"
                  : "normal"
              }
              label={props.label}
              labelPosition={props.labelPosition}
              value={value}
              // onBlur={() => {input.onBlur(value)}}
              onChange={(value, ev) => {
                input.onChange(value);
              }}
              data={options}
              defaultValue={props.defaultValue}
              placeholder={props.placeholder}
              cleanable={props.cleanable}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined,
              }}
            />
          </FormFieldWrapper>
        );
      }}
    />
  );
};

/***
 * ############ DATE ####################
 */
type GFDateProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonPropertyDate;
export const GFDate: React.FC<GFDateProps> = (props) => {
  const { name } = props;

  const min = props.min ? new Date(props.min) : undefined;
  const max = props.max ? new Date(props.max) : undefined;
  const storeData = store.getState();
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props}
      render={(input, meta, name1, jsonProperty) => (
        <FormFieldWrapper {...props.containerStyleProps}>
          <BfDate
            {...input}
            validatorStyle={props.validatorStyle as ValidatorPopoverStyle}
            label={props.label}
            disabled={props.disabled}
            oneTap={props.oneTap}
            labelPosition={props.labelPosition}
            value={input.value}
            defaultValue={
              props.defaultValue ? new Date(props.defaultValue) : undefined
            }
            onBlur={() => {
              if (props._blurActions) {
                ExpressionHelper.handleEvents(props._blurActions, input.value);
              }
            }}
            onChange={() => {
              if (props._changeActions) {
                ExpressionHelper.handleEvents(
                  props._changeActions,
                  input.value
                );
              }
            }}
            onSelect={(value) => {
              const date = moment(value).utc(false).startOf("day");
              input.onChange(date.toDate());
            }}
            cleanable={props.cleanable}
            placeholder={props.placeholder}
            format={props.format}
            validation={{
              level: "error",
              message:
                !meta.active &&
                (meta.touched || meta.submitError || meta.submitFailed)
                  ? meta.error
                  : undefined,
            }}
            disabledDate={
              min || max
                ? (date) => {
                    if (min) {
                      if (Number(min) - Number(date) < 0) {
                        return false;
                      }
                    }
                    if (max) {
                      if (Number(date) - Number(max) < 0) {
                        return false;
                      }
                    }
                    return true;
                  }
                : props.disabledDate
                ? (date) =>
                    ExpressionHelper.evaluateExpression(
                      props.disabledDate,
                      { date },
                      { storeData }
                    )
                : undefined
            }
          />
        </FormFieldWrapper>
      )}
    />
  );
};

/***
 * ############ Upload ####################
 */
type GFUploadProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonPropertyUpload;
export const GFUpload: React.FC<GFUploadProps> = (props) => {
  const { name } = props;
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        const propsOfForm = (
          props.params.formRoot as any
        ).formProps.form.mutators.getProps();
        return (
          <FormFieldWrapper {...props.containerStyleProps}>
            <BFUpload
              {...input}
              title={props.title}
              titleKey={props.titleKey}
              accept={props.accept}
              maxFilesize={props.maxFilesize}
              removable={props.removable}
              value={input.value}
              folderPermissions={props.folderPermissions}
              assetField={name}
              assetType={propsOfForm.assetType}
              assetId={
                propsOfForm.formValue ? propsOfForm.formValue["_id"] : undefined
              }
              // onBlur={() => {input.onBlur(value)}}
              onChange={(data) => input.onChange(data)}
              // validation={{
              // 	level: "error",
              // 	message: !meta.active && (meta.touched || meta.submitError || meta.submitFailed) ? meta.error : undefined
              // }}
            />
          </FormFieldWrapper>
        );
      }}
    />
  );
};

/***
 * ############ Upload ####################
 */
type GFRestrictionProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonPropertyRestriction;
export const GFRestriction: React.FC<GFRestrictionProps> = (props) => {
  const { name } = props;
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        const propsOfForm = (
          props.params.formRoot as any
        ).formProps.form.mutators.getProps();
        return (
          <FormFieldWrapper {...props.containerStyleProps}>
            <RestrictComponent
              assetId={
                propsOfForm.formValue ? propsOfForm.formValue["_id"] : undefined
              }
              value={input.value as RestrictValue}
              onChange={(val) => input.onChange(val)}
              label={props.label}
              {...props}
            />
          </FormFieldWrapper>
        );
      }}
    />
  );
};

/***
 * ############ PIN ####################
 */
interface JsonPropertyPIN {
  _component: "pin";
  length: number;
  validate?: string | string[] | RegExp | ((key: string) => boolean);
  appearance?: string;
  numberOnly?: boolean;
}
type GFPinInputProps = {
  _component: string;
  name: string;
  params?: any;
} & JsonPropertyPIN;
export const GFPinInput: React.FC<GFPinInputProps> = (props: any) => {
  const { _component } = props;

  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={props.name}
      prefix={props.params.prefix}
      jsonProperty={props}
      render={(input, meta, name1, jsonProperty) => {
        return (
          <>
            <FormFieldWrapper {...props.containerStyleProps}>
              <BFPinInput
                {...input}
                appearance={props.appearance}
                value={input.value}
                onChange={(value, ev) => input.onChange(value)}
                numberOnly={props.numberOnly}
                length={props.length}
                validate={props.validate}
                validation={{
                  level: meta.error ? "error" : "warning",
                  message:
                    meta.error &&
                    !meta.active &&
                    (meta.touched || meta.submitError || meta.submitFailed)
                      ? meta.error
                      : meta.data.warning,
                }}
                // validation={{
                // 	level: meta.error ? "error" : "warning",
                // 	message:
                // 		meta.error && !meta.active && (meta.touched || meta.submitError || meta.submitFailed)
                // 			? meta.error
                // 			: meta.data.warning
                // }}
              />
            </FormFieldWrapper>
          </>
        );
      }}
    />
  );
};

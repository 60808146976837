import { translateNonGenerate } from "@/utils/Helpers";
import {
  getCurrentSelectedLanguage,
  getMandatorDefaultLanguage,
} from "../redux/reducers/ui-config/UiConfig";
import { store } from "../redux/store";
import { HTTP } from "../utils/Http";

export type TranslatedLabelObject = Record<string, string>;
export type TranslatedLabel = TranslatedLabelObject | string;

type DeeplResponse = {
  translations: {
    detected_source_language: string;
    text: string;
  }[];
};
class LanguageServiceClass {
  async suggestTranslation(text: string, lang: string) {
    const response: DeeplResponse = await HTTP.get({
      target: "EMPTY",
      url: `/liquiplanservice/getDeeplTranslation`,
      queryParams: {
        param: {
          text,
          lang,
        },
      },
    });
    if (response && response.translations?.length > 0) {
      return response?.translations[0].text;
    } else {
      return "";
    }
  }

  /**
   * Takes a TranslatedLabel object and tries to return the languagae for the
   * current language. --> uiConfig.global.SelectedLanguage
   *
   * If the current language is not available, the default language for mandator is used.
   * --> uiConfig.global.mandatoryDefaultLanguage
   *
   * If the default language is not available, the first key of the TranslatedLabel object is used.
   * @param label
   * @returns
   */
  translateLabel(label: TranslatedLabel) {
    if (label === null || label === undefined) return null;
    if (typeof label === "string") {
      return translateNonGenerate(label);
    }
    const selectedLanguage = getCurrentSelectedLanguage(store.getState());
    const mandatorDefaultLanguage = getMandatorDefaultLanguage(
      store.getState()
    );

    if (selectedLanguage && label[selectedLanguage]) {
      return label[selectedLanguage];
    }

    if (mandatorDefaultLanguage && label[mandatorDefaultLanguage]) {
      return label[mandatorDefaultLanguage];
    }

    const firstKey = Object.keys(label)[0];
    return label[firstKey];
  }

  getMandatorDefaultLanguage() {
    return getMandatorDefaultLanguage(store.getState());
  }
}
const LanguageService = new LanguageServiceClass();
export default LanguageService;

(window as any).LanguageService = LanguageService;

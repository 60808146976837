import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { ContactEmail } from "@/model/db/Contact";
import BFEntries from "@/modules/abstract-ui/data/entries/BFEntries";
import Tools from "@/utils/Tools";
import classNames from "classnames";
import { useRef } from "react";

interface ContactBaseEmailProps {
  emails: ContactEmail[];
}
const ContactBaseEmail = (props: ContactBaseEmailProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className={classNames(`contact-base-emails`)} ref={ref}>
      <BFEntries
        emptyText={i18n.t(
          "ContactBaseDataView.noEmail",
          "Keine Email vorhanden"
        )}
        entries={props.emails.map((email) => {
          const addEmailToClipboard = () => {
            const textToCopy = email.email;
            Tools.copy2Clipboard(
              textToCopy,
              i18n.t(
                "Contact.Email.copiedToClipboard",
                "Email in Zwischenablage kopiert"
              ),
              ref.current
            );
          };

          return {
            content: (
              <>
                <DebugDataComponent data={email} />
                <div className={`email`}>{email.email}</div>
                {email.note && (
                  <div className={`note __sub-text margin-top-5`}>
                    {email.note}
                  </div>
                )}
              </>
            ),
            actions: [
              {
                icon: "duplicate-file",
                onClick: addEmailToClipboard,
                tooltip: i18n.t(
                  "ContactBaseEmail.copyToClipboard",
                  "In Zwischenablage kopieren"
                ),
              },
              {
                icon: "paginate-filter-mail",
                href: `mailto:${email.email}`,
                tooltip: i18n.t("ContactBaseEmail.sendMail", "Email senden"),
              },
            ],
          };
        })}
      />
    </div>
  );
};

export default ContactBaseEmail;

import { withTranslation, WithTranslation } from "react-i18next";
import { IComponent } from "../../../../../configurable/layouts/IComponent";
import { AbstractComponent } from "../../../../../utils/abstracts/AbstractComponent";

type Props = {
  title: string;
  headerComponents: { [key: string]: IComponent };
} & WithTranslation;

type States = {};

class TableComponentTitlebar extends AbstractComponent<Props, States> {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {
      title,
      headerComponents,
      // i18n
    } = this.props;
    return (
      <div className={"table-component-header"}>
        <div className={"title"}>{title}</div>
        <div className={"fill"} />
        {headerComponents
          ? Object.entries(headerComponents)
              .sort((a, b) => a[1].orderIndex - b[1].orderIndex)
              .map(([key, headerComponent]) =>
                (window as any).ComponentsMapper.createElement(
                  { ...headerComponent },
                  this.props.params,
                  key
                )
              )
          : null}
      </div>
    );
  }
}

// const mapStateToProps = (state: AppState) => ({})

export default withTranslation()(TableComponentTitlebar);

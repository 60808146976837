import { CSSProperties } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { Drawer } from "rsuite";
import Log from "../../../debug/Log";
import { ActionData } from "../../../model/common/DataBus/ActionData";
import { clearApplicationData } from "../../../redux/actions/application/application-actions";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractComponent,
  AbstractProps,
  AbstractStates,
  SendEvent,
} from "../../../utils/abstracts/AbstractComponent";
import { IComponent } from "../IComponent";
import SplitPageDetailsPage from "./SplitPageDetailsPage";
import "./SplitPageLayout.scss";

type SplitPageLayoutConf = {
  clearApplicationData: (paths: string[]) => void;
  identifier: string;
  backButtonActionId: string;
  fullPageBreakViewportWidth?: number;
  viewportWidth: number;
  clearApplicationDataOnChange?: string[];
  onChangedEvents: { [key: string]: Function | SendEvent };
  onPreChangeEvents: { [key: string]: Function | SendEvent };

  oType: "asset" | "user" | "group";
  assetType?: string;
  appearance: "split" | "drawer";

  mainPage: {
    component: IComponent;
    stylesBroken: CSSProperties;
    stylesSplitted: CSSProperties;
  };
  detailPage: {
    component: IComponent;
    stylesBroken: CSSProperties;
    stylesSplitted: CSSProperties;
  };
  emptyPage: {
    copyDetail?: boolean;
    component: IComponent;
  };
  // applicationCache: ApplicationCache;
};

type Props = SplitPageLayoutConf &
  RouteComponentProps &
  WithTranslation &
  AbstractProps;

type States = {
  detailsId: string;
  detailsLoading: boolean;
} & AbstractStates;

class SplitPageLayout extends AbstractComponent<Props, States> {
  static defaultProps = {
    appearance: "split",
  };

  readonly state: States = {
    detailsId: null,
    detailsLoading: false,
  };

  componentDidMount(): void {
    this.subscribeActionEvent("backButton", (data) => {
      const actionData = data as ActionData;
      if (actionData.type === "click") {
        this.goBack();
      }
    });
    this.subscribe("SPLITPAGE_UPDATE_OBJECT", (data) => {
      if (data.identifier === this.props.identifier) {
        this.populateComponentState({ id: data.data._id, object: data.data });
      }
    });

    this.updateActionState(this.props);
  }

  goBack() {
    this.props.history.push(this.props.match.url);
  }

  shouldComponentUpdate(nextProps: Props, nextState: States): boolean {
    const oldBreakPage =
      this.props.fullPageBreakViewportWidth !== undefined
        ? this.props.viewportWidth <= this.props.fullPageBreakViewportWidth
        : false;
    const newBreakPage =
      nextProps.fullPageBreakViewportWidth !== undefined
        ? nextProps.viewportWidth <= nextProps.fullPageBreakViewportWidth
        : false;

    if (this.state.detailsId !== nextState.detailsId) {
      this.updateActionState(nextProps);
      return true;
    }
    if (oldBreakPage !== newBreakPage) {
      this.updateActionState(nextProps);

      return true;
    }

    if (this.state.detailsLoading !== nextState.detailsLoading) {
      return true;
    }

    if (this.props.params !== nextProps.params) {
      return true;
    }
    if (this.props.match !== nextProps.match) {
      return true;
    }
    return false;
  }

  updateActionState(props: Props) {
    const newBreakPage =
      props.fullPageBreakViewportWidth !== undefined
        ? props.viewportWidth <= props.fullPageBreakViewportWidth
        : false;
    this.populateButtonState("backButton", { hidden: !newBreakPage });
  }

  render() {
    const {
      mainPage,
      detailPage,
      emptyPage,

      match,
      location,
      i18n,
    } = this.props;
    const t = i18n.t;
    return (
      <Switch>
        <Route
          path={`${match.url}/:id`}
          children={({ match }) => {
            if (match.params.id.indexOf("__") !== -1) {
              this.setState({ detailsId: null });
              return this.renderPages(null);
            }
            if (match.params.id !== this.state.detailsId) {
              this.populateComponentState({ id: match.params.id });
              this.setState({ detailsId: match.params.id });
            }
            return this.renderPages(match.params.id);
          }}
        />

        <Route
          path={`${match.url}`}
          children={({ match }) => {
            this.setState({ detailsId: null });
            return this.renderPages();
          }}
        />
      </Switch>
    );
  }

  renderPages(id?: string) {
    const { appearance } = this.props;

    if (appearance === "drawer") {
      return this.renderAppearanceDrawer(id);
    } else if (appearance === "split") {
      return this.renderAppearanceSplit(id);
    } else {
      Log.warning("unknown appearance type in splitpage layout: " + appearance);
    }
  }

  renderAppearanceSplit(id?: string) {
    const {
      appearance,
      mainPage,
      detailPage,
      emptyPage,
      fullPageBreakViewportWidth,
      viewportWidth,
      match,
      location,
      i18n,
      assetType,
      oType,
    } = this.props;
    const isSmall =
      fullPageBreakViewportWidth !== undefined &&
      viewportWidth <= fullPageBreakViewportWidth;
    const breakPage = isSmall && id !== undefined ? true : false;

    const cssMainPage =
      !isSmall && (id !== undefined || emptyPage !== undefined)
        ? mainPage.stylesSplitted
        : mainPage.stylesBroken;
    const cssDetailPage = isSmall
      ? detailPage.stylesBroken
      : detailPage.stylesSplitted;
    return (
      <div
        className={`split-page-layout ${
          id || emptyPage ? "details-active" : ""
        } ${breakPage ? "break" : ""}`}
      >
        <div className={"main-page"} style={cssMainPage}>
          {(window as any).ComponentsMapper.createElement(
            mainPage.component,
            this.props.params
          )}
        </div>
        {(isSmall && id) || !isSmall ? (
          id || (emptyPage && emptyPage.copyDetail) ? (
            <SplitPageDetailsPage
              params={this.props.params}
              objectId={id}
              oType={oType}
              assetType={assetType}
              cssDetailPage={cssDetailPage}
              component={detailPage.component}
              goToRoot={() => this.goBack()}
              onBeforeObjectFetch={(id) => {
                this.handleEvents(this.props.onPreChangeEvents, { id: id });
              }}
              onObjectFetch={(data) => {
                this.populateComponentState({ id: id, object: data });

                this.handleEvents(this.props.onChangedEvents, { id: id });
              }}
            />
          ) : emptyPage ? (
            <div className={`detail-page`} style={cssDetailPage}>
              {(window as any).ComponentsMapper.createElement(
                emptyPage.component,
                this.props.params
              )}
            </div>
          ) : null
        ) : null}
      </div>
    );
  }
  renderAppearanceDrawer(id?: string) {
    const {
      appearance,
      mainPage,
      detailPage,
      emptyPage,
      fullPageBreakViewportWidth,
      viewportWidth,
      match,
      location,
      i18n,
      assetType,
      oType,
    } = this.props;
    const isSmall =
      fullPageBreakViewportWidth !== undefined &&
      viewportWidth <= fullPageBreakViewportWidth;
    const breakPage = isSmall && id !== undefined ? true : false;

    const cssMainPage =
      !isSmall && (id !== undefined || emptyPage !== undefined)
        ? mainPage.stylesSplitted
        : mainPage.stylesBroken;
    const cssDetailPage = isSmall
      ? detailPage.stylesBroken
      : detailPage.stylesSplitted;

    let children = null;
    if (isSmall) {
      children = (
        <Drawer
          className={`split-page-drawer`}
          open={id !== undefined && id !== null}
          onClose={() => {
            this.goBack();
          }}
        >
          <div className={`drawer-container`}>
            <SplitPageDetailsPage
              params={this.props.params}
              objectId={id}
              oType={oType}
              assetType={assetType}
              cssDetailPage={cssDetailPage}
              component={detailPage.component}
              goToRoot={() => this.goBack()}
              onBeforeObjectFetch={(id) => {
                this.handleEvents(this.props.onPreChangeEvents, { id: id });
              }}
              onObjectFetch={(data) => {
                this.populateComponentState({ id: id, object: data });

                this.handleEvents(this.props.onChangedEvents, { id: id });
              }}
            />
          </div>
        </Drawer>
      );
    } else {
      if (id) {
        children = (
          <SplitPageDetailsPage
            params={this.props.params}
            objectId={id}
            oType={oType}
            assetType={assetType}
            cssDetailPage={cssDetailPage}
            component={detailPage.component}
            goToRoot={() => this.goBack()}
            onBeforeObjectFetch={(id) => {
              this.handleEvents(this.props.onPreChangeEvents, { id: id });
            }}
            onObjectFetch={(data) => {
              this.populateComponentState({ id: id, object: data });

              this.handleEvents(this.props.onChangedEvents, { id: id });
            }}
          />
        );
      } else {
        children = (
          <div className={`detail-page`} style={cssDetailPage}>
            {(window as any).ComponentsMapper.createElement(
              emptyPage.component,
              this.props.params
            )}
          </div>
        );
      }
    }

    return (
      <div
        className={`split-page-layout drawer ${
          id || emptyPage ? "details-active" : ""
        } ${breakPage ? "break" : ""}`}
      >
        <div className={"main-page"} style={cssMainPage}>
          {(window as any).ComponentsMapper.createElement(
            mainPage.component,
            this.props.params
          )}
        </div>
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
  viewportWidth: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH],
});

export default withRouter(
  connect(mapStateToProps, {
    clearApplicationData,
  })(withTranslation()(SplitPageLayout))
);

import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import EZAutocomplete from "@/modules/ez-form/form-elements/ez-autocomplete/EZAutocomplete";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { usePrevious } from "@/utils/Hooks";
import ObjectIdService from "@/utils/ObjectIdUtils";
import classNames from "classnames";
import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, FormSpy } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormFieldValues from "../../../components/Form/Fields/FormFieldValues";
import FormValidators, {
  FV,
} from "../../../components/Form/Validation/FormValidators";
import i18n from "../../../i18n";
import {
  AddressData,
  BankAccountDataEU,
  BankAccountDataUS,
  Contact,
  ContactPerson,
  ContactType,
} from "../../../model/db/Contact";
import { useConstants } from "../../../redux/hooks";
import FormUtils from "../../../utils/FormUtils";
import BFInput from "../../abstract-ui/forms/input/BFInput";
import BFRadioGroup from "../../abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "../../abstract-ui/forms/radio/BfRadio";
import BFSelect from "../../abstract-ui/forms/select/BFSelect";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import ContactRelationFormFields from "../contact-detail-view/relations/ContactRelationFormFields";
import ContactUtils from "../ContactUtils";
import { AddressFormPart } from "./AddressFormPart";
import { BankAccountFormPart } from "./BankAccountFormPart";
import "./CBTenantFormMain.scss";
import { ContactPersonFormPart } from "./ContactPersonFormPart";
import { EmailFormPart } from "./EmailFormPart";
import { PhoneFormPart } from "./PhoneFormPart";

const titles = [
  "Dr.", // Doktor
  "Prof.", // Professor
  "PD", // Privatdozent
  "Mag.", // Magister
  "Dipl.-Ing.", // Diplom-Ingenieur
];

interface CBTenantFormMainProps {
  contact?: Contact;
  prefix?: string;
  form: FormApi;
  disabled?: boolean;
  typeOptions: { label: string; value: string }[];
  overwritedForEntity?: string;
  contactTypes?: ContactType[];
}
const CBTenantFormMain = (props: CBTenantFormMainProps) => {
  const constants = useConstants();
  return (
    <div className={classNames(`cb-tenant-form-main`)}>
      <div className={` section-assignment-data`}>
        <div className={`field-row`}>
          {props.typeOptions.length !== 1 && (
            <div className={`__field`}>
              <Field
                name={(props.prefix || "") + "type"}
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <BFSelect
                    {...input}
                    label={`${i18n.t("Contact.Form.Fields.type", "Bereich")}*`}
                    validation={
                      meta.error && meta.touched
                        ? { level: "error", message: meta.error }
                        : undefined
                    }
                    data={props.typeOptions}
                    onChange={(value) => {
                      input.onChange(value);
                      props.form.mutators.setValue(
                        (props.prefix || "") + "entity",
                        null
                      );
                      props.form.mutators.setValue(
                        (props.prefix || "") + "objectId",
                        null
                      );
                      props.form.mutators.setValue(
                        (props.prefix || "") + "relations",
                        []
                      );
                    }}
                  />
                )}
              </Field>
            </div>
          )}
        </div>
      </div>
      <FormFieldValues
        names={[
          `${props.prefix || ""}persons`,
          `${props.prefix || ""}personType`,
          `${props.prefix || ""}companyName`,
        ]}
      >
        {([persons, personType, companyName]) => (
          <SalutationTextSetter
            form={props.form}
            personType={personType}
            companyName={companyName}
            persons={persons}
            prefix={props.prefix}
          />
        )}
      </FormFieldValues>
      <div className={`section-base-data`}>
        <div className={`field-row`}>
          <div className={`__field tenant-type`}>
            <Field
              name={(props.prefix || "") + "personType"}
              validate={FormValidators.compose(FormValidators.required())}
            >
              {({ input, meta }) => (
                <BFRadioGroup
                  {...input}
                  inline
                  validation={
                    meta.error && meta.touched
                      ? { level: "error", message: meta.error }
                      : undefined
                  }
                  onChange={(value) => {
                    input.onChange(value);
                    if (value === "private") {
                      props.form.mutators.setValue(
                        (props.prefix || "") + "companyName",
                        null
                      );

                      const newPersons = [
                        {
                          title: "",
                          salutation: undefined,
                          lastName: "",
                          firstName: "",
                        },
                      ];
                      props.form.mutators.setValue(
                        (props.prefix || "") + "persons",
                        newPersons
                      );
                    } else {
                      props.form.mutators.setValue(
                        (props.prefix || "") + "persons",
                        undefined
                      );
                    }
                  }}
                >
                  <BfRadio disabled={props.disabled} value={"organization"}>
                    {i18n.t(
                      "Contact.Form.Fields.personTypeOrganization",
                      "Firma"
                    )}
                  </BfRadio>
                  <BfRadio disabled={props.disabled} value={"private"}>
                    {i18n.t("Contact.Form.Fields.personTypePrivate", "Person")}
                  </BfRadio>
                </BFRadioGroup>
              )}
            </Field>
          </div>
        </div>
        <FormFieldValues names={[(props.prefix || "") + "personType"]}>
          {([personType]) => (
            <>
              {personType === "organization" && (
                <>
                  <div className={`__field companyName`}>
                    <Field
                      name={(props.prefix || "") + "companyName"}
                      validate={FormValidators.compose(
                        FormValidators.required(),
                        FormValidators.max(150)
                      )}
                    >
                      {({ input, meta }) => (
                        <BFInput
                          disabled={props.disabled}
                          {...input}
                          label={`${i18n.t(
                            "Contact.Form.Fields.companyName",
                            "Name der Firma"
                          )}*`}
                          onChange={(value) => {
                            input.onChange(value);
                          }}
                          validation={
                            meta.error && meta.touched
                              ? { level: "error", message: meta.error }
                              : undefined
                          }
                        />
                      )}
                    </Field>
                  </div>
                </>
              )}
              {personType === "private" && (
                <FieldArray name={(props.prefix || "") + "persons"}>
                  {({ fields }) => (
                    <div className={`persons`}>
                      {fields.map((name, index) => (
                        <PersonRow
                          {...props}
                          persons={fields.value}
                          prefix={name}
                          key={index}
                          onDelete={
                            fields.length > 1
                              ? () => {
                                  fields.remove(index);
                                }
                              : undefined
                          }
                        />
                      ))}
                      <div
                        className={`__flex __justify-center margin-bottom-10`}
                      >
                        <BFButton
                          appearance="link"
                          size="xs"
                          noPadding
                          onClick={() =>
                            fields.push({
                              salutation: undefined,
                              title: "",
                              firstName: "",
                              lastName: "",
                            })
                          }
                        >
                          {i18n.t(
                            "Contact.Form.AddPerson",
                            "Weitere Person hinzufügen"
                          )}
                        </BFButton>
                      </div>
                    </div>
                  )}
                </FieldArray>
              )}
              {personType && (
                <div className={`__field`}>
                  <Field
                    name={`${props.prefix || ""}salutationText`}
                    validate={FormValidators.compose(
                      FormValidators.max(1000),
                      FormValidators.required()
                    )}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        type="textarea"
                        autoResize
                        disabled={props.disabled}
                        {...input}
                        label={`${i18n.t(
                          "Contact.Form.Fields.SalutationText",
                          "Anredestext"
                        )}`}
                        validation={
                          meta.error && meta.touched
                            ? {
                                level: "error",
                                message: meta.error,
                              }
                            : undefined
                        }
                      />
                    )}
                  </Field>
                </div>
              )}

              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <>
                    {/* Address */}
                    {(props.prefix
                      ? values[
                          props.prefix.substring(0, props.prefix.length - 1)
                        ]
                      : values
                    )?.helpers?.showAddress && (
                      <AddressFormPart
                        disabled={props.disabled}
                        form={props.form}
                        prefix={props.prefix}
                      />
                    )}
                    {/* Emails */}
                    {(props.prefix
                      ? values[
                          props.prefix.substring(0, props.prefix.length - 1)
                        ]
                      : values
                    )?.helpers?.showEmails && (
                      <EmailFormPart
                        disabled={props.disabled}
                        form={props.form}
                        prefix={props.prefix}
                      />
                    )}
                    {/* Phones */}
                    {(props.prefix
                      ? values[
                          props.prefix.substring(0, props.prefix.length - 1)
                        ]
                      : values
                    )?.helpers?.showPhone && (
                      <PhoneFormPart
                        disabled={props.disabled}
                        form={props.form}
                        prefix={props.prefix}
                      />
                    )}

                    {/* BankAccounts */}
                    {(props.prefix
                      ? values[
                          props.prefix.substring(0, props.prefix.length - 1)
                        ]
                      : values
                    )?.helpers?.showBankAccount && (
                      <BankAccountFormPart
                        disabled={props.disabled}
                        form={props.form}
                        prefix={props.prefix}
                      />
                    )}
                    {/* ContactPersons */}
                    {(props.prefix
                      ? values[
                          props.prefix.substring(0, props.prefix.length - 1)
                        ]
                      : values
                    )?.helpers?.showContactPersons && (
                      <ContactPersonFormPart
                        disabled={props.disabled}
                        form={props.form}
                        prefix={props.prefix}
                      />
                    )}
                    {/* Tag */}

                    {/* Actions */}
                    <div className={`add-actions`}>
                      {!(
                        props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                      )?.helpers?.showAddress && (
                        <BFButton
                          disabled={props.disabled}
                          type="button"
                          appearance={"outline"}
                          size="xs"
                          text={i18n.t(
                            "Contact.Form.Buttons.addAddress",
                            "Adresse hinzufügen"
                          )}
                          onClick={() => {
                            props.form.mutators.setValue(
                              (props.prefix || "") + "helpers.showAddress",
                              true
                            );
                            props.form.mutators.setValue(
                              (props.prefix || "") + "address",
                              [
                                {
                                  id: ObjectIdService.new(),
                                  isMain: true,
                                  street: "",
                                  zip: "",
                                  city: "",
                                  country: "",
                                  additional: "",
                                } as AddressData,
                              ]
                            );
                          }}
                        ></BFButton>
                      )}
                      {!(
                        props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                      )?.helpers?.showEmails && (
                        <BFButton
                          disabled={props.disabled}
                          type="button"
                          appearance={"outline"}
                          size="xs"
                          text={i18n.t(
                            "Contact.Form.Buttons.addEmail",
                            "E-Mail hinzufügen"
                          )}
                          onClick={() => {
                            props.form.mutators.setValue(
                              (props.prefix || "") + "helpers.showEmails",
                              true
                            );
                            props.form.mutators.setValue(
                              (props.prefix || "") + "emails",
                              [
                                {
                                  id: ObjectIdService.new(),
                                  email: "",
                                  note: "",
                                },
                              ]
                            );
                          }}
                        ></BFButton>
                      )}
                      {!(
                        props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                      )?.helpers?.showPhone && (
                        <BFButton
                          disabled={props.disabled}
                          type="button"
                          appearance={"outline"}
                          size="xs"
                          text={i18n.t(
                            "Contact.Form.Buttons.addPhone",
                            "Telefon hinzufügen"
                          )}
                          onClick={() => {
                            props.form.mutators.setValue(
                              (props.prefix || "") + "helpers.showPhone",
                              true
                            );
                            props.form.mutators.setValue(
                              (props.prefix || "") + "phone",
                              [
                                {
                                  id: ObjectIdService.new(),
                                  phone: "",
                                  note: "",
                                },
                              ]
                            );
                          }}
                        ></BFButton>
                      )}
                      {!(
                        props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                      )?.helpers?.showBankAccount && (
                        <BFButton
                          disabled={props.disabled}
                          type="button"
                          appearance={"outline"}
                          size="xs"
                          text={i18n.t(
                            "Contact.Form.Buttons.addBankAccount",
                            "Bankkonto hinzufügen"
                          )}
                          onClick={() => {
                            props.form.mutators.setValue(
                              (props.prefix || "") + "helpers.showBankAccount",
                              true
                            );
                            props.form.mutators.setValue(
                              (props.prefix || "") + "bankAccount",
                              [
                                constants?.currency === "USD"
                                  ? ({
                                      id: ObjectIdService.new(),
                                      type: "US",
                                      isMain: true,
                                      accountNumber: "",
                                      routingNumber: "",
                                      accountHolder: "",
                                    } as BankAccountDataUS)
                                  : ({
                                      id: ObjectIdService.new(),
                                      type: "EU",
                                      iban: "",
                                      isMain: true,
                                      bic: "",
                                      bankName: "",
                                      accountHolder: "",
                                    } as BankAccountDataEU),
                              ]
                            );
                          }}
                        ></BFButton>
                      )}
                      {!(
                        props.prefix
                          ? values[
                              props.prefix.substring(0, props.prefix.length - 1)
                            ]
                          : values
                      )?.helpers?.showContactPersons && (
                        <BFButton
                          disabled={props.disabled}
                          type="button"
                          appearance={"outline"}
                          size="xs"
                          text={i18n.t(
                            "Contact.Form.Buttons.addContactPerson",
                            "Kontakt hinzufügen"
                          )}
                          onClick={() => {
                            props.form.mutators.setValue(
                              (props.prefix || "") +
                                "helpers.showContactPersons",
                              true
                            );
                            props.form.mutators.setValue(
                              (props.prefix || "") + "contactPersons",
                              [
                                {
                                  id: ObjectIdService.new(),
                                  salutation: undefined,
                                  firstName: "",
                                  lastName: "",
                                  email: "",
                                  phone: "",
                                  note: "",
                                  salutationText:
                                    ContactUtils.getSalutationString({
                                      personType: "private",
                                    }),
                                } as ContactPerson,
                              ]
                            );
                          }}
                        ></BFButton>
                      )}
                      {/* {!values.showTags && (
                              <BFButton
                                type="button"
                                appearance={"outline"}
                                size="xs"
                                text={i18n.t(
                                  "Contact.Form.Buttons.addTag",
                                  "Tag hinzufügen"
                                )}
                                onClick={() => {
                                  props.form.mutators.setValue((props.prefix || "")+ "showTags", true);
                                  props.form.mutators.setValue((props.prefix || "")+ "tags", [""]);
                                }}
                              ></BFButton>
                            )} */}
                    </div>
                  </>
                )}
              </FormSpy>
            </>
          )}
        </FormFieldValues>
      </div>

      {props.overwritedForEntity && (
        <div className="overwrite-for-entity">
          <hr />
          <div
            className={`section-title __h3 padding-bottom-20 padding-top-20`}
          >
            {i18n.t("Contact.Form.OverwriteForEntity", "Überschreiben für")}{" "}
            {OrgaStruct.getEntity(props.overwritedForEntity).displayName}
          </div>
          <FormFieldValues
            names={["type", "contactPersons", "emails", "phone"]}
          >
            {([type, contactPersons, emails, phone]) => (
              <ContactRelationFormFields
                integrated
                type={type}
                contactTypes={props.contactTypes || []}
                phones={phone || []}
                emails={emails || []}
                contactPersons={contactPersons || []}
                prefix="overwrite"
              />
            )}
          </FormFieldValues>
        </div>
      )}
    </div>
  );
};

export default CBTenantFormMain;

const SalutationTextSetter = (props: {
  form: FormApi;
  prefix?: string;
  persons?: {
    salutation?: "f" | "m" | "d";
    firstName?: string;
    lastName?: string;
    title?: string;
  }[];
  personType: "private" | "organization";
  companyName?: string;
}) => {
  const prevPersons = usePrevious(props.persons);
  const prevPersonType = usePrevious(props.personType);
  const prevCompanyName = usePrevious(props.companyName);

  useEffect(() => {
    if (
      prevPersons === undefined &&
      prevPersonType === undefined &&
      prevCompanyName === undefined
    ) {
      // ignore first run
      return;
    }
    if (
      _.isEqual(prevPersons, props.persons) &&
      _.isEqual(prevPersonType, props.personType) &&
      _.isEqual(prevCompanyName, props.companyName)
    ) {
      // dont set saluation string if nothing changedss
      return;
    }

    if (props.personType === "organization") {
      props.form.mutators.setValue(
        `${props.prefix || ""}.salutationText`,
        ContactUtils.getSalutationString({
          personType: "organization",
        })
      );
    } else {
      props.form.mutators.setValue(
        `${props.prefix || ""}.salutationText`,
        ContactUtils.getSalutationString({
          personType: "private",
          persons: props.persons || [],
        })
      );
    }
  }, [props.persons, props.personType, props.companyName]);

  return null;
};

const PersonRow = (props: {
  prefix?: string;
  disabled?: boolean;
  form: FormApi;
  typeOptions: { label: string; value: string }[];
  persons?: {
    salutation?: "f" | "m" | "d";
    firstName?: string;
    lastName?: string;
    title?: string;
  }[];
  onDelete?: () => void;
}) => {
  return (
    <>
      <div className={`field-row`}>
        <div className={`__field __flex-1`}>
          <Field name={(props.prefix || "") + ".salutation"}>
            {({ input, meta }) => (
              <BFSelect
                disabled={props.disabled}
                {...input}
                onChange={(value: string) =>
                  value ? input.onChange(value) : input.onChange(undefined)
                }
                label={`${i18n.t("Contact.Form.Fields.salutation", "Anrede")}`}
                validation={
                  meta.error && meta.touched
                    ? { level: "error", message: meta.error }
                    : undefined
                }
                data={FormUtils.getSalutationOptions()}
              />
            )}
          </Field>
        </div>
        <div className={`__field __flex-1`}>
          <Field name={(props.prefix || "") + ".title"}>
            {({ input, meta }) => (
              <EZAutocomplete
                ignoreLinebreaks
                appearance="bf"
                disabled={props.disabled}
                newEntryLabel={(value) => <strong>{value}</strong>}
                {...input}
                label={`${i18n.t("Contact.Form.Fields.title", "Titel")}`}
                {...FV.getValidation(meta)}
                options={titles.map((e) => ({
                  label: e,
                  value: e,
                }))}
              />
            )}
          </Field>
        </div>

        <div className={`__field  __flex-2`}>
          <Field
            name={(props.prefix || "") + ".firstName"}
            validate={FormValidators.compose(FormValidators.max(150))}
          >
            {({ input, meta }) => (
              <BFInput
                disabled={props.disabled}
                {...input}
                label={`${i18n.t("Contact.Form.Fields.firstName", "Vorname")}`}
                validation={
                  meta.error && meta.touched
                    ? { level: "error", message: meta.error }
                    : undefined
                }
              />
            )}
          </Field>
        </div>

        <div className={`__field __flex-2`}>
          <Field
            name={(props.prefix || "") + ".lastName"}
            validate={FormValidators.compose(
              FormValidators.required(),
              FormValidators.max(150)
            )}
          >
            {({ input, meta }) => (
              <BFInput
                {...input}
                disabled={props.disabled}
                label={`${i18n.t("Contact.Form.Fields.lastName", "Nachname")}*`}
                validation={
                  meta.error && meta.touched
                    ? { level: "error", message: meta.error }
                    : undefined
                }
              />
            )}
          </Field>
        </div>
        {props.onDelete && (
          <div className={`padding-top-30`}>
            <BFButton appearance="link" size="xs" onClick={props.onDelete}>
              <BfIcon {...DefaultIcons.CLOSE} size="xs" />
            </BFButton>
          </div>
        )}
      </div>
    </>
  );
};

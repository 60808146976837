import i18n from "@/i18n";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import moment from "moment";
import React from "react";
import DebugDataComponent from "../../debug/DebugDataComponent";
import BFDate, {
  BFDateProps,
} from "../../modules/abstract-ui/forms/date/BFDate";
import BFInput, {
  BFInputProps,
} from "../../modules/abstract-ui/forms/input/BFInput";
import BFSelect, {
  BFSelectProps,
} from "../../modules/abstract-ui/forms/select/BFSelect";
import DataBus from "../../services/DataBus";
import { DataBusSubKeys } from "../../utils/Constants";
import { hasValue } from "../../utils/Helpers";
import { ModalParams } from "./ModalComponent";
import LoadModal from "./defaults/LoadModal";
import ProgressModal, { ProgressAnimationType } from "./defaults/ProgressModal";
import SuccessModal from "./defaults/SuccessModal";

class ModalManagerClass {
  show(conf: ModalParams) {
    DataBus.emit(DataBusSubKeys.MODAL_MANAGER_ADD, conf);
  }

  load(conf: {
    title?: string;
    message?: React.ReactNode;
    animationType?: ProgressAnimationType;

    promise: () => Promise<any>;
    onAbort: () => void;
    onFinish: (data: any) => void;
  }) {
    this.show({
      title: conf.title,
      noPadding: true,
      content: (states: any, setStates: (states: any) => void, close) => (
        <LoadModal
          animationType={conf.animationType}
          promise={conf.promise}
          loadingText={conf.message}
          title={conf.title}
          onFinish={(result) => {
            conf.onFinish(result);
            close();
          }}
          onAbort={() => {
            conf.onAbort();
            close();
          }}
        />
      ),
    });
  }
  progress(conf: {
    title?: string;

    entries: any[];
    progressEntry: (entry: any) => Promise<void>;

    loadingText?: React.ReactNode;
    animationType?: ProgressAnimationType;

    onFinish: () => void;
    onAbort: (errosOnEntries?: any[]) => void;
  }) {
    this.show({
      title: conf.title,
      noPadding: true,
      ignoreEscapeToClose: true,
      content: (states: any, setStates: (states: any) => void, close) => (
        <ProgressModal
          animationType={conf.animationType}
          entries={conf.entries}
          progressEntry={conf.progressEntry}
          onClose={(values) => {
            if (values && values.length > 0) {
              conf.onAbort(values);
            } else {
              conf.onFinish();
            }
            close();
          }}
        />
      ),
    });
  }

  custom(conf: {
    title?: string;
    message: string;
    elements: (state: any, setState: (state: any) => void) => React.ReactNode;
    onConfirm: (state: any) => void;
    onAbort?: () => void;
    canConfirm?: (state: any) => boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onHide?: () => void;
    initialState?: any;
  }) {
    const t = (window as any).translate;
    this.show({
      title: conf.title,
      content: (states: any, setStates: (states: any) => void) => (
        <div className="selection-default">
          <div className="message">{conf.message}</div>
          <div className={`form`}>{conf.elements(states, setStates)}</div>
          <DebugDataComponent data={states} />
        </div>
      ),
      onHide: conf.onHide,
      backdrop: "static",
      buttons: [
        {
          text: t(
            conf.cancelButtonText
              ? conf.cancelButtonText
              : "Global.Buttons.cancel"
          ),
          appearance: "outline",
          closeOnClick: true,
          onClick: conf.onAbort,
        },
        {
          text: t(
            conf.confirmButtonText
              ? conf.confirmButtonText
              : "Global.Buttons.ok"
          ),
          appearance: "primary",
          isEnabled: conf.canConfirm,
          closeOnClick: true,
          onClick: (params) => conf.onConfirm(params),
        },
      ],
      params: {
        ...conf.initialState,
      },
    });
  }
  success(conf: {
    title: string;
    message: string;
    buttonText?: string;
    onClose?: () => void;
    onOk?: () => void;
  }) {
    this.show({
      title: conf.title,
      size: "sm",
      content: (states: any, setStates: (states: any) => void) => (
        <SuccessModal message={conf.message} />
      ),
      buttons: [
        {
          appearance: "primary",
          text: conf.buttonText || i18n.t("Global.Buttons.ok"),
          onClick: () => {
            conf.onOk?.();
            conf.onClose?.();
          },
          closeOnClick: true,
        },
      ],
      onHide: conf.onClose,
    });
  }
  select(conf: {
    title?: string;
    message: string;
    optionsLabel?: string;
    defaultValue?: string;
    options: { value: string; label: string }[];
    optionsProps?: Partial<BFSelectProps>;
    onSelect: (value: string) => void;
    onAbort?: () => void;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onHide?: () => void;
  }) {
    const t = (window as any).translate;
    this.show({
      title: conf.title,
      content: (states: any, setStates: (states: any) => void) => (
        <div className="selection-default">
          <div className="message">{conf.message}</div>
          <div className={`selection`}>
            <BFSelect
              {...(conf.optionsProps || {})}
              data={conf.options}
              label={conf.optionsLabel}
              value={states.value}
              onChange={(value) => setStates({ value })}
            />
          </div>
          <DebugDataComponent data={states} />
        </div>
      ),
      onHide: conf.onHide,
      backdrop: "static",
      buttons: [
        {
          text: t(
            conf.cancelButtonText
              ? conf.cancelButtonText
              : "Global.Buttons.cancel"
          ),
          appearance: "outline",
          closeOnClick: true,
          onClick: conf.onAbort,
        },
        {
          text: t(
            conf.confirmButtonText
              ? conf.confirmButtonText
              : "Global.Buttons.ok"
          ),
          appearance: "primary",
          isEnabled: (states) => !!states.value,
          closeOnClick: true,
          onClick: (params) => conf.onSelect(params.value),
        },
      ],
      params: {
        value: conf.defaultValue,
      },
    });
  }

  date(conf: {
    title?: string;
    message: string;
    dateLabel?: string;
    defaultValue?: string;
    dateProps?: Partial<BFDateProps>;
    onSelect: (value: Date) => void;
    onAbort?: () => void;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onHide?: () => void;
    month?: boolean;
  }) {
    const t = (window as any).translate;
    this.show({
      title: conf.title,
      content: (states: any, setStates: (states: any) => void) => (
        <div className="selection-default">
          <div className="message">{conf.message}</div>
          <div className={`selection`}>
            {Boolean(conf.month) ? (
              <BFDate
                format="yyyy-MM"
                {...(conf.dateProps || {})}
                label={conf.dateLabel}
                value={states.value}
                onChange={(date) =>
                  setStates({
                    value: moment(date)
                      .utc(true)
                      .endOf("month")
                      .startOf("day")
                      .toDate(),
                  })
                }
              />
            ) : (
              <BFDatefield
                {...(conf.dateProps || {})}
                label={conf.dateLabel}
                value={states.value}
                onChange={(value) => setStates({ value })}
              />
            )}
          </div>
          <DebugDataComponent data={states} />
        </div>
      ),
      onHide: conf.onHide,
      backdrop: "static",
      buttons: [
        {
          text: t(
            conf.cancelButtonText
              ? conf.cancelButtonText
              : "Global.Buttons.cancel"
          ),
          appearance: "outline",
          closeOnClick: true,
          onClick: conf.onAbort,
        },
        {
          text: t(
            conf.confirmButtonText
              ? conf.confirmButtonText
              : "Global.Buttons.ok"
          ),
          appearance: "primary",
          isEnabled: (states) => !!states.value,
          closeOnClick: true,
          onClick: (params) => conf.onSelect(params.value),
        },
      ],
      params: {
        value: conf.defaultValue,
      },
    });
  }
  input(conf: {
    title?: string;
    message: string;
    inputLabel?: string;
    defaultValue?: string | number;
    inputProps?: Partial<BFInputProps>;
    onFinish: (value: string | number) => void;
    onAbort?: () => void;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onHide?: () => void;
    isEnabled?: (value: string | number) => boolean;
  }) {
    const t = (window as any).translate;
    this.show({
      onHide: conf.onHide,
      title: conf.title,
      content: (states: any, setStates: (states: any) => void) => (
        <div className="selection-default">
          <div className="message">{conf.message}</div>
          <div className={`selection`}>
            <BFInput
              {...(conf.inputProps || {})}
              focusOnMount
              value={states.value}
              onChange={(value) => setStates({ value })}
            />
          </div>
          <DebugDataComponent data={states} />
        </div>
      ),
      buttons: [
        {
          text: t(
            conf.cancelButtonText
              ? conf.cancelButtonText
              : "Global.Buttons.cancel"
          ),
          appearance: "outline",
          closeOnClick: true,
          onClick: conf.onAbort,
        },
        {
          text: t(
            conf.confirmButtonText
              ? conf.confirmButtonText
              : "Global.Buttons.ok"
          ),
          appearance: "primary",
          isEnabled: conf.isEnabled
            ? (states) => conf.isEnabled(states.value)
            : (states) => !!states.value,
          closeOnClick: true,
          onClick: (params) => conf.onFinish(params.value),
        },
      ],
      params: {
        value: hasValue(conf.defaultValue) ? conf.defaultValue : "",
      },
    });
  }

  alert(conf: { title: string; message: string; onHide?: () => void }) {
    const t = (window as any).translate;
    this.show({
      title: conf.title,
      content: conf.message,
      onHide: conf.onHide,
      buttons: [
        {
          appearance: "primary",
          text: t("Global.Buttons.ok"),
          closeOnClick: true,
        },
      ],
    });
  }

  confirm(conf: {
    title?: string | React.ReactNode;
    message: string | React.ReactNode;
    onConfirm: () => void;
    onAbort?: () => void;
    confirmButtonText?: string;
    cancelButtonText?: string;
    size?: "lg" | "md" | "sm" | "xs" | "xl" | "xxl" | "full";
  }) {
    const t = (window as any).translate;
    this.show({
      title: conf.title,
      content: conf.message,
      onHide: conf.onAbort,
      size: conf.size,
      buttons: [
        {
          appearance: "outline",
          text: t(
            conf.cancelButtonText
              ? conf.cancelButtonText
              : "Global.Buttons.cancel"
          ),
          closeOnClick: true,
          onClick: conf.onAbort,
        },
        {
          appearance: "primary",
          text: t(
            conf.confirmButtonText
              ? conf.confirmButtonText
              : "Global.Buttons.ok"
          ),
          closeOnClick: true,
          onClick: conf.onConfirm,
        },
      ],
    });
  }
}

const ModalManager = new ModalManagerClass();

export default ModalManager;

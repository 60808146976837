import classNames from "classnames";
import React, { useEffect } from "react";
import "wicg-inert";
import ConnectionLostAnimation from "../../components/LottieAnimations/ConnectionLost/ConnectionLostAnimation";
import ErrorAnimation from "../../components/LottieAnimations/ErrorAnimation/ErrorAnimation";
import i18n from "../../i18n";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import BFPortal from "../../modules/abstract-ui/general/Portal/BFPortal";
import { useTypedSelector } from "../../redux/hooks";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import "./ReconnectOverlay.scss";

interface ReconnectOverlayProps {}
const ReconnectOverlay = (props: ReconnectOverlayProps) => {
  const [show, setShow] = React.useState(false);

  const socketConnected = useTypedSelector<null | "reconnecting" | "failed">(
    (state) => state.uiConfig.general[DefaultUIConfigs.SOCKET_RECONNECT_STATE]
  );
  const user = useTypedSelector((state) => state.global.user);
  useEffect(() => {
    if (user && socketConnected !== null) setShow(true);
    else setShow(false);
  }, [user, socketConnected]);
  useEffect(() => {
    if (document.querySelector("#root")) {
      if (show) {
        document.querySelector("#root").setAttribute("inert", "");
      } else {
        document.querySelector("#root").removeAttribute("inert");
      }
    }
  }, [show]);
  if (!show) return null;

  return (
    <BFPortal>
      <div className={classNames(`reconnect-overlay`)}>
        {socketConnected === "reconnecting" && (
          <>
            <div className={`animation`}>
              <ConnectionLostAnimation
                loop
                style={{
                  width: 300,
                  height: 300,
                }}
              />
            </div>
            <div className={`text`}>
              {i18n.t(
                "ReconnectOverlay.reconnectingText",
                "Verbindung leider verloren. Wir versuchen Sie wieder herzustellen."
              )}
            </div>
          </>
        )}
        {socketConnected === "failed" && (
          <>
            <div className={`animation`} style={{ padding: 0 }}>
              <ErrorAnimation
                style={{
                  width: 300,
                  height: 300,
                }}
              />
            </div>
            <div className={`text`}>
              {i18n.t(
                "ReconnectOverlay.connectionFailedText",
                "Verbindung leider fehlgeschlagen. Laden Sie die Seite neu"
              )}
            </div>
            <div className={`action`} style={{ paddingTop: 20 }}>
              <BFButton
                size="lg"
                appearance="outline-white"
                onClick={() => window.location.reload()}
              >
                {i18n.t("ReconnectOverlay.reload", "Neu laden")}
              </BFButton>
            </div>
          </>
        )}
      </div>
    </BFPortal>
  );
};

export default ReconnectOverlay;

import classNames from "classnames";
import { ButtonGroup } from "rsuite";
import { PlacementAll } from "../../common/Placements";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import BFButton from "./BFButton";
import "./BFButtonToggle.scss";

interface Props {
  className?: string;
  value: any;
  onChange: (value: any) => void;
  noWrap?: boolean;
  size?: "lg" | "md" | "sm" | "xs";
  appearance?: "default" | "outline";
  buttons: {
    text?: string | (() => string);
    icon?: BfIconProps;
    className?: string;
    value: any;
    tooltip?: {
      tooltip: string;
      delay?: number; //	Delay Time
      delayHide?: number; //	Hidden delay Time
      delayShow?: number; //	Show Delay Time
      placement?: PlacementAll; // PlacementAll ('right')	Dispaly placement
      trigger?: "click" | "hover" | "focus" | "active"; //union: 'click', 'hover', 'focus', 'active' (['hover','focus'])
      container?: HTMLElement | (() => HTMLElement);
    };
  }[];
}

const BFButtonToggle = (props: Props) => {
  return (
    <ButtonGroup
      className={classNames("bf-button-group", props.className, {
        "no-wrap": props.noWrap,
      })}
    >
      {props.buttons.map((button, index) => (
        <BFButton
          appearance={props.appearance}
          size={props.size}
          key={index}
          className={`${button.value === props.value ? "active" : ""} ${
            button.className || ""
          }`}
          onClick={() => props.onChange(button.value)}
          tooltip={button.tooltip}
        >
          {button.icon && <BfIcon {...button.icon} />}
          {button.text
            ? typeof button.text === "function"
              ? button.text()
              : button.text
            : undefined}
        </BFButton>
      ))}
    </ButtonGroup>
  );
};
export default BFButtonToggle;

import { CDNBucketObject } from "@/services/CDNService";
import BaseAsset from "../../model/general-assets/BaseAsset";
import { DocumentStoreDocument } from "../document-store/DSInterfaces";

export enum MailOutgoingCommentStatus {
  "CREATED" = "CREATED",
  "SUCCESS" = "SUCCESS",
  "OPENED" = "OPENED",
  "FAILED" = "FAILED",
  "BOUNCED" = "BOUNCED",
}

export const CommentTypes = {
  INTERN: "intern",
  MAIL_INCOMING: "mail_incoming",
  MAIL_OUTGOING: "mail_outgoing",
  SYSTEM: "system",
  EPOST: "epost_pdf_create",
  MANUAL_PDF: "manual_pdf_create",
};
export type AttachmentDocument = {
  linkToCdn: string;
  name: string;
  cid?: string;
  contentId?: string;
};

export type EmailUser = {
  name: string;
  address: string;
};
type BaseCommentData = {
  linkedAsset: {
    id: string;
    type: string;
  };
  pin?: {
    userId: string;
    date: Date;
  };
  tags?: string[];
  subactivityId?: number;
  message: string;
  contentId?: string;
  // originalMessage: string;
  attachments: DocumentStoreDocument[];
  date: Date;
  mentions: {
    user: string[];
    teams: string[];
  };
  archived?: {
    reason: string;
    userId: string;
  };
};
type InternCommentData = BaseCommentData & {
  type: "intern";
  typeData: {
    userId: string;
  };
};
type MailIncomingCommentData = BaseCommentData & {
  type: "mail_incoming";
  typeData: {
    unassignedData?: {
      type: string;
      activityType: string;
    }[];

    sender: EmailUser;
    subject: string;
    recipients?: EmailUser[];
    replyTo?: string;
    cc?: EmailUser[];
    assignedUser?: string;
    assignedFolder?: string;
    readBy: { user: string; date: Date }[];
  };
};
type MailOutgoingCommentData = BaseCommentData & {
  type: "mail_outgoing";
  typeData: {
    senderUserId: string;
    recipients: EmailUser[];
    subject: string;
    cc?: EmailUser[];
    bcc?: EmailUser[];
    userId: string;
    status: MailOutgoingCommentStatus;
  };
};
type SystemCommentData = BaseCommentData & {
  type: "system";
  typeData: {
    userId?: string;

    message?: string;
    extra?: string | any;
  };
};

type ManualPDFCommentData = BaseCommentData & {
  type: "manual_pdf_create";
  typeData: {
    downloadStatus: boolean;
    status: "CREATED" | "SUCCESS" | "FAILED";
    userId?: string;

    file?: CDNBucketObject;
  };
};

type EpostPDFCommentData = BaseCommentData & {
  type: "epost_pdf_create";
  typeData: {
    downloadStatus: boolean;
    status: "CREATED" | "SUCCESS" | "FAILED";
    userId?: string;

    file?: CDNBucketObject;
  };
  ePostStatus?: {
    desc?: string;
    status?: number;
  };
};

interface BaseComment<T> extends BaseAsset {
  _id: string;
  data: T;
  cdnHtml?: string;
}

export type EpostPDFComment = BaseComment<EpostPDFCommentData>;
export type ManualPDFComment = BaseComment<ManualPDFCommentData>;
export type InternComment = BaseComment<InternCommentData>;
export type MailIncomingComment = BaseComment<MailIncomingCommentData>;
export type MailOutgoingComment = BaseComment<MailOutgoingCommentData>;
export type SystemComment = BaseComment<SystemCommentData>;

export type Comment =
  | ManualPDFComment
  | InternComment
  | EpostPDFComment
  | MailIncomingComment
  | MailOutgoingComment
  | SystemComment;

export enum SystemMessages {
  DAMAGECLAIM_STATUS_CHANGE = "DAMAGECLAIM_STATUS_CHANGE",
  DAMAGECLAIM_ACTIVITY_CREATED = "DAMAGECLAIM_ACTIVITY_CREATED",
  DAMAGECLAIM_OFFER_STARTED = "DAMAGECLAIM_OFFER_STARTED",

  ACTIVITY_CREATED = "ACTIVITY_CREATED",
  ACTIVITY_OFFER_STARTED = "ACTIVITY_OFFER_STARTED",
  ACTIVITY_STATUS_CHANGE = "ACTIVITY_STATUS_CHANGE",
}

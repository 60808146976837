import { useTypedSelector } from "@/redux/hooks";
import { DefaultUIConfigs } from "@/redux/reducers/ui-config/UiConfig";
import DataBusDefaults from "@/services/DataBusDefaults";
import classNames from "classnames";
import i18n from "../../../../i18n";
import LanguageService from "../../../../services/LanguageService";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import BFStatus from "../status/BFStatus";
import "./BFIconDetails.scss";
import { IconAsset, IconFamilyAsset } from "./BFIconInterfaces";

interface BFIconDetailsProps {
  icon: IconAsset;
  familyConfig: IconFamilyAsset;
  onCategoryChange: (category: string) => void;
  onTagClick: (tag: string) => void;
}
const BFIconDetails = (props: BFIconDetailsProps) => {
  const category = props.familyConfig.data.categories.find(
    (category) => category.category === props.icon.data.category
  );
  const subCategory = category?.subCategories.find(
    (subCategory) => subCategory.subCategory === props.icon.data.subCategory
  );

  const debugActive = useTypedSelector(
    (state) => state.uiConfig.general[DefaultUIConfigs.DEBUG]
  );

  const addIconToClipboard = () => {
    const pickedIcon = {
      type: props?.icon?.data?.family || "-",
      data: props?.icon?.data?.fileName || "-",
    };
    const jsonString = JSON.stringify(pickedIcon, null, 2);
    navigator.clipboard
      .writeText(jsonString)
      .then(() => {
        DataBusDefaults.toast({
          type: "success",
          text: i18n.t(
            "BFComponents.IconPicker.CopiedToClipBoard",
            "Icon Daten in die Zwischenablage kopiert."
          ),
        });
      })
      .catch((err) => {
        console.error("Failed to copy icon data to clipboard", err);
      });
  };
  return (
    <div className={classNames(`bf-icon-details`)}>
      <div className={`icon-container`}>
        <BfIcon
          type={props.icon.data.family}
          data={props.icon.data.fileName}
          size="5x"
        />
      </div>
      <div className={`name`}>{props.icon.data.name}</div>
      <div className={`category`}>
        <div className={`__h4`}>
          {i18n.t("BFComponents.IconPicker.Category", "Kategorie")}
        </div>
        <BFButton
          appearance="link"
          noPadding
          onClick={() => props.onCategoryChange(`CAT_${category.category}`)}
        >
          {LanguageService.translateLabel(category.displayName)}
        </BFButton>
      </div>
      <div className={`category`}>
        <div className={`__h4`}>
          {i18n.t("BFComponents.IconPicker.SubCategory", "Unterkategorie")}
        </div>
        <BFButton
          appearance="link"
          noPadding
          onClick={() =>
            props.onCategoryChange(`SUB_${subCategory.subCategory}`)
          }
        >
          {LanguageService.translateLabel(subCategory.displayName)}
        </BFButton>
      </div>
      <div className={`tags`}>
        <div className={`__h4`}>
          {i18n.t("BFComponents.IconPicker.Tags", "Tags")}
        </div>
        <div className={`tag-list`}>
          {props.icon.data.tags.map((tag) => (
            <BFStatus
              label={tag}
              onClick={() => {
                props.onTagClick(tag);
              }}
            />
          ))}
        </div>
        {debugActive && (
          <div>
            <div className="tags">
              <div className={`__h4`}>
                {i18n.t("BFComponents.IconPicker.FileName", "File Name")}
              </div>
              <BFButton
                noPadding
                appearance="link"
                onClick={() => {
                  addIconToClipboard();
                }}
              >
                {props?.icon?.data?.fileName || "-"}
              </BFButton>
            </div>
            <div className="tags">
              <div className={`__h4`}>
                {i18n.t("BFComponents.IconPicker.Type", "Type")}
              </div>
              <BFButton
                noPadding
                appearance="link"
                onClick={() => {
                  addIconToClipboard();
                }}
              >
                {props?.icon?.data?.family || "-"}
              </BFButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BFIconDetails;

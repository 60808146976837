import ContactDrawer from "./ContactDrawer";
// import './ContactModule.scss';

interface ContactModuleProps {}
const ContactModule = (props: ContactModuleProps) => {
  return (
    <>
      <ContactDrawer />
      {/* <ModalRouteView routeName="__contact" size="lg">
        <ContactLoaderModal />
      </ModalRouteView> */}
    </>
  );
};

export default ContactModule;

import i18n from "../../../i18n";
import BFButton from "../../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../../modules/abstract-ui/icon/BfIcon";
import { useTypedSelector } from "../../../redux/hooks";
import DataBus from "../../../services/DataBus";
import DataBusDefaults from "../../../services/DataBusDefaults";
import { DATABUS_OPEN_EXPORT_DIALOG_INFINITE_TABLE } from "./VirtualizedTable";
// import './VirtualizedTableExportButton.scss';

const DEFAULT_MAX_DATA = 1500;
interface VirtualizedTableExportButtonProps {
  identifier: string;
  managedData?: boolean;
  maxData?: number;
  appearance?:
    | "default"
    | "primary"
    | "link"
    | "jump"
    | "clear"
    | "outline"
    | "outline-white"
    | "clear-on-white"
    | "clear-highlight"
    | "transparent-dark"
    | "transparent-light"
    | "debug";
}
const VirtualizedTableExportButton = (
  props: VirtualizedTableExportButtonProps
) => {
  const tableState = useTypedSelector(
    (state) => state.application.infiniteTables[props.identifier]
  );

  return (
    <BFButton
      appearance={props.appearance || "clear-on-white"}
      onClick={() => {
        if (props.managedData) {
          DataBus.emit(DATABUS_OPEN_EXPORT_DIALOG_INFINITE_TABLE, {
            identifier: props.identifier,
          });
        } else {
          if (tableState.totalCount > (props.maxData || DEFAULT_MAX_DATA)) {
            DataBusDefaults.toast({
              type: "warning",
              text: i18n.t(
                "ExportDialog.MaxDataWarning",
                "Sie können maximal {{maxData}} Datensätze exportieren. Bitte filtern Sie die Daten und versuchen den Export erneut.",
                { maxData: props.maxData || DEFAULT_MAX_DATA }
              ),
            });
          } else {
            DataBus.emit(DATABUS_OPEN_EXPORT_DIALOG_INFINITE_TABLE, {
              identifier: props.identifier,
            });
          }
        }
      }}
    >
      <BfIcon type="light" data="share-3" size="xxs" />
    </BFButton>
  );
};

export default VirtualizedTableExportButton;

import BSComponent from "./BSComponent/BSComponent";
import CelebrationComponent from "./CelebrationComponent/CelebrationComponent";
import "./NotSeriousContainer.scss";

const NotSeriousContainer = () => {
  return (
    <div className={`ns-container`}>
      <BSComponent />
      <CelebrationComponent />
    </div>
  );
};
export default NotSeriousContainer;

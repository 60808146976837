import { Field, FormRenderProps } from "react-final-form";
import { LoanDataFields } from "../../../apps/tatar/cashBudget/views/portfolio/loans/CBLoanUtils";
import FormStruct from "../../../components/Form/FormStruct/FormStruct";
import i18n from "../../../i18n";
import { hasValue, isDefined } from "../../../utils/Helpers";
import StringUtils from "../../../utils/StringUtils";
import BFCheckbox from "../forms/checkbox/BFCheckbox";
import "./BFLoanConfirmDialog.scss";

interface BFLoanConfirmDialogProps {
  onClose: () => void;
  fields: LoanDataFields;
  oldData: LoanDataFields;
  onSubmit: (fields: Partial<LoanDataFields>) => void;
}
const BFLoanConfirmDialog = (props: BFLoanConfirmDialogProps) => {
  return (
    <FormStruct<any>
      className={`bf-loan-confirm-dialog`}
      title={i18n.t(
        "BFLoanData.OverwriteDialog.Title",
        "Darlehensdaten ändern"
      )}
      submitText={i18n.t(
        "BFLoanData.OverwriteDialog.Submit",
        "Daten übernehmen"
      )}
      description={i18n.t(
        "BFLoanData.OverwriteDialog.Description",
        "Wir haben folgende Daten aus der CSV Datei erkannt. Bitte überprüfen Sie diese und bestätigen Sie, welche der Daten sie übernehmen wollen."
      )}
      initialValues={{
        loanAmount: isDefined(props.oldData.loanAmount)
          ? null
          : props.fields.loanAmount,
        paymentAmount: isDefined(props.oldData.paymentAmount)
          ? null
          : props.fields.paymentAmount,
        loanPayoutDate: isDefined(props.oldData.loanPayoutDate)
          ? null
          : props.fields.loanPayoutDate,
        loanStartDate: isDefined(props.oldData.loanStartDate)
          ? null
          : props.fields.loanStartDate,
        annualInterest: isDefined(props.oldData.annualInterest)
          ? null
          : props.fields.annualInterest,
        loanTermYears: isDefined(props.oldData.loanTermYears)
          ? null
          : props.fields.loanTermYears,
      }}
      render={(formProps: FormRenderProps<Partial<LoanDataFields>>) => {
        return (
          <>
            {hasValue(props.fields.loanAmount) && (
              <div className={`__field`}>
                <Field name="loanAmount" allowNull>
                  {({ input, meta }) => (
                    <BFCheckbox
                      checked={input.value !== null}
                      onChange={(_val, checked) =>
                        input.onChange(checked ? props.fields.loanAmount : null)
                      }
                    >
                      <div>
                        {i18n.t(
                          "BFLoanData.OverwriteDialog.LoanAmount",
                          "Darlehensbetrag"
                        )}
                        {isDefined(props.oldData.loanAmount) && (
                          <span className={`current`}>
                            {StringUtils.formatCurrency(
                              props.oldData.loanAmount
                            )}
                          </span>
                        )}
                        <span className={`value-field`}>
                          {StringUtils.formatCurrency(props.fields.loanAmount)}
                        </span>
                      </div>
                    </BFCheckbox>
                  )}
                </Field>
              </div>
            )}
            {hasValue(props.fields.paymentAmount) && (
              <div className={`__field`}>
                <Field name="paymentAmount" allowNull>
                  {({ input }) => (
                    <BFCheckbox
                      checked={input.value !== null}
                      onChange={(_val, checked) =>
                        input.onChange(
                          checked ? props.fields.paymentAmount : null
                        )
                      }
                    >
                      <div>
                        {i18n.t(
                          "BFLoanData.OverwriteDialog.PaymentAmount",
                          "Monatliche Zahlung"
                        )}
                        {isDefined(props.oldData.paymentAmount) && (
                          <span className={`current`}>
                            {StringUtils.formatCurrency(
                              props.oldData.paymentAmount
                            )}
                          </span>
                        )}

                        <span className={`value-field`}>
                          {StringUtils.formatCurrency(
                            props.fields.paymentAmount
                          )}
                        </span>
                      </div>
                    </BFCheckbox>
                  )}
                </Field>
              </div>
            )}

            {hasValue(props.fields.loanPayoutDate) && (
              <div className={`__field`}>
                <Field name="loanPayoutDate" allowNull>
                  {({ input, meta }) => (
                    <BFCheckbox
                      checked={input.value !== null}
                      onChange={(_val, checked) =>
                        input.onChange(
                          checked ? props.fields.loanPayoutDate : null
                        )
                      }
                    >
                      <div>
                        {i18n.t(
                          "BFLoanData.OverwriteDialog.LoanPayoutDate",
                          "Auszahlungsdatum"
                        )}
                        {isDefined(props.oldData.loanPayoutDate) && (
                          <span className={`current`}>
                            {StringUtils.formatDate(
                              props.oldData.loanPayoutDate
                            )}
                          </span>
                        )}
                        <span className={`value-field`}>
                          {StringUtils.formatDate(props.fields.loanPayoutDate)}
                        </span>
                      </div>
                    </BFCheckbox>
                  )}
                </Field>
              </div>
            )}

            {hasValue(props.fields.loanStartDate) && (
              <div className={`__field`}>
                <Field name="loanStartDate" allowNull>
                  {({ input, meta }) => (
                    <BFCheckbox
                      checked={input.value !== null}
                      onChange={(_val, checked) =>
                        input.onChange(
                          checked ? props.fields.loanStartDate : null
                        )
                      }
                    >
                      <div>
                        {i18n.t(
                          "BFLoanData.OverwriteDialog.LoanStartDate",
                          "Darlehensbeginn"
                        )}
                        {isDefined(props.oldData.loanStartDate) && (
                          <span className={`current`}>
                            {StringUtils.formatDate(
                              props.oldData.loanStartDate
                            )}
                          </span>
                        )}
                        <span className={`value-field`}>
                          {StringUtils.formatDate(props.fields.loanStartDate)}
                        </span>
                      </div>
                    </BFCheckbox>
                  )}
                </Field>
              </div>
            )}

            {hasValue(props.fields.annualInterest) && (
              <div className={`__field`}>
                <Field name="annualInterest" allowNull>
                  {({ input, meta }) => (
                    <BFCheckbox
                      checked={input.value !== null}
                      onChange={(_val, checked) =>
                        input.onChange(
                          checked ? props.fields.annualInterest : null
                        )
                      }
                    >
                      <div>
                        {i18n.t(
                          "BFLoanData.OverwriteDialog.AnnualInterest",
                          "Sollzins"
                        )}
                        {isDefined(props.oldData.annualInterest) && (
                          <span className={`current`}>
                            {StringUtils.formatPercent(
                              props.oldData.annualInterest
                            )}
                          </span>
                        )}
                        <span className={`value-field`}>
                          {StringUtils.formatPercent(
                            props.fields.annualInterest
                          )}
                        </span>
                      </div>
                    </BFCheckbox>
                  )}
                </Field>
              </div>
            )}

            {hasValue(props.fields.loanTermYears) && (
              <div className={`__field`}>
                <Field name="loanTermYears" allowNull>
                  {({ input, meta }) => (
                    <BFCheckbox
                      checked={input.value !== null}
                      onChange={(_val, checked) =>
                        input.onChange(
                          checked ? props.fields.loanTermYears : null
                        )
                      }
                    >
                      <div>
                        {i18n.t(
                          "BFLoanData.OverwriteDialog.LoanTermYears",
                          "Laufzeit Jahre"
                        )}
                        {isDefined(props.oldData.loanTermYears) && (
                          <span className={`current`}>
                            {props.oldData.loanTermYears}
                          </span>
                        )}
                        <span className={`value-field`}>
                          {props.fields.loanTermYears}
                        </span>
                      </div>
                    </BFCheckbox>
                  )}
                </Field>
              </div>
            )}
          </>
        );
      }}
      onSubmit={async (data: Partial<LoanDataFields>) => {
        props.onSubmit(data);
      }}
      onAbort={(form: FormRenderProps<Partial<LoanDataFields>>) => {
        props.onClose();
      }}
    />
  );
};

export default BFLoanConfirmDialog;

import { useLottie } from "lottie-react";
import { CSSProperties } from "react";
import scanAnimation from "./scanAnimation.json";
interface Props {
  style?: CSSProperties;
}
const DocumentScanAnimation = (props: Props) => {
  const LoadingAnimation = useLottie(
    {
      animationData: scanAnimation,
      loop: true,
    },
    props.style
  );
  return LoadingAnimation.View;
};
export default DocumentScanAnimation;

import i18n from "../../../i18n";
import BFDropdown from "../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../../../modules/abstract-ui/icon/BfIcon";
import "./BaseTableSort.scss";

type SortDirection = "asc" | "desc";

export type InfiniteTableSortDef = {
  dir: SortDirection;
  key: string;
};

export type LocalTableSortDef = {
  sortType: SortDirection;
  dataKey: string;
};

type TableSortDef = InfiniteTableSortDef | LocalTableSortDef;

type BaseTableSortOption = {
  label: () => string;
  icon: string;
};

export type InfiniteTableSortOption = {
  definition: InfiniteTableSortDef;
} & BaseTableSortOption;

export type LocalTableSortOption = {
  definition: LocalTableSortDef;
} & BaseTableSortOption;

type TableSortOption = InfiniteTableSortOption | LocalTableSortOption;

type BaseTableSortProps = {
  options: TableSortOption[];
  identifier: string;
  selected: BaseTableSortOption;
  onSelect: (sort: TableSortDef) => void;
};

const BaseTableSort = (props: BaseTableSortProps) => {
  return (
    <div className="base-table-sort">
      <BFDropdown
        label={
          <div className={`sort-label`}>
            {props.selected && (
              <BfIcon data={props.selected.icon} type="light" size="xs" />
            )}
            {props.selected?.label() || i18n.t("Global.Sort", "Sortieren")}
          </div>
        }
        placement="bottomEnd"
        items={props.options.map((e) => ({
          type: "button",
          icon: {
            type: "light",
            data: e.icon,
            size: "xs",
          },
          text: e.label(),
          onSelect: () => props.onSelect(e.definition),
        }))}
      />
    </div>
  );
};

export default BaseTableSort;

import { useCallback, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { useDispatch } from "react-redux";
import { AdminUser } from "../../../apps/administration/AdminInterfaces";
import Log from "../../../debug/Log";
import i18n from "../../../i18n";
import { ListResponse } from "../../../model/common/HttpModel";
import DataBusDefaults from "../../../services/DataBusDefaults";
import { requestListData } from "../../../services/DataService";

import Collapse from "rsuite/esm/Animation/Collapse";
import BFCheckbox from "../../abstract-ui/forms/checkbox/BFCheckbox";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import "./CommentInput.scss";
import EmailAddressFields, {
  EmailAddressFieldModel,
} from "./EmailAddressFields";

import _ from "lodash";
import { Trans } from "react-i18next";
import { Signature, Textblock } from "../../../model/db/UserConfigAsset";
import { CommunicationSubActivity } from "../../../model/general-assets/BaseAsset";
import { useDatabus } from "../../../redux/hooks";
import { DataBusSubKeys, UserStatus } from "../../../utils/Constants";
import { useGlobalKeyEvent } from "../../../utils/Hooks";
import BFDropdown from "../../abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../../abstract-ui/icon/BfIcon";
import TextEditor, { MentionItem } from "../../ckeditor/TextEditor";
import EZAutocomplete from "../../ez-form/form-elements/ez-autocomplete/EZAutocomplete";
import Validators from "../../generic-forms/util/Validatos";

import { Editor } from "ckeditor5/src/core";
import Fade from "rsuite/esm/Animation/Fade";
import LoadPage from "../../../components/LoadPage/LoadPage";
import IndexDBService from "../../../services/IndexDBService";
import StringUtils from "../../../utils/StringUtils";
import BFDropzone from "../../abstract-ui/dropzone/BFDropzone";
import { DropdownItem } from "../../abstract-ui/general/Dropdown/BFDropdownContent";
import CommentInputAttachments from "./CommentInputAttachments";

const FORBIDDEN_FILETYPES = [
  "vbs",
  "exe",
  "bin",
  "bat",
  "chm",
  "com",
  "cpl",
  "crt",
  "hlp",
  "hta",
  "inf",
  "ins",
  "isp",
  "jse",
  "lnk",
  "mdb",
  "pcd",
  "pif",
  "reg",
  "scr",
  "sct",
  "shs",
  "vbe",
  "vba",
  "wsf",
  "wsh",
  "wsl",
  "msc",
  "msi",
  "msp",
  "ms",
];
const REGEX_SIGNATURE = /<div class="iberio-signature">(.|\n)*?<\/div>/;
const REGEX_BLOCKQUOTE = /<blockquote(.|\n)*?<\/blockquote>/g;

const SAVE_DRAFT_TIMEOUT = 2500;
export type CommentAttachmentEntry = {
  cdnId: string;
  filename: string;
  fromtAssetId?: string;
};

interface CommentInputProps {
  resizeImages?: [number, number];
  placeholder?: string;
  allowMentions?: boolean;
  disableAfterMS?: number;
  identifier?: string;
  onlyAsMail?: boolean;
  subactivity?: "allow" | "force" | "force-on-mail";
  possibleContexts?: CommunicationSubActivity[];
  signatures?: Signature[];
  defaultSignature?: number;
  onlyActive?: boolean;
  textblocks?: Textblock[];
  onSend: (
    comment: string,
    mailAddressFields: EmailAddressFieldModel,
    subactivity?: string,
    attachments?: CommentAttachmentEntry[]
  ) => Promise<void>;
  onActiveChange?: (active: boolean) => void;
  onCancel?: () => void;
  localStorageIdentifier?: string;
  linkedAsset?: {
    assetId: string;
    assetType: string;
  };
  disableEmailCommunication?: boolean;
  type: string;
  onDraftLoaded?: () => void;
}
const CommentInput = (props: CommentInputProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const saveData = useRef({});
  const saveDraftTimeout = useRef(null);
  const timeoutRef = useRef(null);
  const editorRef = useRef<Editor>(null);
  const containerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [emailAddressFields, setEmailAddressFields] =
    useState<EmailAddressFieldModel>({
      recipients: [],
      cc: [],
      bcc: [],
      subject: "",
    });
  const [contextError, setContextError] = useState<string>(null);
  const [sendAsMail, setSendAsMail] = useState<boolean>(
    props.onlyAsMail ? true : false
  );
  const [subactivity, setSubactivity] = useState<string>(null);
  const [active, setActive] = useState<boolean>(props.onlyActive || false);
  const [activeByAction, setActiveByAction] = useState<boolean>(false);
  const [attachmentCDNIds, setAttachmentCDNIds] = useState<
    CommentAttachmentEntry[]
  >([]);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);

  const [disableOverflowHidden, setDisableOverflowHidden] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const cancel = useRef({
    userCancel: { cancel: null },
  });
  useEffect(() => {
    setContextError(null);
  }, [subactivity]);

  useEffect(() => {
    props.onActiveChange?.(active);
  }, [active]);
  useDatabus(
    DataBusSubKeys.COMMENT_INPUT_INIT,
    ({
      identifier,
      comment,
      sendAsMail,
      emailAddressFields,
      subactivity,
      cursorPosition,
      attachments,
    }) => {
      if (identifier === props.identifier) {
        editorRef.current?.data?.set(comment);
        if (emailAddressFields) {
          setEmailAddressFields(emailAddressFields);
        }
        if (typeof subactivity === "string") {
          setSubactivity(subactivity);
        } else if (typeof subactivity === "number") {
          setSubactivity(
            props.possibleContexts.find((e) => e.id === subactivity)
              ?.displayName
          );
        }
        if (props.onlyAsMail) {
          setSendAsMail(true);
        } else {
          setSendAsMail(sendAsMail || false);
        }

        setAttachmentCDNIds(attachments || []);

        // TODO SJ turn feature off, backend is missing some ids for attachments
        // See ticket #158 in donedone
        // ADD Attachments
        // if (props.onlyAsMail || sendAsMail) {
        //   if (attachments && attachments.length > 0) {
        //     setAttachmentCDNIds(attachments);
        //   }
        // }

        if (["start", "end"].includes(cursorPosition)) {
          editorRef.current?.model.change((writer) => {
            writer.setSelection(
              writer.createPositionAt(
                editorRef.current?.model.document.getRoot(),
                cursorPosition === "start" ? 0 : "end"
              )
            );
            editorRef.current?.editing.view.scrollToTheSelection();
            editorRef.current?.editing.view.focus();
          });
          // editorRef.current.editing.view.focus();
          // editorRef.current.editing.view.scrollTo(cursorPosition);
        }
        // editorRef.current.data.set(comment);
        setActive(true);
      }
    }
  );
  const timeout = useRef<NodeJS.Timeout>(null);

  useGlobalKeyEvent(["Escape"], (ev) => {
    if (ev.key === "Escape" && isFullscreen) {
      setIsFullscreen(false);
    }
  });
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (active) {
      timeoutRef.current = setTimeout(() => {
        setDisableOverflowHidden(true);
      }, 400);
    } else {
      setDisableOverflowHidden(false);
    }
  }, [active]);

  useEffect(() => {
    if (props.localStorageIdentifier) {
      setLoading(true);
      IndexDBService.getCommentDraft(props.localStorageIdentifier).then(
        (draft) => {
          if (draft) {
            const emailAddressFields = draft.data?.emailAddressFields
              ? {
                  ...draft.data?.emailAddressFields,
                  recipients: (
                    draft.data?.emailAddressFields.recipients || []
                  ).filter((e) => e),
                  cc: (draft.data?.emailAddressFields.cc || []).filter(
                    (e) => e
                  ),
                  bcc: (draft.data?.emailAddressFields.bcc || []).filter(
                    (e) => e
                  ),
                }
              : undefined;

            setEmailAddressFields(emailAddressFields);
            setSendAsMail(sendAsMail);
            if (props.onlyAsMail) {
              setSendAsMail(true);
            } else {
              setSendAsMail(draft.data?.sendAsMail);
            }
            setSubactivity(draft.data?.subactivity);
            setAttachmentCDNIds(draft.data?.attachmentCDNIds);
            setInitialComment(draft.content);

            DataBusDefaults.toast({
              type: "info",
              text: i18n.t(
                "CommentInput.draftLoaded",
                "Entwurf geladen vom {{date}}",
                { date: StringUtils.formatDate(draft.timestamp, "datetime") }
              ),
            });
            props.onDraftLoaded?.();

            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      );
    } else {
      setLoading(false);
    }
  }, [props.localStorageIdentifier]);

  useEffect(() => {
    saveData.current = {
      emailAddressFields,
      sendAsMail,
      subactivity,
      attachmentCDNIds,
    };
  }, [emailAddressFields, sendAsMail, subactivity, attachmentCDNIds]);

  useEffect(() => {
    return () => {
      // on unnount
      triggerCommentDraft(true);
    };
  }, []);

  useEffect(() => {
    triggerCommentDraft();
  }, [emailAddressFields, sendAsMail, subactivity, attachmentCDNIds]);

  const triggerCommentDraft = (force = false) => {
    if (saveDraftTimeout.current) {
      clearTimeout(saveDraftTimeout.current);
    }
    if (force) {
      saveCommentDraft().then(() => {
        Log.info("comment draft saved");
      });
    } else {
      saveDraftTimeout.current = setTimeout(() => {
        saveCommentDraft().then(() => {
          Log.info("comment draft saved");
        });
      }, SAVE_DRAFT_TIMEOUT);
    }
  };

  const saveCommentDraft = async () => {
    if (props.localStorageIdentifier) {
      if (editorRef?.current?.state === "ready") {
        const text = editorRef.current?.data.get();
        const dataToSave = saveData.current as any;
        if (text) {
          // dispatch(
          //   Customization.setCommentDraft(props.localStorageIdentifier, {
          //     assetId: props.linkedAsset?.assetId,
          //     assetType: props.linkedAsset?.assetType,
          //     text,
          //     ...dataToSave,
          //   })
          // );
          await IndexDBService.upsertCommentDraft(
            props.localStorageIdentifier,
            {
              content: text,
              data: dataToSave,
              assetId: props.linkedAsset?.assetId,
              assetType: props.linkedAsset?.assetType,
            }
          );
          setInitialComment(text);
        } else {
          await IndexDBService.removeCommentDraft(props.localStorageIdentifier);
          // dispatch(
          //   Customization.removeCommentDraft(props.localStorageIdentifier)
          // );
          setInitialComment("");
        }
      }
    }
  };

  const onSendAsMailChange = (sendAsMail: boolean) => {
    setSendAsMail(sendAsMail);
    if (active) {
      if (sendAsMail) {
        if (
          props.defaultSignature !== -1 &&
          props.defaultSignature !== undefined
        ) {
          setSignature(
            props.signatures[props.defaultSignature].signature,
            true
          );
        }
      } else {
        setSignature("");
      }
    }
  };

  const mentionSource = useCallback(async (searchTerm: string) => {
    const users = await onSearch(searchTerm);

    return users;
  }, []);

  const [initialComment, setInitialComment] = useState<string>("");

  // Set input active by actions / not by the input field itself
  useEffect(() => {
    setActive(activeByAction);
  }, [activeByAction]);

  const onSearch = (seachTerm: string) => {
    return new Promise<MentionItem[]>((resolve, reject) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      if (cancel.current.userCancel.cancel) {
        cancel.current.userCancel.cancel();
      }
      timeout.current = setTimeout(() => {
        dispatch(
          requestListData(
            {
              identifier: "user-search",
              limit: 15,
              url: "/api/user",
              textQuery: seachTerm,
              matchQuery: {
                type: "op",
                name: "status",
                value: UserStatus.ACTIVE,
                op: "eq",
              },
              onSuccess: (data: ListResponse<AdminUser>) => {
                resolve(
                  data.data.map((user) => ({
                    id: user._id,
                    type: "user",
                    name: user.displayname,
                  }))
                );
              },
              onError: (error) => {
                Log.error("Error while loading users", error);
                //   setUserLoading(false);
                DataBusDefaults.toast({
                  type: "error",
                  text: i18n.t(
                    "CommentInput.userMentionError",
                    "Fehler beim Laden der Benutzer"
                  ),
                });
              },
            },
            cancel.current.userCancel
          )
        );
      }, 300);
    });
  };

  const sendDisabled =
    !active ||
    (sendAsMail &&
      (!emailAddressFields.subject ||
        emailAddressFields.recipients.length === 0 ||
        [
          ...(emailAddressFields.recipients || []),
          ...(emailAddressFields.cc || []),
          ...(emailAddressFields.bcc || []),
        ].filter((e) => e && !Validators.email(e.address, null, null)).length >
          0));

  const close = async () => {
    setActiveByAction(false);
    setIsFullscreen(false);
    setSubactivity(null);
    setSendAsMail(props.onlyAsMail || false);
    setContextError(null);
    setAttachmentCDNIds([]);
    setUploadFiles([]);
    setEmailAddressFields({
      recipients: [],
      cc: [],
      bcc: [],
      subject: "",
    });
    editorRef.current?.data.set("");
    if (props.localStorageIdentifier) {
      await IndexDBService.removeCommentDraft(props.localStorageIdentifier);
    }
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const setSignature = (signature: string, setToEnd?: boolean) => {
    const text = editorRef.current?.data.get();
    let newText;
    const strippedText = text.replace(REGEX_BLOCKQUOTE, "");

    if (strippedText.match(REGEX_SIGNATURE)) {
      // replacing the first occurance of signature - stripped text checks is there is one without blockquotes
      newText = text.replace(
        REGEX_SIGNATURE,
        `<div class="iberio-signature"></br></br>${signature}</div>`
      );
      editorRef.current?.data.set(newText);
    } else {
      if (setToEnd) {
        editorRef.current.model.change((writer) => {
          writer.setSelection(
            writer.createPositionAt(
              editorRef.current.model.document.getRoot(),
              "end"
            )
          );
        });
        const currentPosition =
          editorRef.current.model.document.selection.getFirstPosition();
        var viewFragment = editorRef.current.data.processor.toView(
          `<div class="iberio-signature"></br></br>${signature}</div>`
        );
        var modelFragment = editorRef.current.data.toModel(viewFragment);

        editorRef.current.model.insertContent(modelFragment, currentPosition);
      } else {
        const currentPosition =
          editorRef.current.model.document.selection.getFirstPosition();

        editorRef.current.model.change((writer) => {
          var viewFragment = editorRef.current.data.processor.toView(
            `<div class="iberio-signature"></br></br>${signature}</div>`
          );
          var modelFragment = editorRef.current.data.toModel(viewFragment);

          editorRef.current.model.insertContent(modelFragment, currentPosition);
        });
      }
    }
  };

  if (loading) {
    return <LoadPage size="md" />;
  }
  return (
    <BFDropzone
      multipe={true}
      validator={(file) => {
        if (
          file.name &&
          FORBIDDEN_FILETYPES.includes(file.name.split(".").pop() || "")
        ) {
          return i18n.t(
            "CommentInput.invalidFileExtension",
            "Dateityp nicht erlaubt"
          );
        }

        return null;
      }}
      className={`comment-input-dropzone`}
      onDrop={(acceptedFiles, rejectedFiles) => {
        if (!sendAsMail) {
          DataBusDefaults.toast({
            type: "warning",
            text: i18n.t(
              "CommentInput.dropzoneDisabled",
              "Dateien können nur im E-Mail Modus angehängt werden"
            ),
          });
        } else {
          if (acceptedFiles.length > 0 && rejectedFiles.length === 0) {
            setUploadFiles(acceptedFiles);
          } else {
            DataBusDefaults.toast({
              type: "warning",
              text: i18n.t(
                "CommentInput.dropzoneError",
                "Datei konnte nicht angehängt werden"
              ),
            });
          }
        }
      }}
      render={(open) => (
        <div
          className={classNames(`comment-input-wrapper`, {
            fullscreen: isFullscreen,
          })}
        >
          <div
            key="comment-input"
            ref={containerRef}
            className={classNames(`comment-input`, {
              active,
              disableOverflowHidden,
              "send-as-mail": sendAsMail,
            })}
            onBlur={(event) => {}}
          >
            <Collapse in={(active || isFullscreen) && sendAsMail}>
              <div>
                <EmailAddressFields
                  value={emailAddressFields}
                  onChange={setEmailAddressFields}
                  type={props.type}
                />
              </div>
            </Collapse>
            {!props.disableEmailCommunication && (
              <Fade in={!active}>
                <div className="mail-button-container">
                  <BFButton
                    appearance="link"
                    icon={{
                      type: "light",
                      size: "sm",
                      data: "email-action-edit",
                    }}
                    tooltip={{
                      tooltip: i18n.t(
                        "TextEditor.MailButtonTooltip",
                        "E-Mail schreiben"
                      ),
                    }}
                    onClick={() => {
                      setSendAsMail(true);
                      setActiveByAction(true);
                    }}
                  />
                </div>
              </Fade>
            )}
            <TextEditor
              onChangeTrigger={() => {
                triggerCommentDraft();
              }}
              textblocks={props.textblocks}
              editorRef={editorRef}
              placeholder={i18n.t(
                "CommentInput.placeholder",
                "Schreiben Sie Ihr Kommentar hier..."
              )}
              // onInit={(editor) => {
              //
              // }}
              initialValue={initialComment}
              onEmptyChange={(empty) => {
                if (!props.onlyActive) {
                  setActive(!empty);
                }
              }}
              mentionSource={mentionSource}
            />
            <Collapse
              in={
                (attachmentCDNIds || []).length > 0 ||
                (uploadFiles || []).length > 0
              }
            >
              <div>
                <CommentInputAttachments
                  value={attachmentCDNIds}
                  onChange={setAttachmentCDNIds}
                  uploadFiles={uploadFiles}
                />
              </div>
            </Collapse>
            <Collapse in={active || isFullscreen}>
              <div>
                <div className={`comment-actions-row`}>
                  <div>
                    {isFullscreen ? (
                      <BFButton
                        tooltip={{
                          tooltip: i18n.t(
                            "CommentModule.CloseFullscreen",
                            "Vollbildmodus schließen"
                          ),
                        }}
                        onClick={() => {
                          setIsFullscreen(false);
                        }}
                        appearance="link"
                      >
                        <BfIcon type="light" data="shrink-1" size="xs" />
                      </BFButton>
                    ) : (
                      <BFButton
                        tooltip={i18n.t(
                          "CommentModule.OpenFullscreen",
                          "Vollbildmodus öffnen"
                        )}
                        onClick={() => setIsFullscreen(true)}
                        appearance="link"
                      >
                        <BfIcon type="light" data="expand-1" size="xs" />
                      </BFButton>
                    )}
                  </div>
                  {props.onlyAsMail ||
                  props.disableEmailCommunication ? null : (
                    <div>
                      <BFCheckbox
                        checked={sendAsMail}
                        onChange={(value, checked) => {
                          onSendAsMailChange(checked);
                        }}
                      >
                        {i18n.t("CommentInput.sendAsMail", "Als Mail senden")}
                      </BFCheckbox>
                    </div>
                  )}
                  {sendAsMail && props.signatures && (
                    <BFDropdown
                      renderToggle={(toggleProps) => (
                        <BFButton appearance="link" {...toggleProps}>
                          {i18n.t("CommentInput.signature", "Signatur")}
                        </BFButton>
                      )}
                      label={i18n.t("CommentInput.signature", "Signatur")}
                      items={
                        [
                          ...(props.signatures.length > 0
                            ? [
                                ...props.signatures.map((signature) => ({
                                  type: "button",
                                  text: signature.title,
                                  onSelect: () =>
                                    setSignature(signature.signature),
                                })),
                                {
                                  type: "button",
                                  text: i18n.t(
                                    "CommentInput.noSignature",
                                    "keine Signatur"
                                  ),
                                  onSelect: () => setSignature(""),
                                },
                                {
                                  type: "divider",
                                },
                              ]
                            : []),
                          {
                            type: "button",
                            text: i18n.t(
                              "CommentInput.manageSignature",
                              "Signaturen verwalten"
                            ),
                            onSelect: () => {
                              DataBusDefaults.route({
                                route: "__userprofile/profile",
                                append: true,
                              });
                              // open signature dialog
                            },
                          },
                        ] as DropdownItem[]
                      }
                    />
                  )}
                  {sendAsMail && (
                    <BFDropdown
                      renderToggle={(toggleProps) => (
                        <BFButton appearance="link" {...toggleProps}>
                          {i18n.t("CommentInput.attachment", "Anhang")}
                        </BFButton>
                      )}
                      label={i18n.t("CommentInput.attachment", "Anhang")}
                      items={
                        [
                          {
                            type: "button",
                            text: i18n.t(
                              "CommentInput.addAttachment",
                              "Anhang hinzufügen"
                            ),
                            onSelect: () => open(),
                          },
                          // todo add functionality when api is ready
                          // {
                          //   type: "button",
                          //   text: i18n.t(
                          //     "CommentInput.addAttachmentFromDocuments",
                          //     "Aus Dokumente hinzufügen"
                          //   ),
                          //   onSelect: () => open(),
                          // },
                          // {
                          //   type: "button",
                          //   text: i18n.t(
                          //     "CommentInput.addAttachmentFromOffers",
                          //     "Aus Angebote hinzufügen"
                          //   ),
                          //   onSelect: () => open(),
                          // },
                          ...(attachmentCDNIds.length > 0
                            ? [
                                {
                                  type: "divider",
                                },
                                {
                                  type: "button",
                                  text: i18n.t(
                                    "CommentInput.removeAttachments",
                                    "Alle Anhänge entfernen"
                                  ),
                                  onSelect: () => setAttachmentCDNIds([]),
                                },
                              ]
                            : []),
                        ] as DropdownItem[]
                      }
                    />
                  )}
                  <div className={`fill`} />
                  {props.subactivity && (
                    <>
                      <div className={`subactivity-selection`}>
                        <span className={`label`}>
                          {i18n.t("CommentInput.context", "Kontext")}:
                        </span>
                        <EZAutocomplete
                          error={contextError}
                          maxLength={30}
                          newEntryLabel={(search) => (
                            <Trans i18nKey="CommentInput.createContext">
                              Kontext <strong>{{ search }}</strong> erstellen
                            </Trans>
                          )}
                          value={subactivity}
                          onChange={setSubactivity}
                          options={_.uniq(
                            (props.possibleContexts || []).map(
                              (context) => context.displayName
                            )
                          )}
                          placeholder={i18n.t(
                            "CommentInput.selectSubActivity",
                            "Kontext setzen"
                          )}
                          onBlur={() => {}}
                        />
                      </div>
                    </>
                  )}
                  <BFButton
                    appearance="link"
                    onClick={async () => await close()}
                  >
                    {i18n.t("CommentInput.abort", "Abbrechen")}
                  </BFButton>
                  <BFButton
                    appearance="link"
                    disabled={sendDisabled}
                    onClick={async () => {
                      try {
                        if (
                          (props.subactivity === "force" ||
                            (props.subactivity === "force-on-mail" &&
                              sendAsMail)) &&
                          !subactivity
                        ) {
                          setContextError(i18n.t("Global.Labels.required"));
                          return;
                        }
                        let text = editorRef.current?.data.get();

                        if (props.resizeImages) {
                          text = await StringUtils.resizeInlineBase64Images(
                            text,
                            props.resizeImages[0],
                            props.resizeImages[1]
                          );
                        }

                        await props.onSend(
                          text,
                          sendAsMail ? emailAddressFields : undefined,
                          subactivity,
                          attachmentCDNIds
                        );
                        editorRef.current?.data.set("");
                        await close();
                      } catch (err) {
                        Log.error("err", err);
                      }
                    }}
                  >
                    {i18n.t("CommentInput.send", "Senden")}
                  </BFButton>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      )}
    />
  );
};

export default CommentInput;

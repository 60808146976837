import classNames from "classnames";
import Userlabel from "../../../../../components/AvatarComponent/Userlabel";
import WrappedText from "../../../../../components/WrappedText/WrappedText";
import BFOverlay from "../../../../../modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "../../../../../modules/abstract-ui/icon/BfIcon";
import StringUtils from "../../../../../utils/StringUtils";
import { UrgentData } from "../../RAInterfaces";
import "./UrgentIndicator.scss";

interface UrgentIndicatorProps {
  urgent?: UrgentData;
  title: string;
}
const UrgentIndicator = (props: UrgentIndicatorProps) => {
  if (props.urgent?.isUrgent) {
    return (
      <BFOverlay
        speaker={
          <div className={`ra-urgent-indicator modal`}>
            <UrgentBubble {...props} />
          </div>
        }
      >
        <div className={classNames(`ra-urgent-indicator indicator`)}>
          <BfIcon type="light" data="earthquake-alert" size={"xs"} />
        </div>
      </BFOverlay>
    );
  } else {
    return null;
  }
};

export default UrgentIndicator;

export const UrgentBubble = (props: UrgentIndicatorProps) => {
  return (
    <div className={`ra-urgent-indicator bubble`}>
      <div className={`title`}>{props.title}</div>
      <div className={`comment`}>
        <WrappedText text={props.urgent.urgentComment} />
      </div>
      <div className={`comment-info`}>
        <Userlabel id={props.urgent.user} />

        <div className={`date`}>
          {StringUtils.formatDate(props.urgent.date, "datetime")}
        </div>
      </div>
    </div>
  );
};

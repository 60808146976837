import UserReplacementService from "@/modules/user-profile-dialog/views/replacement/UserReplacementService";
import StringUtils from "@/utils/StringUtils";
import { Trans } from "react-i18next";
import { getAssetLabelByType } from "../../../apps/tatar/activityApp/ActivityUtils";
import Username from "../../../components/AvatarComponent/Username";
import i18n from "../../../i18n";
import { AssetTypes } from "../../../model/AssetTypes";
import {
  Notification,
  NotificationAssetEntry,
} from "../../../model/notifications/Notification";

const NotificationTypes = {
  MENTION: "mention",
  COMMENT_IN_FOLLOWING_ASSET: "comment_in_following_asset",
  OUTGOING_MAIL_IN_FOLLOWING_ASSET: "outgoing_mail_in_following_asset",
  INCOMING_MAIL_IN_FOLLOWING_ASSET: "incoming_mail_in_following_asset",
  BOUNCED_MAIL_IN_FOLLOWING_ASSET: "bounced_mail_in_following_asset",
  E_POST_ERROR: "e_post_error",
  ACTIVITY_OFFER_ACCEPTED: "offer_accepted",
  ACTIVITY_OFFER_DECLINED: "offer_declined",
  URGENT_INVOICE_APPROVAL: "urgent_invoice_approval",
  BUDGET_STATUS_CHANGE: "budget_status_change",
  //TODO: implement these
  REPLACEMENT_ACCEPTED: "replacement_accepted",
  REPLACEMENT_DECLINED: "replacement_declined",
  REPLACEMENT_REQUESTED: "replacement_requested",
};

export const NotificationContexts = {
  DAMAGE_CLAIM: "damage_claim",
  INVOICE: "invoice",
  PROJECT: "project",
  ORDERING_PROCESS: "ordering_process",
};

const getAssetName = (assetType: string) => {
  switch (assetType) {
    case AssetTypes.Activity.DamageClaim:
      return i18n.t(
        "Notifications.AssetTypes.DamageClaim",
        "der Schadenmeldung"
      );
    case AssetTypes.Activity.Project:
      return i18n.t("Notifications.AssetTypes.Project", "dem Projekt");
    case AssetTypes.Activity.Maintenance:
      return i18n.t(
        "Notifications.AssetTypes.Maintenance",
        "dem Wartungsvertrag"
      );
    case AssetTypes.Activity.SupplyContract:
      return i18n.t(
        "Notifications.AssetTypes.SupplyContract",
        "dem Versorgungsvertrag"
      );
    case AssetTypes.Activity.Vacancy:
      return i18n.t("Notifications.AssetTypes.Vacacy", "dem Leerstand");
    case AssetTypes.Activity.Dunning:
      return i18n.t("Notifications.AssetTypes.Dunning", "dem Mahnlauf");
    case AssetTypes.Invoice:
      return i18n.t("Notifications.AssetTypes.Invoice", "der Rechnung");
    case AssetTypes.Activity.OrderingProcess:
      return i18n.t(
        "Notifications.AssetTypes.OrderingProcess",
        "dem Bestellvorgang"
      );
    case AssetTypes.CashBudget.Booking:
      return i18n.t(
        "Notifications.AssetTypes.CashBudget.Booking",
        "der Buchung"
      );
    case AssetTypes.Rental.RentalAgreement:
      return i18n.t(
        "Notifications.AssetTypes.Rental.RentalAgreement",
        "dem Mietvertrag"
      );
    default:
      return assetType;
  }
};
const contextByAssetType = (assetType: string) => {
  switch (assetType) {
    case AssetTypes.Activity.Project:
      return NotificationContexts.PROJECT;
    case AssetTypes.Activity.DamageClaim:
      return NotificationContexts.DAMAGE_CLAIM;
    case AssetTypes.Invoice:
      return NotificationContexts.INVOICE;
    default:
      return null;
  }
};
const mapBaseData: (
  notificationEntry: NotificationAssetEntry,
  currentNotifications?: Notification[]
) => Partial<Notification> = (notificationEntry, currentNotifications) => {
  return {
    id: notificationEntry.id,
    created: notificationEntry.createdAt,
    seen:
      currentNotifications?.find((e) => e.id === notificationEntry.id)?.seen ||
      notificationEntry.read,
  };
};

export const extractNameOfNotification = (
  notification: NotificationAssetEntry
) => {
  switch (notification.typeData.assetType) {
    case AssetTypes.Rental.RentalAgreement:
    case AssetTypes.Invoice:
      return `${
        notification.meta?.id || notification.meta?.invoiceId
          ? `${notification.meta?.id || notification.meta?.invoiceId} - `
          : ""
      }${notification.meta?.displayName}`;
    case AssetTypes.CashBudget.Booking:
      return `${notification.meta?.displayName}`;
    case AssetTypes.Activity.Project:
    case AssetTypes.Activity.DamageClaim:
    case AssetTypes.Activity.Maintenance:
    case AssetTypes.Activity.SupplyContract:
    case AssetTypes.Activity.Vacancy:
    case AssetTypes.Activity.Dunning:
    case AssetTypes.Activity.Insurance:
    case AssetTypes.Activity.OrderingProcess:
      return `${notification.meta?.activityId} - ${notification.meta?.displayName}`;
    default:
      return `${notification.meta?.displayName || ""}`;
  }
};

export const mapNotificationAssetEntryToNotifcationEntry: (
  notificationEntry: NotificationAssetEntry,
  currentNotifications?: Notification[],
  removeNotification?: (id: string) => void
) => Notification = (
  notificationEntry,
  currentNotifications,
  removeNotification
) => {
  switch (notificationEntry.type) {
    case NotificationTypes.COMMENT_IN_FOLLOWING_ASSET:
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t(
          "Notifications.COMMENT_IN_FOLLOWING_ASSET.title",
          "Neuer Kommentar"
        ),
        context: contextByAssetType(notificationEntry.typeData.assetType),
        message: (
          <>
            <strong>
              <Username id={notificationEntry.createdBy} />
            </strong>{" "}
            <Trans i18nKey="Notifications.COMMENT_IN_FOLLOWING_ASSET.messageWithName">
              hat ein neuen Kommentar an{" "}
              {{
                assetName: getAssetName(notificationEntry.typeData.assetType),
              }}{" "}
              <strong>
                {{ name: extractNameOfNotification(notificationEntry) }}
              </strong>{" "}
              hinterlassen.
            </Trans>
          </>
        ),
        buttons: [
          {
            label: i18n.t(
              "Notifications.COMMENT_IN_FOLLOWING_ASSET.buttons.view",
              "Ansehen"
            ),
            type: "asset",
            data: {
              assetType: notificationEntry.typeData.assetType,
              assetId: notificationEntry.typeData.id,
              type: notificationEntry.meta?.type,
            },
          },
        ],
        deletable: true,
      } as Notification;

    case NotificationTypes.BOUNCED_MAIL_IN_FOLLOWING_ASSET:
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t(
          "Notifications.BOUNCED_MAIL_IN_FOLLOWING_ASSET.title",
          "Fehler beim Versenden einer Mail"
        ),
        context: contextByAssetType(notificationEntry.typeData.assetType),
        message: (
          <>
            <Trans i18nKey="Notifications.BOUNCED_MAIL_IN_FOLLOWING_ASSET.messageWithName">
              Beim Versenden einer E-Mail an{" "}
              {{
                assetName: getAssetName(notificationEntry.typeData.assetType),
              }}{" "}
              <strong>
                {{ name: extractNameOfNotification(notificationEntry) }}
              </strong>{" "}
              ist ein Fehler aufgetreten.
            </Trans>
          </>
        ),
        buttons: [
          {
            label: i18n.t(
              "Notifications.BOUNCED_MAIL_IN_FOLLOWING_ASSET.buttons.view",
              "Ansehen"
            ),
            type: "asset",
            data: {
              assetType: notificationEntry.typeData.assetType,
              assetId: notificationEntry.typeData.id,
            },
          },
        ],
        deletable: true,
      } as Notification;

    case NotificationTypes.E_POST_ERROR:
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t(
          "Notifications.E_POST_ERROR.title",
          "Fehler beim Versenden eines Briefs"
        ),
        context: contextByAssetType(notificationEntry.typeData.assetType),
        message: (
          <>
            <Trans i18nKey="Notifications.E_POST_ERROR.messageWithName">
              Beim Versenden eines Briefs in{" "}
              {{
                assetName: getAssetName(notificationEntry.typeData.assetType),
              }}{" "}
              <strong>
                {{ name: extractNameOfNotification(notificationEntry) }}
              </strong>{" "}
              ist ein Fehler aufgetreten.
            </Trans>
          </>
        ),
        buttons: [
          {
            label: i18n.t("Notifications.E_POST_ERROR.buttons.view", "Ansehen"),
            type: "asset",
            data: {
              assetType: notificationEntry.typeData.assetType,
              assetId: notificationEntry.typeData.id,
            },
          },
        ],
        deletable: true,
      } as Notification;

    case NotificationTypes.INCOMING_MAIL_IN_FOLLOWING_ASSET:
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t(
          "Notifications.INCOMING_MAIL_IN_FOLLOWING_ASSET.title",
          "Eine Nachricht eingegangen"
        ),
        context: contextByAssetType(notificationEntry.typeData.assetType),
        message: (
          <>
            <strong>{notificationEntry.meta?.senderEmail}</strong>{" "}
            <Trans i18nKey="Notifications.INCOMING_MAIL_IN_FOLLOWING_ASSET.messageWithName">
              hat eine E-Mail an{" "}
              {{
                assetName: getAssetName(notificationEntry.typeData.assetType),
              }}{" "}
              <strong>
                {{ name: extractNameOfNotification(notificationEntry) }}
              </strong>{" "}
              hinterlassen.
            </Trans>
          </>
        ),
        buttons: [
          {
            label: i18n.t(
              "Notifications.INCOMING_MAIL_IN_FOLLOWING_ASSET.buttons.view",
              "Ansehen"
            ),
            type: "asset",
            data: {
              assetType: notificationEntry.typeData.assetType,
              assetId: notificationEntry.typeData.id,
            },
          },
        ],
        deletable: true,
      } as Notification;

    case NotificationTypes.OUTGOING_MAIL_IN_FOLLOWING_ASSET:
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t(
          "Notifications.OUTGOING_MAIL_IN_FOLLOWING_ASSET.title",
          "Eine Email wurde versendet"
        ),
        context: contextByAssetType(notificationEntry.typeData.assetType),
        message: (
          <>
            <Trans i18nKey="Notifications.OUTGOING_MAIL_IN_FOLLOWING_ASSET.messageWithName">
              Es wurde eine E-Mail in{" "}
              <strong>
                {{ name: extractNameOfNotification(notificationEntry) }}
              </strong>{" "}
              verschickt.
            </Trans>
          </>
        ),
        buttons: [
          {
            label: i18n.t(
              "Notifications.OUTGOING_MAIL_IN_FOLLOWING_ASSET.buttons.view",
              "Ansehen"
            ),
            type: "asset",
            data: {
              assetType: notificationEntry.typeData.assetType,
              assetId: notificationEntry.typeData.id,
            },
          },
        ],
        deletable: true,
      } as Notification;
    case NotificationTypes.MENTION:
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t("Notifications.MENTION.title", "Sie wurden erwähnt"),
        context: contextByAssetType(notificationEntry.typeData.assetType),
        message: (
          <>
            <strong>
              <Username id={notificationEntry.createdBy} />
            </strong>{" "}
            <Trans i18nKey="Notifications.MENTION.messageWithName">
              hat Sie in einem Kommentar an{" "}
              {{
                assetName: getAssetName(notificationEntry.typeData.assetType),
              }}{" "}
              <strong>
                {{ name: extractNameOfNotification(notificationEntry) }}
              </strong>{" "}
              erwähnt.
            </Trans>
          </>
        ),
        buttons: [
          {
            label: i18n.t("Notifications.MENTION.buttons.view", "Ansehen"),

            type: "asset",
            data: {
              assetType: notificationEntry.typeData.assetType,
              assetId: notificationEntry.typeData.id,
            },
          },
        ],
        deletable: true,
      } as Notification;
    case NotificationTypes.ACTIVITY_OFFER_ACCEPTED:
      const activityLabel = getAssetLabelByType(
        notificationEntry.typeData.assetType
      );
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t(
          "Notifications.ACTIVITY_OFFER_ACCEPTED.title",
          "Angebot genehmigt"
        ),
        context: contextByAssetType(notificationEntry.typeData.assetType),
        message: (
          <>
            <Trans i18nKey="Notifications.ACTIVITY_OFFER_ACCEPTED.message">
              {{ activityLabel }}
              {": "}
              <strong>
                {{ displayName: notificationEntry.meta?.displayName }}
              </strong>{" "}
              (
              <strong>
                {{ activityId: notificationEntry.meta?.activityId }}
              </strong>
              )<br></br>
              Ein Angebot wurde <strong>genehmigt</strong>.
            </Trans>
          </>
        ),
        buttons: [
          {
            label: i18n.t(
              "Notifications.ACTIVITY_OFFER_ACCEPTED.buttons.openActivity",
              "Aktivität öffnen"
            ),
            type: "asset",
            data: {
              assetType: notificationEntry.typeData.assetType,
              assetId: notificationEntry.typeData.id,
              type: notificationEntry.meta?.type,
            },
          },
        ],
        deletable: true,
      } as Notification;
    case NotificationTypes.ACTIVITY_OFFER_DECLINED:
      const activityDeclinedLabel = getAssetLabelByType(
        notificationEntry.typeData.assetType
      );
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t(
          "Notifications.ACTIVITY_OFFER_DECLINED.title",
          "Angebot abgelehnt"
        ),
        context: contextByAssetType(notificationEntry.typeData.assetType),
        message: (
          <>
            <Trans i18nKey="Notifications.ACTIVITY_OFFER_DECLINED.message">
              {{ activityDeclinedLabel }}
              {": "}
              <strong>
                {{ displayName: notificationEntry.meta?.displayName }}
              </strong>{" "}
              (
              <strong>
                {{ activityId: notificationEntry.meta?.activityId }}
              </strong>
              )<br></br>
              Ein Angebot wurde <strong>abgelehnt</strong>.
            </Trans>
          </>
        ),
        buttons: [
          {
            label: i18n.t(
              "Notifications.ACTIVITY_OFFER_ACCEPTED.buttons.openActivity",
              "Aktivität öffnen"
            ),
            type: "asset",
            data: {
              assetType: notificationEntry.typeData.assetType,
              assetId: notificationEntry.typeData.id,
              type: notificationEntry.meta?.type,
            },
          },
        ],
        deletable: true,
      } as Notification;

    case NotificationTypes.BUDGET_STATUS_CHANGE: {
      if (notificationEntry.typeData.newStatus === "accept") {
        return {
          ...mapBaseData(notificationEntry, currentNotifications),
          type: "info",
          title: i18n.t(
            "Notifications.BUDGET_STATUS_CHANGE.accepted.title",
            "Budget genehmigt"
          ),
          message: (
            <>
              <Trans i18nKey="Notifications.BUDGET_STATUS_CHANGE.accepted.message">
                Das Budget an{" "}
                {{
                  assetName: getAssetName(notificationEntry.typeData.assetType),
                }}{" "}
                <strong>
                  {{ name: extractNameOfNotification(notificationEntry) }}
                </strong>{" "}
                wurde genehmigt.
              </Trans>
            </>
          ),
          data: notificationEntry,
          buttons: [
            {
              label: i18n.t(
                "Notifications.BUDGET_STATUS_CHANGE.buttons.openActivity",
                "Aktivität öffnen"
              ),
              type: "asset",
              data: {
                assetType: notificationEntry.typeData.assetType,
                assetId: notificationEntry.typeData.assetId,
                type: notificationEntry.typeData?.type,
              },
            },
          ],
          deletable: true,
        } as Notification;
      }
      if (notificationEntry.typeData.newStatus === "decline") {
        return {
          ...mapBaseData(notificationEntry, currentNotifications),
          type: "info",
          title: i18n.t(
            "Notifications.BUDGET_STATUS_CHANGE.declined.title",
            "Budget genehmigt"
          ),
          message: (
            <>
              <Trans i18nKey="Notifications.BUDGET_STATUS_CHANGE.declined.message">
                Das Budget an{" "}
                {{
                  assetName: getAssetName(notificationEntry.typeData.assetType),
                }}{" "}
                <strong>
                  {{ name: extractNameOfNotification(notificationEntry) }}
                </strong>{" "}
                wurde abgelehnt.
              </Trans>
            </>
          ),
          data: notificationEntry,
          buttons: [
            {
              label: i18n.t(
                "Notifications.BUDGET_STATUS_CHANGE.buttons.openActivity",
                "Aktivität öffnen"
              ),
              type: "asset",
              data: {
                assetType: notificationEntry.typeData.assetType,
                assetId: notificationEntry.typeData.assetId,
                type: notificationEntry.typeData?.type,
              },
            },
          ],
          deletable: true,
        } as Notification;
      } else {
        return {
          ...mapBaseData(notificationEntry, currentNotifications),
          type: "info",
          title: i18n.t("Notifications.UNKNOWN.title", "Unbekannt"),
          context: contextByAssetType(notificationEntry.typeData.assetType),
          message: i18n.t(
            "Notifications.UNKNOWN.message",
            "Unbekannte Nachricht"
          ),
          data: notificationEntry,
        } as Notification;
      }
    }

    case NotificationTypes.URGENT_INVOICE_APPROVAL:
      let label = null;
      let href = null;
      switch (notificationEntry.typeData.actionType) {
        case "APPROVE_INVOICE":
          label = i18n.t(
            "Notifications.APPROVE_INVOICE.title",
            "Sie müssen eine dringende Rechnung genehmigen."
          );
          href = `/app-invoice/approve`;
          break;
        case "APPROVE_DAMAGECLAIM":
          label = i18n.t(
            "Notifications.APPROVE_DAMAGECLAIM.title",
            "Sie müssen ein dringendes Angebot in einem Schadensfall genehmigen."
          );
          href = `/app-damages/approve-tasks`;
          break;
        case "APPROVE_ORDER":
          label = i18n.t(
            "Notifications.APPROVE_ORDER.title",
            "Sie müssen eine dringende Bestellung genehmigen."
          );
          href = `/app-orders/approve-tasks`;
          break;
        case "CHECK_PAYMENT":
          label = i18n.t(
            "Notifications.CHECK_PAYMENT.title",
            "Sie müssen eine dringende Bezahlung freigeben."
          );
          href = `/app-invoice/check-invoices`;
          break;
      }

      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "warning",
        title: i18n.t(
          "Notifications.URGENT_INVOICE_APPROVAL.title",
          "Dringend"
        ),
        context: contextByAssetType(notificationEntry.typeData.assetType),
        message: label,
        buttons: [
          {
            label: i18n.t(
              "Notifications.URGENT_INVOICE_APPROVAL.buttons.open",
              "Öffnen"
            ),
            type: "href",
            href: href,
          },
        ],
        deletable: true,
      } as Notification;

    // REPLACEMENTS
    case NotificationTypes.REPLACEMENT_REQUESTED:
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t(
          "Notifications.REPLACEMENT_REQUESTED.title",
          "Vertretungsanfrage"
        ),
        context: null,
        message: (
          <>
            <strong>
              <Username id={notificationEntry.typeData.data.replacedUser} />
            </strong>{" "}
            <Trans i18nKey="Notifications.REPLACEMENT_REQUESTED.messageWithName">
              hat Ihnen eine Vertretungsanfrage für den Zeitraum vom{" "}
              <strong>
                {{
                  from: StringUtils.formatDate(
                    notificationEntry.typeData.data.from
                  ),
                }}
              </strong>{" "}
              bis zum{" "}
              <strong>
                {{
                  to: StringUtils.formatDate(
                    notificationEntry.typeData.data.to
                  ),
                }}
              </strong>{" "}
              geschickt.
            </Trans>
            {notificationEntry.typeData.data.message && (
              <>
                <hr className={`margin-top-6 margin-bottom-6`} />
                <strong>
                  {i18n.t("UserProfile.Replacement.Message", "Grund")}
                </strong>
                <br />
                {notificationEntry.typeData.data.message}
              </>
            )}
          </>
        ),
        buttons: [
          {
            label: i18n.t(
              "Notifications.REPLACEMENT_REQUESTED.buttons.decline",
              "Ablehnen"
            ),
            onClick: async () => {
              await UserReplacementService.declineReplacementRequest(
                notificationEntry.typeData._id
              );
              removeNotification(notificationEntry.id);
            },
            type: "button",
          },
          {
            label: i18n.t(
              "Notifications.REPLACEMENT_REQUESTED.buttons.accept",
              "Annehmen"
            ),
            onClick: async () => {
              await UserReplacementService.acceptReplacementRequest(
                notificationEntry.typeData._id
              );
              removeNotification(notificationEntry.id);
            },
            type: "button",
          },
        ],
        deletable: true,
      } as Notification;

    case NotificationTypes.REPLACEMENT_ACCEPTED:
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t(
          "Notifications.REPLACEMENT_ACCEPTED.title",
          "Vertretungsanfrage angenommen"
        ),
        context: null,
        message: (
          <>
            <strong>
              <Username id={notificationEntry.typeData.data.replacementUser} />
            </strong>{" "}
            <Trans i18nKey="Notifications.REPLACEMENT_ACCEPTED.messageWithName">
              hat ihre Vertretungsanfrage für den Zeitraum vom{" "}
              <strong>
                {{
                  from: StringUtils.formatDate(
                    notificationEntry.typeData.data.from
                  ),
                }}
              </strong>{" "}
              bis zum{" "}
              <strong>
                {{
                  to: StringUtils.formatDate(
                    notificationEntry.typeData.data.to
                  ),
                }}
              </strong>{" "}
              angenommen.
            </Trans>
          </>
        ),
        deletable: true,
      } as Notification;
    case NotificationTypes.REPLACEMENT_DECLINED:
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t(
          "Notifications.REPLACEMENT_DECLINED.title",
          "Vertretungsanfrage abgelehnt"
        ),
        context: null,
        message: (
          <>
            <strong>
              <Username id={notificationEntry.typeData.data.replacementUser} />
            </strong>{" "}
            <Trans i18nKey="Notifications.REPLACEMENT_DECLINED.messageWithName">
              hat ihre Vertretungsanfrage für den Zeitraum vom{" "}
              <strong>
                {{
                  from: StringUtils.formatDate(
                    notificationEntry.typeData.data.from
                  ),
                }}
              </strong>{" "}
              bis zum{" "}
              <strong>
                {{
                  to: StringUtils.formatDate(
                    notificationEntry.typeData.data.to
                  ),
                }}
              </strong>{" "}
              abgelehnt.
            </Trans>
          </>
        ),
        deletable: true,
      } as Notification;

    default:
      return {
        ...mapBaseData(notificationEntry, currentNotifications),
        type: "info",
        title: i18n.t("Notifications.UNKNOWN.title", "Unbekannt"),
        context: contextByAssetType(notificationEntry.typeData.assetType),
        message: i18n.t(
          "Notifications.UNKNOWN.message",
          "Unbekannte Nachricht"
        ),
        data: notificationEntry,
      } as Notification;
  }

  return null;
};

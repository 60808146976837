import i18n from "../../../../../../i18n";
import LanguageService from "../../../../../../services/LanguageService";
import StringUtils from "../../../../../../utils/StringUtils";
import { CashBudgetCategory } from "../../../model/CashBudgetCofiguration";
import "./CashBudgetCellOverlay.scss";
interface Props {
  category: CashBudgetCategory;
  actualValue: number;
  targetValue: number;
  types?: {
    [typeIdent: string]: number;
  };
}

const mapTypeIdent = (typeIdent: string) => {
  switch (typeIdent) {
    case "damageClaim":
      return i18n.t("cb:LQ.Overlay.damageClaim"); //"Schadensaufwendung";
    case "forecast":
      return i18n.t("cb:LQ.Overlay.forecast"); //"Prognose";
    case "manual":
      return i18n.t("cb:LQ.Overlay.manual"); //"Geplante Buchung";
    case "budget":
      return i18n.t("cb:LQ.Overlay.budget"); //"Budget";
    default:
      return typeIdent;
  }
};

const CashBudgetCellOverlay = (props: Props) => {
  const progress = (props.actualValue / props.targetValue) * 100;
  return (
    <div className="cashbudget-cell-overlay">
      <div className="header">
        <div className="title">
          {LanguageService.translateLabel(props.category.data.displayName)}
        </div>
      </div>
      <div className="main-entries">
        <div className="entry">
          <div className="label">{i18n.t("cb:LQ.Overlay.planValue")}</div>
          <div className="value">
            {StringUtils.formatCurrency(props.targetValue || 0)}
          </div>
        </div>
        <div className="entry">
          <div className="label">{i18n.t("cb:LQ.Overlay.booked")}</div>
          <div className="value">
            {StringUtils.formatCurrency(props.actualValue || 0)}
          </div>
        </div>
        <div className="entry">
          <div className="label"> </div>
          <div className="value grey">
            {Number.isFinite(progress)
              ? StringUtils.formatNumber((progress === 0 ? 0 : progress) || 0)
              : "- "}
            %
          </div>
        </div>
      </div>
      {props.types && Object.entries(props.types).length > 0 && (
        <div className="type-entries">
          {Object.entries(props.types)?.map(([ident, value]) => (
            <div className="entry" key={ident}>
              <div className="label">{mapTypeIdent(ident)}</div>
              <div className="value ">{StringUtils.formatCurrency(value)}</div>
            </div>
          ))}
        </div>
      )}
      {/* <div>{props.targetValue}</div> */}
    </div>
  );
};

export default CashBudgetCellOverlay;

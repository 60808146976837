import { APActivity } from "@/apps/tatar/activityApp/ActivityInterfaces";
import BaseAsset from "@/model/general-assets/BaseAsset";
import { PortfolioSupplyUnit } from "../../../apps/tatar/cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import {
  CBPortfolioRenter,
  CBPortfolioTechnicalUnit,
} from "../../../apps/tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import {
  RentalAgreement,
  RentalUnit,
} from "../../../apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import { RAInvoice } from "../../../apps/tatar/invoiceApp/RAInterfaces";
import i18n from "../../../i18n";
import { AssetTypes } from "../../../model/AssetTypes";
import StringUtils from "../../../utils/StringUtils";

class ObjectRelatedUtilsClass {
  getNameOfObjectRelated(type: string) {
    switch (type) {
      case AssetTypes.Invoice:
        return i18n.t("AssetTypes.Invoice", "Rechnung");
      case AssetTypes.Portfolio.Loan:
        return i18n.t("AssetTypes.Loan", "Darlehen");
      case AssetTypes.Rental.RentalAgreement:
        return i18n.t("AssetTypes.RentalAgreement", "Mietvertrag");
      case AssetTypes.Rental.RentalUnit:
        return i18n.t("AssetTypes.RentalUnit", "Mieteinheit");
      case AssetTypes.Portfolio.SupplyUnit:
        return i18n.t("AssetTypes.SupplyUnit");
      case AssetTypes.Portfolio.TechnicalUnit:
        return i18n.t("AssetTypes.TechnicalUnit");
      case AssetTypes.ActivityRelated.Budget:
      case AssetTypes.Configuration.Budget:
        return i18n.t("AssetTypes.Labels.Budget", "Budget");
      case AssetTypes.Activity.SupplyContract:
        return i18n.t(
          "AssetTypes.Activity.SupplyContract",
          "Versorgungsvertrag"
        );
      case AssetTypes.Activity.Insurance:
        return i18n.t("AssetTypes.Activity.Insurance", "Versicherungsvertrag");
      case AssetTypes.Activity.Maintenance:
        return i18n.t("AssetTypes.Activity.Maintenance", "Wartungsvertrag");
      case AssetTypes.Activity.Project:
        return i18n.t("AssetTypes.Activity.Project", "Projekt");
      case AssetTypes.Activity.DamageClaim:
        return i18n.t("AssetTypes.Activity.DamageClaim", "Schadensmeldung");
      case AssetTypes.Activity.OrderingProcess:
        return i18n.t("AssetTypes.Activity.OrderingProcess", "Bestellvorgang");
      case AssetTypes.Portfolio.Renter:
        return i18n.t("AssetTypes.Renter", "Mieter");
      default:
        return i18n.t("AssetTypes.Unknown", "Unbekannt");
    }
  }
  getDataOfObjectRelated(assetType: string, asset: BaseAsset) {
    switch (assetType) {
      case AssetTypes.Rental.RentalUnit: {
        const assetCasted = asset as RentalUnit;
        return {
          name: assetCasted.data.displayName,
          id: assetCasted.data.id,
        };
      }
      case AssetTypes.Rental.RentalAgreement: {
        const assetCasted = asset as RentalAgreement;
        return {
          name: assetCasted.data.displayName,
          id: assetCasted.data.id,
        };
      }
      case AssetTypes.Portfolio.TechnicalUnit: {
        const assetCasted = asset as CBPortfolioTechnicalUnit;
        return {
          name: assetCasted.data.displayName,
          id: assetCasted.data.id,
        };
      }
      case AssetTypes.Portfolio.SupplyUnit: {
        const assetCasted = asset as PortfolioSupplyUnit;
        return {
          name: assetCasted.data.displayName,
          id: assetCasted.data.id,
        };
      }
      case AssetTypes.Portfolio.Renter: {
        const assetCasted = asset as CBPortfolioRenter;
        return {
          name: assetCasted.data.displayName,
          id: assetCasted.data.renterId,
        };
      }
      case AssetTypes.Activity.DamageClaim:
      case AssetTypes.Activity.Dunning:
      case AssetTypes.Activity.Insurance:
      case AssetTypes.Activity.Maintenance:
      case AssetTypes.Activity.OrderingProcess:
      case AssetTypes.Activity.Project:
      case AssetTypes.Activity.SupplyContract:
      case AssetTypes.Activity.TenantCommunication:
      case AssetTypes.Activity.Vacancy: {
        const assetCasted = asset as APActivity;
        return {
          name: assetCasted.data.displayName,
          id: assetCasted.data.activityId,
        };
      }
      case AssetTypes.Invoice: {
        const assetCasted = asset as RAInvoice;
        return {
          id: assetCasted.id,
          name: ` ${StringUtils.formatCurrency(
            assetCasted.data.invoice.value.amount,
            undefined,
            undefined,
            assetCasted.data.invoice.value.currency
          )}`,
        };
      }
      default:
        return null;
    }
  }
}
const ObjectRelatedUtils = new ObjectRelatedUtilsClass();
export default ObjectRelatedUtils;

import React from "react";
import PinField from "react-pin-field";
import { AbstractComponent, AbstractProps, AbstractStates } from "../../../../utils/abstracts/AbstractComponent";
import { DataBusSubKeys } from "../../../../utils/Constants";
import "./BFPinInput.scss";

export type BFPinInputAppearance = "default" | "clear";
type Props = {
	appearance?: string;
	length: number;
	validate?: string | string[] | RegExp | ((key: string) => boolean);
	disabled?: boolean;
	validation?: {
		message: string;
		level: "error" | "warning";
	};
	password?: boolean;
	numberOnly?: boolean;
	name?: string;
	value?: string;
	onChange?: (value: string | number, ev: Event) => void;
	onComplete?: (code: string) => void;
} & AbstractProps;

type States = {} & AbstractStates;

class BFPinInput extends AbstractComponent<Props, States> {
	static defaultProps = {
		type: "text",
		appearance: "default",
		length: 5,
		validate: /^[0-9]$/
	};
	readonly state: States = {};

	inputRef: React.Ref<HTMLInputElement[]> = React.createRef();

	componentDidMount() {
		// if (this.props.focusOnMount) {
		// 	this.inputRef.focus();
		// }

		this.subscribe(DataBusSubKeys.FOCUS, data => (data.id === this.props.identifier ? this.focus() : undefined));
	}
	componentDidUpdate(prevProps: Props) {
		if (prevProps.value !== this.props.value) {
			const listInputs = (this.inputRef as any).current;
			for (var i = 0; i < listInputs.length; i++) {
				listInputs[i].value = this.props.value && this.props.value.length > i ? this.props.value[i] : "";
			}
		}
	}
	shouldComponentUpdate(nextProps: Props, nextStates: States) {
		if (this.props.value !== nextProps.value) {
			return true;
		}
		return super.shouldComponentUpdate(nextProps, nextStates);
	}
	focus() {
		(this.inputRef as any).current[0].focus();
	}
	reset() {
		(this.inputRef as any).current.forEach(input => input.value = "")
	}

	render() {
		const { numberOnly, appearance,password,length, disabled, validate, name, value, onChange, onComplete, validation } = this.props;
		return (
			<div className={`bf-pin-input appearance-${appearance ? appearance : "default"}`}>
				<PinField
					className={`pin-field`}
					ref={this.inputRef}
					// value={value}
					length={length}
					validate={validate}
					name={name}
					pattern={numberOnly ? "[0-9]*" : undefined}
					inputMode={numberOnly ? "numeric" : undefined}
					type={password ? "password" : undefined}
					disabled={disabled}
					onComplete={code => (onComplete ? onComplete(code) : null)}
					onChange={code => (onChange ? onChange(code, null) : null)}
				/>
				{validation && <div className={`validation ${validation.level}`}>{validation.message}</div>}
			</div>
		);
	}
}

export default BFPinInput;

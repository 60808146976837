import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import classNames from "classnames";
import moment from "moment";
import "./HistoryGraph.scss";

interface HistoryEntry {
  id: string;
  from: Date;
  to?: Date;
  data: any;
}
export type HistoryEntryType = "future" | "current" | "past";

interface HistoryGraphProps {
  data: HistoryEntry[];
  renderContent: (
    data: HistoryEntry,
    historyEntryType: HistoryEntryType,
    previousPeriod?: HistoryEntry
  ) => JSX.Element;
  renderHeader: (
    data: HistoryEntry,
    historyEntryType: HistoryEntryType,
    previousPeriod?: HistoryEntry
  ) => JSX.Element;

  // Optional
  headerActions?: JSX.Element;
}
const HistoryGraph = (props: HistoryGraphProps) => {
  return (
    <div className={classNames("history-graph")}>
      <div className={`periods-container`}>
        {props.headerActions && (
          <div className={`periods-action`}>{props.headerActions}</div>
        )}
        {props.data
          .sort((a, b) => moment(b.from).diff(moment(a.from)))
          .map((entry, index, arr) => {
            return (
              <HistoryEntryComponent
                entry={entry}
                previousEntry={index < arr.length ? arr[index + 1] : undefined}
                key={entry.id}
                renderContent={props.renderContent}
                renderHeader={props.renderHeader}
              />
            );
          })}
        <div className={`vert-line`} />
      </div>
    </div>
  );
};

export default HistoryGraph;

const HistoryEntryComponent = (props: {
  entry: HistoryEntry;
  previousEntry?: HistoryEntry;

  renderContent: (
    data: HistoryEntry,
    historyEntryType: HistoryEntryType,
    previousPeriod?: HistoryEntry
  ) => JSX.Element;
  renderHeader: (
    data: HistoryEntry,
    historyEntryType: HistoryEntryType,
    previousPeriod?: HistoryEntry
  ) => JSX.Element;
}) => {
  const isCurrentPeriod = !props.entry.to
    ? moment().isAfter(moment(props.entry.from))
    : moment().isBetween(moment(props.entry.from), moment(props.entry.to));
  const isFuturePeriod = moment().isBefore(moment(props.entry.from));
  const isPastPeriod = moment().isAfter(moment(props.entry.to));

  const historyEntryType = isCurrentPeriod
    ? "current"
    : isFuturePeriod
    ? "future"
    : "past";
  return (
    <div
      className={classNames(`history-graph-entry`, {
        future: isFuturePeriod,
        past: isPastPeriod,
        current: isCurrentPeriod,
      })}
    >
      <div className={`head-line`}>
        <div className={`circle`}>
          {isCurrentPeriod && <div className={`current-circle`} />}
          {isPastPeriod && <div className={`past-circle`} />}
        </div>
        {isCurrentPeriod && (
          <span className={`current-period`}>
            {i18n.t("cb:RentalAgreementPeriod.currentlyActive", "Aktuell")}
          </span>
        )}
        {isFuturePeriod && (
          <span className={`future-period`}>
            {i18n.t("cb:RentalAgreementPeriod.futureActive", "Demnächst")}
          </span>
        )}
        {i18n.t("Global.Labels.fromDate", "ab")}{" "}
        {moment(props.entry.from).format(i18n.t("Formats.dateFormat"))}{" "}
        {props.entry.to &&
          `${i18n.t("Global.Labels.until", "bis")} ${moment(
            props.entry.to
          ).format(i18n.t("Formats.dateFormat"))}`}
        <div className={`head-content`}>
          {props.renderHeader(
            props.entry,
            historyEntryType,
            props.previousEntry
          )}
        </div>
        <DebugDataComponent data={props.entry} />
      </div>
      <div className={`period-content`}>
        {props.renderContent(
          props.entry,
          historyEntryType,
          props.previousEntry
        )}
      </div>
    </div>
  );
};

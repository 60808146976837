import WrappedText from "@/components/WrappedText/WrappedText";
import i18n from "@/i18n";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { APRentReduction } from "../../../ActivityInterfaces";
import "./APRentReductionDetails.scss";

interface APRentReductionDetailsProps {
  rentReduction: APRentReduction;
}
const APRentReductionDetails = (props: APRentReductionDetailsProps) => {
  return (
    <div className={classNames(`ap-rent-reduction-details`)}>
      <div className={`fields`}>
        <BFValueDisplay
          label={i18n.t(
            "apTemplate:RentReduction.OriginalAmount",
            "Mietbetrag (Original)"
          )}
          value={props.rentReduction.data.originalAmount}
          formatter={(value: number) => StringUtils.formatCurrency(value)}
        />
        <BFValueDisplay
          label={i18n.t(
            "apTemplate:RentReduction.ReducedAmount",
            "Mietbetrag (Reduziert)"
          )}
          value={props.rentReduction.data.reducedAmount}
          formatter={(value: number) => StringUtils.formatCurrency(value)}
        />
      </div>
      <div className={`fields`}>
        <BFValueDisplay
          label={i18n.t(
            "apTemplate:RentReduction.damageSince",
            "Mangel besteht seit"
          )}
          value={props.rentReduction.data.damageSince}
          formatter={(value: string) => StringUtils.formatDate(value)}
        />
        <BFValueDisplay
          label={i18n.t(
            "apTemplate:RentReduction.damageReported",
            "Mangel gemeldet am"
          )}
          value={props.rentReduction.data.damageReported}
          formatter={(value: string) => StringUtils.formatDate(value)}
        />
        <BFValueDisplay
          label={i18n.t(
            "apTemplate:RentReduction.damageFixed",
            "Mangel behoben am"
          )}
          value={props.rentReduction.data.damageFixed}
          formatter={(value: string) => StringUtils.formatDate(value)}
        />
        <BFValueDisplay
          label={i18n.t(
            "apTemplate:RentReduction.noticeDamageRecification",
            "Mängelfreimeldung"
          )}
          value={props.rentReduction.data.noticeDamageRecification}
          formatter={(value: boolean) =>
            value ? i18n.t("Global.Labels.yes") : i18n.t("Global.Labels.no")
          }
        />
      </div>
      <div className={`fields`}>
        <BFValueDisplay
          label={i18n.t(
            "apTemplate:APRentReductionForm.ReductionCalculationNote",
            "Berechnung der Mietminderung"
          )}
          value={props.rentReduction.data.reductionCalculationNote}
          formatter={(value: string) => <WrappedText text={value} />}
        />
      </div>
      <div className={`fields`}>
        <BFValueDisplay
          label={i18n.t(
            "apTemplate:APRentReductionForm.ReductionLegalBasis",
            "Gesetzliche Grundlagen der Mietminderung"
          )}
          value={props.rentReduction.data.reductionLegalBasis}
          formatter={(value: string) => <WrappedText text={value} />}
        />
      </div>
    </div>
  );
};

export default APRentReductionDetails;

import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import EnergyEfficiencyClassIndicator, {
  calculateEnergyCertificateClass,
} from "@/modules/abstract-ui/data/energy-efficiency-class/EnergyEfficiencyClassIndicator";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import { isDefined } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { Field, FormRenderProps } from "react-final-form";
import { getEnergySources } from "../../../ObjectApp.utils";
import { OAObject } from "../../../types/object.interface";
import {
  ObjectKind,
  ObjectKindFeature_IMMO,
  getFeaturesForKind,
} from "../../../types/objectKind.interface";
// import './ObjectFormFeatureImmo.scss';

interface ObjectFormFeatureImmoProps {
  formProps: FormRenderProps<any>;
}

export const getInitialValueFeatureImmo = (
  asset: OAObject,
  kind: ObjectKind
) => {
  const features = getFeaturesForKind(kind);

  if (features.includes("immo")) {
    return {
      constructionYear: asset?.data?.feature?.immo?.constructionYear,
      area: asset?.data?.feature?.immo?.area,
      landArea: asset?.data?.feature?.immo?.landArea,
      landValuePerQM: asset?.data?.feature?.immo?.landValuePerQM,
      energyCertificateClass:
        asset?.data?.feature?.immo?.energyCertificateClass,
      energyCertificateConsumption:
        asset?.data?.feature?.immo?.energyCertificateConsumption,
      energyCertificateCreationDate:
        asset?.data?.feature?.immo?.energyCertificateCreationDate,
      energyCertificateEnergySource:
        asset?.data?.feature?.immo?.energyCertificateEnergySource,

      accountingTemplate: isDefined(asset)
        ? undefined
        : ObjectKindStruct.getKindFeature<ObjectKindFeature_IMMO>(
            kind._id,
            "immo"
          )?.accountingTemplate?.[0]?.id,
    };
  } else {
    return undefined;
  }
};
const ObjectFormFeatureImmo = (props: ObjectFormFeatureImmoProps) => {
  return (
    <div className={classNames(`object-form-feature-immo`)}>
      <div className={`field-row`}>
        <div className={`field __flex-1`}>
          <Field
            name="feature.immo.area"
            validate={FV.compose(FV.required(), FV.min(0))}
            render={({ input, meta }) => (
              <BFInput
                {...input}
                type={"number"}
                label={`${i18n.t("obj:ObjectForm.Immo.Area", "Fläche")}*`}
                suffix={StringUtils.getAreaUnit()}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>
        <div className={`field __flex-1`}>
          <Field
            name="feature.immo.landArea"
            validate={FV.compose(FV.required(), FV.min(0))}
            render={({ input, meta }) => (
              <BFInput
                {...input}
                type={"number"}
                label={`${i18n.t(
                  "obj:ObjectForm.Immo.LandArea",
                  "Grundstücksfläche"
                )}*`}
                suffix={StringUtils.getAreaUnit()}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>
        <div className={`field __flex-1`}>
          <Field
            name="feature.immo.landValuePerQM"
            validate={FV.compose(FV.required(), FV.min(0))}
            render={({ input, meta }) => (
              <BFInput
                {...input}
                type={"priceInput"}
                textAlign="right"
                label={`${i18n.t(
                  "obj:ObjectForm.Immo.LandValuePerQM",
                  "Grundstückswert pro m²"
                )}*`}
                suffix={StringUtils.getCurrencySymbol()}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>

        {/* <div className={`field __flex-1`}>
          <Field
            name="feature.immo.purchasePrice"
            validate={FV.compose(FV.required(), FV.min(0))}
            render={({ input, meta }) => (
              <BFInput
                {...input}
                type={"priceInput"}
                label={`${i18n.t(
                  "obj:ObjectForm.Immo.purchasePrice",
                  "Kaufpreis"
                )}*`}
                suffix={StringUtils.getCurrencySymbol()}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div> */}

        <div className={`field __flex-1`}>
          <Field
            name="feature.immo.constructionYear"
            validate={FV.compose(FV.required(), FV.min(0))}
            render={({ input, meta }) => (
              <BFInput
                {...input}
                type={"number"}
                step={1}
                label={`${i18n.t(
                  "obj:ObjectForm.Immo.constructionYear",
                  "Baujahr"
                )}*`}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>
      </div>
      <div className={`field-row`}>
        <div className={`field __flex-1`}>
          <Field
            name="feature.immo.energyCertificateConsumption"
            validate={FV.compose(FV.min(0))}
            render={({ input, meta }) => (
              <BFInput
                {...input}
                type={"number"}
                label={`${i18n.t(
                  "obj:ObjectForm.Immo.energyCertificateConsumption",
                  "Energieverbrauch"
                )}`}
                prefix={i18n.t("cb:Portfolio.Objekt.kWhm2a", "kWh/m²a")}
                onChange={(value: number) => {
                  input.onChange(value);
                  props.formProps.form.mutators.setValue(
                    "feature.immo.energyCertificateClass",
                    calculateEnergyCertificateClass(value)
                  );
                }}
                className={`energy-consumption-input`}
                removeSuffixPadding
                suffix={
                  <Field
                    name="feature.immo.energyCertificateClass"
                    render={(energyCertificateClass) =>
                      energyCertificateClass.input.value ? (
                        <EnergyEfficiencyClassIndicator
                          className={"margin-right-10"}
                          energyClass={energyCertificateClass.input.value}
                        />
                      ) : undefined
                    }
                  />
                }
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>
        <div className={`field __flex-1`}>
          <Field
            name="feature.immo.energyCertificateCreationDate"
            validate={FV.compose(FV.min(0))}
            render={({ input, meta }) => (
              <BFDatefield
                {...input}
                label={`${i18n.t(
                  "obj:ObjectForm.Immo.energyCertificateCreationDate",
                  "Energieausweis Datum"
                )}`}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>
        <div className={`field __flex-2`}>
          <Field
            name="feature.immo.energyCertificateEnergySource"
            render={({ input, meta }) => (
              <BFSelect
                ignoreDataSort
                type="multiple"
                {...input}
                value={input.value || []}
                label={`${i18n.t(
                  "obj:ObjectForm.Immo.energyCertificateEnergySource",
                  "Energieträger"
                )}`}
                data={getEnergySources()}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ObjectFormFeatureImmo;

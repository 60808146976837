import classNames from "classnames";
import moment from "moment";
import { useRef } from "react";
import { DatePicker, Tag } from "rsuite";
import i18n from "../../../../i18n";
import { Placement } from "../../../abstract-ui/common/Placements";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import BfIcon, { BfIconProps } from "../../../abstract-ui/icon/BfIcon";
import "./EZDate.scss";

interface EZDateProps {
  className?: string;
  value: Date;
  onChange?: (value: Date) => void;
  onBlur?: (ev, value: Date) => void;

  error?: string;
  disabled?: boolean;

  prefixIcon?: BfIconProps;
  placement?: Placement;
  cleanable?: boolean;
  size?: "xs";
  readonly?: boolean;
}
const EZDate = (props: EZDateProps) => {
  const dateRef = useRef<any>(null);
  const renderValue = () => {
    return (
      <>
        {props.prefixIcon ? (
          <div className={`prefix-icon`}>
            {" "}
            <BfIcon {...props.prefixIcon} />
          </div>
        ) : (
          <div className={`prefix-icon`}>
            {" "}
            <BfIcon data="calendar" type="light" size="xs" />
          </div>
        )}
        {props.value ? (
          <span>
            {moment(props.value).format(i18n.t("Formats.dateFormat"))}
          </span>
        ) : (
          <span>-</span>
        )}
      </>
    );
  };
  if (props.readonly) {
    return (
      <div
        className={classNames(`ez-date`, props.className, {
          "size-xs": props.size === "xs",
        })}
      >
        <Tag className={`readonly-tag`} size="sm">
          <div className={`render-value`}>{renderValue()}</div>
        </Tag>
      </div>
    );
  }
  let translations = i18n.getResourceBundle(i18n.language, "common");

  return (
    <div
      className={classNames(`ez-date`, props.className, {
        "size-xs": props.size === "xs",
        error: props.error,
      })}
    >
      <BFButton
        disabled={props.disabled}
        onClick={() => {
          if (!props.readonly) {
            dateRef.current?.open();
          }
        }}
        className={`date-toggle-button`}
      >
        {renderValue()}

        <DatePicker
          ref={dateRef}
          className={`hidden-input`}
          readOnly={props.readonly}
          disabled={props.disabled}
          ranges={
            props.cleanable
              ? [
                  {
                    label: i18n.t("EzForm.EZDate.clear", "Entfernen"),
                    value: null,
                  },
                ]
              : undefined
          }
          value={props.value ? new Date(props.value) : undefined}
          format={i18n.t("Formats.dateFormatFN")}
          locale={translations?.["Global.dates"] || undefined}
          onChange={(date, t) => {
            props.onChange(date);
            props.onBlur(null, date);
            setTimeout(() => {
              dateRef.current?.close();
            });
          }}
          placement={props.placement}
          oneTap
        />
      </BFButton>
    </div>
  );
};

export default EZDate;

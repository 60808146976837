import { useGlobalKeyEvent } from "@/utils/Hooks";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Modal } from "rsuite";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import { useDatabus } from "../../redux/hooks";
import { DataBusSubKeys } from "../../utils/Constants";
import { isDefined } from "../../utils/Helpers";
import "./ModalComponent.scss";

export interface ModalParams {
  title?: string | React.ReactNode;
  maxWidth?: number;
  ignoreEscapeToClose?: boolean;
  content:
    | string
    | React.ReactNode
    | ((
        states: any,
        setStates: (states: any) => void,
        closeModal?: () => void
      ) => React.ReactNode);
  buttons?: {
    className?: string;
    text: string | React.ReactNode;
    onClick?: (
      states,
      setStates?: (states: any) => void,
      closeFc?: () => void
    ) => void;
    closeOnClick?: boolean;
    appearance?:
      | "default"
      | "primary"
      | "link"
      | "clear"
      | "outline"
      | "clear-on-white";
    isEnabled?: (states) => boolean;
    isLoading?: (states) => boolean;
  }[];
  backdrop?: boolean | "static";
  size?: "lg" | "md" | "sm" | "xs" | "xl" | "xxl" | "full" | "fluid";
  headerCloseButton?: boolean;
  onHide?: () => void;
  params?: object;
  modalClassName?: string;
  noPadding?: boolean;
  closable?: boolean;
}

const ModalComponent = () => {
  const [stack, setStack] = useState<
    { modal: ModalParams; show: boolean; state: any }[]
  >([]);

  useDatabus(
    DataBusSubKeys.MODAL_MANAGER_ADD,
    (data: ModalParams) => {
      setStack((stack) => [
        ...stack,
        { modal: data, show: true, state: data.params ? data.params : {} },
      ]);
    },
    [stack, setStack]
  );

  useGlobalKeyEvent(
    ["Escape"],
    (e) => {
      if (e.key === "Escape") {
        const lastModal = stack[stack.length - 1];
        if (lastModal && !lastModal.modal.ignoreEscapeToClose) {
          setStack(
            stack.map((e, i) =>
              i === stack.length - 1 ? { ...e, show: false } : e
            )
          );
        }
      }
    },
    [stack]
  );

  useDatabus(
    DataBusSubKeys.MODAL_MANAGER_CLOSE_ALL,
    () => {
      setStack([]);
    },
    [stack, setStack]
  );

  useEffect(() => {
    if (stack.length === 0) {
      const el = document.querySelector("#root");
      el.removeAttribute("inert");
    }
  }, [stack]);

  const mapSize = (size: string) => {
    switch (size) {
      case "fluid":
        return undefined;

      case "xxl":
        return "calc(100% - 40px)";
      case "xl":
        return 1500;
      default:
        return size;
    }
  };
  if (stack.length === 0) {
    return null;
  } else {
    return (
      <>
        {stack.map(({ modal, show, state }, index) => (
          <Modal
            key={index}
            style={{ maxWidth: modal.maxWidth }}
            overflow={false}
            className={classNames(`modal-component`, modal.modalClassName, {
              "no-padding": modal.noPadding,
              fluid: modal.size === "fluid",
            })}
            backdrop={isDefined(modal.backdrop) ? modal.backdrop : true}
            enforceFocus={true}
            open={show}
            onOpen={() => {
              const el = document.querySelector("#root");
              el.setAttribute("inert", "");
            }}
            onExited={() => {
              setStack(stack.filter((e, i) => i !== index));
              if (modal.onHide) {
                modal.onHide();
              }
            }}
            onClose={
              modal.headerCloseButton || modal.closable
                ? () => {
                    setStack(
                      stack.map((e, i) =>
                        i === index ? { ...e, show: false } : e
                      )
                    );
                  }
                : undefined
            }
            size={
              modal.size === "fluid" ? undefined : mapSize(modal.size) || "xs"
            }
          >
            {modal.title ? (
              <Modal.Header
                closeButton={
                  modal.headerCloseButton ? modal.headerCloseButton : false
                }
              >
                {modal.title}
              </Modal.Header>
            ) : null}
            <Modal.Body>
              {typeof modal.content == "function"
                ? modal.content(
                    state,
                    (states: any) =>
                      setStack(
                        stack.map((e, i) =>
                          i === index ? { ...e, state: states } : e
                        )
                      ),
                    () =>
                      setStack(
                        stack.map((e, i) =>
                          i === index ? { ...e, show: false } : e
                        )
                      )
                  )
                : modal.content}
            </Modal.Body>
            {modal.buttons && (
              <Modal.Footer>
                <div className={`footer`}>
                  {modal.buttons.map((buttonConf, btnIndex) => (
                    <BFButton
                      type="button"
                      disabled={
                        buttonConf.isEnabled
                          ? !buttonConf.isEnabled(state)
                          : false
                      }
                      loading={
                        buttonConf.isLoading
                          ? buttonConf.isLoading(state)
                          : false
                      }
                      key={btnIndex}
                      appearance={
                        buttonConf.appearance
                          ? buttonConf.appearance
                          : "clear-on-white"
                      }
                      className={
                        buttonConf.className ? buttonConf.className : ""
                      }
                      onClick={() => {
                        if (buttonConf.onClick) {
                          buttonConf.onClick(
                            state,
                            (states) =>
                              setStack(
                                stack.map((e, i) =>
                                  i === index ? { ...e, state: states } : e
                                )
                              ),
                            () =>
                              setStack(
                                stack.map((e, i) =>
                                  i === index ? { ...e, show: false } : e
                                )
                              )
                          );
                        }
                        if (buttonConf.closeOnClick !== false) {
                          setStack(
                            stack.map((e, i) => {
                              return i === index ? { ...e, show: false } : e;
                            })
                          );
                        }
                      }}
                    >
                      {buttonConf.text}
                    </BFButton>
                  ))}
                </div>
              </Modal.Footer>
            )}
          </Modal>
        ))}
      </>
    );
  }
};

export default ModalComponent;

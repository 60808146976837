import classNames from "classnames";
import { MouseEvent, useRef, useState } from "react";
import DebugDataComponent from "../../debug/DebugDataComponent";
import DeviceUtils from "../../utils/Device";
import StringUtils from "../../utils/StringUtils";
import BFInput from "../abstract-ui/forms/input/BFInput";
import BFWhisper from "../abstract-ui/general/whisper/BFWhisper";
import BfIcon from "../abstract-ui/icon/BfIcon";
import { CashBudgetRowConfigHint } from "./CashBudgetTable";

interface CommonCellProps {
  rowIndex: number;
  rowHeight: number;
  refFC?: any;
  className?: string;
  isSubHead: boolean;
}

interface CellProps extends CommonCellProps {
  children?: React.ReactNode;
  onHoverEnterFunction?: (rowIndex: number) => void;
  onHoverLeaveFunction?: (rowIndex: number) => void;

  value?: number;
  onChange?: (value: number) => void;
  onClick?: (cmdModifier: boolean, shiftModifier: boolean) => void;
  onDblClick?: () => void;
  cellBackground?: string;
  style?: React.CSSProperties;
}
const CashBudgetCell = (props: CellProps) => {
  const [showEdit, setShowEdit] = useState(false);
  const hoverMethods: any = {};

  if (props.onHoverEnterFunction) {
    hoverMethods.onMouseEnter = () =>
      props.onHoverEnterFunction(props.rowIndex);
  }
  if (props.onHoverLeaveFunction) {
    hoverMethods.onMouseLeave = () =>
      props.onHoverLeaveFunction(props.rowIndex);
    hoverMethods.onMouseOut = () => props.onHoverLeaveFunction(props.rowIndex);
  }

  const onClick = (ev: MouseEvent<HTMLDivElement>) => {
    if (props.onChange) {
      setShowEdit(true);
    }
    if (props.onClick) {
      props.onClick(
        DeviceUtils.isApple() ? ev.metaKey : ev.ctrlKey,
        ev.shiftKey
      );
    }
  };
  if (showEdit) {
    return (
      <div
        className={`cell-edit`}
        style={{
          ...props.style,
          height: props.rowHeight,
          background: props.cellBackground,
        }}
      >
        <BFInput
          focusOnMount
          suffix={StringUtils.getCurrencySymbol()}
          type="priceInput"
          textAlign="right"
          value={props.value}
          // onBlur={() => setShowEdit(false)}
          onChange={(value: number) => {
            if (props.onChange) {
              props.onChange(value);
            }
            setShowEdit(false);
          }}
        />
      </div>
    );
  } else {
    return (
      <div
        className={`cell ${props.className || ""} ${
          props.isSubHead ? "sub-head" : ""
        }`}
        style={{
          ...props.style,
          height: props.rowHeight,
          background: props.cellBackground,
        }}
        ref={props.refFC}
        onClick={onClick}
        onDoubleClick={props.onDblClick ? () => props.onDblClick() : undefined}
        {...hoverMethods}
      >
        {props.children}
      </div>
    );
  }
};

interface BackgroundProps extends CommonCellProps {}
export const CashBudgetBackgroundCell = (props: BackgroundProps) => {
  return (
    <CashBudgetCell
      rowIndex={props.rowIndex}
      isSubHead={props.isSubHead}
      rowHeight={props.rowHeight}
      refFC={props.refFC}
      className={`background ${props.className || ""}  `}
    />
  );
};

interface RowIdentProps extends CommonCellProps {
  text: string;
  bold?: boolean;
  hints?: CashBudgetRowConfigHint[];
  onHoverEnterFunction?: (rowIndex: number) => void;
  onHoverLeaveFunction?: (rowIndex: number) => void;
  collapsingCell?: boolean;
  isCollapsed?: boolean;
  onCollapseToggle?: () => void;
  actions?: React.ReactNode;
  depth: number;
  nextDepth: number;
}
export const CashBudgetRowIdentCell = (props: RowIdentProps) => {
  return (
    <CashBudgetCell
      rowIndex={props.rowIndex}
      isSubHead={props.isSubHead}
      rowHeight={props.rowHeight}
      refFC={props.refFC}
      onHoverEnterFunction={props.onHoverEnterFunction}
      onHoverLeaveFunction={props.onHoverLeaveFunction}
      className={`row-ident ${props.className || ""}  ${
        props.bold ? "bold" : ""
      }`}
      style={{
        paddingRight: props.depth * 15 + (props.collapsingCell ? 0 : 10),
      }}
    >
      {props.hints && (
        <div className="hints">
          {props.hints.map((hint) => (
            <BFWhisper
              key={hint.description}
              speaker={
                <div className="cashbudget-hint-popup">{hint.description}</div>
              }
            >
              <div
                style={{ backgroundColor: hint.color }}
                className="hint"
              ></div>
            </BFWhisper>
          ))}
        </div>
      )}
      {props.actions && props.actions}

      {props.collapsingCell ? (
        <button
          type="button"
          className={`collapsible-cell ${props.isCollapsed ? "collapsed" : ""}`}
          onClick={() => props.onCollapseToggle()}
        >
          {props.text}{" "}
          <div className="collapsible-indicator" style={{ paddingLeft: 5 }}>
            <BfIcon type="bf" data={"arrow_drop_down"} size="lg" />
          </div>
        </button>
      ) : (
        <span className="text" title={props.text}>
          {props.text}
        </span>
      )}

      {props.depth !== 0 &&
        new Array(props.depth).fill(0).map((_, index, arr) => (
          <div
            key={index}
            style={{ right: 15 * (index + 1) - 3 }}
            className={`line ${
              index === arr.length - 1 && props.nextDepth < props.depth
                ? "end"
                : ""
            }`}
          >
            {index === arr.length - 1 && <div className={"line-left"} />}
          </div>
        ))}
    </CashBudgetCell>
  );
};

interface ValueProps extends CommonCellProps {
  cellBackground?: string;
  text: string;
  value: number;
  rowIdentString: string;
  bold?: boolean;
  isNegative?: boolean;
  rowData?: any;
  topBorder?: boolean;
  selected?: boolean;
  progress?: number;
  overdoneClass?: string;
  opacity?: number;
  depth?: number;
  nextDepth?: number;
  renderLeft?: React.ReactNode;
  onChange?: (value: number) => void;
  renderOnHover?: () => React.ReactNode;
  onHoverEnterFunction?: (rowIndex: number) => void;
  onHoverLeaveFunction?: (rowIndex: number) => void;
  onCellDblClick?: (rowIndex: number, rowData?: any) => void;
  onCellClick?: (cmdModifier: boolean, shiftModifier: boolean) => void;
}
export const CashBudgetValueCell = (props: ValueProps) => {
  const timeout = useRef(null);
  // const [timeoutId, setTimeoutId] = useState(null);
  const [open, setOpen] = useState(false);
  const cell = (
    <div
      style={{ paddingRight: props.depth * 10 + 10 }}
      className={`cell-content ${props.isNegative ? "negative" : ""}`}
    >
      <div className={`type`}>{props.rowIdentString} </div>
      {typeof props.progress === "number" && (
        <div
          className={classNames("progress", {
            [props.overdoneClass || "overdone"]: props.progress > 1,
          })}
        >
          {isNaN(props.progress)
            ? `- `
            : StringUtils.formatNumber(
                props.progress === 0 ? 0 : props.progress * 100,
                undefined,
                undefined,
                0,
                0
              )}
          %
        </div>
      )}
      {props.renderLeft && <div className={`left`}>{props.renderLeft}</div>}
      <div className={`value`} style={{ opacity: props.opacity }}>
        {props.text}
      </div>
      {props.depth !== 0 &&
        new Array(props.depth).fill(0).map((_, index, arr) => (
          <div
            key={index}
            style={{ right: 10 * (index + 1) - 3 }}
            className={`line ${
              index === arr.length - 1 && props.nextDepth < props.depth
                ? "end"
                : ""
            }`}
          >
            {index === arr.length - 1 && <div className={"line-left"} />}
          </div>
        ))}
    </div>
  );

  return (
    <CashBudgetCell
      value={props.value}
      onChange={props.onChange}
      cellBackground={props.cellBackground}
      onClick={props.onCellClick}
      onDblClick={
        props.onCellDblClick
          ? () => props.onCellDblClick(props.rowIndex)
          : undefined
      }
      isSubHead={props.isSubHead}
      rowIndex={props.rowIndex}
      rowHeight={props.rowHeight}
      refFC={props.refFC}
      onHoverEnterFunction={(rowIndex) => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
          setOpen(true);
        }, 500);
        props.onHoverEnterFunction(rowIndex);
      }}
      onHoverLeaveFunction={(rowIndex) => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        setOpen(false);
        props.onHoverLeaveFunction(rowIndex);
      }}
      className={`value ${props.selected ? "selected" : ""} ${
        props.onCellDblClick ? "clickable" : ""
      } ${props.className || ""} ${props.bold ? "bold" : ""} ${
        props.topBorder ? "border-top-solid" : ""
      }`}
    >
      {!props.renderOnHover && cell}
      {props.renderOnHover && (
        <BFWhisper
          speaker={
            <div className="cashbudget-hover-container">
              {props.renderOnHover()}
            </div>
          }
          open={open}
        >
          {cell}
        </BFWhisper>
      )}
      <DebugDataComponent data={props.rowData} />
    </CashBudgetCell>
  );
};

import CashBudgetUtils from "@/apps/tatar/cashBudget/CashBudgetUtils";
import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import FormWrapper from "@/modules/generic-forms-impl/FormWrapper";
import AssetService from "@/services/AssetService";
import { ComponentsMapper } from "@/utils/ComponentsMapper";
import CBPortfolioUtils, {
  CB_P_LOANS_TABLE_IDENTIFIER,
} from "../../CBPortfolioUtils";
import { PortfolioLoan } from "../../interfaces/CBPortfolioAsset";
// import "./CBPortfolioLoanForm.scss";

interface CBPortfolioLoanFormProps {
  asset?: PortfolioLoan;
  kind: ObjectKind;
  onClose: () => void;
  onSuccess: (value: any) => void;
  onDelete?: () => void;
}
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!! This is an obsolete component, never ever you should copy that !!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
const CBPortfolioLoanForm = (props: CBPortfolioLoanFormProps) => {
  const additionalDataForm = {
    ...CashBudgetUtils.generateAdditionalDataForm(),
    ...CBPortfolioUtils.generateAdditionalFormData(),
  };

  return (
    <div className={`cb-portfolio-loan-form`}>
      <FormWrapper
        oType="asset"
        assetType={AssetTypes.Portfolio.Loan}
        resetOnSubmit
        formSelector={"creationForm"}
        identifier={"cb-portfolio-loan-form"}
        additionalData={additionalDataForm}
        actionIdMapping={{
          submit: "create-portfolio-loan-form-submit",
        }}
        onSubmitSuccess={(value) => {
          props.onSuccess(value);
          props.onClose();
        }}
        onSubmitError={{
          a1: {
            eventKey: "'TOAST'",
            data: {
              type: "error",
              textKey: "cb:Portfolio.CreateLoan.ErrorMessage",
              duration: 3500,
            },
          },
        }}
        formData={
          props.asset
            ? {
                ...props.asset,
              }
            : {
                data: {
                  type: props.kind?.data.type || props.asset?.data.type,
                  loanData: {
                    paymentsPerYear: 12,
                    // loanAmount: 2290000,
                    // loanPayoutDate: moment(
                    //   "28.11.2021",
                    //   "DD.MM.YYYY"
                    // ).toISOString(),
                    // loanStartDate: moment(
                    //   "01.06.2022",
                    //   "DD.MM.YYYY"
                    // ).toISOString(),
                    // annualInterest: 1.29,
                    // loanTermYears: 28,
                    // paymentAmount: 8186.75,
                  },
                },
              }
        }
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 10,
          marginTop: 10,
        }}
      >
        {props.asset && (
          <BFButton
            appearance="outline"
            onClick={async () => {
              await AssetService.deleteAsset({
                assetId: props.asset._id,
                assetType: AssetTypes.Portfolio.Loan,

                tablesToReload: [CB_P_LOANS_TABLE_IDENTIFIER],
                confirmText: i18n.t("cb:DeleteLoan.confirmText"),
              });

              props.onDelete();
            }}
          >
            {i18n.t("cb:DeleteLoan.Title")}
          </BFButton>
        )}

        <div className={`fill`} />
        <BFButton onClick={() => props.onClose()} appearance="outline">
          {i18n.t("Global.Buttons.cancel")}
        </BFButton>
        {ComponentsMapper.createElement(
          {
            _component: "action",
            actionId: "create-portfolio-loan-form-submit",
            orderIndex: 2,
            textKey: props.asset
              ? "cb:Portfolio.UpdateLoan.SubmitMessage"
              : "cb:Portfolio.CreateLoan.SubmitMessage",
            appearance: "primary",
          },
          undefined,
          undefined
        )}
      </div>
    </div>
  );
};

export default CBPortfolioLoanForm;

import BaseAsset from "@/model/general-assets/BaseAsset";
import BFFormSection from "@/modules/abstract-ui/data/form-section/BFFormSection";
import { isDefined, translateNonGenerate } from "@/utils/Helpers";
import classNames from "classnames";
import { CustomField, Customfield_section } from "../CustomField.interface";
import "./CFSection.scss";

interface CFSectionProps {
  data: Customfield_section;

  prefix?: string;
  asset?: BaseAsset;
  assetType?: string;
  renderField?: (field: CustomField) => JSX.Element;
}
const CFSection = (props: CFSectionProps) => {
  return (
    <div className={classNames(`cf-section`)}>
      <BFFormSection
        title={translateNonGenerate(props.data.title)}
        initialOpen={
          isDefined(props.data.initialOpen) ? props.data.initialOpen : true
        }
        disableCollapse={
          isDefined(props.data.collapsible) ? !props.data.collapsible : true
        }
      >
        <div>{props.data.fields.map((e) => props.renderField?.(e))}</div>
      </BFFormSection>
    </div>
  );
};

export default CFSection;

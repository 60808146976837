import classNames from "classnames";
import { LexoRank } from "lexorank";
import { nanoid } from "nanoid";
import { useRef, useState } from "react";
import { Trans } from "react-i18next";
import Collapse from "rsuite/esm/Animation/Collapse";
import i18n from "../../../../../i18n";
import BFInput from "../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import { useTypedSelector } from "../../../../../redux/hooks";
import SubmitService from "../../../../../services/SubmitService";
import { AssetCashBudgetEntry } from "../../model/CashBudgetEntry";
import "./CBBookingCommentForm.scss";

interface CBBookingCommentFormProps {
  commentFormVisible: boolean;
  entry: AssetCashBudgetEntry;
  onFormClose: () => void;
  assetType: string;
  tableToUpdate?: string;
}

const CBBookingCommentForm = (props: CBBookingCommentFormProps) => {
  const { commentFormVisible, onFormClose, entry, assetType, tableToUpdate } =
    props;
  const [commentText, setCommentText] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const ref = useRef(null);
  const userDisplayname = useTypedSelector(
    (state) => state.global.user.displayname
  );
  const submitComment = () => {
    if (submitLoading) {
      return;
    }
    setSubmitLoading(true);

    const comments = entry.data.comments
      ? Object.entries(entry.data.comments)
          .filter((entry) => entry[1] !== null)
          .sort((a, b) =>
            LexoRank.parse(a[1].orderIndex).compareTo(
              LexoRank.parse(b[1].orderIndex)
            )
          )
      : [];

    const lastLexoRank =
      comments.length > 0
        ? LexoRank.parse(comments[comments.length - 1][1].orderIndex)
        : LexoRank.middle();

    const submitData = {
      _id: entry._id,
      data: {
        comments: {
          [nanoid()]: {
            comment: commentText,
            creator: userDisplayname,
            createdOn: new Date().toISOString(),
            orderIndex: lastLexoRank.genNext().toString(),
          },
        },
      },
    };
    SubmitService.submitData({
      id: entry._id,
      data: submitData,
      type: "asset",
      assetType: assetType,
      pushToCache: true,
      pushToTableCache: tableToUpdate,
      onSuccess: (data) => {
        setCommentText("");
        setSubmitLoading(false);
        onFormClose();
      },
      onError: (err) => {
        setSubmitLoading(false);
      },
      ignorePropChecks: true,
    });
  };

  return (
    <div ref={ref} className={classNames("cb-booking-comment-form")}>
      <Collapse
        in={commentFormVisible}
        unmountOnExit={true}
        onEntered={() =>
          ref.current.querySelector(".comment-form textarea").focus()
        }
      >
        <div>
          <div className={`comment-form`}>
            <BFInput
              type="textarea"
              value={commentText}
              onChange={(value) => {
                setCommentText(value.toString());
              }}
            ></BFInput>
            <div className={`comment-actions`}>
              <BFButton
                onClick={() => {
                  onFormClose();
                  setCommentText("");
                }}
              >
                <Trans i18nKey="Global.Buttons.cancel" />
              </BFButton>
              <BFButton
                onClick={() => submitComment()}
                loading={submitLoading}
                disabled={commentText.trim() === ""}
                appearance="primary"
              >
                {i18n.t("Global.Buttons.submit")}
              </BFButton>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default CBBookingCommentForm;

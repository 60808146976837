import { useLottie } from "lottie-react";
import { CSSProperties } from "react";
import successAnimation from "./successAnimation.json";
interface Props {
  style?: CSSProperties;
}
const SuccessAnimation = (props: Props) => {
  const LoadingAnimation = useLottie(
    {
      animationData: successAnimation,
      loop: false,
    },
    props.style
  );
  return LoadingAnimation.View;
};
export default SuccessAnimation;

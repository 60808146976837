import BaseAsset from "@/model/general-assets/BaseAsset";
import classNames from "classnames";
import { CustomField, Customfield_row } from "../CustomField.interface";
import "./CFRow.scss";

interface CFRowProps {
  data: Customfield_row;

  prefix?: string;
  asset?: BaseAsset;
  assetType?: string;
  renderField?: (field: CustomField) => JSX.Element;
}

const CFRow = (props: CFRowProps) => {
  return (
    <div className={classNames(`cf-row`)}>
      {props.data.fields.map((field, index) => (
        <div
          className={`cf-row-entry`}
          style={{ flex: `${props.data.flex?.[index] || 1} 0` }}
        >
          {props.renderField(field)}
          {/* <CFBase
            asset={props.asset}
            assetType={props.assetType}
            key={field.id}
            field={field}
            prefix={props.prefix}
          /> */}
        </div>
      ))}
    </div>
  );
};

export default CFRow;

import StarIcon from "@rsuite/icons/legacy/Star";
import StarOutlineIcon from "@rsuite/icons/legacy/StarO";
import { useState } from "react";
import { IconButton } from "rsuite";
import AssetLoader from "../../../components/AssetLoader/AssetLoader";
import i18n from "../../../i18n";
import BaseAsset from "../../../model/general-assets/BaseAsset";
import { useTypedSelector } from "../../../redux/hooks";
import CommentsService from "../CommentsService";
import "./FollowButton.scss";

interface FollowButtonProps {
  assetType: string;
  assetId: string;
}
const FollowButton = (props: FollowButtonProps) => {
  const [loading, setLoading] = useState(false);
  const myId = useTypedSelector((state) => state.global.user._id);

  return (
    <AssetLoader
      assetType={props.assetType}
      id={props.assetId}
      renderError={() => null}
      render={(asset: BaseAsset) => {
        const isFollowing = asset.communication?.user_follower?.includes(myId);
        const onClick = async () => {
          try {
            setLoading(true);
            if (isFollowing) {
              await CommentsService.unfollow(props.assetType, props.assetId);
            } else {
              await CommentsService.follow(props.assetType, props.assetId);
            }
          } finally {
            setLoading(false);
          }
        };
        return (
          <IconButton
            className={`follow-button`}
            loading={loading}
            size="xs"
            appearance="ghost"
            icon={isFollowing ? <StarIcon /> : <StarOutlineIcon />}
            onClick={onClick}
          >
            {isFollowing
              ? i18n.t("FollowButton.followed", "Gemerkt")
              : i18n.t("FollowButton.follow", "Merken")}
          </IconButton>
        );
      }}
    />
  );
};

export default FollowButton;

import classNames from "classnames";
import React from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from "react-beautiful-dnd";
import "./BFOrderList.scss";

interface BFOrderListProps {
  value: any[];
  onChange: (value: any[]) => void;
  idSelector: string;

  listId?: string;

  renderValue: (value: any) => React.ReactElement;
}
const BFOrderList = (props: BFOrderListProps) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      props.value,
      result.source.index,
      result.destination.index
    );
    props.onChange(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={props.listId || "bf-order-list"}
        renderClone={(provided, snapshot, descriptor) => (
          <BFOrderListEntry
            renderValue={props.renderValue}
            value={props.value[descriptor.source.index]}
            draggableProvided={provided}
            draggableSnapshot={snapshot}
          />
        )}
      >
        {(droppableProvided, droppableSnapshot) => (
          <div className={`bf-order-list`} ref={droppableProvided.innerRef}>
            {props.value.map((item, index) => (
              <Draggable
                key={item[props.idSelector]}
                draggableId={item[props.idSelector]}
                index={index}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <BFOrderListEntry
                    renderValue={props.renderValue}
                    value={item}
                    draggableProvided={draggableProvided}
                    draggableSnapshot={draggableSnapshot}
                  />
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default BFOrderList;

const BFOrderListEntry = (props: {
  renderValue: (value: any) => React.ReactElement;
  value: any;
  draggableProvided: DraggableProvided;
  draggableSnapshot: DraggableStateSnapshot;
}) => {
  return (
    <div
      ref={props.draggableProvided.innerRef}
      {...props.draggableProvided.draggableProps}
      {...props.draggableProvided.dragHandleProps}
      className={classNames(`bf-order-list-item`, {
        "is-dragging": props.draggableSnapshot.isDragging,
      })}
      style={props.draggableProvided.draggableProps.style}
    >
      {props.renderValue(props.value)}
    </div>
  );
};

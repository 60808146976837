import ProgressLoader from "@/components/Loaders/ProgressLoader/ProgressLoader";
import ErrorAnimation from "@/components/LottieAnimations/ErrorAnimation/ErrorAnimation";
import LoadingAnimation from "@/components/LottieAnimations/Loading/LoadingAnimation";
import SendLetterAnimation from "@/components/LottieAnimations/SendLetter/SendLetterAnimation";
import SuccessAnimation from "@/components/LottieAnimations/Success/SuccessAnimation";
import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import classNames from "classnames";
import { useEffect, useState } from "react";
import "./ProgressModal.scss";

const ANIMATION_STYLE = {
  height: 200,
  width: 300,
};
const ANIMATION_STYLE_LOADER = {
  height: 200,
  width: 150,
};

export type ProgressAnimationType = "letter" | "loading";
interface ProgressModalProps {
  animationType: ProgressAnimationType;
  loadingText?: React.ReactNode;

  title?: string;
  entries: any[];
  progressEntry: (entry: any) => Promise<void>;

  renderErrorInfo?: (entries: any[]) => React.ReactNode;

  onClose: (errorsOnEntries?: any[]) => void;
}
const ProgressModal = (props: ProgressModalProps) => {
  const [runningEntries, setRunningEntries] = useState(props.entries);
  const [showFinish, setShowFinish] = useState(false);
  const { progress, status, results } = useProgressProcess(
    runningEntries,
    props.progressEntry
  );

  useEffect(() => {
    setShowFinish(false);
  }, [runningEntries]);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setShowFinish(true);
      }, 500);
    }
  }, [progress]);

  const animationType = props.animationType || "loading";
  const color = progress === 100 ? "#52c41a" : "#3385ff";

  if (showFinish) {
    const fails = results.filter((r) => r.result === "fail");
    return (
      <div className={classNames(`progress-modal`)}>
        <div className={`animation-container`}>
          {status === "success" ? (
            <SuccessAnimation style={ANIMATION_STYLE} />
          ) : (
            <ErrorAnimation style={ANIMATION_STYLE} />
          )}
        </div>
        {props.title && <div className={`title`}>{props.title}</div>}
        <div className={`info`}>
          {props.renderErrorInfo ? (
            props.renderErrorInfo(fails.map((r) => r.entry))
          ) : (
            <>
              {fails.length === 0 && (
                <>
                  {i18n.t(
                    "ProgressModal.SuccessLabel",
                    "Der Vorgang wurde erfolgreich abgeschlossen."
                  )}
                </>
              )}
              {fails.length > 0 && (
                <>
                  {fails.length === 1
                    ? i18n.t(
                        "ProgressModal.ErrorLabelSingle",
                        "Es ist ein Fehler aufgetreten."
                      )
                    : i18n.t(
                        "ProgressModal.ErrorLabelMultiple",
                        "Es sind {{countErrors}} Fehler aufgetreten.",
                        {
                          countErrors: fails.length,
                        }
                      )}
                </>
              )}
            </>
          )}
        </div>
        <div className={`actions`}>
          {status === "fail" && (
            <BFButton
              appearance="outline"
              onClick={() => {
                props.onClose(
                  results.filter((r) => r.result === "fail").map((r) => r.entry)
                );
              }}
            >
              {i18n.t("Global.Buttons.close")}
            </BFButton>
          )}
          <div className={`fill`} />
          {status === "fail" && (
            <BFButton
              appearance="primary"
              onClick={() => {
                setRunningEntries(
                  results.filter((r) => r.result === "fail").map((r) => r.entry)
                );
              }}
            >
              {i18n.t("ProgressModal.Retry", "Wiederholen")}
            </BFButton>
          )}
          {status === "success" && (
            <BFButton appearance="primary" onClick={() => props.onClose()}>
              {i18n.t("ProgressModal.Close", "Schließen")}
            </BFButton>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(`progress-modal`)}>
      <div className={`animation-container`}>
        {animationType === "loading" && (
          <LoadingAnimation style={ANIMATION_STYLE_LOADER} />
        )}
        {animationType === "letter" && (
          <SendLetterAnimation style={ANIMATION_STYLE_LOADER} />
        )}
      </div>
      {props.title && <div className={`title`}>{props.title}</div>}
      <div className={`info`}>
        {props.loadingText ? (
          props.loadingText
        ) : (
          <>
            {i18n.t(
              "ProgressModal.DescriptionLine1",
              "Bitte haben Sie einen Moment Geduld."
            )}
          </>
        )}
      </div>
      <div>
        <ProgressLoader
          percent={progress}
          strokeColor={color}
          status={status}
          showInfo={false}
        />
      </div>
      <div className={`progress-count`}>
        {results.length}/{runningEntries.length}
      </div>
    </div>
  );
};

export default ProgressModal;

type ResultEntry = {
  entry: any;
  result: "success" | "fail";
};
type ResultState = {
  results: ResultEntry[];
  status: "success" | "fail" | "active";
  progress: number;
};
export const useProgressProcess = (
  entries: any[],
  progressEntry: (entry: any) => Promise<void>
) => {
  const [queue, setQueue] = useState(entries);
  const [result, setResult] = useState<ResultState>({
    results: [],
    status: "active",
    progress: 0,
  });
  useEffect(() => {
    setResult((a) => {
      return {
        results: [],
        status: "active",
        progress: 0,
      };
    });
    setQueue(entries);
  }, [entries]);

  useEffect(() => {
    const process = async () => {
      if (queue.length === 0) {
        return;
      }
      const entry = queue[0];
      try {
        await progressEntry(entry);
        setResult((r: ResultState) => {
          const newResults: ResultEntry[] = [
            ...r.results,
            {
              entry,
              result: "success",
            },
          ];

          let status = "active";
          if (newResults.length === entries.length) {
            if (newResults.every((e) => e.result === "success")) {
              status = "success";
            } else {
              status = "fail";
            }
          }

          return {
            results: newResults,
            status,
            progress: ((r.results.length + 1) / entries.length) * 100,
          } as ResultState;
        });
      } catch (e) {
        setResult((r: ResultState) => {
          const newResults: ResultEntry[] = [
            ...r.results,
            {
              entry,
              result: "fail",
            },
          ];

          const status =
            newResults.length === entries.length
              ? newResults.every((e) => e.result === "success")
                ? "success"
                : "fail"
              : "active";
          return {
            results: newResults,
            status,
            progress: ((r.results.length + 1) / entries.length) * 100,
          } as ResultState;
        });
      }
      setQueue((q) => q.slice(1));
    };

    if (queue.length > 0) {
      process();
    }
  }, [queue]);

  return {
    progress: result.progress,
    status: result.status,
    results: result.results,
  };
};

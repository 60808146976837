import BaseAsset from "@/model/general-assets/BaseAsset";
import CDNService from "@/services/CDNService";
import PromiseLoader from "../PromiseLoader/PromiseLoader";
// import "./CDNPDFViewer.scss";
import { useMemo } from "react";
import PDFViewer, { PDFHighlight } from "./PDFViewer";
import { PDFHightlightState, PDFViewerEditState } from "./PDFViewerHooks";

interface CDNPDFViewerProps {
  asset: BaseAsset;
  cdnID: string;
  assetField: string;
  assetType: string;

  identifier?: string;
  height?: number | string;
  highlights?: PDFHighlight[];
  download?: boolean;
  scrollGroup?: string;
  scrollIdentifier?: string;
  zoomFactorStorageKey?: string;
  border?: boolean;
  pdfHighlightState?: PDFHightlightState;
  pdfViewerEditState?: PDFViewerEditState;
}
const CDNPDFViewer = (props: CDNPDFViewerProps) => {
  const { asset, assetField, assetType, cdnID, ...other } = props;

  const fileKey = asset.cdn.find((e) => e.id === cdnID)?.key;
  const filename = asset.cdn.find((e) => e.id === cdnID)?.filename;

  const param = useMemo(
    () => ({
      assetField: props.assetField,
      assetId: props.asset?._id,
      assetType: props.assetType,
      cdnId: props.cdnID,
      filekey: fileKey,
    }),
    [props.assetField, props.asset?._id, props.assetType, props.cdnID]
  );
  return (
    <PromiseLoader
      param={param}
      promise={(param) =>
        CDNService.fetchCDNLink({
          assetField: param.assetField,
          assetId: param.asset?._id,
          assetType: param.assetType,
          cdnId: param.cdnID,
          hasFolderReadPermissions: true,
          fileKey: param.filekey,
        })
      }
      render={(url) => <PDFViewer {...other} url={url} filename={filename} />}
    />
  );
};

export default CDNPDFViewer;

import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import ModalManager from "@/components/ModalComponent/ModalManager";
import HistoryGraph from "@/modules/abstract-ui/data/history-graph/HistoryGraph";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import classNames from "classnames";
import AssetLoader from "../../../../../../../components/AssetLoader/AssetLoader";
import LoadPage from "../../../../../../../components/LoadPage/LoadPage";
import WrappedText from "../../../../../../../components/WrappedText/WrappedText";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import { useAssetList } from "../../../../../../../redux/hooks";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../utils/StringUtils";
import {
  RentalAgreement,
  RentalAgreementTimePeriod,
  RentalUnit,
} from "../../TenantsInterfaces";
import {
  RentalUnitTableEntry,
  RentalUnitTableHeader,
} from "./CBRentalAgreementHelper";
import "./CBRentalAgreementPeriods.scss";
import CBRentalAgreementRentChange from "./CBRentalAgreementRentChange";

interface CBRentalAgreementPeriodsProps {
  rentalAgreement: RentalAgreement;
  kind: ObjectKind;
}
const CBRentalAgreementPeriods = (props: CBRentalAgreementPeriodsProps) => {
  const object = OrgaStruct.getObject(props.rentalAgreement.data.objectId);
  const objectKind = ObjectKindStruct.getKind(object.objectKindId);

  const data = useAssetList<RentalAgreementTimePeriod>(
    AssetTypes.Rental.RentalAgreementPeriod,
    MQ.and(
      MQ.eq("data.status", "active"),
      MQ.eq("data.rentalAgreement", props.rentalAgreement._id)
    )
  );
  if (!data || data.state === "loading") {
    return <LoadPage />;
  }
  if (data?.state === "error") {
    return (
      <div className={`cb-rental-agreement-periods __card error-page`}>
        {i18n.t(
          "cb:RentalAgreementPeriods.error",
          "Fehler beim Laden der Mietpreis Übersicht"
        )}
      </div>
    );
  }
  return (
    <div className={classNames(`cb-rental-agreement-periods __card`)}>
      <HistoryGraph
        data={data.data.map((e) => ({
          id: e._id,
          data: e,
          from: e.data.from,
          to: e.data.to,
        }))}
        renderContent={(period, type, previousPeriod) => {
          const addedRentalUnits = period.data.data.rentalUnitIds.filter(
            (unitId) =>
              !(previousPeriod?.data.data.rentalUnitIds || []).includes(unitId)
          );
          const removedRentalUnits = (
            previousPeriod?.data.data.rentalUnitIds || []
          ).filter(
            (unitId) => !period.data.data.rentalUnitIds.includes(unitId)
          );

          return (
            <div className={`cb-rental-agreement-period`}>
              <div className={`rent-data`}>
                <div className={`entry-list rent`}>
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t(
                        "cb:RentalAgreement.Labels.rentNet",
                        "Miete netto"
                      )}
                    </div>
                    <div className={`value`}>
                      <span className={`should`}>
                        {StringUtils.formatCurrency(
                          period?.data?.data.rentNet,
                          true
                        )}
                      </span>
                    </div>
                  </div>
                  {props.rentalAgreement.data.taxable && (
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t(
                          "cb:RentalAgreement.Labels.rentGross",
                          "Miete brutto"
                        )}
                      </div>
                      <div className={`value`}>
                        <span className={`should`}>
                          {StringUtils.formatCurrency(
                            period.data.data.rentGross,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t(
                        "cb:RentalAgreement.Labels.operatingCostNet",
                        "Nebenkosten netto"
                      )}
                    </div>
                    <div className={`value`}>
                      <span className={`should`}>
                        {StringUtils.formatCurrency(
                          period.data.data.operatingCostNet,
                          true
                        )}
                      </span>
                    </div>
                  </div>

                  {props.rentalAgreement.data.taxable && (
                    <div className={`entry`}>
                      <div className={`label`}>
                        {i18n.t(
                          "cb:RentalAgreement.Labels.operatingCostGross",
                          "Nebenkosten brutto"
                        )}
                      </div>
                      <div className={`value`}>
                        <span className={`should`}>
                          {StringUtils.formatCurrency(
                            period.data.data.operatingCostNet,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {addedRentalUnits.length > 0 && (
                <div className={`rental-units added`}>
                  <div className={`__h4`}>
                    {i18n.t(
                      "cb:RentalAgreement.Labels.addedRentalUnits",
                      "Hinzugefügte Mieteinheiten"
                    )}
                  </div>
                  {/* <RentalUnitTableHeader /> */}

                  <RentalUnitTableHeader />
                  {addedRentalUnits.map((rentalUnit) => (
                    <AssetLoader
                      assetType={AssetTypes.Rental.RentalUnit}
                      id={rentalUnit}
                      render={(rentalUnit: RentalUnit) => (
                        <RentalUnitTableEntry rentalUnit={rentalUnit} asLink />
                      )}
                    />
                  ))}
                </div>
              )}
              {removedRentalUnits.length > 0 && (
                <div className={`rental-units added`}>
                  <div className={`__h4`}>
                    {i18n.t(
                      "cb:RentalAgreement.Labels.removedRentalUnits",
                      "Entfernte Mieteinheiten"
                    )}
                  </div>
                  {/* <RentalUnitTableHeader /> */}

                  <RentalUnitTableHeader />
                  {removedRentalUnits.map((rentalUnit) => (
                    <AssetLoader
                      assetType={AssetTypes.Rental.RentalUnit}
                      id={rentalUnit}
                      render={(rentalUnit: RentalUnit) => (
                        <RentalUnitTableEntry rentalUnit={rentalUnit} asLink />
                      )}
                    />
                  ))}
                </div>
              )}
              {period.data.note && (
                <>
                  <div className={`__h4 margin-top-10`}>
                    {i18n.t("cb:RentalAgreement.Labels.note", "Notiz")}
                  </div>
                  <WrappedText text={period.data.note} />
                </>
              )}
            </div>
          );
        }}
        renderHeader={(period, type, previousPeriod) => {
          const reason = ObjectKindStruct.getCatalog(
            objectKind._id,
            "immo",
            "rentalChangeReason"
          ).find((e) => e.value === period.data.reason);

          return (
            <div>
              {reason?.label ||
                i18n.t(
                  "cb:RentalAgreementPeriod.defaultReason",
                  "automatischer Mietzeitraum"
                )}
            </div>
          );
        }}
        headerActions={
          <BFButton
            appearance="outline"
            onClick={() => {
              ModalManager.show({
                backdrop: "static",
                noPadding: true,
                size: "lg",
                content: (states, setStates, closeModal) => (
                  <CBRentalAgreementRentChange
                    onClose={closeModal}
                    kind={props.kind}
                    rentalAgreementId={props.rentalAgreement._id}
                  />
                ),
              });
            }}
          >
            {i18n.t(
              "cb:RentalAgreementPeriods.addPeriod",
              "Neuen Mietzeitraum hinzufügen"
            )}
          </BFButton>
        }
      />
    </div>
  );
};

export default CBRentalAgreementPeriods;

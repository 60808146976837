import { Component } from "react";
import { Checkbox } from "rsuite";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import "./BFCheckbox.scss";

export type CheckboxAppearance = "default" | "modern";
type Props = {
  size?: "xs" | "sm" | "md" | "lg";
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  inline?: boolean;
  name?: string;
  onChange?: (value: any, checked: boolean, event: any) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  title?: string;
  value?: any;
  appearance?: CheckboxAppearance;
  defaultChecked?: boolean;
  readOnly?: boolean;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
};

type States = {};

class BFCheckbox extends Component<Props, States> {
  render() {
    const { validation, validatorStyle, className, appearance, size, ...rest } =
      this.props;

    if ((rest.checked as any) === "") {
      rest.checked = null;
    }

    return (
      <ValidationPopover
        validatorStyle={validatorStyle}
        level={validation ? validation.level : "error"}
        message={validation ? validation.message : null}
      >
        <Checkbox
          className={`${className ? className : ""} ${
            size ? "size-" + size : ""
          } ${appearance ? "appearance-" + appearance : "appearance-default"}`}
          {...rest}
        >
          {" "}
          {this.props.children}
        </Checkbox>
      </ValidationPopover>
    );
  }
}

// dirty fix: changing the display name in order to get the allProperties set by the checkbox group as intended ( https://github.com/rsuite/rsuite/blob/6095d1f61c8d014349d7e0e3d3e639f202e5fed5/src/CheckboxGroup.js#L67 )
(BFCheckbox as any).displayName = "Checkbox";

export default BFCheckbox;

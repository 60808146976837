import { ThunkDispatch } from "redux-thunk";
import { GalleryData } from "../../../components/CDNImageGallery/GlobalCDNImageGallery";
import { ALLOWED_FILETYPES_DOCUMENT_VIEWER } from "../../../components/DocumentViewer/DocumentViewerOptions";
import { Application } from "../../../model/db/Application";
import { initializeApplication } from "../../../services/ApplicationService";
import PermissionService from "../../../services/PermissionService";
import FileUtils from "../../../utils/FileUtils";
import { DefaultUIConfigs } from "../../reducers/ui-config/UiConfig";
import {
  ADD_DEBUG_ACTION,
  CLOSE_DOCUMENT_VIEWER,
  OPEN_DOCUMENT_VIEWER,
  SET_ACTIVE_APPLICATION,
  SET_UI_CONFIG,
} from "./ui-config-actions-types";

export function openGallery(gallery: GalleryData) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(setUiConfig(DefaultUIConfigs.GLOBAL_CDN_GALLERY, gallery));
  };
}
export function closeGallery() {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(setUiConfig(DefaultUIConfigs.GLOBAL_CDN_GALLERY, null));
  };
}

export function setUiConfig(key: string, value: any) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_UI_CONFIG,
      key,
      value,
    });
  };
}

export function setActiveApplication(application: Application) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_ACTIVE_APPLICATION,
      application,
    });
    PermissionService.setApplication(
      application === null ? null : application._id
    );
    dispatch(initializeApplication(application));
  };
}

export function addDebugAction(name: string, action: () => void) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: ADD_DEBUG_ACTION,
      name,
      action,
    });
  };
}

export function openOrDownloadDocument(
  data: string | object,
  fileType: string,
  fileName?: string,
  forceDownload = false
) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    if (
      (forceDownload ||
        !ALLOWED_FILETYPES_DOCUMENT_VIEWER.some((e) => e.type === fileType)) &&
      typeof data === "string"
    ) {
      FileUtils.downloadFile(data, fileName, true);
    } else {
      const viewerType = ALLOWED_FILETYPES_DOCUMENT_VIEWER.find(
        (e) => e.type == fileType
      ).viewer;
      // available document for viewer
      dispatch({
        type: OPEN_DOCUMENT_VIEWER,
        data,
        fileName,
        fileType,
        viewerType,
      });
    }
  };
}
export function closeDocumentViewer() {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: CLOSE_DOCUMENT_VIEWER,
    });
  };
}

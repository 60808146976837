import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  Route,
  RouteComponentProps,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import ListEntryComponent from "../../../../configurable/components/ListComponent/ListEntryComponent";
import BaseAsset from "../../../../model/general-assets/BaseAsset";
import { MatchQuery } from "../../../../services/DataService";
import MQ from "../../../../utils/MatchQueryUtils";
import { RSAssigneeFilterOption, Resubmission } from "../../RSInterfaces";
import RSService from "../../RSService";
import RSCreateButton from "../actions/RSCreateButton";
import RSDetailsComponent from "../details/RSDetailsComponent";
import RSFilterButtons from "../filter-buttons/RSFilterButtons";
import RSListEntry from "../list-entry/RSListEntry";
import RSList from "../resubmission-list/RSList";
import "./RSListDetailsComponent.scss";

interface RSListDetailsComponentProps {
  asset: BaseAsset;
  assetType: string;
  listIdentifier: string;
  assetDescription: string;
  renderResubmissionAssetDetails?: (
    resubmission: Resubmission
  ) => React.ReactNode;
  typePermissions?: string[];
}

type MatchParams = {
  resubmissionId: string;
};

const RSListDetailsComponent = (props: RSListDetailsComponentProps) => {
  const defaultMatchQuery = RSService.getDefaultMatchQuery({
    assetType: props.assetType,
    assetId: props.asset?._id,
  });

  const history = useHistory();
  const match = useRouteMatch();

  const [assigneeFilter, setAssigneeFilter] =
    useState<RSAssigneeFilterOption>("own");
  const [additionalMatchQuery, setAdditionalMatchQuery] =
    useState<MatchQuery>(defaultMatchQuery);

  useEffect(() => {
    const assigneeMatchQuery = RSService.getAssigneeMatchQuery(assigneeFilter);
    let defMatchQuery =
      assigneeFilter === "done"
        ? RSService.getDefaultMatchQuery({
            assetType: props.assetType,
            assetId: props.asset?._id,
            status: ["done"],
          })
        : defaultMatchQuery;
    setAdditionalMatchQuery(
      MQ.combine("and", [defMatchQuery, assigneeMatchQuery])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneeFilter]);

  const handleOnClickAssigneeFilter = (filter: RSAssigneeFilterOption) => {
    setAssigneeFilter(filter);
  };

  const handleOnClickResubmissionEntry = (resubmission: Resubmission) => {
    history.push(`${match.url}/${resubmission._id}`);
  };

  const handleOnCloseResubmission = (
    resubmissionId: string,
    routeProps: RouteComponentProps<MatchParams>
  ) => {
    const resubmissionParam = routeProps.match?.params?.resubmissionId;
    if (resubmissionParam === resubmissionId) {
      const url = routeProps.match.url;
      const newUrl = url.replace(`/${resubmissionParam}`, "");
      history.push(newUrl);
    }
  };

  const renderComponent = (routeProps: RouteComponentProps<MatchParams>) => {
    const resubmissionId = routeProps.match?.params?.resubmissionId;

    const handleOnClickClose = (resubmissionId: string) => {
      handleOnCloseResubmission(resubmissionId, routeProps);
    };

    return (
      <div className={classNames("rs-list-details-component")}>
        <div className="rs-list-container __card">
          <div className="rs-list-header">
            <div className="rs-list-title">
              <RSFilterButtons
                selectedFilter={assigneeFilter}
                onClickFilter={handleOnClickAssigneeFilter}
              />
            </div>
            <RSCreateButton
              identifier={props.listIdentifier}
              linkedAsset={{
                assetType: props.assetType,
                assetId: props.asset?._id,
                assetDescription: props.assetDescription,
              }}
              typePermissions={props.typePermissions}
            />
          </div>
          <div className="rs-list-content">
            <RSList
              identifier={props.listIdentifier}
              additionalMatchQuery={additionalMatchQuery}
              renderEntry={(entry: Resubmission) => {
                const isSelected = entry._id === resubmissionId;
                return (
                  <ListEntryComponent
                    isSelected={isSelected}
                    showDebugToggle={true}
                    entryData={entry}
                    onClickEntry={() => {
                      if (isSelected) {
                        handleOnClickClose(entry._id);
                      } else {
                        handleOnClickResubmissionEntry(entry);
                      }
                    }}
                    renderEntry={() => {
                      return <RSListEntry resubmission={entry} />;
                    }}
                  />
                );
              }}
            />
          </div>
        </div>

        {resubmissionId && (
          <div className={classNames("rs-details-container", "__card")}>
            <RSDetailsComponent
              resubmissionId={resubmissionId}
              renderAssetDetails={props.renderResubmissionAssetDetails}
              listIdentifier={props.listIdentifier}
              closeResubmission={handleOnClickClose}
              renderSubAssetDataOnly={true}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <Route path={`${match.url}/:resubmissionId`}>
      {(routeProps: RouteComponentProps<MatchParams>) => {
        return renderComponent(routeProps);
      }}
    </Route>
  );
};

export default RSListDetailsComponent;

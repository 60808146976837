import { ActivityApplicationConstants } from "@/apps/tatar/activityApp/ActivityHooks";
import { APActivity } from "@/apps/tatar/activityApp/ActivityInterfaces";
import APActivityDetailsRoot from "@/apps/tatar/activityApp/views/details/APActivityDetailsRoot";
import { BaseConstants, useSpecificConstants } from "@/redux/hooks";
import { useEffect } from "react";
import AssetDetailModal from "../../../components/AssetDetailModal/AssetDetailModal";
import { AssetTypes } from "../../../model/AssetTypes";
import { openDetailsAction } from "../../GlobalDetailsFunctions";
import "./APVacancyDetailModal.scss";

const ROUTE_NAME = "__asset_activity-vacancy";

const APVacancyDetailModal = () => {
  useEffect(() => {
    openDetailsAction(AssetTypes.Activity.Vacancy, ROUTE_NAME);
  }, []);

  return (
    <AssetDetailModal
      size="full"
      assetType={AssetTypes.Activity.Vacancy}
      routeName={ROUTE_NAME}
      className="ap-vacancy-detail-modal"
      render={(node: any) => {
        return (
          <div className="activity-root-container">
            <VacancyActivityDetail activity={node} />
          </div>
        );
      }}
    />
  );
};

export default APVacancyDetailModal;

type Props = {
  activity: APActivity;
};

const VacancyActivityDetail = (props: Props) => {
  const { activity } = props;

  const vacancyConstants = useSpecificConstants<
    { serviceUrl: string } & BaseConstants
  >("app-vacancy");

  return (
    <APActivityDetailsRoot
      activity={activity}
      constants={vacancyConstants as ActivityApplicationConstants}
    />
  );
};

import React, { FC } from "react";
import { Loader } from "rsuite";
import { OpenLayerLoaderProps } from "./types";
import styles from "./OpenLayerLoader.module.scss";

const OpenLayerLoader: FC<OpenLayerLoaderProps> = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className={styles.loader_wrapper}>
      <Loader size="md" />
    </div>
  );
};

export default OpenLayerLoader;

import _ from "lodash";
import { createContext, useContext, useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AdminUser } from "../apps/administration/AdminInterfaces";
import { QueryAllAssetsResponse } from "../model/common/HttpModel";
import CacheService from "../services/CacheService";
import DataBus from "../services/DataBus";
import {
  MatchQuery,
  queryAllAssets,
  selectQueryAllAssets,
} from "../services/DataService";
import {
  SocketCommand,
  UpdateCommand,
  UpdateCommandSingle,
} from "../services/socket/NotificationInterface";
import SocketService from "../services/socket/SocketService";
import { usePrevious } from "../utils/Hooks";
import { HTTP } from "../utils/Http";
import MQ from "../utils/MatchQueryUtils";
import StringUtils from "../utils/StringUtils";
import {
  queryData,
  setFlexCacheData,
} from "./actions/application/application-actions";
import { ExpandKey } from "./actions/application/application-infinite-table-actions";
import {
  getQueryData,
  selectCacheAsset,
  selectCacheGroup,
  selectCacheTeam,
  selectCacheUser,
} from "./actions/application/application-selectors";
import { AggregationStatisticQuerySelector } from "./model";
import { CacheData } from "./reducers/application/ApplicationInterface";
import { DefaultUIConfigs } from "./reducers/ui-config/UiConfig";
import { AppState, store } from "./store";

export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;

export interface StatisticResult<T> {
  loading: boolean;
  queryId: string;
  data?: T;
  deprecateOn?: Date;
  error?: any;
  reload: () => void;
}
export const useStatisticQuery = <T>(
  name: string,
  version: number | null,
  variables: any,
  deprecateOn?: Date,
  referenceId?: string,
  ignoreQuery?: boolean
): StatisticResult<T> => {
  const dispatch = useDispatch();
  const forceReload = () => {
    if (ignoreQuery) {
      return;
    }
    dispatch(
      queryData(name, version, variables, deprecateOn, true, referenceId)
    );
  };
  useEffect(() => {
    if (ignoreQuery) {
      return;
    }
    dispatch(
      queryData(name, version, variables, deprecateOn, false, referenceId)
    );
  }, [name, version, variables, deprecateOn, ignoreQuery]);
  const data = useTypedSelector((state: AppState) =>
    getQueryData(state, name, version, variables, referenceId)
  );

  return {
    ...(data || { loading: true }),
    reload: forceReload,
  } as StatisticResult<T>;
};

export const useAggregationStatisticQuery = <AggregationStatisticQueryResult>(
  assetType: string,
  matchQuery: MatchQuery,
  selectors: AggregationStatisticQuerySelector[],
  referenceId?: string,
  textQuery?: string,
  ignoreQuery?: boolean,
  expandKeys?: (ExpandKey | string)[]
): StatisticResult<AggregationStatisticQueryResult> => {
  const result = useStatisticQuery<AggregationStatisticQueryResult>(
    "KPI_QUERY",
    1,
    {
      //fixme add textquery
      query: {
        matchQuery: matchQuery ? matchQuery : undefined,
        textQuery,
        expandKeys: expandKeys?.map((e) =>
          typeof e === "string" ? { key: e } : e
        ),
      },
      assetType,
      selector: selectors,
    },
    undefined,
    referenceId,
    ignoreQuery
  );
  return result;
};

export const useAggregationTableQuery = <AggregationStatisticQueryResult>(
  id: string,
  selectors: AggregationStatisticQuerySelector[],
  referenceId?: string,
  expandKeys?: (ExpandKey | string)[]
): StatisticResult<AggregationStatisticQueryResult> => {
  const tableCache = useTypedSelector(
    (state) => state.application.infiniteTables?.[id]
  );

  const aggregated =
    useAggregationStatisticQuery<AggregationStatisticQueryResult>(
      tableCache?.url?.substring(tableCache?.url?.lastIndexOf("/") + 1),
      MQ.combine("and", [
        tableCache?.additionalMatchQuery,
        MQ.combine(
          "and",
          tableCache?.filterStatus?.map((e) => e.matchQuery)
        ),
      ]),
      selectors,
      referenceId,
      tableCache?.searchTerm,
      !tableCache?.url,
      expandKeys
    );
  return aggregated;
};

export const useStatisticQueryMock = <T>(
  name: string,
  version: number,
  variables: any,
  mockResult: T
): StatisticResult<T> => {
  return {
    loading: false,
    queryId: "any",
    data: mockResult,
    reload: () => {},
  };
};
export interface HttpCache<T> {
  state: "loading" | "success" | "error";
  data?: T;
  error?: any;
  timestamp?: Date;
}
export const clearHttpCache = (cacheKey: string) => {
  store.dispatch(setFlexCacheData("httpCache", cacheKey, null));
};
export const updateHttpCache = (cacheKey: string, data: any) => {
  store.dispatch(setFlexCacheData("httpCache", cacheKey, data));
};
export const useHttp = <T>(
  cacheKey: string,
  url: string,
  type: "post" | "get" = "get",
  queryParams?: { [key: string]: any },
  bodyParams?: { [key: string]: any },
  conditionalIgnore = false,
  transformFc?: (data: any) => any
) => {
  return useHttpCache<T>(
    cacheKey,
    url,
    type,
    queryParams,
    bodyParams,
    conditionalIgnore,
    transformFc,
    true
  );
};

export const useHttpCache = <T>(
  cacheKey: string,
  url: string,
  type: "post" | "get" = "get",
  queryParams?: { [key: string]: any },
  bodyParams?: { [key: string]: any },
  conditionalIgnore = false,
  transformFc?: (data: any) => any,
  invalidateOnUnmount = false
) => {
  const dispatch = useDispatch();
  const data = useTypedSelector(
    (state) => state.application.cache?.flex?.["httpCache"]?.[cacheKey]
  ) as HttpCache<T>;

  useEffect(() => {
    if (!conditionalIgnore) {
      if (
        !store.getState().application.cache?.flex?.["httpCache"]?.[cacheKey]
      ) {
        loadData();
      }
    }
  }, [cacheKey, conditionalIgnore]);

  const invalidate = () => {
    dispatch(setFlexCacheData("httpCache", cacheKey, null));
  };
  useEffect(() => {
    return () => {
      if (invalidateOnUnmount) {
        invalidate();
      }
    };
  }, [cacheKey]);
  const loadData = async () => {
    try {
      dispatch(setFlexCacheData("httpCache", cacheKey, { state: "loading" }));
      const result = (await HTTP[type]({
        target: "EMPTY",
        url,
        queryParams,
        bodyParams,
      })) as T;

      let resultTransformed = transformFc?.(result) || result;

      dispatch(
        setFlexCacheData("httpCache", cacheKey, {
          state: "success",
          data: resultTransformed,
          timestamp: new Date(),
        })
      );
    } catch (err) {
      dispatch(
        setFlexCacheData("httpCache", cacheKey, {
          state: "error",
          error: err,
          timestamp: new Date(),
        })
      );
    }
  };
  if (conditionalIgnore) {
    return null;
  }

  return {
    ...(data || { state: "loading" }),
    reload: loadData,
    invalidate,
  };
};

type AssetCacheData<T> = CacheData<T> & {
  reload: (silent?: boolean) => Promise<void>;
  error?: any;
  selector?: string;
};

export const useAssetCache = <T>(
  assetType: string,
  idOrQuery: string | MatchQuery,
  silentReload?: boolean,
  global?: boolean,
  ignoreDelay?: boolean
): AssetCacheData<T> => {
  const dispatch = useDispatch();

  const loadData = (force: boolean, silent?: boolean) => {
    return CacheService.getData({
      oType: "asset",
      assetType,
      forceReload: force,
      checkTables: true,
      global: global,
      ignoreDelay: ignoreDelay,
      silentReload: silent !== undefined ? silent : silentReload,
      ...(typeof idOrQuery === "string"
        ? { id: idOrQuery }
        : { query: idOrQuery }),
    });
  };
  useEffect(() => {
    loadData(false);
  }, [assetType, idOrQuery]);

  const data = useTypedSelector((state: AppState) =>
    selectCacheAsset(state, assetType, idOrQuery)
  );

  return {
    ...(data || { state: "loading" }),
    reload: async (silent: boolean) => await loadData(true, silent),
    selector: CacheService.generateQueryId(assetType, idOrQuery),
  };
};

export const useUserCache = (
  id: string,
  silentReload?: boolean
): AssetCacheData<AdminUser> => {
  const dispatch = useDispatch();

  const loadData = (force: boolean) => {
    if (StringUtils.isObjectId(id)) {
      CacheService.getData({
        oType: "user",
        forceReload: force,
        checkTables: true,
        silentReload: silentReload,
        id,
      });
    }
  };
  useEffect(() => {
    loadData(false);
  }, [id]);

  const data = useTypedSelector((state: AppState) =>
    selectCacheUser(state, id)
  );
  if (!StringUtils.isObjectId(id)) {
    return null;
  }

  return {
    ...(data || { state: "loading" }),
    reload: async () => await loadData(true),
  };
};

export const useTeamCache = <T>(
  id: string,
  silentReload?: boolean
): AssetCacheData<T> => {
  const dispatch = useDispatch();

  const loadData = (force: boolean) => {
    if (StringUtils.isObjectId(id)) {
      CacheService.getData({
        oType: "team",
        forceReload: force,
        checkTables: true,
        silentReload: silentReload,
        id,
      });
    }
  };
  useEffect(() => {
    loadData(false);
  }, [id]);

  const data = useTypedSelector((state: AppState) =>
    selectCacheTeam(state, id)
  );
  if (!StringUtils.isObjectId(id)) {
    return null;
  }

  return {
    ...(data || { state: "loading" }),
    reload: async () => await loadData(true),
  };
};

export const useGroupCache = <T>(
  id: string,
  silentReload?: boolean
): AssetCacheData<T> => {
  const dispatch = useDispatch();

  const loadData = (force: boolean) => {
    if (StringUtils.isObjectId(id)) {
      CacheService.getData({
        oType: "group",
        forceReload: force,
        checkTables: true,
        silentReload: silentReload,
        id,
      });
    }
  };
  useEffect(() => {
    loadData(false);
  }, [id]);

  const data = useTypedSelector((state: AppState) =>
    selectCacheGroup(state, id)
  );
  if (!StringUtils.isObjectId(id)) {
    return null;
  }

  return {
    ...(data || { state: "loading" }),
    reload: async () => await loadData(true),
  };
};

type AssetCacheDataMultiple<T> = {
  data: CacheData<T>[];
  reload: () => void;
  error?: any;
};
export const useAssetCacheMultiple = <T>(
  assetType: string,
  idOrQueries: (string | MatchQuery)[]
): AssetCacheDataMultiple<T> => {
  const dispatch = useDispatch();

  const loadData = (force: boolean) => {
    (idOrQueries || []).forEach((idOrQuery) => {
      CacheService.getData({
        oType: "asset",
        assetType,
        forceReload: force,
        ...(typeof idOrQuery === "string"
          ? { id: idOrQuery }
          : { query: idOrQuery }),
      });
    });
  };
  useEffect(() => {
    loadData(false);
  }, [assetType, idOrQueries]);

  const data = useTypedSelector((state: AppState) =>
    (idOrQueries || []).map(
      (idOrQuery) =>
        selectCacheAsset(state, assetType, idOrQuery) || { state: "loading" }
    )
  );

  return { data, reload: () => loadData(true) };
};

export const useAssetList = <T>(
  assetType: string,
  query?: MatchQuery,
  sort?: { fieldName: string; sortKey: 1 | -1 }[]
) => {
  const dispatch = useDispatch();
  const prevQuery = usePrevious(query);
  const prevSort = usePrevious(sort);
  useEffect(() => {
    if (!_.isEqual(prevQuery, prevQuery) || !_.isEqual(sort, prevSort)) {
      dispatch(queryAllAssets(assetType, query, sort));
    }
  }, [query, sort]);

  useEffect(() => {
    dispatch(queryAllAssets(assetType, query, sort));
  }, []);

  const result = useTypedSelector((state) =>
    selectQueryAllAssets(state, assetType, query, sort)
  );

  return result as QueryAllAssetsResponse<T>;
};

export const useAppConfig = <T>() => {
  const config = useTypedSelector(
    (state: AppState) => state.application.constants["appConfig"]
  ) as T;

  return config;
};

export const useSocket = (
  dataType: string,
  callback: (cmd: SocketCommand) => void,
  dependendArgs: any[]
) => {
  useEffect(() => {
    const subId = SocketService.subscribe(dataType, callback);
    return () => {
      SocketService.unsubscribe(subId);
    };
  }, dependendArgs || []);
};

export const useAssetSocket = (
  assetType: string,
  callback: (cmd: UpdateCommandSingle) => void,
  dependendArgs: any[]
) => {
  useEffect(() => {
    const subId = SocketService.subscribeAsset(
      assetType,
      (cmd: UpdateCommand) => {
        if (Array.isArray(cmd.objectID)) {
          cmd.objectID.forEach((id) => {
            callback({
              ...cmd,
              objectID: id,
            });
          });
        } else {
          callback(cmd as UpdateCommandSingle);
        }
      }
    );
    return () => {
      SocketService.unsubscribe(subId);
    };
  }, dependendArgs || []);
};
export const useAssetSocketNotSplitted = (
  assetType: string,
  callback: (cmd: UpdateCommand) => void,
  dependendArgs: any[],
  splitObjectIds = true
) => {
  useEffect(() => {
    const subId = SocketService.subscribeAsset(
      assetType,
      (cmd: UpdateCommand) => {
        callback(cmd);
      }
    );
    return () => {
      SocketService.unsubscribe(subId);
    };
  }, dependendArgs || []);
};

export const useViewportSelector = () => {
  const viewport = useTypedSelector(
    (state: AppState) =>
      state.uiConfig.general[DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR]
  );
  return viewport as "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
};

export const useDatabus = (
  key: string,
  callback: (data: any) => void,
  dependendArgs?: any[]
) => {
  useEffect(() => {
    const subId = DataBus.subscribe(key, callback);
    return () => {
      DataBus.unsubscribe(subId);
    };
  }, dependendArgs || []);
};
export type BaseConstants = {
  businessUnits: string[];
  format?: string;
  currency?: string;
  areaUnit?: string;
};

export const ConstantsContext = createContext(null);

export const useConstants = <T extends BaseConstants>(
  forceCurrentApplication = false
) => {
  const constantsByContext = useContext(ConstantsContext);
  const constants = useTypedSelector(
    (state) => state.uiConfig?.activeApplication?.constants
  ) as T;
  if (forceCurrentApplication) {
    return constants;
  }
  return constantsByContext || constants;
};

export const useSpecificConstants = <T extends BaseConstants>(appName) => {
  const constantsByApp = useTypedSelector(
    (state) =>
      state.global.user.mandator_info.apps.find((e) => e.name === appName)
        ?.constants
  );
  return constantsByApp as T;
};

export const useUserIds = (field = "_id") => {
  const user = useTypedSelector((state) => state.global.user);
  const replacedByUsers =
    user.replacementFor?.map((e) => e.data.replacedUser) || [];

  const ids = [user._id, ...replacedByUsers];

  return {
    userId: user._id,
    ids,
    mq: MQ.in(field, ids),
    canReplace: (id: string[] | string) => {
      const idsToCheck = Array.isArray(id) ? id : [id];
      return idsToCheck.some((e) => ids.includes(e));
    },
  };
};

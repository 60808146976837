import classNames from "classnames";
import {
  MailIncomingComment,
  MailOutgoingComment,
} from "../../../../../modules/comments-module/Comments";
import CommentsModule from "../../../../../modules/comments-module/CommentsModule";
import { useActivityConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";
import { useActivityStruct } from "../../ActivityStructContext";
import "./APCommunication.scss";
import APDuplicateMessageToActivity from "./duplicate-mail/APDuplicateMessageToActivity";

interface Props {
  activity: APActivity;
}

// TODO SJ DISCUSS APPROACH WITH BENNI
// onClose is a callback from much deeper in the tree
// onehand component APDuplicateMessageToActivity is a modal itself
// otherhand CommentContainer defines that a modal will be shown
const APCommunication = (props: Props) => {
  const constants = useActivityConstants();
  const activityStruct = useActivityStruct();

  return (
    <div className={classNames(`ap-activity-communication`)}>
      <CommentsModule
        allowMailUpload
        identifier={`ap-activity-communication`}
        assetType={constants?.assetType}
        assetId={props.activity._id}
        type={props.activity.data.type}
        subactivity="force-on-mail"
        renderMailDuplicateAction={(
          comment: MailIncomingComment | MailOutgoingComment,
          onClose: () => void
        ) => (
          <APDuplicateMessageToActivity
            activityStruct={activityStruct}
            mail={comment}
            onClose={onClose}
          />
        )}
      />
    </div>
  );
};

export default APCommunication;

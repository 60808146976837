import { useEffect, useState } from "react";
import BaseAsset from "../../../../model/general-assets/BaseAsset";
import LanguageService from "../../../../services/LanguageService";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import BFUseTooltip from "../../../abstract-ui/general/tooltip/BFUseTooltip";
import BfIcon from "../../../abstract-ui/icon/BfIcon";
import {
  DSDirectoryFlattenMapValue,
  DocumentStoreAssetParams,
  DocumentStoreDirectory,
} from "../../DSInterfaces";
import DSService from "../../DSService";
import "./DSUnmetRequirementsColumn.scss";

type DSUnmentRequirementsColumnProps = {
  onClick?: (asset: BaseAsset) => void;
  flattenDirectoryMap: Map<string, DSDirectoryFlattenMapValue>;
  assetParams: DocumentStoreAssetParams;
  directory: DocumentStoreDirectory;
  renderEmpty?: () => JSX.Element;
};

const DSUnmentRequirementsColumn = (props: DSUnmentRequirementsColumnProps) => {
  const documents = DSService.getDocumentsFromAsset(props.assetParams);
  const documentIds = documents.map((document) => document.id).join(",");

  const [unmetRequirements, setUnmetRequirements] = useState([]);

  useEffect(() => {
    if (props.directory) {
      const documentsMap = DSService.getDirectoryDocumentMap(
        documents,
        props.directory
      );

      const directoriesWithUnmetRequirements =
        DSService.findUnmetRequirementsInDescendants(
          documentsMap,
          props.directory,
          props.flattenDirectoryMap
        );

      setUnmetRequirements(directoriesWithUnmetRequirements);
    }
  }, [documentIds, props.directory]);

  if (unmetRequirements.length === 0) {
    if (props.renderEmpty) {
      return props.renderEmpty();
    }
    return null;
  }

  return (
    <div className="ds-unmet-requirements-column">
      <BFButton
        disabled={!props.onClick}
        onClick={() => {
          props.onClick?.(props.assetParams.asset);
        }}
        appearance="clear"
      >
        <BFUseTooltip
          delay={500}
          trigger="hover"
          placement="bottom"
          tooltip={
            <div className="ds-unmet-requirements-tooltip">
              {unmetRequirements.map((e: DocumentStoreDirectory) => (
                <div key={e.id} className="tooltip-entry">
                  {LanguageService.translateLabel(
                    e.directoryRequirements.requiredErrorMessage
                  )}
                </div>
              ))}
            </div>
          }
        >
          <div className="ds-unmetrequirements-icon">
            <BfIcon type="light" data="folder-question" size="sm" />
          </div>
        </BFUseTooltip>
      </BFButton>
    </div>
  );
};

export default DSUnmentRequirementsColumn;

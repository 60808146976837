export const SUBTRACT_DAYS_DEFAULT = 14;

export enum INVOICE_EXTRACT_KEYS {
  contact = "contact",
  usage = "usage",
  supplierCompanyName = "supplierCompanyName",
  supplierAddress = "supplierAddress",
  supplierPhoneNumber = "supplierPhoneNumber",
  supplierEmail = "supplierEmail",
  supplierWebsite = "supplierWebsite",

  customerBillingAddress = "customerBillingAddress",
  customerPhoneNumber = "customerPhoneNumber",
  customerEmail = "customerEmail",
  customerWebsite = "customerWebsite",

  customerNumber = "customerNumber",
  customerCompanyName = "customerCompanyName",
  bankAccountNumber = "bankAccountNumber",
  bankSortCode = "bankSortCode",
  bankIban = "bankIban",
  bankSwift = "bankSwift",
  paymentDateDue = "paymentDateDue",
  invoiceDate = "invoiceDate",
  invoiceNumber = "invoiceNumber",
  currencyCode = "currencyCode",
  paymentAmountTotal = "paymentAmountTotal",
}

import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import { ContactFilterOption } from "@/modules/abstract-ui/data/table-filter/defaults/ContactFilterOption";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import { FilterOptionWithMatchQueryConverter } from "../../../../../../../modules/abstract-ui/data/table-filter/BFTableFilterModel";
import { BooleanFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/BooleanFilterOption";
import { DateFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/DateFilterOption";
import { DefaultFilterOptions } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/DefaultFilterOptions";
import { DocumentsFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/DocumentsFilterOption";
import { ListFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/ListFilterOption";
import { NumberFilterOption } from "../../../../../../../modules/abstract-ui/data/table-filter/defaults/NumberFilterOption";
import { ListSelectionValueWithNegation } from "../../../../../../../modules/abstract-ui/data/table-filter/overlays/OverlayMultipleSelectionWithNegation";
import LanguageService from "../../../../../../../services/LanguageService";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import { getConfigRentalUnitTypeGroup } from "../../CBTenantsConst";
import { allRentalUnitTypeGroups } from "../../TenantsInterfaces";

export const CBRentalAgreementsFilterOptions: (
  kind: ObjectKind
) => FilterOptionWithMatchQueryConverter[] = (kind) => {
  const filterTypes = [kind.data.type];
  const rentalUnitTypeGroups = allRentalUnitTypeGroups.map((group) => {
    const conf = getConfigRentalUnitTypeGroup(group);
    const data = ObjectKindStruct.getUnitTypesByGroup(kind._id, group);
    return {
      value: group,
      label: conf.label,
      data: data.map((unitType) => unitType.id),
    };
  });

  const unitTypes = ObjectKindStruct.getUnitTypeByObjectKind(kind._id).map(
    (unitType) => ({
      value: unitType.id,
      label: LanguageService.translateLabel(unitType.displayName),
    })
  );

  return [
    ...DefaultFilterOptions(),
    DocumentsFilterOption({
      assetFieldPath: "data.attachments",
      assetType: AssetTypes.Rental.RentalAgreement,
      filterTypes: filterTypes || [],
      label: i18n.t("cb:RentalAgreement.documents", "Dokumente"),
      allowNegation: true,
      onlyFieldsWithRequirement: true,
    }),
    ListFilterOption({
      field: "expand.rentalUnits.data.unitTypeGroup",
      label: i18n.t("cb:RentalUnit.unitTypeGroup", "Nutzart"),
      data: rentalUnitTypeGroups,
      allowNegation: true,
      matchQueryConverter: (value: ListSelectionValueWithNegation) =>
        value && value.value && value.value.length > 0
          ? MQ[value.negate ? "nin" : "in"](
              "expand.rentalUnits.data.unitType",
              value.value
                .map(
                  (v) => rentalUnitTypeGroups.find((e) => e.value === v)?.data
                )
                .reduce((prev, cur) => [...prev, ...cur], [])
            )
          : undefined,
    }),
    ContactFilterOption({
      field: "data.tenant",
      label: i18n.t("cb:RentalUnit.tenant", "Mieter"),
      filterTypes: filterTypes,
    }),
    ListFilterOption({
      field: "expand.rentalUnits.data.unitType",
      label: i18n.t("cb:RentalUnit.unitType", "Art"),
      data: unitTypes,
    }),
    ListFilterOption({
      field: "data.contractType",
      label: i18n.t("cb:RentalAgreement.contractType", "Vertragsart"),
      data: [
        {
          value: "business",
          label: i18n.t(
            "cb:RentalAgreement.contractType.business",
            "Gewerblich"
          ),
        },
        {
          value: "private",
          label: i18n.t("cb:RentalAgreement.contractType.private", "Privat"),
        },
      ],
    }),
    DateFilterOption({
      field: "data.moveIn",
      label: i18n.t("cb:RentalAgreement.moveIn"),
    }),
    DateFilterOption({
      field: "data.moveOut",
      label: i18n.t("cb:RentalAgreement.moveOut"),
    }),
    DateFilterOption({
      field: "data.agreementExpiration",
      label: i18n.t("cb:RentalAgreement.agreementExpiration"),
    }),
    NumberFilterOption({
      field: "data.balance",
      label: i18n.t("cb:RentalAgreement.balance", "Kontostand"),
      type: "currency",
    }),
    NumberFilterOption({
      field: "data.rentGross",
      label: i18n.t("cb:RentalAgreement.rentGross"),
      type: "currency",
    }),
    NumberFilterOption({
      field: "data.rentNet",
      label: i18n.t("cb:RentalAgreement.rentNet"),
      type: "currency",
    }),
    NumberFilterOption({
      field: "data.operatingCostGross",
      label: i18n.t("cb:RentalAgreement.operatingCostGross"),
      type: "currency",
    }),
    NumberFilterOption({
      field: "data.operatingCostNet",
      label: i18n.t("cb:RentalAgreement.operatingCostNet"),
      type: "currency",
    }),
    BooleanFilterOption({
      field: "data.taxable",
      label: i18n.t("cb:RentalAgreement.taxable", "Steuerpflichtig"),
      defaultValue: true,
    }),
  ];
};

import classNames from "classnames";
import React from "react";
import { translateNonGenerate } from "../../../utils/Helpers";
import BFOverlay from "../general/whisper/BFOverlay";
import BfIcon from "../icon/BfIcon";
import "./LabeledInput.scss";

type InputLabelType = "normal" | "sub";

type Props = {
  label: string;
  children: React.ReactNode;
  labelPosition?: "top" | "left" | "right" | "bottom";
  labelGrow?: boolean;
  suffix?: React.ReactNode;
  error?: boolean;
  className?: string;
  type?: InputLabelType;
  info?: React.ReactNode;
};

const LabeledInput: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  let { label, children, labelPosition, labelGrow, error, className } = props;

  labelPosition = labelPosition || "top";
  labelGrow = labelGrow || false;

  if (!label) {
    return <>{children}</>;
  } else {
    return (
      <div
        className={classNames(
          `labeled-input label-position-${labelPosition}`,
          { "error-label": error },
          className,
          { "type-sub": props.type === "sub" },
          { highlight: open }
        )}
      >
        <div className={`label ${labelGrow ? "grow" : ""}`}>
          <span>{translateNonGenerate(label, label)}</span>
          {props.suffix && <span className="suffix">{props.suffix}</span>}
          {props.info && (
            <BFOverlay
              trigger="click"
              onOpen={() => setOpen(true)}
              onExit={() => setOpen(false)}
              speaker={
                <div className={`labeled-input-info-overlay`}>{props.info}</div>
              }
            >
              <div className={`info-container`}>
                <BfIcon
                  className={`info-indicator`}
                  type="light"
                  data="information-circle"
                  size="xs"
                />
              </div>
            </BFOverlay>
          )}
        </div>
        {children}
      </div>
    );
  }
};
export default LabeledInput;

import classNames from "classnames";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "rsuite";
import Teamname from "../../../../components/AvatarComponent/Teamname";
import UserAvatar from "../../../../components/AvatarComponent/UserAvatar";
import Username from "../../../../components/AvatarComponent/Username";
import Log from "../../../../debug/Log";
import i18n from "../../../../i18n";
import { ListResponse } from "../../../../model/common/HttpModel";
import { TeamDisplay } from "../../../../model/db/Team";
import { UserDisplay } from "../../../../model/db/User";
import DataBus from "../../../../services/DataBus";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import { MatchQuery, requestListData } from "../../../../services/DataService";
import { DataBusSubKeys, UserStatus } from "../../../../utils/Constants";
import BFButton from "../../general/Button/BFButton";
import BFDropdown from "../../general/Dropdown/BFDropdown";
import BfIcon from "../../icon/BfIcon";
import BFInput from "../input/BFInput";
import { Assignment, TeamAssignment, UserAssignment } from "./BFAssignment";
import "./BFAssignmentSearch.scss";

interface SearchFieldProps {
  text: string;
  type: "user" | "team" | "both";
  onSelect: (value: Assignment) => void;
  value: (Assignment | string)[];
  teamContexts?: string[];
  identifier?: string;
  renderToggle: (toggleProps, ref) => JSX.Element;
  allowClear?: boolean;
  onlyActive?: boolean;
  asOverlay?: boolean;
}
const getEmptyAssignment = () =>
  ({
    id: null,
    name: i18n.t("BFAssignment.NoAssignment", "Keine Zuweisung"),
    // avatar :"urlToAvatar",
    type: "user",
  } as Assignment);
export const BFAssignmentSearch = (props: SearchFieldProps) => {
  const cancel = useRef({
    userCancel: { cancel: null },
    teamCancel: { cancel: null },
  });
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

  const timeout = useRef<NodeJS.Timeout>(null);
  const dispatch = useDispatch();
  const [userOptions, setUserOptions] =
    React.useState<ListResponse<UserAssignment>>(null);
  const [teamOptions, setTeamOptions] =
    React.useState<ListResponse<TeamAssignment>>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  const [search, setSearch] = React.useState<string>("");
  const [userLoading, setUserLoading] = React.useState(false);
  const [teamLoading, setTeamLoading] = React.useState(false);

  const onSelect = (value: Assignment) => {
    props.onSelect(value);
    DataBus.emit(
      DataBusSubKeys.DROPDOWN_TOGGLE,
      props.identifier || "bf-assignment-search"
    );
  };
  const onSearch = async (text: string) => {
    setSearch(text);
    if (props.type === "both" || props.type === "user") {
      setUserLoading(true);
    }
    if (props.type === "both" || props.type === "team") {
      setTeamLoading(true);
    }
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (cancel.current.userCancel.cancel) {
      cancel.current.userCancel.cancel();
    }
    if (cancel.current.teamCancel.cancel) {
      cancel.current.teamCancel.cancel();
    }
    timeout.current = setTimeout(() => {
      if (props.type === "both" || props.type === "user") {
        dispatch(
          requestListData(
            {
              identifier: "user-search",
              limit: 5,
              url: "/api/user",
              textQuery: text,
              matchQuery: {
                type: "and",
                query: [
                  {
                    type: "op",
                    op: "nin",
                    name: "_id",
                    value: props.value
                      .filter((e) =>
                        typeof e === "string" ? true : e.type === "user"
                      )
                      .map((e) => (typeof e === "string" ? e : e.id)),
                  },
                  ...(props.onlyActive
                    ? [
                        {
                          type: "op",
                          op: "eq",
                          name: "status",
                          value: UserStatus.ACTIVE,
                        } as MatchQuery,
                      ]
                    : []),
                ],
              },
              onSuccess: (data) => {
                setSelectedIndex(0);
                setUserLoading(false);
                setUserOptions({ ...data, data: formatUserOptions(data.data) });
              },
              onError: (error) => {
                Log.error("Error while loading users", error);
                setUserLoading(false);
                DataBusDefaults.toast({
                  type: "error",
                  text: "Fehler beim Laden der Benutzer",
                });
              },
            },
            cancel.current.userCancel
          )
        );
      }
      if (props.type === "both" || props.type === "team") {
        dispatch(
          requestListData(
            {
              identifier: "team-search",
              limit: 5,
              url: "/api/team",
              textQuery: text,
              matchQuery: {
                type: "and",
                query: [
                  {
                    type: "op",
                    op: "nin",
                    name: "_id",
                    value: props.value
                      .filter((e) =>
                        typeof e === "string" ? true : e.type === "team"
                      )
                      .map((e) => (typeof e === "string" ? e : e.id)),
                  },
                  // TODO ADD QUERY FOR ACTIVE TEAMS HERE
                  // ...(props.onlyActive
                  //   ? [
                  //       {
                  //         type: "op",
                  //         op: "eq",
                  //         name: "status",
                  //         value: UserStatus.ACTIVE,
                  //       } as MatchQuery,
                  //     ]
                  //   : []),
                  ...(props.teamContexts
                    ? [
                        {
                          type: "op",
                          op: "in",
                          name: "context",
                          value: props.teamContexts,
                        } as MatchQuery,
                      ]
                    : []),
                ],
              },
              onSuccess: (data) => {
                setTeamLoading(false);
                setTeamOptions({ ...data, data: formatTeamOptions(data.data) });
              },
              onError: (error) => {
                Log.error("Error while loading teams", error);
                setTeamLoading(false);
                DataBusDefaults.toast({
                  type: "error",
                  text: "Fehler beim Laden der Teams",
                });
              },
            },
            cancel.current.teamCancel
          )
        );
      }
    }, 300);
  };

  return (
    <div
      ref={ref}
      className={classNames(`bf-assignment-search-field`, {
        "has-value": props.value.length > 0,
      })}
    >
      <BFDropdown
        className="bf-assignment-search-field-as-overlay"
        asOverlay={props.asOverlay}
        identifier={props.identifier || "bf-assignment-search"}
        // open={true}
        onOpen={() => {
          onSearch("");
          setTimeout(() => {
            (
              document.querySelector(
                ".bf-assignment-search input"
              ) as HTMLInputElement
            )?.focus();
            // ref.current?.querySelector("input")?.focus();
          }, 100);
        }}
        onClose={() => {
          setSearch("");
          setUserOptions(null);
          setTeamOptions(null);
          setSelectedIndex(0);
        }}
        label={props.text}
        renderToggle={(toggleProps, ref) =>
          props.renderToggle(toggleProps, ref)
        }
        items={[
          {
            type: "panel",
            children: (
              <div className={`bf-assignment-search search-overlay`}>
                <div className="search-input">
                  <BFInput
                    placeholder="Name eingeben"
                    appearance="clear"
                    prefix={<BfIcon data="search" type="light" size="xs" />}
                    value={search}
                    focusOnMount
                    onChange={(value: string) => onSearch(value)}
                    suffix={
                      <div className={`loading-suffix`}>
                        {(teamLoading || userLoading) && <Loader size="xs" />}
                      </div>
                    }
                    onKeyDown={(e) => {
                      const allOptionsLength =
                        (teamOptions?.data?.length || 0) +
                        (userOptions?.data?.length || 0);
                      if (e.key === "ArrowDown") {
                        e.preventDefault();
                        setSelectedIndex(
                          selectedIndex + (1 % allOptionsLength)
                        );
                      } else if (e.key === "ArrowUp") {
                        e.preventDefault();
                        setSelectedIndex(
                          (selectedIndex - 1 + allOptionsLength) %
                            allOptionsLength
                        );
                      } else if (e.key === "Enter") {
                        const assignment =
                          selectedIndex < (userOptions?.data?.length || 0)
                            ? userOptions?.data[selectedIndex]
                            : teamOptions?.data[
                                selectedIndex - (userOptions?.data?.length || 0)
                              ];
                        onSelect(assignment);
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                {(teamOptions !== null || userOptions !== null) && (
                  <div className={`search-result`}>
                    {search.length === 0 && props.allowClear && (
                      <AssignmentEntryListElement
                        onClick={() => onSelect(getEmptyAssignment())}
                        assignment={getEmptyAssignment()}
                        selected={props.value.length === 0}
                      />
                    )}
                    {search.length > 0 &&
                      (userOptions === null ||
                        userOptions?.data?.length === 0) &&
                      (teamOptions === null ||
                        teamOptions?.data?.length === 0) && (
                        <div className={`no-result`}>
                          {i18n.t(
                            "BFAssignmentSearch.noResult",
                            "Keine Ergebnisse"
                          )}
                        </div>
                      )}
                    {props.type === "both" && (
                      <>
                        {userOptions?.data?.length > 0 && (
                          <div className={`section user`}>
                            <div className={`section-title`}>
                              {i18n.t("BFAssignmentSearch.users", "Benutzer")}
                            </div>
                            <div className={`entries`}>
                              {userOptions?.data?.map((option, index) => (
                                <AssignmentEntryListElement
                                  onClick={() => onSelect(option)}
                                  assignment={option}
                                  selected={selectedIndex === index}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {teamOptions?.data?.length > 0 && (
                          <div className={`section team`}>
                            <div className={`section-title`}>
                              {i18n.t("BFAssignmentSearch.teams", "Teams")}
                            </div>
                            <div className={`entries`}>
                              {teamOptions?.data?.map((option, index) => (
                                <AssignmentEntryListElement
                                  onClick={() => onSelect(option)}
                                  assignment={option}
                                  selected={
                                    selectedIndex ===
                                    userOptions?.data?.length + index
                                  }
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {props.type !== "both" && (
                      <>
                        {userOptions?.data?.map((option, index) => (
                          <AssignmentEntryListElement
                            onClick={() => onSelect(option)}
                            assignment={option}
                            selected={selectedIndex === index}
                          />
                        ))}

                        {teamOptions?.data?.map((option, index) => (
                          <AssignmentEntryListElement
                            onClick={() => onSelect(option)}
                            assignment={option}
                            selected={selectedIndex === index}
                          />
                        ))}
                      </>
                    )}
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
interface AssignmentEntryListProps extends AssignmentEntryProps {
  selected: boolean;
  onClick: () => void;
}
const AssignmentEntryListElement = (props: AssignmentEntryListProps) => {
  return (
    <BFButton
      onClick={props.onClick}
      className={classNames("list-element", { selected: props.selected })}
      tabIndex="-1"
    >
      <AssignmentEntry assignment={props.assignment} />
    </BFButton>
  );
};

export interface AssignmentEntryProps {
  assignment: Assignment;
}
export const AssignmentEntry = (props: AssignmentEntryProps) => {
  return (
    <div className={`assignment-entry`}>
      <div className="prefix">
        {props.assignment.type === "user" && (
          <UserAvatar id={props.assignment.id} size={24} />
        )}
        {props.assignment.type === "team" && (
          <BfIcon data="multiple-users-1" type="light" size="xs" />
        )}
      </div>
      {props.assignment.type === "user" && (
        <div className="assignment-label">
          <Username id={props.assignment.id} />
        </div>
      )}
      {props.assignment.type === "team" && (
        <div className="assignment-label">
          <Teamname id={props.assignment.id} />
        </div>
      )}
    </div>
  );
};

const formatUserOptions = (options: UserDisplay[]) => {
  return options.map((e) => ({
    id: e._id,
    name: e.displayname,
    avatar: e.avatar,
    type: "user",
  })) as UserAssignment[];
};
const formatTeamOptions = (options: TeamDisplay[]) => {
  return options.map((e) => ({
    id: e._id,
    name: e.name,
    type: "team",
  })) as TeamAssignment[];
};

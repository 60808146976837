import classNames from "classnames";
import BfIcon, { BfIconProps } from "../../../abstract-ui/icon/BfIcon";
import "./EZTextTag.scss";

interface EZTextTagProps {
  className?: string;
  value: Date;
  onChange: (value: string) => void;
  onBlur?: (ev, value: string) => void;

  disabled?: boolean;
  error?: string;

  icon?: BfIconProps;
  size?: "xs";
  readonly?: boolean;
  placeholder?: string;
}
const EZTextTag = (props: EZTextTagProps) => {
  return (
    <div
      className={classNames(`ez-text-tag`, props.className, {
        "size-xs": props.size === "xs",
        error: props.error,
        editable: !props.disabled && !props.readonly,
      })}
    >
      {props.icon && (
        <div className={`prefix-icon`}>
          <BfIcon {...props.icon} />
        </div>
      )}
      <span
        className={`editable-text`}
        contentEditable={!props.disabled && !props.readonly}
        // onInput={(e) => {
        //   props.onChange(e.currentTarget.textContent);
        // }}
        suppressContentEditableWarning={true}
        onBlur={(e) => {
          props.onChange(e.currentTarget.textContent);
          props.onBlur(e, e.currentTarget.textContent);
        }}
      >
        {props.value || props.placeholder || "-"}
      </span>
    </div>
  );
};

export default EZTextTag;

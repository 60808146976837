import * as React from "react";

type AboutProps = {};
export const AboutComponent: React.FC<AboutProps> = (props) => {
  return (
    <div style={{ height: "100%", overflow: "auto", padding: 20 }}>
      <h4>Angaben gemäß § 5 TMG</h4>
      iberio GmbH
      <br /> Bahnhofstr. 1-3
      <br /> 33102 Paderborn
      <br /> Handelsregister: HRB 15057
      <br /> Registergericht: Amtsgericht Paderborn
      <br />
      <strong>Vertreten durch:</strong>
      Fabian Kuhn
      <h4>Kontakt</h4>
      E-Mail: info@iber.io
      <h4>Umsatzsteuer-ID</h4>
      Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
      DE340811148
      <h4>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h4>
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
      einer Verbraucherschlichtungsstelle teilzunehmen.
      <h5>Haftung für Inhalte</h5>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
      diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
      10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
      übermittelte oder gespeicherte fremde Informationen zu überwachen oder
      nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
      hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
      Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
      Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
      einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
      entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
      entfernen.
      <h5>Haftung für Links</h5>
      Unser Angebot enthält Links zu externen Websites Dritter, auf deren
      Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
      auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
      Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      <h5>Urheberrecht</h5>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
      Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
      Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
      privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
      dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
      Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
      gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
      aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
      Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
      entfernen. Quelle:{" "}
      <a href="https://www.e-recht24.de/impressum-generator.html">
        https://www.e-recht24.de/impressum-generator.html
      </a>
    </div>
  );
};

type PrivacyProps = {};

export const PrivacyComponent: React.FC<PrivacyProps> = (props) => {
  return (
    <div style={{ height: "100%", overflow: "auto", padding: 20 }}>
      <div>
        <h4>1. Datenschutz auf einen Blick</h4>
        <h5>Allgemeine Hinweise</h5>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
        <h5>Datenerfassung auf dieser Website</h5>
        <h6>
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?
        </h6>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis
        zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
        <h6>Wie erfassen wir Ihre Daten?</h6>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben. Andere Daten werden automatisch oder nach
        Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme
        erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser,
        Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
        Daten erfolgt automatisch, sobald Sie diese Website betreten.
        <h6>Wofür nutzen wir Ihre Daten?</h6>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
        <h6>Welche Rechte haben Sie bezüglich Ihrer Daten?</h6>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
        Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
        für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
        bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu
        weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns
        wenden.
        <h5>Analyse-Tools und Tools von Dritt­anbietern</h5>
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
        ausgewertet werden. Das geschieht vor allem mit sogenannten
        Analyseprogrammen. Detaillierte Informationen zu diesen
        Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
        <h4>2. Hosting und Content Delivery Networks (CDN)</h4>
        <h5>Externes Hosting</h5>
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
        Die personenbezogenen Daten, die auf dieser Website erfasst werden,
        werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.
        a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
        Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
        die über eine Website generiert werden, handeln. Der Einsatz des Hosters
        erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen
        und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse
        einer sicheren, schnellen und effizienten Bereitstellung unseres
        Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit.
        f DSGVO). Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie
        dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere
        Weisungen in Bezug auf diese Daten befolgen. Wir setzen folgenden Hoster
        ein: netcup GmbH Daimlerstraße 25 D-76185 Karlsruhe
        <h4>3. Allgemeine Hinweise und Pflicht­informationen</h4>
        <h5>Datenschutz</h5>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung. Wenn Sie diese Website benutzen, werden
        verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind
        Daten, mit denen Sie persönlich identifiziert werden können. Die
        vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und
        wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
        geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet
        (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
        kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
        nicht möglich.
        <h5>Hinweis zur verantwortlichen Stelle</h5>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist: iberio GmbH Bahnhofstr. 1-3 33098 O Telefon: [Telefonnummer der
        verantwortlichen Stelle] E-Mail: info@iber.io Verantwortliche Stelle ist
        die natürliche oder juristische Person, die allein oder gemeinsam mit
        anderen über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
        entscheidet.
        <h5>Speicherdauer</h5>
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere
        Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
        uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
        berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
        Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir
        keine anderen rechtlich zulässigen  Gründe für die Speicherung Ihrer
        personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
        Fortfall dieser Gründe.
        <h5>
          Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
        </h5>
        Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in
        den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten
        eingebunden. Wenn diese Tools aktiv sind, können Ihre personenbezogene
        Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir
        weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares
        Datenschutzniveau garantiert werden kann. Beispielsweise sind
        US-Unternehmen dazu verpflichtet, personenbezogene Daten an
        Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
        hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
        ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf
        US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten,
        auswerten und dauerhaft speichern. Wir haben auf diese
        Verarbeitungstätigkeiten keinen Einfluss.
        <h5>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h5>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unberührt.
        <h5>
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </h5>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
        DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
        IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
        AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
        IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
        DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
        DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
        VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). WERDEN
        IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
        SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
        BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
        EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER
        DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
        PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
        DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
        <h5>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h5>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
        <h5>Recht auf Daten­übertrag­barkeit</h5>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
        <h5>SSL- bzw. TLS-Verschlüsselung</h5>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw.
        TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
        übermitteln, nicht von Dritten mitgelesen werden.
        <h5>Auskunft, Löschung und Berichtigung</h5>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
        Daten können Sie sich jederzeit an uns wenden.
        <h5>Recht auf Einschränkung der Verarbeitung</h5>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
        an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
        folgenden Fällen:
        <ul>
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit,
            um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
            Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
            Daten zu verlangen.
          </li>
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
            geschah/geschieht, können Sie statt der Löschung die Einschränkung
            der Datenverarbeitung verlangen.
          </li>
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
            jedoch zur Ausübung, Verteidigung oder Geltendmachung von
            Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
            die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen.
          </li>
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abwägung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung
            Ihrer personenbezogenen Daten zu verlangen.
          </li>
        </ul>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
        haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
        Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
        verarbeitet werden.
        <h5>Widerspruch gegen Werbe-E-Mails</h5>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
        <h4>4. Datenerfassung auf dieser Website</h4>
        <h5>Cookies</h5>
        Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
        kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie
        werden entweder vorübergehend für die Dauer einer Sitzung
        (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
        gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch
        gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis
        Sie diese selbst löschen oder eine automatische Löschung durch Ihren
        Webbrowser erfolgt. Teilweise können auch Cookies von Drittunternehmen
        auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite betreten
        (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
        bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur
        Abwicklung von Zahlungsdienstleistungen). Cookies haben verschiedene
        Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
        Websitefunktionen ohne diese nicht funktionieren würden (z.B. die
        Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen
        dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen. Cookies,
        die zur Durchführung des elektronischen Kommunikationsvorgangs
        (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen
        erwünschter Funktionen (funktionale Cookies, z. B. für die
        Warenkorbfunktion) oder zur Optimierung der Website (z.B. Cookies zur
        Messung des Webpublikums) erforderlich sind, werden auf Grundlage von
        Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
        Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine
        Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die
        Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
        jederzeit widerrufbar. Sie können Ihren Browser so einstellen, dass Sie
        über das Setzen von Cookies informiert werden und Cookies nur im
        Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder
        generell ausschließen sowie das automatische Löschen der Cookies beim
        Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
        kann die Funktionalität dieser Website eingeschränkt sein. Soweit
        Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden,
        werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert
        informieren und ggf. eine Einwilligung abfragen.
        <h5>Server-Log-Dateien</h5>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
        <ul>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>IP-Adresse</li>
        </ul>
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
        an der technisch fehlerfreien Darstellung und der Optimierung seiner
        Website – hierzu müssen die Server-Log-Files erfasst werden.
        <h5>Kontaktformular</h5>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf
        Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
        Erfüllung eines Vertrags zusammenhängt oder zur Durchführung
        vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen
        beruht die Verarbeitung auf unserem berechtigten Interesse an der
        effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
        lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
        sofern diese abgefragt wurde. Die von Ihnen im Kontaktformular
        eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
        auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
        für die Datenspeicherung entfällt (z. B. nach abgeschlossener
        Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
        insbesondere Aufbewahrungsfristen – bleiben unberührt.
        <h5>Anfrage per E-Mail, Telefon oder Telefax</h5>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
        Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
        (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
        Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf Grundlage
        von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung
        eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
        Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
        Verarbeitung auf unserem berechtigten Interesse an der effektiven
        Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO)
        oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
        abgefragt wurde. Die von Ihnen an uns per Kontaktanfragen übersandten
        Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
        Einwilligung zur Speicherung widerrufen oder der Zweck für die
        Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres
        Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere
        gesetzliche Aufbewahrungsfristen – bleiben unberührt.
        <h4>5. Newsletter</h4>
        <h5>Newsletter­daten</h5>
        Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
        benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche
        uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen
        E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden
        sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis
        erhoben. Diese Daten verwenden wir ausschließlich für den Versand der
        angeforderten Informationen und geben diese nicht an Dritte weiter. Die
        Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten
        erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
        lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der
        E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können
        Sie jederzeit widerrufen, etwa über den „Austragen“-Link im Newsletter.
        Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
        bleibt vom Widerruf unberührt. Die von Ihnen zum Zwecke des
        Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
        Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter
        gespeichert und nach der Abbestellung des Newsletters oder nach
        Zweckfortfall aus der Newsletterverteilerliste gelöscht. Wir behalten
        uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem
        Ermessen im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1
        lit. f DSGVO zu löschen oder zu sperren. Nach Ihrer Austragung aus der
        Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem
        Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, um
        künftige Mailings zu verhindern. Die Daten aus der Blacklist werden nur
        für diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt.
        Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der
        Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern
        (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
        Speicherung in der Blacklist ist zeitlich nicht befristet.{" "}
        <strong>
          Sie können der Speicherung widersprechen, sofern Ihre Interessen unser
          berechtigtes Interesse überwiegen.
        </strong>
        <h4>6. Plugins und Tools</h4>
        <h5>YouTube</h5>
        Diese Website bindet Videos der Website YouTube ein. Betreiber der
        Website ist die Google Ireland Limited („Google“), Gordon House, Barrow
        Street, Dublin 4, Irland. Wenn Sie eine unserer Webseiten besuchen, auf
        denen YouTube eingebunden ist, wird eine Verbindung zu den Servern von
        YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche
        unserer Seiten Sie besucht haben. Des Weiteren kann YouTube verschiedene
        Cookies auf Ihrem Endgerät speichern oder vergleichbare Technologien zur
        Wiedererkennung verwenden (z.B. Device-Fingerprinting). Auf diese Weise
        kann YouTube Informationen über Besucher dieser Website erhalten. Diese
        Informationen werden u. a. verwendet, um Videostatistiken zu erfassen,
        die Anwenderfreundlichkeit zu verbessern und Betrugsversuchen
        vorzubeugen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind,
        ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen
        Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
        YouTube-Account ausloggen. Die Nutzung von YouTube erfolgt im Interesse
        einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
        Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO; die Einwilligung ist jederzeit widerrufbar. Weitere Informationen
        zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von
        YouTube unter:{" "}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        .<h5>Font Awesome (lokales Hosting)</h5>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten Font
        Awesome. Font Awesome ist lokal installiert. Eine Verbindung zu Servern
        von Fonticons, Inc.  findet dabei nicht statt. Weitere Informationen zu
        Font Awesome finden Sie in der Datenschutzerklärung für Font Awesome
        unter:{" "}
        <a
          href="https://fontawesome.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://fontawesome.com/privacy
        </a>
        . Quelle: <a href="https://www.e-recht24.de">eRecht24</a>
      </div>
    </div>
  );
};

import classNames from "classnames";
import React from "react";
import PromiseLoader from "../../../../../../components/PromiseLoader/PromiseLoader";
import i18n from "../../../../../../i18n";
import BFFormSection from "../../../../../../modules/abstract-ui/data/form-section/BFFormSection";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import { MatchQuery } from "../../../../../../services/DataService";
import { AssetCashBudgetEntry } from "../../../model/CashBudgetEntry";
import { SuggestionHint } from "../CBIdlyBookingsConst";
import "./CBIdlyConnect.scss";
import { Suggestion } from "./CBIdlyConnectService";
import CBIdlyConnectSuggestion from "./CBIdlyConnectSuggestion";
import CBIdlySearchFurther from "./CBIdlySearchFurther";

export interface CBIdlyConnectProps {
  booking: AssetCashBudgetEntry;
  className?: string;
  title: string;
  initialOpen?: boolean;
  isConnected: (suggestion: any) => boolean;
  //   children: React.ReactNode;

  connectionActionDisabledState?: (suggestion: any) => boolean;

  suggestionPromise: (booking: AssetCashBudgetEntry) => Promise<any[]>;
  renderSuggestion: (
    suggestion: any,
    updateSuggestion: (suggestion: any) => void
  ) => React.ReactNode;
  renderSuggestionHint: (suggestion: any) => SuggestionHint[];

  connectActionLabel: string;
  connectActionPromise: (suggestion: any) => Promise<any>;
  disconnectActionLabel: string;
  disconnectActionPromise: (suggestion: any) => Promise<any>;

  allowManualSearch?: boolean;
  manualActionLabel?: string;

  assetType: string;
  ruleActionData?: (suggestion: any) => {
    assetName: string;
    assetDescription: string;
    assetType: string;
    extra?: any;
  };
  ruleActionPromise?: (suggestion: any) => Promise<any>;
  matchQuery: MatchQuery;
  mapSuggestion: (
    data: any,
    booking: AssetCashBudgetEntry
  ) => Suggestion<any, any>;
}
const CBIdlyConnect = (props: CBIdlyConnectProps) => {
  const [suggestions, setSuggestions] = React.useState<any[]>(null);
  const [showFurther, setShowFurther] = React.useState(false);
  return (
    <BFFormSection
      className={classNames(`cb-idly-connect`, props.className)}
      initialOpen={props.initialOpen}
      title={props.title}
    >
      {!showFurther && (
        <div>
          <div className={`suggestions`}>
            <PromiseLoader
              onDataFetched={(data) => setSuggestions(data)}
              promise={async () => await props.suggestionPromise(props.booking)}
              render={(data) => {
                if (!suggestions) {
                  return null;
                }

                if (suggestions.length === 0) {
                  return (
                    <div className={`no-suggestions`}>
                      <div className="no-suggestion-text">
                        {i18n.t(
                          "cb:CBIdlyConnect.noData",
                          "Leider konnten wir für diese Buchung keine passende Vorschläge finden."
                        )}
                      </div>

                      {props.allowManualSearch && props.manualActionLabel && (
                        <BFButton
                          onClick={() => setShowFurther(true)}
                          appearance="outline"
                        >
                          {props.manualActionLabel}
                        </BFButton>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <>
                      <div className={`info-text`}>
                        {i18n.t(
                          "cb:CBIdlyConnect.info",
                          "Wir haben folgende Vorschläge für diese Buchung gefunden:"
                        )}
                      </div>
                      <div className={`suggestion-list`}>
                        {suggestions
                          .filter((_, index) => index < 3)
                          .map((suggestion, index) => (
                            <CBIdlyConnectSuggestion
                              key={index}
                              {...props}
                              suggestion={suggestion}
                              onUpdateSuggestion={(suggestion) =>
                                setSuggestions(
                                  suggestions.map((oldSuggestion, i) =>
                                    i === index ? suggestion : oldSuggestion
                                  )
                                )
                              }
                            />
                          ))}
                      </div>
                      {props.manualActionLabel && props.allowManualSearch && (
                        <div className={`manual-action`}>
                          <div className={`manual-text`}>
                            {i18n.t(
                              "cb:CBIdlyConnect.manualText",
                              "Keine passenden Vorschläge gefunden? Klicken Sie hier, um die Buchung manuell zu verknüpfen."
                            )}
                          </div>
                          <BFButton
                            onClick={() => setShowFurther(true)}
                            appearance="outline"
                          >
                            {props.manualActionLabel}
                          </BFButton>
                        </div>
                      )}
                    </>
                  );
                }
              }}
            />
          </div>
        </div>
      )}
      {showFurther && (
        <div>
          <CBIdlySearchFurther
            onBack={() => setShowFurther(false)}
            {...props}
          />
        </div>
      )}
    </BFFormSection>
  );
};

export default CBIdlyConnect;

import classNames from "classnames";
import { nanoid } from "nanoid";
import { useState } from "react";
import i18n from "../../../../i18n";
import DataBus from "../../../../services/DataBus";
import { DataBusSubKeys } from "../../../../utils/Constants";
import StringUtils from "../../../../utils/StringUtils";
import { Placement } from "../../common/Placements";
import BFButton from "../../general/Button/BFButton";
import BFDropdown from "../../general/Dropdown/BFDropdown";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import BFUseTooltip from "../../general/tooltip/BFUseTooltip";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import LabeledInput from "../LabeledInput";
import "./BFCurrencySelect.scss";
import BFInput from "./BFInput";
import {
  CurrencyOption,
  IMPORTANT_CURRENCIES,
  getCurrencyOptions,
} from "./Currencies";

interface BFCurrencySelectProps {
  favoriteCurrencies?: string[];
  value: string;
  onChange: (value: string) => void;
  placement?: Placement;
  disabled?: boolean;

  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  labelPosition?: "top" | "left";
  info?: React.ReactNode;
  label?: string;
  labelSuffix?: React.ReactNode;
  noDefaultValue?: boolean;
  placeholder?: string;

  appearance?: "default" | "input";
}
const BFCurrencySelect = (props: BFCurrencySelectProps) => {
  const [identifier] = useState(nanoid());
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const currencies = getCurrencyOptions();

  const favCurrencyIds = props.favoriteCurrencies || IMPORTANT_CURRENCIES;

  const favCurrencies = favCurrencyIds
    .map((e) => currencies.find((c) => c.id === e))
    .filter((e) => e);

  const items = [];
  if (advancedOpen || !favCurrencies.length) {
    items.push({
      type: "panel",
      children: (
        <SearchCurrency
          dropdownIdentifier={identifier}
          {...props}
          currencies={currencies}
          onBack={
            favCurrencies.length ? () => setAdvancedOpen(false) : undefined
          }
        />
      ),
    });
  } else {
    items.push(
      ...favCurrencies.map((currency) => ({
        type: "button",
        active: currency.id === props.value,
        text: (
          <div className={`currency-item`}>
            <div className={`currency-name`}>{currency.name}</div>
            <div className={`currency-code`}>
              {currency.id} / {currency.symbol}
            </div>
          </div>
        ),
        onSelect: () => {
          props.onChange(currency.id);
        },
      }))
    );
    items.push({
      type: "button",
      text: i18n.t("BFCurrencySelect.advanced", "Weitere"),
      closeOnClick: false,
      className: "advanced-button",
      onSelect: () => {
        setAdvancedOpen(true);
      },
    });
  }
  const currencyValue =
    props.value ||
    (props.noDefaultValue ? undefined : StringUtils.getCurrency());
  const currencyValueObj = currencies.find((c) => c.id === currencyValue);

  return (
    <LabeledInput
      label={props.label}
      info={props.info}
      labelPosition={props.labelPosition}
      suffix={props.labelSuffix}
      error={!!props.validation?.message}
    >
      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <BFDropdown
          asOverlay
          disabled={props.disabled}
          identifier={identifier}
          className={classNames(`bf-currency-select`, {
            "appearance-default": (props.appearance || "default") === "default",
            "appearance-input": (props.appearance || "default") === "input",
          })}
          // onFocus={props.onFocus}
          // onBlur={props.onBlur}
          onClose={() => {
            setAdvancedOpen(false);
          }}
          placement={props.placement}
          label={
            <BFUseTooltip
              tooltip={
                currencyValueObj ? (
                  <div>
                    {currencyValueObj.name} ({currencyValueObj.symbol})
                  </div>
                ) : props.placeholder ? (
                  <div className={`placeholder`}>{props.placeholder}</div>
                ) : (
                  "-"
                )
              }
            >
              <div className={!currencyValue ? "placeholder" : undefined}>
                {currencyValue || props.placeholder || "-"}
              </div>
            </BFUseTooltip>
          }
          items={items}
        />
      </ValidationPopover>
    </LabeledInput>
  );
};

export default BFCurrencySelect;

const SearchCurrency = (props: {
  currencies: CurrencyOption[];
  value: string;
  onChange: (value: string) => void;
  onBack: () => void;
  dropdownIdentifier: string;
}) => {
  const [search, setSearch] = useState<string>("");

  const filteredCurrencies = props.currencies.filter(
    (c) =>
      c.name.toLowerCase().includes(search.toLowerCase()) ||
      c.id.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <div className={`search-currency-panel`}>
      {props.onBack && (
        <div>
          <BFButton appearance="link" size="xxs" onClick={props.onBack}>
            {i18n.t("Global.Buttons.back")}
          </BFButton>
        </div>
      )}
      <div className={`search-bar`}>
        <BFInput
          focusOnMount
          placeholder={i18n.t("Global.Labels.Search")}
          prefix={<BfIcon {...DefaultIcons.SEARCH} size="xxs" />}
          value={search}
          onChange={(val: string) => setSearch(val)}
        />
      </div>
      <div className={`items`}>
        {filteredCurrencies.length === 0 && (
          <div className={`empty`}>
            {i18n.t(
              "BFCurrencySelect.noResults",
              "Keine Währung für diese Suche gefunden"
            )}
          </div>
        )}
        {filteredCurrencies.map((c) => (
          <BFButton
            className={`item currency-item ${
              c.id === props.value ? "active" : ""
            }`}
            appearance="clear-on-white"
            key={c.id}
            onClick={() => {
              props.onChange(c.id);
              DataBus.emit(
                DataBusSubKeys.DROPDOWN_TOGGLE,
                props.dropdownIdentifier
              );
            }}
          >
            <div className={`currency-name`}>{c.name}</div>
            <div className={`currency-code`}>
              {c.id} / {c.symbol}
            </div>
          </BFButton>
        ))}
      </div>
    </div>
  );
};

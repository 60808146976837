import CBAttachmentFormComponent from "@/apps/tatar/cashBudget/views/config/booking-category-rule/CBAttachmentFormComponent";
import FormValidators from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import ActivitySelect from "@/modules/activity-components/ActivitySelect";
import classNames from "classnames";
import { Field } from "react-final-form";
import { AccountingData } from "../../../AccountingLoader";
import "./AccountingFormAdditionalData.scss";

interface AccountingFormAdditionalDataProps {
  accounting?: AccountingData;
  ignoreBookingText?: boolean;
  ignoreBookingDate?: boolean;
  withReferenceField?: boolean;
}
const AccountingFormAdditionalData = (
  props: AccountingFormAdditionalDataProps
) => {
  return (
    <div className={classNames(`accounting-form-additional-data`)}>
      <div className={`__flex-1`}>
        {(!props.ignoreBookingDate || !props.ignoreBookingText) && (
          <div className={`__flex __flex-1`}>
            {!props.ignoreBookingText && (
              <Field
                name="bookingText"
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div className="__field __flex-1">
                    <BFInput
                      {...input}
                      label={i18n.t(
                        "acc:AccountingBookingForm.bookingText",
                        "Buchungstext"
                      )}
                      {...FormValidators.getValidation(meta)}
                    />
                  </div>
                )}
              </Field>
            )}
            {!props.ignoreBookingDate && (
              <Field
                name="date"
                validate={FormValidators.compose(FormValidators.required())}
              >
                {({ input, meta }) => (
                  <div className="__field">
                    <BFDatefield
                      {...input}
                      label={i18n.t(
                        "acc:AccountingBookingForm.date",
                        "Buchungsdatum"
                      )}
                      {...FormValidators.getValidation(meta)}
                    />
                  </div>
                )}
              </Field>
            )}
          </div>
        )}
        <div>
          <Field
            name="note"
            // validate={FormValidators.compose(FormValidators.required())}
          >
            {({ input, meta }) => (
              <div className="__field note">
                <BFInput
                  {...input}
                  type={"textarea"}
                  autosize
                  label={i18n.t(
                    "acc:AccountingBookingForm.note",
                    "Buchungsnotiz"
                  )}
                  {...FormValidators.getValidation(meta)}
                />
              </div>
            )}
          </Field>
        </div>
      </div>
      {props.withReferenceField && (
        <div>
          <Field
            name="referenceField"
            validate={FormValidators.compose(FormValidators.required())}
          >
            {({ input, meta }) => (
              <div className="__field note">
                <BFInput
                  {...input}
                  label={i18n.t(
                    "acc:AccountingBookingForm.referenceField",
                    "Belegnummer"
                  )}
                  {...FormValidators.getValidation(meta)}
                />
              </div>
            )}
          </Field>
        </div>
      )}

      <div className={`__flex __flex-1 connect-data-row`}>
        <div className="__field __flex-1">
          <Field
            name="linkedAsset"
            // validate={FormValidators.compose(
            //   FormValidators.required(),
            //   FormValidators.min(1)
            // )}
          >
            {({ input, meta }) => (
              <ActivitySelect
                label={i18n.t(
                  "acc:AccountingBookingForm.connectAsset",
                  "Aktivität verknüpfen"
                )}
                className={`accounting-form-assignment`}
                multiple={true}
                {...FormValidators.getValidation(meta)}
                block
                appearance="bf"
                businessUnits={[props.accounting.accounting.data.type]}
                identifier="accounting-form-connect-activities"
                value={input.value || []}
                onChange={input.onChange}
                entityId={props.accounting?.accounting.data.entity}
                // onValueAdded={async (value) => {
                //   await InvoiceService.addActivity(props.asset._id, value);
                // }}
                // onValueRemoved={async (value) => {
                //   await InvoiceService.removeActivity(props.asset._id, value);
                // }}
                cleanable
                // label={RA}
                assets={[
                  AssetTypes.Activity.DamageClaim,
                  AssetTypes.Activity.Project,
                  AssetTypes.ActivityRelated.ProjectDeprecated,
                  AssetTypes.Activity.Maintenance,
                  AssetTypes.Activity.OrderingProcess,
                  AssetTypes.Activity.SupplyContract,
                  AssetTypes.Activity.Insurance,
                ]}
              />
            )}
          </Field>
        </div>
        <div className="__field __flex-1">
          <Field
            name="attachments"
            // validate={FormValidators.compose(
            //   FormValidators.required(),
            //   FormValidators.min(1)
            // )}
          >
            {({ input, meta }) => (
              <CBAttachmentFormComponent
                label={i18n.t(
                  "acc:AccountingBookingForm.attachments",
                  "Anlagen"
                )}
                unit={props.accounting.accounting.data.type}
                {...input}
                {...FormValidators.getValidation(meta)}
              />
            )}
          </Field>
        </div>
      </div>
    </div>
  );
};

export default AccountingFormAdditionalData;

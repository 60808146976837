import FilePreviewComponent from "@/components/FilePreviewComponent/FilePreviewComponent";
import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import BaseAsset from "@/model/general-assets/BaseAsset";
import BFDropzone from "@/modules/abstract-ui/dropzone/BFDropzone";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ValidationPopover from "@/modules/abstract-ui/general/ValidationPopover/ValidationPopover";
import { useDatabus } from "@/redux/hooks";
import LanguageService from "@/services/LanguageService";
import { DataBusSubKeys } from "@/utils/Constants";
import { isDefined } from "@/utils/Helpers";
import ObjectIdService from "@/utils/ObjectIdUtils";
import classNames from "classnames";
import { useState } from "react";
import { Accept } from "react-dropzone";
import { FieldRenderProps } from "react-final-form";
import { Customfield_attachment } from "../CustomField.interface";
import "./CFAttachment.scss";
export type NewAttachmentValue = {
  type: "new";
  file: File;
  id: string;
};
export type CDNAttachmentValue = {
  type: "cdn";
  name: string;
  linkToCdn: string;
  status: "active" | "archived";
};
export type AttachmentValue = NewAttachmentValue | CDNAttachmentValue;

interface CFAttachmentProps {
  data: Customfield_attachment;
  field?: FieldRenderProps<AttachmentValue[], HTMLElement>;
  asset?: BaseAsset;
  assetType?: string;
  prefix?: string;
  additionalData?: Object;
  accept?: Accept;
}
const CFAttachment = (props: CFAttachmentProps) => {
  const attachments = props.field?.input.value || [];
  return (
    <BFDropzone
      onDrop={
        (acceptedFiles, rejectedFiles, event) => {
          props.field?.input.onChange([
            ...attachments,
            ...acceptedFiles.map(
              (file) =>
                ({
                  type: "new",
                  file,
                  id: ObjectIdService.new(),
                } as NewAttachmentValue)
            ),
          ]);
        }
        // this.onDropHandler(acceptedFiles, rejectedFiles, event)
      }
      multipe
      accept={
        props.accept || {
          "image/*": [".heic", ".heif", ".webp"],
          "application/pdf": [],
          "application/msword": [],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [],
        }
      }
      maxSize={40000000}
      render={(open) => (
        <div>
          <ValidationPopover
            level="error"
            validatorStyle="below"
            {...(FV.getValidation(props.field?.meta)?.validation || {})}
          >
            <div className={classNames(`cf-attachment`)}>
              <div className={`attachment-header`}>
                <div className={`title`}>
                  {LanguageService.translateLabel(props.data.displayName)}
                </div>
              </div>
              <div className={`attachments`}>
                {attachments.length === 0 && (
                  <div className={`no-attachment`}>
                    {i18n.t("FileUpload.noFile", "Keine Dateien")}:
                  </div>
                )}

                {attachments
                  .filter((e) => e.type === "cdn")
                  .map((attachment: CDNAttachmentValue) => (
                    <CFAttachmentEntry
                      key={attachment.linkToCdn}
                      attachment={attachment}
                      {...props}
                    />
                  ))}
                {attachments
                  .filter((e) => e.type === "new")
                  .map((attachment: NewAttachmentValue) => (
                    <CFAttachmentEntry
                      key={attachment.id}
                      attachment={attachment}
                      {...props}
                    />
                  ))}

                <div className={`upload-button`}>
                  <BFButton
                    noPadding
                    onClick={open}
                    appearance="link"
                    size="xs"
                  >
                    {i18n.t("FileUpload.upload", "Hochladen")}
                  </BFButton>
                </div>
              </div>
            </div>
          </ValidationPopover>
        </div>
      )}
    />
  );
};

export default CFAttachment;

export const CFAttachmentEntry = (
  props: CFAttachmentProps & { attachment: AttachmentValue }
) => {
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);

  useDatabus(
    DataBusSubKeys.CDN_UPLOAD_FILE_PROGRESS,
    ({ tempID, cdnID, progress }) => {
      if (props.attachment.type === "new" && tempID === props.attachment.id) {
        setUploadProgress(progress);
      }
    },
    [props.attachment]
  );
  return (
    <div className={`cf-attachment-entry`}>
      <div className={`preview`}>
        {props.attachment.type === "new" && (
          <FilePreviewComponent
            type="file"
            file={props.attachment.file}
            width={60}
            height={60}
          />
        )}
        {props.attachment.type === "cdn" && (
          <FilePreviewComponent
            type="cdn"
            asset={props.asset}
            assetField={`data.${props.prefix ? props.prefix + "." : ""}${
              props.data.id
            }`}
            assetType={props.assetType}
            cdnID={props.attachment.linkToCdn}
            height={60}
            width={60}
            hasFolderPermissions
            dimension="thumb"
          />
        )}
      </div>
      <div className={`infos`}>
        <div className={`filename`}>
          {props.attachment.type === "new"
            ? props.attachment.file.name
            : props.attachment.name}
        </div>
        <div className={`action`}>
          {props.attachment.type === "new" && isDefined(uploadProgress) && (
            <div className={`progress-bar`}>
              <div
                className={`progress`}
                style={{ width: `${uploadProgress * 100}%` }}
              />
            </div>
          )}
          {props.field && (
            <BFButton
              size="xs"
              appearance="link"
              noPadding
              onClick={() =>
                props.field.input.onChange(
                  props.field.input.value.filter((e) => e !== props.attachment)
                )
              }
            >
              {i18n.t("FileUpload.remove", "Entfernen")}
            </BFButton>
          )}
        </div>
      </div>
    </div>
  );
};

import i18n from "@/i18n";
import classNames from "classnames";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { Nav } from "rsuite";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import BFNav from "../BFNav/BFNav";
import "./BFNavView.scss";

interface BFNavViewProps {
  className?: string;
  tabs: {
    route: string;
    title: string;
    disabled?: boolean;
    render: () => JSX.Element;
  }[];
  title?: string;
  onClose?: () => void;
  tabAlign?: "left" | "center" | "right";
}
const BFNavView = (props: BFNavViewProps) => {
  const match = useRouteMatch();
  const tabAlign = props.tabAlign || "center";
  return (
    <Route
      path={`${match.url}/:subroute?`}
      render={(routeProps) => {
        const subroute = routeProps.match.params.subroute;
        if (!subroute) {
          const firstNotDisabledTab = props.tabs.find((tab) => !tab.disabled);
          return <Redirect to={`${match.url}/${firstNotDisabledTab.route}`} />;
        }
        const activeTab = props.tabs.find((tab) => tab.route === subroute);

        let content = null;
        if (!activeTab || activeTab.disabled) {
          content = (
            <div>
              {i18n.t(
                "BFNavView.noAccess",
                "Keinen Zugriff auf diesen Bereich"
              )}
            </div>
          );
        } else {
          content = activeTab.render();
        }
        return (
          <div className={classNames(`bf-nav-view`, props.className)}>
            <div className={`tab-bar`}>
              <div
                className={classNames("tab-title", {
                  fill: tabAlign === "center" || tabAlign === "right",
                })}
              >
                {props.title}
              </div>
              {/* {props.title && <div className={`tab-title`}>{props.title}</div>} */}
              {/* {(tabAlign === "center" || tabAlign === "right") && (
                <div className={`fill`} />
              )} */}
              <div className={`tab-bar-nav-wrapper`}>
                <BFNav
                  className={`tab-bar-nav`}
                  activeKey={subroute}
                  appearance="bf"
                  onSelect={(activeKey) => {
                    routeProps.history.push(`${match.url}/${activeKey}`);
                  }}
                >
                  {props.tabs.map((tab) => (
                    <Nav.Item
                      key={tab.route}
                      eventKey={tab.route}
                      disabled={tab.disabled}
                    >
                      {tab.title}
                    </Nav.Item>
                  ))}
                </BFNav>
              </div>
              {(tabAlign === "center" || tabAlign === "left") && (
                <div className={`fill`} />
              )}
              {props.onClose && (
                <div>
                  <BFButton appearance="clear-on-white" onClick={props.onClose}>
                    <BfIcon size="xs" {...DefaultIcons.CLOSE} />
                  </BFButton>
                </div>
              )}
            </div>
            <div className={`tab-content`}>{content}</div>
          </div>
        );
      }}
    />
  );
};

export default BFNavView;

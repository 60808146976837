import { FC } from "react";
import i18n from "@/i18n";
import { ManagePositionsCardDropdownProps } from "./types";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "@/modules/abstract-ui/general/Dropdown/BFDropdown";
import styles from "./ManagePositionsCardDropdown.module.scss";

const ManagePositionsCardDropdown: FC<ManagePositionsCardDropdownProps> = ({
  onViewPositionsClick,
  onManagePositionsClick,
}) => {
  return (
    <BFDropdown
      asOverlay
      label={""}
      renderToggle={(props) => (
        <BFButton {...props} appearance="link" noPadding>
          {i18n.t(
            "cb:Portfolio.Objekt.technicalUnit.positions.positions",
            "Positionen"
          )}
        </BFButton>
      )}
      overlayWidth={"min-content"}
      className={styles.dropdown}
      items={[
        {
          type: "button",
          text: i18n.t(
            "cb:Portfolio.Objekt.technicalUnit.positions.managePositions",
            "Positionen verwalten"
          ),
          icon: {
            type: "light",
            data: "cog",
          },
          onSelect: () => onManagePositionsClick(),
        },
        {
          type: "button",
          text: i18n.t(
            "cb:Portfolio.Objekt.technicalUnit.positions.viewPositions",
            "Positionen anzeigen"
          ),
          icon: {
            type: "light",
            data: "information-circle",
          },
          onSelect: () => onViewPositionsClick(),
        },
      ]}
    />
  );
};

export default ManagePositionsCardDropdown;

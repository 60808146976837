import arrayMutators from "final-form-arrays";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import i18n from "../../../../i18n";
import CDNService from "../../../../services/CDNService";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import LanguageService from "../../../../services/LanguageService";
import BFDropzone from "../../../abstract-ui/dropzone/BFDropzone";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import {
  DocumentStoreAssetParams,
  DocumentStoreDirectory,
} from "../../DSInterfaces";
import DSService from "../../DSService";
import "./DSUploadDialog.scss";
import DSUploadDialogEntry from "./DSUploadDialogEntry";

type DSUploadDialogProps = {
  files: File[];
  assetParams: DocumentStoreAssetParams;
  onSuccess: (data: any) => void;
  onAbort: () => void;
  directory: DocumentStoreDirectory;
};

const DSUploadDialog = (props: DSUploadDialogProps) => {
  const accept = props.directory.accept || DSService.getDefaultAccept();

  // In case its a defaulf folder created in the frontend dont save the directory id
  // in the document
  const directoryId = props.directory.isVirtual
    ? undefined
    : props.directory.id;

  return (
    <div className={"ds-document-upload-dialog"}>
      <Form
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            if (state.formState.values) {
              changeValue(state, field, () => value);
            }
          },
        }}
        onSubmit={async (values) => {
          try {
            let lastResult = null;
            for (const fileEntry of values.files) {
              lastResult = await CDNService.uploadAttachment(
                props.assetParams.asset._id,
                fileEntry,
                props.assetParams.assetType,
                props.assetParams.documentsFieldPath
              );
            }
            props.onSuccess(lastResult);
          } catch (err) {
            DataBusDefaults.toast({
              type: "error",
              text: i18n.t(
                "ds:DocumentStore.Upload.Error",
                "Fehler beim Hochladen"
              ),
            });
          }
        }}
        initialValues={{
          files: props.files.map((file) => ({
            file,
            name: file.name?.substring(0, file.name.lastIndexOf(".")),
            directoryId,
          })),
        }}
        render={(renderProps) => (
          <BFDropzone
            multipe={true}
            onDrop={(accepted, rejected, ev) => {
              if (accepted.length > 0 && rejected.length === 0) {
                accepted.forEach((file) => {
                  renderProps.form.mutators.push("files", {
                    file,
                    name: file.name?.substring(0, file.name.lastIndexOf(".")),
                    directoryId,
                  });
                });
              }
            }}
            accept={accept}
            render={(open) => {
              const translatedFolder = LanguageService.translateLabel(
                props.directory.name
              );

              return (
                <form onSubmit={renderProps.handleSubmit}>
                  <div className={`description`}>
                    {i18n.t(
                      "ds:DocumentStore.UploadDialog.description",
                      "Die Dateien werden in den folgenden Ordner hochgeladen:"
                    )}
                    <div> {translatedFolder}</div>
                  </div>
                  <div className={`file-list`}>
                    <FieldArray name="files">
                      {(fieldArrayObj) => {
                        const { fields } = fieldArrayObj;
                        return (
                          <div>
                            <div className={`entries`}>
                              {fields.map((name, index) => (
                                <DSUploadDialogEntry
                                  key={name}
                                  asset={props.assetParams.asset}
                                  name={name}
                                  onDelete={() => fields.remove(index)}
                                />
                              ))}
                            </div>
                            <div className={`add-action`}>
                              <BFButton appearance="link" onClick={open}>
                                {i18n.t("Global.Buttons.add")}
                              </BFButton>
                            </div>
                          </div>
                        );
                      }}
                    </FieldArray>
                  </div>
                  <div className={`form-actions`}>
                    <BFButton
                      appearance="outline"
                      type="button"
                      onClick={props.onAbort}
                    >
                      {i18n.t("Global.Buttons.cancel")}
                    </BFButton>
                    <BFButton
                      appearance="primary"
                      type="submit"
                      loading={renderProps.submitting}
                      disabled={renderProps.values.files.length === 0}
                    >
                      {i18n.t(
                        "attachment:uploadAttachmentDialog.upload",
                        "Hochladen"
                      )}
                    </BFButton>
                  </div>
                </form>
              );
            }}
          />
        )}
      ></Form>
    </div>
  );
};

export default DSUploadDialog;

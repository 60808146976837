import classNames from "classnames";
import i18n from "../../../../i18n";
import LanguageService from "../../../../services/LanguageService";
import BFTree from "../tree/BFTree";
import { IconFamilyAsset } from "./BFIconInterfaces";
import "./BFIconPickerCategory.scss";

interface BFIconPickerCategoryProps {
  familyConfig: IconFamilyAsset;
  activeValue?: string;
  onSelect?: (item: any) => void;
}
const BFIconPickerCategory = (props: BFIconPickerCategoryProps) => {
  return (
    <div className={classNames(`bf-icon-picker-category`)}>
      <BFTree
        treeData={[
          {
            value: null,
            label: i18n.t("Global.Labels.All", "Alle"),
          },
          ...props.familyConfig.data.categories.map((category) => ({
            value: `CAT_${category.category}`,
            label: LanguageService.translateLabel(category.displayName),
            children: category.subCategories.map((icon) => ({
              value: `SUB_${icon.subCategory}`,
              label: LanguageService.translateLabel(icon.displayName),
            })),
          })),
        ]}
        activeValue={props.activeValue}
        onSelect={props.onSelect}
      />
    </div>
  );
};

export default BFIconPickerCategory;

import { CSSProperties } from "react";
import Dropzone, { Accept, DropEvent, FileRejection } from "react-dropzone";
import { Trans, withTranslation } from "react-i18next";
import { Loader } from "rsuite";
import uuid from "uuid";
import CDNImage from "../../../../components/CDNImage/CDNImage";
import Log from "../../../../debug/Log";
import { TranslationProps } from "../../../../model/common/TranslationProps";
import CDNService from "../../../../services/CDNService";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../../utils/abstracts/AbstractStylableComponent";
import { DataBusSubKeys } from "../../../../utils/Constants";
import FileUtils from "../../../../utils/FileUtils";
import BFButton from "../../general/Button/BFButton";
import "./BFUpload.scss";

export type UploadField = { [id: string]: ExistentUploadEntry };

export interface CurrentUploadEntry {
  isFinished: boolean;
  tempID: string;
  cdnId?: string;
  file: File;
  progress: number;
  url?: string;
  cancelObj: {
    cancel: () => void;
  };
}
export interface ExistentUploadEntry {
  id?: string;
  key?: string;
  size?: number;
  content_type?: string;
  filename?: string;
  url?: string;
  bucket?: string;
  field?: string;
}

export type UploadEntry =
  | {
      state: "uploading" | "uploaded" | "error";
      data: CurrentUploadEntry;
      removed: boolean;
    }
  | {
      state: "existent";
      data: ExistentUploadEntry;
      removed: boolean;
    };

type Props = {
  assetField?: string;
  assetId?: string;
  assetType?: string;

  appearance?: "default" | "gallery";
  title?: string;
  titleKey?: string;

  accept?: Accept;
  maxFilesize?: number;
  removable?: boolean;
  multiple?: boolean;
  value?: {
    [id: string]: ExistentUploadEntry;
  };
  onChange: (data: { [id: string]: ExistentUploadEntry }) => void;

  folderPermissions?: boolean;
} & AbstractStylableProps &
  TranslationProps;

type States = {
  values: UploadEntry[];
  isDraggingOver: boolean;
} & AbstractStylableStates;

const REGEX_MIME_IMAGE = /image\/.*/g;

class BFUpload extends AbstractStylableComponent<Props, States> {
  readonly state: States = {
    isDraggingOver: false,
    values: [],
  };

  componentDidMount() {
    this.subscribe(DataBusSubKeys.CDN_UPLOAD_FILE_PROGRESS, (data) => {
      this.setState({
        values: this.state.values.map((entry) => {
          if (entry.state === "uploading") {
            if (entry.data.tempID === data.tempID) {
              return {
                ...entry,
                data: {
                  ...entry.data,
                  progress: data.progress,
                },
              };
            }
          }
          return entry;
        }),
      });
    });
    this.initUploadEntries();
  }

  componentWillReceiveProps(nextProps: Props, nextStates: States) {
    super.componentWillReceiveProps(nextProps, nextStates);
  }
  componentDidUpdate(prevProps: Props) {
    const prevValue = prevProps.value ? prevProps.value : {};
    const nowValue = this.props.value ? this.props.value : {};

    let shouldUpdateValue = prevProps.assetId !== this.props.assetId;
    if (!shouldUpdateValue) {
      // Object.entries(nowValue).forEach(([key, valEntry]) => {
      // 	if (prevValue[key] && !_.isEqual(prevValue[key], valEntry)) {
      // 		const stateEntry = this.state.values.find(entry => {
      // 			if (entry.state !== "existent") {
      // 				if (entry.data.cdnId === key) {
      // 					return true;
      // 				}
      // 			}
      // 		});
      // 		if (!stateEntry) {
      // 			shouldUpdateValue = true;
      // 		}
      // 	}
      // });
      // Log.debug("##BFUpload initUploadEntries", shouldUpdateValue, nowValue, prevValue);
    }
    if (shouldUpdateValue) {
      setTimeout(() => {
        this.initUploadEntries();
      });
    }
  }

  initUploadEntries(nextProps?: Props) {
    const propsToUse = nextProps ? nextProps : this.props;
    // Log.debug("##BFUpload initializing upload entries", propsToUse);
    const valuesToEval = propsToUse.value ? propsToUse.value : {};
    const uploadEntries: UploadEntry[] = Object.entries(valuesToEval).map(
      ([id, val]) => ({
        state: "existent",
        removed: false,
        data: {
          id: id,
          ...val,
        },
      })
    );
    this.setState({
      values: uploadEntries,
    });
  }

  startUpload(tempID) {
    const stateObj = this.state.values.find(
      (e) => e.state !== "existent" && e.data.tempID === tempID
    );
    if (stateObj && stateObj.state !== "existent") {
      const {
        data: { file, cancelObj },
      } = stateObj;

      this.setState({
        values: this.state.values.map((e) => {
          if (e.state !== "existent" && e.data.tempID === tempID) {
            return {
              ...e,
              state: "uploading",
              data: {
                ...e.data,
                progress: 0,
              },
            };
          } else {
            return e;
          }
        }),
      });

      CDNService.uploadFile({
        tempID: tempID,
        assetField: this.props.assetField,
        assetId: this.props.assetId,
        assetType: this.props.assetType,
        filename: file.name,
        file: file,
        cancelObj,
      })
        .then((resp: { cdnID: string; tempID: string }) => {
          this.setState({
            values: this.state.values.map((entry) => {
              if (entry.state !== "existent" && entry.data.tempID === tempID) {
                return {
                  state: "uploaded",
                  removed: false,
                  data: {
                    ...entry.data,
                    isFinished: true,
                    cdnId: resp.cdnID,
                  },
                };
              } else {
                return entry;
              }
            }),
          });

          const oldVal = this.props.value ? this.props.value : {};

          this.props.onChange({
            ...oldVal,
            [resp.cdnID]: {},
          });
        })
        .catch((err) => {
          this.setState({
            values: this.state.values.map((entry) => {
              if (entry.state !== "existent" && entry.data.tempID === tempID) {
                return {
                  state: "error",
                  removed: false,
                  data: {
                    ...entry.data,
                  },
                };
              } else {
                return entry;
              }
            }),
          });
          Log.error("###BFUpload upload failed", err);
        });
    }
  }

  handleFileupload(file: File) {
    const tempID = uuid();
    const indexOfLast = file.name.lastIndexOf(".");
    const cancelObj = {
      cancel: undefined,
    };

    this.setState(
      {
        values: [
          ...this.state.values,
          {
            state: "uploading",
            removed: false,
            data: {
              isFinished: false,
              tempID,
              file,
              progress: 0,
              cancelObj,
            },
          },
        ],
      },
      () => {
        this.startUpload(tempID);
      }
    );
    // todo: check if appearance supports thumbnails

    if (file?.type?.match(REGEX_MIME_IMAGE)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        const base64data = reader.result as string;
        this.setState({
          values: this.state.values.map((entry) => {
            if (entry.state !== "existent" && entry.data.tempID === tempID) {
              return {
                ...entry,
                data: {
                  ...entry.data,
                  url: base64data,
                },
              };
            } else {
              return entry;
            }
          }),
        });
      };
    }
  }

  onDropHandler(
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
    event: DropEvent
  ) {
    Log.debug("###BFUpload drophandler", acceptedFiles, rejectedFiles, event);
    this.setState({ isDraggingOver: false });
    if (acceptedFiles) {
      acceptedFiles.forEach((file) => {
        this.handleFileupload(file);
      });
    }
  }

  removeValueEntry(data: ExistentUploadEntry) {
    this.props.onChange({
      ...this.props.value,
      [data.id]: null,
    });

    this.setState({
      values: this.state.values.map((entry) => {
        if (entry.state === "existent") {
          if (entry.data.id === data.id) {
            return {
              ...entry,
              removed: true,
            };
          }
        }
        return entry;
      }),
    });
    setTimeout(() => {
      if (this.isMounted()) {
        this.setState({
          values: this.state.values.filter((entry) => {
            if (entry.state === "existent") {
              if (entry.data.id === data.id) {
                return false;
              }
            }
            return true;
          }),
        });
      }
    }, 400);
  }

  removeUploadEntry(data: CurrentUploadEntry) {
    if (data.isFinished) {
      this.props.onChange({
        ...this.props.value,
        [data.cdnId]: null,
      });
    } else {
      data.cancelObj.cancel();
    }

    this.setState({
      values: this.state.values.map((entry) => {
        if (entry.state !== "existent") {
          if (entry.data.tempID === data.tempID) {
            return {
              ...entry,
              removed: true,
            };
          }
        }
        return entry;
      }),
    });
    setTimeout(() => {
      if (this.isMounted()) {
        this.setState({
          values: this.state.values.filter((entry) => {
            if (entry.state !== "existent") {
              if (entry.data.tempID === data.tempID) {
                return false;
              }
            }
            return true;
          }),
        });
      }
    }, 400);
  }

  renderValueEntry(data: ExistentUploadEntry) {
    const isRemoved = this.state.values?.find(
      (entry) => entry.state === "existent" && entry.data.id === data.id
    ).removed;

    return (
      <div
        className={`entry existent ${isRemoved ? "removed" : ""}`}
        key={data.id}
      >
        <div className={`prefix`}>{this.renderDownloadPrefix(data)}</div>
        <div className={`name`}>{data.filename}</div>
        <div className={`postfix`}>
          <BFButton
            onClick={() => this.downloadValueEntry(data)}
            appearance={"clear-on-white"}
            icon={{
              type: "bf",
              data: "download-bottom",
            }}
          />
          <BFButton
            onClick={() => this.removeValueEntry(data)}
            appearance={"clear-on-white"}
            icon={{
              type: "bf",
              data: "close",
            }}
          />
        </div>
      </div>
    );
  }
  renderDownloadPrefix(data: ExistentUploadEntry) {
    if (data && data?.content_type?.match(REGEX_MIME_IMAGE)) {
      return (
        <div className={`preview img`}>
          <CDNImage
            maximizeOnHover={true}
            dimension="thumb"
            cdnId={data.id}
            imageType={"div"}
            filename={data.filename}
            assetType={this.props.assetType}
            assetId={this.props.assetId}
            assetField={this.props.assetField}
            fileKey={data.key}
            hasFolderReadPermissions={!!this.props.folderPermissions}
            onUrlReceived={(url) => {
              this.setState({
                values: this.state.values.map((entry) => {
                  if (entry.state === "existent") {
                    if (entry.data.id === data.id) {
                      return {
                        ...entry,
                        data: {
                          ...entry.data,
                          url: url,
                        },
                      };
                    }
                  }
                  return entry;
                }),
              });
            }}
          />
        </div>
      );
    } else {
      return <div className={`preview file`}></div>;
    }
  }

  renderUploadEntry(data: CurrentUploadEntry) {
    const isRemoved = this.state.values.find(
      (entry) => entry.state !== "existent" && entry.data.tempID === data.tempID
    ).removed;

    const state = this.state.values.find(
      (entry) => entry.state !== "existent" && entry.data.tempID === data.tempID
    ).state;

    return (
      <div
        className={`entry current ${state === "error" ? "errored" : ""} ${
          data.isFinished ? "finished" : ""
        } ${isRemoved ? "removed" : ""}`}
        key={data.tempID}
      >
        <div
          className={`loading-progres`}
          style={{
            width: data.progress * 100 + "%",
          }}
        />
        <div className={`prefix`}>
          {this.renderUploadImg(data, state === "error")}
        </div>
        <div className={`name`}>
          {data.file.name}

          {state === "error" ? (
            <div className={`error-indicator`}>
              <Trans i18nKey="BFComponents.Upload.UploadFailed" />
            </div>
          ) : null}
        </div>
        <div className={`postfix`}>
          {data.isFinished ? (
            <BFButton
              onClick={() => this.downloadUploadEntry(data)}
              appearance={"clear-on-white"}
              icon={{
                type: "bf",
                data: "download-bottom",
              }}
            />
          ) : null}
          {state === "error" ? (
            <BFButton
              onClick={() => this.startUpload(data.tempID)}
              appearance={"clear-on-white"}
              icon={{
                type: "bf",
                data: "synchronize-arrow-1",
              }}
            />
          ) : null}
          <BFButton
            onClick={() => this.removeUploadEntry(data)}
            appearance={"clear-on-white"}
            icon={{
              type: "bf",
              data: "close",
            }}
          />
        </div>
      </div>
    );
  }

  downloadValueEntry(data: ExistentUploadEntry) {
    if (data.url) {
      FileUtils.downloadFile(data.url, data.filename);
    } else {
      // check if finished -> download via cdn
      CDNService.fetchCDNLink({
        cdnId: data.id,
        assetField: this.props.assetField,
        assetId: this.props.assetId,
        assetType: this.props.assetType,
        fileKey: data.key,
        hasFolderReadPermissions: !!this.props.folderPermissions,
      })
        .then((url) => {
          this.setState({
            values: this.state.values.map((entry) => {
              if (entry.state === "existent") {
                if (entry.data.id === data.id) {
                  return {
                    ...entry,
                    data: {
                      ...entry.data,
                      url: url,
                    },
                  };
                }
              }
              return entry;
            }),
          });
          FileUtils.downloadFile(url, data.filename);
        })
        .catch((err) => {});
    }
  }
  downloadUploadEntry(data: CurrentUploadEntry) {
    if (data.url && data.url.indexOf("data") === 0) {
      FileUtils.downloadFile(data.url, data.file.name);
    } else {
      FileUtils.downloadFileBlob(data.file);
      // check if finished -> download via cdn
    }
  }

  renderUploadImg(data: CurrentUploadEntry, ignoreLoader = false) {
    if (data?.file?.type?.match(REGEX_MIME_IMAGE)) {
      const style: CSSProperties = {};
      if (data.url) {
        style.backgroundImage = `url(${data.url})`;
      }
      return (
        <div className={`preview img`} style={style}>
          {!data.isFinished && !ignoreLoader ? <Loader /> : null}
        </div>
      );
    } else {
      return <div className={`preview file`}></div>;
    }
  }

  renderEntry(entry: UploadEntry) {
    if (entry.state === "existent") {
      return this.renderValueEntry(entry.data);
    } else {
      return this.renderUploadEntry(entry.data);
    }
  }

  render() {
    const { title, titleKey, t } = this.props;
    const { isDraggingOver } = this.state;

    let usedTitle = null;
    if (title) {
      usedTitle = title;
    } else if (titleKey) {
      usedTitle = t(titleKey);
    }
    Log.debug("##BFupload ", this.props);
    return (
      <div className={`bf-upload`}>
        {usedTitle ? <div className={`label`}>{usedTitle}</div> : null}
        <Dropzone
          onDragEnter={(e) => this.setState({ isDraggingOver: true })}
          onDragLeave={(e) => this.setState({ isDraggingOver: false })}
          noClick={true}
          multiple={this.props.multiple}
          accept={this.props.accept}
          maxSize={this.props.maxFilesize}
          onDrop={(acceptedFiles, rejectedFiles, event) =>
            this.onDropHandler(acceptedFiles, rejectedFiles, event)
          }
        >
          {({ getRootProps, getInputProps, open }) => (
            <div
              className={`dropzone ${isDraggingOver ? "dragging" : ""}`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {this.state.values.length === 0 ? (
                <div className={`empty-text`}>
                  {t("BFComponents.Upload.EmptyText")}
                </div>
              ) : null}
              <div className={`entries`}>
                {this.state.values.map((entry) => this.renderEntry(entry))}
              </div>
              <div className={`action-row`}>
                <BFButton appearance={"primary"} onClick={open}>
                  {t("BFComponents.Upload.ButtonText")}
                </BFButton>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default withTranslation()(BFUpload);

type ResponseType = "text" | "json" | "formData" | "blob" | "arrayBuffer";

interface PublicResponse {
  [url: string]: {
    response?: any;
    type: ResponseType;
    waitingForResponse?: {
      resolve: (value: Response | PromiseLike<Response>) => void;
      reject: (reason?: any) => void;
    }[];
  };
}
class PublicFetchServiceClass {
  private responses: PublicResponse = {};

  getDirectResponseFromCache(url: string) {
    return this.responses[url]?.response;
  }
  private requestResponseAvailable(url: string) {
    return !!this.responses[url];
  }
  private requestFromCache(
    url: string,
    resolve: (value: Response | PromiseLike<Response>) => void,
    reject: (reason?: any) => void
  ) {
    const response = this.responses[url];

    if (response.response) {
      resolve(response.response);
    } else {
      response.waitingForResponse.push({ resolve, reject });
    }
  }

  public fetch(url: string, type: ResponseType) {
    return new Promise<any>((resolve, reject) => {
      if (this.requestResponseAvailable(url)) {
        this.requestFromCache(url, resolve, reject);
      } else {
        this.responses[url] = {
          type,
          waitingForResponse: [{ resolve, reject }],
        };

        const onError = (err) => {
          this.responses[url].waitingForResponse.forEach(({ reject }) =>
            reject(err)
          );
          delete this.responses[url];
        };
        fetch(url)
          .then((response) => {
            if (response.status === 200) {
              response[type]()
                .then((data) => {
                  this.responses[url].response = data;
                  this.responses[url].waitingForResponse.forEach(
                    ({ resolve }) => resolve(data)
                  );
                  this.responses[url].waitingForResponse = undefined;
                })
                .catch((err) => onError(err));
            } else {
              onError(response.status);
            }
          })
          .catch((err) => onError(err));
      }
    });
  }
}

const PublicFetchService = new PublicFetchServiceClass();
export default PublicFetchService;

import { ThunkDispatch } from "redux-thunk";
import { StructType } from "../../reducers/struct/StructInterface";
import { AppState } from "../../store";
import {
  STRUCT_CLEAR_CACHE,
  STRUCT_SET_DATA,
  STRUCT_SET_ERROR,
  STRUCT_SET_LOADING,
} from "./struct-action-types";
import { STRUCT_MAPPER } from "./struct-mapper";

export function clearStructData() {
  return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
    Object.values(STRUCT_MAPPER).forEach((struct) => struct.clear());

    dispatch({
      type: STRUCT_CLEAR_CACHE,
    });
  };
}
export function reloadStructData(structType: StructType) {
  return async (
    dispatch: ThunkDispatch<{}, {}, any>,
    getState: () => AppState
  ) => {
    const currentStruct = getState().struct;
    const units = Object.keys(currentStruct[structType]);
    dispatch({
      type: STRUCT_CLEAR_CACHE,
      structType,
    });

    await loadStructData([structType], units);
  };
}
export function loadStructData(structTypes: StructType[], unitTypes: string[]) {
  return async (
    dispatch: ThunkDispatch<{}, {}, any>,
    getState: () => AppState
  ) => {
    const currentStruct = getState().struct;

    for (const structType of structTypes) {
      const unitTypesToLoad = [];
      for (const unitType of unitTypes) {
        if (currentStruct[structType]?.[unitType]?.state !== "success") {
          unitTypesToLoad.push(unitType);
        }
      }

      unitTypesToLoad.forEach((unitType) => {
        dispatch({
          type: STRUCT_SET_LOADING,
          structType,
          unitType,
        });
      });
    }

    for (const structType of structTypes) {
      const unitTypesToLoad = [];
      for (const unitType of unitTypes) {
        if (
          !["success", "loading"].includes(
            currentStruct[structType]?.[unitType]?.state
          )
        ) {
          unitTypesToLoad.push(unitType);
        }
      }

      if (unitTypesToLoad.length > 0) {
        const struct = STRUCT_MAPPER[structType];
        try {
          const data = await struct.loadStructData(unitTypes);
          Object.entries(data).forEach(([unitType, unitData]) => {
            struct.businessUnitAdded(unitType);
            dispatch({
              type: STRUCT_SET_DATA,
              structType,
              unitType,
              data: unitData,
            });
          });
        } catch (err) {
          unitTypes.forEach((unitType) => {
            dispatch({
              type: STRUCT_SET_ERROR,
              structType,
              unitType,
              error: err,
            });
          });
        }
      }
    }

    //   dispatch({
    //     type: "SET_STRUCT_DATA",
    //     structType,
    //     unitType,
    //     data,
    //   });
  };
}

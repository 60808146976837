import { css } from "emotion";
import _ from "lodash";
import moment from "moment";
import { Fragment, SyntheticEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Loader } from "rsuite";
import { BfIconProps } from "../../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import { DataBusSubKeys } from "../../../utils/Constants";
import Tools, { StyleConfiguration } from "../../../utils/Tools";
import { ReloadMessage } from "../TableComponent/TableComponent";
import { setLogData } from "./../../../redux/actions/application/application-actions";
import {
  requestLogData,
  RequestLogParam,
} from "./../../../services/LogService";
import "./LogComponent.scss";
import LogEntry from "./LogEntry";
import { LogInterface } from "./LogModel";

type Props = {
  requestLogData: (
    requestParam: RequestLogParam,
    cancelObj: { cancel?: () => void }
  ) => void;
  setLogData: (
    logIdentifier: string,
    mode: "append" | "prepend" | "replace",
    data: Object[]
  ) => void;
  oType: ("asset" | "user" | "group")[];
  logData: LogInterface[];
  logLoading: "append" | "prepend" | "replace" | null;
  limit?: number;
  logParams?: any;
  logTimestamp?: number;
  targetID?: string;
  userID?: string;
  type?: string[];

  entryStyle?: StyleConfiguration;

  userContextMenu?: {
    textKey: string;
    route: string;
    icon?: BfIconProps;
  }[];
  targetContextMenu?: {
    textKey: string;
    route: string;
    icon?: BfIconProps;
  }[];
  dataContextMenu?: {
    textKey: string;
    route: string;
    icon?: BfIconProps;
    params: { [key: string]: any };
  }[];
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {
  hasMore: boolean;
  ignoreFirstDraw: boolean;
} & AbstractStylableStates;

const DATA_TIMEOUT = 600;

class LogComponent extends AbstractStylableComponent<Props, States> {
  _mounted;
  static defaultProps = {
    limit: 15,
  };
  readonly state: States = {
    hasMore: true,
    ignoreFirstDraw: false,
  };

  scrollContainer;
  scrollContent;
  constructor(props) {
    super(props);

    this.subscribe(DataBusSubKeys.RELOAD, (msg: ReloadMessage) => {
      if (msg.identifiers.indexOf(this.props.identifier) !== -1) {
        this.reload();
      }
    });
  }

  componentWillMount() {
    super.componentWillMount();

    this.setState(
      {
        ignoreFirstDraw: true,
        hasMore: true,
      },
      () => {
        const params = {};

        params["oType"] = this.props.oType;

        if (this.props.targetID) {
          params["targetID"] = this.props.targetID
            ? this.evaluateExpression(this.props.targetID)
            : undefined;
        }
        if (this.props.userID) {
          params["userID"] = this.props.userID
            ? this.evaluateExpression(this.props.userID)
            : undefined;
        }
        if (this.props.type) {
          params["type"] = this.props.type;
        }

        if (
          this.props.logData.length === 0 ||
          this.props.logData.length > 90 ||
          !_.isEqual(params, this.props.logParams)
        ) {
          this.props.requestLogData(
            {
              targetID: this.props.targetID
                ? this.evaluateExpression(this.props.targetID)
                : undefined,
              userID: this.props.userID
                ? this.evaluateExpression(this.props.userID)
                : undefined,
              logIdentifier: this.props.identifier,
              limit: this.props.limit,
              mode: "replace",
              oType: this.props.oType,
              onSuccess: (data) => {
                if (this._mounted) {
                  if (data.length === 0) {
                    this.setState({ ignoreFirstDraw: false, hasMore: false });
                  } else {
                    this.setState({ ignoreFirstDraw: false, hasMore: true });
                  }
                }
              },
            },
            null
          );

          this.props.setLogData(this.props.identifier, "replace", []);
        } else {
          setTimeout(() => {
            if (this._mounted) {
              this.setState({
                ignoreFirstDraw: false,
              });
            }
          }, DATA_TIMEOUT);
        }
      }
    );
  }
  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
    super.componentWillUnmount();
  }

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {
    const preStyle = prevState.usedStyle;
    const currentStyle = this.state.usedStyle;

    // if ()
  }

  reload() {
    this.props.requestLogData(
      {
        logIdentifier: this.props.identifier,
        targetID: this.props.targetID
          ? this.evaluateExpression(this.props.targetID)
          : undefined,
        userID: this.props.userID
          ? this.evaluateExpression(this.props.userID)
          : undefined,
        mode: "replace",
        limit: this.props.limit,
        oType: this.props.oType,
        onSuccess: (data) => {
          if (data.length === 0) {
            this.setState({ hasMore: false });
          } else {
            this.setState({ hasMore: true });
          }
        },
      },
      null
    );
  }
  //

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  renderInlineLoader() {
    return (
      <div className={`loader`}>
        <Loader />
      </div>
    );
  }
  renderSeparator(date: Date) {
    const momentDate = moment(date);
    return (
      <div
        key={"sep_" + momentDate.year() + "_" + momentDate.dayOfYear()}
        className={`day-separator`}
      >
        {momentDate.calendar(null, {
          sameDay: "[" + this.props.i18n.t("Global.Date.today") + "]",
          nextDay: "[" + this.props.i18n.t("Global.Date.tomorrow") + "]",
          nextWeek: "dddd",
          lastDay: "[" + this.props.i18n.t("Global.Date.yesterday") + "]",
          lastWeek: "DD/MM/YYYY",
          sameElse: "DD/MM/YYYY",
        })}
      </div>
    );
  }

  scrollHandler(e: SyntheticEvent) {
    if (!this.state.hasMore) {
      return;
    }
    const containerBounds = this.scrollContainer.getBoundingClientRect();
    const contentBounds = this.scrollContent.getBoundingClientRect();
    if (
      containerBounds.height +
        this.scrollContainer.scrollTop -
        contentBounds.height >
      -100
    ) {
      this.props.requestLogData(
        {
          logIdentifier: this.props.identifier,
          targetID: this.props.targetID
            ? this.evaluateExpression(this.props.targetID)
            : undefined,
          userID: this.props.userID
            ? this.evaluateExpression(this.props.userID)
            : undefined,
          mode: "append",
          oType: this.props.oType,
          limit: this.props.limit,
          cursor: this.props.logData[this.props.logData.length - 1]._id,
          onSuccess: (data) => {
            if (data.length === 0) {
              this.setState({ hasMore: false });
            } else {
              this.setState({ hasMore: true });
            }
          },
        },
        null
      );
    }
  }

  render() {
    if (!this.shoudBeRendered()) {
      return null;
    }

    const { ignoreFirstDraw, hasMore } = this.state;
    const {
      logData,
      logLoading,
      userContextMenu,
      targetContextMenu,
      dataContextMenu,
    } = this.props;
    return (
      <div
        className={`LogComponent scroll-container ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
        ref={(ref) => (this.scrollContainer = ref)}
        onScroll={(event: SyntheticEvent) => this.scrollHandler(event)}
      >
        <div
          className={`scroll-content`}
          ref={(ref) => (this.scrollContent = ref)}
        >
          {logLoading === "prepend" ? this.renderInlineLoader() : null}
          {!ignoreFirstDraw
            ? logData.map((logEntry: LogInterface, index) => {
                const timestamp = Tools.dateFromObjectId(logEntry._id);
                let separator = false;
                let before =
                  index === 0
                    ? null
                    : Tools.dateFromObjectId(logData[index - 1]._id);
                if (
                  before === null ||
                  (timestamp.getDate() !== before.getDate() &&
                    timestamp.getMonth() !== before.getMonth() &&
                    timestamp.getFullYear() === before.getFullYear())
                ) {
                  separator = true;
                }

                return (
                  <Fragment key={logEntry._id}>
                    {separator ? this.renderSeparator(timestamp) : null}
                    <LogEntry
                      key={logEntry._id}
                      logEntry={logEntry}
                      style={this.props.entryStyle}
                      parentNode={this.scrollContent}
                      userContextMenu={userContextMenu}
                      targetContextMenu={targetContextMenu}
                      dataContextMenu={dataContextMenu}
                    />
                    {/* {index !== 0 && index % 30 === 0 ? (<div style={{ height: 5, background: "#000" }} />) : null} */}
                  </Fragment>
                );
              })
            : null}
          {!hasMore && logData.length > 0 ? (
            <div className={`no-more-entries`}>
              {this.props.i18n.t("Log.Entries.noMoreEntries")}
            </div>
          ) : !hasMore ? (
            <div className={`no-more-entries`}>
              {this.props.i18n.t("Log.Entries.noEntries")}
            </div>
          ) : null}
          {logLoading === "append" ? this.renderInlineLoader() : null}
        </div>
        {logLoading === "replace" || ignoreFirstDraw ? (
          <div className={`replace-loader`}>
            <Loader />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
  logData:
    state.application.logs[props.identifier] &&
    state.application.logs[props.identifier].data
      ? state.application.logs[props.identifier].data
      : [],
  logParams:
    state.application.logs[props.identifier] &&
    state.application.logs[props.identifier].params
      ? state.application.logs[props.identifier].params
      : {},
  logTimestamp:
    state.application.logs[props.identifier] &&
    state.application.logs[props.identifier].timestamp
      ? state.application.logs[props.identifier].timestamp
      : 0,
  logLoading: state.application.logs[props.identifier]
    ? state.application.logs[props.identifier].loading
    : null,
});

export default withRouter(
  connect(mapStateToProps, {
    requestLogData,
    setLogData,
  })(withTranslation()(LogComponent))
);

import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import GlobalActions from "@/services/GlobalActions";
import moment from "moment";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import { LinkCell } from "../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import StringUtils from "../../../../../../utils/StringUtils";
import "./CBIdlyConnectRentalSuggestion.scss";
import { RentalAgreementSuggestion } from "./CBIdlyConnectService";

interface CBIdlyConnectRentalSuggestionProps {
  suggestion: RentalAgreementSuggestion;
}
const CBIdlyConnectRentalSuggestion = (
  props: CBIdlyConnectRentalSuggestionProps
) => {
  return (
    <div className={`cb-idly-connect-rental-suggestion`}>
      <div className={`data-row`}>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.rental.agreement", "Mietvertrag")}
          </div>
          <div className={`value`}>
            <LinkCell
              text={
                <>
                  {props.suggestion.asset.data.id} -{" "}
                  {props.suggestion.asset.data.displayName}
                </>
              }
              assetType={AssetTypes.Rental.RentalAgreement}
              id={props.suggestion.asset._id}
              type={props.suggestion.asset.data.type}
            />
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.rental.tenant", "Mieter")}
          </div>
          <div className={`value`}>
            <BFButton
              noPadding
              appearance={"link"}
              onClick={() => {
                GlobalActions.openDetails(
                  AssetTypes.Contact,
                  props.suggestion.asset.data.tenant
                );
              }}
            >
              {props.suggestion.asset.nested?.tenantName}
            </BFButton>
          </div>
        </div>
      </div>
      <div className={`data-row`}>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.rental.object", "Objekt")}
          </div>
          <div className={`value`}>
            {OrgaStruct.getObject(props.suggestion.asset.data.objectId)?.id} -{" "}
            {
              OrgaStruct.getObject(props.suggestion.asset.data.objectId)
                ?.displayName
            }
          </div>
        </div>

        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.rental.moveIn", "Mietbeginn")}
          </div>
          <div className={`value`}>
            {props.suggestion.asset.data.moveIn
              ? moment(props.suggestion.asset.data.moveIn).format(
                  i18n.t("Formats.dateFormat")
                )
              : "-"}
          </div>
        </div>

        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t(
              "cb:CBIdlyConnect.rental.agreementExpiration",
              "Vertrag bis"
            )}
          </div>
          <div className={`value`}>
            {props.suggestion.asset.data.agreementExpiration
              ? moment(props.suggestion.asset.data.agreementExpiration).format(
                  i18n.t("Formats.dateFormat")
                )
              : "-"}
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.rental.moveOut", "Auszug")}
          </div>
          <div className={`value`}>
            {props.suggestion.asset.data.moveOut
              ? moment(props.suggestion.asset.data.moveOut).format(
                  i18n.t("Formats.dateFormat")
                )
              : "-"}
          </div>
        </div>
      </div>
      <div className={`data-row`}>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.rental.rentNet", "Miete netto")}
          </div>
          <div className={`value`}>
            {StringUtils.formatCurrency(props.suggestion.asset.data.rentNet)}
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.rental.rentGross", "Miete brutto")}
          </div>
          <div className={`value`}>
            {StringUtils.formatCurrency(props.suggestion.asset.data.rentGross)}
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t(
              "cb:CBIdlyConnect.rental.operatingCostNet",
              "Nebenkosten netto"
            )}
          </div>
          <div className={`value`}>
            {StringUtils.formatCurrency(
              props.suggestion.asset.data.operatingCostNet
            )}
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t(
              "cb:CBIdlyConnect.rental.operatingCostGross",
              "Nebenkosten brutto"
            )}
          </div>
          <div className={`value`}>
            {StringUtils.formatCurrency(
              props.suggestion.asset.data.operatingCostGross
            )}
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t(
              "cb:CBIdlyConnect.rental.rentNetTotal",
              "Gesamtmiete netto"
            )}
          </div>
          <div className={`value`}>
            {StringUtils.formatCurrency(
              props.suggestion.asset.data.rentNetTotal
            )}
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t(
              "cb:CBIdlyConnect.rental.rentGrossTotal",
              "Gesamtmiete brutto"
            )}
          </div>
          <div className={`value`}>
            {StringUtils.formatCurrency(
              props.suggestion.asset.data.rentGrossTotal
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CBIdlyConnectRentalSuggestion;

import OrgaStruct, {
  EntityModel,
} from "@/redux/actions/struct/implemented/OrgaStruct";
import i18n from "../../../../../i18n";
import { setFlexCacheDataMultiple } from "../../../../../redux/actions/application/application-actions";
import { store } from "../../../../../redux/store";
import DataBus from "../../../../../services/DataBus";
import { MatchQuery } from "../../../../../services/DataService";
import { DataBusSubKeys } from "../../../../../utils/Constants";
import MQ from "../../../../../utils/MatchQueryUtils";
import { CB_CONFIG_KEY } from "../../CashBudgetConst";
import { CBPortfolioConfigObject } from "./CBPortfolioConfig";

import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { ProcessingStatus } from "./interfaces/CBDamageClaim";
import {
  CBPortfolioGroup,
  CBPortfolioImmoConfig,
} from "./interfaces/CBPortfolioAsset";

export const CB_P_RENTER_TABLE_IDENTIFIER = "cb-portfolio-renters";
export const CB_PORTFOLIO_OBJECT_TABLE = "portfolio-object-table";
export const CB_PORTFOLIO_OBJECT_TABLE_BY_GROUP =
  "portfolio-object-table-by-group";
export const CB_P_LOANS_TABLE_IDENTIFIER = "cb-portfolio-loans";
export const CB_P_DAMAGECLAIM_TABLE_IDENTIFIER = "cb-portfolio-damageclaim";

class CBPortfolioUtilsClass {
  updateGlobalPortfolioFilter() {
    DataBus.emit(DataBusSubKeys.RELOAD, {
      identifiers: [
        CB_P_LOANS_TABLE_IDENTIFIER,
        CB_PORTFOLIO_OBJECT_TABLE,
        CB_P_RENTER_TABLE_IDENTIFIER,
        CB_P_DAMAGECLAIM_TABLE_IDENTIFIER,
      ],
    });
  }
  initForCurrentApp() {
    store.dispatch(
      setFlexCacheDataMultiple([
        {
          category: CB_CONFIG_KEY,
          identifier: "renterUsageFilter",
          data: "all",
        },
        {
          category: CB_CONFIG_KEY,
          identifier: "damageClaimProcessStatus",
          data: null,
        },
        {
          category: CB_CONFIG_KEY,
          identifier: "renterTypeFilter",
          data: null,
        },
        {
          category: CB_CONFIG_KEY,
          identifier: "loanFilterType",
          data: "all",
        },
        {
          category: CB_CONFIG_KEY,
          identifier: "entitySelectType",
          data: "normal",
        },
        {
          category: CB_CONFIG_KEY,
          identifier: "objectSelectType",
          data: "normal",
        },
        {
          category: CB_CONFIG_KEY,
          identifier: "filteredObjects",
          data: "#all",
        },
        // {
        //   category: configKey,
        //   identifier: "selectedFilter",
        //   data: CASH_BUDGET_ALL_OBJECTS_VAL,
        // },

        {
          category: CB_CONFIG_KEY,
          identifier: "filteredEntity",
          data: "#all", //this.getAllObjectsOfConfig(config).map((e) => e._id),
        },
      ])
    );
  }

  generateMatchQueryForLoanList(
    businessUnits: string[],
    loanFilterType: "all" | "free" | "object",
    filteredEntity: "#all" | string[] | string[][],
    filteredObjects: string | string[],
    restrictions?: MatchQuery
  ) {
    return MQ.and(
      restrictions,
      MQ.in("data.type", businessUnits),
      filteredEntity && Array.isArray(filteredEntity)
        ? MQ.in(
            "data.entity",
            (filteredEntity as string[]).reduce(
              (prev: string[], cur: string | string[]) => [
                ...prev,
                ...(Array.isArray(cur) ? cur : [cur]),
              ],
              [] as string[]
            )
          )
        : undefined,
      loanFilterType === "free"
        ? MQ.in("data.objects.objectId", [null])
        : undefined,
      loanFilterType === "object"
        ? MQ.in(
            "data.objects.objectId",
            Array.isArray(filteredObjects) ? filteredObjects : [filteredObjects]
          )
        : undefined
    );
  }

  generateMatchQueryForObjectList(
    businessUnits: string[],
    filteredEntity: string[] | string[][],
    filteredGroup: CBPortfolioGroup,
    restrictions?: MatchQuery
  ): MatchQuery {
    return MQ.and(
      restrictions,
      MQ.in("data.type", businessUnits),
      !filteredGroup && filteredEntity && Array.isArray(filteredEntity)
        ? MQ.in(
            "data.entity",
            (filteredEntity as string[]).reduce(
              (prev: string[], cur: string | string[]) => [
                ...prev,
                ...(Array.isArray(cur) ? cur : [cur]),
              ],
              [] as string[]
            )
          )
        : undefined,
      filteredGroup ? MQ.in("_id", filteredGroup.data.objects) : undefined
    );
  }

  generateMatchQueryForDamageClaimList(
    filteredEntity: "#all" | string[] | string[][],
    filteredObjects: string | string[],
    damageClaimProcessStatus: null | ProcessingStatus[]
  ): MatchQuery {
    const matchQuery: MatchQuery = {
      type: "and",
      query: [],
    };
    if (damageClaimProcessStatus !== null) {
      matchQuery.query.push({
        type: "op",
        name: "data.processingStatus",
        op: "in",
        value: damageClaimProcessStatus,
      });
    }

    if (
      filteredEntity &&
      (filteredEntity === "#all" || Array.isArray(filteredEntity))
    ) {
      let objectsToFilter = (
        filteredEntity === "#all"
          ? this.getAllEntities().map((e) => e._id)
          : (filteredEntity as string[])
      )
        .reduce(
          (prev: string[], cur: string | string[]) => [
            ...prev,
            ...(Array.isArray(cur) ? cur : [cur]),
          ],
          [] as string[]
        )
        .reduce(
          (prev, entityId) => [
            ...prev,
            ...CBPortfolioUtils.findEntity(entityId)?.objects?.map(
              (e) => e._id
            ),
          ],
          []
        );
      if (filteredObjects !== "#all") {
        objectsToFilter = Array.isArray(filteredObjects)
          ? filteredObjects
          : [filteredObjects];
      }
      matchQuery.query.push({
        type: "op",
        name: "data.objectId",
        op: "in",
        value: objectsToFilter,
      });
    }

    return matchQuery.query.length > 0 ? matchQuery : undefined;
  }

  generateMatchQueryForProjectList(
    filteredEntity: "#all" | string[] | string[][],
    filteredObjects: string | string[]
  ): MatchQuery {
    const matchQuery: MatchQuery = {
      type: "and",
      query: [],
    };

    // if (restrictions) {
    //   matchQuery.query.push(restrictions);
    // }
    if (
      filteredEntity &&
      (filteredEntity === "#all" || Array.isArray(filteredEntity))
    ) {
      let objectsToFilter = (
        filteredEntity === "#all"
          ? this.getAllEntities(true).map((e) => e._id)
          : (filteredEntity as string[])
      )
        .reduce(
          (prev: string[], cur: string | string[]) => [
            ...prev,
            ...(Array.isArray(cur) ? cur : [cur]),
          ],
          [] as string[]
        )
        .reduce(
          (prev, entityId) => [
            ...prev,
            ...CBPortfolioUtils.findEntity(entityId)?.objects?.map(
              (e) => e._id
            ),
          ],
          []
        );
      if (filteredObjects !== "#all") {
        objectsToFilter = Array.isArray(filteredObjects)
          ? filteredObjects
          : [filteredObjects];
      }
      matchQuery.query.push({
        type: "op",
        name: "data.objectId",
        op: "in",
        value: objectsToFilter,
      });
    }

    return matchQuery.query.length > 0 ? matchQuery : undefined;
  }

  generateMatchQueryForRenterList(
    filteredEntity: "#all" | string[] | string[][],
    filteredObjects: string | string[],
    renterUsageFilter: "all" | "nonVacant" | "vacant",
    renterTypeFilter: null | "business" | "private" | "parking",
    portfolioConfig: CBPortfolioImmoConfig
  ): MatchQuery {
    return MQ.and(
      renterUsageFilter !== "all"
        ? renterUsageFilter === "vacant"
          ? MQ.eq("data.tags", "vacant")
          : MQ.ne("data.tags", "vacant")
        : undefined,
      renterTypeFilter !== null
        ? MQ.in(
            "data.type",
            Object.entries(portfolioConfig.data.typeMappings)
              .filter(([type, filter]) => filter === renterTypeFilter)
              .map(([type]) => type)
          )
        : undefined,
      filteredEntity &&
        (filteredEntity === "#all" || Array.isArray(filteredEntity))
        ? MQ.in(
            "data.objectId",
            filteredObjects !== "#all"
              ? Array.isArray(filteredObjects)
                ? filteredObjects
                : [filteredObjects]
              : (filteredEntity === "#all"
                  ? this.getAllEntities(true).map((e) => e._id)
                  : (filteredEntity as string[])
                )
                  .reduce(
                    (prev: string[], cur: string | string[]) => [
                      ...prev,
                      ...(Array.isArray(cur) ? cur : [cur]),
                    ],
                    [] as string[]
                  )
                  .reduce(
                    (prev, entityId) => [
                      ...prev,
                      ...CBPortfolioUtils.findEntity(entityId)?.objects?.map(
                        (e) => e._id
                      ),
                    ],
                    []
                  )
          )
        : undefined
    );
  }

  generateEntityDropDownOptions(
    businessUnits?: string[],
    includeAll?: boolean,
    includeUnits?: boolean,
    notAsArray?: boolean,
    considerRestrictions = false
  ): { label: any; value: string[] | string; name?: string }[] {
    const units = businessUnits
      ? businessUnits.map((e) => UnitStruct.getUnit(e))
      : UnitStruct.getUnits();

    return [
      ...(includeAll
        ? [
            {
              value: "#all",
              label: i18n.t("Global.Labels.total", "Gesamt"),
            },
          ]
        : []),
      ...(includeUnits && units?.length > 1
        ? units.map((unit) => ({
            label: `${i18n.t("cb:UnitLabel", "Bereich")} ${this.getLabelOfUnit(
              unit
            )}`,
            value: OrgaStruct.getEntities(unit, considerRestrictions).map(
              (e) => e._id
            ),
          }))
        : []),
      ...OrgaStruct.getEntities(businessUnits, considerRestrictions)
        .map((entity) => ({
          label: (
            <div className="entity-entry">
              {entity.displayName}
              {units?.length > 1 ? (
                <span className="type-ident">
                  {this.getLabelOfUnit(entity.type)}
                </span>
              ) : null}
            </div>
          ),
          name: entity.displayName,
          value: notAsArray ? entity._id : [entity._id],
        }))
        .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)),
    ];
  }
  generateObjectsDropDownOptions(
    entityId: "#all" | string[] | string[][],
    includeAll?: boolean,
    considerRestrictions = false
  ): { label: any; value: string }[] {
    return [
      ...(includeAll
        ? [
            {
              value: "#all",
              label: i18n.t("Global.Labels.total"), //"Gesamt",
            },
          ]
        : []),
      ...(
        (entityId === "#all"
          ? this.getAllEntities(considerRestrictions).map((e) => e._id)
          : entityId || []) as any[]
      )
        .reduce(
          (prev: string[], cur: string | string[]) => [
            ...prev,
            ...(Array.isArray(cur) ? cur : [cur]),
          ],
          [] as string[]
        )
        .reduce(
          (prev, entityId) => [
            ...prev,
            ...(OrgaStruct.getObjectsByEntities([entityId], true)?.map(
              (e) => e._id
            ) || []),
          ],
          []
        )
        .map((objectId) => this.findObject(objectId))
        .filter((e) => !!e)
        .map((e) => ({
          label: e.displayName || "-",
          value: e._id,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0)),
    ];
  }

  getAllEntities(
    considerRestrictions = false
  ): (EntityModel & { unit: string })[] {
    const businessUnits =
      store.getState().uiConfig.activeApplication.constants?.businessUnits;
    return OrgaStruct.getEntities(businessUnits, considerRestrictions).map(
      (e) => ({
        ...e,
        unit: e.type,
      })
    );
  }
  getAllObjects(considerRestrictions = false): (CBPortfolioConfigObject & {
    unit: string;
    entityId: string;
  })[] {
    return this.getAllEntities(considerRestrictions).reduce(
      (prev, current) => [
        ...prev,
        ...current.objects.map((e) => ({
          ...e,
          unit: current.unit,
          entityId: current._id,
        })),
      ],
      []
    );
  }
  findEntity(id: string) {
    return OrgaStruct.getEntity(id);
  }
  findObject(id: string) {
    return OrgaStruct.getObject(id);
  }

  getLabelOfUnit(type: string) {
    return UnitStruct.getUnitLabel(type);
  }
  getMainAddress(asset: OAObject) {
    return (
      Object.values(asset.data?.feature?.address?.address)?.find(
        (e) => e.isMain
      ) || asset?.data?.feature?.address?.address?.[0]
    );
  }
  getDisplayName(asset: OAObject) {
    const mainAddress = this.getMainAddress(asset);
    if (asset) {
      return asset.data.displayName
        ? asset.data.displayName
        : `${mainAddress.streetname} ${mainAddress.streetnumber}`;
    } else {
      return "-";
    }
  }

  generateAdditionalFormData() {
    return {
      allPortfolioObjects: this.getAllObjects()
        .map((obj) => ({
          label: obj.displayName ? `${obj.id} - ${obj.displayName}` : "-",
          value: obj._id,
          id: obj.id,
        }))
        .sort((a, b) => a.id - b.id),
      portfolioObjects: Object.fromEntries(
        this.getAllEntities()?.map((entry) => [
          entry._id,
          entry.objects
            .map((obj) => ({
              label: obj.displayName || "-",
              value: obj._id,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ])
      ),
    };
  }
}
const CBPortfolioUtils = new CBPortfolioUtilsClass();
(window as any).CBPortfolioUtils = CBPortfolioUtils;
export default CBPortfolioUtils;

import classNames from "classnames";
import { Fragment } from "react";
import { Trans } from "react-i18next";
import i18n from "../../i18n";
import BFUseTooltip from "../../modules/abstract-ui/general/tooltip/BFUseTooltip";
import { useTypedSelector } from "../../redux/hooks";
import { AppState } from "../../redux/store";
import Teamname from "../AvatarComponent/Teamname";
import Userlabel from "../AvatarComponent/Userlabel";
import "./AssignmentLabel.scss";

interface Props {
  users: string[];
  teams: string[];

  asList?: boolean;
  avatarSize?: number;
  clipAtCount?: number;
}

const AssignmentLabel = (props: Props) => {
  const myUserId = useTypedSelector((state: AppState) => state.global.user._id);
  const myTeams = useTypedSelector(
    (state: AppState) => state.global.user.teams
  );

  const assignedAsUser = (props.users || []).find((user) => myUserId === user);
  const assignedAsTeam = (props.teams || []).find(
    (team) => myTeams.indexOf(team) !== -1
  );
  const otherUsers = (props.users || []).filter((user) => user !== myUserId);
  const otherTeams = (props.teams || []).filter(
    (team) => myTeams.indexOf(team) === -1
  );

  let label = null;
  const clip = props.clipAtCount || 2;
  let entries = [];
  if (assignedAsUser) {
    entries.push(
      <UserLabel user={assignedAsUser} avatarSize={props.avatarSize} />
    );
  }
  if (assignedAsTeam) {
    entries.push(<TeamLabel team={assignedAsTeam} />);
  }
  otherUsers.forEach((user) => {
    entries.push(<UserLabel user={user} avatarSize={props.avatarSize} />);
  });
  otherTeams.forEach((team) => {
    entries.push(<TeamLabel team={team} />);
  });
  return (
    <BFUseTooltip
      tooltip={
        <div className={`assignment-label-tooltip`}>
          {(props.users || []).length > 0 && (
            <div className={`assignment-label-tooltip-section`}>
              <div className={`section-title`}>
                {i18n.t(
                  "AssignmentLabel.assignedToUser",
                  "Zugewiesene Benutzer"
                )}
              </div>
              <div className={`assignments`}>
                {props.users.map((user) => (
                  <div className={`assignment-entry`} key={user}>
                    <UserLabel user={user} avatarSize={20} />
                  </div>
                ))}
              </div>
            </div>
          )}
          {(props.teams || []).length > 0 && (
            <div className={`assignment-label-tooltip-section`}>
              <div className={`section-title`}>
                {i18n.t("AssignmentLabel.assignedToTeam", "Zugewiesene Teams")}
              </div>
              <div className={`assignments`}>
                {props.users.map((user) => (
                  <div className={`assignment-entry`} key={user}>
                    <UserLabel user={user} avatarSize={20} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      }
    >
      <div
        className={classNames(`assignment-label`, {
          "as-list": props.asList,
        })}
      >
        {entries.slice(0, clip).map((entry, index) => {
          if (index < clip) {
            return (
              <Fragment key={index}>
                {!props.asList && index !== 0 ? ", " : null}
                {entry}
              </Fragment>
            );
          }
        })}
        {entries.slice(clip).length > 0 ? (
          <span className={`futher-entries`}>
            {" "}
            {entries.slice(clip).length === 1 ? (
              <Trans i18nKey="AssignmentLabel.furtherMoreSingle">
                & 1 weiterer
              </Trans>
            ) : (
              <Trans i18nKey="AssignmentLabel.furtherMoreMultiple">
                & {{ furtherMore: entries.slice(clip).length }} weitere
              </Trans>
            )}
          </span>
        ) : null}
      </div>
    </BFUseTooltip>
  );
};
export default AssignmentLabel;

const UserLabel = (props: { avatarSize?: number; user: string }) => {
  return (
    <div className="user-label-container">
      <Userlabel id={props.user} />
    </div>
  );
};

const TeamLabel = (props: { team: string }) => {
  return (
    <div className="team-label-container">
      {/* {props.includeAvatar ? (
        <div
          style={{
            width: props.avatarSize || 18,
            height: props.avatarSize || 18,
          }}
          className="team-avatar"
        >
          <BfIcon
            type="light"
            data={"multiple-users-2"}
            width={(props.avatarSize || 18) * 0.8}
            height={(props.avatarSize || 18) * 0.8}
          />
        </div>
      ) : null} */}
      <Teamname id={props.team} />
    </div>
  );
};

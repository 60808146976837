import { InvoiceDirection } from "@/apps/tatar/invoiceApp/RAInterfaces";
import QueryLoader from "@/components/QueryLoader/QueryLoader";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact } from "@/model/db/Contact";
import {
  AggregationStatisticQueryResult,
  AggregationStatisticQuerySelector,
} from "@/redux/model";
import MQ from "@/utils/MatchQueryUtils";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import "./ContactInvoiceKPIs.scss";
import {
  InvoiceFilterType,
  matchQueryOfFilterType,
} from "./ContactInvoiceUtils";

const STATIC_SELECTORS: () => AggregationStatisticQuerySelector[] = () => [
  {
    name: "general",
    op: [
      { key: "count", op: "count" },
      {
        key: "amountToPay",
        op: "sum",
        field: "data.invoice.value.converted.amount",
      },
      {
        key: "outstandingPayment",
        op: "sum",
        field: "data.payment.outstandingPayment",
      },
      {
        key: "payedAmount",
        op: "sum",
        field: "data.payment.payedAmount",
      },
      {
        key: "outstandingToPayed",
        op: "sum",
        field: "data.payment.outstandingToPayed",
      },
    ],
    query: {},
  },
  {
    name: "open",
    op: [
      { key: "count", op: "count" },
      {
        key: "amountToPay",
        op: "sum",
        field: "data.invoice.value.converted.amount",
      },
      {
        key: "outstandingPayment",
        op: "sum",
        field: "data.payment.outstandingPayment",
      },
      {
        key: "payedAmount",
        op: "sum",
        field: "data.payment.payedAmount",
      },
      {
        key: "outstandingToPayed",
        op: "sum",
        field: "data.payment.outstandingToPayed",
      },
    ],
    query: {
      matchQuery: matchQueryOfFilterType("open"),
    },
  },
  {
    name: "declined",
    op: [
      { key: "count", op: "count" },
      {
        key: "amountToPay",
        op: "sum",
        field: "data.invoice.value.converted.amount",
      },
    ],
    query: {
      matchQuery: matchQueryOfFilterType("declined"),
    },
  },
  {
    name: "closed",
    op: [
      { key: "count", op: "count" },
      {
        key: "amountToPay",
        op: "sum",
        field: "data.invoice.value.converted.amount",
      },
      {
        key: "payedAmount",
        op: "sum",
        field: "data.payment.payedAmount",
      },
    ],
    query: {
      matchQuery: matchQueryOfFilterType("closed"),
    },
  },
];

interface ContactInvoiceKPIsProps {
  contact: Contact;
  statusFilter: InvoiceFilterType;
  activeDirection: InvoiceDirection;
}
const ContactInvoiceKPIs = (props: ContactInvoiceKPIsProps) => {
  return (
    <div className={classNames(`contact-invoice-kpis`)}>
      <QueryLoader
        assetType={AssetTypes.Invoice}
        referenceId="contact-invoice-kpis"
        matchQuery={MQ.and(
          MQ.eq("data.direction", props.activeDirection),
          MQ.eq("data.invoice.contact", props.contact._id),
          MQ.ne("data.status", "init")
        )}
        selectors={STATIC_SELECTORS()}
        render={(result: AggregationStatisticQueryResult) => (
          <>
            {props.statusFilter === "open" && (
              <>
                <KPIValue
                  label={i18n.t("ContactInvoiceView.count", "Anzahl")}
                  value={"" + (result?.open?.count || 0)}
                />
                <KPIValue
                  label={i18n.t(
                    "ContactInvoiceView.amountToPay",
                    "Rechnungsbetrag"
                  )}
                  // negative
                  value={StringUtils.formatCurrency(
                    result?.open?.amountToPay || 0
                  )}
                />
                {/* <KPIValue
                  label={i18n.t("ContactInvoiceView.dueInvoices", "Überfällig")}
                  negative
                  value={StringUtils.formatCurrency(1402.11)}
                /> */}
              </>
            )}

            {props.statusFilter === "closed" && (
              <>
                <KPIValue
                  label={i18n.t("ContactInvoiceView.count", "Anzahl")}
                  value={"" + (result?.closed?.count || 0)}
                />
                <KPIValue
                  label={i18n.t(
                    "ContactInvoiceView.amountToPay",
                    "Rechnungsbetrag"
                  )}
                  // negative
                  value={StringUtils.formatCurrency(
                    result?.closed?.amountToPay || 0
                  )}
                />
              </>
            )}

            {props.statusFilter === "declined" && (
              <>
                <KPIValue
                  label={i18n.t("ContactInvoiceView.count", "Anzahl")}
                  value={"" + (result?.declined?.count || 0)}
                />
              </>
            )}
          </>
        )}
      />
    </div>
  );
};

export default ContactInvoiceKPIs;

const KPIValue = (props: {
  value: string;
  label: string;
  positive?: boolean;
  negative?: boolean;
}) => {
  return (
    <div className={`kpi-value`}>
      <div className={`label`}>{props.label}</div>
      <div
        className={classNames(`value`, {
          "__color-positive": props.positive,
          "__color-negative": props.negative,
        })}
      >
        {props.value}
      </div>
    </div>
  );
};

import classNames from "classnames";
import i18n from "../../../../../../i18n";
import BaseAsset from "../../../../../../model/general-assets/BaseAsset";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import { DocumentStoreDocument } from "../../../../../../modules/document-store/DSInterfaces";
import DocumentStoreManaged from "../../../../../../modules/document-store/DocumentStoreManaged";
import { DocumentLinkData } from "./CBIdlyConnectService";
import "./CBIdlyDocumentChooser.scss";

interface CBIdlyDocumentChooserProps {
  unit: string;
  asset: BaseAsset;
  assetType: string;
  documentsFieldPath: string;

  addedDocuments: DocumentLinkData[];

  onClose: () => void;
  onSelect: (document: DocumentStoreDocument) => void;
}
const CBIdlyDocumentChooser = (props: CBIdlyDocumentChooserProps) => {
  return (
    <div className={classNames(`cb-idly-document-chooser`)}>
      <DocumentStoreManaged
        assetParams={{
          asset: props.asset,
          assetType: props.assetType,
          type: props.asset?.["data"]?.["type"],
          documentsFieldPath: props.documentsFieldPath,
        }}
        appearance="clean"
        contentScrollable={false}
        renderPostfixElement={(document) => (
          <div className={`choose-document-container`}>
            <BFButton
              disabled={props.addedDocuments.find(
                (e) => e.cdnId === document.linkToCdn
              )}
              onClick={() => {
                props.onSelect(document);
                props.onClose();
              }}
              appearance="link"
              size="xs"
            >
              {i18n.t("cb:CBIdlyDocumentChooser.select", "Auswählen")}
            </BFButton>
          </div>
        )}
      />
    </div>
  );
};

export default CBIdlyDocumentChooser;

import classNames from "classnames";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Nav } from "rsuite";
import i18n from "../../i18n";
import { AssetTypes } from "../../model/AssetTypes";
import { useTypedSelector } from "../../redux/hooks";
import DataBusDefaults from "../../services/DataBusDefaults";
import BfIcon from "../abstract-ui/icon/BfIcon";
import "./UserModalDialog.scss";
import UserDevices from "./views/devices/UserDevices";
import UserProfile from "./views/profile/UserProfile";
import UserReplacementRoot from "./views/replacement/UserReplacementRoot";
import UserSecurity from "./views/security/UserSecurity";
import UserTextblocks from "./views/textblocks/UserTextblocks";

interface UserModalDialogProps {}
const UserModalDialog = (props: UserModalDialogProps) => {
  const match = useRouteMatch();
  const location = useLocation();
  const hasUserConfigAsset = useTypedSelector((state) =>
    state.global.user.permissions
      .reduce((prev, current) => {
        if (!current.ASSET) {
          return prev;
        }
        return [...prev, ...current.ASSET.map((e) => e.AssetID)];
      }, [])
      ?.includes(AssetTypes.UserDependend.UserConfig)
  );

  const onSelect = (eventKey: any, event: any) => {
    DataBusDefaults.route({
      route: match.url + "/" + eventKey,
    });
  };
  if (!match.path.endsWith(":modalId")) {
    return null;
  }
  return (
    <div className={classNames(`user-modal-dialog`)}>
      <div className={`nav-bar`}>
        <Nav
          {...props}
          vertical
          activeKey={location.pathname
            .replace(`${match.url}/`, "")
            .replace(/\/.*/, "")}
          onSelect={onSelect}
          appearance="subtle"
          //   style={{ width: 150 }}
        >
          <Nav.Item
            eventKey="profile"
            icon={<BfIcon data="single-neutral" type="light" size="xs" />}
          >
            {i18n.t("UserProfile.Profile.Title", "Profil")}
          </Nav.Item>
          <Nav.Item
            eventKey="security"
            icon={<BfIcon data="shield-key" type="light" size="xs" />}
          >
            {i18n.t("UserProfile.Security.Title", "Account & Sicherheit")}
          </Nav.Item>
          {/* <Nav.Item
            eventKey="organization"
            icon={<BfIcon data="building-1" type="light" size="xs" />}
          >
            {i18n.t("UserProfile.Organization.Title", "Organisation")}
          </Nav.Item> */}

          <Nav.Item
            eventKey="replacement"
            icon={
              <BfIcon data="plane-trip-take-off-time" type="light" size="xs" />
            }
          >
            {i18n.t("UserProfile.Replacement.Title", "Vertretung")}
          </Nav.Item>
          {hasUserConfigAsset && (
            <Nav.Item
              eventKey="textblocks"
              icon={
                <BfIcon data="paginate-filter-text" type="light" size="xs" />
              }
            >
              {i18n.t("UserProfile.Profile.Textblocks", "Textbausteine")}
            </Nav.Item>
          )}

          <Nav.Item
            eventKey="devices"
            icon={<BfIcon data="mobile-phone-1" type="light" size="xs" />}
          >
            {i18n.t("UserProfile.Devices.Title", "Geräte")}
          </Nav.Item>
          {/* <Nav.Item
            eventKey="history"
            icon={<BfIcon data="time-clock-circle" type="light" size="xs" />}
          >
            {i18n.t("UserProfile.History.Title", "Historie")}
          </Nav.Item> */}
        </Nav>
      </div>
      <div className={`profile-content`}>
        <Switch>
          <Route path={`${match.path}/profile`}>
            <UserProfile />
          </Route>
          {hasUserConfigAsset && (
            <Route path={`${match.path}/textblocks`}>
              <UserTextblocks />
            </Route>
          )}
          <Route path={`${match.path}/security`}>
            <UserSecurity />
          </Route>
          {/* <Route path={`${match.path}/organization`}>
            <UserOrganization />
          </Route> */}
          <Route path={`${match.path}/devices`}>
            <UserDevices />
          </Route>
          <Route path={`${match.path}/replacement`}>
            <UserReplacementRoot />
          </Route>
          {/* <Route path={`${match.path}/history`}>
            <UserHistory />
          </Route> */}
          <Route path={`${match.path}`}>
            <Redirect to={`${match.path}/profile`} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default UserModalDialog;

import classNames from "classnames";
import { useDispatch } from "react-redux";
import { CDNImagePropsWithContent } from "../../../../../../components/CDNImageGallery/CDNImageGallery";
import FilePreviewComponent from "../../../../../../components/FilePreviewComponent/FilePreviewComponent";
import WrappedText from "../../../../../../components/WrappedText/WrappedText";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import { ProgressCell } from "../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFOverlay from "../../../../../../modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "../../../../../../modules/abstract-ui/icon/BfIcon";
import { openGallery } from "../../../../../../redux/actions/ui-config/ui-config-actions";
import { APProjectBudget } from "../project-budget/APProjectBudgetInterfaces";
import { getConstructionDiaryDocumentationTypeLabel } from "./APConstructionDiariesHelpers";
import {
  APProjectConstructionDiary,
  APProjectConstructionDiaryDocumentation,
  ConstructionDiaryGroupLinked,
} from "./APConstructionDiariesInterfaces";
import "./APConstructionDiaryEntryRow.scss";

interface APConstructionDiaryEntryRowProps {
  diary: APProjectConstructionDiary;
  entry: APProjectConstructionDiaryDocumentation;
  budget?: APProjectBudget;
}
const APConstructionDiaryEntryRow = (
  props: APConstructionDiaryEntryRowProps
) => {
  const dispatch = useDispatch();

  const images = props.entry.imageCdnLinks
    .map((id) => ({
      cdn: props.diary.cdn?.find((e) => e._id === id),
      image: props.diary.data?.images?.find((e) => e.linkToCdn === id),
    }))
    .filter((e) => e)
    .map(
      (imgEntry) =>
        ({
          assetField: "data.images",
          assetId: props.diary._id,
          assetType: AssetTypes.ActivityRelated.ConstructionDiary,
          hasFolderReadPermissions: true,
          cdnId: imgEntry.image.linkToCdn,
          fileKey: imgEntry.cdn.key,
          renderContent: imgEntry.image.note ? (
            <WrappedText text={imgEntry.image.note} />
          ) : null,
        } as CDNImagePropsWithContent)
    );

  return (
    <div className={classNames(`ap-construction-diary-entry-row`)}>
      <div className={`row-content`}>
        <div className={`row-head`}>
          <div className={`status`}>
            {getConstructionDiaryDocumentationTypeLabel(
              props.entry.documentationType
            )}
          </div>
          <div className={`title`}>
            {props.entry.group.type === "custom" &&
              props.entry.group.displayName}
            {props.entry.group.type === "group" &&
              props.budget?.data.scheduleGroups.find(
                (group) =>
                  group.id ===
                  (props.entry.group as ConstructionDiaryGroupLinked).linkedId
              )?.name}
          </div>
          {props.entry.documentationType === "workProgress" ? (
            <div className={`progress-container`}>
              <ProgressCell progress={props.entry.progress / 100} />
              {/* {StringUtils.formatPercent(props.entry.progress)} */}
            </div>
          ) : null}
        </div>
        <div className={`row-remark`}>
          {props.entry.note ? props.entry.note : null}
        </div>
      </div>

      {images.length > 0 && (
        <div className={`images-container`}>
          {images.map((image, index) => {
            const cmp = (showInfo: boolean) => (
              <div className={`image`} key={image.cdnId}>
                <FilePreviewComponent
                  type="cdn"
                  asset={props.diary}
                  cdnID={image.cdnId}
                  assetField={image.assetField}
                  assetType={image.assetType}
                  hasFolderPermissions
                  height={60}
                  width={60}
                  onClick={() => {
                    dispatch(
                      openGallery({
                        id: `ap-activity-details-gallery-${props.entry._id}`,
                        images: images,
                        initialActiveIndex: index,
                      })
                    );
                  }}
                />
                {showInfo && (
                  <div className={`info-indicator`}>
                    <BfIcon type="bold" data="messages-bubble" size="xxs" />
                  </div>
                )}
              </div>
            );

            if (!image.renderContent) return cmp(false);
            else
              return (
                <BFOverlay
                  speaker={
                    <div className={`padding-10`}>{image.renderContent}</div>
                  }
                >
                  {cmp(true)}
                </BFOverlay>
              );
          })}
        </div>
        // <div className={`image-container`}>
        //   <CDNImageGallery
        //     id={`ap-activity-details-gallery-${props.entry._id}`}
        //     openable
        //     usePagination={false}
        //     // useMousewheel
        //     useNavigationButtons
        //     cdnImages={images}
        //     initialIndex={0}
        //     slidesPerView={7}
        //     loadTreshold={10}
        //     spaceBetween={5}
        //     ignoreContent={false}
        //     dimension="lg"
        //   />
        // </div>
      )}
    </div>
  );
};

export default APConstructionDiaryEntryRow;

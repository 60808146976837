import classNames from "classnames";
import i18n from "../../../../../../i18n";
import DataBus from "../../../../../../services/DataBus";
import GlobalActions from "../../../../../../services/GlobalActions";
import { DataBusSubKeys } from "../../../../../../utils/Constants";
import BFButton from "../../../../../abstract-ui/general/Button/BFButton";
import { RSListIdentifiers } from "../../../../RSConstants";
import { Resubmission } from "../../../../RSInterfaces";
import RSPreviewComponent from "../../RSPreviewComponent";
import "./RSPreviewModal.scss";

interface RSPreviewModalProps {
  close: () => void;
  resubmission: Resubmission;
}

const RSPreviewModal = (props: RSPreviewModalProps) => {
  const { close, resubmission } = props;

  return (
    <div className={classNames("rs-preview-modal")}>
      <div className="rs-preview-modal-content">
        <RSPreviewComponent
          listIdentifier={RSListIdentifiers.GlobalDashboard}
          resubmission={resubmission}
          closeAction={close}
        />
        <div className="rs-preview-modal-actions">
          <BFButton
            appearance="outline"
            onClick={() => {
              close();
            }}
          >
            {i18n.t("Resubmission.Action.Close", "Wiedervorlage schließen")}
          </BFButton>
          {resubmission.data.linkedAsset && (
            <BFButton
              appearance="outline"
              onClick={() => {
                DataBus.emit(DataBusSubKeys.MODAL_MANAGER_CLOSE_ALL, {});
                const { linkedAsset, type } = resubmission.data;
                GlobalActions.openResubmission({
                  linkedAsset: linkedAsset,
                  type: type,
                  resubmissionId: resubmission._id,
                });
              }}
            >
              {i18n.t(
                "Resubmission.Action.OpenAsset",
                "Verknüpfte Daten öffnen"
              )}
            </BFButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default RSPreviewModal;

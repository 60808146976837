export const RSLinkedAssetFieldIdentifier = {
  DSDocument: "ds-document",
  MailIncoming: "mail_incoming",
};

export const RSListIdentifiers = {
  GlobalDashboard: "RESUBMISSION_GLOBAL_DASHBOARD",
  KpiOwn: "RESUBMISSION_KPI_OWN",
  KpiFeedback: "RESUBMISSION_KPI_FEEDBACK",
  AppBarButton: "RESUBMISSION_APP_BAR_BUTTON",
};

export const RSDatabusEvents = {
  UpdateResubmissions: "update-resubmissions",
};

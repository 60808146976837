import CBTenantRentalAgreements from "@/apps/tatar/cashBudget/views/tenants/components/tenants/CBTenantRentalAgreements";
import { Contact } from "@/model/db/Contact";
import classNames from "classnames";
import "./ContactRentalView.scss";

interface ContactRentalViewProps {
  contact: Contact;
}
const ContactRentalView = (props: ContactRentalViewProps) => {
  return (
    <div className={classNames(`contact-rental-view`)}>
      <CBTenantRentalAgreements tenant={props.contact} />
    </div>
  );
};

export default ContactRentalView;

import classNames from "classnames";
import i18n from "../../../../../i18n";
import BfIcon from "../../../icon/BfIcon";
import BFButton from "../BFButton";

interface JumpToButtonProps {
  onClick: () => void;
  stopPropagation?: boolean;
  tooltipText?: string;
}

const JumpToButton = (props: JumpToButtonProps) => {
  return (
    <div className={classNames("jump-to-button")}>
      <BFButton
        appearance="link"
        onClick={(event) => {
          if (props.stopPropagation) {
            event.stopPropagation();
          }
          props.onClick();
        }}
        size="xs"
        tooltip={{
          tooltip: props.tooltipText
            ? props.tooltipText
            : i18n.t("Global.Buttons.jump"),
        }}
      >
        <BfIcon type="light" data="expand-6" size="xs" />
      </BFButton>
    </div>
  );
};

export default JumpToButton;

import _ from "lodash";
import moment from "moment";
import { ThunkDispatch } from "redux-thunk";
import { Filter } from "../../../services/DataService";
import { CACHE_TTL } from "../../../utils/Constants";
import { isDefined } from "../../../utils/Helpers";
import { HTTP } from "../../../utils/Http";
import { AppState } from "../../store";
import {
  ApplicationAction,
  CLEAR_ALL_CACHES,
  CLEAR_APPLICATION_DATA,
  CLEAR_FLEX_CACHE_DATA,
  DATA_QUERY_SET_DATA,
  DATA_QUERY_SET_ERROR,
  DATA_QUERY_SET_LOADING,
  PATCH_TABLE_ROW_DATA,
  REMOVE_TABLE_NEW_DATA,
  RESET_LOG_DATA,
  SET_APPICATION_CACHE_DATA,
  SET_CONSTANT_DATA,
  SET_FLEX_CACHE_DATA,
  SET_FLEX_CACHE_DATA_MULTIPLE,
  SET_LOG_DATA,
  SET_LOG_LOADING,
  SET_RELOAD_TABLE,
  SET_TABLE_DATA,
  SET_TABLE_EVENT,
  SET_TABLE_FILTER,
  SET_TABLE_FULLTEXT_SEARCH,
  SET_TABLE_LOADING,
  SET_TABLE_NEW_DATA,
  SET_TABLE_SORT,
  TABLE_EVENT_TYPES,
} from "./application-action-types";
import InfiniteTable from "./application-infinite-table-actions";
import { getQueryId } from "./application-utils";

export function addTableNewData(tableIdentifier: string, assetId: string) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: SET_TABLE_NEW_DATA,
      tableIdentifier,
      assetId,
    });
  };
}
export function removeTableNewData(tableIdentifier: string, assetId: string) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: REMOVE_TABLE_NEW_DATA,
      tableIdentifier,
      assetId,
    });
  };
}

export function clearAllCaches() {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: CLEAR_ALL_CACHES,
    });
  };
}

export function setFlexCacheData(category, identifier, data) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: SET_FLEX_CACHE_DATA,
      category,
      identifier,
      data,
    });
  };
}
export function setFlexCacheDataMultiple(
  flexData: { category: string; identifier: string; data: any }[]
) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: SET_FLEX_CACHE_DATA_MULTIPLE,
      flexData,
    });
  };
}

export function clearFlexCacheData(category) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: CLEAR_FLEX_CACHE_DATA,
      category,
    });
  };
}

export function setConstantData(key: string, value: any) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: SET_CONSTANT_DATA,
      key,
      value,
    });
  };
}
export function resetLogData(identifiers: string[]) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: RESET_LOG_DATA,
      identifiers,
    });
  };
}

export function setLogLoading(
  logIdentifier: string,
  mode: "append" | "prepend" | "replace"
) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: SET_LOG_LOADING,
      logIdentifier,
      mode,
    });
  };
}

export function setLogData(
  logIdentifier: string,
  mode: "append" | "prepend" | "replace",
  data: Object[],
  params: any
) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_LOG_DATA,
      logIdentifier,
      mode,
      data,
      params,
    });
  };
}
export function setReloadTable(tableIdentifier: string, reload: boolean) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_RELOAD_TABLE,
      tableIdentifier,
      reload,
    });
  };
}

export function setTableLoading(tableIdentifier: string, loading: boolean) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_TABLE_LOADING,
      tableIdentifier,
      loading,
    });
  };
}
export function setTableData(
  tableIdentifier: string,
  data: {
    loading?: boolean;
    url?: string;
    append?: boolean;
    total?: number;
    data?: Object[];
    limit?: number;
    skip?: number;
    fulltextSearch?: string;
    filters?: { [dataKey: string]: Filter };
    sort?:
      | { dataKey: string; sortType: "asc" | "desc" }
      | { dataKey: string; sortType: "asc" | "desc" }[];
  }
) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_TABLE_DATA,
      tableIdentifier,
      data,
    });
  };
}

export function patchAssetData(id: string, data: any) {
  return (
    dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
    getState: () => AppState
  ) => {
    const state: AppState = getState();

    //update all relevant entries in tables
    const tables = state.application.tables;
    Object.entries(tables).forEach(([tableIdentifier, tableData]) => {
      if (tableData.data) {
        const found = tableData.data.find((row: any) => row._id === id);
        if (found) {
          dispatch(patchTableData(tableIdentifier, id, data));
        }
      }
    });
    //update all relevant entries in tables
    dispatch(InfiniteTable.searchInTablesAnPatchData(id, data, "patchRoot"));

    //update all relevant entries in cache
    const cache = state.application.cache;

    Object.entries(cache)
      .filter(
        ([category]) =>
          ["user", "group", "asset", "flex", "query"].indexOf(category) === -1
      )
      .forEach(([category, categoryData]) => {
        Object.entries(categoryData).forEach(([identifier, cacheData]) => {
          if (cacheData && cacheData.data && cacheData.data._id === id) {
            dispatch({
              type: SET_APPICATION_CACHE_DATA,
              oType: "asset",
              id: id,
              data: _.merge({}, cacheData.data, data),
              ttl: CACHE_TTL, //todo configurable value,
              assetType: category,
              global: cacheData.global,
            });
          }
        });
      });
  };
}

export function patchTableData(
  tableIdentifier: string,
  id: string,
  data: any,
  mode: "overwrite" | "patchRoot" | "merge" = "patchRoot"
) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: PATCH_TABLE_ROW_DATA,
      tableIdentifier,
      rowId: id,
      data,
      mode,
    });
  };
}

export function setTableFilter(
  tableIdentifier: string,
  dataKey: string,
  filter: Filter
) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: SET_TABLE_FILTER,
      tableIdentifier,
      dataKey,
      filter,
    });
  };
}

export function setTableFulltext(
  tableIdentifier: string,
  fulltextSearch: string
) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: SET_TABLE_FULLTEXT_SEARCH,
      tableIdentifier,
      fulltextSearch,
    });
  };
}

export function setTableEvent(
  tableIdentifier: string,
  event: TABLE_EVENT_TYPES,
  data: Object
) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: SET_TABLE_EVENT,
      tableIdentifier,
      event,
      data,
    });
  };
}

export function setTableSort(
  tableIdentifier: string,
  sort: {
    dataKey: string;
    sortType: "asc" | "desc";
  }[]
) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: SET_TABLE_SORT,
      tableIdentifier,
      sort,
    });
  };
}

export function setApplicationCacheData(
  oType: string,
  id: string,
  data: any,
  timestamp: number,
  ttl?: number,
  assetType?: string,
  global?: boolean
) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_APPICATION_CACHE_DATA,
      oType,
      id,
      data,
      timestamp,
      ttl,
      assetType,
      global,
    });
  };
}

export function clearApplicationData(paths: string[]) {
  return (dispatch: ThunkDispatch<{}, {}, ApplicationAction>) => {
    dispatch({
      type: CLEAR_APPLICATION_DATA,
      paths,
    });
  };
}

export function queryData(
  name: string,
  version: number | null,
  variables: any,
  deprecateOn?: Date,
  force?: boolean,
  referenceId?: string
) {
  return (
    dispatch: ThunkDispatch<{}, {}, ApplicationAction>,
    getState: () => AppState
  ) => {
    const {
      application: {
        cache: { query },
      },
    } = getState();

    const queryId = getQueryId(name, version, variables, referenceId);
    const savedQuery = query[queryId] || null;

    if (
      force ||
      !savedQuery ||
      (savedQuery.deprecateOn &&
        moment().isAfter(moment(savedQuery.deprecateOn)))
    ) {
      dispatch({
        type: DATA_QUERY_SET_LOADING,
        queryId,
      });
      HTTP.post({
        target: "STATISTIC",
        url: `query/${name}${isDefined(version) ? `/${version}` : ""}`,
        bodyParams: variables,
      })
        .then((response) => {
          dispatch({
            type: DATA_QUERY_SET_DATA,
            queryId,
            data: response,
            deprecateOn,
            referenceId,
          });
        })
        .catch((err) => {
          //FIXME handle error message properly
          dispatch({
            type: DATA_QUERY_SET_ERROR,
            queryId,
            error: err,
            referenceId,
          });
        });
    }
  };
}

if (!(window as any).actions) {
  (window as any).actions = {};
}
(window as any).actions.application = {
  clearApplicationData,
  setConstantData,
};

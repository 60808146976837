import i18n from "../../i18n";
import { CommentTypes, EmailUser } from "./Comments";

export const EMAIL_TAG_COLOR = "#dcdcdc";

const REGEX_USER_MENTION =
  /<span class="mention".*?data-object-id="([0-9a-f]+)".*?data-mention-type="user".*?<\/span>/g;
const REGEX_TEAM_MENTION =
  /<span class="mention".*?data-object-id="([0-9a-f]+)".*?data-mention-type="team".*?<\/span>/g;
const REGEX_BLOCK_QUOTE = /<blockquote>.*?<\/blockquote>/g;

//@ts-ignore
const REGEX_COMMENT = /<\!\-\-.*?\-\->/gs;
export const COMMENTS_MODULE_FLEX_PREFIX = "CM_Mode_";
class CommentUtilsClass {
  stripMentionsFromHTML(html: string): string {
    return html
      .replaceAll(REGEX_USER_MENTION, "")
      .replaceAll(REGEX_TEAM_MENTION, "");
  }
  stripQuotesFromHTML(html: string): string {
    return html.replaceAll(REGEX_BLOCK_QUOTE, "");
  }
  stripCommentsFromHTML(html: string): string {
    return html.replaceAll(REGEX_COMMENT, "");
  }
  stripImagesBase64FromHTML(html: string): string {
    return html.replace(
      /<img.*?src="data:image\/.*?;base64,([a-zA-Z0-9+\/+\w=]*)".*?\>/gi,
      `<p style="font-style:italic;font-size:12px;font-weight:bold">${i18n.t(
        "CommentsModule.ImageRemoved",
        "Bild entfernt"
      )}</p>`
    );
  }

  formatEmailUser(emailUser: EmailUser) {
    return emailUser.name
      ? `${emailUser.name} (${emailUser.address})`
      : emailUser.address;
  }

  getLabelForCommentType(commentType: string) {
    switch (commentType) {
      case CommentTypes.INTERN:
        return i18n.t("CommentsModule.Type.intern", "Interer Kommentar");
      case CommentTypes.MAIL_INCOMING:
        return i18n.t("CommentsModule.Type.mail_incoming", "Eingehende E-Mail");
      case CommentTypes.MAIL_OUTGOING:
        return i18n.t("CommentsModule.Type.mail_outgoing", "Ausgehende E-Mail");
      case CommentTypes.SYSTEM:
        return i18n.t("CommentsModule.Type.system", "System");
      default:
        return i18n.t("CommentsModule.Type.unknown", "Unbekannt");
    }
  }

  extractMentions(comment: string) {
    const users: string[] = [];
    const teams: string[] = [];

    const userIterator = comment.matchAll(REGEX_USER_MENTION);
    let cur = userIterator.next();
    while (!cur.done) {
      users.push(cur.value[1]);
      cur = userIterator.next();
    }

    const teamIterator = comment.matchAll(REGEX_TEAM_MENTION);
    cur = teamIterator.next();
    while (!cur.done) {
      teams.push(cur.value[1]);
      cur = teamIterator.next();
    }

    return {
      users,
      teams,
    };
  }
}
const CommentUtils = new CommentUtilsClass();
export default CommentUtils;

import i18n from "@/i18n";
import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import React, { useEffect, useState } from "react";
import {
  BaseStyle,
  TableStyle,
} from "@/components/PDFConstructor/services/types/pdfConfigUtilTypes";

type PDFTableFooterInputProps = {
  value: TableStyle & BaseStyle;
  onChange: <Key extends keyof (TableStyle & BaseStyle)>(
    key: Key,
    value: (TableStyle & BaseStyle)[Key]
  ) => void;
  columnsAmount?: number;
  hideColumnsInput?: boolean;
};

const PDFTableFooterInput: React.FC<PDFTableFooterInputProps> = ({
  value,
  onChange,
  columnsAmount = 10,
  hideColumnsInput,
}) => {
  useEffect(() => {
    if (hideColumnsInput) {
      return;
    }

    const defaultFontSize = 16;
    let columns = value.columnsInRow;

    if (value.changeOrientation) {
      columns *= 2;
    }

    if (value.fontSize !== defaultFontSize) {
      // add/subtract a column for each 2px difference
      columns += (defaultFontSize - value.fontSize) / 2;
    }

    const finalColumns = Math.max(
      0,
      Math.min(Math.floor(columns), columnsAmount)
    );
    onChange("columnsInRow", finalColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.fontSize, value.changeOrientation]);

  const [isRowsSplitDisabled, setIsRowsSplitDisabled] = useState(
    value.rowsInTable === -1
  );

  return (
    <>
      <BFInput
        type="number"
        label={i18n.t(
          "Component.PDFConstructor.Block.FontSize",
          "Schriftgröße"
        )}
        value={value.fontSize}
        onChange={(value) => onChange("fontSize", Number(value))}
      />

      <BFCheckbox
        checked={value.insertBreak}
        onChange={(_, value) => onChange("insertBreak", value)}
      >
        {i18n.t(
          "Component.PDFConstructor.Block.TablePageBreak",
          "Seitenumbruch vor der Tabelle einfügent"
        )}
      </BFCheckbox>

      <BFCheckbox
        checked={value.changeOrientation}
        onChange={(_, value) => onChange("changeOrientation", value)}
        disabled={!value.insertBreak}
      >
        {value.changeOrientation
          ? i18n.t(
              "Component.PDFConstructor.Block.PageOrientation.Landscape",
              "Querformat"
            )
          : i18n.t(
              "Component.PDFConstructor.Block.PageOrientation.Portrait",
              "Hochformat"
            )}
      </BFCheckbox>

      {!hideColumnsInput && (
        <BFInput
          label={i18n.t(
            "Component.PDFConstructor.Block.RowColumns",
            "Spalten in einer Zeile"
          )}
          type="number"
          value={value.columnsInRow}
          onChange={(value) => onChange("columnsInRow", Number(value))}
          validation={
            value.columnsInRow > columnsAmount
              ? {
                  message: "You have chosen too many columns",
                  level: "error",
                }
              : null
          }
        />
      )}

      <BFInput
        label={i18n.t(
          "Component.PDFConstructor.Block.RowsInTable",
          "Zeilen in einer Tabelle"
        )}
        type="number"
        value={value.rowsInTable}
        onChange={(value) => onChange("rowsInTable", Number(value))}
        disabled={isRowsSplitDisabled}
      />

      <BFCheckbox
        checked={isRowsSplitDisabled}
        onChange={(_, value) => {
          setIsRowsSplitDisabled(value);

          if (value) {
            onChange("rowsInTable", -1);
          }
        }}
      >
        {isRowsSplitDisabled
          ? i18n.t(
              "Component.PDFConstructor.Block.RowsSplit.Enable",
              "Zeilenaufteilung aktivieren"
            )
          : i18n.t(
              "Component.PDFConstructor.Block.RowsSplit.Disable",
              "Zeilenaufteilung deaktivieren"
            )}
      </BFCheckbox>
    </>
  );
};

export { PDFTableFooterInput };

import arrayMutators from "final-form-arrays";
import setFieldData from "final-form-set-field-data";
import flatten from "flat";
import _ from "lodash";
import { CSSProperties } from "react";
import { Form, FormSpy } from "react-final-form";
import DebugDataComponent from "../../debug/DebugDataComponent";
import Log from "../../debug/Log";
import { SubmitMessage, SubmitResponse } from "../../services/SubmitService";
import {
  AbstractComponent,
  AbstractProps,
  AbstractStates,
} from "../../utils/abstracts/AbstractComponent";
import { DataBusSubKeys } from "../../utils/Constants";
import { Properties } from "../../utils/Properties";
import { GFMessage } from "../generic-forms-impl/layout-components/LayoutComponentsImpl";
import { GFBaseElement } from "./GFBaseElement";
import JsonValidation, { JsonProperties } from "./util/JsonValidation";
import JsonValidationException from "./util/JsonValidationException";
export interface FormDefinition {
  properties: JsonProperties;
  layout: { [key: string]: any };
}

type Props = {
  id?: string;
  assetType?: string;
  formDefinition: FormDefinition;
  formValue?: any;
  formData?: any;
  data?: any;
  additionalData?: any;
  resetOnSubmit?: boolean;
  ignoreDeleteMessage: boolean;
  ignoreReduceToValidInput?: boolean;
  onFormSubmit: (data) => Promise<any>;
  onFormCancel: () => void;
  translateFunc: (key) => string;
  ignoreValidationKeys?: string[];
  ignoreValidationKeysBesides?: string[];
  style?: CSSProperties;
} & AbstractProps;

type States = {
  warning: { [key: string]: string };
} & AbstractStates;

class GenericForms extends AbstractComponent<Props, States> {
  static defaultProps = {
    ignoreDeleteMessage: false,
  };
  oldState = {
    hasValidationErrors: null,
  };
  warningFields = [];
  formProps;

  constructor(props) {
    super(props);
    this.state = {
      warning: {},
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);

    if (
      _.isEqual(nextProps.data, this.props.data) &&
      _.isEqual(nextProps.formDefinition, this.props.formDefinition) &&
      _.isEqual(nextProps.formValue, this.props.formValue) &&
      _.isEqual(nextProps.additionalData, this.props.additionalData) &&
      nextProps.id === this.props.id
    ) {
      return shouldUpdate;
    } else {
      return true;
    }
  }

  componentDidMount(): void {
    this.subscribe(DataBusSubKeys.SUBMIT, (data: SubmitMessage) => {
      if (data.id === this.getIdentifier()) {
        this.populateButtonState("submit", {
          loading: true,
        });
      }
    });

    this.subscribe(DataBusSubKeys.SUBMIT_RESPONSE, (data: SubmitResponse) => {
      if (data.id === this.getIdentifier()) {
        this.populateButtonState("submit", {
          loading: false,
        });
      }
    });

    this.subscribeActionEvent("submit", (actionData) => {
      this.callSubmit();
    });

    this.populateButtonState("submit", { hidden: false });
  }

  onSubmit(data) {
    if (this.props.onFormSubmit) {
      this.props
        .onFormSubmit(data)
        .then(() => {
          if (this.props.resetOnSubmit) {
            this.resetForm();
          }
        })
        .catch((err) => {});
    }
  }

  callSubmit() {
    (this.formProps as any).form.submit();
  }

  resetForm(values?: any) {
    (this.formProps as any).form.reset(values);
  }

  validate(values) {
    const { formDefinition } = this.props;

    let validationResult;
    try {
      validationResult = JsonValidation.validateJson(
        values,
        formDefinition.properties,
        this.props.additionalData,
        undefined,
        undefined,
        values
      );
    } catch (error) {
      if (error instanceof JsonValidationException) {
        return {
          [(error as JsonValidationException).getKey()]: (
            error as JsonValidationException
          ).getMessage(),
        };
      }
      Log.error(error);
      throw error;
    }

    if (!_.isEqual(this.state.warning, validationResult.warning)) {
      setTimeout(() => {
        this.setState({ warning: validationResult.warning });
      });
    }

    if (this.props.ignoreValidationKeys) {
      this.props.ignoreValidationKeys.forEach(
        (key) => delete validationResult.error[key]
      );
    }
    if (this.props.ignoreValidationKeysBesides) {
      validationResult.error = Object.fromEntries(
        Object.entries(validationResult.error).filter(
          ([key]) => this.props.ignoreValidationKeysBesides.indexOf(key) !== -1
        )
      );
    }

    Log.debug("##VALIDATE ", values, validationResult.error);
    return flatten.unflatten(validationResult.error);
  }

  mutateWarnings(props) {
    const warnedKeys = [];
    Object.keys(this.state.warning).forEach((key) => {
      warnedKeys.push(key);
      props.form.mutators.setFieldData(key, {
        warning: this.state.warning[key],
      });
    });

    this.warningFields.forEach((key) => {
      if (warnedKeys.indexOf(key) === -1) {
        props.form.mutators.setFieldData(key, {
          warning: undefined,
        });
      }
    });
    this.warningFields = warnedKeys;

    // this.warningFields
    return null;
  }

  render() {
    const { additionalData, formDefinition, formValue, translateFunc, style } =
      this.props;
    const params = {
      ...this.props.params,
      allProperties: formDefinition.properties,
      formRoot: this,
      additionalData: additionalData,
    };
    Log.debug("##DEV", this.props);
    return (
      <Form
        mutators={{
          ...arrayMutators,
          getProps: () => {
            return this.props;
          },
          getIdentifier: () => {
            return this.props.identifier;
          },
          translateFunc,
          setFieldData,
          setValue: ([field, value], state, { changeValue }) => {
            if (state.formState.values) {
              changeValue(state, field, () => value);
            }
          },
        }}
        onSubmit={(values) => this.onSubmit(values)}
        validate={(values) => this.validate(values)}
        initialValues={
          formValue
            ? {
                _id: formValue._id,
                ...(this.props.ignoreReduceToValidInput
                  ? formValue
                  : JsonValidation.reduceToValidInput(
                      formValue,
                      formDefinition.properties
                    )),
              }
            : null
        }
        render={(props) => {
          this.formProps = props;
          return (
            <form
              className={`generic-forms`}
              style={{
                width: "100%",
                height: "fit-content",
                position: "relative",
                ...style,
              }}
              onSubmit={props.handleSubmit}
            >
              <>
                {!this.props.ignoreDeleteMessage &&
                this.props.formValue &&
                this.props.formValue._dirty &&
                this.props.formValue._dirty.uType === "deleted" ? (
                  <GFMessage
                    messageType="warning"
                    textKey="Global.Form.CurrentAssetDeleted"
                    params={params}
                  />
                ) : null}
                {Object.values(formDefinition.layout).map((item, index) => (
                  <GFBaseElement key={index} {...item} params={params} />
                ))}
                {this.mutateWarnings(props)}
                {Properties.debugMode || true ? (
                  <div className="row-no-gutters">
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => (
                        <DebugDataComponent text={"form-data"} data={values} />
                      )}
                    </FormSpy>
                  </div>
                ) : null}
              </>
            </form>
          );
        }}
      />
    );
  }
}

export default GenericForms;

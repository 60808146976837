import i18n from "../../../../../i18n";
import BFStatus from "../../../../../modules/abstract-ui/data/status/BFStatus";
import BFUseTooltip from "../../../../../modules/abstract-ui/general/tooltip/BFUseTooltip";
import StringUtils from "../../../../../utils/StringUtils";
import { RAInvoice } from "../../RAInterfaces";
import "./InvoicePaymentPlan.scss";

interface LinkedInvoicePaymentPlanProps {
  asset: RAInvoice;
}
const InvoicePaymentPlan = (props: LinkedInvoicePaymentPlanProps) => {
  return (
    <>
      {(props.asset?.data.payment.paymentPlan || []).length > 0 && (
        <BFUseTooltip
          delay={0}
          trigger="hover"
          tooltip={
            <div className={`ra-invoice-entry-suffix-payments`}>
              <div>
                {props.asset.data.payment.paymentPlan.map((payment, i) => (
                  <div key={i} className={`payment-entry`}>
                    <div className={`payment-entry-date`}>
                      {StringUtils.formatDate(payment.date)}
                    </div>
                    <div className={`payment-entry-amount`}>
                      {StringUtils.formatCurrency(
                        payment.amount,
                        undefined,
                        undefined,
                        props.asset?.data.invoice.value.currency
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
        >
          <div className={`invoice-payment-plan`}>
            <BFStatus
              size={"xs"}
              label={i18n.t("ra:Invoice.countPayments", "{{count}} Zahlungen", {
                count: props.asset?.data.payment.paymentPlan.length,
              })}
            />
          </div>
        </BFUseTooltip>
      )}
    </>
  );
};

export default InvoicePaymentPlan;

import classNames from "classnames";
import ListComponent from "../../../../../../../configurable/components/ListComponent/ListComponent";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import { Contact } from "../../../../../../../model/db/Contact";
import MQ from "../../../../../../../utils/MatchQueryUtils";
import { RentalAgreement } from "../../TenantsInterfaces";
import CBRentalAgreementEntry from "../rental-agreements/CBRentalAgreementEntry";
import "./CBTenantRentalAgreements.scss";

interface CBTenantRentalAgreementsProps {
  tenant: Contact;
}
const CBTenantRentalAgreements = (props: CBTenantRentalAgreementsProps) => {
  return (
    <div className={classNames(`cb-tenant-rental-agreements`)}>
      {/* <div className={`filter-row`}>filter</div> */}
      <div className={`list-container`}>
        <ListComponent
          identifier="cb-tenant-rental-agreements"
          asPost
          dataUrl={`/api/asset/list/${AssetTypes.Rental.RentalAgreement}`}
          additionalMatchQuery={MQ.and(MQ.eq("data.tenant", props.tenant._id))}
          render={(node: RentalAgreement) => (
            <div className={`__card list-entry`}>
              <CBRentalAgreementEntry rentalAgreement={node} />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default CBTenantRentalAgreements;

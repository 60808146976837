import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FilePreviewComponent from "@/components/FilePreviewComponent/FilePreviewComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BaseAsset from "@/model/general-assets/BaseAsset";
import BFDropzone from "@/modules/abstract-ui/dropzone/BFDropzone";
import LabeledInput from "@/modules/abstract-ui/forms/LabeledInput";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "@/modules/abstract-ui/general/ValidationPopover/ValidationPopover";
import CDNService from "@/services/CDNService";
import DataBusDefaults from "@/services/DataBusDefaults";
import SubmitService from "@/services/SubmitService";
import React from "react";
import { AssetCashBudgetAttachment } from "../../../model/CashBudgetEntry";
import "./CBAttachmentFormComponent.scss";

const uploadAttachmentFile = async (type: string, file: File) => {
  const cdn = (await CDNService.uploadFile({
    tempID: "tst",
    assetType: AssetTypes.CashBudget.Attachment,
    assetField: "data.files",
    filename: file.name,
    file: file,
  })) as { cdnID: string; tempID: string };

  const result = (await SubmitService.submitDataAsync({
    type: "asset",
    assetType: AssetTypes.CashBudget.Attachment,
    data: {
      data: {
        type,
        uploadType: "manual",
        files: {
          [cdn.cdnID]: {},
        },
      },
    },
    ignorePropChecks: true,
  })) as BaseAsset;

  return result._id;
};

interface CBAttachmentFormComponentProps {
  unit: string;
  value: string[];
  onChange: (value: string[]) => void;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  label?: string;
  labelPosition?: "top" | "left";
  validatorStyle?: ValidatorPopoverStyle;
}
const CBAttachmentFormComponent = (props: CBAttachmentFormComponentProps) => {
  const [uploading, setUploading] = React.useState(false);

  const upload = async (files) => {
    try {
      setUploading(true);
      let uploadedIds = [];
      for (const file of files) {
        const attachmentId = await uploadAttachmentFile(props.unit, file);
        uploadedIds.push(attachmentId);
        props.onChange([...props.value, ...uploadedIds]);
      }
    } catch (err) {
      DataBusDefaults.toast({
        type: "error",
        text: i18n.t("cb:AttachmentFormComponent.error", "Fehler beim Upload"),
      });
    } finally {
      setUploading(false);
    }
    setUploading(true);
  };
  return (
    <LabeledInput
      label={props.label}
      labelPosition={props.labelPosition}
      error={!!props.validation?.message}
    >
      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <BFDropzone
          onDrop={(accepted, rejected) => {
            if (accepted.length > 0) {
              upload(accepted).finally(() => setUploading(false));
            }
          }}
          accept={{
            "application/pdf": [".pdf"],
            "image/*": [".jpg", ".jpeg", ".png"],
          }}
          multipe
          render={(open) => (
            <div className={`connect-document-form`}>
              {(props.value || []).length > 0 && (
                <div className={`documents`}>
                  {props.value.map((attachmentId) => (
                    <AssetLoader
                      assetType={AssetTypes.CashBudget.Attachment}
                      id={attachmentId}
                      ignoreDelay
                      ignoreViewportDelay
                      render={(asset: AssetCashBudgetAttachment) => {
                        const [cdnId, data] = Object.entries(
                          asset.data.files
                        )[0];

                        //   assetType: string;
                        //   assetField: string;
                        //   hasFolderPermissions: boolean;
                        //   asset: BaseAsset;
                        //   cdnID: string;
                        //   type: "cdn";

                        //   bucket: string;
                        //   content_type: string;
                        //   field: string;
                        //   filename: string;
                        //   key: string;
                        //   size: number;
                        //   uploader: string;
                        return (
                          <div key={attachmentId} className={`element`}>
                            <div className={`preview`}>
                              <FilePreviewComponent
                                type="cdnBucket"
                                bucket={data}
                                assetId={asset._id}
                                assetField={"data.files"}
                                assetType={AssetTypes.CashBudget.Attachment}
                                cdnID={cdnId}
                                hasFolderPermissions={true}
                                height={80}
                                width={80}
                              />
                            </div>
                            <div className={`data`}>
                              <div className={`filename`}>{data.filename}</div>
                              <div className={`action`}>
                                <BFButton
                                  appearance="link"
                                  noPadding
                                  onClick={() => {
                                    props.onChange(
                                      props.value.filter(
                                        (e) => e !== attachmentId
                                      )
                                    );
                                  }}
                                >
                                  {i18n.t("Global.Buttons.remove")}
                                </BFButton>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    />
                  ))}
                </div>
              )}
              <div className={`actions`}>
                <BFButton appearance="link" onClick={open} loading={uploading}>
                  {i18n.t(
                    "cb:CashBudget.BookingCategoryRule.AttachmentForm.upload",
                    "Datei hochladen"
                  )}
                </BFButton>
              </div>
            </div>
          )}
        />
      </ValidationPopover>
    </LabeledInput>
  );
};

export default CBAttachmentFormComponent;

import CategoryStruct from "@/redux/actions/struct/implemented/CategoryStruct";
import OrgaStruct, {
  BankAccountModel,
  ObjectModel,
} from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import _ from "lodash";
import moment from "moment";
import i18n from "../../../i18n";
import { AssetTypes } from "../../../model/AssetTypes";
import Toast from "../../../modules/abstract-ui/notification/Toast";
import { store } from "../../../redux/store";
import DataBus from "../../../services/DataBus";
import DataBusDefaults from "../../../services/DataBusDefaults";
import LanguageService from "../../../services/LanguageService";
import SubmitService from "../../../services/SubmitService";
import { DataBusSubKeys } from "../../../utils/Constants";
import { HTTP } from "../../../utils/Http";
import { CashBudgetTags } from "./CashBudgetConst";
import {
  CBAssignmentRuleValueWithChildren,
  ConditionType,
} from "./form/CBAssignmentRuleModel";
import {
  AssignmentRuleWizzardFormValues,
  CashBudgetBookingAssignmentRule,
} from "./model/CashBudgetCofiguration";

export const CB_RENTAL_OPOS_DOCUMENTS_FIELD_PATH = "data.attachments";
export const CB_OBJECT_DOCUMENTS_FIELD_PATH = "data.attachments";
export const CB_TENANT_DOCUMENTS_FIELD_PATH = "data.attachments";
export const CB_RENTALAGREEMENT_DOCUMENTS_FIELD_PATH = "data.attachments";
export const CB_RENTALUNIT_DOCUMENTS_FIELD_PATH = "data.attachments";
export const CB_RENTALVACANCY_DOCUMENTS_FIELD_PATH = "data.attachments";
export const CASHBUDGET_FAST_CATEGORY_CHANGE =
  "CASHBUDGET_FAST_CATEGORY_CHANGE";

export const CB_CATEGORY_RULE_WIZZARD_PREVIEW =
  "CB_CATEGORY_RULE_WIZZARD_PREVIEW";
class CashBudgetUtilsClass {
  getColorOfUnit(type: string) {
    return UnitStruct.getUnit(type)?.data.unitColor;
  }
  getBankAccountByObject(objectId: string) {
    return OrgaStruct.getBankAccount(
      OrgaStruct.getObject(objectId)?.bankInfo?.mainBankAccount
    );
  }
  getLabelOfUnit(type: string) {
    return UnitStruct.getUnitLabel(type);
  }
  getAllCategoriesForType(type: string) {
    return CategoryStruct.getCategorysForType(type);
  }
  getUnitOfBankAccount(bankId: string) {
    return OrgaStruct.getBankAccount(bankId)?.type;
  }
  getUnitByCategory(categoryId: string) {
    return CategoryStruct.getCategory(categoryId)?.data.type;
  }
  getEarliestStartDate() {
    return UnitStruct.getAllData()
      .map((e) => moment(e.data.startDate))
      .sort((a, b) => a.diff(b))[0];
  }
  getAllEntities(considerRestrictions = false) {
    return OrgaStruct.getEntities(undefined, considerRestrictions);
  }
  getAllCategories(types?: string[]) {
    return CategoryStruct.getAllCategorys(types);
  }
  getAllCategoriesOfUnit(unit: string) {
    return CategoryStruct.getCategorysForType(unit);
  }
  getAllCategoriesPlannableOfUnit(unit: string, tagFilter?: string) {
    return this.getAllCategoriesOfUnit(unit).filter((e) =>
      tagFilter
        ? e.data.tags.includes(tagFilter)
        : (e.data.tags || []).indexOf(CashBudgetTags.PREVENT_PLANNABLE) === -1
    );
  }
  getAllCategoriesPlannable() {
    return this.getAllCategories().filter(
      (e) =>
        (e.data.tags || []).indexOf(CashBudgetTags.PREVENT_PLANNABLE) === -1
    );
  }
  findEntity(entityId: string) {
    return this.getAllEntities().find((e) => e._id === entityId);
  }
  getAllBankAccounts(considerRestrictions = false): BankAccountModel[] {
    return this.getAllEntities(considerRestrictions).reduce(
      (prev, current) => [...prev, ...current.banks],
      []
    );
  }
  getObjectsByBankAccount(bankAccount: string): ObjectModel[] {
    return this.getAllObjects().filter(
      (object) => object.bankInfo.mainBankAccount === bankAccount
    );
  }
  getAllObjects(): ObjectModel[] {
    return this.getAllEntities().reduce(
      (prev, current) => [...prev, ...current.objects],
      []
    );
  }
  findObject(objectId: string): ObjectModel {
    return this.getAllObjects().find((e) => e._id === objectId);
  }
  findEntityByEntityExternId(entityExternId: string) {
    return this.getAllEntities().find((e) => e.id === entityExternId);
  }
  findBankAccount(bankAccountId: string): BankAccountModel {
    return this.getAllBankAccounts().find((e) => e._id === bankAccountId);
  }
  getEntityByAccountId(bankAccountId: string) {
    return this.getAllEntities().find((entity) =>
      entity.banks.find((acc) => acc._id === bankAccountId)
    );
  }
  findCategory(categoryId: string) {
    return this.getAllCategories().find((e) => e._id === categoryId);
  }
  getAccountsOfEntities(entities: string[]) {
    return this.getAllEntities()
      .filter((entity) => entities.some((cId) => cId === entity._id))
      .reduce(
        (prev, current) => [...prev, ...current.banks],
        [] as BankAccountModel[]
      );
  }

  getCategoryWithTags(tags: string[]) {
    return this.getAllCategories().filter(
      (e) => _.intersection(tags, e.data.tags).length > 0
    );
  }

  getChildCategories(parentCategoryId: string) {
    return this.getAllCategories().filter(
      (e) => e.data.group === parentCategoryId
    );
  }

  getUnitByEntities(entities: string[]) {
    return OrgaStruct.getEntity(entities[0])?.type;
  }

  generateAdditionalDataForm() {
    const state = store.getState();

    const businessUnits =
      state.uiConfig.activeApplication?.constants?.businessUnits || [];
    const relevantTag =
      state.uiConfig.activeApplication?.constants?.relevantTag;

    return {
      cashBudgetIdentifiers: businessUnits,
      units: UnitStruct.getAllData().map((unit) => ({
        value: unit.data.identifier,
        label: LanguageService.translateLabel(unit.data.label),
      })),
      entities: Object.fromEntries(
        UnitStruct.getAllData().map((unit) => [
          unit.data.identifier,
          OrgaStruct.getEntities(unit.data.identifier, true)
            .map((entry) => ({
              value: entry._id,
              label: entry.displayName || "-",
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ])
      ),
      objects: Object.fromEntries(
        CashBudgetUtils.getAllEntities(true)?.map((entry) => [
          entry._id,
          entry.objects
            .map((obj) => ({
              label: obj.displayName || "-",
              value: obj._id,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ])
      ),
      objectsByBankaccount: Object.fromEntries(
        CashBudgetUtils.getAllBankAccounts(true)?.map((entry) => [
          entry._id,
          CashBudgetUtils.getObjectsByBankAccount(entry._id)
            .map((obj) => ({
              label: obj.displayName || "-",
              value: obj._id,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ])
      ),
      accountsAll: Object.fromEntries(
        UnitStruct.getAllData().map((unit) => [
          unit.data.identifier,
          OrgaStruct.getEntities(unit.data.identifier, true)
            .reduce(
              (prev, current) => [
                ...prev,
                ...current.banks.map((e) => {
                  const objects = CashBudgetUtils.getObjectsByBankAccount(
                    e._id
                  );

                  return {
                    value: e._id,
                    label: `${LanguageService.translateLabel(e.displayName)} (${
                      e.account
                    })`,
                    subLabel:
                      objects.length === 1
                        ? LanguageService.translateLabel(objects[0].displayName)
                        : `${objects.length} ${i18n.t("cb:Label.objects", {
                            unit: unit.data.identifier,
                          })}`,
                  };
                }),
              ],
              []
            )
            .sort((a, b) => a.label.localeCompare(b.label)),
        ])
      ),
      accounts: Object.fromEntries(
        CashBudgetUtils.getAllEntities(true)?.map((entry) => [
          entry._id,
          {
            defaults: Object.fromEntries(
              entry.objects.map((obj) => [
                obj._id,
                obj.bankInfo?.mainBankAccount,
              ])
            ),
            accounts: entry.banks
              .map((e) => {
                const objects = CashBudgetUtils.getObjectsByBankAccount(e._id);

                return {
                  value: e._id,
                  label: `${LanguageService.translateLabel(e.displayName)} (${
                    e.account
                  })`,
                  subLabel:
                    objects.length === 1
                      ? LanguageService.translateLabel(objects[0].displayName)
                      : `${objects.length} ${i18n.t("cb:Label.objects", {
                          unit: entry.type,
                        })}`,
                };
              })
              .sort((a, b) => a.label.localeCompare(b.label)),
          },
        ])
      ),
      categories: Object.fromEntries(
        UnitStruct.getAllData().map((unit) => [
          unit.data.identifier,
          [
            {
              value: "expenses",
              label: i18n.t("cb:Label.expenses"), // "Ausgaben",
              children: CategoryStruct.getCategorysForType(unit.data.identifier)
                .filter((entry) => entry.data.kind === "expense")
                .map((entry) => ({
                  value: entry._id,
                  label:
                    LanguageService.translateLabel(entry.data.displayName) ||
                    "-",
                })),
            },
            {
              value: "income",
              label: i18n.t("cb:Label.income"), //"Einnahmen",
              children: CategoryStruct.getCategorysForType(unit.data.identifier)
                .filter((entry) => entry.data.kind === "income")
                .map((entry) => ({
                  value: entry._id,
                  label:
                    LanguageService.translateLabel(entry.data.displayName) ||
                    "-",
                })),
            },
          ],
        ])
      ),
      categoriesPlannable: Object.fromEntries(
        UnitStruct.getAllData().map((unit) => [
          unit.data.identifier,
          [
            {
              value: "expenses",
              label: i18n.t("cb:Label.expenses"), //"Ausgaben",
              children: CashBudgetUtils.getAllCategoriesPlannableOfUnit(
                unit.data.identifier,
                relevantTag
              )
                .filter((entry) => entry.data.kind === "expense")
                .map((entry) => ({
                  value: entry._id,
                  label:
                    LanguageService.translateLabel(entry.data.displayName) ||
                    "-",
                })),
            },
            {
              value: "income",
              label: i18n.t("cb:Label.income"), //"Einnahmen",
              children: CashBudgetUtils.getAllCategoriesPlannableOfUnit(
                unit.data.identifier,
                relevantTag
              )
                .filter((entry) => entry.data.kind === "income")
                .map((entry) => ({
                  value: entry._id,
                  label:
                    LanguageService.translateLabel(entry.data.displayName) ||
                    "-",
                })),
            },
          ],
        ])
      ),
    };
  }

  getLabelForCategoryKind(kind: string) {
    switch (kind) {
      case "income":
        return i18n.t("cb:Label.income");
      case "expense":
        return i18n.t("cb:Label.expenses");
      default:
        return "-";
    }
  }

  async submitAssignmentRuleWizzardForm(
    formValues: AssignmentRuleWizzardFormValues
  ) {
    const assignmentRule =
      this.transformWizzardFormToAssignmentRule(formValues);

    try {
      const result = await SubmitService.submitDataAsync({
        type: "asset",
        assetType: AssetTypes.CashBudget.CBAssignmentRule,
        data: {
          ...assignmentRule,
        },
        ignorePropChecks: true,
        ignoreSubmitValidation: true,
      });
      DataBusDefaults.toast({
        text: i18n.t(
          "cb:Config.Assignments.SuccessfullySaved",
          "Zuweisungsregel erfolgreich gespeichert"
        ),
        type: "success",
      });

      const rule = result as CashBudgetBookingAssignmentRule;
      this.runBookingAssignmentRule(rule._id);

      return rule;
    } catch (error) {
      DataBusDefaults.toast({
        type: "error",
        text: i18n.t(
          "cb:Config.Assignments.ErrorSaving",
          "Beim speichern der Regel ist ein Fehler aufgetreten"
        ),
      });
      return null;
    }
  }

  transformWizzardFormToAssignmentRule(
    formValues: AssignmentRuleWizzardFormValues
  ): Partial<CashBudgetBookingAssignmentRule> {
    // Prepare conditions
    const recipientCondition: ConditionType = {
      type: "CONDITION",
      field: "recipient",
      op: "eq",
      val: formValues.recipient,
    };

    // condition object
    const conditions: CBAssignmentRuleValueWithChildren = {
      type: "AND",
      rules: [recipientCondition],
    };

    // add usage condition
    if (formValues.usage !== null && formValues.usage !== undefined) {
      conditions.rules.push({
        type: "CONDITION",
        field: "usage",
        op: "include",
        val: formValues.usage,
      } as ConditionType);
    }

    const assignmentRule: Partial<CashBudgetBookingAssignmentRule> = {
      data: {
        displayName: formValues.displayName,
        type: formValues.type,
        sortIndex: 1000,
        valueType: formValues.valueType,
        condition: conditions,
        action: {
          type: "SET_CATEGORY",
          val: formValues.categoryId,
        },
        status: "active",
      },
    };

    return assignmentRule;
  }

  runBookingAssignmentRule(id: string) {
    HTTP.post({
      target: "EMPTY",
      url: "/liquiplanservice/applyAssignmentRule",
      bodyParams: {
        assignmentId: id,
      },
    })
      .then((response) => {
        const { modifiedCount } = response;

        Toast.success(`
           ${modifiedCount} ${i18n.t(
          "cb:BookingCategory.Assignments.toastSuccessfullyAssigned",
          "Buchung(en) erfolgreich zugeordnet"
        )}`);

        DataBus.emit(DataBusSubKeys.RELOAD, {
          identifiers: [CASHBUDGET_FAST_CATEGORY_CHANGE],
          delay: true,
        });
      })
      .catch((err) => {
        Toast.error(
          i18n.t(
            "cb:BookingCategory.Assignments.toastErrorAssigned",
            "Fehler bei der Ausführung der Zuweisungsregel."
          )
        );
      });
  }
}

const CashBudgetUtils = new CashBudgetUtilsClass();
(window as any).CashBudgetUtils = CashBudgetUtils;
export default CashBudgetUtils;

import { Field } from "react-final-form";
import { RadioTile, RadioTileGroup } from "rsuite";
import FormStruct from "../../components/Form/FormStruct/FormStruct";
import i18n from "../../i18n";
import { Contact } from "../../model/db/Contact";
import BFButton from "../abstract-ui/general/Button/BFButton";
import ContactView from "./ContactView";

interface ContactDuplicateProps {
  contactOptions: Contact[];
  onCreateAnyway: () => Promise<void>;
  onSelected: (contact: Contact) => Promise<void>;
  onClose: () => void;
}
const ContactDuplicate = (props: ContactDuplicateProps) => {
  return (
    <FormStruct<any>
      onSubmit={async (values) => {
        await props.onSelected(
          props.contactOptions.find((e) => e._id === values.selectedOption)
        );
        props.onClose();
      }}
      initialValues={{
        selectedOption: props.contactOptions[0]._id,
      }}
      additionalActions={
        <div>
          <BFButton
            onClick={async () => {
              await props.onCreateAnyway();
              props.onClose();
            }}
            appearance="outline"
          >
            {i18n.t(
              "Contact.ContactDuplicate.CreateAnyway",
              "Trotzdem erstellen"
            )}
          </BFButton>
        </div>
      }
      description={i18n.t(
        "Contact.ContactDuplicate.Description",
        "Es wurden Kontakte mit ähnlichen Daten gefunden. Bitte wählen Sie aus, ob Sie diesen nutzen möchten oder einen neuen Kontakt erstellen möchten."
      )}
      submitText={i18n.t(
        "Contact.ContactDuplicate.UseSelected",
        "Ausgewählten nutzen"
      )}
      onAbort={props.onClose}
      render={(formProps) => (
        <div>
          <Field name="selectedOption">
            {({ input, meta }) => (
              <RadioTileGroup {...input}>
                {props.contactOptions.map((e) => (
                  <RadioTile value={e._id} key={e._id}>
                    {/* show contact view in the duplicate stuff, but withouth actions */}
                    <ContactView contact={e} />
                  </RadioTile>
                ))}
              </RadioTileGroup>
            )}
          </Field>
        </div>
      )}
    />
  );
};

export default ContactDuplicate;

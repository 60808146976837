import classNames from "classnames";
import BFButton from "../../../modules/abstract-ui/general/Button/BFButton";
import "./DKpiCard.scss";

interface DKpiCardProps {
  meta?: string;
  kpi: string;
  subTitle?: string;
  onClick?: () => void;
  loading?: boolean;
}
const DKpiCard = (props: DKpiCardProps) => {
  return (
    <BFButton
      className={classNames(`d-kpi-card`)}
      disabled={!props.onClick}
      loading={props.loading}
      onClick={props.onClick}
    >
      {props.meta && <div className={`meta-text`}>{props.meta}</div>}
      <div className={`kpi-value`}>{props.kpi}</div>
      {props.subTitle && <div className={`sub-title`}>{props.subTitle}</div>}
    </BFButton>
  );
};

export default DKpiCard;

import LoadPage from "@/components/LoadPage/LoadPage";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import LanguageService from "@/services/LanguageService";
import classNames from "classnames";
import { useEffect, useState } from "react";
import BFMarkdown from "../abstract-ui/data/markdown/BFMarkdown";
import BFChooser from "../abstract-ui/forms/chooser/BFChooser";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BfIcon from "../abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../abstract-ui/icon/DefaultIcons";
import { ChangelogEntries } from "./changelog-entries";
import {
  fetchChangelogFile,
  updateLastChangelogEntry,
} from "./changelog.utils";
import "./ChangelogModal.scss";

export const openChangelogModal = async () => {
  ModalManager.show({
    closable: true,
    noPadding: true,
    size: "lg",
    content: (state, setState, close) => <ChangelogModal onClose={close} />,
  });
};

interface ChangelogModalProps {
  onClose: () => void;
}

const ChangelogModal = (props: ChangelogModalProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentContent, setCurrentContent] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const entries = ChangelogEntries;

  useEffect(() => {
    updateLastChangelogEntry();
  }, []);
  useEffect(() => {
    setLoading(true);
    fetchChangelogFile(entries[activeIndex].date)
      .then((content) => {
        setCurrentContent(content);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [activeIndex]);
  return (
    <div className={classNames(`changelog-modal`)}>
      <div className={`changelog-header`}>
        <span className={`changelog-title`}>
          {i18n.t("Changelog.title", "Neuigkeiten")}
        </span>
        <BFButton onClick={() => props.onClose()} appearance="clear-on-white">
          <BfIcon {...DefaultIcons.CLOSE} size="xxs" />
        </BFButton>
      </div>
      <div className={`changelog-container`}>
        <div className={`changelog-list`}>
          <BFChooser
            value={activeIndex}
            options={entries.map((entry, index) => ({
              label: LanguageService.translateLabel(entry.title),
              value: index,
              subLabel: entry.date,
            }))}
            onChange={(index: number) => setActiveIndex(index)}
            hideSearch
          />
        </div>
        <div className={`changelog-content`}>
          {loading ? (
            <LoadPage />
          ) : error ? (
            <div className={`__error padding-10`}>
              {i18n.t("Changelog.errorLoading", "Fehler beim Laden")}
            </div>
          ) : (
            <BFMarkdown content={currentContent} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangelogModal;

import AssetDetailModal from "@/components/AssetDetailModal/AssetDetailModal";
import { openDetailsAction } from "@/modals/GlobalDetailsFunctions";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact } from "@/model/db/Contact";
import ContactDetailView from "@/modules/contacts-module/contact-detail-view/ContactDetailView";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./ContactDetailModal.scss";

interface Props {}
const ROUTE_NAME = "__asset_contact";
const ContactDetailsModal = (props: Props) => {
  const history = useHistory();
  useEffect(() => {
    openDetailsAction(AssetTypes.Contact, ROUTE_NAME);
  }, []);
  return (
    <AssetDetailModal
      assetType={AssetTypes.Contact}
      routeName={ROUTE_NAME}
      hideCloseButton
      className="contact-modal"
      render={(contact: Contact, close) => (
        <ContactDetailView contact={contact} onClose={close} />
      )}
    />
  );
};

export default ContactDetailsModal;

import { ColorResult, SketchPicker } from "react-color";
import BFButton from "../../general/Button/BFButton";
import DataBus from "@/services/DataBus";
import i18n from "@/i18n";

const COLOR_PICKER_OVERLAY = "COLOR_PICKER_OVERLAY";

type BFColorPickerPopoverProps = {
  color: string;
  oldColor: string;
  setColor: (value: string) => void;
  setOldColor: (value: string) => void;
  commitColor: (value: string) => void;
};

const BFColorPickerPopover: React.FC<BFColorPickerPopoverProps> = ({
  color,
  oldColor,
  setColor,
  setOldColor,
  commitColor,
}) => {
  const onColorChangeComplete = (color: ColorResult) => {
    commitColor(color.hex);
  };

  return (
    <div className="bf-color-picker-overlay">
      <SketchPicker
        color={color}
        onChangeComplete={(color: ColorResult) => {
          setColor(color.hex);
        }}
      />
      <div className="button-footer">
        <BFButton
          appearance="link"
          onClick={() => {
            setColor(oldColor);
            DataBus.emit("WHISPER", {
              identifier: COLOR_PICKER_OVERLAY,
              type: "CLOSE",
            });
          }}
        >
          {i18n.t("Global.Buttons.Cancel", "Abbrechen")}
        </BFButton>
        <BFButton
          appearance="link"
          onClick={() => {
            setOldColor(color);
            onColorChangeComplete({ hex: color } as ColorResult);
            DataBus.emit("WHISPER", {
              identifier: COLOR_PICKER_OVERLAY,
              type: "CLOSE",
            });
          }}
        >
          {i18n.t("Global.Buttons.Ok", "Ok")}
        </BFButton>
      </div>
    </div>
  );
};

export { BFColorPickerPopover };

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Loader } from "rsuite";
import Log from "../../debug/Log";
import CDNService from "../../services/CDNService";
import "./CDNLink.scss";

interface Props {
  assetType: string;
  assetId: string;
  assetField: string;
  cdnId: string;
  filename: string;
  fileKey: string;
  hasFolderReadPermissions: boolean;

  render: (url: string) => React.ReactNode;
}
const CDNLink = (props: Props) => {
  const cancelRef = useRef({ cancel: null });
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState<string>(null);
  const [error, setError] = useState<string>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    setUrl(null);

    if (cancelRef.current?.cancel) {
      cancelRef.current.cancel();
    }

    CDNService.fetchCDNLink({
      assetField: props.assetField,
      assetId: props.assetId,
      assetType: props.assetType,
      cdnId: props.cdnId,
      hasFolderReadPermissions: props.hasFolderReadPermissions,
      fileKey: props.fileKey,
      cancelObj: cancelRef.current,
      // cancelObj: this.cancelObj,
    })
      .then((url) => {
        setLoading(false);
        setUrl(url);
      })
      .catch((err) => {
        if (err instanceof axios.Cancel) {
          Log.info("canceled request", err);
          return;
        }
        Log.error("CDNLink error", err);
        setLoading(false);
        setError(err);
      });
  }, [
    props.assetType,
    props.assetId,
    props.assetField,
    props.cdnId,
    props.filename,
    props.fileKey,
    props.hasFolderReadPermissions,
  ]);
  if (url) {
    return <>{props.render(url)}</>;
  }
  if (error) {
    return (
      <div className="cdn-link error">
        <div className="message">Fehler beim Laden der Datei</div>
      </div>
    );
  }
  if (loading) {
    return (
      <div className="cdn-link loading">
        <Loader size="md" />
      </div>
    );
  }
  return null;
};
export default CDNLink;

import Log from "@/debug/Log";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Loader } from "rsuite";
import i18n from "../../../i18n";
import { useDatabus } from "../../../redux/hooks";
import { DataBusSubKeys } from "../../../utils/Constants";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import CommentsService from "../CommentsService";
import { CommentAttachmentEntry } from "./CommentInput";
import "./CommentInputAttachments.scss";

type UploadingFile = {
  id: string;
  file: File;
  progress: number;
  error?: string;
  cancelObj: {
    cancel: () => void;
  };
};
interface CommentInputAttachmentsProps {
  uploadFiles: File[];

  value: CommentAttachmentEntry[];
  onChange: (value: CommentAttachmentEntry[]) => void;
}
const CommentInputAttachments = (props: CommentInputAttachmentsProps) => {
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([]);
  useDatabus(
    DataBusSubKeys.CDN_UPLOAD_FILE_PROGRESS,
    (data) => {
      if (uploadingFiles.some((e) => e.id === data.tempID)) {
        if (data.progress === 1) {
          const uploadingFile = uploadingFiles.find(
            (e) => e.id === data.tempID
          );
          props.onChange([
            ...props.value,
            {
              cdnId: data.cdnID,
              filename: uploadingFile.file.name,
            },
          ]);

          setUploadingFiles(uploadingFiles.filter((e) => e.id !== data.tempID));
        } else {
          setUploadingFiles(
            uploadingFiles.map((e) => {
              if (e.id === data.tempID) {
                return {
                  ...e,
                  progress: data.progress,
                };
              }
              return e;
            })
          );
        }
      }
    },
    [uploadingFiles, props.value, setUploadingFiles, props.onChange]
  );

  useEffect(() => {
    if ((props.uploadFiles || []).length === 0) return;
    const newUploadingFiles: UploadingFile[] = [];
    props.uploadFiles.forEach((file) => {
      const cancelObj = {
        cancel: () => {},
      };
      const newUploadingFile: UploadingFile = {
        file,
        progress: 0,
        cancelObj,
        id: nanoid(),
      };
      newUploadingFiles.push(newUploadingFile);
    });

    const newArray = [...uploadingFiles, ...newUploadingFiles];
    setUploadingFiles(newArray);
    (async () => {
      for (const newUploadingFile of newUploadingFiles) {
        await runUpload(newUploadingFile);
      }
    })().finally(() => {});
  }, [props.uploadFiles]);

  const runUpload = async (uploadingFile: UploadingFile) => {
    try {
      await CommentsService.addAttachmentToComment(
        uploadingFile.file,
        uploadingFile.id,
        uploadingFile.cancelObj
      );
    } catch (err) {
      Log.error(err);
      setUploadingFiles(
        uploadingFiles.filter((f) => f.id !== uploadingFile.id)
      );
    }
  };

  return (
    <div className={classNames(`comment-input-attachments`)}>
      <div className={`added-files`}>
        {props.value.map((attachment, index) => (
          <div key={attachment.cdnId} className={`attachment-entry`}>
            <div className={`name`}>{attachment.filename}</div>
            <div className={`action`}>
              <BFButton
                appearance="link"
                size="xs"
                onClick={() =>
                  props.onChange(props.value.filter((e) => e !== attachment))
                }
              >
                {i18n.t("Global.Buttons.remove")}
              </BFButton>
            </div>
          </div>
        ))}
      </div>
      <div className={`uploading-files`}>
        {uploadingFiles.map((uploadingFile, index) => (
          <div key={index} className={`attachment-entry upload`}>
            <div className={`loader-container`}>
              <Loader size="xs" />
            </div>
            <div className={`name`}>{uploadingFile.file.name}</div>
            <div className={`action`}>
              <BFButton
                appearance="link"
                size="xs"
                onClick={() => uploadingFile.cancelObj.cancel()}
              >
                {i18n.t("Global.Buttons.cancel")}
              </BFButton>
            </div>
            <div
              className={`progress`}
              style={{ width: `${uploadingFile.progress * 100}%` }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentInputAttachments;

import classNames from "classnames";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../../../i18n";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import { APContractActivity } from "./APActivityMainteanceInterfaces";
import APActivityMaintenanceAppointmentForm from "./APActivityMaintenanceAppointmentForm";
import "./APActivityMaintenanceAppointmentsTab.scss";
import APActivityMaintenanceDoneAppointmentsList from "./APActivityMaintenanceDoneAppointmentsList";
import APActivityMaintenanceHeatmapTab from "./APActivityMaintenanceHeatmapTab";
import APActivityMaintenanceNextAppointmentsList from "./APActivityMaintenanceNextAppointmentsList";

interface APActivityMaintenanceAppointmentsTabProps {
  activity: APContractActivity;
}
const APActivityMaintenanceAppointmentsTab = (
  props: APActivityMaintenanceAppointmentsTabProps
) => {
  const onAddAppointment = () => {
    ModalManager.show({
      size: "sm",
      noPadding: true,
      content: (state, setState, close) => (
        <APActivityMaintenanceAppointmentForm
          activity={props.activity}
          onClose={close}
        />
      ),
    });
  };
  return (
    <div className={classNames(`ap-activity-maintenance-appointments-tab`)}>
      <div className={`graphs __card`}>
        <APActivityMaintenanceHeatmapTab activity={props.activity} />
      </div>
      <div className={"__card next-appointments"}>
        <div className={`card-title`}>
          {i18n.t(
            "apTemplate:Activity.subtitles.maintenance.nextAppointments.title",
            "Anstehende Fälligkeiten"
          )}
        </div>

        <div className={`appointments`}>
          {(props.activity.data.appointments || []).filter(
            (e) => e.status === "open"
          ).length === 0 && (
            <div className={`no-appointments-yet`}>
              <div className={`info`}>
                {i18n.t(
                  "apTemplate:Activity.subtitles.maintenance.nextAppointments.noAppointmentsYet",
                  "Sie haben keine bevorstehende Fälligkeit für den Verrtrag angelegt."
                )}
              </div>
              <BFButton size="xs" appearance="link" onClick={onAddAppointment}>
                {i18n.t(
                  "apTemplate:Activity.subtitles.maintenance.appointments.add",
                  "Fälligkeit hinzufügen"
                )}
              </BFButton>
            </div>
          )}
          {(props.activity.data.appointments || []).filter(
            (e) => e.status === "open"
          ).length > 0 && (
            <>
              <APActivityMaintenanceNextAppointmentsList
                activity={props.activity}
                onAddAppointment={onAddAppointment}
                appointments={(props.activity.data.appointments || []).filter(
                  (e) => e.status === "open"
                )}
              />
            </>
          )}
        </div>
      </div>
      <div className={`__card`}>
        <div className={`card-title`}>
          {i18n.t(
            "apTemplate:Activity.subtitles.maintenance.doneAppointments.title",
            "Beendete Fälligkeiten"
          )}
        </div>
        {(props.activity.data.appointments || []).filter(
          (e) => e.status !== "open"
        ).length === 0 && (
          <div className={`no-closed-appointments-yet`}>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.doneAppointments.noAppointments",
              "Sie haben bisher keine Fälligkeiten abgeschlossen oder abgebrochen.."
            )}
          </div>
        )}
        {(props.activity.data.appointments || []).filter(
          (e) => e.status !== "open"
        ).length > 0 && (
          <APActivityMaintenanceDoneAppointmentsList
            activity={props.activity}
            appointments={(props.activity.data.appointments || []).filter(
              (e) => e.status !== "open"
            )}
          />
        )}
      </div>
    </div>
  );
};

export default APActivityMaintenanceAppointmentsTab;

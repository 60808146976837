import AssetLoader from "@/components/AssetLoader/AssetLoader";
import i18n from "@/i18n";
import { TableSort } from "@/model/common/CommonInterfaces";
import BaseAsset from "@/model/general-assets/BaseAsset";
import DataBus from "@/services/DataBus";
import { MatchQuery } from "@/services/DataService";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useState } from "react";
import BFButton from "../../general/Button/BFButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import BFOverlay from "../../general/whisper/BFOverlay";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import LabeledInput from "../LabeledInput";
import BFAssetChooser, { BFAssetChooserOption } from "./BFAssetChooser";
import "./BFAssetChooserSelect.scss";

interface BFAssetChooserSelectProps {
  value: string;
  onChange: (value: string, asset: BaseAsset) => void;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  labelPosition?: "top" | "left";
  info?: React.ReactNode;
  label?: string;
  labelSuffix?: React.ReactNode;
  placeholder?: string;
  hideSubLabel?: boolean;
  overlayTopComponent?: (close: () => void) => React.ReactNode;
  overlayBottomComponent?: (close: () => void) => React.ReactNode;
  overlayClassName?: string;
  disabled?: boolean;
  sort?: TableSort[];

  overlayHeight?: string | number;
  groups?: {
    matchQuery: MatchQuery;
    label: string;
    value: string;
  }[];
  assetType: string;
  matchQuery?: MatchQuery;
  getOption: (node: BaseAsset) => BFAssetChooserOption;
}
const BFAssetChooserSelect = (props: BFAssetChooserSelectProps) => {
  const [id] = useState(nanoid());
  return (
    <div className={classNames(`bf-asset-chooser-select`)}>
      <LabeledInput
        label={props.label}
        info={props.info}
        labelPosition={props.labelPosition}
        suffix={props.labelSuffix}
        error={!!props.validation?.message}
      >
        <ValidationPopover
          validatorStyle={props.validatorStyle}
          level={props.validation ? props.validation.level : "error"}
          message={props.validation ? props.validation.message : null}
          marginTop={0}
        >
          <BFOverlay
            identifier={id}
            trigger="click"
            placement="bottomStart"
            speaker={
              <div
                className={`bf-asset-chooser-select-overlay ${
                  props.overlayClassName || ""
                }`}
              >
                {props.overlayTopComponent?.(() => {
                  DataBus.emit("WHISPER", {
                    identifier: id,
                    type: "CLOSE",
                  });
                })}
                <BFAssetChooser
                  dense
                  searchPlaceholder={i18n.t("Global.Labels.search")}
                  groups={props.groups}
                  assetType={props.assetType}
                  getOption={props.getOption}
                  matchQuery={props.matchQuery}
                  sort={props.sort}
                  value={props.value}
                  focusOnMount
                  height={props.overlayHeight || "50vh"}
                  onChange={(value, asset) => {
                    DataBus.emit("WHISPER", {
                      identifier: id,
                      type: "CLOSE",
                    });
                    props.onChange(value, asset);
                  }}
                />
                {props.overlayBottomComponent?.(() => {
                  DataBus.emit("WHISPER", {
                    identifier: id,
                    type: "CLOSE",
                  });
                })}
              </div>
            }
          >
            <div>
              <BFButton
                disabled={props.disabled}
                className={`bf-asset-chooser-select-button`}
              >
                <div
                  className={classNames({
                    "select-value": true,
                    placeholder: !props.value,
                  })}
                >
                  {props.value && (
                    <AssetLoader
                      inline
                      assetType={props.assetType}
                      id={props.value}
                      render={(asset: BaseAsset) => {
                        const option = props.getOption(asset);

                        return (
                          <>
                            {option.subLabel && (
                              <span className={`sub-label`}>
                                {option.subLabel}
                              </span>
                            )}{" "}
                            <span>{option.label}</span>{" "}
                          </>
                        );
                      }}
                    />
                  )}
                  {!props.value && (props.placeholder || "-")}
                </div>
                <BfIcon size="xxs" {...DefaultIcons.CHEVRON} />
              </BFButton>
            </div>
          </BFOverlay>
        </ValidationPopover>
      </LabeledInput>
    </div>
  );
};

export default BFAssetChooserSelect;

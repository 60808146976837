import classNames from "classnames";
import i18n from "../../i18n";
import BFButton from "../abstract-ui/general/Button/BFButton";
import "./ConfigurableDashboardEditBar.scss";

interface ConfigurableDashboardEditBarProps {
  editMode: boolean;
  bright?: boolean;
  onEdit: () => void;
  onReset: () => void;
  onSave: () => Promise<void>;
}
const ConfigurableDashboardEditBar = (
  props: ConfigurableDashboardEditBarProps
) => {
  return (
    <div
      className={classNames(`configurable-dashboard-edit-bar`, {
        bright: props.bright,
      })}
    >
      {props.editMode && (
        <>
          <BFButton
            appearance={props.bright ? "transparent-light" : "outline"}
            size="xs"
            onClick={props.onReset}
          >
            {i18n.t("Global.Buttons.cancel")}
          </BFButton>
          <BFButton
            appearance={props.bright ? "transparent-light" : "primary"}
            size="xs"
            onClick={props.onSave}
          >
            {i18n.t("Global.Buttons.save")}
          </BFButton>
        </>
      )}
      {!props.editMode && (
        <>
          <BFButton
            appearance={props.bright ? "transparent-light" : "outline"}
            size="xs"
            onClick={props.onEdit}
          >
            {i18n.t("Global.Buttons.edit")}
          </BFButton>
        </>
      )}
    </div>
  );
};

export default ConfigurableDashboardEditBar;

import ResubmissionDashboardComponent from "../../../../modules/resubmission/view/ResubmissionDashboardComponent";
import "./DashboardResubmission.scss";

const DashboardResubmission = () => {
  return (
    <div className="dashboard-resubmission">
      <ResubmissionDashboardComponent />
    </div>
  );
};

export default DashboardResubmission;

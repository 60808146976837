import classNames from "classnames";
import React from "react";
import { Tabs } from "rsuite";
import "./BFTabContainer.scss";

type TabContent = {
  id: string;
  label: React.ReactNode;
  content: () => React.ReactNode;
};

interface BFTabsProps {
  tabs: TabContent[];
  preventUnmount?: boolean;
  appearance?: "subtle" | "tabs";

  defaultActiveKey?: string;
  activeTab?: string;
  setActiveTab?: (id: string) => void;
}
const BFTabContainer = (props: BFTabsProps) => {
  return (
    <div className={classNames(`bf-tab-container`)}>
      <Tabs
        appearance={props.appearance || "subtle"}
        defaultActiveKey={props.defaultActiveKey}
        activeKey={props.activeTab}
        onSelect={props.setActiveTab}
      >
        {props.tabs.map((tab) => (
          <Tabs.Tab eventKey={tab.id} title={tab.label}>
            {tab.content()}
          </Tabs.Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default BFTabContainer;

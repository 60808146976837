import { Component, CSSProperties } from "react";
import Log from "../../debug/Log";
import { ActionData, ActionDataEvent, ActionDataStateChange } from "../../model/common/DataBus/ActionData";
import ExpressionHelper from "../../modules/generic-forms/util/ExpressionHelper";
import DataBus from "../../services/DataBus";
import { UpdateCommand } from "../../services/socket/NotificationInterface";
import SocketService from "../../services/socket/SocketService";
import Tools, { StyleConfiguration } from "../Tools";
import { store } from "./../../redux/store";

export type SendEvent =
	| {
			eventKey: string;
			condition?: string;
			isState?: boolean;
			data: any;
	  }
	| ((data) => void);

export type AbstractProps = {
	stateSubscriptions?: string[];
	actionIdMapping?: { [actionId: string]: string };
	identifier?: string;
	_identifier?: string;
	condition?: string;
	style?: StyleConfiguration;
	viewportWidth?: number;
	permission?: string[] | string;
	params?: any;
	allValues?: any;
};

export type AbstractStates = {
	params?: any;
	usedStyle?: CSSProperties;
};

export abstract class AbstractComponent<T, E> extends Component<T & AbstractProps, E & AbstractStates> {
	private socketSubKeys = [];
	private usedSubKeys = [];
	private populatedActions = new Set<string>();
	private _isMounted;

	constructor(props) {
		super(props);
		this._isMounted = true;
	}

	shouldComponentUpdate(nextProps: AbstractProps, nextState: AbstractStates) {
		//do not update on every viewport change
		if (nextState.usedStyle !== this.state.usedStyle) {
			return true;
		}

		if (nextProps.viewportWidth !== this.props.viewportWidth) {
			return false;
		}
		return true;
	}

	isMounted() {
		return this.isMounted;
	}

	componentWillUnmount(): void {
		this.usedSubKeys.forEach(subId => DataBus.unsubscribe(subId));
		this.socketSubKeys.forEach(id => SocketService.unsubscribe(id));

		this.populatedActions.forEach(actionId => {
			this.populateButtonState(actionId, { hidden: true });
		});
		this._isMounted = false;
	}

	componentWillMount(): void {
		let usedStyle = Tools.getActiveBreakpointProperties(this.props.viewportWidth, this.props.style);
		this.setState({
			usedStyle: usedStyle as any
		});

		if (this.props.stateSubscriptions) {
			this.props.stateSubscriptions.forEach(key =>
				this.subscribe(key, data => {
					this.setState(
						{
							params: {
								...(this.state && this.state.params ? this.state.params : {}),
								[key]: data
							}
						},
						() => {
							this.subscriptionParamChangedHook(key, data);
						}
					);
				})
			);
		}
	}

	subscriptionParamChangedHook(param: string, data: any) {}

	componentWillReceiveProps(nextProps: T & AbstractProps, nextStates: E & AbstractStates) {
		if (Array.isArray(this.props.style)) {
			if (nextProps.viewportWidth !== this.props.viewportWidth) {
				this.setState({
					usedStyle: Tools.getActiveBreakpointProperties(nextProps.viewportWidth, nextProps.style) as any
				});
			}
		}
	}

	shoudBeRendered() {
		if (this.props.permission) {
			const applicationPropsAll = store.getState().uiConfig.activeApplication;

			//TODO uncomment these lines
			// if(!applicationPropsAll.permissions.ADMIN) {

			if (!applicationPropsAll.permissions) {
				return false;
			}
			if (typeof this.props.permission === "string") {
				if ((!applicationPropsAll.permissions as any)[this.props.permission]) {
					return false;
				}
			} else {
				for (const permission of this.props.permission as string[]) {
					if ((!applicationPropsAll.permissions as any)[permission]) {
						return false;
					}
				}
			}
			// }
		}

		if (this.props.condition) {
			const conditionResult = this.evaluateExpression(this.props.condition, this.props.allValues);
			return conditionResult;
		}
		return true;
	}

	getIdentifier(props?: AbstractProps) {
		const useProps = props ? props : this.props;
		if (useProps.identifier) {
			return useProps.identifier;
		} else if (useProps._identifier) {
			return this.evaluateExpression(useProps._identifier, undefined, useProps);
		} else {
			return undefined;
		}
	}

	protected replaceStringVariables(condition: string, values: { [key: string]: any } = {}) {
		const applicationPropsAll = store.getState().uiConfig.activeApplication;
		const userPropsAll = store.getState().global.user;

		const params = this.state && this.state.params ? this.state.params : {};
		const constParams = this.props && this.props.params ? this.props.params : {};

		let paramsToEval = {
			...params,
			...constParams
		};
		if (applicationPropsAll) {
			const { config, permissions, ...appProps } = applicationPropsAll;

			paramsToEval = {
				appProps: appProps,
				...paramsToEval
			};
		}
		if (userPropsAll) {
			const { permissions, mandator_info, ...userProps } = userPropsAll;

			paramsToEval = {
				userProps: userProps,
				permissions: permissions,
				...paramsToEval
			};
		}

		return ExpressionHelper.replaceVariables(condition, values, paramsToEval);
	}

	protected evaluateExpression(
		condition: string | Function,
		values: { [key: string]: any } = {},
		nextProps?: any,
		nextStates?: any
	) {
		const usedProps = nextProps ? nextProps : this.props;
		const usedStates = nextStates ? nextStates : this.state;

		const applicationPropsAll = store.getState().uiConfig.activeApplication;
		const userPropsAll = store.getState().global.user;
		const params = usedStates && usedStates.params ? usedStates.params : {};
		const constParams = usedProps && usedProps.params ? usedProps.params : {};
		let paramsToEval = {
			...params,
			...constParams
		};
		if (applicationPropsAll) {
			const { config, permissions, ...appProps } = applicationPropsAll;

			paramsToEval = {
				appProps: appProps,
				...paramsToEval
			};
		}
		if (userPropsAll) {
			const { permissions, mandator_info, ...userProps } = userPropsAll;

			paramsToEval = {
				userProps: userProps,
				permissions: permissions,
				...paramsToEval
			};
		}

		Log.debug("EvalExpression", condition, values, paramsToEval);
		return ExpressionHelper.evaluateExpression(condition, values, paramsToEval);
	}

	protected subscribe<T>(key: string, callback: (T) => void) {
		const subId = DataBus.subscribe(key, callback);
		this.usedSubKeys.push(subId);
		return subId;
	}

	protected emit<T>(key: string, data: T, isState = false) {
		DataBus.emit(key, data, isState);
	}

	protected emitComponentEvent(data: any) {
		if (this.getIdentifier()) {
			DataBus.emit(this.getIdentifier(), data, false);
		} else {
			throw "populated a component state with no subId";
		}
	}

	protected populateComponentState(state: { [key: string]: any }) {
		if (this.getIdentifier()) {
			DataBus.emit(this.getIdentifier(), state, true);
		} else {
			throw "populated a component state with no subId";
		}
	}

	protected subscribeActionEvent(actionId: string, callback: (data: ActionDataEvent) => void) {
		let mappedId = this.getMappingActionId(actionId);

		this.subscribe(mappedId, (subData: ActionData) => {
			if (subData.type === "click") {
				callback(subData);
			}
		});
	}

	socketSubscribeAsset(assetType: string, callback: (msg: UpdateCommand) => void) {
		this.socketSubKeys.push(SocketService.subscribeAsset(assetType, callback));
	}
	socketSubscribeUser(callback: (msg: UpdateCommand) => void) {
		this.socketSubKeys.push(SocketService.subscribeUser(callback));
	}
	socketSubscribeTeam(callback: (msg: UpdateCommand) => void) {
		this.socketSubKeys.push(SocketService.subscribeTeam(callback));
	}
	socketSubscribeGroup(callback: (msg: UpdateCommand) => void) {
		this.socketSubKeys.push(SocketService.subscribeGroup(callback));
	}
	socketSubscribe(dataType: string, callback: (msg: UpdateCommand) => void) {
		this.socketSubKeys.push(SocketService.subscribe(dataType, callback));
	}

	protected populateButtonState(
		actionId,
		state: {
			hidden?: boolean;
			focus?: boolean;
			loading?: boolean;
			disabled?: boolean;
			toggled?: boolean;
		}
	) {
		let mappedId = this.getMappingActionId(actionId);

		this.emit<ActionDataStateChange>(
			mappedId,
			{
				type: "state",
				...state
			},
			true
		);
		this.populatedActions.add(mappedId);
	}

	protected getMappingActionId(actionId: string) {
		const { actionIdMapping } = this.props;
		let mappedId = actionId;
		if (actionIdMapping && actionIdMapping[actionId]) {
			mappedId = actionIdMapping[actionId];
		}
		return mappedId;
	}

	protected handleEvents(events: { [key: string]: Function | SendEvent }, values?: any) {
		if (events) {
			Object.values(events).forEach(event => {
				let sendEvent = true;

				if (typeof event === "string" || typeof event === "function") {
					this.evaluateExpression(event, values);
				} else {
					if (event.condition) {
						sendEvent = this.evaluateExpression(event.condition, values);
					}
					if (sendEvent) {
						this.emit(
							this.evaluateExpression(event.eventKey, values),
							typeof event.data === "string" ? this.evaluateExpression(event.data, values) : event.data,
							event.isState
						);
					}
				}
			});
		}
	}
}

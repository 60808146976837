import classNames from "classnames";
import { useState } from "react";
import AssetLoader from "../../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../../components/ModalComponent/ModalManager";
import Log from "../../../../../debug/Log";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import BFDropzone from "../../../../../modules/abstract-ui/dropzone/BFDropzone";
import CashBudgetBookingFooter from "../../components/CashBudgetBookingFooter";
import CashBudgetDetailEntryCardContent from "../../components/CashBudgetDetailEntryCardContent";
import { AssetCashBudgetEntry } from "../../model/CashBudgetEntry";
import BookingService from "../../services/BookingService";
import CBIdlyBookingConnection from "./CBIdlyBookingConnection";
import "./CBIdlyBookingEntry.scss";

interface CBIdlyBookingEntryProps {
  booking: AssetCashBudgetEntry;
  showConnect?: boolean;
}
const CBIdlyBookingEntry = (props: CBIdlyBookingEntryProps) => {
  const [uploading, setUploading] = useState(false);
  return (
    <div className={classNames(`cb-idly-booking-entry`)}>
      <div className={`__card`}>
        <BFDropzone
          className="left"
          onDrop={
            (acceptedFiles, rejectedFiles, event) => {
              Log.info(
                "###BFUpload drophandler",
                acceptedFiles,
                rejectedFiles,
                event
              );
              if (acceptedFiles && acceptedFiles.length > 0) {
                setUploading(true);
                BookingService.uploadBookingAttachments(
                  props.booking,
                  acceptedFiles
                )
                  .then(() => {})
                  .finally(() => {
                    setUploading(false);
                  });
              }
            }
            // this.onDropHandler(acceptedFiles, rejectedFiles, event)
          }
          render={(open) => {
            return (
              <div className={`booking-container`}>
                <div className={`card-content`}>
                  <CashBudgetDetailEntryCardContent
                    entry={props.booking}
                    showAccountData
                    showCategory
                    isUploading={uploading}
                  />
                </div>
                <div className={`fill`} />
                <CashBudgetBookingFooter
                  booking={props.booking}
                  onUpload={open}
                  onConnect={
                    props.showConnect
                      ? () => {
                          ModalManager.show({
                            backdrop: true,
                            size: "xxl",
                            closable: true,
                            headerCloseButton: true,
                            title: i18n.t(
                              "cb:CashBudgetBookingFooter.linkAssetTitle",
                              "Buchung verknüpfen"
                            ),

                            content: (state, setState, onClose) => (
                              <div className={`modal-booking-footer`}>
                                <AssetLoader
                                  assetType={AssetTypes.CashBudget.Booking}
                                  id={props.booking._id}
                                  render={(booking) => (
                                    <CBIdlyBookingEntry
                                      booking={booking}
                                      showConnect={false}
                                    />
                                  )}
                                />
                              </div>
                            ),
                          });
                        }
                      : undefined
                  }
                  uploading={uploading}
                />
              </div>
            );
          }}
          accept={{
            "image/*": [".heic", ".heif", ".webp"],
            "application/pdf": [],
            "application/msword": [],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [],
          }}
          maxSize={20000000}
        />
        <div className={`right`}>
          <CBIdlyBookingConnection booking={props.booking} />
        </div>
      </div>
    </div>
  );
};

export default CBIdlyBookingEntry;

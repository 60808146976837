import { CSSProperties } from "react";
import {
  Accept,
  DropEvent,
  FileError,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import BfIcon from "../icon/BfIcon";
import "./BFDropzone.scss";

interface BFDropzoneTablerowProps {
  uploadText?: string;
  className?: string;
  style?: CSSProperties;
  accept?: Accept;
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  disabled?: boolean;
  multipe?: boolean;
  validator?: (file: File) => FileError | Array<FileError> | null;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  render: (
    openUploadDialog: () => void,
    isDragActive: boolean
  ) => React.ReactNode;
  acceptText?: string;
  rejectText?: string;
  overlayIconSize?: "xs" | "sm" | "lg" | "xl" | "2x" | "3x" | "4x" | "5x";
  suppressDragOverlay?: boolean;
}
const BFDropzoneTablerow = (props: BFDropzoneTablerowProps) => {
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    validator: props.validator,
    noClick: true,
    accept: props.accept,
    onDrop: props.onDrop,
    maxFiles: props.maxFiles || undefined,
    maxSize: props.maxSize,
    minSize: props.minSize,
    noKeyboard: true,
    disabled: props.disabled,
    multiple: props.multipe,
  });

  return (
    <>
      <input {...getInputProps()} />
      <tr {...getRootProps()} className={`bf-dropzone-tablerow`}>
        {props.render(open, isDragActive)}

        <div
          className={`overlay-drop-indicator ${isDragActive ? "active" : ""} ${
            isDragAccept ? "accept" : ""
          } ${isDragReject ? "reject" : ""} ${
            props.suppressDragOverlay ? "suppress-drag" : ""
          }`}
        >
          <div className="border">
            {isDragAccept && (
              <div className="drag-info accept">
                <BfIcon
                  type="light"
                  data="upload-bottom"
                  size={props.overlayIconSize || "lg"}
                />
                <div className={`text`}>
                  {props.acceptText || "Dateien hochladen"}
                </div>
              </div>
            )}
            {isDragReject && (
              <div className="drag-info accept">
                <BfIcon
                  type="light"
                  data="delete"
                  size={props.overlayIconSize || "lg"}
                />
                <div className={`text`}>
                  {props.rejectText || "Falsche Datei"}
                </div>
              </div>
            )}
          </div>
        </div>
      </tr>
    </>
  );
};

export default BFDropzoneTablerow;

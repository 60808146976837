import { FV } from "@/components/Form/Validation/FormValidators";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import LanguageService from "@/services/LanguageService";
import { isDefined } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import { FieldRenderProps } from "react-final-form";
import { Customfield_number, SuffixPrefixType } from "../CustomField.interface";
// import "./CFNumber.scss";

interface CFNumberProps {
  data: Customfield_number;
  field: FieldRenderProps<any, HTMLElement>;
}

const getSuffixPrefix = (cfg: SuffixPrefixType) => {
  if (isDefined(cfg)) {
    if ((cfg as any).area) {
      return StringUtils.getAreaUnit();
    }
    if ((cfg as any).currency) {
      return StringUtils.getCurrencySymbol(
        (cfg as any).currency === true ? undefined : (cfg as any).currency
      );
    }

    if ((cfg as any).text) {
      return (cfg as any).text;
    }
  }

  return undefined;
};
const CFNumber = (props: CFNumberProps) => {
  const suffix = getSuffixPrefix(props.data.suffix);
  const prefix = getSuffixPrefix(props.data.prefix);
  return (
    <BFInput
      type="number"
      textAlign={props.data.textAlign}
      prefix={prefix}
      suffix={suffix}
      info={LanguageService.translateLabel(props.data.info)}
      label={LanguageService.translateLabel(props.data.displayName)}
      {...props.field.input}
      {...FV.getValidation(props.field.meta)}
    />
  );
};

export default CFNumber;

import LanguageService from "../../../../services/LanguageService";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import BfIcon from "../../../abstract-ui/icon/BfIcon";
import { DocumentStoreDirectory } from "../../DSInterfaces";
// import "./DSDocumentMoveToDirecotry.scss";

interface DSDocumentMoveToDirecotryProps {
  directory: DocumentStoreDirectory;
  depth?: number;
  onDirectoryClick: (directory: DocumentStoreDirectory) => void;
}
const DSDocumentMoveToDirectory = (props: DSDocumentMoveToDirecotryProps) => {
  return (
    <>
      <BFButton
        className={`ds-document-move-to-directory-button`}
        onClick={() => props.onDirectoryClick(props.directory)}
        appearance="clear-on-white"
        style={{
          paddingLeft: (props.depth || 0) * 20 + 10,
        }}
      >
        <BfIcon type="color" data={"folder-empty-10"} size="xs" />
        <span className={`directory-name`}>
          {LanguageService.translateLabel(props.directory.name)}
        </span>
      </BFButton>
      {props.directory.subDirectories?.map((directory) => {
        return (
          <DSDocumentMoveToDirectory
            key={directory.pathIdentifier}
            directory={directory}
            depth={props.depth ? props.depth + 1 : 1}
            onDirectoryClick={props.onDirectoryClick}
          />
        );
      })}
    </>
  );
};

export default DSDocumentMoveToDirectory;

import { FC, memo } from "react";
import i18n from "@/i18n";
import { RentDataGraphDetailsProps } from "./types";
import StringUtils from "@/utils/StringUtils";

const RentDataGraphDetails: FC<RentDataGraphDetailsProps> = ({
  lastMedian,
  rentalIncome,
  rentalIncomePlan,
}) => {
  // ! render
  return (
    <div>
      <div>
        <strong>
          {`${i18n.t<string>(
            "cb:RentDataGraph.labels.currentRent",
            "Aktuelle Miete"
          )}: `}
        </strong>
        {rentalIncome !== undefined || rentalIncome !== 0
          ? StringUtils.formatCurrency(rentalIncome)
          : "-"}
      </div>

      <div>
        <strong>
          {`${i18n.t<string>(
            "cb:RentDataGraph.labels.plannedRent",
            "Geplante Miete"
          )}: `}
        </strong>
        {rentalIncomePlan !== undefined || rentalIncomePlan !== 0
          ? StringUtils.formatCurrency(rentalIncomePlan)
          : "-"}
      </div>

      <div>
        <strong>
          {`${i18n.t<string>(
            "cb:RentDataGraph.labels.lastMedian",
            "Letzter Median"
          )}: `}
        </strong>
        {lastMedian !== undefined || lastMedian !== 0
          ? StringUtils.formatCurrency(lastMedian)
          : "-"}
      </div>
    </div>
  );
};

export default memo(RentDataGraphDetails);

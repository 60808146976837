import { Layer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import { Draw, Modify, Select } from "ol/interaction";
import { click } from "ol/events/condition";

// ! CREATE INTERACTIONS
interface InteractionsArgs {
  featureLayer: Layer;
  featureSource: VectorSource;
}

export const createFeatureInteractions = ({
  featureLayer,
  featureSource,
}: InteractionsArgs) => {
  const selectClickInteraction = createSelectClickInteraction(
    featureLayer,
    featureSource
  );

  const drawInteraction = new Draw({
    type: "Point",
  });

  const modifyInteraction = new Modify({
    source: featureSource,
  });

  return { selectClickInteraction, drawInteraction, modifyInteraction };
};

const createSelectClickInteraction = (layer: Layer, source: VectorSource) => {
  const selectClickInteraction = new Select({
    layers: [layer],
    condition: click,
    hitTolerance: 5,
  });

  // Handle feature removal on click
  selectClickInteraction.on("select", (event) => {
    const selectedFeatures = event.selected;

    if (selectedFeatures.length) {
      selectedFeatures.forEach((feature) => {
        source.removeFeature(feature);
      });
    }
  });

  return selectClickInteraction;
};

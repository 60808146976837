import classNames from "classnames";
import Collapse from "rsuite/esm/Animation/Collapse";
import i18n from "../../i18n";
import {
  BreakpointDefinition,
  DashboardItemConfig,
} from "./ConfigurableDashboard";
import "./ConfigurableDashboardAddElementsBar.scss";

const SCALE_FACTOR = 1;
interface ConfigurableDashboardAddElementsBarProps {
  editMode: boolean;
  usedComponents: string[];
  selector: string;
  configs: DashboardItemConfig[];
  breakpoints: BreakpointDefinition;
  rowHeight: number;
  bright?: boolean;
}
const ConfigurableDashboardAddElementsBar = (
  props: ConfigurableDashboardAddElementsBarProps
) => {
  const notUsedConfigs = props.configs.filter((config) => {
    return !props.usedComponents.includes(config.id.toLowerCase());
  });

  return (
    <Collapse in={props.editMode}>
      <div>
        <div
          className={classNames(`configurable-dashbaord-add-elements-bar`, {
            bright: props.bright,
          })}
        >
          <div className={`title`}>
            {i18n.t(
              "ConfigurableDashboard.AddElementsBar.title",
              "Dashboard-Komponenten hinzufügen"
            )}
          </div>
          <div className={`elements`}>
            {notUsedConfigs.length === 0 && (
              <div className={`no-elements`}>
                {i18n.t(
                  "ConfigurableDashboard.AddElementsBar.noElements",
                  "Keine weiteren Komponenten verfügbar"
                )}
              </div>
            )}
            {notUsedConfigs.map((config) => (
              <div
                key={config.id}
                draggable={true}
                unselectable="on"
                id={config.id}
                // this is a hack for firefox
                // Firefox requires some kind of initialization
                // which we can do by adding this attribute
                // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                onDragStart={(e) => {
                  e.dataTransfer.setData("text/plain", config.id);
                  // real ugly hack to send id to grid
                  e.dataTransfer.setData(config.id, config.id);
                }}
                className={`__card available-component`}
                data-grid={{
                  minW: config.layout[props.selector]?.minW || 1,
                  minH: config.layout[props.selector]?.minH || 1,
                  maxW: config.layout[props.selector]?.maxW,
                  maxH: config.layout[props.selector]?.maxH,
                }}
                style={{
                  // transform: "scale(0.65)",
                  // margin: "-20px",
                  // width:
                  //   ((config.layout[props.selector]?.minW || 1) /
                  //     props.breakpoints[props.selector].columns) *
                  //     100 *
                  //     SCALE_FACTOR +
                  //   "%",
                  height: 80,
                }}
              >
                <div
                  className={`__card`}
                  style={{
                    width: "100%",
                    height: "100%",
                    pointerEvents: "none",
                  }}
                >
                  {config.title}
                </div>
                {/* {config.title} */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default ConfigurableDashboardAddElementsBar;

import { AssetTypes } from "../../../../../../model/AssetTypes";
import CacheService from "../../../../../../services/CacheService";
import ServiceUtils from "../../../../../../services/ServiceUtils";
import SubmitService from "../../../../../../services/SubmitService";
import { HTTP } from "../../../../../../utils/Http";
import {
  FieldDataDto,
  FieldDataForm,
  PortfolioSupplyUnit,
  SupplyTechnicalUnitFormData,
  SupplyUnitCategoryData,
  SupplyUnitCategoryFormData,
  SupplyUnitCategoryGroup,
} from "./SupplyUnitConfigInterfaces";

class SupplyUnitServiceClass {
  convertSupplyUnitConfigFormDataToDto(
    formData: SupplyUnitCategoryFormData,
    prevDto?: SupplyUnitCategoryData
  ) {
    const output = { ...(prevDto || {}) };

    output.type = formData.type;
    output.displayName = formData.displayName;
    output.icon = { type: formData.icon.type, data: formData.icon.icon };
    output.color = formData.icon.color;
    output.units = formData.units;
    output.hasLocation = formData.hasLocation;

    const mapFields = (
      fieldsForm: FieldDataForm[],
      fieldsDto: FieldDataDto[]
    ) => {
      const fieldIds = (fieldsForm || []).map((e) => e.id);
      const newFields = [
        ...(fieldsForm || []).map((e) => ({ ...e, status: "active" })),
        ...(fieldsDto || [])
          .filter((e) => !fieldIds.includes(e.id))
          .map((e) => ({ ...e, status: "archived" })),
      ];
      return newFields as FieldDataDto[];
    };

    output.fields = mapFields(formData.fields, output?.fields || []);

    // const categoryIds = formData.categories.map((e) => e.id);
    // const newCategories = [
    //   ...formData.categories.map((e) => ({
    //     ...e,
    //     status: "active",
    //     fields: mapFields(
    //       e.fields,
    //       (output?.categories || []).find((a) => a.id === e.id)?.fields || []
    //     ),
    //   })),
    //   ...(output.categories || [])
    //     .filter((e) => !categoryIds.includes(e.id))
    //     .map((e) => ({ ...e, status: "archived" })),
    // ];
    // output.categories = newCategories as any;

    output.status = "active";
    return output;
  }
  convertSupplyUnitConfigDtoToFormData(
    dto: SupplyUnitCategoryData
  ): SupplyUnitCategoryFormData {
    const output: SupplyUnitCategoryFormData = {
      type: dto.type,
      displayName: dto.displayName,
      icon: {
        type: dto.icon.type,
        icon: dto.icon.data,
        color: dto.color,
      },
      hasLocation: dto.hasLocation,
      fields: dto.fields.filter((e) => e.status === "active"),
      units: dto.units,
    };
    return output;
  }

  async submitSupplyUnitConfigFormData(
    formData: SupplyUnitCategoryFormData,
    prevDto?: SupplyUnitCategoryGroup
  ) {
    const converted = this.convertSupplyUnitConfigFormDataToDto(
      formData,
      prevDto?.data
    );

    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Portfolio.SupplyUnitCategoryGroup,
      data: {
        _id: prevDto?._id,
        data: converted,
      },
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as SupplyUnitCategoryGroup;
    CacheService.updateDataInCaches(result._id, result);

    return result;
  }
  async disableSupplyUnit(technicalUnit: PortfolioSupplyUnit) {
    const result = (await HTTP.post({
      url: `/supplyContract/${technicalUnit._id}/setSupplyUnitStatus`,
      target: "EMPTY",
      bodyParams: {
        status: "archived",
      },
    })) as PortfolioSupplyUnit;
    CacheService.updateDataInCaches(result._id, result);
  }
  async submitSupplyUnitFormData(
    formData: SupplyTechnicalUnitFormData,
    _id?: string
  ) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Portfolio.SupplyUnit,
      data: {
        _id: _id,
        data: {
          ...formData,
          status: "active",
          serialNumber: formData.serialNumber || "",
          note: formData.note || "",
          customFields: Object.entries(formData.customFields || {}).map(
            ([key, value]) => ({
              fieldId: key,
              value,
            })
          ),
          attachments: undefined,
          images: undefined,
          migrationId: undefined,
        },
      },
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as PortfolioSupplyUnit;
    CacheService.updateDataInCaches(result._id, result);
  }

  convertSupplyUnitDtoToForm(asset: PortfolioSupplyUnit) {
    const formData: SupplyTechnicalUnitFormData = {
      rentalUnits: [],
      ...asset.data,
      //@ts-ignore
      attachments: undefined,
      images: undefined,
      status: undefined,
      migrationId: undefined,
      currentEntry: undefined,
      history: undefined,
      customFields: Object.fromEntries(
        (asset.data.customFields || []).map((e) => [e.fieldId, e.value])
      ),
    };
    return formData;
  }

  async addSupplyUnitMeaterReading(assetId: string, date: Date, value: string) {
    return ServiceUtils.toastError(async () => {
      const result = (await HTTP.post({
        url: `supplyContract/${assetId}/updateSupplyUnitValue`,
        target: "EMPTY",
        bodyParams: {
          date,
          value,
        },
      })) as PortfolioSupplyUnit;
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }
  async deleteSupplyUnitMeaterReading(assetId: string, readingId: string) {
    return ServiceUtils.toastError(async () => {
      const result = (await HTTP.post({
        url: `supplyContract/${assetId}/deleteSupplyUnitValue`,
        target: "EMPTY",
        bodyParams: {
          id: readingId,
        },
      })) as PortfolioSupplyUnit;
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }
}
const SupplyUnitService = new SupplyUnitServiceClass();
export default SupplyUnitService;

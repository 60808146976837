import { AbstractStructSelectors } from "./AbstractStructSelectors";
import CategoryStruct from "./implemented/CategoryStruct";
import CurrencyStruct from "./implemented/CurrencyStruct";
import DamageClaimStruct from "./implemented/DamageClaimStruct";
import DunningStruct from "./implemented/DunningStruct";
import InsuranceStruct from "./implemented/InsuranceStruct";
import InvoiceStruct from "./implemented/InvoiceStruct";
import MaintenanceStruct from "./implemented/MaintenanceStruct";
import ObjectKindStruct from "./implemented/ObjectKindStruct";
import OrderingProcessStruct from "./implemented/OrderingProcessStruct";
import OrgaStruct from "./implemented/OrgaStruct";
import ProjectStruct from "./implemented/ProjectStruct";
import SupplyContractStruct from "./implemented/SupplyContractStruct";
import SupplyUnitStruct from "./implemented/SupplyUnitStruct";
import TechnicalUnitStruct from "./implemented/TechnicalUnitStruct";
import TenantCommunicationStruct from "./implemented/TenantCommunicationStruct";
import UnitStruct from "./implemented/UnitStruct";
import VacancyStruct from "./implemented/VacancyStruct";

export const STRUCT_MAPPER: { [struct: string]: AbstractStructSelectors<any> } =
  {
    // BASIC
    unit: UnitStruct,
    orga: OrgaStruct,
    objectKind: ObjectKindStruct,
    invoice: InvoiceStruct,
    category: CategoryStruct,
    currency: CurrencyStruct,

    // immo related
    supplyUnit: SupplyUnitStruct,
    technicalUnit: TechnicalUnitStruct,
    // ACTIVITIES
    orderingProcess: OrderingProcessStruct,
    project: ProjectStruct,
    damageClaim: DamageClaimStruct,
    maintenance: MaintenanceStruct,
    supplyContract: SupplyContractStruct,
    insurance: InsuranceStruct,
    dunning: DunningStruct,
    vacancy: VacancyStruct,
    tenantCommunication: TenantCommunicationStruct,
  };

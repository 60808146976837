import classNames from "classnames";
import { ReactNode, useState } from "react";
import { Tree } from "rsuite";
import { ItemDataType } from "rsuite/esm/MultiCascadeTree";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import "./BFTree.scss";

export type BFTreeElement = {
  value: string;
  label: ReactNode;
  icon?: BfIconProps;
  iconOnDragged?: BfIconProps;
  children?: BFTreeElement[];
  originObject?: any;
};

type BFTreeProps = {
  treeData: BFTreeElement[];
  className?: string;
  onSelect?: (item: BFTreeElement) => void;
  activeValue?: string;
  onDrop?: (item: BFTreeElement) => void;
  showIndentLine?: boolean;
};

const BFTree = (props: BFTreeProps) => {
  const [draggedOverElement, setDraggedOverElement] = useState<
    BFTreeElement | undefined
  >(undefined);
  const [expandItemValues, setExpandItemValues] = useState<string[]>([]);

  const toggleExpandItem = (item: BFTreeElement) => {
    const index = expandItemValues.indexOf(item.value);
    if (index === -1) {
      setExpandItemValues([...expandItemValues, item.value]);
    } else {
      setExpandItemValues((prev) =>
        prev.filter((oldValue) => oldValue !== item.value)
      );
    }
  };

  const expandTreeItem = (item: BFTreeElement) => {
    if (expandItemValues.indexOf(item.value) === -1) {
      setExpandItemValues((prev) => [...prev, item.value]);
    }
  };

  return (
    <div className={classNames("bf-tree", props.className)}>
      <Tree
        onDrop={(event) => {
          setDraggedOverElement(undefined);
          if (props.onDrop) {
            props.onDrop(event.dropNode as BFTreeElement);
          }
        }}
        onDragEnter={(event) => {
          expandTreeItem(event as BFTreeElement);
        }}
        onDragLeave={(event) => {
          setDraggedOverElement(undefined);
        }}
        onDragOver={(event) => {
          const item = event as BFTreeElement;
          if (item.value !== draggedOverElement?.value) {
            setDraggedOverElement(event as BFTreeElement);
          }
        }}
        showIndentLine={props.showIndentLine}
        value={props.activeValue}
        onSelect={(item, value, event) => {
          if (props.onSelect) {
            props.onSelect(item as BFTreeElement);
          }
        }}
        expandItemValues={expandItemValues}
        onExpand={(item, value, event) => {
          toggleExpandItem(value as BFTreeElement);
        }}
        data={props.treeData.map(
          (element) =>
            ({
              value: element.value,
              label: element.label,
              icon: element.icon,
              iconOnDragged: element.iconOnDragged,
              children: element.children,
              originObject: element.originObject,
            } as ItemDataType)
        )}
        renderTreeNode={(node) => {
          const isDraggedOver = node.value === draggedOverElement?.value;
          let icon = node.icon;
          if (isDraggedOver) {
            icon = node.iconOnDragged || icon;
          }

          return (
            <div
              className={classNames("bf-tree-entry-wrapper", {
                "is-dragged-over": isDraggedOver,
              })}
            >
              {icon && <BfIcon {...icon} />}
              {node.label}
            </div>
          );
        }}
      />
    </div>
  );
};

export default BFTree;

import FileUtils from "../FileUtils";

class CSVUtilsClass {
  DEFAULT_DELIMITER = ";";
  UNIVERSAL_BOM = "\uFEFF";
  async downloadCSVFile(
    filename: string,
    values: string[][],
    delimiter = this.DEFAULT_DELIMITER
  ) {
    const data = this.generateCSVData(values, delimiter);
    const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(
      this.UNIVERSAL_BOM + data
    )}`;

    await FileUtils.downloadFile(csvData, filename);
  }

  generateCSVData(values: string[][], delimiter = this.DEFAULT_DELIMITER) {
    const data = values
      .map((row) =>
        row.map((value) => this.escapeCSVValue(value)).join(delimiter)
      )
      .join("\n");

    return data;
  }
  escapeCSVValue(value: string) {
    return `"${value.replace(/\"/g, '""')}"`;
  }
}

const CSVUtils = new CSVUtilsClass();
export default CSVUtils;

import CBMaintenanceCard from "@/apps/tatar/cashBudget/views/portfolio/object/cards/maintenance/CBMaintenanceCard";

type PropsObject = {
  assets: string[];
  refId: string;
  type: string;
};
type Props = PropsObject;
const ObjectDetailMaintenances = (props: Props) => {
  return (
    <div className="cb-portfolio-object-damage-claim">
      <CBMaintenanceCard
        type={props.type}
        referenceId={props.refId}
        lqObjectIds={props.assets} // fixme delete
        objectIds={props.assets}
        marginBottom={20}
      />
    </div>
  );
};
export default ObjectDetailMaintenances;

//KPI

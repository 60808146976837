import i18n from "../../i18n";
import LanguageService from "../../services/LanguageService";
import { isNotDefined } from "../../utils/Helpers";
import { TARGET } from "../../utils/Http";
import {
  ACInvoiceStructConfig,
  ACInvoiceType,
  InvoicePaymentType,
  InvoicePaymentTypeTags,
  InvoiceStructure,
} from "../AppConfigInterfaces";
import { InvoiceDirection, RAInvoice } from "../tatar/invoiceApp/RAInterfaces";
import AbstractAppConfigUtils from "./AbstractAppConfigUtils";

const FIXED_CUSTOM_FIELDS = () => [];
class ACInvoiceUtilsClass extends AbstractAppConfigUtils<InvoiceStructure> {
  getName(): string {
    return "invoiceTypes";
  }
  getUrl() {
    return "getInvoiceStruct";
  }
  getTarget(): TARGET {
    return "INVOICE";
  }
  getCustomFields(type: string) {
    return this.useCache<{ id: string; displayName: string }[]>(
      "getCustomFields",
      arguments,
      () => {
        const invoiceConfig = this.getInvoiceConfig(type);
        if (!invoiceConfig) return [];
        return [
          ...FIXED_CUSTOM_FIELDS(),
          ...(invoiceConfig.data.customFields || []).map((e) => ({
            id: e.id,
            displayName: LanguageService.translateLabel(e.displayName),
          })),
        ];
      }
    );
  }
  getInvoiceConfig(type: string) {
    return this.useCache<ACInvoiceStructConfig>(
      "getInvoiceConfig",
      arguments,
      () => {
        return this.data?.invoiceConfig.find((e) => e.data.type === type);
      }
    );
  }
  getPaymentType(id: string) {
    return this.useCache<InvoicePaymentType>("getPaymentType", arguments, () =>
      this.data?.invoiceConfig
        .map((e) => e.data.paymentTypes)
        .flat()
        .find((e) => e.id === id)
    );
  }
  getPaymentTypesOf(type: string) {
    return this.useCache<InvoicePaymentType[]>(
      "getPaymentTypesOf",
      arguments,
      () => this.getPaymentTypes([type])
    );
  }
  getPaymentTypes(type?: string[]) {
    return this.useCache<(InvoicePaymentType & { type: string })[]>(
      "getPaymentTypes",
      arguments,
      () =>
        this.data?.invoiceConfig
          .filter((e) =>
            type === undefined ? true : type.includes(e.data.type)
          )
          .map((e) =>
            e.data.paymentTypes.map((paymentType) => ({
              ...paymentType,
              type: e.data.type,
            }))
          )
          .flat()
    );
  }
  checkPaymentTypeTag(paymentTypeId: string, tag: InvoicePaymentTypeTags) {
    return this.useCache<boolean>("checkPaymentTypeTag", arguments, () => {
      const paymentType = this.getPaymentType(paymentTypeId);
      if (!paymentType) return false;
      return paymentType.tags.includes(tag);
    });
  }

  generateDocumentName(invoice: RAInvoice): string {
    // TODO invoice - paymentRecipient is now an object id
    let recipient = "recipient";
    // invoice.data.invoice.paymentRecipient
    //   .replace(/[^a-zA-Z0-9\s]/gm, "")
    //   .split(" ")
    //   .join("_");
    let invoiceId = invoice.data.invoice.invoiceId
      .replace(/[^a-zA-Z0-9\s]/gm, "")
      .split(" ")
      .join("");
    return `${invoice.id}_${recipient}_${invoiceId}.pdf`;
  }

  getInvoiceTypeByRelevantActivity(
    direction: InvoiceDirection,
    activityAssetType: string
  ) {
    return this.useCache<ACInvoiceType[]>(
      "getInvoiceTypeByRelevantActivity",
      arguments,
      () => {
        return this.getData()?.invoiceTypes.filter(
          (e) =>
            e.data.direction === direction &&
            e.data.relevantActivity?.includes(activityAssetType)
        );
      }
    );
  }

  getInvoiceTypeLabel(mainId: string, selectedId: string) {
    return this.useCache<string>("getInvoiceTypeLabel", arguments, () => {
      const mainType = this.getInvoiceType(mainId);
      if (!mainType) return "Unkown";
      if (mainType._id === selectedId) {
        return i18n.t("Global.Labels.misc", "Sonstiges");
      } else {
        return (
          LanguageService.translateLabel(
            mainType.data.costCenters.find((e) => e.costCenterId === selectedId)
              ?.displayName
          ) || "Unkown"
        );
      }
    });
  }

  getInvoiceTypes(direction: InvoiceDirection, type?: string | string[]) {
    return this.useCache<ACInvoiceType[]>("getInvoiceTypes", arguments, () =>
      this.getData()
        ?.invoiceTypes.filter((e) => e.data.direction === direction)
        .filter((e) =>
          isNotDefined(type)
            ? true
            : Array.isArray(type)
            ? type.includes(e.data.type)
            : e.data.type === type
        )
    );
  }
  getInvoiceType(id: string) {
    return this.useCache<ACInvoiceType>("getInvoiceType", arguments, () =>
      this.getData()?.invoiceTypes.find((entry) => entry._id === id)
    );
  }
  getInvoiceTypeCatalogForMainType(id: string) {
    return this.useCache<{ value: string; label: string }[]>(
      "getInvoiceTypeCatalogForMainType",
      arguments,
      () => {
        const mainType = this.getInvoiceType(id);
        if (!mainType) {
          return [];
        }
        return [
          ...(mainType.data.costCenters || []).map((e) => ({
            value: e.costCenterId,
            label: LanguageService.translateLabel(e.displayName),
            description: LanguageService.translateLabel(e.description),
          })),
          {
            value: mainType._id,
            label: i18n.t("Global.Labels.misc", "Sonstiges"),
          },
        ];
      }
    );
  }
}
const ACInvoiceUtils = new ACInvoiceUtilsClass();
(window as any).ACInvoiceUtils = ACInvoiceUtils;
export default ACInvoiceUtils;

import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import moment from "moment";
import { Field } from "react-final-form";
import FormStruct from "../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../i18n";
import BFInput from "../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../modules/abstract-ui/forms/select/BFSelect";
import StringUtils from "../../../../../utils/StringUtils";
import { PaymentPlan } from "../../RAInterfaces";
import { SUBTRACT_DAYS_DEFAULT } from "./RAInvoiceFormConst";

const RAInvoiceFormPaymentPlan = (props: {
  currency: string;
  initialAmount: number;
  initialDate: Date;
  invoiceUsage: string;

  onPaymentPlanCreate: (paymentPlan: PaymentPlan[]) => void;
}) => {
  return (
    <FormStruct<any>
      onSubmit={(values) => {
        const payments = [];
        const firstDate = moment(values.startDate).startOf("day").utc(true);
        for (let i = 0; i < values.countPayments; i++) {
          payments.push({
            date: firstDate
              .clone()
              .add(i * values.paymentCycle, "months")
              .toDate(),
            amount: values.amount,
            extra: false,
            linkedPaymentObject: null,
            usage: props.invoiceUsage,
            activeDate: firstDate
              .clone()
              .add(i * values.paymentCycle, "months")
              .subtract(SUBTRACT_DAYS_DEFAULT, "days")
              .toDate(),
          });
        }
        props.onPaymentPlanCreate(payments);
      }}
      initialValues={{
        amount: props.initialAmount || 0,
        startDate: props.initialDate || new Date(),
        paymentCycle: 1,
        countPayments: 12,
      }}
      submitText={i18n.t(
        "ra:InvoiceForm.PaymentPlan.Creation.CreatePlan",
        "Zahlungsplan generieren"
      )}
      render={(formProps) => (
        <>
          <div className={`ra-invoice-payment-plan-creation-form`}>
            <div className={`description`}>
              {i18n.t(
                "ra:InvoiceForm.PaymentPlan.CreationDescription",
                "Geben Sie an welchen Zyklus und Betrag der Zahlungsplan haben soll. Sie können die Daten im Detail noch im Nachhinein bearbeiten."
              )}
            </div>

            <div className={`form`}>
              <div className={`__field`}>
                <Field
                  name={"amount"}
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => (
                    <BFInput
                      type="priceInput"
                      label={i18n.t(
                        "ra:InvoiceForm.PaymentPlan.Creation.PaymentAmount",
                        "Betrag"
                      )}
                      prefix={StringUtils.getCurrencySymbol(props.currency)}
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>

              <div className={`__field`}>
                <Field
                  name={"startDate"}
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => (
                    <BFDatefield
                      label={i18n.t(
                        "ra:InvoiceForm.PaymentPlan.Creation.StartMonth",
                        "Beginn der Zahlung"
                      )}
                      preventNull
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>

              <div className={`__field`}>
                <Field
                  name={"paymentCycle"}
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => (
                    <BFSelect
                      label={i18n.t(
                        "ra:InvoiceForm.PaymentPlan.Creation.CycleOfPayment.Title",
                        "Zahlungszyklus"
                      )}
                      data={[
                        {
                          value: 1,
                          label: i18n.t(
                            "ra:InvoiceForm.PaymentPlan.Creation.CycleOfPayment.Monthly",
                            "Monatlich"
                          ),
                        },
                        {
                          value: 3,
                          label: i18n.t(
                            "ra:InvoiceForm.PaymentPlan.Creation.CycleOfPayment.Quarterly",
                            "Vierteljährlich"
                          ),
                        },
                        {
                          value: 6,
                          label: i18n.t(
                            "ra:InvoiceForm.PaymentPlan.Creation.CycleOfPayment.HalfYearly",
                            "Halbjährlich"
                          ),
                        },
                        {
                          value: 12,
                          label: i18n.t(
                            "ra:InvoiceForm.PaymentPlan.Creation.CycleOfPayment.Yearly",
                            "Jährlich"
                          ),
                        },
                      ]}
                      cleanable={false}
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>

              <div className={`__field`}>
                <Field
                  name={"countPayments"}
                  validate={FormValidators.compose(
                    FormValidators.required(),
                    FormValidators.min(2)
                  )}
                >
                  {({ input, meta }) => (
                    <BFInput
                      type="number"
                      step={1}
                      label={i18n.t(
                        "ra:InvoiceForm.PaymentPlan.Creation.CountPayments",
                        "Anzahl Zahlungen"
                      )}
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
};
export default RAInvoiceFormPaymentPlan;

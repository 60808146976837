import { Trans } from "react-i18next";
import { HistoryEntryObject } from "../../../../../components/HistoryViewer/IHistoryEntry";
import i18n from "../../../../../i18n";
import {
  CompleteInvoiceFollowUpTaskAsset,
  extractTaskAssignees,
  TaskAsset,
  TaskType,
} from "../../../../../model/general-assets/TaskAsset";
import BFCheckbox from "../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import LanguageService from "../../../../../services/LanguageService";
import ACInvoiceUtils from "../../../../utils/ACInvoiceUtils";
import { RAIncomingInvoice, RAInvoice } from "../../RAInterfaces";

export type PastProcessEntryType = {
  type: "past";
  statusText: string;
  user: string;
  date: Date;
  comment?: string;
  renderExtra?: () => React.ReactNode;
};
export type CurrentProcessEntryType = {
  type: "current";
  statusText: string;
  assignee?: { users: string[]; teams: string[] };
  renderExtra?: () => React.ReactNode;
  icon: string;
};
export type ProcessEntryType = PastProcessEntryType | CurrentProcessEntryType;

export const CurrentProcessTypeFormatter = (
  invoice: RAInvoice,
  task?: TaskAsset
) => {
  const output: CurrentProcessEntryType[] = [];

  if (invoice?.data.needsToBeBooked) {
    output.push({
      type: "current",
      icon: "accounting-invoice",
      statusText: i18n.t("ra:Approval.history.current_booking"),
    });
  }

  if (
    invoice?.data.direction === "OUTGOING" &&
    (invoice as RAIncomingInvoice)?.data.needsToBeChecked
  ) {
    output.push({
      type: "current",
      icon: "check-double-1",
      statusText: i18n.t("ra:Approval.history.current_checking", "In Freigabe"),
    });
  }
  if (invoice?.data.needsToBePayed) {
    output.push({
      type: "current",
      icon: "credit-card-give",
      statusText: i18n.t("ra:Approval.history.current_payment"),
    });
  }
  if (task) {
    if (
      (task.data.taskType === TaskType.APPROVE_INCOMING_INVOICE ||
        task.data.taskType === TaskType.APPROVE_OUTGOING_INVOICE) &&
      (task.data.restrictTo.teams.length !== 0 ||
        task.data.restrictTo.users.length !== 0)
    ) {
      output.push({
        type: "current",
        icon: "cursor-choose-1",
        statusText: i18n.t("ra:Approval.history.current_approval"),
        assignee: extractTaskAssignees(task),
      });
    }
    if (task.data.taskType === "COMPLETE_INVOICE_FOLLOW_UP") {
      const followUpTask = task as CompleteInvoiceFollowUpTaskAsset;
      output.push({
        type: "current",
        icon: "common-file-text-subtract",
        statusText: i18n.t("ra:Approval.history.current_followup"),
        assignee: extractTaskAssignees(task),
        renderExtra: () => {
          return (
            <div>
              {followUpTask.data.metaInfos.needStorno && (
                <BFCheckbox
                  checked={!!followUpTask.data.metaInfos.linkedStorno}
                  readOnly
                >
                  {i18n.t("ra:FollowUp.StornoNeeded")}
                </BFCheckbox>
              )}
              {followUpTask.data.metaInfos.needInvoice && (
                <BFCheckbox
                  checked={!!followUpTask.data.metaInfos.linkedInvoice}
                  readOnly
                >
                  {i18n.t("ra:FollowUp.InvoiceNeeded")}
                </BFCheckbox>
              )}
            </div>
          );
        },
      });
    }
  }
  return output;
};

export const PastProcessTypeFormatter = (
  historyEntry: HistoryEntryObject,
  invoice: RAInvoice
) => {
  if (
    historyEntry.type === "status_update" &&
    historyEntry.data?.status === "inApproval"
  ) {
    return {
      type: "past",
      statusText: i18n.t("ra:Approval.history.past_startApproval"),
      user: historyEntry.user,
      date: historyEntry.date,
    } as PastProcessEntryType;
  } else if (historyEntry.type === "change_chain") {
    return {
      type: "past",
      statusText: i18n.t("ra:Approval.history.past_change_chain"),
      user: historyEntry.user,
      date: historyEntry.date,
      renderExtra: () => {
        const from =
          LanguageService.translateLabel(
            ACInvoiceUtils.getInvoiceType(historyEntry.data.from)?.data
              .displayName
          ) || "-";
        const to =
          LanguageService.translateLabel(
            ACInvoiceUtils.getInvoiceType(historyEntry.data.to)?.data
              .displayName
          ) || "-";
        return (
          <span className={`invoice-change`}>
            <Trans i18nKey="ra:Approval.history.past_change_chain_extra">
              Von <strong>{{ from }}</strong> zu <strong>{{ to }}</strong>
            </Trans>
          </span>
        );
      },
    } as PastProcessEntryType;
  } else if (historyEntry.type === "invoice_payment") {
    return {
      type: "past",
      statusText: i18n.t(
        "ra:Approval.history.invoice_payment",
        "Wurde überwiesen"
      ),
      user: historyEntry.user,
      date: historyEntry.date,
      comment: historyEntry.data?.comment,
    } as PastProcessEntryType;
  } else if (historyEntry.type === "invoice_approve") {
    return {
      type: "past",
      statusText: i18n.t("ra:Approval.history.past_approved"),
      user: historyEntry.user,
      date: historyEntry.date,
      comment: historyEntry.data?.comment,
    } as PastProcessEntryType;
  } else if (historyEntry.type === "invoice_decline") {
    return {
      type: "past",
      statusText: i18n.t("ra:Approval.history.past_declined"),
      user: historyEntry.user,
      date: historyEntry.date,
      comment: historyEntry.data?.comment,
    } as PastProcessEntryType;
  } else if (historyEntry.type === "invoice_approve_override") {
    return {
      type: "past",
      statusText: i18n.t(
        "ra:Approval.history.past_approve_override",
        "Genehmigt in Vertretung"
      ),
      user: historyEntry.user,
      date: historyEntry.date,
      comment: historyEntry.data?.comment,
    } as PastProcessEntryType;
  } else if (historyEntry.type === "invoice_booked") {
    return {
      type: "past",
      statusText: i18n.t("ra:Approval.history.past_booked"),
      user: historyEntry.user,
      date: historyEntry.date,
    } as PastProcessEntryType;
  } else if (historyEntry.type === "invoice_payed") {
    return {
      type: "past",
      statusText: i18n.t("ra:Approval.history.past_payed"),
      user: historyEntry.user,
      date: historyEntry.date,
    } as PastProcessEntryType;
  } else if (historyEntry.type === "invoice_checked") {
    return {
      type: "past",
      statusText: i18n.t("ra:Approval.history.past_checked_true"),
      user: historyEntry.user,
      date: historyEntry.date,
    } as PastProcessEntryType;
  } else if (historyEntry.type === "invoice_follow_up") {
    return {
      type: "past",
      statusText: i18n.t("ra:Approval.history.past_followup"),
      user: historyEntry.user,
      date: historyEntry.date,
    } as PastProcessEntryType;
  }
  return null;
};

import i18n from "@/i18n";
import { BlockType, TextConfig } from "../services/types/pdfConfigBlockTypes";

export const TEXT_SIZES: {
  label: () => string;
  value: TextConfig["size"];
}[] = [
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.TextSizeValues.Header",
        "Überschrift"
      ),
    value: "lg",
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.TextSizeValues.Regular", "Normal"),
    value: "md",
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.TextSizeValues.Small", "Klein"),
    value: "sm",
  },
];

export const TEXT_ALIGNMENTS: {
  label: () => string;
  value: TextConfig["alignment"];
}[] = [
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.AlignmentValues.Left", "Links"),
    value: "left",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.AlignmentValues.Center",
        "Zentriert"
      ),
    value: "center",
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.AlignmentValues.Right", "Rechts"),
    value: "right",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.AlignmentValues.Justify",
        "Blocksatz"
      ),
    value: "justify",
  },
];

export const SelectGroups = {
  BASIC: () =>
    i18n.t("Component.PDFConstructor.BlockGroups.Basic", "Grundlegende"),
  OBJECT: () => i18n.t("Component.PDFConstructor.BlockGroups.Object", "Objekt"),
  TEMPLATE: () =>
    i18n.t("Component.PDFConstructor.BlockGroups.Template", "Template"),
};

export const BlockTypeGroupRelation: Record<
  BlockType,
  keyof typeof SelectGroups
> = {
  break: "BASIC",
  column: "BASIC",
  footer: "BASIC",
  header: "BASIC",
  image: "BASIC",
  line: "BASIC",
  link: "BASIC",
  text: "BASIC",
  page: "BASIC",
  date: "BASIC",
  "page-count": "BASIC",
  "column-group": "BASIC",
  "object-details": "OBJECT",
  "object-gallery": "OBJECT",
  "object-stacking-plan": "OBJECT",
  "vacancy-chart": "OBJECT",
  "upcoming-vacancy-table": "OBJECT",
  "rental-unit-distribution-chart": "OBJECT",
  "object-stacking-plan-table": "OBJECT",
  "object-tenant-list": "OBJECT",
  "object-vacancy-activity": "OBJECT",
  "object-damage-claims-table": "OBJECT",
  "object-rental-income-chart": "OBJECT",
  "object-risk-management": "OBJECT",
  "object-maintenance-contracts-table": "OBJECT",
  "object-loan-chart": "OBJECT",
  "object-loan-table": "OBJECT",
};

export const BlockTypes: {
  label: () => string;
  value: BlockType;
}[] = [
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.Inputs.TextInput", "Texteingabe"),
    value: "text",
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.Inputs.LinkInput", "Linkeingabe"),
    value: "link",
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.Inputs.ImageInput", "Bildeingabe"),
    value: "image",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.LineInput",
        "Linieneingabe"
      ),
    value: "line",
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.Inputs.HeaderInput", "Kopfzeile"),
    value: "header",
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.Inputs.FooterInput", "Fußzeile"),
    value: "footer",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ColumnInput",
        "Spalteneringabe"
      ),
    value: "column",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ColumnGroupInput",
        "Spaltengruppe"
      ),
    value: "column-group",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.PageBreakInput",
        "Seitenumbruch"
      ),
    value: "break",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.PageInput",
        "Seiteneinstellungen"
      ),
    value: "page",
  },
  {
    label: () =>
      i18n.t("Component.PDFConstructor.Block.Inputs.DateInput", "Datumeingabe"),
    value: "date",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.PageCountInput",
        "Seiteneingabe"
      ),
    value: "page-count",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ObjectDetails",
        "Objektdetails"
      ),
    value: "object-details",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ObjectGallery",
        "Objektgalerie"
      ),
    value: "object-gallery",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ObjectStackingPlan",
        "Objekt-Stapelplan"
      ),
    value: "object-stacking-plan",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ObjectVacancyChart",
        "Objekt-Leerstandsdiagramm"
      ),
    value: "vacancy-chart",
  },
  {
    label: () => "Upcoming Vacancy Table",
    value: "upcoming-vacancy-table",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ObjectStackingPlanTable",
        "Objekt Stapelplan Tabelle"
      ),
    value: "object-stacking-plan-table",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ObjectRentalUnitDistributionChart",
        "Objektverteilungsplan Mieteinheiten"
      ),

    value: "rental-unit-distribution-chart",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ObjectTenantListTable",
        "Objekt-Mieterliste-Tabelle"
      ),

    value: "object-tenant-list",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ActivityVacancyTable",
        "Aktivitäts-Leerstandstabelle"
      ),

    value: "object-vacancy-activity",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.DamageClaimsTable",
        "Schadensansprüche-Tabelle"
      ),
    value: "object-damage-claims-table",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ObjectRentalIncomeChart",
        "Tabelle der Mieteinnahmen"
      ),
    value: "object-rental-income-chart",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ObjectRiskManagementChart",
        "Risiko-Management-Diagramm"
      ),
    value: "object-risk-management",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.MaintenanceContractsTable",
        "Wartungsverträge-Tabelle"
      ),
    value: "object-maintenance-contracts-table",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.PortfolioLoanChart",
        "Portfolio-Darlehensdiagramm"
      ),
    value: "object-loan-chart",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.PortfolioLoanTable",
        "Portfolio-Darlehenstabelle"
      ),
    value: "object-loan-table",
  },
];

export const LANGUAGE_INPUT: {
  label: () => string;
  value: string;
}[] = [
  {
    value: "en",
    label: () =>
      i18n.t("Component.PDFConstructor.Language.English", "Englisch"),
  },
  {
    value: "de",
    label: () => i18n.t("Component.PDFConstructor.Language.German", "Deutsch"),
  },
];

import autosize from "autosize";
import classNames from "classnames";
import { CSSProperties, useEffect, useRef } from "react";
import { useDatabus } from "../../../../redux/hooks";
import { DataBusSubKeys } from "../../../../utils/Constants";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import "./AutosizeTextarea.scss";

interface Props {
  style?: CSSProperties;
  maxHeight?: number | string;
  minHeight?: number | string;
  className?: string;
  disabled?: boolean;
  value: string;
  error?: string | boolean;
  onFocus?: () => void;
  onChange: (value: string) => void;
  placeholder?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  label?: string;
  onBlur?: () => void;
  autoComplete?: string;
  autoCorrect?: string;
  spellCheck?: boolean;
  readonly?: boolean;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
}

const AutosizeTextarea = (props: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  useDatabus(
    DataBusSubKeys.UPDATE_AUTOSIZE_TEXTAREA,
    () => {
      if (ref.current) {
        autosize(ref.current);
      }
    },
    [ref]
  );
  useEffect(() => {
    if (ref.current) {
      autosize(ref.current);
    }
  }, [ref.current]);
  return (
    <div
      className={classNames(`autosize-textarea`, props.className, {
        error: !!props.error,
      })}
    >
      {props.label && <div className="label">{props.label}</div>}

      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <textarea
          readOnly={props.readonly}
          spellCheck={props.spellCheck}
          autoCorrect={props.autoCorrect}
          autoComplete={props.autoComplete}
          disabled={props.disabled}
          // onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyPress}
          className={classNames(`textarea`, { readonly: props.readonly })}
          style={{
            ...(props.style || {}),
            maxHeight: props.maxHeight,
            minHeight: props.minHeight,
          }}
          ref={ref}
          rows={1}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          placeholder={props.placeholder}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
        />
      </ValidationPopover>
    </div>
  );
};
export default AutosizeTextarea;

class DateUtilsClass {
  nextWorkday(date: Date): Date {
    let nextDay = new Date(date);
    while (nextDay.getDay() === 0 || nextDay.getDay() === 6) {
      nextDay.setDate(nextDay.getDate() + 1);
    }
    return nextDay;
  }
  lastWorkday(date: Date): Date {
    let lastDay = new Date(date);
    while (lastDay.getDay() === 0 || lastDay.getDay() === 6) {
      lastDay.setDate(lastDay.getDate() - 1);
    }
    return lastDay;
  }

  countMontsInRange(from: Date, to: Date) {
    let count = 0;
    const fromDate = new Date(from);
    const toDate = new Date(to);
    let date = new Date(fromDate);
    while (date <= toDate) {
      count++;
      date.setMonth(date.getMonth() + 1);
    }
    return count;
  }

  countDayOfMonthInRange(from: Date, to: Date, dayOfMonth: number) {
    let count = 0;
    const fromDate = new Date(from);
    const toDate = new Date(to);
    let date = new Date(fromDate);
    while (date <= toDate) {
      if (date.getDate() === dayOfMonth) {
        count++;
      }
      date.setDate(date.getDate() + 1);
    }
    return count;
  }

  /**
   * is checking if two periods are overlapping, if they are, it returns true
   *
   * @param period1 from/to can be null, so it is running from -infinity to +infinity
   * @param period2  from/to can be null, so it is running from -infinity to +infinity
   */
  isPeriodOverlapping(
    period1: { from?: Date; to?: Date },
    period2: { from?: Date; to?: Date }
  ): boolean {
    const from1 = period1.from || new Date(-8640000000000000);
    const to1 = period1.to || new Date(8640000000000000);
    const from2 = period2.from || new Date(-8640000000000000);
    const to2 = period2.to || new Date(8640000000000000);

    return from1 <= to2 && from2 <= to1;
  }
}
const DateUtils = new DateUtilsClass();
export default DateUtils;

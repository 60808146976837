import Log from "../debug/Log";
import ExpressionHelper from "../modules/generic-forms/util/ExpressionHelper";
import { ResubmissionGlobalActionParams } from "../modules/resubmission/RSInterfaces";
import DataBusDefaults from "./DataBusDefaults";

interface GlobalAction {
  func?: string | ((params: any) => void);
  appId?: string;
  params?: Object;
}
class GlobalActionsClass {
  actions: {
    [key: string]: GlobalAction[];
  } = {};

  registerGlobalAction(
    type: string,
    func: string | (() => void),
    appId?: string,
    params?: Object
  ) {
    if (!this.actions[type]) {
      this.actions[type] = [];
    }
    this.actions[type].push({
      func,
      appId,
      params,
    });
  }

  private runGlobalActionWithPrependAndAppend(type: string, value: any) {
    let result = null;

    const currentApplication = (window as any).store.getState().uiConfig
      ?.activeApplication?._id;

    if (currentApplication) {
      // run the actions in the current open application first
      result = this.runGlobalAction(
        `PREPEND_${type}`,
        value,
        currentApplication
      );
      if (result) {
        return result;
      }
      result = this.runGlobalAction(type, value, currentApplication);
      if (result) {
        return result;
      }
      result = this.runGlobalAction(
        `APPEND_${type}`,
        value,
        currentApplication
      );
      if (result) {
        return result;
      }
    }

    result = this.runGlobalAction(`PREPEND_${type}`, value);
    if (result) {
      return result;
    }
    result = this.runGlobalAction(type, value);
    if (result) {
      return result;
    }
    result = this.runGlobalAction(`APPEND_${type}`, value);
    if (result) {
      return result;
    }
    return null;
  }

  private runGlobalAction(type: string, value: any, appId?: string) {
    try {
      if (!this.actions[type]) {
        return null;
      }
      for (let i = 0; i < this.actions[type].length; i++) {
        const action = this.actions[type][i];
        if (appId && action.appId !== appId) {
          continue;
        }
        let result;
        if (typeof action.func === "string") {
          result = ExpressionHelper.evaluateExpression(action.func, {
            ...(value || {}),
            ...(action.params || {}),
          });
        } else {
          result = action.func({
            ...(value || {}),
            ...(action.params || {}),
          });
        }
        if (result) {
          return result;
        }
      }
      return null;
    } catch (err) {
      Log.warning("error running global action", type, value, err);
      return null;
    }
  }

  linkDetails(assetType: string, id: string, type?: string, params?: any) {
    const currentApplication = (window as any).store.getState().uiConfig
      ?.activeApplication?._id;
    return this.runGlobalActionWithPrependAndAppend("details", {
      assetType,
      id,
      type,
      params,
      currentApplication,
    });
  }
  openDetails(assetType: string, id: string, type?: string, params?: any) {
    const result = this.linkDetails(assetType, id, type, params);
    if (!result) {
      DataBusDefaults.toast({
        type: "error",
        text: (window as any).i18n.t(
          "GlobalActions.openDetails.noRouteFound",
          "Sie scheinen keine Berechtigung zu haben, um diese Aktion auszuführen."
        ),
      });
    } else {
      DataBusDefaults.route({
        route: result,
      });
    }
  }
  openResubmission(resubmissionParams: ResubmissionGlobalActionParams) {
    const { linkedAsset, type, resubmissionId } = resubmissionParams;
    const result = this.runGlobalActionWithPrependAndAppend("resubmission", {
      linkedAsset,
      type,
      resubmissionId,
    });
    if (!result) {
      DataBusDefaults.toast({
        type: "error",
        text: (window as any).i18n.t(
          "GlobalActions.openDetails.noRouteFound",
          "Sie scheinen keine Berechtigung zu haben, um diese Aktion auszuführen."
        ),
      });
    }
  }
}
const GlobalActions = new GlobalActionsClass();
export default GlobalActions;

import BFStatus from "@/modules/abstract-ui/data/status/BFStatus";
import { BFVirtualizedTablePosition } from "@/modules/abstract-ui/data/virtualized-table/BFVirtualizedTable";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import LanguageService from "@/services/LanguageService";
import StringUtils from "@/utils/StringUtils";
import { useEffect, useState } from "react";
import ManagedVirtualizedTable from "../../../../../../../configurable/data/VirtualizedTable/ManagedVirtualizedTable";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import {
  LinkCell,
  renderCellValue,
} from "../../../../../../../modules/abstract-ui/data/table/TableUtils";
import { useHttpCache } from "../../../../../../../redux/hooks";
import { DEFAULT_RENTAL_UNITS_OVERVIEW_IDENTIFIER } from "../../../portfolio/CBPortfolioConst";
import {
  EnrichtedRentalUnit,
  RentalUnit,
} from "../../../tenants/TenantsInterfaces";
import CBRentalUtils, {
  ObjectStackingPlanRowEntry,
  ObjectStackingPlanTableData,
} from "../../CBRentalUtils";
import { getConfigRentalUnitTypeGroup } from "../../CBTenantsConst";
import "./CBRentalUnitsOverviewTable.scss";
const SUB_ROW_HEIGHT = 37;
interface CBRentalUnitsOverviewTablePropsSingle {
  objectId: string;
  tableIdentifier?: string;
  onSelect?: (ids: string[]) => void;
}
interface CBRentalUnitsOverviewTablePropsMultiple {
  objectIds: string[];
  tableIdentifier?: string;
  onSelect?: (ids: string[]) => void;
}
type CBRentalUnitsOverviewTableProps =
  | CBRentalUnitsOverviewTablePropsMultiple
  | CBRentalUnitsOverviewTablePropsSingle;

const CBRentalUnitsOverviewTable = (props: CBRentalUnitsOverviewTableProps) => {
  const [tableData, setTableData] = useState<ObjectStackingPlanTableData>(null);
  const data = useHttpCache<EnrichtedRentalUnit[]>(
    `object-stacking-plan-${
      (props as CBRentalUnitsOverviewTablePropsSingle).objectId
        ? (props as CBRentalUnitsOverviewTablePropsSingle).objectId
        : (props as CBRentalUnitsOverviewTablePropsMultiple).objectIds.join(",")
    }`,
    `/rental/getStackingPlan`,
    "post",
    null,
    {
      objectIds: (props as CBRentalUnitsOverviewTablePropsSingle).objectId
        ? [(props as CBRentalUnitsOverviewTablePropsSingle).objectId]
        : (props as CBRentalUnitsOverviewTablePropsMultiple).objectIds,
    }
  );

  useEffect(() => {
    if (data.data) {
      setTableData(
        CBRentalUtils.transformObjectStackinplanDataEnrichedToRowData(
          CBRentalUtils.convertObjectStackingPlanData(data.data)
        )
      );
    }
  }, [data.data]);

  return (
    <ManagedVirtualizedTable
      loading={data?.state === "loading" && !tableData ? "general" : undefined}
      data={tableData?.rows || []}
      className={`cb-rental-units-overview-table`}
      identifier={
        props.tableIdentifier || DEFAULT_RENTAL_UNITS_OVERVIEW_IDENTIFIER
      }
      initialVisibleSort={{
        key: "data.id",
        dir: "asc",
      }}
      params={{
        aggregated: tableData?.aggregated,
      }}
      calculateSize={(node: ObjectStackingPlanRowEntry, index) => {
        if (node.units?.length > 0) {
          return [37, SUB_ROW_HEIGHT * node.units.length];
        } else {
        }

        return 37;
      }}
      subRowRender={(
        position: BFVirtualizedTablePosition,
        node: ObjectStackingPlanRowEntry,
        index: number,
        params?: any
      ) => {
        if (position === "center") {
          return (
            <div className={`sub-rows`}>
              {node.units?.map((unit, index) => {
                const unitType = ObjectKindStruct.getUnitTypeBy(
                  unit.data.unitType
                );

                const status = getConfigRentalUnitTypeGroup(unitType.group);

                return (
                  <div
                    className={`unit-row`}
                    style={{ height: SUB_ROW_HEIGHT }}
                  >
                    <div className={`line`}></div>
                    <div className={`name`}>
                      <LinkCell
                        assetType={AssetTypes.Rental.RentalUnit}
                        id={unit._id}
                        text={renderCellValue(
                          unit,
                          "-",
                          (unit: RentalUnit) =>
                            `${unit.data.id ? `${unit.data.id} - ` : ""}${
                              unit.data.displayName
                            }`
                        )}
                      />
                    </div>
                    {unit.data.building && (
                      <div className={`building`}>{unit.data.building}</div>
                    )}

                    <div className={`area-quantity`}>
                      {unit.data.area > 0 && (
                        <span className={`area`}>
                          {StringUtils.formatArea(unit.data.area)}
                        </span>
                      )}
                      {unit.data.quantity > 0 && (
                        <span className={`quantity`}>
                          {unit.data.quantity}x
                        </span>
                      )}
                    </div>

                    <div className={`status`}>
                      <BFStatus
                        size="sm"
                        color={status.color}
                        label={
                          status.label ===
                          LanguageService.translateLabel(unitType.displayName)
                            ? LanguageService.translateLabel(
                                unitType.displayName
                              )
                            : status.label +
                              " - " +
                              LanguageService.translateLabel(
                                unitType.displayName
                              )
                        }
                      />
                    </div>

                    <div className={`plan-rent`}>
                      <span className={`prefix`}>
                        {i18n.t("cb:UnitsOverview.planValue", "Planmiete")}{" "}
                      </span>
                      {StringUtils.formatCurrency(unit.data.rentNet)}
                      {unitType.taxRate > 0 ? (
                        <span className={`gross`}>
                          {" "}
                          ({StringUtils.formatCurrency(
                            unit.data.rentGross
                          )}{" "}
                          {i18n.t(
                            "cb:UnitsOverview.taxString",
                            "inkl. {{rate}}% Mwst.",
                            { rate: unitType.taxRate }
                          )}
                          ){" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }
        return null;
      }}
      onSelectionChange={(selected: string[]) => {
        if (props.onSelect) {
          if (selected.length === 0) {
          } else {
            const units = tableData?.rows.find(
              (e) => e._id === selected[0]
            )?.units;

            props.onSelect((units || []).map((e) => e._id));
          }
        }
      }}
      selection={props.onSelect ? "single" : undefined}
      rowClass={(node: ObjectStackingPlanRowEntry, index) =>
        node.type === "vacant" ? "vacant" : ""
      }
      columns={{
        displayName: {
          label: i18n.t("cb:UnitsOverview.RentalAgreement", "Mietvertrag"),
          flexWidth: 180,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            node.type === "vacant" ? (
              renderCellValue(node?.displayName, "-")
            ) : (
              <LinkCell
                assetType={AssetTypes.Rental.RentalAgreement}
                id={node._id}
                text={renderCellValue(node?.displayName, "-")}
              />
            ),
        },
        tenantName: {
          label: i18n.t("cb:UnitsOverview.tenant", "Mieter"),
          flexWidth: 180,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            node.type === "vacant" ? (
              renderCellValue(node?.tenantName, "-")
            ) : (
              <LinkCell
                assetType={AssetTypes.Contact}
                id={node.tenantId}
                text={renderCellValue(node?.tenantName, "-")}
              />
            ),
        },
        area: {
          label: i18n.t("cb:UnitsOverview.area", "Fläche"),
          flexWidth: 120,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(node?.area, "-", (value: number) =>
              StringUtils.formatArea(value)
            ),
          renderFooter: (params) =>
            renderCellValue(params?.aggregated?.area, "-", (value: number) =>
              StringUtils.formatArea(value)
            ),
        },
        quantity: {
          label: i18n.t("cb:UnitsOverview.quantity", "Anzahl/Stellplätze"),
          flexWidth: 120,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(node?.quantity, "-"),
          renderFooter: (params) =>
            renderCellValue(params?.aggregated?.quantity, "-"),
        },
        rentNet: {
          label: i18n.t("cb:UnitsOverview.rentNet", "NKM IST"),
          flexWidth: 120,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(node?.rentNet, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            renderCellValue(params?.aggregated?.rentNet, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
        },
        rentNetPerSqm: {
          label: i18n.t("cb:UnitsOverview.rentNetPerSqm", "NKM IST / m²"),
          flexWidth: 100,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(node?.rentNetPerSqm, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            renderCellValue(params?.aggregated?.rentNetPerSqm, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
        },
        planRentNet: {
          label: i18n.t("cb:UnitsOverview.planRentNet", "NKM SOLL"),
          flexWidth: 120,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(node?.planRentNet, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            renderCellValue(params?.aggregated?.planRentNet, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
        },
        planRentNetPerSqm: {
          label: i18n.t("cb:UnitsOverview.planRentNetPerSqm", "NKM SOLL / m²"),
          flexWidth: 100,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(node?.planRentNetPerSqm, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            renderCellValue(
              params?.aggregated?.planRentNetPerSqm,
              "-",
              (value) => StringUtils.formatCurrency(value)
            ),
        },
        rentNetDiff: {
          label: i18n.t("cb:UnitsOverview.rentNetDiff", "NKM Diff"),
          flexWidth: 120,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(
              node?.rentNetDiff,
              "-",
              (value) => StringUtils.formatCurrency(value),
              undefined,
              node?.rentNetDiff > 0 ? "green" : "red"
            ),
          renderFooter: (params) =>
            renderCellValue(
              params?.aggregated?.rentNetDiff,
              "-",
              (value) => StringUtils.formatCurrency(value),
              undefined,
              params?.aggregated?.rentNetDiff > 0 ? "green" : "red"
            ),
        },

        rentGross: {
          label: i18n.t("cb:UnitsOverview.rentGross", "Bruttomiete IST"),
          flexWidth: 120,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(node?.rentGross, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            renderCellValue(params?.aggregated?.rentGross, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
        },
        rentGrossPerSqm: {
          label: i18n.t(
            "cb:UnitsOverview.rentGrossPerSqm",
            "Bruttomiete IST / m²"
          ),
          flexWidth: 100,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(node?.rentGrossPerSqm, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            renderCellValue(params?.aggregated?.rentGrossPerSqm, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
        },
        planRentGross: {
          label: i18n.t("cb:UnitsOverview.planRentGross", "Bruttomiete SOLL"),
          flexWidth: 120,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(node?.planRentGross, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            renderCellValue(params?.aggregated?.planRentGross, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
        },
        planRentGrossPerSqm: {
          label: i18n.t(
            "cb:UnitsOverview.planRentGrossPerSqm",
            "Bruttomiete SOLL / m²"
          ),
          sortable: true,
          flexWidth: 100,
          resizable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(node?.planRentGrossPerSqm, "-", (value) =>
              StringUtils.formatCurrency(value)
            ),
          renderFooter: (params) =>
            renderCellValue(
              params?.aggregated?.planRentGrossPerSqm,
              "-",
              (value) => StringUtils.formatCurrency(value)
            ),
        },
        rentGrossDiff: {
          label: i18n.t("cb:UnitsOverview.rentGrossDiff", "Bruttomiete Diff"),
          flexWidth: 120,
          resizable: true,
          sortable: true,
          render: (node: ObjectStackingPlanRowEntry, index, params) =>
            renderCellValue(
              node?.rentGrossDiff,
              "-",
              (value) => StringUtils.formatCurrency(value),
              undefined,
              node?.rentGrossDiff > 0 ? "green" : "red"
            ),
          renderFooter: (params) =>
            renderCellValue(
              params?.aggregated?.rentGrossDiff,
              "-",
              (value) => StringUtils.formatCurrency(value),
              undefined,
              params?.aggregated?.rentGrossDiff > 0 ? "green" : "red"
            ),
        },
      }}
    />
  );
};

export default CBRentalUnitsOverviewTable;

import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ListComponent from "../../../../../../configurable/components/ListComponent/ListComponent";
import i18n from "../../../../../../i18n";
import BFSearch from "../../../../../../modules/abstract-ui/forms/input/BFSearch";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../../../../../modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../../../../../../modules/abstract-ui/icon/DefaultIcons";
import InfiniteTable from "../../../../../../redux/actions/application/application-infinite-table-actions";
import { CBIdlyConnectProps } from "./CBIdlyConnect";
import { Suggestion } from "./CBIdlyConnectService";
import CBIdlyConnectSuggestion from "./CBIdlyConnectSuggestion";
import "./CBIdlySearchFurther.scss";

const splitUsageInUsefulParts = (usageString: string) => {
  const delimiters = [
    " ",
    // ".",
    ",",
    // "/",
    // "-"
  ];
  const result: { token: string; relevant: boolean }[] = [];

  let currentToken = "";

  for (const char of usageString) {
    const charAsString = char.toString();

    if (delimiters.includes(charAsString)) {
      // Found a delimiter, push the current token if not empty
      if (currentToken.trim() !== "") {
        result.push({ token: currentToken.trim(), relevant: true });
        currentToken = "";
      }

      // Push the delimiter as a separate token
      result.push({ token: charAsString, relevant: false });
    } else {
      // Not a delimiter, append to the current token
      currentToken += charAsString;
    }
  }

  // Push the last token if not empty
  if (currentToken.trim() !== "") {
    result.push({ token: currentToken.trim(), relevant: true });
  }

  return result;
};
type CBIdlySearchFurtherProps = CBIdlyConnectProps & {
  onBack: () => void;
};
const CBIdlySearchFurther = (props: CBIdlySearchFurtherProps) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [usageSplitted] = useState(
    splitUsageInUsefulParts(props.booking.data.usage)
  );
  const [recipientSplitted] = useState(
    splitUsageInUsefulParts(props.booking.data.recipient)
  );
  const identifier = `suggestion-${props.assetType}-${props.booking._id}`;

  useEffect(() => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, []);
  return (
    <div className={classNames(`cb-idly-search-further`)} ref={ref}>
      <div className={`head`}>
        <div className={`back-action`}>
          <BFButton onClick={props.onBack} appearance="clear-on-white">
            <BfIcon {...DefaultIcons.BACK} size="xs" />
          </BFButton>
        </div>
        <div className={`filter`} />
        <div className={`search-input`}>
          <BFSearch focusOnMount tableIdentifier={identifier} />
          <div className={`receiver-tags helper-tags`}>
            <span className={`describer`}>
              {props.booking.data.value > 0
                ? i18n.t("cb:idlySearchFurther.PaymentSender", "Bezahlt von")
                : i18n.t("cb:idlySearchFurther.Recipient", "Bezahlt an")}
            </span>

            {recipientSplitted.map((token) => (
              <span
                className={token.relevant ? "token" : ""}
                onClick={
                  token.relevant
                    ? () => {
                        dispatch(
                          InfiniteTable.setSearch(identifier, token.token)
                        );
                      }
                    : undefined
                }
              >
                {token.token}
              </span>
            ))}
          </div>
          <div className={`usage-tags helper-tags`}>
            <span className={`describer`}>
              {i18n.t("cb:idlySearchFurther.Usage", "Verwendungszweck")}
            </span>
            {usageSplitted.map((token) => (
              <span
                className={token.relevant ? "token" : ""}
                onClick={
                  token.relevant
                    ? () => {
                        dispatch(
                          InfiniteTable.setSearch(identifier, token.token)
                        );
                      }
                    : undefined
                }
              >
                {token.token}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={`search-result`}>
        <ListComponent
          cleanupOnUnmount
          identifier={identifier}
          additionalMatchQuery={props.matchQuery}
          assetType={props.assetType}
          asPost
          limitPerRequest={5}
          render={(data) => {
            return <EntryWrapper {...props} data={data} />;
          }}
        />
      </div>
    </div>
  );
};

const EntryWrapper = (props: CBIdlySearchFurtherProps & { data: any }) => {
  const [suggestion, setSuggestion] = React.useState<Suggestion<any, any>>(
    props.mapSuggestion(props.data, props.booking)
  );

  return (
    <div className={`search-entry`}>
      <CBIdlyConnectSuggestion
        {...props}
        suggestion={suggestion}
        onUpdateSuggestion={(suggestion) => setSuggestion(suggestion)}
      />
    </div>
  );
};
export default CBIdlySearchFurther;

import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import AccountingService from "@/apps/tatar/accounting/AccountingService";
import {
  AccountingAccount,
  AccountingBooking,
  AccountingBookingGroup,
  AccountingBookingType,
} from "@/apps/tatar/accounting/interfaces/account.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import MultipleAssetLoaders from "@/components/AssetLoader/MultipleAssetLoaders";
import StructLoader from "@/components/StructLoader/StructLoader";
import DebugDataComponent from "@/debug/DebugDataComponent";
import Log from "@/debug/Log";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFQuickSelect from "@/modules/abstract-ui/forms/input/BFQuickSelect";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFMessage from "@/modules/abstract-ui/general/Message/BFMessage";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import { useHttpCache } from "@/redux/hooks";
import LanguageService from "@/services/LanguageService";
import NumberUtils from "@/utils/ NumberUtils";
import ArrayUtils from "@/utils/ArrayUtils";
import { hasNoValue, hasValue, isDefined } from "@/utils/Helpers";
import { usePrevious } from "@/utils/Hooks";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { FieldState } from "final-form";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Loader } from "rsuite";
import "./CBRentalAgreementOpenDebitPositions.scss";
import CBRentalAgreementPositionInfo from "./CBRentalAgreementPositionInfo";

const NOT_CATEGORIZED_ID = "not-categorized";
export type DebitPositionResult = AccountingBookingGroup & {
  bookings: AccountingBooking[];
  contraBookings: AccountingBooking[];
};
export interface ManualBookingPosition {
  name: string;
  id: string;
  taxRate: number;
  monthlyCost: number;
  account: string;
}

type OpenDebitError = {
  type: "message" | "referenceField";
  message: string;
};
export const validateOpenDebitPosition =
  (withCounterbooking: boolean) =>
  (
    value: OpenDebitPositionValue,
    allValues: object,
    meta?: FieldState<any>
  ) => {
    const relevantBookings = value.bookings.filter(
      (e) =>
        e.value.amount !== 0 || (e.counterBooking?.value?.amount || 0) !== 0
    );
    if (relevantBookings.length === 0) {
      return {
        type: "message",
        message: i18n.t(
          "cb:OpenDebitPositions.Errors.noBookings",
          "Bitte füllen Sie mindestens eine Position aus"
        ),
      };
    }
    const bookingsWithCostIdNull = relevantBookings.filter(
      (e) => e.costId === null
    );
    if (bookingsWithCostIdNull.length > 0 && hasNoValue(value.referenceField)) {
      return {
        type: "referenceField",
        message: i18n.t(
          "cb:OpenDebitPositions.Errors.referenceFieldRequired",
          "Buchungsnummer erforderlich"
        ),
      };
    }
    if (withCounterbooking) {
      const sum = (
        values: { value: number; bookingType: AccountingBookingType }[]
      ) => {
        return _.sum(
          values.map((e) =>
            e.bookingType === AccountingBookingType.HABEN ? -e.value : e.value
          )
        );
      };
      const amountBooking = sum(
        relevantBookings.map((e) => ({
          value: e.value.amount || 0,
          bookingType: e.bookingType,
        }))
      );
      const amountCounter = sum(
        relevantBookings.map((e) => ({
          value: e.counterBooking?.value?.amount || 0,
          bookingType:
            e.counterBooking?.bookingType || AccountingBookingType.SOLL,
        }))
      );

      const diff = amountBooking + amountCounter;
      if (diff !== 0) {
        return {
          type: "message",
          message: i18n.t(
            "cb:OpenDebitPositions.Errors.differentAmounts",
            "Sie müssen den ausgebuchten Betrag entsprechend wieder einbuchen. Bitte prüfen Sie Ihren Eingaben."
          ),
        };
      }
    }
  };

export type OpenDebitPositionBookingValue = {
  value: {
    amount: number;
    currency: string;
  };

  usedForId?: string;

  bookingType: AccountingBookingType;
  costId: string;
  costAccount?: string;

  counterBooking?: {
    value: {
      amount: number;
      currency: string;
    };
    bookingType: AccountingBookingType;
  };
};

export type OpenDebitPositionValue = {
  bookings: OpenDebitPositionBookingValue[];
  referenceField: string;
};
interface CBRentalAgreementOpenDebitPositionsProps {
  accountingData: AccountingData;
  account: string;
  countbooking?: boolean;
  value?: OpenDebitPositionValue;

  onChange?: (value: OpenDebitPositionValue) => void;
  validation?: OpenDebitError;
  openAmount?: number;

  showBalanced: boolean;
  manualBookingPositions?: ManualBookingPosition[];
}

const CBRentalAgreementOpenDebitPositions = (
  props: CBRentalAgreementOpenDebitPositionsProps
) => {
  const prevBalancedFlag = usePrevious(props.showBalanced);
  useEffect(() => {
    if (prevBalancedFlag === undefined) {
      return;
    }
    if (prevBalancedFlag !== props.showBalanced) {
      props.onChange({ bookings: [], referenceField: "" });
    }
  }, [props.showBalanced]);
  const data = useHttpCache<DebitPositionResult[]>(
    `cb-rental-agreement-open-debit-positions-${props.account}-${
      props.showBalanced || false
    }`,
    `/api/accounting/${props.accountingData.accounting.data.entity}/${props.account}/getOpenDebitPositions`,
    "get",
    {
      param: { filter0: !props.showBalanced },
    },
    undefined,
    undefined,
    (data) => {
      return ArrayUtils.sortData(data, {
        dir: "desc",
        key: "data.date",
      });
    }
  );

  if (data.state === "loading") {
    return <Loader />;
  }
  if (data.state === "error") {
    return (
      <div>
        {i18n.t(
          "cb:OpenDebitPositions.OpenDebitPositions.Error",
          "Fehler beim Laden der Positionen"
        )}
      </div>
    );
  }

  return (
    <CBRentalAgreementOpenDebitPositionsComp {...props} data={data.data} />
  );
};

export const CBRentalAgreementOpenDebitPositionsComp = (
  props: CBRentalAgreementOpenDebitPositionsProps & {
    data: DebitPositionResult[];
  }
) => {
  const [openState, setOpenState] = useState<string[]>([]);
  const editable = isDefined(props.onChange);

  const headers = [
    {
      label: "",
      renderPosition: (pos: DebitPositionResult) => {
        return (
          <div className="collapse-button">
            <BFButton
              onClick={() => {
                if (openState.includes(pos._id)) {
                  setOpenState(openState.filter((id) => id !== pos._id));
                } else {
                  setOpenState([...openState, pos._id]);
                }
              }}
            >
              <BfIcon
                size="xxs"
                {...DefaultIcons.CHEVRON}
                className={`${openState.includes(pos._id) ? "open" : ""}`}
              />
            </BFButton>
          </div>
        );
      },
      renderManualPosition: () => {
        return (
          <div className="collapse-button">
            <BFButton
              onClick={() => {
                if (openState.includes(NOT_CATEGORIZED_ID)) {
                  setOpenState(
                    openState.filter((id) => id !== NOT_CATEGORIZED_ID)
                  );
                } else {
                  setOpenState([...openState, NOT_CATEGORIZED_ID]);
                }
              }}
            >
              <BfIcon
                size="xxs"
                {...DefaultIcons.CHEVRON}
                className={`${
                  openState.includes(NOT_CATEGORIZED_ID) ? "open" : ""
                }`}
              />
            </BFButton>
          </div>
        );
        // return (
        //   <div className="collapse-button">
        //     <BFButton
        //       onClick={() => {
        //         if (openState.includes("manual")) {
        //           setOpenState(openState.filter((id) => id !== "manual"));
        //         } else {
        //           setOpenState([...openState, "manual"]);
        //         }
        //       }}
        //     >
        //       <BfIcon
        //         size="xxs"
        //         {...DefaultIcons.CHEVRON}
        //         className={`${openState.includes("manual") ? "open" : ""}`}
        //       />
        //     </BFButton>
        //   </div>
        // );
      },
      renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
        return <div></div>;
      },
      renderBooking: (booking: AccountingBooking, pos: DebitPositionResult) => {
        return <div></div>;
      },
    },
    {
      label: i18n.t("cb:OpenDebitPositions.OpenDebitPositions.Date", "Datum"),

      renderPosition: (pos: DebitPositionResult) => {
        return <div>{StringUtils.formatDate(pos.data.date)} </div>;
      },
      renderBooking: (booking: AccountingBooking, pos: DebitPositionResult) => {
        return <div>{StringUtils.formatDate(booking.data.date)}</div>;
      },

      renderManualPosition: () => {
        return <div></div>;
      },
      renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
        return <div></div>;
      },
    },
    {
      label: i18n.t(
        "cb:OpenDebitPositions.OpenDebitPositions.BookingText",
        "Buchungstext"
      ),

      renderPosition: (pos: DebitPositionResult) => {
        return <div>{pos.data.displayName} </div>;
      },
      renderManualPosition: () => {
        return (
          <div>
            {i18n.t(
              "cb:OpenDebitPositions.OpenDebitPositions.NotCageorized",
              "Nicht kategorisiert"
            )}
          </div>
        );
      },
      renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
        return <div>{manualBookingPosition.name}</div>;
      },
      // renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
      //   return null;
      //   return <div>{manualBookingPosition.name}</div>;
      // },
      renderBooking: (
        booking: AccountingBooking,
        pos: DebitPositionResult,
        subIndex: number,
        subLength: number
      ) => {
        return (
          <AssetLoader
            assetType={AssetTypes.Accounting.Account}
            id={booking.data.contraAccount}
            render={(account: AccountingAccount) => (
              <div className={`position-name`}>
                <div
                  className={`position-parent-indicator ${
                    subIndex === subLength - 1 ? "last" : ""
                  }`}
                >
                  <div className={`position-border`} />
                  <div className={`position-indicator`} />
                </div>
                <div className={`position-text`}>
                  {LanguageService.translateLabel(account.data.displayName)}
                  {booking.data.usedForId && (
                    <AssetLoader
                      inline
                      assetType={AssetTypes.Accounting.Account}
                      id={booking.data.usedForId}
                      render={(usedForAccount: AccountingAccount) => (
                        <span className={`used-for`}>
                          {" "}
                          (
                          {LanguageService.translateLabel(
                            usedForAccount?.data?.displayName
                          )}
                          )
                        </span>
                      )}
                    />
                  )}
                </div>
              </div>
            )}
          />
        );
      },
    },

    {
      fixedWith: 85,
      label: "",
      renderPosition: (pos: DebitPositionResult) => {
        return <CBRentalAgreementPositionInfo pos={pos} />;
      },
      renderBooking: () => {
        return <div></div>;
      },

      renderManualPosition: () => {
        return <div></div>;
      },
      renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
        return <div></div>;
      },
    },
    editable
      ? {
          label: i18n.t(
            "cb:OpenDebitPositions.OpenDebitPositions.AmountOfBookings",
            "OP-Summe"
          ),

          className: "currency-column",
          renderPosition: (pos: DebitPositionResult) => {
            const entryData = evaluatedData.groupValues[pos._id];
            const type =
              entryData.op >= 0
                ? AccountingBookingType.HABEN
                : AccountingBookingType.SOLL;
            return (
              <div className={`value ${entryData.op > 0 ? "negative" : ""}`}>
                {StringUtils.formatCurrency(
                  Math.abs(entryData.op),
                  undefined,
                  undefined,
                  props.accountingData.accounting.data.currency
                )}{" "}
                {type}
              </div>
            );
          },
          renderBooking: (
            booking: AccountingBooking,
            pos: DebitPositionResult
          ) => {
            const entryData = evaluatedData.bookingValues[booking._id];
            const type =
              entryData.op >= 0
                ? AccountingBookingType.HABEN
                : AccountingBookingType.SOLL;
            return (
              <div className={`value ${entryData.op > 0 ? "negative" : ""}`}>
                {StringUtils.formatCurrency(
                  Math.abs(entryData.op),
                  undefined,
                  undefined,
                  booking.data.value.currency
                )}{" "}
                {type}
              </div>
            );
          },

          renderManualBooking: (
            manualBookingPosition: ManualBookingPosition
          ) => {
            return <div></div>;
          },

          renderManualPosition: () => {
            return (
              <div>
                <BFInput
                  prefix={i18n.t(
                    "cb:OpenDebitPositions.ReferenceField",
                    "Belegnr."
                  )}
                  value={props.value?.referenceField || ""}
                  onChange={(value: string) => {
                    props.onChange({ ...props.value, referenceField: value });
                  }}
                  validation={
                    props.validation?.type === "referenceField"
                      ? {
                          level: "error",
                          message: props.validation?.message,
                        }
                      : undefined
                  }
                />
              </div>
            );
          },
          colSpanManualPosition: 2,
        }
      : null,
    {
      label: i18n.t(
        "cb:OpenDebitPositions.OpenDebitPositions.OpenAmount",
        "Offen"
      ),
      className: "currency-column",
      renderPosition: (pos: DebitPositionResult) => {
        const entryData = evaluatedData.groupValues[pos._id];
        const type =
          entryData.open >= 0
            ? AccountingBookingType.HABEN
            : AccountingBookingType.SOLL;

        return (
          <div
            className={`value ${entryData.open === 0 ? "balanced" : ""} ${
              entryData.open > 0 ? "negative" : ""
            }`}
          >
            {StringUtils.formatCurrency(
              Math.abs(entryData.open),
              undefined,
              undefined,
              props.accountingData.accounting.data.currency
            )}{" "}
            {type}
          </div>
        );
      },
      renderBooking: (booking: AccountingBooking, pos: DebitPositionResult) => {
        const entryData = evaluatedData.bookingValues[booking._id];
        const type =
          entryData.open >= 0
            ? AccountingBookingType.HABEN
            : AccountingBookingType.SOLL;
        return (
          <div
            className={`value  ${entryData.open === 0 ? "balanced" : ""} ${
              entryData.open > 0 ? "negative" : ""
            }`}
          >
            {StringUtils.formatCurrency(
              Math.abs(entryData.open),
              undefined,
              undefined,
              booking.data.value.currency
            )}{" "}
            {type}
          </div>
        );
      },

      renderManualPosition: null,
      renderManualBooking: () => {
        return <div></div>;
      },
      // renderManualBooking: (manualBookingPosition: ManualBookingPosition) => {
      //   return <div></div>;
      // },
    },
    ...(editable
      ? [
          {
            label: props.countbooking
              ? i18n.t(
                  "cb:OpenDebitPositions.OpenDebitPositions.Bookout",
                  "Ausbuchung"
                )
              : i18n.t(
                  "cb:OpenDebitPositions.OpenDebitPositions.Amount",
                  "Zahlung"
                ),
            renderFooter: () => {
              const sum = evaluatedData.sums.bookIn;
              return (
                <div
                  className={classNames("footer-value", {
                    negative: sum < 0,
                    positive: sum > 0,
                  })}
                >
                  {StringUtils.formatCurrency(
                    sum,
                    undefined,
                    undefined,
                    props.accountingData.accounting.data.currency
                  )}
                </div>
              );
            },
            renderPosition: (pos: DebitPositionResult) => {
              const entryData = evaluatedData.groupValues[pos._id];
              const valueType =
                entryData.bookIn < 0
                  ? AccountingBookingType.SOLL
                  : AccountingBookingType.HABEN;
              const disableQuickAction =
                props.openAmount === 0 || entryData.open === 0;
              const allowRevert = entryData.bookIn !== 0;
              return (
                <MultipleAssetLoaders
                  inline
                  assets={pos.bookings.map((b) => ({
                    assetType: AssetTypes.Accounting.Account,
                    assetId: b.data.contraAccount,
                  }))}
                  render={(relevantAccounts: AccountingAccount[]) => (
                    <div>
                      <BFInput
                        type="priceInput"
                        currencySuffix={StringUtils.getCurrencySymbol(
                          props.accountingData.accounting.data.currency
                        )}
                        removePrefixPadding
                        prefix={
                          <MultipleAssetLoaders
                            inline
                            assets={pos.bookings.map((b) => ({
                              assetType: AssetTypes.Accounting.Account,
                              assetId: b.data.contraAccount,
                            }))}
                            render={(relevantAccounts: AccountingAccount[]) => (
                              <div className={`position-quick-actions`}>
                                <BFButton
                                  appearance="link"
                                  disabled={disableQuickAction}
                                  noPadding
                                  size="sm"
                                  onClick={() => {
                                    distribute(
                                      "booking",
                                      hasValue(props.openAmount)
                                        ? Math.min(
                                            Math.abs(entryData.op),
                                            Math.abs(props.openAmount)
                                          )
                                        : Math.abs(entryData.op),
                                      entryData.op < 0
                                        ? AccountingBookingType.HABEN
                                        : AccountingBookingType.SOLL,
                                      pos.bookings,
                                      relevantAccounts
                                    );
                                  }}
                                  tooltip={{
                                    tooltip: i18n.t(
                                      "cb:OpenDebitPositions.OpenDebitPositions.DistributeOpenAmount",
                                      "Offenen Betrag auf den Positionen aufteilen"
                                    ),
                                  }}
                                >
                                  <BfIcon
                                    size="xxs"
                                    type="light"
                                    data="diagram-arrow-right"
                                  />
                                </BFButton>
                                {allowRevert && (
                                  <BFButton
                                    appearance="link"
                                    noPadding
                                    size="sm"
                                    onClick={() =>
                                      distribute(
                                        "booking",
                                        0,
                                        entryData.op < 0
                                          ? AccountingBookingType.SOLL
                                          : AccountingBookingType.HABEN,
                                        pos.bookings,
                                        relevantAccounts
                                      )
                                    }
                                    tooltip={{
                                      tooltip: i18n.t(
                                        "cb:OpenDebitPositions.OpenDebitPositions.RedoOpenAmount",
                                        "Beträge wieder zurücksetzen auf 0"
                                      ),
                                    }}
                                  >
                                    <BfIcon
                                      size="xxs"
                                      type="light"
                                      data="undo"
                                    />
                                  </BFButton>
                                )}
                              </div>
                            )}
                          />
                        }
                        suffix={
                          <BFQuickSelect
                            value={valueType}
                            // disabled={values.length === 0}
                            onChange={(newType: AccountingBookingType) => {
                              if (newType !== valueType) {
                                // "booking",
                                // newType !== valueType
                                //   ? -entryData.bookIn
                                //   : entryData.bookIn,
                                // valueType,
                                // [booking],
                                // relevantAccounts,
                                // props.showBalanced

                                let amount = entryData.bookIn;

                                if (valueType === AccountingBookingType.SOLL) {
                                  // now switched so, if amount is negative, negate it
                                  amount = amount < 0 ? -amount : amount;
                                }

                                distribute(
                                  "booking",
                                  amount,
                                  newType,
                                  pos.bookings,
                                  relevantAccounts,
                                  props.showBalanced
                                );
                              }
                            }}
                            options={[
                              {
                                label: i18n.t("acc:SollShort", "S"),
                                value: AccountingBookingType.SOLL,
                              },
                              {
                                label: i18n.t("acc:HabenShort", "H"),
                                value: AccountingBookingType.HABEN,
                              },
                            ]}
                          />
                        }
                        removeSuffixPadding
                        textAlign="right"
                        value={Math.abs(entryData.bookIn)}
                        onChange={(value: number) => {
                          let amount;
                          if (props.showBalanced) {
                            amount = value;
                          } else {
                            amount = props.openAmount
                              ? Math.min(
                                  Math.abs(value),
                                  Math.abs(props.openAmount)
                                )
                              : Math.abs(value);
                          }
                          distribute(
                            "booking",
                            amount,
                            valueType,
                            pos.bookings,
                            relevantAccounts,
                            props.showBalanced
                          );
                        }}
                      />
                    </div>
                  )}
                />
              );
            },
            renderBooking: (
              booking: AccountingBooking,
              pos: DebitPositionResult
            ) => {
              const entryData = evaluatedData.bookingValues[booking._id];
              const valueType =
                entryData.bookIn < 0
                  ? AccountingBookingType.SOLL
                  : AccountingBookingType.HABEN;
              const disableQuickAction =
                props.openAmount === 0 || entryData.open === 0;
              return (
                <MultipleAssetLoaders
                  inline
                  assets={pos.bookings.map((b) => ({
                    assetType: AssetTypes.Accounting.Account,
                    assetId: b.data.contraAccount,
                  }))}
                  render={(relevantAccounts: AccountingAccount[]) => (
                    <div>
                      <BFInput
                        type="priceInput"
                        currencySuffix={StringUtils.getCurrencySymbol(
                          props.accountingData.accounting.data.currency
                        )}
                        removePrefixPadding
                        prefix={
                          <BFButton
                            appearance="link"
                            disabled={disableQuickAction}
                            size="xs"
                            onClick={() => {
                              distribute(
                                "booking",
                                hasValue(props.openAmount)
                                  ? Math.min(
                                      Math.abs(entryData.op),
                                      Math.abs(props.openAmount)
                                    )
                                  : Math.abs(entryData.op),
                                entryData.op < 0
                                  ? AccountingBookingType.HABEN
                                  : AccountingBookingType.SOLL,
                                [booking],
                                relevantAccounts
                              );
                            }}
                            tooltip={{
                              tooltip: i18n.t(
                                "cb:OpenDebitPositions.OpenDebitPositions.BalanceOpenAmount",
                                "Offenen Betrag ausgleichen"
                              ),
                            }}
                          >
                            <BfIcon
                              size="xxs"
                              type="light"
                              data="keyboard-arrow-right"
                            />
                          </BFButton>
                        }
                        suffix={
                          <BFQuickSelect
                            value={valueType}
                            onChange={(newType: AccountingBookingType) => {
                              if (newType !== valueType) {
                                let amount = entryData.bookIn;

                                if (valueType === AccountingBookingType.SOLL) {
                                  // now switched so, if amount is negative, negate it
                                  amount = amount < 0 ? -amount : amount;
                                }

                                distribute(
                                  "booking",
                                  amount,
                                  newType,
                                  [booking],
                                  relevantAccounts,
                                  props.showBalanced
                                );
                              }
                            }}
                            options={[
                              {
                                label: i18n.t("acc:SollShort", "S"),
                                value: "S",
                              },
                              {
                                label: i18n.t("acc:HabenShort", "H"),
                                value: "H",
                              },
                            ]}
                          />
                        }
                        removeSuffixPadding
                        textAlign="right"
                        value={Math.abs(entryData.bookIn)}
                        onChange={(value: number) => {
                          let amount;
                          if (props.showBalanced) {
                            amount = value;
                          } else {
                            amount = props.openAmount
                              ? Math.min(
                                  Math.abs(value),
                                  Math.abs(props.openAmount)
                                )
                              : Math.abs(value);
                          }

                          distribute(
                            "booking",
                            amount,
                            valueType,
                            [booking],
                            relevantAccounts,
                            props.showBalanced
                          );
                        }}
                      />
                    </div>
                  )}
                />
              );
            },

            renderManualPosition: () => {
              const entryData = evaluatedData.groupValues["null"];
              const valueType =
                entryData.bookIn < 0
                  ? AccountingBookingType.SOLL
                  : AccountingBookingType.HABEN;
              const disableQuickAction = props.openAmount === 0;
              const allowRevert = entryData.bookIn !== 0;
              return (
                <MultipleAssetLoaders
                  inline
                  assets={props.manualBookingPositions
                    .filter((e) => e.monthlyCost)
                    .map((b) => ({
                      assetType: AssetTypes.Accounting.Account,
                      assetId: b.account,
                    }))}
                  render={(relevantAccounts: AccountingAccount[]) => (
                    <div>
                      <BFInput
                        type="priceInput"
                        currencySuffix={StringUtils.getCurrencySymbol(
                          props.accountingData.accounting.data.currency
                        )}
                        removePrefixPadding
                        prefix={
                          <div className={`position-quick-actions`}>
                            <BFButton
                              appearance="link"
                              disabled={disableQuickAction}
                              noPadding
                              size="sm"
                              onClick={() => {
                                distribute(
                                  "manualBooking",
                                  Math.abs(props.openAmount),
                                  entryData.op < 0
                                    ? AccountingBookingType.HABEN
                                    : AccountingBookingType.SOLL,
                                  props.manualBookingPositions,
                                  relevantAccounts,
                                  undefined,
                                  true
                                );
                              }}
                              tooltip={{
                                tooltip: i18n.t(
                                  "cb:OpenDebitPositions.OpenDebitPositions.DistributeOpenAmount",
                                  "Offenen Betrag auf den Positionen aufteilen"
                                ),
                              }}
                            >
                              <BfIcon
                                size="xxs"
                                type="light"
                                data="diagram-arrow-right"
                              />
                            </BFButton>
                            {allowRevert && (
                              <BFButton
                                appearance="link"
                                noPadding
                                size="sm"
                                onClick={() =>
                                  distribute(
                                    "manualBooking",
                                    0,
                                    entryData.op < 0
                                      ? AccountingBookingType.SOLL
                                      : AccountingBookingType.HABEN,
                                    props.manualBookingPositions,
                                    relevantAccounts
                                  )
                                }
                                tooltip={{
                                  tooltip: i18n.t(
                                    "cb:OpenDebitPositions.OpenDebitPositions.RedoOpenAmount",
                                    "Beträge wieder zurücksetzen auf 0"
                                  ),
                                }}
                              >
                                <BfIcon size="xxs" type="light" data="undo" />
                              </BFButton>
                            )}
                          </div>
                        }
                        suffix={
                          <BFQuickSelect
                            value={valueType}
                            // disabled={values.length === 0}
                            onChange={(newType: AccountingBookingType) => {
                              if (newType !== valueType) {
                                let amount = entryData.bookIn;

                                if (valueType === AccountingBookingType.SOLL) {
                                  // now switched so, if amount is negative, negate it
                                  amount = amount < 0 ? -amount : amount;
                                }

                                distribute(
                                  "manualBooking",
                                  amount,
                                  newType,
                                  props.manualBookingPositions,
                                  relevantAccounts
                                );
                              }
                            }}
                            options={[
                              {
                                label: i18n.t("acc:SollShort", "S"),
                                value: "S",
                              },
                              {
                                label: i18n.t("acc:HabenShort", "H"),
                                value: "H",
                              },
                            ]}
                          />
                        }
                        removeSuffixPadding
                        textAlign="right"
                        value={Math.abs(entryData.bookIn)}
                        onChange={(value: number) => {
                          distribute(
                            "manualBooking",
                            props.openAmount
                              ? Math.min(
                                  Math.abs(value),
                                  Math.abs(props.openAmount)
                                )
                              : Math.abs(value),

                            valueType,
                            props.manualBookingPositions,
                            relevantAccounts
                          );
                        }}
                      />
                    </div>
                  )}
                />
              );
            },

            renderManualBooking: (
              manualBookingPosition: ManualBookingPosition
            ) => {
              const entryData =
                evaluatedData.manualValues[manualBookingPosition.account];
              const valueType =
                entryData.bookIn < 0
                  ? AccountingBookingType.SOLL
                  : AccountingBookingType.HABEN;
              const disableQuickAction = props.openAmount === 0;
              return (
                <div>
                  <BFInput
                    type="priceInput"
                    currencySuffix={StringUtils.getCurrencySymbol(
                      props.accountingData.accounting.data.currency
                    )}
                    removePrefixPadding
                    prefix={
                      <BFButton
                        appearance="link"
                        disabled={disableQuickAction}
                        size="xs"
                        onClick={() => {
                          distribute(
                            "manualBooking",
                            Math.abs(props.openAmount),
                            entryData.op < 0
                              ? AccountingBookingType.HABEN
                              : AccountingBookingType.SOLL,
                            [manualBookingPosition],
                            null
                          );
                        }}
                        tooltip={{
                          tooltip: i18n.t(
                            "cb:OpenDebitPositions.OpenDebitPositions.BalanceOpenAmount",
                            "Offenen Betrag ausgleichen"
                          ),
                        }}
                      >
                        <BfIcon
                          size="xxs"
                          type="light"
                          data="keyboard-arrow-right"
                        />
                      </BFButton>
                    }
                    suffix={
                      <BFQuickSelect
                        value={valueType}
                        onChange={(newType: AccountingBookingType) => {
                          if (newType !== valueType) {
                            let amount = entryData.bookIn;

                            if (valueType === AccountingBookingType.SOLL) {
                              // now switched so, if amount is negative, negate it
                              amount = amount < 0 ? -amount : amount;
                            }

                            distribute(
                              "manualBooking",
                              amount,
                              newType,
                              [manualBookingPosition],
                              null
                            );
                          }
                        }}
                        options={[
                          { label: i18n.t("acc:SollShort", "S"), value: "S" },
                          { label: i18n.t("acc:HabenShort", "H"), value: "H" },
                        ]}
                      />
                    }
                    removeSuffixPadding
                    textAlign="right"
                    value={Math.abs(entryData.bookIn)}
                    onChange={(value: number) => {
                      distribute(
                        "manualBooking",
                        props.openAmount
                          ? Math.min(
                              Math.abs(value),
                              Math.abs(props.openAmount)
                            )
                          : Math.abs(value),
                        entryData.op < 0
                          ? AccountingBookingType.HABEN
                          : AccountingBookingType.SOLL,
                        [manualBookingPosition],
                        null
                      );
                    }}
                  />
                </div>
              );
            },
          },
          ...(props.countbooking
            ? [
                {
                  label: i18n.t(
                    "cb:OpenDebitPositions.OpenDebitPositions.BookIn",
                    "Einbuchung"
                  ),

                  renderFooter: () => {
                    const sum =
                      evaluatedData.sums.bookOut + evaluatedData.sums.bookIn;

                    return (
                      <div
                        className={classNames("footer-value", {
                          negative: sum < 0,
                          positive: sum > 0,
                        })}
                      >
                        {i18n.t(
                          "cb:OpenDebitPositions.OpenDebitPositions.BookingOutstanding",
                          "Noch zu Buchen:"
                        )}{" "}
                        {StringUtils.formatCurrency(
                          sum,
                          undefined,
                          undefined,
                          props.accountingData.accounting.data.currency
                        )}
                      </div>
                    );
                  },
                  renderPosition: (pos: DebitPositionResult) => {
                    const entryData = evaluatedData.groupValues[pos._id];
                    const valueType =
                      entryData.bookOut >= 0
                        ? AccountingBookingType.HABEN
                        : AccountingBookingType.SOLL;
                    const allowRevert = entryData.bookOut !== 0;

                    // const disableQuickAction =
                    //   props.openAmount === 0 || entryData.open === 0;
                    const disableQuickAction = false;
                    const values = [];

                    const maxValue =
                      evaluatedData.sums.bookIn * -1 -
                      evaluatedData.sums.bookOut;
                    return (
                      <MultipleAssetLoaders
                        inline
                        assets={pos.bookings.map((b) => ({
                          assetType: AssetTypes.Accounting.Account,
                          assetId: b.data.contraAccount,
                        }))}
                        render={(relevantAccounts: AccountingAccount[]) => (
                          <div>
                            <BFInput
                              type="priceInput"
                              currencySuffix={StringUtils.getCurrencySymbol(
                                props.accountingData.accounting.data.currency
                              )}
                              removePrefixPadding
                              prefix={
                                <div className={`position-quick-actions`}>
                                  <BFButton
                                    appearance="link"
                                    disabled={disableQuickAction}
                                    noPadding
                                    size="sm"
                                    onClick={() => {
                                      distribute(
                                        "counterBooking",
                                        maxValue,
                                        entryData.op < 0
                                          ? AccountingBookingType.HABEN
                                          : AccountingBookingType.SOLL,
                                        pos.bookings,
                                        relevantAccounts
                                      );
                                    }}
                                    tooltip={{
                                      tooltip: i18n.t(
                                        "cb:OpenDebitPositions.OpenDebitPositions.DistributeOpenAmount",
                                        "Offenen Betrag auf den Positionen aufteilen"
                                      ),
                                    }}
                                  >
                                    <BfIcon
                                      size="xxs"
                                      type="light"
                                      data="diagram-arrow-right"
                                    />
                                  </BFButton>
                                  {allowRevert && (
                                    <BFButton
                                      appearance="link"
                                      noPadding
                                      size="sm"
                                      onClick={() => {
                                        distribute(
                                          "counterBooking",
                                          0,
                                          entryData.op < 0
                                            ? AccountingBookingType.HABEN
                                            : AccountingBookingType.SOLL,
                                          pos.bookings,
                                          relevantAccounts
                                        );
                                      }}
                                      tooltip={{
                                        tooltip: i18n.t(
                                          "cb:OpenDebitPositions.OpenDebitPositions.RedoOpenAmount",
                                          "Beträge wieder zurücksetzen auf 0"
                                        ),
                                      }}
                                    >
                                      <BfIcon
                                        size="xxs"
                                        type="light"
                                        data="undo"
                                      />
                                    </BFButton>
                                  )}
                                </div>
                              }
                              suffix={
                                <BFQuickSelect
                                  value={valueType}
                                  disabled={values.length === 0}
                                  onChange={(
                                    newType: AccountingBookingType
                                  ) => {
                                    if (newType !== valueType) {
                                      let amount = entryData.bookOut;

                                      if (
                                        valueType ===
                                        AccountingBookingType.HABEN
                                      ) {
                                        // now switched so, if amount is negative, negate it
                                        amount = amount < 0 ? -amount : amount;
                                      }

                                      distribute(
                                        "counterBooking",
                                        amount,
                                        newType,
                                        pos.bookings,
                                        relevantAccounts,
                                        props.showBalanced
                                      );
                                    }
                                  }}
                                  options={[
                                    {
                                      label: i18n.t("acc:SollShort", "S"),
                                      value: "S",
                                    },
                                    {
                                      label: i18n.t("acc:HabenShort", "H"),
                                      value: "H",
                                    },
                                  ]}
                                />
                              }
                              removeSuffixPadding
                              textAlign="right"
                              value={Math.abs(entryData.bookOut)}
                              onChange={(value: number) => {
                                distribute(
                                  "counterBooking",
                                  Math.min(Math.abs(maxValue), Math.abs(value)),
                                  valueType,
                                  pos.bookings,
                                  relevantAccounts
                                );
                              }}
                            />
                          </div>
                        )}
                      />
                    );
                  },
                  //todo change to einbuchung
                  renderBooking: (
                    booking: AccountingBooking,
                    pos: DebitPositionResult
                  ) => {
                    const entryData = evaluatedData.bookingValues[booking._id];

                    // const maxValue =
                    //   evaluatedData.sums.bookIn + evaluatedData.sums.bookOut;

                    const maxValue =
                      evaluatedData.sums.bookIn * -1 -
                      evaluatedData.sums.bookOut +
                      (entryData?.bookOut || 0);

                    const valueType =
                      (entryData?.bookOut || 0) >= 0
                        ? AccountingBookingType.HABEN
                        : AccountingBookingType.SOLL;
                    const disableQuickAction =
                      props.openAmount === 0 || maxValue === 0;

                    return (
                      <div>
                        <BFInput
                          type="priceInput"
                          currencySuffix={StringUtils.getCurrencySymbol(
                            props.accountingData.accounting.data.currency
                          )}
                          removePrefixPadding
                          prefix={
                            <BFButton
                              appearance="link"
                              disabled={disableQuickAction}
                              size="xs"
                              onClick={() => {
                                distribute(
                                  "counterBooking",
                                  Math.min(
                                    Math.abs(entryData.op),
                                    Math.abs(maxValue)
                                  ),
                                  valueType,
                                  [booking],
                                  null
                                );
                              }}
                              tooltip={{
                                tooltip: i18n.t(
                                  "cb:OpenDebitPositions.OpenDebitPositions.BalanceOpenAmount",
                                  "Offenen Betrag ausgleichen"
                                ),
                              }}
                            >
                              <BfIcon
                                size="xxs"
                                type="light"
                                data="keyboard-arrow-right"
                              />
                            </BFButton>
                          }
                          suffix={
                            <BFQuickSelect
                              value={valueType}
                              onChange={(newType: AccountingBookingType) => {
                                if (newType !== valueType) {
                                  let amount = entryData.bookOut;

                                  if (
                                    valueType === AccountingBookingType.HABEN
                                  ) {
                                    // now switched so, if amount is negative, negate it
                                    amount = amount < 0 ? -amount : amount;
                                  }
                                  distribute(
                                    "counterBooking",
                                    amount,
                                    newType,
                                    [booking],
                                    null,
                                    props.showBalanced
                                  );
                                }
                              }}
                              options={[
                                {
                                  label: i18n.t("acc:SollShort", "S"),
                                  value: "S",
                                },
                                {
                                  label: i18n.t("acc:HabenShort", "H"),
                                  value: "H",
                                },
                              ]}
                            />
                          }
                          removeSuffixPadding
                          textAlign="right"
                          value={Math.abs(entryData.bookOut)}
                          onChange={(value: number) => {
                            distribute(
                              "counterBooking",
                              Math.min(value, Math.abs(maxValue)),
                              valueType,
                              [booking],
                              null,
                              true
                            );
                          }}
                        />
                      </div>
                    );
                  },

                  //todo change to einbuchung
                  renderManualPosition: () => {
                    const entryData = evaluatedData.groupValues["null"];
                    const valueType =
                      (entryData?.bookOut || 0) >= 0
                        ? AccountingBookingType.HABEN
                        : AccountingBookingType.SOLL;

                    const maxValue =
                      evaluatedData.sums.bookOut +
                      evaluatedData.sums.bookIn -
                      (entryData?.bookOut || 0);
                    const disableQuickAction = maxValue === 0;
                    return (
                      <MultipleAssetLoaders
                        inline
                        assets={props.manualBookingPositions.map((b) => ({
                          assetType: AssetTypes.Accounting.Account,
                          assetId: b.account,
                        }))}
                        render={(relevantAccounts: AccountingAccount[]) => (
                          <div>
                            <BFInput
                              type="priceInput"
                              currencySuffix={StringUtils.getCurrencySymbol(
                                props.accountingData.accounting.data.currency
                              )}
                              removePrefixPadding
                              prefix={
                                <BFButton
                                  appearance="link"
                                  disabled={disableQuickAction}
                                  size="xs"
                                  onClick={() => {
                                    distribute(
                                      "manualCounterBooking",
                                      Math.abs(maxValue),
                                      (maxValue || 0) >= 0
                                        ? AccountingBookingType.HABEN
                                        : AccountingBookingType.SOLL,
                                      props.manualBookingPositions,
                                      relevantAccounts
                                    );
                                  }}
                                  tooltip={{
                                    tooltip: i18n.t(
                                      "cb:OpenDebitPositions.OpenDebitPositions.BalanceOpenAmount",
                                      "Offenen Betrag ausgleichen"
                                    ),
                                  }}
                                >
                                  <BfIcon
                                    size="xxs"
                                    type="light"
                                    data="diagram-arrow-right"
                                  />
                                </BFButton>
                              }
                              suffix={
                                <BFQuickSelect
                                  value={valueType}
                                  onChange={(val: AccountingBookingType) => {
                                    if (val !== valueType) {
                                      let amount = entryData.bookOut;

                                      if (
                                        valueType === AccountingBookingType.SOLL
                                      ) {
                                        // now switched so, if amount is negative, negate it
                                        amount = amount < 0 ? -amount : amount;
                                      }

                                      distribute(
                                        "manualCounterBooking",
                                        amount,
                                        amount >= 0
                                          ? AccountingBookingType.HABEN
                                          : AccountingBookingType.SOLL,
                                        props.manualBookingPositions,
                                        relevantAccounts
                                      );
                                    }
                                  }}
                                  options={[
                                    {
                                      label: i18n.t("acc:SollShort", "S"),
                                      value: "S",
                                    },
                                    {
                                      label: i18n.t("acc:HabenShort", "H"),
                                      value: "H",
                                    },
                                  ]}
                                />
                              }
                              removeSuffixPadding
                              textAlign="right"
                              value={Math.abs(entryData?.bookOut || 0)}
                              onChange={(value: number) => {
                                distribute(
                                  "manualCounterBooking",
                                  Math.min(Math.abs(value), Math.abs(maxValue)),
                                  valueType,
                                  props.manualBookingPositions,
                                  relevantAccounts
                                );
                              }}
                            />
                          </div>
                        )}
                      />
                    );
                  },

                  renderManualBooking: (
                    manualBookingPosition: ManualBookingPosition
                  ) => {
                    // const entryData =
                    //   evaluatedData.manualValues[manualBookingPosition.account];

                    const entryData =
                      evaluatedData.manualValues[manualBookingPosition.account];

                    // const maxValue =
                    //   evaluatedData.sums.bookIn + evaluatedData.sums.bookOut;

                    const maxValue =
                      evaluatedData.sums.bookIn * -1 -
                      evaluatedData.sums.bookOut +
                      (entryData?.bookOut || 0);

                    const valueType =
                      (entryData?.bookOut || 0) >= 0
                        ? AccountingBookingType.HABEN
                        : AccountingBookingType.SOLL;
                    const disableQuickAction =
                      props.openAmount === 0 || maxValue === 0;

                    return (
                      <div>
                        <BFInput
                          type="priceInput"
                          currencySuffix={StringUtils.getCurrencySymbol(
                            props.accountingData.accounting.data.currency
                          )}
                          removePrefixPadding
                          prefix={
                            <BFButton
                              appearance="link"
                              disabled={disableQuickAction}
                              size="xs"
                              onClick={() => {
                                distribute(
                                  "manualCounterBooking",
                                  Math.min(
                                    Math.abs(entryData.op),
                                    Math.abs(maxValue)
                                  ),
                                  valueType,
                                  [manualBookingPosition],
                                  null
                                );
                              }}
                              tooltip={{
                                tooltip: i18n.t(
                                  "cb:OpenDebitPositions.OpenDebitPositions.BalanceOpenAmount",
                                  "Offenen Betrag ausgleichen"
                                ),
                              }}
                            >
                              <BfIcon
                                size="xxs"
                                type="light"
                                data="keyboard-arrow-right"
                              />
                            </BFButton>
                          }
                          suffix={
                            <BFQuickSelect
                              value={valueType}
                              onChange={(newType: AccountingBookingType) => {
                                if (newType !== valueType) {
                                  let amount = entryData.bookOut;

                                  if (
                                    valueType === AccountingBookingType.SOLL
                                  ) {
                                    // now switched so, if amount is negative, negate it
                                    amount = amount < 0 ? -amount : amount;
                                  }

                                  distribute(
                                    "manualCounterBooking",
                                    amount,
                                    newType,
                                    [manualBookingPosition],
                                    null
                                  );
                                }
                              }}
                              options={[
                                {
                                  label: i18n.t("acc:SollShort", "S"),
                                  value: "S",
                                },
                                {
                                  label: i18n.t("acc:HabenShort", "H"),
                                  value: "H",
                                },
                              ]}
                            />
                          }
                          removeSuffixPadding
                          textAlign="right"
                          value={Math.abs(entryData.bookOut)}
                          onChange={(value: number) => {
                            distribute(
                              "manualCounterBooking",
                              Math.min(value, Math.abs(maxValue)),
                              valueType,
                              [manualBookingPosition],
                              null,
                              true
                            );
                          }}
                        />
                      </div>
                    );
                  },
                },
              ]
            : []),
        ]
      : []),
  ].filter((e) => e);

  const evaluatedData = evaluateCurrentData({
    account: props.account,
    manualBookingPositions: props.manualBookingPositions,
    accountingData: props.accountingData,
    positions: props.data,
    openAmount: props.openAmount,
    value: props.value,
  });

  const distribute = (
    type:
      | "booking"
      | "counterBooking"
      | "manualBooking"
      | "manualCounterBooking",
    amountToDistribute: number,
    bookingType: AccountingBookingType,
    bookings: AccountingBooking[] | ManualBookingPosition[] | null,
    relevantAccounts: AccountingAccount[],
    ignoreMax = false,
    divideCostsOnMonthlyCosts = false
  ) => {
    const isCounterBooking =
      type === "counterBooking" || type === "manualCounterBooking";
    const currency = props.accountingData.accounting.data.currency;

    let valuesWhichAreBooked: OpenDebitPositionBookingValue[] = [];
    let valuesWithoutBookings: OpenDebitPositionBookingValue[] = [];
    let bookingsSorted: AccountingBooking[] = [];

    if (type === "booking" || type === "counterBooking") {
      const bookingsToBook = bookings as AccountingBooking[];
      bookingsSorted = bookingsToBook
        ? getSortedBookings(bookingsToBook, relevantAccounts)
        : [];
      const bookingsSortedIds = bookingsSorted.map((e) => e._id);
      if (bookingsToBook === null) {
        bookingsSortedIds.push(null);
      }
      valuesWithoutBookings =
        props.value?.bookings?.filter(
          (e) => !bookingsSortedIds.includes(e.costId)
        ) || [];
      valuesWhichAreBooked =
        props.value?.bookings?.filter((e) =>
          bookingsSortedIds.includes(e.costId)
        ) || [];
    } else {
      const manualBookings = bookings as ManualBookingPosition[];
      const bookingsToBookIds = manualBookings.map((e) => e.account);
      valuesWhichAreBooked =
        props.value?.bookings?.filter(
          (e) =>
            hasValue(e.usedForId) && bookingsToBookIds.includes(e.usedForId)
        ) || [];
      valuesWithoutBookings =
        props.value?.bookings?.filter(
          (e) =>
            !(hasValue(e.usedForId) && bookingsToBookIds.includes(e.usedForId))
        ) || [];
    }

    let amountToDistributeNormalized = normalize(
      amountToDistribute,
      bookingType
    );
    const typeToSet =
      amountToDistributeNormalized < 0
        ? AccountingBookingType.SOLL
        : AccountingBookingType.HABEN;
    let restSum = Math.abs(amountToDistributeNormalized);

    // Initialize alteredValues with valuesWhichAreBooked setting amounts to 0.
    let alteredValues = valuesWhichAreBooked.map(
      (bookingValue) =>
        ({
          ...bookingValue,
          ...(isCounterBooking
            ? {
                counterBooking: {
                  value: { amount: 0, currency },
                  bookingType: typeToSet,
                },
              }
            : {
                bookingType: typeToSet,
                value: { amount: 0, currency },
              }),
        } as OpenDebitPositionBookingValue)
    );

    const updateAlteredValue = (
      bookingId: string | null,
      usedForId: string | null,
      contraAccount: string | null,
      distributeToBookingAmount: number,
      invertBookingType?: boolean
    ) => {
      const useTypeToSet = invertBookingType
        ? AccountingService.switchAccountingBookingType(typeToSet)
        : typeToSet;
      const existing = alteredValues.find(
        (e) =>
          e.costId === (bookingId || null) &&
          (hasValue(usedForId) ? e.usedForId === usedForId : true)
      );
      if (existing) {
        if (isCounterBooking) {
          existing.counterBooking = {
            value: {
              amount: NumberUtils.normalizeDecimal(distributeToBookingAmount),
              currency,
            },
            bookingType: useTypeToSet,
          };
        } else {
          existing.value = {
            amount: NumberUtils.normalizeDecimal(distributeToBookingAmount),
            currency,
          };
          existing.bookingType = useTypeToSet;
        }
      } else {
        alteredValues.push({
          costId: bookingId || null,
          costAccount: contraAccount || null,
          usedForId: usedForId || null,
          value: {
            amount: isCounterBooking
              ? 0
              : NumberUtils.normalizeDecimal(distributeToBookingAmount),
            currency,
          },
          bookingType: useTypeToSet,
          ...(isCounterBooking
            ? {
                counterBooking: {
                  value: {
                    amount: NumberUtils.normalizeDecimal(
                      distributeToBookingAmount
                    ),
                    currency,
                  },
                  bookingType: useTypeToSet,
                },
              }
            : {}),
        });
      }
    };

    const distributeManual = () => {
      const manualBookings = (bookings as ManualBookingPosition[]).map((e) => ({
        ...e,
        amount: 0,
      }));
      const bookingsToDo = manualBookings.filter((e) =>
        divideCostsOnMonthlyCosts ? e.monthlyCost : true
      );
      let i = 0;
      while (restSum > 0 && bookingsToDo.length > 0) {
        const toBook = bookingsToDo[i % bookingsToDo.length];
        const limit = hasValue(toBook.monthlyCost)
          ? toBook.monthlyCost
          : Number.MAX_SAFE_INTEGER;
        const amountToBook = Math.min(restSum, limit);
        restSum -= amountToBook;
        toBook.amount += amountToBook;
        i++;
      }
      bookingsToDo.forEach((booking) => {
        updateAlteredValue(
          null,
          booking.account,
          null,
          booking.amount,
          props.openAmount >= 0
        );
      });
    };

    const distributeRegular = () => {
      for (let i = 0; i < bookingsSorted.length && restSum > 0; i++) {
        const bookingToBook = bookingsSorted[i];
        const valuesOfBooking = evaluatedData.bookingValues[bookingToBook._id];
        const op = ignoreMax ? restSum : valuesOfBooking.op;
        const distributeToBookingAmount = Math.min(Math.abs(op), restSum);
        updateAlteredValue(
          bookingToBook?._id,
          null,
          bookingToBook.data?.contraAccount,
          distributeToBookingAmount
        );
        restSum -= distributeToBookingAmount;
      }
    };

    if (type === "manualBooking" || type === "manualCounterBooking") {
      distributeManual();
    } else {
      distributeRegular();
    }

    Log.info("DISTRIBUTION", {
      params: {
        amountToDistribute,
        bookingType,
        isCounterBooking,
      },
      result: {
        valuesWithoutBookings,
        alteredValues,
      },
    });
    props.onChange({
      ...props.value,
      bookings: [...valuesWithoutBookings, ...alteredValues],
    });
  };

  return (
    <StructLoader
      unitType={props.accountingData.accounting.data.type}
      structTypes={["objectKind", "orga"]}
      render={() => {
        return (
          <div
            className={classNames(`cb-rental-agreement-open-debit-positions`)}
          >
            <DebugDataComponent
              data={{
                date: props.data,
                value: props.value,
                openAmount: props.openAmount,
              }}
            />
            <table>
              <tr className={`head-row`}>
                {headers.map((e, index) => (
                  <th className={classNames(e.className)} key={index}>
                    {e.label}
                  </th>
                ))}
              </tr>

              <>
                <DebugDataComponent data={props.data} />
                {props.data.length === 0 && (
                  <tr>
                    <td
                      colSpan={headers.length}
                      className={`no-debit-position`}
                    >
                      {i18n.t(
                        "cb:OpenDebitPositions.OpenDebitPositions.NoOpenPositions",
                        "Keine offenen Sollstellungen"
                      )}
                    </td>
                  </tr>
                )}
                {props.data.map((pos) => (
                  <>
                    <tr className={`debit-position`}>
                      {headers.map((e, index) => {
                        return (
                          <td
                            className={classNames(e.className)}
                            style={{
                              width: e.fixedWith,
                            }}
                          >
                            {e.renderPosition(pos)}
                          </td>
                        );
                      })}
                    </tr>
                    {openState.includes(pos._id) &&
                      pos.bookings
                        // .map((booking) =>
                        //   AccountingService.getCorrectedBooking(
                        //     pos.data.account,
                        //     booking
                        //   )
                        // )
                        .map((booking, i) => {
                          return (
                            <tr
                              className={`debit-booking ${
                                i === pos.bookings.length - 1
                                  ? "last-booking"
                                  : ""
                              }`}
                            >
                              {headers.map((e, index) => {
                                return (
                                  <td className={classNames(e.className)}>
                                    {e.renderBooking(
                                      booking,
                                      pos,
                                      i,
                                      pos.bookings.length
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                  </>
                ))}

                {props.manualBookingPositions && (
                  <>
                    <tr>
                      {headers
                        .filter((e) => e.renderManualPosition)
                        .map((e, index) => {
                          return (
                            <td
                              className={classNames(e.className)}
                              colSpan={e.colSpanManualPosition}
                            >
                              {e.renderManualPosition()}
                            </td>
                          );
                        })}
                    </tr>

                    {openState.includes(NOT_CATEGORIZED_ID) &&
                      props.manualBookingPositions.map((booking, i) => {
                        return (
                          <tr
                            className={`debit-booking ${
                              i === props.manualBookingPositions.length - 1
                                ? "last-booking"
                                : ""
                            }`}
                          >
                            {headers.map((e, index) => {
                              return (
                                <td className={classNames(e.className)}>
                                  {e.renderManualBooking(booking)}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                  </>
                )}
                {editable && (
                  <tr className={`footer-row`}>
                    {headers.map((e, index) => {
                      return (
                        <td className={classNames(e.className)}>
                          {e.renderFooter?.() || <div></div>}
                        </td>
                      );
                    })}
                  </tr>
                )}
              </>
            </table>
            {props.validation?.type === "message" && (
              <div className={`margin-top-10`}>
                <BFMessage type="error" text={props.validation.message} />
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default CBRentalAgreementOpenDebitPositions;

type ValueObject = {
  op: number;
  open: number;
  bookIn: number;
  bookOut: number;
};
const normalize = (amount: number, bookingType: AccountingBookingType) => {
  return bookingType === "S" ? amount * -1 : amount;
};

const evaluateCurrentData = (props: {
  positions: DebitPositionResult[];
  manualBookingPositions?: ManualBookingPosition[];
  accountingData: AccountingData;
  account: string;
  openAmount?: number;
  value?: OpenDebitPositionValue;
}) => {
  const result: {
    manualValues: {
      [manualBookingId: string]: ValueObject;
    };
    bookingValues: {
      [bookingId: string]: ValueObject;
    };
    groupValues: {
      [groupId: string]: ValueObject;
    };
    sums: {
      bookIn: number;
      bookOut: number;
    };
  } = {
    manualValues: {},
    bookingValues: {},
    groupValues: {
      null: {
        op: 0,
        open: 0,
        bookIn: 0,
        bookOut: 0,
      },
    },
    sums: {
      bookIn: 0,
      bookOut: 0,
    },
  };

  props.positions?.forEach((pos) => {
    const positionObject: ValueObject = {
      op: 0,
      open: 0,
      bookIn: 0,
      bookOut: 0,
    };

    pos.bookings
      // .map((booking) =>
      //   AccountingService.getCorrectedBooking(props.account, booking)
      // )
      .forEach((booking) => {
        const valuesForBooking =
          props.value?.bookings?.filter((e) => e.costId === booking._id) || [];

        const contraBookingsForBooking = pos.contraBookings
          // .map((e) => AccountingService.getCorrectedBooking(props.account, e))
          .filter((e) => e.data.costId === booking._id);

        const sumOfContraBookings = _.sumBy(contraBookingsForBooking, (e) =>
          normalize(e.data.value.converted.amount, e.data.bookingType)
        );

        const bookIn = _.sumBy(valuesForBooking, (value) =>
          normalize(value.value?.amount || 0, value.bookingType)
        );
        const bookOut = _.sumBy(valuesForBooking, (value) =>
          normalize(
            value.counterBooking?.value?.amount || 0,
            value.counterBooking?.bookingType
          )
        );

        const original = normalize(
          booking.data.value.converted.amount,
          booking.data.bookingType
        );

        const op = original + sumOfContraBookings;

        result.bookingValues[booking._id] = {
          op: NumberUtils.normalizeDecimal(op),
          open: NumberUtils.normalizeDecimal(op + bookIn),
          bookIn: NumberUtils.normalizeDecimal(bookIn),
          bookOut: NumberUtils.normalizeDecimal(bookOut),
        };

        positionObject.op += op;
        positionObject.bookIn += bookIn;
        positionObject.bookOut += bookOut;
        positionObject.open += op + bookIn;

        result.sums.bookIn += bookIn;
        result.sums.bookOut += bookOut;
      });

    result.groupValues[pos._id] = {
      op: NumberUtils.normalizeDecimal(positionObject.op),
      open: NumberUtils.normalizeDecimal(positionObject.open),
      bookIn: NumberUtils.normalizeDecimal(positionObject.bookIn),
      bookOut: NumberUtils.normalizeDecimal(positionObject.bookOut),
    };
  });

  props.manualBookingPositions?.forEach((pos) => {
    const otherBooking = props.value?.bookings?.find(
      (e) => e.costId === null && pos.account === e.usedForId
    );
    if (otherBooking) {
      const bookIn = NumberUtils.normalizeDecimal(
        normalize(otherBooking.value.amount, otherBooking.bookingType)
      );
      const bookOut = otherBooking.counterBooking
        ? NumberUtils.normalizeDecimal(
            normalize(
              otherBooking.counterBooking?.value?.amount || 0,
              otherBooking.counterBooking?.bookingType
            )
          )
        : 0;

      result.manualValues[pos.account] = {
        op: 0,
        open: 0,
        bookIn,
        bookOut,
      };
      result.groupValues["null"].bookIn += bookIn;
      result.groupValues["null"].bookOut += bookOut;
      result.sums.bookIn += bookIn;
      result.sums.bookOut += bookOut;
    } else {
      result.manualValues[pos.account] = {
        op: 0,
        open: 0,
        bookIn: 0,
        bookOut: 0,
      };
    }
  });

  return result;
};

const getSortedBookings = (
  bookings: AccountingBooking[],
  relevantAccounts: AccountingAccount[]
  // accountingData: AccountingData
) => {
  const bookingsSorted = [...bookings].sort((a, b) => {
    const accA = (relevantAccounts || []).find(
      (e) => e._id === a.data.contraAccount
    );
    const accB = (relevantAccounts || []).find(
      (e) => e._id === b.data.contraAccount
    );

    return (
      (accA.data.priority || Number.MAX_SAFE_INTEGER) -
      (accB.data.priority || Number.MAX_SAFE_INTEGER)
    );
  });
  return bookingsSorted;
};

import "./PDFTemplatesManager.scss";

import BFButton from "@/modules/abstract-ui/general/Button/BFButton";

import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import {
  deleteTemplate,
  updateTemplateName,
} from "../../actions/templateActions";
import i18n from "@/i18n";
import ModalManager from "@/components/ModalComponent/ModalManager";
import { PDFTemplatesSaver } from "./PDFTemplatesSaver";

type PDFTemplateProps = {
  id: string;
  displayName: string;

  onDelete: () => Promise<void> | void;
  onEdit: () => Promise<void> | void;
};

const PDFTemplate: React.FC<PDFTemplateProps> = ({
  id,
  displayName,
  onDelete,
  onEdit,
}) => {
  return (
    <li key={id}>
      <p className="pdf-constructor--templates-manager--template-name">
        {displayName}
      </p>

      <div className="template-actions">
        <>
          <BFButton
            appearance="outline"
            onClick={() =>
              ModalManager.show({
                size: "fluid",
                noPadding: true,
                closable: true,
                content: (_, __, close) => (
                  <PDFTemplatesSaver
                    defaultContent={displayName}
                    onSave={async (content: string) => {
                      await updateTemplateName(id, content);
                      await onEdit();
                      close();
                    }}
                  />
                ),
              })
            }
          >
            <BfIcon type="light" data="content-pen" size="xxs" />
          </BFButton>
          <BFButton
            appearance="outline"
            onClick={async () => {
              await deleteTemplate(id);
              onDelete();
            }}
          >
            <BfIcon type="light" data="bin-2" size="xxs" />
          </BFButton>
        </>
      </div>
    </li>
  );
};

type PDFTemplatesManagerProps = {
  data: {
    templates: {
      _id: string;
      displayName: string;
    }[];
  };
  onChange: () => Promise<void> | void;
};

const PDFTemplatesManager: React.FC<PDFTemplatesManagerProps> = ({
  data,
  onChange,
}) => {
  return (
    <div className="pdf-constructor--templates-manager">
      <h5>
        {i18n.t(
          "Component.PDFConstructor.Template.TemplateList",
          "Vorlagenliste"
        )}
      </h5>

      {data?.templates.length > 0 && (
        <ul>
          {data?.templates?.map((template) => (
            <PDFTemplate
              key={template._id}
              id={template._id}
              displayName={template.displayName}
              onDelete={async () => {
                await onChange();
              }}
              onEdit={async () => {
                await onChange();
              }}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export { PDFTemplatesManager };

import { createPortal } from "react-dom";

interface Props {
  container?: HTMLElement;
  children: React.ReactNode;
}
const BFPortal = (props: Props) => {
  const domNode = props.container ? props.container : document.body;

  return createPortal(props.children, domNode);
};
export default BFPortal;

import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import { BaseStyle, TableStyle } from "../../services/types/pdfConfigUtilTypes";
import "./PDFCommonInput.scss";

import BfIcon from "@/modules/abstract-ui/icon/BfIcon";

type Style = Partial<BaseStyle & TableStyle>;

type PDFCommonInputProps = {
  value: Style;
  onChange: <Key extends keyof Style>(key: Key, value: Style[Key]) => void;
};

const PDFCommonInput: React.FC<PDFCommonInputProps> = ({ value, onChange }) => (
  <div className="pdf-common-input">
    <h5>Margins</h5>

    <div className="margin-input">
      <BFInput
        prefix={<BfIcon type="light" data="arrow-up" size="xxs" />}
        suffix="px"
        type="number"
        value={value.marginTop}
        onChange={(value) => onChange("marginTop", Number(value))}
        step={1}
        min={0}
        textAlign="center"
      />

      <BFInput
        prefix={<BfIcon type="light" data="arrow-right" size="xxs" />}
        suffix="px"
        type="number"
        value={value.marginRight}
        onChange={(value) => onChange("marginRight", Number(value))}
        step={1}
        min={0}
        textAlign="center"
      />

      <BFInput
        prefix={<BfIcon type="light" data="arrow-down" size="xxs" />}
        suffix="px"
        type="number"
        value={value.marginBottom}
        onChange={(value) => onChange("marginBottom", Number(value))}
        step={1}
        min={0}
        textAlign="center"
      />

      <BFInput
        prefix={<BfIcon type="light" data="arrow-left" size="xxs" />}
        suffix="px"
        type="number"
        value={value.marginLeft}
        onChange={(value) => onChange("marginLeft", Number(value))}
        step={1}
        min={0}
        textAlign="center"
      />
    </div>
  </div>
);

export { PDFCommonInput };

import classNames from "classnames";
import { Tag } from "rsuite";
import UserAvatar from "../../../../components/AvatarComponent/UserAvatar";
import Username from "../../../../components/AvatarComponent/Username";
import i18n from "../../../../i18n";
import { BFAssignmentSearch } from "../../../abstract-ui/forms/assignment/BFAssignmentSearch";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import "./EZAssignment.scss";

interface EZAssignmentProps {
  block?: boolean;
  className?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: (ev, value: string) => void;

  error?: string;
  disabled?: boolean;
  identifier?: string;
  type: "user" | "team" | "both";
  teamContexts?: string[];
  readonly?: boolean;
}
const EZAssignment = (props: EZAssignmentProps) => {
  const renderValue = () => {
    return (
      <>
        {props.value && (
          <div className={`user-entry`}>
            <UserAvatar id={props.value} size={20} />
            <Username id={props.value} />
          </div>
        )}
        {!props.value && (
          <div className={`not-assigned`}>
            {i18n.t("EZAssignment.not-assigned", "Nicht zugeordnet")}
          </div>
        )}
      </>
    );
  };

  if (props.readonly) {
    return (
      <div className={classNames(`ez-assignment`, { block: props.block })}>
        <Tag className={`readonly-tag`} size="lg">
          {renderValue()}
        </Tag>
      </div>
    );
  }

  return (
    <div className={classNames(`ez-assignment`, { block: props.block })}>
      <BFAssignmentSearch
        identifier={props.identifier}
        teamContexts={props.teamContexts}
        text={"test"}
        type={props.type}
        onSelect={(assignment) => {
          props.onChange(assignment.id);
          props.onBlur(null, assignment.id);
        }}
        value={props.value ? [props.value] : []}
        renderToggle={(toggleProps, ref) => (
          <BFButton {...toggleProps} disabled={props.disabled}>
            {renderValue()}
          </BFButton>
        )}
        onlyActive={true}
      />
    </div>
  );
};

export default EZAssignment;

import { RiskScenario } from "@/apps/tatar/cashBudget/views/portfolio/object/cards/risk-scenario/RiskScenarioInterfaces";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import PermissionService from "@/services/PermissionService";
import MQ from "@/utils/MatchQueryUtils";
import { Field } from "react-final-form";
import { OAObject } from "../../types/object.interface";
import {
  ObjectKind,
  checkAnyFeature,
  checkFeature,
} from "../../types/objectKind.interface";
import "./ObjectExportDialog.scss";
import ObjectExportService from "./ObjectExportService";

interface ObjectExportDialogProps {
  onClose: () => void;
  title: string;
  referenceId: string;
  assets: OAObject[];
  kind: ObjectKind;
}
const ObjectExportDialog = (props: ObjectExportDialogProps) => {
  const assetValue =
    checkFeature("assetValue", props.kind) &&
    PermissionService.hasObjectKindPermission(props.kind, "assetValue.view");

  const rentalData = checkAnyFeature(["immo"], props.kind);
  const purchasePrice = checkAnyFeature(["purchasePrice"], props.kind);
  const loanable =
    checkAnyFeature(["loanable"], props.kind) &&
    PermissionService.hasObjectKindPermission(props.kind, "loanable.view");

  const riskManagement =
    checkFeature("immo", props.kind) &&
    PermissionService.hasObjectKindPermission(
      props.kind,
      "risk-management.view"
    );
  return (
    <FormStruct<any>
      className={`object-export-dialog`}
      title={props.title}
      description={i18n.t(
        "cb:Portfolio.Objekt.exportDescription",
        "Wollen Sie dieses Asset exportieren? Geben Sie an, welche Daten Sie zusätzlich im Export angezeigt haben wollen."
      )}
      initialValues={{
        assetValue: assetValue ? true : false,
        rentalData: rentalData ? true : false,
        loanable: loanable ? true : false,
        loanableOpts: {
          showGraph: true,
        },
        riskManagement: riskManagement ? true : false,
        purchasePrice: purchasePrice ? true : false,
        rentalDataOpts: {
          showStackingPlan: true,
          showRentalIncomeGraph: true,
          rentNetPlan: true,
          rentNetCurrent: true,
          rentGrossPlan: true,
          rentGrossCurrent: true,
          vacancyPage: true,
        },
      }}
      onAbort={props.onClose}
      onSubmit={async (values) => {
        await ObjectExportService.exportObject(
          props.assets,
          props.title,
          props.referenceId,
          props.kind,
          {
            assetValue: values.assetValue,
            rentalData: values.rentalData,
            loanable: values.loanable,
            riskScenarios: values.riskManagement ? values.riskScenarios : [],
            purchasePrice: values.purchasePrice,
            rentalDataOpts: values.rentalDataOpts,
            loanableOpts: values.loanableOpts,
          }
        );

        // props.onClose();
      }}
      render={(formProps) => (
        <>
          {assetValue && (
            <Field name="assetValue">
              {({ input, meta }) => (
                <div className="__field">
                  <BFCheckbox
                    checked={input.value}
                    onChange={(_, checked) => input.onChange(checked)}
                  >
                    {i18n.t(
                      "cb:Portfolio.Objekt.export.assetValue",
                      "Verkehrswert exportieren"
                    )}
                  </BFCheckbox>
                </div>
              )}
            </Field>
          )}
          {purchasePrice && (
            <Field name="purchasePrice">
              {({ input, meta }) => (
                <div className="__field">
                  <BFCheckbox
                    checked={input.value}
                    onChange={(_, checked) => input.onChange(checked)}
                  >
                    {i18n.t(
                      "cb:Portfolio.Objekt.export.purchasePrice",
                      "Kaufpreis exportieren"
                    )}
                  </BFCheckbox>
                </div>
              )}
            </Field>
          )}
          {rentalData && (
            <Field name="rentalData">
              {({ input, meta }) => (
                <>
                  <div className="__field">
                    <BFCheckbox
                      checked={input.value}
                      onChange={(_, checked) => input.onChange(checked)}
                    >
                      {i18n.t(
                        "cb:Portfolio.Objekt.export.rentalData",
                        "Vermierungsdaten exportieren"
                      )}
                    </BFCheckbox>
                  </div>

                  {input.value && (
                    <>
                      <div className={`sub-title`}>
                        {i18n.t(
                          "cb:Portfolio.Objekt.export.selectData",
                          "Vermietungsdaten auswählen"
                        )}
                      </div>
                      <div className={`sub-list`}>
                        {props.assets.length === 1 && (
                          <Field name="rentalDataOpts.showStackingPlan">
                            {({ input, meta }) => (
                              <div className="sub-field">
                                <BFCheckbox
                                  checked={input.value}
                                  onChange={(_, checked) =>
                                    input.onChange(checked)
                                  }
                                >
                                  {i18n.t(
                                    "cb:Portfolio.Objekt.export.showStackingPlan",
                                    "Zeige Stackingplan an"
                                  )}
                                </BFCheckbox>
                              </div>
                            )}
                          </Field>
                        )}
                        <Field name="rentalDataOpts.showRentalIncomeGraph">
                          {({ input, meta }) => (
                            <div className="sub-field">
                              <BFCheckbox
                                checked={input.value}
                                onChange={(_, checked) =>
                                  input.onChange(checked)
                                }
                              >
                                {i18n.t(
                                  "cb:Portfolio.Objekt.export.showRentalIncomeGraph",
                                  "Zeige Einkommensgrafik an"
                                )}
                              </BFCheckbox>
                            </div>
                          )}
                        </Field>
                        <Field name="rentalDataOpts.rentNetPlan">
                          {({ input, meta }) => (
                            <div className="sub-field">
                              <BFCheckbox
                                checked={input.value}
                                onChange={(_, checked) =>
                                  input.onChange(checked)
                                }
                              >
                                {i18n.t(
                                  "cb:Portfolio.Objekt.export.rentNetPlan",
                                  "NKM Plan"
                                )}
                              </BFCheckbox>
                            </div>
                          )}
                        </Field>
                        <Field name="rentalDataOpts.rentNetCurrent">
                          {({ input, meta }) => (
                            <div className="sub-field">
                              <BFCheckbox
                                checked={input.value}
                                onChange={(_, checked) =>
                                  input.onChange(checked)
                                }
                              >
                                {i18n.t(
                                  "cb:Portfolio.Objekt.export.rentNetCurrent",
                                  "NKM Ist"
                                )}
                              </BFCheckbox>
                            </div>
                          )}
                        </Field>
                        <Field name="rentalDataOpts.rentGrossPlan">
                          {({ input, meta }) => (
                            <div className="sub-field">
                              <BFCheckbox
                                checked={input.value}
                                onChange={(_, checked) =>
                                  input.onChange(checked)
                                }
                              >
                                {i18n.t(
                                  "cb:Portfolio.Objekt.export.rentGrossPlan",
                                  "Miete Brutto Plan"
                                )}
                              </BFCheckbox>
                            </div>
                          )}
                        </Field>
                        <Field name="rentalDataOpts.rentGrossCurrent">
                          {({ input, meta }) => (
                            <div className="sub-field">
                              <BFCheckbox
                                checked={input.value}
                                onChange={(_, checked) =>
                                  input.onChange(checked)
                                }
                              >
                                {i18n.t(
                                  "cb:Portfolio.Objekt.export.rentGrossCurrent",
                                  "Miete Brutto Ist"
                                )}
                              </BFCheckbox>
                            </div>
                          )}
                        </Field>
                        <Field name="rentalDataOpts.vacancyPage">
                          {({ input, meta }) => (
                            <div className="sub-field">
                              <BFCheckbox
                                checked={input.value}
                                onChange={(_, checked) =>
                                  input.onChange(checked)
                                }
                              >
                                {i18n.t(
                                  "cb:Portfolio.Objekt.export.vacancyPage",
                                  "Leerstands Statistik"
                                )}
                              </BFCheckbox>
                            </div>
                          )}
                        </Field>
                      </div>
                    </>
                  )}
                </>
              )}
            </Field>
          )}
          {loanable && (
            <Field name="loanable">
              {({ input, meta }) => (
                <>
                  <div className="__field">
                    <BFCheckbox
                      checked={input.value}
                      onChange={(_, checked) => input.onChange(checked)}
                    >
                      {i18n.t(
                        "cb:Portfolio.Objekt.export.loanable",
                        "Darlehen exportieren"
                      )}
                    </BFCheckbox>
                  </div>

                  {input.value && (
                    <>
                      <div className={`sub-title`}>
                        {i18n.t(
                          "cb:Portfolio.Objekt.export.selectLoanableOpts",
                          "Darlehen Optionen"
                        )}
                      </div>
                      <div className={`sub-list`}>
                        <Field name="loanableOpts.showGraph">
                          {({ input, meta }) => (
                            <div className="sub-field">
                              <BFCheckbox
                                checked={input.value}
                                onChange={(_, checked) =>
                                  input.onChange(checked)
                                }
                              >
                                {i18n.t(
                                  "cb:Portfolio.Objekt.export.showGraph",
                                  "Graph einblenden"
                                )}
                              </BFCheckbox>
                            </div>
                          )}
                        </Field>
                      </div>
                    </>
                  )}
                </>
              )}
            </Field>
          )}
          {riskManagement && (
            <Field name="riskManagement">
              {({ input, meta }) => (
                <>
                  <div className="__field">
                    <BFCheckbox
                      checked={input.value}
                      onChange={(_, checked) => input.onChange(checked)}
                    >
                      {i18n.t(
                        "cb:Portfolio.Objekt.export.riskManagement",
                        "Risikomanagement exportieren"
                      )}
                    </BFCheckbox>
                  </div>

                  {input.value && (
                    <Field
                      name="riskScenarios"
                      validate={FV.compose(FV.required(), FV.min(1))}
                    >
                      {({ input, meta }) => (
                        <>
                          <div className={`sub-title`}>
                            {i18n.t(
                              "cb:Portfolio.Objekt.export.selectScenarios",
                              "Risikoszenarien auswählen"
                            )}
                          </div>
                          <div className={`sub-list`}>
                            <ListComponent
                              identifier="export-risk-management"
                              cleanupOnUnmount
                              assetType={AssetTypes.Portfolio.RiskScenario}
                              additionalMatchQuery={MQ.and(
                                MQ.eq("data.referenceId", props.referenceId)
                              )}
                              asPost
                              render={(risk: RiskScenario) => (
                                <div className={`risk-entry`}>
                                  <BFCheckbox
                                    checked={(input.value || [])
                                      .map((e) => e._id)
                                      .includes(risk._id)}
                                    onChange={(_, checked) => {
                                      if (checked) {
                                        input.onChange([
                                          ...(input.value || []),
                                          risk,
                                        ]);
                                      } else {
                                        input.onChange(
                                          (input.value || []).filter(
                                            (valEntry) =>
                                              valEntry._id !== risk._id
                                          )
                                        );
                                      }
                                    }}
                                  >
                                    <div className={`risk-container`}>
                                      <div className={`name`}>
                                        {risk.data.name}
                                      </div>
                                      {risk.data.description && (
                                        <div className={`description`}>
                                          {risk.data.description}
                                        </div>
                                      )}
                                    </div>
                                  </BFCheckbox>
                                </div>
                              )}
                            />
                          </div>
                          {meta.error && meta.touched && (
                            <div className={`risk-management-error`}>
                              {i18n.t(
                                "cb:Portfolio.Objekt.export.errorSelectScenarios",
                                "Wählen Sie mindestens ein Risiko-Szenario aus"
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  )}
                </>
              )}
            </Field>
          )}
        </>
      )}
    />
  );
};

export default ObjectExportDialog;

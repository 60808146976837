import {
  FieldCustomField,
  SupplyUnitCategoryGroup,
} from "../../../../apps/tatar/cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import LanguageService from "../../../../services/LanguageService";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import {
  AbstractStructSelectors,
  DataByUnitType,
} from "../AbstractStructSelectors";

class SupplyUnitStructClass extends AbstractStructSelectors<
  SupplyUnitCategoryGroup[]
> {
  getStructType(): StructType {
    return "supplyUnit";
  }
  loadStructData(
    units: string[]
  ): Promise<DataByUnitType<SupplyUnitCategoryGroup[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: `/supplyContract/getSupplyTechnicalUnitGroupsStruct`,
          target: "EMPTY",
          queryParams: {
            param: {
              types: units,
            },
          },
        })) as SupplyUnitCategoryGroup[];

        const result: DataByUnitType<SupplyUnitCategoryGroup[]> = {};
        units.forEach((unit) => {
          result[unit] = data.filter((tucg) => tucg.data.type === unit);
        });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }
  getById(id: string) {
    return this.useCache<SupplyUnitCategoryGroup>("getById", arguments, () => {
      return this.getAllData()
        .flat()
        .find((tucg) => tucg._id === id);
    });
  }
  getCategories(types?: string[]) {
    return this.useCache<{ label: string; value: string }[]>(
      "getCategories",
      arguments,
      () => {
        return this.getAllData()
          .flat()
          .filter((cat) => !types || types.includes(cat.data.type))
          .map((cat) => ({
            label: LanguageService.translateLabel(cat.data.displayName),
            value: cat._id,
          }));
      }
    );
  }

  getSelectOptions(unit: string, addSubCategories = false) {
    return this.useCache<{ value: string; label: string }[]>(
      "getSelectOptions",
      arguments,
      () => {
        const output: {
          value: string;
          label: string;
        }[] = [];

        (this.getData(unit) || []).forEach((categoryGroup) => {
          output.push({
            value: categoryGroup._id,
            label: LanguageService.translateLabel(
              categoryGroup.data.displayName
            ),
          });
        });

        return output;
      }
    );
  }

  getCustomFields(categoryId: string, showAll = false): FieldCustomField[] {
    return this.useCache<{}[]>("getCustomFields", arguments, () => {
      const customFields: FieldCustomField[] = [];
      const category = this.getCategory(categoryId);
      if (category) {
        if (category.data.fields?.length > 0) {
          customFields.push(...category.data.fields);
        }
      }
      return customFields.filter((e) =>
        showAll ? true : e.status !== "archived"
      );
    }) as FieldCustomField[];
  }
  getMeasurementUnits(categoryId: string, showAll = false): string[] {
    return this.useCache<{}[]>("getMeasurementUnits", arguments, () => {
      const units: string[] = [];
      const category = this.getCategory(categoryId);
      if (category) {
        if (category.data.units?.length > 0) {
          units.push(...category.data.units);
        }
      }
      return units;
    }) as string[];
  }

  getCategory(categoryId: string) {
    return this.useCache<SupplyUnitCategoryGroup>(
      "getCategory",
      arguments,
      () => {
        const allData = this.getAllData();
        for (const data of allData) {
          for (const categoryGroup of data) {
            if (categoryGroup._id === categoryId) {
              return categoryGroup;
            }
          }
        }
        return null;
      }
    );
  }
}
const SupplyUnitStruct = new SupplyUnitStructClass();
export default SupplyUnitStruct;

import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import { requestListData } from "../../../services/DataService";
import {
	AbstractStylableComponent,
	AbstractStylableProps,
	AbstractStylableStates
} from "../../../utils/abstracts/AbstractStylableComponent";

type Props = {
	_route: string;
} & AbstractStylableProps &
	WithTranslation &
	RouteComponentProps;

type States = {} & AbstractStylableStates;

class RedirectComponent extends AbstractStylableComponent<Props, States> {
	static defaultProps = {};
	readonly state: States = {};

	render() {
		const { _route } = this.props;

		return <Redirect to={this.evaluateExpression(_route, { match: this.props.match })} />;
	}
}

const mapStateToProps = (state: AppState, props: Props) => ({
	viewportWidth: Array.isArray(props.style) ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH] : null
});

export default withRouter(connect(mapStateToProps, { requestListData })(withTranslation()(RedirectComponent)));

import { useDatabus } from "@/redux/hooks";
import { isNotDefined } from "@/utils/Helpers";
import "./BSComponent.scss";

interface BSComponentProps {}
const BSComponent = (props: BSComponentProps) => {
  useDatabus("BS", (data: { class: string; active: boolean }) => {
    if (
      (isNotDefined(data.active) || data.active) &&
      !document.body.classList.contains(data.class)
    ) {
      document.body.classList.add(data.class);
    } else {
      document.body.classList.remove(data.class);
    }
  });

  return null;
};

export default BSComponent;

import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import UAParser from "ua-parser-js";
import ActionComponent from "../../../configurable/components/ActionComponent/ActionComponent";
import Log from "../../../debug/Log";
import { AppState } from "../../../redux/store";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import GenericForms, { FormDefinition } from "../../generic-forms/GenericForms";
import { addTrustedDevice } from "./../../../redux/actions/global/global-actions";
import "./RegisterPinLoginForm.scss";
import { TrustedDevicesTypes } from "./TrustedDevicesTypes";

type Props = {
  formDefinition: FormDefinition;
  onCancel: () => void;
  onFinish: () => void;
  device: UAParser.IResult;
  addTrustedDevice: (
    deviceName: string,
    pin: string,
    appName: string,
    onSuccess: () => void,
    onError: (err: any) => void
  ) => void;
} & RouteComponentProps &
  WithTranslation;

type States = {
  submitState: "idle" | "loading" | "error" | "success";
};

const actionIDSubmit = "regisgter-pin-login-submit";
class RegisterPinLoginForm extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    submitState: "idle",
  };

  onFormSubmit(data) {
    const { submitState } = this.state;
    const { addTrustedDevice, onFinish } = this.props;
    if (submitState === "loading") return null;

    return new Promise((resolve, reject) => {
      this.setState({ submitState: "loading" });
      const { deviceName, pin, appName } = data;
      addTrustedDevice(
        deviceName,
        pin,
        appName,
        () => {
          this.setState({ submitState: "success" }, () => {
            resolve(null);
            onFinish();
          });
        },
        (err) => {
          this.setState({ submitState: "error" }, () => reject());
        }
      );
    });
  }

  render() {
    const { submitState } = this.state;
    const { t, onCancel, device } = this.props;

    const deviceName = `${
      device.browser.name ? device.browser.name + ", " : ""
    }${device.os.name ? device.os.name + " " : ""}${
      device.os.version ? device.os.version : ""
    }`;
    return (
      <div className={`register-pin-login-form`}>
        <div className={`title`}>
          {t("UserModal.Devices.RegisterPin.Title")}
        </div>
        <GenericForms
          translateFunc={this.props.i18n.t}
          formValue={{
            deviceName,
            appName: TrustedDevicesTypes.PIN,
          }}
          actionIdMapping={{
            submit: actionIDSubmit,
          }}
          formDefinition={this.props.formDefinition}
          onFormSubmit={(data) => {
            return this.onFormSubmit(data);
          }}
          onFormCancel={() => Log.debug("canceled")}
        />

        <div className="action-row">
          <BFButton
            textKey={"Global.Buttons.cancel"}
            onClick={() => onCancel()}
          />
          <div className="fill" />
          <ActionComponent
            loading={submitState === "loading"}
            actionId={actionIDSubmit}
            appearance={"primary"}
            textKey={"UserModal.Devices.RegisterPin.SubmitButton"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  formDefinition: state.global.config.standardForms["registerPinLogin"],
  device: state.uiConfig.device,
});

export default connect(mapStateToProps, { addTrustedDevice })(
  withRouter(withTranslation()(RegisterPinLoginForm))
) as any;

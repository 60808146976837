import RAInvoiceDetailsComponent from "@/apps/tatar/invoiceApp/components/InvoiceDetails/RAInvoiceDetailsComponent";
import {
  getIncomingInvoiceColumns,
  RA_INVOICE_STATIC_SELECTORS,
} from "@/apps/tatar/invoiceApp/components/ListInvoices/RAInvoicesList";
import {
  InvoiceDirection,
  RAInvoice,
} from "@/apps/tatar/invoiceApp/RAInterfaces";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import PersistentSplitPane from "@/configurable/data/SplitPane/PersistentSplitPane";
import VirtualizedTable from "@/configurable/data/VirtualizedTable/VirtualizedTable";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact } from "@/model/db/Contact";
import BFSearch from "@/modules/abstract-ui/forms/input/BFSearch";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import BFNav from "@/modules/abstract-ui/navigation/BFNav/BFNav";
import InfiniteTable from "@/redux/actions/application/application-infinite-table-actions";
import { useAggregationStatisticQuery, useTypedSelector } from "@/redux/hooks";
import { MatchQuery } from "@/services/DataService";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Nav } from "rsuite";
import ContactInvoiceKPIs from "./ContactInvoiceKPIs";
import {
  InvoiceFilterType,
  matchQueryOfFilterType,
} from "./ContactInvoiceUtils";
import "./ContactInvoiceView.scss";

interface ContactInvoiceViewProps {
  contact: Contact;
}

const TABLE_IDENTIFIER = "contact-invoice-list";
const ContactInvoiceView = (props: ContactInvoiceViewProps) => {
  const [activeDirection, setActiveDirection] =
    useState<InvoiceDirection>("INCOMING");
  const [statusFilter, setStatusFilter] = useState<InvoiceFilterType>("open");

  const matchquery = MQ.and(
    MQ.eq("data.invoice.contact", props.contact._id),
    MQ.eq("data.direction", activeDirection),
    matchQueryOfFilterType(statusFilter)
  );

  return (
    <div className={classNames(`contact-invoice-view`)}>
      <div className={`invoice-filter`}>
        <div className={`border-top-overlay`} />
        <div className={`search-container fill`}>
          <div>
            <BFSearch tableIdentifier={TABLE_IDENTIFIER} />
          </div>
        </div>

        <BFNav
          className={`tab-bar-nav`}
          activeKey={activeDirection}
          appearance="bf"
          onSelect={setActiveDirection}
        >
          <Nav.Item
            eventKey={"INCOMING"}
            //   disabled={tab.disabled}
          >
            {i18n.t(
              "ContactInvoiceView.incomingInvoices",
              "Eingehende Rechnungen"
            )}
          </Nav.Item>
          <Nav.Item
            eventKey={"OUTGOING"}
            //   disabled={tab.disabled}
          >
            {i18n.t(
              "ContactInvoiceView.outgoingInvoices",
              "Ausgehende Rechnungen"
            )}
          </Nav.Item>
        </BFNav>

        <BFNav
          className={`tab-bar-nav`}
          activeKey={statusFilter}
          appearance="bf"
          onSelect={setStatusFilter}
        >
          <Nav.Item
            eventKey={"open"}
            //   disabled={tab.disabled}
          >
            {i18n.t("ContactInvoiceView.openInvoices", "Offen")}
          </Nav.Item>
          <Nav.Item
            eventKey={"closed"}
            //   disabled={tab.disabled}
          >
            {i18n.t("ContactInvoiceView.closedInvoices", "Abgeschlossen")}
          </Nav.Item>
          <Nav.Item eventKey={"declined"}>
            {i18n.t("ContactInvoiceView.declinedInvoices", "Abgelehnt")}
          </Nav.Item>
        </BFNav>

        <div className={`fill kpi-row`}>
          <ContactInvoiceKPIs
            statusFilter={statusFilter}
            activeDirection={activeDirection}
            contact={props.contact}
          />
        </div>
      </div>
      <div className={`invoice-content-wrapper`}>
        <InvoiceContent
          activeDirection={activeDirection}
          matchquery={matchquery}
        />
      </div>
    </div>
  );
};

export default ContactInvoiceView;

const InvoiceContent = (props: {
  activeDirection: InvoiceDirection;
  matchquery: MatchQuery;
}) => {
  const search = useTypedSelector(
    (state) =>
      state.application.infiniteTables[TABLE_IDENTIFIER]?.searchTerm || ""
  );
  const dispatch = useDispatch();
  const aggregated = useAggregationStatisticQuery(
    "invoice",
    MQ.and(props.matchquery),
    RA_INVOICE_STATIC_SELECTORS,
    undefined,
    search,
    undefined
  );
  const selected = useTypedSelector(
    (state) =>
      state.application.infiniteTables[TABLE_IDENTIFIER]?.selection?.[0] || null
  );

  return (
    <div className={`invoice-content`}>
      <PersistentSplitPane
        split="vertical"
        identifier="contact-invoice-view"
        defaultSize={500}
        minSize={400}
        maxSize={1000}
        primary="first"
        hideSecondary={!selected}
      >
        <div className={classNames(`invoice-list`)} key={"invoice-list"}>
          <VirtualizedTable
            selection="single"
            assetType={AssetTypes.Invoice}
            reloadOnMount
            expandKeys={["data.invoice.contact"]}
            hover
            params={{
              aggregated,
            }}
            identifier={TABLE_IDENTIFIER}
            onRowDoubleClick={(row: RAInvoice) => {}}
            additionalMatchQuery={props.matchquery}
            columns={{
              ...getIncomingInvoiceColumns(props.activeDirection),
              //   actions: {
              //     label: "",
              //     fixedWidth: 70,
              //     fixed: "right",
              //     render: (node: RAInvoice, index, params) => (
              //       <>
              //         <BFButton
              //           appearance="link"
              //           size="xs"
              //           onClick={() =>
              //             DataBusDefaults.route({
              //               route: `__details/${node._id}`,
              //               append: true,
              //             })
              //           }
              //         >
              //           {i18n.t("Global.Buttons.details")}
              //         </BFButton>
              //       </>
              //     ),
              //   },
            }}
          />
        </div>
        {selected && (
          <div className={`invoice-details`}>
            <AssetLoader
              assetType={AssetTypes.Invoice}
              id={selected}
              render={(invoice: RAInvoice) => (
                <>
                  <div className={`invoice-details-header`}>
                    <div>
                      <BFButton
                        appearance="clear-on-white"
                        onClick={() => {
                          dispatch(
                            InfiniteTable.setTableSelection(
                              TABLE_IDENTIFIER,
                              null
                            )
                          );
                        }}
                      >
                        <BfIcon
                          size="xs"
                          type="light"
                          data="arrow-circle-right"
                        />
                      </BFButton>
                    </div>
                    <div className={`fill`}></div>
                  </div>
                  <div className={`invoice-details-content`}>
                    <RAInvoiceDetailsComponent invoice={invoice} />
                  </div>
                </>
              )}
            />
          </div>
        )}
      </PersistentSplitPane>
    </div>
  );
};

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import {
  LatLong,
  calculateBounds,
} from "../../../../components/MapComponent/MapUtils";
import DataBus from "../../../../services/DataBus";
import GeoService, { GeoResult } from "../../../../services/GeoService";
import { DataBusSubKeys } from "../../../../utils/Constants";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import "./BFLongLat.scss";

interface SuggestionState {
  loading: boolean;
  data?: GeoResult[];
}

export interface Suggestion {
  geo: LatLong;
}
const containerStyle = {
  width: "100%",
  height: "100%",
};
export interface Zoomstate extends LatLong {
  zoom: number;
}
const initialState: Zoomstate = {
  lat: -3.745,
  lng: -38.523,
  zoom: 5,
};
const PADDING = 0.06;
interface Props {
  infoText?: string;
  identifier?: string;
  value: LatLong;
  onChange: (value: LatLong) => void;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
}

const BFLongLat = (props: Props) => {
  const [suggestionState, setSuggestionState] = useState<SuggestionState>(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBz4ujoaakqAwfNiGrRLgLorrAZxM51Ank",
  });
  const [map, setMap] = React.useState(null);
  const [selectedStores, setSelectedStores] = React.useState([]);

  useEffect(() => {
    const subId = DataBus.subscribe(
      DataBusSubKeys.BF_LONG_LAT_QUERY,
      (val: { identifier: string; query: string }) => {
        if (val.identifier === props.identifier) {
          queryData(val.query);
        }
      }
    );

    return () => {
      DataBus.unsubscribe(subId);
    };
  }, []);
  const queryData = async (query: string) => {
    if (suggestionState?.loading) {
      return;
    }
    try {
      setSuggestionState({ loading: true });

      const data = await GeoService.query(query);
      setSuggestionState({
        loading: false,
        data,
      });
    } catch (err) {
      setSuggestionState(null);
    }
  };

  const onLoad = React.useCallback((map) => {
    // fitToBounds(map);
    setMap(map);

    if (props.value) {
      map.fitBounds(calculateBounds([props.value], 0.001));
    }
  }, []);
  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  return (
    <div className="bf-long-lat">
      {isLoaded && (
        <div className={`map-container ${props.infoText ? "has-info" : ""}`}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={initialState}
            options={{
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false,
            }}
            zoom={initialState.zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={(e) => {
              props.onChange({
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
              });
            }}
          >
            {props.value && (
              <Marker
                position={props.value}
                draggable
                onDragEnd={(e) => {
                  props.onChange({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  });
                }}
                cursor={"pointer"}
              />
            )}
          </GoogleMap>
          <div className="actions">
            <button
              disabled={!props.value}
              type="button"
              onClick={() => {
                if (!props.value) {
                  return;
                }
                map.fitBounds(calculateBounds([props.value], 0.001));
              }}
            >
              <BfIcon data={"location-target"} type="light" size="sm" />
            </button>
          </div>
          {suggestionState && (
            <div className="suggestions">
              <div className="header">
                <div className="title">Vorschläge</div>
                <BFButton
                  className="close-action"
                  appearance={"clear-on-white"}
                  onClick={() => setSuggestionState(null)}
                  icon={{ type: "light", data: "close", size: "xs" }}
                />
              </div>

              <div
                className={`content ${
                  suggestionState.loading ? "loading" : ""
                }`}
              >
                {suggestionState.loading && <Loader size="lg" />}
                {!suggestionState.loading &&
                  suggestionState.data.map((entry) => (
                    <button
                      type="button"
                      key={entry.osm_id}
                      className="suggestion-button"
                      onClick={() => {
                        const newValue = {
                          lat: Number(entry.lat),
                          lng: Number(entry.lon),
                        };
                        props.onChange(newValue);
                        setSuggestionState(null);

                        map.fitBounds(calculateBounds([newValue], 0.001));
                      }}
                    >
                      <div className="button-content">
                        <div className="street">
                          {entry.address.road || ""}{" "}
                          {entry.address.house_number || ""}
                        </div>

                        <div className="city">
                          {entry.address.postcode} {entry.address.town},{" "}
                          {entry.address.suburb}
                        </div>

                        <div className="country">{entry.address.country}</div>
                      </div>
                      <div className="arrow">
                        <BfIcon type="light" data="arrow-right-1" size="sm" />
                      </div>
                    </button>
                  ))}
                {!suggestionState.loading &&
                  suggestionState.data.length === 0 && (
                    <div className="no-entries">Keine Geodaten gefunden</div>
                  )}
              </div>
            </div>
          )}
        </div>
      )}
      {props.infoText && (
        <div className="info">
          <BfIcon type="light" data="information-circle" />
          <div className="text">{props.infoText}</div>
        </div>
      )}
      {props.validation?.message && (
        <div className={`validation ${props.validation.level}`}>
          {props.validation.message}
        </div>
      )}
    </div>
  );
};
export default BFLongLat;

import Fuse from "fuse.js";
import _ from "lodash";
import Log from "../debug/Log";
import { TableSort } from "../model/common/CommonInterfaces";

export type SortableField = string | number | boolean | Date;
export type ConvertField<T> = {
  [selector: string]: (a: T, b: T) => [SortableField, SortableField];
};

class ArrayUtilsClass {
  fuzzyFilter<T>(
    searchString: string,
    searchIn: T[],
    options?: Fuse.IFuseOptions<T>
  ) {
    const fuse = new Fuse(searchIn || [], options);

    return fuse.search(searchString);
  }

  private sortHelper<T>(
    a: T,
    b: T,
    sort: TableSort,
    converter: (a: T, b: T) => [SortableField, SortableField]
  ) {
    let aValue: SortableField = undefined; //a[sort.key];
    let bValue: SortableField = undefined; //b[sort.key];

    if (converter) {
      [aValue, bValue] = converter(a, b);
    } else {
      aValue = _.get(a, sort.key);
      bValue = _.get(b, sort.key);
    }
    if (
      ((aValue === undefined || aValue === null) &&
        (bValue === undefined || bValue === null)) ||
      aValue === bValue
    ) {
      return 0;
    }
    if (aValue === undefined || aValue === null) {
      return sort.dir === "asc" ? 1 : -1;
    }
    if (bValue === undefined || bValue === null) {
      return sort.dir === "asc" ? -1 : 1;
    }

    if (typeof aValue === "string" && typeof bValue === "string") {
      return sort.dir === "asc"
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }
    if (typeof aValue === "number" && typeof bValue === "number") {
      return sort.dir === "asc" ? aValue - bValue : bValue - aValue;
    }
    if (typeof aValue === "boolean" && typeof bValue === "boolean") {
      return sort.dir === "asc" ? (aValue ? 1 : -1) : bValue ? 1 : -1;
    }
    if (aValue instanceof Date && bValue instanceof Date) {
      return sort.dir === "asc"
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime();
    }
    Log.warning(
      "Invalid case for sorting. Seems to be an unusual case to sort fields",
      "Value1:",
      aValue,
      typeof aValue,
      "Value2:",
      bValue,
      typeof bValue
    );
    return 0;
  }
  sortData<T>(
    data: T[],
    sortInput?: TableSort | TableSort[],
    fieldConverter?: ConvertField<T>
  ) {
    if (!sortInput || !data) {
      return data;
    }
    const sorts = Array.isArray(sortInput) ? sortInput : [sortInput];

    const converter = sorts.map((sort) =>
      fieldConverter ? fieldConverter[sort.key] : undefined
    );

    return data.sort((a, b) => {
      for (let i = 0; i < sorts.length; i++) {
        const sort = sorts[i];
        const sortHelper = this.sortHelper(a, b, sort, converter[i]);
        if (sortHelper !== 0) {
          return sortHelper;
        }
      }
      return 0;
    });
  }
  reverse<T>(data: T[]) {
    return [...data].reverse();
  }

  insert<T>(toAdd: T, index: number, arr: T[]) {
    return [...arr.slice(0, index), toAdd, ...arr.slice(index)];
  }
}
const ArrayUtils = new ArrayUtilsClass();
export default ArrayUtils;

import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Animation, Loader } from "rsuite";
import AvatarComponent from "../../../../components/AvatarComponent/AvatarComponent";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import { TranslationProps } from "../../../../model/common/TranslationProps";
import { User } from "../../../../model/db/User";
import BFPinInput from "../../../../modules/abstract-ui/forms/pin-input/BFPinInput";
import BFButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import { AppState } from "../../../../redux/store";
import { loginTrustedDevice } from "../../../../services/AuthenticationService";
import { LSTrustedDeviceModel } from "../../../../utils/Constants";
import "./TrustedDeviceLoginForm.scss";

const { Collapse } = Animation;

interface Props extends TranslationProps {
  onGoBack: () => void;
  cookiesAccepted: boolean;
  trustedDevices: LSTrustedDeviceModel[];
  onTrustedDeviceRemove: (device: LSTrustedDeviceModel) => void;
  loginTrustedDevice: (
    userID: string,
    mandator: string,
    deviceID: string,
    token: string,
    pin: string,
    onSuccess: (data: User) => void,
    onError: (err: any) => void
  ) => void;
}

interface States {
  loading: boolean;
  showErrors: boolean;
  selectedTrustedDevice: LSTrustedDeviceModel;
  editMode: boolean;
}

class TrustedDeviceLoginForm extends Component<Props, States> {
  pinInput = React.createRef<BFPinInput>();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showErrors: false,
      selectedTrustedDevice:
        props.trustedDevices.length === 1 ? props.trustedDevices[0] : null,
      editMode: false,
    };
  }
  componentDidMount() {
    if (this.state.selectedTrustedDevice) {
      setTimeout(() => {
        this.pinInput.current.reset();
        this.pinInput.current.focus();
      });
    }
  }
  startEditMode() {
    this.setState({ editMode: true });
  }
  stopEditMode() {
    this.setState({ editMode: false });
  }
  removeTrustedDevice(device: LSTrustedDeviceModel) {
    const { t, onTrustedDeviceRemove } = this.props;
    const user = device.displayName;
    ModalManager.confirm({
      title: t("views.login.trustedDevice.removePinConfirmation.title"),
      message: (
        <Trans
          i18nKey={
            "views.login.trustedDevice.removePinConfirmation.description"
          }
        >
          prefix
          <span style={{ fontWeight: "bold" }}>{{ user }}</span>
          postfix
        </Trans>
      ),
      onConfirm: () => {
        onTrustedDeviceRemove(device);
      },
      confirmButtonText: t(
        "views.login.trustedDevice.removePinConfirmation.confirm"
      ),
    });
  }
  onCodeEnter(code: string) {
    const { loading } = this.state;
    if (loading) return;

    const { deviceID, userID, mandator, token } =
      this.state.selectedTrustedDevice;
    this.setState({ loading: true });
    this.props.loginTrustedDevice(
      userID,
      mandator,
      deviceID,
      token,
      code,
      (user) => {
        // login success
      },
      (err) => {
        // login error
        this.pinInput.current.reset();
        this.setState({ loading: false });
        this.pinInput.current.focus();
      }
    );
  }
  selectUser(device: LSTrustedDeviceModel) {
    if (this.state.editMode) {
      return;
    }
    this.setState({ selectedTrustedDevice: device }, () => {
      setTimeout(() => {
        this.pinInput.current.reset();
        this.pinInput.current.focus();
      });
    });
  }
  renderUser(device: LSTrustedDeviceModel) {
    const { selectedTrustedDevice } = this.state;
    return (
      <div
        key={device.deviceID}
        onClick={() => this.selectUser(device)}
        className={`trusted-device-entry ${
          selectedTrustedDevice !== null
            ? selectedTrustedDevice.deviceID === device.deviceID
              ? "active"
              : "smaller"
            : ""
        }`}
      >
        <div className={`avatar-container`}>
          <AvatarComponent
            avatar={device.avatar}
            displayName={device.displayName}
            size="lg"
          />
          <BFButton
            size="xs"
            appearance={"primary"}
            circle
            className={`remove-button`}
            icon={{ type: "bf", data: "close" }}
            onClick={() => this.removeTrustedDevice(device)}
          />
        </div>
        <div className={`displayname`}>{device.displayName}</div>
      </div>
    );
  }
  renderUserList() {
    const { trustedDevices } = this.props;
    return (
      <div className={`list`}>
        {trustedDevices.map((device) => this.renderUser(device))}
      </div>
    );
  }

  render() {
    const { selectedTrustedDevice, loading, editMode } = this.state;
    const { t, trustedDevices } = this.props;

    return (
      <div
        className={`trusted-device-login-form ${editMode ? "edit" : ""} ${
          trustedDevices.length >= 4 ? "ignoreAnimation" : ""
        }`}
      >
        {this.renderUserList()}
        <Collapse in={selectedTrustedDevice !== null}>
          <div>
            <div className={`pin-input-container ${loading ? "loading" : ""}`}>
              {loading && <Loader center />}
              <BFPinInput
                numberOnly={true}
                ref={this.pinInput}
                password={true}
                disabled={loading}
                length={4}
                onComplete={(code) => this.onCodeEnter(code)}
              />
            </div>

            <div className={`user-actions`}>
              <BFButton
                icon={{
                  type: "bf",
                  data: "single-neutral-circle",
                }}
                text={"Benutzer wechseln"}
                appearance={"clear-on-white"}
                // textKey={"Global.Buttons.back"}
                onClick={() => this.setState({ selectedTrustedDevice: null })}
              />
            </div>
          </div>
        </Collapse>

        <Collapse in={selectedTrustedDevice === null && !editMode}>
          <div className={`actions-list-icons`}>
            <div className={`actions`}>
              <BFButton
                appearance={"clear-on-white"}
                icon={{
                  data: "cog",
                  type: "bf",
                }}
                // textKey={"views.login.trustedDevice.startEdit"}
                onClick={() => this.startEditMode()}
              />
              <div className={`fill`} />
              <BFButton
                appearance={"clear-on-white"}
                icon={{
                  data: "password-lock-1",
                  type: "bf",
                }}
                textKey={"views.login.trustedDevice.useNormalAuth"}
                onClick={() => this.props.onGoBack()}
              />
            </div>
          </div>
        </Collapse>

        <Collapse in={selectedTrustedDevice === null && editMode}>
          <div className={`actions-list-icons`}>
            <div className={`actions center`}>
              <BFButton
                appearance={"clear-on-white"}
                icon={{
                  data: "cog-approved",
                  type: "bf",
                }}
                textKey={"views.login.trustedDevice.stopEdit"}
                onClick={() => this.stopEditMode()}
              />
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  context: state.global.context,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: any
) => ({
  loginTrustedDevice: (
    email: string,
    mandator: string,
    deviceID: string,
    token: string,
    pin: string,
    onSuccess: (data: User) => void,
    onError: (err: any) => void
  ) => {
    dispatch(
      loginTrustedDevice(
        email,
        mandator,
        deviceID,
        token,
        pin,
        onSuccess,
        onError
      )
    );
  },
  // setUserData: (user: User, apps: Application[], permissions: Permission[]) => { dispatch(GlobalActions.setUserData(user, apps, permissions)) }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(withTranslation()(TrustedDeviceLoginForm))) as any;

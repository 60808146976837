import classNames from "classnames";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import { RSAssetParams } from "../../RSInterfaces";
import "./RSAssetDetails.scss";

interface RSAssetDetailsProps {
  assetParams: RSAssetParams;
  renderAssetData: (asset: any) => JSX.Element;
}

const RSAssetDetails = (props: RSAssetDetailsProps) => {
  return (
    <div className={classNames("rs-asset-details")}>
      <AssetLoader
        assetType={props.assetParams.assetType}
        query={{
          type: "op",
          op: "eq",
          name: "_id",
          value: props.assetParams.assetId,
        }}
        render={(data) => {
          return props.renderAssetData(data);
        }}
      />
    </div>
  );
};

export default RSAssetDetails;

import i18n from "../../../../../i18n";
import BFButton from "../../../../abstract-ui/general/Button/BFButton";

const RSRemoveAdditionalFieldBtn = (props: { clearFields: () => void }) => {
  const { clearFields } = props;

  return (
    <BFButton
      className="rs-remove-additional-field-btn"
      appearance="link"
      size="xs"
      onClick={() => {
        clearFields();
      }}
    >
      {i18n.t("Global.Buttons.remove")}
    </BFButton>
  );
};

export default RSRemoveAdditionalFieldBtn;

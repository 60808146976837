import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { BankAccountData } from "@/model/db/Contact";
import BFEntries from "@/modules/abstract-ui/data/entries/BFEntries";
import StringUtils from "@/utils/StringUtils";
import Tools from "@/utils/Tools";
import classNames from "classnames";
import { useRef } from "react";
import "./ContactBaseBankaccounts.scss";

interface ContactBaseBankaccountsProps {
  accounts: BankAccountData[];
}
const ContactBaseBankaccounts = (props: ContactBaseBankaccountsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className={classNames(`contact-base-address`)} ref={ref}>
      <BFEntries
        emptyText={i18n.t(
          "ContactBaseDataView.noBankaccounts",
          "Keine Bankkonten vorhanden"
        )}
        entries={props.accounts.map((account) => {
          const addAddressToClipboard = () => {
            const textToCopy =
              account.type === "EU" ? account.iban : account.accountNumber;
            Tools.copy2Clipboard(
              textToCopy,
              i18n.t(
                "Contact.BankAccount.copiedToClipboard",
                "Bankdaten in Zwischenablage kopiert"
              ),
              ref.current
            );
          };

          return {
            content: (
              <>
                <DebugDataComponent data={account} />
                {account.type === "EU" && (
                  <>
                    <div className={`iban text-selection`}>
                      {StringUtils.formatIban(account.iban)}
                    </div>
                    <table>
                      <tr>
                        <td className={`info-label`}>
                          {i18n.t("Contact.Bankaccount.bankName", "Bank")}
                        </td>
                        <td className={`text-selection`}>{account.bankName}</td>
                      </tr>
                      <tr>
                        <td className={`info-label`}>
                          {i18n.t("Contact.Bankaccount.bic", "BIC")}
                        </td>
                        <td className={`text-selection`}>{account.bic}</td>
                      </tr>
                      <tr>
                        <td className={`info-label`}>
                          {i18n.t(
                            "Contact.Bankaccount.accountHolder",
                            "Kontoinhaber"
                          )}
                        </td>
                        <td className={`text-selection`}>
                          {account.accountHolder}
                        </td>
                      </tr>
                    </table>
                  </>
                )}
                {account.type === "US" && (
                  <>
                    <div className={`routing-number`}>
                      {account.routingNumber}
                    </div>
                    <div className={`account-holder`}>
                      {account.accountHolder}
                    </div>
                    <div className={`bank-name`}>{account.bankName}</div>
                    <div className={`account-number`}>
                      {account.accountNumber}
                    </div>
                  </>
                )}
              </>
            ),
            actions: [
              {
                icon: "duplicate-file",
                onClick: addAddressToClipboard,
                tooltip: i18n.t(
                  "ContactBaseAddress.copyToClipboard",
                  "In Zwischenablage kopieren"
                ),
              },
            ],
          };
        })}
      />
    </div>
  );
};

export default ContactBaseBankaccounts;

import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { Drawer, Nav, Navbar, Sidenav } from "rsuite";
import { Application } from "../../model/db/Application";
import BfButton from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import "./ApplicationSidenav.scss";

type Props = {
  appearance: "default" | "grey";
  ignoreNavToggle?: boolean;
  children?: ReactNode;
  applicationConfig?: Application;
  activeKey?: string;
  onKeySelect: (key: string) => any;
  defaultOpenKeys?: string[];
  noTransition?: boolean;
  viewportSizeSelector: string;
  setUiConfig: (key: string, value: any) => void;
  currentApplication?: Application;
  renderChildren?: (expanded: boolean) => React.ReactNode;
};
type States = {
  expanded: boolean;
  showDrawer: boolean;
};

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="inverse" className="nav-toggle">
      <Navbar.Body>
        {/*<Nav>*/}
        {/*    <Dropdown*/}
        {/*        placement="topLeft"*/}
        {/*        trigger="click"*/}
        {/*        renderTitle={children => {*/}
        {/*            return <Icon style={iconStyles} icon="cog" />;*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <Dropdown.Item>Help</Dropdown.Item>*/}
        {/*        <Dropdown.Item>Settings</Dropdown.Item>*/}
        {/*        <Dropdown.Item>Sign out</Dropdown.Item>*/}
        {/*    </Dropdown>*/}
        {/*</Nav>*/}

        <Nav pullRight>
          <Nav.Item
            onClick={onChange}
            style={{ width: 56, textAlign: "center" }}
          >
            <BfIcon
              type="light"
              data={expand ? "arrow-left-1" : "arrow-right-1"}
              size="xs"
            />
            {/* <Icon icon={expand ? "angle-left" : "angle-right"} /> */}
          </Nav.Item>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};

class ApplicationSidenav extends Component<Props, States> {
  static defaultProps = {
    noTransition: true,
    appearance: "default",
  };

  readonly state: States = {
    expanded: true,
    showDrawer: false,
  };

  componentDidMount(): void {
    if (this.props.viewportSizeSelector === "xs") {
      this.props.setUiConfig(
        DefaultUIConfigs.SHOW_HEADER_LEFT_NODE,
        <>
          <BfButton
            appearance={"clear"}
            onClick={() => this.setState({ showDrawer: true })}
          >
            <BfIcon type="bf" size={"xl"} data={"navigation-menu"} />
          </BfButton>
        </>
      );
    }
  }

  componentWillUnmount(): void {
    this.props.setUiConfig(DefaultUIConfigs.SHOW_HEADER_LEFT_NODE, null);
  }

  componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): void {
    if (nextProps.viewportSizeSelector !== this.props.viewportSizeSelector) {
      if (nextProps.viewportSizeSelector === "xs") {
        this.props.setUiConfig(
          DefaultUIConfigs.SHOW_HEADER_LEFT_NODE,
          <>
            <BfButton
              appearance={"clear"}
              onClick={() => this.setState({ showDrawer: true })}
            >
              <BfIcon type="bf" size={"xl"} data={"navigation-menu"} />
            </BfButton>
          </>
        );
      } else {
        this.props.setUiConfig(DefaultUIConfigs.SHOW_HEADER_LEFT_NODE, null);
      }
    }
  }

  handleToggle() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  handleSelect(eventKey) {
    if (this.props.onKeySelect) {
      this.props.onKeySelect(eventKey);
    }
  }

  render() {
    const { expanded } = this.state;
    const { renderChildren, appearance } = this.props;

    if (this.props.viewportSizeSelector !== "xs") {
      return (
        <div
          className={`application-sidenav ${
            this.props.noTransition ? "noTransition" : ""
          } appearance-${appearance}`}
        >
          <div className={"app-layout-content"}>
            <Sidenav
              expanded={expanded}
              defaultOpenKeys={this.props.defaultOpenKeys}
              activeKey={this.props.activeKey}
              onSelect={(eventKey) => this.handleSelect(eventKey)}
              appearance={"inverse"}
            >
              <Sidenav.Body>
                {/*{this.props.children}*/}
                {renderChildren
                  ? renderChildren(expanded)
                  : this.props.children}
              </Sidenav.Body>
            </Sidenav>
          </div>
          {!this.props.ignoreNavToggle ? (
            <div className={"footer"}>
              <NavToggle
                expand={expanded}
                onChange={() => this.handleToggle()}
              />
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <Drawer
          placement={"left"}
          open={this.state.showDrawer}
          onClose={() => this.setState({ showDrawer: false })}
          // onHide={this.close}
          size={"xs"}
          className={`sidenav-drawer  appearance-${appearance}`}
        >
          <Sidenav
            expanded={true}
            defaultOpenKeys={this.props.defaultOpenKeys}
            activeKey={this.props.activeKey}
            onSelect={(eventKey, ev) => {
              if (eventKey && !(eventKey as string).startsWith("submenu_")) {
                this.setState({ showDrawer: false });
                this.handleSelect(eventKey);
              }
            }}
            appearance={"inverse"}
          >
            <Sidenav.Body>
              {this.props.currentApplication ? (
                <div className={"sideNavHeader"}>
                  <img
                    className={"logo"}
                    src={this.props.currentApplication.logo.navbar}
                  />

                  {this.props.currentApplication.logo.navbarDisplayname ? (
                    <span className={"applicationName"}>
                      {this.props.currentApplication.displayName}
                    </span>
                  ) : null}
                </div>
              ) : null}
              {/*{this.props.children}*/}
              {renderChildren ? renderChildren(expanded) : this.props.children}
            </Sidenav.Body>
          </Sidenav>
        </Drawer>
      );
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user,
  viewportSizeSelector:
    state.uiConfig.general[DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR],
  currentApplication: state.uiConfig.activeApplication,
});

export default connect(mapStateToProps, { setUiConfig })(
  ApplicationSidenav
) as any;

import moment from "moment";
import ModalManager from "../../../components/ModalComponent/ModalManager";
import i18n from "../../../i18n";
import { useAggregationStatisticQuery, useDatabus } from "../../../redux/hooks";
import BFBadge from "../../abstract-ui/general/Badge/BFBadge";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import BfIcon from "../../abstract-ui/icon/BfIcon";
import { RSDatabusEvents, RSListIdentifiers } from "../RSConstants";
import RSService from "../RSService";
import ResubmissionDashboardComponent from "../view/ResubmissionDashboardComponent";
import "./ResubmissionAppBarButton.scss";

const ResubmissionAppBarButton = () => {
  const openResubmissionAggregation = useAggregationStatisticQuery(
    "resubmission",
    {
      type: "and",
      query: [
        {
          type: "op",
          op: "in",
          name: "data.status",
          value: ["open"],
        },
        RSService.getAssigneeMatchQuery("own"),
      ],
    },
    [
      {
        name: "due",
        op: [{ key: "count", op: "count" }],
        query: {
          matchQuery: {
            type: "op",
            op: "eq",
            name: "data.dueDate",
            value: moment().startOf("day").utc(true).toISOString(),
          },
        },
      },
      {
        name: "overdue",
        op: [{ key: "count", op: "count" }],
        query: {
          matchQuery: {
            type: "op",
            op: "lt",
            name: "data.dueDate",
            value: moment().startOf("day").utc(true).toISOString(),
          },
        },
      },
    ],
    RSListIdentifiers.AppBarButton
  );

  useDatabus(RSDatabusEvents.UpdateResubmissions, () => {
    openResubmissionAggregation.reload();
  });

  const openResubmissionsDueCount =
    openResubmissionAggregation.data?.["due"]?.count || 0;
  const openResubmissionsOverdueCount =
    openResubmissionAggregation.data?.["overdue"]?.count || 0;

  return (
    <BFButton
      appearance="clear-on-white"
      icon={{ type: "light", data: "list-to-do", size: "xs" }}
      onClick={() => {
        ModalManager.show({
          backdrop: "static",
          size: "md",
          title: i18n.t("Resubmission.Dashboard.Title", "Wiedervorlagen"),
          buttons: [
            {
              appearance: "primary",
              text: i18n.t("Global.Buttons.close"),
              closeOnClick: true,
            },
          ],
          content: (states, setStates, close) => {
            return (
              <div className="rs-modal-dashboard">
                <ResubmissionDashboardComponent onCloseModal={close} />
              </div>
            );
          },
        });
      }}
    >
      <BFBadge
        content={openResubmissionsDueCount + openResubmissionsOverdueCount}
        color={openResubmissionsOverdueCount > 0 ? "red" : "green"}
      >
        <BfIcon type="light" data="list-to-do" size="xs" />
      </BFBadge>
    </BFButton>
  );
};

export default ResubmissionAppBarButton;

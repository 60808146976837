import moment from "moment";
import { ThunkDispatch } from "redux-thunk";
import Log from "../../../debug/Log";
import { FavoriteFilter } from "../../../modules/abstract-ui/data/table-filter/BFTableFilterModel";
import { isDefined } from "../../../utils/Helpers";
import StorageUtils from "../../../utils/StorageUtils";
import { useTypedSelector } from "../../hooks";
import {
  LOCALSTORAGE_KEY_CUSTOMIZATIONS,
  LOCALSTORAGE_KEY_FAV_FILTERS,
  LOCALSTORAGE_KEY_FLEX_VALUES,
} from "../../reducers/customization/Customization";
import {
  CommentDraft,
  CommentDraftData,
  TableColumnCustomization,
} from "../../reducers/customization/CustomizationInterface";
import { AppState } from "../../store";
import {
  CUSTOMIZATION_DRAFT_INIT,
  CUSTOMIZATION_DRAFT_REMOVE,
  CUSTOMIZATION_DRAFT_SET,
  CUSTOMIZATION_FAV_FILTER_ADD,
  CUSTOMIZATION_FAV_FILTER_REMOVE,
  CUSTOMIZATION_FAV_FILTER_UPDATE,
  CUSTOMIZATION_INFINITE_TABLE_ADD_PERSIST,
  CUSTOMIZATION_INFINITE_TABLE_REVERT,
  CUSTOMIZATION_INFINITE_TABLE_SET_COLUMNS,
  CUSTOMIZATION_SET_FLEX,
} from "./customization-actions-types";

export const selectorFlexCustomization = (
  state: AppState,
  key: string,
  defaultValue: any | any[] = null
) =>
  isDefined(state.customizations.flex[key])
    ? (state.customizations.flex[key] as any | any[])
    : defaultValue;

export const useFlexCustomization = (
  key: string,
  defaultValue: any | any[] = null
) => {
  return useTypedSelector((state) =>
    selectorFlexCustomization(state, key, defaultValue)
  );
};

class CustomizationActionClass {
  customizationsSaveTimeout: NodeJS.Timeout = null;
  favoriteSaveTimeout: NodeJS.Timeout = null;
  flexSaveTimeout: NodeJS.Timeout = null;

  setFlexValue(key: string, value: any) {
    return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
      dispatch({
        type: CUSTOMIZATION_SET_FLEX,
        key,
        value,
      });
      this.saveFlex(dispatch, getState);
    };
  }

  initCommentDrafts() {
    return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
      const userId = getState().global.user._id;
      const drafts: CommentDraft[] = [];

      const keysToLoad = Object.keys(localStorage).filter((key) =>
        key.startsWith(`${userId}-drafts-`)
      );
      keysToLoad.forEach((key) => {
        const draftString = StorageUtils.LocalStorage.get(key) as string;
        if (draftString) {
          try {
            const draft = JSON.parse(draftString);
            drafts.push(draft);
          } catch (e) {
            Log.error("Error parsing draft", e, draftString);
          }
        }
      });

      dispatch({
        type: CUSTOMIZATION_DRAFT_INIT,
        drafts: drafts.filter((draft) => {
          const deleteDraft = moment(draft.date)
            .add(48, "h")
            .isBefore(moment());
          if (deleteDraft) {
            this.deleteDraftFromStorage(draft.id, userId);
          }
          return !deleteDraft;
        }),
      });
    };
  }
  private deleteDraftFromStorage(id: string, userId: string) {
    StorageUtils.LocalStorage.delete(`${userId}-drafts-${id}`);
  }
  setCommentDraft(id: string, draft: CommentDraftData) {
    return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
      const userId = getState().global.user._id;
      StorageUtils.LocalStorage.save(
        `${userId}-drafts-${id}`,
        JSON.stringify({
          id,
          draft,
          date: new Date().toISOString(),
        })
      );
      dispatch({
        type: CUSTOMIZATION_DRAFT_SET,
        id,
        draft,
      });
    };
  }
  removeCommentDraft(id: string) {
    return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
      const userId = getState().global.user._id;
      this.deleteDraftFromStorage(id, userId);
      dispatch({
        type: CUSTOMIZATION_DRAFT_REMOVE,
        id,
      });
    };
  }

  updateFavoriteFilter(tableIdentifier: string, filter: FavoriteFilter) {
    return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
      dispatch({
        type: CUSTOMIZATION_FAV_FILTER_UPDATE,
        tableIdentifier,
        filter,
      });
      this.saveFilters(dispatch, getState);
    };
  }
  addFavoriteFilter(tableIdentifier: string, filter: FavoriteFilter) {
    return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
      dispatch({
        type: CUSTOMIZATION_FAV_FILTER_ADD,
        tableIdentifier,
        filter,
      });
      this.saveFilters(dispatch, getState);
    };
  }
  removeFavoriteFilter(tableIdentifier: string, filterId: string) {
    return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
      dispatch({
        type: CUSTOMIZATION_FAV_FILTER_REMOVE,
        tableIdentifier,
        id: filterId,
      });
      this.saveFilters(dispatch, getState);
    };
  }

  revertInfiniteTable(
    tableIdentifier: string,
    persistCustomizations?: boolean
  ) {
    return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
      dispatch({
        type: CUSTOMIZATION_INFINITE_TABLE_REVERT,
        tableIdentifier,
      });
      if (persistCustomizations) {
        this.saveCustomizations(tableIdentifier, dispatch, getState);
      }
    };
  }
  customizeInfiniteTableColumns(
    tableIdentifier: string,
    columns: { [columnIdentifier: string]: Partial<TableColumnCustomization> },
    persistCustomizations?: boolean,
    overwrite: boolean = false
  ) {
    return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
      dispatch({
        type: CUSTOMIZATION_INFINITE_TABLE_SET_COLUMNS,
        tableIdentifier,
        columns,
        overwrite,
      });

      if (persistCustomizations) {
        this.saveCustomizations(tableIdentifier, dispatch, getState);
      }
    };
  }

  private saveFlex(
    dispatch: ThunkDispatch<{}, {}, any>,
    getState: () => AppState
  ) {
    const state = getState();

    if (this.flexSaveTimeout) {
      clearTimeout(this.flexSaveTimeout);
    }
    this.flexSaveTimeout = setTimeout(() => {
      const flexData = state.customizations.flex;
      StorageUtils.LocalStorage.save(
        `${LOCALSTORAGE_KEY_FLEX_VALUES}`,
        JSON.stringify(flexData)
      );
    }, 1000);
  }

  private saveFilters(
    dispatch: ThunkDispatch<{}, {}, any>,
    getState: () => AppState
  ) {
    const state = getState();

    if (this.favoriteSaveTimeout) {
      clearTimeout(this.favoriteSaveTimeout);
    }
    this.favoriteSaveTimeout = setTimeout(() => {
      const favoriteFilters = state.customizations.favoriteFilters;
      StorageUtils.LocalStorage.save(
        `${LOCALSTORAGE_KEY_FAV_FILTERS}`,
        JSON.stringify(favoriteFilters)
      );
    }, 1000);
  }

  private saveCustomizations(
    tableIdentifier: string,
    dispatch: ThunkDispatch<{}, {}, any>,
    getState: () => AppState
  ) {
    const state = getState();

    dispatch({
      type: CUSTOMIZATION_INFINITE_TABLE_ADD_PERSIST,
      tableIdentifier,
    });
    if (this.customizationsSaveTimeout) {
      clearTimeout(this.customizationsSaveTimeout);
    }
    this.customizationsSaveTimeout = setTimeout(() => {
      const tablesToPersist = state.customizations.persistedInfiniteTables;
      const customzation = Object.fromEntries(
        tablesToPersist.map((tableIdentifier) => [
          tableIdentifier,
          state.customizations.infiniteTable[tableIdentifier],
        ])
      );

      StorageUtils.LocalStorage.save(
        `${LOCALSTORAGE_KEY_CUSTOMIZATIONS}`,
        JSON.stringify(customzation)
      );
    }, 1000);
  }
}
const Customization = new CustomizationActionClass();
export default Customization;

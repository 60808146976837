import GlobalActions from "../services/GlobalActions";

export const openDetailsAction = (checkAssetType: string, route: string) => {
  const fc = ({ assetType, id }) => {
    if (assetType === checkAssetType) {
      if (window.location.pathname.indexOf("__asset_") > 0) {
        return `${window.location.pathname.substring(
          0,
          window.location.pathname.indexOf("__asset_")
        )}${route}/${id}`;
      } else {
        return `${window.location.pathname}/${route}/${id}`;
      }
    }
  };
  GlobalActions.registerGlobalAction("details", fc as any);
};

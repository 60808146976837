import BFInput from "@/modules/abstract-ui/forms/input/BFInput";

import { LinkConfig } from "../../../services/types/pdfConfigBlockTypes";
import { withPDFBlock } from "../../PDFBlock";
import { PDFBlockFooter } from "../../PDFBlockFooter";
import { useState } from "react";
import i18n from "@/i18n";
import { PDFTextarea } from "./PDFTextarea";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { useBlockChanges } from "../../PDFBlockHooks";
import { PDFCommonInput } from "../../Common/PDFCommonInput";

const PDFLinkInput = withPDFBlock<LinkConfig>(
  ({ value, parentId }) => {
    const [form, setForm] = useState(value);
    const [_, { deleteBlock, saveBlock }] = usePDFConstructor();

    const setFormValue = <K extends keyof typeof form>(
      key: K,
      value: typeof form[K]
    ) => {
      // color picker sets a new color whenever it's opened which causes unwanted rerenders
      if (form[key] === value) {
        return;
      }

      setForm((form) => ({
        ...form,
        [key]: value,
      }));
    };

    useBlockChanges(value, form);

    return (
      <div>
        <PDFTextarea form={form} setFormValue={setFormValue} />

        <div>
          <BFInput
            label={i18n.t("Component.PDFConstructor.Block.Link", "Link")}
            value={form.link}
            placeholder={i18n.t("Component.PDFConstructor.Block.Link", "Link")}
            type="url"
            onChange={(value) => setFormValue("link", value.toString())}
          />
        </div>

        <div>
          <BFInput
            label={i18n.t(
              "Component.PDFConstructor.Block.LineHeight",
              "Linieneingabe"
            )}
            value={form.lineHeight}
            placeholder={i18n.t(
              "Component.PDFConstructor.Block.LineHeight",
              "Linieneingabe"
            )}
            type="number"
            onChange={(value) => {
              setFormValue("lineHeight", Number(value));
            }}
            min={1}
            step={1}
          />
        </div>

        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () => i18n.t("Component.PDFConstructor.Block.Inputs.LinkInput", "Linkeingabe")
);

export { PDFLinkInput };

import classNames from "classnames";
import { AdminUser } from "../../apps/administration/AdminInterfaces";
import AvatarComponent from "./AvatarComponent";

interface PrepUserlabelProps {
  user: AdminUser;
  avatarSize?: number;
  className?: string;
}
const PrepUserlabel = (props: PrepUserlabelProps) => {
  return (
    <div className={classNames(`user-label`, props.className)}>
      <AvatarComponent
        avatar={props.user.avatar}
        displayName={props.user.displayname}
        size={props.avatarSize}
      />
      <span>{props.user.displayname}</span>
    </div>
  );
};

export default PrepUserlabel;

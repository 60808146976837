import VirtualizedTable, {
  VirtualizedTableProps,
} from "../../data/VirtualizedTable/VirtualizedTable";
import TablePageLayout, { TablePageLayoutProps } from "./TablePageLayout";

export type TablePageProps = {
  layout: TablePageLayoutProps;
  table: VirtualizedTableProps;
};
const TablePage = ({ layout, table }: TablePageProps) => {
  return (
    <TablePageLayout {...layout}>
      <VirtualizedTable {...table} />
    </TablePageLayout>
  );
};
export default TablePage;

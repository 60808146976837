import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import InfiniteTable from "@/redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "@/redux/hooks";
import { when } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import OverlayTitle from "./OverlayTitle";
import { APProjectActivity } from "@/apps/tatar/activityApp/ActivityInterfaces";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import StructLoader from "@/components/StructLoader/StructLoader";
import { PlacementAll } from "@/modules/abstract-ui/common/Placements";
import BFOverlay from "@/modules/abstract-ui/general/whisper/BFOverlay";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import "./OverlayAPProject.scss";

export interface APProjectValue {
  projects: string[];
}

type Props = {
  title: string;
  value: APProjectValue;
  filterTypes?: string[];
  onChange: (value: APProjectValue) => void;
};

export const generateAPProjectLabel = (
  typeLabel: string,
  value: APProjectValue
) => ({
  typeLabel: typeLabel,
  valueLabel:
    value === null || value === undefined || value.projects?.length === 0 ? (
      "-"
    ) : value.projects.length === 1 ? (
      <ProjectLabel projectId={value.projects[0]} />
    ) : (
      `${value.projects.length} ${i18n.t("Global.Label.Projects", "Projekte")}`
    ),
});

const OverlayAPProject = (props: Props) => {
  const [ident] = useState(nanoid());
  const dispatch = useDispatch();
  const tableData = useTypedSelector(
    (state) => state.application.infiniteTables[ident]
  );

  useEffect(() => {
    return () => {
      dispatch(InfiniteTable.clearTable(ident));
    };
  }, []);

  const addProject = (projectId: string) => {
    props.onChange({
      ...props.value,
      projects: [...(props.value?.projects || []), projectId],
    });
  };

  const removeProject = (projectId: string) => {
    props.onChange({
      ...props.value,
      projects: (props.value?.projects || []).filter((e) => e !== projectId),
    });
  };

  return (
    <div className={classNames(`overlay-project`)}>
      <OverlayTitle title={props.title} onClear={() => props.onChange(null)} />

      <div className={`selected-project-list`}>
        {(props.value?.projects || []).length === 0 && (
          <div className={`no-entries`}>
            {i18n.t(
              "BFFIlter.OverlayProject.notRestricted",
              "Kein Project ausgewählt"
            )}
          </div>
        )}
        {props.value?.projects?.map((project) => (
          <div className={`selected-project-entry`}>
            <ProjectLabel projectId={project} />
            <BFButton
              appearance="link"
              className={`remove-project-button`}
              noPadding
              onClick={() => {
                removeProject(project);
              }}
            >
              <BfIcon {...DefaultIcons.REMOVE} size="xxs" />
            </BFButton>
          </div>
        ))}
      </div>
      <div className="search">
        <BFInput
          placeholder={i18n.t("BFTableSearch.searchPlaceholder", "Suche...")}
          appearance="clear"
          value={tableData?.searchTerm || ""}
          onChange={(value: string) =>
            dispatch(InfiniteTable.setSearch(ident, value))
          }
        />
      </div>
      <div className="content">
        <ListComponent
          assetType={AssetTypes.Activity.Project}
          identifier={ident}
          params={{
            selectedProjects: props.value?.projects || [],
            addProject,
            removeProject,
          }}
          additionalMatchQuery={MQ.and(
            when(props.filterTypes, MQ.in("data.type", props.filterTypes))
          )}
          render={(data: APProjectActivity, _index, params: any) => (
            <div className={`entry`}>
              <BFCheckbox
                onChange={(_value, checked) => {
                  if (checked) {
                    params.addProject(data._id);
                  } else {
                    params.removeProject(data._id);
                  }
                }}
                checked={params?.selectedProjects?.includes(data._id)}
              >
                <ProjectLabel project={data} withType />
              </BFCheckbox>
            </div>
          )}
        />
      </div>
      <div className="footer">
        <BFButton
          appearance="link"
          onClick={() => props.onChange({ ...props.value, projects: [] })}
        >
          {i18n.t("BFFilterbar.Multiple.deselectAll", "Alle abwählen")}
        </BFButton>
      </div>
    </div>
  );
};

export default OverlayAPProject;

interface ProjectLabelProps {
  project: APProjectActivity;
  withType?: boolean;
  placement?: PlacementAll;
}
interface ProjectLoadLabelProps {
  projectId: string;
  withType?: boolean;
  placement?: PlacementAll;
}

const ProjectLabel = (props: ProjectLabelProps | ProjectLoadLabelProps) => {
  if ((props as ProjectLabelProps).project) {
    return (
      <ProjectLabelWithData
        project={(props as ProjectLabelProps).project}
        withType={props.withType}
        placement={props.placement}
      />
    );
  } else {
    return (
      <ProjectLabelLoader
        projectId={(props as ProjectLoadLabelProps).projectId}
        withType={props.withType}
        placement={props.placement}
      />
    );
  }
};

//   export default ProjectLabel;

const ProjectLabelWithData = (props: ProjectLabelProps) => {
  return (
    <BFOverlay
      delay={500}
      enterable
      placement={props.placement}
      speaker={
        <div className={`padding-10 project-view-container`}>
          {props.project.data.displayName}
        </div>
      }
    >
      <span className={classNames(`project-label`)}>
        {props.withType && (
          <StructLoader
            unitType={props.project?.data?.type}
            structType="unit"
            render={() => {
              return (
                <span className={`business-type`}>
                  {UnitStruct.getUnitLabel(props.project?.data?.type) ||
                    props.project?.data?.type}
                </span>
              );
            }}
          />
        )}
        {props.project?.data?.activityId}
      </span>
    </BFOverlay>
  );
};

const ProjectLabelLoader = (props: ProjectLoadLabelProps) => {
  if (!props.projectId) {
    return <>-</>;
  }
  return (
    <AssetLoader
      assetType={AssetTypes.Activity.Project}
      id={props.projectId}
      render={(project: APProjectActivity) => (
        <ProjectLabelWithData
          withType={props.withType}
          placement={props.placement}
          project={project}
        />
      )}
    />
  );
};

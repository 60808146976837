import classNames from "classnames";
import { useDispatch } from "react-redux";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import DebugDataComponent from "../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import BFStatus from "../../../../../../modules/abstract-ui/data/status/BFStatus";
import { AssetCell } from "../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import { openOrDownloadDocument } from "../../../../../../redux/actions/ui-config/ui-config-actions";
import CDNService from "../../../../../../services/CDNService";
import LanguageService from "../../../../../../services/LanguageService";
import ArrayUtils from "../../../../../../utils/ArrayUtils";
import FileUtils from "../../../../../../utils/FileUtils";
import StringUtils from "../../../../../../utils/StringUtils";
import { CBPortfolioTechnicalUnit } from "../../../../cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import {
  APContractActivity,
  APMaintenanceAppointment,
  MaintenanceProtocol,
} from "./APActivityMainteanceInterfaces";
import "./APActivityMaintenanceDoneAppointmentsList.scss";

interface APActivityMaintenanceDoneAppointmentsListProps {
  appointments: APMaintenanceAppointment[];
  activity: APContractActivity;
}
const APActivityMaintenanceDoneAppointmentsList = (
  props: APActivityMaintenanceDoneAppointmentsListProps
) => {
  const dispatch = useDispatch();
  return (
    <div
      className={classNames(`ap-activity-maintenance-done-appointments-list`)}
    >
      <table className={`table-of-appointments`}>
        <tr>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.doneAppointments.state",
              "Status"
            )}
          </th>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.doneAppointments.dueDate",
              "Fälligkeitsdatum"
            )}
          </th>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.doneAppointments.statusChangeNote",
              "Notiz"
            )}
          </th>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.doneAppointments.dateFinished",
              "Abgeschlossen"
            )}
          </th>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.doneAppointments.affectedTechnicalUnits",
              "Technische Einheit"
            )}
          </th>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.doneAppointments.protocol",
              "Protokoll"
            )}
          </th>
        </tr>
        {ArrayUtils.sortData(props.appointments, {
          key: "dueDate",
          dir: "asc",
        }).map((appointment) => {
          const status = appointment.status;

          const schedule = props.activity.data.schedulingRules.find(
            (e) => e.id === appointment.scheduleRuleId
          );
          return (
            <tr>
              <td>
                <DebugDataComponent data={appointment} />
                {status === "done" && (
                  <BFStatus
                    size="xs"
                    label={i18n.t(
                      "apTemplate:Activity.subtitles.maintenance.doneAppointments.done",
                      "Abgeschlossen"
                    )}
                    color="#49a43f"
                  />
                )}
                {status === "canceled" && (
                  <BFStatus
                    size="xs"
                    label={i18n.t(
                      "apTemplate:Activity.subtitles.maintenance.doneAppointments.canceled",
                      "Abgebrochen"
                    )}
                    color="#933e3e"
                  />
                )}
              </td>
              <td>{StringUtils.formatDate(appointment.dueDate)}</td>
              <td>{appointment.statusChangeNote || "-"}</td>
              <td>
                {status === "done"
                  ? StringUtils.formatDate(appointment.maintenanceDate)
                  : StringUtils.formatDate(appointment.statusChangeDate)}
              </td>
              <td>
                {appointment.affectedTechnicalUnits.map((technicalunitId) => (
                  <AssetCell
                    assetType={AssetTypes.Portfolio.TechnicalUnit}
                    id={technicalunitId}
                    render={(asset: CBPortfolioTechnicalUnit) => (
                      <>
                        <span className={`technical-unit-id`}>
                          {asset.data.id}
                        </span>
                        <span className={`technical-unit-name`}>
                          {LanguageService.translateLabel(
                            asset.data.displayName
                          )}
                        </span>
                      </>
                    )}
                  />
                ))}
              </td>
              <td>
                {appointment.status === "done" &&
                  appointment.maintenanceProtocolId && (
                    <AssetLoader
                      assetType={AssetTypes.ActivityRelated.MaintenanceProtocol}
                      id={appointment.maintenanceProtocolId}
                      render={(asset: MaintenanceProtocol) => {
                        return (
                          <BFButton
                            onClick={async () => {
                              //todo open document
                              const cdnItem = asset?.cdn.find(
                                (e) => e._id === asset.data.document.linkToCdn
                              );
                              const url = await CDNService.fetchCDNLink({
                                assetType:
                                  AssetTypes.ActivityRelated
                                    .MaintenanceProtocol,
                                assetId: asset?._id,
                                assetField: "data.file",
                                cdnId: asset.data.document.linkToCdn,
                                hasFolderReadPermissions: true,
                                fileKey: cdnItem?.key,
                              });
                              dispatch(
                                openOrDownloadDocument(
                                  url,
                                  FileUtils.mimeToExt(cdnItem.content_type)
                                )
                              );
                            }}
                            appearance="link"
                            noPadding
                          >
                            {i18n.t(
                              "apTemplate:Activity.subtitles.maintenance.doneAppointments.viewProtocol",
                              "Protokoll anzeigen"
                            )}
                          </BFButton>
                        );
                      }}
                    />
                  )}
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default APActivityMaintenanceDoneAppointmentsList;

import { useAppTheme } from "@/context/AppThemeContext";
import BfRadio from "@/modules/abstract-ui/forms/radio/BfRadio";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { useTranslation } from "react-i18next";
import { Dropdown } from "rsuite";
import { APP_THEMES_HEADER_CONFIG } from "./config";
import styles from "./HeaderThemeSwitcher.module.scss";

const HeaderThemeSwitcher = () => {
  const { t } = useTranslation();
  const { currentTheme, setAppTheme } = useAppTheme();

  // ! render
  return (
    <Dropdown.Menu
      icon={<BfIcon type="light" data="color-painting-palette" size="xs" />}
      title={t("Header.theme.title", "Farbschema")}
      className="pull-left"
    >
      {APP_THEMES_HEADER_CONFIG().map(({ key, labelTranslationKey }) => (
        <Dropdown.Item
          key={key}
          onSelect={() => setAppTheme(key)}
          // icon={<BfIcon type={icon.type} data={icon.data} />}
        >
          <div className={styles.option}>
            <div className={styles.name}>{t(labelTranslationKey)}</div>
            <BfRadio checked={currentTheme === key} />
          </div>
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );
};

export default HeaderThemeSwitcher;

import { AssetTypes } from "@/model/AssetTypes";
import {
  FloorPlanPositionsBase,
  FloorPlanPositionsData,
} from "@/apps/tatar/objectsApp/types/object.interface";
import MQ from "@/utils/MatchQueryUtils";
import DataBusDefaults from "@/services/DataBusDefaults";
import CacheService from "@/services/CacheService";
import SubmitService, { SubmitMessage } from "@/services/SubmitService";
import { HTTP, HttpInterface } from "@/utils/Http";

// ! types
interface FetchPositionArgs {
  assetId?: string;
  assetType?: string;
  floorPlanId?: string;
  cancelToken?: HttpInterface["cancelToken"];
}

// ! class
class FloorPlanPositionsServiceClass {
  fetchPosition({
    assetId,
    floorPlanId,
  }: FetchPositionArgs): Promise<FloorPlanPositionsBase | null> {
    return CacheService.getData({
      assetType: AssetTypes.Portfolio.FloorPlanLocation,
      query: MQ.and(
        MQ.ne("data.status", "archived"),
        MQ.when(floorPlanId, MQ.eq("data.floorPlanId", floorPlanId)),
        MQ.when(assetId, MQ.eq("data.assetLink.assetId", assetId))
      ),
      oType: "asset",
      forceReload: true,
    });
  }

  async fetchMultiplePositions({
    assetId,
    assetType,
    floorPlanId,
    cancelToken,
  }: FetchPositionArgs): Promise<FloorPlanPositionsBase[] | null> {
    const response = await HTTP.post({
      url: `asset/list/${AssetTypes.Portfolio.FloorPlanLocation}`,
      bodyParams: {
        matchQuery: MQ.and(
          MQ.ne("data.status", "archived"),
          MQ.when(floorPlanId, MQ.eq("data.floorPlanId", floorPlanId)),
          MQ.when(assetId, MQ.eq("data.assetLink.assetId", assetId)),
          MQ.when(assetType, MQ.eq("data.assetLink.assetType", assetType))
        ),
      },
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken,
    });

    return response?.data?.length ? response.data : null;
  }

  createPosition(position: FloorPlanPositionsData) {
    return this.makeRequest<FloorPlanPositionsBase>({ data: position });
  }

  updatePosition(
    position: Partial<FloorPlanPositionsData>,
    positionBaseId: string
  ): Promise<FloorPlanPositionsBase> {
    return this.makeRequest<FloorPlanPositionsBase>({
      data: position,
      _id: positionBaseId,
    });
  }

  deletePosition(positionBaseId: string): Promise<FloorPlanPositionsBase> {
    return this.updatePosition({ status: "archived" }, positionBaseId);
  }

  deleteMultiplePositions(
    positionBaseIds: string[]
  ): Promise<FloorPlanPositionsBase[]> {
    return Promise.all(positionBaseIds.map((id) => this.deletePosition(id)));
  }

  private async makeRequest<ReturnType>(
    data: SubmitMessage["data"],
    identifiersToUpdate?: string[]
  ) {
    const result = await SubmitService.submitDataAsync(
      {
        type: "asset",
        assetType: AssetTypes.Portfolio.FloorPlanLocation,
        data,
        ignorePropChecks: true,
        ignoreSubmitValidation: true,
      },
      true
    );

    if (identifiersToUpdate) {
      DataBusDefaults.reload({ identifiers: identifiersToUpdate });
    }

    return result as ReturnType;
  }
}

// ! instance
const FloorPlanPositionsService = new FloorPlanPositionsServiceClass();

export default FloorPlanPositionsService;

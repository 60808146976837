import flatten from "flat";
import ExpressionHelper from "./ExpressionHelper";

const JSON_PARSE_FUNC_PREFIX = "__JS_FUNC__";

class ObjectToolsClass {
  select(field: string, obj: any) {
    if (obj && typeof obj === "object" && !Array.isArray(obj)) {
      const flat = flatten(obj, {
        safe: true,
      });
      return flat[field];
    }
    return undefined;
  }
  flatten(
    obj: Object,
    options?: {
      delimiter?: string;
      safe?: boolean;
      maxDepth?: number;
    }
  ) {
    return flatten.flatten(obj, options);
  }

  unflatten(
    obj: Object,
    options?: {
      delimiter?: string;
      object?: boolean;
      overwrite?: boolean;
    }
  ) {
    return flatten.unflatten(obj, options);
  }

  subtractObject(
    obj1: Object,
    obj2: Object,
    arrayObjEqFc: (key, obj1, obj2) => boolean,
    ...ignoreKeys: string[]
  ): Object {
    const resultObj = {};

    Object.keys(obj1).forEach((key) => {
      if (ignoreKeys.indexOf(key) === -1) {
        if (obj2[key]) {
          //check if both are objects
          if (this.isObject(obj1[key]) && this.isObject(obj2[key])) {
            resultObj[key] = this.subtractObject(
              obj1[key],
              obj2[key],
              arrayObjEqFc,
              ...ignoreKeys
            );
          } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
            resultObj[key] = obj1[key]
              .map((entry) => {
                const other = obj2[key].find((entry2) =>
                  arrayObjEqFc(key, entry, entry2)
                );
                if (other) {
                  return this.subtractObject(
                    entry,
                    other,
                    arrayObjEqFc,
                    ...ignoreKeys
                  );
                } else {
                  return entry;
                }
              })
              .filter((entry) => Object.keys(entry).length !== 0);
          }
          //else do not add
        } else {
          resultObj[key] = obj1[key];
        }
      } else {
        resultObj[key] = obj1[key];
      }
    });

    return resultObj;
  }

  mergeObjects(obj1: Object, obj2: Object, ignoreUndefined?: boolean) {
    const resultObj = {};
    if (obj1) {
      Object.keys(obj1).forEach((key) => {
        resultObj[key] = obj1[key];
      });
    }
    if (obj2) {
      Object.keys(obj2).forEach((key) => {
        if (resultObj[key] === undefined) {
          resultObj[key] = obj2[key];
        } else if (resultObj[key] !== obj2[key]) {
          if (ignoreUndefined && obj2[key] === undefined) {
          } else {
            if (this.isObject(resultObj[key]) && this.isObject(obj2[key])) {
              resultObj[key] = this.mergeObjects(resultObj[key], obj2[key]);
            } else {
              resultObj[key] = obj2[key];
            }
          }
        }
      });
    }

    return resultObj;
  }

  isEmpty(obj) {
    if (typeof obj === "object" && !Array.isArray(obj)) {
      return Object.keys(obj).length > 0;
    }
    return false;
  }
  isObject(obj) {
    return !Array.isArray(obj) && obj === Object(obj);
  }

  toJSON(obj) {
    return JSON.stringify(obj, (key, value) => {
      if (typeof value === "function" || value.constructor === RegExp) {
        return JSON_PARSE_FUNC_PREFIX + value.toString();
      } else {
        return value;
      }
    });
  }
  fromJSON(str: string) {
    return JSON.parse(str, (key, value) => {
      if (
        typeof value === "string" &&
        value.indexOf(JSON_PARSE_FUNC_PREFIX) === 0
      ) {
        return eval(value.substr(JSON_PARSE_FUNC_PREFIX.length));
      }
      return value;
    });
  }
  readJsonFunctions(obj: Object) {
    if (!ObjectTools.isObject(obj)) {
      return obj;
    }
    const output = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (
        typeof value === "string" &&
        value.indexOf(JSON_PARSE_FUNC_PREFIX) === 0
      ) {
        output[key] = ExpressionHelper.registerFunction(
          eval(value.substr(JSON_PARSE_FUNC_PREFIX.length))
        );
      } else if (Array.isArray(value)) {
        output[key] = value.map((val) => ObjectTools.readJsonFunctions(val));
      } else if (ObjectTools.isObject(value)) {
        output[key] = ObjectTools.readJsonFunctions(value);
      } else {
        output[key] = value;
      }
    });

    return output;
  }
}

const ObjectTools = new ObjectToolsClass();
(window as any).objectTools = ObjectTools;
export default ObjectTools;

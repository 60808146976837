import i18n from "../../../../../i18n";
import { MatchQuery } from "../../../../../services/DataService";
import { BfIconProps } from "../../../icon/BfIcon";
import { FilterOptionWithMatchQueryConverter } from "../BFTableFilterModel";
import OverlayMultipleSelectionWithNegation, {
  ListSelectionValueWithNegation,
} from "../overlays/OverlayMultipleSelectionWithNegation";

type ListEntry = {
  label: string;
  value: string;
  data?: any;
  color?: string;
  subLabel?: string;
};

export const ListFilterOption: (opts: {
  field: string;
  label: string;
  icon?: BfIconProps;
  data: ListEntry[];
  allowNegation?: boolean;
  matchQueryConverter?: (
    value: ListSelectionValueWithNegation
  ) => MatchQuery | null;
}) => FilterOptionWithMatchQueryConverter = (opts) => {
  const { field, label, icon, data } = opts;

  const matchQueryConverter =
    opts.matchQueryConverter ||
    ((value: ListSelectionValueWithNegation) =>
      value?.value?.length > 0
        ? {
            type: "op",
            op: value.negate ? "nin" : "in",
            name: field,
            value: value.value,
          }
        : null);
  return {
    matchQueryConverter: matchQueryConverter,
    key: field,
    label: label,
    icon: {
      type: icon?.type || "light",
      data: icon?.data || "list-bullets-1",
    },
    renderOverlay: (
      value: ListSelectionValueWithNegation,
      onChange: (value: ListSelectionValueWithNegation) => void
    ) => (
      <OverlayMultipleSelectionWithNegation
        title={label}
        entries={data}
        value={value}
        onChange={onChange}
        allowNegation={opts.allowNegation}
      />
    ),
    defaultValue: {
      negate: false,
      value: [],
    },
    renderValueData: (value: ListSelectionValueWithNegation) => ({
      typeLabel: label,
      valueLabel: value?.value
        ? value?.value?.length === 1
          ? `${value.negate ? `${i18n.t("Global.Label.Not", "Nicht")}: ` : ""}${
              data.find((e) => e.value === value?.value[0])?.label
            }`
          : `${value.negate ? `${i18n.t("Global.Label.Not", "Nicht")}: ` : ""}${
              value?.value?.length
            }/${data.length}`
        : null,
    }),
    getOptions: (currentValue: ListSelectionValueWithNegation, searchTerm) => {
      return data
        .filter((e) => (currentValue?.value || []).indexOf(e.value) === -1)
        .map((entry, index) => ({
          filterKey: field,
          typeLabel: label,
          label: entry.label,
          labelAsString: entry.label,
          value: entry.value,
          valueKey: entry.value,
          onSelect: (
            optionValue,
            currentValue: ListSelectionValueWithNegation,
            onChange
          ) => {
            onChange(
              currentValue
                ? {
                    ...currentValue,
                    value: [...currentValue.value, optionValue],
                  }
                : { megate: false, value: [optionValue] }
            );
          },
        }));
    },
  };
};

import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import moment from "moment";
import { InfiniteTableSortOption } from "../../configurable/data/SortComponent/BaseTableSort";
import i18n from "../../i18n";
import { AssetTypes } from "../../model/AssetTypes";
import BaseAsset from "../../model/general-assets/BaseAsset";
import LanguageService from "../../services/LanguageService";
import { FilterOptionWithMatchQueryConverter } from "../abstract-ui/data/table-filter/BFTableFilterModel";
import { ListFilterOption } from "../abstract-ui/data/table-filter/defaults/ListFilterOption";
import OverlayDateSelection, {
  DefaultDateSelectionValue,
  generateLabelForDateFilter,
  generateMatchQueryForDateFilter,
  getDefaultDateOptions,
} from "../abstract-ui/data/table-filter/overlays/OverlayDateSelection";
import OverlayMultipleSelection from "../abstract-ui/data/table-filter/overlays/OverlayMultipleSelection";
import CommentUtils from "./CommentUtils";
import { CommentTypes } from "./Comments";

export const CommentsListSortOptions: InfiniteTableSortOption[] = [
  {
    definition: {
      key: "data.date",
      dir: "desc",
    },
    label: () => i18n.t("CommentsModule.SortNewestFirst", "Datum absteigend"),
    icon: "arrange-list-descending",
  },
  {
    definition: {
      key: "data.date",
      dir: "asc",
    },
    label: () => i18n.t("CommentsModule.SortOldestFirst", "Datum aufsteigend"),
    icon: "arrange-list-ascending",
  },
];

export const CommentsFilterOptions: (
  units: string[],
  asset?: BaseAsset,
  ignoreTypeSelection?: boolean,
  tagGroup?: string
) => FilterOptionWithMatchQueryConverter[] = (
  units,
  asset,
  ignoreTypeSelection,
  tagGroup
) => {
  const allContexts =
    asset?.communication?.subactivities?.map((subactivity) => ({
      label: subactivity.displayName,
      value: "" + subactivity.id,
    })) || [];

  const allCommentTypes = Object.values(CommentTypes).map((type) => ({
    label: CommentUtils.getLabelForCommentType(type),
    value: type,
  }));

  const categoryTags = UnitStruct.getTagConfigForUnits(
    units || [],
    AssetTypes.Comment,
    tagGroup
  );

  return [
    ...(asset
      ? [
          {
            key: "data.subactivityId",
            label: i18n.t("CommentsModule.Contexts", "Kontext"),
            icon: {
              type: "light",
              data: "bookmarks-document",
            },
            matchQueryConverter: (value: any) =>
              value
                ? {
                    type: "op",
                    op: "in",
                    name: "data.subactivityId",
                    value: value.map((e) => Number(e)),
                  }
                : null,
            renderOverlay: (value, onChange) => (
              <OverlayMultipleSelection
                title={i18n.t("CommentsModule.Contexts", "Kontext")}
                entries={allContexts}
                value={value}
                onChange={onChange}
              />
            ),
            renderValueData: (value) => ({
              typeLabel: i18n.t("CommentsModule.Contexts", "Kontext"),
              valueLabel: value
                ? value.length < 3
                  ? value
                      .map(
                        (valEntry) =>
                          allContexts.find((e) => e.value === valEntry)?.label
                      )
                      .join(", ")
                  : `${value.length}/${allContexts.length}`
                : null,
            }),
            getOptions: (currentValue, searchTerm) => {
              return allContexts
                .filter((e) => (currentValue || []).indexOf(e.value) === -1)
                .map((option, index) => ({
                  filterKey: "data.subactivityId",
                  typeLabel: i18n.t("CommentsModule.Contexts", "Kontext"),
                  label: option.label,
                  labelAsString: option.label,
                  value: option.value,
                  valueKey: option.value,
                  onSelect: (optionValue, currentValue, onChange) => {
                    onChange(
                      currentValue
                        ? [...currentValue, optionValue]
                        : [optionValue]
                    );
                  },
                }));
            },
          } as FilterOptionWithMatchQueryConverter,
        ]
      : []),

    ...(ignoreTypeSelection === true
      ? []
      : [
          {
            key: "data.type",
            label: i18n.t("CommentsModule.EntryType", "Nachrichtentyp"),
            icon: {
              type: "light",
              data: "filter-3",
            },
            matchQueryConverter: (value: any) =>
              value
                ? {
                    type: "op",
                    op: "in",
                    name: "data.type",
                    value: value.map((e) => e),
                  }
                : null,
            renderOverlay: (value, onChange) => (
              <OverlayMultipleSelection
                title={i18n.t("CommentsModule.EntryType", "Nachrichtentyp")}
                entries={allCommentTypes}
                value={value}
                onChange={onChange}
              />
            ),
            renderValueData: (value) => ({
              typeLabel: i18n.t("CommentsModule.EntryType", "Nachrichtentyp"),
              valueLabel: value
                ? value.length < 3
                  ? value
                      .map(
                        (valEntry) =>
                          allCommentTypes.find((e) => e.value === valEntry)
                            ?.label
                      )
                      .join(", ")
                  : `${value.length}/${allCommentTypes.length}`
                : null,
            }),
            getOptions: (currentValue, searchTerm) => {
              return allCommentTypes
                .filter((e) => (currentValue || []).indexOf(e.value) === -1)
                .map((option, index) => ({
                  filterKey: "data.type",
                  typeLabel: i18n.t(
                    "CommentsModule.EntryType",
                    "Nachrichtentyp"
                  ),
                  label: option.label,
                  labelAsString: option.label,
                  value: option.value,
                  valueKey: option.value,
                  onSelect: (optionValue, currentValue, onChange) => {
                    onChange(
                      currentValue
                        ? [...currentValue, optionValue]
                        : [optionValue]
                    );
                  },
                }));
            },
          } as FilterOptionWithMatchQueryConverter,
        ]),

    // {
    //   key: "data.typeData.sender.address",
    //   label: i18n.t("CommentsModule.MailSender", "Email-Absender"),
    //   icon: {
    //     type: "light",
    //     data: "email-action-send-1",
    //   },
    //   matchQueryConverter: (value: string[]) =>
    //     generateMatchQueryForEmailFilter("data.typeData.sender.address", value),
    //   renderOverlay: (value, onChange) => (
    //     <OverlayEmails
    //       title={i18n.t("CommentsModule.MailSender", "Email-Absender")}
    //       value={value}
    //       onChange={onChange}
    //     />
    //   ),
    //   renderValueData: (value) => ({
    //     typeLabel: i18n.t("CommentsModule.MailSender", "Email-Absender"),
    //     valueLabel: generateLabelForEmailFilter(value),
    //   }),
    //   getOptions: (currentValue, searchTerm) => [],
    //   // getOptions: (currentValue, searchTerm) =>
    //   //   getDefaultDateOptions(
    //   //     currentValue,
    //   //     searchTerm,
    //   //     "data.typeData.sender.address",
    //   //     i18n.t("CommentsModule.MailSender", "Email-Absender"),
    //   //     (value) =>
    //   //       moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
    //   //   ),
    // },
    {
      matchQueryConverter: (value: any) =>
        generateMatchQueryForDateFilter("data.date", value),
      key: "data.date",
      label: i18n.t("CommentsModule.Date", "Datum"),
      icon: {
        type: "light",
        data: "calendar",
      },
      defaultValue: DefaultDateSelectionValue,
      renderOverlay: (value, onChange) => (
        <OverlayDateSelection
          title={i18n.t("CommentsModule.Date", "Datum")}
          value={value}
          onChange={onChange}
        />
      ),
      renderValueData: (value) => ({
        typeLabel: i18n.t("CommentsModule.Date", "Datum"),
        valueLabel: generateLabelForDateFilter(value, (value) =>
          moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
        ),
      }),
      getOptions: (currentValue, searchTerm) =>
        getDefaultDateOptions(
          currentValue,
          searchTerm,
          "data.date",
          i18n.t("CommentsModule.Date", "Datum"),
          (value) =>
            moment(value).utc(true).format(i18n.t("Formats.dateFormat"))
        ),
    },
    ...(categoryTags.length > 0
      ? [
          ListFilterOption({
            field: "data.tags",
            label: i18n.t("CommentsModule.Categories", "Kategorien"),
            data: categoryTags.map((e) => ({
              label: LanguageService.translateLabel(e.displayName),
              value: e.id,
              color: e.color,
            })),
          }),
        ]
      : []),
  ];
};

import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../utils/abstracts/AbstractStylableComponent";
import DivLayout from "../layouts/DivLayout/DivLayout";

type Props = {
  breakpointMobile?: number | "xs" | "sm" | "md" | "lg";
  breakpointMiddle?: number | "xs" | "sm" | "md" | "lg";
  textKey?: string;
  text?: string;
  iconData: string;
  iconRotation?: number;
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {} & AbstractStylableStates;

class TabButtonDefault extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    const {
      breakpointMobile,
      breakpointMiddle,
      textKey,
      text,
      iconData,
      iconRotation,
    } = this.props;

    const config = {
      style: [
        {
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "70px",
          fontSize: 14,
        },
      ],
      components: {},
    };
    if (breakpointMobile) {
      config["style"].push({
        breakpoint: breakpointMobile,
        minWidth: "40px",
      } as any);
    }
    if (breakpointMiddle) {
      config["style"].push({
        breakpoint: breakpointMiddle,
        minWidth: "50px",
      } as any);
    }

    if (iconData) {
      config.components["icon"] = {
        _component: "icon",
        type: "bf",
        data: iconData,
        size: "xl",
        style: [
          {
            marginTop: 5,
            transform: "rotate(" + (iconRotation ? iconRotation : 0) + "deg)",
          },
        ],
      };
      if (breakpointMobile) {
        config.components["icon"].style.push({
          breakpoint: breakpointMobile,
          marginTop: 0,
          paddingLeft: 5,
          paddingRight: 5,
        });
      }

      // if (breakpointMiddle) {
      //     config.components["icon"].style.push({
      //         "breakpoint": breakpointMiddle,
      //         "marginTop": 0,
      //         "paddingLeft": 5,
      //         "paddingRight": 5
      //     } as any);
      // }
    }
    if (text || textKey) {
      config.components["text"] = {
        _component: "simpleText",
        type: "span",
        textKey: textKey,
        text: text,
        style: [
          {
            paddingTop: 4,
          },
        ],
      };
      if (breakpointMobile) {
        config.components["text"].style.push({
          breakpoint: breakpointMobile,
          display: "none",
        });
      }
      if (breakpointMiddle) {
        config.components["text"].style.push({
          breakpoint: breakpointMiddle,
          fontSize: "0.8em",
        });
      }
    }

    return <DivLayout {...config} />;
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(TabButtonDefault))
);

import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
// import "./CBAgreementAutomatic.scss";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import {
  RentalAgreement,
  RentalAgreementAutomatic,
} from "../../../TenantsInterfaces";
import { CBRentalAgreementAutomaticUpdateForm } from "../form-components/CBRentalAgreementAutomaticForm";

export type AutomaticType = {
  automatic: null | RentalAgreementAutomatic;
};
interface CBAgreementAutomaticProps {
  data: AutomaticType;
  marks: PDFEditRect[] | PDFDocumentRect[];
  hideIfEmpty?: boolean;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
}
const CBAgreementAutomatic = (props: CBAgreementAutomaticProps) => {
  if (props.hideIfEmpty && !props.data.automatic) {
    return null;
  }
  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={props.marks}
      title={
        <>
          <span className={`padding-right-6`}>
            {i18n.t(
              "cb:RentalAgreement.Form.Sections.automatic.title",
              "Auszugsautomatik"
            )}
          </span>
          {props.rentalAgreement && props.editable && (
            <BFButton
              noPadding
              inline
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  size: "fluid",
                  noPadding: true,
                  content: (state, setState, close) => {
                    return (
                      <CBRentalAgreementAutomaticUpdateForm
                        rentalAgreement={props.rentalAgreement}
                        onClose={close}
                      />
                    );
                  },
                });
              }}
              size="sm"
            >
              {i18n.t("Global.Buttons.Customize", "Anpassen")}
            </BFButton>
          )}
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.automatic.viewHint",
        "Informationen zur Auszugsautomatik"
      )}
      identifier="automatic"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      <>
        {props.data.automatic ? (
          <div className={`__flex-wrap`}>
            <BFValueDisplay
              label={i18n.t(
                "cb:RentalAgreement.Labels.Automatic.monthBeforeContractEnd",
                "Vor Auszug"
              )}
              value={props.data.automatic.monthBeforeContractEnd}
              formatter={(value) =>
                `${value} ${i18n.t("Global.Labels.months", "Monate")}`
              }
            />
            <BFValueDisplay
              label={i18n.t(
                "cb:RentalAgreement.Labels.Automatic.extendByMonths",
                "Verlängern um"
              )}
              value={props.data.automatic.extendByMonths}
              formatter={(value) =>
                `${value} ${i18n.t("Global.Labels.months", "Monate")}`
              }
            />
          </div>
        ) : (
          <div className={`__empty no-padding`}>
            {i18n.t(
              "cb:RentalAgreement.Labels.NoAutomatic",
              "Keine Auszugsautomatik hinterlegt"
            )}
          </div>
        )}
      </>
    </BFPDFMarkerSection>
  );
};

export default CBAgreementAutomatic;

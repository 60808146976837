import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { ContactPhone } from "@/model/db/Contact";
import BFEntries from "@/modules/abstract-ui/data/entries/BFEntries";
import Tools from "@/utils/Tools";
import classNames from "classnames";
import { useRef } from "react";

interface ContactBasePhoneProps {
  phones: ContactPhone[];
}
const ContactBasePhone = (props: ContactBasePhoneProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className={classNames(`contact-base-emails`)} ref={ref}>
      <BFEntries
        emptyText={i18n.t(
          "ContactBaseDataView.noPhone",
          "Keine Telefonnummer vorhanden"
        )}
        entries={props.phones.map((phone) => {
          const addPhoneToClipboard = () => {
            const textToCopy = phone.phone;
            Tools.copy2Clipboard(
              textToCopy,
              i18n.t(
                "Contact.Phone.copiedToClipboard",
                "Telefonnummer in Zwischenablage kopiert"
              ),
              ref.current
            );
          };

          return {
            content: (
              <>
                <DebugDataComponent data={phone} />
                <div className={`phone`}>{phone.phone}</div>
                {phone.note && (
                  <div className={`note __sub-text margin-top-5`}>
                    {phone.note}
                  </div>
                )}
              </>
            ),
            actions: [
              {
                icon: "duplicate-file",
                onClick: addPhoneToClipboard,
                tooltip: i18n.t(
                  "ContactBasePhone.copyToClipboard",
                  "In Zwischenablage kopieren"
                ),
              },
              {
                icon: "phone-actions-call",
                href: `tel:${phone.phone}`,
                tooltip: i18n.t("ContactBasePhone.call", "Anrufen"),
              },
            ],
          };
        })}
      />
    </div>
  );
};

export default ContactBasePhone;

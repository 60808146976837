import _ from "lodash";
import ObjectTools from "../../../modules/generic-forms/util/ObjectTools";
import DataBus from "../../../services/DataBus";
import { DataBusSubKeys } from "../../../utils/Constants";
import {
  ApplicationAction,
  CLEAR_ALL_CACHES,
  CLEAR_APPLICATION_DATA,
  CLEAR_FLEX_CACHE_DATA,
  DATA_QUERY_DEPRECATE,
  DATA_QUERY_SET_DATA,
  DATA_QUERY_SET_ERROR,
  DATA_QUERY_SET_LOADING,
  PATCH_TABLE_ROW_DATA,
  REMOVE_TABLE_NEW_DATA,
  RESET_CACHE_COMPLETE,
  RESET_LOG_DATA,
  SET_APPICATION_CACHE_DATA,
  SET_APPICATION_CACHE_DEPRECATED,
  SET_APPICATION_CACHE_ERROR,
  SET_APPICATION_CACHE_LOADING,
  SET_CONSTANT_DATA,
  SET_FLEX_CACHE_DATA,
  SET_FLEX_CACHE_DATA_MULTIPLE,
  SET_LOG_DATA,
  SET_LOG_LOADING,
  SET_RELOAD_TABLE,
  SET_TABLE_DATA,
  SET_TABLE_EVENT,
  SET_TABLE_FILTER,
  SET_TABLE_FULLTEXT_SEARCH,
  SET_TABLE_LOADING,
  SET_TABLE_NEW_DATA,
  SET_TABLE_SORT,
} from "../../actions/application/application-action-types";
import { InfiniteTableActions } from "../../actions/application/application-infinite-table-action-types";
import { AppState } from "../../store";
import { ApplicationReducer, CacheDataSuccess } from "./ApplicationInterface";
import InfiniteTableReducer from "./InfiniteTableReducer";

export const initialState: ApplicationReducer = {
  tables: {},
  infiniteTables: {},
  cache: {
    team: {},
    user: {},
    group: {},
    asset: {},
    flex: {},
    query: {},
  },
  logs: {},
  constants: {},
};

const reduceToGlobalCache = (state: ApplicationReducer) => {
  return {
    ...state,
    tables: initialState.tables,
    infiniteTables: initialState.infiniteTables,
    cache: Object.fromEntries(
      Object.entries(state.cache).map(([key, value]) => {
        const data = Object.fromEntries(
          Object.entries(value).filter(([id, entry]) => entry.global)
        );
        return [key, data];
      })
    ),
  } as ApplicationReducer;
};
export default function (
  state = initialState,
  action: ApplicationAction,
  root: AppState
): ApplicationReducer {
  if (action.type.startsWith("INFINITE_TABLE")) {
    return InfiniteTableReducer(state, action as InfiniteTableActions, root);
  }
  switch (action.type) {
    /** see InfiniteTableReducer for explanation */
    case CLEAR_ALL_CACHES:
      return reduceToGlobalCache(state);

    case SET_FLEX_CACHE_DATA:
      return {
        ...state,
        cache: {
          ...state.cache,
          flex: {
            ...state.cache.flex,
            [action.category]: {
              ...(state.cache.flex[action.category]
                ? state.cache.flex[action.category]
                : {}),
              [action.identifier]: action.data,
            },
          },
        },
      };
    case SET_FLEX_CACHE_DATA_MULTIPLE:
      let flexCache = { ...state.cache.flex };

      action.flexData.forEach((e) => {
        flexCache = {
          ...flexCache,
          [e.category]: {
            ...(flexCache[e.category] ? flexCache[e.category] : {}),
            [e.identifier]: e.data,
          },
        };
      });

      return {
        ...state,
        cache: {
          ...state.cache,
          flex: flexCache,
        },
      };
    case CLEAR_FLEX_CACHE_DATA:
      return {
        ...state,
        cache: {
          ...state.cache,
          flex: {
            ...state.cache.flex,
            [action.category]: {},
          },
        },
      };
    case SET_CONSTANT_DATA:
      return {
        ...state,
        constants: {
          ...state.constants,
          [action.key]: action.value,
        },
      };
    case RESET_CACHE_COMPLETE:
      const stateNew = {
        ...initialState,
      };
      return stateNew;
    case RESET_LOG_DATA:
      const newLogState = {
        ...state,
        logs: {
          ...state.logs,
        },
      };
      action.identifiers.forEach(
        (identifier) => (newLogState.logs[identifier] = undefined)
      );
      return newLogState;
    case SET_LOG_LOADING:
      const oldLog = state.logs[action.logIdentifier]
        ? state.logs[action.logIdentifier]
        : { data: [] };
      return {
        ...state,
        logs: {
          ...state.logs,
          [action.logIdentifier]: {
            ...oldLog,
            loading: action.mode,
          },
        },
      };
    case SET_LOG_DATA:
      const oldData = state.logs[action.logIdentifier]
        ? state.logs[action.logIdentifier].data
        : [];
      let newData = [];
      switch (action.mode) {
        case "replace":
          newData = action.data;
          break;
        case "append":
          // newData = [...(oldData.slice(oldData.length - allowedSize, oldData.length)), ...action.data]
          newData = [...oldData, ...action.data];
          break;
        case "prepend":
          // newData = [...action.data, ...(oldData.slice(0, allowedSize))]
          newData = [...action.data, ...oldData];
          break;
      }

      return {
        ...state,
        logs: {
          ...state.logs,
          [action.logIdentifier]: {
            loading: null,
            timestamp: Number(new Date()),
            params: action.params
              ? action.params
              : state.logs[action.logIdentifier]
              ? state.logs[action.logIdentifier].params
              : {},
            data: newData,
          },
        },
      };

    case CLEAR_APPLICATION_DATA:
      const paths = action.paths;
      const stateCopy = { ...state };
      // removes some application data
      for (const path of paths) {
        // steps are "."-seprated
        const steps = path.split(".");

        let tmpState = stateCopy;
        for (let i = 0; i < steps.length; i++) {
          const nextPathStep = tmpState[steps[i]];

          if (tmpState === undefined) {
            break;
          } else if (i < steps.length - 1) {
            tmpState[steps[i]] = {
              ...nextPathStep,
            };
            tmpState = tmpState[steps[i]];
          } else {
            tmpState[steps[i]] = undefined;
          }
        }
      }
      return stateCopy;

    case SET_APPICATION_CACHE_DATA:
      setTimeout(() => {
        DataBus.emit(DataBusSubKeys.CACHE_CHANGED, {
          oType: action.oType,
          assetType: action.assetType,
          id: action.id,
          data: action.data,
        });
      });
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.oType !== "asset" ? action.oType : action.assetType]: {
            ...state.cache[
              action.oType !== "asset" ? action.oType : action.assetType
            ],
            [action.id]: {
              state: "cached",
              ttl: action.ttl,
              timestamp: Number(new Date()),
              data: action.data,
              deprecated: false,
              global: action.global,
            },
          },
        },
      };
    case SET_APPICATION_CACHE_DEPRECATED:
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.oType !== "asset" ? action.oType : action.assetType]: {
            ...state.cache[
              action.oType !== "asset" ? action.oType : action.assetType
            ],
            [action.id]: {
              ...(state.cache[
                action.oType !== "asset" ? action.oType : action.assetType
              ]?.[action.id] || {}),
              deprecated: true,
              data: {
                ...(state.cache[
                  action.oType !== "asset" ? action.oType : action.assetType
                ]?.[action.id] &&
                state.cache[
                  action.oType !== "asset" ? action.oType : action.assetType
                ]?.[action.id].state === "cached"
                  ? (
                      state.cache[
                        action.oType !== "asset"
                          ? action.oType
                          : action.assetType
                      ][action.id] as CacheDataSuccess<any>
                    ).data
                  : {}),
                ...(action.changeCmd ? { _dirty: action.changeCmd } : {}),
              },
            },
          },
        },
      };
    case SET_APPICATION_CACHE_LOADING:
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.oType !== "asset" ? action.oType : action.assetType]: {
            ...state.cache[
              action.oType !== "asset" ? action.oType : action.assetType
            ],
            [action.id]: {
              state: "loading",
            },
          },
        },
      };
    case SET_APPICATION_CACHE_ERROR:
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.oType !== "asset" ? action.oType : action.assetType]: {
            ...state.cache[
              action.oType !== "asset" ? action.oType : action.assetType
            ],
            [action.id]: {
              state: "error",
              error: action.error,
            },
          },
        },
      };

    case SET_RELOAD_TABLE:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableIdentifier]: {
            ...state.tables[action.tableIdentifier],
            reload: action.reload,
          },
        },
      };
    case SET_TABLE_FULLTEXT_SEARCH:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableIdentifier]: {
            ...state.tables[action.tableIdentifier],
            fulltextSearch: action.fulltextSearch,
          },
        },
      };
    case SET_TABLE_FILTER:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableIdentifier]: {
            ...state.tables[action.tableIdentifier],
            filters: {
              ...state.tables[action.tableIdentifier].filters,
              [action.dataKey]: action.filter,
            },
          },
        },
      };
    case SET_TABLE_SORT:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableIdentifier]: {
            ...state.tables[action.tableIdentifier],
            sort: action.sort,
          },
        },
      };
    case SET_TABLE_LOADING:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableIdentifier]: {
            ...state.tables[action.tableIdentifier],
            loading: action.loading,
          },
        },
      };
    case SET_TABLE_DATA:
      let data = action.data.data;
      if (
        action.data.append &&
        state.tables[action.tableIdentifier] &&
        state.tables[action.tableIdentifier].data
      ) {
        data = [...state.tables[action.tableIdentifier].data, ...data];
      }

      let tableData = state.tables[action.tableIdentifier] || ({} as any);

      tableData = ObjectTools.mergeObjects(tableData, {
        ...action.data,
        data: data,
        newDataIDs: action.data.append ? tableData.newDataIDs : [],
      }) as any;

      tableData.timestamp = new Date();
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableIdentifier]: tableData,
          //  {
          // 	events: {
          // 		...(state.tables[action.tableIdentifier] ? state.tables[action.tableIdentifier].events : {})
          // 	}, //new data resets all events on the table
          // 	data: data,
          // 	url: action.data.url ? action.data.url : state.tables[action.tableIdentifier].url,
          // 	total: action.data.total,
          // 	skip: action.data.skip,
          // 	limit: action.data.limit,
          // 	filters: action.data.filters,
          // 	sort: action.data.sortKey,
          // 	timestamp: new Date(),
          // 	fulltextSearch: action.data.fulltextSearch
          // }
        },
      };
    case REMOVE_TABLE_NEW_DATA:
      if (!state.tables[action.tableIdentifier]) {
        return state;
      }
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableIdentifier]: {
            ...state.tables[action.tableIdentifier],
            newDataIDs: (
              state.tables[action.tableIdentifier].newDataIDs || []
            ).filter((id) => id !== action.assetId),
          },
        },
      };
    case SET_TABLE_NEW_DATA:
      if (!state.tables[action.tableIdentifier]) {
        return state;
      }
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableIdentifier]: {
            ...state.tables[action.tableIdentifier],
            newDataIDs: [
              ...(state.tables[action.tableIdentifier].newDataIDs || []),
              action.assetId,
            ],
          },
        },
      };
    case PATCH_TABLE_ROW_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableIdentifier]: {
            ...state.tables[action.tableIdentifier],
            data: state.tables[action.tableIdentifier]?.data
              ? state.tables[action.tableIdentifier].data.map((entry) => {
                  if (entry["_id"] === action.rowId) {
                    if (action.mode === "overwrite") {
                      return action.data;
                    } else if (action.mode === "merge") {
                      return _.merge({}, entry, action.data);
                    } else {
                      return {
                        ...entry,
                        ...action.data,
                      };
                    }
                  } else {
                    return entry;
                  }
                })
              : [],
          },
        },
      };
    case SET_TABLE_EVENT:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.tableIdentifier]: {
            ...state.tables[action.tableIdentifier],
            events: {
              ...(state.tables[action.tableIdentifier]
                ? state.tables[action.tableIdentifier].events
                : {}),
              [action.event]: action.data,
            },
          },
        },
      };

    case DATA_QUERY_SET_LOADING: {
      return {
        ...state,
        cache: {
          ...state.cache,
          query: {
            ...state.cache.query,
            [action.queryId]: {
              ...(state.cache.query[action.queryId] || {}),
              loading: true,
              referenceId: action.referenceId,
            },
          },
        },
      };
    }
    case DATA_QUERY_SET_DATA: {
      return {
        ...state,
        cache: {
          ...state.cache,
          query: {
            ...state.cache.query,
            [action.queryId]: {
              ...(state.cache.query[action.queryId] || {}),
              loading: false,
              data: action.data,
              deprecateOn: action.deprecateOn,
              referenceId: action.referenceId,
            },
          },
        },
      };
    }
    case DATA_QUERY_DEPRECATE: {
      return {
        ...state,
        cache: {
          ...state.cache,
          query: {
            ...state.cache.query,
            [action.queryId]: {
              ...(state.cache.query[action.queryId] || { loading: false }),
              deprecateOn: new Date(),
            },
          },
        },
      };
    }
    case DATA_QUERY_SET_ERROR: {
      return {
        ...state,
        cache: {
          ...state.cache,
          query: {
            ...state.cache.query,
            [action.queryId]: {
              ...(state.cache.query[action.queryId] || {}),
              // deprecateOn: new Date(),
              error: action.error,
              data: null,
              loading: false,
              referenceId: action.referenceId,
            },
          },
        },
      };
    }
    default:
      return state;
  }
}

import CustomFields from "@/modules/customfields/CustomFields";
import CustomFieldsService from "@/modules/customfields/CustomFieldsService";
import EZGroup from "@/modules/ez-form/form-group/ez-form/EZGroup";
import CacheService from "@/services/CacheService";
import { HTTP } from "@/utils/Http";
import { ActivityApplicationConstants } from "../../../ActivityHooks";
import { APActivity } from "../../../ActivityInterfaces";
import { APDunningActivity } from "../../../specific/dunning/dunning.interface";
// import "./APBaseData.scss";
import APDunningBaseData from "./APDunningBaseData";

interface APBaseDataProps {
  activity: APActivity;
  constants: ActivityApplicationConstants;
}

const convertDunningInitialData = (activity: APDunningActivity) => {
  if (!activity.data.dunning) {
    return undefined;
  } else {
    const {
      automatic,
      calculationDefaultInterest,
      defaultInterestDate,
      ...otherProps
    } = activity.data.dunning;
    return otherProps;
  }
};
const APBaseData = (props: APBaseDataProps) => {
  return (
    <EZGroup
      value={{
        dunning: convertDunningInitialData(props.activity as APDunningActivity),
        meta: props.activity.data.meta,
      }}
      // url={`/${props.constants?.serviceUrl}/${props.activity._id}/setBaseData`}
      // onSubmitSuccess={(value) =>
      //   CacheService.updateDataInCaches(value._id, value)
      // }
      onSubmit={async (value) => {
        await CustomFieldsService.handleCustomFieldAttachments({
          assetType: props.constants?.assetType,
          values: value,
          customFields: props.constants.fields.customFields,
          prefix: "meta",
          asset: props.activity,
          submitFc: async (values) => {
            const result = (await HTTP.post({
              url: `/${props.constants?.serviceUrl}/${props.activity._id}/setBaseData`,
              target: "EMPTY",
              bodyParams: {
                ...values,
              },
            })) as APActivity;
            CacheService.update(result);
            return result;
          },
        });
      }}
    >
      {(renderProps) => (
        <>
          <APDunningBaseData
            asset={props.activity as APDunningActivity}
            dunningOptions={props.constants?.fields?.dunning}
          />
          <CustomFields
            fields={props.constants?.fields?.customFields}
            asset={props.activity as APActivity}
            assetType={props.constants?.assetType}
            prefix="meta"
          />
        </>
      )}
    </EZGroup>
  );
};

export default APBaseData;

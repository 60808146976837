import React from "react";
import Collapse from "rsuite/esm/Animation/Collapse";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import DebugDataComponent from "../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../i18n";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import { BidlyAssignAction } from "../CBIdlyBookingsConst";
import { CBIdlyConnectProps } from "./CBIdlyConnect";
import "./CBIdlyConnectSuggestion.scss";
import CBIdlyCreateRule from "./CBIdlyCreateRule";
import CBidlyConnectionSuggestionHint from "./CBidlyConnectionSuggestionHint";

const CBIdlyConnectSuggestion = (
  props: {
    suggestion: any;
    onUpdateSuggestion: (suggestion: any) => void;
  } & CBIdlyConnectProps
) => {
  const [ruleCreated, setRuleCreated] = React.useState(false);
  const suggestion = props.suggestion;
  return (
    <div className={`idly-suggestion-entry`}>
      <DebugDataComponent data={suggestion} />
      <div className={`suggestion-data`}>
        {props.renderSuggestion(suggestion, props.onUpdateSuggestion)}
      </div>
      <div className={`suggestion-action`}>
        <CBidlyConnectionSuggestionHint
          hints={props.renderSuggestionHint(suggestion)}
        />
        <BFButton
          disabled={
            props.connectionActionDisabledState &&
            !props.isConnected(suggestion)
              ? props.connectionActionDisabledState(suggestion)
              : false
          }
          className={`suggestion-action-button`}
          appearance={props.isConnected(suggestion) ? "outline" : "primary"}
          onClick={async () => {
            if (props.isConnected(suggestion)) {
              await props.disconnectActionPromise(suggestion);
            } else {
              await props.connectActionPromise(suggestion);
            }
          }}
        >
          {props.isConnected(suggestion)
            ? props.disconnectActionLabel
            : props.connectActionLabel}
        </BFButton>
        {(props.ruleActionPromise || props.ruleActionData) && (
          <>
            <Collapse in={!ruleCreated && props.isConnected(suggestion)}>
              <div>
                {/* ruleActionPromise */}

                <BFButton
                  className={`ruleset-action-button`}
                  appearance={"link"}
                  // appearance={"primary"}
                  onClick={async () => {
                    if (props.ruleActionPromise) {
                      await props.ruleActionPromise(suggestion);
                    } else {
                      ModalManager.show({
                        size: "sm",
                        noPadding: true,
                        backdrop: "static",
                        content: (states, setStates, closeModal) => (
                          <CBIdlyCreateRule
                            closeModal={closeModal}
                            booking={props.booking}
                            assetDescription={
                              props.ruleActionData(suggestion).assetDescription
                            }
                            assetName={
                              props.ruleActionData(suggestion).assetName
                            }
                            actions={[
                              {
                                type: "SET_ACTIVITY",
                                val: {
                                  assetType:
                                    props.ruleActionData(suggestion).assetType,
                                  assetId: suggestion.asset._id,
                                  extra: props.ruleActionData(suggestion).extra,
                                },
                              } as BidlyAssignAction,
                            ]}
                            onRuleCreated={() => {
                              setRuleCreated(true);
                            }}
                          />
                        ),
                      });
                    }
                  }}
                  icon={{
                    type: "light",
                    data: "design-tool-selection-wand",
                    size: "xs",
                  }}
                  text={i18n.t(
                    "cb:CBIdlyConnect.ruleAction",
                    "Regel erstellen"
                  )}
                />
                {/* >
                <BfIcon
                  type="light"
                  data="design-tool-selection-wand"
                  size="xs"
                />
                {i18n.t(
                  "cb:CBIdlyConnect.ruleAction",
                  "Regel erstellen"
                )}
              </BFButton> */}
              </div>
            </Collapse>

            <Collapse in={ruleCreated}>
              <div>
                <div className={`rule-created-label`}>
                  {i18n.t("cb:CBIdlyConnect.ruleCreated", "Regel erstellt")}
                </div>
              </div>
            </Collapse>
          </>
        )}
      </div>
    </div>
  );
};

export default CBIdlyConnectSuggestion;

import { ActivityAsset } from "@/model/general-assets/BaseAsset";
import ActivityUtils from "@/modules/activity-components/ActivityUtils";
import { openOrDownloadDocument } from "@/redux/actions/ui-config/ui-config-actions";
import CDNService from "@/services/CDNService";
import GlobalActions from "@/services/GlobalActions";
import FileUtils from "@/utils/FileUtils";
import classNames from "classnames";
import moment from "moment";
import { useDispatch } from "react-redux";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import Userlabel from "../../../../components/AvatarComponent/Userlabel";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import BFButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import BFOverlay from "../../../../modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "../../../../modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../../../../modules/abstract-ui/icon/DefaultIcons";
import { ActivitiesLabel } from "../../../../modules/activity-components/ActivityLabel/ActivityLabel";
import DSService from "../../../../modules/document-store/DSService";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import StringUtils from "../../../../utils/StringUtils";
import { RAInvoice } from "../../invoiceApp/RAInterfaces";
import RAInvoiceProcessHistoryInline from "../../invoiceApp/components/InvoiceProcessHistory/RAInvoiceProcessHistoryInline";
import InvoicePaymentPlan from "../../invoiceApp/components/ListInvoices/InvoicePaymentPlan";
import {
  AssetCashBudgetAttachment,
  AssetCashBudgetEntry,
  LinkedAsset,
} from "../model/CashBudgetEntry";
import CBIdlyConnectService, {
  DocumentLinkData,
} from "../views/idly-bookings/connections/CBIdlyConnectService";
import {
  CBPortfolioObject,
  PortfolioLoan,
} from "../views/portfolio/interfaces/CBPortfolioAsset";
import { RentalAgreement } from "../views/tenants/TenantsInterfaces";
import "./CashBudgetLinkedItem.scss";

interface CashBudgetLinkedItemProps {
  linkedAsset: LinkedAsset;
  booking: AssetCashBudgetEntry;
}
const CashBudgetLinkedItem = (props: CashBudgetLinkedItemProps) => {
  if (props.linkedAsset.assetType.startsWith("activity-")) {
    return <LinkedActivity {...props} />;
  }
  switch (props.linkedAsset.assetType) {
    case AssetTypes.CashBudget.Attachment:
      return <LinkedAttachment {...props} />;
    case AssetTypes.Portfolio.Object:
      return <LinkedObjectDocument {...props} />;
    case AssetTypes.Portfolio.Loan:
      return <LinkedLoan {...props} />;
    case AssetTypes.Rental.RentalAgreement:
      return <LinkedRentalAgreement {...props} />;
    case AssetTypes.Invoice:
      return <LinkedInvoice {...props} />;
    default:
      return null;
  }
};

const LinkedActivity = (props: CashBudgetLinkedItemProps) => {
  return (
    <AssetLoader
      assetType={props.linkedAsset.assetType}
      id={props.linkedAsset.assetId}
      render={(asset: ActivityAsset) => {
        return (
          <LinkedItem
            booking={props.booking}
            linkedAsset={props.linkedAsset}
            typeText={ActivityUtils.getNameOfActivityType(
              props.linkedAsset.assetType
            )}
            buttonText={`${asset?.data.activityId} - ${asset?.data.displayName} `}
            onOpen={async () => {
              GlobalActions.openDetails(
                props.linkedAsset.assetType,
                props.linkedAsset.assetId,
                asset?.data.type
              );
            }}
          />
        );
      }}
    />
  );
};

const LinkedAttachment = (props: CashBudgetLinkedItemProps) => {
  const dispatch = useDispatch();
  return (
    <AssetLoader
      assetType={props.linkedAsset.assetType}
      id={props.linkedAsset.assetId}
      render={(asset: AssetCashBudgetAttachment) => {
        const [cdnId, data] = Object.entries(asset?.data.files)[0];

        return (
          <LinkedItem
            booking={props.booking}
            linkedAsset={props.linkedAsset}
            typeText={i18n.t("cb:CBLinkedItem.document", "Dokument")}
            buttonText={data.filename}
            onOpen={async () => {
              const url = await CDNService.fetchCDNLink({
                assetType: props.linkedAsset.assetType,
                assetId:
                  props.linkedAsset.assetId || (props.linkedAsset as any).value,
                assetField: "data.files",
                cdnId: cdnId,
                hasFolderReadPermissions: true,
                fileKey: data.key,
              });
              dispatch(
                openOrDownloadDocument(
                  url,
                  FileUtils.mimeToExt(data.content_type)
                )
              );
            }}
          />
        );
      }}
    />
  );
};

const LinkedInvoice = (props: CashBudgetLinkedItemProps) => {
  return (
    <AssetLoader
      assetType={props.linkedAsset.assetType}
      id={props.linkedAsset.assetId}
      render={(asset: RAInvoice) => {
        return (
          <LinkedItem
            booking={props.booking}
            linkedAsset={props.linkedAsset}
            typeText={i18n.t("cb:CBLinkedItem.invoice", "Rechnung")}
            buttonText={`${asset?.data?.invoice?.invoiceId || "/"} - ${
              asset?.nested?.contactDisplayName || ""
            } (${StringUtils.formatCurrency(
              asset?.data.invoice.value.amount,
              true,
              asset?.data.invoice.value.currency
            )})`}
            dependencies={
              <>
                <div className={`invoice-history`}>
                  <RAInvoiceProcessHistoryInline invoice={asset} />
                </div>
                <div className={`activity`}>
                  {asset?.data.activity?.length > 0 ? (
                    <ActivitiesLabel
                      activities={asset?.data.activity}
                      withType
                    />
                  ) : (
                    <div className={`no-activity`}></div>
                  )}
                </div>
              </>
            }
            postfix={<InvoicePaymentPlan asset={asset} />}
            // onOpen={async () => {
            //   DataBusDefaults.route({
            //     route: `__invoice-details/${asset._id}`,
            //     append: true,
            //   });
            //   // GlobalActions.openDetails(
            //   //   props.linkedAsset.assetType,
            //   //   props.linkedAsset.assetId,
            //   //   asset?.data.type
            //   // );
            // }}
          />
        );
      }}
    />
  );
};
const LinkedRentalAgreement = (props: CashBudgetLinkedItemProps) => {
  return (
    <AssetLoader
      assetType={props.linkedAsset.assetType}
      id={props.linkedAsset.assetId}
      render={(asset: RentalAgreement) => {
        const isArchived = asset?.data.status === "archived";
        const isTerminated =
          asset?.data.moveOut && moment(asset.data.moveOut).isBefore(moment());

        const postfix = isArchived
          ? `(${i18n.t("cb:CBLinkedItem.archived", "Archiviert")})`
          : isTerminated
          ? `(${i18n.t("cb:CBLinkedItem.terminated", "Beendet")})`
          : "";
        return (
          <LinkedItem
            booking={props.booking}
            linkedAsset={props.linkedAsset}
            typeText={i18n.t("cb:CBLinkedItem.rental", "Mietvertrag")}
            buttonText={`${asset?.data.id} - ${asset?.data.displayName} `}
            postfix={postfix}
            outfaded={isArchived}
            // onOpen={async () => {
            //   GlobalActions.openDetails(
            //     props.linkedAsset.assetType,
            //     props.linkedAsset.assetId,
            //     asset?.data.type
            //   );
            // }}
          />
        );
      }}
    />
  );
};
const LinkedObjectDocument = (props: CashBudgetLinkedItemProps) => {
  return (
    <AssetLoader
      assetType={props.linkedAsset.assetType}
      id={props.linkedAsset.assetId}
      render={(asset: CBPortfolioObject) => {
        const directoryConf = DSService.getDirectoryConfigurationForAsset(
          asset?.data.type,
          props.linkedAsset.assetType,
          "data.attachments",
          true
        );

        return (
          <>
            {props.linkedAsset.extra?.map((doc: DocumentLinkData) => {
              const document = asset?.data.attachments?.find(
                (e) => e.linkToCdn === doc.cdnId
              );
              const isArchived = (doc as any).status === "archived";

              return (
                <LinkedItem
                  outfaded={isArchived}
                  booking={props.booking}
                  linkedAsset={props.linkedAsset}
                  typeText={i18n.t(
                    "cb:CBLinkedItem.objectDocument",
                    "Dokument"
                  )}
                  buttonText={doc.filename}
                  onOpen={async () => {
                    await DSService.openDocument(document, {
                      asset: asset,
                      assetType: props.linkedAsset.assetType,
                      type: asset?.data.type,
                      documentsFieldPath: "data.attachments",
                    });
                  }}
                  postfix={
                    <div className={`document-from-object`}>
                      <div className={`from-object-label`}>
                        {i18n.t("cb:CBLinkedItem.fromObject", "von Objekt")}
                      </div>
                      <BFButton
                        className={`open-object-button`}
                        noPadding
                        onClick={() => {
                          const path = DSService.getDocumentPath(
                            document,
                            directoryConf
                          );

                          DataBusDefaults.route({
                            route: `/objects/${asset?._id}/attachments/object/${path}`,
                            stayInApp: true,
                          });
                        }}
                        appearance="link"
                      >
                        {asset?.data.id} - {asset?.data.displayName}
                      </BFButton>
                    </div>
                  }
                  onUnlick={async () => {
                    const newExtra = props.linkedAsset.extra.filter(
                      (d: DocumentLinkData) => d.cdnId !== doc.cdnId
                    );
                    await CBIdlyConnectService.updateLinkExtra(
                      props.booking._id,
                      props.linkedAsset.assetType,
                      props.linkedAsset.assetId,
                      newExtra
                    );
                  }}
                />
              );
            })}
          </>
        );
      }}
    />
  );
};
const LinkedLoan = (props: CashBudgetLinkedItemProps) => {
  return (
    <AssetLoader
      assetType={props.linkedAsset.assetType}
      id={props.linkedAsset.assetId}
      render={(asset: PortfolioLoan) => {
        return (
          <LinkedItem
            booking={props.booking}
            linkedAsset={props.linkedAsset}
            typeText={i18n.t("cb:CBLinkedItem.loan", "Darlehen")}
            buttonText={`${asset?.data.loanID} - ${i18n.t(
              "cb:CBLinkedItem.loanAnnuity",
              "Annuität"
            )} ${StringUtils.formatCurrency(
              asset?.data.loanData.paymentAmount
            )} `}
            // onOpen={async () => {
            //   DataBusDefaults.route({
            //     route: `/loans/${asset._id}`,
            //     stayInApp: true,
            //   });
            //   // GlobalActions.openDetails(
            //   //   props.linkedAsset.assetType,
            //   //   props.linkedAsset.assetId,
            //   //   asset?.data.type
            //   // );
            // }}
          />
        );
      }}
    />
  );
};

const LinkedItem = (props: {
  className?: string;
  typeText: string;
  buttonText: string;
  postfix?: React.ReactNode;
  booking: AssetCashBudgetEntry;
  onOpen?: () => Promise<void>;
  linkedAsset: LinkedAsset;
  dependencies?: React.ReactNode;
  onUnlick?: () => Promise<void>;
  outfaded?: boolean;
}) => {
  return (
    <div
      className={classNames(`cb-linked-item`, {
        outfaded: props.outfaded,
      })}
    >
      <div className={`main`}>
        <div className={`type`}>{props.typeText}</div>
        {props.onOpen ? (
          <BFButton
            className={`link-button`}
            noPadding
            appearance="link"
            onClick={props.onOpen}
          >
            {props.buttonText}
          </BFButton>
        ) : (
          <BFDetailsButton
            className={`link-button`}
            noPadding
            appearance="link"
            data={{
              assetType: props.linkedAsset.assetType,
              assetId: props.linkedAsset.assetId,
              type: props.booking.data.unit,
            }}
          >
            {props.buttonText}
          </BFDetailsButton>
        )}
        {props.postfix && <div className={`post`}>{props.postfix}</div>}
        <div className={`fill`} />
        <BFOverlay
          placement="left"
          speaker={<LinkCreatedByInfo linkedAsset={props.linkedAsset} />}
        >
          <div className={`cb-linked-item-assigned-by`}>
            <BfIcon type="light" data="information-circle" size="xxs" />
          </div>
        </BFOverlay>
        <BFButton
          className={`unlink-button`}
          appearance="clear-on-white"
          onClick={() => {
            ModalManager.confirm({
              title: i18n.t(
                "cb:CBLinkedItem.unlinkConfirmTitle",
                "Verknüpfung entfernen"
              ),
              message: i18n.t(
                "cb:CBLinkedItem.unlinkConfirmMessage",
                "Wollen Sie die Verknüpfung entfernen?"
              ),
              confirmButtonText: i18n.t(
                "cb:CBLinkedItem.unlinkConfirmButton",
                "Entfernen"
              ),
              onConfirm: async () => {
                const onUnlick =
                  props.onUnlick ||
                  (async () =>
                    CBIdlyConnectService.unlink(
                      props.booking._id,
                      props.linkedAsset.assetType,
                      props.linkedAsset.assetId
                    ));
                await onUnlick();
              },
            });
          }}
        >
          <BfIcon {...DefaultIcons.CLOSE} size="xxs" />
        </BFButton>
      </div>
      {props.dependencies && (
        <div className={`dependencies`}>{props.dependencies} </div>
      )}
    </div>
  );
};

export default CashBudgetLinkedItem;

export const LinkCreatedByInfo = (props: { linkedAsset: LinkedAsset }) => {
  if (!props.linkedAsset.assignedBy) {
    return (
      <div className={`link-created-by-info-overlay`}>
        <div className={`text`}>
          {i18n.t(
            "cb:CBLinkedItem.noAssignment",
            "Keine Informationen vorhanden"
          )}
        </div>
      </div>
    );
  }
  if (props.linkedAsset.assignedBy?.ruleId) {
    return (
      <div className={`link-created-by-info-overlay`}>
        <div className={`text`}>
          {i18n.t("cb:CBLinkedItem.assignedByRule", "Verknüpft mit Regel")}
        </div>
        <div className={`date`}>
          {StringUtils.formatDate(props.linkedAsset.assignedBy.date)}
        </div>
      </div>
    );
  }
  if (props.linkedAsset.assignedBy?.userId === "system") {
    return (
      <div className={`link-created-by-info-overlay`}>
        <div className={`text`}>
          {i18n.t(
            "cb:CBLinkedItem.assignedBySystem",
            "Verknüpft durchs System"
          )}
        </div>
        <div className={`date`}>
          {StringUtils.formatDate(props.linkedAsset.assignedBy.date)}
        </div>
      </div>
    );
  }
  if (props.linkedAsset.assignedBy?.userId) {
    return (
      <div className={`link-created-by-info-overlay`}>
        <div className={`text`}>
          {i18n.t("cb:CBLinkedItem.assignedByUser", "Verknüpft von Benutzer")}
        </div>
        <div className={`user`}>
          <Userlabel id={props.linkedAsset.assignedBy.userId} />
        </div>
        <div className={`date`}>
          {StringUtils.formatDate(props.linkedAsset.assignedBy.date)}
        </div>
      </div>
    );
  }

  return (
    <div className={`link-created-by-info-overlay`}>
      <div className={`text`}>
        {i18n.t(
          "cb:CBLinkedItem.noAssignment",
          "Keine Informationen vorhanden"
        )}
      </div>
    </div>
  );
};

//   <Dropdown title="Dropdown">
//   <Dropdown.Item>New File</Dropdown.Item>
//   <Dropdown.Item>New File with Current Profile</Dropdown.Item>
//   <Dropdown.Item>Download As...</Dropdown.Item>
//   <Dropdown.Item>Export PDF</Dropdown.Item>
//   <Dropdown.Item>Export HTML</Dropdown.Item>
//   <Dropdown.Item>Settings</Dropdown.Item>
//   <Dropdown.Item>About</Dropdown.Item>
// </Dropdown>

import classNames from "classnames";
import _ from "lodash";
import { ElementType, useEffect, useRef, useState } from "react";
import { Dropdown } from "rsuite";
import DataBus from "../../../../services/DataBus";
import { DataBusSubKeys } from "../../../../utils/Constants";
import BFOverlay from "../whisper/BFOverlay";
import "./BFDropdown.scss";
import BFDropdownContent, { DropdownItem } from "./BFDropdownContent";

export interface Props {
  identifier?: string;
  items: DropdownItem[];
  label: string | React.ReactNode;
  className?: string;
  renderToggle?: (props, ref) => any;
  toggleAs?: ElementType | ((props: any) => React.ReactNode);
  itemRef?: React.MutableRefObject<any>;
  asOverlay?: boolean;
  overlayWidth?: number | string;
  overlayOverflowAllow?: boolean;
  overlayClassName?: string;
  [key: string]: any;
}

const BFDropdown = (props: Props) => {
  const [identifier] = useState(props.identifier || _.uniqueId("dropdown-"));
  const ref = useRef();
  //### FIXME there is following warning, investigate and fix:
  //   react_devtools_backend.js:4012 Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?

  // Check the render method of `DropdownToggle`.
  //     at I18nextWithTranslation (http://localhost:3000/static/js/bundle.js:265650:92)
  //     at http://localhost:3000/static/js/bundle.js:290866:25
  //     at div
  //     at Menu (http://localhost:3000/static/js/bundle.js:294261:23)
  //     at http://localhost:3000/static/js/bundle.js:290203:25
  //     at BFDropdown (http://localhost:3000/src_modules_abstract-ui_general_Dropdown_BFDropdown_tsx-src_modules_abstract-ui_general_whisp-66fd47.613f297d328a7c1ebf7a.hot-update.js:47:60)
  useEffect(() => {
    let subId = DataBus.subscribe(DataBusSubKeys.DROPDOWN_TOGGLE, (data) => {
      if (data === identifier) {
        close();
      }
    });
    return () => {
      if (subId) DataBus.unsubscribe(subId);
    };
  }, [identifier]);

  const close = () => {
    if (props.asOverlay) {
      DataBus.emit("WHISPER", {
        identifier: identifier,
        type: "CLOSE",
      });
    } else {
      (ref.current as any)?.querySelector("button").click();
    }
  };

  const dropdown = (
    <Dropdown
      title={props.label}
      ref={ref}
      toggleAs={props.toggleAs}
      renderToggle={props.renderToggle}
      {...props}
      className={classNames("bf-dropdown", props.className)}
    >
      {!props.asOverlay && (
        <BFDropdownContent withoutMenu items={props.items} onClose={close} />
      )}
    </Dropdown>
  );

  if (props.asOverlay) {
    return (
      <BFOverlay
        className={props.overlayClassName}
        identifier={identifier}
        placement={props.placement || "bottomStart"}
        trigger={"click"}
        overflowAllow={props.overlayOverflowAllow}
        enterable
        zIndex={1051}
        onOpen={() => {
          props?.onOpen?.();
        }}
        onExit={() => {
          props?.onClose?.();
        }}
        speaker={
          <div className={classNames(`bf-dropdown-overlay`, props.className)}>
            <BFDropdownContent
              style={{
                width: props.overlayWidth || 200,
                border: "1px solid var(--border-color-primary)",
              }}
              items={props.items}
              onClose={close}
            />
          </div>
        }
      >
        <div>
          {props.toggleAs
            ? typeof props.toggleAs === "function"
              ? (props.toggleAs as any)({})
              : props.toggleAs
            : dropdown}
          {/* <BFButton>+</BFButton> */}
        </div>
      </BFOverlay>
    );
  } else {
    return dropdown;
  }
};
export default BFDropdown;

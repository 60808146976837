import i18n from "@/i18n";
import classNames from "classnames";
import { PlacementAll } from "../../common/Placements";
import BFButton from "../../general/Button/BFButton";
import BFConfirmButton from "../../general/Button/BFConfirmButton";
import BfIcon from "../../icon/BfIcon";
import "./BFEntries.scss";

interface BFEntriesProps {
  entries: BFEntry[];
  emptyText?: string;
  ignoreHover?: boolean;
}
interface BFEntry {
  content: JSX.Element;
  actions?: {
    icon: string;
    onClick?: () => void;
    href?: string;
    disabled?: boolean;
    tooltip: string;
    confirm?:
      | {
          confirmButtonText?: string;
          confirmDescription?: string;
          placement?: PlacementAll;
        }
      | true;
  }[];
}
const BFEntries = (props: BFEntriesProps) => {
  return (
    <div
      className={classNames(`bf-entries`, {
        "ignore-hover": props.ignoreHover,
      })}
    >
      {props.entries.map((entry, index) => (
        <div className={`bf-entry`}>
          <div className={`entry-content`}>{entry.content}</div>
          {entry.actions && (
            <div className={`entry-actions`}>
              {entry.actions.map((action, index) => {
                const button = action.confirm ? (
                  <BFConfirmButton
                    onConfirm={action.onClick}
                    tooltip={
                      action.tooltip
                        ? {
                            tooltip: action.tooltip,
                          }
                        : undefined
                    }
                    appearance="link"
                    {...(action.confirm === true ? {} : action.confirm)}
                  >
                    <BfIcon data={action.icon} type="light" size="xs" />
                  </BFConfirmButton>
                ) : (
                  <BFButton
                    tooltip={
                      action.tooltip
                        ? {
                            tooltip: action.tooltip,
                          }
                        : undefined
                    }
                    appearance="link"
                    onClick={action.onClick}
                  >
                    <BfIcon data={action.icon} type="light" size="xs" />
                  </BFButton>
                );

                if (action.href) {
                  return (
                    <a target="_blank" href={action.href}>
                      {button}
                    </a>
                  );
                } else {
                  return button;
                }
              })}
            </div>
          )}
        </div>
      ))}
      {props.entries.length === 0 && (
        <div className={`__empty`}>
          {props.emptyText ||
            i18n.t("BFEntries.noEntries", "Keine Einträge vorhanden")}
        </div>
      )}
    </div>
  );
};

export default BFEntries;

import { Loader } from "rsuite";
import i18n from "../../i18n";
import { ExpandKey } from "../../redux/actions/application/application-infinite-table-actions";
import { useAggregationStatisticQuery } from "../../redux/hooks";
import { AggregationStatisticQuerySelector } from "../../redux/model";
import { MatchQuery } from "../../services/DataService";
// import './QueryLoader.scss';

interface QueryLoaderProps {
  assetType: string;
  matchQuery: MatchQuery;
  selectors: AggregationStatisticQuerySelector[];
  referenceId?: string;
  textQuery?: string;
  ignoreQuery?: boolean;
  expandKeys?: (ExpandKey | string)[];

  render: (result: any) => JSX.Element;
}
const QueryLoader = (props: QueryLoaderProps) => {
  const result = useAggregationStatisticQuery<any>(
    props.assetType,
    props.matchQuery,
    props.selectors,
    props.referenceId,
    props.textQuery,
    props.ignoreQuery,
    props.expandKeys
  );
  if (result.loading) {
    return <Loader size="xs" />;
  }
  if (result.error) {
    return (
      <div className={`query-loader error`}>
        {i18n.t("Global.Labels.error")}
      </div>
    );
  }
  return props.render(result.data);
};

export default QueryLoader;

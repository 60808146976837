import classNames from "classnames";
import { useDispatch } from "react-redux";
import i18n from "../../../i18n";
import BFButton from "../../../modules/abstract-ui/general/Button/BFButton";
import NotificationEntry from "../../../modules/notifications/NotificationEntry";
import { clearNotifications } from "../../../redux/actions/notifications/notifications-actions";
import { useTypedSelector } from "../../../redux/hooks";
import DContentCard from "../../common/DContentCard/DContentCard";
import "./DashboardNotifications.scss";

interface DashboardNotificationsProps {}
const DashboardNotifications = (props: DashboardNotificationsProps) => {
  const dispatch = useDispatch();
  const notifications = useTypedSelector(
    (state) => state.notifications.entries || []
  );
  return (
    <DContentCard
      title={i18n.t("dashboard:notifications.title", "Deine Nachrichten")}
      className={classNames(`dashboard-notifications`)}
      actions={
        <>
          <BFButton
            appearance="link"
            icon={{ data: "bin-1", type: "bf" }}
            onClick={async () => await dispatch(clearNotifications())}
          />
        </>
      }
    >
      {" "}
      {notifications.length === 0 && (
        <div className={`empty-text`}>{i18n.t("Notifications.EmptyText")}</div>
      )}
      {notifications.map((notification) => {
        return (
          <NotificationEntry
            dense
            key={notification.id}
            notification={notification}
          />
        );
      })}
    </DContentCard>
  );
};

export default DashboardNotifications;

import StructLoader from "@/components/StructLoader/StructLoader";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import classNames from "classnames";
import AssetLoader from "../../../../../components/AssetLoader/AssetLoader";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import BFDetailsButton from "../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import { RentalAgreement } from "../../../cashBudget/views/tenants/TenantsInterfaces";
import { CBRentalAgreementDetailsPage } from "../../../cashBudget/views/tenants/components/rental-agreements/CBRentalAgreementDetailsPage";
import { useActivityConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";
import "./APActivityTenantData.scss";

interface APActivityTenantDataProps {
  activity: APActivity;
}
const APActivityTenantData = (props: APActivityTenantDataProps) => {
  const constants = useActivityConstants();
  const relevantRelations = props.activity.data.relations.filter(
    (e) => e.assetType === AssetTypes.Rental.RentalAgreement
  );

  return (
    <div className={classNames(`ap-activity-tenant-data`)}>
      {relevantRelations.map((relation, index) => (
        <div className={`activity-tenant-entry __card margin-bottom-20`}>
          <AssetLoader
            assetType={AssetTypes.Rental.RentalAgreement}
            id={relation.assetId}
            render={(asset: RentalAgreement) => (
              <StructLoader
                structTypes={["objectKind", "orga"]}
                unitType={asset.data.type}
                render={() => {
                  const obj = OrgaStruct.getObject(asset.data.objectId);
                  const kind = ObjectKindStruct.getKind(obj.objectKindId);
                  return (
                    <>
                      <CBRentalAgreementDetailsPage
                        kind={kind}
                        rentalAgreement={asset}
                        hideActions
                        hideBackButton
                        hideStackingPlan
                      />
                      <div className={`action-row`}>
                        <BFDetailsButton
                          appearance={"outline"}
                          data={{
                            assetId: asset._id,
                            assetType: AssetTypes.Rental.RentalAgreement,
                            type: asset.data.type,
                          }}
                        >
                          {i18n.t(
                            "apTemplate:Activity.TenantData.OpenRentalAgreement",
                            "Mietvertrag öffnen",
                            {
                              ns: [constants?.assetType, "apTemplate"],
                            }
                          )}
                        </BFDetailsButton>
                      </div>
                    </>
                  );
                }}
              />
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default APActivityTenantData;

import classNames from "classnames";
import moment from "moment";
import { Field, FormRenderProps } from "react-final-form";
import { Trans } from "react-i18next";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../i18n";
import LabeledInput from "../../../../../../modules/abstract-ui/forms/LabeledInput";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../../../../../modules/abstract-ui/icon/BfIcon";
import CashBudgetDetailEntryCardContent from "../../../components/CashBudgetDetailEntryCardContent";
import { AssetCashBudgetEntry } from "../../../model/CashBudgetEntry";
import { BidlyAssignAction, BidlyCreateRuleForm } from "../CBIdlyBookingsConst";
import CBIdlyConnectService from "./CBIdlyConnectService";
import "./CBIdlyCreateRule.scss";

interface CBIdlyCreateRuleProps {
  closeModal: () => void;
  booking: AssetCashBudgetEntry;

  actions: BidlyAssignAction[];

  assetName: string;
  assetDescription: string;
  onRuleCreated: () => void;
}

const CBIdlyCreateRule = (props: CBIdlyCreateRuleProps) => {
  const { booking } = props;

  const initialValues: Partial<BidlyCreateRuleForm> = {
    type: booking.data.unit,
    condition: {
      recipient: booking.data.recipient || "",
      usage: booking.data.usage,
      value: booking.data.value < 0 ? "isNeg" : "isPos",
    },
    entity: booking.data.entity,
    validTillYear: moment().year(),

    action: props.actions,
  };

  const validateUsageRule = (value: string) => {
    if (!booking.data.usage.includes(value)) {
      return i18n.t(
        "cb:CBIdlyCreateRule.Rule.Error.Usage",
        "Die Bedingung für den Verwendungszweck ist nicht gültig.\nDie Bedingung muss im Verwendugnszweck enthalten sein."
      );
    }
    return undefined;
  };
  return (
    <div className={classNames("cb-idly-create-rule-component")}>
      <FormStruct<any>
        title={""}
        className="cb-idly-create-rule"
        initialValues={initialValues}
        submitText={i18n.t(
          "cb:CBIdlyCreateRule.Rule.Form.SaveAndRun",
          "Regel erstellen"
        )}
        onSubmit={async (values) => {
          await CBIdlyConnectService.createRule(values);
          props.closeModal();
          props.onRuleCreated();
        }}
        onAbort={(form: FormRenderProps) => {
          props.closeModal();
        }}
        validate={(values) => {
          if (values.condition.recipient.trim() === "") {
            return {
              recipient: i18n.t(
                "cb:CBIdlyCreateRule.Rule.Form.Error.Recipient",
                "Der Empfänger darf nicht leer sein."
              ),
            };
          }
        }}
        render={(form: FormRenderProps) => {
          const isRecipientEmpty =
            form.values.condition.recipient.trim() === "";
          return (
            <div className="form-preview-container">
              <div className="cash-budget-entry">
                <CashBudgetDetailEntryCardContent
                  entry={booking}
                  showAccountData={true}
                />
              </div>
              <div className="fields">
                <div className={`-title __h3 margin-top-10`}>
                  {i18n.t("cb:CBIdlyCreateRule.Rule.Form.Rule", "Regel")}
                </div>
                <div className="__form-row">
                  <div className="__field">
                    {!isRecipientEmpty ? (
                      <div className={`__description-text margin-top-10`}>
                        <Trans i18nKey="cb:CBIdlyCreateRule.Rule.Form.Recipient">
                          Die Regel wird für alle{" "}
                          <strong>neu eingehende</strong> Buchungen mit dem
                          Empfänger{" "}
                          <strong>
                            {{ recipient: form.values.condition.recipient }}
                          </strong>{" "}
                          angewendet.
                        </Trans>
                        <br />
                        <br />
                        <span>
                          {i18n.t(
                            "cb:CBIdlyCreateRule.Rule.Form.RuleInfo",
                            "Die Regel verknüpft neue entsprechende Buchungen mit:"
                          )}
                        </span>
                        <br />
                        <strong>{props.assetName}</strong> -{" "}
                        {props.assetDescription}
                      </div>
                    ) : (
                      <div className="recipient-error">
                        {i18n.t(
                          "cb:BookingCategory.Rule.Form.Error.NoRecipient",
                          "Zu Buchungen ohne Empfänger kann keine Regel erstellt werden."
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="__form-row">
                  <div className={classNames("__field", "usage-input")}>
                    <LabeledInput
                      label={i18n.t(
                        "cb:BookingCategory.Rule.Form.Usage",
                        "Verwendungszweck"
                      )}
                      suffix={
                        <div className="bold">
                          {i18n.t(
                            "cb:BookingCategory.Rule.Form.UsageContains",
                            "enthält"
                          )}
                        </div>
                      }
                    >
                      <Field
                        name="condition.usage"
                        validate={FormValidators.compose(
                          FormValidators.required(),
                          FormValidators.min(3),
                          validateUsageRule
                        )}
                      >
                        {({ input, meta }) => {
                          return (
                            <div className="__field">
                              <BFInput
                                validation={{
                                  level: "error",
                                  message: meta.error,
                                }}
                                suffixOnClick={() => {
                                  form.form.mutators.setValue(
                                    "condition.usage",
                                    booking.data.usage
                                  );
                                }}
                                suffix={
                                  <BfIcon
                                    type="light"
                                    size="xs"
                                    data="synchronize-refresh-arrow-3"
                                  />
                                }
                                {...input}
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </LabeledInput>
                  </div>
                  <BFButton
                    className="remove-additional-field-btn"
                    appearance="link"
                    size="xs"
                    onClick={() => {
                      form.form.mutators.setValue("condition.usage", null);
                    }}
                  >
                    {i18n.t("Global.Buttons.remove")}
                  </BFButton>
                </div>

                <div className={`add-actions`}>
                  {/* {!form.values?.helpers?.showUsage && (
                    <BFButton
                      type="button"
                      appearance={"outline"}
                      size="xs"
                      text={i18n.t(
                        "cb:CategoryDetailsEntry.Rule.AddUsage",
                        "Regel für Verwendungszweck hinzufügen"
                      )}
                      onClick={() => {
                        form.form.mutators.setValue("helpers.showUsage", true);
                        form.form.mutators.setValue(
                          "condition.usage",
                          booking.data.usage
                        );
                      }}
                    ></BFButton>
                  )} */}
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default CBIdlyCreateRule;

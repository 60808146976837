import axios from "axios";
import md5 from "md5";
import { ThunkDispatch } from "redux-thunk";
import Log from "../debug/Log";
import {
  ListResponse,
  QueryAllAssetsResponse,
} from "../model/common/HttpModel";
import {
  addTableNewData,
  patchTableData,
  removeTableNewData,
  setFlexCacheData,
  setReloadTable,
  setTableData,
  setTableLoading,
} from "../redux/actions/application/application-actions";
import { HTTP } from "../utils/Http";
import { AppState, store } from "./../redux/store";
import { UpdateCommand } from "./socket/NotificationInterface";
import SocketService from "./socket/SocketService";

export type Filter = {
  name: string;
  value: any;
  op:
    | "LIKE"
    | "NOT_LIKE"
    | "EQUALS"
    | "NOT_EQUALS"
    | "LESS_THAN"
    | "LESS_THAN_EQUALS"
    | "GREATER_THAN"
    | "GREATER_THAN_EQUALS"
    | "IN"
    | "NOT_IN"
    | "BETWEEN";
};

export type MatchQueryOP = {
  type: "op";
  name: string;
  op: "eq" | "ne" | "lt" | "lte" | "gt" | "gte" | "in" | "nin" | "regex";
  value: any;

  id?: string;
};
export type MatchQueryOr = {
  type: "or";
  query: MatchQuery[];

  id?: string;
};
export type MatchQueryAnd = {
  type: "and";
  query: MatchQuery[];

  id?: string;
};
export type MatchQueryAndOr = MatchQueryOr | MatchQueryAnd;
export type MatchQuery = MatchQueryAndOr | MatchQueryOP;

export type RequestListOpts = {
  identifier?: string;
  tableIdentifier?: string;
  url: string;
  limit?: number;
  realSkip?: number;
  skip?: number;
  filters?: { [dataKey: string]: Filter }; // matchQuery?: MatchQuery,
  textQuery?: string;
  sort?:
    | { dataKey: string; sortType: "asc" | "desc" }
    | { dataKey: string; sortType: "asc" | "desc" }[];
  onSuccess?: (data: any) => void;
  onError?: (err: any) => void;
  matchQuery?: MatchQuery;
  append?: boolean;
  post?: boolean;
};

const requestIdGen: { [key: string]: number } = {};

const convertName = (name: string) => name.replace(/\|/g, ".");

const convertFiltersToMatchQuery = (filters: {
  [dataKey: string]: Filter;
}): MatchQuery => {
  const keys = Object.keys(filters);
  const andMatchQueries: MatchQuery[] = [];

  keys.forEach((key) => {
    const filter = filters[key];
    if (filter && filter.value !== "") {
      switch (filter.op) {
        case "EQUALS":
          andMatchQueries.push({
            type: "op",
            name: convertName(filter.name),
            value: filter.value,
            op: "eq",
          });
          break;
        case "NOT_EQUALS":
          andMatchQueries.push({
            type: "op",
            name: convertName(filter.name),
            value: filter.value,
            op: "ne",
          });
          break;
        case "IN":
          if (filter.value.length !== 0) {
            andMatchQueries.push({
              type: "op",
              name: convertName(filter.name),
              value: filter.value,
              op: "in",
            });
          }
          break;
        case "NOT_IN":
          if (filter.value.length !== 0) {
            andMatchQueries.push({
              type: "op",
              name: convertName(filter.name),
              value: filter.value,
              op: "nin",
            });
          }
          break;
        case "GREATER_THAN":
          andMatchQueries.push({
            type: "op",
            name: convertName(filter.name),
            value: filter.value,
            op: "gt",
          });
          break;
        case "GREATER_THAN_EQUALS":
          andMatchQueries.push({
            type: "op",
            name: convertName(filter.name),
            value: filter.value,
            op: "gte",
          });
          break;
        case "LESS_THAN":
          andMatchQueries.push({
            type: "op",
            name: convertName(filter.name),
            value: filter.value,
            op: "lt",
          });
          break;
        case "LESS_THAN_EQUALS":
          andMatchQueries.push({
            type: "op",
            name: convertName(filter.name),
            value: filter.value,
            op: "lte",
          });
          break;
      }
    }
  });

  if (andMatchQueries.length === 0) {
    return null;
  }

  return {
    type: "and",
    query: andMatchQueries,
  };
};

export const emptyListData = (tableIdentifier: string) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch(
      setTableData(tableIdentifier, {
        loading: false,
        total: 0,
        data: [],
        limit: 0,
        skip: 0,
        filters: {},
        sort: null,
        fulltextSearch: "",
      })
    );
  };
};

export const reloadTable = (
  tableIdentifier: string,
  onSuccess: (data) => void,
  onError: (error) => void
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    const conf = store.getState().application.tables[tableIdentifier];

    dispatch(
      requestListData(
        {
          ...conf,
          tableIdentifier: tableIdentifier,
          append: false,
          skip: 0,
          onSuccess,
          onError,
        },
        null
      )
    );
  };
};

export const requestListData = (
  opts: RequestListOpts,
  cancelObj?: { cancel?: () => void }
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    requestListDataHelper(opts, cancelObj, dispatch);
  };
};

const generateIdForAllAssets = (
  assetType: string,
  matchQuery?: MatchQuery,
  sort?: { fieldName: string; sortKey: 1 | -1 }[]
) => {
  return md5(`${assetType}#${JSON.stringify({ matchQuery, sort })}`);
};
export const selectQueryAllAssets = (
  state: AppState,
  assetType: string,
  matchQuery?: MatchQuery,
  sort?: { fieldName: string; sortKey: 1 | -1 }[]
) => {
  return state.application.cache.flex[`list-${assetType}`]?.[
    generateIdForAllAssets(assetType, matchQuery, sort)
  ];
};
export const queryAllAssets = (
  assetType: string,
  matchQuery?: MatchQuery,
  sort?: { fieldName: string; sortKey: 1 | -1 }[],
  expandKeys?: string[]
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    queryAllAssetsChained(dispatch, assetType, matchQuery, sort, expandKeys);
  };
};

export const queryAllAssetsChained = async <T>(
  dispatch: ThunkDispatch<{}, {}, any>,
  assetType: string,
  matchQuery?: MatchQuery,
  sort?: { fieldName: string; sortKey: 1 | -1 }[],
  expandKeys?: string[]
) => {
  try {
    dispatch?.(
      setFlexCacheData(
        `list-${assetType}`,
        generateIdForAllAssets(assetType, matchQuery, sort),
        { state: "loading" } as QueryAllAssetsResponse<T>
      )
    );

    let data = [];
    let skip = 0;
    let count = 0;
    let finish = false;

    while (!finish) {
      const result = await queryAssetListData<T>({
        assetType,
        matchQuery,
        sort,

        limit: 30,
        skip,
        expandKeys,
      });
      skip = result.skip + result.limit;
      count = result.count;
      data = [...data, ...result.data];

      if (skip > count) {
        finish = true;
      }
    }
    dispatch?.(
      setFlexCacheData(
        `list-${assetType}`,
        generateIdForAllAssets(assetType, matchQuery, sort),
        { state: "success", data } as QueryAllAssetsResponse<T>
      )
    );
    return data;
  } catch (err) {
    dispatch?.(
      setFlexCacheData(
        `list-${assetType}`,
        generateIdForAllAssets(assetType, matchQuery, sort),
        { state: "error", error: err } as QueryAllAssetsResponse<T>
      )
    );
  }
};
export const queryAllUsers = () => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    queryAllUsersChained(dispatch);
  };
};

export const queryAllUsersChained = async <T>(
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  try {
    dispatch?.(
      setFlexCacheData(`users`, "allUsers", {
        state: "loading",
      } as QueryAllAssetsResponse<T>)
    );

    let data = [];
    let skip = 0;
    let count = 0;
    let finish = false;

    while (!finish) {
      const result = await queryUserListData<T>({
        limit: 30,
        skip,
      });
      skip = result.skip + result.limit;
      count = result.count;
      data = [...data, ...result.data];

      if (skip > count) {
        finish = true;
      }
    }
    dispatch?.(
      setFlexCacheData(`users`, "allUsers", {
        state: "success",
        data,
      } as QueryAllAssetsResponse<T>)
    );
    return data;
  } catch (err) {
    dispatch?.(
      setFlexCacheData(`users`, "allUsers", {
        state: "error",
        error: err,
      } as QueryAllAssetsResponse<T>)
    );
  }
};

export const queryAssetListData = async <T>(data: {
  assetType: string;
  matchQuery: MatchQuery;
  sort?: { fieldName: string; sortKey: 1 | -1 }[];
  limit: number;
  skip: number;
  expandKeys?: string[];
}) => {
  const result = (await HTTP.post({
    url: `asset/list/${data.assetType}`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    bodyParams: {
      matchQuery: data.matchQuery,
      sort: data.sort || undefined,
      skip: data.skip,
      limit: data.limit,
      expandKeys:
        data.expandKeys?.map((e) => ({
          key: e,
        })) || undefined,
    },
  })) as ListResponse<T>;

  return result;
};

export const queryUserListData = async <T>(data: {
  limit: number;
  skip: number;
}) => {
  const result = (await HTTP.get({
    url: `user`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    queryParams: {
      param: {
        skip: data.skip,
        limit: data.limit,
      },
    },
  })) as ListResponse<T>;

  return result;
};

const requestListDataHelper = (
  opts: RequestListOpts,
  cancelObj: undefined | { cancel?: () => void },
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  dispatch(setReloadTable(opts.tableIdentifier, false));
  const queryParams: any = {};
  if (opts.limit) {
    queryParams.limit = opts.limit;
  }
  if (opts.skip) {
    queryParams.skip = opts.skip;
  }
  if (opts.filters) {
    queryParams.matchQuery = convertFiltersToMatchQuery(
      opts.filters
    ) as MatchQuery;
  }

  if (opts.matchQuery) {
    if (queryParams.matchQuery) {
      queryParams.matchQuery = {
        type: "and",
        query: [queryParams.matchQuery, opts.matchQuery],
      };
    } else {
      queryParams.matchQuery = opts.matchQuery;
    }
  }

  if (!queryParams.matchQuery) {
    queryParams.matchQuery = undefined;
  }

  if (opts.textQuery) {
    queryParams.textQuery = opts.textQuery;
  }
  if (opts.sort) {
    queryParams.sort = (Array.isArray(opts.sort) ? opts.sort : [opts.sort]).map(
      (entry) => ({
        fieldName: entry.dataKey || (entry as any).fieldName,
        sortKey: entry.sortType === "asc" ? 1 : -1,
      })
    );
  }
  const usedIdentifier = opts.tableIdentifier
    ? opts.tableIdentifier
    : opts.identifier;
  const requestId =
    requestIdGen[usedIdentifier] !== undefined
      ? (requestIdGen[usedIdentifier] + 1) % 10000
      : 0;
  requestIdGen[usedIdentifier] = requestId;

  dispatch(setTableLoading(opts.tableIdentifier, true));
  const doRequest = (requestId) => {
    HTTP[opts.post ? "post" : "get"]({
      url: opts.url,
      target: "EMPTY",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      queryParams: opts.post
        ? undefined
        : {
            param: queryParams,
          },
      bodyParams: opts.post ? queryParams : undefined,
      cancelToken: cancelObj
        ? new axios.CancelToken((cancel) => (cancelObj.cancel = cancel))
        : undefined,
    })
      .then((data) => {
        if (cancelObj) {
          cancelObj.cancel = undefined;
        }

        if (requestIdGen[usedIdentifier] !== requestId) {
          return;
        }
        if (data.count < data.skip) {
          opts.skip = data.count - (data.count % data.limit);
          requestListDataHelper(opts, cancelObj, dispatch);
          return;
        }
        if (opts.tableIdentifier) {
          registerSocketListener(opts.url, dispatch);
          dispatch(
            setTableData(opts.tableIdentifier, {
              loading: false,
              url: opts.url,
              append: opts.append,
              total: data.count,
              data: data.data,
              limit: data.limit,
              skip: opts.realSkip ? opts.realSkip : data.skip,
              filters: opts.filters,
              sort: opts.sort ? opts.sort : null,
              fulltextSearch: opts.textQuery,
            })
          );
        }

        if (opts.onSuccess) {
          opts.onSuccess(data);
        }
      })
      .catch((err) => {
        dispatch(setTableLoading(opts.tableIdentifier, false));
        if (cancelObj) {
          cancelObj.cancel = undefined;
        }

        if (err instanceof axios.Cancel) {
          Log.info("canceled request", err);
          return;
        }

        if (opts.onError) {
          opts.onError(err);
        }
      });
  };
  doRequest(requestId);
};

/**
 * this is a helper function to register a function that checks if a socket update command is relevant for a table
 */
const checkFunctions: {
  [tableIdentifier: string]: (cmd: UpdateCommand) => boolean;
} = {};
export const registerSocketFilterChangeCommandForTables = (
  tableIdentifier: string,
  checkFC: (cmd: UpdateCommand) => boolean
) => {
  checkFunctions[tableIdentifier] = checkFC;
};
export const unregisterSocketFilterChangeCommandForTables = (
  tableIdentifier: string
) => {
  delete checkFunctions[tableIdentifier];
};

const registeredSocketListeners = {};
export const registerSocketListener = (
  url: string,
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  if (!registeredSocketListeners[url]) {
    registeredSocketListeners[url] = true;

    const subFC = (cmd: UpdateCommand) => {
      const storeState = store.getState();
      let url: string;
      if (cmd.type === "asset") {
        url = `asset/${cmd.assetType}`;
      } else {
        url = cmd.type;
      }
      Object.entries(storeState.application.tables)
        .filter(([identifier, table]) => table.url === url)
        .forEach(([identifier, table]) => {
          (Array.isArray(cmd.objectID) ? cmd.objectID : [cmd.objectID]).forEach(
            (objectId) => {
              if (
                checkFunctions[identifier] &&
                !checkFunctions[identifier](cmd)
              ) {
                return;
              }

              if (cmd.uType === "created") {
                dispatch(addTableNewData(identifier, objectId));
              } else {
                if (cmd.uType === "deleted") {
                  dispatch(removeTableNewData(identifier, objectId));
                }

                const obj = table.data.find((e) => e["_id"] === objectId);
                if (obj) {
                  dispatch(
                    patchTableData(identifier, objectId, {
                      _dirty: cmd,
                      _dirtyTime: Date.now(),
                      [`_${cmd.uType}`]: true,
                    })
                  );
                }
              }
            }
          );
        });
    };

    if (url.toLocaleLowerCase().indexOf("asset") === 0) {
      SocketService.subscribeAsset(
        url.toLocaleLowerCase().split("/")[1],
        subFC
      );
    } else {
      switch (url.toLocaleLowerCase()) {
        case "user":
          SocketService.subscribeUser(subFC);
          break;
        case "team":
          SocketService.subscribeTeam(subFC);
          break;
        case "group":
          SocketService.subscribeGroup(subFC);
          break;
      }
    }
  }
};

(window as any).DataService = {
  emptyListData: (tableIdentifier: string) => {
    store.dispatch(emptyListData(tableIdentifier));
  },
  reloadTable: (
    tableIdentifier: string,
    onSuccess: (data) => void,
    onError: (error) => void
  ) => {
    store.dispatch(reloadTable(tableIdentifier, onSuccess, onError));
  },
};

import classNames from "classnames";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "rsuite";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import { TableSort } from "../../../../model/common/CommonInterfaces";
import InfiniteTable from "../../../../redux/actions/application/application-infinite-table-actions";
import { MatchQuery } from "../../../../services/DataService";
import LabeledInput from "../../../abstract-ui/forms/LabeledInput";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../../abstract-ui/general/ValidationPopover/ValidationPopover";
import BfIcon, { BfIconProps } from "../../../abstract-ui/icon/BfIcon";
import "./EZAssetSelect.scss";
import EZAssetSelectDropdown from "./EZAssetSelectDropdown";

export interface AssetValue {
  assetType: string;
  assetId: string;
}
export interface AssetSelectProps {
  id: string;
  label: string;
  assetType: string;
  matchQuery?: MatchQuery;
  icon?: BfIconProps;
  sort?: TableSort[];
  renderValue: (value: any) => React.ReactNode;
  renderMenu?: (value: any) => React.ReactNode;
}
export type AssetSelectStyle = "default" | "bf";
interface EZAssetSelectProps {
  readonly?: boolean;
  block?: boolean;
  additionalMatchQuery?: MatchQuery;
  identifier: string;
  className?: string;
  value: AssetValue;
  onChange: (value: AssetValue) => void;
  onBlur?: (ev, value: AssetValue) => void;
  disabled?: boolean;
  cleanable?: boolean;
  label?: string;
  labelPosition?: "top" | "left";
  assetTypes: AssetSelectProps[];
  disabledAssetTypes?: string[];
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  appearance?: AssetSelectStyle;
  initialAssetType?: string;
}

const EZAssetSelect = (props: EZAssetSelectProps) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const selectedAssetType = props.assetTypes.find(
    (e) => e.assetType === props.value?.assetType
  );

  const onSelect = (value?: AssetValue, ev?) => {
    props.onChange(value);
    props.onBlur?.(ev, value);

    if (ref?.current) {
      (ref?.current as any)
        ?.querySelector("button.rs-dropdown-toggle")
        ?.click();
    }
  };

  return (
    <LabeledInput
      label={props.label}
      labelPosition={props.labelPosition}
      error={!!props.validation?.message}
    >
      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <div
          className={classNames(
            `ez-asset-select`,
            props.className,
            props.block && "block",
            `appearance-${props.appearance || "default"}`
          )}
        >
          <Dropdown
            disabled={props.disabled || props.readonly}
            onOpen={() => {
              setIsOpen(true);
            }}
            onClose={() => {
              setIsOpen(false);
              dispatch(InfiniteTable.setSearch(props.identifier, ""));
            }}
            ref={ref}
            className="select-dropdown"
            title={
              <div className={`render-value`}>
                {selectedAssetType?.icon && (
                  <div className={`prefix-icon`}>
                    <BfIcon {...selectedAssetType?.icon} />
                  </div>
                )}
                {props.value && (
                  <AssetLoader
                    assetType={props.value.assetType}
                    id={props.value.assetId}
                    render={(asset) => (
                      <div className={`asset-select-label`}>
                        {selectedAssetType?.renderValue(asset)}
                      </div>
                    )}
                    placeholderProps={{ width: 50 }}
                  />
                )}
                {!props.value && <div className={`asset-select-label`}>-</div>}
              </div>
            }
          >
            <EZAssetSelectDropdown
              isOpen={isOpen}
              initialAssetType={props.initialAssetType}
              additionalMatchQuery={props.additionalMatchQuery}
              selectedIds={props.value ? [props.value.assetId] : []}
              assetTypes={
                (props.assetTypes || []).filter(
                  (e) =>
                    (props.disabledAssetTypes || []).indexOf(e.assetType) === -1
                ) || []
              }
              onSelect={onSelect}
              identifier={props.identifier}
              cleanable={props.cleanable}
            />
          </Dropdown>
        </div>
      </ValidationPopover>
    </LabeledInput>
  );
};

export default EZAssetSelect;

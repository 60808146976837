import { css } from "emotion";
import React from "react";
import { connect } from "react-redux";
import { GenericFormsLayoutProps } from "../../../modules/generic-forms/GFBaseElement";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import { IComponent } from "../IComponent";

export type SpanLayoutConf = {
  className?: string;
  _className?: string;
  components?: { [key: string]: IComponent | React.ReactNode };
  items: { [key: string]: any };
  condition?: string;
  content?: string;
  _content?: string;
} & AbstractStylableProps &
  GenericFormsLayoutProps;

type States = {} & AbstractStylableStates;

class SpanLayout extends AbstractStylableComponent<SpanLayoutConf, States> {
  render() {
    const {
      clickEvents,
      className,
      _className,
      components,
      items,
      _content,
      content,
      condition,
    } = this.props;

    if (!this.shoudBeRendered()) {
      return null;
    }

    const renderContent = _content
      ? this.evaluateExpression(_content)
      : content || "";

    const useClassName = _className
      ? this.evaluateExpression(_className)
      : className;
    return (
      <span
        className={`span-layout ${useClassName ? useClassName : ""} ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
      >
        {renderContent}
      </span>
    );
  }
}

const mapStateToProps = (state: AppState, props: SpanLayoutConf) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

const exportComp = connect(mapStateToProps, {})(SpanLayout);

export default exportComp;

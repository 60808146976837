import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import BaseAsset from "@/model/general-assets/BaseAsset";
import LanguageService from "@/services/LanguageService";
import classNames from "classnames";
import BFValueDisplay from "../abstract-ui/data/value-display/BFValueDisplay";
import { CustomField, Customfield_attachment } from "./CustomField.interface";
import CustomFieldsService from "./CustomFieldsService";
import "./DisplayCustomFields.scss";
import { CDNAttachmentValue, CFAttachmentEntry } from "./fields/CFAttachment";

interface CustomFieldsProps {
  fields: CustomField[];
  values: any;
  asset?: BaseAsset;
  assetType?: string;
  prefix?: string;
}
const DisplayCustomFields = (props: CustomFieldsProps) => {
  const fieldsTraversed = CustomFieldsService.getTraversedValues(
    props.fields,
    props.values
  );

  const nonAttachmentFields = fieldsTraversed.filter(
    (e) => e.fieldType !== "attachment"
  );
  const attachmentFields = fieldsTraversed.filter(
    (e) => e.fieldType === "attachment"
  );

  if (nonAttachmentFields.length === 0 && attachmentFields.length === 0) {
    return null;
  }
  return (
    <div className={classNames(`display-custom-fields`)}>
      <DebugDataComponent data={{ nonAttachmentFields, attachmentFields }} />
      {nonAttachmentFields.length > 0 && (
        <div className={`normal-fields`}>
          {nonAttachmentFields.map((e) => (
            <BFValueDisplay
              key={e.id}
              label={LanguageService.translateLabel(e.displayName)}
              value={props.values[e.id]}
              formatter={(value) =>
                CustomFieldsService.formatCustomFieldValue(e, value)
              }
            />
          ))}
        </div>
      )}
      {attachmentFields.map((e: Customfield_attachment) => {
        const value = (
          (props.values[e.id] || []) as CDNAttachmentValue[]
        ).filter((e) => e.status !== "archived");

        return (
          <div key={e.id} className={`attachment-entry`}>
            <div className={`attachment-header`}>{e.displayName}</div>
            <div className={`attachments`}>
              {value.length === 0 && (
                <div className={`no-attachment`}>
                  {i18n.t("FileUpload.noFile", "Keine Dateien")}
                </div>
              )}
              {value
                .map((e) => ({
                  type: "cdn",
                  linkToCdn: e.linkToCdn,
                  name: e.name,
                }))
                .map((attachment: CDNAttachmentValue) => (
                  <CFAttachmentEntry
                    attachment={attachment}
                    data={e}
                    asset={props.asset}
                    assetType={props.assetType}
                    prefix={props.prefix}
                  />
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DisplayCustomFields;

import BfButton from "../abstract-ui/general/Button/BFButton";
import FormPurposeChooser from "../generic-forms/layout/FormPurposeChooser";
import FormWizard from "../generic-forms/layout/FormWizard";
import { JsonPropertyComponent } from "../generic-forms/util/JsonValidation";
import Validators from "../generic-forms/util/Validatos";
import { ComponentsMapper } from "./../../utils/ComponentsMapper";
import { DEFAULT_MASKS } from "./../abstract-ui/forms/input/BFInput";
import { GFButton } from "./../generic-forms/common/CommonComponents";
import {
  GFFieldLabel,
  GFFieldValue,
  GFImage,
  GFLabel,
} from "./../generic-forms/display/DisplayComponents";
import { GFBaseElement } from "./../generic-forms/GFBaseElement";
import { GFCol, GFRow } from "./../generic-forms/layout/BootsrapLayout";
import ArrayFormField from "./form-fields/ArrayFormField";
import { GFAssetAssign } from "./form-fields/assetAssign/AssetAssign";
import { GFSingleAssetAssign } from "./form-fields/assetAssign/AssetAssignNew";
import {
  GFAvatar,
  GFCheckbox,
  GFCheckboxGroup,
  GFDate,
  GFGridCheck,
  GFIban,
  GFMail,
  GFNumber,
  GFPinInput,
  GFPriceInput,
  GFRadioGroup,
  GFRating,
  GFRestriction,
  GFSelect,
  GFText,
  GFTextarea,
  GFToggle,
  GFUpload,
} from "./form-fields/GenericFormFieldsImpl";
import GFLatLong from "./form-fields/GFLongLat";
import { GFLoanData } from "./form-fields/loan/GFLoanData";
import PermissionsFormField from "./form-fields/PermissionFormField";
import PermissionFormFieldSimple from "./form-fields/PermissionFormFieldSimple";
import PermissionFormFieldValues from "./form-fields/PermissionFormFieldValues";
import GFSubmitTransformer from "./GFSubmitTransformer";
import GFAutosave from "./layout-components/GFAutosave";
import {
  BFFieldViewTable,
  GFMessage,
  GFPromptBlock,
  GFShowAvatar,
} from "./layout-components/LayoutComponentsImpl";
import GFFormManipulator from "./manipulators/GFFormManipulator";

const GFInitializer = () => {
  ComponentsMapper.registerComponent("property", GFBaseElement, true);
  //### COMPONENTS

  ComponentsMapper.registerComponent("fieldValue", GFFieldValue as any, false);
  ComponentsMapper.registerComponent("fieldLabel", GFFieldLabel as any, false);
  ComponentsMapper.registerComponent("row", GFRow as any, false);
  ComponentsMapper.registerComponent("col", GFCol as any, false);
  ComponentsMapper.registerComponent("wizard", FormWizard as any, false);
  ComponentsMapper.registerComponent(
    "purposeChooser",
    FormPurposeChooser as any,
    false
  );
  ComponentsMapper.registerComponent("image", GFImage as any, false);
  ComponentsMapper.registerComponent("label", GFLabel as any, false);
  ComponentsMapper.registerComponent("button", GFButton as any, false);
  // ComponentsMapper.registerComponent("action", ActionComponent as any, true);

  // layout elements
  ComponentsMapper.registerComponent("autosave", GFAutosave as any, false);
  ComponentsMapper.registerComponent("prompt", GFPromptBlock as any, true);
  ComponentsMapper.registerComponent(
    "field-table-view",
    BFFieldViewTable as any,
    false
  );
  // ComponentsMapper.registerComponent("card", GFCard, true);

  ComponentsMapper.registerComponent("showAvatar", GFShowAvatar, true);
  ComponentsMapper.registerComponent("message", GFMessage, true);
  // form elementspassword
  ComponentsMapper.registerComponent("array", ArrayFormField as any, true);
  ComponentsMapper.registerComponent(
    "singleAsset",
    GFSingleAssetAssign as any,
    true
  );

  ComponentsMapper.registerComponent(
    DEFAULT_MASKS.priceInput,
    GFPriceInput,
    true
  );

  ComponentsMapper.registerComponent("loan-values", GFLoanData, true);

  ComponentsMapper.registerComponent("password", GFText, true);
  ComponentsMapper.registerComponent("mail", GFMail, true);
  ComponentsMapper.registerComponent("textarea", GFTextarea, true);
  ComponentsMapper.registerComponent("text", GFText, true);
  ComponentsMapper.registerComponent("toggle", GFToggle, true);
  ComponentsMapper.registerComponent("select", GFSelect, true);
  ComponentsMapper.registerComponent("radio", GFRadioGroup, true);
  ComponentsMapper.registerComponent("number", GFNumber, true);
  ComponentsMapper.registerComponent("date", GFDate, true);
  ComponentsMapper.registerComponent("checkbox", GFCheckbox, true);
  ComponentsMapper.registerComponent("checkbox-group", GFCheckboxGroup, true);
  ComponentsMapper.registerComponent("edit-avatar", GFAvatar, true);
  ComponentsMapper.registerComponent("rating", GFRating, true);
  ComponentsMapper.registerComponent("gridcheck", GFGridCheck, true);
  ComponentsMapper.registerComponent("upload", GFUpload, true);
  ComponentsMapper.registerComponent("pin", GFPinInput, true);
  ComponentsMapper.registerComponent("ibanInput", GFIban, true);

  ComponentsMapper.registerComponent("asset", GFAssetAssign, true);
  ComponentsMapper.registerComponent("latlong", GFLatLong, true);
  ComponentsMapper.registerComponent("longlat", GFLatLong, true);

  ComponentsMapper.registerComponent("button", BfButton as any, true);

  ComponentsMapper.registerComponent(
    "permissions-advanced",
    PermissionsFormField,
    true
  );
  ComponentsMapper.registerComponent(
    "permissions",
    PermissionFormFieldSimple,
    true
  );
  ComponentsMapper.registerComponent(
    "app-permissions",
    PermissionFormFieldValues,
    true
  );

  ComponentsMapper.registerComponent("restriction", GFRestriction, true);
  ComponentsMapper.registerComponent(
    "manipulator",
    GFFormManipulator as any,
    true
  );

  //### TRANSFORMERS
  GFSubmitTransformer.registerTransformer("restriction", (value, values) => {
    if (value) {
      return {
        users: value.users.map((entry) =>
          typeof entry === "string" ? entry : entry.id
        ),
        teams: value.teams.map((entry) =>
          typeof entry === "string" ? entry : entry.id
        ),
      };
    }
    return value;
  });

  //### VALIDATORS

  Validators.registerType(
    DEFAULT_MASKS.ibanInput,
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return typeof value === "string";
    }
  );
  Validators.registerType(
    DEFAULT_MASKS.priceInput,
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return typeof value === "number";
    }
  );

  const longlatCheck = (
    value: any,
    jsonPropertyComp: JsonPropertyComponent,
    jsonValue: { [key: string]: any }
  ) => {
    //todo check type if valid
    return typeof value.lng === "number" && typeof value.lat === "number";
  };

  Validators.registerType("longlat", longlatCheck);
  Validators.registerType("latlong", longlatCheck);

  Validators.registerType(
    "pin",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return typeof value === "string";
    }
  );
  Validators.registerType(
    "asset",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      if ((jsonPropertyComp as any).multiple) {
        return Array.isArray(value);
      } else {
        return typeof value === "string";
      }
    }
  );
  Validators.registerType(
    "singleAsset",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      return typeof value === "string";
    }
  );
  Validators.registerType(
    "objectId",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return true;
    }
  );
  // init validaton functions
  Validators.registerType(
    "array",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return true;
    }
  );
  // init validaton functions
  Validators.registerType(
    "restriction",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return true;
    }
  );

  Validators.registerType(
    "app-permissions",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return true;
    }
  );
  Validators.registerType(
    "permissions",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return true;
    }
  );
  Validators.registerType(
    "permissions-advanced",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return true;
    }
  );
  Validators.registerType(
    "edit-avatar",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return true;
    }
  );
  Validators.registerType("gridcheck", (value, jsonPropertyComp, jsonValue) => {
    if (Array.isArray(value)) {
      const allowedOptions = Validators.getValidOptions(
        (jsonPropertyComp as any).options,
        jsonValue
      );
      let success = true;
      for (const val of value) {
        success =
          success && Validators.checkOptionValidity(val, allowedOptions);
      }
      return success;
    } else {
      return false;
    }
  });

  Validators.registerType(
    "rating",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return typeof value === "number";
    }
  );
  Validators.registerType(
    "loan-values",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return true;
    }
  );
  Validators.registerType(
    "budget-distribution",
    (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => {
      //todo check type if valid
      return true;
    }
  );
};

export default GFInitializer;

import _ from "lodash";
import { WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Application } from "../../../model/db/Application";
import { UserConfig } from "../../../model/db/User";
import BFTable, {
  ColumnAction,
  ColumnProperties,
  ColumnState,
  PagingProperties,
  TableAddons,
  TableProperties,
} from "../../../modules/abstract-ui/data/table/BFTable";
import ExpressionHelper from "../../../modules/generic-forms/util/ExpressionHelper";
import { TABLE_EVENT_TYPES } from "../../../redux/actions/application/application-action-types";
import {
  setTableData,
  setTableEvent,
  setTableFilter,
  setTableSort,
} from "../../../redux/actions/application/application-actions";
import { updateUserConfig } from "../../../redux/actions/global/global-actions";
import { TableCache } from "../../../redux/reducers/application/ApplicationInterface";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  Filter,
  MatchQuery,
  RequestListOpts,
  emptyListData,
  requestListData,
} from "../../../services/DataService";
import { SubmitResponse } from "../../../services/SubmitService";
import { DataBusSubKeys } from "../../../utils/Constants";
import { getErrorLocalized } from "../../../utils/ErrorCodes";
import StringUtils from "../../../utils/StringUtils";
import Tools from "../../../utils/Tools";
import { SendEvent } from "../../../utils/abstracts/AbstractComponent";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import { IComponent } from "../../layouts/IComponent";
import "./TableComponent.scss";

interface ColumnConfig {
  component?: IComponent;
  className?: string;
  orderIndex: number;
  columnHeaderTextKey?: string;
  columnHeaderText?: string;

  // className?:string,
  width?: number;
  minWidth?: number;
  flexGrow?: number;
  fixed?: boolean | "left" | "right";
  resizable?: boolean;
  align?: "left" | "center" | "right";
  verticalAlign?: "top" | "middle" | "bottom";
  sortable?: boolean;
  hidden?: boolean;
  actions?: ColumnAction[];
  textSelectable?: boolean;
  convertParams?: (params: any, original: any) => any;
  // onResize?: (columnWidth: number, dataKey:string) => void
}

export type TableComponentProps = {
  useNewDataWarning?: boolean;
  newDataWarningText?: string;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  post?: boolean;
  asPost?: boolean;
  identifier: string;
  reloadOnMatchQueryChange?: boolean;
  noDataText?: string;
  className?: string;
  selectedRows?: any[];
  overwriteSkip?: (data: any[], tableCache: TableCache) => number;
  modifySkipFC?: (data: any[], tableCache: TableCache) => number;
  initialReload?: boolean;
  ignoreTableSelection?: boolean;
  ignoreInteractionHighlights?: boolean;
  addons?: TableAddons;
  onDoubleClickEvents?: { [key: string]: Function | SendEvent };
  prerequestCondition?: string;
  // fulltextSearch: string;
  insetShadow?: boolean;
  rowClassNameConditions?: { [className: string]: string };
  appearance: "clear" | "default";
  actionIdMapping: { [actionId: string]: string };
  stateSubscriptions?: string[];
  headerComponents: { [key: string]: IComponent };
  hideSelectionControls?: boolean;
  keyField?: string;
  reloadOnSubmitId?: string;
  detailIdentifier?: string;
  selectionLinkHref?: string;
  activeApplication: Application;
  displayNameKey?: string;
  hideTitlebar?: boolean;
  hideColumnHeaders?: boolean;
  scrollToSelection?: boolean;
  dataUrl: string;
  pageSize: number;
  dataRules?: {
    [key: string]: {
      condition?: string;
      [key: string]: any;
    };
  };
  // limit: number;
  // skip: number;
  striped?: boolean;
  hideConfigMenu?: boolean;
  // filters: { [dataKey: string]: Filter };
  _filters: string;
  columnsSortable?: boolean;

  useEndlessScrolling?: boolean;

  columns: { [dataKey: string]: ColumnConfig };
  initialSort?:
    | {
        dataKey: string;
        sortType: "asc" | "desc";
      }
    | {
        dataKey: string;
        sortType: "asc" | "desc";
      }[];
  sortInterceptorFc?: (
    sort: { dataKey: string; sortType: "asc" | "desc" }[]
  ) => { dataKey: string; sortType: "asc" | "desc" }[];

  // events: TableEvents;
  selection?: "multiple" | "single" | "none";

  userConfig: UserConfig;
  tableCache: {
    [tableIdentifier: string]: TableCache;
  };
  // data: Object[];
  // total: number;
  // sort?: {
  // 	dataKey: string;
  // 	sortType: "asc" | "desc";
  // };
  setTableEvent: (
    identifier: string,
    event: TABLE_EVENT_TYPES,
    data: Object
  ) => void;
  requestListData: (
    opts: RequestListOpts,
    cancelObj: { cancel?: () => void }
  ) => void;
  setTableFilter: (identifier: string, dataKey: string, filter: Filter) => void;
  setTableSort: (
    identifier: string,
    sort: {
      dataKey: string;
      sortType: "asc" | "desc";
    }[]
  ) => void;
  emptyListData: (identifier: string) => void;
  setTableData: (identifier: string, data: any) => void;

  updateUserConfig: (config: UserConfig) => void;
  matchQuery?: MatchQuery;
  ignoreMatchQueryExpressions?: boolean;
  footer?: IComponent;
  subHeader?: IComponent;
  onDataUpdate?: (data: TableCache) => void;
} & WithTranslation &
  RouteComponentProps &
  AbstractStylableProps;

type States = {
  loading: boolean;
  pageLoading: number;
  errorMessage: string;
  activePage: number;

  realPageSize: number;

  realColumns: { [dataKey: string]: ColumnConfig };
} & AbstractStylableStates;

export interface ReloadMessage {
  identifiers: string[];
  delay?: boolean;
}

class TableComponent extends AbstractStylableComponent<
  TableComponentProps,
  States
> {
  cancelObj: { cancel?: () => void } = {};

  readonly state: States = {
    loading: false,
    pageLoading: null,
    errorMessage: null,
    activePage: 1,
    realPageSize: 30,
    realColumns: null,
  };
  delayTimeoutID = null;

  shouldComponentUpdate(nextProps: TableComponentProps, nextState: States) {
    const output = super.shouldComponentUpdate(nextProps, nextState);

    //TODO PERFORMANCE check if other tables were updated, then do not rerender

    return output;
  }

  getDataRule() {
    if (this.props.dataRules) {
      let rule = Object.values(this.props.dataRules).find((rule) =>
        rule.condition ? this.evaluateExpression(rule.condition) : rule
      );

      if (rule) {
        let { condition, ...ruleToEvaluate } = rule;
        return Tools.evaluateObject(
          ruleToEvaluate,
          this.evaluateExpression.bind(this)
        ) as any;
      }
    }
    return null;
  }

  getTableCache(props?: TableComponentProps): TableCache {
    const useProps = props ? props : this.props;
    return useProps.tableCache[this.getIdentifier(useProps)]
      ? useProps.tableCache[this.getIdentifier(useProps)]
      : {
          loading: false,
          url: "",
          total: 0,
          limit: 0,
          skip: 0,
          timestamp: null,
          filters: null,
          data: [],
          events: {},
          fulltextSearch: "",
          sort: null,
        };
  }

  componentWillMount(): void {
    super.componentWillMount();

    this.calculateColumnConfiguration();
    this.setState({
      realPageSize:
        this.getTableCache().data?.length !== 0
          ? this.getTableCache().limit
          : this.props.pageSize || 30, //for the first request, the pageSize is one given into the component
      activePage:
        (this.getTableCache().data?.length || 0) !== 0
          ? Math.floor(
              (this.getTableCache().skip || 0) /
                (this.getTableCache().limit || this.props.pageSize || 30) +
                1
            )
          : 1,
    });
  }

  componentDidMount(): void {
    if (this.props.initialReload) {
      if (!this.getTableCache().sort && this.props.initialSort) {
        this.props.setTableSort(
          this.getIdentifier(),
          Array.isArray(this.props.initialSort)
            ? this.props.initialSort
            : [this.props.initialSort]
        );
      }
      setTimeout(() => this.reload()); // via timeout, so that tableSOrt will not be overset by requestPageData
    }

    // this.subscribe(DataBusSubKeys.RELOAD, (msg: ReloadMessage) => {
    //   if (msg.identifiers.indexOf(this.getIdentifier()) !== -1) {
    //     if (msg.delay) {
    //       this.refreshSideDelayed();
    //     } else {
    //       this.reload();
    //     }
    //   }
    // });

    this.subscribe(DataBusSubKeys.COLUMNS_UPDATED, ({ tableIdentifiers }) => {
      if (tableIdentifiers.indexOf(this.getIdentifier()) !== -1) {
        this.calculateColumnConfiguration();
      }
    });

    if (this.props.reloadOnSubmitId) {
      this.subscribe(DataBusSubKeys.SUBMIT_RESPONSE, (data: SubmitResponse) => {
        if (data.id === this.props.reloadOnSubmitId && data.success) {
          this.reload();
        }
      });
    }
    if (this.getTableCache().data?.length === 0) {
      if (!this.getTableCache().sort && this.props.initialSort) {
        // this.handleSortChanged(this.props.initialSort.dataKey, this.props.initialSort.sortType);
        this.props.setTableSort(
          this.getIdentifier(),
          Array.isArray(this.props.initialSort)
            ? this.props.initialSort
            : [this.props.initialSort]
        );
        setTimeout(() => this.requestPageData(1)); // via timeout, so that tableSOrt will not be overset by requestPageData
      } else {
        this.requestPageData(1);
      }
    }
    if (this.props.onDataUpdate) {
      this.props.onDataUpdate(this.getTableCache());
    }

    const reloadNow =
      this.props.tableCache[this.getIdentifier(this.props)]?.reload;

    if (reloadNow === true) {
      this.reload();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.userConfig !== this.props.userConfig) {
      this.calculateColumnConfiguration();
    }

    if (!this.getTableCache().loading && this.state.loading) {
      this.setState({ loading: false });
    }

    if (this.getTableCache().loading && !this.state.loading) {
      this.setState({ loading: true });
    }

    const reloadNow =
      this.props.tableCache[this.getIdentifier(this.props)]?.reload;
    // const reloadBefore =
    //   prevProps.tableCache[this.getIdentifier(prevProps.props)]?.reload;

    if (reloadNow === true) {
      this.reload();
    }

    // ignore undefined/null/"" changes, do not refresh the data on these changes
    const prevFulltextSearch =
      !this.getTableCache(prevProps).fulltextSearch ||
      this.getTableCache(prevProps).fulltextSearch.trim() === ""
        ? ""
        : this.getTableCache(prevProps).fulltextSearch;
    const fulltextSearch =
      !this.getTableCache().fulltextSearch ||
      this.getTableCache().fulltextSearch.trim() === ""
        ? ""
        : this.getTableCache().fulltextSearch;

    if (prevFulltextSearch !== fulltextSearch) {
      this.refreshSideDelayed();
    }

    if (
      this.getIdentifier() !== this.getIdentifier(prevProps) &&
      (!this.getTableCache() || !this.getTableCache().timestamp)
    ) {
      this.refreshSideDelayed();
    }

    if (
      this.props.reloadOnMatchQueryChange &&
      !_.isEqual(this.props.matchQuery, prevProps.matchQuery)
    ) {
      this.refreshSideDelayed();
    }

    const dataRule = this.getDataRule();
    if (dataRule !== null) {
      const tableCache = this.getTableCache();
      if (
        !_.isEqual(
          {
            filters: dataRule.filters,
            sort: dataRule.sort,
            fulltextSearch: dataRule.fulltextSearch
              ? dataRule.fulltextSearch
              : "",
          },
          {
            filters: tableCache.filters,
            sort: tableCache.sort,
            fulltextSearch: tableCache.fulltextSearch
              ? tableCache.fulltextSearch
              : "",
          }
        )
      ) {
        this.props.setTableData(this.getIdentifier(), dataRule);
        this.refreshSideDelayed();
      }
    }
  }

  calculateColumnConfiguration() {
    const { columns, userConfig, identifier, activeApplication } = this.props;

    let userColumnsConf = {};
    if (
      userConfig &&
      userConfig.application &&
      userConfig.application[activeApplication.name] &&
      userConfig.application[activeApplication.name].tableConfigs &&
      userConfig.application[activeApplication.name].tableConfigs[
        this.getIdentifier()
      ]
    ) {
      userColumnsConf =
        userConfig.application[activeApplication.name].tableConfigs[
          this.getIdentifier()
        ].columns || {};
    }

    const realColumns = _.merge(
      Object.fromEntries(
        Object.entries(columns).filter((e) => e[1] !== undefined)
      ),
      userColumnsConf
    ) as any; // ObjectTools.mergeObjects(columns, userColumnsConf);
    Object.keys(realColumns).forEach((key) => {
      realColumns[key].onResize = (columnWidth: number, dataKey: string) =>
        this.handleColumnResize(columnWidth, dataKey);
    });

    this.setState({
      realColumns,
    });
  }

  requestPageData(page: number, reset?: boolean) {
    const { prerequestCondition, onDataUpdate, modifySkipFC, overwriteSkip } =
      this.props;
    if (!reset && this.state.loading && this.state.pageLoading === page) {
      return;
    }
    this.setState({
      loading: true,
      errorMessage: null,
      pageLoading: page,
    });
    this.cancelObj = {};

    if (prerequestCondition && !this.evaluateExpression(prerequestCondition)) {
      this.setState({
        loading: false,
        activePage: 1,
        pageLoading: null,
        realPageSize: this.getTableCache().limit,
      });
      this.props.emptyListData(this.getIdentifier());
    } else {
      this.props.requestListData(
        {
          post: this.props.post || this.props.asPost,
          append: reset ? false : this.props.useEndlessScrolling,
          url: this.props.dataUrl,
          limit: this.state.realPageSize,
          realSkip: StringUtils.normalizeDecimal(
            (page - 1) * this.state.realPageSize
          ),
          skip:
            page === 1
              ? 0
              : overwriteSkip
              ? overwriteSkip(this.getTableCache().data, this.getTableCache())
              : StringUtils.normalizeDecimal(
                  (page - 1) * this.state.realPageSize
                ) +
                (modifySkipFC
                  ? StringUtils.normalizeDecimal(
                      modifySkipFC(
                        this.getTableCache().data,
                        this.getTableCache()
                      )
                    )
                  : 0),
          filters: this.getTableCache().filters,
          tableIdentifier: this.getIdentifier(),
          sort: this.getTableCache().sort,
          matchQuery: this.convertMatchQuery(this.props.matchQuery),
          textQuery:
            this.getTableCache().fulltextSearch &&
            this.getTableCache().fulltextSearch.trim() !== ""
              ? this.getTableCache().fulltextSearch
              : undefined,
          // sort: {
          //     fieldName: "",
          //     sortKey: "asc"
          // },
          onSuccess: (result) => {
            this.onDataSuccess();
          },
          onError: (err) => {
            this.setState({
              pageLoading: null,
              loading: false,
              errorMessage: getErrorLocalized(err),
              activePage: 1,
            });
            if (onDataUpdate) {
              onDataUpdate(this.getTableCache());
            }
          },
        },
        this.cancelObj
      );
    }
  }
  onDataSuccess() {
    const { onDataUpdate, modifySkipFC } = this.props;
    this.setState({
      pageLoading: null,
      loading: false,
      activePage: Math.floor(
        ((this.getTableCache().skip || 0) +
          (modifySkipFC
            ? modifySkipFC(this.getTableCache().data, this.getTableCache())
            : 0)) /
          (this.getTableCache().limit || this.props.pageSize || 30) +
          1
      ),
      realPageSize: this.getTableCache().limit || this.props.pageSize || 30,
    });
    if (onDataUpdate) {
      onDataUpdate(this.getTableCache());
    }
  }

  convertMatchQuery(param: MatchQuery | string) {
    const { ignoreMatchQueryExpressions } = this.props;
    if (!param) {
      return;
    }
    if (ignoreMatchQueryExpressions) {
      return param;
    }
    if (typeof param === "string") {
      return this.evaluateExpression(param);
    }

    const current = { ...param };
    if (current.type === "op") {
      if (current.op === "in" || current.op === "nin") {
        let values = [];
        if (typeof current.value === "string") {
          values = this.evaluateExpression(current.value);
        } else {
          values = current.value
            ? current.value.map((value) => {
                if (typeof current.value === "string") {
                  return this.replaceStringVariables(current.value);
                } else {
                  return value;
                }
              })
            : [];
        }
        current.value = values;
      } else if (typeof current.value === "string") {
        current.value = this.evaluateExpression(current.value);
      }
    } else {
      const query = current.query.map((value) => this.convertMatchQuery(value));
      current.query = query;
    }

    return current;
  }

  handlePageChanged(page: number) {
    if (page !== undefined) {
      this.requestPageData(page);
    }
  }

  handleSortChanged(dataKey: string, sortType: "asc" | "desc") {
    this.props.setTableSort(
      this.getIdentifier(),
      this.props.sortInterceptorFc
        ? this.props.sortInterceptorFc([{ dataKey, sortType }])
        : [{ dataKey, sortType }]
    );
    this.refreshSideDelayed();
  }

  handleRowClicked(rowData: Object[]) {
    // this.props.setTableEvent(this.getIdentifier(), "SELECTION", rowData);

    if (this.props.selection === "single" && this.props.selectionLinkHref) {
      // href structure = "/${appRoute}/group/${selectedId}"
      const appName = this.props.activeApplication.name;
      const params = {
        appRoute: appName,
        selectedId: rowData.length === 1 ? rowData[0]["_id"] : "",
        location: this.props.location.pathname,
      };
      const link = ExpressionHelper.evaluateExpression(
        this.props.selectionLinkHref,
        params
      );
      // setApplicationCacheData(`${this.getIdentifier()}#selection`, rowData[0], Number(new Date()), -1);
      if (this.props.history.location.pathname !== link) {
        this.props.history.push(link);
      }
    } else if (!this.props.ignoreTableSelection) {
      this.props.setTableEvent(this.getIdentifier(), "SELECTION", rowData);
    }
    this.emitComponentEvent({ type: "SELECTION", data: rowData });
  }

  handleRowDoubleClicked(rowData: Object) {
    this.emitComponentEvent({ type: "DOUBLE_CLICK", data: rowData });
    this.props.setTableEvent(this.getIdentifier(), "DOUBLE_CLICK", rowData);

    if (this.props.onDoubleClickEvents) {
      this.handleEvents(this.props.onDoubleClickEvents, { row: rowData });
    }
  }

  handleColumnResize(columnWidth: number, dataKey: string) {
    const columnProps: ColumnProperties = {
      width: columnWidth,
    };

    this.updateColumn(dataKey, columnProps);
  }

  handleFilterColumnChanged(dataKey: string, filter: Filter) {
    this.props.setTableFilter(this.getIdentifier(), dataKey, filter);
    this.refreshSideDelayed();
  }

  handleColumnStateChanged(
    dataKey: string,
    columnState: ColumnState,
    orderIndex?: number
  ) {
    const columnProps: ColumnProperties = {};

    if (columnState === "hidden") {
      columnProps.hidden = true;
      this.handleFilterColumnChanged(dataKey, null);
    }
    if (columnState === "visible") {
      columnProps.hidden = false;
      if (orderIndex === undefined) {
        const allSorted = Object.values(this.state.realColumns)
          .filter((a) => a.orderIndex >= 0 && a.orderIndex < 100000)
          .sort((a, b) => b.orderIndex - a.orderIndex);
        orderIndex = allSorted.length > 0 ? allSorted[0].orderIndex + 1 : 0;
      }
    }
    if (orderIndex !== undefined) {
      columnProps.orderIndex = orderIndex;
    }

    this.updateColumn(dataKey, columnProps);
  }

  updateColumn(dataKey: string, columnProps: ColumnProperties) {
    this.props.updateUserConfig({
      application: {
        [this.props.activeApplication.name]: {
          tableConfigs: {
            [this.getIdentifier()]: {
              columns: {
                [dataKey]: columnProps,
              },
            },
          },
        },
      },
    });
  }

  refreshSideDelayed() {
    if (!this.state.loading) {
      this.setState({
        loading: true,
        errorMessage: null,
      });
    }
    if (this.cancelObj && this.cancelObj.cancel) {
      this.cancelObj.cancel();
    }
    if (this.delayTimeoutID) {
      clearTimeout(this.delayTimeoutID);
    }

    this.delayTimeoutID = setTimeout(() => {
      this.requestPageData(1, true);
    }, 500);
  }

  reload() {
    const { activePage } = this.state;
    if (this.props.useEndlessScrolling) {
      this.requestPageData(1, true);
    } else {
      this.requestPageData(activePage);
    }
  }

  render() {
    if (!this.shoudBeRendered()) {
      return null;
    }
    const { loading, activePage, realPageSize, errorMessage, realColumns } =
      this.state;

    const {
      onScroll,
      keyField,
      hideConfigMenu,
      displayNameKey,
      hideTitlebar,
      selection,
      striped,
      columnsSortable,
      hideSelectionControls,
      headerComponents,
      stateSubscriptions,
      actionIdMapping,
      appearance,
      rowClassNameConditions,
      addons,
      hideColumnHeaders,
      useEndlessScrolling,
      footer,
      subHeader,
      ignoreInteractionHighlights,
      noDataText,
      insetShadow,
      className,
      scrollToSelection,
      newDataWarningText,
      useNewDataWarning,
    } = this.props;

    const tableProps: TableProperties = {
      useNewDataWarning,
      newDataWarningCount: useNewDataWarning
        ? (this.getTableCache().newDataIDs || []).length
        : undefined,
      newDataWarningText: newDataWarningText,
      onScroll,
      className,
      scrollToSelection,
      noDataText,
      insetShadow,
      ignoreInteractionHighlights,
      hideColumnHeaders,
      addons,
      headerComponents,
      hideSelectionControls,
      keyField: keyField ? keyField : "_id",
      hideConfigMenu,
      tableIdentifier: this.getIdentifier(),
      data: this.getTableCache().data,
      onSortColumn: (dataKey, sortType) =>
        this.handleSortChanged(dataKey, sortType),
      onRowClick: (rowDatas) => this.handleRowClicked(rowDatas),
      onRowDoubleClick: (rowData) => this.handleRowDoubleClicked(rowData),
      onReload: () => this.reload(),
      onColumnStateChanged: (
        dataKey: string,
        state: ColumnState,
        orderIndex?: number
      ) => this.handleColumnStateChanged(dataKey, state, orderIndex),
      onFilterChange: (dataKey: string, filter: Filter) =>
        this.handleFilterColumnChanged(dataKey, filter),
      filtersObject: this.getTableCache().filters,
      loading: loading || this.getTableCache().loading,
      title: displayNameKey ? (window as any).translate(displayNameKey) : null,
      hideTitlebar: hideTitlebar,
      selectedRows: this.props.selectedRows
        ? this.props.selectedRows
        : this.getTableCache().events && this.getTableCache().events.SELECTION
        ? this.getTableCache().events.SELECTION
        : [],
      selection: selection ? selection : "multiple",
      columnsSortable: columnsSortable ? columnsSortable : false,
      striped,
      errorMessage,
    };
    if (this.getTableCache().sort) {
      tableProps.sortColumn = this.getTableCache().sort[0].dataKey;
      tableProps.sortType = this.getTableCache().sort[0].sortType;
    }
    const { modifySkipFC } = this.props;

    const pagingProps: PagingProperties = {
      activePage,
      currentSize:
        this.getTableCache().data?.length +
        (modifySkipFC
          ? StringUtils.normalizeDecimal(
              modifySkipFC(this.getTableCache().data, this.getTableCache())
            )
          : 0),
      total:
        this.getTableCache().total +
        (modifySkipFC
          ? StringUtils.normalizeDecimal(
              modifySkipFC(this.getTableCache().data, this.getTableCache())
            )
          : 0),
      pageSize: realPageSize,
      onChangePage: (eventKey) => this.handlePageChanged(eventKey),
      disabled: loading || errorMessage !== null,
      useEndlessScrolling,
    };

    return (
      <BFTable
        params={this.props.params}
        rowClassNameConditions={rowClassNameConditions}
        appearance={appearance}
        style={this.state.usedStyle}
        actionIdMapping={actionIdMapping}
        stateSubscriptions={stateSubscriptions}
        columnProps={realColumns}
        tableProps={tableProps}
        pagingProps={pagingProps}
        subHeader={subHeader}
        footer={footer}
      />
    );
  }
}

const mapStateToProps = (state: AppState, props: TableComponentProps) => ({
  activeApplication: state.uiConfig.activeApplication,
  userConfig: state.global.user.config,
  tableCache: state.application.tables,
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default connect(mapStateToProps, {
  setTableEvent,
  setTableFilter,
  requestListData,
  updateUserConfig,
  setTableSort,
  emptyListData,
  setTableData,
})(withRouter(TableComponent)) as any;

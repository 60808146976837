import classNames from "classnames";
import React from "react";
import { Placement } from "../../common/Placements";
import BFDropdown from "../../general/Dropdown/BFDropdown";
import { DropdownItem } from "../../general/Dropdown/BFDropdownContent";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import BFUseTooltip from "../../general/tooltip/BFUseTooltip";
import LabeledInput from "../LabeledInput";
// import "./BFQuickSelect.scss";

interface BFQuickSelectProps {
  value: string;
  onChange: (value: string) => void;
  options: {
    label: string;
    value: string;
  }[];

  placeholder?: string;
  placement?: Placement;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  labelPosition?: "top" | "left";
  info?: React.ReactNode;
  label?: string;
  labelSuffix?: React.ReactNode;
  disabled?: boolean;
}
const BFQuickSelect = (props: BFQuickSelectProps) => {
  const selectedOption = props.options.find(
    (option) => option.value === props.value
  );
  const items = props.options.map(
    (option) =>
      ({
        type: "button",
        text: option.label,
        active: option.value === props.value,
        onSelect: () => {
          props.onChange(option.value);
        },
        closeOnClick: true,
      } as DropdownItem)
  );
  return (
    <LabeledInput
      label={props.label}
      info={props.info}
      labelPosition={props.labelPosition}
      suffix={props.labelSuffix}
      error={!!props.validation?.message}
    >
      <ValidationPopover
        validatorStyle={props.validatorStyle}
        level={props.validation ? props.validation.level : "error"}
        message={props.validation ? props.validation.message : null}
        marginTop={0}
      >
        <BFDropdown
          asOverlay
          disabled={props.disabled}
          className={classNames(`bf-quick-select`, {})}
          // onFocus={props.onFocus}
          // onBlur={props.onBlur}
          placement={props.placement}
          label={
            <BFUseTooltip
              tooltip={
                selectedOption ? (
                  <div>{selectedOption.label}</div>
                ) : props.placeholder ? (
                  <div className={`placeholder`}>{props.placeholder}</div>
                ) : (
                  "-"
                )
              }
            >
              <div className={!selectedOption ? "placeholder" : undefined}>
                {selectedOption.label || props.placeholder || "-"}
              </div>
            </BFUseTooltip>
          }
          items={items}
        />
      </ValidationPopover>
    </LabeledInput>
  );
};

export default BFQuickSelect;

import classNames from "classnames";
import i18n from "../../../../i18n";
import BFButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import BFMessage from "../../../../modules/abstract-ui/general/Message/BFMessage";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import { RAInvoice } from "../RAInterfaces";
import "./RAInvoiceEntryDependency.scss";

interface RAInvoiceEntryDependencyProps {
  invoice: RAInvoice;
  modalLinkIdentifier?: string;
}
const RAInvoiceEntryDependency = (props: RAInvoiceEntryDependencyProps) => {
  const routeTo = (id: string) => {
    const modalLinkIdentifier = props.modalLinkIdentifier || "__details";
    if (window.location.pathname.includes(`/${modalLinkIdentifier}`)) {
      DataBusDefaults.route({
        route: window.location.pathname.replace(
          new RegExp(`\/${modalLinkIdentifier}\/.*`),
          // /\/__details\/.*/,
          `/${modalLinkIdentifier}/${id}`
        ),
      });
    } else {
      DataBusDefaults.route({
        route: `${modalLinkIdentifier}/${id}`,
        append: true,
      });
    }
  };
  return (
    <div className={classNames(`ra-invoice-entry-dependecy`)}>
      {props.invoice?.data.correction?.isCorrection && (
        <div className="correction">
          <BFMessage
            type="info"
            text={
              <>
                <div className={`text`}>
                  {i18n.t(
                    "ra:UploadIncomingInvoice.InvoiceIsCorrection",
                    "Diese Rechnung ist eine Korrektur-Rechnung."
                  )}
                </div>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() =>
                    routeTo(props.invoice?.data.correction.linkedInvoice)
                  }
                >
                  {i18n.t(
                    "ra:UploadIncomingInvoice.ShowOriginal",
                    "Original anzeigen"
                  )}
                </BFButton>
              </>
            }
          ></BFMessage>
        </div>
      )}
      {props.invoice?.data.correction?.isStorno && (
        <div className="correction">
          <BFMessage
            type="info"
            text={
              <>
                <div className={`text`}>
                  {i18n.t(
                    "ra:UploadIncomingInvoice.InvoiceIsStorno",
                    "Diese Rechnung ist eine Storno-Rechnung."
                  )}
                </div>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() =>
                    routeTo(props.invoice?.data.correction.linkedInvoice)
                  }
                >
                  {i18n.t(
                    "ra:UploadIncomingInvoice.ShowOriginal",
                    "Original anzeigen"
                  )}
                </BFButton>
              </>
            }
          ></BFMessage>
        </div>
      )}

      {props.invoice?.data.correction?.stornoId && (
        <div className="correction">
          <BFMessage
            type="info"
            text={
              <>
                <div className={`text`}>
                  {i18n.t(
                    "ra:UploadIncomingInvoice.InvoiceHasStorno",
                    "Für diese Rechnung wurde eine Storno-Rechnung hochgeladen."
                  )}
                </div>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() =>
                    routeTo(props.invoice?.data.correction.stornoId)
                  }
                >
                  {i18n.t(
                    "ra:UploadIncomingInvoice.ShowStorno",
                    "Storno-Rechnung anzeigen"
                  )}
                </BFButton>
              </>
            }
          ></BFMessage>
        </div>
      )}

      {props.invoice?.data.correction?.correctedInvoiceId && (
        <div className="correction">
          <BFMessage
            type="info"
            text={
              <>
                <div className={`text`}>
                  {i18n.t(
                    "ra:UploadIncomingInvoice.InvoiceHasCorrection",
                    "Für diese Rechnung wurde eine Korrektur-Rechnung hochgeladen."
                  )}
                </div>
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() =>
                    routeTo(props.invoice?.data.correction.correctedInvoiceId)
                  }
                >
                  {i18n.t(
                    "ra:UploadIncomingInvoice.ShowCorrection",
                    "Korrektur-Rechnung anzeigen"
                  )}
                </BFButton>
              </>
            }
          ></BFMessage>
        </div>
      )}
    </div>
  );
};

export default RAInvoiceEntryDependency;

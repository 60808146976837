import { Component } from "react";
import Swiper, {
  FreeMode,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  SwiperOptions,
  Thumbs,
  Zoom,
} from "swiper";
import "swiper/css";
import "./SwiperComponent.scss";

type Props = {
  children?: React.ReactNode;
  id: string;
  style?: any;
  className?: string;
  usePagination: boolean;
  useNavigationButtons: boolean;
  useScrollbar: boolean;
  useMousewheel: boolean;
  useFreeMode?: boolean;
  useThumbs?: boolean;
  thumbsSwiper?: Swiper;
  direction: "horizontal" | "vertical";
  loop: boolean;
  spaceBetween?: number;
  useZoom?: boolean;
  watchSlidesProgress?: boolean;
  slidesPerView?: number | "auto";
  useKeyboard?: boolean;
  initialIndex?: number;
  centeredSlides?: boolean;
  onSlideChange?: (index: number) => void;
  onSwiper?: (swiper: Swiper) => void;
};

type States = {};

class SwiperComponent extends Component<Props, States> {
  static defaultProps = {
    usePagination: true,
    useNavigationButtons: false,
    useScrollbar: false,
    direction: "horizontal",
    loop: false,
  };
  readonly state: States = {};

  swiperComp: Swiper;

  componentDidUpdate(prevProps: Props) {
    if (this.props.children !== prevProps.children) {
      if (this.swiperComp?.updateSlides) {
        this.swiperComp.updateSlides();
      }
    }
    if (this.props.initialIndex !== prevProps.initialIndex) {
      if (this.swiperComp?.slideTo) {
        this.swiperComp.slideTo(this.props.initialIndex);
      }
    }
  }

  componentDidMount() {
    const {
      id,
      usePagination,
      direction,
      loop,
      useNavigationButtons,
      useScrollbar,
      useMousewheel,
      useZoom,
      useFreeMode,
      thumbsSwiper,
      spaceBetween,
      useThumbs,
      watchSlidesProgress,
      slidesPerView,
      useKeyboard,
    } = this.props;

    const options: SwiperOptions = {
      initialSlide: this.props.initialIndex,
      direction: direction,
      loop: loop,
      centeredSlides:
        this.props.centeredSlides !== undefined
          ? this.props.centeredSlides
          : true,
      observeParents: true,
      observer: true,
      keyboard: useKeyboard || false,
      threshold: 10,
      spaceBetween,
      zoom: this.props.useZoom,
      watchSlidesProgress,
      freeMode: useFreeMode,
      slidesPerView: slidesPerView || 1,
      thumbs: thumbsSwiper
        ? {
            swiper: thumbsSwiper,
          }
        : undefined,
      on: {
        slideChange: (swiper) => {
          if (this.props.onSlideChange) {
            this.props.onSlideChange(swiper.activeIndex);
          }
        },
      },
      ...(useMousewheel
        ? {
            mousewheel: {
              invert: false,
              // forceToAxis: true,
            },
          }
        : {}),
      modules: [
        ...(useNavigationButtons ? [Navigation] : []),
        ...(usePagination ? [Pagination] : []),
        ...(useMousewheel ? [Mousewheel] : []),
        ...(useZoom ? [Zoom] : []),
        ...(useFreeMode ? [FreeMode] : []),
        ...(useThumbs ? [Thumbs] : []),
        ...(useKeyboard ? [Keyboard] : []),
      ],
    };
    if (usePagination) {
      options.pagination = {
        el: `#${id}-pagination`,
      };
    }

    if (useNavigationButtons) {
      options.navigation = {
        nextEl: `#${id}-navigation-next`,
        prevEl: `#${id}-navigation-previous`,
      };
    }

    if (useScrollbar) {
      options.scrollbar = {
        el: `#${id}-scrollbar`,
      };
    }

    this.swiperComp = new Swiper(`#${id}-swiper`, options);

    if (this.props.onSwiper) {
      this.props.onSwiper(this.swiperComp);
    }
    // if (this.props.onSlideChange) {
    //   this.swiperComp.on("slideChange", (swiper) => {
    //     this.props.onSlideChange(swiper.activeIndex);
    //   });
    // }
  }

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return true;
  }

  render() {
    const {
      id,
      style,
      className,
      usePagination,
      useNavigationButtons,
      useScrollbar,
      useZoom,
    } = this.props;

    return (
      <div
        id={`${id}-swiper`}
        className={`swiper bf-swiper-component ${className ? className : ""}`}
        style={style}
      >
        <div className="swiper-wrapper">{this.props.children}</div>

        {usePagination ? (
          <div id={`${id}-pagination`} className="swiper-pagination"></div>
        ) : null}

        {useNavigationButtons ? (
          <>
            <div
              id={`${id}-navigation-previous`}
              className="swiper-button-prev"
            ></div>
            <div
              id={`${id}-navigation-next`}
              className="swiper-button-next"
            ></div>
          </>
        ) : null}

        {useScrollbar ? (
          <div id={`${id}-scrollbar`} className="swiper-scrollbar"></div>
        ) : null}
      </div>
    );
  }
}

export default SwiperComponent;

import { Message } from "rsuite";
import DataBus from "../../../services/DataBus";
import "./Toast.scss";

export const ToastMessage = (props: {
  type: any;
  content: string | React.ReactElement;
}) => <Message type={props.type}>{props.content}</Message>;
class ToastClass {
  // config
  constructor() {
    DataBus.subscribe(
      "TOAST",
      (data: {
        type: "info" | "success" | "warning" | "error";
        textKey?: string;
        text?: string | React.ReactNode;
        duration?: number;
      }) => {
        this[data.type](
          data.textKey ? (window as any).translate(data.textKey) : data.text,
          data.duration
        );
      }
    );
  }
  private show(
    type: "info" | "success" | "warning" | "error",
    text: string | React.ReactNode,
    duration?: number,
    onClose?: () => void
  ) {
    DataBus.emit("CREATE_TOAST_OBJ", {
      component: (
        <Message showIcon type={type} duration={duration} onClose={onClose}>
          {text}
        </Message>
      ),
      placement: "topCenter",
    });
  }

  info(
    text: string | React.ReactNode,
    duration?: number,
    onClose?: () => void
  ) {
    this.show("info", text, duration, onClose);
  }
  success(
    text: string | React.ReactNode,
    duration?: number,
    onClose?: () => void
  ) {
    this.show("success", text, duration, onClose);
  }
  warning(
    text: string | React.ReactNode,
    duration?: number,
    onClose?: () => void
  ) {
    this.show("warning", text, duration, onClose);
  }
  error(
    text: string | React.ReactNode,
    duration?: number,
    onClose?: () => void
  ) {
    this.show("error", text, duration, onClose);
  }
}
const Toast = new ToastClass();

export default Toast;

import classNames from "classnames";
import { Breadcrumb } from "rsuite";
import LanguageService, {
  TranslatedLabel,
} from "../../../../services/LanguageService";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import "./BFBreadcrumb.scss";

export type BFBreadcrumbEntry = {
  label: TranslatedLabel | string;
  path: string;
  id?: string;
};

type BFBreadcrumbProps = {
  entries: BFBreadcrumbEntry[];
  activePath?: string;
  className?: string;
  onClickBreadcrumb?: (breadcrumb: BFBreadcrumbEntry) => void;
  prefixIcon?: BfIconProps;
};

const BFBreadcrumb = (props: BFBreadcrumbProps) => {
  return (
    <div className={classNames("bf-breadcrumb", props.className)}>
      {props.prefixIcon && (
        <div className="breadcrumb-pre-icon">
          <BfIcon {...props.prefixIcon} size="sm" />
        </div>
      )}
      <Breadcrumb separator=">">
        {props.entries.map((entry) => {
          const isActive = props.activePath === entry.path;

          return (
            <Breadcrumb.Item
              active={isActive}
              key={`${entry.path}`}
              onClick={() => {
                if (props.onClickBreadcrumb && !isActive) {
                  props.onClickBreadcrumb(entry);
                }
              }}
            >
              {LanguageService.translateLabel(entry.label)}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BFBreadcrumb;

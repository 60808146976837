import ModalManager from "../../../components/ModalComponent/ModalManager";
import { BfIconProps } from "../../abstract-ui/icon/BfIcon";
import { RSAssetBaseParams, Resubmission } from "../RSInterfaces";
import ResubmissionForm from "../components/forms/ResubmissionForm";

const useOpenResubmissionForm = () => {
  const openResubmissionDialog = (params: {
    identifierToUpdate?: string;
    linkedAsset?: RSAssetBaseParams;
    resubmission?: Resubmission;
    typePermissions?: string[];
  }) => {
    ModalManager.show({
      size: "sm",
      noPadding: true,
      backdrop: "static",
      content: (states, setStates, closeModal) => (
        <ResubmissionForm
          onClose={closeModal}
          assetParams={params.linkedAsset}
          listIdentifier={params.identifierToUpdate}
          resubmission={params.resubmission}
          typePermissions={params.typePermissions}
        />
      ),
    });
  };

  const openResubmissionBtnIconProps: BfIconProps = {
    type: "light",
    size: "sm",
    data: "calendar-add",
  };

  return { openResubmissionDialog, openResubmissionBtnIconProps };
};

export default useOpenResubmissionForm;

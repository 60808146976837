import { useLottie } from "lottie-react";
import { CSSProperties } from "react";
import errorAnimation from "./errorAnimation.json";
interface Props {
  style?: CSSProperties;
}
const ErrorAnimation = (props: Props) => {
  const LoadingAnimation = useLottie(
    {
      animationData: errorAnimation,
      loop: false,
    },
    props.style
  );
  return LoadingAnimation.View;
};
export default ErrorAnimation;

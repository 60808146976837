import { TDocumentDefinitions } from "pdfmake/interfaces";
import { BlockType } from "../services/types/pdfConfigBlockTypes";
import {
  extractContentArray,
  findContentNode,
  findParentNode,
  isFrameNode,
  isPageConfigurationNode,
  swapElements,
} from "./PDFReducerUtils";
import {
  PDFDocumentDescription,
  PDFDocumentDescriptionContent,
  PDFDocumentDescriptionDynamicContent,
  PDFDocumentPageConfiguration,
} from "../services/types/pdfConfigNodeTypes";

export enum PDFDocumentDescriptionActions {
  ADD = "add",
  EDIT = "edit",
  DELETE = "delete",
  SWAP = "swap",
  ADD_BREAK_PAGE = "add-break-page",
  EDIT_BREAK_PAGE = "edit-break-page",
  DELETE_BREAK_PAGE = "delete-break-page",
  TEMPLATE = "template",
  EDIT_MULTIPLE = "edit-multiple",
}

const PageConfigurationTypesKeysMap: Partial<
  Record<BlockType, keyof TDocumentDefinitions>
> = {
  header: "header",
  footer: "footer",
  page: "pageMargins",
};

type Action =
  | {
      type: PDFDocumentDescriptionActions.ADD;
      payload: {
        block:
          | PDFDocumentDescriptionContent
          | PDFDocumentDescriptionDynamicContent;
        type: BlockType;
        parentId: number;
      };
    }
  | {
      type: PDFDocumentDescriptionActions.TEMPLATE;
      payload: {
        config: (
          | PDFDocumentDescriptionContent
          | PDFDocumentDescriptionDynamicContent
        )[];
      };
    }
  | {
      type: PDFDocumentDescriptionActions.EDIT_MULTIPLE;
      payload: {
        config: (
          | PDFDocumentDescriptionContent
          | PDFDocumentDescriptionDynamicContent
        )[];
      };
    }
  | {
      type: PDFDocumentDescriptionActions.EDIT;
      payload: {
        block:
          | PDFDocumentDescriptionContent
          | PDFDocumentDescriptionDynamicContent;
        type: BlockType;
        id: number;
      };
    }
  | {
      type: PDFDocumentDescriptionActions.DELETE;
      payload: {
        id: number;
        type: BlockType;
      };
    }
  | {
      type: PDFDocumentDescriptionActions.SWAP;
      payload: {
        dragId: number;
        hoverId: number;
        parentId: number;
      };
    };

type DocumentDescriptionState = PDFDocumentDescription;

export const pdfDocumentDescriptionReducer = (
  state: DocumentDescriptionState,
  action: Action
) => {
  const addTemplate = () => {
    if (
      action.type !== PDFDocumentDescriptionActions.TEMPLATE &&
      action.type !== PDFDocumentDescriptionActions.EDIT_MULTIPLE
    ) {
      return;
    }

    action.payload.config.forEach((node) => {
      if ("type" in node) {
        // it's header or footer, because only they have page count property

        if (node.type === "footer") {
          state.footer = node;
          return;
        }

        if (node.type === "header") {
          state.header = node;
          return;
        }

        if (node.type === "page") {
          state.page = node as unknown as PDFDocumentPageConfiguration;
          return;
        }
      }

      state.content.push(node);
    });
  };

  switch (action.type) {
    case PDFDocumentDescriptionActions.ADD: {
      if (isFrameNode(action.payload.type, action.payload.block)) {
        state[action.payload.type === "header" ? "header" : "footer"] = action
          .payload.block as PDFDocumentDescriptionDynamicContent;
        return;
      }

      if (isPageConfigurationNode(action.payload.type, action.payload.block)) {
        state.page = action.payload.block;
        return;
      }

      if (action.payload.parentId === 0) {
        state.content.push(action.payload.block);
        return;
      }

      if (state.header && action.payload.parentId === state.header.id) {
        state.header.content.push(action.payload.block);
        return;
      }

      if (state.footer && action.payload.parentId === state.footer.id) {
        state.footer.content.push(action.payload.block);
        return;
      }

      const element = findContentNode(action.payload.parentId, state.content);

      if (!element) {
        return;
      }

      const content = extractContentArray(
        element as PDFDocumentDescriptionContent
      );

      content.push(action.payload.block as any);
      return;
    }
    case PDFDocumentDescriptionActions.TEMPLATE: {
      addTemplate();
      return;
    }
    case PDFDocumentDescriptionActions.EDIT_MULTIPLE: {
      state.content = [];
      addTemplate();
      return;
    }
    case PDFDocumentDescriptionActions.EDIT: {
      if (isFrameNode(action.payload.type, action.payload.block)) {
        state[action.payload.type === "header" ? "header" : "footer"] = action
          .payload.block as PDFDocumentDescriptionDynamicContent;
        return;
      }

      if (isPageConfigurationNode(action.payload.type, action.payload.block)) {
        state.page = action.payload.block;
        return;
      }

      const headerNode = findContentNode(
        action.payload.id,
        state.header?.content ?? []
      );

      if (headerNode) {
        for (const key of Object.keys(action.payload.block)) {
          headerNode[key] = action.payload.block[key];
        }

        return;
      }

      const footerNode = findContentNode(
        action.payload.id,
        state.footer?.content ?? []
      );

      if (footerNode) {
        for (const key of Object.keys(action.payload.block)) {
          footerNode[key] = action.payload.block[key];
        }

        return;
      }

      const node = findContentNode(action.payload.id, state.content);

      if (!node) {
        return;
      }

      for (const key of Object.keys(action.payload.block)) {
        node[key] = action.payload.block[key];
      }

      return;
    }
    case PDFDocumentDescriptionActions.DELETE: {
      if (["header", "footer", "page"].includes(action.payload.type)) {
        const type = PageConfigurationTypesKeysMap[action.payload.type];

        state[type] = null;
        return;
      }

      const headerNode = findContentNode(
        action.payload.id,
        state.header?.content ?? []
      );

      if (headerNode) {
        state.header.content = state.header.content.filter(
          (node) => node.id !== action.payload.id
        );
        return;
      }

      const footerNode = findContentNode(
        action.payload.id,
        state.footer?.content ?? []
      );

      if (footerNode) {
        state.footer.content = state.footer.content.filter(
          (node) => node.id !== action.payload.id
        );
        return;
      }

      const node = findParentNode(action.payload.id, state.content);

      if (node) {
        extractContentArray(node, (nodes) =>
          (nodes as any).filter((node) => node.id !== action.payload.id)
        );
        return;
      }

      state.content = state.content.filter(
        (node) => node.id !== action.payload.id
      );

      return;
    }
    case PDFDocumentDescriptionActions.SWAP: {
      if (state.header && action.payload.parentId === state.header.id) {
        swapElements(
          state.header.content,
          action.payload.dragId,
          action.payload.hoverId
        );
        return;
      }

      if (state.footer && action.payload.parentId === state.footer.id) {
        swapElements(
          state.footer.content,
          action.payload.dragId,
          action.payload.hoverId
        );
        return;
      }

      const parent = findContentNode(action.payload.parentId, state.content);

      if (!parent && action.payload.parentId !== 0) {
        return;
      }

      if (action.payload.parentId !== 0) {
        extractContentArray(parent as PDFDocumentDescriptionContent, (nodes) =>
          swapElements(nodes, action.payload.dragId, action.payload.hoverId)
        );
        return;
      }

      swapElements(
        state.content,
        action.payload.dragId,
        action.payload.hoverId
      );
    }
  }
};

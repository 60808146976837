import { hasValue } from "../../../utils/Helpers";
import { StructType } from "../../reducers/struct/StructInterface";
import { AppState, store } from "../../store";

export type DataByUnitType<T> = {
  [unitType: string]: T;
};
export abstract class AbstractStructSelectors<T> {
  businessUnits: string[] = [];
  cache: { [key: string]: any } = {};

  abstract getStructType(): StructType;
  abstract loadStructData(types: string[]): Promise<DataByUnitType<T>>;

  clear() {
    this.cache = {};
  }
  businessUnitAdded(unit: string) {
    if (!this.businessUnits.includes(unit)) {
      this.businessUnits.push(unit);
    }
    // clearing cache to prevent getAllMethods to be cached incorrectly
    this.clear();
  }

  useCache<T>(methodName: string, args: IArguments, selector: () => T) {
    const key = methodName + JSON.stringify(args);
    if (!this.cache[key]) {
      const data = selector();
      if (hasValue(data)) {
        this.cache[key] = data;
      } else {
        return data;
      }
    }
    return this.cache[key] as T;
  }

  getData(unit: string): T {
    return this.useCache<T>("getData", arguments, () =>
      this.selectData(unit)(store.getState())
    );
  }

  getAllData(): T[] {
    return this.useCache<T[]>("getAllData", arguments, () =>
      this.businessUnits.map((unit) => this.getData(unit)).filter((e) => e)
    );
  }

  selectData: (unit: string) => (state: AppState) => T = (unit) => {
    return (state) => {
      const structData = state.struct[this.getStructType()]?.[unit];
      if (structData?.state === "success") {
        return structData.data as T;
      } else {
        throw new Error(
          `Structdata '${this.getStructType()}' for '${unit}' not loaded yet, but tried to access`
        );
      }
    };
  };
}

import {
  CLEAR_NOTIFICATIONS,
  NotificationsAction,
  REGISTER_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SEEN_ALL_NOTIFICATIONS,
  SET_ALL_NOTIFICATION,
  SET_SEEN_NOTIFICATION,
  TOGGLE_EXPAND_STATE,
} from "../../actions/notifications/notifications-actions-types";
import { AppState } from "../../store";
import { NotificationsReducer } from "./NotificationsInterface";

export const initialState: NotificationsReducer = {
  entries: [
    // ### EXAMPLES ###
    // {
    // 	type: "progress",
    // 	id: "11",
    // 	state: "inprogress",
    // 	title: "Generiere Dokument",
    // 	progressType: "indeterminated",
    // 	seen: true,
    // 	created: new Date("21.01.2018"),
    // 	deletable: false
    // },
    // {
    // 	type: "standard",
    // 	id: "12",
    // 	title: "Nachricht von Felix",
    // 	seen: false,
    // 	created: new Date()
    // },
    // {
    // 	type: "error",
    // 	id: "13",
    // 	title: "Fehlermeldung erhalten",
    // 	seen: true,
    // 	created: new Date(),
    // 	deletable: false
    // },
    // {
    // 	type: "warning",
    // 	id: "14",
    // 	title: "Wartungsarbeiten",
    // 	expanded: false,
    // 	message:
    // 		"Geplante Wartungsarbeiten am 31.12.2020 um 23.00 Uhr. Es kann zu einem Ausfall der Applikation kommen. Die geplanten Ausfallzeiten werden nicht die Dauer von 4 Stunden überschreiten.",
    // 	seen: true,
    // 	created: new Date()
    // }
  ],
};

export default function (
  state = initialState,
  action: NotificationsAction,
  root: AppState
): NotificationsReducer {
  let stateNew: NotificationsReducer;

  switch (action.type) {
    case SET_SEEN_NOTIFICATION:
      stateNew = {
        ...initialState,
        entries: (state.entries || []).map((entry) => {
          if (entry.id === action.id) {
            return {
              ...entry,
              seen: action.seen,
            };
          } else {
            return entry;
          }
        }),
      };
      return stateNew;

    case SEEN_ALL_NOTIFICATIONS:
      stateNew = {
        ...initialState,
        entries: (state.entries || []).map((entry) => {
          if (!entry.seen) {
            return {
              ...entry,
              seen: true,
            };
          } else {
            return entry;
          }
        }),
      };

      return stateNew;

    case CLEAR_NOTIFICATIONS:
      stateNew = {
        ...initialState,
        entries: (state.entries || []).filter(
          (entry) => entry.deletable === false
        ),
      };

      return stateNew;

    case REMOVE_NOTIFICATION:
      stateNew = {
        ...initialState,
        entries: (state.entries || []).filter((entry) => {
          if (entry.id === action.id) {
            // if (entry.deletable !== false) {
            return false;
            // }
          }
          return true;
        }), // && entry.deletable !== false)
      };

      return stateNew;

    case SET_ALL_NOTIFICATION: {
      return {
        entries: action.notifications,
      };
    }
    case REGISTER_NOTIFICATION: {
      const notification = (state.entries || []).find(
        (entry) => entry.id === action.notification.id
      );
      let entries;
      if (notification) {
        entries = (state.entries || []).map((entry) => {
          if (entry.id === action.notification.id) {
            return {
              ...entry,
              ...action.notification,
            };
          } else {
            return entry;
          }
        });
      } else {
        entries = [...(state.entries || []), action.notification];
      }

      stateNew = {
        ...initialState,
        entries,
      };
      return stateNew;
    }
    case TOGGLE_EXPAND_STATE: {
      const entries = (state.entries || []).map((entry) => {
        if (entry.id === action.id && entry.expanded !== undefined) {
          return {
            ...entry,
            expanded: action.toggled,
          };
        } else {
          return entry;
        }
      });
      stateNew = {
        ...initialState,
        entries: entries,
      };
      return stateNew;
    }
    default:
      return state;
  }
}

import { useTypedSelector } from "@/redux/hooks";
import { DefaultUIConfigs } from "@/redux/reducers/ui-config/UiConfig";
import classNames from "classnames";
import { FormApi, FormSubscription, SubmissionErrors } from "final-form";
import arrayMutators from "final-form-arrays";
import React, { useRef } from "react";
import { Form, FormRenderProps, FormSpy } from "react-final-form";
import Collapse from "rsuite/esm/Animation/Collapse";
import DebugDataComponent from "../../../debug/DebugDataComponent";
import i18n from "../../../i18n";
import { BFButtonProps } from "../../../modules/abstract-ui/general/Button/BFButton";
import { GFPromptBlock } from "../../../modules/generic-forms-impl/layout-components/LayoutComponentsImpl";
import { hasValue, valueOrDefault } from "../../../utils/Helpers";
import FormActionRow from "../FormActionRow/FormActionRow";
import "./FormStruct.scss";

interface FormStructProps<T> {
  className?: string;
  allowOverflow?: boolean;
  title?: string;
  description?: React.ReactNode;
  render: (props: FormRenderProps<T>) => React.ReactNode;

  validate?: (value: T) => any;
  notModal?: boolean;
  onSubmit: (
    data: T,
    form?: FormApi<T>,
    callback?: (errors?: SubmissionErrors) => void,
    submitActionId?: string
  ) => void | Promise<void>;
  initialValues?: T;
  onAbort?: (form: FormRenderProps) => void | Promise<void>;
  cancelText?: string;
  submitText?: string;
  additionalActions?: React.ReactNode;
  noPadding?: boolean;
  usePrompt?: boolean | "allowSubpaths";
  actionsOnChange?: boolean;
  hideActions?: boolean;
  ignoreSubmitOnEnter?: boolean;
  subscription?: FormSubscription;
  hideSubmit?: boolean;
  abortBtnProps?: Partial<BFButtonProps>;
  submitBtnProps?: Partial<BFButtonProps>;
  renderLeft?: (props: FormRenderProps<any>) => React.ReactNode;
  renderRight?: (props: FormRenderProps<any>) => React.ReactNode;
  additionalSubmit?: {
    submitText: string;
    props?: Partial<BFButtonProps>;
    dataActionid: string;
  };
}

const FormStruct = <T,>(props: FormStructProps<T>) => {
  const formRef = useRef(null);
  const debugActive = useTypedSelector(
    (state) => state.uiConfig.general[DefaultUIConfigs.DEBUG_COMPONENTS]
  );
  const submitActionRef = React.useRef<string>();
  const hideSubmit = props.hideSubmit || false;

  const scrollToFirstError = () => {
    setTimeout(() => {
      const errorEl = formRef?.current?.querySelector(
        ".error .rs-message-show"
      );
      if (errorEl) {
        errorEl.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 150);
  };

  return (
    <div
      className={classNames(`form-struct`, props.className, {
        "no-padding": props.noPadding,
        "not-modal": props.notModal,
        "allow-overflow": props.allowOverflow,
      })}
    >
      <Form
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            if (state.formState.values) {
              changeValue(state, field, () => value);
            }
          },
        }}
        initialValues={props.initialValues}
        onSubmit={async (values, form, errors) =>
          await props.onSubmit(values, form, errors, submitActionRef.current)
        }
        validate={props.validate}
        subscription={props.subscription}
        render={(renderProps) => (
          <form
            ref={formRef}
            onSubmit={(ev) => {
              const submitterAction = (ev.nativeEvent as SubmitEvent)
                .submitter as HTMLButtonElement;
              if (submitterAction) {
                submitActionRef.current =
                  submitterAction.getAttribute("data-action-id");
              }
              renderProps.handleSubmit(ev);
              scrollToFirstError();
            }}
            onKeyDown={
              valueOrDefault(props.ignoreSubmitOnEnter, true)
                ? (e) => {
                    if (e.key === "Enter") {
                      if (
                        (e.target as HTMLElement).localName !== "textarea" &&
                        !(e.target as HTMLElement).classList.contains(
                          "ck-content"
                        )
                      ) {
                        e.preventDefault();
                      }
                    }
                  }
                : undefined
            }
          >
            {hasValue(props.usePrompt) && (
              <GFPromptBlock
                allowSubpaths={props.usePrompt === "allowSubpaths"}
              />
            )}
            {debugActive && (
              <FormSpy subscription={{ values: true, errors: true }}>
                {(spyProps) => {
                  return (
                    <DebugDataComponent
                      data={{
                        values: spyProps.values,
                        errors: spyProps.errors,
                      }}
                    />
                  );
                }}
              </FormSpy>
            )}

            {props.title && <div className={"form-title"}>{props.title}</div>}
            <div className={`form-content`}>
              {props.renderLeft && (
                <div className={`form-left`}>
                  {props.renderLeft(renderProps)}
                </div>
              )}
              <div className={`form-center`}>
                {props.description && (
                  <div className={`form-description`}>{props.description}</div>
                )}
                <div className={`form-fields`}>{props.render(renderProps)}</div>
              </div>

              {props.renderRight && (
                <div className={`form-right`}>
                  {props.renderRight(renderProps)}
                </div>
              )}
            </div>

            {!props.hideActions && (
              <>
                {props.actionsOnChange && (
                  <FormSpy subscription={{ dirty: true, submitting: true }}>
                    {(spyProps) => (
                      <Collapse in={spyProps.dirty}>
                        <div>
                          <FormActionRow
                            abortBtnProps={props.abortBtnProps}
                            submitBtnProps={props.submitBtnProps}
                            additionalContent={props.additionalActions}
                            submitting={spyProps.submitting}
                            onAbort={
                              props.onAbort
                                ? () => props.onAbort(renderProps)
                                : undefined
                            }
                            cancelText={
                              props.cancelText ||
                              i18n.t("Global.Buttons.cancel")
                            }
                            submitText={
                              props.submitText ||
                              i18n.t("Global.Buttons.create")
                            }
                            hideSubmit={hideSubmit}
                            additionalSubmit={props.additionalSubmit}
                          />
                        </div>
                      </Collapse>
                    )}
                  </FormSpy>
                )}
                {!props.actionsOnChange && (
                  <FormSpy subscription={{ dirty: true, submitting: true }}>
                    {(spyProps) => (
                      <FormActionRow
                        abortBtnProps={props.abortBtnProps}
                        submitBtnProps={props.submitBtnProps}
                        additionalContent={props.additionalActions}
                        submitting={spyProps.submitting}
                        onAbort={
                          props.onAbort
                            ? () => props.onAbort(renderProps)
                            : undefined
                        }
                        cancelText={
                          props.cancelText || i18n.t("Global.Buttons.cancel")
                        }
                        submitText={
                          props.submitText || i18n.t("Global.Buttons.create")
                        }
                        hideSubmit={hideSubmit}
                        additionalSubmit={props.additionalSubmit}
                      />
                    )}
                  </FormSpy>
                )}
              </>
            )}
          </form>
        )}
      />
    </div>
  );
};

export default FormStruct;

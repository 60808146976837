import classNames from "classnames";
import i18n from "../../../../../i18n";
import {
  DSListEntryAction,
  DocumentStoreAssetParams,
  DocumentStoreDocument,
} from "../../../../../modules/document-store/DSInterfaces";
import DocumentStoreManaged from "../../../../../modules/document-store/DocumentStoreManaged";
import DocumentStoreRouted from "../../../../../modules/document-store/DocumentStoreRouted";
import { RSLinkedAssetFieldIdentifier } from "../../../../../modules/resubmission/RSConstants";
import { RSAssetBaseParams } from "../../../../../modules/resubmission/RSInterfaces";
import useOpenResubmissionForm from "../../../../../modules/resubmission/hooks/useOpenResubmissionForm";
import { useActivityConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";
import {
  AP_ACTIVITY_DOCUMENTS_FIELD_PATH,
  getAssetLabel,
} from "../../ActivityUtils";
import "./APActivityDocuments.scss";
interface APDamageClaimDocumentsProps {
  activity: APActivity;
  isStateManaged?: boolean;
}

const APActivityDocuments = (props: APDamageClaimDocumentsProps) => {
  const { activity } = props;
  const constants = useActivityConstants();
  const assetParams: DocumentStoreAssetParams = {
    asset: activity,
    assetType: constants?.assetType,
    type: activity.data.type,
    documentsFieldPath: "data.attachments",
  };

  const { openResubmissionBtnIconProps, openResubmissionDialog } =
    useOpenResubmissionForm();

  const documentEntryActions: DSListEntryAction<DocumentStoreDocument>[] = [
    {
      type: "button",
      text: i18n.t("Resubmission.Labels.Create", "Wiedervorlage erstellen"),
      onSelect: (document: DocumentStoreDocument) => {
        const type = props.activity.data.type;

        const assetParams: RSAssetBaseParams = {
          assetType: constants.assetType,
          assetId: props.activity._id,
          assetDescription: `${getAssetLabel(constants)} - ${i18n.t(
            "DocumentStore.Labels.Document",
            "Dokument:"
          )} ${document.name}`,
          assetField: {
            identifier: RSLinkedAssetFieldIdentifier.DSDocument,
            fieldPath: AP_ACTIVITY_DOCUMENTS_FIELD_PATH,
            id: document.linkToCdn,
          },
        };

        openResubmissionDialog({
          linkedAsset: assetParams,
          typePermissions: [type],
        });
      },
      icon: openResubmissionBtnIconProps,
    },
  ];

  return (
    <div
      className={classNames("ap-document-store", {
        "no-shadow": props.isStateManaged,
      })}
    >
      {props.isStateManaged ? (
        <DocumentStoreManaged
          assetParams={assetParams}
          documentEntryActions={documentEntryActions}
        />
      ) : (
        <DocumentStoreRouted
          assetParams={assetParams}
          documentEntryActions={documentEntryActions}
        />
      )}
    </div>
  );
};

export default APActivityDocuments;

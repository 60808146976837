import _ from "lodash";
import React, { CSSProperties } from "react";
import { FormSpy } from "react-final-form";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Loader } from "rsuite";
import { AppState } from "../../../redux/store";
import { AbstractComponent, AbstractStates } from "../../../utils/abstracts/AbstractComponent";
import { DataBusSubKeys } from "../../../utils/Constants";
import GenericLayoutComponent from "../../generic-forms/GenericLayoutComponent";
import { GenericFormsLayoutProps } from "../../generic-forms/GFBaseElement";
import "./GFAutosave.scss";

type Props = {
	ignoreNavigationPrompt?: boolean;
	autosaveDelay: number;
	style?: CSSProperties;
} & WithTranslation &
	RouteComponentProps &
	GenericFormsLayoutProps;

type States = {
	saving: "idle" | "delay" | "request";
	values: any;
	cancelToken: {
		cancel: () => void;
	};
} & AbstractStates;

class GFAutosave extends AbstractComponent<Props, States> {
	static defaultProps = {
		autosaveDelay: 1000
	};
	readonly state: States = {
		saving: "idle",
		cancelToken: null,
		values: null
	};

	autosaveTimout: NodeJS.Timeout;

	componentDidMount() {
		this.subscribe(DataBusSubKeys.SUBMIT_START, data => {
			if (data.id) {
				if (this.isMounted()) {
					if (data.id === (this.props.params.formRoot as any).formProps.form.mutators.getIdentifier()) {
						this.setState({ saving: "request", cancelToken: data.cancelObj });
					}
				}
			}
		});

		this.subscribe(DataBusSubKeys.SUBMIT_RESPONSE, data => {
			if (data.id) {
				if (this.isMounted()) {
					if (data.id === (this.props.params.formRoot as any).formProps.form.mutators.getIdentifier()) {
						this.setState({ saving: "idle" });
					}
				}
			}
		});
	}

	shouldComponentUpdate(nextProps: Props, nextState: States) {
		//do not update on every viewport change
		const shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
		if (nextProps.location.pathname !== this.props.location.pathname && nextState.saving !== "idle") {
			this.submitForm();
			this.setState({ cancelToken: null });
		}

		return shouldUpdate;
	}

	submitForm() {
		(this.props.params.formRoot as any).formProps.form.submit();
	}

	render() {
		const { condition, stateSubscriptions, style } = this.props;

		return (
			<>
				<GenericLayoutComponent
					stateSubscriptions={stateSubscriptions}
					condition={condition}
					style={style}
					render={styleProps => {
						return (
							<>
								<FormSpy subscription={{ errors: true, values: true }}>
									{({ errors, values }) => {
										const intialValues = (this.props.params.formRoot as any).formProps.formValues;
										if (_.isEqual(intialValues, values)) {
											if (this.autosaveTimout) {
												clearTimeout(this.autosaveTimout);
											}
											this.setState({ saving: "idle", values: values });
										} else if (this.state.values !== values) {
											if (this.state.saving === "request" && this.state.cancelToken && this.state.cancelToken.cancel) {
												this.state.cancelToken.cancel();
											}

											if (this.autosaveTimout) {
												clearTimeout(this.autosaveTimout);
											}
											if (Object.keys(errors).length === 0) {
												this.setState({ saving: "delay", values: values });
												this.autosaveTimout = setTimeout(() => {
													this.submitForm();
												}, this.props.autosaveDelay);
											}
										}
										if (this.state.saving === "request") {
											return (
												<div className={`gf-autosave`} style={styleProps}>
													<Loader size="sm" />
												</div>
											);
										} else {
											return null;
										}
									}}
								</FormSpy>
							</>
						);
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: AppState, props: Props) => ({});

export default withRouter(GFAutosave);

import moment from "moment";
import { isDefined } from "../../../../../../../../utils/Helpers";
import {
  RiskObjectLoanData,
  RiskScenarioLoanDataEntry,
} from "./RiskScenarioInterfaces";

export const RISK_SCENARIO_TABLE = "cb-risk-scenario-table";
class RiskScenarioServiceClass {
  calculateLoan = (data: RiskObjectLoanData) => {
    const output: RiskScenarioLoanDataEntry[] = [];

    const { startDate, duration, interestRate, repaymentRate, startValue } =
      data;
    const mStart = moment(data.startDate).utc(true);

    let currentMonth = mStart.clone();
    let currentMonthIndex = 0;
    let currentLoanValue = startValue;
    const monthDuration = duration * 12;

    const annuity =
      (currentLoanValue * (repaymentRate / 100)) / 12 +
      (currentLoanValue * (interestRate / 100)) / 12;

    while (currentMonthIndex < monthDuration && currentLoanValue > 0) {
      const interest = (currentLoanValue * (interestRate / 100)) / 12;
      const repayment = annuity - interest;
      const newLoanValue = Math.max(0, currentLoanValue - repayment);

      output.push({
        date: {
          month: currentMonth.month(),
          year: currentMonth.year(),
        },
        interest,
        repayment,
        openAmount: newLoanValue,
        payout: currentMonthIndex === 0 ? startValue : 0,
        count: 1,
      });

      currentMonth.add(1, "month");
      currentMonthIndex++;
      currentLoanValue = newLoanValue;
    }

    return output;
  };

  monthDateToDate = (date: { month: number; year: number }) => {
    if (!date) {
      return null;
    }
    return new Date(date.year, date.month, 1);
  };

  aggregateLoanDataEntries = (
    loanDataEntries: RiskScenarioLoanDataEntry[][]
  ) => {
    const output: RiskScenarioLoanDataEntry[] = [];

    loanDataEntries.forEach((entries) => {
      entries.forEach((entry) => {
        const existingEntry = output.find(
          (e) =>
            e.date.month === entry.date.month && e.date.year === entry.date.year
        );
        if (existingEntry) {
          existingEntry.interest += entry.interest;
          existingEntry.repayment += entry.repayment;
          existingEntry.openAmount += entry.openAmount;
          existingEntry.payout += entry.payout;
          existingEntry.count += entry.count;
        } else {
          output.push({ ...entry });
        }
      });
    });

    return output.sort((a, b) => {
      return (
        Number(this.monthDateToDate(a.date)) -
        Number(this.monthDateToDate(b.date))
      );
    });
  };

  createArrayWithIncome = (
    from: Date,
    to: Date,
    rentNet: number,
    growthRate: number,
    vacancyRate: number,
    otherCostFactor: number
  ) => {
    const output: {
      income: [Date, number][];
      vacancy: [Date, number][];
      costs: [Date, number][];
      plan: [Date, number][];
    } = {
      income: [],
      costs: [],
      vacancy: [],
      plan: [],
    };

    let currentMonth = moment(from).utc(true).clone();
    let currentMonthIndex = 0;

    let currentRentNet = rentNet;

    while (currentMonth.isSameOrBefore(to)) {
      if (currentMonth.isSameOrAfter(moment().utc(true), "month")) {
        if (
          currentMonth.month() === 0 &&
          !currentMonth.isSame(moment().utc(true), "month")
        ) {
          currentRentNet = currentRentNet * (1 + growthRate / 100);
        }

        const rentWithVacancy = currentRentNet * (1 - vacancyRate / 100);
        const vacancyLoss = currentRentNet * (vacancyRate / 100);

        output.plan.push([currentMonth.toDate(), currentRentNet]);
        output.income.push([currentMonth.toDate(), rentWithVacancy]);
        output.costs.push([
          currentMonth.toDate(),
          (rentWithVacancy * otherCostFactor) / 100,
        ]);
        output.vacancy.push([currentMonth.toDate(), vacancyLoss]);
      } else {
        output.income.push([currentMonth.toDate(), undefined]);
        output.costs.push([currentMonth.toDate(), undefined]);
        output.vacancy.push([currentMonth.toDate(), undefined]);
        output.plan.push([currentMonth.toDate(), undefined]);
      }
      currentMonth.add(1, "month");
      currentMonthIndex++;
    }

    return output;
  };

  extractRegions = (
    baseData: [Date, number][],
    comparisonData: [Date, number][],
    compareFC: (a: number, b: number) => boolean,
    valueSumFC: (a: number, b: number) => number
  ) => {
    const output: {
      from: Date;
      to: Date;
      sum: number;
    }[] = [];

    let currentRegionStart = null;
    let currentRegionEnd = null;
    let currentRegionSum = 0;

    let currentIndex = 0;
    while (currentIndex < baseData.length) {
      const baseValue = baseData[currentIndex][1];
      const comparisonValue = comparisonData[currentIndex][1];

      if (compareFC(baseValue, comparisonValue)) {
        if (!currentRegionStart) {
          currentRegionStart = baseData[currentIndex][0];
        }
        currentRegionSum += valueSumFC(baseValue, comparisonValue);
        currentRegionEnd = baseData[currentIndex][0];
      } else {
        if (currentRegionStart) {
          output.push({
            from: currentRegionStart,
            to: currentRegionEnd,
            sum: currentRegionSum,
          });
          currentRegionStart = null;
          currentRegionEnd = null;
          currentRegionSum = 0;
        }
      }

      currentIndex++;
    }

    if (currentRegionStart) {
      output.push({
        from: currentRegionStart,
        to: baseData[baseData.length - 1][0],
        sum: currentRegionSum,
      });
    }
    return output;
  };

  aggregateData = (data: [Date, number][][]) => {
    return this.aggregateDataEntries(data.flat(1));
  };
  aggregateDataEntries = (data: [Date, number][]) => {
    return data
      .reduce((acc, val) => {
        const existingEntry = acc.find(
          (e) =>
            e[0].getFullYear() === val[0].getFullYear() &&
            e[0].getMonth() === val[0].getMonth()
        );
        if (existingEntry) {
          return acc.map((e) =>
            e[0] === existingEntry[0]
              ? [
                  existingEntry[0],
                  isDefined(existingEntry[1]) || isDefined(val[1])
                    ? (existingEntry[1] || 0) + (val[1] || 0)
                    : undefined,
                ]
              : e
          );
        } else {
          return [...acc, val];
        }
      }, [])
      .sort((a, b) => Number(a[0]) - Number(b[0])) as [Date, number][];
  };

  createArrayWithData = (
    from: Date,
    to: Date,
    data: RiskScenarioLoanDataEntry[],
    selector: (e: RiskScenarioLoanDataEntry) => number,
    addEndZero = false,
    handleEmptyMonth:
      | "setUndefined"
      | "usePrevious"
      | "setZero" = "setUndefined",
    addStartZero = false
  ) => {
    const output: [Date, number][] = [];

    let currentMonth = moment(from).clone().utc(true);
    let currentMonthIndex = 0;

    const lastDate = data.reduce(
      (prev, curr) =>
        prev.year > curr.date.year ||
        (prev.year === curr.date.year && prev.month >= curr.date.month)
          ? prev
          : { month: curr.date.month, year: curr.date.year },
      { month: 0, year: 0 }
    );

    const lastDateMonth = moment(this.monthDateToDate(lastDate)).utc(true);
    const checkAddEndZeroMonth = lastDateMonth
      .clone()
      .add(1, "month")
      .utc(true);
    let addedAlreadyOne = false;

    const toMoment = moment(to).utc(true);
    while (currentMonth.isSameOrBefore(toMoment)) {
      const indexOfdataForMonth = data.findIndex(
        (e) =>
          e.date.month === currentMonth.month() &&
          e.date.year === currentMonth.year()
      );

      const dataForMonth =
        indexOfdataForMonth >= 0 ? data[indexOfdataForMonth] : null;

      let value = undefined;
      // check if date is after last date
      if (
        currentMonth.year() < lastDate.year ||
        (lastDate.year === currentMonth.year() &&
          lastDate.month >= currentMonth.month())
      ) {
        if (!dataForMonth && handleEmptyMonth === "usePrevious") {
          const previousData = output[output.length - 1];
          if (previousData) {
            value = previousData[1];
          }
        }
        if (!dataForMonth && handleEmptyMonth === "setZero") {
          value = 0;
        }
        if (dataForMonth) {
          value = selector(dataForMonth);
        }
      }

      if (addStartZero) {
        if (value !== undefined && addedAlreadyOne === false) {
          addedAlreadyOne = true;
          const datePrevious = currentMonth
            .clone()
            .utc(true)
            .subtract(1, "month")
            .toDate();
          const indexPrevious = output.findIndex(
            (e) => Number(e[0]) === Number(datePrevious)
          );
          if (indexPrevious >= 0) {
            output[indexPrevious] = [datePrevious, 0];
          } else {
            output.push([datePrevious, 0]);
          }
        }
      }

      output.push([currentMonth.toDate(), value]);

      currentMonth.add(1, "month");
      currentMonthIndex++;

      if (addEndZero && currentMonth.isSame(checkAddEndZeroMonth, "month")) {
        output.push([currentMonth.toDate(), 0]);
        // ingore next month, added 0 already
        currentMonth.add(1, "month");
        currentMonthIndex++;
      }
    }

    return output;
  };
}
const RiskScenarioService = new RiskScenarioServiceClass();
export default RiskScenarioService;

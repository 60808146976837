import MQ from "@/utils/MatchQueryUtils";

export const matchQueryOfFilterType = (filterType: InvoiceFilterType) => {
  switch (filterType) {
    case "open":
      return MQ.and(
        MQ.eq("data.payed", false),
        MQ.ne("data.status", "declined"),
        MQ.ne("data.status", "init")
      );
    case "declined":
      return MQ.and(MQ.eq("data.status", "declined"));
    case "closed":
      return MQ.and(MQ.eq("data.payed", true), MQ.ne("data.status", "init"));
  }
};
export type InvoiceFilterType = "open" | "closed" | "declined";

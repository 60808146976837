import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFCurrency from "@/modules/abstract-ui/forms/input/BFCurrency";
import PermissionService from "@/services/PermissionService";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { Field } from "react-final-form";
import { OAObject } from "../../../types/object.interface";
import {
  ObjectKind,
  getFeaturesForKind,
} from "../../../types/objectKind.interface";
// import './ObjectFormFeaturePurchasePrice.scss';

interface ObjectFormFeaturePurchasePriceProps {}

export const getInitialValueFeaturePurchasePrice = (
  asset: OAObject,
  kind: ObjectKind
) => {
  const features = getFeaturesForKind(kind);

  if (
    features.includes("purchasePrice") &&
    PermissionService.hasObjectKindPermission(kind, "purchasePrice.createEdit")
  ) {
    return {
      value: asset?.data?.feature?.purchasePrice.value || {
        amount: 0,
        currency: StringUtils.getCurrency(),
      },
      date: asset?.data?.feature?.purchasePrice.date || null,
    };
  } else {
    return undefined;
  }
};
const ObjectFormFeaturePurchasePrice = (
  props: ObjectFormFeaturePurchasePriceProps
) => {
  return (
    <div className={classNames(`object-form-feature-immo`)}>
      <div className={`field-row`}>
        <div className={`field __flex-1`}>
          <Field
            name="feature.purchasePrice.value"
            validate={FV.compose(FV.required(), FV.min(0))}
            render={({ input, meta }) => (
              <BFCurrency
                {...input}
                label={`${i18n.t(
                  "obj:ObjectForm.PurchasePrice.purchasePrice",
                  "Kaufpreis"
                )}*`}
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>

        <div className={`field __flex-1`}>
          <Field
            name="feature.purchasePrice.date"
            validate={FV.compose(FV.required(), FV.min(0))}
            render={({ input, meta }) => (
              <BFDatefield
                label={i18n.t("obj:ObjectForm.PurchasePrice.date", "Kaufdatum")}
                {...input}
                preventNull
                {...FV.getValidation(meta)}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ObjectFormFeaturePurchasePrice;

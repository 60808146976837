import classNames from "classnames";
import React, { SyntheticEvent, useRef } from "react";
import styles from "./vertical-scroll.module.css";

export const VerticalScroll: React.FC<{
  ganttHeight: number;
  ganttFullHeight: number;
  headerHeight: number;
  rtl: boolean;
  onScroll: (event: SyntheticEvent<HTMLDivElement>) => void;
}> = ({ ganttHeight, ganttFullHeight, headerHeight, rtl, onScroll }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <div
      style={{
        height: ganttHeight,
        marginTop: headerHeight,
        marginLeft: rtl ? "" : "-1rem",
      }}
      className={classNames(styles.scroll, "vertical-scroll")}
      onScroll={onScroll}
      ref={scrollRef}
    >
      <div style={{ height: ganttFullHeight, width: 1 }} />
    </div>
  );
};

import classNames from "classnames";
import TrustedDevices from "../../../user-management/trusted-devices/TrustedDevices";
import "./UserDevices.scss";

interface UserDevicesProps {}
const UserDevices = (props: UserDevicesProps) => {
  return (
    <div className={classNames(`user-devices`)}>
      <TrustedDevices />
    </div>
  );
};

export default UserDevices;

import Collapse from "rsuite/esm/Animation/Collapse";
import { TagConfigWithUnit } from "../../../../apps/AppConfigInterfaces";
import LanguageService from "../../../../services/LanguageService";
import { isDefined } from "../../../../utils/Helpers";
import BFStatus from "../status/BFStatus";
import "./TagView.scss";

interface TagViewProps {
  possibleTags: TagConfigWithUnit[];
  tags: string[];
}
const TagView = (props: TagViewProps) => {
  const validTags = (props.tags || [])
    .map((e) => props.possibleTags.find((a) => a.id === e))
    .filter((e) => isDefined(e));

  return (
    <Collapse in={validTags.length > 0}>
      <div>
        <div className={`bf-tag-view`}>
          {validTags.map((tag) => (
            <BFStatus
              label={LanguageService.translateLabel(tag.displayName)}
              color={tag.color}
              key={tag.id}
              size="xxs"
            />
          ))}
        </div>
      </div>
    </Collapse>
  );
};

export default TagView;

import classNames from "classnames";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import ListComponent from "../../../../../../configurable/components/ListComponent/ListComponent";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import DataBusDefaults from "../../../../../../services/DataBusDefaults";
import MQ from "../../../../../../utils/MatchQueryUtils";
import { useActivityConstants } from "../../../ActivityHooks";
import { APProjectActivity } from "../../../ActivityInterfaces";
import { APProjectBudget } from "../project-budget/APProjectBudgetInterfaces";
import APConstructionDiariesForm from "./APConstructionDiariesForm";
import { APProjectConstructionDiary } from "./APConstructionDiariesInterfaces";
import "./APConstructionDiariesView.scss";
import APConstructionDiaryCard from "./APConstructionDiaryCard";

interface APConstructionDiariesViewProps {
  activity: APProjectActivity;
}

const ENTRY_ROW_HEIGHT = 60;

const TABLE_IDENT = "construction-diaries-of-project";
const APConstructionDiariesView = (props: APConstructionDiariesViewProps) => {
  const constants = useActivityConstants();
  const activeId = props.activity?.data.currentActiveBudgetId;

  const renderContent = (currentBudget?: APProjectBudget) => (
    <div className={classNames(`ap-construction-diaries-view`)}>
      <div className={`filter-header`}>
        <BFButton
          onClick={() => {
            ModalManager.show({
              noPadding: true,
              size: "md",
              content: (states, setStates, onClose) => (
                <APConstructionDiariesForm
                  // diary={props.diary}
                  projectId={props.activity._id}
                  onClose={onClose}
                  budget={currentBudget}
                  onSuccess={() => {
                    DataBusDefaults.reload({
                      identifiers: [TABLE_IDENT],
                    });
                  }}
                />
              ),
            });
          }}
        >
          {i18n.t("apTemplate.ConstructionDiary.AddNewDiary", "Neuer Eintrag")}
        </BFButton>
      </div>

      <div className={`diaries`}>
        <ListComponent
          assetType={AssetTypes.ActivityRelated.ConstructionDiary}
          additionalMatchQuery={MQ.and(
            MQ.eq("data.projectId", props.activity._id)
          )}
          limitPerRequest={5}
          asPost
          render={(data: APProjectConstructionDiary) => (
            <APConstructionDiaryCard
              key={data._id}
              diary={data}
              budget={currentBudget}
            />
          )}
          identifier={TABLE_IDENT}
          cleanupOnUnmount
          initialVisibleSort={{
            key: "data.entryDate",
            dir: "desc",
          }}
        />
      </div>
    </div>
  );

  return props.activity?.data.currentActiveBudgetId ? (
    <AssetLoader
      assetType={constants.fields?.projectBudgetFeature?.budgetAssetType}
      id={activeId}
      render={renderContent}
    />
  ) : (
    renderContent(null)
  );
};

export default APConstructionDiariesView;

import { useEffect, useState } from "react";
import { RouteConstants } from "../../../utils/Constants";
import { DocumentStoreDirectory } from "../DSInterfaces";

type useDocumentStorePathProps = {
  pathEntries: string[];
  rootDocumentDirectory: DocumentStoreDirectory;
};

export const useDocumentStorePath = (
  props: useDocumentStorePathProps
): {
  directoryPathObjects: DocumentStoreDirectory[];
  currentDirectory: DocumentStoreDirectory;
  unvalidPath: string | undefined;
} => {
  const { pathEntries, rootDocumentDirectory } = props;

  const [unvalidPath, setUnvalidPath] = useState<string | undefined>(undefined);
  const [directoryPathObjects, setDirectoryPathObjects] = useState<
    DocumentStoreDirectory[]
  >([]);

  useEffect(() => {
    if (unvalidPath !== undefined) {
      setUnvalidPath(undefined);
    }

    const breadcrumbOjects: DocumentStoreDirectory[] = [];
    const trimmedPathEntries = pathEntries.filter((path) => path !== "");
    if (trimmedPathEntries.length === 1) {
      if (trimmedPathEntries[0] !== rootDocumentDirectory.pathIdentifier) {
        setUnvalidPath(trimmedPathEntries[0]);
        return;
      }
      setDirectoryPathObjects([rootDocumentDirectory]);
      return;
    }

    // Check if a modal is in the path and get path only until this point
    let modalInPath = false;
    // Walk the DocumentStoreDirectory tree to get all directories of the current path
    trimmedPathEntries.forEach((path) => {
      if (!modalInPath) {
        if (path.startsWith(RouteConstants?.MODAL_PREFIX)) {
          modalInPath = true;
          return;
        }

        // each path is an identifier of a props.documentDirectories entry
        let directoryObj: DocumentStoreDirectory | null = null;
        if (breadcrumbOjects.length === 0) {
          directoryObj = rootDocumentDirectory;
        } else {
          directoryObj = breadcrumbOjects[
            breadcrumbOjects.length - 1
          ].subDirectories?.find(
            (directory) => directory.pathIdentifier === path
          );
        }

        if (directoryObj) {
          breadcrumbOjects.push(directoryObj);
        } else {
          setUnvalidPath(path);
        }
      }
    });

    setDirectoryPathObjects(breadcrumbOjects);
  }, [pathEntries.join("#")]);

  const currentDirectory =
    directoryPathObjects[directoryPathObjects.length - 1];

  return {
    directoryPathObjects,
    currentDirectory,
    unvalidPath,
  };
};

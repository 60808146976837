import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import _ from "lodash";
import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import i18n from "../../../../i18n";
import BFSelect from "../../../../modules/abstract-ui/forms/select/BFSelect";
import BfIcon from "../../../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../redux/store";
import ArrayUtils from "../../../../utils/ArrayUtils";
import { CB_CONFIG_KEY } from "../CashBudgetConst";
import CashBudgetUtils from "../CashBudgetUtils";
import {
  CASH_BUDGET_ALL_OBJECTS_VAL,
  CASH_BUDGET_DATA_PREFIX,
  CASH_BUDGET_FILTER_PREFIX_ACCOUNT,
  CASH_BUDGET_FILTER_PREFIX_OBJECT,
} from "../services/CashBudgetService";
import {
  setFlexCacheData,
  setFlexCacheDataMultiple,
} from "./../../../../redux/actions/application/application-actions";
import "./CashBudgetFilterHeader.scss";

type Props = {
  debugMode: boolean;
  setFlexCacheDataMultiple: (
    flexData: { category: string; identifier: string; data: any }[]
  ) => void;
  setFlexCacheData: (category: string, identifier: string, data: any) => void;
  selectedEntities: string[];
  selectedFilter: string;
  entitySelectType: "normal" | "multiple";
  availableEntities: { value: any; label: string }[];
  ignoreAccounts: boolean;
  useForComparison?: boolean;
  businessUnits?: string[];
} & RouteComponentProps &
  WithTranslation;

type States = {};

class CashBudgetFilterHeader extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  omponentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return true;
  }

  render() {
    const { ignoreAccounts } = this.props;

    let optionsObjects = [];
    if (
      this.props.selectedEntities &&
      this.props.selectedEntities.length === 1
    ) {
      const selectedEntity = this.props.selectedEntities[0];
      const entity = CashBudgetUtils.findEntity(selectedEntity);
      optionsObjects = [
        ...optionsObjects,
        ...OrgaStruct.getObjectsOfEntity(entity._id, true).map((e) => {
          if (!e.bankInfo?.mainBankAccount) {
            return null;
          }

          const bank = CashBudgetUtils.findBankAccount(
            e.bankInfo.mainBankAccount
          );
          const allObjectsOfBank = CashBudgetUtils.getObjectsByBankAccount(
            e.bankInfo.mainBankAccount
          );

          return {
            value: `${CASH_BUDGET_FILTER_PREFIX_OBJECT}${e._id}`,
            listLabel: (
              <div className={`account-entry`}>
                <div className={`name`}>{e.displayName}</div>
                <div className={`infos`}>
                  <span className={`extras`}>
                    {i18n.t("cb:Label.account", "Konto")} ({bank.account})
                    {allObjectsOfBank.length > 1
                      ? ` - ${allObjectsOfBank.length - 1} weitere${
                          allObjectsOfBank.length - 1 === 1 ? "s" : ""
                        } Objekt${allObjectsOfBank.length - 1 !== 1 ? "e" : ""}`
                      : ""}
                  </span>
                </div>
              </div>
            ),
            label: e.displayName,
          };
        }),
      ].filter((e) => e);
    }
    // optionsObjects.push({
    //   value: `${CASH_BUDGET_FILTER_PREFIX_OBJECT}${CASH_BUDGET_UNASSIGNED_OBJECTS_VAL}`,
    //   label: "- keinem Objekt zugeordnet -",
    // });
    let optionsAccounts = [];
    if (
      this.props.selectedEntities &&
      this.props.selectedEntities.length === 1
    ) {
      const selectedEntity = this.props.selectedEntities[0];
      const entity = CashBudgetUtils.findEntity(selectedEntity);
      optionsAccounts = [
        ...optionsAccounts,
        ...OrgaStruct.getBankAccountsOfEntity(entity._id, true).map((e) => {
          const objects = CashBudgetUtils.getObjectsByBankAccount(e._id);
          return {
            value: `${CASH_BUDGET_FILTER_PREFIX_ACCOUNT}${e._id}`,
            label: e.bankName + (this.props.debugMode ? ` (${e._id})` : ""),
            listLabel: (
              <div className={`account-entry`}>
                <div className={`name`}>{e.bankName}</div>
                <div className={`infos`}>
                  <span className={`extras`}>
                    {e.iban} -{" "}
                    {objects.length !== 1 ? `${objects.length} ` : ""}
                    {objects.length !== 1 ? "Objekte" : objects[0].displayName}
                  </span>
                </div>
              </div>
            ),
          };
        }),
      ];
    }
    // FIXME temporary removed
    // optionsAccounts.push({
    //   value: `${CASH_BUDGET_FILTER_PREFIX_ACCOUNT}${CASH_BUDGET_UNASSIGNED_OBJECTS_VAL}`,
    //   label: "- unbekanntem Konto zugeordnet -",
    // });

    const objects = [
      {
        value: CASH_BUDGET_ALL_OBJECTS_VAL,
        label: i18n.t("Global.Labels.total"),
      },
      // ...(this.props.requestType !== "starmoney"
      ...(ignoreAccounts || optionsAccounts.length === 0
        ? []
        : [
            {
              value: CASH_BUDGET_FILTER_PREFIX_ACCOUNT,
              label: (
                <div className={`filter-sub-head`}>
                  <BfIcon type="bf" size="lg" data="saving-bank-1" />{" "}
                  {i18n.t("cb:Label.accounts", "Konten")}
                </div>
              ),
              children: optionsAccounts || [],
            },
          ]),
      ...(optionsObjects.length === 0
        ? []
        : [
            {
              value: CASH_BUDGET_FILTER_PREFIX_OBJECT,
              label: (
                <div className={`filter-sub-head`}>
                  <BfIcon type="bf" size="lg" data="building-house" />{" "}
                  {i18n.t("cb:Label.objects", "Objekte")}
                </div>
              ),
              children: optionsObjects || [],
            },
          ]),
      // : []),
    ];

    return (
      <div className={`cashbudget-filter-header`}>
        <BFSelect
          type={this.props.entitySelectType}
          placement={"bottomStart"}
          menuMaxHeight={400}
          cleanable={false}
          label={i18n.t("cb:Label.entity", "Gesellschaft")}
          labelPosition="left"
          placeholder={i18n.t("Global.Labels.pleaseSelect", "Bitte auswählen")}
          value={this.props.selectedEntities}
          renderSelectAllFooter={true}
          renderMultipleValueCount={true}
          onChange={(comp) =>
            comp
              ? this.props.setFlexCacheDataMultiple([
                  {
                    category: CB_CONFIG_KEY,
                    identifier: "selectedEntities",
                    data: comp,
                  },
                  {
                    category: CB_CONFIG_KEY,
                    identifier: "selectedFilter",
                    data: CASH_BUDGET_ALL_OBJECTS_VAL,
                  },
                ])
              : null
          }
          data={
            this.props.entitySelectType === "multiple"
              ? this.props.availableEntities
              : [
                  {
                    value: this.props.availableEntities.map((e) => e.value),
                    label: i18n.t("Global.Labels.total", "Gesamt"),
                  },
                  ...(this.props.businessUnits.length > 1
                    ? this.props.businessUnits
                        .map((ident) => ({
                          label: `${i18n.t(
                            "cb:Label.unit",
                            "Bereich"
                          )} - ${CashBudgetUtils.getLabelOfUnit(ident)}`,
                          value: this.props.availableEntities
                            .filter(
                              (e) =>
                                OrgaStruct.getEntity(e.value)?.type === ident
                            )
                            .map((e) => e.value),
                        }))
                        .filter(
                          (e) =>
                            e.value.length > 0 &&
                            !_.isEqual(
                              ArrayUtils.sortData(
                                this.props.availableEntities.map((e) => e.value)
                              ),
                              ArrayUtils.sortData(e.value)
                            )
                        )
                    : []),
                  ...this.props.availableEntities.map((e) => ({
                    label: e.label,
                    value: [e.value],
                  })),
                ]
          }
          typeChangeText={
            this.props.entitySelectType === "multiple"
              ? i18n.t(
                  "cb:Labels.switchToSingleSelection",
                  "Wechseln zu Einzelauswahl"
                )
              : i18n.t(
                  "cb:Labels.switchToMultipleSelection",
                  "Wechseln zu Mehrfachauswahl"
                )
          }
          onTypeChange={() => {
            this.props.setFlexCacheDataMultiple([
              {
                category: CB_CONFIG_KEY,
                identifier: "entitySelectType",
                data:
                  this.props.entitySelectType === "multiple"
                    ? "normal"
                    : "multiple",
              },
              {
                category: CB_CONFIG_KEY,
                identifier: "selectedFilter",
                data: CASH_BUDGET_ALL_OBJECTS_VAL,
              },
              ...(this.props.entitySelectType === "multiple"
                ? [
                    {
                      category: CB_CONFIG_KEY,
                      identifier: "selectedEntities",
                      data: this.props.availableEntities.map((e) => e.value),
                    },
                  ]
                : []),
            ]);
          }}
        />

        {this.props.selectedEntities &&
        this.props.selectedEntities.length === 1 ? (
          <BFSelect
            placement={"bottomStart"}
            className={`selection-filter`}
            menuMaxHeight={400}
            cleanable={false}
            defaultExpandItemValues={[
              CASH_BUDGET_FILTER_PREFIX_OBJECT,
              CASH_BUDGET_FILTER_PREFIX_ACCOUNT,
            ]}
            disabledItemValues={[
              CASH_BUDGET_FILTER_PREFIX_OBJECT,
              CASH_BUDGET_FILTER_PREFIX_ACCOUNT,
            ]}
            label={i18n.t("cb:Label.filter", "Filter")}
            labelPosition="left"
            searchable={false}
            value={this.props.selectedFilter}
            renderTreeNode={(nodeData) => {
              // return "asdasdasd";
              if (nodeData.listLabel) {
                return nodeData.listLabel;
              } else {
                return nodeData.label;
              }
            }}
            renderValue={(value, item, selectedElement) => {
              return (
                <div className={`selected-value`}>
                  {value.indexOf(CASH_BUDGET_FILTER_PREFIX_ACCOUNT) === 0 ? (
                    <BfIcon type="bf" size="lg" data="saving-bank-1" />
                  ) : value.indexOf(CASH_BUDGET_FILTER_PREFIX_OBJECT) === 0 ? (
                    <BfIcon type="bf" size="lg" data="building-house" />
                  ) : (
                    ""
                  )}{" "}
                  {item.label}
                </div>
              );
            }}
            onChange={(comp) =>
              comp
                ? this.props.setFlexCacheData(
                    CB_CONFIG_KEY,
                    "selectedFilter",
                    comp
                  )
                : null
            }
            type={"tree"}
            data={objects}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => {
  return {
    businessUnits: state.uiConfig.activeApplication.constants?.businessUnits,
    debugMode: state.uiConfig.general[DefaultUIConfigs.DEBUG],
    viewportHeight: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_HEIGHT],
    cachedData: state.application.cache.flex[CASH_BUDGET_DATA_PREFIX],
    CB_CONFIG_KEY: CB_CONFIG_KEY,
    selectedEntities:
      state.application.cache.flex[CB_CONFIG_KEY] &&
      state.application.cache.flex[CB_CONFIG_KEY]["selectedEntities"]
        ? state.application.cache.flex[CB_CONFIG_KEY]["selectedEntities"]
        : null,
    entitySelectType:
      state.application.cache.flex[CB_CONFIG_KEY] &&
      state.application.cache.flex[CB_CONFIG_KEY]["entitySelectType"]
        ? state.application.cache.flex[CB_CONFIG_KEY]["entitySelectType"]
        : null,
    selectedFilter:
      state.application.cache.flex[CB_CONFIG_KEY] &&
      state.application.cache.flex[CB_CONFIG_KEY]["selectedFilter"]
        ? state.application.cache.flex[CB_CONFIG_KEY]["selectedFilter"]
        : null,
    availableEntities:
      state.application.cache.flex[CB_CONFIG_KEY] &&
      state.application.cache.flex[CB_CONFIG_KEY]["availableEntities"]
        ? state.application.cache.flex[CB_CONFIG_KEY]["availableEntities"]
        : [],
  };
};

export default connect(mapStateToProps, {
  setFlexCacheData,
  setFlexCacheDataMultiple,
})(withRouter(withTranslation()(CashBudgetFilterHeader))) as any;

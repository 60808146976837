import { useLottie } from "lottie-react";
import { CSSProperties } from "react";
import arrowAnimation from "./arrowAnimation.json";
interface Props {
  style?: CSSProperties;
}
const ArrowAnimation = (props: Props) => {
  const ArrowAnimation = useLottie(
    {
      animationData: arrowAnimation,
      loop: true,
    },
    props.style
  );
  return ArrowAnimation.View;
};
export default ArrowAnimation;

import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import { useState } from "react";
import { Field } from "react-final-form";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../../i18n";
import BFInput from "../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BfIcon from "../../../../../../modules/abstract-ui/icon/BfIcon";
import CacheService from "../../../../../../services/CacheService";
import { APProjectBudget } from "../project-budget/APProjectBudgetInterfaces";
import APConstructionDiariesEntriesField from "./APConstructionDiariesEntriesField";
import "./APConstructionDiariesForm.scss";
import {
  getConstructionDiaryStatusLabel,
  getConstructionDiaryTemperatureUnit,
  getConstructionDiaryTypeLabel,
  getInitialValuesForConstructionDiary,
  submitConstructionDiary,
} from "./APConstructionDiariesHelpers";
import {
  APConstructionDiaryForm,
  APProjectConstructionDiary,
  ConstructionDiaryDocumentationType,
} from "./APConstructionDiariesInterfaces";

interface APConstructionDiariesFormProps {
  projectId: string;
  diary?: APProjectConstructionDiary;
  budget?: APProjectBudget;
  onClose: () => void;
  onSuccess?: (diary: APProjectConstructionDiary) => void;
}
const APConstructionDiariesForm = (props: APConstructionDiariesFormProps) => {
  const [initial] = useState(getInitialValuesForConstructionDiary(props.diary));
  return (
    <FormStruct<any>
      onSubmit={async (values: APConstructionDiaryForm) => {
        const diary = (await submitConstructionDiary(
          values,
          props.projectId,
          props.diary
        )) as APProjectConstructionDiary;

        CacheService.update(diary);

        props.onSuccess?.(diary);
        props.onClose();
      }}
      onAbort={() => props.onClose()}
      initialValues={initial}
      submitText={
        props.diary
          ? i18n.t(
              "apTemplate.ConstructionDiary.EditDiary",
              "Änderungen speichern"
            )
          : i18n.t(
              "apTemplate.ConstructionDiary.CreateDiary",
              "Bautagebuch erstellen"
            )
      }
      usePrompt
      ignoreSubmitOnEnter
      className={`ap-construction-diaries-form`}
      // noPadding
      render={(form) => (
        <>
          <div className={`meta`}>
            <Field name="entryDate">
              {({ input, meta }) => (
                <div className={`__field`}>
                  <BFDatefield
                    label={i18n.t(
                      "apTemplate:ConstructionDiary.EntryDate",
                      "Datum"
                    )}
                    {...input}
                    {...FormValidators.getValidation(meta)}
                  />
                </div>
              )}
            </Field>
            <Field name="status">
              {({ input, meta }) => (
                <div className={`__field`}>
                  <BFSelect
                    label={i18n.t(
                      "apTemplate:ConstructionDiary.Status",
                      "Status"
                    )}
                    cleanable={false}
                    data={[
                      {
                        value: "draft",
                        label: getConstructionDiaryStatusLabel("draft"),
                      },
                      {
                        value: "complete",
                        label: getConstructionDiaryStatusLabel("complete"),
                      },
                    ]}
                    {...input}
                    {...FormValidators.getValidation(meta)}
                  />
                </div>
              )}
            </Field>
            <Field name="diaryType">
              {({ input, meta }) => (
                <div className={`__field`}>
                  <BFSelect
                    label={i18n.t(
                      "apTemplate:ConstructionDiary.DiaryType",
                      "Art"
                    )}
                    cleanable={false}
                    data={[
                      {
                        value: "daily",
                        label: getConstructionDiaryTypeLabel("daily"),
                      },
                      {
                        value: "weekly",
                        label: getConstructionDiaryTypeLabel("weekly"),
                      },
                    ]}
                    {...input}
                    {...FormValidators.getValidation(meta)}
                  />
                </div>
              )}
            </Field>
          </div>
          <div className={`weather`}>
            <Field name="weather.temperature.unit">
              {({ input: unitInput }) => (
                <Field name="weather.temperature.value">
                  {({ input, meta }) => (
                    <div className={`__field`}>
                      <BFInput
                        textAlign="right"
                        step={0.1}
                        type="number"
                        label={i18n.t(
                          "apTemplate:ConstructionDiary.Temperature",
                          "Temperatur"
                        )}
                        prefix={
                          <BfIcon
                            type="light"
                            data="temperature-thermometer"
                            size="xs"
                          />
                        }
                        suffix={getConstructionDiaryTemperatureUnit(
                          unitInput.value || "celsius"
                        )}
                        {...input}
                        {...FormValidators.getValidation(meta)}
                      />
                    </div>
                  )}
                </Field>
              )}
            </Field>
            <Field name="weather.wind">
              {({ input, meta }) => (
                <div className={`__field`}>
                  <BFInput
                    step={0.1}
                    textAlign="right"
                    type="number"
                    label={i18n.t("apTemplate:ConstructionDiary.Wind", "Wind")}
                    prefix={
                      <BfIcon
                        type="light"
                        data="weather-wind-flow-3"
                        size="xs"
                      />
                    }
                    suffix={i18n.t(
                      i18n.t("apTemplate:ConstructionDiary.WindUnit", "km/h")
                    )}
                    {...input}
                    {...FormValidators.getValidation(meta)}
                  />
                </div>
              )}
            </Field>
            <Field name="weather.rain">
              {({ input, meta }) => (
                <div className={`__field`}>
                  <BFInput
                    step={1}
                    textAlign="right"
                    type="number"
                    label={i18n.t("apTemplate:ConstructionDiary.Rain", "Regen")}
                    prefix={
                      <BfIcon
                        type="light"
                        data="weather-cloud-rain-drops"
                        size="xs"
                      />
                    }
                    suffix={i18n.t(
                      "apTemplate:ConstructionDiary.RainUnit",
                      "mm"
                    )}
                    {...input}
                    {...FormValidators.getValidation(meta)}
                  />
                </div>
              )}
            </Field>
          </div>
          <div className={`remark`}>
            <Field name="notes">
              {({ input, meta }) => (
                <div className={`__field`}>
                  <BFInput
                    label={i18n.t(
                      "apTemplate:ConstructionDiary.Notes",
                      "Bemerkung"
                    )}
                    rows={6}
                    autosize
                    type="textarea"
                    {...input}
                    {...FormValidators.getValidation(meta)}
                  />
                </div>
              )}
            </Field>
          </div>

          {(
            [
              "observations",
              "workProgress",
              "defects",
            ] as ConstructionDiaryDocumentationType[]
          ).map((field) => (
            <APConstructionDiariesEntriesField
              fieldName={field}
              key={field}
              budget={props.budget}
              form={form}
              diary={props.diary}
            />
          ))}
        </>
      )}
    />
  );
};

export default APConstructionDiariesForm;

import MultiplePDFViewer from "@/components/PDFViewer/MultiplePDFViewer";
import { PDFDocumentRect } from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import BaseAsset from "@/model/general-assets/BaseAsset";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import "./BFPDFOverlay.scss";

interface BFPDFOverlayProps {
  asset: BaseAsset;
  value: PDFDocumentRect[];
  assetField: string;
  assetType: string;
}
const BFPDFOverlay = (props: BFPDFOverlayProps) => {
  const [identifier] = useState(_.uniqueId("pdfOverlay"));
  const cdnIds = _.uniq((props.value || []).map((rect) => rect.cdnID));

  if (cdnIds.length > 0) {
    return (
      <div className={classNames(`bf-pdf-overlay`)}>
        <MultiplePDFViewer
          //   asset={props.asset}
          //   assetField={props.assetField}
          //   assetType={props.assetType}
          //   cdnID={cdnIds[index]}
          identifier={identifier}
          height={"100%"}
          pdfs={cdnIds.map((id) => ({
            url: props.asset.cdn.find((e) => e.id === id)?.key,
            filename: props.asset.cdn.find((e) => e.id === id)?.filename,
            type: "cdn",
            asset: props.asset,
            cdnID: id,
            assetField: props.assetField,
            assetType: props.assetType,
          }))}
          highlights={props.value.map((rect, index) => ({
            ...rect,
            value: rect.id,
            identifier: rect.id,
          }))}
        />
      </div>
    );
  } else {
    return (
      <div className={`padding-20`}>
        {i18n.t("BFPDFMarker.noPDFs", "Keine PDFs zum Anzeigen verfügbar")}
      </div>
    );
  }
};

export default BFPDFOverlay;

import classNames from "classnames";
import i18n from "../../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../../model/AssetTypes";
import { Resubmission } from "../../../../../../../../modules/resubmission/RSInterfaces";
import RSSubAssetMapper from "../../../../../../../../modules/resubmission/components/asset-details/sub-asset-mapper/RSSubAssetMapper";
import RSListDetailsComponent from "../../../../../../../../modules/resubmission/components/list-details/RSListDetailsComponent";
import { RentalAgreement } from "../../../TenantsInterfaces";

interface CBRentalAgreementResubmissionViewProps {
  rentalAgreement: RentalAgreement;
}
const CBRentalAgreementResubmissionView = (
  props: CBRentalAgreementResubmissionViewProps
) => {
  const renderResubmissionTenantDetails = (resubmission: Resubmission) => {
    return (
      <RSSubAssetMapper
        resubmission={resubmission}
        asset={props.rentalAgreement}
      />
    );
  };

  return (
    <div className={classNames("cb-tenant-resubmission-view")}>
      <RSListDetailsComponent
        asset={props.rentalAgreement}
        assetType={AssetTypes.Rental.RentalAgreement}
        assetDescription={i18n.t(
          "AssetTypes.CB.Labels.RentalAgreement",
          "Mietvertrag"
        )}
        listIdentifier={`AP_RESUBMISSIONS_${props.rentalAgreement._id}`}
        renderResubmissionAssetDetails={renderResubmissionTenantDetails}
        typePermissions={[props.rentalAgreement.data.type]}
      />
    </div>
  );
};

export default CBRentalAgreementResubmissionView;

import CBRenterDistributionCard from "@/apps/tatar/cashBudget/views/portfolio/object/cards/renter/CBRenterDistributionCard";
import CBRenterListCard from "@/apps/tatar/cashBudget/views/portfolio/object/cards/renter/CBRenterListCard";
import {
  CBVacancyWALE,
  CBVacancyWALT,
} from "@/apps/tatar/cashBudget/views/portfolio/object/cards/vacancy/CBVacancySingleValues";
import "./ObjectDetailRenter.scss";

type PropsObject = {
  assets: string[];
  refId: string;
};
type Props = PropsObject;
const ObjectDetailRenter = (props: Props) => {
  return (
    <div className="object-renter">
      <div className="single-kpis">
        <div className="entry">
          <CBVacancyWALT referenceId={props.refId} objectIds={props.assets} />
        </div>

        <div className="entry">
          <CBVacancyWALE referenceId={props.refId} objectIds={props.assets} />
        </div>
      </div>
      <CBRenterDistributionCard
        referenceId={props.refId}
        objectIds={props.assets}
        marginBottom={20}
      />
      <CBRenterListCard
        referenceId={props.refId}
        objectIds={props.assets}
        marginBottom={20}
      />
    </div>
  );
};
export default ObjectDetailRenter;

//KPI

import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Loader } from "rsuite";
import WrappedText from "../../../components/WrappedText/WrappedText";
import i18n from "../../../i18n";
import { AssetTypes } from "../../../model/AssetTypes";
import CacheService from "../../../services/CacheService";
import { useCDNLinkAndFetch } from "../../../utils/Hooks";
import StringUtils from "../../../utils/StringUtils";
import { Comment } from "../Comments";
import "./CommentEntryContent.scss";
interface CommentEntryContentProps {
  comment: Comment;
}
const CommentEntryContent = (props: CommentEntryContentProps) => {
  if (props.comment.data.contentId) {
    return <CommentEntryContentByCDN {...props} />;
  } else {
    return <CommentEntryContentByHTML {...props} />;
  }
};

export default CommentEntryContent;

const CommentEntryContentByCDN = (props: CommentEntryContentProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      addTargetBlankToLinks(ref.current);
    }
  }, [ref.current]);

  const fetchData = useCDNLinkAndFetch(
    AssetTypes.Comment,
    "data.contentId",
    props.comment.data.contentId,
    props.comment,
    true
  );
  useEffect(() => {
    if (fetchData.data) {
      CacheService.updateDataInCaches(
        props.comment._id,
        { cdnHtml: fetchData.data },
        "patchRoot"
      );
    }
  }, [fetchData.data]);

  if (fetchData.loading)
    return (
      <div className={`comment-content-entry  loader-container`}>
        <Loader size={"md"} />
      </div>
    );
  if (fetchData.error)
    return (
      <div className={`comment-content-entry error-backup`}>
        <div className={`error-info`}>
          {i18n.t(
            "CommentsModule.CommentEntryContentByCDN.ErrorLoading",
            "Fehler beim Laden des originalen Inhalts. Es wird stattdessen nur der Textinhalt angezeigt"
          )}
        </div>
        <div className={`error-content`}>
          <div
            className={classNames(
              `comment-content-entry text text-selection-all`
            )}
          >
            <WrappedText text={props.comment.data.message} />
          </div>
        </div>
      </div>
    );

  return (
    <div
      ref={ref}
      className={classNames(`comment-content-entry text text-selection-all`)}
      dangerouslySetInnerHTML={{
        __html: StringUtils.sanitizeHtml(fetchData.data),
      }}
    ></div>
  );
};
const CommentEntryContentByHTML = (props: CommentEntryContentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [content, setContent] = useState<string | undefined>(undefined);
  const [type, setType] = useState<"html" | "text">("html");

  useEffect(() => {
    if (
      props.comment.data.type === "mail_incoming" ||
      props.comment.data.type === "mail_outgoing"
    ) {
      setType("text");
      setContent(StringUtils.sanitizeHtml(props.comment.data.message));
    } else {
      setType("html");
      setContent(props.comment.data.message);
    }
  }, [props.comment]);
  useEffect(() => {
    if (ref.current) {
      addTargetBlankToLinks(ref.current);
    }
  }, [ref.current]);

  if (type === "html") {
    return (
      <div
        ref={ref}
        className={classNames(`comment-content-entry text text-selection-all`)}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    );
  } else {
    return (
      <div
        ref={ref}
        className={classNames(`comment-content-entry text text-selection-all`)}
      >
        <WrappedText text={content} />
      </div>
    );
  }
};

const addTargetBlankToLinks = (div: HTMLDivElement) => {
  div.querySelectorAll("a").forEach((a) => {
    a.setAttribute("target", "_blank");
  });
};

import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { Contact } from "@/model/db/Contact";
import BFEntries from "@/modules/abstract-ui/data/entries/BFEntries";
import BFSection from "@/modules/abstract-ui/data/section/BFSection";
import BFChooser from "@/modules/abstract-ui/forms/chooser/BFChooser";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFOverlay from "@/modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import EZGroup from "@/modules/ez-form/form-group/ez-form/EZGroup";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import ContactService from "@/services/ContactService";
import DataBusDefaults from "@/services/DataBusDefaults";
import classNames from "classnames";
import { useState } from "react";
import ContactRelationFormFields from "./ContactRelationFormFields";
import "./ContactRelationsView.scss";

interface ContactRelationsViewProps {
  contact: Contact;
}
const overlayIdentifier = "contact-relations-view";
const ContactRelationsView = (props: ContactRelationsViewProps) => {
  const [search, setSearch] = useState("");

  const contactOverwrites = props.contact.data.contactOverwrite || [];
  const contactOverwritesEntities =
    contactOverwrites?.map((a) => a.entity) || [];

  const contactOverwritesFiltered = contactOverwrites.filter((overwrite) => {
    if (!search.trim()) {
      return true;
    }
    const values = [
      OrgaStruct.getEntity(overwrite.entity)?.displayName,
      overwrite?.invoice?.accountNumber,
      overwrite?.bill?.accountNumber,
      overwrite?.invoice?.customerNumber,
      overwrite?.bill?.customerNumber,
    ].filter(Boolean);

    return values.some((value) =>
      value.toLowerCase().includes(search.toLowerCase())
    );
  });
  return (
    <div className={classNames(`contact-relations-view`)}>
      <div className={`relations-filter-bar`}>
        <div className={`border-top-overlay`} />
        <div>
          <BFInput
            focusOnMount
            placeholder={i18n.t("Global.Labels.search", "Suchen")}
            prefix={<BfIcon {...DefaultIcons.SEARCH} size="xxs" />}
            value={search}
            onChange={(val: string) => setSearch(val)}
          />
        </div>
        <div className={`fill`} />
        <BFOverlay
          identifier={overlayIdentifier}
          placement="bottomEnd"
          trigger="click"
          speaker={
            <div>
              <BFChooser
                options={OrgaStruct.getEntities(props.contact.data.type)
                  .filter((e) => !contactOverwritesEntities.includes(e._id))
                  .map((e) => ({
                    label: e.displayName,
                    value: e._id,
                    subLabel: e.id,
                  }))}
                description={i18n.t(
                  "ContactRelationsView.selectEntity",
                  "Wählen Sie eine Gesellschaft, für welche Sie Zuordnungen zu dem Kontakt erstellen möchten."
                )}
                focusOnMount
                maxHeight={500}
                onChange={async (entityId: string) => {
                  await ContactService.updateIndividualData(props.contact._id, {
                    entity: entityId,
                    bill: null,
                    invoice: null,
                    contactMail: null,
                    contactPerson: null,
                    contactPhone: null,
                  });
                  DataBusDefaults.closeOverlay(overlayIdentifier);
                }}
              />
            </div>
          }
        >
          <BFButton appearance="outline">
            {i18n.t(
              "ContactRelationsView.addNewEntity",
              "Gesellschaft hinzufügen"
            )}
          </BFButton>
        </BFOverlay>
      </div>
      <div className={`relations-content`}>
        <BFEntries
          emptyText={i18n.t(
            "ContactBaseDataView.noEntities",
            "Keine Gesellschaft hinzugefügt"
          )}
          ignoreHover
          entries={contactOverwritesFiltered.map((overwrite) => {
            const entity = OrgaStruct.getEntity(overwrite.entity);
            return {
              content: (
                <>
                  <DebugDataComponent data={overwrite} />
                  <BFSection collapsible title={entity.displayName}>
                    <div className={`form-wrapper`}>
                      <EZGroup
                        value={{
                          ...overwrite,
                          bill: {
                            ...(overwrite.bill || {}),
                            sepaActive: Boolean(overwrite.bill?.sepa),
                          },
                          invoice: {
                            ...(overwrite.invoice || {}),
                            sepaActive: Boolean(overwrite.invoice?.sepa),
                          },
                        }}
                        onSubmit={async (values) => {
                          await ContactService.updateIndividualData(
                            props.contact._id,
                            values
                          );
                        }}
                      >
                        {() => (
                          <ContactRelationFormFields
                            type={props.contact.data.type}
                            contactPersons={props.contact.data.contactPersons}
                            emails={props.contact.data.emails}
                            phones={props.contact.data.phone}
                            contactTypes={props.contact.data.contactType}
                          />
                        )}
                      </EZGroup>
                    </div>
                  </BFSection>

                  {/* <div className={`street`}>{address.street}</div>
                  <div className={`zip-city`}>
                    {[address.zip, address.city].filter(Boolean).join(" ")}
                  </div>
                  {address.additional && (
                    <div className={`additional`}>{address.additional}</div>
                  )}
                  {address.country && (
                    <div className={`country`}>{address.country}</div>
                  )} */}
                </>
              ),
              // actions: [
              //   {
              //     icon: "duplicate-file",
              //     onClick: addAddressToClipboard,
              //     tooltip: i18n.t(
              //       "ContactBaseAddress.copyToClipboard",
              //       "In Zwischenablage kopieren"
              //     ),
              //   },
              //   {
              //     icon: "maps",
              //     href: `https://www.google.com/maps/search/?api=1&query=${mapsHrefLinkParts}`,
              //     tooltip: i18n.t(
              //       "ContactBaseAddress.showInMaps",
              //       "In Maps öffnen"
              //     ),
              //   },
              // ],
            };
          })}
        />
      </div>
    </div>
  );
};

export default ContactRelationsView;

import VirtualizedTableExportButton from "@/configurable/data/VirtualizedTable/VirtualizedTableExportButton";
import _ from "lodash";
import { useEffect, useState } from "react";
import KPIDetailCard from "../../../../../../../../components/KPIDetailCard/KPIDetailCard";
import StructLoader from "../../../../../../../../components/StructLoader/StructLoader";
import VirtualizedTable from "../../../../../../../../configurable/data/VirtualizedTable/VirtualizedTable";
import DebugDataComponent from "../../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../../i18n";
import {
  LinkCell,
  renderCellValue,
} from "../../../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFButtonToggle from "../../../../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import { ActivityAbstractStructClass } from "../../../../../../../../redux/actions/struct/implemented/ActivityAbstractStruct";
import {
  useAggregationTableQuery,
  useConstants,
} from "../../../../../../../../redux/hooks";
import { AggregationStatisticQuerySelector } from "../../../../../../../../redux/model";
import DataBus from "../../../../../../../../services/DataBus";
import { MatchQuery } from "../../../../../../../../services/DataService";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import { when } from "../../../../../../../../utils/Helpers";
import MQ from "../../../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../../../utils/StringUtils";
import { APStatusTags } from "../../../../../../../AppConfigInterfaces";
import { ActivityApplicationConstants } from "../../../../../../activityApp/ActivityHooks";
import { APActivity } from "../../../../../../activityApp/ActivityInterfaces";
import {
  AP_FIELDS,
  AP_STATIC_SELECTORS,
} from "../../../../../../activityApp/views/list/APList";
import "./CBDamageClaimCard.scss";

const TABLE_IDENT = `cb-portfolio-damage-claim-table`;

export const ProcessingFilters = (
  type: string,
  structToUse: ActivityAbstractStructClass<any>
) => {
  const status = structToUse.getAllStatus([type]);
  const activeStatus = status.filter((s) => s.activity_status === "active");
  const inactiveStatus = status.filter((s) => s.activity_status === "inactive");
  const waitingStatus = status.filter((s) => s.activity_status === "waiting");
  const output = [
    {
      text: () => i18n.t("cb:DamageClaim.ProcessingFilter.all", "Gesamt"),
      value: null,
    },
  ];
  if (activeStatus) {
    output.push({
      text: () => i18n.t("cb:DamageClaim.ProcessingFilter.active", "Aktiv"),
      value: activeStatus.map((s) => s.id),
    });
  }
  if (inactiveStatus) {
    output.push({
      text: () => i18n.t("cb:DamageClaim.ProcessingFilter.inactive", "Inaktiv"),
      value: inactiveStatus.map((s) => s.id),
    });
  }
  if (waitingStatus) {
    output.push({
      text: () => i18n.t("cb:DamageClaim.ProcessingFilter.waiting", "Wartend"),
      value: waitingStatus.map((s) => s.id),
    });
  }

  return output;
};

const generateMatchQuery = (
  // filterMonths: number,
  // typeFilter: DetailFilter,
  // config: CBPortfolioImmoConfig,
  processingStatusFilter: null | string[],
  objectIds?: string[]
) => {
  return {
    type: "and",
    query: [
      ...(processingStatusFilter
        ? [
            {
              type: "op",
              name: "data.status",
              op: "in",
              value: processingStatusFilter,
            },
          ]
        : []),
      { type: "op", name: "data.objectId", op: "in", value: objectIds || [] },
      // {
      //   type: "op",
      //   name: "data.moveOut",
      //   op: "gte",
      //   value: moment().startOf("day").toISOString(),
      // },
      // {
      //   type: "op",
      //   name: "data.moveOut",
      //   op: "lte",
      //   value: moment().add(filterMonths, "month").endOf("day").toISOString(),
      // },
    ],
  } as MatchQuery;
};

const STATIC_SELECTORS: AggregationStatisticQuerySelector[] = [
  {
    name: "general",
    op: [
      {
        key: "count",
        op: "count",
      },
      {
        key: "estimatedCost",
        op: "sum",
        field: "data.estimatedCosts",
      },
    ],
    query: {},
  },
];

interface DamageClaimCardProps {
  objectIds: string[];
  lqObjectIds: string[];
  marginBottom?: number;
  referenceId?: string;
  type: string;
}
const CBDamageClaimCard = (props: DamageClaimCardProps) => {
  const constantsByApp = useConstants();
  const constants: ActivityApplicationConstants = {
    idPrefix: "IDC",
    serviceUrl: "damageClaim",
    assetType: "activity-damageClaim",
    permissionPrefix: "dc_",
    taskType: "APPROVE_DAMAGECLAIM",
    fields: {
      insurance: true,
      estimatedCosts: true,
      objectIdMandatory: true,
      comparison: ["immo"],
    },
    businessUnits: constantsByApp.businessUnits,
  };
  return (
    <StructLoader
      unitTypes={constants?.businessUnits}
      structTypes={[constants.serviceUrl as any, "unit", "orga", "category"]}
      render={([structToUse]) => (
        <CardContent
          {...props}
          constants={constants}
          structToUse={structToUse}
        />
      )}
    />
  );
};
export default CBDamageClaimCard;

const CardContent = (
  props: DamageClaimCardProps & {
    constants: ActivityApplicationConstants;
    structToUse: ActivityAbstractStructClass<any>;
  }
) => {
  const [matchQuery, setMatchQuery] = useState<MatchQuery>();
  // mocked constants here, because we want to see damageclaims here properly

  const archiveStatus = props.structToUse.getStatusByTags(
    APStatusTags.ARCHIVED
  )?.[0];

  const aggregated = useAggregationTableQuery(
    TABLE_IDENT,
    AP_STATIC_SELECTORS(props.constants?.fields?.customFields)
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          aggregated.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });

  const [filter] = useState(ProcessingFilters(props.type, props.structToUse));
  const [processingStatusFilter, setProcessingStatusFilter] = useState<
    null | string[]
  >(filter[1].value);

  // const configCache = useAssetCache<CBPortfolioImmoConfig>("portfolio-config", {
  //   type: "op",
  //   name: "data.type",
  //   op: "eq",
  //   value: "immo",
  // });
  // const [filterMonths, setFilterMonths] = useState<number>(12);
  // const [detailFilter, setDetailFilter] = useState<DetailFilter>(null);
  const loading = false; //configCache.state === "loading";

  useEffect(() => {
    setMatchQuery(generateMatchQuery(processingStatusFilter, props.objectIds));
  }, [processingStatusFilter]);
  return (
    <KPIDetailCard
      marginBottom={props.marginBottom}
      className="cb-damage-claim-card"
      title={i18n.t("cb:DamageClaim.damageClaims", "Schadensmeldungen")}
      data={{
        query: matchQuery,
      }}
      loading={loading}
      // headRight={

      // }
      kpis={[
        {
          name: i18n.t("cb:DamageClaim.count", "Anzahl"),
          value: aggregated.data?.["general"]?.["count"] || "-",
          width: 50,
          loading: aggregated.loading,
        },

        {
          name: i18n.t("cb:DamageClaim.estimatedCost", "Geschätzte Kosten"),
          value: aggregated.data?.["general"]?.["estimatedCosts"]
            ? StringUtils.formatCurrency(
                aggregated.data?.["general"]?.["estimatedCosts"]
              )
            : "-",
          width: 100,
          loading: aggregated.loading,
        },
      ]}
      subHead={
        <div className="filters">
          <div className="groups">
            <BFButtonToggle
              buttons={filter}
              value={processingStatusFilter}
              onChange={(val: null | string[]) =>
                setProcessingStatusFilter(val)
              }
            />
          </div>
          <div className="actions">
            <VirtualizedTableExportButton
              identifier={TABLE_IDENT}
              appearance="default"
            />
          </div>
        </div>
      }
      childOptions={{
        height: 800,
        render: (data) => (
          <div className="damage-claim-table">
            <div className="table-wrapper">
              <VirtualizedTable
                dataUrl={`/api/asset/list/${props.constants?.assetType}`}
                asPost
                identifier={TABLE_IDENT}
                params={{ aggregated }}
                additionalMatchQuery={MQ.and(
                  when(archiveStatus, MQ.ne("data.status", archiveStatus?.id)),
                  MQ.in("data.objectId", props.objectIds),
                  processingStatusFilter
                    ? MQ.in("data.status", processingStatusFilter)
                    : null
                )}
                columns={_.merge(
                  {},
                  AP_FIELDS(props.constants, props.structToUse),
                  {
                    "data.type": {
                      hidden: true,
                    },
                    "data.entity": {
                      hidden: true,
                    },
                    "data.objectId": {
                      hidden: props.objectIds.length === 1,
                    },
                    "data.activityId": {
                      render: (node: APActivity, index, params) => (
                        <>
                          <DebugDataComponent data={node} />
                          <LinkCell
                            assetType={props.constants.assetType}
                            id={node._id}
                            type={node.data.type}
                            text={renderCellValue(node.data.activityId)}
                          />
                        </>
                      ),
                    },
                  }
                )}
              />
            </div>
          </div>
        ),
      }}
    />
  );
};

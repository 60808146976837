import classNames from "classnames";
import AutosizeTextarea from "../../../abstract-ui/forms/autosize-textarea/AutosizeTextarea";
import "./EZTextfield.scss";

interface EZTextfieldProps {
  className?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  canChange?: boolean;
  disabled?: boolean;
  placeholder?: string;
  error?: boolean;
  ignoreLinebreaks?: boolean;
  textType?: "header" | "subHeader" | "default" | "description";
  label?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  readonly?: boolean;
  appearance?: "default" | "bf";
}
const EZTextfield = (props: EZTextfieldProps) => {
  return (
    <div
      className={classNames(
        `ez-textfield`,
        props.className,
        `type-${props.textType || "default"}`,
        {
          readonly: props.readonly,
          "appearance-bf": props.appearance === "bf",
          error: props.error,
        }
      )}
    >
      <AutosizeTextarea
        autoComplete="off"
        autoCorrect="off"
        spellCheck={false}
        placeholder={props.placeholder}
        value={props.value}
        label={props.label}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onKeyPress={(ev) => {
          props.onKeyPress && props.onKeyPress(ev);
          if (props.ignoreLinebreaks) {
            if (ev.key === "Enter") {
              ev.preventDefault();
            }
          }
        }}
        onChange={(value: string) => {
          if (props.ignoreLinebreaks) {
            props.onChange(value.replaceAll("\n", ""));
          } else {
            props.onChange(value);
          }
        }}
        readonly={props.readonly}
      />
    </div>
  );
};

export default EZTextfield;

import { FrameConfig } from "../../../services/types/pdfConfigBlockTypes";
import { PDFBlockFooter } from "../../PDFBlockFooter";
import { PDFBlockList } from "../../PDFBlockList";
import { PDFBlockSelect } from "../../PDFBlockSelect";
import { PDFLinkInput } from "../TextInput/PDFLinkInput";
import { PDFTextInput } from "../TextInput/PDFTextInput";
import { BasePDFBlockElementProps } from "@/components/PDFConstructor/common/PDFBlockTypes";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { PDFDateBlock } from "../TextInput/PDFDateInput";
import { PDFPageCountInput } from "../TextInput/PDFPageCountInput";
import { useState } from "react";
import { useDebouncedEffect } from "@/utils/Hooks";
import { PDFCommonInput } from "../../Common/PDFCommonInput";

const PDFFrameLayout: React.FC<BasePDFBlockElementProps<FrameConfig>> = ({
  value,
  id,
  parentId,
}) => {
  const [_, { addBlock, editBlock, deleteBlock, saveBlock }] =
    usePDFConstructor();

  const [form, setForm] = useState<
    Pick<
      FrameConfig,
      "marginTop" | "marginLeft" | "marginRight" | "marginBottom"
    >
  >({
    marginTop: value.marginTop,
    marginBottom: value.marginBottom,
    marginLeft: value.marginLeft,
    marginRight: value.marginRight,
  });

  useDebouncedEffect(() => {
    editBlock({
      ...value,
      ...form,
    });
  }, [form]);

  return (
    <div>
      <PDFBlockList
        parentId={value.id}
        config={value.content}
        elements={{
          link: PDFLinkInput,
          text: PDFTextInput,
          date: PDFDateBlock,
          "page-count": PDFPageCountInput,
        }}
      />

      <PDFBlockSelect
        addValue={(element) => {
          addBlock(element, id);
        }}
        show={["link", "text", "date", "page-count"]}
      />

      <PDFCommonInput
        value={form}
        onChange={(key, value) =>
          setForm((form) => ({
            ...form,
            [key]: value,
          }))
        }
      />

      <PDFBlockFooter
        isEmpty={value.isEmpty}
        onSave={() => saveBlock(value, parentId)}
        onDelete={() => deleteBlock(value)}
      />
    </div>
  );
};

export { PDFFrameLayout };

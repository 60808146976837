import React, { useMemo } from "react";
import { generateDefault } from "../services/PDFConfigParser";
import "./PDFBlockSelect.scss";
import { BlockConfig, BlockType } from "../services/types/pdfConfigBlockTypes";
import i18n from "@/i18n";
import {
  BlockTypes,
  BlockTypeGroupRelation,
  SelectGroups,
} from "../common/InputConstants";
import BFDropdown from "@/modules/abstract-ui/general/Dropdown/BFDropdown";

type PDFBlockSelectProps = {
  addValue: (value: BlockConfig) => void;
  addTemplate?: (id: string) => void;
  additionalValues?: { value: string; label: () => string }[];
  hide?: BlockType[];
  show?: BlockType[];
  used?: BlockType[];
  showOnce?: boolean;
};

const groupBlocksBySelectGroup = (blocks: typeof BlockTypes) => {
  const groupsRelation: Partial<
    Record<keyof typeof SelectGroups, typeof BlockTypes>
  > = {};

  for (const block of blocks) {
    const selectGroupType = BlockTypeGroupRelation[block.value];

    if (selectGroupType) {
      groupsRelation[selectGroupType] ??= [];
      groupsRelation[selectGroupType].push(block);
    } else {
      groupsRelation["TEMPLATE"] ??= [];
      groupsRelation["TEMPLATE"].push(block);
    }
  }

  const groups = Object.entries(groupsRelation).map(([key, value]) => ({
    label: SelectGroups[key],
    value,
  }));

  return groups;
};

const PDFBlockSelect: React.FC<PDFBlockSelectProps> = ({
  addValue,
  addTemplate,
  additionalValues = [],
  show = [],
  hide = [],
  used = [],
  showOnce = false,
}) => {
  const selectableBlocks = useMemo(() => {
    const visibleTypes = [
      ...BlockTypes.filter(
        (block) =>
          !hide.includes(block.value) &&
          (show.length === 0 || show.includes(block.value))
      ),
      ...additionalValues,
    ];

    if (!showOnce) {
      return groupBlocksBySelectGroup(visibleTypes as typeof BlockTypes);
    }

    const selectableTypes = visibleTypes.filter(
      ({ value }) => !used.includes(value as BlockType)
    );

    return groupBlocksBySelectGroup(selectableTypes as typeof BlockTypes);
  }, [hide, show, used, showOnce, additionalValues]);

  return (
    <BFDropdown
      label={i18n.t(
        "Component.PDFConstructor.BlockSelect.AddButton",
        "Block hinzufügen"
      )}
      asOverlay
      overlayOverflowAllow
      className="pdf-constructor--block-input--dropdown"
      items={selectableBlocks.map((option) => ({
        ...option,
        text: option.label(),
        type: "menu",
        items: option.value.map((block) => ({
          type: "button",
          text: block.label(),
          onSelect: () => {
            if (additionalValues.some((value) => value.value === block.value)) {
              addTemplate?.(block.value);
              return;
            }

            addValue(generateDefault(block.value));
          },
          closeOnClick: true,
        })),
      }))}
    >
      +
    </BFDropdown>
  );
};

export { PDFBlockSelect };

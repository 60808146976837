import classNames from "classnames";
import i18n from "../../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../../model/AssetTypes";
import { Resubmission } from "../../../../../../../../modules/resubmission/RSInterfaces";
import RSSubAssetMapper from "../../../../../../../../modules/resubmission/components/asset-details/sub-asset-mapper/RSSubAssetMapper";
import RSListDetailsComponent from "../../../../../../../../modules/resubmission/components/list-details/RSListDetailsComponent";
import { RentalUnit } from "../../../TenantsInterfaces";

interface CBRentalUnitResubmissionViewProps {
  rentalUnit: RentalUnit;
}
const CBRentalUnitResubmissionView = (
  props: CBRentalUnitResubmissionViewProps
) => {
  const renderResubmissionTenantDetails = (resubmission: Resubmission) => {
    return (
      <RSSubAssetMapper resubmission={resubmission} asset={props.rentalUnit} />
    );
  };

  return (
    <div className={classNames("cb-tenant-resubmission-view")}>
      <RSListDetailsComponent
        asset={props.rentalUnit}
        assetType={AssetTypes.Rental.RentalUnit}
        assetDescription={i18n.t(
          "AssetTypes.CB.Labels.RentalUnit",
          "Mieteinheit"
        )}
        listIdentifier={`AP_RESUBMISSIONS_${props.rentalUnit._id}`}
        renderResubmissionAssetDetails={renderResubmissionTenantDetails}
        typePermissions={[props.rentalUnit.data.type]}
      />
    </div>
  );
};

export default CBRentalUnitResubmissionView;

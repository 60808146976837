import StructLoader from "@/components/StructLoader/StructLoader";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { Contact } from "@/model/db/Contact";
import BFNavView from "@/modules/abstract-ui/navigation/BFNavModal/BFNavView";
import "./ContactDetailView.scss";
import ContactBaseDataView from "./basedata/ContactBaseDataView";
import ContactInvoiceView from "./invoices/ContactInvoiceView";
import ContactRelationsView from "./relations/ContactRelationsView";
import ContactRentalView from "./rental/ContactRentalView";

interface ContactDetailViewProps {
  contact: Contact;
  onClose?: () => void;
}
const ContactDetailView = (props: ContactDetailViewProps) => {
  return (
    <>
      <DebugDataComponent data={props.contact} />
      <StructLoader
        structTypes={["unit", "orga"]}
        unitType={props.contact.data.type}
        render={() => (
          <BFNavView
            className={`contact-detail-view`}
            tabs={[
              {
                title: i18n.t("ContactDetailView.basedata", "Stammdaten"),
                route: "basedata",
                render: () => (
                  <div className={`scroll-view-with-padding`}>
                    <ContactBaseDataView contact={props.contact} />
                  </div>
                ),
              },
              // {
              //   title: i18n.t("ContactDetailView.activities", "Aktivitäten"),
              //   route: "activities",
              //   render: () => <div>activities</div>,
              // },

              ...(props.contact.data.contactType.includes("BILL") ||
              props.contact.data.contactType.includes("INVOICE")
                ? [
                    {
                      title: i18n.t(
                        "ContactDetailView.relations",
                        "Gesellschaftszuordnung"
                      ),
                      route: "relations",
                      render: () => (
                        <ContactRelationsView contact={props.contact} />
                      ),
                    },
                    {
                      title: i18n.t("ContactDetailView.invoices", "Rechnungen"),
                      route: "invoices",
                      render: () => (
                        <ContactInvoiceView contact={props.contact} />
                      ),
                    },
                  ]
                : []),
              ...(props.contact.data.contactType.includes("TENANT")
                ? [
                    {
                      title: i18n.t("ContactDetailView.rental", "Mieterdaten"),
                      route: "rental",
                      render: () => (
                        <ContactRentalView contact={props.contact} />
                      ),
                    },
                  ]
                : []),
              // {
              //   title: i18n.t("ContactDetailView.history", "Historie"),
              //   route: "history",
              //   render: () => <div>history</div>,
              // },
            ]}
            onClose={props.onClose}
            title={props.contact.data.displayName}
          />
        )}
      />
    </>
  );
};

export default ContactDetailView;

import classNames from "classnames";
import { DocumentStoreDirectory } from "../../DSInterfaces";
import DSDocumentMoveToDirectory from "./DSDocumentMoveToDirectory";
import "./DSDocumentMoveToDirectoryView.scss";

interface DSDocumentMoveToDirectoryViewProps {
  directoryConfRoot: DocumentStoreDirectory;
  onDirectoryClick: (directory: DocumentStoreDirectory) => void;
}
const DSDocumentMoveToDirectoryView = (
  props: DSDocumentMoveToDirectoryViewProps
) => {
  return (
    <div className={classNames(`ds-document-move-to-directory-view`)}>
      <DSDocumentMoveToDirectory
        onDirectoryClick={props.onDirectoryClick}
        directory={props.directoryConfRoot}
      />
    </div>
  );
};

export default DSDocumentMoveToDirectoryView;

import { MutableRefObject, useEffect, useRef, useState } from "react";
import { ConnectDragSource, useDrag, useDrop, XYCoord } from "react-dnd";
import {
  DraggablePDFBlock,
  DraggablePDFBlockData,
} from "../common/PDFBlockTypes";
import { usePDFConstructor } from "../context/PDFConstructorContext";
import { useDebouncedEffect } from "@/utils/Hooks";
import { BlockConfig } from "../services/types/pdfConfigBlockTypes";

const ITEM_TYPE = "pdf-constructor-block";

type UseDraggablePDFBlock = (
  block: DraggablePDFBlock,
  moveBlock: (dragIndex: number, hoverIndex: number) => void,
  rerenderDocument: (dragIndex: number, hoverIndex: number) => void,
  revertSwap: (blockId: number, originalIndex: number) => void
) => [
  MutableRefObject<HTMLDivElement>,
  { isDragging: boolean; handlerId: string | symbol; drag: ConnectDragSource }
];

export const useDraggablePDFBlock: UseDraggablePDFBlock = (
  block,
  moveBlock,
  rerenderDocument,
  revertSwap
) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: ITEM_TYPE,

      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
      item: (): DraggablePDFBlockData => {
        return { ...block, originalIndex: block.index };
      },
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          revertSwap(droppedId, originalIndex);
        }
      },
    }),
    [block.index]
  );

  const [{ handlerId }, drop] = useDrop({
    accept: ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      };
    },
    drop(item: DraggablePDFBlockData) {
      const dragIndex = item.id;
      const hoverIndex = item.swappedWithId ?? block.id;

      if (!item.skipRerender) {
        rerenderDocument(dragIndex, hoverIndex);
      }
    },
    hover(item: DraggablePDFBlockData, monitor) {
      if (!ref.current) {
        return;
      }

      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      if (item.parentId !== block.parentId) {
        return;
      }

      const dragId = item.id;
      const hoverId = block.id;

      if (dragId === hoverId) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (item.index < block.index && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (item.index > block.index && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveBlock(dragId, hoverId);

      item.skipRerender = block.index === item.originalIndex;

      item.swappedWithId = block.id;
      item.index = block.index;
    },
  });

  dragPreview(drop(ref));

  return [ref, { isDragging, handlerId, drag }];
};

export const useBlockChanges = (value: BlockConfig, form: BlockConfig) => {
  const [_, { editBlock }] = usePDFConstructor();
  const [isTemplateFirstRerender, setIsTemplateFirstRerender] = useState(
    value.isTemplate
  );

  useEffect(() => {
    if (!value.isEmpty) {
      return;
    }

    if (isTemplateFirstRerender) {
      setIsTemplateFirstRerender(false);
      return;
    }

    editBlock({ ...form, isEmpty: value.isEmpty });
  }, [form]);

  useDebouncedEffect(() => {
    if (value.isEmpty) {
      return;
    }

    if (isTemplateFirstRerender) {
      setIsTemplateFirstRerender(false);
      return;
    }

    editBlock({ ...form, isEmpty: value.isEmpty });
  }, [form]);
};

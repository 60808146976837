import { DashboardItemConfig } from "../modules/configurable-dashboard/ConfigurableDashboard";
import { DASHBOARD_DEFAULT_CARD_SIZE } from "../modules/configurable-dashboard/ConfigurableDashboardConstants";
import ExpressionHelper from "../modules/generic-forms/util/ExpressionHelper";
import { translateNonGenerate } from "../utils/Helpers";
import {
  Application,
  GlobalDashboardComponent,
} from "./../model/db/Application";

class GlobalDashboardClass {
  private components: DashboardItemConfig[];
  reset() {
    this.components = [];
  }

  getComponents() {
    return this.components;
  }

  registerDashboardComponentsFromApp(appConfig: Application) {
    const dashboardComponents = appConfig.global?.dashboardComponents;
    if (dashboardComponents) {
      Object.entries(dashboardComponents).forEach(([defaultId, config]) => {
        this.registerDashboardComponent(defaultId, config, appConfig);
      });
    }
  }
  registerDashboardComponentConfig(config: DashboardItemConfig) {
    this.components.push(config);
  }
  private registerDashboardComponent(
    defaultId: string,
    config: GlobalDashboardComponent,
    appConfig?: Application
  ) {
    if (config._condition) {
      const result = ExpressionHelper.evaluateExpression(config._condition, {
        appConfig,
      });
      if (!result) {
        return;
      }
    }
    let params = {};
    if (config._params) {
      params = ExpressionHelper.evaluateExpression(config._params, {
        appConfig,
      });
    }
    let action = undefined;
    if (config._action) {
      action = (params) =>
        ExpressionHelper.evaluateExpression(config._action, {
          appConfig,
        });
    }
    this.components.push({
      id: appConfig ? `${appConfig._id}_${defaultId}` : defaultId,
      layout:
        // get layoutsize from the defualt sizes, if not found use mini. But it can also be defined a custom layout
        typeof config.layout === "string"
          ? DASHBOARD_DEFAULT_CARD_SIZE[config.layout] ||
            DASHBOARD_DEFAULT_CARD_SIZE.mini
          : config.layout,
      render: (selector) => {
        return (window as any).ComponentsMapper.createElement(
          { _component: config._component, ...params, selector, action },
          {
            appConfig,
          }
        );
      },
      title: translateNonGenerate(config.title),
      description: config.description
        ? translateNonGenerate(config.description)
        : undefined,
    });
  }
}
const GlobalDashboard = new GlobalDashboardClass();
export default GlobalDashboard;
(window as any).GlobalDashboard = GlobalDashboard;

import { PortfolioLoan } from "@/apps/tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import {
  CBStatisticICR,
  CBStatisticImmoVacancyDistribution,
  CBStatisticImmoVacancyRate,
  CBStatisticLTV,
  CBStatisticLoanEntry,
} from "@/apps/tatar/cashBudget/views/portfolio/interfaces/CBStatisticQueries";
import {
  cbLoanChartFillEmptyData,
  cbLoanChartOption,
} from "@/apps/tatar/cashBudget/views/portfolio/object/cards/loan/CBLoanChart";
import { cbRentalStatusChartOption } from "@/apps/tatar/cashBudget/views/portfolio/object/cards/renter/CBRentalStatusChart";
import { riskScenarioCalculateData } from "@/apps/tatar/cashBudget/views/portfolio/object/cards/risk-scenario/RiskScenarioChart";
import {
  RiskScenario,
  RiskScenarioLoanRiskResponse,
} from "@/apps/tatar/cashBudget/views/portfolio/object/cards/risk-scenario/RiskScenarioInterfaces";
import {
  calcRelevantDataForVacancyByUnitChart,
  cbVacancyByUnitChartOptions,
} from "@/apps/tatar/cashBudget/views/portfolio/object/cards/vacancy/CBVacancyByUnitChart";
import { cbVacancyDistributionChartOptions } from "@/apps/tatar/cashBudget/views/portfolio/object/cards/vacancy/CBVacancyDistributionChart";
import { cbVacanyHistoryCardOptions } from "@/apps/tatar/cashBudget/views/portfolio/object/cards/vacancy/CBVacancyHistoryCard";
import {
  getConfigRentalStatus,
  getConfigRentalUnitTypeGroup,
} from "@/apps/tatar/cashBudget/views/tenants/CBTenantsConst";
import {
  EnrichtedRentalUnit,
  convertEnrichtedRentalUnitToEnrichtedRentalUnitKPISGathered,
} from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import {
  getObjectStackingPlanChartOptions,
  objectStackingPlanSplitRentalUnitIntoGroups,
} from "@/apps/tatar/cashBudget/views/tenants/components/stacking-plan/ObjectStackingPlan";
import { generateStackingPlanSVG } from "@/apps/tatar/cashBudget/views/tenants/components/stacking-plan/StackingPlanSVG";
import {
  GOGOLE_MAPS_API_KEY,
  calculateCenter,
} from "@/components/MapComponent/MapUtils";
import Log from "@/debug/Log";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import PDFExporter from "@/modules/pdf-export/PDFExporter";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { store } from "@/redux/store";
import CDNService from "@/services/CDNService";
import { queryAllAssetsChained } from "@/services/DataService";
import LanguageService from "@/services/LanguageService";
import OverwriteService from "@/services/OverwriteService";
import NumberUtils from "@/utils/ NumberUtils";
import ArrayUtils from "@/utils/ArrayUtils";
import FileUtils from "@/utils/FileUtils";
import { isDefined, isNotDefined, when } from "@/utils/Helpers";
import { HTTP } from "@/utils/Http";
import MQ from "@/utils/MatchQueryUtils";
import StringUtils from "@/utils/StringUtils";
import * as ECharts from "echarts";
import _ from "lodash";
import moment from "moment";
import { nanoid } from "nanoid";
import pdfMake from "pdfmake/build/pdfmake";
import {
  Column,
  Content,
  CustomTableLayout,
  TDocumentDefinitions,
  TableCell,
} from "pdfmake/interfaces";
import {
  OAObject,
  ObjectFeature_valueEntry,
} from "../../types/object.interface";
import {
  ObjectKind,
  checkAnyFeature,
  checkFeature,
} from "../../types/objectKind.interface";

export type ExportOpt = {
  assetValue: boolean;
  rentalData: boolean;
  rentalDataOpts?: {
    showStackingPlan: boolean;
    showRentalIncomeGraph: boolean;
    rentNetPlan: boolean;
    rentNetCurrent: boolean;
    rentGrossPlan: boolean;
    rentGrossCurrent: boolean;
    vacancyPage: boolean;
  };
  loanable: boolean;
  loanableOpts?: {
    showGraph: boolean;
  };
  riskScenarios: RiskScenario[];
  purchasePrice: boolean;
};
class ObjectExportServiceClass {
  async exportObject(
    assets: OAObject[],
    title: string,
    referenceId: string,
    kind: ObjectKind,
    opt: ExportOpt
  ) {
    const content: Content[] = [];
    content.push(...(await this.exportBaseData(assets, kind, opt, title)));

    if (opt.rentalData) {
      content.push(
        ...(await this.getRentalData(assets, kind, opt.rentalDataOpts))
      );
    }
    if (opt.loanable) {
      content.push(...(await this.getLoanable(assets, kind, opt.loanableOpts)));
    }
    if (opt.riskScenarios.length > 0) {
      content.push(
        ...(await this.getRiskScenarios(assets, kind, opt.riskScenarios))
      );
    }

    const headerImageUrl = OverwriteService.getConfig(
      "PDFExport.asset.headerUrl",
      {
        defaultValue: null,
      }
    );

    pdfMake.tableLayouts = this.getPdfTableLayouts();

    const template: TDocumentDefinitions = {
      content,
      defaultStyle: {
        font: "Roboto",
      },
      images: {
        ...(isDefined(headerImageUrl)
          ? {
              headerImage: await FileUtils.getBase64ImageFromUrl(
                headerImageUrl
              ),
            }
          : undefined),
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            margin: [40, 10, 40, 0],
            columnGap: 10,
            columns: [
              {
                alignment: "left",
                width: "*",
                text: `${title}`,
                style: "footerPageCount",
              },
              {
                alignment: "center",
                text: StringUtils.formatDate(new Date()),
                style: "footerPageCount",
              },
              {
                alignment: "right",
                text: currentPage.toString() + " / " + pageCount,
                style: "footerPageCount",
              },
            ],
          },
        ];
      },
      header: function (currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        if (headerImageUrl) {
          return [
            {
              image: "headerImage",
              fit: [200, 16],
              marginTop: 8,
              alignment: "center",
            },
          ];
        } else {
          return [];
        }
      },

      styles: {
        id: {
          fontSize: 14,
          color: "#888",
        },
        header: {
          fontSize: 18,
          bold: true,
          color: "#333",
        },
        subHeader: {
          fontSize: 12,
          color: "#555",
        },
        sectionHeader: {
          fontSize: 15,
          color: "#333",
        },
        description: {
          fontSize: 12,
          color: "#555",
        },
        footerPageCount: {
          fontSize: 10,
          color: "#888",
        },
        fieldLabel: {
          fontSize: 9,
          color: "#888",
        },
        fieldValue: {
          fontSize: 11,
          color: "#333",
        },

        tableHeader: {
          fontSize: 9,
          color: "#888",
        },
        tableStatus: {
          fontSize: 8,
          bold: true,
        },
        tableId: {
          fontSize: 7,
          color: "#888",
        },
        tableValue: {
          fontSize: 9,
        },
        tableCalculation: {
          fontSize: 7,
        },
        tableAmount: {
          fontSize: 8,
        },
        tableFooter: {
          fontSize: 9,
          bold: true,
          color: "#333",
        },
        tableHeaderXS: {
          fontSize: 8,
          color: "#888",
        },
        tableValueXS: {
          fontSize: 8,
        },
      },
    };
    Log.info("ObjectExportService.exportObject", template);
    await PDFExporter.exportPdf("export.pdf", template);
  }

  getPdfTableLayouts(): Record<string, CustomTableLayout> {
    return {
      constructorTable: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i) {
          return "#eee";
        },
        paddingLeft: function (i) {
          return 0;
        },
        paddingRight: function (i, node) {
          return 0;
        },
      },
      assetsTable: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i) {
          return "#eee";
        },
        paddingLeft: function (i) {
          return 0;
        },
        paddingRight: function (i, node) {
          return 0;
        },
      },
      coolTable: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return i === node.table.headerRows ? 2 : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i) {
          return i === 1 ? "#333" : "#eee";
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 4;
        },
        paddingRight: function (i, node) {
          return i === node.table.widths.length - 1 ? 0 : 4;
        },
      },
      coolTableWithFooter: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return i === node.table.headerRows
            ? 2
            : i === node.table.body.length - 1
            ? 2
            : 1;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i, node) {
          return i === 1
            ? "#333"
            : i === node.table.body.length - 1
            ? "#888"
            : "#eee";
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8;
        },
        paddingRight: function (i, node) {
          return i === node.table.widths.length - 1 ? 0 : 8;
        },
      },
    };
  }

  async exportBaseData(
    assets: OAObject[],
    kind: ObjectKind,
    opt: ExportOpt,
    title: string
  ) {
    const content: Content[] = [];

    if (assets.length === 1) {
      content.push(...(await this.exportBaseDataSingle(assets[0], kind, opt)));
    } else {
      content.push(
        ...(await this.exportBaseDataMultiple(assets, kind, opt, title))
      );
    }

    return content;
  }

  async exportBaseDataSingle(
    asset: OAObject,
    kind: ObjectKind,
    opt: ExportOpt
  ) {
    const content: Content[] = [];
    content.push({
      text: asset.data.id,
      style: "id",
    });
    content.push({
      text: asset.data.displayName,
      style: "header",
    });
    content.push({
      text: OrgaStruct.getEntity(asset.data.entity)?.displayName,
      style: "subHeader",
      margin: [0, 0, 0, 10],
    });

    content.push(...(await this.getImageBoxMainPage([asset])));

    if (checkFeature("address", kind)) {
      content.push(...(await this.getAssetAddressSingle([asset], kind)));
    }
    content.push(
      ...(await this.getAssetFields(
        asset,
        kind,
        opt.purchasePrice,
        opt.assetValue,
        true
      ))
    );
    return content;
  }

  async exportBaseDataMultiple(
    assets: OAObject[],
    kind: ObjectKind,
    opt: ExportOpt,
    title: string
  ) {
    const content: Content[] = [];
    content.push({
      text: title,
      style: "header",
      marginBottom: 20,
    });

    const rows: TableCell[][] = [];

    let i = 0;
    for (const asset of assets) {
      const dataStack = await this.getAssetFields(
        asset,
        kind,
        opt.purchasePrice,
        opt.assetValue,
        false
      );
      const firstImageOfAsset = await this.loadImages([asset], 1);

      let firstCol: TableCell[] = [{ text: "" }];
      if (firstImageOfAsset.length === 1) {
        firstCol = [
          {
            image: firstImageOfAsset[0],
            cover: {
              width: 90,
              height: 90,
              valign: "bottom",
              align: "right",
            },
          },
        ];
      }

      rows.push([
        ...firstCol,
        {
          stack: [
            {
              marginTop: 5,
              columns: [
                {
                  text: asset.data.id,
                  color: "#888",
                  width: "auto",
                  fontSize: 8,
                },
                {
                  text: asset.data.displayName,
                  color: "#333",
                  width: "auto",
                  fontSize: 10,
                },
                {
                  text: OrgaStruct.getEntity(asset.data.entity)?.displayName,
                  color: "#555",
                  width: "auto",
                  fontSize: 8,
                },
              ],
              columnGap: 10,
            },
            ...dataStack,
          ],
        },
      ]);
      i++;
    }

    content.push({
      table: {
        headerRows: 0,
        body: rows,
        widths: [100, "*"],
      },
      layout: "assetsTable",
    } as Content);

    const map = await this.loadMapImage(assets, kind, 1000, 350);

    content.push({
      image: map,
      marginTop: 10,
      width: 500,
    });

    return content;
  }

  async getStackingPlanImage(
    mapId: string,
    stackingPlan: EnrichtedRentalUnit[],
    options: ECharts.EChartsOption
  ) {
    const images = [];

    const groups = objectStackingPlanSplitRentalUnitIntoGroups(
      stackingPlan || []
    );

    for await (const e of groups) {
      ECharts.registerMap(mapId, {
        svg: generateStackingPlanSVG([e], [false], true),
      });

      const image = await this.getChartImage(options, 1000, 600, true);

      images.push(image);
    }

    return images;
  }

  getChartImage(option: any, width: number, height: number, asSvg?: boolean) {
    return new Promise<string>((resolve) => {
      const id = `chart-${nanoid()}`;

      const div = document.createElement("div");
      document.body.appendChild(div);
      div.id = id;
      div.style.width = `${width}px`;
      div.style.height = `${height}px`;

      const chart = ECharts.init(document.querySelector(`#${id}`), "default", {
        renderer: asSvg ? "svg" : "canvas", // "canvas" // "svg"
      });

      chart.setOption(option);

      let image = chart.getDataURL({ type: asSvg ? "svg" : "png" });
      // const svgString = chart.renderToSVGString({
      //   useViewBox: true,
      // });
      if (asSvg) {
        image = decodeURIComponent(image.split("charset=UTF-8,")[1]);
      }
      document.body.removeChild(div);
      resolve(image);
    });
  }
  async getLoanable(
    assets: OAObject[],
    kind: ObjectKind,
    opts?: { showGraph: boolean }
  ) {
    const content: Content[] = [];

    content.push({
      text: i18n.t("obj:ObjectExport.Loanable", "Darlehen") as string,
      style: "header",
      marginBottom: 20,
      pageOrientation: "portrait",
      pageBreak: "before",
    });

    const loanData: CBStatisticLoanEntry[] = await HTTP.post({
      target: "STATISTIC",
      url: `query/LOAN_DETAIL/1`,
      bodyParams: {
        query: {
          matchQuery: MQ.and(
            MQ.in(
              "data.objects.objectId",
              assets.map((e) => e._id)
            )
          ),
        },
        objectIds: assets.map((e) => e._id),
      },
    });

    const icr: CBStatisticICR = await HTTP.post({
      target: "STATISTIC",
      url: `query/INTEREST_COVERAGE_RATIO/1`,
      bodyParams: {
        objectIds: assets.map((e) => e._id),
        // query: {
        //   matchQuery: MQ.and(MQ.in("data.objects.objectId", [asset._id])),
        // },
      },
    });

    const ltv: CBStatisticLTV = await HTTP.post({
      target: "STATISTIC",
      url: `query/LOAN_TO_VALUE/1`,
      bodyParams: {
        objectIds: assets.map((e) => e._id),
        // query: {
        //   matchQuery: MQ.and(MQ.in("data.objects.objectId", [asset._id])),
        // },
      },
    });
    const filledData = cbLoanChartFillEmptyData(loanData).map((e) => ({
      ...e,
      annuity: e.repaymentAmount + e.interestAmount,
    }));
    const currentFrame = filledData?.find(
      (e) =>
        e._id.month === moment().utc(true).month() + 1 &&
        e._id.year === moment().utc(true).year()
    );

    const fields: { label: string; value: string }[] = [];

    fields.push({
      label: i18n.t("obj:ObjectExport.ICR", "ICR"),
      value: StringUtils.formatPercent(icr.ICR, true),
    });

    fields.push({
      label: i18n.t("obj:ObjectExport.ltv", "LTV"),
      value: StringUtils.formatPercent(ltv.LTV, true),
    });

    fields.push({
      label: i18n.t("obj:ObjectExport.annuity", "Annuität"),
      value: StringUtils.formatCurrency(currentFrame?.annuity),
    });

    fields.push({
      label: i18n.t("obj:ObjectExport.endBalance", "Restvaluta"),
      value: StringUtils.formatCurrency(currentFrame?.endBalance),
    });
    const fieldsBelow: { label: string; value: string }[] = [];

    fieldsBelow.push({
      label: i18n.t("obj:ObjectExport.repaymentAmount", "Aktuelle Tilgung"),
      value: StringUtils.formatCurrency(currentFrame?.repaymentAmount),
    });

    fieldsBelow.push({
      label: i18n.t("obj:ObjectExport.interestAmount", "Aktuelle Zinsen"),
      value: StringUtils.formatCurrency(currentFrame?.interestAmount),
    });

    fieldsBelow.push({
      label: i18n.t("obj:ObjectExport.accumulatedInterest", "Bezahlte Zinsen"),
      value: StringUtils.formatCurrency(currentFrame?.accumulatedInterest),
    });

    if (!opts.showGraph) {
      fields.push(...fieldsBelow);
    }

    content.push({
      columnGap: 10,
      marginBottom: 20,
      columns: fields.map((field) => ({
        width: "auto",
        stack: [
          {
            text: field.label,
            style: "fieldLabel",
          },
          {
            text: field.value,
            style: "fieldValue",
          },
        ],
      })),
    });

    if (opts.showGraph) {
      const option = cbLoanChartOption(filledData, true);
      const image = await this.getChartImage(option, 1000, 1000, false);
      content.push({
        image,
        width: 500,
      });
      content.push({
        columnGap: 10,
        marginBottom: 20,
        columns: fieldsBelow.map((field) => ({
          width: "auto",
          stack: [
            {
              text: field.label,
              style: "fieldLabel",
            },
            {
              text: field.value,
              style: "fieldValue",
            },
          ],
        })),
      });
    }

    const allLoans: PortfolioLoan[] =
      await queryAllAssetsChained<PortfolioLoan>(
        store.dispatch,
        AssetTypes.Portfolio.Loan,
        MQ.and(
          MQ.in(
            "data.objects.objectId",
            assets.map((e) => e._id)
          )
        )
      );

    if (allLoans.length > 0) {
      content.push({
        style: "tableExample",
        table: {
          headerRows: 1,
          body: [
            [
              {
                text: i18n.t("obj:ObjectExport.Loan", "Darlehen"),
                style: "tableHeader",
              },
              {
                text: i18n.t("obj:ObjectExport.LoanAmount", "Darlehenssumme"),
                style: "tableHeader",
              },
              {
                text: i18n.t("obj:ObjectExport.InterestRate", "Zinssatz"),
                style: "tableHeader",
              },
              {
                text: i18n.t(
                  "obj:ObjectExport.openAmount",
                  "Offener Endbetrag"
                ),
                style: "tableHeader",
              },
              {
                text: i18n.t("obj:ObjectExport.start", "Start"),
                style: "tableHeader",
              },
              {
                text: i18n.t("obj:ObjectExport.end", "Ende"),
                style: "tableHeader",
              },
            ],
            ...allLoans.map((loan) =>
              [
                `${loan.data.loanID} - ${loan.data.text}`,
                StringUtils.formatCurrency(loan.data.loanData.loanAmount),
                StringUtils.formatPercent(loan.data.loanData.annualInterest),
                StringUtils.formatCurrency(
                  loan.data.calculated.refinancingSum,
                  true
                ),
                StringUtils.formatDate(loan.data.loanData.loanPayoutDate),
                StringUtils.formatDate(loan.data.calculated.loanEndDate),
              ].map((e) => ({ text: e, style: "tableValue" }))
            ),
            [
              {
                text: "",
                style: "tableValue",
              },
              {
                text: StringUtils.formatCurrency(
                  _.sum(allLoans.map((e) => e.data.loanData.loanAmount))
                ),
                style: "tableValue",
              },
              {
                text: "",
                style: "tableValue",
              },
              {
                text: StringUtils.formatCurrency(
                  _.sum(allLoans.map((e) => e.data.calculated.refinancingSum))
                ),
                style: "tableValue",
              },
              {
                text: "",
                style: "tableValue",
              },
              { text: "", style: "tableValue" },
            ],
          ],
        },
        layout: "coolTableWithFooter",
      });
    }

    return content;
  }

  async getRiskScenarios(
    assets: OAObject[],
    kind: ObjectKind,
    riskScenarios: RiskScenario[]
  ) {
    const content: Content[] = [];
    for (const riskScenario of riskScenarios) {
      content.push(...(await this.getRiskScenrio(assets, kind, riskScenario)));
    }
    return content;
  }

  async getRiskScenrio(
    assets: OAObject[],
    kind: ObjectKind,
    riskScenario: RiskScenario
  ) {
    const content: Content[] = [];

    content.push({
      text: i18n.t("obj:ObjectExport.RiskScenrio", "Risikoszenario") as string,
      style: "header",
      marginBottom: 20,
      pageOrientation: "landscape",
      pageBreak: "before",
    });

    content.push({
      text: riskScenario.data.name,
      style: "sectionHeader",
      marginBottom: 10,
    });
    if (riskScenario.data.description) {
      content.push({
        text: riskScenario.data.description,
        style: "description",
        marginBottom: 10,
      });
    }

    const rsData: RiskScenarioLoanRiskResponse = await HTTP.post({
      target: "STATISTIC",
      url: `query/LOAN_RISK/1`,
      bodyParams: {
        objectIds: assets.map((e) => e._id),
      },
    });
    const singleValue = riskScenario.data.objectData.length === 1;
    const multipleLoans = rsData?.loans.length > 1;

    const allLoans = riskScenario.data.objectData
      .map((obj) => obj.loanData)
      .flat();
    const aggregations = {
      rentNet: _.sumBy(rsData.objects, (obj) => obj.rentNet),
      rentNetPlan: _.sumBy(rsData.objects, (obj) => obj.rentNetPlan),
      interestRate:
        _.sumBy(allLoans, (loan) => loan.interestRate) / allLoans.length,
      repaymentRate:
        _.sumBy(allLoans, (loan) => loan.repaymentRate) / allLoans.length,
      duration: _.sumBy(allLoans, (loan) => loan.duration) / allLoans.length,
      vacancyRate:
        _.sumBy(
          riskScenario.data.objectData,
          (obj) => obj.estimatedVacancyRate
        ) / riskScenario.data.objectData.length,
      otherCostRate:
        _.sumBy(
          riskScenario.data.objectData,
          (obj) => obj.estimatedCostFactor
        ) / riskScenario.data.objectData.length,
      rentGrowthEstimation:
        _.sumBy(
          riskScenario.data.objectData,
          (obj) => obj.estimatedRentNetGrowth
        ) / riskScenario.data.objectData.length,
    };

    const fieldValues: { label: string; value: string }[] = [];

    fieldValues.push({
      label: i18n.t("cb:RiscScenarioCard.planNKMPerMonth", "Plan NKM p.m."),
      value: StringUtils.formatCurrency(aggregations.rentNetPlan / 12),
    });

    fieldValues.push({
      label: i18n.t("cb:RiscScenarioCard.planNKMPerYear", "Plan NKM p.a."),
      value: StringUtils.formatCurrency(aggregations.rentNetPlan),
    });
    fieldValues.push({
      label: i18n.t("cb:RiscScenarioCard.currentNKMPerMonth", "Ist NKM p.m."),
      value: StringUtils.formatCurrency(aggregations.rentNet / 12),
    });
    fieldValues.push({
      label: i18n.t("cb:RiscScenarioCard.currentNKMPerYear", "Ist NKM p.a."),
      value: StringUtils.formatCurrency(aggregations.rentNet),
    });
    fieldValues.push({
      label:
        (singleValue ? "" : "Ø ") +
        i18n.t("cb:RiscScenarioCard.planGrowthNKM", "NKM Anpassung"),
      value: StringUtils.formatPercent(aggregations.rentGrowthEstimation),
    });
    fieldValues.push({
      label:
        (singleValue ? "" : "Ø ") +
        i18n.t("cb:RiscScenarioCard.planVacancyRate", "Leerstandsquote"),
      value: StringUtils.formatPercent(aggregations.vacancyRate),
    });

    fieldValues.push({
      label:
        (singleValue ? "" : "Ø ") +
        i18n.t("cb:RiscScenarioCard.planOtherCostRate", "Weitere Kosten"),
      value: StringUtils.formatPercent(aggregations.otherCostRate),
    });
    fieldValues.push({
      label:
        (!multipleLoans ? "" : "Ø ") +
        i18n.t("cb:RiscScenarioCard.planInterestRate", "Sollzins"),
      value: StringUtils.formatPercent(aggregations.interestRate),
    });
    fieldValues.push({
      label:
        (!multipleLoans ? "" : "Ø ") +
        i18n.t("cb:RiscScenarioCard.planRepaymentRate", "Tilgungsrate"),
      value: StringUtils.formatPercent(aggregations.repaymentRate),
    });
    fieldValues.push({
      label:
        (!multipleLoans ? "" : "Ø ") +
        i18n.t("cb:RiscScenarioCard.planDuration", "Laufzeit"),
      value:
        StringUtils.formatNumber(aggregations.duration, false, undefined, 0) +
        " " +
        i18n.t("Globals.Label.Years", "Jahre"),
    });

    content.push({
      columnGap: 10,
      marginBottom: 10,
      columns: fieldValues.map((field) => ({
        width: "auto",
        stack: [
          {
            text: field.label,
            style: "fieldLabel",
          },
          {
            text: field.value,
            style: "fieldValue",
          },
        ],
      })),
    });
    // content.push({
    //   columnGap: 10,
    //   marginBottom: 20,
    //   columns: fieldValues
    //     .filter((e, i) => i >= 5)
    //     .map((field) => ({
    //       width: "auto",
    //       stack: [
    //         {
    //           text: field.label,
    //           style: "fieldLabel",
    //         },
    //         {
    //           text: field.value,
    //           style: "fieldValue",
    //         },
    //       ],
    //     })),
    // });

    const option = riskScenarioCalculateData(
      rsData,
      riskScenario.data.objectData,
      false,
      true
    );

    const image = await this.getChartImage(option, 1600, 600, true);

    content.push({ svg: image, width: 760 });

    // date
    // auszahlung
    // darlhenestand
    // annuität
    // tilgung
    // zinsen
    // accumZin
    // mieteinnahme
    // übeschuss
    // note

    const headers = [
      {
        text: i18n.t("obj:ObjectExport.Date", "Datum") as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.Outpayment", "Auszahlung") as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.Debt", "Darlehenstand") as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.PlanRent", "Planmiete") as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t(
          "obj:ObjectExport.VacancyLoss",
          "Leerstandsverlust"
        ) as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.Rentalincome", "Mieteinnahme") as string,
        style: "tableHeaderXS",
        width: "auto",
      },

      {
        text: i18n.t("obj:ObjectExport.Annuitet", "Annuität") as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.Repayment", "Tilgung") as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.Interest", "Zinsen") as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.OtherCost", "Weitere Kosten") as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.Overpayment", "Übeschuss") as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t(
          "obj:ObjectExport.AccumulatedInterest",
          "Kumulierte Zinsen"
        ) as string,
        style: "tableHeaderXS",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.Note", "Notiz") as string,
        style: "tableHeaderXS",
        width: "auto",
      },
    ];

    const fields: TableCell[][] = [];

    fields.push(headers.map(({ width, ...e }) => e));

    const currentBalance = option.series.find(
      (e) => e.id === "currentBalance"
    ).data;
    const plannedBalance = option.series.find(
      (e) => e.id === "plannedBalance"
    ).data;
    const interests = option.series.find((e) => e.id === "interest").data;
    const repayments = option.series.find((e) => e.id === "repayment").data;
    const otherCosts = option.series.find((e) => e.id === "otherCost").data;
    const rentalPlan = option.series.find((e) => e.id === "rentalPlan").data;
    const vacancyLosses = option.series.find(
      (e) => e.id === "vacancyLoss"
    ).data;
    const accumulatedInterests = option.series.find(
      (e) => e.id === "accumulatedInterest"
    ).data;

    const dates = currentBalance.map((e) => e[0]) as Date[];

    const datesEndOfLoans = rsData.loans.map((e) => moment(e.endDate));

    dates.forEach((date) => {
      const isCurrent = moment(date).isSame(moment(), "month");
      const isFuture = moment(date).isSameOrAfter(moment(), "month");
      if (!isFuture) {
        return; // ignore older entries
      }
      const entries = rsData.loanData.filter(
        (e) =>
          e.date.month === date.getMonth() && e.date.year === date.getFullYear()
      );
      const payouts = _.sum(entries.map((e) => e.payout || 0));

      const balance =
        (currentBalance.find((e) =>
          moment(e[0]).isSame(moment(date), "month")
        )?.[1] || 0) +
        (plannedBalance.find((e) =>
          moment(e[0]).isSame(moment(date), "month")
        )?.[1] || 0);
      const interest =
        interests.find((e) =>
          moment(e[0]).isSame(moment(date), "month")
        )?.[1] || 0;
      const repayment =
        repayments.find((e) =>
          moment(e[0]).isSame(moment(date), "month")
        )?.[1] || 0;
      const otherCost =
        otherCosts.find((e) =>
          moment(e[0]).isSame(moment(date), "month")
        )?.[1] || 0;
      const rentalIncome =
        (rentalPlan.find((e) =>
          moment(e[0]).isSame(moment(date), "month")
        )?.[1] || 0) -
        (vacancyLosses.find((e) =>
          moment(e[0]).isSame(moment(date), "month")
        )?.[1] || 0);
      const vacancyLoss =
        vacancyLosses.find((e) =>
          moment(e[0]).isSame(moment(date), "month")
        )?.[1] || 0;
      const accumulatedInterest =
        accumulatedInterests.find((e) =>
          moment(e[0]).isSame(moment(date), "month")
        )?.[1] || 0;

      let notes = [];

      if (isCurrent) {
        notes.push(
          i18n.t("obj:ObjectExport.CurrentMonth", "Aktueller Monat") as string
        );
      }

      if (datesEndOfLoans.find((e) => e.isSame(moment(date), "month"))) {
        notes.push(
          i18n.t("obj:ObjectExport.EndOfLoan", "Kreditende") as string
        );
      }
      const surplus = rentalIncome - otherCost - repayment - interest;
      fields.push([
        { text: StringUtils.formatDate(date), style: "tableValueXS" },
        {
          text: StringUtils.formatCurrency(payouts, true),
          style: "tableValueXS",
        },
        {
          text: StringUtils.formatCurrency(balance, true),
          style: "tableValueXS",
        },
        {
          text: StringUtils.formatCurrency(vacancyLoss + rentalIncome, true),
          style: "tableValueXS",
        },
        {
          text: StringUtils.formatCurrency(vacancyLoss, true),
          style: "tableValueXS",
        },
        {
          text: isFuture ? StringUtils.formatCurrency(rentalIncome, true) : "-",
          style: "tableValueXS",
        },
        {
          text: StringUtils.formatCurrency(repayment + interest, true),
          style: "tableValueXS",
        },
        {
          text: StringUtils.formatCurrency(repayment, true),
          style: "tableValueXS",
        },
        {
          text: StringUtils.formatCurrency(interest, true),
          style: "tableValueXS",
        },
        {
          text: StringUtils.formatCurrency(otherCost, true),
          style: "tableValueXS",
        },
        {
          text: isFuture ? StringUtils.formatCurrency(surplus) : "-",
          style: "tableValueXS",
          color: surplus >= 0 ? "green" : "red",
        },
        {
          text: StringUtils.formatCurrency(accumulatedInterest, true),
          style: "tableValueXS",
        },
        { text: notes.join("\n"), style: "tableValueXS" },
      ]);
    });

    content.push({
      pageBreak: "before",
      style: "table",
      table: {
        headerRows: 1,
        body: fields,
        widths: headers.map((e) => e.width),
      },

      layout: "coolTable",
    });

    return content;
  }

  async getRentalData(
    assets: OAObject[],
    kind: ObjectKind,
    rentalDataOpts?: {
      showStackingPlan: boolean;
      showRentalIncomeGraph: boolean;
      rentNetPlan: boolean;
      rentNetCurrent: boolean;
      rentGrossPlan: boolean;
      rentGrossCurrent: boolean;
      vacancyPage: boolean;
    }
  ) {
    const content: Content[] = [];

    // content.push({
    //   text: i18n.t("obj:ObjectExport.RentalData", "Mietdaten") as string,
    //   style: "header",
    //   marginBottom: 20,
    //   pageOrientation: "portrait",
    //   pageBreak: "before",
    // });

    // plandata
    const vacancyData: CBStatisticImmoVacancyRate = await HTTP.post({
      target: "STATISTIC",
      url: `query/IMMO_VACANCY_RATE/1`,
      bodyParams: {
        fromDate: moment().startOf("month").utc(true).toISOString(),
        toDate: moment().endOf("month").utc(true).toISOString(),
        objectIds: assets.map((e) => e._id),
      },
    });
    const data = vacancyData
      ? {
          rentPlanned:
            (vacancyData?.[0]?.kpi.vacancyRentNet.totalNonVacantPlanned +
              vacancyData?.[0]?.kpi.vacancyRentNet.totalVacant) *
            12,
          rented: vacancyData?.[0]?.kpi.vacancyRentNet.totalNonVacant * 12,
          rentedDiff: NumberUtils.normalizeDecimal(
            (vacancyData?.[0]?.kpi.vacancyRentNet.totalNonVacantPlanned -
              vacancyData?.[0]?.kpi.vacancyRentNet.totalNonVacant) *
              12
          ),
          vacant: vacancyData?.[0]?.kpi.vacancyRentNet.totalVacant * 12,
        }
      : null;
    // stackingplan
    const stackingData: EnrichtedRentalUnit[] = await HTTP.post({
      target: "EMPTY",
      url: `/rental/getStackingPlan`,
      bodyParams: {
        objectIds: assets.map((e) => e._id),
      },
    });
    const listData = stackingData
      ? convertEnrichtedRentalUnitToEnrichtedRentalUnitKPISGathered(
          stackingData
        )
      : [];
    if (assets.length === 1 && rentalDataOpts.showStackingPlan) {
      content.push({
        text: i18n.t("obj:ObjectExport.StackingPlan", "Stackingplan") as string,
        pageBreak: "before",
        fontSize: 14,
        bold: true,
        marginBottom: 10,
      });
      const newGroupedData = objectStackingPlanSplitRentalUnitIntoGroups(
        stackingData || []
      );

      let index = 0;
      for (const e of newGroupedData) {
        // const svg = generateStackingPlanSVG([e], [true], true);
        // content.push({ svg: svg, width: 500 });

        const mapString = `object-stacking-plan-pdf-${assets
          .map((e) => e._id)
          .join("_")}-${index}`;
        ECharts.registerMap(mapString, {
          svg: generateStackingPlanSVG([e], [false], true),
        });

        const image = await this.getChartImage(
          getObjectStackingPlanChartOptions(
            stackingData,
            [e],
            mapString,
            undefined,
            undefined,
            true
          ),
          1000,
          600,
          true
        );

        content.push({ svg: image, width: 500 });
        index++;
      }
    }

    content.push({
      pageOrientation: "landscape",
      pageBreak: "before",
      text: i18n.t("obj:ObjectExport.Tenants", "Mieter") as string,
      fontSize: 14,
      bold: true,
      // pageBreak: "before",
      marginBottom: 10,
    });

    // unit
    // status + rental
    // nutzart + art + etage
    // area / count
    // NKM ist
    // NKM soll
    // NKM Diff
    // brutto soll
    // brutto ist
    // brutto diff
    // plan nebenkosten?

    const header = [
      ...when(
        assets.length > 1,
        [
          {
            text: i18n.t("obj:ObjectExport.Object", "Objekt") as string,
            style: "tableHeader",
            width: "auto",
          },
        ],
        []
      ),
      {
        text: i18n.t("obj:ObjectExport.Unit", "Einheit") as string,
        style: "tableHeader",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.Tenant", "Mieter") as string,
        style: "tableHeader",
        width: "auto",
      },
      {
        text: i18n.t("obj:ObjectExport.UnitTypeGroup", "Nutzart") as string,
        style: "tableHeader",
        width: "auto",
      },
      // {
      //   text: i18n.t("obj:ObjectExport.Floor", "Etage") as string,
      //   style: "tableHeader",
      // },
      {
        text: i18n.t("obj:ObjectExport.AreaCount", "Raum") as string,
        style: "tableHeader",
        width: 60,
      },
      ...when(
        rentalDataOpts.rentNetCurrent && rentalDataOpts.rentNetPlan,
        [
          {
            text: i18n.t(
              "obj:ObjectExport.rentNet_new",
              "NKM Ist/Soll"
            ) as string,
            style: "tableHeader",
            width: 80,
          },
        ],
        []
      ),
      ...when(
        rentalDataOpts.rentNetCurrent && !rentalDataOpts.rentNetPlan,
        [
          {
            text: i18n.t("obj:ObjectExport.rentNetIs", "NKM Ist") as string,
            style: "tableHeader",
            width: 65,
          },
        ],
        []
      ),
      ...when(
        !rentalDataOpts.rentNetCurrent && rentalDataOpts.rentNetPlan,
        [
          {
            text: i18n.t("obj:ObjectExport.rentNetPlan", "NKM Plan") as string,
            style: "tableHeader",
            width: 65,
          },
        ],
        []
      ),
      ...when(
        rentalDataOpts.rentGrossCurrent && rentalDataOpts.rentGrossPlan,
        [
          {
            text: i18n.t(
              "obj:ObjectExport.rentGross_new",
              "Brutto Ist/Soll"
            ) as string,
            style: "tableHeader",
            width: 80,
          },
        ],
        []
      ),
      ...when(
        rentalDataOpts.rentGrossCurrent && !rentalDataOpts.rentGrossPlan,
        [
          {
            text: i18n.t(
              "obj:ObjectExport.rentGrossIs",
              "Brutto Ist"
            ) as string,
            style: "tableHeader",
            width: 65,
          },
        ],
        []
      ),
      ...when(
        !rentalDataOpts.rentGrossCurrent && rentalDataOpts.rentGrossPlan,
        [
          {
            text: i18n.t(
              "obj:ObjectExport.rentGrossPlan",
              "Brutto Plan"
            ) as string,
            style: "tableHeader",
            width: 65,
          },
        ],
        []
      ),
      {
        text: i18n.t("obj:ObjectExport.AggrementUntil", "Mietende") as string,
        style: "tableHeader",
        width: 60,
      },
    ];

    const fields: TableCell[][] = [header.map(({ width, ...e }) => e)];

    ArrayUtils.sortData(listData, [
      { dir: "desc", key: "data.objectId" },
      {
        dir: "desc",
        key: "data.floor",
      },
    ]).forEach((entry) => {
      const status = getConfigRentalStatus(entry.data.rentalStatus);
      const tenant = entry.data.vacant
        ? `${entry.data.vacant?.data.id} - ${entry.data.vacant?.data.displayName}`
        : `${entry.data.agreement?.data.id} - ${entry.data.agreement?.data.displayName}`;

      const unit = ObjectKindStruct.getUnitTypeBy(entry.data.unitType);
      const unitTypeGroup = getConfigRentalUnitTypeGroup(unit.group);
      const unitType = ObjectKindStruct.getUnitTypeBy(entry.data.unitType);

      fields.push([
        ...when(
          assets.length > 1,
          [
            {
              stack: [
                {
                  text: assets.find((e) => e._id === entry.data.objectId)?.data
                    ?.id,
                  style: "tableId",
                },
                {
                  text: assets.find((e) => e._id === entry.data.objectId)?.data
                    ?.displayName,
                  style: "tableValue",
                },
              ],
            },
          ],
          []
        ),

        {
          stack: [
            {
              text: entry.data.id,
              style: "tableId",
            },
            {
              text: entry?.data?.displayName,
              style: "tableValue",
            },
          ],
        },
        {
          stack: [
            {
              text: status.label,
              color: status.color,
              style: "tableStatus",
            },
            {
              text: tenant,
              style: "tableValue",
            },
          ],
        },
        {
          stack: [
            {
              text: LanguageService.translateLabel(unitTypeGroup.label),
              style: "tableId",
            },
            {
              text: LanguageService.translateLabel(unitType.displayName),
              style: "tableValue",
            },
          ],
        },
        {
          stack: [
            {
              text: entry.data.area
                ? StringUtils.formatArea(entry.data.area)
                : "",
              style: "tableValue",
            },
            {
              text: entry.data.quantity ? entry.data.quantity.toString() : "",
              style: "tableValue",
            },
          ],
        },
        ...when(
          rentalDataOpts.rentNetCurrent || rentalDataOpts.rentNetPlan,
          [
            {
              stack: [
                {
                  text: `${when(
                    rentalDataOpts.rentNetCurrent,
                    StringUtils.formatCurrency(entry?.kpis.rentNetCurrent),
                    ""
                  )}${when(
                    rentalDataOpts.rentNetCurrent && rentalDataOpts.rentNetPlan,
                    " / ",
                    ""
                  )}${when(
                    rentalDataOpts.rentNetPlan,
                    StringUtils.formatCurrency(entry?.data?.rentNet),
                    ""
                  )}`,
                  style: "tableAmount",
                },
                ...when(
                  rentalDataOpts.rentNetCurrent && rentalDataOpts.rentNetPlan,
                  [
                    {
                      text: StringUtils.formatCurrency(
                        entry.kpis.rentNetDeficitCurrent
                      ),
                      color:
                        entry.kpis.rentNetDeficitCurrent < 0 ? "red" : "green",
                      style: "tableCalculation",
                    },
                  ],
                  []
                ),
              ],
            },
          ],
          []
        ),
        ...when(
          rentalDataOpts.rentGrossCurrent || rentalDataOpts.rentGrossPlan,
          [
            {
              stack: [
                {
                  text: `${when(
                    rentalDataOpts.rentGrossCurrent,
                    StringUtils.formatCurrency(entry?.kpis.rentGrossCurrent),
                    ""
                  )}${when(
                    rentalDataOpts.rentGrossCurrent &&
                      rentalDataOpts.rentGrossPlan,
                    " / ",
                    ""
                  )}${when(
                    rentalDataOpts.rentGrossPlan,
                    StringUtils.formatCurrency(entry?.data?.rentGross),
                    ""
                  )}`,
                  style: "tableAmount",
                },
                ...when(
                  rentalDataOpts.rentGrossCurrent &&
                    rentalDataOpts.rentGrossPlan,
                  [
                    {
                      text: StringUtils.formatCurrency(
                        entry.kpis.rentGrossDeficitCurrent
                      ),
                      color:
                        entry.kpis.rentGrossDeficitCurrent < 0
                          ? "red"
                          : "green",
                      style: "tableCalculation",
                    },
                  ],
                  []
                ),
              ],
            },
          ],
          []
        ),
        {
          stack: [
            {
              text: StringUtils.formatDate(
                entry.data.agreement?.data.agreementExpiration
              ),
              style: "tableValue",
            },
          ],
        },
      ] as TableCell[]);
    });

    const aggregated = {
      rentPerAreaCurrentAVG:
        _.sumBy(
          listData.filter((e) => e.data.area > 0),
          (item) => item.kpis.rentNetCurrent
        ) / _.sumBy(listData, (item) => item.data.area),
      rentPerQuantityCurrentAVG:
        _.sumBy(
          listData.filter((e) => e.data.quantity > 0),
          (item) => item.kpis.rentNetCurrent
        ) / _.sumBy(listData, (item) => item.data.quantity),
      rentPerAreaPlanAVG:
        _.sumBy(
          listData.filter((e) => e.data.area > 0),
          (item) => item.data.rentNet
        ) / _.sumBy(listData, (item) => item.data.area),
      rentPerQuantityPlanAVG:
        _.sumBy(
          listData.filter((e) => e.data.quantity > 0),
          (item) => item.data.rentNet
        ) / _.sumBy(listData, (item) => item.data.quantity),

      rentGrossDeficit: _.sumBy(
        listData,
        (item) => item.kpis.rentGrossDeficitCurrent
      ),
      area: _.sumBy(listData, (item) => item.data.area),
      rentNetPerAreaPlan:
        _.sumBy(
          listData.filter((e) => e.data.area > 0),
          (item) => item.data.rentNet
        ) / _.sumBy(listData, (item) => item.data.area),
      rentNetPerAreaCurrent:
        _.sumBy(
          listData.filter((e) => e.data.area > 0),
          (item) => item.kpis.rentNetCurrent
        ) / _.sumBy(listData, (item) => item.data.area),

      rentNetPerQuantityPlan:
        _.sumBy(
          listData.filter((e) => e.data.quantity > 0),
          (item) => item.data.rentNet
        ) / _.sumBy(listData, (item) => item.data.quantity),
      rentNetPerQuantityCurrent:
        _.sumBy(
          listData.filter((e) => e.data.quantity > 0),
          (item) => item.kpis.rentNetCurrent
        ) / _.sumBy(listData, (item) => item.data.quantity),

      quantity: _.sumBy(listData, (item) => item.data.quantity),
      rentNet: _.sumBy(listData, (item) => item.data.rentNet),
      rentGross: _.sumBy(listData, (item) => item.data.rentGross),
      rentNetCurrent: _.sumBy(listData, (item) => item.kpis.rentNetCurrent),
      rentNetDeficitCurrent: _.sumBy(
        listData,
        (item) => item.kpis.rentNetDeficitCurrent
      ),
      rentGrossCurrent: _.sumBy(listData, (item) => item.kpis.rentGrossCurrent),
    };

    fields.push([
      {
        stack: [
          {
            text: `${i18n.t(
              "cb:ObjectExport.RentalUnits",
              "{{count}} Mieteinheiten",
              { count: listData.length }
            )}`,
            style: "tableFooter",
          },
        ],
      },

      ...when(
        assets.length > 1,
        [
          {
            text: "",
          },
        ],
        []
      ),
      {
        text: "",
      },
      {
        text: "",
      },
      {
        text: "",
      },
      {
        stack: [
          {
            text: aggregated.area
              ? StringUtils.formatArea(aggregated.area)
              : "",
            style: "tableFooter",
          },
          {
            text: aggregated.quantity ? aggregated.quantity.toString() : "",
            style: "tableFooter",
          },
        ],
      },
      ...when(
        rentalDataOpts.rentNetCurrent || rentalDataOpts.rentNetPlan,
        [
          {
            stack: [
              {
                text: `${when(
                  rentalDataOpts.rentNetPlan,
                  StringUtils.formatCurrency(aggregated?.rentNet),
                  ""
                )}${when(
                  rentalDataOpts.rentNetCurrent && rentalDataOpts.rentNetPlan,
                  " / ",
                  ""
                )}${when(
                  rentalDataOpts.rentNetCurrent,
                  StringUtils.formatCurrency(aggregated.rentNetCurrent),
                  ""
                )}`,
                style: "tableFooter",
              },
              ...when(
                rentalDataOpts.rentNetCurrent && rentalDataOpts.rentNetPlan,
                [
                  {
                    text: StringUtils.formatCurrency(
                      aggregated.rentNetDeficitCurrent
                    ),
                    color:
                      aggregated.rentNetDeficitCurrent < 0 ? "red" : "green",
                    style: "tableCalculation",
                  },
                ],
                []
              ),
            ],
          },
        ],
        []
      ),
      ...when(
        rentalDataOpts.rentGrossCurrent || rentalDataOpts.rentGrossPlan,
        [
          {
            stack: [
              {
                text: `${when(
                  rentalDataOpts.rentGrossPlan,
                  StringUtils.formatCurrency(aggregated.rentGross),
                  ""
                )}${when(
                  rentalDataOpts.rentGrossCurrent &&
                    rentalDataOpts.rentGrossPlan,
                  " / ",
                  ""
                )}${when(
                  rentalDataOpts.rentGrossCurrent,
                  StringUtils.formatCurrency(aggregated.rentGrossCurrent),
                  ""
                )}`,
                style: "tableFooter",
              },
              ...when(
                rentalDataOpts.rentGrossCurrent && rentalDataOpts.rentGrossPlan,
                [
                  {
                    text: StringUtils.formatCurrency(
                      aggregated.rentGrossDeficit
                    ),
                    color: aggregated.rentGrossDeficit < 0 ? "red" : "green",
                    style: "tableCalculation",
                  },
                ],
                []
              ),
            ],
          },
        ],
        []
      ),
    ] as TableCell[]);

    content.push({
      style: "table",
      table: {
        headerRows: 1,
        body: fields,
        widths: header.map((e) => e.width),
      },

      layout: "coolTableWithFooter",
    });

    if (rentalDataOpts?.showRentalIncomeGraph) {
      const image = await this.getChartImage(
        cbRentalStatusChartOption(data, true),
        1000,
        250,
        false
      );
      content.push({
        text: i18n.t(
          "obj:ObjectExport.RentalSituation",
          "Mieteinnahmen"
        ) as string,
        fontSize: 14,
        bold: true,
        marginTop: 10,
        marginBottom: 10,
      });
      content.push({
        image,
        width: 500,
      });
    }

    if (rentalDataOpts.vacancyPage) {
      content.push({
        pageOrientation: "portrait",
        pageBreak: "before",
        text: i18n.t("obj:ObjectExport.Vacancy", "Leerstand") as string,
        fontSize: 14,
        bold: true,
        // pageBreak: "before",
        marginBottom: 10,
      });

      // plandata
      const distributionData: CBStatisticImmoVacancyDistribution =
        await HTTP.post({
          target: "STATISTIC",
          url: `query/IMMO_VACANCY_RATE_DETAIL/2`,
          bodyParams: {
            date: moment().startOf("day").utc(true).toISOString(),
            objectIds: assets.map((e) => e._id),
          },
        });

      const vacnacyDistributionImage = await this.getChartImage(
        cbVacancyDistributionChartOptions(
          vacancyData,
          "rentNet",
          true,
          undefined,
          true
        ),
        500,
        500
      );
      let vacnacyDistributionByUnitImage;
      if (distributionData.length > 0) {
        const relevantVacancyUnits =
          calcRelevantDataForVacancyByUnitChart(distributionData);
        vacnacyDistributionByUnitImage = await this.getChartImage(
          cbVacancyByUnitChartOptions(relevantVacancyUnits, "rentNet", true),
          500,
          500
        );
      }

      content.push({
        columns: [
          {
            image: vacnacyDistributionImage,
            width: 250,
          },
          ...when(
            distributionData.length > 0,
            [
              {
                image: vacnacyDistributionByUnitImage,
                width: 250,
              },
            ],
            []
          ),
        ],
        columnGap: 0,
      });

      const vacancyDataHistory: CBStatisticImmoVacancyRate = await HTTP.post({
        target: "STATISTIC",
        url: `query/IMMO_VACANCY_RATE/1`,
        bodyParams: {
          fromDate: moment()
            .subtract(6, "month")
            .startOf("month")
            .toISOString(),
          toDate: moment().add(5, "year").startOf("month").toISOString(),
          objectIds: assets.map((e) => e._id),
        },
      });

      const vacancyHistoryImage = await this.getChartImage(
        cbVacanyHistoryCardOptions(vacancyDataHistory, "vacancyRentNet", true),
        1000,
        500
      );

      content.push({
        image: vacancyHistoryImage,
        width: 500,
      });
    }

    return content;
  }

  async getImageBoxMainPage(assets: OAObject[]) {
    const content: Content[] = [];
    const imageBase64 = await this.loadImages(assets);
    if (imageBase64.length > 0) {
      if (imageBase64.length === 1) {
        const firstImage = imageBase64[0];
        content.push({
          image: firstImage,
          cover: { width: 510, height: 300, valign: "bottom", align: "right" },
        });
      }
      if (imageBase64.length <= 4) {
        const firstImage = imageBase64[0];

        const otherImages = imageBase64.slice(1, 4); // only show next 3 images

        if (otherImages.length > 0) {
          content.push({
            columns: [
              {
                image: firstImage,
                cover: {
                  width: 410,
                  height: 300,
                  valign: "bottom",
                  align: "right",
                },
              },

              {
                width: 100,
                stack: otherImages.map((img) => ({
                  image: img,
                  width: 100,
                  cover: {
                    width: 100,
                    height: 100,
                    valign: "bottom",
                    align: "right",
                  },
                })),
              },
            ],
            columnGap: 0,
          });
        }
      } else {
        const colCount = Math.ceil(Math.sqrt(imageBase64.length));
        const imagesSize = 500 / colCount;

        for (let i = 0; i < colCount; i++) {
          const columns = imageBase64.slice(
            i * colCount,
            i * colCount + colCount
          );

          content.push({
            columns: columns.map((img) => ({
              image: img,
              width: imagesSize,
              cover: {
                width: imagesSize,
                height: imagesSize,
                valign: "bottom",
                align: "right",
              },
            })),
          });
        }
      }
    }
    return content;
  }

  async getAssetAddressSingle(assets: OAObject[], kind: ObjectKind) {
    const content: Content[] = [];

    const addresses =
      assets.map((e) => e.data.feature.address?.address).flat() || [];
    content.push({
      marginTop: 20,
      columnGap: 20,
      columns: [
        {
          image: await this.loadMapImage(assets, kind, 500, 300),
          width: 300,
        },
        addresses.map((address) => ({
          marginTop: 10,
          stack: [
            {
              fontSize: 14,
              color: "#333",
              text: address.streetname + " " + address.streetnumber,
            },
            {
              fontSize: 12,
              color: "#888",
              text: address.postalcode + " " + address.city,
            },
          ],
        })),
      ],
    } as Content);

    return content;
  }
  async loadMapImage(
    assets: OAObject[],
    kind: ObjectKind,
    width: number,
    height: number
  ) {
    const locations = assets.map(
      (asset) => asset.data.feature?.address?.location
    );
    const calcBounds = calculateCenter(locations);
    const url = `https://maps.googleapis.com/maps/api/staticmap?center=${
      calcBounds?.lat
    },${calcBounds?.lng}&${locations
      .map(
        (location) => `markers=color:red%7C${location?.lat},${location?.lng}`
      )
      .join("&")}&${
      assets.length === 1 ? "zoom=14&" : ""
    }maptype=roadmap&size=${width}x${height}&key=${
      GOGOLE_MAPS_API_KEY.googleMapsApiKey
    }`; //&signature=YOUR_SIGNATURE`;

    const image = await FileUtils.getBase64ImageFromUrl(url);

    return image;
  }

  //
  //  Grundstück fläche
  // Nutzfläche
  // Etagen anzahl
  // Leerstandsquote (berechnet)
  //
  //
  // Vermietbare Fläche
  //
  // summen
  // spalten auswählbar
  // Mietlaufzeit

  // export of wartungsverträge/Schadensfälle as export

  //

  async getAssetFields(
    asset: OAObject,
    kind: ObjectKind,
    addPurchasePrice: boolean,
    addAssetValue: boolean,
    bigSize: boolean
  ) {
    const content: Content = [];
    const columns: Column[] = [];

    const usePurchasePrice =
      addPurchasePrice && checkAnyFeature(["purchasePrice"], kind);
    const featureActive =
      checkAnyFeature(["immo"], kind) ||
      (kind?.data?.customfields || []).length > 0 ||
      usePurchasePrice;

    if (featureActive) {
      const fields: { label: string; value: string }[] = [];

      if (checkFeature("immo", kind)) {
        fields.push({
          label: i18n.t("cb:Portfolio.Objekt.area", "Fläche"),
          value: StringUtils.formatArea(asset.data?.feature?.immo?.area),
        });

        fields.push({
          label: i18n.t("cb:Portfolio.Objekt.constructionYear", "Baujahr"),
          value: asset.data?.feature?.immo?.constructionYear?.toString(),
        });
      }
      if (usePurchasePrice) {
        fields.push({
          label: i18n.t("cb:Portfolio.Objekt.purchasePrice", "Kaufpreis"),
          value: StringUtils.formatCurrency(
            asset.data?.feature?.purchasePrice?.value.amount,
            undefined,
            undefined,
            asset.data?.feature?.purchasePrice?.value.currency
          ),
        });

        fields.push({
          label: i18n.t("cb:Portfolio.Objekt.purchaseDate", "Kaufdatum"),
          value: when(
            asset.data?.feature?.purchasePrice?.date,
            (value) => StringUtils.formatDate(value),
            "-"
          ),
        });
      }

      kind.data.customfields.forEach((cf) => {
        let value = "-";
        if (isDefined(asset.data.custom?.[cf.id])) {
          switch (cf.fieldType) {
            case "boolean":
              value = asset.data.custom?.[cf.id] ? "Ja" : "Nein";
              break;
            case "number":
              value = asset.data.custom?.[cf.id].toString();
              break;
            case "string":
              value = asset.data.custom?.[cf.id].toString();
              break;
            case "date":
              value = when(
                asset.data.custom?.[cf.id],
                (value) => StringUtils.formatDate(value),
                "-"
              );
              break;
            default:
              value = asset.data.custom?.[(cf as any).id].toString();
          }
        }
        fields.push({
          label: LanguageService.translateLabel(cf.displayName),
          value: value,
        });
      });

      const c: Content = {
        stack: [
          {
            text: i18n.t("obj:ObjectExport.Data", "Daten") as string,
            fontSize: bigSize ? 14 : 10,
            bold: true,
            // pageBreak: "before",
            marginTop: bigSize ? 20 : 5,
            marginBottom: bigSize ? 10 : 5,
          },
          ...fields.map((field) => [
            {
              columnGap: 10,
              columns: [
                {
                  text: field.label,
                  // style: "fieldValue",
                  fontSize: bigSize ? 12 : 8,
                  color: "#888",
                  width: "auto",
                },
                {
                  text: field.value,
                  // style: "fieldLabel",
                  fontSize: bigSize ? 12 : 8,
                  color: "#000",
                  width: "auto",
                },
              ],
            },
          ]),
        ],
      };
      columns.push(c);
    }

    const assetValue = asset.data.feature?.assetValue;

    if (addAssetValue && assetValue?.current) {
      let stack = [];
      stack.push({
        text: i18n.t("obj:ObjectExport.AssetValue", "Verkehrswert") as string,
        fontSize: bigSize ? 14 : 10,
        bold: true,
        // pageBreak: "before",
        marginTop: bigSize ? 20 : 5,
        marginBottom: bigSize ? 10 : 5,
      });

      const renderValueEntry = (entry: ObjectFeature_valueEntry) => {
        stack.push({
          columnGap: 10,
          columns: [
            {
              text: StringUtils.formatCurrency(entry.value),
              // style: "fieldValue",
              fontSize: bigSize ? 12 : 8,
              color: "#888",
              width: "auto",
            },
            {
              text: StringUtils.formatDate(entry.date),
              // style: "fieldLabel",
              fontSize: bigSize ? 12 : 8,
              color: "#000",
              width: "auto",
            },
          ],
        });
      };
      renderValueEntry(assetValue.current);
      (assetValue.history || [])
        .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)))
        .forEach((entry) => renderValueEntry(entry));

      columns.push(stack);
    }
    content.push({
      columns: columns,
      columnGap: 20,
    });

    return content;
  }

  async loadImages(assets: OAObject[], maxImageCount?: number) {
    const imageBaseBase64: string[] = [];
    let index = 0;
    for (const asset of assets) {
      const images = asset.data.images.filter((e) => e.status !== "archived");

      for (const image of images) {
        if (isNotDefined(maxImageCount) || index < maxImageCount) {
          const imageBase64 = await this.loadImage(
            asset,
            image.linkToCdn,
            index === 0 ? "md" : "sm"
          );
          imageBaseBase64.push(imageBase64);
        }
        index++;
      }
    }

    return imageBaseBase64;
  }

  async loadImage(asset: OAObject, cdnId: string, size = "xs") {
    const cdnObj = asset.cdn.find((e) => e._id === cdnId);
    const url = await CDNService.fetchCDNLink({
      assetField: "data.images",
      assetId: asset._id,
      cdnId: cdnId,
      assetType: AssetTypes.Portfolio.Object,
      fileKey: cdnObj.key,
      hasFolderReadPermissions: true,
    });

    const imageBase64 = await FileUtils.getBase64ImageFromUrl(
      url.trim() + "&d=" + size
    );

    return imageBase64;
  }
}
const ObjectExportService = new ObjectExportServiceClass();
export default ObjectExportService;

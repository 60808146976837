import { AccountingData } from "@/apps/tatar/accounting/AccountingLoader";
import { AccountingAccount } from "@/apps/tatar/accounting/interfaces/account.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import Log from "@/debug/Log";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact } from "@/model/db/Contact";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import PDFExporter from "@/modules/pdf-export/PDFExporter";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import DataBusDefaults from "@/services/DataBusDefaults";
import OverwriteService from "@/services/OverwriteService";
import ArrayUtils from "@/utils/ArrayUtils";
import FileUtils from "@/utils/FileUtils";
import { hasValue, isDefined } from "@/utils/Helpers";
import { HTTP } from "@/utils/Http";
import MQ from "@/utils/MatchQueryUtils";
import StringUtils from "@/utils/StringUtils";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import {
  Content,
  ContentTable,
  ContentText,
  TableCell,
  TDocumentDefinitions,
} from "pdfmake/interfaces";
import { Field } from "react-final-form";
import { RentalAgreement } from "../../../TenantsInterfaces";
import { DebitPositionResult } from "./CBRentalAgreementDebitPositions";
// import "./CBRentalAgreementDebitPositionsExport.scss";

export const openCBRentalAgreementDebitPositionsExport = (
  rentalAgreement: RentalAgreement,
  accountingData: AccountingData
) => {
  ModalManager.show({
    size: "fluid",
    noPadding: true,
    content: (state, setState, close) => (
      <AssetLoader
        assetType={AssetTypes.Accounting.Account}
        query={MQ.and(
          MQ.eq("data.entity", accountingData.accounting.data.entity),
          MQ.eq("data.linkedAsset.assetId", rentalAgreement._id)
        )}
        render={(account: AccountingAccount) => (
          <AssetLoader
            assetType={AssetTypes.Contact}
            id={rentalAgreement.data.tenant}
            render={(tenant: Contact) => (
              <CBRentalAgreementDebitPositionsExport
                onClose={close}
                onExport={async () => {
                  Log.info("exporting");
                }}
                rentalAgreement={rentalAgreement}
                tenant={tenant}
                accountingData={accountingData}
                account={account}
              />
            )}
          />
        )}
      />
    ),
  });
};

interface CBRentalAgreementDebitPositionsExportProps {
  onClose: () => void;
  onExport: () => Promise<void>;

  rentalAgreement: RentalAgreement;
  tenant: Contact;
  account: AccountingAccount;
  accountingData: AccountingData;
}
type FormValue = {
  fromDate?: Date;
  toDate: Date;
};
const CBRentalAgreementDebitPositionsExport = (
  props: CBRentalAgreementDebitPositionsExportProps
) => {
  return (
    <FormStruct<any>
      className={`cb-rental-agreement-debit-positions-export`}
      onAbort={props.onClose}
      title={i18n.t(
        "cb:RentalAgreementDebitPositionsExport.title",
        "Mieterkonto exportieren"
      )}
      description={i18n.t(
        "cb:RentalAgreementDebitPositionsExport.description",
        "Geben Sie den Zeitraum an, für den Sie den Mieterkontoauszug erstellen möchten."
      )}
      submitText={i18n.t(
        "cb:RentalAgreementDebitPositionsExport.submit",
        "Exportieren"
      )}
      onSubmit={async (values: FormValue) => {
        await exportAsPdf(
          props.rentalAgreement,
          props.tenant,
          {
            from: values.fromDate,
            to: values.toDate,
          },
          props.accountingData,
          props.account
        );
        DataBusDefaults.toast({
          type: "success",
          text: i18n.t(
            "cb:RentalAgreementDebitPositionsExport.exportedSuccess",
            "Mieterkonto erfolgreich exportiert"
          ),
        });

        props.onClose();
      }}
      initialValues={{
        toDate: new Date(),
      }}
      render={(formProps) => (
        <>
          <div className={`__flex`}>
            <div className={`__field`}>
              <FormFieldValues names={["toDate"]}>
                {([toDate]) => (
                  <Field name="fromDate" validate={FV.dateBefore(toDate)}>
                    {({ input, meta }) => (
                      <BFDatefield
                        {...input}
                        {...FV.getValidation(meta)}
                        label={i18n.t(
                          "cb:RentalAgreementDebitPositionsExport.fromDate",
                          "Ab Datum"
                        )}
                      />
                    )}
                  </Field>
                )}
              </FormFieldValues>
            </div>
            <div className={`__field`}>
              <Field name="toDate" validate={FV.compose(FV.required())}>
                {({ input, meta }) => (
                  <BFDatefield
                    {...input}
                    {...FV.getValidation(meta)}
                    label={
                      i18n.t(
                        "cb:RentalAgreementDebitPositionsExport.toDate",
                        "Bis Datum"
                      ) + "*"
                    }
                    preventNull
                  />
                )}
              </Field>
            </div>
          </div>
        </>
      )}
    />
  );
};

export default CBRentalAgreementDebitPositionsExport;

const exportAsPdf = async (
  rentalAgreement: RentalAgreement,
  tenant: Contact,
  timespan: { from?: Date; to: Date },
  accountingData: AccountingData,
  account: AccountingAccount
) => {
  const debitPositions: DebitPositionResult[] = await HTTP.get({
    target: "EMPTY",
    url: `/api/accounting/${accountingData.accounting.data.entity}/${account._id}/getDebitPositions`,
  });

  const debitPositionsTransformed: ({
    saldo: number;
  } & DebitPositionResult)[] = ArrayUtils.sortData(debitPositions, [
    {
      dir: "asc",
      key: "data.date",
    },
    {
      dir: "asc",
      key: "_id",
    },
  ])
    .reduce((prev, current, index) => {
      // ...pos,
      // saldo:

      return [
        ...prev,
        {
          ...current,
          saldo:
            (prev[index - 1]?.saldo || 0) -
            current.data.sum.soll +
            current.data.sum.haben,
        },
      ];
    }, [])
    .reverse()
    .filter((pos) => {
      if (hasValue(timespan.from)) {
        return moment(pos.data.date).isBetween(
          moment(timespan.from),
          moment(timespan.to)
        );
      } else {
        return moment(pos.data.date).isBefore(moment(timespan.to));
      }
    });

  const mainAddress = tenant.data.address.find((a) => a.isMain);
  const object = OrgaStruct.getObject(rentalAgreement.data.objectId);

  const content: Content[] = [
    {
      alignment: "justify",
      columns: [
        {
          stack: [
            {
              text: tenant.data.displayName,
              fontSize: 10,
            },
            {
              text: mainAddress.street,
              fontSize: 10,
            },
            {
              text: `${mainAddress.zip} ${mainAddress.city}`,
              fontSize: 10,
            },
          ],
        },
        {
          width: "*",
          text: "",
        },
        {
          stack: [
            {
              text:
                i18n.t(
                  "cb:RentalAgreementDebitPositionsExport.objectNumber",
                  "Objekt-Nummer"
                ) +
                ": " +
                object.id,
              fontSize: 10,
            },
            {
              text:
                i18n.t(
                  "cb:RentalAgreementDebitPositionsExport.objectName",
                  "Objekt"
                ) +
                ": " +
                object.displayName,
              fontSize: 10,
            },
            {
              text:
                i18n.t(
                  "cb:RentalAgreementDebitPositionsExport.rentalAgreementId",
                  "Mieternummer"
                ) +
                ": " +
                rentalAgreement.data.id,
              fontSize: 10,
            },
            {
              text:
                i18n.t(
                  "cb:RentalAgreementDebitPositionsExport.moveIn",
                  "Einzug"
                ) +
                ": " +
                StringUtils.formatDate(rentalAgreement.data.moveIn),
              fontSize: 10,
            },
          ],
        },
      ],
    },
    {
      margin: [0, 20, 0, 0],
      layout: "headerTable", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "auto"],

        body: [
          [
            {
              text: i18n.t(
                "cb:RentalAgreementDebitPositionsExport.Overview",
                "Kontoübersicht"
              ),
              fontSize: 16,
            },
            {
              text:
                hasValue(timespan.from) && hasValue(timespan.to)
                  ? `${i18n.t(
                      "cb:RentalAgreementDebitPositionsExport.from",
                      "Von"
                    )} ${StringUtils.formatDate(timespan.from)} ${i18n.t(
                      "cb:RentalAgreementDebitPositionsExport.untilSmall",
                      "bis"
                    )} ${StringUtils.formatDate(timespan.to)}`
                  : `${i18n.t(
                      "cb:RentalAgreementDebitPositionsExport.untilBig",
                      "Bis"
                    )} ${StringUtils.formatDate(timespan.to)}`,
              fontSize: 10,
              margin: [0, 4, 0, 0],
            },
          ],
        ],
      },
    },
  ];

  if (debitPositionsTransformed.length > 0) {
    const rows: TableCell[][] = [];

    const headerRow: TableCell[] = [
      {
        text: i18n.t("cb:RentalAgreementDebitPositionsExport.date", "Datum"),
        style: "tableHeader",
      },
      {
        text: i18n.t("cb:RentalAgreementDebitPositionsExport.due", "Fällig am"),
        style: "tableHeader",
      },
      {
        text: i18n.t("cb:RentalAgreementDebitPositionsExport.text", "Text"),
        style: "tableHeader",
      },
      {
        text: i18n.t(
          "cb:RentalAgreementDebitPositionsExport.demand",
          "Forderung"
        ),
        alignment: "right",
        style: "tableHeader",
      },
      {
        text: i18n.t(
          "cb:RentalAgreementDebitPositionsExport.payment",
          "Zahlung"
        ),
        alignment: "right",
        style: "tableHeader",
      },
      {
        text: i18n.t(
          "cb:RentalAgreementDebitPositionsExport.saldo",
          "laufender Saldo"
        ),
        alignment: "right",
        style: "tableHeader",
      },
    ];
    rows.push(headerRow);

    debitPositionsTransformed.forEach((pos) => {
      const row: TableCell[] = [
        {
          text: StringUtils.formatDate(pos.data.date),
          style: "tableValue",
        },
        {
          text: StringUtils.formatDate(pos.data.date),
          style: "tableValue",
        },
        {
          text: pos.data.displayName,
          style: "tableValue",
        },
        {
          alignment: "right",
          text:
            pos.data.sum.soll !== 0
              ? StringUtils.formatCurrency(pos.data.sum.soll)
              : "",
          style: "tableValue",
        },
        {
          alignment: "right",
          text:
            pos.data.sum.haben !== 0
              ? StringUtils.formatCurrency(pos.data.sum.haben)
              : "",
          style: "tableValue",
        },
        {
          alignment: "right",
          text: StringUtils.formatCurrency(pos.saldo),
          style: "tableValue",
        },
      ];
      rows.push(row);
    });

    const table = {
      table: {
        headerRows: 1,
        body: rows,
        widths: ["auto", "auto", "*", "auto", "auto", "auto"],
      },
      margin: [0, 20, 0, 0],
      layout: "coolTable",
    } as ContentTable;
    content.push(table);
  } else {
    content.push({
      text: i18n.t(
        "cb:RentalAgreementDebitPositionsExport.noEntries",
        "Keine Einträge vorhanden"
      ),
      fontSize: 12,
      margin: [0, 20, 0, 0],
      color: "#666",
    } as ContentText);
  }

  pdfMake.tableLayouts = {
    headerTable: {
      hLineWidth: function (i, node) {
        return 3;
      },
      vLineWidth: function (i) {
        return 0;
      },
      hLineColor: function (i) {
        return "#aaa";
      },
      paddingLeft: function (i) {
        return 0;
      },
      paddingRight: function (i, node) {
        return 0;
      },
    },
    coolTable: {
      hLineWidth: function (i, node) {
        if (i === 0 || i === node.table.body.length) {
          return 0;
        }
        return i === node.table.headerRows ? 2 : 1;
      },
      vLineWidth: function (i) {
        return 0;
      },
      hLineColor: function (i) {
        return i === 1 ? "#333" : "#eee";
      },
      paddingLeft: function (i) {
        return i === 0 ? 0 : 7;
      },
      paddingRight: function (i, node) {
        return i === node.table.widths.length - 1 ? 0 : 7;
      },
    },
  };

  const headerImageUrl = OverwriteService.getConfig(
    "PDFExport.asset.headerUrl",
    {
      defaultValue: null,
    }
  );

  const template: TDocumentDefinitions = {
    content,
    defaultStyle: {
      font: "Roboto",
    },
    images: {
      ...(isDefined(headerImageUrl)
        ? {
            headerImage: await await FileUtils.getBase64ImageFromUrl(
              headerImageUrl
            ),
          }
        : undefined),
    },
    footer: function (currentPage, pageCount) {
      return [
        {
          margin: [40, 10, 40, 0],
          columnGap: 10,
          columns: [
            // {
            //   alignment: "left",
            //   width: "*",
            //   text: `${title}`,
            //   style: "footerPageCount",
            // },
            {
              alignment: "center",
              text: StringUtils.formatDate(new Date()),
              style: "footerPageCount",
              fontSize: 8,
            },
            {
              alignment: "right",
              text: currentPage.toString() + " / " + pageCount,
              style: "footerPageCount",
              fontSize: 8,
            },
          ],
        },
      ];
    },
    header: function (currentPage, pageCount, pageSize) {
      // you can apply any logic and return any valid pdfmake element
      if (headerImageUrl) {
        return [
          {
            image: "headerImage",
            fit: [200, 16],
            marginTop: 8,
            alignment: "center",
          },
        ];
      } else {
        return [];
      }
    },

    styles: {
      tableHeader: {
        fontSize: 9,
        color: "#888",
      },
      tableValue: {
        fontSize: 9,
      },
    },
  };
  await PDFExporter.exportPdf("export.pdf", template);
};

import classNames from "classnames";
import moment from "moment";
import i18n from "../../i18n";
import { PlacementAll } from "../../modules/abstract-ui/common/Placements";
import BFUseTooltip from "../../modules/abstract-ui/general/tooltip/BFUseTooltip";

interface TimestampProps {
  date: Date;
  placement?: PlacementAll;
  addDateBehind?: boolean;
}
const Timestamp = (props: TimestampProps) => {
  return (
    <BFUseTooltip
      placement={props.placement}
      tooltip={moment(props.date).format(i18n.t("Formats.dateTimeFormat"))}
    >
      <span className={classNames(`timestamp`)}>
        {moment(props.date).fromNow()}
        {props.addDateBehind && (
          <span>
            {" "}
            ({moment(props.date).format(i18n.t("Formats.dateTimeFormat"))})
          </span>
        )}
      </span>
    </BFUseTooltip>
  );
};

export default Timestamp;

import classNames from "classnames";
import moment from "moment";
import { Fragment } from "react";
import AssignmentLabel from "../../../../components/AssignmentLabel/AssignmentLabel";
import i18n from "../../../../i18n";
import { Resubmission } from "../../RSInterfaces";
import RSService from "../../RSService";
import RSDateTag from "../date-tag/RSDateTag";
import RSPriorityTag from "../priority-tag/RSPriorityTag";
import "./RSListEntry.scss";

interface RSListEntryProps {
  resubmission: Resubmission;
}

const RSListEntry = (props: RSListEntryProps) => {
  const tagDueStatus = RSService.getDueStatus(props.resubmission.data.dueDate);
  const tagDueLabel = RSService.formatDueDate(props.resubmission.data.dueDate);

  const isDone = props.resubmission.data.status === "done";

  return (
    <div className={classNames("rs-list-entry")}>
      <div className="rs-list-entry-container">
        <div className="rs-text-column">
          <div className="rs-title-row">{props.resubmission.data.title}</div>
          <div className="rs-detail-row">
            {props.resubmission.data.description}
          </div>
        </div>
        <div className="rs-data-column">
          {!isDone && (
            <Fragment>
              <RSDateTag status={tagDueStatus} text={tagDueLabel} />
              <RSPriorityTag resubmission={props.resubmission} />
              <AssignmentLabel
                users={props.resubmission.data.assignees.users}
                teams={props.resubmission.data.assignees.teams}
              />
            </Fragment>
          )}
          {isDone && (
            <Fragment>
              <div className="rs-done-container">
                <div className="rs-done-row">
                  {i18n.t("Resubmission.Label.DoneAt", "Erledigt am")}
                  {` ${moment(props.resubmission.data.doneAt).format(
                    i18n.t("Formats.dateTimeFormat")
                  )}`}
                </div>
                <div className="rs-done-row">
                  <AssignmentLabel
                    users={[props.resubmission.data.doneBy]}
                    teams={[]}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default RSListEntry;

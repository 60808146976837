import i18n from "@/i18n";
import DataBus from "@/services/DataBus";
import { hasValue } from "@/utils/Helpers";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useState } from "react";
import BFButton from "../../general/Button/BFButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import BFOverlay from "../../general/whisper/BFOverlay";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import LabeledInput from "../LabeledInput";
import BFChooser, { BFChooserOption } from "./BFChooser";
import "./BFChooserMultipleSelect.scss";

interface BFChooserSelectProps {
  value: (string | number | Date)[];
  onChange: (value: (string | number | Date)[]) => void;
  data: BFChooserOption<string | number | Date>[];
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
  labelPosition?: "top" | "left";
  info?: React.ReactNode;
  label?: string;
  labelSuffix?: React.ReactNode;
  placeholder?: string;
  hideSubLabel?: boolean;
  overlayTopComponent?: (close: () => void) => React.ReactNode;
  overlayBottomComponent?: (close: () => void) => React.ReactNode;
  overlayClassName?: string;
  disabled?: boolean;
  groupSort?: (a: string, b: string) => number;
  hideSearch?: boolean;
  cleanable?: boolean;
}
const BFChooserMultipleSelect = (props: BFChooserSelectProps) => {
  const [id] = useState(nanoid());
  const selectedValues =
    (props.value || []).map((e) => props.data.find((f) => f.value === e)) || [];
  return (
    <div className={classNames(`bf-chooser-multiple-select`)}>
      <LabeledInput
        label={props.label}
        info={props.info}
        labelPosition={props.labelPosition}
        suffix={props.labelSuffix}
        error={!!props.validation?.message}
      >
        <ValidationPopover
          validatorStyle={props.validatorStyle}
          level={props.validation ? props.validation.level : "error"}
          message={props.validation ? props.validation.message : null}
          marginTop={0}
        >
          <BFOverlay
            identifier={id}
            trigger="click"
            placement="bottomStart"
            speaker={
              <div
                className={`bf-chooser-select-overlay ${
                  props.overlayClassName || ""
                }`}
              >
                {props.cleanable && hasValue(props.value) && (
                  <div className="cleanable-action">
                    <BFButton
                      size="xs"
                      appearance="link"
                      onClick={() => {
                        props.onChange([]);
                        DataBus.emit("WHISPER", {
                          identifier: id,
                          type: "CLOSE",
                        });
                      }}
                      noPadding
                    >
                      {i18n.t("Global.Buttons.CleanValue", "Auswahl entfernen")}
                    </BFButton>
                  </div>
                )}
                {props.overlayTopComponent?.(() => {
                  DataBus.emit("WHISPER", {
                    identifier: id,
                    type: "CLOSE",
                  });
                })}
                <BFChooser
                  hideSearch={props.hideSearch}
                  groupSort={props.groupSort}
                  focusOnMount
                  maxHeight={"60vh"}
                  onChange={(value) => {
                    if ((props.value || []).includes(value)) {
                      // remove it
                      props.onChange(
                        (props.value || []).filter((e) => e !== value)
                      );
                    } else {
                      // add it
                      props.onChange([...(props.value || []), value]);
                    }
                  }}
                  options={props.data.map((e) => ({
                    ...e,
                    bold: (props.value || []).includes(e.value),
                  }))}
                />
                {props.overlayBottomComponent?.(() => {
                  DataBus.emit("WHISPER", {
                    identifier: id,
                    type: "CLOSE",
                  });
                })}
              </div>
            }
          >
            <div>
              <BFButton
                disabled={props.disabled}
                className={`bf-chooser-select-button`}
              >
                <div
                  className={classNames({
                    "select-value": true,
                    placeholder: !props.value,
                  })}
                >
                  {selectedValues.length > 0 && (
                    <div className={`values`}>
                      {selectedValues.map((selectedValue) => (
                        <div className={`value-entry`}>
                          {!props.hideSubLabel && (
                            <>
                              {selectedValue.subLabel && (
                                <span className={`sub-label`}>
                                  {selectedValue.subLabel}
                                </span>
                              )}
                            </>
                          )}
                          {selectedValue.label}
                        </div>
                      ))}
                    </div>
                  )}
                  {selectedValues.length === 0 && (props.placeholder || "-")}
                </div>
                <BfIcon size="xxs" {...DefaultIcons.CHEVRON} />
              </BFButton>
            </div>
          </BFOverlay>
        </ValidationPopover>
      </LabeledInput>
    </div>
  );
};

export default BFChooserMultipleSelect;

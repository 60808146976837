import { useDispatch } from "react-redux";
import InfiniteTable from "../../../redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "../../../redux/hooks";
import BaseTableSort, {
  InfiniteTableSortDef,
  InfiniteTableSortOption,
} from "./BaseTableSort";

type InfiniteTableSortProps = {
  options: InfiniteTableSortOption[];
  identifier: string;
};

const InfiniteTableSort = (props: InfiniteTableSortProps) => {
  const dispatch = useDispatch();

  const sort =
    useTypedSelector(
      (state) => state.application.infiniteTables[props.identifier]?.visibleSort
    ) || props.options[0].definition;

  const selected = props.options.find((e) => {
    const definition = e.definition as InfiniteTableSortDef;
    return definition.key === sort.key && e.definition.dir === sort.dir;
  });

  const onSelectSort = (option: InfiniteTableSortDef) => {
    const infiniteOption = option;
    dispatch(InfiniteTable.setVisibleSort(props.identifier, infiniteOption));
  };

  return (
    <BaseTableSort
      onSelect={onSelectSort}
      identifier={props.identifier}
      options={props.options}
      selected={selected}
    />
  );
};

export default InfiniteTableSort;

import TeamLoader from "../AssetLoader/TeamLoader";

interface UsernameProps {
  id: string;
}
const Teamname = (props: UsernameProps) => {
  return (
    <TeamLoader
      id={props.id}
      render={(data) => <span>{data.displayname}</span>}
      placeholderProps={{ width: 50 }}
    />
  );
};

export default Teamname;

import classNames from "classnames";
import i18n from "../../../../i18n";
import BFValueDisplay from "../../../../modules/abstract-ui/data/value-display/BFValueDisplay";
import BFOverlay from "../../../../modules/abstract-ui/general/whisper/BFOverlay";
import StringUtils from "../../../../utils/StringUtils";
import { InvoicePaymentTypeTags } from "../../../AppConfigInterfaces";
import { PaymentData } from "../RAInterfaces";
import "./RAInvoicePaymentLabel.scss";

interface RAInvoicePaymentLabelProps {
  paymentData: PaymentData;
}
const RAInvoicePaymentLabel = (props: RAInvoicePaymentLabelProps) => {
  const hasPayment = props.paymentData.type !== "NONE";
  const node = (
    <div className={classNames(`ra-invoice-payment-label`)}>
      {getLabelOf(props.paymentData)}
    </div>
  );
  return hasPayment ? (
    <BFOverlay
      enterable
      speaker={
        <div>
          <PaymentOverlayData paymentData={props.paymentData} />
        </div>
      }
    >
      {node}
    </BFOverlay>
  ) : (
    node
  );
};

export const PaymentOverlayData = (props: { paymentData: PaymentData }) => {
  const paymentData = props.paymentData;
  if (paymentData.type === "PAYMENT_IBAN") {
    return (
      <div className={`payment-label-overlay`}>
        <BFValueDisplay
          label={i18n.t("ra:PaymentReleaseList.iban", "IBAN")}
          value={paymentData.iban}
          formatter={(iban: string) => StringUtils.formatIban(iban)}
        />
        <BFValueDisplay
          label={i18n.t("ra:PaymentReleaseList.paymentDueDate", "Zahlbar bis")}
          value={paymentData.paymentDueDate}
          formatter={(date: string) => StringUtils.formatDate(date)}
        />
        <BFValueDisplay
          label={i18n.t("ra:PaymentReleaseList.usage", "Verwendungszweck")}
          value={paymentData.usage}
        />
      </div>
    );
  }
  if (
    paymentData.type === InvoicePaymentTypeTags.PAYMENT_US_NATIONAL ||
    paymentData.type === InvoicePaymentTypeTags.PAYMENT_US_INTERNATIONAL
  ) {
    return (
      <div className={`payment-label-overlay`}>
        <BFValueDisplay
          label={i18n.t("ra:PaymentReleaseList.account", "Kontonummer")}
          value={paymentData.account}
        />
        <BFValueDisplay
          label={i18n.t("ra:PaymentReleaseList.paymentDueDate", "Zahlbar bis")}
          value={paymentData.paymentDueDate}
          formatter={(date: string) => StringUtils.formatDate(date)}
        />
        <BFValueDisplay
          label={i18n.t("ra:PaymentReleaseList.routing", "Routing")}
          value={paymentData.routing}
        />
        <BFValueDisplay
          label={i18n.t("ra:PaymentReleaseList.swift", "SWIFT")}
          value={paymentData.swift}
        />
        <BFValueDisplay
          label={i18n.t("ra:PaymentReleaseList.usage", "Verwendungszweck")}
          value={paymentData.usage}
        />
      </div>
    );
  }
};

const getLabelOf = (paymentData: PaymentData) => {
  if (paymentData.type === "PAYMENT_IBAN") {
    return (
      <>
        <span className={`type-of-payment`}>
          {i18n.t("ra:PaymentReleaseList.iban", "IBAN")}
        </span>
        <span>{StringUtils.formatIban(paymentData.iban)}</span>
      </>
    );
  }
  if (
    paymentData.type === InvoicePaymentTypeTags.PAYMENT_US_NATIONAL ||
    paymentData.type === InvoicePaymentTypeTags.PAYMENT_US_INTERNATIONAL
  ) {
    return (
      <>
        <span className={`type-of-payment`}>
          {i18n.t("ra:PaymentReleaseList.account", "Konto")}
        </span>
        <span>{StringUtils.formatIban(paymentData.account)}</span>
      </>
    );
  }
  if (paymentData.type === "NONE") {
    return "-";
  }
};

export default RAInvoicePaymentLabel;

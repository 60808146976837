import classNames from "classnames";
import BfIcon from "../../../../../../modules/abstract-ui/icon/BfIcon";
import { SuggestionHint } from "../CBIdlyBookingsConst";
import "./CBidlyConnectionSuggestionHint.scss";

const getSuggestionHintIcon = (suggestionHint: SuggestionHint) => {
  if (suggestionHint.level === "fully") {
    return "check-circle-1";
  }
  if (suggestionHint.level === "almostFully") {
    return "check-circle-1";
  }
  if (suggestionHint.level === "partially") {
    return "alert-circle";
  }
  return "remove-circle";
};

interface CBidlyConnectionSuggestionHintProps {
  hints: SuggestionHint[];
}
const CBidlyConnectionSuggestionHint = (
  props: CBidlyConnectionSuggestionHintProps
) => {
  return (
    <div className={classNames(`cb-idly-connection-suggestion-hints`)}>
      {props.hints?.map((hint, index) => (
        <div className={classNames(`suggestion-hint`, hint.level)} key={index}>
          <BfIcon type="light" data={getSuggestionHintIcon(hint)} size="xs" />
          <span>{hint.text}</span>
        </div>
      ))}
    </div>
  );
};

export default CBidlyConnectionSuggestionHint;

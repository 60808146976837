import { MatchQuery } from "../../../../../services/DataService";
import { BfIconProps } from "../../../icon/BfIcon";
import { FilterOptionWithMatchQueryConverter } from "../BFTableFilterModel";
import OverlayBoolean, {
  BooleanValue,
  generateBooleanLabel,
} from "../overlays/OverlayBoolean";

export const BooleanFilterOption: (opts: {
  field: string;
  label: string;
  icon?: BfIconProps;
  matchQueryConverter?: (value: any) => MatchQuery | null;
  defaultValue: boolean;
}) => FilterOptionWithMatchQueryConverter = (opts) => {
  const { field, label, icon } = opts;
  const matchQueryConverter =
    opts.matchQueryConverter ||
    ((value: any) =>
      value?.show === true
        ? {
            type: "op",
            op: "nin",
            name: field,
            value: [null, false],
          }
        : value?.show === false
        ? {
            type: "op",
            op: "in",
            name: field,
            value: [null, false],
          }
        : null);
  return {
    matchQueryConverter: matchQueryConverter,
    key: field,
    label: label,
    icon: {
      type: icon?.type || "light",
      data: icon?.data || "condition",
    },
    renderOverlay: (value, onChange) => (
      <OverlayBoolean title={label} value={value} onChange={onChange} />
    ),
    renderValueData: (value: BooleanValue) =>
      generateBooleanLabel(label, value),
    getOptions: (currentValue, searchTerm) => [],
    defaultValue: {
      show: opts.defaultValue,
    },
  };
};

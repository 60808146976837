import moment from "moment";
import React from "react";
import KPIDetailCard from "../../../../../../../../components/KPIDetailCard/KPIDetailCard";
import i18n from "../../../../../../../../i18n";
import BFButtonToggle from "../../../../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import {
  StatisticResult,
  useConstants,
} from "../../../../../../../../redux/hooks";
import { CBStatisticImmoVacancyDistribution } from "../../../interfaces/CBStatisticQueries";
import CBRentalStatusChart from "./CBRentalStatusChart";
import "./CBRenterDistributionCard.scss";

interface VacancyDistributionCardProps {
  objectIds: string[];
  marginBottom?: number;
  referenceId?: string;
}
const CBRenterDistributionCard = (props: VacancyDistributionCardProps) => {
  const constants = useConstants();
  const [yearly, setYearly] = React.useState(false);

  return (
    <KPIDetailCard
      marginBottom={props.marginBottom}
      className="cb-renter-distribution-card"
      title={i18n.t("cb:Renter.rentalStatus", "Mieteinnahmen SOLL/IST ")}
      headRight={
        <BFButtonToggle
          buttons={[
            {
              text: i18n.t("cb:Renter.ShowMonthly", "Monatlich"),
              value: false,
            },
            {
              text: i18n.t("cb:Renter.ShowYearly", "Jährlich"),
              value: true,
            },
          ]}
          value={yearly}
          onChange={(val: boolean) => setYearly(val)}
        />
      }
      childOptions={{
        renderWhileLoading: true,
        render: (
          queryData: StatisticResult<CBStatisticImmoVacancyDistribution>
        ) => (
          <div className="charts">
            <CBRentalStatusChart
              month={moment().startOf("day").utc(true)}
              objectIds={props.objectIds}
              referenceId={props.referenceId}
              className="chart-entry full"
              yearly={yearly}
            />
          </div>
        ),
      }}
    />
  );
};
export default CBRenterDistributionCard;

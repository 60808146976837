import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import ModalManager from "@/components/ModalComponent/ModalManager";
import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import StringUtils from "@/utils/StringUtils";
import _ from "lodash";
import { PaymentPosition, RentalAgreement } from "../../../TenantsInterfaces";
import CBRentalAgreementRentChange from "../CBRentalAgreementRentChange";
// import "./CBAgreementPaymentInfo.scss";

export type PaymentPositionType = {
  taxable: "taxable" | "notTaxable";
  rentPaymentTimingOptions: "startOfMonth" | "endOfMonth" | "middleOfMonth";
  paymentPositions: PaymentPosition[];
};
interface CBAgreementPaymentInfoProps {
  marks: PDFEditRect[] | PDFDocumentRect[];
  data: PaymentPositionType;
  objectId: string;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
  kind: ObjectKind;
}
const CBAgreementPaymentInfo = (props: CBAgreementPaymentInfoProps) => {
  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={props.marks}
      title={
        <>
          <span className={`padding-right-6`}>
            {i18n.t(
              "cb:RentalAgreement.Form.SectionTitle.paymentInfo.title",
              "Zahldaten"
            )}
          </span>
          {props.rentalAgreement && props.editable && (
            <BFButton
              noPadding
              inline
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  size: "fluid",
                  noPadding: true,
                  content: (state, setState, close) => {
                    return (
                      <CBRentalAgreementRentChange
                        onClose={close}
                        kind={props.kind}
                        rentalAgreementId={props.rentalAgreement._id}
                      />
                    );
                  },
                });
              }}
              size="sm"
            >
              {i18n.t("Global.Buttons.Customize", "Anpassen")}
            </BFButton>
          )}
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.paymentInfo.viewHint",
        "Informationen zur Miete"
      )}
      identifier="paymentInfo"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      <div className={`__flex-wrap`}>
        <PaymentPositionsData
          objectId={props.objectId}
          paymentPositions={props.data.paymentPositions}
          taxable={props.data.taxable === "taxable"}
        />
      </div>
    </BFPDFMarkerSection>
  );
};

export default CBAgreementPaymentInfo;

export const PaymentPositionsData = (props: {
  objectId: string;
  paymentPositions: PaymentPosition[];
  taxable: boolean;
}) => {
  return (
    <AssetLoader
      assetType={AssetTypes.Portfolio.Object}
      id={props.objectId}
      render={(object: OAObject) => {
        const debitPositionsRent =
          object.data.feature.immo.accounting.debitposition
            .filter((e) => e.kind === "rent")
            .map((e) => e.id);
        const debitPositionsOperatingCosts =
          object.data.feature.immo.accounting.debitposition
            .filter(
              (e) =>
                e.kind === "operatingCost" ||
                e.kind === "operatingCostAllInclusive"
            )
            .map((e) => e.id);

        const debitPositionsRentSum = [
          ...debitPositionsRent,
          ...debitPositionsOperatingCosts,
        ];
        return (
          <>
            <BFValueDisplay
              label={i18n.t(
                "cb:RentalAgreement.Form.Fields.rentNet",
                "Miete Netto"
              )}
              value={props.paymentPositions
                .filter((e) => debitPositionsRent.includes(e.id))
                .map((e) => e.net || 0)}
              formatter={(value) => StringUtils.formatCurrency(_.sum(value))}
            />
            {props.taxable && (
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.rentGross",
                  "Miete Brutto"
                )}
                value={props.paymentPositions
                  .filter((e) => debitPositionsRent.includes(e.id))
                  .map((e) => e.gross || 0)}
                formatter={(value) => StringUtils.formatCurrency(_.sum(value))}
              />
            )}
            <BFValueDisplay
              label={i18n.t(
                "cb:RentalAgreement.Form.Fields.operatingCostNet",
                "Nebenkosten Netto"
              )}
              value={props.paymentPositions
                .filter((e) => debitPositionsOperatingCosts.includes(e.id))
                .map((e) => e.net || 0)}
              formatter={(value) => StringUtils.formatCurrency(_.sum(value))}
            />
            {props.taxable && (
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.operatingCostGross",
                  "Nebenkosten Brutto"
                )}
                value={props.paymentPositions
                  .filter((e) => debitPositionsOperatingCosts.includes(e.id))
                  .map((e) => e.gross || 0)}
                formatter={(value) => StringUtils.formatCurrency(_.sum(value))}
              />
            )}

            <BFValueDisplay
              label={i18n.t(
                "cb:RentalAgreement.Form.Fields.rentNetSum",
                "Gesamtmiete Netto"
              )}
              value={props.paymentPositions
                .filter((e) => debitPositionsRentSum.includes(e.id))
                .map((e) => e.net || 0)}
              formatter={(value) => StringUtils.formatCurrency(_.sum(value))}
            />
            {props.taxable && (
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.rentGrossSum",
                  "Gesamtmiete Brutto"
                )}
                value={props.paymentPositions
                  .filter((e) => debitPositionsRentSum.includes(e.id))
                  .map((e) => e.gross || 0)}
                formatter={(value) => StringUtils.formatCurrency(_.sum(value))}
              />
            )}
          </>
        );
      }}
    />
  );
};

import classNames from "classnames";
import { TaskAsset } from "../../model/general-assets/TaskAsset";
import AssignmentLabel from "../AssignmentLabel/AssignmentLabel";
import "./TaskRestrictions.scss";

interface TaskRestrictionsProps {
  task: TaskAsset;
  avatarSize?: number;
}
const TaskRestrictions = (props: TaskRestrictionsProps) => {
  return (
    <div className={classNames(`task-restrictions`)}>
      <AssignmentLabel
        users={props.task?.data.restrictTo?.users}
        teams={props.task?.data.restrictTo?.teams}
      />
    </div>
  );
};

export default TaskRestrictions;

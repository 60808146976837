import classNames from "classnames";
import { Resubmission } from "../../../../../../modules/resubmission/RSInterfaces";
import RSSubAssetMapper from "../../../../../../modules/resubmission/components/asset-details/sub-asset-mapper/RSSubAssetMapper";
import RSListDetailsComponent from "../../../../../../modules/resubmission/components/list-details/RSListDetailsComponent";
import { useActivityConstants } from "../../../ActivityHooks";
import { APActivity } from "../../../ActivityInterfaces";
import { getAssetLabel } from "../../../ActivityUtils";
import "./APActivityResubmissionView.scss";

interface APActivityResubmissionViewProps {
  activity: APActivity;
}

const APActivityResubmissionView = (props: APActivityResubmissionViewProps) => {
  const constants = useActivityConstants();

  const renderResubmissionObjectDetails = (resubmission: Resubmission) => {
    return (
      <RSSubAssetMapper resubmission={resubmission} asset={props.activity} />
    );
  };

  const assetType = constants?.assetType;
  const typePermissions = [props.activity.data.type];

  return (
    <div className={classNames("ap-activity-resubmission")}>
      <RSListDetailsComponent
        asset={props.activity}
        assetType={assetType}
        assetDescription={`${getAssetLabel(constants)}: ${
          props.activity.data.displayName
        }`}
        listIdentifier={`AP_RESUBMISSIONS_${props.activity._id}`}
        renderResubmissionAssetDetails={renderResubmissionObjectDetails}
        typePermissions={typePermissions}
      />
    </div>
  );
};

export default APActivityResubmissionView;

import { useDispatch } from "react-redux";
import i18n from "../../../../i18n";
import InfiniteTable from "../../../../redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "../../../../redux/hooks";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import BFInput from "./BFInput";

interface BFSearchProps {
  tableIdentifier: string;
  focusOnMount?: boolean;
  disabled?: boolean;
}
const BFSearch = (props: BFSearchProps) => {
  const dispatch = useDispatch();
  const value = useTypedSelector(
    (state) =>
      state.application.infiniteTables[props.tableIdentifier]?.searchTerm
  );

  return (
    <BFInput
      disabled={props.disabled}
      focusOnMount={props.focusOnMount}
      placeholder={i18n.t("Global.Labels.search", "Suchen")}
      prefix={<BfIcon {...DefaultIcons.SEARCH} size="xxs" />}
      value={value}
      onChange={(val: string) =>
        dispatch(InfiniteTable.setSearch(props.tableIdentifier, val))
      }
    />
  );
};

export default BFSearch;

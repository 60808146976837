import { HTTP } from "../utils/Http";

const TIME_BETWEEN_QUERYS = 2000;

export interface GeoResult {
  address: {
    country: string;
    country_code: string;
    county: string;
    postcode: string;
    road: string;
    state: string;
    suburb: string;
    town: string;
    house_number: string;
  };
  boundingbox: [string, string, string, string];
  lat: string;
  lon: string;
  type: string;
  importance: number;
  display_name: string;
  osm_id: string;
}

class GeoServiceClass {
  lastQueryTimestamp = 0;
  running = false;

  async query(query: string, limit = 10): Promise<GeoResult[]> {
    if (this.running === true) {
      return [];
    }
    try {
      this.running = true;
      const current = Number(new Date());
      const timeSinceLastQuery = current - this.lastQueryTimestamp;

      if (timeSinceLastQuery < TIME_BETWEEN_QUERYS) {
        await wait(TIME_BETWEEN_QUERYS - timeSinceLastQuery);
      }

      const response = (await HTTP.get({
        target: "EMPTY",
        url: `liquiplanservice/getLocationInfo`,
        queryParams: {
          param: {
            query,
            limit,
          },
        },
      })) as GeoResult[];

      this.running = false;
      return response;
    } catch (err) {
      this.running = false;
      return [];
    }
  }
}
const GeoService = new GeoServiceClass();
export default GeoService;

const wait = (wait: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(0);
    }, wait);
  });
};

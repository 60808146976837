import QRCode from "qrcode.react";
import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Loader } from "rsuite";
import { AppState } from "../../../redux/store";
import { HTTP } from "../../../utils/Http";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import "./RegisterMobileDeviceQRForm.scss";

interface QRToken {
  token: string;
  expire: Date;
  user: string;
  mandator: string;
  _id: string;
}
type Props = {
  onCancel: () => void;
  onFinish: () => void;
} & RouteComponentProps &
  WithTranslation;

type States = {
  code: QRToken;
  loading: boolean;
  error: string;
};

class RegisterMobileDeviceQRForm extends Component<Props, States> {
  static defaultProps = {};
  _mounted = true;
  readonly state: States = {
    code: null,
    loading: true,
    error: null,
  };

  componentDidMount() {
    this.requestQRCode();
  }
  getQRCode() {
    const {
      code: { mandator, token, user },
    } = this.state;
    // const useDomain = window.location.origin;
    const checksum = (token + user)
      .split("")
      .reduce(
        (prev, current, index) => index + current.charCodeAt(0) + prev,
        0
      );
    const code = `iberio;${mandator};${user};${token};${checksum}`;
    return code;
  }
  requestQRCode() {
    if (!this._mounted) {
      return;
    }
    this.setState({
      code: null,
      error: null,
      loading: true,
    });
    HTTP.post({
      url: "user/requestQrToken",
    })
      .then((response) => {
        const token = response as QRToken;

        this.setState({
          loading: false,
          error: "errored",
          code: token,
        });

        setTimeout(() => {
          this.requestQRCode();
        }, Number(new Date(token.expire)) - Number(new Date()) - 5000);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: "errored",
        });
      });
  }
  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return true;
  }

  render() {
    const { t, onCancel } = this.props;
    return (
      <div className="register-mobile-device-qr">
        <div className={`title`}>
          {t("UserModal.Devices.RegisterQRCode.Title")}
        </div>
        <div className={`description`}>
          {t("UserModal.Devices.RegisterQRCode.Description")}
        </div>
        <div className="qr-code">
          {this.state.loading ? (
            <div>
              <Loader
                size="md"
                content={t("UserModal.Devices.RegisterQRCode.LoadingText")}
              />
            </div>
          ) : (
            <QRCode value={this.getQRCode()} size={200} />
          )}
        </div>
        <div className="actions">
          <BFButton
            textKey={"Global.Buttons.cancel"}
            onClick={() => onCancel()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(RegisterMobileDeviceQRForm))) as any;

import classNames from "classnames";
import { Input, InputGroup } from "rsuite";
import i18n from "../../../i18n";
import { EmailUser } from "../Comments";
import "./EmailAddressFields.scss";
import MailContactTagInputSearch from "./contact-search/MailContactTagInputSearch";

export interface EmailAddressFieldModel {
  recipients: EmailUser[];
  cc?: EmailUser[];
  bcc?: EmailUser[];
  subject: string;
}
interface EmailAddressFieldsProps {
  value: EmailAddressFieldModel;
  onChange: (value: EmailAddressFieldModel) => void;
  type: string;
}
const EmailAddressFields = (props: EmailAddressFieldsProps) => {
  return (
    <div className={classNames(`email-address-fields`)}>
      <div className={`recipient`}>
        <EmailField
          value={props.value.recipients}
          onChange={(value) =>
            props.onChange({ ...props.value, recipients: value })
          }
          label={`${i18n.t(
            "CommentInput.EmailAddress.recipient",
            "Empfänger"
          )}*`}
          type={props.type}
        />
      </div>
      <div className={`cc`}>
        <EmailField
          value={props.value.cc}
          onChange={(value) => props.onChange({ ...props.value, cc: value })}
          label={i18n.t("CommentInput.EmailAddress.cc", "CC")}
          type={props.type}
        />
      </div>
      <div className={`bcc`}>
        <EmailField
          value={props.value.bcc}
          onChange={(value) => props.onChange({ ...props.value, bcc: value })}
          label={i18n.t("CommentInput.EmailAddress.bcc", "BCC")}
          type={props.type}
        />
      </div>
      <div className={`subject`}>
        <InputGroup inside>
          <InputGroup.Addon>
            <div className={`label`}>
              {`${i18n.t("CommentInput.EmailAddress.subject", "Betreff")}*`}
            </div>
          </InputGroup.Addon>
          <Input
            value={props.value.subject}
            onChange={(value) =>
              props.onChange({ ...props.value, subject: value })
            }
          />
        </InputGroup>
      </div>
    </div>
  );
};

export default EmailAddressFields;

interface EmailFieldProps {
  value: EmailUser[];
  onChange: (value: EmailUser[]) => void;
  label: string;
  type: string;
}
const EmailField = (props: EmailFieldProps) => {
  const emailUsers = props.value || [];
  const addMailAddress = (newMailAddress: EmailUser) => {
    props.onChange([...emailUsers, newMailAddress]);
  };

  const removeMailAddress = (removedMailAddress: string) => {
    props.onChange(
      emailUsers.filter((mail) => mail.address !== removedMailAddress)
    );
  };
  return (
    <div className={`email-field`}>
      <div className={`label`}>{props.label}</div>

      <div className={`field-input`}>
        <MailContactTagInputSearch
          mailContacts={emailUsers}
          onAddMailAddress={addMailAddress}
          onRemoveMailAddress={removeMailAddress}
          noBorder={true}
          type={props.type}
        />
      </div>
    </div>
  );
};

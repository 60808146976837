import validator from "validator";
export const REGEX_WEBSITE =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/i;
export const REGEX_DNS =
  /^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9\-]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/i;
class ValidatorsUtilsClass {
  isWebsiteString(toTest: string) {
    return REGEX_WEBSITE.test(toTest);
  }
  isEmail(toTest: string) {
    const emailValid = validator.isEmail(toTest, {
      allow_utf8_local_part: false,
      domain_specific_validation: true,
    });
    if (emailValid) {
      const dnsPart = toTest.split("@")[1];
      return REGEX_DNS.test(dnsPart);
    } else {
      return false;
    }
  }
}

const ValidatorsUtils = new ValidatorsUtilsClass();

export default ValidatorsUtils;

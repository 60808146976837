import { DEFAULT_RENTAL_UNITS_OVERVIEW_IDENTIFIER } from "@/apps/tatar/cashBudget/views/portfolio/CBPortfolioConst";
import { EnrichtedRentalUnit } from "@/apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import CBRentalUnitMultipleForm from "@/apps/tatar/cashBudget/views/tenants/components/rental-unit/CBRentalUnitMultipleForm";
import CBRentalUnitsOverviewTable from "@/apps/tatar/cashBudget/views/tenants/components/stacking-plan/CBRentalUnitsOverviewTable";
import ObjectStackingPlan from "@/apps/tatar/cashBudget/views/tenants/components/stacking-plan/ObjectStackingPlan";
import ModalManager from "@/components/ModalComponent/ModalManager";
import PersistentSplitPane from "@/configurable/data/SplitPane/PersistentSplitPane";
import VirtualizedTableExportButton from "@/configurable/data/VirtualizedTable/VirtualizedTableExportButton";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import { useHttpCache } from "@/redux/hooks";
import DataBusDefaults from "@/services/DataBusDefaults";
import GlobalActions from "@/services/GlobalActions";
import PermissionService from "@/services/PermissionService";
import React from "react";
import { OAObject } from "../../../types/object.interface";
import "./ObjectDetailStackingPlan.scss";

type PropsObject = {
  asset: OAObject;
};
type Props = PropsObject;

const ObjectDetailStackingPlan = (props: Props) => {
  const [selection, setSelection] = React.useState<string[]>([]);
  const data = useHttpCache<EnrichtedRentalUnit[]>(
    `object-stacking-plan-${props.asset?._id}`,
    `/rental/getStackingPlan`,
    "post",
    null,
    {
      objectIds: [props.asset?._id],
    }
  );

  const kind = ObjectKindStruct.getKind(props.asset.data.objectKindId);
  const chartId = React.useRef<string>(null);
  return (
    <div className="object-stackingplan __card">
      <PersistentSplitPane
        identifier="object-stackingplan"
        split="horizontal"
        defaultSize={"50%"}
        maxSize={-200}
        minSize={150}
        primary="first"
        allowResize
        onSizeChange={() => {
          if (chartId.current) {
            DataBusDefaults.chartResized(chartId.current);
          }
        }}
      >
        <div className={`plan`}>
          <ObjectStackingPlan
            markedUnits={selection.length > 0 ? selection : undefined}
            objectId={props.asset?._id}
            selectMode="single"
            onMounted={(ident, chart) => {
              chartId.current = ident;
            }}
            onSelect={(selected: EnrichtedRentalUnit) => {
              GlobalActions.openDetails(
                selected.data.rentalStatus === "vacant"
                  ? AssetTypes.Rental.RentalUnit
                  : AssetTypes.Rental.RentalAgreement,
                selected.data.rentalStatus === "vacant"
                  ? selected._id
                  : selected.data.agreement._id,
                selected.data.type
              );
            }}
          />
        </div>
        <div className={`entries`}>
          <div className={`actions`}>
            {!OrgaStruct.isImportedObject(props.asset._id) &&
              PermissionService.hasObjectKindPermission(
                kind,
                "rental.units.createEdit"
              ) && (
                <BFButton
                  appearance="link"
                  size="xs"
                  onClick={() => {
                    ModalManager.show({
                      backdrop: "static",
                      size: "xxl",
                      noPadding: true,
                      content: (state, setState, onClose) => (
                        <CBRentalUnitMultipleForm
                          objectId={props.asset?._id}
                          onClose={onClose}
                          kind={kind}
                        />
                      ),
                    });
                  }}
                >
                  {i18n.t(
                    "cb:Portfolio.Object.addRentalUnits",
                    "Mieteinheiten hinzufügen"
                  )}
                </BFButton>
              )}
            <VirtualizedTableExportButton
              managedData
              identifier={DEFAULT_RENTAL_UNITS_OVERVIEW_IDENTIFIER}
            />
          </div>
          <div className={`content`}>
            <CBRentalUnitsOverviewTable
              objectId={props.asset?._id}
              onSelect={setSelection}
            />
          </div>
        </div>
      </PersistentSplitPane>
    </div>
  );
};
export default ObjectDetailStackingPlan;

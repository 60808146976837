import i18n from "@/i18n";

import BFNumbericSlider from "@/modules/abstract-ui/forms/slider/BFNumbericSlider";
import "./PDFNumericSlider.scss";

type PDFNumericSliderProps = React.ComponentProps<typeof BFNumbericSlider>;

const PDFNumericSlider: React.FC<PDFNumericSliderProps> = (props) => {
  return (
    <div>
      <BFNumbericSlider
        label={i18n.t("Component.PDFConstructor.Block.Width", "Breite")}
        className="pdf-constructor--numeric-slider--slide"
        {...props}
      />
    </div>
  );
};

export { PDFNumericSlider };

import { withPDFBlock } from "../../PDFBlock";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";

import { PDFBlockFooter } from "../../PDFBlockFooter";
import { RentalUnitDistributionChartConfig } from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import { OBJECT_VACANCY_CHART } from "./PDFObjectInputConstants";
import { useState } from "react";
import { useBlockChanges } from "../../PDFBlockHooks";
import i18n from "@/i18n";
import { PDFCommonInput } from "../../Common/PDFCommonInput";
import { PDFChartFooterInput } from "./Components/PDFChartFooterInput";

const PDFObjectRentalUnitDistributionChartBlock =
  withPDFBlock<RentalUnitDistributionChartConfig>(
    ({ value, parentId }) => {
      const [{ asset }, { deleteBlock, saveBlock }] = usePDFConstructor();
      const [form, setForm] = useState({ ...value, assetId: asset._id });

      useBlockChanges(value, form);

      return (
        <div>
          <BFSelect
            data={OBJECT_VACANCY_CHART.map((option) => ({
              ...option,
              label: option.label(),
            }))}
            value={form.chartType}
            onSelect={(value) =>
              setForm((form) => ({
                ...form,
                chartType: value,
              }))
            }
            cleanable={false}
          />

          <PDFCommonInput
            value={form}
            onChange={(key, value) =>
              setForm((form) => ({
                ...form,
                [key]: value,
              }))
            }
          />

          <PDFChartFooterInput
            value={form}
            onChange={(key, value) =>
              setForm((form) => ({
                ...form,
                [key]: value,
              }))
            }
          />

          <PDFBlockFooter
            isEmpty={value.isEmpty}
            onSave={() => saveBlock({ ...value, assetId: asset._id }, parentId)}
            onDelete={() => deleteBlock(value)}
          />
        </div>
      );
    },
    () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.ObjectRentalUnitDistributionChart",
        "Objektverteilungsplan Mieteinheiten"
      )
  );

export { PDFObjectRentalUnitDistributionChartBlock };

import i18n from "../../i18n";
import { AssetTypes } from "../../model/AssetTypes";

export const ActivityTypes = {
  [AssetTypes.Activity.Insurance]: AssetTypes.Activity.Insurance,
  [AssetTypes.Activity.SupplyContract]: AssetTypes.Activity.SupplyContract,
  [AssetTypes.Activity.Maintenance]: AssetTypes.Activity.Maintenance,
  [AssetTypes.Activity.OrderingProcess]: AssetTypes.Activity.OrderingProcess,
  [AssetTypes.Activity.Project]: AssetTypes.Activity.Project,
  [AssetTypes.Activity.DamageClaim]: AssetTypes.Activity.DamageClaim,
  [AssetTypes.Activity.Dunning]: AssetTypes.Activity.Dunning,
  [AssetTypes.Activity.Vacancy]: AssetTypes.Activity.Vacancy,
  [AssetTypes.Activity.TenantCommunication]:
    AssetTypes.Activity.TenantCommunication,
  [AssetTypes.ActivityRelated.ProjectDeprecated]:
    AssetTypes.ActivityRelated.ProjectDeprecated,
};

class ActivityUtilsClass {
  getNameOfActivityType(type: string) {
    switch (type) {
      case ActivityTypes[AssetTypes.Activity.Insurance]:
        return i18n.t("AssetTypes.Insurance", "Versicherungsvertrag");
      case ActivityTypes[AssetTypes.Activity.SupplyContract]:
        return i18n.t("AssetTypes.SupplyContract", "Versorgungsvertrag");
      case ActivityTypes[AssetTypes.Activity.OrderingProcess]:
        return i18n.t("AssetTypes.OrderingProcess", "Bestellung");
      case ActivityTypes[AssetTypes.Activity.Maintenance]:
        return i18n.t("AssetTypes.Maintenance", "Wartungsvertrag");
      case ActivityTypes[AssetTypes.Activity.Project]:
        return i18n.t("AssetTypes.Project", "Projekt");
      case ActivityTypes[AssetTypes.Activity.DamageClaim]:
        return i18n.t("AssetTypes.DamageClaim");
      case ActivityTypes[AssetTypes.Activity.Dunning]:
        return i18n.t("AssetTypes.Dunning", "Mahnung");
      case ActivityTypes[AssetTypes.Activity.Vacancy]:
        return i18n.t("AssetTypes.Vacancy", "Leerstand");
      case ActivityTypes[AssetTypes.Activity.TenantCommunication]:
        return i18n.t("AssetTypes.TenantCommunication", "Mieterkommunikation");
      case ActivityTypes[AssetTypes.ActivityRelated.ProjectDeprecated]:
        return i18n.t("AssetTypes.DeprecatedProject", "Projekt (alt)");
      default:
        return i18n.t("AssetTypes.Unknown", "Unbekannt");
    }
  }
}
const ActivityUtils = new ActivityUtilsClass();
export default ActivityUtils;

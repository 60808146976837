import { useEffect } from "react";
import i18n from "../../../../../../../../i18n";
import { useStatisticQuery } from "../../../../../../../../redux/hooks";
import DataBus from "../../../../../../../../services/DataBus";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import StringUtils from "../../../../../../../../utils/StringUtils";
import {
  CBStatisticICR,
  CBStatisticLTV,
} from "../../../interfaces/CBStatisticQueries";
import KPISingleValueCard from "../SingleKPIValue";

interface Props {
  objectIds: string[];
  referenceId?: string;
}
export const CBLoanICR = (props: Props) => {
  const queryData = useStatisticQuery<CBStatisticICR>(
    "INTEREST_COVERAGE_RATIO",
    1,
    {
      objectIds: props.objectIds,
    }
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          queryData.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });
  return (
    <KPISingleValueCard
      name={i18n.t("cb:Loan.icr", "ICR")}
      value={`${
        queryData.data?.ICR
          ? StringUtils.formatNumber(queryData.data?.ICR * 100)
          : "-"
      } %`}
      loading={queryData.loading}
      error={queryData.error}
      onReload={queryData.reload}
    />
  );
};

export const CBLoanLTV = (props: Props) => {
  const queryData = useStatisticQuery<CBStatisticLTV>("LOAN_TO_VALUE", 1, {
    objectIds: props.objectIds,
  });

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          queryData.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });
  return (
    <KPISingleValueCard
      name={i18n.t("cb:Loan.ltv", "LTV")}
      value={`${
        queryData.data?.LTV
          ? StringUtils.formatNumber(queryData.data?.LTV * 100)
          : "-"
      } %`}
      loading={queryData.loading}
      error={queryData.error}
      onReload={queryData.reload}
    />
  );
};

import { ProjectScheduleGroup } from "./APProjectBudgetInterfaces";

export const TEMPLATE_TYPE_BUDGET_GROUP = "ap-project-budget-group";
export const TEMPLATE_TYPE_BUDGET_WHOLE_BUDGET = "ap-project-budget-whole";

export function getAllChildsOfProjectScheduleGroup(
  id: string,
  groups: ProjectScheduleGroup[]
): ProjectScheduleGroup[] {
  const childs: ProjectScheduleGroup[] = [];

  function traverse(id: string) {
    for (const group of groups) {
      if (group.parentId === id) {
        childs.push(group);
        traverse(group.id);
      }
    }
  }

  traverse(id);
  return childs;
}

import CBDamageClaimCard from "@/apps/tatar/cashBudget/views/portfolio/object/cards/damage-claim/CBDamageClaimCard";

type PropsObject = {
  assets: string[];
  refId: string;
  type: string;
};
type Props = PropsObject;
const ObjectDetailDamageClaim = (props: Props) => {
  return (
    <div className="object-damage-claim">
      <CBDamageClaimCard
        type={props.type}
        referenceId={props.refId}
        lqObjectIds={props.assets} // fixme delete
        objectIds={props.assets}
        marginBottom={20}
      />
    </div>
  );
};
export default ObjectDetailDamageClaim;

//KPI

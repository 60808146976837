import { HTTP } from "@/utils/Http";
import { Config } from "../services/types/pdfConfigBlockTypes";
import { prepareForTemplateSaving } from "../services/PDFConfigParser";
import { PDFDocumentDescriptionContent } from "../services/types/pdfConfigNodeTypes";

export const updateTemplateName = async (id: string, name: string) => {
  return HTTP.patch({
    url: `/pdfConstructor/template/${id}`,
    bodyParams: {
      name,
    },
  });
};

export const deleteTemplate = async (id: string) => {
  return HTTP.delete({
    url: `/pdfConstructor/template/${id}`,
  });
};

export const saveTemplate = async (
  name: string,
  config: Config,
  language: string
) => {
  return HTTP.post({
    url: "/pdfConstructor/template",
    bodyParams: {
      name,
      config: prepareForTemplateSaving(config),
      language,
    },
  });
};

export const requestConfigParsing = async (
  config: Config,
  language: string
): Promise<PDFDocumentDescriptionContent[]> => {
  const response = await HTTP.post({
    url: "/pdfConstructor/template/parse",
    bodyParams: {
      config,
      language,
    },
  });

  return response.config;
};

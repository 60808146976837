import { TaskAsset } from "../model/general-assets/TaskAsset";

class TaskUtilsClass {
  checkRestriction(task: TaskAsset, user: string, teams: string[]) {
    if (task?.data?.restrictTo?.users?.includes(user)) {
      return true;
    }
    if (task?.data?.restrictTo?.teams?.some((team) => teams?.includes(team))) {
      return true;
    }

    return false;
  }
}
const TaskUtils = new TaskUtilsClass();
export default TaskUtils;

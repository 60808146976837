import classNames from "classnames";
import Collapse from "rsuite/esm/Animation/Collapse";
import { DocumentStoreDocument } from "../../document-store/DSInterfaces";
import DSDocumentList from "../../document-store/components/DSDocumentList/DSDocumentList";
import { Comment } from "../Comments";
import "./CommentAttachments.scss";

interface CommentAttachmentsProps {
  open: boolean;
  asset: Comment;
  assetType: string;
  attachmentFieldPath: string;
  attachments: DocumentStoreDocument[];
}
const CommentAttachments = (props: CommentAttachmentsProps) => {
  return (
    <Collapse in={props.open}>
      <div>
        <div className={classNames(`comment-attachments`)}>
          <DSDocumentList
            hideUploadData={true}
            documents={props.attachments}
            assetParams={{
              asset: props.asset,
              type: props.asset.data.type,
              assetType: props.assetType,
              documentsFieldPath: props.attachmentFieldPath,
            }}
          />
        </div>
      </div>
    </Collapse>
  );
};

export default CommentAttachments;

import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import ModalManager from "@/components/ModalComponent/ModalManager";
import VirtualizedTable from "@/configurable/data/VirtualizedTable/VirtualizedTable";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { renderCellValue } from "@/modules/abstract-ui/data/table/TableUtils";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { ObjectRelatedMultipleLabel } from "@/modules/structure-components/object/ObjectRelatedLabel";
import DataBusDefaults from "@/services/DataBusDefaults";
import DateUtils from "@/utils/DateUtils";
import MQ from "@/utils/MatchQueryUtils";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { RentalAdjustment, RentalAgreement } from "../../TenantsInterfaces";
import "./CBRentalAgreementRentAdjustment.scss";
import CBRentalAgreementRentAdjustmentForm from "./form-components/CBRentalAgreementRentAdjustmentForm";

const TABLE_IDENT = "rental-agreement-rent-reduction";

interface CBRentalAgreementPeriodsProps {
  rentalAgreement: RentalAgreement;
  kind: ObjectKind;
}
const CBRentalAgreementRentAdjustment = (
  props: CBRentalAgreementPeriodsProps
) => {
  return (
    <div className={classNames(`cb-rental-agreement-rent-adjustment __card`)}>
      <div className={`card-header `}>
        <div className={`title __h3`}>
          {i18n.t("cb:RentalAgreementRentAdjustment.Title", "Mietreduktionen")}
        </div>
        <div className={`button-container`}>
          <BFButton
            appearance="outline"
            size="sm"
            onClick={() => {
              ModalManager.show({
                noPadding: true,
                content: (state, setState, close) => (
                  <CBRentalAgreementRentAdjustmentForm
                    onClose={close}
                    rentalAgreement={props.rentalAgreement}
                    adjustment={undefined}
                    onSubmit={() => {
                      DataBusDefaults.reload({
                        identifiers: [TABLE_IDENT],
                      });
                    }}
                  />
                ),
              });
            }}
          >
            {i18n.t(
              "cb:RentalAgreementRentAdjustment.New",
              "Neue Mietreduktion"
            )}
          </BFButton>
        </div>
      </div>
      <div className={`card-content`}>
        <VirtualizedTable
          assetType={AssetTypes.Rental.RentalAdjustment}
          additionalMatchQuery={MQ.and(
            MQ.eq("data.rentalAgreementId", props.rentalAgreement._id)
          )}
          selection="single"
          asPost
          cleanupOnUnmount
          onRowDoubleClick={(node: RentalAdjustment) => {
            ModalManager.show({
              noPadding: true,
              content: (state, setState, close) => (
                <CBRentalAgreementRentAdjustmentForm
                  onClose={close}
                  rentalAgreement={props.rentalAgreement}
                  adjustment={node}
                  onSubmit={() => {
                    DataBusDefaults.reload({
                      identifiers: [TABLE_IDENT],
                    });
                  }}
                />
              ),
            });
          }}
          identifier={TABLE_IDENT}
          columns={{
            "data.from": {
              label: i18n.t("cb:RentalAgreementRentAdjustment.from", "Von"),
              flexWidth: 100,
              sortable: true,
              resizable: true,
              render: (node: RentalAdjustment) => {
                return (
                  <>
                    <DebugDataComponent data={node} />
                    {renderCellValue(node?.data.from, "-", (value) =>
                      StringUtils.formatDate(value)
                    )}
                  </>
                );
              },
            },
            "data.to": {
              label: i18n.t("cb:RentalAgreementRentAdjustment.to", "Bis"),
              flexWidth: 100,
              sortable: true,
              resizable: true,
              render: (node: RentalAdjustment) => {
                return renderCellValue(node?.data.to, "-", (value) =>
                  StringUtils.formatDate(value)
                );
              },
            },
            "data.adjustmentType": {
              label: i18n.t(
                "cb:RentalAgreementRentAdjustment.adjustmentType",
                "Art"
              ),
              flexWidth: 130,
              sortable: true,
              resizable: true,
              render: (node: RentalAdjustment) => {
                return renderCellValue(
                  node?.data.adjustmentType,
                  "-",
                  (value) =>
                    value === "loss"
                      ? i18n.t(
                          "cb:RentalAgreementRentAdjustment.reduction",
                          "Mietminderung"
                        )
                      : i18n.t(
                          "cb:RentalAgreementRentAdjustment.loss",
                          "Mietausfall"
                        )
                );
              },
            },
            "data.linkedAsset": {
              label: i18n.t(
                "cb:RentalAgreementRentAdjustment.linkedAsset",
                "Aktivität"
              ),
              flexWidth: 200,
              resizable: true,
              sortable: true,
              render: (node: RentalAdjustment, index, params) =>
                node.data.linkedAsset ? (
                  <div className={`relation-wrapper`}>
                    <ObjectRelatedMultipleLabel
                      objectRelatedList={node.data.linkedAsset}
                    />
                  </div>
                ) : (
                  "-"
                ),
            },

            "data.rentNet": {
              label: i18n.t(
                "cb:RentalAgreementRentAdjustment.rentNetReduction",
                "Geminderte Kaltmiete Netto"
              ),
              flexWidth: 100,
              sortable: true,
              resizable: true,
              render: (node: RentalAdjustment) => {
                return renderCellValue(node?.data.rentNet, "-", (value) =>
                  StringUtils.formatCurrency(value)
                );
              },
            },
            "data.rentGross": {
              label: i18n.t(
                "cb:RentalAgreementRentAdjustment.rentNetReduction",
                "Geminderte Kaltmiete Brutto"
              ),
              flexWidth: 100,
              sortable: true,
              resizable: true,
              render: (node: RentalAdjustment) => {
                return renderCellValue(node?.data.rentGross, "-", (value) =>
                  StringUtils.formatCurrency(value)
                );
              },
            },
            rentNetSum: {
              label: i18n.t(
                "cb:RentalAgreementRentAdjustment.rentNetSum",
                "Summe Kaltmiete Netto"
              ),
              flexWidth: 100,
              sortable: false,
              resizable: true,
              render: (node: RentalAdjustment) => {
                return renderCellValue(node?.data.rentNet, "-", (value) => {
                  const from = node?.data.from;
                  const to = node?.data.to || new Date();

                  // count first day of months in range from-to
                  const countOfReductions = DateUtils.countMontsInRange(
                    from,
                    to
                  );
                  return StringUtils.formatCurrency(value * countOfReductions);
                });
              },
            },
            "data.operatingCostsNet": {
              label: i18n.t(
                "cb:RentalAgreementRentAdjustment.operatingCostsNetReduction",
                "Geminderte Nebenkosten Netto"
              ),
              flexWidth: 100,
              sortable: true,
              resizable: true,
              render: (node: RentalAdjustment) => {
                return renderCellValue(
                  node?.data.operatingCostsNet,
                  "-",
                  (value) => StringUtils.formatCurrency(value)
                );
              },
            },
            "data.operatingCostsGross": {
              label: i18n.t(
                "cb:RentalAgreementRentAdjustment.operatingCostsGrossReduction",
                "Geminderte Nebenkosten Brutto"
              ),
              flexWidth: 100,
              sortable: true,
              resizable: true,
              render: (node: RentalAdjustment) => {
                return renderCellValue(
                  node?.data.operatingCostsGross,
                  "-",
                  (value) => StringUtils.formatCurrency(value)
                );
              },
            },
            operatingCostSum: {
              label: i18n.t(
                "cb:RentalAgreementRentAdjustment.operatingCostSum",
                "Summe Nebenkosten Netto"
              ),
              flexWidth: 100,
              sortable: false,
              resizable: true,
              render: (node: RentalAdjustment) => {
                return renderCellValue(
                  node?.data.operatingCostsNet,
                  "-",
                  (value) => {
                    const from = node?.data.from;
                    const to = node?.data.to || new Date();

                    // count first day of months in range from-to
                    const countOfReductions = DateUtils.countMontsInRange(
                      from,
                      to
                    );
                    return StringUtils.formatCurrency(
                      value * countOfReductions
                    );
                  }
                );
              },
            },
            "data.note": {
              label: i18n.t("cb:RentalAgreementRentAdjustment.note", "Notiz"),
              flexWidth: 200,
              sortable: true,
              resizable: true,
              render: (node: RentalAdjustment) => {
                return renderCellValue(node?.data.note, "-");
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default CBRentalAgreementRentAdjustment;

import {
  PDFDocumentRect,
  PDFEditRect,
  usePdfMarkerEditState,
  usePdfMarkerHighlightState,
} from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import BaseAsset from "@/model/general-assets/BaseAsset";
import { hasNoValue, hasValue } from "@/utils/Helpers";
import classNames from "classnames";
import React from "react";
import BFButton from "../../general/Button/BFButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import BFOverlay from "../../general/whisper/BFOverlay";
import BfIcon from "../../icon/BfIcon";
import "./BFPDFMarker.scss";
import BFPDFOverlay from "./BFPDFOverlay";

export type BFPDFMarkerPropsPdfViewer = {
  className?: string;
  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;

  value: PDFEditRect[] | PDFDocumentRect[];
  onChange?: (rects: PDFEditRect[] | PDFDocumentRect[]) => void;
  identifier: string;
  pdfViewerIdentifier?: string;
  editHint?: string;
  viewHint?: string;
  children?: React.ReactNode;
  readonly?: boolean;
  title?: React.ReactNode;

  cdnData?: {
    asset: BaseAsset;
    assetField: string;
    assetType: string;
    directoryId?: string;
  };
};
// export type BFPDFMarkerPropsAssetFile = {
//   value: PDFEditRect[];
//   onChange: (rects: PDFEditRect[]) => void;
//   identifier: string;

//   asset: BaseAsset;
//   cdnId?: string;
//   hint: string;
// };
export type BFPDFMarkerProps = BFPDFMarkerPropsPdfViewer;

const BFPDFMarker = (props: BFPDFMarkerPropsPdfViewer) => {
  const { showOnPdf, endShowOnPdf } = usePdfMarkerHighlightState(
    props.value,
    props.identifier,
    (props as BFPDFMarkerPropsPdfViewer).pdfViewerIdentifier ||
      `pdf-${props.identifier}`,
    props.viewHint
  );
  const { editActive, startEdit, abortEdit } = usePdfMarkerEditState(
    props.value,
    props.onChange,
    props.identifier,
    (props as BFPDFMarkerPropsPdfViewer).pdfViewerIdentifier ||
      `pdf-${props.identifier}`,
    props.editHint ||
      i18n.t("BFPDFMarker.EditHint", "Markieren Sie die Positionen im PDF"),
    props.viewHint ||
      i18n.t("BFPDFMarker.ViewHint", "Markierte Positionen im PDF")
  );

  return (
    <div className={classNames(`bf-pdf-marker`, props.className)}>
      <div className={`action-container`}>
        {props.title && (
          <div className={`marker-title __h3`}>{props.title}</div>
        )}
        <div className={`fill`} />
        <ValidationPopover
          flexGrow={0}
          validatorStyle={props.validatorStyle}
          level={props.validation ? props.validation.level : "error"}
          message={props.validation ? props.validation.message : null}
          marginTop={0}
        >
          {props.readonly && hasNoValue(props.value) ? null : (
            <div
              className={classNames(`pdf-marker-comp`, {
                error: hasValue(props.validation?.message),
                "has-value": hasValue(props.value),
              })}
            >
              <div
                className={classNames(`icon-container`, {
                  readonly: props.readonly,
                })}
              >
                {props.cdnData && (
                  <BFOverlay
                    trigger="click"
                    enterable
                    speaker={
                      <div>
                        <BFPDFOverlay
                          asset={props.cdnData.asset}
                          assetField={props.cdnData.assetField}
                          assetType={props.cdnData.assetType}
                          value={props.value as PDFDocumentRect[]}
                        />
                      </div>
                    }
                  >
                    <div>
                      <BFButton
                        //   noPadding
                        size="xs"
                        disabled={hasNoValue(props.value)}
                        appearance="default"
                      >
                        <BfIcon
                          data={"task-list-pin-1"}
                          type="light"
                          size="xs"
                        />
                      </BFButton>
                    </div>
                  </BFOverlay>
                )}
                {props.pdfViewerIdentifier && (
                  <BFButton
                    //   noPadding
                    size="xs"
                    disabled={hasNoValue(props.value)}
                    appearance="default"
                    onClick={() => {
                      if (
                        (props as BFPDFMarkerPropsPdfViewer).pdfViewerIdentifier
                      ) {
                        endShowOnPdf();
                        showOnPdf();
                      }
                    }}
                  >
                    <BfIcon data={"task-list-pin-1"} type="light" size="xs" />
                  </BFButton>
                )}
              </div>
              {!props.readonly && (
                <>
                  {props.pdfViewerIdentifier && (
                    <>
                      {!editActive && (
                        <>
                          <BFButton
                            appearance="link"
                            size="xs"
                            onClick={() => {
                              if (
                                (props as BFPDFMarkerPropsPdfViewer)
                                  .pdfViewerIdentifier
                              ) {
                                const viewerProps =
                                  props as BFPDFMarkerPropsPdfViewer;

                                startEdit();
                              }
                            }}
                          >
                            <span>
                              {(props.value || []).length === 0
                                ? i18n.t(
                                    "BFPDFMarker.AddMarkOnPDF",
                                    "Im PDF markieren"
                                  )
                                : i18n.t(
                                    "BFPDFMarker.EditMarkOnPDF",
                                    "Markierung bearbeiten"
                                  )}
                            </span>
                          </BFButton>
                        </>
                      )}
                      {editActive && (
                        <>
                          <BFButton
                            size="xs"
                            appearance="link"
                            onClick={() => {
                              if (
                                (props as BFPDFMarkerPropsPdfViewer)
                                  .pdfViewerIdentifier
                              ) {
                                const viewerProps =
                                  props as BFPDFMarkerPropsPdfViewer;

                                abortEdit();
                              }
                            }}
                          >
                            <span className={`__color-negative`}>
                              {i18n.t(
                                "BFPDFMarker.Abort",
                                "Markieren abbrechen"
                              )}
                            </span>
                          </BFButton>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </ValidationPopover>
      </div>
      {props.children && (
        <div
          className="content"
          onFocus={() => {
            showOnPdf();
          }}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

export default BFPDFMarker;

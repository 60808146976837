import { Coordinate } from "ol/coordinate";
import { XYCoordinates } from "@/apps/tatar/objectsApp/types/object.interface";

// Converts coordinates between TupleCoordinates and XYCoordinates formats.
// If the input is a TupleCoordinates array, it returns an XYCoordinates object with x and y properties.
// If the input is an XYCoordinates object, it returns a TupleCoordinates array [x, y].
export function convertOpenLayerCoordinates(
  coordinates: Coordinate
): XYCoordinates;

export function convertOpenLayerCoordinates(
  coordinates: XYCoordinates
): Coordinate;

export function convertOpenLayerCoordinates(
  coordinates: Coordinate | XYCoordinates
) {
  if (Array.isArray(coordinates)) {
    return {
      x: coordinates[0],
      y: coordinates[1],
    };
  }

  return [coordinates.x, coordinates.y];
}

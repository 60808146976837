import _ from "lodash";
import React, { Component } from "react";
import {
  Field,
  FieldInputProps,
  FieldMetaState,
  FormSpy,
} from "react-final-form";
import { GenericFormsLayoutProps } from "./GFBaseElement";
import ExpressionHelper from "./util/ExpressionHelper";
import { JsonPropertyComponent } from "./util/JsonValidation";
type Props = {
  name: string;
  prefix?: string;
  jsonProperty: JsonPropertyComponent;
  render: (
    input: FieldInputProps<any, any>,
    meta: FieldMetaState<any>,
    name: string,
    jsonProperty: JsonPropertyComponent,
    currentValues: any,
    prefix: string
  ) => React.ReactNode;
  forceFormSpy?: boolean;
} & GenericFormsLayoutProps;

type States = {};

class GenericFormField extends Component<Props, States> {
  shouldComponentUpdate(nextProps: Props) {
    if (_.isEqual(this.props, nextProps)) {
      return false;
    }
    return true;
  }
  render() {
    const { name, jsonProperty, forceFormSpy, prefix } = this.props;
    if (jsonProperty.condition || forceFormSpy) {
      return (
        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            if (jsonProperty.condition) {
              if (
                ExpressionHelper.evaluateExpression(
                  jsonProperty.condition,
                  values,
                  this.props.formRoot.formValues
                )
              ) {
                return this.renderField(values);
              } else {
                return (
                  <Field
                    name={prefix ? `${prefix}.${name}` : name}
                    render={({ input, meta }) => {
                      input.onChange(null);
                      return null;
                    }}
                  />
                );
              }
            } else {
              return this.renderField(values);
            }
          }}
        </FormSpy>
      );
    } else {
      return this.renderField();
    }
  }

  renderField(values?: any) {
    const { name, jsonProperty, render, prefix } = this.props;

    return (
      <Field
        name={prefix ? `${prefix}.${name}` : name}
        defaultValue={
          this.props.formRoot &&
          this.props.formRoot.formProps &&
          (!this.props.formRoot.formProps.values ||
            this.props.formRoot.formProps.values[
              prefix ? `${prefix}.${name}` : name
            ] === undefined)
            ? jsonProperty.defaultValue
            : undefined
        }
        render={({ input, meta }) => {
          return render(input, meta, name, jsonProperty, values, prefix);
        }}
      />
    );
  }
}

export default GenericFormField;

import flatten from "flat";
import UAParser from "ua-parser-js";
import StorageUtils from "../../../utils/StorageUtils";
import {
  ADD_DEBUG_ACTION,
  CLOSE_DOCUMENT_VIEWER,
  OPEN_DOCUMENT_VIEWER,
  SET_ACTIVE_APPLICATION,
  SET_UI_CONFIG,
  UIConfigAction,
} from "../../actions/ui-config/ui-config-actions-types";
import { AppState } from "../../store";
import { UiConfigReducer } from "./UiConfigInterface";

export const DefaultUIConfigs = {
  OPEN_CONTACT_DRAWER: "openContactDrawer",
  GLOBAL_CDN_GALLERY: "globalCDNGalleryData",
  NOTIFICATION_OVERLAY_ACTIVE: "notificationsOverlayActive",
  APP_DRAWER_OVERLAY_ACTIVE: "appDrawerOverlayActive",
  HEADER_TRANSPARENT: "headerTrasparent",
  HEADER_APP_DRAWER: "headerApplicationDrawer",
  VIEWPORT_WIDTH: "viewportWidth",
  VIEWPORT_HEIGHT: "viewportHeight",
  VIEWPORT_SIZE_SELECTOR: "viewportSizeSelector",
  SHOW_HEADER_LEFT_NODE: "showHeaderLeftNode",
  APPLICATION_IS_INITIALIZING: "applicationIsInitializing",
  BLOCK_UI_INPUT: "blockUiInput",
  HELP_DRAWER_ACTIVE: "helpDrawerActive",
  PROFILE_MODAL_ACTIVE: "ProfileModalActive",
  MOBILE_HEADER: "MobileHeader",
  DEBUG: "Debug",
  DEBUG_COMPONENTS: "DebugComponents",
  DEBUG_DATA_JSON: "DebugDataJson",
  DEBUG_COLOR_THEME_EDITOR_ACTIVE: "DebugColorThemeEditorActive",
  AVAILABLE_LANGUAGES: "AvailableLanguages",
  MANDATOR_DEFAULT_LANGUAGE: "mandatorDefaultLanguage",
  SELECTED_LANGUAGE: "SelectedLanguage",
  SOCKET_RECONNECT_STATE: "SocketReconnectState",
};

const SESSION_SAVE_CONSTS = [
  DefaultUIConfigs.DEBUG,
  DefaultUIConfigs.DEBUG_COMPONENTS,
  DefaultUIConfigs.DEBUG_COLOR_THEME_EDITOR_ACTIVE,
];

var parser = new UAParser();
const initialState: UiConfigReducer = {
  general: {
    [DefaultUIConfigs.SOCKET_RECONNECT_STATE]: false,
    [DefaultUIConfigs.GLOBAL_CDN_GALLERY]: null,
    [DefaultUIConfigs.VIEWPORT_WIDTH]: window.innerWidth,
    [DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE]: false,
    [DefaultUIConfigs.NOTIFICATION_OVERLAY_ACTIVE]: false,
    [DefaultUIConfigs.HEADER_TRANSPARENT]: false,
    [DefaultUIConfigs.HEADER_APP_DRAWER]: null,
    [DefaultUIConfigs.APPLICATION_IS_INITIALIZING]: null,
    [DefaultUIConfigs.BLOCK_UI_INPUT]: false,
    [DefaultUIConfigs.HELP_DRAWER_ACTIVE]: false,
    [DefaultUIConfigs.MOBILE_HEADER]: null,
    [DefaultUIConfigs.DEBUG_DATA_JSON]: null,
    [DefaultUIConfigs.DEBUG]:
      StorageUtils.SessionStorage.get(`UI_CONFIG_${DefaultUIConfigs.DEBUG}`) ===
        "true" || false,
    [DefaultUIConfigs.DEBUG_COMPONENTS]:
      StorageUtils.SessionStorage.get(
        `UI_CONFIG_${DefaultUIConfigs.DEBUG_COMPONENTS}`
      ) === "true" || false,
    [DefaultUIConfigs.DEBUG_COLOR_THEME_EDITOR_ACTIVE]:
      StorageUtils.SessionStorage.get(
        `UI_CONFIG_${DefaultUIConfigs.DEBUG_COLOR_THEME_EDITOR_ACTIVE}`
      ) === "true" || false,
    [DefaultUIConfigs.AVAILABLE_LANGUAGES]: [
      {
        selector: "de",
        flag: "DE",
        name: "Deutsch",
      },
      {
        selector: "en",
        flag: "GB",
        name: "English",
      },
    ],
    [DefaultUIConfigs.MANDATOR_DEFAULT_LANGUAGE]: "en",
  },
  documentViewDocument: null,
  debugActions: [],
  device: parser.getResult(),
  activeApplication: null,
};

/**
 * Custom selectors for language data
 */
export const getMandatorDefaultLanguage = (state) =>
  state.uiConfig.general[DefaultUIConfigs.MANDATOR_DEFAULT_LANGUAGE];
export const getCurrentSelectedLanguage = (state) =>
  state.uiConfig.general[DefaultUIConfigs.SELECTED_LANGUAGE];
export const getAvailableLanguages = (state) =>
  state.uiConfig.general[DefaultUIConfigs.AVAILABLE_LANGUAGES];

export default function (
  state = initialState,
  action: UIConfigAction,
  root: AppState
): UiConfigReducer {
  switch (action.type) {
    case OPEN_DOCUMENT_VIEWER:
      return {
        ...state,
        documentViewDocument: {
          data: action.data,
          fileName: action.fileName,
          fileType: action.fileType,
          viewerType: action.viewerType,
        },
      };
    case CLOSE_DOCUMENT_VIEWER:
      return {
        ...state,
        documentViewDocument: null,
      };
    case ADD_DEBUG_ACTION:
      return {
        ...state,
        debugActions: [
          ...state.debugActions,
          {
            action: action.action,
            name: action.name,
          },
        ],
      };
    case SET_UI_CONFIG:
      if (SESSION_SAVE_CONSTS.indexOf(action.key) !== -1) {
        StorageUtils.SessionStorage.save(
          `UI_CONFIG_${action.key}`,
          action.value
        );
      }
      return {
        ...state,
        general: {
          ...state.general,
          [action.key]: action.value,
        },
      };
    case SET_ACTIVE_APPLICATION:
      if (!action.application) {
        return {
          ...state,
          activeApplication: null,
        };
      } else {
        return {
          ...state,
          activeApplication: {
            ...action.application,
            permissions: action.application
              ? flatten(
                  root.global.user.permissions.find(
                    (app) => app.APP === action.application._id
                  ),
                  { safe: true }
                )
              : {},
          },
        };
      }

    default:
      return state;
  }
}

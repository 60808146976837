import { useTypedSelector } from "@/redux/hooks";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import ApplicationHeader from "../ApplicationHeader/ApplicationHeader";
import "./ApplicationBody.scss";
import ApplicationSidebar from "./ApplicationSidebar";

type Props = {
  children: React.ReactNode;
};

const ApplicationBody = (props: Props) => {
  const location = useLocation();
  const user = useTypedSelector((state) => state.global.user);

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: `/login/${encodeURIComponent(location.pathname)}`,
          state: { from: location },
        }}
      />
    );
  }

  return (
    <>
      <div className="application-body">
        <ApplicationHeader />
        <div className={`application-content-container`}>
          <div className="application-content">{props.children}</div>
          <ApplicationSidebar />
        </div>
      </div>
    </>
  );
};

export default ApplicationBody;

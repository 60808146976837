import { get, set } from "local-storage";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Animation } from "rsuite";
import Fade from "rsuite/esm/Animation/Fade";
import ArrowAnimation from "../../components/LottieAnimations/Arrow/ArrowAnimation";
import { ContextData } from "../../model/db/ContextData";
import { User } from "../../model/db/User";
import BfButton from "../../modules/abstract-ui/general/Button/BFButton";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { AppState } from "../../redux/store";
import DataBus from "../../services/DataBus";
import "./LoginView.scss";

const { Collapse } = Animation;

type Props = {
  mandator?: string;
  user: User;
  context: ContextData;
  setUiConfig: (key: string, value: any) => void;
  renderContent: (
    cookiesAccepted: boolean,
    mandatorName: string
  ) => React.ReactNode;
} & WithTranslation;

type States = {
  showHelpDrawer: boolean;
  cookiesAccepted: boolean;
  showCookieFrame: boolean;
  showCookieArrow: boolean;
};

class LoginView extends Component<Props, States> {
  _subId = null;
  constructor(props) {
    super(props);
    this.state = {
      showHelpDrawer: false,
      cookiesAccepted: get("cookiesAccepted"),
      showCookieFrame: false,
      showCookieArrow: false,
    };
  }

  componentWillMount(): void {}

  componentDidMount() {
    if (!this.state.cookiesAccepted) {
      setTimeout(() => {
        this.setState({ showCookieFrame: true });
      }, 1500);
    }

    if (this.props.user) {
    }

    this._subId = DataBus.subscribe("ShowCookieArrow", (data) => {
      this.setState({
        showCookieArrow: true,
      });
    });
  }
  componentWillUnmount(): void {
    if (this._subId) {
      DataBus.unsubscribe(this._subId);
    }
  }

  acceptCookies() {
    set("cookiesAccepted", true);
    this.setState({ cookiesAccepted: true, showCookieFrame: false });
  }

  render() {
    const { setUiConfig, i18n, user, context } = this.props;

    if (user) {
      //user is logged in, no login screen necessary
      return <Redirect to="/home" />;
    } else {
      return (
        <div
          className={`view-login-wrapper ${
            context && context.login && context.login.type
              ? context.login.type
              : "default"
          }`}
        >
          <div style={{ zIndex: 1000 }}>
            <Collapse in={this.state.showCookieFrame}>
              <div>
                <div className="cookies-notification">
                  <div className="text">
                    {i18n.t("views.login.cookies.msg")}
                  </div>
                  <div className="buttons">
                    <BfButton
                      appearance="clear"
                      onClick={() => this.acceptCookies()}
                    >
                      {i18n.t("views.login.cookies.confirm")}
                    </BfButton>
                    {this.state.showCookieArrow && (
                      <Fade in={true}>
                        <div>
                          <div className={`arrow-animation`}>
                            <ArrowAnimation style={{ width: 60, height: 60 }} />
                          </div>
                        </div>
                      </Fade>
                    )}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
          <div
            className={`background`}
            style={{
              backgroundImage:
                context && context.login ? context.login.background : undefined,
            }}
          />
          <div className="view-login">
            <div className="header">
              {/* <BfButton
                size="lg"
                appearance="clear"
                onClick={() =>
                  setUiConfig(DefaultUIConfigs.HELP_DRAWER_ACTIVE, true)
                }
              >
                <BfIcon
                  color="white"
                  size="2x"
                  type="bf"
                  data="question-circle"
                />
              </BfButton> */}
            </div>
            <div className="center">
              <div className="login-center">
                <img
                  className="logo"
                  src={
                    context?.login?.logo
                      ? context.login.logo
                      : context?.login?.type === "clear"
                      ? "/img/logo_white.png"
                      : "/img/logo.png"
                  }
                  alt="logo"
                />

                {this.props.renderContent(
                  this.state.cookiesAccepted,
                  this.props.mandator
                )}
                {context && context.login && context.login.showPoweredBy ? (
                  <div className={`powered-by`}>
                    <a target="_blank" href="https://iberio.de">
                      <img
                        src={
                          context.login.customPoweredByImage
                            ? context.login.customPoweredByImage
                            : "/img/poweredby.png"
                        }
                      />
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="footer">
              <div>&copy; iberio GmbH</div>
              <div className="fill" />
              <BfButton
                appearance="clear"
                onClick={() =>
                  DataBus.emit("ROUTE", { route: "__about", append: true })
                }
              >
                {i18n.t("views.login.labels.about")}
              </BfButton>
              <BfButton
                appearance="clear"
                onClick={() =>
                  DataBus.emit("ROUTE", { route: "__privacy", append: true })
                }
              >
                {i18n.t("views.login.labels.dataPrivacy")}
              </BfButton>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user,
  context: state.global.context,
  mandator: state.global.mandator ? state.global.mandator["_id"] : undefined,
});

export default connect(mapStateToProps, { setUiConfig })(
  withTranslation()(LoginView)
) as any;

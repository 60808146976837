import { IComponent } from "../../../../configurable/layouts/IComponent";
import { MatchQuery } from "../../../../services/DataService";
import { ComponentsMapper } from "../../../../utils/ComponentsMapper";
import EZSelectQuery from "../../../ez-form/form-elements/ez-select-query/EZSelectQuery";
import GenericFormField from "../../../generic-forms/GenericFormField";
import { GenericFormsLayoutProps } from "../../../generic-forms/GFBaseElement";
import ExpressionHelper from "../../../generic-forms/util/ExpressionHelper";
import { JsonPropertyCommon } from "../../../generic-forms/util/JsonValidation";

export interface JsonSingleAssetAssignProps extends JsonPropertyCommon {
  _component: "single-asset";
  assetType: string;
  tableIdentifier: string;
  renderAsset: IComponent;
  matchQuery?: MatchQuery;
  _matchQuery?: string;
  sort?: {
    dataKey: string;
    sortType: "asc" | "desc";
  }[];
}
type GFAssetAssignProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonSingleAssetAssignProps;
export const GFSingleAssetAssign: React.FC<GFAssetAssignProps> = (props) => {
  const { name } = props;
  const forceFormSpy = !!props._matchQuery;
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      forceFormSpy={forceFormSpy}
      jsonProperty={props as any}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        const useMatchQuery = props._matchQuery
          ? ExpressionHelper.evaluateExpression(
              props._matchQuery,
              currentValues,
              props.params
            )
          : props.matchQuery;

        return (
          <div className={"gf-asset-assign"}>
            <EZSelectQuery
              appearance="bf"
              matchQuery={useMatchQuery}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined,
              }}
              label={props.label}
              assetType={props.assetType}
              renderValue={(asset) =>
                ComponentsMapper.createElement(props.renderAsset, asset)
              }
              value={input.value}
              onChange={input.onChange}
              identifier={props.tableIdentifier}
            />
          </div>
        );
      }}
    />
  );
};

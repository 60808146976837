import { BreakpointDefinition } from "./ConfigurableDashboard";

export const DASHBOARD_DEFAULT_BREAKPOINTS: BreakpointDefinition = {
  xs: {
    columns: 12,
    breakpoint: 0,
    margin: [10, 10],
    containerPadding: [10, 10],
  },
  sm: {
    columns: 12,
    breakpoint: 576,
    margin: [10, 10],
    containerPadding: [10, 10],
  },
  md: {
    columns: 12,
    breakpoint: 768,
    margin: [10, 10],
    containerPadding: [10, 10],
  },
  lg: {
    columns: 12,
    breakpoint: 992,
    margin: [10, 10],
    containerPadding: [10, 10],
  },
  xl: {
    columns: 12,
    breakpoint: 1400,
    margin: [10, 10],
    containerPadding: [10, 10],
  },
};

export const DASHBOARD_DEFAULT_CARD_SIZE: {
  [key: string]: {
    [selector: string]: {
      minW: number;
      minH: number;
      maxW: number;
      maxH: number;
    };
  };
} = {
  mid: {
    xs: {
      minW: 12,
      minH: 6,
      maxW: 12,
      maxH: 24,
    },
    sm: {
      minW: 6,
      minH: 6,
      maxW: 12,
      maxH: 18,
    },
    md: {
      minW: 6,
      minH: 6,
      maxW: 12,
      maxH: 16,
    },
    lg: {
      minW: 4,
      minH: 4,
      maxW: 6,
      maxH: 12,
    },
    xl: {
      minW: 3,
      minH: 3,
      maxW: 4,
      maxH: 10,
    },
  },
  mini: {
    xs: {
      minW: 6,
      minH: 6,
      maxW: 12,
      maxH: 12,
    },
    sm: {
      minW: 4,
      minH: 4,
      maxW: 12,
      maxH: 6,
    },
    md: {
      minW: 3,
      minH: 3,
      maxW: 6,
      maxH: 6,
    },
    lg: {
      minW: 2,
      minH: 2,
      maxW: 4,
      maxH: 4,
    },
    xl: {
      minW: 1,
      minH: 1,
      maxW: 3,
      maxH: 3,
    },
  },
  list: {
    xs: {
      minW: 12,
      minH: 3,
      maxW: 12,
      maxH: 8,
    },
    sm: {
      minW: 6,
      minH: 3,
      maxW: 12,
      maxH: 8,
    },
    md: {
      minW: 4,
      minH: 3,
      maxW: 12,
      maxH: 8,
    },
    lg: {
      minW: 3,
      minH: 3,
      maxW: 6,
      maxH: 6,
    },
    xl: {
      minW: 2,
      minH: 2,
      maxW: 6,
      maxH: 6,
    },
  },
};

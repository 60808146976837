import classNames from "classnames";
import { Fragment, useEffect, useRef, useState } from "react";
import { Animation, Loader } from "rsuite";
import Bounce from "rsuite/esm/Animation/Bounce";
import i18n from "../../../../i18n";
import BFButton from "../../general/Button/BFButton";
import "./BFList.scss";

const SCROLL_BOTTOM_OFFSET = 100;
interface BFListBaseProps {
  className?: string;
  data: any[];
  paramsConverter?: (data: any, index: number, params: any) => any;
  onScrollEnd?: () => void;
  onScroll?: (ev: React.UIEvent<HTMLDivElement>) => void;
  params?: any;
  emptyText?: string;
  loading?: "general" | "append";
  newDataAvailable?: number;
  onNewDataReload?: () => void;
  newDataWarningText?: string;
  flow?: boolean;
  postNode?: React.ReactNode;
}

type BFListNodeRenderProps = BFListBaseProps & {
  dataRenderType?: "default" | undefined;
  render: (node: any, index: number, params: any) => React.ReactNode;
};

type BFListAsListRenderProps = BFListBaseProps & {
  dataRenderType: "render-as-list";
  render: (list: any[], params: any) => React.ReactNode;
};

const BFList = (props: BFListNodeRenderProps | BFListAsListRenderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showNewData, setShowNewData] = useState(false);
  useEffect(() => {
    if ((props.newDataAvailable || 0) > 0) {
      setShowNewData(true);
    } else {
      setShowNewData(false);
    }
  }, [props.newDataAvailable]);
  useEffect(() => {
    if (props.data && ref.current) {
      checkInfiniteScroll(ref.current);
    }
  }, [props.data]);
  const checkInfiniteScroll = (target: HTMLDivElement) => {
    // Log.info(
    //   "#checkInfiniteScroll",
    //   target,
    //   target.scrollTop,
    //   target.clientHeight,
    //   target.scrollHeight,
    //   target.offsetHeight
    // );
    if (target && props.onScrollEnd) {
      if (
        target.scrollTop + target.clientHeight + SCROLL_BOTTOM_OFFSET >=
        target.scrollHeight
      ) {
        props.onScrollEnd();
      }
    }
  };

  const renderList = () => {
    if (props.dataRenderType === "default" || !props.dataRenderType) {
      return (
        <>
          {props.data.map((node, index) => (
            <Fragment key={node._id || index}>
              {props.render(
                node,
                index,
                props.paramsConverter
                  ? props.paramsConverter(node, index, props.params)
                  : props.params
              )}
            </Fragment>
          ))}
          {props.postNode}
        </>
      );
    } else if (props.dataRenderType === "render-as-list") {
      return (
        <>
          {" "}
          {props.render(props.data, props.params)}
          {props.postNode}
        </>
      );
    }
  };

  return (
    <div
      className={classNames(`bf-list`, props.className, { flow: props.flow })}
    >
      <div
        className={`list-scroll`}
        ref={ref}
        onScroll={(ev) => {
          if (props.onScroll) {
            props.onScroll(ev);
          }
          checkInfiniteScroll(ev.target as HTMLDivElement);
        }}
      >
        {props.data.length === 0 ? (
          <div className={`empty-text`}>
            {props.emptyText ||
              i18n.t("BFList.emptyText", "Keine Daten vorhanden")}
          </div>
        ) : (
          renderList()
        )}
      </div>

      {props.newDataAvailable > 0 && (
        <div className="show-new-data-warning-wrapper">
          <Bounce unmountOnExit in={showNewData}>
            <div>
              <div className="show-new-data-warning">
                <BFButton
                  className="close-button"
                  appearance="clear"
                  icon={{ type: "light", data: "close", size: "xs" }}
                  onClick={() => setShowNewData(false)}
                />
                <div className="text">
                  {props.newDataWarningText ||
                    "Es sind neue Daten vorhanden. Bitte laden Sie die Tabelle neu, indem Sie auf 'Neu laden' klicken"}
                </div>
                {props.onNewDataReload && (
                  <BFButton
                    onClick={() => props.onNewDataReload()}
                    appearance="outline-white"
                  >
                    {i18n.t("Global.Buttons.reload")}
                  </BFButton>
                )}
              </div>
            </div>
          </Bounce>
        </div>
      )}

      <Animation.Fade in={props.loading === "general"} unmountOnExit>
        <div className={`loading-overlay`}>
          <Loader size="lg" />
        </div>
      </Animation.Fade>

      <Animation.Bounce in={props.loading === "append"} unmountOnExit>
        <div className={`loading-bottom-container`}>
          <div className={`loading-bottom`}>
            <Loader size="sm" />
          </div>
        </div>
      </Animation.Bounce>
    </div>
  );
};
export default BFList;

import classNames from "classnames";
import React from "react";
import "./FlexGrowContainer.scss";

interface FlexGrowContainerProps {
  children: React.ReactElement;
  className?: string;
  flexGrow?: number;
  flexShrink?: number;
  scroll?: boolean;
  noOverflow?: boolean;
}
const FlexGrowContainer = (props: FlexGrowContainerProps) => {
  return (
    <div
      className={classNames(`flex-grow-container`)}
      style={{
        flexGrow: props.flexGrow || 1,
        flexShrink: props.flexShrink || 0,
      }}
    >
      <div
        style={{
          overflow: props.scroll
            ? "auto"
            : props.noOverflow
            ? "hidden"
            : undefined,
        }}
        className={`flex-grow-container__inner`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default FlexGrowContainer;

import { ObjectAddress } from "@/apps/tatar/objectsApp/types/object.interface";
import FormUtils from "@/utils/FormUtils";
import ObjectIdService from "@/utils/ObjectIdUtils";
import _ from "lodash";
import i18n from "../../i18n";
import {
  AddressData,
  Contact,
  ContactData,
  ContactType,
  PersonContactData,
} from "../../model/db/Contact";
import { BFChooserOption } from "../abstract-ui/forms/chooser/BFChooser";

class ContactUtilsClass {
  getContactTypes: () => ContactType[] = () => [
    "EMPLOYEE",
    "INVOICE",
    "TENANT",
    "OTHER",
    "TRANSIENT",
  ];

  mapObjectAdressToContactAdress = (
    objectAddress: ObjectAddress
  ): AddressData => {
    return {
      id: ObjectIdService.new(),
      isMain: objectAddress.isMain,
      additional: "",
      city: objectAddress.city,
      country: null,
      street: objectAddress.streetname + " " + objectAddress.streetnumber,
      zip: objectAddress.postalcode,
    };
  };

  getPersonDisplayName = (
    person: {
      salutation?: "f" | "m" | "d";
      firstName?: string;
      lastName?: string;
      title?: string;
    },
    withoutSalutation = false,
    withoutFirstname = false
  ) => {
    return [
      withoutSalutation
        ? null
        : FormUtils.getSalutationOptions().find(
            (e) => e.value === person.salutation
          )?.label,
      person.title,
      withoutFirstname ? null : person.firstName,
      person.lastName,
    ]
      .filter((e) => e)
      .join(" ");
  };
  getPersonsDisplayName = (
    contact: PersonContactData,
    withoutSalutation = false
  ) => {
    return contact.persons.map((person) =>
      this.getPersonDisplayName(
        {
          salutation: person.salutation,
          title: person.title,
          firstName: person.firstName,
          lastName: person.lastName,
        },
        withoutSalutation
      )
    );
  };

  evaluateDisplayName = (contact: ContactData): string => {
    if (contact.personType === "organization") {
      return contact.companyName;
    } else {
      const persons = this.getPersonsDisplayName(
        contact as PersonContactData,
        true
      );

      const showPersons = persons.splice(0, 2);

      if (persons.length > 0) {
        return (
          showPersons.join(", ") +
          (persons.length > 1
            ? " " +
              i18n.t("AssignmentLabel.furtherMoreMultiple", {
                count: persons.length,
              })
            : " " + i18n.t("AssignmentLabel.furtherMoreSingle"))
        );
      } else {
        return showPersons.join(" & ");
      }
    }
  };

  reduceContactTypes = (contactTypes: ContactType[]) => {
    return _.uniq(
      contactTypes.map((tag) => {
        if (tag === "TRANSIENT") return "OTHER";

        return tag;
      })
    );
  };
  getContactTypeName(contactType: ContactType) {
    switch (contactType) {
      case "EMPLOYEE":
        return i18n.t("Contact.Type.EMPLOYEE", "Mitarbeiter");
      case "INVOICE":
        return i18n.t("Contact.Type.INVOICE", "Rechnungsteller");
      case "TENANT":
        return i18n.t("Contact.Type.TENANT", "Mieter");
      case "BILL":
        return i18n.t("Contact.Type.BILL", "Kunde");
      case "OTHER":
        return i18n.t("Contact.Type.OTHER", "Weitere");
      case "TRANSIENT":
        return i18n.t("Contact.Type.TRANSIENT", "Vorläufig");
      default:
        return contactType;
    }
  }
  getSalutationString = (salutationData: {
    personType: "organization" | "private";
    persons?: {
      salutation?: "f" | "m" | "d";
      firstName?: string;
      lastName?: string;
      title?: string;
    }[];
  }) => {
    const { personType, persons } = salutationData;

    if (personType === "organization") {
      return i18n.t(
        "Salutation.organization",
        "Sehr geehrte Damen und Herren,"
      );
    }
    let greeting = "";

    if (personType === "private") {
      greeting = persons
        ?.map((person) => {
          let greetingPerson = "";
          const { salutation } = person;
          // Determine the formal salutation based on the gender
          if (salutation === "m") {
            greetingPerson = i18n.t("Salutation.privateMale1", "Sehr geehrter");
          } else if (salutation === "f") {
            greetingPerson = i18n.t(
              "Salutation.privateFemale1",
              "Sehr geehrte"
            );
          } else {
            greetingPerson = i18n.t(
              "Salutation.privateNoSaluation",
              "Sehr geehrte/r"
            );
          }

          greetingPerson +=
            " " + this.getPersonDisplayName(person, false, true);

          return greetingPerson;
        })
        .join(",\n");
    }
    // If neither name is provided, leave it generic

    return greeting;
  };
  getInfoLine = (contact: Contact) => {
    let infoLines: string[] = [];

    if (contact.data.address?.length > 0) {
      const firstAddress = contact.data.address[0];
      if (firstAddress.zip && firstAddress.city) {
        infoLines.push(`${firstAddress.zip} ${firstAddress.city}`);
      } else if (firstAddress.city) {
        infoLines.push(firstAddress.city);
      }
      if (firstAddress.street) {
        infoLines.push(firstAddress.street);
      }
    }
    if (contact.data.emails?.length > 0) {
      infoLines.push(contact.data.emails[0]?.email);
    }

    if (contact.data.note) {
      infoLines.push(contact.data.note);
    }
    return infoLines.join(" | ");
  };

  getContactPersonSelectOptions = (
    contact: Contact
  ): BFChooserOption<string>[] => {
    const contactPersonOptions: BFChooserOption<string>[] =
      contact.data.contactPersons?.map((contactPerson) => ({
        label: [contactPerson.firstName, contactPerson.lastName]
          .filter((e) => e)
          .join(" "),
        value: contactPerson.id,
        subLabel: contactPerson.note,
      })) || [];
    return contactPersonOptions;
  };
  getAdressSelectOptions = (contact: Contact): BFChooserOption<string>[] => {
    const addressOptions: BFChooserOption<string>[] =
      contact.data.address?.map((address) => ({
        label: [
          address.street,
          address.additional ? "/" : null,
          address.additional,
        ]
          .filter((e) => e)
          .join(" "),
        value: address.id,
        subLabel: [address.zip, address.city].filter((e) => e).join(" "),
      })) || [];
    return addressOptions;
  };
  getPhoneSelectOptions = (contact: Contact): BFChooserOption<string>[] => {
    const phones: BFChooserOption<string>[] =
      contact.data.phone?.map((phone) => ({
        label: phone.phone,
        value: phone.id,
        subLabel: phone.note,
      })) || [];
    const contactPhones =
      contact.data.contactPersons
        ?.filter((e) => e.phone)
        .map((e) => ({
          label: e.phone,
          value: e.id,
          subLabel: [
            [e.firstName, e.lastName].filter((e) => e).join(" "),
            e.note,
          ].join(" - "),
        })) || [];

    return [...phones, ...contactPhones];
  };
  getEmailSelectOptions = (contact: Contact): BFChooserOption<string>[] => {
    const emails: BFChooserOption<string>[] =
      contact.data.emails?.map((email) => ({
        label: email.email,
        value: email.id,
        subLabel: email.note,
      })) || [];
    const contactEmails =
      contact.data.contactPersons
        ?.filter((e) => e.email)
        .map((e) => ({
          label: e.email,
          value: e.id,
          subLabel: [
            [e.firstName, e.lastName].filter((e) => e).join(" "),
            e.note,
          ].join(" - "),
        })) || [];
    return [...emails, ...contactEmails];
  };
}
const ContactUtils = new ContactUtilsClass();
export default ContactUtils;

import { AdminUser } from "@/apps/administration/AdminInterfaces";
import UserLoader from "@/components/AssetLoader/UserLoader";
import Username from "@/components/AvatarComponent/Username";
import { AssetTypes } from "@/model/AssetTypes";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import CacheService from "@/services/CacheService";
import DataBus from "@/services/DataBus";
import { DataBusSubKeys } from "@/utils/Constants";
import { Trans } from "react-i18next";
import { InternComment } from "../../Comments";
import CommentUtils from "../../CommentUtils";
import { CommentContainer } from "../CommentContainer";
import CommentEntryContent from "../CommentEntryContent";

interface InternCommentProps {
  onPinChange?: () => void;
  comment: InternComment;
  commentModuleIdentifier: string;
  context?: {
    id: number;
    name: string;
  };
  onContextClick?: (contextId: number) => void;
  allowFullscreen?: boolean;
  hideAllActions?: boolean;
  disableFocusMode?: boolean;
  apperance?: "normal" | "slim";
  onClose?: () => void;
  collapsible?: boolean;
}
export const InternCommentEntry = (props: InternCommentProps) => {
  const possibleTags = (
    UnitStruct.getAllTagConfigs(AssetTypes.Comment) || []
  ).filter((e) => e.status !== "archived");

  return (
    <UserLoader
      id={props.comment.data?.typeData.userId}
      render={(sender) => (
        <CommentContainer
          collapsible={props.collapsible}
          pinned={props.comment.data.pin}
          onPinChange={props.onPinChange}
          archived={props.comment.data?.archived}
          commentId={props.comment._id}
          tagData={{
            possibleTags,
            tags: props.comment.data?.tags,
          }}
          hideAllActions={props.hideAllActions}
          onClose={props.onClose}
          onContextClick={props.onContextClick}
          className="intern-comment"
          allowFullscreen={props.allowFullscreen}
          apperance={props.apperance}
          onPrint={() => ({
            from: {
              name: sender.displayname,
            },
            date: props.comment.data?.date,
          })}
          title={
            <>
              <strong>
                <Username id={props.comment.data?.typeData.userId} />
              </strong>{" "}
              <Trans i18nKey="CommentsModule.InternalMessage.headerText">
                hat eine <span className="weight-600">interne Nachricht</span>{" "}
                hinzugefügt
              </Trans>
            </>
          }
          disableFocusMode={props.disableFocusMode}
          context={props.context}
          sender={props.comment.data?.typeData.userId}
          date={props.comment.data?.date}
          content={<CommentEntryContent comment={props.comment} />}
          footer={{
            onReply:
              props.commentModuleIdentifier && !props.hideAllActions
                ? async () => {
                    const replyToId = props.comment.data?.typeData.userId;
                    const replyTo = (await CacheService.getData({
                      oType: "user",
                      checkTables: true,
                      id: replyToId,
                    })) as AdminUser;
                    DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                      identifier: props.commentModuleIdentifier,
                      cursorPosition: "end",
                      subactivity: props.comment.data?.subactivityId,
                      comment: `
                  <span class="mention" data-mention="@${
                    replyTo.displayname
                  }" data-object-id="${replyToId}" data-mention-type="user">@${
                        replyTo.displayname
                      }</span>
                  <br>
                  <blockquote>${CommentUtils.stripQuotesFromHTML(
                    CommentUtils.stripMentionsFromHTML(
                      props.comment.cdnHtml ||
                        props.comment.data?.message.split("\n").join("<br>")
                    )
                  )}</blockquote><p><br></p><p><br></p>`,
                      sendAsMail: false,
                    });
                  }
                : undefined,
          }}
        />
      )}
    />
  );
};

import React from "react";
import ListEntryComponent from "../../../../configurable/components/ListComponent/ListEntryComponent";
import BFUseTooltip from "../../../abstract-ui/general/tooltip/BFUseTooltip";
import BfIcon from "../../../abstract-ui/icon/BfIcon";
import {
  DSListEntryAction,
  DocumentStoreDirectory,
  DocumentStoreDocument,
} from "../../DSInterfaces";
import "./DSListEntry.scss";

type IndicatorProps = {
  showIndicator: boolean;
  indicatorText: string | React.ReactNode;
};

type DSListEntryPropsBase = {
  iconName: string;
  name: string;
  onClick: () => void;
  postFixedElements?: React.ReactNode;
  indicatorProps?: IndicatorProps;
  postfix?: React.ReactNode;
};

type DSListEntryDocumentProps = DSListEntryPropsBase & {
  type: "document";
  entryObject: DocumentStoreDocument;
  entryActions?: DSListEntryAction<DocumentStoreDocument>[];
};

type DSListEntryDirectoryProps = DSListEntryPropsBase & {
  type: "directory";
  entryObject: DocumentStoreDirectory;
  entryActions: DSListEntryAction<DocumentStoreDirectory>[];
};

const DSListEntry = (
  props: DSListEntryDocumentProps | DSListEntryDirectoryProps
) => {
  return (
    <ListEntryComponent
      onClickEntry={props.onClick}
      indicatorProps={{
        showIndicator: props.indicatorProps?.showIndicator,
        indicatorText: props.indicatorProps?.indicatorText,
      }}
      postfix={props.postfix}
      renderEntry={() => {
        return (
          <div className="ds-entry-container">
            <div className="directory-icon-container">
              <BfIcon type="color" data={props.iconName} size="sm" />
            </div>
            <div className="entry-text">
              <BFUseTooltip
                delay={500}
                trigger="hover"
                placement="bottom"
                tooltip={<div>{props.name}</div>}
              >
                <span>{props.name}</span>
              </BFUseTooltip>
            </div>
            {props.postFixedElements && (
              <div className="postfix-content">{props.postFixedElements}</div>
            )}
          </div>
        );
      }}
      entryData={props.entryObject}
      entryActions={props.entryActions}
      showDebugToggle={true}
    />
  );
};

export default DSListEntry;

import AccountingRentalInfo from "@/apps/tatar/accounting/views/rental/form/info/AccountingRentalInfo";
import LoadPage from "@/components/LoadPage/LoadPage";
import WrappedText from "@/components/WrappedText/WrappedText";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import HistoryGraph from "@/modules/abstract-ui/data/history-graph/HistoryGraph";
import { AssetCell } from "@/modules/abstract-ui/data/table/TableUtils";
import { useAssetList } from "@/redux/hooks";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import moment from "moment";
import {
  RentalAgreement,
  RentalAgreementTimePeriod,
  RentalUnit,
} from "../../../TenantsInterfaces";
import "./RentalUnitHistory.scss";

interface RentalUnitHistoryProps {
  rentalUnit: RentalUnit;
}
const RentalUnitHistory = (props: RentalUnitHistoryProps) => {
  const data = useAssetList<RentalAgreementTimePeriod>(
    AssetTypes.Rental.RentalAgreementPeriod,
    MQ.and(
      MQ.eq("data.status", "active"),
      MQ.eq("data.rentalUnitIds", props.rentalUnit._id)
    )
  );
  if (!data || data.state === "loading") {
    return <LoadPage />;
  }
  if (data?.state === "error") {
    return (
      <div className={`cb-rental-agreement-periods __card error-page`}>
        {i18n.t(
          "cb:RentalUnitHistory.error",
          "Fehler beim Laden des Mietverlaufs"
        )}
      </div>
    );
  }

  const gaps = findGaps(data.data);
  const enrichedData = [
    ...data.data.map((e) => ({
      id: e._id,
      data: {
        type: "rented",
        data: e,
      },
      from: e.data.from,
      to: e.data.to,
    })),
    ...gaps.map((e) => ({
      id: e.from?.toString() + "_" + e.to?.toString(),
      data: {
        type: "vacancy",
      },
      from: e.from,
      to: e.to,
    })),
  ];

  return (
    <div className={classNames("rental-unit-history __card")}>
      <HistoryGraph
        data={enrichedData}
        renderContent={(period, type, previousPeriod) => {
          return (
            <div className={`cb-rental-unit-period`}>
              {period.data.type === "rented" && (
                <AccountingRentalInfo
                  ignoreAccounting
                  rentalAgreementId={period.data.data.data.rentalAgreement}
                />
              )}
              {period.data.note && (
                <>
                  <div className={`__h4 margin-top-10`}>
                    {i18n.t("cb:RentalAgreement.Labels.note", "Notiz")}
                  </div>
                  <WrappedText text={period.data.note} />
                </>
              )}
            </div>
          );
        }}
        renderHeader={(period, type, previousPeriod) => {
          if (period.data.type === "vacancy") {
            return (
              <div className={`vacancy-header`}>
                {i18n.t("cb:RentalUnitHistory.vacancy", "Leerstand")}
              </div>
            );
          }
          return (
            <AssetCell
              assetType={AssetTypes.Rental.RentalAgreement}
              id={period.data.data.data.rentalAgreement}
              render={(rentalAgreement: RentalAgreement) => (
                <>
                  {rentalAgreement.data.id} - {rentalAgreement.data.displayName}
                </>
              )}
            />
          );
        }}
      />
    </div>
  );
};

export default RentalUnitHistory;

function findGaps(data: RentalAgreementTimePeriod[]) {
  // Sortiere die Daten nach `from` (Tagesgenau)
  data.sort(
    (a, b) => moment(a.data.from).valueOf() - moment(b.data.from).valueOf()
  );

  const gaps = [];

  for (let i = 0; i < data.length - 1; i++) {
    const current = data[i];
    const next = data[i + 1];

    // Falls `to` existiert, prüfe, ob es mindestens 1 Tag vor `from` des nächsten Eintrags liegt
    if (current.data.to) {
      const toDate = moment(current.data.to).startOf("day"); // Uhrzeit entfernen
      const nextFromDate = moment(next.data.from).startOf("day"); // Uhrzeit entfernen

      if (toDate.add(1, "day").isBefore(nextFromDate)) {
        gaps.push({
          from: toDate.format("YYYY-MM-DD"),
          to: nextFromDate.format("YYYY-MM-DD"),
        });
      }
    }
  }

  // Prüfe das letzte Element für eine Lücke mit offenem Ende
  const last = data[data.length - 1];
  if (last?.data.to) {
    gaps.push({
      from: moment(last.data.to).add(1, "day").format("YYYY-MM-DD"),
      to: null,
    });
  }

  return gaps;
}

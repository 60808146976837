import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import classNames from "classnames";
import ModalManager from "../../../../../components/ModalComponent/ModalManager";
import ListComponent from "../../../../../configurable/components/ListComponent/ListComponent";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { FilterValue } from "../../../../../modules/abstract-ui/data/table-filter/BFTableFilterModel";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDetailsButton from "../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import ACInvoiceUtils from "../../../../utils/ACInvoiceUtils";
import { RAInvoice } from "../../../invoiceApp/RAInterfaces";
import RAInvoiceEntryView from "../../../invoiceApp/components/RAInvoiceEntryView";
import RAUploadInvoiceContainer from "../../../invoiceApp/components/UploadIncomingInvoice/RAUploadInvoiceContainer";
import { useActivityConstants } from "../../ActivityHooks";
import { APActivity } from "../../ActivityInterfaces";
import "./APActivityInvoices.scss";
import APAssignActivityToInvoice from "./APAssignActivityToInvoice";

const AP_ACTIVITY_INVOICE_LIST = "ap_activity_invoice_list";
interface APDamageClaimInvoicesProps {
  activity: APActivity;
}
const APActivityInvoices = (props: APDamageClaimInvoicesProps) => {
  const constants = useActivityConstants();
  return (
    <RAUploadInvoiceContainer
      direction="INCOMING"
      types={[
        {
          value: props.activity.data.type,
          label: UnitStruct.getUnitLabel(props.activity.data.type),
        },
      ]}
      forceConfirmation
      reloadTableIdentifiers={[AP_ACTIVITY_INVOICE_LIST]}
      invoiceOptions={{
        linkedActivity: {
          assetType: constants?.assetType,
          assetId: props.activity._id,
        },
      }}
      render={(open) => {
        return (
          <div className={classNames(`ap-activity-invoices`)}>
            <div className={`actions`}>
              <BFButton
                onClick={() => {
                  const initialFilter: FilterValue[] = [];
                  const invoiceTypesByActivityType =
                    ACInvoiceUtils.getInvoiceTypeByRelevantActivity(
                      "INCOMING",
                      constants?.assetType
                    ) || [];
                  if (invoiceTypesByActivityType.length > 0) {
                    initialFilter.push({
                      filterKey: "data.invoice.invoiceType",
                      value: {
                        value: invoiceTypesByActivityType.map((e) => e._id),
                      },
                    });
                  }
                  if (props.activity.data.entity) {
                    initialFilter.push({
                      filterKey: "data.entity",
                      value: [props.activity.data.entity],
                    });
                  }
                  if (props.activity.data.objectId) {
                    initialFilter.push({
                      filterKey: "data.objectId",
                      value: [props.activity.data.objectId],
                    });
                  }

                  ModalManager.show({
                    backdrop: "static",
                    size: "xxl",
                    noPadding: true,
                    content: (states, setStates, close) => (
                      <APAssignActivityToInvoice
                        initialFilter={{ filter: initialFilter }}
                        onClose={(updatedStuff: boolean) => {
                          close();
                          if (updatedStuff) {
                            DataBusDefaults.reload({
                              identifiers: [AP_ACTIVITY_INVOICE_LIST],
                            });
                          }
                        }}
                        activity={props.activity}
                      />
                    ),
                  });
                }}
              >
                {i18n.t(
                  "apTemplate:ActivitiesInvoices.ConnectInvoice",
                  "Beleg verknüpfen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )}
              </BFButton>
              <BFButton onClick={open}>
                {i18n.t(
                  "apTemplate:ActivitiesInvoices.UploadInvoice",
                  "Neuen Beleg hochladen",
                  {
                    ns: [constants?.assetType, "apTemplate"],
                  }
                )}
              </BFButton>
            </div>
            <div className={`list-container`}>
              {/* <ListComponent /> */}
              <ListComponent
                dataUrl="/api/asset/invoice"
                identifier={AP_ACTIVITY_INVOICE_LIST}
                additionalMatchQuery={{
                  type: "and",
                  query: [
                    {
                      type: "op",
                      name: "data.activity.assetType",
                      op: "eq",
                      value: constants?.assetType,
                    },
                    {
                      type: "op",
                      name: "data.activity.assetId",
                      op: "eq",
                      value: props.activity._id,
                    },
                  ],
                }}
                reloadOnMount
                render={(asset: RAInvoice) => {
                  return (
                    <div className={`__card invoice-entry-card`}>
                      <RAInvoiceEntryView
                        invoice={asset}
                        hideRelations
                        ignoreHistory
                        ignoreMoreInfos
                        hideCostCenter
                        showInvoiceIdHeader={true}
                      />
                      <div className={`action-row`}>
                        <BFDetailsButton
                          appearance="link"
                          data={{
                            assetType: AssetTypes.Invoice,
                            assetId: asset._id,
                            type: asset.data.type,
                          }}
                        >
                          {i18n.t("Global.Buttons.show", "Anzeigen")}
                        </BFDetailsButton>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default APActivityInvoices;

import TableComponent from "@/configurable/components/TableComponent/TableComponent";
import { AssetTypes } from "@/model/AssetTypes";
import { TableCache } from "@/redux/reducers/application/ApplicationInterface";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Animation, Loader } from "rsuite";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../i18n";
import BFButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import BfIcon from "../../../../modules/abstract-ui/icon/BfIcon";
import { openOrDownloadDocument } from "../../../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../redux/store";
import CDNService, { CDNBucketObject } from "../../../../services/CDNService";
import DataBus from "../../../../services/DataBus";
import { DataBusSubKeys } from "../../../../utils/Constants";
import FileUtils from "../../../../utils/FileUtils";
import { HTTP } from "../../../../utils/Http";
import { AssetCashBudgetEntry } from "../model/CashBudgetEntry";
import CBValidationIndicator from "../views/idly-bookings/validate/CBValidationIndicator";
import {
  clearFlexCacheData,
  patchTableData,
} from "./../../../../redux/actions/application/application-actions";
import "./CashBudgetAttachments.scss";
import CashBudgetConnectionsInvoiceSum from "./CashBudgetConnectionsInvoiceSum";
import CashBudgetLinkedItem from "./CashBudgetLinkedItem";

const { Collapse } = Animation;

type Props = {
  userId: string;
  entry: AssetCashBudgetEntry;
  debug: boolean;
  isUploading?: boolean;
  patchTableData: (tableIdentifier: string, id: string, data: any) => void;
  openOrDownloadDocument: typeof openOrDownloadDocument;
};

type States = {
  countAttachments: number;
  // attachmentsOpen: boolean;
};
export const CASHBUDGET_ATTACHMENT_COMP = "CASHBUDGET_ATTACHMENT_COMP_";
class CashBudgetAttachments extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    countAttachments: null,
    // attachmentsOpen: false
  };

  ref = React.createRef<any>();

  getIconType(filename) {
    const filetype = FileUtils.getFiletype(filename);
    switch (filetype.toLowerCase()) {
      case "pdf":
        return "office-file-pdf-1";
      case "doc":
      case "docx":
      case "docm":
        return "office-file-doc-1";
      case "zip":
      case "rar":
      case "7zip":
        return "zip-file-2";
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
      case "tif":
        return "image-file-landscape";
      case "xls":
      case "xlsx":
      case "xlsm":
        return "office-file-xls-1";
      case "ppt":
      case "pptx":
      case "pptm":
        return "office-file-ppt-1";
      default:
        return "common-file-question";
    }
  }
  componentDidUpdate(oldProps: Props) {
    const props = this.props;

    if (
      props.entry.data.receipts &&
      props.entry._id === oldProps.entry._id &&
      !_.isEqual(props.entry.data.receipts, oldProps.entry.data.receipts)
    ) {
      DataBus.emit(DataBusSubKeys.RELOAD, {
        identifiers: [`${CASHBUDGET_ATTACHMENT_COMP}_${props.entry._id}`],
      });
    }
  }

  unlinkAttachment(receiptId: string, filename: string) {
    const { patchTableData, entry } = this.props;
    ModalManager.confirm({
      message: i18n.t(
        "Attachment.unlinkAttachment.Description",
        "Willst du den Anhang entfernen?"
      ),
      title: filename,
      confirmButtonText: i18n.t(
        "Attachment.unlinkAttachment.button",
        "Anhang entfernen"
      ),
      onConfirm: () => {
        patchTableData(
          `${CASHBUDGET_ATTACHMENT_COMP}_${entry._id}`,
          receiptId,
          {
            _deleting: true,
          }
        );

        HTTP.post({
          target: "EMPTY",
          url: "liquiplanservice/receipt/disconnect",
          bodyParams: {
            entryID: entry._id,
            receiptID: receiptId,
          },
        })
          .then((data) => {
            patchTableData(
              `${CASHBUDGET_ATTACHMENT_COMP}_${entry._id}`,
              receiptId,
              { _deleted: true, _deleting: false }
            );
          })
          .catch((err) => {
            patchTableData(
              `${CASHBUDGET_ATTACHMENT_COMP}_${entry._id}`,
              receiptId,
              { _deleting: false }
            );
          });
      },
    });
  }
  revertUnlinkAttachment(receiptId: string) {
    const { patchTableData, entry } = this.props;
    patchTableData(`${CASHBUDGET_ATTACHMENT_COMP}_${entry._id}`, receiptId, {
      _connecting: true,
    });

    HTTP.post({
      target: "EMPTY",
      url: "liquiplanservice/receipt/assign",
      bodyParams: {
        entryID: entry._id,
        receiptID: receiptId,
      },
    })
      .then((data) => {
        patchTableData(
          `${CASHBUDGET_ATTACHMENT_COMP}_${entry._id}`,
          receiptId,
          { _deleted: false, _connecting: false }
        );
      })
      .catch((err) => {
        patchTableData(
          `${CASHBUDGET_ATTACHMENT_COMP}_${entry._id}`,
          receiptId,
          { _connecting: false }
        );
      });
  }

  renderAttachmentObject(id: string, obj: any, value: CDNBucketObject) {
    return (
      <div
        key={id}
        className={`attachement-row ${obj._deleted ? "deleted" : ""}`}
      >
        <div
          className={`filetype`}
          onClick={() => this.downloadAttachment(id, value)}
        >
          <BfIcon data={this.getIconType(value.filename)} type={"bf"} />
        </div>
        <div
          className={`name`}
          onClick={() => this.downloadAttachment(id, value)}
        >
          <span title={value.filename}>{value.filename}</span>
        </div>

        <div className={`actions`}>
          <BFButton
            loading={obj._deleting}
            size="xs"
            appearance={"clear-on-white"}
            icon={{ type: "bf", data: "close" }}
            onClick={() => this.unlinkAttachment(id, value.filename)}
          />
        </div>

        <div className={`deleted-overlay`}>
          <div>{i18n.t("Attachment.deleted", "Gelöscht")}</div>
        </div>
      </div>
    );
  }

  downloadAttachment(id: string, value: CDNBucketObject) {
    CDNService.fetchCDNLink({
      assetField: value.field,
      assetId: id,
      assetType: AssetTypes.CashBudget.Attachment,
      fileKey: value.key,
      hasFolderReadPermissions: true,
    })
      .then((url) => {
        this.props.openOrDownloadDocument(
          url,
          FileUtils.getFiletype(value.filename),
          value.filename
        );

        // FileUtils.downloadFile(url, value.filename);
      })
      .catch((err) => {});
  }
  render() {
    // const { attachmentsOpen } = this.state;
    const { entry } = this.props;

    if (
      !entry ||
      ((!entry.data.receipts || entry.data.receipts.length === 0) &&
        (!entry.data.linkedAsset || entry.data.linkedAsset.length === 0))
    ) {
      return null;
    }

    return (
      <div className={`cashbudget-attachments`}>
        {((entry.data.linkedAsset && entry.data.linkedAsset.length > 0) ||
          this.props.isUploading) && (
          <div className={`linked-asset-container`}>
            <div className={`cat-header`}>
              <div className={`title`}>
                {i18n.t("Attachment.linked_assets", "Verknüpfungen")}
              </div>
              <div className={`fill`} />

              <CBValidationIndicator placement="left" booking={entry} />
            </div>
            <CashBudgetConnectionsInvoiceSum
              assets={entry.data.linkedAsset}
              value={entry.data.value}
            />
            {entry.data.linkedAsset
              .filter((e) => e.assetId && e.assetType) // because fabi messed up our local db
              .map((asset, index) => {
                return (
                  <CashBudgetLinkedItem
                    booking={entry}
                    linkedAsset={asset}
                    key={index}
                  />
                );
              })}

            {this.props.isUploading && (
              <div className={`uploading-container`}>
                <Loader
                  size="xs"
                  content={i18n.t(
                    "Attachment.isUploading",
                    "Anhänge werden hochgeladen"
                  )}
                />
              </div>
            )}
          </div>
        )}
        {entry.data.receipts && entry.data.receipts.length > 0 ? (
          <div className={`attachement-container open`}>
            <div className={`cat-header`}>
              <div className={`title`}>
                {i18n.t("Attachment.attachment_multiple", "Anhänge")}
              </div>
            </div>
            <div
              style={{ height: Math.min(150, entry.data.receipts.length * 32) }}
            >
              <TableComponent
                initialReload={false}
                appearance={"clear"}
                ignoreInteractionHighlights={true}
                ignoreTableSelection={true}
                useEndlessScrolling={true}
                dataUrl={"/api/asset/list/cashbudget-receipt"}
                post
                hideColumnHeaders={true}
                hideConfigMenu={true}
                pageSize={30}
                identifier={`${CASHBUDGET_ATTACHMENT_COMP}_${entry._id}`}
                onDataUpdate={(data: TableCache) => {
                  this.setState({
                    countAttachments: data.total ? data.total : null,
                  });
                }}
                hideSelectionControls={true}
                striped={false}
                // initialSort={this.props.filterBy}
                matchQuery={{
                  type: "and",
                  query: [
                    {
                      name: "_id",
                      op: "in",
                      type: "op",
                      value: entry.data.receipts,
                    },
                  ],
                }}
                columns={{
                  file: {
                    columnHeaderTextKey: "",
                    cellRenderer: (row, key, columnConf) =>
                      Object.values(row.original.data["files"]).map((value) =>
                        this.renderAttachmentObject(
                          row.original._id,
                          row.original,
                          value as CDNBucketObject
                        )
                      ),
                  } as any,
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => {
  return {
    userId: state.global.user._id,
    debug: state.uiConfig.general[DefaultUIConfigs.DEBUG],
  };
};

export default connect(mapStateToProps, {
  clearFlexCacheData,
  patchTableData,
  openOrDownloadDocument,
})(CashBudgetAttachments) as any;

import { nanoid } from "nanoid";
import { useState } from "react";
import { AssetTypes } from "../../model/AssetTypes";
import BFStatus from "../abstract-ui/data/status/BFStatus";
import BFOverlay from "../abstract-ui/general/whisper/BFOverlay";
import "./CommentCountBubble.scss";
import CommentsList from "./CommentsList";

interface CommentCountBubbeProps {
  assetType: string;
  assetId: string;
  type: string;
  numComments: number;
  onClick?: () => void;
}
/**
 * Renders a bubble displaying the number of comments.
 *
 * @param {CommentCountBubbleProps} props - The props object containing the necessary data for rendering the bubble.
 * @return {ReactNode} The rendered bubble component.
 */
const CommentCountBubble = (props: CommentCountBubbeProps) => {
  const [tempId] = useState(nanoid());

  return props.numComments > 0 ? (
    <BFOverlay
      enterable
      trigger="hover"
      speaker={
        <div className={`comment-count-bubble-overlay`}>
          <CommentsList
            assetType={AssetTypes.Invoice}
            assetId={props.assetId}
            mode="list"
            identifier={tempId}
            type={props.type}
            clearOnUnmount
            allowMailUpload={false}
            hideResubmissionActionForMail={true}
            disableEmailCommunication
            viewOnly
          />
        </div>
        // <PaymentCommentsOverlay
        //   comments={node.comments.map((e) => {
        //     return e as unknown as {
        //       comment: string;
        //       date: Date;
        //       user: string;
        //     };
        //   })}
        // />
      }
      placement="auto"
      delay={350}
    >
      <div className="comments-counter">
        <BFStatus
          showIcon
          icon={{
            data: "messages-bubble",
            type: "light",
          }}
          size="xs"
          label={"" + props.numComments}
          color="#0492c2"
        />
      </div>
    </BFOverlay>
  ) : null;
};

export default CommentCountBubble;

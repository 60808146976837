import { initGlobalDashboardElements } from "../../../dashboard/GlobalDashboardComponents";
import { Application } from "../../../model/db/Application";
import { UserConfig } from "../../../model/db/User";
import ObjectTools from "../../../modules/generic-forms/util/ObjectTools";
import GlobalActions from "../../../services/GlobalActions";
import GlobalDashboard from "../../../services/GlobalDashboard";
import {
  ADD_TRUSTED_DEVICE,
  GlobalAction,
  MERGE_GLOBAL_CONFIG,
  REMOVE_TRUSTED_DEVICE,
  SET_ASSET_TYPE_CONF,
  SET_CONTEXT_DATA,
  SET_GLOBAL_CACHE_DATA,
  SET_MANDATOR_DATA,
  SET_USER_DATA,
  UPDATE_USER_CONFIG,
} from "../../actions/global/global-actions-types";
import { AppState } from "../../store";
import { GlobalConfig, GlobalReducer, MandatorData } from "./GlobalInterface";
import { StandardForms } from "./StandardForms";

const initialState: GlobalReducer = {
  user: null,
  mandator: null,
  context: null,
  configInitialized: false,
  config: {
    standardForms: StandardForms,
    assetTypeForms: {},
  },
  cache: {},
};

export default function (
  state = initialState,
  action: GlobalAction,
  root: AppState
): GlobalReducer {
  switch (action.type) {
    case SET_ASSET_TYPE_CONF:
      const assetTypeForms = {};
      action.assetTypeConfigs.forEach((entry) => {
        assetTypeForms[entry.name] = {
          properties: entry.properties,
          formDefinitions: entry.formDefinition,
        };
      });

      return {
        ...state,
        config: {
          ...state.config,
          assetTypeForms: ObjectTools.readJsonFunctions(assetTypeForms) as any,
        },
      };
    case SET_CONTEXT_DATA:
      return {
        ...state,
        context: action.context,
      };

    case SET_MANDATOR_DATA:
      if (action.mandator === null) {
        return {
          ...state,
          mandator: null,
        };
      } else {
        return {
          ...state,
          mandator: state.mandator
            ? (ObjectTools.mergeObjects(
                state.mandator,
                action.mandator
              ) as MandatorData)
            : action.mandator,
        };
      }
    case MERGE_GLOBAL_CONFIG:
      return {
        ...state,
        configInitialized: true,
        config: ObjectTools.mergeObjects(
          state.config,
          action.config
        ) as GlobalConfig,
      };
    case SET_GLOBAL_CACHE_DATA:
      const prestate = state.cache[action.key];
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.key]: {
            ttl: action.ttl !== undefined ? action.ttl : prestate?.ttl,
            timestamp:
              action.timestamp !== undefined
                ? action.timestamp
                : prestate?.timestamp,
            data: action.data !== undefined ? action.data : prestate?.data,
            error: action.error !== undefined ? action.error : prestate?.error,
            loading:
              action.loading !== undefined ? action.loading : prestate?.loading,
          },
        },
      };
    case UPDATE_USER_CONFIG:
      return {
        ...state,
        user: {
          ...state.user,
          config: ObjectTools.mergeObjects(
            state.user.config,
            action.config
          ) as UserConfig,
        },
      };
    case SET_USER_DATA:
      if (action.user == null) {
        return {
          ...state,
          user: null,
        };
      } else {
        const currentUser = state.user || {};
        GlobalDashboard.reset();
        initGlobalDashboardElements();

        if (action.user.mandator_info) {
          action.user.mandator_info.apps = action.user.mandator_info.apps.map(
            (app) => ObjectTools.readJsonFunctions(app) as Application
          );
          // todo add global dashboard components here
          action.user.mandator_info.apps.forEach((app) => {
            GlobalDashboard.registerDashboardComponentsFromApp(app);

            if (app.global?.actions) {
              Object.entries(app.global.actions).forEach(
                ([actionId, functionId]) => {
                  GlobalActions.registerGlobalAction(
                    actionId,
                    functionId,
                    app._id,
                    {
                      appName: app.name,
                      appConfig: app,
                    }
                  );
                }
              );
            }
          });
        }

        // if (action.user.groups && action.user.groups.length > 0 && typeof action.user.groups[0] === "string") {
        //     action.user["groups"] = undefined;
        // }

        return {
          ...state,
          user: {
            ...currentUser,
            ...action.user,
          },
        };
      }

    case ADD_TRUSTED_DEVICE:
      const { type, ...deviceProps } = action;
      return {
        ...state,
        user: {
          ...state.user,
          devices: [
            ...(state.user.devices ? state.user.devices : []),
            {
              ...deviceProps,
            },
          ],
        },
      };
    case REMOVE_TRUSTED_DEVICE:
      return {
        ...state,
        user: {
          ...state.user,
          devices: state.user.devices.filter(
            (e) => e.deviceID !== action.deviceID
          ),
        },
      };
    default:
      return state;
  }
}

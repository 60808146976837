import classNames from "classnames";
import BFPDFMarker, { BFPDFMarkerPropsPdfViewer } from "./BFPDFMarker";
import "./BFPDFMarkerSection.scss";

export type BFPDFMarkerSectionProps = BFPDFMarkerPropsPdfViewer & {
  marginBottom?: number;
};
const BFPDFMarkerSection = (props: BFPDFMarkerSectionProps) => {
  return (
    <div
      className={classNames(`bf-pdf-marker-section`)}
      style={{
        marginBottom: props.marginBottom,
      }}
    >
      <div className={`border-line`}></div>
      <BFPDFMarker
        {...props}
        children={
          props.children ? (
            <div className={`section-content-wrapper`}>{props.children}</div>
          ) : null
        }
      />
    </div>
  );
};

export default BFPDFMarkerSection;

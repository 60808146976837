import { Loader } from "rsuite";
import BFPlaceholder from "../../modules/abstract-ui/general/Placeholder/BFPlaceholder";
import { useTeamCache } from "../../redux/hooks";

import { AdminUser } from "../../apps/administration/AdminInterfaces";
import "./AssetLoader.scss";

type Props = {
  render: (data: AdminUser) => JSX.Element;
  renderLoading?: () => JSX.Element;
  renderError?: (err: any) => JSX.Element;
  id?: string;
  silentReload?: boolean;

  placeholderProps?: {
    width: number | string;
  };
};

const TeamLoader = (props: Props) => {
  const cache = useTeamCache(props.id, props.silentReload);
  if (cache.state === "cached") {
    return props.render((cache as any).data);
  }
  if (cache.state === "error") {
    if (props.renderError) {
      return props.renderError(cache.error);
    } else {
      return (
        <div className="asset-loader error">Fehler beim Laden der Daten</div>
      );
    }
  }

  if (props.renderLoading) {
    return props.renderLoading();
  } else {
    if (props.placeholderProps) {
      return (
        <BFPlaceholder loading width={props.placeholderProps.width}>
          -
        </BFPlaceholder>
      );
    } else {
      return (
        <div className="asset-loader loading">
          <Loader />
        </div>
      );
    }
  }
};

export default TeamLoader;

import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import { withPDFBlock } from "../PDFBlock";
import { PDFBlockFooter } from "../PDFBlockFooter";
import i18n from "@/i18n";
import { LineConfig } from "../../services/types/pdfConfigBlockTypes";
import { useState } from "react";
import { usePDFConstructor } from "../../context/PDFConstructorContext";

import { useBlockChanges } from "../PDFBlockHooks";
import { PDFCommonInput } from "../Common/PDFCommonInput";

const PDFLineInput = withPDFBlock<LineConfig>(
  ({ value, parentId }) => {
    const [form, setForm] = useState(value);
    const [_, { deleteBlock, saveBlock }] = usePDFConstructor();

    useBlockChanges(value, form);

    return (
      <div>
        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t("Component.PDFConstructor.Block.Inputs.LineInput", "Linieneingabe")
);

export { PDFLineInput };

import { nanoid } from "nanoid";
import Log from "../../../debug/Log";

const VARIABLE_REGEX = /(\#\{[_a-zA-Z0-9\.\|]*\})|(\$\{[_a-zA-Z0-9\.\|]*\})/g;
const FUNCTION_IDENTIFIER = "_JS_FUNC_ID:";

type SendEvent =
  | {
      eventKey: string;
      condition?: string;
      isState?: boolean;
      data: any;
    }
  | ((data) => void);

class ExpressionHelperClass {
  functions: { [key: string]: Function } = {};

  registerFunction(func: Function) {
    const nanoId = nanoid();
    this.functions[nanoId] = func;

    return `${FUNCTION_IDENTIFIER}${nanoId}`;
  }

  getVariableMatches(text: string) {
    return text.match(VARIABLE_REGEX);
  }

  public handleEvents(
    additionalEvents: SendEvent[] | { [key: string]: SendEvent },
    values: any
  ) {
    if (additionalEvents) {
      let valuesToEvaluate: SendEvent[] = [];
      if (Array.isArray(additionalEvents)) {
        valuesToEvaluate = additionalEvents;
      } else {
        valuesToEvaluate = Object.values(additionalEvents);
      }

      valuesToEvaluate.forEach((event) => {
        let sendEvent = true;
        if (typeof event === "function") {
          event(values);
        } else if (typeof event === "string") {
          (window as any).DataBus.emit(
            ExpressionHelper.evaluateExpression(event, values),
            event
          );
        } else {
          if (event.condition) {
            sendEvent = ExpressionHelper.evaluateExpression(
              event.condition,
              values
            );
          }
          if (sendEvent) {
            (window as any).DataBus.emit(
              ExpressionHelper.evaluateExpression(event.eventKey, values),
              typeof event.data === "string"
                ? ExpressionHelper.evaluateExpression(event.data, values)
                : event.data,
              event.isState
            );
          }
        }
      });
    }
  }

  private replace(
    text: string,
    values: { [key: string]: any } = {},
    data: { [key: string]: any } = {},
    stringify: boolean
  ) {
    text = text.replace(VARIABLE_REGEX, (match) => {
      const variableKey = match.substr(2, match.length - 3);

      const value = match[0] === "#" ? data[variableKey] : values[variableKey];

      if (value !== undefined) {
        return stringify ? JSON.stringify(value) : value;
      } else {
        return stringify ? "undefined" : "";
      }
    });
    return text;
  }

  replaceVariables(
    text: string,
    values: { [key: string]: any } = {},
    data: { [key: string]: any } = {}
  ) {
    let output = text;
    text = this.replace(text, values, data, false);
    return text;
  }

  evaluateExpression(
    expression: string | Function,
    values: { [key: string]: any },
    data?: { [key: string]: any }
  ) {
    try {
      if (typeof expression === "function") {
        return expression(values, data);
      } else {
        if (expression.indexOf(FUNCTION_IDENTIFIER) === 0) {
          const func =
            this.functions[expression.substr(FUNCTION_IDENTIFIER.length)];

          return func(values, data);
        } else {
          let expressionString = expression;
          expressionString = this.replace(expressionString, values, data, true);

          // Log.state(expression, expressionString, values, data);
          return eval(
            `try{(${expressionString})} catch(err) { console.error("error expression", err); throw err; }`
          );
        }
      }
    } catch (error) {
      Log.error("evaluation error", expression, values, data, error);
      throw error;
    }
  }

  callFunction(functionId: string, ...param: any[]) {
    const func = this.functions[functionId.substr(FUNCTION_IDENTIFIER.length)];
    return func(...param);
  }
}

const ExpressionHelper = new ExpressionHelperClass();
(window as any).expressionHelper = ExpressionHelper;
export default ExpressionHelper;

import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import { FV } from "@/components/Form/Validation/FormValidators";
import StructLoader from "@/components/StructLoader/StructLoader";
import i18n from "@/i18n";
import {
  ContactEmail,
  ContactPerson,
  ContactPhone,
  ContactType,
} from "@/model/db/Contact";
import BFSection from "@/modules/abstract-ui/data/section/BFSection";
import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import InvoiceStruct from "@/redux/actions/struct/implemented/InvoiceStruct";
import LanguageService from "@/services/LanguageService";
import classNames from "classnames";
import { Field } from "react-final-form";
import "./ContactRelationFormFields.scss";

interface ContactRelationFormFieldsProps {
  type: string;
  phones: ContactPhone[];
  emails: ContactEmail[];
  contactPersons: ContactPerson[];

  prefix?: string;
  integrated?: boolean;
  contactTypes: ContactType[];
}
const ContactRelationFormFields = (props: ContactRelationFormFieldsProps) => {
  const contactPerson = props.contactPersons?.filter((e) => e) || [];
  const phones = props.phones?.filter((e) => e) || [];
  const emails = props.emails?.filter((e) => e) || [];

  const prefixUsed = props.prefix ? `${props.prefix}.` : "";

  return (
    <StructLoader
      structTypes={["invoice"]}
      unitType={props.type}
      render={() => (
        <div
          className={classNames(`contact-relation-form-fields`, {
            integrated: props.integrated,
          })}
        >
          <FormFieldValues names={["entity"]}>
            {([entity]) => (
              <>
                <BFSection
                  collapsible
                  small
                  className={`contact-fields`}
                  title={i18n.t(
                    "ContactRelationFormFields.contactVia",
                    "Kontakt"
                  )}
                >
                  <>
                    <div
                      className={`__sub-text description padding-bottom-10 padding-top-10`}
                    >
                      {i18n.t(
                        "ContactRelationFormFields.contactViaDescription",
                        "Wählen Sie aus, wie der Kontakt von dieser Gesellschaft aus kontaktiert werden soll."
                      )}
                    </div>
                    <Field name={`${prefixUsed}contactMail`}>
                      {({ input, meta }) => (
                        <div className={`__field`}>
                          <BFChooserSelect
                            data={emails.map((e) => ({
                              label: e.email,
                              subLabel: e.note,
                              value: e.id,
                            }))}
                            {...input}
                            cleanable
                            label={i18n.t(
                              "ContactRelationFormFields.contactMail",
                              "E-Mail-Adresse"
                            )}
                          />
                        </div>
                      )}
                    </Field>
                    <Field name={`${prefixUsed}contactPhone`}>
                      {({ input, meta }) => (
                        <div className={`__field`}>
                          <BFChooserSelect
                            data={phones.map((e) => ({
                              label: e.phone,
                              subLabel: e.note,
                              value: e.id,
                            }))}
                            {...input}
                            cleanable
                            label={i18n.t(
                              "ContactRelationFormFields.contactPhone",
                              "Telefon"
                            )}
                          />
                        </div>
                      )}
                    </Field>
                    <Field name={`${prefixUsed}contactPerson`}>
                      {({ input, meta }) => (
                        <div className={`__field`}>
                          <BFChooserSelect
                            data={contactPerson.map((e) => ({
                              label: [e.firstName, e.lastName]
                                .filter(Boolean)
                                .join(" "),
                              subLabel: e.note,
                              value: e.id,
                            }))}
                            {...input}
                            cleanable
                            label={i18n.t(
                              "ContactRelationFormFields.contactPerson",
                              "Kontaktperson"
                            )}
                          />
                        </div>
                      )}
                    </Field>
                  </>
                </BFSection>

                {props.contactTypes.includes("INVOICE") && (
                  <BFSection
                    collapsible
                    small
                    className={`invoice-fields`}
                    title={i18n.t(
                      "ContactRelationFormFields.incomingInvoice",
                      "Eingehende Rechnung"
                    )}
                  >
                    <RelationInvoiceFields
                      basePrefix={prefixUsed}
                      type={props.type}
                      entityId={entity}
                      prefix="invoice"
                    />
                  </BFSection>
                )}

                {props.contactTypes.includes("BILL") && (
                  <BFSection
                    collapsible
                    small
                    className={`invoice-fields`}
                    title={i18n.t(
                      "ContactRelationFormFields.outgoingInvoice",
                      "Ausgehende Rechnung"
                    )}
                  >
                    <RelationInvoiceFields
                      basePrefix={prefixUsed}
                      type={props.type}
                      entityId={entity}
                      prefix="bill"
                    />
                  </BFSection>
                )}
              </>
            )}
          </FormFieldValues>
        </div>
      )}
    />
  );
};

export default ContactRelationFormFields;

const RelationInvoiceFields = (props: {
  entityId: string;
  prefix: "bill" | "invoice";
  type: string;
  basePrefix?: string;
}) => {
  const prefix = props.basePrefix
    ? `${props.basePrefix}${props.prefix}.`
    : `${props.prefix}.`;
  return (
    <div className={`padding-top-10`}>
      <div className={`__flex`}>
        <Field name={`${prefix}category`}>
          {({ input, meta }) => (
            <div className={`__field  __flex-1`}>
              <BFChooserSelect
                data={(
                  InvoiceStruct.getInvoiceTypesByUnitAndDirection(
                    props.type,
                    props.prefix === "bill" ? "OUTGOING" : "INCOMING"
                  ) || []
                )
                  .filter((e) => e)
                  .map((e) => ({
                    label: LanguageService.translateLabel(e.data.displayName),
                    value: e._id,
                  }))}
                {...input}
                cleanable
                label={i18n.t("Invoice.invoiceType")}
                info={i18n.t(
                  "ContactRelationFormFields.selectInvoiceType",
                  "Wählen Sie den Rechnungstyp aus, der standardmäßig ausgewählt werden soll, wenn eine Rechnung mit diesem Kontakt verknüpft ist. Der Rechnungstyp kann geändert werden, wenn die Rechnung in die Genehmigung geschickt wird."
                )}
              />
            </div>
          )}
        </Field>
        <Field name={`${prefix}termOfPayment`}>
          {({ input, meta }) => (
            <div className={`__field  __flex-1`}>
              <BFChooserSelect
                data={[]}
                {...input}
                cleanable
                label={i18n.t(
                  "ContactRelationFormFields.termOfPayment",
                  "Zahlungsbedingung"
                )}
                info={i18n.t(
                  "ContactRelationFormFields.termOfPayment",
                  "Wählen Sie die Zahlungsbedingung aus, die standardmäßig ausgewählt werden soll, wenn eine Rechnung mit diesem Kontakt verknüpft ist. Die Zahlungsbedingung kann geändert werden, wenn die Rechnung in die Genehmigung geschickt wird."
                )}
              />
            </div>
          )}
        </Field>
      </div>
      <div className={`__flex  `}>
        <Field name={`${prefix}customerNumber`}>
          {({ input, meta }) => (
            <div className={`__field __flex-1`}>
              <BFInput
                {...input}
                label={i18n.t(
                  "ContactRelationFormFields.customerNumber",
                  "Kundennummer"
                )}
                placeholder={i18n.t(
                  "ContactRelationFormFields.customerNumberPlaceholder",
                  "Kundennummer"
                )}
              />
            </div>
          )}
        </Field>
        <Field name={`${prefix}accountNumber`}>
          {({ input, meta }) => (
            <div className={`__field __flex-1`}>
              <BFInput
                type="numericString"
                useTempValue
                placeholder={i18n.t(
                  "ContactRelationFormFields.accountNumberPlaceholder",
                  "Kontonummer"
                )}
                {...input}
                label={i18n.t(
                  "ContactRelationFormFields.accountNumber",
                  "Buchungskonto"
                )}
              />
            </div>
          )}
        </Field>
      </div>
      <div className={`__flex padding-top-10`}>
        <Field name={`${prefix}sepaActive`}>
          {({ input, meta }) => (
            <div className={`__field`}>
              <BFCheckbox
                checked={input.value}
                onChange={(_, checked) => input.onChange(checked)}
              >
                {i18n.t(
                  "ContactRelationFormFields.sepaActive",
                  "Lastschriftmandat vorhanden"
                )}
              </BFCheckbox>
            </div>
          )}
        </Field>
      </div>
      <FormFieldValues names={[`${prefix}sepaActive`]}>
        {([sepaActive]) => {
          if (sepaActive) {
            return (
              <div>
                <div className={`__flex`}>
                  <Field
                    name={`${prefix}sepa.referenceNumber`}
                    validate={FV.compose(FV.required())}
                  >
                    {({ input, meta }) => (
                      <div className={`__field __flex-1`}>
                        <BFInput
                          {...input}
                          {...FV.getValidation(meta)}
                          label={i18n.t(
                            "ContactRelationFormFields.sepaReferenceNumber",
                            "Mandat-Referenznummer"
                          )}
                          placeholder={i18n.t(
                            "ContactRelationFormFields.sepaReferenceNumberPlaceholder",
                            "Mandat-Referenznummer"
                          )}
                        />
                      </div>
                    )}
                  </Field>
                  <Field
                    name={`${prefix}sepa.validTill`}
                    validate={FV.compose(FV.required())}
                  >
                    {({ input, meta }) => (
                      <div className={`__field __flex-1`}>
                        <BFDatefield
                          {...input}
                          {...FV.getValidation(meta)}
                          label={i18n.t(
                            "ContactRelationFormFields.sepaValidTill",
                            "Gültig bis"
                          )}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            );
          } else {
            return null;
            // removed because we will make it later
            if (props.prefix === "invoice") {
              return null;
            }
            return (
              <div>
                <div className={`generate-sepa-description`}>
                  <span className={`__sub-text padding-right-10`}>
                    {i18n.t(
                      "ContactRelationFormFields.generateSepaDescription",
                      "Erstellen Sie ein SEPA-Lastschriftmandat, lassen Sie den Kunden unterschreiben und laden Sie es hier hoch."
                    )}
                  </span>
                  <BFButton
                    inline
                    className={`margin-top-6 margin-bottom-6`}
                    onClick={() => {}}
                    appearance="outline"
                    size="xs"
                  >
                    {i18n.t(
                      "ContactRelationFormFields.generateSepa",
                      "SEPA-Lastschriftmandat erstellen"
                    )}
                  </BFButton>
                </div>
                {/* <div className={`padding-top-10`}>
                </div> */}
              </div>
            );
          }
        }}
      </FormFieldValues>
    </div>
  );
};

import { FV } from "@/components/Form/Validation/FormValidators";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import LanguageService from "@/services/LanguageService";
import { FieldRenderProps } from "react-final-form";
import { Customfield_select } from "../CustomField.interface";
// import "./CFSelect.scss";

interface CFSelectProps {
  data: Customfield_select;
  field: FieldRenderProps<any, HTMLElement>;
}
const CFSelect = (props: CFSelectProps) => {
  return (
    <BFChooserSelect
      label={LanguageService.translateLabel(props.data.displayName)}
      {...props.field.input}
      info={LanguageService.translateLabel(props.data.info)}
      {...FV.getValidation(props.field.meta)}
      data={props.data.options}
    />
  );
};

export default CFSelect;

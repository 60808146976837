import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFPlaceholder from "../../../../../../../modules/abstract-ui/general/Placeholder/BFPlaceholder";
import "./SingleKPIValue.scss";

interface Props {
  name: string;
  value?: string;
  loading: boolean;
  error?: boolean;
  onReload?: () => void;
}
const KPISingleValueCard = (props: Props) => {
  return (
    <div className={`kpi-single-value __card ${props.error ? "error" : ""}`}>
      <div className="label">{props.name}</div>
      {props.error ? (
        <div>
          <BFButton
            appearance={"clear-on-white"}
            icon={{ type: "light", data: "button-refresh-arrow" }}
          />
        </div>
      ) : (
        <BFPlaceholder className="value" width={80} loading={props.loading}>
          {props.value}
        </BFPlaceholder>
      )}
    </div>
  );
};
export default KPISingleValueCard;

import classNames from "classnames";
import i18n from "../../../../../i18n";
import BFToggle from "../../../forms/toggle/BFToggle";
import "./OverlayBoolean.scss";
import OverlayTitle from "./OverlayTitle";

export interface BooleanValue {
  show: boolean;
}

interface OverlayBooleanProps {
  title: string;
  value: BooleanValue;
  onChange: (value: BooleanValue) => void;
}

// export const getDefaultIdQueryOptions = (
//   value: IDQueryValue,
//   searchTerm: string,
//   filterKey: string,
//   typeLabel: string,
//   additionalKeys?: string[]
// ) => {
//   let options: FilterSearchOption[] = [];
//   if (searchTerm) {
//     const matches = searchTerm.match(ID_EXTRACT_REGEX);
//     if (matches && matches.length > 0) {
//       const onSelect = (
//         optionValue: any,
//         currentValue: any,
//         onChange: (value: any) => void
//       ) => {
//         onChange({ ...(currentValue || {}), ...optionValue });
//       };
//       if (!hasValue(value)) {
//         options.push({
//           filterKey,
//           valueKey: `_${filterKey}_eq`,
//           label: `${matches[0]}`,
//           value: {
//             value: matches[0],
//           },
//           labelAsString: `${matches[0]} ${typeLabel}`,
//           onSelect,
//           typeLabel,
//           searchKeys: [...(additionalKeys || []), ">"],
//         });
//       }
//     }
//   }
//   return options;
// };
export const generateBooleanLabel = (
  typeLabel: string,
  value: BooleanValue
) => ({
  typeLabel: typeLabel,
  valueLabel:
    value === null || value === undefined
      ? "-"
      : value.show === true
      ? i18n.t("Global.Labels.true")
      : i18n.t("Global.Labels.false"),
});
const OverlayBoolean = (props: OverlayBooleanProps) => {
  return (
    <div className={classNames(`overlay-boolean`)}>
      <OverlayTitle title={props.title} onClear={() => props.onChange(null)} />

      <div className="content">
        <BFToggle
          label={props.value ? "Anzeigen" : "Ausblenden"}
          checked={props.value?.show}
          onChange={(checked) => props.onChange({ show: checked })}
        />
      </div>
    </div>
  );
};

export default OverlayBoolean;

import classNames from "classnames";
import { Nav, NavProps } from "rsuite";
import "./BFNav.scss";

type BFNavProps = Omit<NavProps, "appearance"> & {
  appearance?: "default" | "subtle" | "tabs" | "pills" | `bf`;
};
const BFNav = (props: BFNavProps) => {
  return (
    <Nav
      {...props}
      appearance={props.appearance === "bf" ? "pills" : props.appearance}
      className={classNames("bf-nav", props.className, {
        "appearance-bf": props.appearance === "bf",
      })}
    />
  );
};

export default BFNav;

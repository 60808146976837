import { FV } from "@/components/Form/Validation/FormValidators";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import LanguageService from "@/services/LanguageService";
import { FieldRenderProps } from "react-final-form";
import { Customfield_date } from "../CustomField.interface";
// import "./CFDate.scss";

interface CFDateProps {
  data: Customfield_date;
  field: FieldRenderProps<any, HTMLElement>;
}
const CFDate = (props: CFDateProps) => {
  return (
    <BFDatefield
      info={LanguageService.translateLabel(props.data.info)}
      label={LanguageService.translateLabel(props.data.displayName)}
      {...props.field.input}
      {...FV.getValidation(props.field.meta)}
    />
  );
};

export default CFDate;

import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Animation } from "rsuite";
import ApplicationSidenav from "../../../components/ApplicationSidenav/ApplicationSidenav";
import { setUiConfig } from "../../../redux/actions/ui-config/ui-config-actions";
import { AppState } from "../../../redux/store";
import { AbstractStylableComponent } from "../../../utils/abstracts/AbstractStylableComponent";
import { IComponent } from "../IComponent";
import "./ApplicationSidenavLayoutRoutes.scss";

const { Collapse } = Animation;

type ApplicationSidenavLayoutConf = {
  appearance: "default" | "grey";
  sidenavComponent: IComponent;
  centerComponent: IComponent;
  idleComponents?: { [key: string]: IComponent };
};

type Props = {
  setUiConfig: (key: string, value: any) => void;
} & ApplicationSidenavLayoutConf &
  RouteComponentProps &
  WithTranslation;

type States = {};

class ApplicationSidenavLayoutRoutes extends AbstractStylableComponent<
  Props,
  States
> {
  componentDidMount() {
    this.setMobileHeader();
  }

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;

    if (prevProps.location.pathname !== location.pathname) {
      this.setMobileHeader();
    }
  }
  componentWillUnmount() {
    // this.props.setUiConfig(DefaultUIConfigs.MOBILE_HEADER, null);
  }
  setMobileHeader() {
    const { setUiConfig, location } = this.props;

    // if (activeSubpage) {
    // 	setUiConfig(DefaultUIConfigs.MOBILE_HEADER, {
    // 		icon: activeSubpage.icon,
    // 		textKey: activeSubpage.displayNameSelector
    // 	});
    // } else {
    // 	setUiConfig(DefaultUIConfigs.MOBILE_HEADER, null);
    // }
  }

  render() {
    const {
      match,
      location,
      i18n,
      centerComponent,
      sidenavComponent,
      idleComponents,
    } = this.props;

    return (
      <>
        <div className="application-sidenav-layout app-layout content">
          <div className={"sidenav"}>
            <ApplicationSidenav
              appearance={this.props.appearance}
              ignoreNavToggle={true}
              noTransition={true}
              renderChildren={(expanded) => {
                return (window as any).ComponentsMapper.createElement(
                  sidenavComponent,
                  this.props.params
                );
              }}
            />
          </div>

          <div className={"app-layout-content"}>
            {(window as any).ComponentsMapper.createElement(
              centerComponent,
              this.props.params
            )}
          </div>
        </div>

        {idleComponents
          ? Object.entries(idleComponents)
              .sort((a, b) =>
                a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
              )
              .map(([key, item]) => {
                return (window as any).ComponentsMapper.createElement(
                  { ...item },
                  null,
                  key
                );
              })
          : null}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

export default withRouter(
  connect(mapStateToProps, { setUiConfig })(
    withTranslation()(ApplicationSidenavLayoutRoutes)
  )
);

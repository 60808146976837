import { ConditionalFormattingRule, Style } from "exceljs";
import StringUtils from "../../utils/StringUtils";

export const getDefaultCurrencyNumberFormat = (currency?: string) => {
  const currencySymbol = currency
    ? StringUtils.getCurrencySymbol(currency)
    : StringUtils.getCurrencySymbol();

  return `#,##0.00 "${currencySymbol}";[Red]-#,##0.00 "${currencySymbol}"`;
};

export const getDefaultAreaNumberFormat = () =>
  `#,##0.00 "${StringUtils.getAreaUnit()}"`;

export interface ExportTableColumn {
  id: string;
  label: string;
  selector: (row: any) => any;
  width: number;
  type: "image" | "string" | "number" | "date";

  height?: number;
  style?: Partial<Style>;
  // cellStyle?: Style;

  // to test
  outlineLevel?: number;

  totalFunction?:
    | "average"
    | "sum"
    | "countNums"
    | "count"
    | "max"
    | "min"
    | "stdDev"
    | "var";

  conditionalFormats?: ConditionalFormattingRule[];
}

export type TableExcelExportOptions = {
  autoFilter?: boolean;
  frozenHeader?: boolean;
  filename?: string;
};

export type ExportDataBlob = {
  [key: string]: any;
};

export type ExportTreeData = {
  name: string;
  data: ExportDataBlob;
  entities: ExportTreeDataEntity[];
};

export type ExportTreeDataEntity = {
  name: string;
  data: ExportDataBlob;
  objects: ExportTreeDataObject[];
};
export type ExportTreeDataObject = {
  name: string;
  data: ExportDataBlob;
  entries: ExportTreeDataEntry[];
};
export type ExportTreeDataEntry = {
  name: string;
  data: ExportDataBlob;
};

import classNames from "classnames";
import arrayMutators from "final-form-arrays";
import { Form } from "react-final-form";
import Collapse from "rsuite/esm/Animation/Collapse";
import DebugDataComponent from "../../../../debug/DebugDataComponent";
import Log from "../../../../debug/Log";
import i18n from "../../../../i18n";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import { HTTP } from "../../../../utils/Http";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import { GFPromptBlock } from "./../../../generic-forms-impl/layout-components/LayoutComponentsImpl";
import "./EZGroup.scss";

interface EZGroupPropsBase {
  value: any;
  readonly?: boolean;

  children: Function;
  validate?: (value: any) => any;
}
interface EZGroupPropsSubmitCallback {
  onSubmit: (value: any) => Promise<any>;
}

interface EZGroupPropsSubmitUrl {
  overwriteHttpMethod?: "put" | "post" | "patch";
  url: string;
  transformSubmitData?: (value: any) => any;
  onSubmitSuccess: (result: any) => void;
  submitErrorMsg?: string;
  submitSuccessMsg?: string;
}
type EZGroupProps = EZGroupPropsBase &
  (EZGroupPropsSubmitCallback | EZGroupPropsSubmitUrl);
const EZGroup = (props: EZGroupProps) => {
  const isReadOnly = props.readonly;

  const onSubmit = async (data: any) => {
    const hasSubmit = (props as EZGroupPropsSubmitCallback).onSubmit;
    if (hasSubmit) {
      await (props as EZGroupPropsSubmitCallback).onSubmit(data);
    } else {
      const propsToUse = props as EZGroupPropsSubmitUrl;
      try {
        const result = await HTTP[propsToUse.overwriteHttpMethod || "post"]({
          url: propsToUse.url,
          target: "EMPTY",
          bodyParams: propsToUse.transformSubmitData
            ? propsToUse.transformSubmitData(data)
            : data,
        });
        if (propsToUse.submitSuccessMsg) {
          DataBusDefaults.toast({
            type: "success",
            text: propsToUse.submitSuccessMsg,
            duration: 2000,
          });
        }
        propsToUse.onSubmitSuccess(result);
      } catch (err) {
        Log.error(err);
        DataBusDefaults.toast({
          type: "error",
          text:
            propsToUse.submitErrorMsg ||
            i18n.t("EZ.Field.SubmitError", "Fehler beim Speichern der Daten"),
          duration: 3000,
        });
      }
    }
  };
  return (
    <Form
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          if (state.formState.values) {
            changeValue(state, field, () => value);
          }
        },
      }}
      validate={props.validate}
      onSubmit={onSubmit}
      initialValues={props.value}
      render={(renderProps) => (
        <form onSubmit={renderProps.handleSubmit}>
          <DebugDataComponent data={renderProps.values} />
          <div
            className={classNames(`ez-group`, { active: renderProps.dirty })}
          >
            <GFPromptBlock />
            <div className={`form`}>
              {props.children(
                {
                  ...renderProps,
                },
                isReadOnly
              )}
            </div>

            <Collapse in={renderProps.dirty}>
              <div>
                <div className={`action-row`}>
                  <BFButton
                    size="sm"
                    appearance="outline"
                    type="button"
                    onClick={() => renderProps.form.reset()}
                  >
                    {i18n.t("Global.Button.Cancel", "Abbrechen")}
                  </BFButton>
                  <BFButton
                    size="sm"
                    appearance="primary"
                    type="submit"
                    loading={renderProps.submitting}
                  >
                    {i18n.t("Global.Buttons.save")}
                  </BFButton>
                </div>
              </div>
            </Collapse>
          </div>
        </form>
      )}
    />
  );
};

export default EZGroup;

import classNames from "classnames";
import { useCallback, useState } from "react";
import i18n from "../../../i18n";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import BfIcon from "../../abstract-ui/icon/BfIcon";
import { DocumentStoreDocument } from "../../document-store/DSInterfaces";
import { Comment } from "../Comments";
import CommentAttachments from "./CommentAttachments";
import "./CommentEntryFooter.scss";

export interface CommentEntryFooterProps {
  onReply?: () => void;
  onRedirect?: () => void;
  shareContent?: string;
  onEdit?: () => void;
  onCreateResubmission?: () => void;
  center?: React.ReactNode;
  attachment?: {
    asset: Comment;
    assetType: string;
    attachmentFieldPath: string;
    attachments: DocumentStoreDocument[];
  };
}
const CommentEntryFooter = (props: CommentEntryFooterProps) => {
  const [collapsedAttachments, setCollapsedAttachments] = useState(false);

  const onClickAttachment = useCallback(() => {
    setCollapsedAttachments(!collapsedAttachments);
  }, [collapsedAttachments]);

  if (
    !props.shareContent &&
    !props.onReply &&
    !props.onRedirect &&
    !props.onEdit &&
    !props.onCreateResubmission &&
    !props.attachment
  )
    return null;
  return (
    <>
      <div className={classNames(`comment-entry-footer`)}>
        {props.attachment && props.attachment.attachments?.length > 0 && (
          <BFButton
            onClick={onClickAttachment}
            appearance="link"
            className={classNames(`attachment-button`, {
              collapsed: collapsedAttachments,
            })}
          >
            <BfIcon
              data="attachment"
              type="light"
              size="xs"
              className={`attachment-icon`}
            />
            <span>
              {props.attachment.attachments.length}{" "}
              {props.attachment.attachments.length > 1
                ? i18n.t("CommentsModule.attachmentsMultiple", "Anhänge")
                : i18n.t("CommentsModule.attachmentsSingle", "Anhang")}
            </span>
            <BfIcon
              data="arrow-down-1"
              type="light"
              size="xs"
              className={`dropdown-ident`}
            />
          </BFButton>
        )}
        {props.onEdit && (
          <BFButton appearance="link" onClick={props.onEdit}>
            {i18n.t("Global.Buttons.edit")}
          </BFButton>
        )}
        <div className={`fill`} />
        {props.center}
        <div className={`fill`} />
        {props.shareContent && (
          <BFButton
            appearance="link"
            onClick={() => {
              //todo share props.shareContent
            }}
          >
            {i18n.t("Global.Buttons.share", "Teilen")}
          </BFButton>
        )}
        {props.onCreateResubmission && (
          <BFButton
            appearance="link"
            onClick={() => {
              props.onCreateResubmission();
            }}
          >
            {i18n.t("Global.Buttons.resubmission", "Wiedervorlage erstellen")}
          </BFButton>
        )}
        {props.onRedirect && (
          <BFButton
            appearance="link"
            onClick={() => {
              props.onRedirect();
            }}
          >
            {i18n.t("Global.Buttons.redirect", "Weiterleiten")}
          </BFButton>
        )}
        {props.onReply && (
          <BFButton
            appearance="link"
            onClick={() => {
              props.onReply();
            }}
          >
            {i18n.t("Global.Buttons.reply", "Antworten")}
          </BFButton>
        )}
      </div>
      {props.attachment && props.attachment.attachments?.length > 0 && (
        <CommentAttachments open={collapsedAttachments} {...props.attachment} />
      )}
    </>
  );
};

export default CommentEntryFooter;

import React, { Component, CSSProperties } from "react";
import "./BfCard.scss";

type Props = {
  style?: CSSProperties;
  className?: string;
  noPadding?: boolean;
};

type States = {};

class BfCard extends Component<Props, States> {
  render() {
    return (
      <div
        className={`bf-card-component ${this.props.className} ${
          this.props.noPadding ? "no-padding" : ""
        }`}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

export default BfCard;

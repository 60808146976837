import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators, {
  FV,
} from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFRadioGroup from "@/modules/abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "@/modules/abstract-ui/forms/radio/BfRadio";
import ActivitySelect from "@/modules/activity-components/ActivitySelect";
import StringUtils from "@/utils/StringUtils";
import { useState } from "react";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import {
  RentalAdjustment,
  RentalAdjustmentFormData,
  RentalAgreement,
} from "../../../TenantsInterfaces";
// import './CBRentalAgreementRentAdjustmentForm.scss';

interface CBRentalAgreementRentAdjustmentFormProps {
  onClose: () => void;
  onSubmit: () => void;
  adjustment?: RentalAdjustment;
  rentalAgreement: RentalAgreement;
}
const CBRentalAgreementRentAdjustmentForm = (
  props: CBRentalAgreementRentAdjustmentFormProps
) => {
  const [initialValues] = useState<RentalAdjustmentFormData>({
    rentalAgreementId: props.rentalAgreement._id,
    from: props.adjustment?.data.from || new Date(),
    to: props.adjustment?.data.to || null,
    adjustmentType: props.adjustment?.data.adjustmentType || "reduction",
    rentNet: props.adjustment?.data.rentNet || 0,
    rentGross: props.adjustment?.data.rentGross || 0,
    operatingCostsNet: props.adjustment?.data.operatingCostsNet || 0,
    operatingCostsGross: props.adjustment?.data.operatingCostsGross || 0,
    note: props.adjustment?.data.note || "",
    status: props.adjustment?.data.status || "active",
    linkedAsset: props.adjustment?.data.linkedAsset || [],
  });

  const taxRate =
    props.rentalAgreement.data.rentGross / props.rentalAgreement.data.rentNet;

  return (
    <FormStruct<RentalAdjustmentFormData>
      className={`cb-rental-agreement-rent-adjustment-form`}
      onSubmit={async (values) => {
        if (props.adjustment) {
          await CBRentalService.updateRentAdjustment(
            props.adjustment._id,
            values
          );
        } else {
          await CBRentalService.createRentAdjustment(values);
        }
        props.onSubmit();
        props.onClose();
      }}
      title={i18n.t(
        "cb:RentalAgreementRentAdjustment.Title",
        "Mietreduktionen"
      )}
      initialValues={initialValues}
      onAbort={props.onClose}
      submitText={
        props.rentalAgreement
          ? i18n.t(
              "cb:RentalAgreementRentAdjustment.Save",
              "Mietreduktion speichern"
            )
          : i18n.t(
              "cb:RentalAgreementRentAdjustment.Create",
              "Mietreduktion erstellen"
            )
      }
      render={(formProps) => (
        <>
          <div className={`__flex`}>
            <div className={` __flex-1 __field`}>
              <Field name="from" validate={FV.compose(FV.required())}>
                {({ input, meta }) => (
                  <BFDatefield
                    label={i18n.t(
                      "cb:RentalAgreementRentAdjustment.from",
                      "Von"
                    )}
                    preventNull
                    {...input}
                    {...FV.getValidation(meta)}
                  />
                )}
              </Field>
            </div>
            <div className={` __flex-1  __field`}>
              <FormFieldValues names={["from"]}>
                {([from]) => (
                  <Field name="to" validate={FV.compose(FV.dateAfter(from))}>
                    {({ input, meta }) => (
                      <BFDatefield
                        label={i18n.t(
                          "cb:RentalAgreementRentAdjustment.from",
                          "Von"
                        )}
                        {...input}
                        {...FV.getValidation(meta)}
                      />
                    )}
                  </Field>
                )}
              </FormFieldValues>
            </div>
          </div>
          <div className={`__flex padding-top-10`}>
            <div className={` __flex-1  __field`}>
              <Field
                name="adjustmentType"
                validate={FV.compose(FV.required())}
                defaultValue={"reduction"}
              >
                {({ input, meta }) => (
                  <BFRadioGroup
                    inline
                    {...input}
                    {...FV.getValidation(meta)}
                    onChange={(value) => {
                      if (value === "loss") {
                        formProps.form.batch(() => {
                          formProps.form.change(
                            "rentNet",
                            props.rentalAgreement.data.rentNet
                          );
                          formProps.form.change(
                            "rentGross",
                            props.rentalAgreement.data.rentGross
                          );
                          formProps.form.change(
                            "operatingCostsNet",
                            props.rentalAgreement.data.operatingCostNet
                          );
                          formProps.form.change(
                            "operatingCostsGross",
                            props.rentalAgreement.data.operatingCostGross
                          );
                        });
                      }
                      input.onChange(value);
                    }}
                  >
                    <BfRadio value="reduction">
                      {i18n.t(
                        "cb:RentalAgreementRentAdjustment.reduction",
                        "Mietminderung"
                      )}
                    </BfRadio>
                    <BfRadio value="loss">
                      {i18n.t(
                        "cb:RentalAgreementRentAdjustment.loss",
                        "Mietausfall"
                      )}
                    </BfRadio>
                  </BFRadioGroup>
                )}
              </Field>
            </div>
          </div>
          <FormFieldValues names={["adjustmentType"]}>
            {([adjustmentType]) => (
              <>
                {adjustmentType === "loss" && (
                  <div className={`__sub-text padding-bottom-6`}>
                    {i18n.t(
                      "cb:RentalAgreementRentAdjustment.lossNote",
                      "Mietausfall: Die Miete wird nicht reduziert, sondern die gesamte Miete wird nicht bezahlt."
                    )}
                  </div>
                )}
                {adjustmentType === "reduction" && (
                  <div className={`__sub-text  padding-bottom-6`}>
                    {i18n.t(
                      "cb:RentalAgreementRentAdjustment.reductionNote",
                      "Mietminderung: Geben Sie an, um wieviel die Mietzahlung reduziert wird."
                    )}
                  </div>
                )}
                <div className={`__flex`}>
                  <div className={` __flex-1  __field`}>
                    <Field name="rentNet">
                      {({ input, meta }) => (
                        <BFInput
                          type="priceInput"
                          disabled={adjustmentType === "loss"}
                          label={i18n.t(
                            "cb:RentalAgreementRentAdjustment.rentNet",
                            "Kaltmiete netto"
                          )}
                          suffix={StringUtils.getCurrencySymbol()}
                          textAlign="right"
                          {...input}
                          {...FV.getValidation(meta)}
                          onChange={(value: number) => {
                            formProps.form.batch(() => {
                              formProps.form.change(
                                "rentGross",
                                value * taxRate
                              );
                            });
                            input.onChange(value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  {taxRate === 1 ? null : (
                    <div className={` __flex-1  __field`}>
                      <Field name="rentGross">
                        {({ input, meta }) => (
                          <BFInput
                            type="priceInput"
                            disabled={adjustmentType === "loss"}
                            label={i18n.t(
                              "cb:RentalAgreementRentAdjustment.rentGross",
                              "Kaltmiete brutto"
                            )}
                            suffix={StringUtils.getCurrencySymbol()}
                            textAlign="right"
                            {...input}
                            {...FV.getValidation(meta)}
                            onChange={(value: number) => {
                              formProps.form.batch(() => {
                                formProps.form.change(
                                  "rentNet",
                                  value / taxRate
                                );
                              });
                              input.onChange(value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                </div>
                <div className={`__flex`}>
                  <div className={`__flex-1  __field`}>
                    <Field name="operatingCostsNet">
                      {({ input, meta }) => (
                        <BFInput
                          type="priceInput"
                          disabled={adjustmentType === "loss"}
                          label={i18n.t(
                            "cb:RentalAgreementRentAdjustment.operatingCostsNet",
                            "Nebenkosten netto"
                          )}
                          suffix={StringUtils.getCurrencySymbol()}
                          textAlign="right"
                          {...input}
                          {...FV.getValidation(meta)}
                          onChange={(value: number) => {
                            formProps.form.batch(() => {
                              formProps.form.change(
                                "operatingCostsGross",
                                value * taxRate
                              );
                            });
                            input.onChange(value);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  {taxRate === 1 ? null : (
                    <div className={` __flex-1  __field`}>
                      <Field name="operatingCostsGross">
                        {({ input, meta }) => (
                          <BFInput
                            type="priceInput"
                            disabled={adjustmentType === "loss"}
                            label={i18n.t(
                              "cb:RentalAgreementRentAdjustment.operatingCostsGross",
                              "Nebenkosten brutto"
                            )}
                            suffix={StringUtils.getCurrencySymbol()}
                            textAlign="right"
                            {...input}
                            {...FV.getValidation(meta)}
                            onChange={(value: number) => {
                              formProps.form.batch(() => {
                                formProps.form.change(
                                  "operatingCostsNet",
                                  value / taxRate
                                );
                              });
                              input.onChange(value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                </div>
              </>
            )}
          </FormFieldValues>
          <div className={`__flex `}>
            <div className={`__flex-1 __field`}>
              <Field name="note">
                {({ input, meta }) => (
                  <BFInput
                    label={i18n.t(
                      "cb:RentalAgreementRentAdjustment.note",
                      "Notiz"
                    )}
                    autoResize
                    type="textarea"
                    {...input}
                    {...FV.getValidation(meta)}
                  />
                )}
              </Field>
            </div>
          </div>

          <div className="__field __flex-1">
            <Field
              name="linkedAsset"
              // validate={FormValidators.compose(
              //   FormValidators.required(),
              //   FormValidators.min(1)
              // )}
            >
              {({ input, meta }) => (
                <ActivitySelect
                  label={i18n.t(
                    "acc:AccountingBookingForm.connectAsset",
                    "Aktivität verknüpfen"
                  )}
                  className={`accounting-form-assignment`}
                  multiple={true}
                  {...FormValidators.getValidation(meta)}
                  block
                  appearance="bf"
                  businessUnits={[props.rentalAgreement.data.type]}
                  identifier="accounting-form-connect-activities"
                  value={input.value || []}
                  onChange={input.onChange}
                  entityId={props.rentalAgreement.data.entity}
                  // onValueAdded={async (value) => {
                  //   await InvoiceService.addActivity(props.asset._id, value);
                  // }}
                  // onValueRemoved={async (value) => {
                  //   await InvoiceService.removeActivity(props.asset._id, value);
                  // }}
                  cleanable
                  // label={RA}
                  assets={[
                    AssetTypes.Activity.DamageClaim,
                    AssetTypes.Activity.Dunning,
                    AssetTypes.Activity.Project,
                  ]}
                />
              )}
            </Field>
          </div>
        </>
      )}
    />
  );
};

export default CBRentalAgreementRentAdjustmentForm;

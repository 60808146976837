import { ReactNode } from "react";

export interface IAppThemeProviderProps {
  children: ReactNode;
}

export enum EAppTheme {
  LIGHT = "light",
  DARK = "dark",
}

export interface IAppThemeContext {
  currentTheme: EAppTheme;
  setAppTheme: (theme: EAppTheme) => void;
}

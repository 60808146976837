import "./PDFCheckbox.scss";

type PDFCheckboxProps = {
  checked: boolean;
  onCheckedChange: (value: boolean) => void;

  icon: React.ReactNode;
};

const PDFCheckbox: React.FC<PDFCheckboxProps> = ({
  checked,
  onCheckedChange,
  icon,
}) => (
  <button
    data-checked={checked}
    type="button"
    onClick={() => onCheckedChange(!checked)}
    className="pdf-constructor--checkbox"
  >
    {icon}
  </button>
);

export { PDFCheckbox };

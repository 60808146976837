import ModalManager from "@/components/ModalComponent/ModalManager";
import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import HTMLText from "@/components/WrappedText/HTMLText";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import StringUtils from "@/utils/StringUtils";
import moment from "moment";
import { IndexOption, RentalAgreement } from "../../../TenantsInterfaces";
import { CBRentalAgreementIndexUpdateForm } from "../form-components/CBRentalAgreementIndexForm";
// import "./CBAgreementIndex.scss";

export type IndexType = {
  index: null | IndexOption;
};
interface CBAgreementIndexProps {
  marks: PDFEditRect[] | PDFDocumentRect[];
  hideIfEmpty?: boolean;
  data: IndexType;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
}
const CBAgreementIndex = (props: CBAgreementIndexProps) => {
  if (props.hideIfEmpty && !props.data.index) {
    return null;
  }
  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={props.marks}
      title={
        <>
          <span className={`padding-right-6`}>
            {i18n.t("cb:RentalAgreement.Form.Sections.index.title", "Index")}
          </span>
          {props.rentalAgreement && props.editable && (
            <BFButton
              noPadding
              inline
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  size: "fluid",
                  noPadding: true,
                  content: (state, setState, close) => {
                    return (
                      <CBRentalAgreementIndexUpdateForm
                        rentalAgreement={props.rentalAgreement}
                        onClose={close}
                      />
                    );
                  },
                });
              }}
              size="sm"
            >
              {i18n.t("Global.Buttons.Customize", "Anpassen")}
            </BFButton>
          )}
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.index.viewHint",
        "Informationen zur Indexierung"
      )}
      identifier="index"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      <>
        {props.data.index ? (
          <>
            <div className={`__flex-wrap padding-bottom-10`}>
              <BFValueDisplay
                label={i18n.t("cb:RentalAgreement.Labels.Index.type", "Art")}
                value={props.data.index.type}
                formatter={(value) =>
                  value === "default"
                    ? i18n.t(
                        "cb:RentalAgreement.Form.Index.defaultType",
                        "Normal"
                      )
                    : i18n.t(
                        "cb:RentalAgreement.Form.Index.yearlyType",
                        "Jährlich"
                      )
                }
              />
              {props.data.index.type === "default" && (
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Index.indexFactor",
                    "Erhöhung bei Index-Veränderung"
                  )}
                  value={props.data.index.indexFactor}
                  formatter={(value) => StringUtils.formatPercent(value)}
                />
              )}
              {props.data.index.type === "yearly" && (
                <BFValueDisplay
                  label={i18n.t(
                    "cb:RentalAgreement.Labels.Index.indexMonth",
                    "Monat"
                  )}
                  value={props.data.index.indexMonth}
                  formatter={(value) => moment().month(value).format("MMMM")}
                />
              )}

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.lastPriceIndex",
                  "Letzter Indexierungsfaktor"
                )}
                value={props.data.index.lastPriceIndex}
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.lastPriceIndexChange",
                  "Datum der Indexierung"
                )}
                value={props.data.index.lastPriceIndexChange}
                formatter={StringUtils.formatDate}
              />
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.ignoreUntil",
                  "Indexierungssperre bis"
                )}
                value={props.data.index.ignoreUntil}
                formatter={StringUtils.formatDate}
              />
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.backPayPossible",
                  "Rückzahlung möglich"
                )}
                value={props.data.index.backPayPossible}
                formatter={(value) => StringUtils.formatBoolean(value)}
              />
            </div>
            <div className={`__flex-wrap padding-bottom-10`}>
              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.paragraph",
                  "Absatz im Mietvertrag"
                )}
                value={props.data.index.paragraph}
              />

              <BFValueDisplay
                label={i18n.t(
                  "cb:RentalAgreement.Labels.Index.agreementLabel",
                  "Mietvertragsname"
                )}
                value={props.data.index.agreementLabel}
              />
            </div>

            <div className={`__flex`}>
              <BFValueDisplay
                label={i18n.t("cb:RentalAgreement.Labels.Index.note", "Notiz")}
                value={props.data.index.note}
                formatter={(value) => (
                  <HTMLText withBorder maxHeight={400} text={value} />
                )}
              />
            </div>
          </>
        ) : (
          <div className={`__empty no-padding`}>
            {i18n.t(
              "cb:RentalAgreement.Labels.NoIndex",
              "Keine Indexierung hinterlegt"
            )}
          </div>
        )}
      </>
    </BFPDFMarkerSection>
  );
};

export default CBAgreementIndex;

import { FV } from "@/components/Form/Validation/FormValidators";
import StructLoader from "@/components/StructLoader/StructLoader";
import i18n from "@/i18n";
import BFChooserSelect from "@/modules/abstract-ui/forms/chooser/BFChooserSelect";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import DunningStruct from "@/redux/actions/struct/implemented/DunningStruct";
import classNames from "classnames";
import { Field } from "react-final-form";
import { ActivityDunningOptions } from "../../../ActivityHooks";
import { APDunningActivity } from "../../../specific/dunning/dunning.interface";
// import './APDunningBaseData.scss';

interface APDunningBaseDataProps {
  dunningOptions: ActivityDunningOptions;
  asset: APDunningActivity;
}
const APDunningBaseData = (props: APDunningBaseDataProps) => {
  if (!props.dunningOptions) {
    return null;
  }
  return (
    <StructLoader
      structType="dunning"
      unitType={props.asset.data.type}
      render={() => (
        <div className={classNames(`ap-dunning-activity`)}>
          <div className={`__flex`}>
            <div className={`__field __flex-1`}>
              <Field
                name="dunning.dunningLevel"
                validate={FV.compose(FV.required())}
              >
                {({ input, meta }) => (
                  <BFChooserSelect
                    {...input}
                    {...FV.getValidation(meta)}
                    label={
                      i18n.t("apTemplate:Activity.DunningLevel", "Mahnstufe") +
                      "*"
                    }
                    data={DunningStruct.getDunningLevelOptions(
                      props.asset.data.type
                    )}
                  />
                )}
              </Field>
            </div>
            <div className={`__field __flex-1`}>
              <Field
                name="dunning.nextDueDate"
                validate={FV.compose(FV.required())}
              >
                {({ input, meta }) => (
                  <BFDatefield
                    {...input}
                    {...FV.getValidation(meta)}
                    label={
                      i18n.t("apTemplate:Activity.NextDueDate", "Fälligkeit") +
                      "*"
                    }
                  />
                )}
              </Field>
            </div>
          </div>

          <div className={`__flex`}>
            <div className={`__field __flex-1`}>
              <Field name="dunning.typeOfResidue">
                {({ input, meta }) => (
                  <BFChooserSelect
                    {...input}
                    {...FV.getValidation(meta)}
                    label={i18n.t(
                      "apTemplate:Activity.typeOfResidue",
                      "Art des Rückstands"
                    )}
                    data={DunningStruct.getTypeOfResidueOptions(
                      props.asset.data.type
                    )}
                  />
                )}
              </Field>
            </div>
            <div className={`__field __flex-1`}></div>
          </div>

          <div className={`__field`}>
            <Field name="dunning.reason">
              {({ input, meta }) => (
                <BFInput
                  type="textarea"
                  autosize
                  {...input}
                  {...FV.getValidation(meta)}
                  label={i18n.t(
                    "apTemplate:Activity.reason",
                    "Grund der Mahnung"
                  )}
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="dunning.action">
              {({ input, meta }) => (
                <BFInput
                  type="textarea"
                  autosize
                  {...input}
                  {...FV.getValidation(meta)}
                  label={i18n.t("apTemplate:Activity.action", "Maßnahme")}
                />
              )}
            </Field>
          </div>
        </div>
      )}
    />
  );
};

export default APDunningBaseData;

import { useState } from "react";

interface StateWrapperProps<T> {
  initialValue: T;
  children: (data: { value: T; setValue: (value: T) => void }) => JSX.Element;
}
const StateWrapper = (props: StateWrapperProps<any>) => {
  const [value, setValue] = useState(props.initialValue);

  return props.children({ value, setValue });
};

export default StateWrapper;

export type LatLong = {
  lat: number;
  lng: number;
};

export const GOGOLE_MAPS_API_KEY = {
  id: "google-map-script",
  googleMapsApiKey: "AIzaSyBz4ujoaakqAwfNiGrRLgLorrAZxM51Ank",
};

export const calculateBounds = (positions: LatLong[], padding = 0.06) => {
  const minLat = positions.reduce<number>(
    (prev, cur) => (prev === null || cur.lat < prev ? cur.lat : prev),
    null
  );
  const maxLat = positions.reduce<number>(
    (prev, cur) => (prev === null || cur.lat > prev ? cur.lat : prev),
    null
  );
  const minLong = positions.reduce<number>(
    (prev, cur) => (prev === null || cur.lng < prev ? cur.lng : prev),
    null
  );
  const maxLong = positions.reduce<number>(
    (prev, cur) => (prev === null || cur.lng > prev ? cur.lng : prev),
    null
  );

  const bounds = {
    south: minLat - padding,
    west: minLong - padding,
    north: maxLat + padding,
    east: maxLong + padding,
  };

  return bounds;
};

export const calculateCenter = (positions: LatLong[]) => {
  const bounds = calculateBounds(positions);

  const center = {
    lat: (bounds.north + bounds.south) / 2,
    lng: (bounds.east + bounds.west) / 2,
  };

  return center;
};

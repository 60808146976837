import classNames from "classnames";
import React, { SyntheticEvent, useRef } from "react";
import styles from "./horizontal-scroll.module.css";

export const HorizontalScroll: React.FC<{
  svgWidth: number;
  taskListWidth: number;
  rtl: boolean;
  onKeyDown: (event: SyntheticEvent<HTMLDivElement>) => void;
  onScroll: (event: SyntheticEvent<HTMLDivElement>) => void;
}> = ({ svgWidth, taskListWidth, rtl, onScroll }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <div
      dir="ltr"
      style={{
        margin: rtl
          ? `var(--m-0) ${taskListWidth}px var(--m-0) var(--m-0)`
          : `var(--m-0) var(--m-0) var(--m-0) ${taskListWidth}px`,
      }}
      className={classNames(styles.scrollWrapper, "horizontal-scroll")}
      onScroll={onScroll}
      ref={scrollRef}
    >
      <div style={{ width: svgWidth }} className={styles.scroll} />
    </div>
  );
};

import { ReloadMessage } from "../configurable/components/TableComponent/TableComponent";
import Log from "../debug/Log";
import {
  ActionData,
  ActionDataStateChange,
} from "../model/common/DataBus/ActionData";
import Toast from "../modules/abstract-ui/notification/Toast";
import ExpressionHelper from "../modules/generic-forms/util/ExpressionHelper";
import { SET_APPICATION_CACHE_DATA } from "../redux/actions/application/application-action-types";
import { addTrustedDeviceToStore } from "../redux/actions/global/global-actions";
import { SendEvent } from "../utils/abstracts/AbstractComponent";
import { CACHE_TTL, DataBusSubKeys, UserStatus } from "../utils/Constants";
import { handleError } from "../utils/ErrorCodes";
import { HTTP } from "../utils/Http";
import { store } from "./../redux/store";
import DataBus from "./DataBus";
import SocketMessages from "./socket/SocketMessages";
import SocketService from "./socket/SocketService";

class UserServiceClass {
  constructor() {}

  init() {
    DataBus.subscribe<ActionData>(DataBusSubKeys.ACTION_DELETE_USER, (data) =>
      this.actionDeleteUserDialog(data)
    );
    DataBus.subscribe<ActionData>(DataBusSubKeys.ACTION_LOCK_USER, (data) =>
      this.actionLockUserDialog(data)
    );
    DataBus.subscribe<ActionData>(DataBusSubKeys.ACTION_UNLOCK_USER, (data) =>
      this.actionUnlockUserDialog(data)
    );

    DataBus.subscribe<ActionData>(
      DataBusSubKeys.ACTION_SEND_PASSWORD_TOKEN_MAIL,
      (data) => this.actionSendPasswordTokenMailDialog(data)
    );
    DataBus.subscribe<ActionData>(
      DataBusSubKeys.ACTION_SEND_ACCOUNT_ACTIVATION_MAIL,
      (data) => this.actionSendAccountActivationMailDialog(data)
    );

    DataBus.emit<ActionDataStateChange>(
      DataBusSubKeys.ACTION_DELETE_USER,
      {
        type: "state",
        hidden: false,
        disabled: false,
      },
      true
    );
    DataBus.emit<ActionDataStateChange>(
      DataBusSubKeys.ACTION_LOCK_USER,
      {
        type: "state",
        hidden: false,
        disabled: false,
      },
      true
    );
    DataBus.emit<ActionDataStateChange>(
      DataBusSubKeys.ACTION_UNLOCK_USER,
      {
        type: "state",
        hidden: false,
        disabled: false,
      },
      true
    );

    DataBus.emit<ActionDataStateChange>(
      DataBusSubKeys.ACTION_SEND_PASSWORD_TOKEN_MAIL,
      {
        type: "state",
        hidden: false,
        disabled: false,
      },
      true
    );
    DataBus.emit<ActionDataStateChange>(
      DataBusSubKeys.ACTION_SEND_ACCOUNT_ACTIVATION_MAIL,
      {
        type: "state",
        hidden: false,
        disabled: false,
      },
      true
    );

    SocketService.subscribe(SocketMessages.QR_CODE_ACTIVATED, (msg) => {
      if (msg.obj) {
        const { appName, creationTime, deviceID, deviceName, lastUsed } =
          msg.obj;
        store.dispatch(
          addTrustedDeviceToStore(
            deviceName,
            deviceID,
            null,
            appName,
            creationTime,
            lastUsed
          )
        );
      }
    });
  }

  async updateUserAvatar(avatar: string) {
    const user = await HTTP.post({
      url: "/user/updateUserAvatar",
      bodyParams: {
        avatar,
      },
    });
    return user;
  }
  async deleteUserAvatar() {
    const user = await HTTP.post({
      url: "/user/deleteUserAvatar",
    });
    return user;
  }

  actionLockUserDialog(actionData: ActionData) {
    if (actionData.type === "click") {
      DataBus.emit<ActionDataStateChange>(
        DataBusSubKeys.ACTION_LOCK_USER,
        {
          type: "state",
          hidden: false,
          disabled: false,
          loading: true,
        },
        true
      );
      this.setUserStatus(
        actionData.data["userId"],
        UserStatus.INACTIVE,
        actionData.data["tablesToReload"],
        actionData.data["goToUrl"],
        actionData.data["additionalEvents"]
      ).finally(() => {
        DataBus.emit<ActionDataStateChange>(
          DataBusSubKeys.ACTION_LOCK_USER,
          {
            type: "state",
            hidden: false,
            disabled: false,
            loading: false,
          },
          true
        );
      });
    }
  }
  actionUnlockUserDialog(actionData: ActionData) {
    if (actionData.type === "click") {
      DataBus.emit<ActionDataStateChange>(
        DataBusSubKeys.ACTION_UNLOCK_USER,
        {
          type: "state",
          hidden: false,
          disabled: false,
          loading: true,
        },
        true
      );
      this.setUserStatus(
        actionData.data["userId"],
        UserStatus.ACTIVE,
        actionData.data["tablesToReload"],
        actionData.data["goToUrl"],
        actionData.data["additionalEvents"]
      ).finally(() => {
        DataBus.emit<ActionDataStateChange>(
          DataBusSubKeys.ACTION_UNLOCK_USER,
          {
            type: "state",
            hidden: false,
            disabled: false,
            loading: false,
          },
          true
        );
      });
    }
  }

  actionSendPasswordTokenMailDialog(actionData: ActionData) {
    if (actionData.type === "click") {
      DataBus.emit<ActionDataStateChange>(
        DataBusSubKeys.ACTION_SEND_PASSWORD_TOKEN_MAIL,
        {
          type: "state",
          hidden: false,
          disabled: false,
          loading: true,
        },
        true
      );
      this.requestNewPassword(
        actionData.data["userEmail"],
        actionData.data["userMandator"],
        actionData.data["goToUrl"],
        actionData.data["additionalEvents"]
      ).finally(() => {
        DataBus.emit<ActionDataStateChange>(
          DataBusSubKeys.ACTION_SEND_PASSWORD_TOKEN_MAIL,
          {
            type: "state",
            hidden: false,
            disabled: false,
            loading: false,
          },
          true
        );
      });
    }
  }
  actionSendAccountActivationMailDialog(actionData: ActionData) {
    if (actionData.type === "click") {
      DataBus.emit<ActionDataStateChange>(
        DataBusSubKeys.ACTION_SEND_PASSWORD_TOKEN_MAIL,
        {
          type: "state",
          hidden: false,
          disabled: false,
          loading: true,
        },
        true
      );
      this.resendActiovationToken(
        actionData.data["userId"],
        actionData.data["goToUrl"],
        actionData.data["additionalEvents"]
      ).finally(() => {
        DataBus.emit<ActionDataStateChange>(
          DataBusSubKeys.ACTION_SEND_PASSWORD_TOKEN_MAIL,
          {
            type: "state",
            hidden: false,
            disabled: false,
            loading: false,
          },
          true
        );
      });
    }
  }

  actionDeleteUserDialog(actionData: ActionData) {
    if (actionData.type === "click") {
      DataBus.emit<ActionDataStateChange>(
        DataBusSubKeys.ACTION_DELETE_USER,
        {
          type: "state",
          hidden: false,
          disabled: false,
          loading: true,
        },
        true
      );
      this.deleteUser(
        actionData.data["userId"],
        actionData.data["tablesToReload"],
        actionData.data["goToUrl"],
        actionData.data["additionalEvents"]
      ).finally(() => {
        DataBus.emit<ActionDataStateChange>(
          DataBusSubKeys.ACTION_DELETE_USER,
          {
            type: "state",
            hidden: false,
            disabled: false,
            loading: false,
          },
          true
        );
      });
    }
  }

  public requestNewPassword(
    userEmail,
    userMandator,
    goToUrl?: string,
    additionalEvents?: SendEvent[]
  ) {
    return new Promise((resolve, reject) => {
      HTTP.post({
        url: `user/requestNewPassword`,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
        },
        bodyParams: {
          email: userEmail,
          mandator: userMandator,
        },
      })
        .then((response) => {
          ExpressionHelper.handleEvents(additionalEvents, response);
          Toast.success(
            (window as any).translate("UserService.requestNewPasswordSuccess")
          );
          if (goToUrl) {
            (window as any).router.push(goToUrl);
          }
          resolve(response);
        })
        .catch((err) => {
          handleError(err);
          reject(err);
        });
    });
  }
  public resendActiovationToken(
    userId,
    goToUrl?: string,
    additionalEvents?: SendEvent[]
  ) {
    return new Promise((resolve, reject) => {
      HTTP.post({
        url: `user/${userId}/resendActivationToken`,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          ExpressionHelper.handleEvents(additionalEvents, response);

          Toast.success(
            (window as any).translate("UserService.requestNewPasswordSuccess")
          );
          if (goToUrl) {
            (window as any).router.push(goToUrl);
          }
          resolve(response);
        })
        .catch((err) => {
          handleError(err);
          reject(err);
        });
    });
  }

  public setUserStatus(
    userId,
    statusId,
    tablesToReload?: string[],
    goToUrl?: string,
    additionalEvents?: SendEvent[],
    hideToast?: boolean
  ) {
    return new Promise((resolve, reject) => {
      HTTP.post({
        url: `user/${userId}/setStatus`,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        bodyParams: {
          status: statusId,
        },
      })
        .then((response) => {
          ExpressionHelper.handleEvents(additionalEvents, response);
          if (tablesToReload) {
            DataBus.emit(DataBusSubKeys.RELOAD, {
              identifiers: tablesToReload,
            } as ReloadMessage);
          }
          if (hideToast !== true) {
            Toast.success(
              (window as any).translate("UserService.StatusSet_" + statusId)
            );
          }
          if (goToUrl) {
            (window as any).router.push(goToUrl);
          }
          Log.debug("SET_STATUS Response", response);
          store.dispatch({
            type: SET_APPICATION_CACHE_DATA,
            oType: "user",
            id: response._id,
            data: response,
            ttl: CACHE_TTL,
          });
          resolve(response);
        })
        .catch((err) => {
          handleError(err);
          reject(err);
        });
    });
  }

  deleteUser(
    UserId,
    tablesToReload?: string[],
    goToUrl?: string,
    additionalEvents?: SendEvent[]
  ) {
    return new Promise((resolve, reject) => {
      HTTP.delete({
        url: `User/${UserId}`,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          ExpressionHelper.handleEvents(additionalEvents, response);
          DataBus.emit(DataBusSubKeys.ACTION_DELETE_USER_RESPONSE, {
            success: true,
            data: response,
          });
          DataBus.emit(DataBusSubKeys.RELOAD, {
            identifiers: tablesToReload,
          } as ReloadMessage);
          Toast.success(
            (window as any).translate("UserService.UserSuccessfullyDeleted")
          );
          if (goToUrl) {
            (window as any).router.push(goToUrl);
          }
          resolve(response);
        })
        .catch((err) => {
          DataBus.emit(DataBusSubKeys.ACTION_DELETE_USER_RESPONSE, {
            success: false,
            data: err,
          });
          handleError(err);
          reject(err);
        });
    });
  }
}

const UserService = new UserServiceClass();

export default UserService;

import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dropdown } from "rsuite";
import { Placement8 } from "../../../modules/abstract-ui/common/Placements";
import BfIcon, { BfIconProps } from "../../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import { IComponent } from "../../layouts/IComponent";
import "./DropdownComponent.scss";

type Props = {
  appearance?: "default" | "dark";
  titleKey: string;
  icon?: BfIconProps;
  disabled?: string;
  trigger?: "click" | "hover" | "contextMenu";
  placement?: Placement8;
  noCaret?: boolean;
  titleComponent?: IComponent;
  components?: {
    [key: string]: IComponent;
  };
} & AbstractStylableProps &
  RouteComponentProps &
  WithTranslation;

type States = {
  open: boolean;
} & AbstractStylableStates;

class DropdownComponent extends AbstractStylableComponent<Props, States> {
  static defaultProps = {
    appearance: "default",
  };
  readonly state: States = {
    open: false,
  };

  toggleEnabled = true;

  //componentDidMount() {
  //}

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  //componentDidUpdate(prevProps:Props, prevState:States, snapshot) {}

  //

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  cooldownToggle() {
    this.toggleEnabled = false;
    setTimeout(() => {
      this.toggleEnabled = true;
    }, 150);
  }

  render() {
    const {
      i18n,
      titleKey,
      icon,
      disabled,
      trigger,
      placement,
      noCaret,
      titleComponent,
      components,
      appearance,
    } = this.props;

    if (!this.shoudBeRendered()) {
      return null;
    }

    return (
      <Dropdown
        onOpen={() => {
          if (this.toggleEnabled) {
            this.setState({ open: true });
            this.cooldownToggle();
          }
        }}
        onClose={() => {
          if (this.toggleEnabled) {
            this.setState({ open: false });
            this.cooldownToggle();
          }
        }}
        open={this.state.open}
        className={`dropdown-component ${appearance}`}
        renderTitle={
          titleComponent
            ? () =>
                (window as any).ComponentsMapper.createElement(
                  titleComponent,
                  this.props.params
                )
            : undefined
        }
        icon={icon ? <BfIcon {...icon} /> : undefined}
        disabled={disabled ? this.evaluateExpression(disabled) : false}
        trigger={trigger}
        placement={placement}
        noCaret={noCaret}
        title={titleKey ? i18n.t(titleKey) : undefined}
      >
        {Object.entries(components)
          .sort((a, b) =>
            a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
          )
          .map(([key, item]) => {
            return (
              <Dropdown.Item key={key} panel>
                {(window as any).ComponentsMapper.createElement(
                  item,
                  this.props.params,
                  key
                )}
              </Dropdown.Item>
            );
          })}
      </Dropdown>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(DropdownComponent))
);

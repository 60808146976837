import { useLottie } from "lottie-react";
import { CSSProperties } from "react";
import loadingAnimation from "./loadingAnimation.json";
interface Props {
  style?: CSSProperties;
}
const LoadingAnimation = (props: Props) => {
  const LoadingAnimation = useLottie(
    {
      animationData: loadingAnimation,
      loop: true,
    },
    props.style
  );
  return LoadingAnimation.View;
};
export default LoadingAnimation;

import classNames from "classnames";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Bounce from "rsuite/esm/Animation/Bounce";
import i18n from "../../../../i18n";
import EZTextfield from "../../../ez-form/form-elements/ez-textfield/EZTextfield";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import "./BFGroupedOrderList.scss";

export type GorupedOrderListItem = {
  id: string;
  data?: any;
};

export type GroupedOrderListGroup = {
  id: string;
  name: string;
  metaValues?: any;
  items: GorupedOrderListItem[];
  editable?: boolean;
};
export type GroupedOrderListValue = GroupedOrderListGroup[];

const reorder = (list: GroupedOrderListValue, startIndex, endIndex) => {
  const result: GroupedOrderListValue = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const reorderEntries = ({ list, source, destination }) => {
  const current = [...list.find((e) => e.id === source.droppableId)?.items];
  const next = [...list.find((e) => e.id === destination.droppableId)?.items];
  const target = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    const result = list.map((e) =>
      e.id === source.droppableId ? { ...e, items: reordered } : e
    );

    return result;
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);
  const result = list.map((e) =>
    e.id === source.droppableId
      ? { ...e, items: current }
      : e.id === destination.droppableId
      ? { ...e, items: next }
      : e
  );
  return result;
};

const CLS_PREFX = "bf-gol_";
interface BFGroupedOrderListProps {
  value: GroupedOrderListValue;
  onChange: (value: GroupedOrderListValue) => void;
  renderItem: (
    id: string,
    data: any,
    isDragged: boolean,
    group?: string
  ) => React.ReactNode;
  renderGroupForm?: (
    id: string,
    metaValues: any,
    onMetaChange: (metaValues: any) => void
  ) => React.ReactNode;
  onAddGroup?: () => void;
  disableRemoveForGroup?: string[] | boolean;
  onGroupRemove?: (groupId: string) => void;
  onAddGroupText?: string;
  highlightErrors?: boolean;
  renderGroupActions?: (id: string, name: string) => React.ReactNode;
  additionalAddActions?: React.ReactNode;
}
const BFGroupedOrderList = (props: BFGroupedOrderListProps) => {
  const onDragEnd = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }
    const source = result.source;
    const destination = result.destination;
    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    // reordering column
    if (result.type === "GROUP") {
      const reorderedorder = reorder(
        props.value,
        source.index,
        destination.index
      );
      props.onChange(reorderedorder);
      return;
    }
    const data = reorderEntries({ list: props.value, source, destination });
    props.onChange(data);
  };

  return (
    <div className={classNames(`${CLS_PREFX}bf-grouped-order-list`)}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="board"
          type="GROUP"
          direction="vertical"
          //   ignoreContainerClipping={Boolean(containerHeight)}
          isCombineEnabled={false}
          renderClone={(provided, snapshot, descriptor) => (
            //     <div style={{ background: "#fff" }}>
            //       <pre>
            //         <code>{JSON.stringify(descriptor.source, null, 2)}</code>
            //       </pre>
            //     </div>

            <OrderlistGroupContent
              renderGroupActions={props.renderGroupActions}
              highlightErrors={props.highlightErrors}
              renderItem={props.renderItem}
              items={props.value[descriptor.source.index].items}
              name={props.value[descriptor.source.index].name}
              id={props.value[descriptor.source.index].id}
              metaValues={props.value[descriptor.source.index].metaValues}
              editable={props.value[descriptor.source.index].editable}
              provided={provided}
              snapshot={snapshot}
              isClone
              renderGroupForm={props.renderGroupForm}
              onGroupRemove={() => {
                if (props.onGroupRemove) {
                  props.onGroupRemove(props.value[descriptor.source.index].id);
                } else {
                  props.onChange(
                    props.value.filter(
                      (e) => e.id !== props.value[descriptor.source.index].id
                    )
                  );
                }
              }}
              disableGroupRemove={
                props.disableRemoveForGroup === true ||
                (Array.isArray(props.disableRemoveForGroup) &&
                  props.disableRemoveForGroup?.includes(
                    props.value[descriptor.source.index].id
                  ))
              }
            />
          )}
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {props.value.map((group, index) => (
                <OrderListGroup
                  onMetaChange={(metaValues) => {
                    props.onChange(
                      props.value.map((e) =>
                        e.id === group.id ? { ...e, metaValues } : e
                      )
                    );
                  }}
                  highlightErrors={props.highlightErrors}
                  id={group.id}
                  renderItem={props.renderItem}
                  key={group.id}
                  editable={group.editable}
                  index={index}
                  metaValues={group.metaValues}
                  items={group.items}
                  name={group.name}
                  renderGroupActions={props.renderGroupActions}
                  renderGroupForm={props.renderGroupForm}
                  onNameChange={(name) => {
                    props.onChange(
                      props.value.map((e) =>
                        e.id === group.id ? { ...e, name } : e
                      )
                    );
                  }}
                  onGroupRemove={() => {
                    if (props.onGroupRemove) {
                      props.onGroupRemove(group.id);
                    } else {
                      props.onChange(
                        props.value.filter((e) => e.id !== group.id)
                      );
                    }
                  }}
                  disableGroupRemove={
                    props.disableRemoveForGroup === true ||
                    (Array.isArray(props.disableRemoveForGroup) &&
                      props.disableRemoveForGroup?.includes(group.id))
                  }
                />
              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {props.onAddGroup && (
        <div className={`add-group-button`}>
          <BFButton onClick={props.onAddGroup} appearance="link">
            {props.onAddGroupText ||
              i18n.t("BFGroupedOrderList.AddGroup", "Gruppe hinzufügen")}
          </BFButton>
          {props.additionalAddActions && props.additionalAddActions}
        </div>
      )}
    </div>
  );
};

export default BFGroupedOrderList;

const OrderListGroup = (props: {
  index: number;
  id: string;
  name: string;
  items: GorupedOrderListItem[];
  editable?: boolean;
  highlightErrors?: boolean;
  isClone?: boolean;
  metaValues?: any;
  renderItem: (
    id: string,
    data: any,
    isDragged: boolean,
    group?: string
  ) => React.ReactNode;
  renderGroupForm?: (
    id: string,
    metaValues: any,
    onMetaChange: (metaValues: any) => void
  ) => React.ReactNode;
  onMetaChange: (metaValues: any) => void;
  onNameChange?: (name: string) => void;
  disableGroupRemove: boolean;
  onGroupRemove: () => void;
  renderGroupActions?: (id: string, name: string) => React.ReactNode;
}) => {
  return (
    <Draggable draggableId={props.id} index={props.index}>
      {(provided, snapshot) => (
        <OrderlistGroupContent
          {...props}
          provided={provided}
          snapshot={snapshot}
        />
      )}
    </Draggable>
  );
};

const OrderlistGroupContent = (props: {
  renderGroupActions?: (id: string, name: string) => React.ReactNode;
  provided: any;
  snapshot?: any;
  id: string;
  name: string;
  items: GorupedOrderListItem[];
  editable?: boolean;
  highlightErrors?: boolean;
  isClone?: boolean;
  metaValues?: any;
  renderItem: (
    id: string,
    data: any,
    isDragged: boolean,
    group?: string
  ) => React.ReactNode;
  renderGroupForm?: (
    id: string,
    metaValues: any,
    onMetaChange: (metaValues: any) => void
  ) => React.ReactNode;
  onMetaChange?: (metaValues: any) => void;
  onNameChange?: (name: string) => void;
  disableGroupRemove: boolean;
  onGroupRemove: () => void;
}) => {
  return (
    <div
      className={classNames(`${CLS_PREFX}order-list-group`, {
        clone: props.isClone,
      })}
      ref={props.provided.innerRef}
      {...props.provided.draggableProps}
    >
      <div
        className={classNames(`list-group-header`, {
          dragging: props.snapshot.isDragging,
        })}
        {...props.provided.dragHandleProps}
      >
        <EZTextfield
          error={
            props.highlightErrors && (props.name || "").trim().length === 0
          }
          ignoreLinebreaks
          readonly={props.editable === false}
          value={props.name}
          onChange={props.onNameChange}
          appearance="default"
          textType="subHeader"
          placeholder={i18n.t(
            "BFGroupedOrderList.GroupName",
            "Gruppenname eingeben"
          )}
        />
        {props.renderGroupActions &&
          props.renderGroupActions(props.id, props.name)}
        {!props.disableGroupRemove && (
          <BFButton appearance="link" onClick={props.onGroupRemove}>
            <BfIcon {...DefaultIcons.TRASH} size="xs" />
          </BFButton>
        )}
        <div className={`icon`}>
          <BfIcon type="light" data="select-drag-reorder-dots" size="xs" />
        </div>
      </div>
      <div className={`${CLS_PREFX}order-list-group-content`}>
        <OrderListEntryList
          id={props.id}
          items={props.items}
          renderItem={props.renderItem}
        />
      </div>
      {props.renderGroupForm && (
        <div className={`group-edit-form`}>
          {props.renderGroupForm(
            props.id,
            props.metaValues,
            props.onMetaChange
          )}
        </div>
      )}
    </div>
  );
};

const OrderListEntryList = (props: {
  id: string;
  items: GorupedOrderListItem[];
  renderItem: (
    id: string,
    data: any,
    isDragged: boolean,
    group?: string
  ) => React.ReactNode;
}) => {
  return (
    <Droppable
      droppableId={props.id}
      type={"ENTRY"}
      // ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={false}
      isCombineEnabled={false}
      renderClone={(provided, snapshot, descriptor) => (
        <QuoteItem
          group={props.id}
          renderItem={props.renderItem}
          item={props.items[descriptor.source.index]}
          provided={provided}
          isDragging={snapshot.isDragging}
          isClone
        />
      )}
      // renderClone={
      //   useClone
      //     ? (provided, snapshot, descriptor) => (
      //         <QuoteItem
      //           quote={quotes[descriptor.source.index]}
      //           provided={provided}
      //           isDragging={snapshot.isDragging}
      //           isClone
      //         />
      //       )
      //     : null
      // }
    >
      {(dropProvided, dropSnapshot) => (
        <div
          className={`${CLS_PREFX}order-list-entry-list`}
          {...dropProvided.droppableProps}
        >
          <div className={`inner-list`}>
            <div className={`drop-zone`} ref={dropProvided.innerRef}>
              <div className={`empty-indicator`}>
                <Bounce
                  in={(props.items || []).length === 0}
                  timeout={300}
                  animation={(props.items || []).length === 0}
                >
                  <div>
                    {i18n.t("BFGroupedOrderList.NoEntries", "Keine Einträge")}
                  </div>
                </Bounce>
              </div>
              <InnerQuoteList
                group={props.id}
                items={props.items}
                renderItem={props.renderItem}
              />
              {dropProvided.placeholder}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
};

const InnerQuoteList = (props: {
  items: GorupedOrderListItem[];
  group?: string;
  renderItem: (
    id: string,
    data: any,
    isDragged: boolean,
    group?: string
  ) => React.ReactNode;
}) => {
  return (
    <>
      {(props.items || []).map((item, index) => (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(dragProvided, dragSnapshot) => (
            <QuoteItem
              group={props.group}
              index={index}
              renderItem={props.renderItem}
              key={item.id}
              item={item}
              isDragging={dragSnapshot.isDragging}
              provided={dragProvided}
            />
          )}
        </Draggable>
      ))}
    </>
  );
};

function QuoteItem(props: {
  item: GorupedOrderListItem;
  group?: string;
  isDragging: boolean;
  provided: any;
  index?: number;
  renderItem: (
    id: string,
    data: any,
    isDragged: boolean,
    group?: string
  ) => React.ReactNode;
  isClone?: boolean;
}) {
  const { item, isDragging, provided, index } = props;

  return (
    <div
      className={classNames(`${CLS_PREFX}item-entry`, { clone: props.isClone })}
      //   href={item.author.url}
      isDragging={isDragging}
      //   isClone={isClone}
      //   colors={item.author.colors}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      // style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      //   data-testid={quote.id}
      data-index={index}
      //   aria-label={`${quote.author.name} quote ${quote.content}`}
    >
      <div className={`entry-content`}>
        {props.renderItem(item.id, item.data, isDragging, props.group)}
      </div>
      <div className={`icon`}>
        <BfIcon type="light" data="select-drag-reorder-dots" size="xxs" />
      </div>
    </div>
  );
}

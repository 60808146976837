import * as echarts from "echarts";
import { ThunkDispatch } from "redux-thunk";
import Log from "../debug/Log";
import i18n from "../i18n";
import ExpressionHelper from "../modules/generic-forms/util/ExpressionHelper";
import ObjectTools from "../modules/generic-forms/util/ObjectTools";
import {
  mergeGlobalConfig,
  setContextData,
  setGlobalCacheData,
  setMandatorData,
} from "../redux/actions/global/global-actions";
import { setUiConfig } from "../redux/actions/ui-config/ui-config-actions";
import { defaultChartConfiguration } from "../redux/reducers/global/StandardLayouts";
import { DefaultUIConfigs } from "../redux/reducers/ui-config/UiConfig";
import { store } from "../redux/store";
import { HTTP } from "../utils/Http";
import { Properties, devProp } from "../utils/Properties";

export interface RequestInitAction {
  type: "globalCache" | "applicationCache" | "function";
  readJsonFunctions?: boolean;
  url?: string;
  key?: string;
  ttl?: number;
  function?: any;
}

export type InitAction = RequestInitAction;

export const loadCacheAction = (
  action: InitAction,
  onSuccess?: () => void,
  onError?: (err) => void
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    handleAction(action, dispatch)
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        if (onError) {
          onError(err);
        }
        Log.error(err);
      });
  };
};

const handleAction = (
  action: InitAction,
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  return new Promise<boolean>((resolve, reject) => {
    if (action.type === "function") {
      ExpressionHelper.evaluateExpression(action.function, {
        resolve,
        reject,
        dispatch,
      });
    }

    if (action.type === "globalCache") {
      let oldCache;

      oldCache = store.getState().global.cache[action.key];

      if (
        oldCache &&
        (oldCache.ttl === undefined ||
          (oldCache.ttl !== undefined &&
            oldCache.timestamp + oldCache.ttl > Number(new Date())))
      ) {
        resolve(oldCache);
      } else {
        HTTP.get({
          url: action["url"],
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((result) => {
            let data = result;
            if (action.readJsonFunctions) {
              data = ObjectTools.readJsonFunctions(result);
            }
            dispatch(setGlobalCacheData(action.key, data, action.ttl));
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      }
    }
  });
};

export const loadGlobalConfig = (
  onSuccess?: (data) => void,
  onError?: (err) => void
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    loadGlobalConfigHelper(dispatch, onSuccess, onError);
  };
};

const loadGlobalConfigHelper = (
  dispatch: ThunkDispatch<{}, {}, any>,
  onSuccess?: (data) => void,
  onError?: (err) => void
) => {
  HTTP.get({
    url: "config",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
    queryParams: {
      param: {
        mainLanguage: i18n.language,
        fallbackLanguage: Properties.fallbackLanguage,
        context: devProp(window.location.hostname, Properties.dev.context),
      },
    },
  })
    .then((data) => {
      const availableLanguages = data.context.availableLanguages;
      if (!availableLanguages.find((lng) => lng.selector === i18n.language)) {
        if (
          !availableLanguages.find(
            (lng) => lng.selector === Properties.fallbackLanguage
          )
        ) {
          i18n.changeLanguage(availableLanguages[0].selector).then(() => {
            loadGlobalConfigHelper(dispatch, onSuccess, onError);
          });

          return;
        } else {
          i18n.changeLanguage(Properties.fallbackLanguage);
        }
      }

      dispatch(
        setUiConfig(DefaultUIConfigs.AVAILABLE_LANGUAGES, availableLanguages)
      );

      let chartConfig = defaultChartConfiguration;

      if (data && data.mandator) {
        dispatch(setMandatorData(data.mandator));
      }

      if (data && data.context) {
        dispatch(setContextData(data.context));

        if (data.context.chartConfiguration) {
          chartConfig = ObjectTools.mergeObjects(
            chartConfig,
            data.context.chartConfiguration
          ) as any;
        }
      }

      if (data && data.config) {
        dispatch(mergeGlobalConfig(data.config)); //data));
      } else {
        dispatch(mergeGlobalConfig({})); //data));
      }

      if (onSuccess) {
        onSuccess(data);
      }
      //todo own request for mandator info
      echarts.registerTheme("default", chartConfig);
    })
    .catch((err) => {
      Log.error(err);

      if (onError) {
        onError(err);
      }
      // todo what should happen on error?
    });
};

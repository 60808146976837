import classNames from "classnames";
import Page from "../../../components/PageView/Page";
import "./TablePageLayout.scss";

export type TablePageLayoutProps = {
  className?: string;
  noPadding?: boolean;
  pageHeader?: React.ReactNode;
  pageFooter?: React.ReactNode;
  cardFooter?: React.ReactNode;
  cardHeader?: React.ReactNode;
  cardTitle?: string;
};
type TablePageLayoutWithChildProps = TablePageLayoutProps & {
  children: React.ReactNode;
};
const TablePageLayout = (props: TablePageLayoutWithChildProps) => {
  return (
    <Page
      className={classNames(`table-page-layout`, props.className, {
        "no-padding": props.noPadding,
      })}
    >
      {props.pageHeader && (
        <div className={`page-header`}>{props.pageHeader}</div>
      )}
      <div className={`__card table-card`}>
        {(props.cardHeader || props.cardTitle) && (
          <div className={`table-card-header`}>
            {props.cardTitle && (
              <div className={`table-card-title`}>{props.cardTitle}</div>
            )}
            {props.cardHeader ? props.cardHeader : null}
          </div>
        )}
        <div className={`table-card-content`}>{props.children}</div>
        {props.cardFooter && (
          <div className="table-card-footer">{props.cardFooter}</div>
        )}
      </div>
      {props.pageFooter && (
        <div className={`page-footer`}>{props.pageFooter}</div>
      )}
    </Page>
  );
};
export default TablePageLayout;

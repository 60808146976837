import DebugDataComponent from "@/debug/DebugDataComponent";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, useLocation, useRouteMatch } from "react-router-dom";
import AssetLoader from "../../components/AssetLoader/AssetLoader";
import Userlabel from "../../components/AvatarComponent/Userlabel";
import ListComponent from "../../configurable/components/ListComponent/ListComponent";
import VirtualizedTable from "../../configurable/data/VirtualizedTable/VirtualizedTable";
import i18n from "../../i18n";
import { AssetTypes } from "../../model/AssetTypes";
import BaseAsset from "../../model/general-assets/BaseAsset";
import InfiniteTable from "../../redux/actions/application/application-infinite-table-actions";
import { useAggregationStatisticQuery } from "../../redux/hooks";
import DataBusDefaults from "../../services/DataBusDefaults";
import MQ from "../../utils/MatchQueryUtils";
import StringUtils from "../../utils/StringUtils";
import BFStatus from "../abstract-ui/data/status/BFStatus";
import { renderCellValue } from "../abstract-ui/data/table/TableUtils";
import TagViewCompact from "../abstract-ui/data/tag-component/TagViewCompact";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BFDropdown from "../abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../abstract-ui/icon/BfIcon";
import CommentUtils, { EMAIL_TAG_COLOR } from "./CommentUtils";
import {
  Comment,
  CommentTypes,
  EpostPDFComment,
  InternComment,
  MailIncomingComment,
  MailOutgoingComment,
} from "./Comments";
import { CommentsListSortOptions } from "./CommentsFilterOptions";
import "./CommentsList.scss";
import { CommentsModuleProps } from "./CommentsModule";
import CommentEntry from "./components/CommentEntry";

interface CommentsListProps extends CommentsModuleProps {
  mode: "list" | "table";
  asset?: BaseAsset;
  open?: () => void;
  defaultSignature?: string;
  hideResubmissionActionForMail?: boolean;
  clearOnUnmount?: boolean;
  viewOnly?: boolean;
}
const CommentsList = (props: CommentsListProps) => {
  const pinnedQuery = useAggregationStatisticQuery(
    AssetTypes.Comment,
    MQ.and(
      MQ.eq("data.linkedAsset.type", props.assetType),
      MQ.eq("data.linkedAsset.id", props.assetId),
      // only filter for entries that have proper comment entry section
      MQ.in("data.type", [
        CommentTypes.INTERN,
        CommentTypes.MAIL_INCOMING,
        CommentTypes.MAIL_OUTGOING,
      ])
    ),
    [
      {
        name: "pinned",
        op: [{ key: "count", op: "count" }],
        query: {
          matchQuery: MQ.isNotNull("data.pin"),
        },
      },
    ]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      if (props.clearOnUnmount) {
        dispatch(InfiniteTable.clearTable(props.identifier));
      }
    };
  }, []);
  const onPinChange = () => {
    pinnedQuery?.reload();
    DataBusDefaults.reload({
      identifiers: [`${props.identifier}_pins`],
    });
  };
  return (
    <div className={`comment-list`}>
      {pinnedQuery?.data?.["pinned"]?.count > 0 && (
        <div className={`pinned-messages`}>
          <BFDropdown
            className={`pinned-message-button`}
            label={
              <div className={`button-content`}>
                <BfIcon type="light" data="bh-pin" size="xs" />
                {i18n.t(
                  "CommentsModule.Pinned",
                  "{{count}} angepinnte Nachrichten",
                  {
                    count: pinnedQuery?.data?.["pinned"]?.count || 0,
                  }
                )}
              </div>
            }
            items={[
              {
                type: "panel",
                children: (
                  <div className={`comments-list-pinned-messages-container`}>
                    <ListComponent
                      className={`pinned-messages-list`}
                      dataUrl={`/api/asset/comment`}
                      reloadOnMount
                      limitPerRequest={10}
                      initialVisibleSort={CommentsListSortOptions[0].definition}
                      identifier={`${props.identifier}_pins`}
                      additionalMatchQuery={MQ.and(
                        MQ.eq("data.linkedAsset.type", props.assetType),
                        MQ.eq("data.linkedAsset.id", props.assetId),
                        MQ.isNotNull("data.pin"),
                        MQ.isNull("data.archived")
                      )}
                      render={(node: Comment) => (
                        <div>
                          {node.data.pin && (
                            <div className={`pinned-by`}>
                              <span className={`label`}>
                                {i18n.t(
                                  "CommentsModule.PinnedBy",
                                  "Wurde angepinnt von"
                                )}
                              </span>
                              <Userlabel id={node.data.pin.userId} />
                              <span className={`date-of-pin`}>
                                - {StringUtils.formatDate(node.data.pin.date)}
                              </span>
                            </div>
                          )}
                          <CommentEntry
                            apperance="slim"
                            onPinChange={() => onPinChange()}
                            onContextClick={(context) => {
                              DataBusDefaults.filterChanged({
                                identifier: props.identifier,
                                filter: [
                                  {
                                    filterKey: "data.subactivityId",
                                    value: [context.toString()],
                                  },
                                ],
                              });
                            }}
                            defaultSignature={props.defaultSignature}
                            comment={node}
                            commentModuleIdentifier={props.identifier}
                            context={
                              node.data.subactivityId
                                ? {
                                    id: node.data.subactivityId,
                                    name: props.asset?.communication?.subactivities?.find(
                                      (e) => e.id === node.data.subactivityId
                                    )?.displayName,
                                  }
                                : undefined
                            }
                            hideResubmissionActionForMail={
                              props.hideResubmissionActionForMail
                            }
                            renderMailDuplicateAction={
                              props.renderMailDuplicateAction
                            }
                            hideAllActions={props.viewOnly}
                            allowFullscreen={props.viewOnly ? false : true}
                            // disableFocusMode={props.viewOnly}
                          />
                        </div>
                      )}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}
      <div className={`list-wrapper`}>
        {props.mode === "list" && (
          <CommentAsListView {...props} reloadPins={() => onPinChange()} />
        )}
        {props.mode === "table" && (
          <CommentAsTableView {...props} reloadPins={() => onPinChange()} />
        )}
      </div>
    </div>
  );
};

export default CommentsList;

const CommentAsListView = (
  props: CommentsListProps & { reloadPins: () => void }
) => {
  return (
    <ListComponent
      className={`comments-view-as-list`}
      dataUrl={`/api/asset/comment`}
      reloadOnMount
      limitPerRequest={10}
      initialVisibleSort={CommentsListSortOptions[0].definition}
      identifier={props.identifier}
      additionalMatchQuery={MQ.and(
        MQ.eq("data.linkedAsset.type", props.assetType),
        MQ.eq("data.linkedAsset.id", props.assetId)
      )}
      render={(node: Comment) => (
        <>
          <CommentEntry
            collapsible
            onPinChange={props.reloadPins}
            onContextClick={(context) => {
              DataBusDefaults.filterChanged({
                identifier: props.identifier,
                filter: [
                  {
                    filterKey: "data.subactivityId",
                    value: [context.toString()],
                  },
                ],
              });
            }}
            defaultSignature={props.defaultSignature}
            comment={node}
            commentModuleIdentifier={props.identifier}
            context={
              node.data.subactivityId
                ? {
                    id: node.data.subactivityId,
                    name: props.asset?.communication?.subactivities?.find(
                      (e) => e.id === node.data.subactivityId
                    )?.displayName,
                  }
                : undefined
            }
            hideResubmissionActionForMail={props.hideResubmissionActionForMail}
            renderMailDuplicateAction={props.renderMailDuplicateAction}
            hideAllActions={props.viewOnly}
            allowFullscreen={props.viewOnly ? false : true}
            // disableFocusMode={props.viewOnly}
          />
        </>
      )}
    />
  );
};

const TYPE_DATA = () => ({
  [CommentTypes.MAIL_INCOMING]: {
    icon: "email-action-unread",
    tooltip: i18n.t("CommentModule.IncomingMail", "Eingehende Email"),
  },
  [CommentTypes.MAIL_OUTGOING]: {
    icon: "send-email",
    tooltip: i18n.t("CommentModule.OutgoingMail", "Ausgehende Email"),
  },
  [CommentTypes.INTERN]: {
    icon: "messages-bubble",
    tooltip: i18n.t("CommentModule.InternComment", "Interner Kommentar"),
  },
  [CommentTypes.INTERN]: {
    icon: "messages-bubble",
    tooltip: i18n.t("CommentModule.InternComment", "Interner Kommentar"),
  },
  [CommentTypes.INTERN]: {
    icon: "messages-bubble",
    tooltip: i18n.t("CommentModule.InternComment", "Interner Kommentar"),
  },
  [CommentTypes.MANUAL_PDF]: {
    icon: "office-file-pdf",
    tooltip: i18n.t("CommentModule.ManualPDF", "Generiertes Anschreiben"),
  },
  [CommentTypes.EPOST]: {
    icon: "mailbox-in",
    tooltip: i18n.t("CommentModule.EPost", "Brief per E-Post"),
  },
});

const CommentAsTableView = (
  props: CommentsListProps & { reloadPins: () => void }
) => {
  const match = useRouteMatch();
  const location = useLocation();
  const selectedId = location.pathname.substr(match.url.length + 1);

  const possibleTags = (
    UnitStruct.getAllTagConfigs(AssetTypes.Comment) || []
  ).filter((e) => e.status !== "archived");

  return (
    <div className={`table-view __card`}>
      <div className={`table-container`}>
        <VirtualizedTable
          dataUrl={`/api/asset/comment`}
          reloadOnMount
          selectedIds={selectedId ? [selectedId] : undefined}
          limitPerRequest={20}
          onRowClick={(node) => {
            if (selectedId === node._id) {
              DataBusDefaults.route({
                route: match.url,
                replace: true,
              });
            } else {
              DataBusDefaults.route({
                route: `${match.url}/${node._id}`,
                replace: true,
              });
            }
          }}
          initialVisibleSort={CommentsListSortOptions[0].definition}
          identifier={props.identifier}
          additionalMatchQuery={MQ.and(
            MQ.eq("data.linkedAsset.type", props.assetType),
            MQ.eq("data.linkedAsset.id", props.assetId),
            // only filter for entries that have proper comment entry section
            MQ.in("data.type", [
              CommentTypes.INTERN,
              CommentTypes.MAIL_INCOMING,
              CommentTypes.MAIL_OUTGOING,
              CommentTypes.MANUAL_PDF,
              CommentTypes.EPOST,
            ])
          )}
          columns={{
            type: {
              label: "",
              // sortKey: "",
              fixedWidth: 40,
              resizable: true,
              sortable: false,
              classNameFC: (node: Comment) =>
                node.data.archived ? "archived-entry" : "",
              alignment: "center",
              render: (node: Comment, index, params) =>
                TYPE_DATA()[node.data.type] && (
                  <div
                    className={`type-icon`}
                    title={TYPE_DATA()[node.data.type].tooltip}
                  >
                    <DebugDataComponent data={node} />
                    <BfIcon
                      type="light"
                      data={TYPE_DATA()[node.data.type].icon}
                      size="xs"
                    />
                  </div>
                ),
              // renderCellValue(node?.data?.type, "-"),
            },
            sender: {
              label: i18n.t("CommentsModule.Sender", "Gesendet von"),
              // sortKey: "",
              flexWidth: 120,
              resizable: true,
              sortable: false,
              classNameFC: (node: Comment) =>
                node.data.archived ? "archived-entry" : "",
              render: (node: Comment, index, params) => (
                <div className={`sender-cell`}>
                  {node.data.type === CommentTypes.MAIL_INCOMING && (
                    <BFStatus
                      key={index}
                      label={CommentUtils.formatEmailUser(
                        (node as MailIncomingComment).data.typeData.sender
                      )}
                      size="sm"
                      color={EMAIL_TAG_COLOR}
                      style={{ fontWeight: 400 }}
                    />
                  )}
                  {node.data.type === CommentTypes.MAIL_OUTGOING && (
                    <Userlabel
                      //todo check ifuserId or senderUserId
                      id={(node as MailOutgoingComment).data.typeData.userId}
                      avatarSize={14}
                    />
                  )}
                  {[
                    CommentTypes.INTERN,
                    CommentTypes.MANUAL_PDF,
                    CommentTypes.EPOST,
                  ].includes(node.data.type) && (
                    <Userlabel
                      id={(node as InternComment).data.typeData.userId}
                      avatarSize={14}
                    />
                  )}
                </div>
              ),
              // renderCellValue(node?.data?.type, "-"),
            },
            receiver: {
              label: i18n.t("CommentsModule.Receiver", "Empfänger"),
              // sortKey: "",
              flexWidth: 120,
              resizable: true,
              sortable: false,
              classNameFC: (node: Comment) =>
                node.data.archived ? "archived-entry" : "",
              render: (node: Comment, index, params) =>
                node.data.type === CommentTypes.MAIL_OUTGOING ||
                node.data.type === CommentTypes.MAIL_INCOMING ? (
                  <div className={`receiver-cell`}>
                    {(node as MailOutgoingComment).data.typeData.recipients.map(
                      (recipient, index) => (
                        <BFStatus
                          key={index}
                          label={CommentUtils.formatEmailUser(recipient)}
                          size="sm"
                          color={EMAIL_TAG_COLOR}
                          style={{ fontWeight: 400 }}
                        />
                      )
                    )}
                  </div>
                ) : (
                  "-"
                ),
            },
            subject: {
              label: i18n.t("CommentsModule.Subject", "Betreff"),
              // sortKey: "",
              flexWidth: 200,
              resizable: true,
              sortable: false,
              classNameFC: (node: Comment) =>
                node.data.archived ? "archived-entry" : "",
              render: (node: Comment, index, params) => {
                if (node.data.type === CommentTypes.EPOST) {
                  const epost = node as EpostPDFComment;
                  return (
                    <div className={`epost-description`}>
                      {epost.data.ePostStatus.status === 99 && (
                        <BFStatus
                          label={i18n.t("Global.Labels.Error", "Fehler")}
                          color={"#a40000"}
                          size="xs"
                        />
                      )}
                      <div className={`description`}>
                        {renderCellValue(epost.data?.ePostStatus?.desc, "-")}
                      </div>
                    </div>
                  );
                }

                return (
                  <>
                    {(node.data?.attachments || []).length > 0 && (
                      <BfIcon
                        style={{ flexShrink: 0 }}
                        type="light"
                        data="attachment"
                        size={"xxs"}
                        className="margin-right-6"
                      />
                    )}
                    <TagViewCompact
                      possibleTags={possibleTags}
                      tags={node.data.tags}
                      marginRight={5}
                    />
                    {renderCellValue(
                      [CommentTypes.INTERN].includes(node?.data?.type)
                        ? StringUtils.extractTextFromHtml(node.data.message)
                        : [
                            CommentTypes.MAIL_INCOMING,
                            CommentTypes.MAIL_OUTGOING,
                          ].includes(node?.data?.type)
                        ? (node as MailOutgoingComment | MailIncomingComment)
                            .data.typeData.subject
                        : undefined,
                      "-"
                    )}
                  </>
                );
              },
            },
            context: {
              label: i18n.t("CommentsModule.context", "Kontext"),
              // sortKey: "",
              flexWidth: 100,
              resizable: true,
              sortable: false,
              classNameFC: (node: Comment) =>
                node.data.archived ? "archived-entry" : "",
              render: (node: Comment, index, params) =>
                renderCellValue(node?.data?.date, "-", (value) =>
                  node.data.subactivityId ? (
                    <BFButton
                      appearance="link"
                      noPadding
                      onClick={() => {
                        DataBusDefaults.filterChanged({
                          identifier: props.identifier,
                          filter: [
                            {
                              filterKey: "data.subactivityId",
                              value: [node.data.subactivityId.toString()],
                            },
                          ],
                        });
                      }}
                    >
                      {
                        props.asset?.communication?.subactivities?.find(
                          (e) => e.id === node.data.subactivityId
                        )?.displayName
                      }
                    </BFButton>
                  ) : null
                ),
            },
            date: {
              label: i18n.t("CommentsModule.Date", "Datum"),
              // sortKey: "",
              fixedWidth: 130,
              resizable: true,
              sortable: false,
              classNameFC: (node: Comment) =>
                node.data.archived ? "archived-entry" : "",
              render: (node: Comment, index, params) =>
                renderCellValue(node?.data?.date, "-", (value) =>
                  StringUtils.formatDate(value, "datetime", true)
                ),
            },
          }}
        />
      </div>
      <Route
        path={`${match.path}/:commentID([0-9a-f]*)?`}
        render={(innerMatch) =>
          innerMatch.match.params.commentID && (
            <div className={`opened-email`}>
              <AssetLoader
                assetType={AssetTypes.Comment}
                id={innerMatch.match.params.commentID}
                render={(comment: Comment) => (
                  <CommentEntry
                    onPinChange={props.reloadPins}
                    onClose={() =>
                      DataBusDefaults.route({
                        route: match.url,
                        replace: true,
                      })
                    }
                    apperance="slim"
                    disableFocusMode
                    onContextClick={(context) => {
                      DataBusDefaults.filterChanged({
                        identifier: props.identifier,
                        filter: [
                          {
                            filterKey: "data.subactivityId",
                            value: [context.toString()],
                          },
                        ],
                      });
                    }}
                    defaultSignature={props.defaultSignature}
                    comment={comment}
                    commentModuleIdentifier={props.identifier}
                    context={
                      comment.data.subactivityId
                        ? {
                            id: comment.data.subactivityId,
                            name: props.asset?.communication?.subactivities?.find(
                              (e) => e.id === comment.data.subactivityId
                            )?.displayName,
                          }
                        : undefined
                    }
                    hideResubmissionActionForMail={
                      props.hideResubmissionActionForMail
                    }
                    hideAllActions={props.viewOnly}
                    allowFullscreen={props.viewOnly ? false : true}
                    renderMailDuplicateAction={props.renderMailDuplicateAction}
                  />
                )}
              />
            </div>
          )
        }
      />
    </div>
  );
};

import { setFlexCacheData } from "@/redux/actions/application/application-actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CB_CONFIG_KEY } from "./CashBudgetConst";
import CashBudgetService, {
  CASH_BUDGET_ALL_OBJECTS_VAL,
} from "./services/CashBudgetService";
import CBPortfolioUtils from "./views/portfolio/CBPortfolioUtils";

interface CashBudgetInitWrapperProps {
  businessUnits: string[];
  ignoreBalances?: boolean;
  children: JSX.Element;
}
const CashBudgetInitWrapper = (props: CashBudgetInitWrapperProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let entityDropdownOptions = CBPortfolioUtils.generateEntityDropDownOptions(
      props.businessUnits,
      false,
      false,
      true,
      true
    );

    dispatch(
      setFlexCacheData(
        CB_CONFIG_KEY,
        "availableEntities",
        entityDropdownOptions
      )
    );
    // dispatch(setFlexCacheData(
    //   dispatch(comparisonConfigKey,
    //   "availableEntities",
    //   entityDropdownOptions
    // );
    dispatch(
      setFlexCacheData(
        CB_CONFIG_KEY,
        "selectedFilter",
        CASH_BUDGET_ALL_OBJECTS_VAL
      )
    );
    // dispatch(setFlexCacheData(
    //   dispatch(comparisonConfigKey,
    //   "selectedFilter",
    //   CASH_BUDGET_ALL_OBJECTS_VAL
    // );

    dispatch(
      setFlexCacheData(
        CB_CONFIG_KEY,
        "selectedEntities",
        entityDropdownOptions.map((e) => e.value)
      )
    );

    dispatch(setFlexCacheData(CB_CONFIG_KEY, "entitySelectType", "normal"));
    // dispatch(setFlexCacheData(
    //   dispatch(comparisonConfigKey,
    //   "selectedEntities",
    //   entityDropdownOptions.map((e) => e.value)
    // );
    dispatch(
      setFlexCacheData(
        CB_CONFIG_KEY,
        "selectedEntitiesDashboard",
        entityDropdownOptions.map((e) => e.value)
      )
    );
    dispatch(
      setFlexCacheData(CB_CONFIG_KEY, "selectedTimeframeDashboard", "MONTHLY")
    );

    if (!props.ignoreBalances) {
      CashBudgetService.requestBalances(props.businessUnits);
    }

    CBPortfolioUtils.initForCurrentApp();
  }, [props.businessUnits]);
  return props.children;
};

export default CashBudgetInitWrapper;

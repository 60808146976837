import ModalManager from "@/components/ModalComponent/ModalManager";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { useTypedSelector } from "@/redux/hooks";
import CacheService from "@/services/CacheService";
import DataBusDefaults from "@/services/DataBusDefaults";
import SubmitService from "@/services/SubmitService";
import { hasValue, when } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import BFSearch from "../abstract-ui/forms/input/BFSearch";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BFDropdown from "../abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../abstract-ui/icon/DefaultIcons";
import "./BFTemplateChooser.scss";
import { TemplateAsset } from "./TemplateInterfaces";

const TABLE_IDENT = "load-template-table";
export const TEMPLATE_CHOOSER_ADD_NEW = "new";
interface BFTemplateChooserProps {
  identifier?: string;
  disabled?: boolean;
  templateType: string;
  type?: string;
  height?: number;
  addNewEntryText?: string;
  value?: string;
  onSelect: (value: TemplateAsset | null) => void;
}
const BFTemplateChooser = (props: BFTemplateChooserProps) => {
  const userId = useTypedSelector((state) => state.global.user._id);
  const identifier = props.identifier || TABLE_IDENT;

  return (
    <div className={classNames(`bf-template-chooser`)}>
      <div className={`search`}>
        <BFSearch
          tableIdentifier={identifier}
          focusOnMount
          disabled={props.disabled}
        />
      </div>

      <div className={`table-content`} style={{ height: props.height }}>
        <ListComponent
          identifier={identifier}
          assetType={AssetTypes.Template}
          additionalMatchQuery={MQ.and(
            MQ.eq("data.templateType", props.templateType),
            MQ.ne("data.status", "archived"),
            when(hasValue(props.type), MQ.eq("data.type", props.type)),
            MQ.or(MQ.eq("data.user", userId), MQ.eq("data.forAll", true))
          )}
          cleanupOnUnmount
          injectData={
            props.addNewEntryText
              ? [
                  {
                    _id: TEMPLATE_CHOOSER_ADD_NEW,
                    data: {
                      name: props.addNewEntryText,
                    },
                  },
                ]
              : undefined
          }
          render={(node: TemplateAsset) => (
            <div className={`template-button-container`}>
              <BFButton
                disabled={props.disabled}
                className={classNames(`template-button`, {
                  selected: node._id === props.value,
                })}
                onClick={() => props.onSelect(node)}
              >
                {node._id !== TEMPLATE_CHOOSER_ADD_NEW && (
                  <div className={`first-row`}>
                    <div className={`identifier`}>{node.data.identifier}</div>
                    <div className={`fill`} />
                    <div className={`for-whom`}>
                      {node.data.forAll
                        ? i18n.t("Template.forAllAvailable", "Geteilte Vorlage")
                        : i18n.t("Template.forUser", "Deine Vorlage")}
                    </div>
                  </div>
                )}

                <div className={`name`}>{node.data.name}</div>
              </BFButton>
              {node._id !== TEMPLATE_CHOOSER_ADD_NEW && (
                <BFDropdown
                  placement="bottomEnd"
                  label={<BfIcon {...DefaultIcons.MORE} size="xs" />}
                  renderToggle={(props) => (
                    <BFButton
                      className={`more-button`}
                      appearance="default"
                      {...props}
                    >
                      <BfIcon {...DefaultIcons.MORE} size="xs" />
                    </BFButton>
                  )}
                  items={[
                    {
                      type: "button",
                      text: i18n.t("Global.Buttons.delete"),
                      onSelect: () => {
                        ModalManager.confirm({
                          title: i18n.t(
                            "Template.deleteTemplate",
                            "Vorlage löschen"
                          ),
                          message: i18n.t(
                            "Template.deleteTemplateMessage",
                            "Möchtest du die Vorlage wirklich löschen?"
                          ),
                          onConfirm: async () => {
                            const result: TemplateAsset =
                              (await SubmitService.submitDataAsync({
                                type: "asset",
                                assetType: AssetTypes.Template,
                                ignorePropChecks: true,
                                ignoreSubmitValidation: true,
                                data: {
                                  _id: node._id,
                                  data: {
                                    ...node.data,
                                    status: "archived",
                                  },
                                },
                              })) as TemplateAsset;
                            CacheService.update(result);

                            DataBusDefaults.reload({
                              identifiers: [identifier],
                            });
                          },
                          confirmButtonText: i18n.t(
                            "Global.Buttons.delete",
                            "Löschen"
                          ),
                        });
                      },
                    },
                  ]}
                />
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default BFTemplateChooser;

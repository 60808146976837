import { useEffect, useState } from "react";
import { FeatureItemWithSvg, OpenLayerLocationItem } from "../types";
import { fetchFeatureSvg, processSvgSerialization } from "../helpers";
import { convertOpenLayerCoordinates } from "../utils";

export const useFeaturesWithSvg = (
  predefinedLocations?: OpenLayerLocationItem[]
) => {
  // ! state
  const [loading, setLoading] = useState(false);
  const [featuresWithSvg, setFeaturesWithSvg] = useState<FeatureItemWithSvg[]>(
    []
  );

  // ! effects
  useEffect(() => {
    if (!predefinedLocations?.length) return;

    const fetchSvgForFeatures = async () => {
      setLoading(true);
      // Fetch all SVGs in parallel
      const resolvedFeaturesWithSvg = await Promise.all(
        predefinedLocations.map<Promise<FeatureItemWithSvg>>(
          async (feature) => {
            // serialize coordinated for OpenLayer
            const serializedCoordinates = convertOpenLayerCoordinates(
              feature.coordinates
            );

            // define no icon feature result
            const featureToReturn: FeatureItemWithSvg = {
              ...feature,
              coordinates: serializedCoordinates,
              svg: null,
            };

            // return no icon feature or get the svgIcon
            if (!feature.icon) return featureToReturn;

            const unSerializedSvg = await fetchFeatureSvg(feature.icon);
            if (!unSerializedSvg) return featureToReturn;

            const iconSvg = processSvgSerialization(
              unSerializedSvg,
              feature.color
            );
            if (!iconSvg) return featureToReturn;

            return {
              ...featureToReturn,
              svg: iconSvg,
            };
          }
        )
      );

      // Update the state
      setFeaturesWithSvg(resolvedFeaturesWithSvg);
      setLoading(false);
    };

    fetchSvgForFeatures();
  }, [predefinedLocations]);

  return { featuresWithSvg, featuresWithSvgLoading: loading };
};

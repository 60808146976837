import classNames from "classnames";
import "./DContentCard.scss";

interface DContentCardProps {
  title: string;
  className?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
}
const DContentCard = (props: DContentCardProps) => {
  return (
    <div className={classNames(`ap-content-card`, props.className)}>
      <div className={`card-header`}>
        <div className={`title`}>{props.title}</div>
        {props.actions && <div className={`actions`}>{props.actions}</div>}
      </div>
      <div className={`card-scroller`}>
        <div className={`card-content`}>{props.children}</div>
      </div>
    </div>
  );
};

export default DContentCard;

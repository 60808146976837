import i18n from "@/i18n";
import axios from "axios";
import flatten from "flat";
import _ from "lodash";
import Log from "../debug/Log";
import GFSubmitTransformer from "../modules/generic-forms-impl/GFSubmitTransformer";
import { store } from "../redux/store";
import { DataBusSubKeys } from "../utils/Constants";
import { HTTP } from "../utils/Http";
import JsonValidation, {
  JsonProperties,
} from "./../modules/generic-forms/util/JsonValidation";
import { PATCH_TABLE_ROW_DATA } from "./../redux/actions/application/application-action-types";
import CacheService from "./CacheService";
import DataBus from "./DataBus";
import DataBusDefaults from "./DataBusDefaults";

export interface SubmitMessage {
  id?: string;
  type: "asset" | "user" | "group";
  ignorePropChecks?: boolean;
  ignoreSubmitValidation?: boolean;
  assetType?: string;
  pushToCache?: boolean;
  pushToTableCache?: string;
  initialData?: { [key: string]: any };
  data: { [key: string]: any };
  onSuccess?: (data) => void;
  onError?: (err) => void;
  properties?: JsonProperties;
  overwriteUrl?: string;
  overwriteMethod?: "patch" | "put" | "post";
  force?: boolean;
  target?: string;
}

export interface SubmitResponse {
  id: string;
  success: boolean;
  data: any;
}

class SubmitServiceClass {
  constructor() {}

  init() {
    DataBus.subscribe<SubmitMessage>(DataBusSubKeys.SUBMIT, (data) =>
      this.submitData(data)
    );
  }

  submitDataAsync(submitMessage: SubmitMessage, ignoreToast?: boolean) {
    return new Promise((resolve, reject) => {
      this.submitData({
        ...submitMessage,
        onSuccess: (data) => {
          resolve(data);
        },
        onError: (err) => {
          if (!ignoreToast) {
            DataBusDefaults.toast({
              type: "error",
              text: i18n.t(
                "Global.Error.general",
                "Leider ist ein Fehler aufgetreten."
              ),
            });
          }
          reject(err);
        },
      });
    });
  }
  submitData(submitMessage: SubmitMessage) {
    Log.info("###SUBMIT", submitMessage);
    let method = null;
    let url = null;

    if (submitMessage.type === "asset") {
      url = `/${submitMessage.type}/${submitMessage.assetType}`;
      method = HTTP.post;
    } else {
      url = `/${submitMessage.type}`;
      if (submitMessage.data["_id"]) {
        method = HTTP.patch;
        url += `/${submitMessage.data["_id"]}`;
      } else {
        method = HTTP.put;
      }
    }
    if (submitMessage.overwriteUrl) {
      url = submitMessage.overwriteUrl;
    }
    if (submitMessage.overwriteMethod) {
      method = HTTP[submitMessage.overwriteMethod];
    }

    let submitData: object;
    if (!submitMessage.ignorePropChecks) {
      // const submitData = { ...submitMessage.data };
      // delete submitData["_id"];
      // delete submitData["id"];
      // delete submitData["permissions"]["ADMIN"];
      // properties
      // const initialFlattened = submitMessage.initialData
      // 	? flatten(submitMessage.initialData, {
      // 			safe: true,
      // 			delimiter: "|"
      // 	  })
      // 	: null;

      const initialFlattened = {};

      Object.entries(submitMessage.properties).forEach(([key, props]) => {
        const value = JsonValidation.getValue(
          key.split("|"),
          submitMessage.initialData
        );

        initialFlattened[key] = value;
      });

      submitData = flatten.unflatten(
        Object.fromEntries(
          Object.entries(submitMessage.data)
            // .map(([key, value]) => {
            //   let val = value;
            //   if (val === undefined) {
            //     val = null;
            //   }
            //   return [key, val];
            // })
            .filter(([key, value]) => {
              if (submitMessage.ignoreSubmitValidation) {
                return true;
              }
              if (!submitMessage.properties[key]) {
                return false;
              }

              switch (submitMessage.properties[key].submitType) {
                case "add":
                  return true;
                case "ignore":
                  return false;
                default:
                  if (initialFlattened) {
                    let valCheck = value !== undefined ? value : undefined;

                    if (method === HTTP.post) {
                      return valCheck !== undefined;
                    } else {
                      return !_.isEqual(initialFlattened[key], valCheck);
                    }
                  } else {
                    return true;
                  }
              }
            })
        ),
        {
          delimiter: "|",
        }
      );
    } else {
      submitData = submitMessage.data;
    }

    if (Object.keys(submitData).length !== 0 || submitMessage.force) {
      // //todo set cache to loading
      // if(submitMessage.pushToCache) {
      // }
      if (submitMessage.properties) {
        Object.entries(submitMessage.properties).forEach(([key, prop]) => {
          if (submitData[key] !== undefined && submitData[key] !== null) {
            submitData[key] = GFSubmitTransformer.transform(
              prop._component,
              submitData[key],
              submitData
            );
          }
        });
      }

      let cancelObj = { cancel: undefined };

      DataBus.emit(DataBusSubKeys.SUBMIT_START, {
        id: submitMessage.id,
        cancelObj: cancelObj,
      });

      method({
        url: url,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        target: submitMessage.target || "API",
        bodyParams: {
          ...submitData,
          ...(submitMessage.type === "asset"
            ? { _id: submitMessage.data["_id"] }
            : {}),
        },
        cancelToken: new axios.CancelToken(
          (cancel) => (cancelObj.cancel = cancel)
        ),
      })
        .then((response) => {
          if (cancelObj) {
            cancelObj.cancel = undefined;
          }
          if (submitMessage.pushToCache) {
            CacheService.updateDataInCaches(
              submitMessage.data["_id"],
              response
            );

            // store.dispatch({
            //   type: SET_APPICATION_CACHE_DATA,
            //   oType: submitMessage.type,
            //   assetType: submitMessage.assetType,
            //   id: submitMessage.data["_id"],
            //   data: response,
            //   ttl: CACHE_TTL,
            // });
          }
          if (submitMessage.pushToTableCache) {
            store.dispatch({
              type: PATCH_TABLE_ROW_DATA,
              tableIdentifier: submitMessage.pushToTableCache,
              rowId: submitMessage.data["_id"],
              data: {
                ...response,
                _dirty: undefined,
              },
            });
          }

          if (submitMessage.onSuccess) {
            submitMessage.onSuccess(response);
          }

          DataBus.emit(DataBusSubKeys.SUBMIT_RESPONSE, {
            id: submitMessage.id,
            success: true,
            data: response,
          } as SubmitResponse);
        })
        .catch((err) => {
          if (cancelObj) {
            cancelObj.cancel = undefined;
          }
          if (submitMessage.onError) {
            submitMessage.onError(err);
          }
          DataBus.emit(DataBusSubKeys.SUBMIT_RESPONSE, {
            id: submitMessage.id,
            success: false,
            data: err,
          });
        });
    } else {
      //TODO better fix for this issue
      setTimeout(() => {
        DataBus.emit(DataBusSubKeys.SUBMIT_RESPONSE, {
          id: submitMessage.id,
          success: true,
        } as SubmitResponse);
      });
    }
  }
}

const SubmitService = new SubmitServiceClass();

export default SubmitService;

import { useContext } from "react";
import { IAppThemeContext } from "./types";
import { AppThemeContext } from "./context";

export const useAppTheme = (): IAppThemeContext => {
  const appThemeContextValue = useContext(AppThemeContext);

  if (!appThemeContextValue) {
    throw new Error(
      `useAppTheme hook must be used within a AppThemeProvider component. Make sure that your component tree is wrapped with AppThemeProvider.`
    );
  }

  return appThemeContextValue;
};

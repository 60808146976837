import classNames from "classnames";
import { TagConfigWithUnit } from "../../../../apps/AppConfigInterfaces";
import { isDefined } from "../../../../utils/Helpers";
import BFUseTooltip from "../../general/tooltip/BFUseTooltip";
import TagView from "./TagView";
import "./TagViewCompact.scss";

interface TagViewCompactProps {
  possibleTags: TagConfigWithUnit[];
  tags: string[];
  marginRight?: number;
}
const TagViewCompact = (props: TagViewCompactProps) => {
  const validTags = (props.tags || [])
    .map((e) => props.possibleTags.find((a) => a.id === e))
    .filter((e) => isDefined(e));
  if (validTags.length === 0) return null;

  return (
    <BFUseTooltip tooltip={<TagView {...props} />}>
      <div
        className={classNames(`tag-view-compact`)}
        style={{ marginRight: props.marginRight }}
      >
        {validTags.map((tag) => (
          <div
            className={`tag-color-indicator`}
            style={{ background: tag.color }}
          />
        ))}
      </div>
    </BFUseTooltip>
  );
};

export default TagViewCompact;

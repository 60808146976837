import classNames from "classnames";
import moment from "moment";
import { useState } from "react";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import StructLoader from "../../../../../../components/StructLoader/StructLoader";
import VirtualizedTable from "../../../../../../configurable/data/VirtualizedTable/VirtualizedTable";
import DebugDataComponent from "../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import { renderCellValue } from "../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFCheckbox from "../../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import CurrencyLabel from "../../../../../../modules/abstract-ui/forms/input/CurrencyLabel";
import BFButtonToggle from "../../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import ContactLabel from "../../../../../../modules/contacts-module/ContactLabel";
import { getDefaultCurrencyNumberFormat } from "../../../../../../modules/export/export.model";
import InvoiceStruct from "../../../../../../redux/actions/struct/implemented/InvoiceStruct";
import DataBus from "../../../../../../services/DataBus";
import { MatchQuery } from "../../../../../../services/DataService";
import GlobalActions from "../../../../../../services/GlobalActions";
import LanguageService from "../../../../../../services/LanguageService";
import { valueOrDefault, when } from "../../../../../../utils/Helpers";
import MQ from "../../../../../../utils/MatchQueryUtils";
import { InvoiceStatus } from "../../../../invoiceApp/RAInterfaces";
import {
  RA_INVOICE_MAIN_FIELDS,
  getInvoiceStatusLabel,
} from "../../../../invoiceApp/RAUtils";
import InvoicePaymentPlan from "../../../../invoiceApp/components/ListInvoices/InvoicePaymentPlan";
import UrgentIndicator from "../../../../invoiceApp/components/UrgentIndidactor/UrgentIndicator";
import { useActivityConstants } from "../../../ActivityHooks";
import { APActivity } from "../../../ActivityInterfaces";
import {
  APInvoiceProjectLink,
  APProjectBudget,
} from "../project-budget/APProjectBudgetInterfaces";
import APBudgetInvoiceSubrow from "./APBudgetInvoiceSubrow";
import "./APBudgetInvoices.scss";

const AP_BUDGET_INVOICE_LIST = "ap_budget_invoice_list";
interface APBudgetInvoicesProps {
  activity: APActivity;
  hideHeader?: boolean;
  additionalMatchQuery?: MatchQuery;
  overwriteIdentifier?: string;
}
const APBudgetInvoices = (props: APBudgetInvoicesProps) => {
  const constants = useActivityConstants();
  const [filter, setFilter] = useState<"all" | "assigned" | "not-assigned">(
    "all"
  );

  const render = (budget: APProjectBudget) => {
    return (
      <div className={classNames(`ap-budget-invoices`)}>
        {!props.hideHeader && (
          <div className={`filter-header`}>
            <BFButtonToggle
              value={filter}
              onChange={setFilter}
              buttons={[
                {
                  value: "all",
                  text: i18n.t("apTemplate:BudgetInvoices.All", "Alle", {
                    ns: [constants?.assetType, "apTemplate"],
                  }),
                },
                {
                  value: "assigned",
                  text: i18n.t(
                    "apTemplate:BudgetInvoices.Assigned",
                    "Zugeordnet",
                    {
                      ns: [constants?.assetType, "apTemplate"],
                    }
                  ),
                },
                {
                  value: "not-assigned",
                  text: i18n.t(
                    "apTemplate:BudgetInvoices.NotAssigned",
                    "Nicht zugeordnet",
                    {
                      ns: [constants?.assetType, "apTemplate"],
                    }
                  ),
                },
              ]}
            />
          </div>
        )}
        <div className={`invoice-list __card`}>
          <VirtualizedTable
            cleanupOnUnmount
            identifier={props.overwriteIdentifier || AP_BUDGET_INVOICE_LIST}
            assetType={AssetTypes.ActivityRelated.ProjectInvoiceLink}
            asPost
            hover
            selection="single"
            onRowDoubleClick={async (link: APInvoiceProjectLink) => {
              await GlobalActions.openDetails(
                AssetTypes.Invoice,
                link?.expand?.linkedInvoice._id,
                link?.expand?.linkedInvoice.data.type
              );
            }}
            additionalMatchQuery={MQ.and(
              // MQ.eq("expand.linkedInvoice.data.direction", "INCOMING"),
              MQ.eq("data.linkedProject.assetId", props.activity._id),
              when(filter === "assigned", MQ.eq("data.difference", 0)),
              when(filter === "not-assigned", MQ.ne("data.difference", 0)),
              props.additionalMatchQuery
            )}
            calculateSize={(data: APInvoiceProjectLink, index) => {
              return [
                35,
                (data.data.difference !== 0 ? 30 : 0) +
                  data.data.splitValues.length * 30,
              ];
            }}
            expandKeys={[
              {
                key: "data.linkedInvoice",
                assetType: AssetTypes.Invoice,
              },
            ]}
            subRowRender={(position, node: APInvoiceProjectLink) =>
              position === "center" ? (
                <APBudgetInvoiceSubrow
                  budget={budget}
                  link={node}
                  onUpdated={() => {
                    DataBus.emit("TABLE_FORCE_RERENDER", {
                      identifier:
                        props.overwriteIdentifier || AP_BUDGET_INVOICE_LIST,
                    });
                  }}
                />
              ) : null
            }
            columns={{
              "expand.linkedInvoice.id": {
                label: "ID",
                sortable: true,
                flexWidth: 70,
                resizable: true,
                render: (node: APInvoiceProjectLink, index, params) => (
                  <>
                    <DebugDataComponent data={node} />
                    <UrgentIndicator
                      urgent={node.expand?.linkedInvoice.data.urgent}
                      title={i18n.t(
                        "ra:Invoice.Indicator.Urgent",
                        "Dringende Rechnung"
                      )}
                    />
                    {renderCellValue(node.expand?.linkedInvoice.id)}
                  </>
                ),
                // renderFooter: (params) =>
                //   params.aggregated
                //     ? renderCellValue(
                //         `${i18n.t("ra:List.count", "Anz.")}: ${
                //           params?.aggregated?.data?.["general"]?.count || "-"
                //         }`
                //       )
                //     : undefined,
                export: {
                  width: 30,
                  label: i18n.t("ra:List.id"),
                  type: "number",
                  selector: (node: APInvoiceProjectLink) =>
                    node.expand?.linkedInvoice.id,
                },
              },

              "expand.linkedInvoice.data.invoice.invoiceType": {
                label: RA_INVOICE_MAIN_FIELDS().invoiceType.label,
                flexWidth: 200,
                sortable: true,
                resizable: true,
                render: (node: APInvoiceProjectLink, index, params) => (
                  <StructLoader
                    structType="invoice"
                    unitType={node.expand?.linkedInvoice.data.type}
                    render={() => {
                      return renderCellValue(
                        node.expand?.linkedInvoice.data?.invoice.invoiceType,
                        "-",
                        (value: string) =>
                          LanguageService.translateLabel(
                            InvoiceStruct.getInvoiceType(value)?.data
                              .displayName || value
                          )
                      );
                    }}
                  />
                ),

                export: {
                  width: 30,
                  label: RA_INVOICE_MAIN_FIELDS().invoiceType.label,
                  type: "string",
                  selector: (node: APInvoiceProjectLink) =>
                    valueOrDefault(
                      LanguageService.translateLabel(
                        InvoiceStruct.getInvoiceType(
                          node.expand?.linkedInvoice.data?.invoice?.invoiceType
                        )?.data.displayName
                      ),
                      "-"
                    ),
                },
              },
              "expand.linkedInvoice.data.status": {
                label: i18n.t("Global.Labels.Status"),
                sortable: true,
                flexWidth: 130,
                resizable: true,
                render: (node: APInvoiceProjectLink, index, params) =>
                  renderCellValue(
                    node?.expand?.linkedInvoice?.data?.status,
                    "-",
                    (value: InvoiceStatus) => getInvoiceStatusLabel(value)
                    //fixme add label
                    // (value: number) => StringUtils.formatCurrency(value)
                  ),

                export: {
                  width: 30,
                  label: i18n.t("Global.Labels.Status"),
                  type: "string",
                  selector: (node: APInvoiceProjectLink) =>
                    valueOrDefault(
                      getInvoiceStatusLabel(
                        node.expand?.linkedInvoice?.data?.status
                      ),
                      "-"
                    ),
                },
              },

              "expand.contact.data.displayName": {
                label: RA_INVOICE_MAIN_FIELDS().contact.label,
                sortable: true,
                flexWidth: 200,
                resizable: true,
                render: (node: APInvoiceProjectLink, index, params) =>
                  node.expand?.linkedInvoice.data.invoice.contact ? (
                    <ContactLabel
                      contactId={
                        node.expand?.linkedInvoice.data.invoice.contact
                      }
                    />
                  ) : (
                    "-"
                  ),
              },

              "expand.linkedInvoice.data.invoice.value.converted.amount": {
                label: RA_INVOICE_MAIN_FIELDS().amountToPay.label,
                alignment: "right",
                sortable: true,
                flexWidth: 220,
                resizable: true,
                render: (node: APInvoiceProjectLink, index, params) => (
                  <div className={`amount-info`}>
                    <InvoicePaymentPlan asset={node.expand?.linkedInvoice} />

                    <CurrencyLabel
                      value={node.expand?.linkedInvoice.data.invoice.value}
                    />
                  </div>
                ),
                // renderFooter: (params) =>
                //   params.aggregated
                //     ? renderCellValue(
                //         params?.aggregated?.data?.["general"]?.amountToPay,
                //         "-",
                //         (value: number) => StringUtils.formatCurrency(value)
                //       )
                //     : undefined,
                export: (node: APInvoiceProjectLink) => ({
                  width: 30,
                  label: RA_INVOICE_MAIN_FIELDS().amountToPay.label,
                  type: "number",
                  totalFunction: "sum",
                  style: {
                    numFmt: getDefaultCurrencyNumberFormat(),
                  },
                  //TODO fix currency to specitifc one - we need to add possibility to set specific currency for each invoice
                  selector: (node: APInvoiceProjectLink) =>
                    node.expand?.linkedInvoice.data.invoice.value.converted
                      .amount,
                }),
              },
              "expand.linkedInvoice.data.payed": {
                label: i18n.t("ra:List.payed"),
                sortable: true,
                alignment: "center",
                resizable: true,
                flexWidth: 100,
                render: (node: APInvoiceProjectLink, index, params) => (
                  <div className="checkbox-wrapper">
                    <BFCheckbox
                      readOnly
                      checked={node.expand?.linkedInvoice.data.payed}
                    />
                  </div>
                ),
                // renderFooter: (params) =>
                //   params.aggregated
                //     ? renderCellValue(
                //         `${
                //           params?.aggregated?.data?.["payed"]?.count || "0"
                //         } / ${
                //           params?.aggregated?.data?.["general"]?.count || "0"
                //         }`
                //       )
                //     : undefined,
                export: {
                  width: 30,
                  label: i18n.t("ra:List.payed"),
                  type: "string",
                  selector: (node: APInvoiceProjectLink) =>
                    node.expand?.linkedInvoice.data?.payed ? "TRUE" : "FALSE",
                },
              },

              "expand.linkedAsset.data.invoice.documentDate": {
                label: RA_INVOICE_MAIN_FIELDS().documentDate.label,
                sortable: true,
                resizable: true,
                flexWidth: 130,
                render: (node: APInvoiceProjectLink, index, params) =>
                  renderCellValue(
                    node?.expand?.linkedInvoice.data?.invoice?.documentDate,
                    "-",
                    (value: string) =>
                      moment(value)
                        .utc(true)
                        .format(i18n.t("Formats.dateFormat"))
                  ),

                export: {
                  width: 30,
                  label: RA_INVOICE_MAIN_FIELDS().documentDate.label,
                  type: "date",
                  selector: (node: APInvoiceProjectLink) =>
                    node?.expand?.linkedInvoice.data?.invoice?.documentDate
                      ? new Date(
                          node?.expand?.linkedInvoice.data?.invoice?.documentDate
                        )
                      : node?.expand?.linkedInvoice.data?.invoice?.documentDate,
                },
              },
            }}
          />
        </div>
      </div>
    );
  };

  if (!props.activity?.data.currentActiveBudgetId) {
    return render(null);
  }
  return (
    <AssetLoader
      assetType={constants.fields?.projectBudgetFeature?.budgetAssetType}
      query={MQ.eq("_id", props.activity?.data.currentActiveBudgetId)}
      render={(budget: APProjectBudget) => render(budget)}
    />
  );
};

export default APBudgetInvoices;

import AccountingLoader, {
  AccountingData,
} from "@/apps/tatar/accounting/AccountingLoader";
import {
  AccountingAccount,
  AccountingBookingFormValue,
  AccountType,
} from "@/apps/tatar/accounting/interfaces/account.interface";
import AccountingInternBalancingForm from "@/apps/tatar/accounting/views/rental/form/account-intern-balancing/AccountingInternBalancingForm";
import AccountingInternBookingForm, {
  AccountingInternBookingFormValue,
} from "@/apps/tatar/accounting/views/rental/form/account-intern-booking/AccountingInternBookingForm";
import AccountingShouldPositionForm from "@/apps/tatar/accounting/views/rental/form/account-should-position/AccountingShouldPositionForm";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import StructLoader from "@/components/StructLoader/StructLoader";
import PersistentSplitPane from "@/configurable/data/SplitPane/PersistentSplitPane";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "@/modules/abstract-ui/general/Dropdown/BFDropdown";
import { clearHttpCache } from "@/redux/hooks";
import DataBusDefaults from "@/services/DataBusDefaults";
import { when } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import { RentalAgreement } from "../../TenantsInterfaces";
import "./CBRentalAgreementAccounting.scss";
import CBRentalAgreementDebitPositions from "./accounting/CBRentalAgreementDebitPositions";
import { openCBRentalAgreementDebitPositionsExport } from "./accounting/CBRentalAgreementDebitPositionsExport";
import CBRentalAgreementOpenAmounts from "./accounting/CBRentalAgreementOpenAmounts";
import CBRentalAgreementOpenDebitPositions from "./accounting/CBRentalAgreementOpenDebitPositions";

const CHART_ID = "rental-agreement-accounting-chart";
interface CBRentalAgreementAccountingProps {
  rentalAgreement: RentalAgreement;
}
const CBRentalAgreementAccounting = (
  props: CBRentalAgreementAccountingProps
) => {
  const [form, setForm] = useState<React.ReactNode>(null);

  return (
    <AssetLoader
      assetType={AssetTypes.Portfolio.Object}
      id={props.rentalAgreement.data.objectId}
      render={(object: OAObject) => (
        <StructLoader
          unitType={props.rentalAgreement.data.type}
          structTypes={["objectKind", "orga"]}
          render={() => {
            return (
              <AssetLoader
                assetType={AssetTypes.Accounting.Account}
                query={MQ.eq(
                  "data.linkedAsset.assetId",
                  props.rentalAgreement._id
                )}
                render={(account: AccountingAccount) =>
                  account ? (
                    <AssetLoader
                      assetType={AssetTypes.Accounting.Account}
                      query={MQ.and(
                        MQ.eq("data.entity", props.rentalAgreement.data.entity),
                        MQ.eq(
                          "data.linkedAsset.assetType",
                          AssetTypes.Rental.RentalAgreement
                        ),
                        MQ.eq(
                          "data.linkedAsset.assetId",
                          props.rentalAgreement._id
                        )
                      )}
                      render={(
                        debitAcc: AccountingAccount,
                        _selector,
                        reload
                      ) => (
                        <AccountingLoader
                          entityId={props.rentalAgreement.data.entity}
                          render={(accountingData: AccountingData) => {
                            const bookingDropdown = (
                              <CBRentalAccountingDropdown
                                accountingData={accountingData}
                                rentalAgreement={props.rentalAgreement}
                                account={account}
                                reloadAccountingAccount={reload}
                                addForm={(form) => setForm(form)}
                                closeForm={() => setForm(null)}
                              />
                            );

                            const mainContent = (
                              <PersistentSplitPane
                                className={classNames(`split-pane-top`, {
                                  "form-open": Boolean(form),
                                })}
                                identifier="rental-agreement-accounting-split-pane-top"
                                split="horizontal"
                                defaultSize={"30%"}
                                maxSize={600}
                                minSize={300}
                                primary="first"
                                allowResize
                                onSizeChange={() => {
                                  DataBusDefaults.chartResized(CHART_ID);
                                }}
                              >
                                <div className={`open-amounts`}>
                                  <AssetLoader
                                    assetType={AssetTypes.Accounting.Account}
                                    query={MQ.and(
                                      MQ.eq(
                                        "data.entity",
                                        accountingData.accounting.data.entity
                                      ),
                                      MQ.eq(
                                        "data.linkedAsset.assetType",
                                        AssetTypes.Rental.RentalAgreement
                                      ),
                                      MQ.eq(
                                        "data.linkedAsset.assetId",
                                        props.rentalAgreement._id
                                      )
                                    )}
                                    render={(
                                      debitAccount: AccountingAccount,
                                      _selector,
                                      reload
                                    ) => (
                                      <AssetLoader
                                        assetType={
                                          AssetTypes.Accounting.Account
                                        }
                                        query={MQ.and(
                                          MQ.eq(
                                            "data.entity",
                                            accountingData.accounting.data
                                              .entity
                                          ),
                                          MQ.eq(
                                            "data.linkedAsset.assetType",
                                            AssetTypes.Rental.RentalAgreement
                                          ),
                                          MQ.eq(
                                            "data.linkedAsset.assetId",
                                            props.rentalAgreement._id
                                          )
                                        )}
                                        render={(
                                          account: AccountingAccount
                                        ) => (
                                          <CBRentalAgreementOpenAmounts
                                            chartId={CHART_ID}
                                            accountingData={accountingData}
                                            object={object}
                                            rentalAgreement={
                                              props.rentalAgreement
                                            }
                                            account={account}
                                            debitAccount={debitAccount}
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </div>
                                <div className={`split-pane-container`}>
                                  <PersistentSplitPane
                                    identifier="rental-agreement-accounting-split-pane-bottom"
                                    split="horizontal"
                                    defaultSize={"50%"}
                                    maxSize={-200}
                                    minSize={150}
                                    primary="first"
                                    allowResize
                                    onSizeChange={() => {
                                      // if (chartId.current) {
                                      //   DataBusDefaults.chartResized(chartId.current);
                                      // }
                                    }}
                                  >
                                    <div className={`open-demands`}>
                                      <div className={`sub-header`}>
                                        <div className={`label`}>
                                          {i18n.t(
                                            "cb:RentalAgreement.Accounting.OpenDemands",
                                            "Offene Forderungen"
                                          )}
                                        </div>
                                        {bookingDropdown}
                                      </div>
                                      <div className={`open-debit-positions`}>
                                        <AssetLoader
                                          assetType={
                                            AssetTypes.Accounting.Account
                                          }
                                          query={MQ.and(
                                            MQ.eq(
                                              "data.entity",
                                              accountingData.accounting.data
                                                .entity
                                            ),
                                            MQ.eq(
                                              "data.linkedAsset.assetId",
                                              props.rentalAgreement._id
                                            )
                                          )}
                                          render={(
                                            account: AccountingAccount
                                          ) => (
                                            <CBRentalAgreementOpenDebitPositions
                                              accountingData={accountingData}
                                              account={account?._id}
                                              showBalanced={false}
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className={`debit-positions`}>
                                      <div className={`sub-header`}>
                                        <div className={`label`}>
                                          {i18n.t(
                                            "cb:RentalAgreement.Accounting.AllDebitPositions",
                                            "Mieterkonto"
                                          )}
                                        </div>
                                        <BFButton
                                          appearance="link"
                                          onClick={() => {
                                            openCBRentalAgreementDebitPositionsExport(
                                              props.rentalAgreement,
                                              accountingData
                                            );
                                          }}
                                        >
                                          {i18n.t(
                                            "Global.Buttons.export",
                                            "Export"
                                          )}
                                        </BFButton>
                                        {bookingDropdown}
                                      </div>
                                      <div
                                        className={`debit-positions-container`}
                                      >
                                        <AssetLoader
                                          assetType={
                                            AssetTypes.Accounting.Account
                                          }
                                          query={MQ.and(
                                            MQ.eq(
                                              "data.entity",
                                              accountingData.accounting.data
                                                .entity
                                            ),
                                            MQ.eq(
                                              "data.linkedAsset.assetId",
                                              props.rentalAgreement._id
                                            )
                                          )}
                                          render={(
                                            account: AccountingAccount
                                          ) => (
                                            <CBRentalAgreementDebitPositions
                                              accountingData={accountingData}
                                              account={account?._id}
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </PersistentSplitPane>
                                </div>
                              </PersistentSplitPane>
                            );
                            return (
                              <div
                                className={classNames(
                                  `cb-rental-agreement-accounting __card`
                                )}
                              >
                                {!form && mainContent}

                                {form && (
                                  <PersistentSplitPane
                                    className={classNames(`split-pane-outer`)}
                                    identifier="rental-agreement-accounting-split-pane-outer"
                                    split="horizontal"
                                    defaultSize={"30%"}
                                    maxSize={-300}
                                    minSize={300}
                                    primary="first"
                                    allowResize
                                    onSizeChange={() => {
                                      DataBusDefaults.chartResized(CHART_ID);
                                    }}
                                  >
                                    <div className={`main-container`}>
                                      {mainContent}
                                    </div>
                                    <div className={`form-container`}>
                                      {form}
                                    </div>
                                  </PersistentSplitPane>
                                )}
                              </div>
                            );
                          }}
                        />
                      )}
                    />
                  ) : (
                    <div>No account found</div>
                  )
                }
              />
            );
          }}
        />
      )}
    />
  );
};

export default CBRentalAgreementAccounting;

const CBRentalAccountingDropdown = (props: {
  accountingData: AccountingData;
  rentalAgreement: RentalAgreement;
  account: AccountingAccount;
  reloadAccountingAccount: () => void;
  addForm: (form: React.ReactNode) => void;
  closeForm: () => void;
}) => {
  const { accountingData, rentalAgreement, account, reloadAccountingAccount } =
    props;

  const onSuccess = () => {
    accountingData.reload();
    clearHttpCache(
      `cb-rental-agreement-open-debit-positions-${account._id}-true`
    );
    clearHttpCache(
      `cb-rental-agreement-open-debit-positions-${account._id}-false`
    );

    clearHttpCache(`cb-rental-agreement-all-debit-positions-${account._id}`);
    reloadAccountingAccount();
    props.closeForm();
  };

  const openInterBookingForm = (
    baseAccount: string,
    title: string,
    transformFc?: (
      values: AccountingInternBookingFormValue
    ) => AccountingBookingFormValue
  ) => {
    props.addForm(
      <AccountingInternBookingForm
        accountTypes={[AccountType.debitor_rentalagreement]}
        onClose={props.closeForm}
        baseAccount={baseAccount}
        accounting={accountingData}
        forAccount={account}
        onSuccess={() => {
          onSuccess();
        }}
        title={title}
        transformSubmitValues={transformFc}
      />
    );
    // ModalManager.show({
    //   size: "xl",
    //   noPadding: true,
    //   content: (state, setState, close) => (
    //     <AccountingInternBookingForm
    //       accountTypes={[AccountType.debitor_rentalagreement]}
    //       onClose={close}
    //       baseAccount={baseAccount}
    //       accounting={accountingData}
    //       forAccount={account}
    //       onSuccess={() => {
    //         onSuccess();
    //       }}
    //       title={title}
    //       transformSubmitValues={transformFc}
    //     />
    //   ),
    // });
  };

  return (
    <div>
      <AssetLoader
        inline
        assetType={AssetTypes.Accounting.Account}
        query={MQ.and(
          MQ.eq("data.entity", accountingData.accounting.data.entity),
          MQ.eq("data.accountType", AccountType.debitor_rent_reduction),
          when(
            rentalAgreement.data.taxable,
            MQ.ne("data.taxAccount.taxRate", 0)
          ),
          when(
            !rentalAgreement.data.taxable,
            MQ.eq("data.taxAccount.taxRate", 0)
          )
          // rentalAgreement.data.taxable
          // ? (e.taxAccount?.taxRate || 0) !== 0
          // : (e.taxAccount?.taxRate || 0) === 0
        )}
        render={(debitor_rent_reduction: AccountingAccount) => (
          <AssetLoader
            inline
            assetType={AssetTypes.Accounting.Account}
            query={MQ.and(
              MQ.eq("data.entity", accountingData.accounting.data.entity),
              MQ.eq("data.accountType", AccountType.debitor_rent_loss),
              when(
                rentalAgreement.data.taxable,
                MQ.ne("data.taxAccount.taxRate", 0)
              ),
              when(
                !rentalAgreement.data.taxable,
                MQ.eq("data.taxAccount.taxRate", 0)
              )
              // rentalAgreement.data.taxable
              // ? (e.taxAccount?.taxRate || 0) !== 0
              // : (e.taxAccount?.taxRate || 0) === 0
            )}
            render={(debitor_rent_loss: AccountingAccount) => (
              <BFDropdown
                label={i18n.t("cb:RentalAgreement.Accounting.Book", "Buchen")}
                asOverlay
                placement={"bottomEnd"}
                toggleAs={(toggleProps) => (
                  <BFButton appearance="link" {...toggleProps}>
                    {i18n.t("cb:RentalAgreement.Accounting.Book", "Buchen")}
                  </BFButton>
                )}
                items={[
                  {
                    type: "button",
                    text: i18n.t(
                      "cb:RentalAgreement.Accounting.internBalancing",
                      "Mietinterner Ausgleich"
                    ),
                    info: i18n.t(
                      "cb:RentalAgreement.Accounting.internBalancingInfo",
                      "Verrechnungen von Gutschriften mit Sollstellungen"
                    ),
                    onSelect: () => {
                      props.addForm(
                        <AccountingInternBalancingForm
                          accountTypes={[AccountType.debitor_rentalagreement]}
                          onClose={props.closeForm}
                          accounting={accountingData}
                          forAccount={account}
                          onSuccess={() => {
                            onSuccess();
                          }}
                        />
                      );
                      // ModalManager.show({
                      //   size: "xl",
                      //   noPadding: true,
                      //   content: (state, setState, close) => (
                      //     <AccountingInternBalancingForm
                      //       accountTypes={[AccountType.debitor_rentalagreement]}
                      //       onClose={close}
                      //       accounting={accountingData}
                      //       forAccount={account}
                      //       onSuccess={() => {
                      //         onSuccess();
                      //       }}
                      //     />
                      //   ),
                      // });
                    },
                  },
                  {
                    type: "button",
                    text: i18n.t(
                      "cb:RentalAgreement.Accounting.rentalReduction",
                      "Mietminderung"
                    ),
                    info: i18n.t(
                      "cb:RentalAgreement.Accounting.rentalReductionInfo",
                      "Vereinbarte Mietminderungen aufgrund von Schäden oder ähnlichem"
                    ),
                    onSelect: () => {
                      if (debitor_rent_reduction) {
                        openInterBookingForm(
                          debitor_rent_reduction._id,
                          i18n.t(
                            "cb:RentalAgreement.Accounting.rentalReduction",
                            "Mietminderung"
                          )
                        );
                      } else {
                        DataBusDefaults.toast({
                          type: "error",
                          text: i18n.t(
                            "cb:RentalAgreement.Accounting.noReductionAccount",
                            "Kein Mietminderungskonto gefunden"
                          ),
                        });
                      }
                    },
                  },
                  {
                    type: "button",
                    text: i18n.t(
                      "cb:RentalAgreement.Accounting.lossOfRent",
                      "Mietausfall"
                    ),
                    info: i18n.t(
                      "cb:RentalAgreement.Accounting.lossOfRentInfo",
                      "Mietausfall wegen Insolvenzen oder ähnlichem"
                    ),
                    onSelect: () => {
                      if (debitor_rent_loss) {
                        openInterBookingForm(
                          debitor_rent_loss._id,
                          i18n.t(
                            "cb:RentalAgreement.Accounting.lossOfRent",
                            "Mietausfall"
                          )
                        );
                      } else {
                        DataBusDefaults.toast({
                          type: "error",
                          text: i18n.t(
                            "cb:RentalAgreement.Accounting.noLossOfRentAccount",
                            "Kein Mietausfallkonto gefunden"
                          ),
                        });
                      }
                    },
                  },
                  {
                    type: "button",
                    text: i18n.t(
                      "cb:RentalAgreement.Accounting.createManualShouldPosition",
                      "Sollstellung"
                    ),
                    info: i18n.t(
                      "cb:RentalAgreement.Accounting.createManualShouldPositionInfo",
                      "Manuelle Sollstellung einmalig erstellen"
                    ),
                    onSelect: () => {
                      props.addForm(
                        <AccountingShouldPositionForm
                          accountTypes={[AccountType.debitor_rentalagreement]}
                          taxable={rentalAgreement.data.taxable}
                          onClose={props.closeForm}
                          accounting={accountingData}
                          forAccount={account}
                          onSuccess={() => {
                            onSuccess();
                          }}
                        />
                      );
                    },
                  },
                  {
                    type: "button",
                    text: i18n.t(
                      "cb:RentalAgreement.Accounting.correctionShouldPosition",
                      "Sollstellungskorrektur"
                    ),
                    info: i18n.t(
                      "cb:RentalAgreement.Accounting.correctionShouldPositionInfo",
                      "Korrekturen aufgrund falscher Stammdatenpflege"
                    ),
                    onSelect: () => {
                      openInterBookingForm(
                        null,
                        i18n.t(
                          "cb:RentalAgreement.Accounting.correctionShouldPosition",
                          "Sollstellungskorrektur"
                        ),
                        (values) => {
                          // return null;
                          return {
                            groupDisplayName: values.bookingText,
                            date: values.date,
                            entity: values.entity,
                            account: account._id,
                            fullAmount: 0,
                            note: values.note,
                            linkedAsset: [
                              ...values.linkedAsset,
                              ...values.attachments.map((e) => ({
                                assetType: AssetTypes.CashBudget.Attachment,
                                assetId: e,
                              })),
                            ],
                            frames: _.uniq(
                              values.bookings.bookings
                                .filter((e) => (e.value?.amount || 0) !== 0)
                                .map((e) => e.costAccount)
                            ).map((contraAcc) => ({
                              objectId: values.objectId,
                              contraAccount: contraAcc,
                              bookings: values.bookings.bookings
                                .filter((e) => (e.value?.amount || 0) !== 0)
                                .filter((e) => e.costAccount === contraAcc)
                                .map((booking) => ({
                                  ...booking,
                                  bookingType:
                                    booking.bookingType === "S" ? "H" : "S",
                                  referenceField:
                                    booking.costId === null
                                      ? values.bookings.referenceField
                                      : null,
                                })),
                            })),
                            // frames: [
                            //   {
                            //     objectId: values.objectId,
                            //     contraAccount: values.account,
                            //     bookings: values.bookings.filter(
                            //       (e) => (e.value?.amount || 0) !== 0
                            //     ),
                            //   },
                            // ],
                          } as AccountingBookingFormValue;
                        }
                      );
                    },
                  },
                  // {
                  //   type: "button",
                  //   disabled: true,
                  //   text: i18n.t(
                  //     "cb:RentalAgreement.Accounting.depositSettlement",
                  //     "Kautionsabrechnung"
                  //   ),
                  //   info: i18n.t(
                  //     "cb:RentalAgreement.Accounting.depositSettlementInfo",
                  //     "Abrechnungen von Kautionsbuchungen"
                  //   ),
                  //   onSelect: () => {},
                  // },
                  // {
                  //   type: "button",
                  //   disabled: true,
                  //   text: i18n.t(
                  //     "cb:RentalAgreement.Accounting.operationCostCorrection",
                  //     "BKA Korrektur"
                  //   ),
                  //   info: i18n.t(
                  //     "cb:RentalAgreement.Accounting.operationCostCorrectionInfo",
                  //     "Korrekturen an der Betriebskostenabrechnungen"
                  //   ),
                  //   onSelect: () => {},
                  // },
                ]}
              />
            )}
          />
        )}
      />
    </div>
  );
};

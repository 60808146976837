import { css } from "emotion";
import React, { SyntheticEvent } from "react";
import { connect } from "react-redux";
import { GenericFormsLayoutProps } from "../../../modules/generic-forms/GFBaseElement";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import { SendEvent } from "../../../utils/abstracts/AbstractComponent";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";
import { IComponent } from "../IComponent";

export type DivLayoutConf = {
  className?: string;
  _className?: string;
  components?: { [key: string]: IComponent | React.ReactNode };
  clickEvents?: { [key: string]: Function | SendEvent };

  items: { [key: string]: any };
  condition?: string;
} & AbstractStylableProps &
  GenericFormsLayoutProps;

type States = {} & AbstractStylableStates;

class DivLayout extends AbstractStylableComponent<DivLayoutConf, States> {
  render() {
    const { clickEvents, className, _className, components, items, condition } =
      this.props;

    if (!this.shoudBeRendered()) {
      return null;
    }

    const useClassName = _className
      ? this.evaluateExpression(_className)
      : className;
    return (
      <div
        className={`div-layout ${useClassName ? useClassName : ""} ${
          this.state.usedStyle ? css(this.state.usedStyle as any) : ""
        }`}
        onClick={
          clickEvents
            ? (e: SyntheticEvent) => {
                this.handleEvents(clickEvents, {
                  target: {
                    className: (e.target as HTMLElement).className,
                    localName: (e.target as HTMLElement).localName,
                    nodeName: (e.target as HTMLElement).nodeName,
                  },
                });
              }
            : undefined
        }
      >
        {components
          ? Object.entries(components)
              .sort((a, b) =>
                a[1] && b[1] ? a[1].orderIndex - b[1].orderIndex : 0
              )
              .map(([key, item]) => {
                return (window as any).ComponentsMapper.createElement(
                  item,
                  this.props.params,
                  key
                );
              })
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: DivLayoutConf) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

const exportComp = connect(mapStateToProps, {})(DivLayout) as any;

export default exportComp;

import { Contact } from "@/model/db/Contact";
import moment from "moment";
import i18n from "../../../../../i18n";
import {
  EnrichtedRentalUnit,
  RentalAgreement,
  RentalUnit,
  RentalVacancy,
} from "./TenantsInterfaces";
export const RentalAgreementOptionBy = () => [
  {
    value: "tenant",
    label: i18n.t("cb:RentalAgreement.Form.Option.optionByTenant", "Mieter"),
  },
  {
    value: "landlord",
    label: i18n.t(
      "cb:RentalAgreement.Form.Option.optionByLandloard",
      "Vermieter"
    ),
  },
  {
    value: "automatic",
    label: i18n.t(
      "cb:RentalAgreement.Form.Option.optionByAutomatic",
      "Automatisch"
    ),
  },
];

export const RentalAgreementChangeReasons = () => [
  {
    value: "creation",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.creation",
      "Mietvertragsabschluss"
    ),
    editable: false,
  },
  {
    value: "agreementChange",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.agreementChange",
      "Mietvertragsänderung"
    ),
    editable: true,
  },
  {
    value: "indexChange",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.indexChange",
      "Änderung Mietpreis (Index)"
    ),
    editable: true,
  },
  {
    value: "seasonChange",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.seasonChange",
      "Änderung Mietpreis (Staffel)"
    ),
    editable: true,
  },
  {
    value: "rentChange",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.rentChange",
      "Änderung Mietpreis (sonstige)"
    ),
    editable: true,
  },
  {
    value: "operatingCostChange",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.operatingCostChange",
      "Änderung Betriebskosten"
    ),
    editable: true,
  },
  {
    value: "rentFreeTime",
    label: i18n.t(
      "cb:RentalAgreementChangeReasons.rentFreeTime",
      "Mietfreie Zeit"
    ),
    editable: true,
  },
  {
    value: "misc",
    label: i18n.t("cb:RentalAgreementChangeReasons.misc", "Sonstiges"),
    editable: true,
  },
];

const RentalVacancyStatus = {
  future: {
    label: () =>
      i18n.t("cb:RentalVacancyStatus.future", "Zukünftiger Leerstand"),
    value: "future",
    color: "#ffc493",
  },
  current: {
    label: () =>
      i18n.t("cb:RentalVacancyStatus.current", "Aktueller Leerstand"),
    value: "current",
    color: "#dd9f9f",
  },
  past: {
    label: () => i18n.t("cb:RentalVacancyStatus.past", "Ehemaliger Leerstand"),
    value: "past",
    color: "#dbdbdb",
  },
};

class CBRentalUtilsClass {
  calculateVacancyStatus = (vacancy: RentalVacancy) => {
    if (moment(vacancy.data.moveIn).isAfter(moment())) {
      return RentalVacancyStatus.future;
    }
    if (
      !vacancy.data.moveOut ||
      moment(vacancy.data.moveOut).isAfter(moment())
    ) {
      return RentalVacancyStatus.current;
    }
    return RentalVacancyStatus.past;
  };

  getContractTypeLabel = (contractType: "business" | "private") => {
    switch (contractType) {
      case "business":
        return i18n.t(
          "cb:RentalAgreement.Form.ContractType.business",
          "Gewerblich"
        );
      case "private":
        return i18n.t("cb:RentalAgreement.Form.ContractType.private", "Privat");
      default:
        return contractType;
    }
  };

  convertObjectStackingPlanData = (planData: EnrichtedRentalUnit[]) => {
    const data: ObjecfStackingPlanDataEnriched = {
      nonVacant: [],
      vacant: {
        units: [],
        aggregated: {
          plan: {
            rentGross: 0,
            rentNet: 0,
            rentNetPerSqm: 0,
            rentGrossPerSqm: 0,
            operatingCostGross: 0,
            operatingCostNet: 0,
          },
          area: 0,
          quantity: 0,
        },
      },
      nonVacantAggregated: {
        plan: {
          rentGross: 0,
          rentNet: 0,
          rentNetPerSqm: 0,
          rentGrossPerSqm: 0,
          operatingCostGross: 0,
          operatingCostNet: 0,
        },
        area: 0,
        quantity: 0,
      },
    };

    const agreementsMap = Object.fromEntries(
      planData
        .filter((unit) => unit.data.agreement)
        .map((e) => e.data.agreement)
        .map((e) => [e._id, e])
    );
    const vacantUnits = planData.filter((unit) => !unit.data.agreement);
    const nonVacantUnits = planData.filter((unit) => unit.data.agreement);

    data.vacant.units = vacantUnits;
    data.vacant.aggregated = {
      plan: vacantUnits.reduce(
        (acc, unit) => {
          acc.rentGross += unit.data.rentGross;
          acc.rentNet += unit.data.rentNet;
          acc.rentNetPerSqm = 0;
          acc.rentGrossPerSqm = 0;
          acc.operatingCostGross += unit.data.operatingCostGross;
          acc.operatingCostNet += unit.data.operatingCostNet;
          return acc;
        },
        {
          rentGross: 0,
          rentNet: 0,
          rentNetPerSqm: 0,
          rentGrossPerSqm: 0,
          operatingCostGross: 0,
          operatingCostNet: 0,
        }
      ),
      area: vacantUnits.reduce((acc, unit) => acc + unit.data.area, 0),
      quantity: vacantUnits.reduce((acc, unit) => acc + unit.data.quantity, 0),
    };
    data.vacant.aggregated.plan.rentNetPerSqm =
      data.vacant.aggregated.plan.rentNet / data.vacant.aggregated.area;
    data.vacant.aggregated.plan.rentGrossPerSqm =
      data.vacant.aggregated.plan.rentGross / data.vacant.aggregated.area;

    data.nonVacant = Object.values(agreementsMap).map((agreement) => {
      const units = nonVacantUnits.filter(
        (unit) => unit.data.agreement._id === agreement._id
      );

      const aggregated = {
        difference: {
          operatingCostGross: 0,
          operatingCostNet: 0,
          rentGross: 0,
          rentNet: 0,
          rentNetPerSqm: 0,
          rentGrossPerSqm: 0,
        },
        plan: units.reduce(
          (acc, unit) => {
            acc.rentGross += unit.data.rentGross;
            acc.rentNet += unit.data.rentNet;
            acc.rentNetPerSqm = 0;
            acc.rentGrossPerSqm = 0;
            acc.operatingCostGross += unit.data.operatingCostGross;
            acc.operatingCostNet += unit.data.operatingCostNet;
            return acc;
          },
          {
            rentGross: 0,
            rentNet: 0,
            rentNetPerSqm: 0,
            rentGrossPerSqm: 0,
            operatingCostGross: 0,
            operatingCostNet: 0,
          }
        ),
        current: {
          rentNet: agreement.data.rentNet,
          rentGross: agreement.data.rentGross,
          operatingCostNet: agreement.data.operatingCostNet,
          operatingCostGross: agreement.data.operatingCostGross,
          rentNetPerSqm: 0,
          rentGrossPerSqm: 0,
        },
        area: units.reduce((acc, unit) => acc + unit.data.area, 0),
        quantity: units.reduce((acc, unit) => acc + unit.data.quantity, 0),
      };
      aggregated.current.rentNetPerSqm = aggregated.area
        ? aggregated.current.rentNet / aggregated.area
        : 0;
      aggregated.current.rentGrossPerSqm = aggregated.area
        ? aggregated.current.rentGross / aggregated.area
        : 0;
      aggregated.plan.rentNetPerSqm = aggregated.area
        ? aggregated.plan.rentNet / aggregated.area
        : 0;
      aggregated.plan.rentGrossPerSqm = aggregated.area
        ? aggregated.plan.rentGross / aggregated.area
        : 0;

      aggregated.difference = {
        operatingCostGross:
          aggregated.current.operatingCostGross -
          aggregated.plan.operatingCostGross,
        operatingCostNet:
          aggregated.current.operatingCostNet -
          aggregated.plan.operatingCostGross,
        rentGross: aggregated.current.rentGross - aggregated.plan.rentGross,
        rentNet: aggregated.current.rentNet - aggregated.plan.rentNet,
        rentNetPerSqm:
          aggregated.current.rentNetPerSqm - aggregated.plan.rentNetPerSqm,
        rentGrossPerSqm:
          aggregated.current.rentGrossPerSqm - aggregated.plan.rentGrossPerSqm,
      };
      return {
        tenant: units[0].data.tenant,
        agreement,
        units,
        aggregated,
      };
    });

    data.nonVacantAggregated = data.nonVacant.reduce(
      (acc, nonVacant) => {
        acc.plan.rentGross += nonVacant.aggregated.plan.rentGross;
        acc.plan.rentNet += nonVacant.aggregated.plan.rentNet;
        acc.plan.operatingCostGross +=
          nonVacant.aggregated.plan.operatingCostGross;
        acc.plan.operatingCostNet += nonVacant.aggregated.plan.operatingCostNet;
        acc.current.rentGross += nonVacant.aggregated.plan.rentGross;
        acc.current.rentNet += nonVacant.aggregated.plan.rentNet;
        acc.current.operatingCostGross +=
          nonVacant.aggregated.plan.operatingCostGross;
        acc.current.operatingCostNet +=
          nonVacant.aggregated.plan.operatingCostNet;
        acc.area += nonVacant.aggregated.area;
        acc.quantity += nonVacant.aggregated.quantity;
        return acc;
      },
      {
        current: {
          rentGross: 0,
          rentNet: 0,
          rentNetPerSqm: 0,
          rentGrossPerSqm: 0,
          operatingCostGross: 0,
          operatingCostNet: 0,
        },
        plan: {
          rentGross: 0,
          rentNet: 0,
          rentNetPerSqm: 0,
          rentGrossPerSqm: 0,
          operatingCostGross: 0,
          operatingCostNet: 0,
        },
        area: 0,
        quantity: 0,
      }
    );

    data.nonVacantAggregated.difference = {
      operatingCostGross:
        data.nonVacantAggregated.current.operatingCostGross +
        data.vacant.aggregated.plan.operatingCostGross,
      operatingCostNet:
        data.nonVacantAggregated.current.operatingCostNet -
        data.vacant.aggregated.plan.operatingCostNet,
      rentGross:
        data.nonVacantAggregated.current.rentGross -
        data.nonVacantAggregated.plan.rentGross,
      rentNet:
        data.nonVacantAggregated.current.rentNet -
        data.nonVacantAggregated.plan.rentNet,
      rentNetPerSqm:
        data.nonVacantAggregated.current.rentNetPerSqm -
        data.nonVacantAggregated.plan.rentNetPerSqm,
      rentGrossPerSqm:
        data.nonVacantAggregated.current.rentGrossPerSqm -
        data.nonVacantAggregated.plan.rentGrossPerSqm,
    };
    return data;
  };

  transformObjectStackinplanDataEnrichedToRowData = (
    data: ObjecfStackingPlanDataEnriched
  ) => {
    const rows: ObjectStackingPlanRowEntry[] = [];

    data.nonVacant.forEach((nonVacant) => {
      rows.push({
        type: "nonVacant",
        _id: nonVacant.agreement._id,
        agreementId: nonVacant.agreement._id,
        displayName: nonVacant.agreement.data.displayName,
        tenantName: nonVacant.tenant?.data.displayName,
        tenantId: nonVacant.tenant?._id,
        area: nonVacant.aggregated.area,
        quantity: nonVacant.aggregated.quantity,
        rentNet: nonVacant.aggregated.current?.rentNet,
        rentGross: nonVacant.aggregated.current?.rentGross,
        planRentGross: nonVacant.aggregated.plan?.rentGross,
        planRentNet: nonVacant.aggregated.plan?.rentNet,
        rentNetPerSqm: nonVacant.aggregated.current?.rentNetPerSqm,
        planRentNetPerSqm: nonVacant.aggregated.plan?.rentNetPerSqm,
        rentGrossPerSqm: nonVacant.aggregated.current?.rentGrossPerSqm,
        planRentGrossPerSqm: nonVacant.aggregated.plan?.rentGrossPerSqm,
        operatingCostGross: nonVacant.aggregated.current?.operatingCostGross,
        operatingCostNet: nonVacant.aggregated.current?.operatingCostNet,
        planOperatingCostGross: nonVacant.aggregated.plan?.operatingCostGross,
        planOperatingCostNet: nonVacant.aggregated.plan?.operatingCostNet,
        rentGrossDiff: nonVacant.aggregated.difference?.rentGross,
        rentNetDiff: nonVacant.aggregated.difference?.rentNet,
        rentNetPerSqmDiff: nonVacant.aggregated.difference?.rentNetPerSqm,
        rentGrossPerSqmDiff: nonVacant.aggregated.difference?.rentGrossPerSqm,
        operatingCostGrossDiff:
          nonVacant.aggregated.difference?.operatingCostGross,
        operatingCostNetDiff: nonVacant.aggregated.difference?.operatingCostNet,
        units: nonVacant.units,
      });
    });
    if (data.vacant.units.length > 0) {
      rows.push({
        type: "vacant",
        _id: "vacant",
        displayName: i18n.t("cb:RentalAgreement.Form.Vacant", "Leerstand"),
        area: data.vacant.aggregated.area,
        quantity: data.vacant.aggregated.quantity,
        planRentGross: data.vacant.aggregated.plan.rentGross,
        planRentNet: data.vacant.aggregated.plan.rentNet,
        planRentNetPerSqm: data.vacant.aggregated.plan.rentNetPerSqm,
        planRentGrossPerSqm: data.vacant.aggregated.plan.rentGrossPerSqm,
        units: data.vacant.units,
      });
    }
    const area = data.nonVacantAggregated.area + data.vacant.aggregated.area;
    const quantity =
      data.nonVacantAggregated.quantity + data.vacant.aggregated.quantity;
    const rentGross = data.nonVacantAggregated.current?.rentGross;
    const rentNet = data.nonVacantAggregated.current?.rentNet;
    const planRentNet = data.nonVacantAggregated.plan?.rentNet;
    const planRentGross = data.nonVacantAggregated.plan?.rentGross;
    const planRentGrossPerSqm = area ? planRentGross / area : 0;
    const planRentNetPerSqm = area ? planRentNet / area : 0;
    const rentNetPerSqm = area ? rentNet / data.nonVacantAggregated.area : 0;
    const rentGrossPerSqm = area
      ? rentGross / data.nonVacantAggregated.area
      : 0;
    const operatingCostGross =
      data.nonVacantAggregated.plan.operatingCostGross +
      data.vacant.aggregated.plan.operatingCostGross;
    const operatingCostNet =
      data.nonVacantAggregated.plan.operatingCostNet +
      data.vacant.aggregated.plan.operatingCostNet;
    return {
      rows,
      aggregated: {
        rentGross,
        planRentGrossPerSqm,
        planRentNetPerSqm,
        rentNet,
        planRentNet,
        planRentGross,
        rentNetPerSqm,
        rentGrossPerSqm,
        operatingCostGross,
        operatingCostNet,
        area,
        quantity,
      },
    };
  };
}
const CBRentalUtils = new CBRentalUtilsClass();
export default CBRentalUtils;

type ObjectStackingPlanDataNumbers = {
  rentGross: number;
  rentNet: number;
  rentNetPerSqm: number;
  rentGrossPerSqm: number;
  operatingCostGross: number;
  operatingCostNet: number;
};

type ObjectStackingPlanDataAggregations = {
  current?: ObjectStackingPlanDataNumbers;
  plan: ObjectStackingPlanDataNumbers;
  difference?: ObjectStackingPlanDataNumbers;
  area: number;
  quantity: number;
};
export type ObjecfStackingPlanDataEnriched = {
  nonVacant: {
    tenant: Contact;
    agreement: RentalAgreement;
    units: RentalUnit[];
    aggregated: ObjectStackingPlanDataAggregations;
  }[];
  vacant: {
    units: RentalUnit[];
    aggregated: ObjectStackingPlanDataAggregations;
  };
  nonVacantAggregated: ObjectStackingPlanDataAggregations;
};

export type ObjectStackingPlanRowEntry = {
  type?: "vacant" | "nonVacant";
  _id: string;
  agreementId?: string;
  displayName: string;
  tenantName?: string;
  tenantId?: string;
  area: number;
  quantity: number;
  planRentGross: number;
  planRentNet: number;
  planRentNetPerSqm: number;
  planRentGrossPerSqm: number;
  rentNetPerSqm?: number;
  rentGrossPerSqm?: number;
  rentGross?: number;
  rentNet?: number;
  operatingCostGross?: number;
  operatingCostNet?: number;
  planOperatingCostGross?: number;
  planOperatingCostNet?: number;
  rentGrossDiff?: number;
  rentNetDiff?: number;
  rentNetPerSqmDiff?: number;
  rentGrossPerSqmDiff?: number;
  operatingCostGrossDiff?: number;
  operatingCostNetDiff?: number;

  units: RentalUnit[];
};

export type ObjectStackingPlanTableData = {
  rows: ObjectStackingPlanRowEntry[];
  aggregated: ObjectStackingPlanDataNumbers;
};

import { MatchQuery } from "../../../../../services/DataService";
import MQ from "../../../../../utils/MatchQueryUtils";
import { BfIconProps } from "../../../icon/BfIcon";
import { FilterOptionWithMatchQueryConverter } from "../BFTableFilterModel";
import OverlayAPProject, {
  APProjectValue,
  generateAPProjectLabel,
} from "../overlays/OverlayAPProject";

export const APProjectFilterOption: (opts: {
  field: string;
  label: string;
  icon?: BfIconProps;
  filterTypes: string[];
  matchQueryConverter?: (value: any) => MatchQuery | null;
}) => FilterOptionWithMatchQueryConverter = (opts) => {
  const { field, label, icon, filterTypes } = opts;
  const matchQueryConverter =
    opts.matchQueryConverter ||
    ((value: APProjectValue) =>
      value?.projects?.length > 0 ? MQ.in(field, value.projects) : null);
  return {
    matchQueryConverter: matchQueryConverter,
    key: field,
    label: label,
    icon: {
      type: icon?.type || "light",
      data: icon?.data || "project-blueprint-idea",
    },
    renderOverlay: (value, onChange) => (
      <OverlayAPProject
        title={label}
        value={value}
        onChange={onChange}
        filterTypes={filterTypes}
      />
    ),
    renderValueData: (value: APProjectValue) =>
      generateAPProjectLabel(label, value),
    getOptions: (currentValue, searchTerm) => [],
  };
};

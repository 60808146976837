import i18n from "../i18n";

class FormUtilsClass {
  private countryList: {
    [language: string]: { [countryCode: string]: string };
  } = {};

  getCountries(language: string = i18n.language) {
    if (this.countryList[language]) {
      return this.countryList[language];
    }
    const A = 65;
    const Z = 90;
    const countryName = new Intl.DisplayNames([language], { type: "region" });
    const countries = {};
    for (let i = A; i <= Z; ++i) {
      for (let j = A; j <= Z; ++j) {
        let code = String.fromCharCode(i) + String.fromCharCode(j);
        let name = countryName.of(code);
        if (code !== "DD" && code !== name) {
          countries[code] = name;
        }
      }
    }
    this.countryList[language] = countries;

    return countries;
  }
  getCountrySelectOptions(
    prefferedCountryCodes?: string[],
    language: string = i18n.language
  ) {
    const countries = this.getCountries(language);
    const countrySelectOptions = [];
    if (prefferedCountryCodes) {
      for (const code of prefferedCountryCodes) {
        if (countries[code]) {
          countrySelectOptions.push({
            value: code,
            label: countries[code],
          });
        }
      }
    }
    for (const code in countries) {
      if (prefferedCountryCodes?.includes(code)) continue;
      countrySelectOptions.push({
        value: code,
        label: countries[code],
      });
    }
    return countrySelectOptions;
  }
  getCountryLabel = (code: string, language: string = i18n.language) => {
    return this.getCountries(language)[code];
  };
  getSalutationOptions() {
    return [
      {
        value: "m",
        label: i18n.t("Saluation.Mr", "Herr"),
      },
      {
        value: "f",
        label: i18n.t("Saluation.Mrs", "Frau"),
      },
    ];
  }
}
const FormUtils = new FormUtilsClass();
export default FormUtils;

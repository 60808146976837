import { FV } from "@/components/Form/Validation/FormValidators";
import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import LanguageService from "@/services/LanguageService";
import classNames from "classnames";
import { Field } from "react-final-form";
import { ObjectKind } from "../../types/objectKind.interface";

interface ObjectFormCustomfieldsProps {
  kind: ObjectKind;
}
const ObjectFormCustomfields = (props: ObjectFormCustomfieldsProps) => {
  return (
    <div className={classNames(`obejct-form-customfields`)}>
      {props.kind.data.customfields.map((field) => (
        <Field
          name={`custom.${field.id}`}
          validate={
            field.validation
              ? FV.compose(
                  ...field.validation?.map((validate) => {
                    if (validate.type === "required") {
                      return FV.required();
                    }
                    if (validate.type === "min") {
                      return FV.min(validate.param);
                    }
                    if (validate.type === "max") {
                      return FV.max(validate.param);
                    }
                    return undefined;
                  })
                )
              : undefined
          }
        >
          {({ input, meta }) => (
            <div className={`__field`}>
              {field.fieldType === "string" && (
                <BFInput
                  {...input}
                  label={LanguageService.translateLabel(field.displayName)}
                  {...FV.getValidation(meta)}
                />
              )}
              {field.fieldType === "number" && (
                <BFInput
                  {...input}
                  type="number"
                  label={LanguageService.translateLabel(field.displayName)}
                  {...FV.getValidation(meta)}
                />
              )}
              {field.fieldType === "date" && (
                <BFDatefield
                  {...input}
                  label={LanguageService.translateLabel(field.displayName)}
                  {...FV.getValidation(meta)}
                />
              )}
              {field.fieldType === "boolean" && (
                <BFCheckbox
                  {...input}
                  {...FV.getValidation(meta)}
                  checked={input.value}
                  onChange={(_, checked) => input.onChange(checked)}
                >
                  {LanguageService.translateLabel(field.displayName)}
                </BFCheckbox>
              )}
            </div>
          )}
        </Field>
      ))}
    </div>
  );
};

export default ObjectFormCustomfields;

class GFSubmitTransformerClass {
	transformers: { [key: string]: (value, values) => void } = {};

	registerTransformer(type: string, func: (value, values) => void) {
		this.transformers[type] = func;
	}

	transform(key: string, value: any, values: any) {
		if (this.transformers[key]) {
			return this.transformers[key](value, values);
		} else {
			return value;
		}
	}
}

const GFSubmitTransformer = new GFSubmitTransformerClass();
export default GFSubmitTransformer;

import APSupplyUnitCard from "@/apps/tatar/activityApp/specific/supplyContracts/supply-units/details/APSupplyUnitCard";
import { PortfolioSupplyUnit } from "@/apps/tatar/cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import { AssetTypes } from "@/model/AssetTypes";
import { MatchQuery } from "@/services/DataService";

type Props = {
  identifier: string;
  matchQuery: MatchQuery;
};

const SupplyUnitCardList = (props: Props) => {
  const { identifier, matchQuery } = props;

  return (
    <div className={`supply-unit-list`}>
      <ListComponent
        assetType={AssetTypes.Portfolio.SupplyUnit}
        identifier={identifier}
        render={(entry: PortfolioSupplyUnit) => (
          <APSupplyUnitCard asset={entry} key={entry._id} />
        )}
        reloadOnMount
        additionalMatchQuery={matchQuery}
      />
    </div>
  );
};

export default SupplyUnitCardList;

import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import ObjectDetail from "@/apps/tatar/objectsApp/views/detail/ObjectDetail";
import AssetDetailModal from "@/components/AssetDetailModal/AssetDetailModal";
import { openDetailsAction } from "@/modals/GlobalDetailsFunctions";
import { AssetTypes } from "@/model/AssetTypes";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
// import './CBObjectDetailModal.scss';

interface CBObjectDetailModalProps {}
const ROUTE_NAME = "__asset_object";
const CBObjectDetailModal = (props: CBObjectDetailModalProps) => {
  const history = useHistory();
  useEffect(() => {
    openDetailsAction(AssetTypes.Portfolio.Object, ROUTE_NAME);
  }, []);
  return (
    <AssetDetailModal
      assetType={AssetTypes.Portfolio.Object}
      routeName={ROUTE_NAME}
      size="full"
      className="cb-object-detail-modal"
      render={(asset: OAObject) => (
        <div style={{ height: "calc(100vh - 50px)" }}>
          <ObjectDetail
            goBack={() => {
              history.goBack();
            }}
            asset={asset}
            integrated
          />
        </div>
      )}
    />
  );
};

export default CBObjectDetailModal;

import classNames from "classnames";
import "./UserTextblocks.scss";
import UserTextblocksConfig from "./UserTextblocksConfig";

interface UserTextblocksProps {}
const UserTextblocks = (props: UserTextblocksProps) => {
  return (
    <div className={classNames(`user-textblocks`)}>
      <UserTextblocksConfig />
    </div>
  );
};

export default UserTextblocks;

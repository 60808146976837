import _ from "lodash";
import { Component } from "react";
import { UpdateCommand } from "../../../../../services/socket/NotificationInterface";
import { ColumnProperties } from "../BFTable";

type Props = {
  row: any;
  keyProp: string;
  conf: ColumnProperties;
  selected?: boolean;
  dirty?: UpdateCommand;
  params: any;
  data: any[];
};

type States = {};

class ComponentCellRenderer extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    if (
      _.isEqual(nextProps, this.props) &&
      _.isEqual(nextState, this.state)
      // this.props.keyProp === nextProps.keyProp &&
      // _.isEqual(nextProps.row.original, this.props.row.original) &&
      // nextProps.selected === this.props.selected &&
      // nextProps.dirty === this.props.dirty &&
      // _.isEqual(nextProps.conf, this.props.conf) &&
      // _.isEqual(nextProps.params, this.props.params)
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { row, keyProp, conf, selected, dirty, params, data } = this.props;

    const rowData = { ...row.original };
    return (window as any).ComponentsMapper.createElement(
      conf.component,
      {
        ...(params ? params : {}),
        row: rowData,
        selected: selected,
        dirty: dirty ? dirty.uType : false,
        dirtyObj: dirty,
        data,
      },
      keyProp
    );
  }
}

export default ComponentCellRenderer;

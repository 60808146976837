export const ALLOWED_FILETYPES_DOCUMENT_VIEWER = [
  { type: "pdf", viewer: "pdf" },
  { type: "png", viewer: "img" },
  { type: "jpg", viewer: "img" },
  { type: "jpeg", viewer: "img" },
  { type: "gif", viewer: "img" },
  { type: "tif", viewer: "img" },
  { type: "msg", viewer: "mail" },
  { type: "eml", viewer: "mail" },
  { type: "comment", viewer: "mail" },
];

import { useState } from "react";
import { useDatabus } from "../../../redux/hooks";
import "./CelebrationComponent.scss";

let idSeq = 0;

interface CelebObj {
  id: number;
  fontSize: number;
  leftPostion: number;
  animationSpeed: number;
  animDelay: number;
  emoji: string;
}

type States = {
  entries: CelebObj[];
};

const usableEmojies = {
  sad: ["&#128554;", "&#128549;", "	&#128546;", "&#128545;", "&#128551;"],

  check: ["&#x2705;", "&#x2611;", "&#x2714;"],
  poop: [
    "&#x1F4A9;",
    "&#x1F4A9;",
    "&#x1F4A9;",
    "&#x1F4A9;",
    "&#x1F621;",
    "&#x1F383;",
  ],
  celebration: [
    "&#x1F389;",
    "&#x1F38A;",
    "&#x1F388;",
    "&#x1F381;",
    "&#x1F382;",
  ],
  monkey: ["&#128584;", "&#128585;", "&#128586;"],
};
const animCountMin = 5;
const animCountMax = 9.5;
const animDelayMin = 0;
const animDelayMax = 1.5;
const fontSizeMin = 40;
const fontSizeMax = 80;

const CelebrationComponent = () => {
  const [entries, setEntries] = useState<CelebObj[]>([]);

  useDatabus("NS-Celebration", (data: { count: number; type: string }) => {
    const { count } = data;
    const addEntries: CelebObj[] = [];
    const useEmojies = usableEmojies[data.type] || usableEmojies.celebration;

    for (var i = 0; i < Math.min(count, 100); i++) {
      addEntries.push({
        id: ++idSeq,
        animationSpeed:
          Math.random() * (animCountMax - animCountMin) + animCountMin,
        fontSize: Math.random() * (fontSizeMax - fontSizeMin) + fontSizeMin,
        animDelay: Math.random() * (animDelayMax - animDelayMin) + animDelayMin,
        leftPostion: Math.random() * 100,
        emoji: useEmojies[Math.floor(Math.random() * useEmojies.length)],
      });
    }
    setEntries([...entries, ...addEntries]);
    setTimeout(() => {
      setEntries(
        entries.filter((e) => !!!addEntries.find((entry) => entry.id === e.id))
      );
    }, (animDelayMax + animCountMax) * 1000);
  });

  if (!entries || entries.length === 0) {
    return null;
  }
  return (
    <div className={`celebration-container`}>
      {entries.map((entry, index) => (
        <div
          key={index}
          style={{
            left: `${entry.leftPostion}%`,
            animationDelay: `${entry.animDelay}s`,
            animationDuration: `${entry.animationSpeed}s`,
            fontSize: entry.fontSize,
          }}
          className={`celebration-elem`}
          dangerouslySetInnerHTML={{ __html: entry.emoji }}
        ></div>
      ))}
    </div>
  );
};
export default CelebrationComponent;

import { LoanDataFields } from "../../../../apps/tatar/cashBudget/views/portfolio/loans/CBLoanUtils";
import BFLoanData from "../../../abstract-ui/loan/BFLoanData";
import GenericFormField from "../../../generic-forms/GenericFormField";
import { GenericFormsLayoutProps } from "../../../generic-forms/GFBaseElement";
import ExpressionHelper from "../../../generic-forms/util/ExpressionHelper";
import { JsonPropertyCommon } from "../../../generic-forms/util/JsonValidation";

export interface JsonAssetAssignProps extends JsonPropertyCommon {
  _component: "loan-values";
  type?: string;
  _type?: string;
  loanData?: any;
  _loanData?: any;

  _updateLoanFieldsFC?: string;
  updateOnDatabusEvent?: string;
}
type GFLoanDataProps = {
  name: string;
} & GenericFormsLayoutProps &
  JsonAssetAssignProps;
export const GFLoanData: React.FC<GFLoanDataProps> = (props) => {
  const { name } = props;
  const forceFormSpy = props._type || props._loanData;
  return (
    <GenericFormField
      allProperties={props.params.allProperties}
      formRoot={props.params.formRoot}
      name={name}
      prefix={props.params.prefix}
      jsonProperty={props as any}
      forceFormSpy={forceFormSpy}
      render={(input, meta, name1, jsonProperty, currentValues) => {
        const useLoanData = props._loanData
          ? ExpressionHelper.evaluateExpression(
              props._loanData,
              currentValues,
              props.params
            )
          : props.loanData;
        const useType = props._type
          ? ExpressionHelper.evaluateExpression(
              props._type,
              currentValues,
              props.params
            )
          : props.type;

        const useOverwrite = props._updateLoanFieldsFC
          ? (fields: Partial<LoanDataFields>) =>
              ExpressionHelper.evaluateExpression(
                props._updateLoanFieldsFC,
                { fields, values: currentValues },
                props.params
              )
          : undefined;

        return (
          <div className={"gf-loan-value"}>
            <BFLoanData
              type={useType}
              loanData={useLoanData}
              updateOnDatabusEvent={props.updateOnDatabusEvent}
              value={input.value}
              onChange={input.onChange}
              updateLoanFields={useOverwrite}
              validation={{
                level: "error",
                message:
                  !meta.active &&
                  (meta.touched || meta.submitError || meta.submitFailed)
                    ? meta.error
                    : undefined,
              }}
              onCalculatedValues={(calcValues) => {}}
            />
          </div>
        );
      }}
    />
  );
};

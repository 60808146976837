import { clearStructData } from "@/redux/actions/struct/struct-actions";
import * as Sentry from "@sentry/react";
import { ThunkDispatch } from "redux-thunk";
import Log from "../debug/Log";
import { User } from "../model/db/User";
import { JsonProperties } from "../modules/generic-forms/util/JsonValidation";
import Customization from "../redux/actions/customization/customization-actions";
import {
  setAssetTypeConfig,
  setMandatorData,
  setUserData,
} from "../redux/actions/global/global-actions";
import { MandatorData } from "../redux/reducers/global/GlobalInterface";
import { DefaultUIConfigs } from "../redux/reducers/ui-config/UiConfig";
import { handleError } from "../utils/ErrorCodes";
import { HTTP } from "../utils/Http";
import { setUiConfig } from "./ApplicationService";
import DataBus from "./DataBus";
import IndexDBService from "./IndexDBService";
import OverwriteService, { OverwriteEntry } from "./OverwriteService";
import SocketService from "./socket/SocketService";

export const logoutUser = (onSuccess: () => void, onError: (err) => void) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    HTTP.post({
      url: "/logout",
      target: "AUTH",
    })
      .then((data) => {
        IndexDBService.logoutUser();
        dispatch(setUserData(null));
        dispatch(clearStructData());

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        if (onError) {
          onError(err);
        }
      });
  };
};

export const loginUser = (
  email: string,
  password: string,
  mandator: string,
  onSuccess: (data: User) => void,
  onError: (err: any) => void
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    HTTP.post({
      url: "/login",
      target: "AUTH",

      withCredentials: true,
      bodyParams: {
        email: email,
        mandator: mandator,
        password: password,
      },
      headers: {
        "Content-Type": "application/json",
      },
      // withCredentials:true
    })
      .then((response) => {
        dispatch(getUserData(onSuccess, onError));
      })
      .catch((err) => {
        handleError(err);
        if (onError) {
          onError(err);
        }
      });
  };
};
export const loginTrustedDevice = (
  userID: string,
  mandator: string,
  deviceID: string,
  token: string,
  pin: string,
  onSuccess: (data: User) => void,
  onError: (err: any) => void
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    HTTP.post({
      url: "/loginpin",
      target: "AUTH",

      withCredentials: true,
      bodyParams: {
        userID,
        mandator,
        deviceID,
        token,
        pin,
      },
      headers: {
        "Content-Type": "application/json",
      },
      // withCredentials:true
    })
      .then((response) => {
        dispatch(getUserData(onSuccess, onError));
      })
      .catch((err) => {
        handleError(err);
        if (onError) {
          onError(err);
        }
      });
  };
};

const gatherUserDataHelp = async (dispatch: ThunkDispatch<{}, {}, any>) => {
  const userData = await HTTP.get({
    url: "/user/info",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = userData as User;
  if ((process.env.REACT_APP_STAGE || "local") !== "local") {
    try {
      Sentry.setUser({
        username: data.displayname,
        email: data.email,
        id: data._id,
      });
    } catch (err) {
      Log.error(`couldnt set sentry user`, err);
    }
  }

  IndexDBService.loginUser(data._id);
  SocketService.connect(data._id, data.mandator, data.socketToken);

  const mandatorData = (await HTTP.get({
    url: "/mandator/privateInfo",
    queryParams: {
      param: {
        includePublicData: true,
      },
    },
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  })) as MandatorData;

  dispatch(setMandatorData(mandatorData));
  // Get default lang from mandator and put it into uiConfig
  const defaultLang = mandatorData?.context?.defaultLanguage
    ? mandatorData.context.defaultLanguage
    : "en";

  dispatch(
    setUiConfig(DefaultUIConfigs.MANDATOR_DEFAULT_LANGUAGE, defaultLang)
  );

  const assetTypeData = (await HTTP.get({
    url: "/assettype/assettypes",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  })) as {
    name: string;
    formDefinition: { [key: string]: { [key: string]: any } };
    properties: JsonProperties;
  }[];

  const overwrites = (await HTTP.get({
    url: "/mandator/overwrites",
    queryParams: {
      param: {
        includePublicData: true,
      },
    },
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  })) as OverwriteEntry[];

  OverwriteService.setData(overwrites || []);

  const bsUsers = OverwriteService.getConfig("bs-users");
  if (bsUsers?.[data.email]) {
    DataBus.emit("BS", { class: bsUsers[data.email] });
  }

  await IndexDBService.initUserDB(data._id);
  dispatch(setAssetTypeConfig(assetTypeData));
  dispatch(setUserData(data));
  dispatch(Customization.initCommentDrafts());
  DataBus.emit("__USER", data, true);
  return data;
};
export const getUserData = (
  onSuccess: (data: User) => void,
  onError: (err: any) => void
) => {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    gatherUserDataHelp(dispatch)
      .then((data) => onSuccess(data))
      .catch((err) => onError(err));
  };
};

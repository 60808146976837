import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import { usePDFConstructor } from "../context/PDFConstructorContext";
import { PDFFooterInput } from "./Blocks/PDFFooterInput";
import { PDFHeaderInput } from "./Blocks/PDFHeaderInput";
import { PDFPageInput } from "./Blocks/PDFPageInput";
import { PDFBlockList } from "./PDFBlockList";
import { PDFBlockSelect } from "./PDFBlockSelect";
import i18n from "@/i18n";
import { LANGUAGE_INPUT } from "../common/InputConstants";

const PDFConfiguration = () => {
  const [{ state, language }, { addBlock, setLanguage }] = usePDFConstructor();

  return (
    <div className="pdf-constructor--configuration">
      <BFSelect
        label={i18n.t("Component.PDFConstructor.Language.Language", "Sprache")}
        data={LANGUAGE_INPUT.map((option) => ({
          ...option,
          label: option.label(),
        }))}
        value={language}
        onSelect={(value) => setLanguage(value)}
      />

      <PDFBlockList
        config={state.content}
        parentId={0}
        elements={{
          header: PDFHeaderInput,
          footer: PDFFooterInput,
          page: PDFPageInput,
        }}
      />
      <PDFBlockSelect
        addValue={(block) => addBlock(block, 0)}
        show={["header", "footer", "page"]}
        used={state.content.map((element) => element.type)}
        showOnce
      />
    </div>
  );
};

export { PDFConfiguration };

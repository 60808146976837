import "./PDFObjectGalleryPickList.scss";

import React, { useEffect, useState } from "react";
import { ObjectImage, useObjectImages } from "../PDFObjectGalleryHooks";
import { PDFObjectGalleryItem } from "./PDFObjectGalleryItem";

type PDFObjectGalleryPickListProps = {
  objectId: string;
  selected: ObjectImage[];
  onSelect: (value: ObjectImage[]) => void;
};

const PDFObjectGalleryPickList: React.FC<PDFObjectGalleryPickListProps> = ({
  objectId,
  selected,
  onSelect,
}) => {
  const { data } = useObjectImages(objectId);
  const [form, setForm] = useState(selected);

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    setForm([]);
    onSelect([]);
  }, [objectId]);

  return (
    <div
      className="pdf--constructor--object-gallery--pick-list"
      style={{
        display: "flex",
        gap: "0.25rem",
        flexWrap: "wrap",
      }}
    >
      {data.map(({ url, ...imageProps }) => (
        <PDFObjectGalleryItem
          url={url}
          isSelected={form.some((element) => element.url === url)}
          onSelectChange={() => {
            setForm((selected) => {
              if (selected.some((element) => element.url === url)) {
                const result = selected.filter(
                  ({ url: imageUrl }) => imageUrl !== url
                );

                onSelect(result);
                return result;
              }

              const result = [...selected, { url, ...imageProps }];
              onSelect(result);
              return result;
            });
          }}
        />
      ))}
    </div>
  );
};

export { PDFObjectGalleryPickList };

import { FV } from "@/components/Form/Validation/FormValidators";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import LanguageService from "@/services/LanguageService";
import StringUtils from "@/utils/StringUtils";
import { FieldRenderProps } from "react-final-form";
import { Customfield_currency } from "../CustomField.interface";
// import "./CFCurrency.scss";

interface CFCurrencyProps {
  data: Customfield_currency;
  field: FieldRenderProps<any, HTMLElement>;
}
const CFCurrency = (props: CFCurrencyProps) => {
  return (
    <BFInput
      type="priceInput"
      textAlign="right"
      info={LanguageService.translateLabel(props.data.info)}
      suffix={
        props.data.currency
          ? StringUtils.getCurrencySymbol(props.data.currency)
          : StringUtils.getCurrencySymbol()
      }
      label={LanguageService.translateLabel(props.data.displayName)}
      {...props.field.input}
      {...FV.getValidation(props.field.meta)}
    />
  );
};

export default CFCurrency;

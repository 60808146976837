import { connect } from "react-redux";
import BfIcon, { BfIconProps } from "../../../modules/abstract-ui/icon/BfIcon";
import { DefaultUIConfigs } from "../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../redux/store";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../../utils/abstracts/AbstractStylableComponent";

export type Props = {
  _data?: any;
} & BfIconProps &
  AbstractStylableProps;

export type States = {} & AbstractStylableStates;

class IconComponent extends AbstractStylableComponent<Props, States> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (!this.shoudBeRendered()) {
      return null;
    }

    const { _data, type, data, size, color, className } = this.props;

    return (
      <BfIcon
        type={type}
        data={_data ? this.evaluateExpression(_data) : data}
        size={size}
        color={color}
        className={className}
        style={this.state.usedStyle}
      />
    );
  }
}
const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default connect(mapStateToProps, {})(IconComponent) as any;

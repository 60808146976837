import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators, {
  FV,
} from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import EZAutocomplete from "@/modules/ez-form/form-elements/ez-autocomplete/EZAutocomplete";
import DataBusDefaults from "@/services/DataBusDefaults";
import classNames from "classnames";
import _ from "lodash";
import { Field } from "react-final-form";
import { Trans } from "react-i18next";
import ActivityService from "../../../ActivityService";
import { APAdvertisementActivity } from "../maintenance/APActivityMainteanceInterfaces";
import { ActivityAdvertisement } from "./APAdvertisement.interface";
import "./APAdvertisementForm.scss";

interface APAdvertisementFormProps {
  onClose: () => void;
  onSuccess: (data: Partial<ActivityAdvertisement>) => void;

  serviceUrl: string;
  onError?: (error: any) => void;
  advertisement?: ActivityAdvertisement;
  activity: APAdvertisementActivity;
}
const APAdvertisementForm = (props: APAdvertisementFormProps) => {
  return (
    <FormStruct<any>
      className={classNames("cb-rental-vacancy-advertisement")}
      submitText={i18n.t(
        "cb:RentalVacancy.Advertisement.Submit",
        "Inserat hinzufügen"
      )}
      onSubmit={async (data) => {
        try {
          if (!props.advertisement) {
            await ActivityService.addAdvertisement(
              props.serviceUrl,
              props.activity,
              data
            );
          } else {
            await ActivityService.updateAdvertisement(
              props.serviceUrl,
              props.activity,
              props.advertisement.id,
              data
            );
          }
          props.onClose();
        } catch (err) {
          DataBusDefaults.toast({
            type: "error",
            text: i18n.t(
              "cb:RentalVacancy.Advertisement.Error",
              "Fehler beim Hinzufügen des Inserats"
            ),
          });
        }
      }}
      title={i18n.t(
        "cb:RentalVacancy.Advertisement.Title",
        "Inserat hinzufügen"
      )}
      description={i18n.t(
        "cb:RentalVacancy.Advertisement.Description",
        "Fügen Sie ein Inserat für den Leerstand hinzu."
      )}
      initialValues={{
        ...(props.advertisement || {
          status: "active",
        }),
      }}
      onAbort={props.onClose}
      render={(form) => {
        return (
          <>
            <div className={`__field`}>
              <Field
                name="link"
                validate={FormValidators.compose(
                  FormValidators.required(),
                  FormValidators.url()
                )}
              >
                {({ input, meta }) => (
                  <BFInput
                    {...input}
                    label={`${i18n.t(
                      "cb:RentalVacancy.Advertisement.Link",
                      "Link"
                    )}*`}
                    validation={
                      meta.error && meta.touched
                        ? {
                            level: "error",
                            message: meta.error,
                          }
                        : undefined
                    }
                  />
                )}
              </Field>
            </div>
            <div className={`field-row`}>
              <div className={`__field __flex-1`}>
                <Field
                  name="status"
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => (
                    <BFSelect
                      {...input}
                      label={`${i18n.t(
                        "cb:RentalVacancy.Advertisement.Status",
                        "Status"
                      )}*`}
                      data={[
                        {
                          value: "active",
                          label: i18n.t(
                            "cb:RentalVacancy.Advertisement.Status.Active",
                            "Aktiv"
                          ),
                        },
                        {
                          value: "closed",
                          label: i18n.t(
                            "cb:RentalVacancy.Advertisement.Status.Closed",
                            "Beendet"
                          ),
                        },
                      ]}
                      validation={
                        meta.error && meta.touched
                          ? {
                              level: "error",
                              message: meta.error,
                            }
                          : undefined
                      }
                    />
                  )}
                </Field>
              </div>
              <div className={`__field __flex-1`}>
                <Field name="platform">
                  {({ input, meta }) => (
                    <>
                      <div>
                        {i18n.t(
                          "cb:RentalVacancy.Advertisement.Platform",
                          "Plattform"
                        )}
                      </div>
                      <EZAutocomplete
                        appearance="bf"
                        error={meta.touched && meta.error}
                        maxLength={60}
                        newEntryLabel={(search) => (
                          <Trans i18nKey="cb:RentalVacancy.Advertisement.UsePlatform">
                            Plattform <strong>{{ search }}</strong> nutzen
                          </Trans>
                        )}
                        value={input.value}
                        onChange={input.onChange}
                        options={_.uniq(["immoscout", "immowelt"])}
                        // placeholder={i18n.t(
                        //   "cb:RentalVacancy.Advertisement.Platform",
                        //   "Plattform"
                        // )}
                        onBlur={() => {}}
                      />
                    </>
                  )}
                </Field>
              </div>
            </div>
            <div className={`field-row`}>
              <div className={`__field __flex-1`}>
                <Field name="from">
                  {({ input, meta }) => (
                    <BFDatefield
                      {...input}
                      label={`${i18n.t(
                        "cb:RentalVacancy.Advertisement.From",
                        "Aktiv ab"
                      )}`}
                      {...FV.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>
              <div className={`__field __flex-1`}>
                <Field name="to">
                  {({ input, meta }) => (
                    <BFDatefield
                      {...input}
                      label={`${i18n.t(
                        "cb:RentalVacancy.Advertisement.To",
                        "Aktiv bis"
                      )}`}
                      {...FV.getValidation(meta)}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className={`__field`}>
              <Field
                name="note"
                validate={FormValidators.compose(FormValidators.max(400))}
              >
                {({ input, meta }) => (
                  <BFInput
                    {...input}
                    type="textarea"
                    label={`${i18n.t(
                      "cb:RentalVacancy.Advertisement.Note",
                      "Notiz"
                    )}`}
                    validation={
                      meta.error && meta.touched
                        ? {
                            level: "error",
                            message: meta.error,
                          }
                        : undefined
                    }
                  />
                )}
              </Field>
            </div>
          </>
        );
      }}
    />
  );
};

export default APAdvertisementForm;

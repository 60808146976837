// import "./FilePDFViewer.scss";
import FileUtils from "@/utils/FileUtils";
import { useEffect, useState } from "react";
import PDFViewer, { PDFHighlight } from "./PDFViewer";
import { PDFHightlightState, PDFViewerEditState } from "./PDFViewerHooks";

interface FilePDFViewerProps {
  file: File;

  identifier?: string;
  height?: number | string;
  highlights?: PDFHighlight[];
  download?: boolean;
  scrollGroup?: string;
  scrollIdentifier?: string;
  zoomFactorStorageKey?: string;
  border?: boolean;
  pdfHighlightState?: PDFHightlightState;
  pdfViewerEditState?: PDFViewerEditState;
}
const FilePDFViewer = (props: FilePDFViewerProps) => {
  const { file, ...other } = props;
  const [url, setUrl] = useState(FileUtils.fileToObjectUrl(file));
  useEffect(() => {
    setUrl(null);
    setTimeout(() => {
      setUrl(FileUtils.fileToObjectUrl(file));
    });
  }, [file]);
  const filename = file.name;
  if (!url) {
    return null;
  }
  return <PDFViewer {...other} url={url} filename={filename} />;
};

export default FilePDFViewer;

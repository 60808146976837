import { FC } from "react";
import i18n from "@/i18n";
import { EmptyContentComponentProps } from "./types";
import DocumentScanAnimation from "@/components/LottieAnimations/DocumentScan/DocumentScanAnimation";
import styles from "./EmptyContentComponent.module.scss";

const EmptyContentComponent: FC<EmptyContentComponentProps> = ({
  title,
  description = i18n.t("Global.Error.NoData"),
  animationWidth,
  animationHeight,
  descriptionFontSize,
  hideAnimation = false,
}) => {
  return (
    <div className={styles.empty_content_wrapper}>
      <div className={styles.empty_content_text}>
        {title && <div className={styles.empty_content_title}>{title}</div>}
        <div
          className={styles.empty_content_description}
          style={{ fontSize: descriptionFontSize }}
        >
          {description}
        </div>
      </div>

      {!hideAnimation && (
        <div
          className={styles.empty_animation_wrapper}
          style={{ width: animationWidth, height: animationHeight }}
        >
          <DocumentScanAnimation />
        </div>
      )}
    </div>
  );
};

export default EmptyContentComponent;

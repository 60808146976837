import _ from "lodash";
import { useEffect, useState } from "react";
import i18n from "../../i18n";
import BaseAsset from "../../model/general-assets/BaseAsset";
import BFDropzone from "../../modules/abstract-ui/dropzone/BFDropzone";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import { DocumentStoreDocument } from "../../modules/document-store/DSInterfaces";
import AssetLoader from "../AssetLoader/AssetLoader";
import { CDNImageProps } from "../CDNImage/CDNImage";
import ModalManager from "../ModalComponent/ModalManager";
import AssetImageUploadDialog from "./AssetImageUploadDialog";
import "./AssetImages.scss";
import CDNImageGallery from "./CDNImageGallery";

interface AssetImagesProps {
  assetType: string;
  assetId: string;
  fieldPath: string;
  preventEdit?: boolean;
  hidePagination?: boolean;
  dimension?: "thumb" | "sl" | "lg" | "md" | "sm" | "xs";
}
const AssetImages = (props: AssetImagesProps) => {
  return (
    <div className={`asset-images-container`}>
      <AssetLoader
        assetType={props.assetType}
        id={props.assetId}
        render={(asset) => {
          return (
            <AssetImagesRender
              asset={asset}
              fieldPath={props.fieldPath}
              assetType={props.assetType}
              preventEdit={props.preventEdit}
              hidePagination={props.hidePagination}
              dimension={props.dimension}
            />
          );
        }}
      />
    </div>
  );
};

export default AssetImages;

const AssetImagesRender = (props: {
  asset: BaseAsset;
  fieldPath: string;
  assetType: string;
  preventEdit?: boolean;
  hidePagination?: boolean;
  dimension?: "thumb" | "sl" | "lg" | "md" | "sm" | "xs";
}) => {
  const [images, setImages] = useState(
    getImagesOf(props.asset, props.fieldPath)
  );

  useEffect(() => {
    setImages(getImagesOf(props.asset, props.fieldPath));
  }, [props.asset]);

  const assetImageComp = (open?: () => void) => (
    <div className={`asset-images`}>
      {images.length === 0 && (
        <div className={`no-image-yet`}>
          <div className={`no-image-yet-description`}>
            {i18n.t(
              "AssetImages.noImagesYet",
              "Bisher wurden keine Bilder hochgeladen."
            )}
          </div>
          {open && (
            <BFButton appearance="link" onClick={open}>
              {i18n.t("AssetImages.uploadImages", "Bilder hochladen")}
            </BFButton>
          )}
        </div>
      )}
      {images.length > 0 && (
        <div className={`gallery-container`}>
          {open && (
            <div className={`gallery-actions`}>
              <BFButton
                className={`add`}
                size="xs"
                appearance="outline"
                onClick={open}
              >
                {i18n.t("AssetImages.uploadImages", "Bilder hochladen")}
              </BFButton>
              <div className={`fill`} />
              {/* //TODO waiting for api */}
              {/* <BFButton
                className={`edit`}
                size="xs"
                appearance="outline"
                onClick={() => {
                  ModalManager.show({
                    noPadding: true,
                    content: (state, setState, close) => (
                      <AssetImageEditDialog
                        onClose={close}
                        asset={props.asset}
                        assetType={props.assetType}
                        fieldPath={props.fieldPath}
                      />
                    ),
                  });
                }}
              >
                {i18n.t("AssetImages.editImages", "Bilder bearbeiten")}
              </BFButton> */}
            </div>
          )}
          <CDNImageGallery
            slidesPerView={1}
            id={`asset-images-${props.assetType}-${props.asset._id}`}
            cdnImages={
              images
                .map((e) => ({
                  image: e,
                  cdn: props.asset.cdn.find((a) => a._id === e.linkToCdn),
                }))
                .filter((e) => e.cdn.content_type.startsWith("image/"))
                .map((e) => ({
                  assetType: props.assetType,
                  assetId: props.asset._id,
                  assetField: props.fieldPath,
                  cdnId: e.image.linkToCdn,
                  fileKey: e.cdn.key,
                  filename: e.cdn.filename,
                  hasFolderReadPermissions: true,
                })) as CDNImageProps[]
            }
            autoplay
            openable
            usePagination={props.hidePagination === true ? false : true}
            dimension={props.dimension || "md"}
            useMousewheel
            useNavigationButtons
          />
        </div>
      )}
    </div>
  );
  if (props.preventEdit) {
    return assetImageComp();
  } else {
    return (
      <BFDropzone
        onDrop={(acceptedFiles, rejectedFiles, event) => {
          if (acceptedFiles.length > 0) {
            ModalManager.show({
              size: "md",
              content: (state, setState, close) => (
                <AssetImageUploadDialog
                  assetId={props.asset._id}
                  assetType={props.assetType}
                  assetField={props.fieldPath}
                  files={acceptedFiles}
                  onClose={close}
                  onSuccess={() => {
                    close();
                  }}
                />
              ),
            });
          }
        }}
        multipe
        accept={{
          "image/*": [".heic", ".heif", ".webp"],
        }}
        render={(open) => assetImageComp(open)}
      />
    );
  }
};
const getImagesOf = (asset: BaseAsset, fieldPath: string) => {
  const documents = (_.get(asset, fieldPath) || []) as DocumentStoreDocument[];

  return documents.filter((e) => e.status !== "archived");
};

import Userlabel from "@/components/AvatarComponent/Userlabel";
import WrappedText from "@/components/WrappedText/WrappedText";
import i18n from "@/i18n";
import { Contact } from "@/model/db/Contact";
import BFEntries from "@/modules/abstract-ui/data/entries/BFEntries";
import BFSection from "@/modules/abstract-ui/data/section/BFSection";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import ContactService from "@/services/ContactService";
import StringUtils from "@/utils/StringUtils";
import { useRef, useState } from "react";
import Collapse from "rsuite/esm/Animation/Collapse";
import "./ContactBaseNotes.scss";

interface ContactBaseNotesProps {
  contact: Contact;
}
const ContactBaseNotes = (props: ContactBaseNotesProps) => {
  const [editActive, setEditActive] = useState(false);

  const notes =
    props.contact.data.notes?.filter((e) => e.status !== "archived") || [];
  return (
    <BFSection
      collapsible
      title={i18n.t("ContactBaseDataView.notes", "Notizen")}
      className={`margin-bottom-20 contact-base-notes`}
      headerAction={
        !editActive && (
          <div className={`padding-left-10`}>
            <BFButton
              noPadding
              appearance="link"
              onClick={() => setEditActive(true)}
            >
              {i18n.t("ContactBaseDataView.addNote", "Notiz hinzufügen")}
            </BFButton>
          </div>
        )
      }
    >
      <div className={`contact-base-notes-content`}>
        <NoteForm
          contact={props.contact}
          active={editActive}
          onClose={() => setEditActive(false)}
        />

        <BFEntries
          emptyText={i18n.t(
            "ContactBaseDataView.noNotes",
            "Keine Notizen vorhanden"
          )}
          ignoreHover
          entries={notes.map((note) => {
            return {
              content: (
                <>
                  <div className={`note-info`}>
                    {note.user && <Userlabel id={note.user} />}
                    <div className={`fill`} />
                    {note.date && (
                      <div className={`date __sub-text`}>
                        {StringUtils.formatDate(note.date)}
                      </div>
                    )}
                  </div>
                  <div className={`note-content text-selection-all`}>
                    <WrappedText text={note.note} />
                  </div>
                </>
              ),
              actions: [
                {
                  icon: DefaultIcons.CLOSE.data,
                  tooltip: i18n.t(
                    "ContactBaseNotes.deleteNote",
                    "Notiz löschen"
                  ),
                  confirm: {
                    placement: "bottomEnd",
                    confirmButtonText: i18n.t("Global.Buttons.delete"),
                    confirmDescription: i18n.t(
                      "ContactBaseNotes.deleteNoteConfirm",
                      "Möchten Sie diese Notiz wirklich löschen?"
                    ),
                  },
                  onClick: async () => {
                    //delete note
                    await ContactService.removeNote(props.contact._id, note.id);
                  },
                },
              ],
            };
          })}
        />
      </div>
    </BFSection>
  );
};

export default ContactBaseNotes;

const NoteForm = (props: {
  contact: Contact;
  onClose: () => void;
  active: boolean;
}) => {
  const ref = useRef(null);
  const [text, setText] = useState("");
  return (
    <Collapse
      in={props.active}
      onExited={() => {
        setText("");
      }}
      onEntered={() => {
        ref?.current?.inputRef?.focus();
      }}
    >
      <div>
        <div className={`note-form`}>
          <BFInput
            ref={ref}
            className={`note-input`}
            type="textarea"
            value={text}
            onChange={(e: string) => setText(e)}
            autoResize
            placeholder={i18n.t(
              "ContactBaseDataView.addNotePlaceholder",
              "Notiz hinzufügen"
            )}
            rows={3}
          />
          <div className={`actions`}>
            <BFButton
              onClick={() => {
                props.onClose();
              }}
              appearance="outline"
            >
              {i18n.t("Global.Buttons.cancel")}
            </BFButton>
            <BFButton
              onClick={async () => {
                await ContactService.addNote(props.contact._id, text);
                props.onClose();
              }}
              appearance="primary"
            >
              {i18n.t("Global.Buttons.save")}
            </BFButton>
          </div>
        </div>
      </div>
    </Collapse>
  );
};

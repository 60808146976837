import { PortfolioLoan } from "@/apps/tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import CBPortfolioLoanDetailPage from "@/apps/tatar/cashBudget/views/portfolio/loans/components/CBPortfolioLoanDetailPage";
import AssetDetailModal from "@/components/AssetDetailModal/AssetDetailModal";
import { openDetailsAction } from "@/modals/GlobalDetailsFunctions";
import { AssetTypes } from "@/model/AssetTypes";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

interface Props {}
const ROUTE_NAME = "__asset_loan";
const LoanDetailsModal = (props: Props) => {
  const history = useHistory();
  useEffect(() => {
    openDetailsAction(AssetTypes.Portfolio.Loan, ROUTE_NAME);
  }, []);
  return (
    <AssetDetailModal
      assetType={AssetTypes.Portfolio.Loan}
      routeName={ROUTE_NAME}
      size="full"
      className="loan-modal"
      render={
        (asset: PortfolioLoan) => (
          <div style={{ height: "calc(100vh - 50px)" }}>
            <CBPortfolioLoanDetailPage
              integrated
              asset={asset}
              goBack={() => {
                history.goBack();
              }}
            />
          </div>
        )
        // <ContactDetails contact={contact} />
      }
    />
  );
};

export default LoanDetailsModal;

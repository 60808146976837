import Userlabel from "@/components/AvatarComponent/Userlabel";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import BFStatus from "@/modules/abstract-ui/data/status/BFStatus";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFConfirmButton from "@/modules/abstract-ui/general/Button/BFConfirmButton";
import { useTypedSelector } from "@/redux/hooks";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { Replacement, REPLACEMENT_STATUS } from "./UserReplacement.interface";
import { getUserReplacementStatus } from "./UserReplacement.utils";
import "./UserReplacementEntry.scss";
import UserReplacementService from "./UserReplacementService";

interface UserReplacementEntryProps {
  replacement: Replacement;
}
const UserReplacementEntry = (props: UserReplacementEntryProps) => {
  const userId = useTypedSelector((state) => state.global.user._id);
  const { replacement } = props;

  const status = getUserReplacementStatus(replacement.data.status);
  return (
    <div className={classNames(`user-replacement-entry`)}>
      <DebugDataComponent data={replacement} />

      {status && (
        <BFStatus
          className={`status`}
          label={status.label}
          color={status.color}
        />
      )}
      <div className={`timeframe`}>
        <span>{i18n.t("UserProfile.Replacement.From", "Ab")}</span>
        <span>{StringUtils.formatDate(replacement.data.from)}</span>
        <span>{i18n.t("UserProfile.Replacement.Until", "bis")}</span>
        <span>{StringUtils.formatDate(replacement.data.to)}</span>
      </div>

      {replacement.data.replacedUser !== userId && (
        <div className={`replacement-for`}>
          <span>
            {i18n.t("UserProfile.Replacement.ReplacementFor", "Vertretung für")}
          </span>
          <Userlabel avatarSize={26} id={replacement.data.replacedUser} />
        </div>
      )}
      {replacement.data.replacementUser !== userId && (
        <div className={`replacement-from`}>
          <span>
            {i18n.t(
              "UserProfile.Replacement.ReplacementFrom",
              "Vertretung von"
            )}
          </span>
          <Userlabel avatarSize={26} id={replacement.data.replacementUser} />
        </div>
      )}
      <BFValueDisplay
        className="padding-top-10"
        label={i18n.t("UserProfile.Replacement.Message", "Grund")}
        value={replacement.data.message}
      />

      {props.replacement.data.replacedUser === userId && (
        <div className={`padding-top-10 __flex`}>
          {(replacement.data.status === REPLACEMENT_STATUS.ACCEPTED ||
            replacement.data.status === REPLACEMENT_STATUS.PENDING) && (
            <BFConfirmButton
              appearance="link"
              noPadding
              onConfirm={async () => {
                await UserReplacementService.cancelReplacementRequest(
                  replacement._id
                );
              }}
            >
              {i18n.t("UserProfile.Replacement.Cancel", "Stornieren")}
            </BFConfirmButton>
          )}
          <div className={`fill`} />
        </div>
      )}
      {props.replacement.data.replacementUser === userId && (
        <div className={`padding-top-10 __flex`}>
          {replacement.data.status === REPLACEMENT_STATUS.ACCEPTED && (
            <BFConfirmButton
              appearance="link"
              noPadding
              confirmDescription={i18n.t(
                "UserProfile.Replacement.CancelConfirmationText",
                "Wollen Sie die Vertretung wirklich stornieren?"
              )}
              confirmButtonText={i18n.t(
                "UserProfile.Replacement.Cancel",
                "Stornieren"
              )}
              onConfirm={async () => {
                await UserReplacementService.cancelReplacementRequest(
                  replacement._id
                );
              }}
            >
              {i18n.t("UserProfile.Replacement.Cancel", "Stornieren")}
            </BFConfirmButton>
          )}

          <div className={`fill`} />

          {replacement.data.status === REPLACEMENT_STATUS.PENDING && (
            <>
              <BFButton
                appearance="outline"
                onClick={async () => {
                  await UserReplacementService.declineReplacementRequest(
                    replacement._id
                  );
                }}
              >
                {i18n.t("UserProfile.Replacement.Decline", "Ablehnen")}
              </BFButton>
              <BFButton
                appearance="primary"
                onClick={async () => {
                  await UserReplacementService.acceptReplacementRequest(
                    replacement._id
                  );
                }}
              >
                {i18n.t("UserProfile.Replacement.Accept", "Akzeptieren")}
              </BFButton>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UserReplacementEntry;

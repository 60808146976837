import {
  RAInvoice,
  UrgentData,
} from "../../apps/tatar/invoiceApp/RAInterfaces";
import BaseAsset from "./BaseAsset";

export const enum TaskType {
  APPROVE_BUDGET = "APPROVE_BUDGET",

  APPROVE_INCOMING_INVOICE = "APPROVE_INVOICE",
  APPROVE_OUTGOING_INVOICE = "APPROVE_OUTGOING_INVOICE",
  COMPLETE_INVOICE_FOLLOW_UP = "COMPLETE_INVOICE_FOLLOW_UP",
  APPROVE_DAMAGECLAIM = "APPROVE_DAMAGECLAIM",
  APPROVE_ORDERINGPROCESS = "APPROVE_ORDERINGPROCESS",
}

export type TaskHistory = {
  date: Date;
  userId: string;
  responseType: "accept" | "decline";
  data?: any;
};
export type TaskAsset = BaseAsset & {
  data: {
    type: string;
    restrictTo: {
      users: string[];
      teams: string[];
    };
    reassignedFrom?: {
      user: string;
      users: string[];
      teams: string[];
    };
    taskType: TaskType;
    linkedAsset: {
      assetId: string;
      assetType: string;
      extra?: any;
    };
    // metaInfos: {
    //   [key: string]: any;
    // };
    assignmentDate?: Date;
    createdAt: Date;
    dueDate: Date;
    dueDateComment?: {
      user: string;
      message: string;
    };
    history: TaskHistory[];
  };
};

export interface ApproveDamangeClaimTaskAssetUserSelection {
  user: string;
  id: string;
  date: Date;
}
export type ApproveActivityTaskAsset = TaskAsset & {
  _approved?: boolean;
  _declined?: boolean;
  _deleted?: boolean;
  data: {
    taskType:
      | TaskType.APPROVE_DAMAGECLAIM
      | TaskType.APPROVE_ORDERINGPROCESS
      | TaskType.APPROVE_BUDGET;
    metaInfos: {
      urgent: UrgentData;
      offerEntries: string[];
      userSelections: ApproveDamangeClaimTaskAssetUserSelection[];
    };
  };
};

export type ApproveInvoiceTaskAsset = TaskAsset & {
  _approved?: boolean;
  _declined?: boolean;
  _deleted?: boolean;
  data: {
    taskType: TaskType.APPROVE_INCOMING_INVOICE;
    metaInfos: {
      step: number;
    };
  };
  expand?: {
    linkedAsset: RAInvoice;
  };
};
export type CompleteInvoiceFollowUpTaskAsset = TaskAsset & {
  _deleted?: boolean;
  _finished?: boolean;

  data: {
    taskType: TaskType.COMPLETE_INVOICE_FOLLOW_UP;
    metaInfos: {
      linkedInvoice: string | null;
      linkedStorno: string | null;
      needInvoice: boolean;
      needStorno: boolean;
    };
  };
};

export const extractTaskAssignees = (task: TaskAsset) => {
  const { restrictTo } = task.data;
  const { users, teams } = restrictTo;
  return {
    users: users || [],
    teams: teams || [],
  };
};

export type ApproveActivityAsset = TaskAsset & {
  data: {
    taskType: TaskType.APPROVE_DAMAGECLAIM | TaskType.APPROVE_ORDERINGPROCESS;
    metaInfos: {
      offerEntries: string[];
    };
  };
};

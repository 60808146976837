import { SelectionAggregation } from "@/configurable/data/VirtualizedTable/VirtualizedTable";
import StringUtils from "@/utils/StringUtils";
import _ from "lodash";
import { useState } from "react";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import BFValueDisplay from "../value-display/BFValueDisplay";
import "./BFVirtualizedTable.scss";

export type BFDataAggregationOverlayProps = {
  data: any[];
  selectionAggregations: SelectionAggregation[];
};

const BFDataAggregationOverlay = (props: BFDataAggregationOverlayProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && props.data?.length && (
        <BFDataAggregationContainer close={() => setOpen(false)} {...props} />
      )}
      {!open && <BFDataAggregationIcon open={() => setOpen(true)} />}
    </>
  );
};

export type BFDataAggregationIconProps = { open: () => void };

export const BFDataAggregationIcon = (props: BFDataAggregationIconProps) => {
  return (
    <BFButton
      appearance="default"
      onClick={() => props.open()}
      className={`data-aggregation-icon`}
    >
      <BfIcon type="light" data={"workflow-data-table-sum"} size="sm" />
    </BFButton>
  );
};

export type BFDataAggregationContainerProps = {
  close: () => void;
  data: any[];
  selectionAggregations: SelectionAggregation[];
};

const BFDataAggregationContainer = (props: BFDataAggregationContainerProps) => {
  let aggregatedValues: {
    label: string;
    value: Date | number;
    formatter?: any;
  }[] = [];
  for (let aggregation of props.selectionAggregations) {
    let aggregatedValue;
    let selectorFunction = (data) => {
      if (typeof aggregation.selector === "function") {
        return aggregation.selector(data);
      }
      return _.get(data, aggregation.selector as string);
    };

    // maxBy and minBy return the matching record, not the value. Thats why we call the selector function on it again
    if (aggregation.aggregationType === "sum") {
      aggregatedValue = _.sumBy(props.data, selectorFunction);
    } else if (aggregation.aggregationType === "avg") {
      aggregatedValue = _.meanBy(props.data, selectorFunction);
    } else if (aggregation.aggregationType === "max") {
      aggregatedValue = selectorFunction(_.maxBy(props.data, selectorFunction));
    } else if (aggregation.aggregationType === "min") {
      aggregatedValue = selectorFunction(_.minBy(props.data, selectorFunction));
    } else if (aggregation.aggregationType === "range") {
      aggregatedValue = [
        selectorFunction(_.minBy(props.data, selectorFunction)),
        selectorFunction(_.maxBy(props.data, selectorFunction)),
      ];
    }

    let formatFunction = (value) => value;
    if (typeof aggregation.format === "function") {
      formatFunction = aggregation.format;
    }
    if (aggregation.format === "currency") {
      formatFunction = (value) => {
        return StringUtils.formatCurrency(value);
      };
    } else if (aggregation.format === "date") {
      if (aggregation.aggregationType === "range") {
        formatFunction = (value) => {
          return (
            StringUtils.formatDate(value[0]) +
            " - " +
            StringUtils.formatDate(value[1])
          );
        };
      } else {
        formatFunction = (value) => {
          return StringUtils.formatDate(value);
        };
      }
    } else if (aggregation.format === "number") {
      formatFunction = (value) => {
        return StringUtils.formatNumberSimple(value);
      };
    } else if (aggregation.format === "percent") {
      formatFunction = (value) => {
        return StringUtils.formatPercent(value);
      };
    }

    aggregatedValues.push({
      label: aggregation.label,
      value: aggregatedValue,
      formatter: formatFunction,
    });
  }

  return (
    <div className={`data-aggregation-container`}>
      <BFButton appearance="link" onClick={() => props.close()}>
        {/* <BfIcon type="light" data={"close"} size="xxs" /> */}
        <BfIcon type="light" data={"arrow-down-1"} size="xxs" />
      </BFButton>
      {aggregatedValues.map((aggregatedValue) => (
        <BFValueDisplay
          key={aggregatedValue.label}
          value={aggregatedValue.value}
          label={aggregatedValue.label}
          formatter={aggregatedValue.formatter}
        />
      ))}
    </div>
  );
};

export default BFDataAggregationOverlay;

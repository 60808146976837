import BFToggle from "@/modules/abstract-ui/forms/toggle/BFToggle";
import ObjectIdService from "@/utils/ObjectIdUtils";
import { FormApi } from "final-form";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormFieldValues from "../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../components/Form/Validation/FormValidators";
import i18n from "../../../i18n";
import { useConstants } from "../../../redux/hooks";
import BFInput from "../../abstract-ui/forms/input/BFInput";
import BFButton from "../../abstract-ui/general/Button/BFButton";

export const BankAccountFormPart = (props: {
  form: FormApi;
  prefix?: string;
  disabled?: boolean;
}) => {
  const constants = useConstants();
  return (
    <FieldArray name={(props.prefix || "") + "bankAccount"}>
      {({ fields }) => (
        <div className={`section bankAccount-section`}>
          <div className={`section-title __h3 margin-bottom-0 margin-top-s0`}>
            {i18n.t("Contact.Form.Sections.BankAccount", "Bankverbindung")}
            <BFButton
              disabled={props.disabled}
              className={`margin-left-10`}
              appearance="link"
              size="xs"
              onClick={() =>
                fields.push({
                  id: ObjectIdService.new(),
                  isMain: false,
                  type: constants?.currency === "USD" ? "US" : "EU",
                })
              }
            >
              {i18n.t(
                "Contact.Form.Fields.BankAccount.AddAnother",
                "Weitere Bankverbindung hinzufügen"
              )}
            </BFButton>
          </div>
          <div className="bankAccounts">
            {fields.map((name, index) => (
              <FormFieldValues names={[`${name}.type`]} key={index}>
                {([type]) => (
                  <>
                    <div className={`bankAccount-entry`} key={index}>
                      <div className={`action-row`}>
                        <BFButton
                          disabled={props.disabled}
                          appearance="link"
                          size="xs"
                          onClick={() => {
                            if (fields.length > 1) {
                              fields.remove(index);
                            } else {
                              props.form.mutators.setValue(
                                (props.prefix || "") + "bankAccount",
                                null
                              );
                              props.form.mutators.setValue(
                                (props.prefix || "") +
                                  "helpers.showBankAccount",
                                false
                              );
                            }
                          }}
                        >
                          {i18n.t("Global.Buttons.remove")}
                        </BFButton>
                      </div>

                      <div className={`field-row`}>
                        <div className={`__field __flex-1`}>
                          <Field name={`${name}.isMain`}>
                            {({ input }) => (
                              <BFToggle
                                label={i18n.t(
                                  "Contact.Form.Fields.BankAccount.Main",
                                  "Hauptverbindung"
                                )}
                                onChange={(_, checked) => {
                                  fields.forEach((otherField) => {
                                    if (otherField !== name) {
                                      props.form.mutators.setValue(
                                        `${otherField}.isMain`,
                                        false
                                      );
                                    }
                                  });
                                  input.onChange(checked);
                                }}
                                checked={input.value}
                                disabled={props.disabled}
                              ></BFToggle>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className={`field-row`}>
                        <div className={`__field __flex-1`}>
                          <Field
                            name={`${name}.accountHolder`}
                            validate={FormValidators.compose(
                              FormValidators.required(),
                              FormValidators.max(150)
                            )}
                          >
                            {({ input, meta }) => (
                              <BFInput
                                disabled={props.disabled}
                                {...input}
                                label={`${i18n.t(
                                  "Contact.Form.Fields.Bank.AccountHolder",
                                  "Kontoinhaber"
                                )}*`}
                                validation={
                                  meta.error && meta.touched
                                    ? {
                                        level: "error",
                                        message: meta.error,
                                      }
                                    : undefined
                                }
                              />
                            )}
                          </Field>
                        </div>
                        {type === "US" && (
                          <div className={`__field __flex-1`}>
                            <Field
                              name={`${name}.accountNumber`}
                              validate={FormValidators.compose(
                                FormValidators.required(),
                                FormValidators.max(30)
                              )}
                            >
                              {({ input, meta }) => (
                                <BFInput
                                  disabled={props.disabled}
                                  {...input}
                                  label={`${i18n.t(
                                    "Contact.Form.Fields.Bank.AccountNumber",
                                    "Kontonummer"
                                  )}*`}
                                  validation={
                                    meta.error && meta.touched
                                      ? {
                                          level: "error",
                                          message: meta.error,
                                        }
                                      : undefined
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        )}
                        {type === "EU" && (
                          <div className={`__field __flex-1`}>
                            <Field
                              name={`${name}.iban`}
                              validate={FormValidators.compose(
                                FormValidators.required(),
                                FormValidators.iban()
                              )}
                            >
                              {({ input, meta }) => (
                                <BFInput
                                  disabled={props.disabled}
                                  {...input}
                                  type="ibanInput"
                                  label={`${i18n.t(
                                    "Contact.Form.Fields.Bank.IBAN",
                                    "IBAN"
                                  )}*`}
                                  validation={
                                    meta.error && meta.touched
                                      ? {
                                          level: "error",
                                          message: meta.error,
                                        }
                                      : undefined
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      </div>
                      {type === "US" && (
                        <div className={`field-row`}>
                          <div className={`__field __flex-1`}>
                            <Field
                              name={`${name}.routingNumber`}
                              validate={FormValidators.compose(
                                FormValidators.required(),
                                FormValidators.max(30)
                              )}
                            >
                              {({ input, meta }) => (
                                <BFInput
                                  disabled={props.disabled}
                                  {...input}
                                  label={`${i18n.t(
                                    "Contact.Form.Fields.Bank.RoutingNumber",
                                    "ABA"
                                  )}*`}
                                  validation={
                                    meta.error && meta.touched
                                      ? {
                                          level: "error",
                                          message: meta.error,
                                        }
                                      : undefined
                                  }
                                />
                              )}
                            </Field>
                          </div>
                          <div className={`__field __flex-1`}>
                            <Field
                              name={`${name}.bankName`}
                              validate={FormValidators.compose(
                                FormValidators.required(),
                                FormValidators.max(50)
                              )}
                            >
                              {({ input, meta }) => (
                                <BFInput
                                  disabled={props.disabled}
                                  {...input}
                                  label={`${i18n.t(
                                    "Contact.Form.Fields.Bank.BankName",
                                    "Bank"
                                  )}*`}
                                  validation={
                                    meta.error && meta.touched
                                      ? {
                                          level: "error",
                                          message: meta.error,
                                        }
                                      : undefined
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      )}
                      {type === "EU" && (
                        <div className={`field-row`}>
                          <div className={`__field __flex-1`}>
                            <Field
                              name={`${name}.bic`}
                              validate={FormValidators.compose(
                                FormValidators.required(),
                                FormValidators.max(30)
                              )}
                            >
                              {({ input, meta }) => (
                                <BFInput
                                  disabled={props.disabled}
                                  {...input}
                                  label={`${i18n.t(
                                    "Contact.Form.Fields.Bank.BIC",
                                    "BIC"
                                  )}*`}
                                  validation={
                                    meta.error && meta.touched
                                      ? {
                                          level: "error",
                                          message: meta.error,
                                        }
                                      : undefined
                                  }
                                />
                              )}
                            </Field>
                          </div>
                          <div className={`__field __flex-1`}>
                            <Field
                              name={`${name}.bankName`}
                              validate={FormValidators.compose(
                                FormValidators.required(),
                                FormValidators.max(50)
                              )}
                            >
                              {({ input, meta }) => (
                                <BFInput
                                  disabled={props.disabled}
                                  {...input}
                                  label={`${i18n.t(
                                    "Contact.Form.Fields.Bank.BankName",
                                    "Bank"
                                  )}*`}
                                  validation={
                                    meta.error && meta.touched
                                      ? {
                                          level: "error",
                                          message: meta.error,
                                        }
                                      : undefined
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </FormFieldValues>
            ))}
          </div>
        </div>
      )}
    </FieldArray>
  );
};

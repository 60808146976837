import { FC } from "react";
import i18n from "@/i18n";
import MQ from "@/utils/MatchQueryUtils";
import { RentDataSectionProps } from "./types";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import { AssetTypes } from "@/model/AssetTypes";
import LoadPage from "@/components/LoadPage/LoadPage";
import { RentDataGraphContent } from "./RentDataGraphContent";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import KPIDetailCard from "@/components/KPIDetailCard/KPIDetailCard";
import { ErrorContentComponent } from "@/components/ErrorContentComponent";

const RentDataSection: FC<RentDataSectionProps> = ({ objectId }) => {
  // ! render
  return (
    <KPIDetailCard
      title={i18n.t(
        "cb:RentDataGraph.labels.graphCardTitle",
        "Mietpreis Übersicht"
      )}
      marginBottom={20}
      childOptions={{
        render: () => {
          return (
            <AssetLoader
              assetType={AssetTypes.Portfolio.Object}
              query={MQ.eq("_id", objectId)}
              renderLoading={() => <LoadPage size="lg" />}
              renderError={() => {
                return (
                  <ErrorContentComponent
                    description={i18n.t(
                      "cb:RentDataGraph.messages.errors.errorGettingRentHistory",
                      "Fehler beim Abrufen der Mietgeschichte"
                    )}
                  />
                );
              }}
              render={(asset: OAObject) => {
                if (!asset?._id) {
                  return (
                    <ErrorContentComponent
                      description={i18n.t(
                        "cb:RentDataGraph.messages.errors.errorGettingRentHistory",
                        "Fehler beim Abrufen der Mietgeschichte"
                      )}
                    />
                  );
                }

                const objectId = asset._id;
                const surfaceArea = asset.data?.feature?.immo?.area;

                return (
                  <RentDataGraphContent
                    objectId={objectId}
                    surfaceArea={surfaceArea}
                  />
                );
              }}
            />
          );
        },
      }}
    />
  );
};

export default RentDataSection;

import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import _ from "lodash";
import moment from "moment";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import Collapse from "rsuite/esm/Animation/Collapse";
import FormFieldValues from "../../../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../../i18n";
import BFCheckbox from "../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFInput from "../../../../../modules/abstract-ui/forms/input/BFInput";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "../../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import BFMessage from "../../../../../modules/abstract-ui/general/Message/BFMessage";
import BfIcon from "../../../../../modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../../../../../modules/abstract-ui/icon/DefaultIcons";
import { hasValue } from "../../../../../utils/Helpers";
import StringUtils from "../../../../../utils/StringUtils";
import { RAInvoice } from "../../RAInterfaces";
import { RA_INVOICE_MAIN_FIELDS } from "../../RAUtils";
import { SUBTRACT_DAYS_DEFAULT } from "./RAInvoiceFormConst";
import RAInvoiceFormPaymentPlan from "./RAInvoiceFormPaymentPlan";

export const RAInvoicePaymentPlanForm = (props: {
  invoice: RAInvoice;
  withDescription?: boolean;
}) => {
  const invoicePayed = props.invoice.data.payed;
  return (
    <div className={`ra-invoice-payment-plan-form`}>
      {props.withDescription && (
        <div className={`description`}>
          {i18n.t(
            "ra:InvoiceForm.PaymentPlan.Description",
            "Wenn die Rechnung in mehreren Abschlägen oder Raten bezahlt werden soll, verwenden Sie bitte den Zahlungsplan. Hier können Sie bestimmen, wann welcher Betrag fällig wird. Die Summe der Zahlungen aus dem Zahlungsplan bestimmt den gesamten Rechnungsbetrag."
          )}
        </div>
      )}

      <FormFieldValues names={["value"]} allProps>
        {([amountToPay]) => (
          <div className={`payment-plan`}>
            <FieldArray name="paymentPlan">
              {({ fields }) => (
                <div>
                  <div className="entries">
                    {fields.map((name, index) => (
                      <FormFieldValues names={[`${name}.payedDate`]}>
                        {([payedDate]) => (
                          <div key={name} className={`payment-plan-entry`}>
                            <div className="form-row">
                              <FormFieldValues
                                names={[`${name}.activeDate`, `${name}.extra`]}
                                allProps
                              >
                                {([activeDate, extra]) => (
                                  <div className={`date`}>
                                    <Field
                                      name={`${name}.date`}
                                      validate={FormValidators.compose(
                                        FormValidators.required()
                                      )}
                                    >
                                      {({ input, meta }) => (
                                        <BFDatefield
                                          disabled={hasValue(payedDate)}
                                          label={i18n.t(
                                            "ra:InvoiceForm.PaymentPlan.date",
                                            "Datum"
                                          )}
                                          preventNull
                                          {...FormValidators.getValidation(
                                            meta
                                          )}
                                          {...input}
                                          onChange={(value) => {
                                            input.onChange(value);
                                            if (!extra?.input?.value) {
                                              activeDate?.input?.onChange(
                                                moment(value)
                                                  .subtract(
                                                    SUBTRACT_DAYS_DEFAULT,
                                                    "days"
                                                  )
                                                  .toDate()
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                )}
                              </FormFieldValues>
                              <div className={`amount`}>
                                <Field
                                  name={`${name}.amount`}
                                  validate={FormValidators.compose(
                                    FormValidators.required()
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <BFInput
                                      disabled={hasValue(payedDate)}
                                      label={i18n.t(
                                        "ra:InvoiceForm.PaymentPlan.amount",
                                        "Betrag"
                                      )}
                                      type={"priceInput"}
                                      prefix={StringUtils.getCurrencySymbol(
                                        amountToPay?.input?.value?.currency ||
                                          StringUtils.getCurrency()
                                      )}
                                      {...FormValidators.getValidation(meta)}
                                      {...input}
                                      onChange={(value: number) => {
                                        const difference = value - input.value;
                                        input.onChange(value);

                                        amountToPay?.input?.onChange({
                                          amount:
                                            amountToPay.input.value.amount +
                                            difference,
                                          currency:
                                            amountToPay.input.value.currency,
                                        });
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className={`extra`}>
                                <Field name={`${name}.extra`}>
                                  {({ input, meta }) => (
                                    <BFCheckbox
                                      disabled={hasValue(payedDate)}
                                      {...FormValidators.getValidation(meta)}
                                      checked={input.value}
                                      onChange={(_val, checked) => {
                                        input.onChange(checked);
                                      }}
                                    >
                                      {i18n.t(
                                        "ra:InvoiceForm.PaymentPlan.extra",
                                        "Erweitert"
                                      )}
                                    </BFCheckbox>
                                  )}
                                </Field>
                              </div>
                              <BFButton
                                appearance="link"
                                disabled={hasValue(payedDate)}
                                onClick={() => {
                                  const deletedAmount = (fields.value || [])[
                                    index
                                  ].amount;
                                  fields.remove(index);
                                  amountToPay?.input?.onChange({
                                    amount:
                                      amountToPay.input.value.amount -
                                      deletedAmount,
                                    currency: amountToPay.input.value.currency,
                                  });
                                }}
                                tooltip={{
                                  tooltip: i18n.t(
                                    "ra:InvoiceForm.PaymentPlan.removeEntry",
                                    "Eintrag entfernen"
                                  ),
                                }}
                              >
                                <BfIcon {...DefaultIcons.TRASH} size="xxs" />
                              </BFButton>
                            </div>
                            <FormFieldValues names={[`${name}.extra`]}>
                              {([extra]) => (
                                <Collapse in={extra}>
                                  <div>
                                    <div className={`form-row`}>
                                      <div className={`usage`}>
                                        <Field name={`${name}.usage`}>
                                          {({ input, meta }) => (
                                            <BFInput
                                              disabled={hasValue(payedDate)}
                                              label={i18n.t(
                                                "ra:InvoiceForm.PaymentPlan.usage",
                                                "Verwendungszweck"
                                              )}
                                              {...FormValidators.getValidation(
                                                meta
                                              )}
                                              {...input}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                      <div className={`activeDate`}>
                                        <Field name={`${name}.activeDate`}>
                                          {({ input, meta }) => (
                                            <BFDatefield
                                              disabled={hasValue(payedDate)}
                                              label={i18n.t(
                                                "ra:InvoiceForm.PaymentPlan.activeDate",
                                                "Zahlbar ab"
                                              )}
                                              preventNull
                                              {...FormValidators.getValidation(
                                                meta
                                              )}
                                              {...input}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                  </div>
                                </Collapse>
                              )}
                            </FormFieldValues>
                            {hasValue(payedDate) && (
                              <div className={`payed-info`}>
                                <BFMessage
                                  showIcon
                                  squished
                                  type="success"
                                  text={i18n.t(
                                    "ra:InvoiceForm.PaymentPlan.paid",
                                    "Bezahlt am {{date}}",
                                    {
                                      date: StringUtils.formatDate(payedDate),
                                    }
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </FormFieldValues>
                    ))}
                  </div>

                  <div className="array-actions">
                    {/* <div className="add-action">
                        <BFButton
                          type="button"
                          appearance={"link"}
                          icon={{ type: "bf", data: "add" }}
                          text={i18n.t(
                            "ra:InvoiceForm.PaymentPlan.addEntry",
                            "Zahlung hinzufügen"
                          )}
                          onClick={() =>
                            fields.push({
                              info: "",
                              objectId: objectId?.input?.value || null,
                              // usage: `[split] ${fields.length + 1} - ${
                              //   props.bookingEntry.data.usage
                              // }`,
                              value:
                                (amountToPay?.input?.value?.amount || 0) -
                                (fields?.value?.reduce(
                                  (prev, current) => prev + current.value || 0,
                                  0
                                ) || 0),
                            })
                          }
                        ></BFButton>
                      </div> */}
                    {fields.length === 0 && !invoicePayed && (
                      <div className="create-action">
                        <BFDropdown
                          identifier="create-plan-dropdown"
                          className={`create-plan-dropdown`}
                          asOverlay
                          overlayWidth={300}
                          overlayOverflowAllow
                          renderToggle={(props) => (
                            <BFButton
                              {...props}
                              appearance="link"
                              icon={{ type: "bf", data: "add" }}
                              text={i18n.t(
                                "ra:InvoiceForm.PaymentPlan.createPlan",
                                "Zahlungsplan erstellen"
                              )}
                            />
                          )}
                          label={i18n.t(
                            "ra:InvoiceForm.PaymentPlan.createPlan",
                            "Zahlungsplan erstellen"
                          )}
                          items={[
                            {
                              type: "panel",
                              render: () => (
                                <FormFieldValues
                                  names={[
                                    RA_INVOICE_MAIN_FIELDS().usage.identifier,
                                  ]}
                                >
                                  {([usage]) => (
                                    <RAInvoiceFormPaymentPlan
                                      invoiceUsage={usage}
                                      initialAmount={
                                        amountToPay.input.value.amount
                                      }
                                      initialDate={new Date()}
                                      currency={
                                        amountToPay?.input?.value?.currency
                                      }
                                      onPaymentPlanCreate={(paymentPlan) => {
                                        paymentPlan.forEach((payment) => {
                                          fields.push(payment);
                                        });
                                        amountToPay?.input?.onChange({
                                          amount: _.sum(
                                            paymentPlan.map((e) => e.amount)
                                          ),
                                          currency:
                                            amountToPay.input.value.currency,
                                        });
                                      }}
                                    />
                                  )}
                                </FormFieldValues>
                              ),
                            },
                          ]}
                        />
                      </div>
                    )}
                    {fields?.length > 0 && !invoicePayed && (
                      <div className="create-action">
                        <FormFieldValues
                          names={[RA_INVOICE_MAIN_FIELDS().usage.identifier]}
                        >
                          {([usage]) => (
                            <BFButton
                              appearance="link"
                              onClick={() => {
                                const amountToAdd = (fields.value || [])[
                                  fields.length - 1
                                ].amount;
                                fields.push({
                                  date: moment(
                                    (fields.value || [])[fields.length - 1].date
                                  )
                                    .add(1, "month")
                                    .toDate(),
                                  linkedPaymentObject: null,
                                  amount: amountToAdd,
                                  extra: false,
                                  usage: usage,
                                  activeDate: moment(
                                    (fields.value || [])[fields.length - 1]
                                      .activeDate
                                  )
                                    .add(1, "month")
                                    .toDate(),
                                });
                                amountToPay?.input?.onChange({
                                  amount:
                                    amountToPay.input.value.amount +
                                    amountToAdd,
                                  currency: amountToPay.input.value.currency,
                                });
                              }}
                            >
                              <BfIcon {...DefaultIcons.ADD} size="xs" />
                              {i18n.t(
                                "ra:InvoiceForm.PaymentPlan.addEntry",
                                "Zahlung hinzufügen"
                              )}
                            </BFButton>
                          )}
                        </FormFieldValues>
                      </div>
                    )}
                    {(fields.value || []).length !==
                      (props.invoice.data.payment.paymentPlan || []).length &&
                      (fields.value || []).every((e) => e.payedDate) && (
                        <BFMessage
                          type="warning"
                          text={i18n.t(
                            "ra:InvoiceForm.PaymentPlan.allPayed",
                            "Sie haben alle zukünftigen Zahlungen entfernt. Die Rechnung wird als bezahlt markiert, sobald Sie die Änderungen speichern."
                          )}
                        />
                      )}
                  </div>
                </div>
              )}
            </FieldArray>
          </div>
        )}
      </FormFieldValues>
    </div>
  );
};

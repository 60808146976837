import classNames from "classnames";
import moment from "moment";
import { RentalAgreement } from "../../../../../../apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import { Contact } from "../../../../../../model/db/Contact";
import LabeledInput from "../../../../../abstract-ui/forms/LabeledInput";
import { Resubmission } from "../../../../RSInterfaces";
import RSAssetDetails from "../../../asset-details/RSAssetDetails";
import RSSubAssetMapper from "../../../asset-details/sub-asset-mapper/RSSubAssetMapper";
import "./RSRentalAgreementPreview.scss";

interface RSRentalAgreementPreviewProps {
  resubmission: Resubmission;
}

const RSRentalAgreementPreview = (props: RSRentalAgreementPreviewProps) => {
  const { linkedAsset } = props.resubmission.data;

  const renderTypeInfoDetails = (tenant: RentalAgreement) => {
    return (
      <RSSubAssetMapper resubmission={props.resubmission} asset={tenant} />
    );
  };

  return (
    <div className={classNames("rs-rental-agreement-preview")}>
      <LabeledInput
        type="sub"
        label={i18n.t("Resubmission.Dashboard.Tenant.Title", "Mieter Daten")}
      >
        <RSAssetDetails
          assetParams={linkedAsset}
          renderAssetData={(asset: RentalAgreement) => {
            return (
              <div>
                <div className={`unit-data entry-list`}>
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t("cb:RentalAgreement.Labels.moveIn", "Mietbeginn")}
                    </div>
                    <div className={`value`}>
                      {asset.data.moveIn
                        ? moment(asset.data.moveIn).format(
                            i18n.t("Formats.dateFormat")
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t(
                        "cb:RentalAgreement.Form.Fields.agreementExpiration",
                        "Vertrag bis"
                      )}
                    </div>
                    <div className={`value`}>
                      {asset.data.agreementExpiration
                        ? moment(asset.data.agreementExpiration).format(
                            i18n.t("Formats.dateFormat")
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t(
                        "cb:RentalAgreement.Form.Fields.moveOut",
                        "Auszug"
                      )}
                    </div>
                    <div className={`value`}>
                      {asset.data.moveOut
                        ? moment(asset.data.moveOut).format(
                            i18n.t("Formats.dateFormat")
                          )
                        : "-"}
                    </div>
                  </div>
                </div>
                <AssetLoader
                  assetType={AssetTypes.Contact}
                  id={asset.data.tenant}
                  render={(tenant: Contact) => {
                    return (
                      <div className={`tenant entry-list`}>
                        <div className={`entry`}>
                          <div className={`label`}>
                            {i18n.t("cb:Tenant.Labels.Name", "Mieter")}
                          </div>
                          <div className={`value`}>
                            <div>{tenant.data.displayName}</div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
                <div className="entry-list">{renderTypeInfoDetails(asset)}</div>
              </div>
            );
          }}
        />
      </LabeledInput>
    </div>
  );
};

export default RSRentalAgreementPreview;

import classNames from "classnames";
import BFButton from "../../general/Button/BFButton";
import BFUseTooltip from "../../general/tooltip/BFUseTooltip";
import BfIcon, { BfIconProps } from "../../icon/BfIcon";
import "./BFIconTagCard.scss";

interface BFIconTagCardProps {
  children: React.ReactNode;
  onClick?: () => void;
  tooltipText?: string | React.ReactNode;
  logoUrl?: string;
  icon?: BfIconProps;
  circleComponent?: React.ReactNode;
  color?: string;
  backgroundColor?: string;
  disableButton?: boolean;
}

export const BF_ICON_TAG_CARD_HEIGHT = 66;

const BFIconTagCard = (props: BFIconTagCardProps) => {
  const getIconStyle = () => {
    const style = {
      color: props.color || "#fff",
      backgroundColor: props.backgroundColor || "#003468",
    };

    if (props.logoUrl) {
      style["backgroundImage"] = `url(${props.logoUrl})`;
    }
    return style;
  };

  const defaultIcon: BfIconProps = {
    type: "light",
    size: "sm",
    data: "information-circle",
  };

  const icon = props.icon || defaultIcon;

  const renderCircleContainer = () => {
    return (
      <div className={`circle-container`}>
        <div className={`icon-container`} style={getIconStyle()}>
          {props.circleComponent ? (
            props.circleComponent
          ) : !props?.logoUrl ? (
            <BfIcon {...icon} />
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className={classNames("bf-icon-tag-card")}>
      <BFButton
        className={classNames("bf-icon-tag-btn", {
          "disable-button": props.disableButton,
        })}
        noPadding={true}
        appearance="clear-on-white"
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
      >
        <div className={`bf-tag-card-container`}>
          {props.tooltipText ? (
            <BFUseTooltip
              delay={100}
              trigger="hover"
              placement="bottom"
              tooltip={props.tooltipText}
            >
              {renderCircleContainer()}
            </BFUseTooltip>
          ) : (
            renderCircleContainer()
          )}
          <div className="bf-tag-card-content">{props.children}</div>
        </div>
      </BFButton>
    </div>
  );
};

export default BFIconTagCard;

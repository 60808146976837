import moment from "moment";
import DebugDataComponent from "../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import {
  LinkCell,
  renderCellValue,
} from "../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFVirtualizedTable from "../../../../../../modules/abstract-ui/data/virtualized-table/BFVirtualizedTable";
import StringUtils from "../../../../../../utils/StringUtils";
import { AssetCashBudgetEntry } from "../../../model/CashBudgetEntry";
import { LoanValueEntry } from "../../portfolio/interfaces/CBPortfolioAsset";
import "./CBIdlyConnectLoanSugggestion.scss";
import { LoanSuggestion } from "./CBIdlyConnectService";

interface CBIdlyConnectLoanSugggestionProps {
  suggestion: LoanSuggestion;
  booking: AssetCashBudgetEntry;
}
const CBIdlyConnectLoanSugggestion = (
  props: CBIdlyConnectLoanSugggestionProps
) => {
  const releventMonths = [
    moment(props.booking.data.date).subtract(1, "month"),
    moment(props.booking.data.date),
    moment(props.booking.data.date).add(1, "month"),
  ];

  const relevantPlanData = props.suggestion.asset.data.values.filter((e) => {
    const month = moment(e.date);
    return releventMonths.some((monthOfBooking) =>
      monthOfBooking.isSame(month, "month")
    );
  });

  return (
    <div className={`cb-idly-connect-loan-suggestion`}>
      <DebugDataComponent data={props.suggestion} />
      <div className={`data-row`}>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.loan.name", "Darlehen")}
          </div>
          <div className={`value`}>
            <LinkCell
              text={<>{props.suggestion.asset.data.loanID}</>}
              assetType={AssetTypes.Portfolio.Loan}
              id={props.suggestion.asset._id}
              type={props.suggestion.asset.data.type}
            />
          </div>
        </div>
        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.loan.loanAmount", "Darlehensbetrag")}
          </div>
          <div className={`value`}>
            {StringUtils.formatCurrency(
              props.suggestion.asset.data.loanData.loanAmount
            )}
          </div>
        </div>

        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.loan.paymentAmount", "Annuität")}
          </div>
          <div className={`value`}>
            {StringUtils.formatCurrency(
              props.suggestion.asset.data.loanData.paymentAmount
            )}
          </div>
        </div>

        <div className={`entry`}>
          <div className={`label`}>
            {i18n.t("cb:CBIdlyConnect.loan.description", "Beschreibung")}
          </div>
          <div className={`value`}>
            {props.suggestion.asset.data.text || "-"}
          </div>
        </div>
      </div>
      <div className={`payment-plan`}>
        {relevantPlanData.length === 0 && (
          <div className={`no-data-found`}>
            {i18n.t(
              "cb:CBIdlyConnect.loan.noPaymentPlan",
              "Keine relevanten Positionen im Zahlungsplan gefunden"
            )}
          </div>
        )}
        {relevantPlanData.length > 0 && (
          <div className={`payment-plan-list`}>
            <BFVirtualizedTable
              insetShadow
              data={relevantPlanData}
              columns={{
                date: {
                  label: i18n.t("cb:CBIdlyConnect.loan.date", "Datum"),
                  flexWidth: 100,
                  disableCustomize: true,
                  render: (data: LoanValueEntry) => {
                    return renderCellValue(data.date, "-", (date) =>
                      StringUtils.formatDate(date)
                    );
                  },
                },
                payout: {
                  label: i18n.t("cb:CBIdlyConnect.loan.payout", "Auszahlung"),
                  flexWidth: 120,
                  disableCustomize: true,
                  render: (data: LoanValueEntry) => {
                    return renderCellValue(data.payout, "-", (value) =>
                      StringUtils.formatCurrency(value)
                    );
                  },
                },
                repaymentAmount: {
                  label: i18n.t(
                    "cb:CBIdlyConnect.loan.repaymentAmount",
                    "Rückzahlung"
                  ),
                  flexWidth: 120,
                  disableCustomize: true,
                  render: (data: LoanValueEntry) => {
                    return renderCellValue(data.repaymentAmount, "-", (value) =>
                      StringUtils.formatCurrency(value)
                    );
                  },
                },
                interestAmount: {
                  label: i18n.t(
                    "cb:CBIdlyConnect.loan.interestAmount",
                    "Zinsen"
                  ),
                  flexWidth: 120,
                  disableCustomize: true,
                  render: (data: LoanValueEntry) => {
                    return renderCellValue(data.interestAmount, "-", (value) =>
                      StringUtils.formatCurrency(value)
                    );
                  },
                },
                totalPayment: {
                  label: i18n.t(
                    "cb:CBIdlyConnect.loan.totalPayment",
                    "Annuität"
                  ),
                  flexWidth: 120,
                  disableCustomize: true,
                  render: (data: LoanValueEntry) => {
                    return renderCellValue(data.totalPayment, "-", (value) =>
                      StringUtils.formatCurrency(value)
                    );
                  },
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CBIdlyConnectLoanSugggestion;

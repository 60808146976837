import classNames from "classnames";
import _ from "lodash";
import React from "react";
import DebugDataComponent from "../../../../debug/DebugDataComponent";
import { TableSort } from "../../../../model/common/CommonInterfaces";
import { ColumnConfigWithIdentifier } from "./BFVirtualizedTable";

interface BFVirtualizedTableFooterProps {
  tableIdentifier: string;
  column: ColumnConfigWithIdentifier;
  index: number;
  sort?: TableSort;
  onSort?: (sort: TableSort) => void;
  params?: any;
  persistCustomizations?: boolean;
}
const BFVirtualizedTableFooter = (props: BFVirtualizedTableFooterProps) => {
  const sortDir =
    props.sort?.key === props.column.identifier ? props.sort?.dir : null;
  return (
    <div
      key={props.column.identifier}
      className={classNames(
        "footer-column",
        `alignment-${props.column.alignment || "left"}`
      )}
      style={{
        flex: !props.column.fixedWidth
          ? `${props.column.flexWidth || 1} 0 auto`
          : undefined,
        width: props.column.flexWidth || props.column.fixedWidth || 1,
        minWidth: props.column.flexWidth || props.column.fixedWidth || 1,
      }}
    >
      <DebugDataComponent data={props.column} />
      <div className="content">
        {props.column.renderFooter && props.column.renderFooter(props.params)}
      </div>
    </div>
  );
};

BFVirtualizedTableFooter.defaultProps =
  {} as Partial<BFVirtualizedTableFooterProps>;

export default React.memo(BFVirtualizedTableFooter, (prev, next) => {
  // fix memo issue with functions - we should consider some general solution for this issue
  return _.isEqualWith(prev, next, (a, b) => {
    if (typeof a === "function" && typeof b === "function") {
      return true;
    }
    return undefined;
  });
});

import moment from "moment";
import { useState } from "react";
import KPIDetailCard from "../../../../../../../../components/KPIDetailCard/KPIDetailCard";
import i18n from "../../../../../../../../i18n";
import BFButton from "../../../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFButtonToggle from "../../../../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import {
  useAssetCache,
  useConstants,
} from "../../../../../../../../redux/hooks";
import { CBPortfolioImmoConfig } from "../../../interfaces/CBPortfolioAsset";
import { CBStatisticImmoVacancyDistribution } from "../../../interfaces/CBStatisticQueries";
import CBVacancyByUnitChart from "./CBVacancyByUnitChart";
import "./CBVacancyDistributionCard.scss";
import CBVacancyDistributionChart from "./CBVacancyDistributionChart";

const checkUnassigned = (
  data: CBStatisticImmoVacancyDistribution,
  configData: CBPortfolioImmoConfig
) => {
  if (!data || !configData) {
    return false;
  }
  return (
    Object.entries(data).filter(
      ([key, _kpi]) => configData.data.typeMappings[key] === undefined
    ).length !== 0
  );
};
const aggreagateDistributionData = (
  data: CBStatisticImmoVacancyDistribution,
  configData: CBPortfolioImmoConfig,
  selector?: "business" | "private" | "parking" | "not-assigned"
) => {
  return null;

  // if (!data || !configData) {
  //   return {
  //     rent: {
  //       vacant: 0,
  //       nonVacant: 0,
  //     },
  //     count: {
  //       vacant: 0,
  //       nonVacant: 0,
  //     },
  //     area: {
  //       vacant: 0,
  //       nonVacant: 0,
  //     },
  //   };
  // }
  // return Object.entries(data)
  //   .filter(([key, _kpi]) =>
  //     !selector
  //       ? true
  //       : selector !== "not-assigned"
  //       ? configData.data.typeMappings[key] === selector
  //       : configData.data.typeMappings[key] === undefined
  //   )
  //   .reduce(
  //     (prev, current) => ({
  //       rent: {
  //         vacant: prev.rent.vacant + current[1].rent.vacant,
  //         nonVacant: prev.rent.nonVacant + current[1].rent.nonVacant,
  //       },
  //       count: {
  //         vacant: prev.count.vacant + current[1].count.vacant,
  //         nonVacant: prev.count.nonVacant + current[1].count.nonVacant,
  //       },
  //       area: {
  //         vacant: prev.area.vacant + current[1].area.vacant,
  //         nonVacant: prev.area.nonVacant + current[1].area.nonVacant,
  //       },
  //     }),
  //     {
  //       rent: {
  //         vacant: 0,
  //         nonVacant: 0,
  //       },
  //       count: {
  //         vacant: 0,
  //         nonVacant: 0,
  //       },
  //       area: {
  //         vacant: 0,
  //         nonVacant: 0,
  //       },
  //     }
  //   );
};

const nameOf = (
  filter: "business" | "parking" | "private" | "not-assigned"
) => {
  switch (filter) {
    case "business":
      return i18n.t("cb:Vacancy.business", "Gewerbe");
    case "parking":
      return i18n.t("cb:Vacancy.parking", "Stellplatz");
    case "private":
      return i18n.t("cb:Vacancy.private", "Privat");
    case "not-assigned":
    default:
      return i18n.t("cb:Vacancy.not-assigned", "Sonstiges");
  }
};
interface VacancyDistributionCardProps {
  objectIds: string[];
  marginBottom?: number;
  referenceId?: string;
}
const CBVacancyDistributionCard = (props: VacancyDistributionCardProps) => {
  const constants = useConstants();
  const configCache = useAssetCache<CBPortfolioImmoConfig>("portfolio-config", {
    type: "op",
    name: "data.type",
    op: "in",
    value: constants.currency === "USD" ? ["immo-us"] : ["immo"],
  });

  const [detailFilter, setDetailFilter] = useState<
    null | "business" | "private" | "parking" | "not-assigned"
  >(null);
  const [selector, setSelector] = useState<"count" | "area" | "rentNet">(
    "rentNet"
  );

  return (
    <KPIDetailCard
      // error={queryData.error}
      // onReload={queryData.reload}
      // loading={
      //   queryDataDetails.loading ||
      //   queryData.loading ||
      //   configCache.state === "loading"
      // }
      marginBottom={props.marginBottom}
      className="cb-vacancy-distribution-card"
      title={i18n.t("cb:Vacancy.currentVacancy", "Aktueller Leerstand")}
      // data={queryData}
      subHead={
        detailFilter && (
          <div className="detail-filter-back-action">
            <BFButton onClick={() => setDetailFilter(null)}>
              {i18n.t("Global.Buttons.back", "Zurück")}
            </BFButton>
          </div>
        )
      }
      headRight={
        <BFButtonToggle
          buttons={[
            {
              text: i18n.t("cb:Vacancy.rentNet", "NKM"),
              value: "rentNet",
            },
            {
              text: i18n.t("cb:Vacancy.area", "Fläche"),
              value: "area",
            },
            {
              text: i18n.t("cb:Vacancy.count", "Anzahl"),
              value: "count",
            },
          ]}
          value={selector}
          onChange={(val: "count" | "area" | "rentNet") => setSelector(val)}
        />
      }
      childOptions={{
        renderWhileLoading: true,
        render: () => (
          <div className="charts">
            <CBVacancyDistributionChart
              showPlannedRent
              month={moment().utc(true)}
              objectIds={props.objectIds}
              selector={selector}
              referenceId={props.referenceId}
              className="chart-entry fixed"
            />
            <CBVacancyByUnitChart
              date={moment().startOf("day").utc(true)}
              objectIds={props.objectIds}
              selector={selector}
              referenceId={props.referenceId}
              className="chart-entry full"
            />
          </div>
        ),
      }}
    />
  );
};
export default CBVacancyDistributionCard;

import classNames from "classnames";
import { APActivity } from "../../../../../../apps/tatar/activityApp/ActivityInterfaces";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import LabeledInput from "../../../../../abstract-ui/forms/LabeledInput";
import { Resubmission } from "../../../../RSInterfaces";
import RSAssetDetails from "../../../asset-details/RSAssetDetails";
import RSSubAssetMapper from "../../../asset-details/sub-asset-mapper/RSSubAssetMapper";
import "./RSPreviewActivity.scss";

interface RSPreviewActivityProps {
  resubmission: Resubmission;
}

const RSPreviewActivity = (props: RSPreviewActivityProps) => {
  const { linkedAsset } = props.resubmission.data;

  const renderSubDetails = (activity: APActivity) => {
    return (
      <RSSubAssetMapper resubmission={props.resubmission} asset={activity} />
    );
  };

  const getAssetTitle = () => {
    switch (linkedAsset.assetType) {
      case AssetTypes.Activity.DamageClaim:
        return i18n.t("Resubmission.App.Title.DamageClaim", "Schadenmeldung");
      case AssetTypes.Activity.OrderingProcess:
        return i18n.t(
          "Resubmission.App.Title.OrderingProcess",
          "Bestellvorgang"
        );
    }
  };

  const shouldRenderSubDetails = linkedAsset.subAsset || linkedAsset.assetField;
  return (
    <div className={classNames("rs-preview-activity")}>
      <LabeledInput label={getAssetTitle()} type="sub">
        <RSAssetDetails
          assetParams={linkedAsset}
          renderAssetData={(asset: APActivity) => {
            return (
              <div className="activity-container">
                <div className="row">
                  <div className="activity-title">
                    <LabeledInput
                      type="sub"
                      label={i18n.t(
                        "Resubmission.Activity.Title.Process",
                        "Vorgang"
                      )}
                    >
                      {asset.data.displayName}
                    </LabeledInput>
                  </div>
                  {asset.data.shortDescription && (
                    <div className="activity-description">
                      <LabeledInput
                        type="sub"
                        label={i18n.t(
                          "Resubmission.Activity.Title.Description",
                          "Beschreibung"
                        )}
                      >
                        {asset.data.shortDescription}
                      </LabeledInput>
                    </div>
                  )}
                </div>
                {shouldRenderSubDetails && (
                  <div className="row">{renderSubDetails(asset)}</div>
                )}
              </div>
            );
          }}
        />
      </LabeledInput>
    </div>
  );
};

export default RSPreviewActivity;

import { IDefaultIcon } from "@/types/common";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs } from "rsuite";
import Collapse from "rsuite/esm/Animation/Collapse";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import ListComponent from "../../../../configurable/components/ListComponent/ListComponent";
import DebugDataComponent from "../../../../debug/DebugDataComponent";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import InfiniteTable from "../../../../redux/actions/application/application-infinite-table-actions";
import { useTypedSelector } from "../../../../redux/hooks";
import LanguageService from "../../../../services/LanguageService";
import MQ from "../../../../utils/MatchQueryUtils";
import BFInput from "../../forms/input/BFInput";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import { DefaultIcons } from "../../icon/DefaultIcons";
import BFBreadcrumb from "../../navigation/BFBreadcrumb/BFBreadcrumb";
import BFStatus from "../status/BFStatus";
import BFIconDetails from "./BFIconDetails";
import { IconAsset, IconFamilyAsset } from "./BFIconInterfaces";
import BFIconPickerCategory from "./BFIconPickerCategory";
import "./BFIconPickerModal.scss";

const AVAILABLE_ICON_FAMILIES = ["light"]; //, "bold"];
export const openIconPicker = (
  onSelect: (value: IDefaultIcon) => void,
  families: string[] = AVAILABLE_ICON_FAMILIES
) => {
  ModalManager.show({
    size: "xxl",
    noPadding: true,
    content: (states, setStates, close) => (
      <BFIconPickerModal
        families={families}
        onSelect={(value) => {
          close();
          onSelect(value);
        }}
        onAbort={close}
      />
    ),
  });
};
const TABLE_IDENTIFIER = "bf-icon-picker";
interface BFIconPickerModalProps {
  onSelect: (value: IDefaultIcon) => void;
  onAbort: () => void;
  identifier?: string;
  families: string[];
}
const BFIconPickerModal = (props: BFIconPickerModalProps) => {
  const [identifier] = useState(props.identifier || TABLE_IDENTIFIER);
  const dispatch = useDispatch();
  const tableProps = useTypedSelector(
    (state) => state.application.infiniteTables[identifier]
  );

  const [family, setFamily] = useState(props.families[0]);
  const [selectedIcon, setSelectedIcon] = useState<IconAsset>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<string>(null);

  const resetTable = () => {
    dispatch(InfiniteTable.clearTable(identifier));
  };
  const selectIcon = (value: IDefaultIcon) => {
    props.onSelect(value);

    resetTable();
  };
  const abort = () => {
    props.onAbort();
    resetTable();
  };

  return (
    <div className={classNames(`bf-icon-picker-modal`)}>
      <div className={`list-container`}>
        {props.families.length > 1 && (
          <div className={`tabs`}>
            <Tabs
              defaultActiveKey={family}
              onSelect={(newFamily: string) => setFamily(newFamily)}
              appearance="subtle"
            >
              {props.families.map((f) => (
                <Tabs.Tab key={f} eventKey={f} title={f}>
                  {f}
                </Tabs.Tab>
              ))}
            </Tabs>
          </div>
        )}
        <AssetLoader
          assetType={AssetTypes.Global.IconFamily}
          query={MQ.eq("data.family", family)}
          render={(familyConfig: IconFamilyAsset) => (
            <div className={`family-icons`}>
              <div className={`category-filter`}>
                <BFIconPickerCategory
                  familyConfig={familyConfig}
                  activeValue={selectedCategory}
                  onSelect={(category) => {
                    if (selectedCategory === category.value) {
                      setSelectedCategory(null);
                    } else {
                      setSelectedCategory(category.value);
                    }
                  }}
                />
              </div>
              <div className="list">
                <div className={`list-header`}>
                  <div className={`left`}>
                    <div className={`breadcrumb`}>
                      <BFBreadcrumb
                        entries={
                          selectedCategory?.startsWith("CAT_")
                            ? [
                                {
                                  label: i18n.t("Global.Labels.All", "Alle"),
                                  path: null,
                                },
                                {
                                  label: LanguageService.translateLabel(
                                    familyConfig.data.categories.find(
                                      (category) =>
                                        category.category ===
                                        selectedCategory.replace("CAT_", "")
                                    ).displayName
                                  ),
                                  path: selectedCategory,
                                },
                              ]
                            : selectedCategory?.startsWith("SUB_")
                            ? [
                                {
                                  label: i18n.t("Global.Labels.All", "Alle"),
                                  path: null,
                                },
                                {
                                  label: LanguageService.translateLabel(
                                    familyConfig.data.categories.find(
                                      (category) =>
                                        category.subCategories.find(
                                          (subCategory) =>
                                            subCategory.subCategory ===
                                            selectedCategory.replace("SUB_", "")
                                        )
                                    ).displayName
                                  ),
                                  path: `CAT_${
                                    familyConfig.data.categories.find(
                                      (category) =>
                                        category.subCategories.find(
                                          (subCategory) =>
                                            subCategory.subCategory ===
                                            selectedCategory.replace("SUB_", "")
                                        )
                                    ).category
                                  }`,
                                },
                                {
                                  label: LanguageService.translateLabel(
                                    familyConfig.data.categories
                                      .find((category) =>
                                        category.subCategories.find(
                                          (subCategory) =>
                                            subCategory.subCategory ===
                                            selectedCategory.replace("SUB_", "")
                                        )
                                      )
                                      .subCategories.find(
                                        (subCategory) =>
                                          subCategory.subCategory ===
                                          selectedCategory.replace("SUB_", "")
                                      ).displayName
                                  ),
                                  path: selectedCategory,
                                },
                              ]
                            : [
                                {
                                  label: i18n.t("Global.Labels.All", "Alle"),
                                  path: null,
                                },
                              ]
                        }
                        activePath={selectedCategory}
                        onClickBreadcrumb={(breadcrumb) => {
                          if (breadcrumb.path) {
                            setSelectedCategory(breadcrumb.path);
                          } else {
                            setSelectedCategory(null);
                          }
                        }}
                      />
                    </div>
                    <Collapse in={selectedTags.length > 0}>
                      <div>
                        <div className={`tags`}>
                          {selectedTags.map((tag) => (
                            <BFStatus
                              key={tag}
                              label={tag}
                              onRemove={() => {
                                setSelectedTags((prev) =>
                                  prev.filter((oldTag) => oldTag !== tag)
                                );
                              }}
                            />
                          ))}
                          <BFButton
                            className={`remove-all`}
                            appearance="link"
                            noPadding
                            onClick={() => setSelectedTags([])}
                          >
                            {i18n.t(
                              "BFComponents.IconPicker.RemoveAll",
                              "Alle entfernen"
                            )}
                          </BFButton>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <div className={`right`}>
                    <BFInput
                      className={`search-input`}
                      value={tableProps?.searchTerm || ""}
                      onChange={(value: string) =>
                        dispatch(InfiniteTable.setSearch(identifier, value))
                      }
                      placeholder={
                        i18n.t(
                          "BFComponents.IconPicker.Search",
                          "Suche (nur englische Begriffe)"
                        ) + "..."
                      }
                      prefix={<BfIcon {...DefaultIcons.SEARCH} size="xs" />}
                    />
                  </div>
                </div>
                <div className={`list-container`}>
                  <ListComponent
                    params={{
                      selectedIcon,
                      setSelectedIcon,
                    }}
                    dataUrl={`/api/asset/icon`}
                    identifier={identifier}
                    flow
                    additionalMatchQuery={MQ.and(
                      MQ.eq("data.family", family),
                      MQ.when(selectedCategory, (selectedCategory) => {
                        if (selectedCategory.startsWith("CAT_")) {
                          return MQ.eq(
                            "data.category",
                            selectedCategory.replace("CAT_", "")
                          );
                        }
                        if (selectedCategory.startsWith("SUB_")) {
                          return MQ.eq(
                            "data.subCategory",
                            selectedCategory.replace("SUB_", "")
                          );
                        }
                        return null;
                      }),
                      MQ.when(
                        selectedTags.length > 0,
                        MQ.in("data.tags", selectedTags)
                      )
                    )}
                    limitPerRequest={100}
                    render={(data: IconAsset, index, params) => {
                      return (
                        <div
                          className={classNames(`icon-entry`, {
                            selected: params.selectedIcon?._id === data._id,
                          })}
                        >
                          <DebugDataComponent data={data} />
                          <BFButton
                            appearance="clear-on-white"
                            onClick={() => {
                              if (params.selectedIcon?._id === data._id) {
                                params.setSelectedIcon(null);
                              } else {
                                params.setSelectedIcon(data);
                              }
                            }}
                            onDoubleClick={() => {
                              selectIcon({
                                type: data.data.family,
                                data: data.data.fileName,
                              });
                            }}
                          >
                            <BfIcon
                              type={data.data.family}
                              data={data.data.fileName}
                              size="2x"
                            />
                          </BFButton>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
              <div className={`selected-details`}>
                <div className={`item-content`}>
                  {!selectedIcon && (
                    <div className={`no-selection`}>
                      {i18n.t(
                        "BFComponents.IconPicker.NoIconSelectedYet",
                        "Wählen Sie ein Icon aus, um Details anzuzeigen"
                      )}
                    </div>
                  )}
                  {selectedIcon && (
                    <BFIconDetails
                      icon={selectedIcon}
                      familyConfig={familyConfig}
                      onCategoryChange={(category) =>
                        setSelectedCategory(category)
                      }
                      onTagClick={(tag) =>
                        setSelectedTags((prev) => _.uniq([...prev, tag]))
                      }
                    />
                  )}
                </div>
                <div className={`actions`}>
                  <BFButton appearance="outline" onClick={abort}>
                    {i18n.t("Global.Buttons.cancel")}
                  </BFButton>
                  <BFButton
                    appearance="primary"
                    onClick={() =>
                      selectIcon({
                        type: selectedIcon.data.family,
                        data: selectedIcon.data.fileName,
                      })
                    }
                    disabled={!selectedIcon}
                  >
                    {i18n.t("Global.Buttons.select", "Auswählen")}
                  </BFButton>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default BFIconPickerModal;

import { CustomField } from "@/modules/customfields/CustomField.interface";
import { ContactType } from "../../../model/db/Contact";
import { BaseConstants, useConstants } from "../../../redux/hooks";

export type ActivityDunningOptions = {};
export type ActivityApplicationConstants = BaseConstants & {
  serviceUrl: string;
  assetType: string;
  taskType: string;
  permissionPrefix: string;
  businessUnits: string[];
  idPrefix: string;

  relevantTag?: string;
  fields?: {
    linkedAsset?: string;
    dunning?: ActivityDunningOptions;
    allowDuplicate?: boolean;
    stackingPlan?: boolean;
    advertisementFeature?: boolean;
    hideInvoices?: boolean;
    relationTypes?: string[];
    enableFinishDate?: boolean;
    showVacancyRate?: string[];
    constructionDiary?: boolean;
    insuranceFeature?: boolean;
    categoryQuickfilter?: boolean;
    supplyContractFeature?: boolean;
    contactType?: ContactType;
    disableDeclineButton?: boolean;
    disableDueDate?: boolean;
    insurance?: boolean;
    estimatedCosts?: boolean;
    objectIdMandatory?: boolean;
    activityCostCenter?: boolean;
    comparison?: string[];
    disableApprovals?: boolean;
    disableCreationDate?: boolean;
    projectBudgetFeature?: {
      taskType: string;
      invoiceLinkAssetType: string;
      budgetAssetType: string;
    };
    budgetFeature?: boolean;
    budgetFeatureType?: "fixYearPlan" | "timespan";
    contractData?: boolean;
    maintenanceFeature?: boolean;
    timeframeQuickfilter?: boolean;
    rentReduction?: boolean;
    disableCreation?: boolean;

    customFields?: CustomField[];
    assetApproval?: {
      valueLabel: string;
      tabTitle: string;
      fields?: CustomField[];
      updateTitle?: string;
      createTitle?: string;
    };
  };
};

export const useActivityConstants = (
  forceCurrentApplication = false
): ActivityApplicationConstants => {
  const constants = useConstants<ActivityApplicationConstants>(
    forceCurrentApplication
  );
  return constants;
};

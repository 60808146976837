import { APConfigBase } from "../../../../apps/AppConfigInterfaces";
import { TranslatedLabel } from "../../../../services/LanguageService";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import { DataByUnitType } from "../AbstractStructSelectors";
import { ActivityAbstractStructClass } from "./ActivityAbstractStruct";

class InsuranceStructClass extends ActivityAbstractStructClass<"insurance"> {
  getStructType(): StructType {
    return "insurance";
  }
  loadStructData(
    types: string[]
  ): Promise<DataByUnitType<APConfigBase<"insurance">>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: "/insurance/getConfig",
          target: "EMPTY",
          queryParams: {
            param: {
              types: types,
            },
          },
        })) as APConfigBase<"insurance">[];

        const result: DataByUnitType<APConfigBase<"insurance">> = {};
        types.forEach((type) => {
          result[type] = data.find((config) => config.data.type === type);
        });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }
  getInsurancePositions(unit: string) {
    return this.useCache<{ displayName: TranslatedLabel; id: string }[]>(
      "getInsurancePositions",
      arguments,
      () => {
        const config = this.getConfig(unit);
        const insurancePositions =
          (config.data as any).insurancePositions || [];
        return insurancePositions;
      }
    );
  }
}
const InsuranceStruct = new InsuranceStructClass();
export default InsuranceStruct;

import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFStatus from "@/modules/abstract-ui/data/status/BFStatus";
import useOpenResubmissionForm from "@/modules/resubmission/hooks/useOpenResubmissionForm";
import { RSAssetBaseParams } from "@/modules/resubmission/RSInterfaces";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import DataBus from "@/services/DataBus";
import { DataBusSubKeys } from "@/utils/Constants";
import { hasValue } from "@/utils/Helpers";
import moment from "moment";
import { Trans } from "react-i18next";
import { Comment, MailIncomingComment } from "../../Comments";
import CommentUtils, { EMAIL_TAG_COLOR } from "../../CommentUtils";
import { CommentContainer } from "../CommentContainer";
import CommentEntryContent from "../CommentEntryContent";
import { CommentAttachmentEntry } from "../CommentInput";
import { EmailAddressFieldModel } from "../EmailAddressFields";

interface MailIncomingCommentProps {
  onPinChange?: () => void;
  comment: MailIncomingComment;
  commentModuleIdentifier?: string;
  context?: {
    id: number;
    name: string;
  };
  onContextClick?: (contextId: number) => void;
  defaultSignature?: string;
  allowFullscreen?: boolean;
  onReply?: () => void;
  onRedirect?: () => void;
  hideAllActions?: boolean;
  disableFocusMode?: boolean;
  hideResubmissionActionForMail?: boolean;
  apperance?: "normal" | "slim";
  onClose?: () => void;
  renderMailDuplicateAction?: (
    comment: Comment,
    onClose: () => void
  ) => JSX.Element;
  collapsible?: boolean;
}
export const MailIncomingCommentEntry = (props: MailIncomingCommentProps) => {
  const { openResubmissionDialog } = useOpenResubmissionForm();

  const possibleTags = (
    UnitStruct.getAllTagConfigs(AssetTypes.Comment) || []
  ).filter((e) => e.status !== "archived");

  const openResubmissionAction = () => {
    const linkedAsset: RSAssetBaseParams = {
      assetType: props.comment.data?.linkedAsset?.type,
      assetId: props.comment.data?.linkedAsset.id,
      assetDescription: `${i18n.t(
        "CommentsModule.OutgoingMail.Label",
        "E-Mail (ausgehend)"
      )}: ${props.comment.data?.typeData.subject}`,
      subAsset: {
        assetType: AssetTypes.Comment,
        assetId: props.comment._id,
      },
    };
    openResubmissionDialog({ linkedAsset });
  };

  const renderMailDuplicateAction = props.renderMailDuplicateAction
    ? (onClose: () => void) => {
        if (props.renderMailDuplicateAction) {
          return props.renderMailDuplicateAction(
            props.comment as Comment,
            onClose
          );
        }
      }
    : undefined;

  return (
    <CommentContainer
      collapsible={props.collapsible}
      pinned={props.comment.data.pin}
      onPinChange={props.onPinChange}
      archived={props.comment.data?.archived}
      commentId={props.comment._id}
      tagData={{
        possibleTags,
        tags: props.comment.data?.tags,
      }}
      hideAllActions={props.hideAllActions}
      onClose={props.onClose}
      onContextClick={props.onContextClick}
      className={`incoming-mail`}
      allowFullscreen={props.allowFullscreen}
      disableFocusMode={props.disableFocusMode}
      subTitle={
        hasValue(props.comment.data?.typeData.replyTo) && (
          <>
            <Trans
              tOptions={{
                interpolation: {
                  escapeValue: true,
                },
              }}
              i18nKey="CommentsModule.IncomingMail.replyToText"
            >
              Antwort wird gesendet an:
              <span className="weight-600">
                {{
                  replyTo: props.comment.data?.typeData.replyTo,
                }}
              </span>
            </Trans>
          </>
        )
      }
      title={
        <>
          <Trans
            tOptions={{
              interpolation: {
                escapeValue: true,
              },
            }}
            i18nKey="CommentsModule.IncomingMail.headerLabel"
          >
            Sie haben eine <span className="weight-600"> E-Mail</span> von{" "}
            <span className="weight-600">
              {{
                from: CommentUtils.formatEmailUser(
                  props.comment.data?.typeData.sender
                ),
              }}
            </span>{" "}
            erhalten.
          </Trans>
        </>
      }
      context={props.context}
      icon={"email-action-unread"}
      apperance={props.apperance}
      onPrint={() => ({
        subject: props.comment.data?.typeData.subject,
        cc: props.comment.data?.typeData.cc,
        to: props.comment.data?.typeData.recipients,
        date: props.comment.data?.date,
        from: props.comment.data?.typeData.sender,
      })}
      date={props.comment.data?.date}
      content={<CommentEntryContent comment={props.comment} />}
      subData={
        <div className={`mail-data`}>
          <div className={`recipients field`}>
            <div className={`label`}>
              {i18n.t("CommentsModule.OutgoingMail.recipients", "Empfänger")}:
            </div>
            <div className="value">
              {props.comment.data?.typeData.recipients.map((recipient, key) => (
                <BFStatus
                  key={key}
                  label={CommentUtils.formatEmailUser(recipient)}
                  size="sm"
                  color={EMAIL_TAG_COLOR}
                  style={{ fontWeight: 400 }}
                />
              ))}
            </div>
          </div>
          {props.comment.data?.typeData.cc?.length !== 0 && (
            <div className={`cc field`}>
              <div className={`label`}>
                {i18n.t("CommentsModule.OutgoingMail.cc", "CC")}:
              </div>
              <div className="value">
                {props.comment.data?.typeData.cc.map((cc, index) => (
                  <BFStatus
                    key={index}
                    label={CommentUtils.formatEmailUser(cc)}
                    size="sm"
                    color={EMAIL_TAG_COLOR}
                    style={{ fontWeight: 400 }}
                  />
                ))}
              </div>
            </div>
          )}
          <div className={`subject field`}>
            <div className={`label`}>
              {i18n.t("CommentsModule.OutgoingMail.subject", "Betreff")}:
            </div>
            <div className="value">{props.comment.data?.typeData.subject}</div>
          </div>
        </div>
      }
      footer={{
        attachment: {
          asset: props.comment,
          assetType: "comment",
          attachmentFieldPath: "data.attachments",
          attachments: props.comment.data?.attachments,
        },
        onCreateResubmission:
          props.hideResubmissionActionForMail || props.hideAllActions
            ? undefined
            : openResubmissionAction,
        onRedirect:
          props.onRedirect && !props.hideAllActions
            ? props.onRedirect
            : props.commentModuleIdentifier && !props.hideAllActions
            ? async () => {
                DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                  identifier: props.commentModuleIdentifier,
                  cursorPosition: "start",
                  comment: `
          <p>
          </p>
          ${props.defaultSignature || ""}
          <br>
          <br>
          
          <div class="iberio-cite-prefix">${i18n.t(
            "CommentsModule.IncomingMail.citePrefix",
            "Am {{date}}, {{sender}} schrieb: ",
            {
              date: moment(props.comment.data?.date).format(
                i18n.t("Formats.dateTimeFormat")
              ),
              sender: CommentUtils.formatEmailUser(
                props.comment.data?.typeData.sender
              ),
            }
          )}<br></div>
          <blockquote type="cite" cite="mid:${
            props.comment.data?.typeData.sender.address
          }">
          ${CommentUtils.stripImagesBase64FromHTML(
            CommentUtils.stripCommentsFromHTML(
              props.comment.cdnHtml ||
                props.comment.data?.message.split("\n").join("<br>")
            )
          )}
          </blockquote>
        `,
                  sendAsMail: true,
                  attachments: props.comment.cdn
                    .filter((cdn) => {
                      return props.comment.data?.attachments?.find(
                        (attachment) => attachment.linkToCdn === cdn._id
                      );
                    })
                    .map(
                      (cdn) =>
                        ({
                          cdnId: cdn._id,
                          filename: cdn.filename,
                          fromtAssetId: props.comment._id,
                        } as CommentAttachmentEntry)
                    ),
                  subactivity: props.comment.data?.subactivityId,
                  emailAddressFields: {
                    recipients: [],
                    cc: [],
                    subject: props.comment.data?.typeData.subject,
                  } as EmailAddressFieldModel,
                });
              }
            : undefined,
        onReply:
          props.onReply && !props.hideAllActions
            ? props.onReply
            : props.commentModuleIdentifier && !props.hideAllActions
            ? async () => {
                DataBus.emit(DataBusSubKeys.COMMENT_INPUT_INIT, {
                  identifier: props.commentModuleIdentifier,
                  cursorPosition: "start",
                  comment: `
              <p>
              </p>
              ${props.defaultSignature || ""}
              <br>
              <br>
              
              <div class="iberio-cite-prefix">${i18n.t(
                "CommentsModule.IncomingMail.citePrefix",
                "Am {{date}}, {{sender}} schrieb: ",
                {
                  date: moment(props.comment.data?.date).format(
                    i18n.t("Formats.dateTimeFormat")
                  ),
                  sender: CommentUtils.formatEmailUser(
                    props.comment.data?.typeData.sender
                  ),
                }
              )}<br></div>
              <blockquote type="cite" cite="mid:${
                props.comment.data?.typeData.sender.address
              }">
              ${CommentUtils.stripImagesBase64FromHTML(
                CommentUtils.stripCommentsFromHTML(
                  props.comment.cdnHtml ||
                    props.comment.data?.message.split("\n").join("<br>")
                )
              )}
              </blockquote>
            `,
                  sendAsMail: true,
                  // attachments: props.comment.cdn
                  //   .filter((cdn) => {
                  //     return props.comment.data?.attachments?.find(
                  //       (attachment) => attachment.linkToCdn === cdn._id
                  //     );
                  //   })
                  //   .map(
                  //     (cdn) =>
                  //       ({
                  //         cdnId: cdn._id,
                  //         filename: cdn.filename,
                  //       } as CommentAttachmentEntry)
                  //   ),
                  subactivity: props.comment.data?.subactivityId,
                  emailAddressFields: {
                    recipients: hasValue(props.comment.data?.typeData.replyTo)
                      ? [
                          {
                            name: "",
                            address: props.comment.data?.typeData.replyTo,
                          },
                        ]
                      : [props.comment.data?.typeData.sender],
                    cc: props.comment.data?.typeData.cc,
                    subject: props.comment.data?.typeData.subject,
                  } as EmailAddressFieldModel,
                });
              }
            : undefined,
      }}
      renderMailDuplicateAction={renderMailDuplicateAction}
    />
  );
};

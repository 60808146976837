import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Resubmission } from "@/modules/resubmission/RSInterfaces";
import RSSubAssetMapper from "@/modules/resubmission/components/asset-details/sub-asset-mapper/RSSubAssetMapper";
import RSListDetailsComponent from "@/modules/resubmission/components/list-details/RSListDetailsComponent";
import classNames from "classnames";
import { OAObject } from "../../../types/object.interface";
import "./ObjectResubmissionView.scss";

interface CBPortfolioObjectResubmissionViewProps {
  objectAsset: OAObject;
}

const ObjectResubmissionView = (
  props: CBPortfolioObjectResubmissionViewProps
) => {
  const renderResubmissionObjectDetails = (resubmission: Resubmission) => {
    return (
      <RSSubAssetMapper resubmission={resubmission} asset={props.objectAsset} />
    );
  };

  return (
    <div className={classNames("object-resubmission-view")}>
      <RSListDetailsComponent
        asset={props.objectAsset}
        assetType={AssetTypes.Portfolio.Object}
        assetDescription={`${i18n.t(
          "AssetTypes.CB.Labels.Object",
          "Objekt"
        )}: ${props.objectAsset.data.displayName}`}
        listIdentifier={`CB_PORTFOLIO_OBJECT_TS_${props.objectAsset._id}`}
        renderResubmissionAssetDetails={renderResubmissionObjectDetails}
        typePermissions={[props.objectAsset.data.type]}
      />
    </div>
  );
};

export default ObjectResubmissionView;

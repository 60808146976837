import { useRef } from "react";
import { Loader } from "rsuite";
import AssetLoader from "../../components/AssetLoader/AssetLoader";
import { AssetTypes } from "../../model/AssetTypes";
import { Contact } from "../../model/db/Contact";
import DataBus from "../../services/DataBus";
import BFOverlay from "../abstract-ui/general/whisper/BFOverlay";
import ContactViewWithEdit from "./ContactViewWithEdit";

const CONTACT_SELECT_OVERLAY_ID = "contact-select-overlay";

export const ContactSelectValue = (props: {
  id: string;
  overwriteForEntity?: string;
}) => {
  const ref = useRef(null);
  if (!props.id) {
    return null;
  }
  return (
    <AssetLoader
      renderLoading={() => <Loader size="xs" />}
      assetType={AssetTypes.Contact}
      id={props.id}
      render={(contact: Contact) => (
        <BFOverlay
          delay={1000}
          enterable
          identifier={CONTACT_SELECT_OVERLAY_ID}
          speaker={
            <div className={`contact-view-container`}>
              <ContactViewWithEdit
                overwriteForEntity={props.overwriteForEntity}
                contact={contact}
                onClose={() => {
                  DataBus.emit("WHISPER", {
                    identifier: CONTACT_SELECT_OVERLAY_ID,
                    type: "CLOSE",
                  });
                }}
              />
            </div>
          }
        >
          <div className={`contact-select-value`}>
            {contact.data.displayName}
          </div>
        </BFOverlay>
      )}
    />
  );
};

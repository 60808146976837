import { ThunkDispatch } from "redux-thunk";
import { ContextData } from "../../../model/db/ContextData";
import { User, UserConfig } from "../../../model/db/User";
import { JsonProperties } from "../../../modules/generic-forms/util/JsonValidation";
import DataBus from "../../../services/DataBus";
import PermissionService from "../../../services/PermissionService";
import { ConstantMisc, LSTrustedDeviceModel } from "../../../utils/Constants";
import { HTTP } from "../../../utils/Http";
import StorageUtils from "../../../utils/StorageUtils";
import { MandatorData } from "../../reducers/global/GlobalInterface";
import { AppState } from "../../store";
import { RESET_CACHE_COMPLETE } from "../application/application-action-types";
import {
  ADD_TRUSTED_DEVICE,
  MERGE_GLOBAL_CONFIG,
  REMOVE_TRUSTED_DEVICE,
  SET_ASSET_TYPE_CONF,
  SET_CONTEXT_DATA,
  SET_GLOBAL_CACHE_DATA,
  SET_MANDATOR_DATA,
  SET_USER_DATA,
  UPDATE_USER_CONFIG,
} from "./global-actions-types";

// TypeScript infers that this function is returning SendMessageAction

export function setAssetTypeConfig(
  assetTypeConf: {
    name: string;
    formDefinition: { [key: string]: { [key: string]: any } };
    properties: JsonProperties;
  }[]
) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_ASSET_TYPE_CONF,
      assetTypeConfigs: assetTypeConf,
    });
  };
}

export function setContextData(context: ContextData) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_CONTEXT_DATA,
      context,
    });
  };
}

export function setMandatorData(mandator: MandatorData) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    DataBus.emit("mandator", mandator, true);
    dispatch({
      type: SET_MANDATOR_DATA,
      mandator,
    });
  };
}

export function setUserData(user: User) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    PermissionService.setUser(user);
    dispatch({
      type: SET_USER_DATA,
      user,
    });
    if (!user) {
      dispatch({
        type: RESET_CACHE_COMPLETE,
      });
    }
  };
}

export function updateUserConfig(config: UserConfig) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: UPDATE_USER_CONFIG,
      config: config,
    });
  };
}

export function setGlobalCacheData(
  key: string,
  data?: any,
  ttl?: number,
  error?: any,
  loading?: boolean
) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: SET_GLOBAL_CACHE_DATA,
      key,
      data,
      timestamp: Number(new Date()),
      ttl,
      error,
      loading,
    });
  };
}

export function mergeGlobalConfig(config: any) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    dispatch({
      type: MERGE_GLOBAL_CONFIG,
      config,
    });
  };
}

export function addTrustedDevice(
  deviceName: string,
  pin: string,
  appName: string,
  onSuccess: () => void,
  onError: (err: any) => void
) {
  return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
    HTTP.post({
      target: "API",
      url: `user/requestPinToken`,
      bodyParams: {
        deviceName,
        pin,
        appName,
      },
    })
      .then((response) => {
        const store = getState();

        const { token, deviceID } = response;

        //save the trusted device locally
        const storedDevices: LSTrustedDeviceModel[] =
          (StorageUtils.LocalStorage.get(ConstantMisc.LS_KEY_TRUSTED_DEVICES) ||
            []) as LSTrustedDeviceModel[];

        storedDevices.push({
          token,
          deviceID,
          mandator: store.global.mandator._id,
          avatar: store.global.user.avatar,
          displayName: store.global.user.displayname,
          userID: store.global.user._id,
        });

        StorageUtils.LocalStorage.save(
          ConstantMisc.LS_KEY_TRUSTED_DEVICES,
          storedDevices
        );
        dispatch(
          addTrustedDeviceToStore(
            deviceName,
            token,
            deviceID,
            appName,
            new Date()
          )
        );
        onSuccess();
      })
      .catch((err) => {
        onError(err);
      });
  };
}
export function addTrustedDeviceToStore(
  deviceName: string,
  deviceID: string,
  token: string,
  appName: string,
  creationTime?: Date,
  lastUsed?: Date
) {
  return (dispatch: ThunkDispatch<{}, {}, any>, getState: () => AppState) => {
    if (getState().global.user) {
      dispatch({
        type: ADD_TRUSTED_DEVICE,
        deviceName,
        deviceID,
        token,
        appName,
        creationTime,
        lastUsed,
      });
    }
  };
}
export function removeTrustedDevice(
  deviceID: string,
  onSuccess: () => void,
  onError: (err: any) => void
) {
  return (dispatch: ThunkDispatch<{}, {}, any>) => {
    HTTP.post({
      target: "API",
      url: `user/removePinToken`,
      bodyParams: {
        deviceID,
      },
    })
      .then((response) => {
        // dispatch for list

        let storedDevices: LSTrustedDeviceModel[] =
          (StorageUtils.LocalStorage.get(ConstantMisc.LS_KEY_TRUSTED_DEVICES) ||
            []) as LSTrustedDeviceModel[];
        storedDevices = storedDevices.filter(
          (device) => device.deviceID !== deviceID
        );
        StorageUtils.LocalStorage.save(
          ConstantMisc.LS_KEY_TRUSTED_DEVICES,
          storedDevices
        );

        dispatch({
          type: REMOVE_TRUSTED_DEVICE,
          deviceID,
        });
        onSuccess();
      })
      .catch((err) => {
        onError(err);
      });
  };
}

// interface AddTrustedDevice {
// 	type: typeof ADD_TRUSTED_DEVICE;
// 	deviceName: string;
// 	pin: string;
// }
// interface RemoveTrustedDevice {
// 	type: typeof REMOVE_TRUSTED_DEVICE;
// 	deviceId: string;
// }

import { withPDFBlock } from "../../PDFBlock";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";

import { PDFBlockFooter } from "../../PDFBlockFooter";
import { ObjectVacancyActivityConfig } from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";

import { useState } from "react";

import { useBlockChanges } from "../../PDFBlockHooks";

import i18n from "@/i18n";

import {
  BaseConstants,
  ConstantsContext,
  useSpecificConstants,
} from "@/redux/hooks";
import StructLoader from "@/components/StructLoader/StructLoader";
import { VacancyActivityBlockCheckboxList } from "./Components/VacancyActivityBlockCheckboxList";
import { PDFTableFooterInput } from "./Components/PDFTableFooterInput";
import { PDFCommonInput } from "../../Common/PDFCommonInput";

const PDFObjectVacancyActivityBlock = withPDFBlock<ObjectVacancyActivityConfig>(
  ({ value, parentId }) => {
    const [{ asset }, { deleteBlock, saveBlock }] = usePDFConstructor();

    const vacancyConstants = useSpecificConstants<
      { serviceUrl: string; businessUnits: string[] } & BaseConstants
    >("app-vacancy");

    const [form, setForm] = useState({ ...value, assetId: asset._id });

    useBlockChanges(value, form);

    return (
      <div>
        <StructLoader
          unitTypes={vacancyConstants.businessUnits}
          structTypes={[vacancyConstants.serviceUrl as any, "unit", "orga"]}
          render={([structToUse]) => {
            return (
              <ConstantsContext.Provider value={vacancyConstants}>
                <VacancyActivityBlockCheckboxList
                  value={form}
                  onChange={(key, value) =>
                    setForm((form) => ({
                      ...form,
                      [key]: value,
                    }))
                  }
                  structToUse={structToUse}
                />
              </ConstantsContext.Provider>
            );
          }}
        />

        <PDFTableFooterInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
          hideColumnsInput
        />

        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock({ ...value, assetId: asset._id }, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.ActivityVacancyTable",
      "Aktivitäts-Leerstandstabelle"
    )
);

export { PDFObjectVacancyActivityBlock };

import { MatchQuery } from "../../../../../services/DataService";
import MQ from "../../../../../utils/MatchQueryUtils";
import { BfIconProps } from "../../../icon/BfIcon";
import { FilterOptionWithMatchQueryConverter } from "../BFTableFilterModel";
import OverlayUser, {
  UserValue,
  generateUserLabel,
} from "../overlays/OverlayUser";

export const UserFilterOption: (opts: {
  field: string;
  label: string;
  icon?: BfIconProps;
  matchQueryConverter?: (value: any) => MatchQuery | null;
}) => FilterOptionWithMatchQueryConverter = (opts) => {
  const { field, label, icon } = opts;
  const matchQueryConverter =
    opts.matchQueryConverter ||
    ((value: UserValue) =>
      value?.users?.length > 0 ? MQ.in(field, value.users) : null);
  return {
    matchQueryConverter: matchQueryConverter,
    key: field,
    label: label,
    icon: {
      type: icon?.type || "light",
      data: icon?.data || "single-neutral",
    },
    renderOverlay: (value, onChange) => (
      <OverlayUser title={label} value={value} onChange={onChange} />
    ),
    renderValueData: (value: UserValue) => generateUserLabel(label, value),
    getOptions: (currentValue, searchTerm) => [],
  };
};

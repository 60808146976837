import BFBadge from "@/modules/abstract-ui/general/Badge/BFBadge";
import { hasNewChangelogEntry } from "@/modules/changelog/changelog.utils";
import { openChangelogModal } from "@/modules/changelog/ChangelogModal";
import React, { Component } from "react";
import Flag from "react-flags";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dropdown } from "rsuite";
import screenfull from "screenfull";
import Separator from "../../configurable/components/Separator/Separator";
import i18n, { changeLanguage } from "../../i18n";
import { Application } from "../../model/db/Application";
import { User } from "../../model/db/User";
import BfRadio from "../../modules/abstract-ui/forms/radio/BfRadio";
import {
  default as BFButton,
  default as BfButton,
} from "../../modules/abstract-ui/general/Button/BFButton";
import BfIcon, { BfIconProps } from "../../modules/abstract-ui/icon/BfIcon";
import AppDrawerButton from "../../modules/app-drawer/AppDrawerButton";
import ContactsButton from "../../modules/contacts-module/ContactsButton";
import NotificationsButton from "../../modules/notifications/NotificationsButton";
import ResubmissionAppBarButton from "../../modules/resubmission/app/ResubmissionAppBarButton";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import { logoutUser } from "../../services/AuthenticationService";
import AvatarComponent from "../AvatarComponent/AvatarComponent";
import "./ApplicationHeader.scss";
import { HeaderThemeSwitcher } from "./components";

type Props = {
  availableLanguages: { selector: string; flag: string; name: string }[];
  selectedLanguage: string;
  user: User;
  logoutUser: (onSuccess: () => void, onError: (err) => void) => void;
  headerTransparent: boolean;
  headerShowAppDrawer: boolean;
  appDrawerOverlayActive: boolean;
  setUiConfig: (key: string, value: any) => void;
  activeApplication: Application;
  viewportSizeSelector: string;
  initializingApp: Application;
  showHeaderLeftNode: React.ReactNode;
  mobileHeader?: { icon?: BfIconProps; textKey: string };
} & RouteComponentProps;
type States = {
  fullscreenActive: boolean;
  fullscreenEnabled: boolean;
};

class ApplicationHeader extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    fullscreenActive: false,
    fullscreenEnabled: false,
  };

  componentDidMount() {
    if (screenfull.isEnabled) {
      this.setState({
        fullscreenActive: screenfull.isFullscreen,
        fullscreenEnabled: true,
      });
      screenfull.on("change", () => {
        if (screenfull.isEnabled) {
          this.setState({ fullscreenActive: screenfull.isFullscreen });
        }
      });
    }
  }

  openFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.request();
    }
  }

  closeFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.exit();
    }
  }

  logoutUser() {
    this.props.logoutUser(
      () => {
        this.props.history.push("/login");
      },
      (err) => {}
    );
  }

  renderResubmissionIcon() {
    return <ResubmissionAppBarButton />;
  }

  renderAppDrawer() {
    const {
      activeApplication,
      user: {
        mandator_info: { apps },
      },
    } = this.props;
    // if (!activeApplication || !apps || apps.length === 0) {
    //   return null;
    // }

    return <AppDrawerButton />;
  }

  renderHomeButton() {
    const {
      activeApplication,
      user: {
        mandator_info: { apps },
      },
    } = this.props;
    if (!activeApplication || !apps || apps.length === 0) {
      return null;
    }

    return (
      <BfButton
        className={"home-button"}
        appearance="clear"
        onClick={() => this.props.history.push("/home")}
      >
        <BfIcon type="light" data="house" />
      </BfButton>
    );
  }

  renderDropdown() {
    const { mobileHeader, user } = this.props;
    return (
      <Dropdown
        className="header-dropdown"
        title={
          <AvatarComponent
            size={"sm"}
            avatar={user.avatar}
            displayName={user.displayname}
          />
        }
        noCaret
        placement="bottomEnd"
      >
        <Dropdown.Item
          className="dropdown-item profile-panel"
          // icon={
          //   <AvatarComponent
          //     style={{ marginLeft: -3, marginRight: 10 }}
          //     size="sm"
          //     avatar={user.avatar}
          //     displayName={user.displayname}
          //   />
          // }
          panel
          // onClick={() => {
          //   this.props.history.push(
          //     this.props.location.pathname + "/__userprofile/profile"
          //   );
          // }}
        >
          <div className={`profile-image`}>
            <AvatarComponent
              style={{ marginLeft: -3, marginRight: 10 }}
              size={42}
              avatar={user.avatar}
              displayName={user.displayname}
            />
          </div>
          <div className={`data`}>
            <div className={`name`}>{user.displayname}</div>
            <div>
              <BFButton
                appearance="link"
                noPadding
                onClick={() => {
                  this.props.history.push(
                    this.props.location.pathname + "/__userprofile/profile"
                  );
                }}
              >
                {i18n.t("ApplicationHeader.showProfile", "Profil anzeigen")}
              </BFButton>
            </div>
          </div>
        </Dropdown.Item>

        <Separator horizontal={true} />
        {/* {
                        this.props.viewportSizeSelector !== "xs" && this.props.headerShowAppDrawer ? (
                            <Dropdown.Item
                                icon={<BfIcon type="bf" data="layout-module" />}
                                onClick={() => this.props.setUiConfig(DefaultUIConfigs.APP_DRAWER_OVERLAY_ACTIVE, !this.props.appDrawerOverlayActive)} >
                                <Trans i18nKey="Header.appChange" />
                            </Dropdown.Item>
                        ) : null
                    } */}
        {this.state.fullscreenActive ? (
          <Dropdown.Item
            icon={<BfIcon type="bf" data="shrink-4" />}
            onClick={() => this.closeFullscreen()}
          >
            <Trans i18nKey="Header.closeFullscreen" />
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            icon={<BfIcon type="bf" data="expand-5" />}
            onClick={() => this.openFullscreen()}
          >
            <Trans i18nKey="Header.openFullscreen" />
          </Dropdown.Item>
        )}
        <Separator horizontal={true} />
        {this.props.availableLanguages.length > 1 ? (
          <Dropdown.Menu
            icon={<BfIcon type="bf" data="earth-3" />}
            title={<Trans i18nKey="Header.language" />}
            className="pull-left"
          >
            {this.props.availableLanguages.map((lang) => (
              <Dropdown.Item
                key={lang.flag}
                onSelect={() => changeLanguage(lang.selector)}
                icon={
                  <Flag
                    basePath={`/img/flags`}
                    name={lang.flag}
                    format="png"
                    pngSize={32}
                  />
                }
              >
                <div className={`language-option`}>
                  <div className={`name`}>{lang.name}</div>{" "}
                  <BfRadio
                    checked={lang.selector === this.props.selectedLanguage}
                  />
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        ) : null}
        <Separator horizontal={true} />
        <HeaderThemeSwitcher />
        {this.props.availableLanguages.length > 1 ? (
          <Separator horizontal={true} />
        ) : null}
        <Dropdown.Item
          icon={<BfIcon type="light" data="megaphone" size="xs" />}
          onClick={() => openChangelogModal()}
        >
          <BFBadge content={hasNewChangelogEntry() ? "1" : undefined}>
            <span>{i18n.t("Header.changelog", "Updates")}</span>
          </BFBadge>
        </Dropdown.Item>
        <Dropdown.Item
          icon={<BfIcon type="bf" data="information-circle" />}
          onClick={() =>
            this.props.history.push(this.props.location.pathname + "/__about")
          }
        >
          <Trans i18nKey="Header.about" />
        </Dropdown.Item>
        <Dropdown.Item
          icon={<BfIcon type="bf" data="shield-lock" />}
          onClick={() =>
            this.props.history.push(this.props.location.pathname + "/__privacy")
          }
        >
          <Trans i18nKey="Header.privacy" />
        </Dropdown.Item>
        {/* <Dropdown.Item
          icon={<BfIcon type="bf" data="question-help-circle" />}
          onClick={() =>
            this.props.setUiConfig(DefaultUIConfigs.HELP_DRAWER_ACTIVE, true)
          }
        >
          <Trans i18nKey="Header.help" />
        </Dropdown.Item> */}

        <Separator horizontal={true} />
        <Dropdown.Item
          icon={<BfIcon type="bf" data="logout-1" />}
          onClick={() => this.logoutUser()}
        >
          <Trans i18nKey="Header.logout" />
        </Dropdown.Item>
      </Dropdown>
    );
  }

  render() {
    const { mobileHeader, user } = this.props;

    return (
      <div
        className={`application-header ${
          this.props.headerTransparent ? "transparent" : ""
        } ${this.props.viewportSizeSelector === "xs" ? "mobile" : ""} `}
      >
        {this.renderDropdown()}
        <ContactsButton />
        <NotificationsButton />
        {this.renderResubmissionIcon()}
        {this.renderAppDrawer()}
        {this.renderHomeButton()}
        {this.props.headerShowAppDrawer ? (
          this.props.viewportSizeSelector === "xs" ? (
            <>
              <div className="fill" />
              {mobileHeader ? (
                <div className="mobile-header">
                  {mobileHeader.icon ? (
                    <BfIcon className={"icon"} {...mobileHeader.icon} />
                  ) : null}
                  {
                    <span style={{ paddingLeft: mobileHeader.icon ? 0 : 10 }}>
                      {mobileHeader.textKey}
                    </span>
                  }
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className="fill" />
              <div
                className={`appDrawerButton ${
                  this.props.initializingApp ? "initializing" : ""
                }`}
              >
                <img
                  className={"logo"}
                  src={this.props.activeApplication.logo.navbar}
                />
                {this.props.activeApplication &&
                this.props.activeApplication.logo.navbarDisplayname ? (
                  <div className="applicationName">
                    {this.props.activeApplication.displayName}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )
        ) : null}
        {this.props.showHeaderLeftNode ? this.props.showHeaderLeftNode : null}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.global.user,
  headerTransparent:
    state.uiConfig.general[DefaultUIConfigs.HEADER_TRANSPARENT],
  headerShowAppDrawer:
    state.uiConfig.general[DefaultUIConfigs.HEADER_APP_DRAWER],
  activeApplication: state.uiConfig.activeApplication,
  viewportSizeSelector:
    state.uiConfig.general[DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR],
  showSidenavDrawer:
    state.uiConfig.general[DefaultUIConfigs.VIEWPORT_SIZE_SELECTOR],
  showHeaderLeftNode:
    state.uiConfig.general[DefaultUIConfigs.SHOW_HEADER_LEFT_NODE],
  initializingApp:
    state.uiConfig.general[DefaultUIConfigs.APPLICATION_IS_INITIALIZING],
  mobileHeader: state.uiConfig.general[DefaultUIConfigs.MOBILE_HEADER],
  availableLanguages:
    state.uiConfig.general[DefaultUIConfigs.AVAILABLE_LANGUAGES],
  selectedLanguage: state.uiConfig.general[DefaultUIConfigs.SELECTED_LANGUAGE],
});

export default connect(mapStateToProps, { logoutUser, setUiConfig })(
  withRouter(ApplicationHeader)
) as any;
// withRouter()withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ApplicationHeader);

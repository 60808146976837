import { useEffect } from "react";
import { useToaster } from "rsuite";
import DataBus from "../../../services/DataBus";
import { DataBusSubKeys } from "../../../utils/Constants";

const ToastManager = () => {
  const toaster = useToaster();
  useEffect(() => {
    DataBus.subscribe(
      DataBusSubKeys.TOAST_CREATE,
      ({ component, placement }: any) =>
        toaster.push(component, {
          placement,
        })
    );
    DataBus.subscribe(DataBusSubKeys.TOAST_REMOVE, ({ id }: any) =>
      toaster.remove(id)
    );
  }, [toaster]);
  return null;
};
export default ToastManager;

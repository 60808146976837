import DataBusDefaults from "../services/DataBusDefaults";

interface BfToastOptions {
  content: string | React.ReactElement;
  type: "info" | "success" | "warning" | "error";
  duration?: number;

  onClose?: () => void;
}

export const BFToast = {
  open: (options: BfToastOptions) => {
    const { type, content } = options;

    DataBusDefaults.toast({
      type: options.type,
      text: options.content,
      duration: options.duration,
    });
    // const toaster = useToaster();
    // toaster.push(<ToastMessage type={type} content={content} />, {
    //   placement: "topCenter",
    // });
    // Alert[type](options.content, options.duration, options.onClose);
  },
};

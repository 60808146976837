import classNames from "classnames";
import { Field } from "react-final-form";
import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import ActivitySelect from "../../../../../modules/activity-components/ActivitySelect";
import EZGroup from "../../../../../modules/ez-form/form-group/ez-form/EZGroup";
import InvoiceService from "../../InvoiceService";
import { RAInvoice } from "../../RAInterfaces";
import { InvoiceBaseFormModel } from "../InvoiceForm/RAInvoiceFormModel";
import "./RARelationsView.scss";

interface RARelationsViewProps {
  invoice: RAInvoice;
  disableEdit?: boolean;
}
const RARelationsView = (props: RARelationsViewProps) => {
  return (
    <div className={classNames(`ra-relations-view`)}>
      <EZGroup
        value={{
          activity: props.invoice.data.activity || [],
        }}
        onSubmit={async (value) => {
          await InvoiceService.updateInvoice(props.invoice._id, {
            ...InvoiceService.getInitialDataForInvoice(props.invoice),
            activity: value.activity || [],
          } as InvoiceBaseFormModel);
        }}
      >
        {(renderProps, isReadOnly, state, setState) => (
          <Field name="activity">
            {({ input, meta }) => (
              <>
                {(input.value || []).length === 0 && (
                  <div className={`no-relations`}>
                    {i18n.t(
                      "ra:RelationsView.noRelations",
                      "Keine Aktivität zugeordnet"
                    )}
                  </div>
                )}

                <ActivitySelect
                  disabled={props.disableEdit}
                  showJump
                  multiple={true}
                  block
                  appearance="bf"
                  businessUnits={[props.invoice?.data.type]}
                  identifier={"ra-relations-view-select"}
                  value={input.value || []}
                  onChange={input.onChange}
                  cleanable
                  assets={[
                    AssetTypes.Activity.DamageClaim,
                    AssetTypes.Activity.Project,
                    AssetTypes.ActivityRelated.ProjectDeprecated,
                  ]}
                />
              </>
            )}
          </Field>
        )}
      </EZGroup>
    </div>
  );
};

export default RARelationsView;

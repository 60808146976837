import { Notification } from "../../../model/notifications/Notification";

export const REGISTER_NOTIFICATION = `REGISTER_NOTIFICATION`;
export const TOGGLE_EXPAND_STATE = `TOGGLE_EXPAND_STATE`;
export const REMOVE_NOTIFICATION = `REMOVE_NOTIFICATION`;
export const SET_ALL_NOTIFICATION = `SET_ALL_NOTIFICATION`;
export const CLEAR_NOTIFICATIONS = `CLEAR_NOTIFICATIONS`;

export const SET_SEEN_NOTIFICATION = `SET_SEEN_NOTIFICATION`;
export const SEEN_ALL_NOTIFICATIONS = `SEEN_ALL_NOTIFICATIONS`;

interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION;
  id: string;
}

interface SetSeenNotificationAction {
  type: typeof SET_SEEN_NOTIFICATION;
  id: string;
  seen: boolean;
}
interface ClearNotificationsAction {
  type: typeof CLEAR_NOTIFICATIONS;
}
interface SeenAllNotificationsAction {
  type: typeof SEEN_ALL_NOTIFICATIONS;
}

interface RegisterNotificationAction {
  type: typeof REGISTER_NOTIFICATION;
  notification: Notification;
}
interface SetAllNotificationAction {
  type: typeof SET_ALL_NOTIFICATION;
  notifications: Notification[];
}

interface NotificationToggleExpandAction {
  type: typeof TOGGLE_EXPAND_STATE;
  id: string;
  toggled: boolean;
}

export type NotificationsAction =
  | SetSeenNotificationAction
  | ClearNotificationsAction
  | SeenAllNotificationsAction
  | RemoveNotificationAction
  | SetAllNotificationAction
  | RegisterNotificationAction
  | NotificationToggleExpandAction;

// setTimeout(() => {
//   DataBus.subscribeReduxActions([SET_USER_DATA]);
// });

import classNames from "classnames";
import { useRef, useState } from "react";
import DebugDataComponent from "../../../debug/DebugDataComponent";
import i18n from "../../../i18n";
import ObjectIdService from "../../../utils/ObjectIdUtils";
import BFDateInput from "../../abstract-ui/forms/date/BFDateInput";
import BFInput from "../../abstract-ui/forms/input/BFInput";
import BFButton from "../../abstract-ui/general/Button/BFButton";
import BfIcon from "../../abstract-ui/icon/BfIcon";
import {
  differenceOfDates,
  evaluateEndDateOfTask,
} from "../BFGanttChart.utils";
import { Task } from "../lib";
// import './BFGantChartRow.scss';

interface BFGantChartRowProps {
  rowHeight: number;
  rowWidth: string;

  currentDraggingTask?: Task;
  onDragStart: (task: Task) => void;
  onDragEnd: () => void;
  onDrop: (taskId: string, position: "child" | "below" | "above") => void;
  onDragOver: (task: Task) => void;
  isDraggingOver: boolean;

  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;

  onTaskUpdate: (task: Task, newIndex?: number) => void;
  onTaskCreate: (task: Task, indexToPush: number) => void;
  onTaskDelete: (taskId: string) => void;

  task: Task;
  parentIds: string[];
  index: number;
  depth: number;
  readonly?: boolean;

  includeSaturday?: boolean;
  includeSunday?: boolean;
  fixedRow?: boolean;
  additionalColumn?: {
    renderHeader: () => React.ReactNode;
    renderColumn: (task: Task) => React.ReactNode;
  };
}
const BFGanttChartRow = (props: BFGantChartRowProps) => {
  const ref = useRef<HTMLDivElement>(null);
  let expanderSymbol = "";
  if (props.task.hideChildren === false) {
    expanderSymbol = "▼";
  } else if (props.task.hideChildren === true) {
    expanderSymbol = "▶";
  }

  return (
    <div
      className={classNames(`bf-gantt-chart-row`, {
        readonly: props.readonly,
        draggingOver: props.isDraggingOver,
        otherDragging:
          props.currentDraggingTask &&
          props.currentDraggingTask.id !== props.task.id &&
          !props.parentIds.includes(props.currentDraggingTask.id),
        selfDragging:
          props.currentDraggingTask &&
          (props.currentDraggingTask.id === props.task.id ||
            props.parentIds.includes(props.currentDraggingTask.id)),
      })}
      ref={ref}
      onDragEnter={() => {
        props.onDragOver(props.task);
      }}
      style={{ height: props.rowHeight }}
      // className={styles.taskListTableRow}
      // style={{ height: rowHeight }}
      key={`${props.task.id}row`}
    >
      <DebugDataComponent data={props.task} />
      <div className={`name-wrapper col`}>
        {!props.readonly && props.onTaskCreate && (
          <>
            <div className={`hover-action`}>
              <BFButton
                appearance="transparent-dark"
                size="xs"
                onClick={() => {
                  props.onTaskCreate(
                    {
                      ...props.task,
                      id: ObjectIdService.createNewObjectIdInstance().toString(),
                      name: "",
                      progress: 0,
                    },
                    props.index + 1
                  );
                }}
              >
                {i18n.t("GanttChart.addTask", "Weitere Aufgabe")}
              </BFButton>
              <BFButton
                appearance="transparent-dark"
                size="xs"
                disabled={props.fixedRow && props.task.type === "task"}
                tooltip={
                  props.fixedRow && props.task.type === "task"
                    ? {
                        tooltip: i18n.t(
                          "GanttChart.notConvertableIntoGroup",
                          "Eine bereits genehmigte Aufgabe kann nicht in eine Gruppe umgewandelt werden"
                        ),
                      }
                    : undefined
                }
                onClick={() => {
                  props.onTaskCreate(
                    {
                      ...props.task,
                      id: ObjectIdService.createNewObjectIdInstance().toString(),
                      name: "",
                      progress: 0,
                      project: props.task.id,
                    },
                    props.index + 1
                  );
                }}
              >
                {i18n.t("GanttChart.addSubtask", "Neue Unteraufgabe")}
              </BFButton>

              <BFButton
                disabled={props.fixedRow}
                tooltip={
                  props.fixedRow
                    ? {
                        tooltip: i18n.t(
                          "GanttChart.notRemovable",
                          "Eine bereits genehmigte Aufgabe kann nicht entfernt werden"
                        ),
                      }
                    : undefined
                }
                className={`delete`}
                appearance="transparent-dark"
                size="xs"
                onClick={() => {
                  props.onTaskDelete(props.task.id);
                }}
              >
                {i18n.t("GanttChart.removeTask", "Aufgabe entfernen")}
              </BFButton>
            </div>
          </>
        )}
        {props.depth > 0 && (
          <div
            className={`depth-line`}
            style={{ marginLeft: props.depth * 25 }}
          />
        )}
        {!props.readonly && (
          <div
            className={`reorder-knob`}
            onDragStart={(ev) => {
              // ev.dataTransfer.setData("text/plain", props.task.id);
              props.onDragStart(props.task);
            }}
            draggable="true"
            onDragEnd={(e) => {
              props.onDragEnd();
            }}
            // onDragEnd={() => props.onDragEnd(props.task)}
            // onDragExit={() => props.onDragEnd(props.task)}
            // onDragLeave={() => props.onDragEnd(props.task)}
          >
            <BfIcon type="light" data="select-drag-reorder-dots" size="xs" />
          </div>
        )}
        <div
          className={
            expanderSymbol
              ? "task-list-expander" //styles.taskListExpander
              : "task-list-empty-expander" //styles.taskListEmptyExpander
          }
          onClick={() => props.onExpanderClick(props.task)}
        >
          {expanderSymbol}
        </div>
        <div className={`name`}>
          {props.readonly ? (
            <span>{props.task.name}</span>
          ) : (
            <BFInput
              useTempValue
              readonly={props.readonly}
              value={props.task.name}
              onChange={(name: string) =>
                props.onTaskUpdate({ ...props.task, name })
              }
            />
          )}
        </div>
      </div>
      <div className={`from col`}>
        <BFDateInput
          useTempValue
          plaintext={props.readonly || props.task.type === "project"}
          format="dd.MM.yyyy"
          value={props.task.start}
          onChange={(value) => {
            const diffDaysSoFar = differenceOfDates(
              props.task.start,
              props.task.end,
              props.includeSaturday,
              props.includeSunday
            );
            const newEnd = evaluateEndDateOfTask(
              value,
              diffDaysSoFar,
              props.includeSaturday,
              props.includeSunday
            );
            props.onTaskUpdate({ ...props.task, start: value, end: newEnd });
          }}
        />
      </div>
      <div className={`days col`}>
        {props.readonly || props.task.type === "project" ? (
          <div className={`plaintext`}>
            {differenceOfDates(props.task.start, props.task.end)}
          </div>
        ) : (
          <BFInput
            type="number"
            step={1}
            value={differenceOfDates(
              props.task.start,
              props.task.end,
              props.includeSaturday,
              props.includeSunday
            )}
            onChange={(value: number) => {
              const endDate = evaluateEndDateOfTask(
                props.task.start,
                value || 1,
                props.includeSaturday,
                props.includeSunday
              );
              props.onTaskUpdate({ ...props.task, end: endDate });
            }}
          />
        )}
      </div>
      <div className={`to col`}>
        <BFDateInput
          useTempValue
          plaintext={props.readonly || props.task.type === "project"}
          format="dd.MM.yyyy"
          value={props.task.end}
          onChange={(value) =>
            props.onTaskUpdate({ ...props.task, end: value })
          }
        />
      </div>
      {props.additionalColumn && (
        <div className={`additional col`}>
          {props.additionalColumn.renderColumn(props.task)}
        </div>
      )}

      <Dropzone
        taskId={props.task.id}
        type="above"
        currentDraggingTask={props.currentDraggingTask}
        onDrop={props.onDrop}
      />
      <Dropzone
        taskId={props.task.id}
        type="below"
        currentDraggingTask={props.currentDraggingTask}
        onDrop={props.onDrop}
      />
      <Dropzone
        taskId={props.task.id}
        type="child"
        currentDraggingTask={props.currentDraggingTask}
        onDrop={props.onDrop}
        disabled={props.fixedRow && props.task.type === "task"}
      />
    </div>
  );
};
export default BFGanttChartRow;

const Dropzone = (props: {
  type: "above" | "below" | "child";
  taskId: string;
  currentDraggingTask?: Task;
  onDrop: (taskId: string, position: "child" | "below" | "above") => void;
  disabled?: boolean;
}) => {
  const [dragOver, setDragOver] = useState(false);
  return (
    <div
      className={classNames(`droppable`, `dropzone-${props.type}`, {
        dragging: dragOver,
        disabled: props.disabled,
      })}
      onDragEnter={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        setDragOver(true);
      }}
      onDragLeave={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        setDragOver(false);
      }}
      onDragOver={(ev) => {
        if (!props.disabled) {
          ev.preventDefault();
        }
      }}
      onDrop={
        !props.disabled
          ? (ev) => {
              if (props.currentDraggingTask) {
                props.onDrop(props.currentDraggingTask.id, props.type);
              }
            }
          : undefined
      }
      // onDrop={(ev) => {
      //   if (props.currentDraggingTask) {
      //     props.onDrop(props.currentDraggingTask, props.type);
      //   }
      //   ev.stopPropagation();
      //   ev.preventDefault();
      // }}
    >
      <div className={`text`}>
        {props.type === "above" &&
          i18n.t("BFGanttChart.DroppableAbove", "Darüber")}
        {props.type === "below" &&
          i18n.t("BFGanttChart.DroppableBelow", "Darunter")}
        {props.type === "child" &&
          i18n.t("BFGanttChart.DroppableChild", "Als Unteraufgabe")}
      </div>
    </div>
  );
};

// export default React.memo(BFGanttChartRow, (prev, next) => {
//   // fix memo issue with functions - we should consider some general solution for this issue
//   return _.isEqualWith(prev, next, (a, b) => {
//     if (typeof a === "function" && typeof b === "function") {
//       return true;
//     }
//     return undefined;
//   });
// });

import { OAFloorPlanBase } from "@/apps/tatar/objectsApp/types/object.interface";
import DataBusDefaults from "@/services/DataBusDefaults";
import i18n from "@/i18n";
import FloorPlanService from "@/services/FloorPlanService";

export const fetchImageUrl = async (selected: OAFloorPlanBase) => {
  try {
    return await FloorPlanService.fetchImageURL(selected);
  } catch (error) {
    DataBusDefaults.toast({
      type: "error",
      text: i18n.t(
        "cb:FloorPlan.messages.errors.failedToLoadFloorPlanImage",
        "Das Laden des Grundrissbildes ist fehlgeschlagen"
      ),
    });
  }
};

import classNames from "classnames";
import React, { useEffect } from "react";
import { Loader } from "rsuite";
import i18n from "../../i18n";
import BFPlaceholder from "../../modules/abstract-ui/general/Placeholder/BFPlaceholder";
// import './PromiseLoader.scss';

interface PromiseLoaderProps {
  inline?: boolean;
  param?: any;
  promise: (param?: any) => Promise<any>;

  renderLoading?: () => JSX.Element;
  renderError?: (err: any) => JSX.Element;

  onDataFetched?: (data: any) => void;
  render: (data: any, reload: (silent?: boolean) => void) => JSX.Element;

  placeholderProps?: {
    width: number | string;
  };
}
const PromiseLoader = (props: PromiseLoaderProps) => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>(null);

  useEffect(() => {
    loadData();
  }, [props.param]);

  const loadData = () => {
    setData(null);
    setError(null);
    setLoading(true);
    props
      .promise(props.param)
      .then((data) => {
        props.onDataFetched?.(data);
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  const renderError = (errorMessage: string) => {
    if (props.renderError) {
      return props.renderError(error);
    } else {
      return (
        <div className="promise-loader error">
          {i18n.t("Global.Labels.defaultError")}
        </div>
      );
    }
  };

  if (data) {
    return props.render(data, () => loadData());
  }
  if (error) {
    return renderError(error);
  }
  if (props.renderLoading) {
    return props.renderLoading();
  } else {
    if (props.placeholderProps) {
      return (
        <BFPlaceholder loading width={props.placeholderProps.width}>
          -
        </BFPlaceholder>
      );
    } else {
      return (
        <div
          className={classNames("promise-loader loading", {
            inline: props.inline,
          })}
        >
          <Loader />
        </div>
      );
    }
  }
};

export default PromiseLoader;

import CategoryStruct from "@/redux/actions/struct/implemented/CategoryStruct";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import { Field } from "react-final-form";
import { Nav } from "rsuite";
import FormFieldValues from "../../../../components/Form/Fields/FormFieldValues";
import FormStruct from "../../../../components/Form/FormStruct/FormStruct";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import Log from "../../../../debug/Log";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import BFDropzone from "../../../../modules/abstract-ui/dropzone/BFDropzone";
import BFInput from "../../../../modules/abstract-ui/forms/input/BFInput";
import BfRadio from "../../../../modules/abstract-ui/forms/radio/BfRadio";
import BFButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import { DropdownItem } from "../../../../modules/abstract-ui/general/Dropdown/BFDropdownContent";
import BfIcon from "../../../../modules/abstract-ui/icon/BfIcon";
import { DefaultIcons } from "../../../../modules/abstract-ui/icon/DefaultIcons";
import { openCommentsModal } from "../../../../modules/comments-module/CommentsModal";
import DataBus from "../../../../services/DataBus";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import LanguageService from "../../../../services/LanguageService";
import ArrayUtils from "../../../../utils/ArrayUtils";
import { DataBusSubKeys } from "../../../../utils/Constants";
import { hasValue } from "../../../../utils/Helpers";
import CashBudgetUtils from "../CashBudgetUtils";
import { AssetCashBudgetEntry } from "../model/CashBudgetEntry";
import BookingService from "../services/BookingService";
import { splitBookingWithFile } from "../services/CashBudgetActions";
import CashBudgetService from "../services/CashBudgetService";
import "./CashBudgetBookingFooter.scss";
import { CASHBUDGET_CATEGORY_DETAIL_TABLE } from "./CashBudgetCategoryDetailEntry";
import { DetailEntryType } from "./CashBudgetCategoryDetailEntryTypes";

enum Functionality {
  DELETION,
  COMMENT,
  CATEGORY_CHANGE,
  OBJECT_CHANGE,
  UPLOAD,
  SPLIT,
  SEARCH_INVOICE,
}

function getFunctionalityForType(type: string): Functionality[] {
  if (type === undefined || type === null) {
    return [];
  }
  if (type === DetailEntryType.IMPORT) {
    return [
      Functionality.SEARCH_INVOICE,
      Functionality.SPLIT,
      Functionality.CATEGORY_CHANGE,
      Functionality.COMMENT,
      Functionality.UPLOAD,
    ];
  }
  if (type === DetailEntryType.COMPARISON_FIX) {
    return [Functionality.COMMENT];
  }
  if (type === DetailEntryType.COMPARISON_ADJUSTMENTS) {
    return [Functionality.DELETION, Functionality.COMMENT];
  }
  if (type === DetailEntryType.MANUAL) {
    return [
      Functionality.DELETION,
      Functionality.COMMENT,
      Functionality.CATEGORY_CHANGE,
      Functionality.OBJECT_CHANGE,
    ];
  }
  if (type === null || type === undefined || type === DetailEntryType.IMPORT) {
    return [
      Functionality.DELETION,
      Functionality.COMMENT,
      Functionality.CATEGORY_CHANGE,
      Functionality.OBJECT_CHANGE,
      Functionality.UPLOAD,
    ];
  }
  return [];
}

interface CashBudgetBookingFooterProps {
  booking: AssetCashBudgetEntry;
  expanded?: boolean;

  uploading?: boolean;

  onUpload?: () => void;
  onConnect?: () => void;

  disableSplit?: boolean;
  disableComments?: boolean;
  disableCategoryChange?: boolean;
  disableDelete?: boolean;
  disableObjectChange?: boolean;
}
const CashBudgetBookingFooter = (props: CashBudgetBookingFooterProps) => {
  const [overlayId] = useState(nanoid());
  const openFileDialogSepaXML = useRef<any>();
  const expandedValue = hasValue(props.expanded) ? props.expanded : true;

  const functionalities = getFunctionalityForType(props.booking.data.type);

  const allowedSplit =
    !props.disableSplit && functionalities.includes(Functionality.SPLIT);
  const allowedConnect =
    props.onConnect && functionalities.includes(Functionality.SEARCH_INVOICE);
  const allowedComments =
    !props.disableComments && functionalities.includes(Functionality.COMMENT);
  const allowedCategoryChange =
    !props.disableCategoryChange &&
    functionalities.includes(Functionality.CATEGORY_CHANGE);
  const allowedObjectChange =
    !props.disableObjectChange &&
    functionalities.includes(Functionality.OBJECT_CHANGE);
  const allowedDelete =
    !props.disableDelete && functionalities.includes(Functionality.DELETION);
  const allowedUpload =
    props.onUpload && functionalities.includes(Functionality.UPLOAD);

  const showDropdown = allowedDelete || allowedSplit;
  return (
    <div className={classNames(`cb-booking-footer`)}>
      <div className={`actions-left`}>
        {showDropdown && (
          <BFDropdown
            label={i18n.t("CashBudgetBookingFooter.splitBooking", "Aufteilen")}
            items={[
              ...(allowedSplit
                ? ([
                    {
                      type: "button",
                      text: i18n.t(
                        "CashBudgetBookingFooter.splitBookingManual",
                        "Buchung manuell teilen"
                      ),
                      onSelect: () => {
                        DataBus.emit(DataBusSubKeys.ROUTE, {
                          route: `__splitBooking/${props.booking._id}`,
                          append: true,
                        });
                      },
                    },
                    {
                      type: "button",
                      text: i18n.t(
                        "CashBudgetBookingFooter.splitBookingBySepaXML",
                        "Teilen mit Zahlungsdatei"
                      ),
                      onSelect: () => openFileDialogSepaXML.current?.(),
                    },
                  ] as DropdownItem[])
                : []),
              ...(allowedDelete
                ? ([
                    {
                      type: "button",
                      text: i18n.t(
                        "CashBudgetBookingFooter.deleteBooking",
                        "Buchung entfernen"
                      ),
                      onSelect: () => {
                        BookingService.deleteBooking(props.booking._id)
                          .then(() => {
                            DataBusDefaults.reload({
                              identifiers: [CASHBUDGET_CATEGORY_DETAIL_TABLE],
                            });
                          })
                          .catch((err) => {
                            DataBusDefaults.toast({
                              type: "error",
                              text: i18n.t(
                                "CashBudgetBookingFooter.deleteBookingError",
                                "Buchung konnte nicht entfernt werden"
                              ),
                            });
                          });
                      },
                    },
                  ] as DropdownItem[])
                : []),
            ]}
            renderToggle={(props) => (
              <BFButton {...props} appearance="clear-on-white">
                {" "}
                <BfIcon {...DefaultIcons.MORE} size="xs" />
              </BFButton>
            )}
          />

          // <BFButton appearance="clear-on-white">
          //   <BfIcon {...DefaultIcons.MORE} size="xs" />
          // </BFButton>
        )}
        {allowedUpload && (
          <BFButton
            appearance="clear-on-white"
            icon={{
              type: "light",
              data: "attachment",
              size: "xs",
            }}
            loading={props.uploading}
            text={i18n.t(
              "CashBudgetBookingFooter.uploadAttachment",
              "Anhang hochladen"
            )}
            onClick={props.onUpload}
          />
          //   <BfIcon type="light" data="attachment" size="xs" />
          //   Anhang hochladen
          // </BFButton>
        )}
        {allowedConnect && (
          <BFButton
            appearance="clear-on-white"
            icon={{
              type: "light",
              data: "hyperlink",
              size: "xs",
            }}
            text={i18n.t("CashBudgetBookingFooter.linkAsset", "Verknüpfen")}
            onClick={() => {
              props.onConnect();
            }}
          />
        )}
      </div>

      {allowedSplit && (
        <BFDropzone
          multipe={false}
          style={{ display: "none" }}
          onDrop={(acceptedFiles, rejectedFiles, event) => {
            if (acceptedFiles.length === 1) {
              ModalManager.confirm({
                title: i18n.t(
                  "cb:CashBudgetBookingFooter.confirmSplitBookingByFile",
                  "Buchung aufteilen"
                ),
                message: i18n.t(
                  "cb:CashBudgetBookingFooter.confirmSplitBookingByFileMessage",
                  "Wollen Sie die Buchung anhand der hochgeladenen XML-Datei aufteilen?"
                ),
                confirmButtonText: i18n.t(
                  "cb:CashBudgetBookingFooter.confirmSplitBookingByFileConfirm",
                  "Aufteilen"
                ),
                onConfirm: () => {
                  splitBookingWithFile(
                    props.booking._id,
                    acceptedFiles[0]
                  ).then(() => {
                    CashBudgetService.clearFlexCaches();
                    DataBusDefaults.reload({
                      identifiers: [CASHBUDGET_CATEGORY_DETAIL_TABLE],
                    });
                  });
                },
              });
            }

            if (rejectedFiles.length > 0) {
              rejectedFiles.forEach((file) => {
                Log.info(
                  `Rejected file`,
                  file.file.name,
                  file.file.type,
                  file.file.size,
                  file.errors.map((e) => e.message).join(" & ")
                );
              });
            }
          }}
          render={(open) => {
            openFileDialogSepaXML.current = open;
            return null;
          }}
          accept={{
            "application/xml": [],
            "text/xml": [".cct", ".xml"],
            "": [".cct", ".xml"],
          }}
        />
      )}
      <div className={`fill`} />
      <div className={`actions-right`}>
        {allowedComments && (
          <BFButton
            badge={
              (props.booking.communication?.numComments || 0) > 0
                ? {
                    color: "blue",
                    content: props.booking.communication?.numComments,
                  }
                : undefined
            }
            badgeOnContent
            appearance="clear-on-white"
            icon={{
              type: "light",
              data: "conversation-chat-2",
              size: "xs",
            }}
            onClick={() => {
              openCommentsModal({
                assetId: props.booking._id,
                assetType: AssetTypes.CashBudget.Booking,
                identifier: `CB_${props.booking._id}_comments`,
                type: props.booking.data.unit,
                allowMailUpload: false,
                disableEmailCommunication: true,
                hideResubmissionActionForMail: true,
                mode: "both",
              });
            }}
          />
        )}
        {allowedCategoryChange && (
          <BFDropdown
            identifier={overlayId}
            asOverlay
            label={i18n.t(
              "CashBudgetBookingFooter.changeCategory",
              "Kategorie wechseln"
            )}
            placement="bottomRight"
            overlayWidth={350}
            items={[
              {
                type: "panel",
                render: () => (
                  <CategoryChangeFooter
                    booking={props.booking}
                    onClose={() => {
                      DataBus.emit("WHISPER", {
                        identifier: overlayId,
                        type: "CLOSE",
                      });
                    }}
                  />
                ),
              },
            ]}
            renderToggle={(props) => (
              <BFButton {...props} appearance="clear-on-white">
                {i18n.t(
                  "CashBudgetBookingFooter.changeCategory",
                  "Kategorie wechseln"
                )}
              </BFButton>
            )}
          />

          // <BFButton
          //   appearance="clear-on-white"
          //   // icon={{
          //   //   type: "light",
          //   //   data: "conversation-chat-2",
          //   //   size: "xs",
          //   // }}
          //   text={i18n.t(
          //     "CashBudgetBookingFooter.changeCategory",
          //     "Kategorie wechseln"
          //   )}
          // />
        )}
      </div>
    </div>
  );
};

export default CashBudgetBookingFooter;

const CategoryChangeFooter = (props: {
  onClose: () => void;
  onSubmit?: (values: any) => void;
  booking: AssetCashBudgetEntry;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const currentCategory = CategoryStruct.getCategory(
    props.booking.data.category
  );

  const expensesOptions = CashBudgetUtils.getAllCategories()
    .filter((e) => e.data.type === props.booking.data.unit)
    .filter((e) => e.data.kind === "expense")
    .filter((e) => (e.data.tags || []).indexOf("group") === -1)
    .map((e) => ({
      value: e._id,
      label: LanguageService.translateLabel(e.data.displayName),
    }));
  // .filter((e) => e._id !== currentCategory._id);
  const incomeOptions = CashBudgetUtils.getAllCategories()
    .filter((e) => e.data.type === props.booking.data.unit)
    .filter((e) => e.data.kind === "income")
    .filter((e) => (e.data.tags || []).indexOf("group") === -1)
    .map((e) => ({
      value: e._id,
      label: LanguageService.translateLabel(e.data.displayName),
    }));
  // .filter((e) => e._id !== currentCategory._id);

  useEffect(() => {
    ref.current?.querySelector(".category-entry.selected")?.scrollIntoView({
      block: "center",
    });
  }, []);
  return (
    <FormStruct<any>
      noPadding
      className={`cb-category-change-footer-overlay`}
      // onAbort={() => props.onCancel()}
      submitText={i18n.t(
        "CashBudgetBookingFooter.changeCategory",
        "Kategorie wechseln"
      )}
      initialValues={{
        kind: currentCategory.data.kind,
        category: currentCategory._id,
        searchInput: "",
      }}
      onSubmit={async (values) => {
        const result = await BookingService.submitCategory(
          props.booking._id,
          values.category
        );
        props.onSubmit?.(result);
        props.onClose();
      }}
      render={(formProps) => (
        <>
          <Field name="kind">
            {(kind) => (
              <>
                <div className={`search`}>
                  <Field name="searchInput">
                    {({ input }) => (
                      <BFInput
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        focusOnMount
                        placeholder={i18n.t("Global.Labels.search", "Suchen")}
                        prefix={<BfIcon {...DefaultIcons.SEARCH} size="xxs" />}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <Nav
                  appearance="subtle"
                  activeKey={kind.input.value}
                  onSelect={(key) => kind.input.onChange(key)}
                >
                  <Nav.Item eventKey="income">
                    {i18n.t("cb:CategoryChangeFooter.income", "Einnahme")}
                  </Nav.Item>
                  <Nav.Item eventKey="expense">
                    {i18n.t("cb:CategoryChangeFooter.expense", "Ausgaben")}
                  </Nav.Item>
                </Nav>

                <FormFieldValues names={["searchInput"]}>
                  {([searchInput]) => {
                    const collection =
                      kind.input.value === "income"
                        ? incomeOptions
                        : expensesOptions;
                    const options = !searchInput?.trim()
                      ? collection
                      : ArrayUtils.fuzzyFilter(
                          searchInput?.trim(),
                          collection,
                          {
                            threshold: 0.3,
                            keys: ["label", "value"],
                          }
                        ).map((e) => e.item);
                    return (
                      <Field name="category">
                        {(categoryInput) => {
                          return (
                            <div className={`categories`} ref={ref}>
                              {options.length === 0 && (
                                <div className={`empty`}>
                                  {i18n.t(
                                    "cb:CategoryChangeFooter.noResults",
                                    "Keine Ergebnisse"
                                  )}
                                </div>
                              )}
                              {options.map((category) => (
                                <div
                                  className={`category-entry ${
                                    category.value === categoryInput.input.value
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  <BfRadio
                                    checked={
                                      categoryInput.input.value ===
                                      category.value
                                    }
                                    onChange={(_, checked) =>
                                      categoryInput.input.onChange(
                                        category.value
                                      )
                                    }
                                  >
                                    {category.label}
                                  </BfRadio>
                                </div>
                              ))}
                            </div>
                          );
                        }}
                      </Field>
                    );
                  }}
                </FormFieldValues>
              </>
            )}
          </Field>
        </>
      )}
    />
  );
};

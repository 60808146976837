import classNames from "classnames";
import { Rate } from "rsuite";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import Userlabel from "../../../../../../components/AvatarComponent/Userlabel";
import MetaRow from "../../../../../../components/MetaRow/MetaRow";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import { PlacementAll } from "../../../../../../modules/abstract-ui/common/Placements";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFOverlay from "../../../../../../modules/abstract-ui/general/whisper/BFOverlay";
import EZTextfield from "../../../../../../modules/ez-form/form-elements/ez-textfield/EZTextfield";
import DataBusDefaults from "../../../../../../services/DataBusDefaults";
import { MatchQuery } from "../../../../../../services/DataService";
import StringUtils from "../../../../../../utils/StringUtils";
import { useActivityConstants } from "../../../ActivityHooks";
import { APActivity, APActivityRating } from "../../../ActivityInterfaces";
import APRatingEditDialog from "./APRatingEditDialog";
import "./APRatingViewButton.scss";

interface Props {
  readonly?: boolean;
  activity: APActivity;

  renderedInList?: boolean;
  placement?: PlacementAll;
}
const APRatingViewButton = (props: Props) => {
  const constants = useActivityConstants();
  const onOpenRatingDialog = (rating: APActivityRating, reload: () => void) => {
    ModalManager.show({
      backdrop: "static",
      noPadding: true,
      content: (states, setStates, closeModal) => (
        <APRatingEditDialog
          rating={rating}
          activity={props.activity}
          onClose={closeModal}
          onError={(error) => {
            DataBusDefaults.toast({
              type: "error",
              text: i18n.t(
                "apTemplate:Rating.ErrorRating",
                "Fehler beim Speichern der Bewertung"
              ),
            });
          }}
          onSuccess={() => {
            DataBusDefaults.toast({
              type: "success",
              text: i18n.t(
                "apTemplate:Rating.SuccessRating",
                "Bewertung erfolgreich gespeichert"
              ),
            });
            reload();
          }}
        />
      ),
    });
  };
  return (
    <AssetLoader
      assetType={AssetTypes.ActivityRelated.Rating}
      query={
        {
          type: "and",
          query: [
            {
              type: "op",
              op: "eq",
              name: "data.linkedAsset.assetType",
              value: constants?.assetType,
            },
            {
              type: "op",
              op: "eq",
              name: "data.linkedAsset.assetId",
              value: props.activity._id,
            },
          ],
        } as MatchQuery
      }
      render={(rating: APActivityRating, _selector, reload) => {
        return (
          <div
            className={classNames(`ap-rating-view-button`, {
              "rendered-in-list": props.renderedInList,
            })}
          >
            {!rating &&
              (props.renderedInList ? (
                <div>
                  {i18n.t("apTemplate:Rating.NoRatingYet", "Keine Bewertung")}
                </div>
              ) : (
                <BFButton
                  disabled={props.readonly}
                  icon={{
                    data: "rating-star",
                    size: "xs",
                    type: "light",
                  }}
                  text={
                    props.readonly
                      ? i18n.t(
                          "apTemplate:Rating.NoRatingYet",
                          "Keine Bewertung"
                        )
                      : i18n.t("apTemplate:Rating.createRating", "Bewerten")
                  }
                  onClick={() => {
                    onOpenRatingDialog(rating, reload);
                  }}
                />
              ))}
            {rating && (
              <BFOverlay
                placement={props.placement || "bottomStart"}
                trigger="click"
                zIndex={900}
                speaker={
                  <div>
                    <APRatingOverlay
                      onOpenRatingDialog={() => {
                        onOpenRatingDialog(rating, reload);
                      }}
                      rating={rating}
                      readonly={props.readonly}
                    />
                  </div>
                }
              >
                <div>
                  <BFButton className={`button-rate`}>
                    <Rate
                      className={`button-inline-rating`}
                      readOnly
                      defaultValue={rating.data.rating}
                      size="xs"
                    />
                  </BFButton>
                </div>
              </BFOverlay>
            )}
          </div>
        );
      }}
    />
  );
};

export default APRatingViewButton;

interface APRatingOverlayProps {
  rating: APActivityRating;
  readonly?: boolean;
  onOpenRatingDialog: () => void;
}
const APRatingOverlay = (props: APRatingOverlayProps) => {
  return (
    <div className={`ap-rating-overlay`}>
      {!props.readonly && (
        <div className={`edit-action`}>
          <BFButton
            appearance="link"
            size="xs"
            text={i18n.t("apTemplate:Rating.Edit", "Bearbeiten")}
            onClick={props.onOpenRatingDialog}
          />
        </div>
      )}
      <div className={`rate`}>
        <Rate readOnly defaultValue={props.rating.data.rating} size="sm" />
      </div>
      {props.rating.data.comment && (
        <div className={`comment`}>
          <div className={`label`}>
            {i18n.t("apTemplate:Rating.Comment", "Kommentar")}
          </div>
          <EZTextfield
            onChange={() => {}}
            value={props.rating.data.comment}
            readonly
          />
        </div>
      )}

      {props.rating.data.potentialSavings && (
        <div className={`potential-saving`}>
          <div className={`label`}>
            {i18n.t(
              "apTemplate:Rating.PotentialSavings",
              "Mögliche Einsparung"
            )}
          </div>
          <div className={`value`}>
            {StringUtils.formatCurrency(props.rating.data.potentialSavings)}
          </div>
        </div>
      )}

      {props.rating.data.ratedAssignee && (
        <div className={`rated-assignee`}>
          <div className={`label`}>
            {i18n.t(
              "apTemplate:Rating.RatedAssignee",
              "Bewerteter Sachbearbeiter"
            )}
          </div>
          <div className={`value`}>
            <Userlabel id={props.rating.data.ratedAssignee} />
          </div>
        </div>
      )}

      <MetaRow meta={props.rating.meta} showModified />
    </div>
  );
};

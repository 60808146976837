import Log from "../../debug/Log";
import { TARGET } from "../../utils/Http";

export default abstract class AbstractAppConfigUtils<T> {
  data: T = null;
  cache: { [key: string]: any } = {};
  constants?: { [key: string]: any } = {};

  convertData(data: any) {
    return data as T;
  }

  setConstants(constants: { [key: string]: any }) {
    this.constants = constants;
  }

  setData(data: T | any) {
    Log.info(`Application config (${this.getName()}) setData`, data);
    this.data = this.convertData(data);
    this.cache = {};
  }

  clear() {
    Log.info(`Application config (${this.getName()}) cleared `);
    this.data = null;
    this.cache = {};
  }
  abstract getName(): string;
  abstract getUrl(): string;
  abstract getTarget(): TARGET;

  useCache<T>(methodName: string, args: IArguments, selector: () => T) {
    const key = methodName + JSON.stringify(args);
    if (!this.cache[key]) {
      this.cache[key] = selector();
    }
    return this.cache[key] as T;
  }

  getData(): T {
    if (!this.data) {
      Log.warning(
        `Application config (${this.getName()}) - tried to access config, no data available`
      );

      // should we throw an error?
    }
    return this.data;
  }
}

import moment from "moment";
import i18n from "../i18n";
import IBAN from "../lib-overwrites/iban/iban";

import DateFieldUtils from "@/modules/abstract-ui/forms/datefield/DateFieldUtils";
import Mustache from "mustache";
import sanitizeHtml from "sanitize-html";
import { isNotDefined } from "./Helpers";
import ImageUtils from "./ImageUtils";

class StringUtilsClass {
  private defaultFormat = "de-de";
  private defaultCurrency = "EUR";
  private thousandsSeparator = ".";
  private decimalSeparator = ",";
  private defaultAreaUnit = "m²";

  /**
   * templating with mustache
   * https://www.npmjs.com/package/mustache
   * @param template
   * @param values
   * @returns
   */
  template(template: string, values: Object) {
    return Mustache.render(template, values);
  }

  checkStandardGSMAlphabetAndNewline(text: string) {
    const gsm7bitChars =
      "@£$¥èéùìòÇ\nØøÅåΔ_ΦΓΛΩΠΨΣΘΞ¡ÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà";
    for (let i = 0; i < text.length; i++) {
      const char = text[i];
      if (!gsm7bitChars.includes(char)) {
        return false;
      }
    }
    return true;
  }

  setDefaultCurrency(currency: string = "EUR") {
    this.defaultCurrency = currency;
  }
  setDefaultFormat(format: string = "de-de") {
    this.defaultFormat = format;
    if (format === "en-us") {
      this.thousandsSeparator = ",";
      this.decimalSeparator = ".";
    } else {
      this.thousandsSeparator = ".";
      this.decimalSeparator = ",";
    }
  }
  setDefaultAreaUnit(unit: string = "m²") {
    this.defaultAreaUnit = unit;
  }
  getAreaUnit() {
    return this.defaultAreaUnit;
  }
  getThousandsSeparator() {
    return this.thousandsSeparator;
  }
  getDecimalSeparator() {
    return this.decimalSeparator;
  }
  encodeHTML(str: string) {
    return str
      ?.replace(/&/g, "&amp;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }
  getCurrency() {
    return this.defaultCurrency;
  }
  getFormat() {
    return this.defaultFormat;
  }
  getCurrencyCentLabel(currency: string = this.defaultCurrency) {
    if (currency === "EUR") return "Cent";
    if (currency === "USD") return "Cents";
    if (currency === "GBP") return "Pence";
    if (currency === "JPY") return "Sen";
    if (currency === "CNY") return "Cents";
    if (currency === "RUB") return "Kopek";
    if (currency === "INR") return "Paisa";
    if (currency === "BRL") return "Centavos";
    if (currency === "CAD") return "Cents";
    if (currency === "AUD") return "Cents";
    if (currency === "CHF") return "Cents";
    if (currency === "HKD") return "Cents";
    if (currency === "IDR") return "Sen";
    if (currency === "KRW") return "Won";
    if (currency === "MXN") return "Centavos";
    if (currency === "MYR") return "Kina";
    if (currency === "NZD") return "Cents";
    if (currency === "SGD") return "Cents";
    if (currency === "THB") return "Sen";
    if (currency === "TRY") return "Yukarı";
    if (currency === "VND") return "Đèn";
    if (currency === "ZAR") return "Cents";
    return "";
  }
  getCurrencySymbol(currency: string = this.defaultCurrency) {
    if (currency === "EUR") return "€";
    if (currency === "USD") return "$";
    if (currency === "GBP") return "£";
    if (currency === "JPY") return "¥";
    if (currency === "CNY") return "¥";
    if (currency === "RUB") return "₽";
    if (currency === "INR") return "₹";
    if (currency === "BRL") return "R$";
    if (currency === "CAD") return "C$";
    if (currency === "AUD") return "A$";
    if (currency === "CHF") return "CHF";
    if (currency === "HKD") return "HK$";
    if (currency === "IDR") return "Rp";
    if (currency === "KRW") return "₩";
    if (currency === "MXN") return "Mex$";
    if (currency === "MYR") return "RM";
    if (currency === "NZD") return "NZ$";
    if (currency === "PHP") return "₱";
    if (currency === "SGD") return "S$";
    if (currency === "THB") return "฿";
    if (currency === "TRY") return "₺";
    if (currency === "ZAR") return "R";
    if (currency === "SEK") return "kr";
    if (currency === "NOK") return "kr";
    if (currency === "DKK") return "kr";
    if (currency === "PLN") return "zł";
    if (currency === "HUF") return "Ft";
    if (currency === "CZK") return "Kč";
    if (currency === "ILS") return "₪";
    if (currency === "CLP") return "CLP$";
    if (currency === "PHP") return "₱";
    if (currency === "AED") return "د.إ";
    if (currency === "SAR") return "﷼";
    if (currency === "RON") return "lei";
    if (currency === "NGN") return "₦";
    if (currency === "COP") return "COL$";
    if (currency === "PEN") return "S/";
    if (currency === "VND") return "₫";
    if (currency === "EGP") return "£";
    if (currency === "KWD") return "د.ك";
    if (currency === "QAR") return "﷼";
    if (currency === "BDT") return "৳";
    if (currency === "OMR") return "﷼";
    if (currency === "LKR") return "₨";
    if (currency === "UAH") return "₴";
    if (currency === "NGN") return "₦";
    return currency;
  }

  equalsIgnoreCase(str1: string, str2: string) {
    return str1.toLowerCase() === str2.toLowerCase();
  }

  isValidHTML(htmlString) {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(htmlString, "text/html");

    // Check if the parsing resulted in any errors
    if (parsedDocument.documentElement.nodeName === "parsererror") {
      return false;
    }

    // If the string is not empty and the parsed document has children, it is valid HTML
    return (
      htmlString.trim() !== "" &&
      parsedDocument.documentElement.childNodes.length > 0
    );
  }

  // isHtml(content: string) {
  //   var doc = new DOMParser().parseFromString(content, "text/html");
  //   return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  // }

  sanitizeHtml(html: string, options?: sanitizeHtml.IOptions) {
    return sanitizeHtml(
      html,
      options || {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          span: ["class", "data-denotation-char", "data-id", "data-value"],
          img: ["src", "width", "height", "alt", "title", "style", "class"],
        },
        allowedSchemesByTag: {
          ...sanitizeHtml.defaults.allowedSchemesByTag,
          img: ["data"],
        },
      }
    );
  }
  extractDateOfStringWhole(term: string) {
    const checkFormats: {
      format: string;
      type: "year" | "month" | "week" | "day";
    }[] = [
      { format: i18n.t("Formats.dateFormat"), type: "day" },
      { format: i18n.t("Formats.monthFormat"), type: "month" },
      { format: i18n.t("Formats.dateFormatReadable"), type: "day" },
      { format: "DD MMMM", type: "day" },
      { format: "DD MMM", type: "day" },
      { format: "DD MM", type: "day" },
      { format: "DD. MMMM", type: "day" },
      { format: "DD. MMM", type: "day" },
      { format: "DD. MM", type: "day" },
      { format: "MM YYYY", type: "month" },
      { format: "MMM YYYY", type: "month" },
      { format: "MMMM YYYY", type: "month" },
      { format: "MM YY", type: "month" },
      { format: "MMM YY", type: "month" },
      { format: "MMMM YY", type: "month" },
      { format: "MMMM", type: "month" },
      { format: "MMM", type: "month" },
      { format: "MM", type: "month" },
      //some iso formats
      { format: "YYYY-MM-DD", type: "day" },
      { format: "YYYYMMDD", type: "day" },
      { format: "YYYY-MM", type: "month" },
      { format: "YYYYMM", type: "month" },
      { format: "YYYY-Ww", type: "week" },
      { format: "YYYY-Www", type: "week" },
      { format: "YYYYWw", type: "week" },
      { format: "YYYYWww", type: "week" },
      { format: "YY", type: "year" },
      { format: "YYYY", type: "year" },
    ];

    const dates: {
      date: moment.Moment;
      text: string;
      type: "year" | "month" | "week" | "day";
    }[] = [];

    for (const option of checkFormats) {
      const date = moment(term, option.format, true).utc(true);
      if (date.isValid()) {
        dates.push({
          date,
          type: option.type,
          text: term,
        });
        break;
      }
    }
    return dates;
  }
  extractTextFromHtml(html: string, space = true) {
    const sanitizedHtml = this.sanitizeHtml(html);
    const span = document.createElement("span");
    span.innerHTML = sanitizedHtml;

    if (space) {
      var children = span.querySelectorAll("*");
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else (children[i] as HTMLElement).innerText += " ";
      }
    }

    const text = [span.textContent || span.innerText]
      .toString()
      .replace(/ +/g, " ");

    span.remove();

    return text;
  }
  extractDatesOfString(term: string): {
    date: moment.Moment;
    type: "year" | "month" | "week" | "day";
    text: string;
  }[] {
    let dates = this.extractDateOfStringWhole(term);

    if (dates.length === 0) {
      //try to extract parts of the string
      const splitted = term.trim().split(/\s+/);
      splitted.forEach((part) => {
        dates = [...dates, ...this.extractDateOfStringWhole(part)];
      });
    }

    return dates;
  }

  formatDate(
    date: string | Date,
    format:
      | "date"
      | "time"
      | "datetime"
      | "dateReadable"
      | "month"
      | string = "date",
    isTimezoned = false
  ) {
    if (!date) {
      return "-";
    }
    let useFormat = DateFieldUtils.getFormat(format);
    let momentDate = moment(date);
    if (!isTimezoned) {
      momentDate = momentDate.utc(true);
    }
    return momentDate.format(useFormat);
  }

  extractFilenameFromUrl(url: string) {
    let name = url;
    if (name.indexOf("/") !== -1) {
      name = name.split("/")[name.split("/").length - 1];
    }
    if (name.indexOf("?") !== -1) {
      name = name.split("?")[0];
    }
    return name;
  }

  formatCurrencyCents(
    price: number,
    format = this.defaultFormat,
    currency = this.defaultCurrency,
    maximumFractionDigits = 2
  ) {
    var formatter = new Intl.NumberFormat(format, {
      style: "decimal",
      maximumFractionDigits,
    });

    let formatted = formatter.format(price);
    const currencyCentLabel = this.getCurrencyCentLabel(currency);

    if (formatted.indexOf(currencyCentLabel) === -1) {
      formatted = formatted + " " + currencyCentLabel;
    }

    return formatted;
  }
  formatCurrency(
    price: number,
    specialHandlingZero = false,
    format = this.defaultFormat,
    currency = this.defaultCurrency,
    maximumFractionDigits = 2
  ) {
    var formatter = new Intl.NumberFormat(format || this.defaultFormat, {
      style: "currency",
      currency: currency,
      maximumFractionDigits,
    });

    let formatted = formatter.format(price);

    if (specialHandlingZero && (price === 0 || isNaN(price))) {
      formatted = formatted
        .replace("0,00", "-")
        .replace("0.00", "-")
        .replace("NaN", "-");
    }
    if (formatted.startsWith("-0,00") || formatted.startsWith("-0.00")) {
      formatted = formatted.replace("-0,00", "0,00").replace("-0.00", "0.00");
    }
    return formatted;
  }

  formatPercent(number: number, multiplyBy100 = false) {
    if (isNotDefined(number)) {
      return "-";
    }
    return `${this.formatNumber(number * (multiplyBy100 ? 100 : 1))} %`;
  }
  formatArea(area: number) {
    if (!area) {
      return "-";
    }
    return `${this.formatNumber(area)} ${this.getAreaUnit()}`;
  }

  formatIban(iban: string) {
    if (IBAN.isValid(iban)) {
      return IBAN.printFormat(iban, " ");
    } else {
      return iban;
    }
  }
  ibanToISO(iban: string) {
    return iban.replaceAll(" ", "");
  }
  formatBoolean(value: boolean) {
    if (value) {
      return i18n.t("Global.Labels.yes", "Ja");
    } else {
      return i18n.t("Global.Labels.no", "Nein");
    }
  }

  formatNumber(
    number: number,
    withoutCurrency = false,
    format = this.defaultFormat,
    minimumFractionDigits = 1,
    maximumFractionDigits = 2
  ) {
    var formatter = new Intl.NumberFormat(format, {
      style: "decimal",
      minimumFractionDigits,
      maximumFractionDigits,
    });

    return formatter.format(number);
  }

  formatNumberSimple(number: number, format = this.defaultFormat) {
    var formatter = new Intl.NumberFormat(format, {
      style: "decimal",
      useGrouping: false,
    });

    return formatter.format(number);
  }

  parseNumber(price: string) {
    if (typeof price === "string") {
      let number = parseFloat(
        price
          .replaceAll(this.thousandsSeparator, "")
          .replaceAll(this.decimalSeparator, ".")
      );

      return this.normalizeDecimal(number);
    } else {
      return null;
    }
  }
  getPercentageString(input: number) {
    return Number(input / 100).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  }

  normalizeDecimal(number: number, decimalPoints = 2) {
    return parseFloat(number.toFixed(decimalPoints));
  }

  isObjectId(id: string) {
    return /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i.test(id);
  }

  getTextWidth(textString: string, fontSize: number = 14) {
    const text = document.createElement("span");
    document.body.appendChild(text);

    text.style.fontSize = fontSize + "px";
    text.style.width = "auto";
    text.style.position = "absolute";
    text.style.whiteSpace = "no-wrap";
    text.innerHTML = textString;
    const width = Math.ceil(text.clientWidth);
    document.body.removeChild(text);

    return width;
  }

  async resizeInlineBase64Images(
    html: string,
    maxWidth: number,
    maxHeight: number
  ) {
    const regex =
      /src="(data:image\/(png|jpeg|tiff);base64,[a-zA-Z0-9\/\+\=]+)"/g;

    const matches = html.match(regex);

    if (matches) {
      for (const match of matches) {
        const src = match.replace('src="', "").replace('"', "");
        const resized = await ImageUtils.resizeBase64Image(
          src,
          maxWidth,
          maxHeight
        );
        html = html.replace(src, resized);
      }
    }
    return html;
  }

  random(minLength: number, maxLength = minLength) {
    const length =
      Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * Escapes regex special characters in a string.
   * @param input - The input string to escape.
   * @returns The escaped string.
   */
  escapeRegex(input: string): string {
    return input.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  /**
   * Unescapes regex special characters in a string.
   * @param input - The input string to unescape.
   * @returns The unescaped string.
   */
  unescapeRegex(input: string): string {
    return input.replace(/\\([-\/\\^$*+?.()|[\]{}])/g, "$1");
  }

  /**
   * Returns the string between two characters.
   * @param input - The input string.
   * @param startChar - The character to start from.
   * @param endChar - The character to end at.
   * @returns The string between the characters.
   */
  getBetween(input: string, startChar: string, endChar: string): string {
    try {
      const startIndex = input.indexOf(startChar) + 1;
      const endIndex = input.indexOf(endChar, startIndex);
      if (startIndex === -1 || endIndex === -1) {
        return input;
      } else {
        return input.substring(startIndex, endIndex);
      }
    } catch (err) {
      return input;
    }
  }
}
const StringUtils = new StringUtilsClass();
(window as any).StringUtils = StringUtils;
export default StringUtils;

import React, { Component } from "react";
import { Slider } from "rsuite";
import ValidationPopover, { ValidatorPopoverStyle } from "../../general/ValidationPopover/ValidationPopover";
import "./BFSlider.scss";

type Props = {
	barClassName?: string; //A css class to apply to the Bar DOM node
	defaultValue?: number; //	Default value
	disabled?: boolean; //	The disabled of component
	graduated?: boolean; //	Show Ticks
	handleClassName?: string; //	A css class to apply to the Handle node
	handleStyle?: React.CSSProperties; //	A css style to apply to the Handle node
	handleTitle?: React.ReactNode; //	Customizing what is displayed inside a handle
	max?: number; //Maximum sliding range
	min?: number; //Minimum value of sliding range
	onChange?: (value: number) => void; //Callback function that changes data
	progress?: boolean; //Show sliding progress bar
	renderMark?: (mark: number) => React.ReactNode; //Customize labels on the render ruler
	step?: number; //Slide the value of one step
	tooltip?: boolean; //Whether to show Tooltip when sliding
	value?: number; //Value (Controlled)
	vertical?: boolean;
	onBlur?: () => void;
	onFocus?: () => void;

	validatorStyle?: ValidatorPopoverStyle;
	validation?: {
		message: string;
		level: "error" | "warning";
	};
};

type States = {};

class BFSlider extends Component<Props, States> {
	render() {
		const { validation, validatorStyle, ...rest } = this.props;

		return (
			<ValidationPopover
				validatorStyle={validatorStyle}
				level={validation ? validation.level : "error"}
				message={validation ? validation.message : null}
			>
				<Slider className={"bf-slider"} style={{ width: 200 }} {...rest} />
			</ValidationPopover>
		);
	}
}

export default BFSlider;

import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { useState } from "react";
import { Field } from "react-final-form";
import FormFieldValues from "../../../../../../../components/Form/Fields/FormFieldValues";
import FormStruct from "../../../../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../../../../components/Form/Validation/FormValidators";
import ModalManager from "../../../../../../../components/ModalComponent/ModalManager";
import StructLoader from "../../../../../../../components/StructLoader/StructLoader";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFInput from "../../../../../../../modules/abstract-ui/forms/input/BFInput";
import BFSelect from "../../../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../../../modules/abstract-ui/general/Button/BFButton";
import ObjectRelatedSelect from "../../../../../../../modules/structure-components/object/ObjectRelatedSelect";
import SupplyUnitStruct from "../../../../../../../redux/actions/struct/implemented/SupplyUnitStruct";
import {
  PortfolioSupplyUnit,
  PortfolioSupplyUnitData,
} from "../../../../../cashBudget/views/config/supply-unit-config/SupplyUnitConfigInterfaces";
import SupplyUnitService from "../../../../../cashBudget/views/config/supply-unit-config/SupplyUnitService";
import CBTechnicelUnitFormCustomField from "./APSupplyUnitFormCustomField";

interface APSupplyUnitFormProps {
  forObjectId?: string;
  asset?: PortfolioSupplyUnit;
  onClose: () => void;
  onSuccess: () => void;
  types?: string[];
  onArchived?: () => void;
}

const getInitialData = (props: APSupplyUnitFormProps) => {
  let initialObjectData = {};
  if (props.forObjectId) {
    const obj = OrgaStruct.getObject(props.forObjectId);
    initialObjectData["type"] = obj.type;
    initialObjectData["entity"] = obj.entityId;
    initialObjectData["objectId"] = obj._id;
  }
  if (props.asset) {
    initialObjectData = SupplyUnitService.convertSupplyUnitDtoToForm(
      props.asset
    );
  } else {
    initialObjectData["rentalUnits"] = [];
  }
  return initialObjectData;
};
const SupplyUnitForm = (props: APSupplyUnitFormProps) => {
  const [initial] = useState(getInitialData(props));
  const typeOptions = UnitStruct.getUnitSelectOptions(props.types);
  //     PermissionService.hasBusinessUnitRole("cbc_supplyUnitConfig") || []
  //   );

  return (
    <FormStruct<any>
      className={`cb-technical-unit-form`}
      initialValues={initial}
      submitText={
        props.asset
          ? i18n.t("Global.Buttons.save")
          : i18n.t("Global.Buttons.create")
      }
      additionalActions={
        props.onArchived ? (
          <div>
            <BFButton
              onClick={() => {
                ModalManager.confirm({
                  message: i18n.t(
                    "cb:Portfolio.Objekt.supplyUnit.deleteConfirm",
                    "Mochten Sie diese technische Einheit wirklich entfernen?"
                  ),
                  confirmButtonText: i18n.t(
                    "Global.Buttons.delete",
                    "Entfernen"
                  ),
                  onConfirm: async () => {
                    await SupplyUnitService.disableSupplyUnit(props.asset);
                    props.onArchived();
                  },
                });
              }}
              appearance="outline"
            >
              {i18n.t("Global.Buttons.delete", "Entfernen")}
            </BFButton>
          </div>
        ) : undefined
      }
      onSubmit={async (values: PortfolioSupplyUnitData) => {
        await SupplyUnitService.submitSupplyUnitFormData(
          values as any, //todo fix,
          props.asset?._id
        );

        props.onSuccess();
      }}
      onAbort={props.onClose}
      render={(formProps) => (
        <>
          {!props.forObjectId && (
            <>
              <div className={`__field`}>
                <Field
                  name="type"
                  validate={FormValidators.compose(FormValidators.required())}
                >
                  {({ input, meta }) => (
                    <BFSelect
                      {...input}
                      label={`${i18n.t(
                        "cb:Portfolio.Objekt.supplyUnit.create.type",
                        "Bereich"
                      )}*`}
                      {...FormValidators.getValidation(meta)}
                      data={typeOptions}
                      onChange={(value) => {
                        input.onChange(value);
                        formProps.form.mutators.setValue("entity", null);
                        formProps.form.mutators.setValue("objectId", null);

                        formProps.form.mutators.setValue(
                          "supplyTechnicalUnitCategory",
                          null
                        );
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className={`__field`}>
                <FormFieldValues names={["type"]}>
                  {([type]) => (
                    <Field
                      name="entity"
                      validate={FormValidators.compose(
                        FormValidators.required()
                      )}
                    >
                      {({ input, meta }) => (
                        <BFSelect
                          disabled={!type}
                          {...input}
                          label={`${i18n.t(
                            "cb:Portfolio.Objekt.supplyUnit.create.entity",
                            "Gesellschaft"
                          )}*`}
                          {...FormValidators.getValidation(meta)}
                          data={OrgaStruct.getEntitySelectOptions(type)}
                          onChange={(value) => {
                            input.onChange(value);
                            formProps.form.mutators.setValue("objectId", null);
                          }}
                        />
                      )}
                    </Field>
                  )}
                </FormFieldValues>
              </div>
              <div className={`__field`}>
                <FormFieldValues names={["type", "entity"]}>
                  {([type, entity]) => (
                    <Field
                      name="objectId"
                      validate={FormValidators.compose(
                        FormValidators.required()
                      )}
                    >
                      {({ input, meta }) => (
                        <BFSelect
                          disabled={!type}
                          {...input}
                          label={`${i18n.t(
                            "cb:Portfolio.Objekt.supplyUnit.create.objectId",
                            "Objekt"
                          )}*`}
                          {...FormValidators.getValidation(meta)}
                          data={OrgaStruct.getObjectSelectOptions(entity, [
                            type,
                          ])}
                          onChange={(value) => {
                            input.onChange(value);
                            if (!entity) {
                              const entityObj = OrgaStruct.getEntity(
                                OrgaStruct.getObject(value)?.entityId
                              );
                              formProps.form.mutators.setValue(
                                "entity",
                                entityObj._id
                              );
                            }
                            formProps.form.mutators.setValue("linkedAsset", []);
                          }}
                        />
                      )}
                    </Field>
                  )}
                </FormFieldValues>
              </div>

              <Field name="objectId">
                {({ input: { value: objectValue } }) => (
                  <Field name="linkedAsset">
                    {({ input, meta }) => (
                      <ObjectRelatedSelect
                        block
                        identifier={"ap-unit-relation-selection"}
                        appearance="bf"
                        objectId={objectValue}
                        onChange={(value) => input.onChange(value)}
                        value={input.value || []}
                        multiple={true}
                        label={i18n.t(
                          "apTemplate:Activity.relation",
                          "Verknüpfung"
                        )}
                        addButtonText={i18n.t(
                          "apTemplate:Activity.addRelation",
                          "Verknüpfung hinzufügen"
                        )}
                        assets={[
                          AssetTypes.Rental.RentalUnit,
                          `${AssetTypes.Rental.RentalUnit}_historical`,
                        ]}
                      />
                    )}
                  </Field>
                )}
              </Field>
            </>
          )}
          <FormFieldValues names={["objectId"]}>
            {([objectId]) => {
              if (!objectId) {
                return null;
              }

              return (
                <Field name="rentalUnits">
                  {({ input, meta }) => {
                    const value = Array.isArray(input.value)
                      ? input.value.map((v) => ({
                          assetId: v,
                          assetType: AssetTypes.Rental.RentalUnit,
                        }))
                      : [];
                    return (
                      <ObjectRelatedSelect
                        block
                        identifier={"ap-unit-relation-selection"}
                        appearance="bf"
                        objectId={objectId}
                        onChange={(value) => {
                          const unitIds = value.map((v) => v.assetId);
                          input.onChange(unitIds);
                        }}
                        value={value}
                        multiple={true}
                        label={i18n.t(
                          "cb:Portfolio.Objekt.supplyUnit.create.rentalUnits",
                          "Mieteinheiten"
                        )}
                        addButtonText={i18n.t(
                          "cb:Portfolio.Objekt.supplyUnit.create.rentalUnitsAdd",
                          "Mieteinheit hinzufügen"
                        )}
                        assets={[AssetTypes.Rental.RentalUnit]}
                      />
                    );
                  }}
                </Field>
              );
            }}
          </FormFieldValues>
          <hr />
          <div className={`__field`}>
            <FormFieldValues names={["type"]}>
              {([type]) => (
                <>
                  {type ? (
                    <StructLoader
                      structType="supplyUnit"
                      unitType={type}
                      render={() => (
                        <Field
                          name="supplyTechnicalUnitCategory"
                          validate={FormValidators.compose(
                            FormValidators.required()
                          )}
                        >
                          {({ input, meta }) => (
                            <BFSelect
                              disabled={!type}
                              {...input}
                              label={`${i18n.t(
                                "cb:Portfolio.Objekt.supplyUnit.create.supplyTechnicalUnitCategory",
                                "Versorgungsart"
                              )}*`}
                              {...FormValidators.getValidation(meta)}
                              data={SupplyUnitStruct.getSelectOptions(type)}
                              onChange={(value) => {
                                input.onChange(value);
                                formProps.form.mutators.setValue(
                                  "customFields",
                                  {}
                                );
                              }}
                            />
                          )}
                        </Field>
                      )}
                    />
                  ) : (
                    <BFSelect
                      disabled={true}
                      label={`${i18n.t(
                        "cb:Portfolio.Objekt.supplyUnit.create.supplyTechnicalUnitCategory",
                        "Art der technischen Einheit"
                      )}*`}
                      data={[]}
                    />
                  )}
                </>
              )}
            </FormFieldValues>
          </div>
          <div className={`__field`}>
            <FormFieldValues names={["supplyTechnicalUnitCategory"]}>
              {([supplyTechnicalUnitCategory]) => {
                if (!supplyTechnicalUnitCategory) {
                  return (
                    <BFSelect
                      disabled={true}
                      label={`${i18n.t(
                        "cb:Portfolio.Objekt.supplyUnit.create.measurementUnit",
                        "Maßeinheit"
                      )}*`}
                      data={[]}
                    />
                  );
                }
                const measurementUnits = SupplyUnitStruct.getMeasurementUnits(
                  supplyTechnicalUnitCategory
                );

                const mesaurementUnitOptions = measurementUnits.map((unit) => {
                  return {
                    value: unit,
                    label: unit,
                  };
                });
                return (
                  <Field name="unit">
                    {({ input, meta }) => (
                      <BFSelect
                        disabled={!supplyTechnicalUnitCategory}
                        {...input}
                        label={`${i18n.t(
                          "cb:Portfolio.Objekt.supplyUnit.create.measurementUnit",
                          "Maßeinheit"
                        )}*`}
                        {...FormValidators.getValidation(meta)}
                        data={mesaurementUnitOptions}
                        onChange={(value) => {
                          input.onChange(value);
                        }}
                      />
                    )}
                  </Field>
                );
              }}
            </FormFieldValues>
          </div>

          <div className={`__field`}>
            <Field name="id">
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  label={`${i18n.t(
                    "cb:Portfolio.Objekt.supplyUnit.create.id",
                    "ID"
                  )}*`}
                  validation={
                    meta.error && meta.touched
                      ? { level: "error", message: meta.error }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="serialNumber">
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  label={`${i18n.t(
                    "cb:Portfolio.Objekt.supplyUnit.create.serialNumber",
                    "Zählernummer"
                  )}*`}
                  validation={
                    meta.error && meta.touched
                      ? { level: "error", message: meta.error }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="displayName">
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  label={`${i18n.t(
                    "cb:Portfolio.Objekt.supplyUnit.create.displayName",
                    "Name"
                  )}*`}
                  validation={
                    meta.error && meta.touched
                      ? { level: "error", message: meta.error }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="note">
              {({ input, meta }) => (
                <BFInput
                  type="textarea"
                  {...input}
                  label={i18n.t(
                    "cb:Portfolio.Objekt.supplyUnit.create.note",
                    "Notiz"
                  )}
                  validation={
                    meta.error && meta.touched
                      ? { level: "error", message: meta.error }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <FormFieldValues names={["type", "supplyTechnicalUnitCategory"]}>
            {([type, supplyTechnicalUnitCategory]) => (
              <>
                {type && supplyTechnicalUnitCategory && (
                  <StructLoader
                    structType="supplyUnit"
                    unitType={type}
                    render={() => (
                      <>
                        {SupplyUnitStruct.getCustomFields(
                          supplyTechnicalUnitCategory
                        ).map((field, index) => (
                          <>
                            {index === 0 && <hr />}
                            <CBTechnicelUnitFormCustomField
                              key={field.id}
                              customFieldProps={field}
                            />
                          </>
                        ))}
                      </>
                    )}
                  />
                )}
              </>
            )}
          </FormFieldValues>
        </>
      )}
    />
  );
};

export default SupplyUnitForm;

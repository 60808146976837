import { Component } from "react";
import { connect } from "react-redux";
import { AppState } from "../../redux/store";

type Props = {
  appPermissions: { [key: string]: any };
  permission: string | (string | string[])[];
};

type States = {};

class PermissionChecker extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    const { permission, appPermissions } = this.props;
    if (permission) {
      if (!appPermissions) {
        return null;
      }
      if (typeof this.props.permission === "string") {
        if (!appPermissions[this.props.permission]) {
          return null;
        }
      } else if (Array.isArray(this.props.permission)) {
        let hasPermission = false;
        for (const permission of this.props.permission) {
          if (typeof permission === "string") {
            if (appPermissions[permission]) {
              hasPermission = true;
            }
          } else {
            let permissionResult = true;

            for (const permissionAnd of permission) {
              if (!appPermissions[permissionAnd]) {
                permissionResult = false;
              }
            }

            hasPermission = hasPermission || permissionResult;
          }
        }
        if (!hasPermission) {
          return null;
        }
      }
    }

    return this.props.children;
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  appPermissions: state.uiConfig.activeApplication
    ? state.uiConfig.activeApplication.permissions
    : undefined,
});

export default connect(mapStateToProps, {})(PermissionChecker) as any;

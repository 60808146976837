import { useThrottle } from "@uidotdev/usehooks";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import useResizeObserver from "use-resize-observer";
import DebugDataComponent from "../../debug/DebugDataComponent";
import i18n from "../../i18n";
import { hasValue } from "../../utils/Helpers";
import StringUtils from "../../utils/StringUtils";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BfIcon from "../abstract-ui/icon/BfIcon";
import "./ConfigurableDashboard.scss";
import ConfigurableDashboardAddElementsBar from "./ConfigurableDashboardAddElementsBar";
import ConfigurableDashboardEditBar from "./ConfigurableDashboardEditBar";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

export interface BreakpointDefinition {
  [selector: string]: {
    columns: number;
    breakpoint: number;
    margin: [number, number];
    containerPadding: [number, number];
  };
}
export interface DashboardData {
  layouts: {
    [selector: string]: {
      i: string;
      x?: number;
      y?: number;
      w?: number;
      h?: number;
    }[];
  };
  data: string[];
}
export interface DashboardItemConfig {
  id: string;
  title: string;
  description?: string;
  layout: {
    [selector: string]: {
      minW: number;
      minH: number;
      maxW?: number;
      maxH?: number;
    };
  };
  render: (selector: string) => JSX.Element;
}

const ResponsiveGridLayout = WidthProvider(Responsive);

type ConfigurableDashboardProps = {
  filterComponent?: React.ReactNode;
  rowHeight?: number;
  breakpoints: BreakpointDefinition;
  configs: DashboardItemConfig[];
  data: DashboardData;
  editable?: boolean;
  bright?: boolean;
  onLayoutSubmit?: (layout: Layouts, elements: string[]) => Promise<void>;
};

const ConfigurableDashboard = (props: ConfigurableDashboardProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [temporaryElements, setTemporaryElements] = useState<string[]>([]);
  const [temporaryLayouts, setTemporaryLayouts] = useState<Layouts>(null);
  const [activeBreakpoint, setActiveBreakpoint] = useState<string>("lg");
  const [breakPoints, setBreakPoints] =
    useState<{ [key: string]: number }>(null);
  const [cols, setCols] = useState<{ [key: string]: number }>(null);
  const [margin, setMargin] =
    useState<{ [key: string]: [number, number] }>(null);
  const [containerPadding, setContainerPadding] =
    useState<{ [key: string]: [number, number] }>(null);
  const ref = useRef();
  const { width = 1 } = useResizeObserver({
    ref,
  });
  const throttledWidth = useThrottle(width);
  useEffect(() => {
    calculateHeight();
  }, [throttledWidth]);
  const [rowHeight, setRowHeight] = useState<number>(
    props.rowHeight || window.innerWidth / 12
  );

  useEffect(() => {
    calculateLayout();
  }, [props.configs, props.data, props.breakpoints]);

  useEffect(() => {
    const data = Object.entries(props.breakpoints);
    setBreakPoints(
      Object.fromEntries(data.map(([key, value]) => [key, value.breakpoint]))
    );
    setCols(
      Object.fromEntries(data.map(([key, value]) => [key, value.columns]))
    );
    setMargin(
      Object.fromEntries(data.map(([key, value]) => [key, value.margin]))
    );
    setContainerPadding(
      Object.fromEntries(
        data.map(([key, value]) => [key, value.containerPadding])
      )
    );
  }, [props.breakpoints]);

  const calculateHeight = () => {
    if (!props.rowHeight && throttledWidth !== 1) {
      setRowHeight(throttledWidth / 12);
    }
  };
  const calculateLayout = () => {
    setTemporaryElements(props.data.data);
    const layouts = Object.fromEntries(
      Object.keys(props.breakpoints).map((breakpoint) => {
        const data = [];
        props.data.data.forEach((item) => {
          const itemLayouts = props.data.layouts[breakpoint];
          const itemLayout = itemLayouts?.find((layout) => layout.i === item);
          data.push({
            i: item,
            x: hasValue(itemLayout?.x) ? itemLayout.x : 0,
            y: hasValue(itemLayout?.y) ? itemLayout.y : Infinity,
            w: hasValue(itemLayout?.w)
              ? itemLayout.w
              : props.configs.find((config) =>
                  StringUtils.equalsIgnoreCase(config.id, item)
                )?.layout[breakpoint]?.minW || 1,
            h: hasValue(itemLayout?.h)
              ? itemLayout.h
              : props.configs.find((config) =>
                  StringUtils.equalsIgnoreCase(config.id, item)
                )?.layout[breakpoint]?.minH || 1,
            minW: props.configs.find((config) =>
              StringUtils.equalsIgnoreCase(config.id, item)
            )?.layout[breakpoint]?.minW,
            minH: props.configs.find((config) =>
              StringUtils.equalsIgnoreCase(config.id, item)
            )?.layout[breakpoint]?.minH,
            maxW: props.configs.find((config) =>
              StringUtils.equalsIgnoreCase(config.id, item)
            )?.layout[breakpoint]?.maxW,
            maxH: props.configs.find((config) =>
              StringUtils.equalsIgnoreCase(config.id, item)
            )?.layout[breakpoint]?.maxH,
          });
        });

        return [breakpoint, data];
      })
    );

    setTemporaryLayouts(layouts);
  };

  const removeElement = (elementId: string) => {
    setTemporaryElements(
      temporaryElements.filter(
        (e) => !StringUtils.equalsIgnoreCase(e, elementId)
      )
    );
    const newLayouts = { ...temporaryLayouts };
    Object.keys(newLayouts).forEach((e) => {
      newLayouts[e] = newLayouts[e].filter(
        (layout) => !StringUtils.equalsIgnoreCase(layout.i, elementId)
      );
    });
    setTemporaryLayouts(newLayouts);
  };

  const addElement = (elementId: string, layouts: Layout[]) => {
    setTemporaryElements([...temporaryElements, elementId]);
    const newLayouts = { ...temporaryLayouts };
    newLayouts[activeBreakpoint] = layouts;
    Object.keys(newLayouts)
      .filter((e) => e !== activeBreakpoint)
      .forEach((e) => {
        newLayouts[e].push({
          i: elementId,
          x: 0,
          y: Infinity,
          w:
            props.configs.find((config) =>
              StringUtils.equalsIgnoreCase(config.id, elementId)
            )?.layout[e]?.minW || 1,
          h:
            props.configs.find((config) =>
              StringUtils.equalsIgnoreCase(config.id, elementId)
            )?.layout[e]?.minH || 1,
          ...props.configs.find((config) =>
            StringUtils.equalsIgnoreCase(config.id, elementId)
          )?.layout[e],
        });
      });

    setTemporaryLayouts(newLayouts);
  };

  if (
    breakPoints === null ||
    cols === null ||
    margin === null ||
    containerPadding === null
  ) {
    return null;
  }

  return (
    <div className={classNames(`configurable-dashboard`)} ref={ref}>
      <DebugDataComponent data={{ temporaryLayouts, temporaryElements }} />

      <div className={`dashboard-header-row`}>
        {props.filterComponent && <div>{props.filterComponent}</div>}
        <div className={`fill`} />
        {props.editable && (
          <ConfigurableDashboardEditBar
            bright={props.bright}
            editMode={editMode}
            onEdit={() => setEditMode(true)}
            onReset={() => {
              calculateLayout();
              setEditMode(false);
            }}
            onSave={async () => {
              await props.onLayoutSubmit(temporaryLayouts, temporaryElements);
              setEditMode(false);
            }}
          />
        )}
      </div>
      {props.editable && (
        <ConfigurableDashboardAddElementsBar
          bright={props.bright}
          rowHeight={props.rowHeight}
          breakpoints={props.breakpoints}
          editMode={editMode}
          usedComponents={temporaryElements}
          configs={props.configs}
          selector={activeBreakpoint}
        />
      )}

      <ResponsiveGridLayout
        className="layout-grid"
        rowHeight={rowHeight}
        layouts={temporaryLayouts}
        onLayoutChange={(layout, allLayouts) => {
          setTemporaryLayouts(allLayouts);
        }}
        breakpoints={breakPoints}
        cols={cols}
        margin={margin}
        containerPadding={containerPadding}
        onBreakpointChange={(newBreakpoint) =>
          setActiveBreakpoint(newBreakpoint)
        }
        isDroppable={editMode}
        isDraggable={editMode}
        isResizable={editMode}
        onDropDragOver={(e) => {
          const id = (e as any).dataTransfer.types[1];
          const config = props.configs.find((config) =>
            StringUtils.equalsIgnoreCase(config.id, id)
          );
          if (config) {
            return {
              w: config.layout[activeBreakpoint]?.minW || 1,
              h: config.layout[activeBreakpoint]?.minH || 1,
            };
          }

          return false;
        }}
        onDrop={(layout, layoutItem, e) => {
          const id = (e as any).dataTransfer.types[1];
          const layoutToSet = layout.map((item) => {
            if (StringUtils.equalsIgnoreCase(item.i, layoutItem.i)) {
              return {
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.h,
                i: id,
                ...props.configs.find((config) =>
                  StringUtils.equalsIgnoreCase(config.id, id)
                )?.layout[activeBreakpoint],
              };
            } else {
              return item;
            }
          });
          addElement(id, layoutToSet);
        }}
      >
        {(temporaryLayouts[activeBreakpoint] || []).map((item) => {
          const conf = props.configs.find((config) =>
            StringUtils.equalsIgnoreCase(config.id, item.i)
          );

          return (
            <div key={item.i} className={`__card grid-item`}>
              {conf ? conf.render(activeBreakpoint) : <InvalidConfig />}

              {editMode && (
                <div className={`edit-overlay`}>
                  <BFButton
                    appearance={"transparent-light"}
                    className={`delete-button`}
                    icon={{
                      data: "close",
                      type: "light",
                      size: "xs",
                    }}
                    onClick={() => {
                      removeElement(item.i);
                    }}
                  />

                  <BfIcon data="direction-button-2" type="light" size="lg" />
                  {item.w === 1 || item.h === 1 ? null : (
                    <div className={`move-text`}>
                      {i18n.t(
                        "DasboardItemConfig.EditOverlay.Move",
                        "Verschieben Sie das Element durch ziehen"
                      )}
                    </div>
                  )}

                  {item.w === 1 || item.h === 1 ? null : (
                    <div className={`resize-text`}>
                      {i18n.t(
                        "DasboardItemConfig.EditOverlay.Resize",
                        "Größe verändern"
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </ResponsiveGridLayout>
    </div>
  );
};

export default ConfigurableDashboard;

const InvalidConfig = () => {
  const ref = useRef<HTMLDivElement>();
  const { width = 1, height = 1 } = useResizeObserver({
    ref,
  });
  return (
    <div className={`invalid-config`} ref={ref}>
      <BfIcon data="cat-lost" type="light" />
      {width > 130 && height > 130 && (
        <div className={`description`}>
          {i18n.t(
            "DashboardItemConfig.InvalidConfig",
            "Du hast keine Berechtigung mehr, um dies zu sehen."
          )}
        </div>
      )}
    </div>
  );
};

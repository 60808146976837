import { BFChooserOption } from "@/modules/abstract-ui/forms/chooser/BFChooser";
import { MatchQuery } from "@/services/DataService";

export type CustomFieldBaseAll = {
  condition?: MatchQuery;
  id: string;
};

export type CustomFieldBase = CustomFieldBaseAll & {
  fieldType: CustomFieldEditType;
  displayName: string;
  defaultValue?: any;
  validation: CustomfieldValidation[];
  table?:
    | true
    | {
        aggregate?: boolean;
        disableExport?: boolean;

        fixedWidth?: number;
        flexWidth?: number;
        resizable?: boolean;
        resizableOptions?: {
          min: number;
          max: number;
        };
        sortable?: boolean;
        alignment?: "center" | "left" | "right";
      };
};
export enum CustomFieldEditType {
  number = "number",
  currency = "currency",
  string = "string",
  radio = "radio",
  textarea = "textarea",
  boolean = "boolean",
  date = "date",
  attachment = "attachment",
  select = "select",
}
export enum CustomFieldLayoutType {
  section = "section",
  row = "row",
}
export type SuffixPrefixType =
  | {
      area: true;
    }
  | {
      currency: true | string;
    }
  | {
      text: string;
    };
export type Customfield_number = CustomFieldBase & {
  fieldType: CustomFieldEditType.number;
  textAlign?: "left" | "right" | "center";
  prefix?: SuffixPrefixType;
  suffix?: SuffixPrefixType;
  info?: string;
};
export type Customfield_radio = CustomFieldBase & {
  fieldType: CustomFieldEditType.radio;
  options: { value: string; label: string }[];
  inline?: boolean;
};
export type Customfield_currency = CustomFieldBase & {
  fieldType: CustomFieldEditType.currency;
  currency?: string; // if not set, used default
  info?: string;
};
export type Customfield_string = CustomFieldBase & {
  fieldType: CustomFieldEditType.string;
  info?: string;
};
export type Customfield_textarea = CustomFieldBase & {
  fieldType: CustomFieldEditType.textarea;
  info?: string;
};
export type Customfield_boolean = CustomFieldBase & {
  fieldType: CustomFieldEditType.boolean;
};
export type Customfield_date = CustomFieldBase & {
  fieldType: CustomFieldEditType.date;
  info?: string;
};
export type Customfield_attachment = CustomFieldBase & {
  fieldType: CustomFieldEditType.attachment;
};
export type Customfield_select = CustomFieldBase & {
  fieldType: CustomFieldEditType.select;
  options: BFChooserOption<string>[];
  info?: string;
};

export type CustomFieldLayoutBase = CustomFieldBaseAll & {
  fieldType: CustomFieldLayoutType;
  fields: CustomfieldType[];
};

export type Customfield_section = CustomFieldLayoutBase & {
  fieldType: CustomFieldLayoutType.section;
  collapsible?: boolean;
  initialOpen?: boolean;
  title: string;
};
export type Customfield_row = CustomFieldLayoutBase & {
  fieldType: CustomFieldLayoutType.row;
  flex?: number[];
};

export type CustomfieldType_edit =
  | Customfield_number
  | Customfield_currency
  | Customfield_radio
  | Customfield_textarea
  | Customfield_string
  | Customfield_date
  | Customfield_select
  | Customfield_attachment
  | Customfield_boolean;
export type CustomfieldType_layout = Customfield_section | Customfield_row;
export type CustomfieldType = CustomfieldType_edit | CustomfieldType_layout;

type CustomfieldValidation_Required = {
  type: "required";
};
type CustomfieldValidation_Min = {
  type: "min";
  param: number;
};
type CustomfieldValidation_Max = {
  type: "max";
  param: number;
};

export type CustomfieldValidation =
  | CustomfieldValidation_Required
  | CustomfieldValidation_Min
  | CustomfieldValidation_Max;

export type CustomField = CustomfieldType;

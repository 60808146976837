import i18n from "@/i18n";
import { getRestrictions } from "@/services/RestrictionService";
import ArrayUtils from "@/utils/ArrayUtils";
import { hasNoValue } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import _ from "lodash";
import { ItemDataType } from "rsuite/esm/MultiCascadeTree";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import {
  AbstractStructSelectors,
  DataByUnitType,
} from "../AbstractStructSelectors";
import UnitStruct from "./UnitStruct";

export type EntityModel = {
  _id: string;
  id: string;
  entityName: string;
  displayName: string;
  type: string; // unit
  objects: ObjectModel[];
  banks: BankAccountModel[];
  usesAccounting?: boolean;
};
export type ObjectModel = {
  _id: string;
  bankInfo: {
    mainBankAccount: string;
    outgoingInvoiceBankAccount?: string;
  };
  imported: boolean;
  displayName: string;
  end: Date;
  start: Date;
  id: string;
  objectKindId: string;
  status: "active" | "archived";
};
export type BankAccountModel = {
  _id: string;
  account: string;
  bankName: string;
  blz: string;
  displayName: string;
  iban: string;
  startBalance: number;
  objects: string[];
};
class OrgaStructClass extends AbstractStructSelectors<EntityModel[]> {
  getStructType(): StructType {
    return "orga";
  }
  loadStructData(types: string[]): Promise<DataByUnitType<EntityModel[]>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: `/liquiplanservice/getObjectStructure`,
          target: "EMPTY",
          queryParams: {
            param: {
              types: types,
            },
          },
        })) as EntityModel[];
        const result: DataByUnitType<EntityModel[]> = {};
        types.forEach((type) => {
          result[type] = data?.filter((config) => config.type === type);
        });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }

  isImportedObject(id: string): boolean {
    return this.useCache<boolean>("isImportedObject", arguments, () => {
      const object = this.getObject(id);
      return object?.imported === true;
    });
  }

  getEntities(type?: string | string[], considerRestrictions = false) {
    return this.useCache<EntityModel[]>("getEntities", arguments, () => {
      const entities = this.getAllData()
        ?.flat()
        .filter((e) =>
          hasNoValue(type)
            ? true
            : Array.isArray(type)
            ? type.includes(e.type)
            : e.type === type
        );

      if (considerRestrictions) {
        const restrictions = getRestrictions();
        if (restrictions) {
          return (
            entities?.filter((e) =>
              restrictions.dependencies.entities.includes(e._id)
            ) || []
          );
        }
      }

      return entities;
    });
  }

  getEntity(id: string): EntityModel {
    return this.useCache<EntityModel>("getEntity", arguments, () => {
      const allData = this.getAllData();
      for (const dataOfUnit of allData) {
        const entity = dataOfUnit.find((e) => e._id === id);
        if (entity) {
          return entity;
        }
      }
    });
  }
  getEntitySelectOptions(
    type?: string | string[],
    considerRestrictions = false
  ) {
    return this.useCache<{ label: string; value: string }[]>(
      "getEntitySelectOptions",
      arguments,
      () =>
        ArrayUtils.sortData(
          this.getEntities(type, considerRestrictions).map((entity) => ({
            label: entity.displayName,
            value: entity._id,
          })),
          {
            dir: "asc",
            key: "label",
          }
        )
    );
  }

  getAllBankAccounts(types?: string[], considerRestrictions = false) {
    return this.useCache<
      (BankAccountModel & { entityId: string; type: string })[]
    >("getAllBankAccounts", arguments, () => {
      const bankAccounts = this.getAllData()
        .flat()
        .reduce(
          (prev, current) => [
            ...prev,
            ...(current.banks?.map((object) => ({
              ...object,
              entityId: current._id,
              type: current.type,
            })) || []),
          ],
          []
        )
        .filter((e) => (hasNoValue(types) ? true : types.includes(e.type)));

      if (considerRestrictions) {
        const restrictions = getRestrictions();
        if (restrictions) {
          return (
            bankAccounts?.filter((e) =>
              restrictions.bankAccounts.includes(e._id)
            ) || []
          );
        }
      }
      return bankAccounts;
    });
  }
  getBankAccount(
    id: string
  ): BankAccountModel & { entityId: string; type: string } {
    return this.useCache<BankAccountModel & { entityId: string; type: string }>(
      "getBankAccount",
      arguments,
      () => {
        const allData = this.getAllData();
        for (const dataOfUnit of allData) {
          for (const entity of dataOfUnit) {
            const account = entity.banks.find((a) => a._id === id);
            if (account) {
              return { ...account, entityId: entity._id, type: entity.type };
            }
          }
        }
      }
    );
  }

  getBankAccountsOfEntity(entityId: string, considerRestrictions = false) {
    return this.useCache<
      (BankAccountModel & { entityId: string; type: string })[]
    >("getBankAccountsOfEntity", arguments, () => {
      return this.getAllBankAccounts(undefined, considerRestrictions).filter(
        (e) => e.entityId === entityId
      );
    });
  }

  getObject(id: string): ObjectModel & { entityId: string; type: string } {
    return this.useCache<ObjectModel & { entityId: string; type: string }>(
      "getObject",
      arguments,
      () => {
        const allData = this.getAllData();
        for (const dataOfUnit of allData) {
          for (const entity of dataOfUnit) {
            const object = entity.objects.find((o) => o._id === id);
            if (object) {
              return { ...object, entityId: entity._id, type: entity.type };
            }
          }
        }
      }
    );
  }
  getBankAccountSelectOptions(entityId: string, considerRestrictions = false) {
    return this.useCache<{ label: string; value: string; subLabel?: any }[]>(
      "getBankAccountSelectOptions",
      arguments,
      () =>
        this.getBankAccountsOfEntity(entityId, considerRestrictions).map(
          (bankAccount) => ({
            label: bankAccount.displayName,
            subLabel:
              bankAccount.objects.length === 0 ? (
                StringUtils.formatIban(bankAccount.iban)
              ) : (
                <>
                  {bankAccount.objects.length === 1
                    ? this.getObject(bankAccount.objects[0])?.displayName
                    : i18n.t("Base.CountAssets", "{{count}} Assets", {
                        count: bankAccount.objects.length,
                      })}
                  <br />
                  {StringUtils.formatIban(bankAccount.iban)}
                </>
              ),
            value: bankAccount._id,
          })
        )
    );
  }

  getEntityOfUnit(unit: string, id: string): EntityModel {
    return this.useCache<EntityModel>("getEntityOfUnit", arguments, () =>
      this.getData(unit).find((e) => e._id === id)
    );
  }
  getEntitiesByObjects(objectIds: string[], considerRestrictions = false) {
    return this.useCache<EntityModel[]>(
      "getEntitiesByObjects",
      arguments,
      () => {
        return this.getEntities(null, considerRestrictions).filter((e) =>
          e.objects?.some((object) => objectIds.includes(object._id))
        );
      }
    );
  }
  getObjectsByEntities(entityIds: string[], considerRestrictions = false) {
    return this.useCache<ObjectModel[]>("getObjectsByEntities", arguments, () =>
      this.getAllObjects(undefined, considerRestrictions).filter((object) =>
        entityIds.includes(object.entityId)
      )
    );
  }
  getAllObjects(type?: string[], considerRestrictions = false) {
    return this.useCache<(ObjectModel & { entityId: string; type: string })[]>(
      "getAllObjects",
      arguments,
      () => {
        const objects = this.getAllData()
          ?.flat()
          .reduce(
            (prev, current) => [
              ...prev,
              ...(current.objects?.map((object) => ({
                ...object,
                entityId: current._id,
                type: current.type,
              })) || []),
            ],
            []
          )
          .filter((e) => (hasNoValue(type) ? true : type.includes(e.type)));

        if (considerRestrictions) {
          const restrictions = getRestrictions();
          if (restrictions) {
            return (
              objects?.filter((e) => restrictions.lqObjects.includes(e._id)) ||
              []
            );
          }
        }

        return objects;
      }
    );
  }

  getObjectsOfEntity(
    entityId: string | string[],
    considerRestrictions?: boolean
  ) {
    return this.useCache<(ObjectModel & { entityId: string; type: string })[]>(
      "getObjectsOfEntity",
      arguments,
      () => {
        const entities = this.getAllData()
          .flat()
          .filter((entity) =>
            Array.isArray(entityId)
              ? entityId.find((e) => e === entity._id)
              : entity._id === entityId
          );
        const restrictions = getRestrictions();
        return entities
          .flatMap((entity) =>
            entity.objects.map((object) => ({
              ...object,
              entityId: entity._id,
              type: entity.type,
            }))
          )
          .filter((e) =>
            considerRestrictions && restrictions
              ? restrictions.lqObjects?.includes(e._id)
              : true
          );
      }
    );
  }

  getAllObjectsByBankAccount(accountId: string, considerRestrictions = false) {
    return this.useCache<ObjectModel[]>(
      "getAllObjectsByBankAccount",
      arguments,
      () =>
        this.getAllObjects(undefined, considerRestrictions).filter(
          (object) => object.bankInfo?.mainBankAccount === accountId
        )
    );
  }
  findEntityByName(name: string) {
    return this.useCache<EntityModel>("findEntityByName", arguments, () =>
      this.getAllData()
        .flat()
        .find(
          (entity) =>
            entity.displayName.trim().toLowerCase() ===
            name.trim().toLowerCase()
        )
    );
  }

  getObjectSelectOptions(
    entityId?: string | string[],
    type?: string[],
    ignoreImported = false
  ) {
    return this.useCache<{ label: string; value: string }[]>(
      "getObjectSelectOptions",
      arguments,
      () => {
        let objects: ObjectModel[];
        if (entityId) {
          objects = this.getObjectsOfEntity(entityId);
        } else {
          objects = this.getAllObjects(type);
        }

        return ArrayUtils.sortData(
          objects
            ?.filter((e) => (ignoreImported ? !e.imported : true))
            .map((object) => ({
              label: `${object.id} - ${object.displayName}`,
              value: object._id,
            })) || [],
          {
            dir: "asc",
            key: "label",
          }
        );
      }
    );
  }
  getObjectSelectOptionsOfKind(kind: string) {
    return this.useCache<{ label: string; value: string }[]>(
      "getObjectSelectOptions",
      arguments,
      () => {
        const objects = this.getAllObjects().filter(
          (e) => e.objectKindId === kind
        );

        return (
          objects?.map((object) => ({
            label: `${object.id} - ${object.displayName}`,
            value: object._id,
          })) || []
        );
      }
    );
  }

  filterObjectsBy(units: string[], entities: string[], objects: string[]) {
    return this.useCache<string[]>("filterObjectsBy", arguments, () => {
      const objectIds =
        this.getAllObjects()
          ?.map((object) => object._id)
          .filter((id) => objects.includes(id)) || [];
      const objectIdsByIds = this.getObjectsByEntities(entities)?.map(
        (object) => object._id
      );
      const objectIdsByUnits = this.getAllObjects()
        .filter((obj) => units.includes(obj.type))
        .map((e) => e._id);

      return _.uniq([...objectIds, ...objectIdsByIds, ...objectIdsByUnits]);
    });
  }

  filterEntitiesBy(units: string[], entities: string[]) {
    return this.useCache<string[]>("filterEntitiesBy", arguments, () => {
      const entityIds =
        this.getEntities()
          ?.map((entity) => entity._id)
          .filter((id) => entities.includes(id)) || [];
      const entityIdsByUnits = this.getEntities()
        .filter((entity) => units.includes(entity.type))
        .map((e) => e._id);

      return _.uniq([...entityIds, ...entityIdsByUnits]);
    });
  }

  getBankAccountsWithData(unitTypes: string[], onlyAccounting = false) {
    return this.useCache<
      (BankAccountModel & {
        type: string;
        entityId: string;
        objectId: string;
      })[]
    >("getBankAccountsWithData", arguments, () => {
      return OrgaStruct.getEntities(unitTypes)
        .filter((e) => (onlyAccounting ? e.usesAccounting : true))
        .map((e) =>
          e.banks.map((a) => ({
            ...a,
            type: e.type,
            entityId: e._id,
            objectId: e.objects.find(
              (t) => t.bankInfo.mainBankAccount === a._id
            )?._id,
          }))
        )
        .flat();
    });
  }

  getAccountsListOfCascaderSelectionWithPrefix(
    values: string[],
    unitTypes: string[],
    onlyAccounting = false
  ) {
    return this.useCache<ItemDataType[]>(
      "getAccountsListOfCascaderSelectionWithPrefix",
      arguments,
      () => {
        const bankaccounts = OrgaStruct.getBankAccountsWithData(
          unitTypes,
          onlyAccounting
        );

        const data = [];
        // fill data with the category ids
        values.forEach((entry) => {
          if (entry.startsWith("type_")) {
            const unitId = entry.substring(5);
            bankaccounts
              .filter((e) => e.type === unitId)
              .forEach((e) => data.push(e._id));
          } else if (entry.startsWith("entity_")) {
            const entityId = entry.substring(7);
            bankaccounts
              .filter((e) => e.entityId === entityId)
              .forEach((e) => data.push(e._id));
          } else if (entry.startsWith("object_")) {
            const objectId = entry.substring(7);
            bankaccounts
              .filter((e) => e.objectId === objectId)
              .forEach((e) => data.push(e._id));
          } else {
            data.push(entry);
          }
        });
        return _.uniq(data);
      }
    );
  }
  getBankAccountCascaderSelection(
    unitTypes: string[],
    onlyAccounting = false,
    usePrefixes = false
  ) {
    return this.useCache<ItemDataType[]>(
      "getBankAccountCascaderSelection",
      arguments,
      () => {
        const data = this.getBankAccountsWithData(unitTypes, onlyAccounting);

        const result: ItemDataType[] = [];

        const bankAccountsByType = _.groupBy(data, "type");

        for (const [type, bankAccounts] of Object.entries(bankAccountsByType)) {
          const typeValue: ItemDataType = {
            children: [],
            label: UnitStruct.getUnitLabel(type),
            value: (usePrefixes ? "type_" : "") + type,
          };
          const entityIds = _.uniq(bankAccounts.map((e) => e.entityId));
          for (const entityId of entityIds) {
            const entity = OrgaStruct.getEntity(entityId);
            if (!entity) {
              continue;
            }
            const entityValue: ItemDataType = {
              children: [],
              label: entity.displayName,
              value: (usePrefixes ? "entity_" : "") + entity._id,
            };
            const objectIds = _.uniq(
              bankAccounts
                .filter((e) => e.entityId === entityId)
                .map((e) => e.objectId)
            );
            for (const objectId of objectIds) {
              const object = OrgaStruct.getObject(objectId);
              if (!object) {
                continue;
              }
              const objectValue: ItemDataType = {
                label: object.displayName,
                value: (usePrefixes ? "object_" : "") + object._id,
                children: [],
              };

              const accounts = bankAccounts.filter(
                (e) => e.objectId === objectId
              );
              for (const account of accounts) {
                objectValue.children.push({
                  label: account.displayName,
                  value: account._id,
                });
              }

              if (objectValue.children.length > 0) {
                entityValue.children.push(objectValue);
              }
            }
            const accountsAssignedToEntityButWithoutObject =
              bankAccounts.filter(
                (e) => e.entityId === entityId && !e.objectId
              );
            if (accountsAssignedToEntityButWithoutObject.length > 0) {
              const notAssignedAccounts: ItemDataType = {
                label: "Nicht zugeordnet",
                value: "notAssigned",
                children: [],
              };
              for (const account of accountsAssignedToEntityButWithoutObject) {
                notAssignedAccounts.children.push({
                  label: account.displayName,
                  value: account._id,
                });
              }
              if (notAssignedAccounts.children.length > 0) {
                entityValue.children.push(notAssignedAccounts);
              }
            }

            if (entityValue.children.length > 0) {
              typeValue.children.push(entityValue);
            }
          }

          result.push(typeValue);
        }

        return result;
      }
    );
  }
}
const OrgaStruct = new OrgaStructClass();
(window as any).OrgaStruct = OrgaStruct;
export default OrgaStruct;

import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Badge, Dropdown } from "rsuite";
import HorizontalLoader from "../../components/Loaders/HorizontalLoader/HorizontalLoader";
import { Application } from "../../model/db/Application";
import { User } from "../../model/db/User";
import { Notification } from "../../model/notifications/Notification";
import { setUiConfig } from "../../redux/actions/ui-config/ui-config-actions";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import BfIcon from "../abstract-ui/icon/BfIcon";
import { hasNewChangelogEntry } from "../changelog/changelog.utils";
import { seenAllNotifications } from "./../../redux/actions/notifications/notifications-actions";
import "./NotificationsButton.scss";
import NotificationsMenu from "./NotificationsMenu";

type Props = {
  activeApplication: Application;
  user: User;
  seenAllNotifications: () => void;
  notifications: Notification[];
  notificationsOverlayActive: boolean;
  setUiConfig: (key: string, value: any) => void;
} & WithTranslation;

type States = {};

class NotificationsButton extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  // omponentDidMount() {}

  // componentWillUnmount() {}

  // componentDidUpdate(prevProps: Props, prevState: States, snapshot) {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return true;
  }
  toggleNotification(open) {
    // if (!this.props.notificationsOverlayActive) {
    //   this.props.seenAllNotifications();
    // }
    this.props.setUiConfig(DefaultUIConfigs.NOTIFICATION_OVERLAY_ACTIVE, open);
  }

  evaluateNotifications() {
    const { notifications } = this.props;
    let badgeCounter = 0;
    let asyncTask = false;

    notifications?.forEach((notification) => {
      if (!notification.seen) {
        badgeCounter++;
      }
      if (
        notification.type === "progress" &&
        notification.state === "inprogress"
      ) {
        asyncTask = true;
      }
    });

    return {
      badgeCounter,
      asyncTask,
    };
  }

  render() {
    const { notificationsOverlayActive } = this.props;
    const { badgeCounter, asyncTask } = this.evaluateNotifications();

    const alarmIcon = (
      <div className="font-color-white">
        <BfIcon data="alarm-bell" type="bf" size="lg" />
      </div>
    );
    const newChangelogEntry = hasNewChangelogEntry();

    const badgeCounterWithChangelog = newChangelogEntry
      ? badgeCounter + 1
      : badgeCounter;
    return (
      <Dropdown
        className="header-dropdown notifications-dropdown"
        open={notificationsOverlayActive}
        onToggle={(open) => this.toggleNotification(open)}
        title={
          <>
            {badgeCounterWithChangelog !== 0 ? (
              <Badge content={badgeCounterWithChangelog}>{alarmIcon}</Badge>
            ) : (
              alarmIcon
            )}
            {asyncTask ? (
              <div className={`async-task-loader`}>
                <HorizontalLoader size="xs" />
              </div>
            ) : null}
          </>
        }
        noCaret
        placement="bottomEnd"
      >
        <NotificationsMenu />
      </Dropdown>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  activeApplication: state.uiConfig.activeApplication,
  user: state.global.user,
  notifications: state.notifications.entries,
  notificationsOverlayActive:
    state.uiConfig.general[DefaultUIConfigs.NOTIFICATION_OVERLAY_ACTIVE],
});

export default connect(mapStateToProps, { setUiConfig, seenAllNotifications })(
  withTranslation()(NotificationsButton)
) as any;

import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import CacheService from "@/services/CacheService";
import { HTTP } from "@/utils/Http";
import StringUtils from "@/utils/StringUtils";
import {
  OAObject,
  ObjectFeature_valueEntry,
} from "../../../types/object.interface";

export const AssetValueEntry = (props: {
  obj: OAObject;
  isCurrent?: boolean;
  valueEntry: ObjectFeature_valueEntry;
  editable?: boolean;
}) => {
  const onDelete = () => {
    ModalManager.confirm({
      title: (
        <div className="asset-value-entry-deletion">
          {props.isCurrent && (
            <span className="info">
              {i18n.t(
                "cb:Portfolio.Objekt.currentValueLabel",
                "Aktueller Wert"
              )}
              :
            </span>
          )}
          <span className="value">
            {StringUtils.formatCurrency(props.valueEntry.value)}
          </span>
          <span>{i18n.t("cb:Portfolio.Objekt.currentValueAddedAt", "am")}</span>
          <span className="date">
            {StringUtils.formatDate(props.valueEntry.date)}
          </span>
        </div>
      ),
      message: i18n.t(
        "cb:Portfolio.Objekt.confirmDeleteValue",
        "Wollen Sie den Verkehrswert entgültig entfernen?"
      ), //`Wollen Sie den Verkehrswert entgültig entfernen?`,
      onConfirm: async () => {
        const asset = await HTTP.post({
          url: `/api/portfolioObject/${props.obj._id}/deleteAssetValue/${props.valueEntry.id}`,
          target: "EMPTY",
        });
        CacheService.update(asset);
      },
      confirmButtonText: i18n.t(
        "cb:Portfolio.Objekt.confirmDeleteValueButton",
        "Verkehrswert entfernen"
      ), //`Verkehrswert entfernen`,
    });
  };

  return (
    <div className="asset-value-entry">
      <div className="name">
        {props.isCurrent && (
          <span className="info">
            {i18n.t("cb:Portfolio.Objekt.currentValueLabel", "Aktueller Wert")}:
          </span>
        )}
        <span className="value ">
          {StringUtils.formatCurrency(props.valueEntry.value)}
        </span>
        <span>{i18n.t("cb:Portfolio.Objekt.currentValueAddedAt", "am")}</span>
        <span className="date">
          {StringUtils.formatDate(props.valueEntry.date)}
        </span>
      </div>
      {props.editable && (
        <div className="action">
          <BFButton
            onClick={() => onDelete()}
            appearance={"link"}
            icon={{ type: "bf", data: "trash-can", size: "sm" }}
          />
        </div>
      )}
    </div>
  );
};

// const ImmoValueEntry = (props: {
//   obj: OAObject;
//   index: number;
//   valueEntry: ObjectFeature_valueEntry;
// }) => {
//   const onDelete = () => {
//     ModalManager.confirm({
//       title: (
//         <div className="asset-value-entry-deletion">
//           {props.index === 0 && (
//             <span className="info">
//               {i18n.t("cb:Portfolio.Objekt.currentValueLabel")}:
//             </span>
//           )}
//           <span className="value">
//             {StringUtils.formatCurrency(props.valueEntry.value)}
//           </span>
//           <span>{i18n.t("cb:Portfolio.Objekt.currentValueAddedAt")}</span>
//           <span className="date">
//             {moment(props.valueEntry.date).format(i18n.t("Formats.dateFormat"))}
//           </span>
//         </div>
//       ),
//       message: i18n.t("cb:Portfolio.Objekt.confirmDeleteValue"), //`Wollen Sie den Verkehrswert entgültig entfernen?`,
//       onConfirm:  async () => {
//         const asset = await HTTP.post({
//           url: `/api/portfolioObject/${props.obj._id}/deleteAssetValue/${props.valueEntry.id}`,
//         });
//         CacheService.update(asset);
//       },
//       confirmButtonText: i18n.t("cb:Portfolio.Objekt.confirmDeleteValueButton"), //`Verkehrswert entfernen`,
//     });
//   };

//   return (
//     <div className="immo-value-entry">
//       <div className="name">
//         {props.index === 0 && (
//           <span className="info">
//             {i18n.t("cb:Portfolio.Objekt.currentValueLabel")}:
//           </span>
//         )}
//         <span className="value">
//           {StringUtils.formatCurrency(props.valueEntry.value)}
//         </span>
//         <span>{i18n.t("cb:Portfolio.Objekt.currentValueAddedAt")}</span>
//         <span className="date">
//           {moment(props.valueEntry.date).format(i18n.t("Formats.dateFormat"))}
//         </span>
//       </div>
//       <div className="action">
//         <BFButton
//           onClick={() => onDelete()}
//           appearance={"link"}
//           icon={{ type: "bf", data: "trash-can", size: "sm" }}
//         />
//       </div>
//     </div>
//   );
// };

import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import moment from "moment";
import { Trans } from "react-i18next";
import HistoryViewer from "../../../../components/HistoryViewer/HistoryViewer";
import { HistoryEntryObject } from "../../../../components/HistoryViewer/IHistoryEntry";
import i18n from "../../../../i18n";
import BaseAsset from "../../../../model/general-assets/BaseAsset";
import { BfIconProps } from "../../../../modules/abstract-ui/icon/BfIcon";
import LanguageService from "../../../../services/LanguageService";
import StringUtils from "../../../../utils/StringUtils";
import ACInvoiceUtils from "../../../utils/ACInvoiceUtils";
import ACProjectUtils from "../../../utils/ACProjectUtils";
import {
  getPaymentTypeLabel,
  RA_INVOICE_ADDITIONAL_INFOFIELDS,
  RA_INVOICE_MAIN_FIELDS,
} from "../RAUtils";

interface Props {
  entries: HistoryEntryObject[];
  asset: BaseAsset;
}

const Formatters = {
  invoiceType: (val: string) =>
    LanguageService.translateLabel(
      ACInvoiceUtils.getInvoiceType(val)?.data.displayName
    ),
  amountToPay: (val: number) => StringUtils.formatCurrency(val),
  paymentDueDate: (val: Date) =>
    moment(val).format(i18n.t("Formats.dateFormat")),
  documentDate: (val: Date) => moment(val).format(i18n.t("Formats.dateFormat")),
};

const getInvoiceOptions = (entry: HistoryEntryObject) => {
  switch (entry.type) {
    case "invoice_payed":
      return {
        text: i18n.t("ra:History.invoice_payed", "Rechnung wurde bezahlt"), //"Rechnung wurde geprüft",
        icon: {
          data: "accounting-bills-1",
          type: "light",
        },
      };
    case "invoice_payment":
      return {
        text: i18n.t("ra:History.invoice_payment", "Betrag wurde überwiesen"), //"Rechnung wurde geprüft",
        icon: {
          data: "credit-card-dollar",
          type: "light",
        },
      };
    case "invoice_checked":
      return {
        text: i18n.t("ra:History.invoice_checked_true"), //"Rechnung wurde geprüft",
        icon: {
          data: "check-double-1",
          type: "light",
        },
      };
    case "create":
      return {
        text: i18n.t("ra:History.create"), //"Rechnung wurde hochgeladen",
        icon: {
          data: "add",
          type: "light",
        } as BfIconProps,
      };
    case "invoice_decline":
      return {
        text: i18n.t("ra:History.invoice_decline"), //"Rechnung wurde abgelehnt",
        icon: {
          data: "subtract-circle",
          type: "light",
        },
      };
    case "invoice_approved":
      return {
        text: i18n.t("ra:History.invoice_approved", "Rechnung wurde genehmigt"),
        icon: {
          data: "check-circle",
          type: "light",
        },
      };
    case "invoice_approve_override":
      return {
        text: i18n.t(
          "ra:History.invoice_approve_override",
          "Rechnung wurde in Vertretung genehmigt"
        ),
        icon: {
          data: "check-circle",
          type: "light",
        },
      };
    case "invoice_booked":
      return {
        text: i18n.t("ra:History.invoice_booked"), //"Rechnung wurde gebucht",
        icon: {
          data: "accounting-invoice",
          type: "light",
        },
      };
    case "status_update":
      switch (entry.data.status) {
        case "booked":
          return null;
        case "inApproval":
          return {
            text: i18n.t("ra:History.status_update_inApproval"), //"Rechnung wurde zur Freigabe eingereicht",
            icon: {
              data: "space-rocket-flying",
              type: "light",
            },
          };
        case "needFollowUp":
          return {
            text: i18n.t("ra:History.needFollowUp"), //"Rechnung muss nachbearbeitet werden",
            icon: {
              data: "task-list-pin-1",
              type: "light",
            },
          };
        case "approved":
          return null;
        default:
          return {
            text: "?",
          };
      }
    case "change_debitCharge": {
      return {
        icon: {
          data: "saving-bank-1",
          type: "light",
        },
        text: entry.data.to ? (
          <>
            <span className="weight-600">
              {i18n.t("Global.Labels.activated")}
            </span>{" "}
            {i18n.t("ra:History.change_debitCharge")}
          </>
        ) : (
          <>
            <span className="weight-600">
              {i18n.t("Global.Labels.deactivated")}
            </span>{" "}
            {i18n.t("ra:History.change_debitCharge")}
          </>
        ),
      };
    }
    case "invoice_approve":
      return {
        text: i18n.t("ra:History.invoice_checked"), //"Genehmigung erteilt",
        icon: {
          data: "check-circle-1",
          type: "light",
        },
      };
    case "invoice_downloaded_for_booking":
      return {
        text: "Download der Rechnung für die Buchung",
        icon: {
          data: "download-bottom",
          type: "light",
        },
      };
    case "change_chain":
      return {
        text: (
          <Trans
            i18nKey="ra:History.change_chain"
            defaults="typefrom <0>{{from}}</0> to <1>{{to}}</1>" // optional defaultValue
            values={{
              from:
                LanguageService.translateLabel(
                  ACInvoiceUtils.getInvoiceType(entry.data.from)?.data
                    .displayName
                ) || "-",
              to:
                LanguageService.translateLabel(
                  ACInvoiceUtils.getInvoiceType(entry.data.to)?.data.displayName
                ) || "-",
            }}
            components={[
              <span className={`weight-600`}>first</span>,
              <span className={`weight-600`}>second</span>,
            ]}
          />
          // <Trans
          //   i18nKey="ra:History.change_chain"
          // >
          //   typefrom
          //   <span className="weight-600">
          //     {{
          //       from:
          //         LanguageService.translateLabel(
          //           ACInvoiceUtils.getInvoiceType(entry.data.from)?.data
          //             .displayName
          //         ) || "-",
          //     }}
          //   </span>{" "}
          //   to
          //   <span className="weight-600">
          //     {{
          //       to:
          //         LanguageService.translateLabel(
          //           ACInvoiceUtils.getInvoiceType(entry.data.to)?.data
          //             .displayName
          //         ) || "-",
          //     }}
          //   </span>
          // </Trans>
        ),
        icon: {
          data: "download-bottom",
          type: "light",
        },
      };
    case "change_paymentDueDate":
      return {
        text: (
          <Trans i18nKey="ra:History.change_paymentDueDate">
            pre
            <span className="weight-600">
              {{
                date: moment(entry.data.to).format(
                  i18n.t("Formats.dateFormat")
                ),
              }}
            </span>
            post
          </Trans>
        ),
        icon: {
          data: "calendar-cash-1",
          type: "light",
        },
      };

    case "change_invoiceType": {
      const entity =
        LanguageService.translateLabel(
          ACInvoiceUtils.getInvoiceType(entry.data.to)?.data.displayName
        ) || entry.data.toDisplayName;

      return {
        icon: {
          data: "archive-drawer",
          type: "light",
        },
        text: (
          <Trans i18nKey="ra:History.change_invoiceType">
            prefix <span className="weight-600">{{ entity }}</span> suffix
          </Trans>
        ),
      };
    }
    case "change_entity": {
      const entity =
        OrgaStruct.getEntity(entry.data.to)?.displayName ||
        entry.data.toDisplayName;

      return {
        icon: {
          data: "building-modern",
          type: "light",
        },
        text: (
          <Trans i18nKey="ra:History.change_entity">
            prefix <span className="weight-600">{{ entity }}</span>suffix
          </Trans>
        ),
      };
    }
    // TODO!
    // case "change_activity": {
    //   const activity = entry.data
    //   return {
    //     icon: {
    //       data: "building-modern",
    //       type: "light",
    //     },
    //     text: (
    //       <span>
    //         {i18n.t("ra:History.change_activity", "Aktivit wurde gesetzt auf")}:{" "}<ActivityLabel />
    //       </span>
    //       <Trans i18nKey="ra:History.change_activity">
    //         prefix <span className="weight-600">{{ project }}</span>suffix
    //       </Trans>
    //     ),
    //   };
    // }
    case "change_projectId": {
      const project =
        ACProjectUtils.getProject(entry.data.to)?.data.displayName ||
        entry.data.toDisplayName;

      return {
        icon: {
          data: "building-modern",
          type: "light",
        },
        text: (
          <Trans i18nKey="ra:History.change_project">
            prefix <span className="weight-600">{{ project }}</span>suffix
          </Trans>
        ),
      };
    }
    case "change_objectId": {
      const object =
        OrgaStruct.getObject(entry.data.to)?.displayName ||
        entry.data.toDisplayName;

      return {
        icon: {
          data: "house-1",
          type: "light",
        },
        text: (
          <Trans i18nKey="ra:History.change_objectId">
            Objekt wurde auf <span className="weight-600">{{ object }}</span>{" "}
            gesetzt.
          </Trans>
        ),
      };
    }
    case "change_paymentType": {
      const paymentType = getPaymentTypeLabel(entry.data.to);

      return {
        icon: {
          data: "check-payment-give",
          type: "light",
        },
        text: (
          <Trans i18nKey="ra:History.change_paymentType">
            prefix <span className="weight-600">{{ paymentType }}</span> suffix
          </Trans>
        ),
      };
    }
    case "change_field":
    case "change_info": {
      const RootField =
        entry.type === "change_field"
          ? RA_INVOICE_MAIN_FIELDS()
          : RA_INVOICE_ADDITIONAL_INFOFIELDS();
      const fieldName = RootField[entry.data.field]?.label || entry.data.field;

      const toValueFormatted =
        Formatters[entry.data.field]?.(entry.data.to) || entry.data.to;
      const fromValueFormatted =
        Formatters[entry.data.field]?.(entry.data.from) || entry.data.from;

      if (entry.data.from === null && entry.data.to !== null) {
        return {
          icon: {
            data: "pencil-2",
            type: "light",
          },
          text: (
            <Trans i18nKey="ra:History.change_info_add">
              <span className="weight-600">{{ fieldName }}</span> wurde
              hinzugefügt mit dem Wert '
              <span className="weight-600">{{ toValueFormatted }}</span>'
            </Trans>
          ),
        };
      }
      if (entry.data.from !== null && entry.data.to === null) {
        return {
          icon: {
            data: "pencil-2",
            type: "light",
          },
          text: (
            <Trans i18nKey="ra:History.change_info_remove">
              <span className="weight-600">{{ fieldName }}</span> wurde
              entfernt. Der bisherige Wert war '
              <span className="weight-600 strike-through">
                {{ fromValueFormatted }}
              </span>
              '
            </Trans>
          ),
        };
      }

      return {
        text: (
          <Trans i18nKey="ra:History.change_info_change">
            <span className="weight-600">{{ fieldName }}</span> wurde von '
            <span className="weight-600 strike-through">
              {{ fromValueFormatted }}
            </span>
            ' auf '<strong>{{ toValueFormatted }}</strong>' geändert.
          </Trans>
        ) as any,
        color: "green",
        icon: {
          data: "pencil-2",
          type: "light",
        } as BfIconProps,
      };
    }
    default:
      return {
        text: `invalid type - ${entry.type}`,
        color: "red",
      };
  }
};

const RAInvoiceHistory = (props: Props) => {
  return (
    <HistoryViewer
      asset={props.asset}
      entries={props.entries}
      entryOptionsFc={(entry) => getInvoiceOptions(entry)}
      filters={[
        {
          name: i18n.t("ra:History.Filters.RequiredFields"), //"Pflichtfelder",
          filterFC: (entry) => entry.type === "change_field",
        },
        {
          name: i18n.t("ra:History.Filters.ProcessSteps"), //"Prozessschritte",
          filterFC: (entry) => false,
        },
      ]}
    />
  );
};

export default RAInvoiceHistory;

export const PROCESS_STEPS = [
  "start_approval",
  "invoice_approve",
  "status_update",
  "invoice_decline",
];

import validator from "validator";
import IBAN from "../../../lib-overwrites/iban/iban";
import StringUtils from "../../../utils/StringUtils";
import ExpressionHelper from "./ExpressionHelper";
import {
  JsonPropertyArray,
  JsonPropertyCheckboxGroup,
  JsonPropertyComponent,
  JsonPropertyRadioGroup,
  JsonPropertySelect,
  OptionConditionGroup,
  OptionEntry,
  Validator,
} from "./JsonValidation";
import JsonValidationException from "./JsonValidationException";
import ValidatorsUtils from "./ValidatorsUtils";
export type ValidatorTypes = "required" | "condition" | string;

class ValidatorsClass {
  typesValidatioFuncs: {
    [key: string]: (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any },
      additionalProperties: any
    ) => boolean | any;
  } = {
    string: (value, jsonPropertyComp, jsonValue) => typeof value === "string",
    password: (value, jsonPropertyComp, jsonValue) => typeof value === "string",
    upload: (value, jsonPropertyComp, jsonValue) => typeof value === "object",

    website: (value, jsonPropertyComp, jsonValue) => {
      return (
        typeof value === "string" &&
        (ValidatorsUtils.isWebsiteString(value) || value === "")
      );
    },
    mail: (value, jsonPropertyComp, jsonValue) => {
      return (
        typeof value === "string" &&
        (ValidatorsUtils.isEmail(value) || value === "")
      );
    },
    text: (value, jsonPropertyComp, jsonValue) => typeof value === "string",
    textarea: (value, jsonPropertyComp, jsonValue) => typeof value === "string",
    number: (value, jsonPropertyComp, jsonValue) =>
      typeof value === "number" &&
      ((jsonPropertyComp as any).decimalFixed === undefined ||
        Number(value.toFixed((jsonPropertyComp as any).decimalFixed)) ===
          value),
    toggle: (value, jsonPropertyComp, jsonValue) => typeof value === "boolean",
    checkbox: (value, jsonPropertyComp, jsonValue) =>
      typeof value === "boolean",
    select: (value, jsonPropertyComp, jsonValue, additionalData) => {
      const props = jsonPropertyComp as JsonPropertySelect;
      let useOptions = [];
      if (props.options) {
        useOptions = props.options;
      } else if (props._options) {
        useOptions = ExpressionHelper.evaluateExpression(
          props._options,
          jsonValue,
          additionalData
        );
      }
      const options = Validators.getValidOptions(useOptions, jsonValue);

      if (props.multiple) {
        if (Array.isArray(value)) {
          let success = true;
          for (const val of value) {
            success = success && this.checkOptionValidity(val, options);
          }
          return success;
        } else {
          return false;
        }
      } else {
        return this.checkOptionValidity(value, options);
      }
    },
    radio: (value, jsonPropertyComp, jsonValue) => {
      const allowedOptions = this.getValidOptions(
        (jsonPropertyComp as JsonPropertyRadioGroup).options,
        jsonValue
      );
      return this.checkOptionValidity(value, allowedOptions);
    },
    //TODO validate array with the defined props
    array: (value, jsonPropertyComp, jsonValue) => {
      const props = jsonPropertyComp as JsonPropertyArray;
      if (props.max !== undefined && value.length > props.max) {
        return false;
      }
      return true;
    },
    "checkbox-group": (value, jsonPropertyComp, jsonValue) => {
      if (Array.isArray(value)) {
        const allowedOptions = this.getValidOptions(
          (jsonPropertyComp as JsonPropertyCheckboxGroup).options,
          jsonValue
        );
        let success = true;
        for (const val of value) {
          success = success && this.checkOptionValidity(val, allowedOptions);
        }
        return success;
      } else {
        return false;
      }
    },
    date: (value, jsonPropertyComp, jsonValue) =>
      new Date(value).toString() !== "Invalid Date",
  };

  registerType(
    typeIdentifier: string,
    validateTypeFunc: (
      value: any,
      jsonPropertyComp: JsonPropertyComponent,
      jsonValue: { [key: string]: any }
    ) => boolean | any
  ) {
    this.typesValidatioFuncs[typeIdentifier] = validateTypeFunc;
  }

  validateValue(
    fieldName: string,
    value: any,
    validatorObj: Validator,
    allValues: any
  ) {
    if (!this[validatorObj.type]) {
      throw new JsonValidationException(
        fieldName,
        `validator type ${validatorObj.type} doesn't exists`
      );
    }

    if (
      validatorObj.condition &&
      !ExpressionHelper.evaluateExpression(validatorObj.condition, allValues)
    ) {
      // this validator should not be evaluated -> return true
      return true;
    }

    const success = this[validatorObj.type](
      value,
      fieldName,
      allValues,
      validatorObj.param
    );

    return success;
  }

  getValidValue(
    value: any,
    options: OptionEntry[],
    jsonValue: { [key: string]: any }
  ) {
    const validOptions = this.getValidOptions(options, jsonValue);
    if (Array.isArray(value)) {
      const values = new Set();

      const goThroughOptions = (mOptions: OptionEntry[]) => {
        mOptions.forEach((option) => {
          if (value.indexOf(option.value) !== -1) {
            values.add(option.value);
          }
          if (option.children) {
            goThroughOptions(option.children);
          }
        });
      };
      goThroughOptions(validOptions);

      return Array.from(values.values());
    } else {
      let foundValue = "";
      const findValueInOptions = (options: OptionEntry[]) => {
        options.forEach((option) => {
          if (foundValue) return;
          if (value === option.value) {
            foundValue = option.value;
          }
          if (option.children && !foundValue) {
            findValueInOptions(option.children);
          }
        });
      };
      findValueInOptions(validOptions);
      return foundValue;
    }
  }

  getValidOptions(
    options: (OptionEntry | OptionConditionGroup)[],
    jsonValue: { [key: string]: any }
  ) {
    let allowedOptions = [];
    (options || []).forEach((option) => {
      if ((option as OptionConditionGroup).condition) {
        if (
          ExpressionHelper.evaluateExpression(
            (option as OptionConditionGroup).condition,
            jsonValue
          )
        ) {
          allowedOptions = allowedOptions.concat(
            this.getValidOptions(
              (option as OptionConditionGroup).options,
              jsonValue
            )
          );
        }
      } else {
        const opt = option as OptionEntry;
        if (opt.children) {
          allowedOptions.push({
            ...opt,
            children: this.getValidOptions(opt.children, jsonValue),
          });
        } else {
          allowedOptions.push(option);
        }
      }
    });
    return allowedOptions;
  }

  checkOptionValidity(value: any, options: OptionEntry[]) {
    var isValid = false;

    options.forEach((e) => {
      if (e.children) {
        isValid = isValid || this.checkOptionValidity(value, e.children);
      } else {
        isValid = isValid || e.value === value;
      }
    });
    return isValid;
  }

  /**
   * validates a value against its type, checks in selects if the values are in the options
   * @param value
   * @param jsonPropertyComp
   */
  validateType(
    value: any,
    jsonPropertyComp: JsonPropertyComponent,
    jsonValue: { [key: string]: any },
    additionalData: any
  ) {
    if (value === null || value === undefined) {
      return true;
    }
    const typeValidationFunc =
      this.typesValidatioFuncs[jsonPropertyComp._component];
    if (typeValidationFunc && typeof typeValidationFunc === "function") {
      return typeValidationFunc(
        value,
        jsonPropertyComp,
        jsonValue,
        additionalData
      );
    } else {
      return false;
    }
  }

  required(value: any, fieldName?: string, allValues?: any, param?: any) {
    if (
      value === null ||
      value === undefined ||
      (typeof value === "string" && value.trim() === "") ||
      value.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  email(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return ValidatorsUtils.isEmail(value);
  }

  currency(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isCurrency(value, param);
  }

  iban(value: any, fieldName?: string, allValues?: any, param?: any) {
    if (!value) {
      return true;
    }
    return IBAN.isValid(StringUtils.ibanToISO(value));
  }

  creditCard(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isCreditCard(value);
  }

  alphanumeric(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isAlphanumeric(value);
  }

  contains(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.contains(value, param);
  }

  hexolor(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isHexColor(value);
  }

  decimal(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isDecimal(value, param);
  }

  mobilephone(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isMobilePhone(value, param);
  }

  postalcode(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return validator.isPostalCode(value, param);
  }

  condition(value: any, fieldName: string, allValues: any, param?: any) {
    return ExpressionHelper.evaluateExpression(param, allValues);
  }

  maxLength(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return value.length <= param;
  }
  minLength(value: any, fieldName: string, allValues: any, param?: any) {
    if (!value) {
      return true;
    }
    return value.length >= param;
  }
  max(value: any, fieldName: string, allValues: any, param?: any) {
    if (value === undefined && value === null && value === "") {
      return true;
    }
    return value <= param;
  }
  min(value: any, fieldName: string, allValues: any, param?: any) {
    if (value === undefined && value === null && value === "") {
      return true;
    }
    return value >= param;
  }
  maxExclude(value: any, fieldName: string, allValues: any, param?: any) {
    if (value === undefined && value === null && value === "") {
      return true;
    }
    return value < param;
  }
  minExclude(value: any, fieldName: string, allValues: any, param?: any) {
    if (value === undefined && value === null && value === "") {
      return true;
    }
    return value > param;
  }
}

const Validators = new ValidatorsClass();
export default Validators;

import classNames from "classnames";
import AssetLoader from "../../components/AssetLoader/AssetLoader";
import StructLoader from "../../components/StructLoader/StructLoader";
import { AssetTypes } from "../../model/AssetTypes";
import { Contact } from "../../model/db/Contact";
import UnitStruct from "../../redux/actions/struct/implemented/UnitStruct";
import { PlacementAll } from "../abstract-ui/common/Placements";
import BFOverlay from "../abstract-ui/general/whisper/BFOverlay";
import "./ContactLabel.scss";
import ContactView from "./ContactView";

interface ContactLabelProps {
  contact: Contact;
  withType?: boolean;
  placement?: PlacementAll;
}
interface ContactLoadLabelProps {
  contactId: string;
  withType?: boolean;
  placement?: PlacementAll;
}
const ContactLabel = (props: ContactLabelProps | ContactLoadLabelProps) => {
  if ((props as ContactLabelProps).contact) {
    return (
      <ContactLabelWithData
        contact={(props as ContactLabelProps).contact}
        withType={props.withType}
        placement={props.placement}
      />
    );
  } else {
    return (
      <ContactLabelLoader
        contactId={(props as ContactLoadLabelProps).contactId}
        withType={props.withType}
        placement={props.placement}
      />
    );
  }
};

export default ContactLabel;

const ContactLabelWithData = (props: ContactLabelProps) => {
  return (
    <BFOverlay
      delay={500}
      enterable
      placement={props.placement}
      speaker={
        <div className={`contact-view-container`}>
          <ContactView contact={props.contact} />
        </div>
      }
    >
      <span className={classNames(`contact-label`)}>
        {props.withType && (
          <StructLoader
            unitType={props.contact?.data?.type}
            structType="unit"
            render={() => {
              return (
                <span className={`business-type`}>
                  {UnitStruct.getUnitLabel(props.contact?.data?.type) ||
                    props.contact?.data?.type}
                </span>
              );
            }}
          />
        )}
        {props.contact?.data?.displayName}
      </span>
    </BFOverlay>
  );
};

const ContactLabelLoader = (props: ContactLoadLabelProps) => {
  if (!props.contactId) {
    return <>-</>;
  }
  return (
    <AssetLoader
      assetType={AssetTypes.Contact}
      id={props.contactId}
      render={(contact: Contact) => (
        <ContactLabelWithData
          withType={props.withType}
          placement={props.placement}
          contact={contact}
        />
      )}
    />
  );
};

import { ElementQueries } from "css-element-queries";
import React from "react";
import DashboardAppDrawer from "./implemented/general/DashboardAppDrawer";
import DashboardNotifications from "./implemented/general/DashboardNotifications";
import DashboardResubmission from "./implemented/general/resubmission/DashboardResubmission";

export const DashboardMapper = {
  "dashboard.general.notifications": DashboardNotifications,
  "dashboard.general.appdrawer": DashboardAppDrawer,
  "dashboard.general.resubmission": DashboardResubmission,
  "dashboard.task.openApprovals": React.lazy(() =>
    import("./implemented/task/TaskOpenApprovals").finally(() => {
      ElementQueries.init();
    })
  ),
  "dashboard.invoice.paymentApprovals": React.lazy(() =>
    import("./implemented/invoice/RAPaymentApproval").finally(() => {
      ElementQueries.init();
    })
  ),
};

import { USABLE_PAGE_WIDTH } from "@/components/PDFConstructor/services/PDFConfigParser";
import { ChartStyle } from "@/components/PDFConstructor/services/types/pdfConfigUtilTypes";
import i18n from "@/i18n";
import NumberUtils from "@/utils/ NumberUtils";

import { PDFNumericSlider } from "../../../Common/PDFNumericSlider";

type PDFChartFooterInputProps = {
  value: ChartStyle;
  onChange: <Key extends keyof ChartStyle>(
    key: Key,
    value: ChartStyle[Key]
  ) => void;
};

const PDFChartFooterInput: React.FC<PDFChartFooterInputProps> = ({
  value,
  onChange,
}) => {
  return (
    <PDFNumericSlider
      label={i18n.t("Component.PDFConstructor.Block.Width", "Breite")}
      className="chart-footer-input-numeric-slide"
      step={1}
      max={100}
      min={1}
      value={NumberUtils.getPercentage(value.width, USABLE_PAGE_WIDTH)}
      onChange={(value) => {
        onChange(
          "width",
          NumberUtils.getValueFromPercentage(Number(value), USABLE_PAGE_WIDTH)
        );
      }}
    />
  );
};

export { PDFChartFooterInput };

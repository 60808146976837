import { createContext, useContext, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";
import {
  PDFConstructorActions,
  pdfConstructorReducer,
  PDFConstructorState,
} from "./PDFConstructorReducer";
import { BlockConfig } from "../services/types/pdfConfigBlockTypes";
import {
  PDFDocumentDescriptionActions,
  pdfDocumentDescriptionReducer,
} from "./PDFDocumentDescriptionReducer";
import { HTTP } from "@/utils/Http";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import {
  assignPropertiesToConfigElements,
  loadImages,
  processStackingPlan,
} from "./PDFReducerUtils";
import {
  PDFDocumentDescription,
  PDFDocumentDescriptionContent,
  PDFDocumentDescriptionStackingPlanConfig,
} from "../services/types/pdfConfigNodeTypes";
import { requestConfigParsing } from "../actions/templateActions";
import Toast from "@/modules/abstract-ui/notification/Toast";
import i18n from "@/i18n";
import { ObjectGalleryConfig } from "../services/types/pdfConfigObjectBlockTypes";

export const PDFConstructorContext = createContext<
  [
    {
      state: PDFConstructorState;
      document: PDFDocumentDescription;
      asset: OAObject;
      language: string;
    },
    {
      addBlock: (block: BlockConfig, parentId: number) => void;
      addTemplate: (templateId: string, assetId: string) => void;
      saveBlock: (block: BlockConfig, parentId: number) => void;
      editBlock: (block: BlockConfig) => void;
      deleteBlock: (block: BlockConfig) => void;
      swapBlocks: (dragId: number, hoverId: number, parentId: number) => void;
      revertSwap: (
        blockId: number,
        originalIndex: number,
        parentId: number
      ) => void;
      rerenderDocument: (
        dragId: number,
        hoverId: number,
        parentId: number
      ) => void;
      setLanguage: (language: string) => void;
    }
  ]
>(null);

const initialState: PDFConstructorState = {
  content: [],
};

const prepareBlock = (block: BlockConfig) => {
  if (block.type === "object-gallery") {
    const { assetId: _, ...block_ } = block as ObjectGalleryConfig;
    return {
      ...block_,
      images: block_.images.map((image) => ({
        bucket: image.bucket,
        key: image.key,
      })),
    };
  }

  return block;
};

const requestNodeParsing = async (
  block: BlockConfig,
  language: string
): Promise<PDFDocumentDescriptionContent> => {
  const response = await HTTP.post({
    url: "/pdfConstructor",
    bodyParams: {
      node: prepareBlock(block),
      language,
    },
  });

  return response.node;
};

export const PDFConstructorProvider: React.FC<{
  children: React.ReactNode;
  asset: OAObject;
}> = ({ asset, children }) => {
  const [state, dispatch] = useImmerReducer(
    pdfConstructorReducer,
    initialState
  );

  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    if (state.content.length > 0) {
      updateConfigLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const [documentDescription, dispatchDocumentDescription] = useImmerReducer(
    pdfDocumentDescriptionReducer,
    {
      content: [],
      header: null,
      footer: null,
    }
  );

  const updateConfigLanguage = async (language: string) => {
    const parsedConfig = await requestConfigParsing(
      state.content.map((block) => prepareBlock(block) as BlockConfig),
      language
    );

    dispatchDocumentDescription({
      type: PDFDocumentDescriptionActions.EDIT_MULTIPLE,
      payload: {
        config: await Promise.all(
          parsedConfig.map(async (node) => {
            const nodeWithImage = await loadImages(node);
            return nodeWithImage;
          })
        ),
      },
    });
  };

  const addBlock = async (block: BlockConfig, parentId: number) => {
    dispatch({
      type: PDFConstructorActions.ADD,
      payload: {
        block,
        parentId,
      },
    });

    if (!block.isEmpty) {
      await saveBlock(block, parentId);
      return;
    }
  };

  const addTemplate = async (templateId: string, assetId: string) => {
    const template = (await HTTP.get({
      url: `/pdfConstructor/template/${templateId}`,
    })) as {
      template: {
        data: {
          config: Omit<BlockConfig, "id">[];
        };
      };
    };

    if (!template) {
      return;
    }

    const processedConfig = assignPropertiesToConfigElements(
      template.template.data.config,
      assetId
    );

    try {
      const parsedConfig = await requestConfigParsing(
        processedConfig.map((block) => prepareBlock(block) as BlockConfig),
        language
      );

      dispatch({
        type: PDFConstructorActions.TEMPLATE,
        payload: {
          config: processedConfig,
        },
      });

      const config = await Promise.all(
        parsedConfig.map(async (node) => {
          if (node.config) {
            return await processStackingPlan(
              node as PDFDocumentDescriptionStackingPlanConfig
            );
          }

          const nodeWithImage = await loadImages(node);
          return nodeWithImage;
        })
      );

      console.log(config);

      dispatchDocumentDescription({
        type: PDFDocumentDescriptionActions.TEMPLATE,
        payload: {
          config,
        },
      });
    } catch {
      console.error("error");
      Toast.error("Failed to save the template");
    }
  };

  console.log(documentDescription);

  const saveBlock = async (block: BlockConfig, parentId: number) => {
    try {
      const node = await requestNodeParsing(block, language);

      dispatch({
        type: PDFConstructorActions.SAVE,
        payload: {
          id: block.id,
        },
      });

      await loadImages(node);
      if (block.type === "object-stacking-plan") {
        dispatchDocumentDescription({
          type: PDFDocumentDescriptionActions.ADD,
          payload: {
            parentId,
            block: await processStackingPlan(
              node as PDFDocumentDescriptionStackingPlanConfig
            ),
            type: block.type,
          },
        });
        return;
      }

      dispatchDocumentDescription({
        type: PDFDocumentDescriptionActions.ADD,
        payload: {
          parentId,
          block: node,
          type: block.type,
        },
      });
    } catch {
      Toast.error(
        i18n.t(
          "Component.PDFConstructor.Error.BlockError",
          "Block konnte nicht hinzugefügt werden"
        )
      );
    }
  };

  const editBlock = async (block: BlockConfig) => {
    try {
      dispatch({
        type: PDFConstructorActions.EDIT,
        payload: block,
      });

      if (block.isEmpty) {
        return;
      }

      const node = await requestNodeParsing(block, language);
      await loadImages(node);

      if (block.type === "object-stacking-plan") {
        dispatchDocumentDescription({
          type: PDFDocumentDescriptionActions.EDIT,
          payload: {
            id: block.id,
            block: await processStackingPlan(
              node as PDFDocumentDescriptionStackingPlanConfig
            ),
            type: block.type,
          },
        });
        return;
      }

      dispatchDocumentDescription({
        type: PDFDocumentDescriptionActions.EDIT,
        payload: {
          id: block.id,
          block: node,
          type: block.type,
        },
      });
    } catch {}
  };

  const deleteBlock = (block: BlockConfig) => {
    dispatch({
      type: PDFConstructorActions.DELETE,
      payload: block,
    });

    dispatchDocumentDescription({
      type: PDFDocumentDescriptionActions.DELETE,
      payload: {
        id: block.id,
        type: block.type,
      },
    });
    // }
  };

  const swapBlocks = (dragId: number, hoverId: number, parentId: number) => {
    dispatch({
      type: PDFConstructorActions.SWAP,
      payload: {
        dragId,
        hoverId,
        parentId,
      },
    });
  };

  const revertSwap = (
    blockId: number,
    originalIndex: number,
    parentId: number
  ) => {
    dispatch({
      type: PDFConstructorActions.REVERT_SWAP,
      payload: {
        blockId,
        originalIndex,
        parentId,
      },
    });
  };

  const rerenderDocument = (
    dragId: number,
    hoverId: number,
    parentId: number
  ) => {
    dispatchDocumentDescription({
      type: PDFDocumentDescriptionActions.SWAP,
      payload: {
        dragId,
        hoverId,
        parentId,
      },
    });
  };

  return (
    <PDFConstructorContext.Provider
      value={[
        { state, document: documentDescription, asset, language },
        {
          addBlock,
          addTemplate,
          saveBlock,
          editBlock,
          deleteBlock,
          swapBlocks,
          revertSwap,
          rerenderDocument,
          setLanguage,
        },
      ]}
    >
      {children}
    </PDFConstructorContext.Provider>
  );
};

export const usePDFConstructor = () => {
  const context = useContext(PDFConstructorContext);

  if (!context) {
    throw new Error("Can't use the hook outside of PDFConstructorContext");
  }

  return context;
};

import { ContextData } from "../../../model/db/ContextData";
import { User, UserConfig } from "../../../model/db/User";
import { JsonProperties } from "../../../modules/generic-forms/util/JsonValidation";
import DataBus from "../../../services/DataBus";
import { MandatorData } from "../../reducers/global/GlobalInterface";

export const SET_ASSET_TYPE_CONF = "SET_ASSET_TYPE_CONF";
export const SET_CONTEXT_DATA = "SET_CONTEXT_DATA";
export const SET_MANDATOR_DATA = "SET_MANDATOR_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_USER_CONFIG = "UPDATE_USER_CONFIG";
export const SET_GLOBAL_CACHE_DATA = "SET_GLOBAL_CACHE_DATA";
export const MERGE_GLOBAL_CONFIG = "MERGE_GLOBAL_CONFIG";
export const ADD_TRUSTED_DEVICE = "ADD_TRUSTED_DEVICE";
export const REMOVE_TRUSTED_DEVICE = "REMOVE_TRUSTED_DEVICE";

interface MergeGlobalConfigAction {
  type: typeof MERGE_GLOBAL_CONFIG;
  config: any;
}

interface SetAssetTypeConfig {
  type: typeof SET_ASSET_TYPE_CONF;
  assetTypeConfigs: {
    name: string;
    formDefinition: { [key: string]: { [key: string]: any } };
    properties: JsonProperties;
  }[];
}

interface SetUserAction {
  type: typeof SET_USER_DATA;
  user: User;
}
interface SetContextData {
  type: typeof SET_CONTEXT_DATA;
  context: ContextData;
}

interface SetMandatorData {
  type: typeof SET_MANDATOR_DATA;
  mandator: MandatorData;
}

interface UpdateUserConfig {
  type: typeof UPDATE_USER_CONFIG;
  config: UserConfig;
}

interface SetGlobalCacheData {
  type: typeof SET_GLOBAL_CACHE_DATA;
  key: string;
  data?: any;
  ttl?: number;
  timestamp: number;
  error?: any;
  loading?: boolean;
}

interface AddTrustedDevice {
  type: typeof ADD_TRUSTED_DEVICE;
  deviceName: string;
  deviceID: string;
  token: string;
  appName: string;
  creationTime?: Date;
  lastUsed?: Date;
}
interface RemoveTrustedDevice {
  type: typeof REMOVE_TRUSTED_DEVICE;
  deviceID: string;
}

export type GlobalAction =
  | SetContextData
  | SetMandatorData
  | SetUserAction
  | UpdateUserConfig
  | SetGlobalCacheData
  | SetAssetTypeConfig
  | MergeGlobalConfigAction
  | AddTrustedDevice
  | RemoveTrustedDevice;

setTimeout(() => {
  DataBus.subscribeReduxActions([SET_USER_DATA]);
});

import { Field } from "react-final-form";

interface FieldsProps {
  names: string[];
  children: (values: any[]) => JSX.Element;
  allProps?: boolean;
}
const FormFieldValues = (props: FieldsProps) => {
  if (props.names.length === 0) {
    return props.children([]);
  }
  return <FieldWrapper {...props} index={0} values={[]} />;
};

export default FormFieldValues;

const FieldWrapper = (
  props: FieldsProps & { index: number; values: any[] }
) => {
  return (
    <Field name={props.names[props.index]} allowNull>
      {(fieldProps) => {
        if (props.index === props.names.length - 1) {
          return props.children([
            ...props.values,
            props.allProps ? fieldProps : fieldProps.input.value,
          ]);
        } else {
          return (
            <FieldWrapper
              {...props}
              index={props.index + 1}
              values={[
                ...props.values,
                props.allProps ? fieldProps : fieldProps.input.value,
              ]}
            />
          );
        }
      }}
    </Field>
  );
};

import { Component } from "react";

type Props = {
  zoom?: boolean;
  swiperWidth?: number | string;
};

type States = {};

class SwiperPage extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return true;
  }

  render() {
    return (
      <div className="swiper-slide" style={{ width: this.props.swiperWidth }}>
        {this.props.zoom ? (
          <div className="swiper-zoom-container">{this.props.children}</div>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

export default SwiperPage;

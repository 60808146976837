import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import classNames from "classnames";
import { useEffect, useState } from "react";
import i18n from "../../../i18n";
import BFDropdown from "../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import DataBusDefaults from "../../../services/DataBusDefaults";
import PermissionService from "../../../services/PermissionService";
import StorageUtils from "../../../utils/StorageUtils";
import "./BusinessUnitSelectionComponent.scss";

interface BusinessUnitSelectionComponentProps {
  checkBusinessUnitRoles: string[];
  storageKey?: string;
}
const BusinessUnitSelectionComponent = (
  props: BusinessUnitSelectionComponentProps
) => {
  const [availableBusinessUnits, setAvailableBusinessUnits] =
    useState<string[]>(null);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<string[]>(
    props.storageKey
      ? JSON.parse(StorageUtils.SessionStorage.get(props.storageKey) || "[]")
      : []
  );

  useEffect(() => {
    const units = new Set<string>();
    props.checkBusinessUnitRoles.forEach((role) => {
      PermissionService.hasBusinessUnitRole(role, true)?.forEach((unit) => {
        units.add(unit);
      });
    });
    setAvailableBusinessUnits(Array.from(units));
  }, []);

  useEffect(() => {
    PermissionService.setBusinessUnitFilters(
      selectedBusinessUnits.length === 0 ? null : selectedBusinessUnits
    );

    if (props.storageKey) {
      StorageUtils.SessionStorage.save(
        props.storageKey,
        JSON.stringify(selectedBusinessUnits)
      );
    }
  }, [selectedBusinessUnits]);

  const triggerReload = () => {
    DataBusDefaults.route({
      route: window.location.pathname,
    });
    // DataBusDefaults.route({
    //   route: "/",
    //   stayInApp: true,
    // });
  };

  if (availableBusinessUnits === null || availableBusinessUnits.length === 1)
    return null;

  let label = "";

  if (
    (selectedBusinessUnits || []).length === 0 ||
    (selectedBusinessUnits || []).length === availableBusinessUnits.length
  ) {
    label = i18n.t("BusinessUnitSelection.AllUnits", "Alle Bereiche");
  } else if (selectedBusinessUnits.length === 1) {
    label =
      i18n.t("BusinessUnitSelection.Unit", "Bereich") +
      ": " +
      UnitStruct.getUnitLabel(selectedBusinessUnits[0]);
  } else {
    label =
      selectedBusinessUnits.length +
      " " +
      i18n.t("BusinessUnitSelection.Units", "Bereiche");
  }

  return (
    <BFDropdown
      className={classNames(
        `business-unit-selection-component`,
        "sidenav-dropdown",
        { open: "active" }
      )} //   open={open}
      //   onClick={() => setOpen(!open)}

      label={label}
      items={availableBusinessUnits.map((unit) => ({
        type: "check",
        className: "subpage-action",
        text: UnitStruct.getUnitLabel(unit),
        checked: selectedBusinessUnits?.includes(unit) || false,
        onCheck: (checked) => {
          if (checked) {
            setSelectedBusinessUnits([...(selectedBusinessUnits || []), unit]);
          } else {
            setSelectedBusinessUnits(
              (selectedBusinessUnits || []).filter((u) => u !== unit)
            );
          }
          setTimeout(() => {
            triggerReload();
          });
        },
        closeOnClick: false,
      }))}
      appearance="transparent-light"
      // toggleAs={BFButton}
    />
  );
};

export default BusinessUnitSelectionComponent;

import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { useState } from "react";
import "./PDFBlock.scss";
import { useDraggablePDFBlock } from "./PDFBlockHooks";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import {
  BasePDFBlockElementProps,
  DraggablePDFBlock,
} from "../common/PDFBlockTypes";

import { BlockConfig } from "../services/types/pdfConfigBlockTypes";
import { Button } from "rsuite";
import { usePDFConstructor } from "../context/PDFConstructorContext";

export type PDFBlockProps = {
  title: () => string;
  children: React.ReactElement | React.ReactElement[];
} & DraggablePDFBlock;

const PDFBlock: React.FC<PDFBlockProps> = ({
  title,
  id,
  parentId,
  index,
  children,
}) => {
  const [_, { swapBlocks, rerenderDocument, revertSwap }] = usePDFConstructor();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [ref, { isDragging, handlerId, drag }] = useDraggablePDFBlock(
    {
      id,
      index,
      parentId,
    },
    (dragId, hoverId) => swapBlocks(dragId, hoverId, parentId),
    (dragId, hoverId) => rerenderDocument(dragId, hoverId, parentId),
    (blockId, index) => revertSwap(blockId, index, parentId)
  );

  return (
    <div
      ref={ref}
      className="pdf-constructor--block __card"
      style={{ opacity: isDragging ? 0 : 1 }}
      data-handler-id={handlerId}
    >
      <header className="header">
        <Button
          type="button"
          appearance="link"
          className="bf-button jump"
          ref={drag}
        >
          <BfIcon
            type="light"
            data="select-drag-reorder-dots"
            size="xs"
            color="red"
          />
        </Button>

        <BFButton
          className="pdf-constructor--block-title __card-title"
          appearance="clear-on-white"
          onClick={() => setIsCollapsed((isCollapsed) => !isCollapsed)}
        >
          {title()}

          {!isCollapsed ? (
            <i className="icon icon-keyboard-arrow-up"></i>
          ) : (
            <i className="icon icon-keyboard-arrow-down"></i>
          )}
        </BFButton>
      </header>

      {!isCollapsed && <main>{children}</main>}
    </div>
  );
};

export const withPDFBlock = <
  E extends BlockConfig,
  T extends BasePDFBlockElementProps<E> = BasePDFBlockElementProps<E>
>(
  Element: React.FC<T>,
  title: () => string
) => {
  return (props: T & DraggablePDFBlock) => {
    return (
      <PDFBlock title={title} {...props}>
        {<Element {...props} />}
      </PDFBlock>
    );
  };
};

export { PDFBlock };

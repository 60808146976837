import classNames from "classnames";
import React, { useState } from "react";
import { TagPicker } from "rsuite";
import i18n from "../../../../i18n";
import { Placement } from "../../../abstract-ui/common/Placements";
import BfIcon from "../../../abstract-ui/icon/BfIcon";
import "./EZTags.scss";

interface EZTagOption {
  label: string;
  value: string;
  disabled?: boolean;
}
interface EZTagsProps {
  className?: string;
  value: string[];
  onChange: (value: string[]) => void;
  onBlur?: (ev, value: string[]) => void;
  data: EZTagOption[];
  readonly?: boolean;
  onClose?: () => void;
}
const EZTags = (props: EZTagsProps) => {
  const ref = React.useRef(null);
  const [placement, setPlacement] = useState<Placement>(null);
  return (
    <div
      ref={ref}
      className={classNames(`ez-tags`, { readonly: props.readonly })}
    >
      <div className={`tag-icon`}>
        <BfIcon data="tags-1" type="light" size="xs" />
      </div>
      <TagPicker
        menuClassName="ez-tags-menu"
        onClose={props.onClose}
        onEntering={() => {
          if (ref.current?.getBoundingClientRect().top > 400) {
            setPlacement("topStart");
          } else {
            setPlacement("bottomStart");
          }
        }}
        menuAutoWidth={false}
        placement={placement}
        placeholder={i18n.t("EzForm.EZTags.placeholder", "Tags auswählen")}
        creatable={false}
        data={props.data.filter((e) => {
          if (e.disabled) {
            if ((props.value || []).includes(e.value)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })}
        disabledItemValues={props.data
          .filter((e) => e.disabled)
          .map((e) => e.value)}
        value={props.value || []}
        cleanable={false}
        onChange={(value) => {
          props.onChange(value);
          props.onBlur(null, value);
        }}
        size="lg"
        plaintext={props.readonly}
      />
    </div>
  );
};

export default EZTags;

import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import classNames from "classnames";
import {
  getConfigRentalStatus,
  getConfigRentalUnitTypeGroup,
} from "../../../../../../apps/tatar/cashBudget/views/tenants/CBTenantsConst";
import { RentalUnit } from "../../../../../../apps/tatar/cashBudget/views/tenants/TenantsInterfaces";
import i18n from "../../../../../../i18n";
import LanguageService from "../../../../../../services/LanguageService";
import StringUtils from "../../../../../../utils/StringUtils";
import BFStatus from "../../../../../abstract-ui/data/status/BFStatus";
import LabeledInput from "../../../../../abstract-ui/forms/LabeledInput";
import { Resubmission } from "../../../../RSInterfaces";
import RSAssetDetails from "../../../asset-details/RSAssetDetails";
import RSSubAssetMapper from "../../../asset-details/sub-asset-mapper/RSSubAssetMapper";
import "./RSRentalUnitPreview.scss";

interface RSRentalUnitPreviewProps {
  resubmission: Resubmission;
}

const RSRentalUnitPreview = (props: RSRentalUnitPreviewProps) => {
  const { linkedAsset } = props.resubmission.data;

  const renderTypeInfoDetails = (rentalUnit: RentalUnit) => {
    return (
      <RSSubAssetMapper resubmission={props.resubmission} asset={rentalUnit} />
    );
  };

  return (
    <div className={classNames("rs-rental-unit")}>
      <LabeledInput
        type="sub"
        label={i18n.t("Resubmission.Dashboard.RentalUnit.Title", "Mieteinheit")}
      >
        <RSAssetDetails
          assetParams={linkedAsset}
          renderAssetData={(asset: RentalUnit) => {
            if (asset === null || asset === undefined) {
              return (
                <div className="rs-asset-not-found">
                  {i18n.t(
                    "Resubmission.Error.RentalVacancyNotFound",
                    "Keine Leerstand Daten gefunden"
                  )}
                </div>
              );
            }

            const rentalStatus = getConfigRentalStatus(asset.data.rentalStatus);
            const unit = ObjectKindStruct.getUnitTypeBy(asset.data.unitType);
            const unitTypeGroup = getConfigRentalUnitTypeGroup(unit?.group);

            return (
              <div className="rental-unit-container">
                <div className={`entry-list`}>
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t(
                        "Resubmission.Dashboard.RentalUnit.Object",
                        "Objekt"
                      )}
                    </div>
                    <div className={`value`}>
                      {OrgaStruct.getObject(asset.data.objectId)?.id} -{" "}
                      {OrgaStruct.getObject(asset.data.objectId)?.displayName}
                    </div>
                  </div>
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t(
                        "Resubmission.Dashboard.RentalUnit.Unit",
                        "Einheit"
                      )}
                    </div>
                    <div className={`value`}>
                      {LanguageService.translateLabel(asset.data.displayName)}
                    </div>
                  </div>
                </div>
                <div className={`entry-list`}>
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t("cb:RentalUnit.Labels.RentalStatus", "Status")}
                    </div>
                    <div className={`value`}>
                      {rentalStatus ? (
                        <BFStatus
                          size="sm"
                          color={rentalStatus.color}
                          label={rentalStatus.label}
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>

                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t("cb:RentalUnit.Labels.unitTypeGroup", "Nutzart")}
                    </div>
                    <div className={`value`}>
                      {unitTypeGroup ? (
                        <BFStatus
                          size="sm"
                          color={unitTypeGroup.color}
                          label={unitTypeGroup.label}
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t("cb:RentalUnit.Labels.unit", "Art")}
                    </div>
                    <div className={`value`}>
                      {unit
                        ? LanguageService.translateLabel(unit.displayName)
                        : "-"}
                    </div>
                  </div>

                  <div className={`entry`}>
                    <div className={`label`}>
                      {unitTypeGroup.areaType === "area"
                        ? i18n.t("cb:RentalUnit.Labels.Area", "Fläche")
                        : i18n.t("cb:RentalUnit.Labels.Count", "Anzahl")}
                    </div>
                    <div className={`value`}>
                      {unitTypeGroup.areaType === "area"
                        ? `${StringUtils.formatNumber(
                            asset.data.quantity
                          )} ${StringUtils.getAreaUnit()}`
                        : asset.data.quantity}
                    </div>
                  </div>
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t("cb:RentalUnit.Labels.Floor", "Etage/Ebene")}
                    </div>
                    <div className={`value`}>{asset.data.floor}</div>
                  </div>
                  <div className={`entry`}>
                    <div className={`label`}>
                      {i18n.t("cb:RentalUnit.Labels.Building", "Gebäude")}
                    </div>
                    <div className={`value`}>{asset.data.building || "-"}</div>
                  </div>
                </div>
                <div className={`entry-list`}>
                  <div className={`entry`}>{renderTypeInfoDetails(asset)}</div>
                </div>
              </div>
            );
          }}
        />
      </LabeledInput>
    </div>
  );
};

export default RSRentalUnitPreview;

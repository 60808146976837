import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ModalManager from "../../components/ModalComponent/ModalManager";
import i18n from "../../i18n";
import { hasValue, isDefined } from "../../utils/Helpers";
import StringUtils from "../../utils/StringUtils";
import BFButton from "../abstract-ui/general/Button/BFButton";
import BFDropdown from "../abstract-ui/general/Dropdown/BFDropdown";
import CashBudgetTable, {
  CashBudgetColumnConfig,
  CashBudgetRowConfig,
  ValueData,
} from "../cashbudget-table/CashBudgetTable";
import "./PlanTable.scss";
import PlanTableGroupManage from "./PlanTableGroupManage";
import PlanTableTimespanManage from "./PlanTableTimespanManage";

export type PlainTableGroup = {
  id: string;
  name: string;
  children?: PlainTableGroup[];
};
export type PlanTableEntry = {
  date: Date;
  values: {
    [groupId: string]: number;
  };
};

export type PlanTableValue = {
  from: Date;
  to: Date;
  entries: PlanTableEntry[];
  groups: PlainTableGroup[];
};

interface PlanTableProps {
  type?: "day" | "month";
  startBudget?: number;
  value: PlanTableValue;
  onChange: (value: PlanTableValue) => void;
  onDoubleClick?: (value: any, index?: number) => void;
  actualValue?: PlanTableEntry[];
  readonly?: boolean;
  title?: string;
  fixedPositionIds?: string[];
  planType: "timespanAndGroups" | "fixYearPlan" | "timespan";
  templateData?: {
    templateType: string;
    type: string;
  };
}

const PlanTable = (props: PlanTableProps) => {
  const ref = useRef<HTMLDivElement>();
  const [rowConfig, setRowConfig] = useState<CashBudgetRowConfig[]>([]);
  const [columns, setColumns] = useState<CashBudgetColumnConfig[]>([]);

  // useEffect(() => {
  //   ref.current
  //     ?.querySelector(`div.highlight-current`)
  //     ?.scrollIntoView({ block: "end" });
  // }, [columns]);

  useEffect(() => {
    setRowConfig(
      generateRows(
        props.value.groups,
        hasValue(props.startBudget),
        undefined,
        props.planType !== "timespanAndGroups"
      )
    );
  }, [props.value?.groups]);
  useEffect(() => {
    setColumns(
      generateColumns(
        props.value,
        props.type || "month",
        props.startBudget,
        props.readonly ? undefined : props.onChange,
        props.actualValue,
        props.planType === "fixYearPlan"
      )
    );
  }, [props.value, props.startBudget, props.actualValue]);

  const actualDates = (props.actualValue || [])
    .map((e) =>
      Object.values(e.values).find((e) => e > 0)
        ? moment(e.date).toDate()
        : undefined
    )
    .filter((e) => e);
  const budgetDates = (props.value.entries || [])
    .map((e) =>
      Object.values(e.values).find((e) => e > 0)
        ? moment(e.date).toDate()
        : undefined
    )
    .filter((e) => e);
  const dates = [...actualDates, ...budgetDates];

  const maxDate = _.max(dates);
  const minDates = _.min(dates);

  return (
    <div ref={ref} className={classNames(`plan-table`)}>
      <CashBudgetTable
        columnWidth={160}
        onCellDblClick={(index, item) => {
          props.onDoubleClick?.(item, index);
        }}
        initialCollapsed={props.value.groups.map((e) => e.id)}
        headContent={
          <div className={`plan-table-head`}>
            <div className={`table-title`}>
              {props.title || i18n.t("PlanTable.Title", "Budgetplanung")}
            </div>
            {!props.readonly && props.planType !== "fixYearPlan" && (
              <>
                <BFDropdown
                  className={`manage-button`}
                  toggleAs={(props) => (
                    <BFButton {...props} appearance="outline" size="xs">
                      {/* <BfIcon type="light" data="tools-wrench" size="xs" /> */}
                      {i18n.t("PlanTable.managePlan", "Bearbeiten")}
                    </BFButton>
                  )}
                  label={i18n.t("PlanTable.managePlan", "Bearbeiten")}
                  items={[
                    {
                      type: "button",
                      text: i18n.t(
                        "PlanTable.manageTimespan",
                        "Zeitraum bearbeiten"
                      ),
                      onSelect: () => {
                        ModalManager.show({
                          size: "xs",
                          noPadding: true,
                          content: (state, setStates, onClose) => (
                            <PlanTableTimespanManage
                              onClose={onClose}
                              onUpdate={(dateFrom, dateTo) => {
                                props.onChange({
                                  entries: props.value.entries.filter(
                                    (entry) =>
                                      moment(entry.date).isSameOrBefore(
                                        dateTo,
                                        "month"
                                      ) &&
                                      moment(entry.date).isSameOrAfter(
                                        dateFrom,
                                        "month"
                                      )
                                  ),
                                  groups: props.value.groups,
                                  from: dateFrom,
                                  to: dateTo,
                                });
                              }}
                              from={props.value.from}
                              to={props.value.to}
                              minDate={_.min(actualDates)}
                              maxDate={_.max(actualDates)}
                              warnMinDate={_.min(budgetDates)}
                              warnMaxDate={_.max(budgetDates)}
                            />
                          ),
                        });
                      },
                    },
                    {
                      type: "panel",
                      children: (
                        <div className={`date-actions`}>
                          <BFButton
                            appearance="link"
                            noPadding
                            size="xs"
                            onClick={() => {
                              props.onChange({
                                entries: props.value.entries,
                                groups: props.value.groups,
                                from: minDates,
                                to: maxDate,
                              });
                            }}
                            tooltip={{
                              tooltip: i18n.t(
                                "PlanTable.timespan.optimizeSpanExplain",
                                "Die Zeitspanne wird auf die Monate reduziert, die eine Buchung oder eine Planung haben."
                              ),
                            }}
                          >
                            {i18n.t(
                              "PlanTable.timespan.optimizeSpan",
                              "Optimieren"
                            )}
                          </BFButton>
                          <BFButton
                            appearance="link"
                            noPadding
                            size="xs"
                            onClick={() => {
                              props.onChange({
                                entries: props.value.entries,
                                groups: props.value.groups,
                                from: props.value.from,
                                to: moment(props.value.to)
                                  .utc(true)
                                  .add(1, "month")
                                  .toDate(),
                              });
                              setTimeout(() => {
                                ref?.current
                                  .querySelector(".total-column")
                                  ?.scrollIntoView({
                                    behavior: "smooth",
                                  });
                              }, 50);
                            }}
                          >
                            {i18n.t(
                              "PlanTable.timespan.addMonth",
                              "Monat hinzufügen"
                            )}
                          </BFButton>
                        </div>
                      ),
                    },

                    ...((props.planType === "timespanAndGroups"
                      ? [
                          {
                            type: "divider",
                          },
                          {
                            type: "button",
                            text: i18n.t(
                              "PlanTable.manageGroups",
                              "Gruppen bearbeiten"
                            ),
                            onSelect: () => {
                              ModalManager.show({
                                backdrop: true,
                                noPadding: true,
                                size: "sm",

                                content: (state, setState, onClose) => (
                                  <PlanTableGroupManage
                                    groups={props.value.groups}
                                    templateData={props.templateData}
                                    onUpdate={(data) =>
                                      props.onChange({
                                        entries: props.value.entries,
                                        groups: data,
                                        from: props.value.from,
                                        to: props.value.to,
                                      })
                                    }
                                    onClose={onClose}
                                    disableEntriesDeletion={
                                      props.fixedPositionIds
                                    }
                                  />
                                ),
                              });
                            },
                          },
                        ]
                      : []) as any),
                  ]}
                />
              </>
            )}
          </div>
        }
        rowHeight={32}
        onSelectionChange={(values: number[]) => {}}
        data={columns}
        rowConfiguration={rowConfig}
      />
    </div>
  );
};

export default PlanTable;

const generateRows = (
  groups: PlainTableGroup[],
  hasBudget?: boolean,
  onDelete?: (id: string) => void,
  hideGroupSubhead = false
) => {
  const output: CashBudgetRowConfig[] = [];

  if (hasBudget) {
    output.push({
      title: i18n.t("PlanTable.openBudget", "Offenes Budget"),
      bold: true,
      selectable: false,
    });
  }
  output.push({
    title: i18n.t("PlanTable.spentBudget", "Budget Anfang"),
    bold: true,
    selectable: false,
  });
  // output.push({
  //   title: "",
  //   bold: false,
  //   isSubHead: true,
  //   selectable: false,
  // });
  groups.forEach((group, index) => {
    output.push({
      title: group.name,
      isSubHead: hideGroupSubhead ? false : index === 0,
      selectable: true,
      actions: onDelete ? (
        <BFButton appearance="link" size="xxs" noPadding onClick={() => {}}>
          -
        </BFButton>
      ) : undefined,
      bold: group.children?.length > 0,
      subRowIdent: group.children?.length > 0 ? group.id : undefined,
      subRows: group.children?.map((subRow) => ({
        title: subRow.name,
        selectable: true,
      })),
    });
  });

  output.push({
    title: i18n.t("PlanTable.sum", "Summe"),
    bold: true,
    borderTop: true,
    selectable: false,
    isSubHead: false,
  });
  output.push({
    title: i18n.t("PlanTable.plannedBudget", "Budget Ende"),
    bold: true,
    isSubHead: true,
    borderTop: false,
    selectable: false,
  });
  if (hasBudget) {
    output.push({
      title: i18n.t("PlanTable.remainingBudget", "Verbleibendes Budget"),
      bold: true,
      selectable: false,
    });
  }

  return output;
};

const generateColumns = (
  value: PlanTableValue,
  type: "day" | "month",
  startBudget?: number,
  onChange?: (value: PlanTableValue) => void,
  actualValues?: PlanTableEntry[],
  hideTopHeaderTextCenter?: boolean
) => {
  const hasBudget = hasValue(startBudget);
  const hasActualValue = isDefined(actualValues);
  const groups = value.groups;
  const entries = value.entries;
  const output: CashBudgetColumnConfig[] = [];

  const dates = [
    ...value.entries.map((e) => moment(e.date).utc(true).toDate()),
    ...(actualValues || []).map((e) => moment(e.date).utc(true).toDate()),
  ];

  const startDate = moment(
    _.min([...dates, moment(value.from).utc(true).toDate()])
  )
    .utc(true)
    .startOf(type);
  const endDate = moment(_.max([...dates, moment(value.to).utc(true).toDate()]))
    .utc(true)
    .endOf(type);

  const currentDate = startDate.clone();
  let budgetPlanned = startBudget || 0;
  let spentPlanned = 0;
  let spentActual = 0;

  const totalPlannedCategories: { [key: string]: number } = {};
  const totalActualCategories: { [key: string]: number } = {};
  let totalPlanned = 0;

  let totalActual = 0;
  let budgetActual = startBudget;
  while (currentDate.isSameOrBefore(endDate, type)) {
    const dateClone = currentDate.clone();
    let spanPlanned = 0;
    let spanActual = 0;
    const valuesPlanned =
      entries.find((e) => moment(e.date).isSame(dateClone, type))?.values || {};

    const valuesActual = hasActualValue
      ? actualValues?.find((e) => moment(e.date).isSame(dateClone, type))
          ?.values || {}
      : {};

    const valuesToSet: ValueData[] | [ValueData, ValueData][] = [];

    if (hasActualValue) {
      if (hasBudget) {
        (valuesToSet as [ValueData, ValueData][]).push([
          {
            // "Offenes Budget",
            text: StringUtils.formatCurrency(budgetPlanned, false),
            value: budgetPlanned,
            bold: true,
          } as ValueData,
          {
            // "Offenes Budget",
            text: StringUtils.formatCurrency(budgetActual, false),
            value: budgetPlanned,
            bold: true,
            progress: budgetActual / budgetPlanned,
            overdoneClass: "overdone-budget",
          } as ValueData,
        ] as [ValueData, ValueData]);
      }

      (valuesToSet as [ValueData, ValueData][]).push([
        {
          // "Budget Anfang",
          text: StringUtils.formatCurrency(spentPlanned, false),
          value: spentPlanned,
          bold: true,
        } as ValueData,
        {
          // "Budget Anfang",
          text: StringUtils.formatCurrency(spentActual, false),
          value: spentActual,
          bold: true,
          progress: spentPlanned / spentActual,
          overdoneClass: "overdone-budget",
        } as ValueData,
      ] as [ValueData, ValueData]);

      // (valuesToSet as [ValueData, ValueData][]).push([
      //   {
      //     // "Planung",
      //     text: "",
      //     value: 0,
      //   },
      //   {
      //     // "Planung",
      //     text: "",
      //     value: 0,
      //   },
      // ]);
    } else {
      if (hasBudget) {
        (valuesToSet as ValueData[]).push({
          // "Offenes Budget",
          text: StringUtils.formatCurrency(budgetPlanned, false),
          value: budgetPlanned,
          bold: true,
        });
      }
      (valuesToSet as ValueData[]).push({
        // "Budget Anfang",
        text: StringUtils.formatCurrency(spentPlanned, false),
        value: spentPlanned,
        bold: true,
      });
      // (valuesToSet as ValueData[]).push({
      //   // "Planung",
      //   text: "",
      //   value: 0,
      // });
    }
    groups.forEach((group, index) => {
      const hasChildren = group.children?.length > 0;

      let valuePlannedOfGroup = hasChildren
        ? _.sum(group.children.map((r) => valuesPlanned[r.id] || 0))
        : valuesPlanned[group.id] || 0;

      let valueActualOfGroup = hasChildren
        ? _.sum(group.children.map((r) => valuesActual[r.id] || 0))
        : valuesActual[group.id] || 0;

      spanPlanned += valuePlannedOfGroup || 0;
      spanActual += valueActualOfGroup || 0;

      totalPlannedCategories[group.id] =
        (totalPlannedCategories[group.id] || 0) + valuePlannedOfGroup || 0;

      totalActualCategories[group.id] =
        (totalActualCategories[group.id] || 0) + valueActualOfGroup || 0;

      const plannedEntry: ValueData = {
        text: StringUtils.formatCurrency(valuePlannedOfGroup, true),
        value: valuePlannedOfGroup,
        onChange:
          !hasChildren && onChange
            ? (val: number) => {
                if (val === valuesPlanned[group.id]) {
                  return;
                }
                const newValues = { ...valuesPlanned };
                newValues[group.id] = val;

                let added = false;
                const newEntries = entries.map((e) => {
                  if (moment(e.date).utc(true).isSame(dateClone, type)) {
                    added = true;
                    return { ...e, values: newValues };
                  } else {
                    return e;
                  }
                });
                if (!added) {
                  newEntries.push({
                    date: dateClone.toDate(),
                    values: newValues,
                  });
                }
                onChange({
                  groups: value.groups,
                  entries: newEntries,
                  from: value.from,
                  to: value.to,
                });
              }
            : undefined,
        bold: hasChildren,
        subValuesIdent: hasChildren ? group.id : undefined,
        subValues: group.children?.map((subRow) => {
          const subRowValue = valuesPlanned[subRow.id] || 0;
          totalPlannedCategories[subRow.id] =
            (totalPlannedCategories[subRow.id] || 0) + subRowValue;

          return {
            text: StringUtils.formatCurrency(
              valuesPlanned[subRow.id] || 0,
              true
            ),
            value: subRowValue,
            onChange: onChange
              ? (val: number) => {
                  if (val === valuesPlanned[subRow.id]) {
                    return;
                  }
                  const newValues = { ...valuesPlanned };
                  newValues[subRow.id] = val;

                  let added = false;
                  const newEntries = entries.map((e) => {
                    if (moment(e.date).utc(true).isSame(dateClone, type)) {
                      added = true;
                      return { ...e, values: newValues };
                    } else {
                      return e;
                    }
                  });
                  if (!added) {
                    newEntries.push({
                      date: dateClone.toDate(),
                      values: newValues,
                    });
                  }
                  onChange({
                    groups: value.groups,
                    entries: newEntries,
                    from: value.from,
                    to: value.to,
                  });
                }
              : undefined,
          };
        }),
      };
      if (hasActualValue) {
        (valuesToSet as [ValueData, ValueData][]).push([
          plannedEntry,
          {
            text: StringUtils.formatCurrency(valueActualOfGroup, true),
            value: valueActualOfGroup,
            bold: hasChildren,
            clickable: true,
            clickData: {
              id: group.id,
              type: "group",
              data: currentDate.toDate(),
              value: valueActualOfGroup,
            },
            progress: valueActualOfGroup / valuePlannedOfGroup,
            subValuesIdent: hasChildren ? group.id : undefined,
            subValues: group.children?.map((subRow) => {
              const subRowValue = valuesActual[subRow.id] || 0;
              totalActualCategories[subRow.id] =
                (totalActualCategories[subRow.id] || 0) + subRowValue;

              return {
                text: StringUtils.formatCurrency(
                  valuesActual[subRow.id] || 0,
                  true
                ),
                clickable: true,
                clickData: {
                  id: subRow.id,
                  type: "subGroup",
                  data: currentDate.toDate(),
                  value: subRowValue,
                },
                value: subRowValue,
                progress: subRowValue / (valuesPlanned[subRow.id] || 0),
              };
            }),
          },
        ]);
      } else {
        (valuesToSet as ValueData[]).push(plannedEntry);
      }
    });
    if (hasActualValue) {
      (valuesToSet as [ValueData, ValueData][]).push([
        {
          // "Budget Ende",
          text: StringUtils.formatCurrency(spanPlanned, false),
          value: spanPlanned,
          bold: true,
        },
        {
          // "Budget Ende",
          text: StringUtils.formatCurrency(spanActual, false),
          value: spanActual,
          bold: true,
          progress: spanActual / spanPlanned,
        },
      ]);
      budgetPlanned -= spanPlanned;
      totalPlanned += spanPlanned;
      budgetActual -= spanActual || 0;
      totalActual += spanActual || 0;

      spentActual += spanActual || 0;
      spentPlanned += spanPlanned;

      (valuesToSet as [ValueData, ValueData][]).push([
        {
          // "Übriges Budget",
          text: StringUtils.formatCurrency(spentPlanned, false),
          value: spentPlanned,
        },
        {
          // "Übriges Budget",
          text: StringUtils.formatCurrency(spentPlanned, false),
          value: spentPlanned,
          progress: spentPlanned / spentPlanned,
          overdoneClass: "overdone-budget",
        },
      ]);

      if (hasBudget) {
        (valuesToSet as [ValueData, ValueData][]).push([
          {
            // "Verbleibendes Budget",
            text: StringUtils.formatCurrency(budgetPlanned, false),
            value: budgetPlanned,
          },
          {
            // "Verbleibendes Budget",
            text: StringUtils.formatCurrency(budgetActual, false),
            value: budgetActual,
            progress: budgetActual / budgetPlanned,
            overdoneClass: "overdone-budget",
          },
        ]);
      }
    } else {
      budgetPlanned -= spanPlanned;
      totalPlanned += spanPlanned;
      spentPlanned += spanPlanned;
      (valuesToSet as ValueData[]).push({
        // "Budget Ende",
        text: StringUtils.formatCurrency(spanPlanned, false),
        value: spanPlanned,
        bold: true,
      });
      (valuesToSet as ValueData[]).push({
        // "Übriges Budget",
        text: StringUtils.formatCurrency(spentPlanned, false),
        value: spentPlanned,
      });
      if (hasBudget) {
        (valuesToSet as ValueData[]).push({
          // "Verbleibendes Budget",
          text: StringUtils.formatCurrency(budgetPlanned, false),
          value: budgetPlanned,
        });
      }
    }

    output.push({
      type: hasActualValue ? "comparison" : "default",
      showEndDivider:
        type === "month"
          ? currentDate.clone().endOf("year").month() === currentDate.month()
          : false,
      topHeaderTextCenter: hideTopHeaderTextCenter
        ? ""
        : type === "month"
        ? currentDate.format("YYYY")
        : currentDate.format("MMMM YY"),
      id: currentDate.format("YYYY-MM-DD"),
      headerText:
        type === "month"
          ? currentDate.format("MMMM")
          : currentDate.format("dd.mm.yy"),
      values: valuesToSet as any,
      highlight: moment().isSame(currentDate, "month") ? "current" : undefined,
      subHeaders: hasActualValue
        ? [
            i18n.t("PlanTable.Column.Plan", "Plan"),
            i18n.t("PlanTable.Column.Should", "Ist"),
          ]
        : undefined,
    });
    currentDate.add(1, type);
  }

  if (hasActualValue) {
    const total: CashBudgetColumnConfig = {
      headerText: "Gesamt",
      id: "total",
      type: "comparison",
      className: "total-column",
      allBold: true,
      highlight: "sum",
      subHeaders: hasActualValue
        ? [
            i18n.t("PlanTable.Column.Plan", "Plan"),
            i18n.t("PlanTable.Column.Should", "Ist"),
          ]
        : undefined,
      values: [
        [
          {
            text: StringUtils.formatCurrency(0),
            value: 0,
            bold: true,
          },
          {
            text: StringUtils.formatCurrency(0),
            value: 0,
            bold: true,
            progress: budgetActual / budgetPlanned,
          },
        ],
        ...groups.map((group) => {
          const hasChildren = group.children?.length > 0;
          return [
            {
              text: StringUtils.formatCurrency(
                totalPlannedCategories[group.id] || 0,
                true
              ),
              bold: true,
              value: totalPlannedCategories[group.id] || 0,
              subValuesIdent: hasChildren ? group.id : undefined,
              subValues: group.children?.map((subRow) => {
                return {
                  text: StringUtils.formatCurrency(
                    totalPlannedCategories[subRow.id] || 0,
                    true
                  ),
                  value: totalPlannedCategories[subRow.id] || 0,
                };
              }),
            },
            {
              text: StringUtils.formatCurrency(
                totalActualCategories[group.id] || 0,
                true
              ),
              bold: true,
              value: totalActualCategories[group.id] || 0,
              progress:
                totalActualCategories[group.id] /
                totalPlannedCategories[group.id],
              subValuesIdent: hasChildren ? group.id : undefined,
              subValues: group.children?.map((subRow) => {
                return {
                  text: StringUtils.formatCurrency(
                    totalActualCategories[subRow.id] || 0,
                    true
                  ),
                  value: totalActualCategories[subRow.id] || 0,
                  progress:
                    totalActualCategories[subRow.id] /
                    totalPlannedCategories[subRow.id],
                };
              }),
            },
          ];
        }),

        [
          {
            text: StringUtils.formatCurrency(totalPlanned, false),
            bold: true,
            value: totalPlanned,
          },
          {
            text: StringUtils.formatCurrency(totalActual, false),
            bold: true,
            value: totalActual,
            progress: totalActual / totalPlanned,
          },
        ],
        [
          {
            text: StringUtils.formatCurrency(totalPlanned, false),
            bold: true,
            value: totalPlanned,
          },
          {
            text: StringUtils.formatCurrency(totalActual, false),
            bold: true,
            value: totalActual,
            progress: totalActual / totalPlanned,
          },
        ],
      ],
    };
    output.push(total);
  } else {
    const total: CashBudgetColumnConfig = {
      headerText: i18n.t("PlanTable.Column.Total", "Gesamt"),
      id: "total",
      type: "default",
      allBold: true,
      highlight: "sum",
      className: "total-column",
      values: [
        {
          text: StringUtils.formatCurrency(0),
          value: 0,
          bold: true,
        },
        ...(hasBudget
          ? [
              {
                text: StringUtils.formatCurrency(budgetPlanned),
                value: budgetPlanned,
                bold: true,
              },
            ]
          : []),
        ...groups.map((group) => {
          const hasChildren = group.children?.length > 0;
          return {
            text: StringUtils.formatCurrency(
              totalPlannedCategories[group.id] || 0,
              true
            ),
            bold: true,
            value: totalPlannedCategories[group.id] || 0,
            subValuesIdent: hasChildren ? group.id : undefined,
            subValues: group.children?.map((subRow) => {
              return {
                text: StringUtils.formatCurrency(
                  totalPlannedCategories[subRow.id] || 0,
                  true
                ),
                value: totalPlannedCategories[subRow.id] || 0,
              };
            }),
          };
        }),
        {
          text: StringUtils.formatCurrency(totalPlanned, false),
          bold: true,
          value: totalPlanned,
        },
        {
          text: StringUtils.formatCurrency(spentPlanned, false),
          bold: true,
          value: spentPlanned,
        },

        ...(hasBudget
          ? [
              {
                text: StringUtils.formatCurrency(budgetPlanned),
                value: budgetPlanned,
                bold: true,
              },
            ]
          : []),
      ],
    };
    output.push(total);
  }

  return output;
};

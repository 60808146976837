import i18n from "@/i18n";
import { withPDFBlock } from "../PDFBlock";
import { PDFBlockFooter } from "../PDFBlockFooter";
import { usePDFConstructor } from "../../context/PDFConstructorContext";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import { PageBreakConfig } from "../../services/types/pdfConfigBlockTypes";

const orientation = [
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.PageOrientation.Landscape",
        "Querformat"
      ),
    value: "landscape",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.PageOrientation.Portrait",
        "Hochformat"
      ),
    value: "portrait",
  },
];

const PDFPageBreak = withPDFBlock<PageBreakConfig>(
  ({ value, parentId }) => {
    const [_, { saveBlock, deleteBlock, editBlock }] = usePDFConstructor();

    return (
      <>
        <BFSelect
          label="Page Orientation"
          data={orientation.map((option) => ({
            ...option,
            label: option.label(),
          }))}
          value={value.pageOrientation}
          onChange={(orientation) =>
            editBlock({
              ...value,
              pageOrientation: orientation,
            })
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          // no need to save page break
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.PageBreakInput",
      "Seitenumbruch"
    )
);

export { PDFPageBreak };

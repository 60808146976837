import { CB_OBJECT_DOCUMENTS_FIELD_PATH } from "@/apps/tatar/cashBudget/CashBudgetUtils";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import {
  DSListEntryAction,
  DocumentStoreAssetParams,
  DocumentStoreDocument,
} from "@/modules/document-store/DSInterfaces";
import DocumentStoreRouted from "@/modules/document-store/DocumentStoreRouted";
import { RSLinkedAssetFieldIdentifier } from "@/modules/resubmission/RSConstants";
import { RSAssetBaseParams } from "@/modules/resubmission/RSInterfaces";
import useOpenResubmissionForm from "@/modules/resubmission/hooks/useOpenResubmissionForm";
import { OAObject } from "../../../types/object.interface";
import "./ObjectDocumentsView.scss";

type Props = {
  asset: OAObject;
};
const ObjectDocumentsView = (props: Props) => {
  const { asset } = props;

  const assetParams: DocumentStoreAssetParams = {
    asset: asset,
    type: asset.data.type,
    assetType: AssetTypes.Portfolio.Object,
    documentsFieldPath: "data.attachments",
  };

  const { openResubmissionBtnIconProps, openResubmissionDialog } =
    useOpenResubmissionForm();

  const documentEntryActions: DSListEntryAction<DocumentStoreDocument>[] = [
    {
      type: "button",
      text: i18n.t("Resubmission.Labels.Create", "Wiedervorlage erstellen"),
      onSelect: (document: DocumentStoreDocument) => {
        const assetParams: RSAssetBaseParams = {
          assetType: AssetTypes.Portfolio.Object,
          assetId: props.asset?._id,
          assetDescription: `${i18n.t(
            "AssetTypes.CB.Labels.Object",
            "Objekt"
          )} - ${i18n.t("DocumentStore.Labels.Document", "Dokument:")} ${
            document.name
          }`,
          assetField: {
            identifier: RSLinkedAssetFieldIdentifier.DSDocument,
            fieldPath: CB_OBJECT_DOCUMENTS_FIELD_PATH,
            id: document.linkToCdn,
          },
        };

        openResubmissionDialog({ linkedAsset: assetParams });
      },
      icon: openResubmissionBtnIconProps,
    },
  ];

  return (
    <div className="object-document-store">
      <DocumentStoreRouted
        assetParams={assetParams}
        documentEntryActions={documentEntryActions}
      />
    </div>
  );
};

export default ObjectDocumentsView;

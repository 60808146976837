import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import BFButton from "../../modules/abstract-ui/general/Button/BFButton";
import { useTypedSelector } from "../../redux/hooks";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import DataBus from "../../services/DataBus";
import { isDevelopment, isTestserver } from "../../utils/Properties";
import WrappedText from "../WrappedText/WrappedText";
import NotSeriousContainer from "../not-serious/NotSeriousContainer";
import "./ErrorBoundaryErrorPage.scss";

const KNOWN_ERRORS = [
  {
    match: (error: Error) => {
      const regex = [/'insertBefore' on 'Node'/g, /'removeChild' on 'Node'/g];
      return regex.some((e) => e.test(error.message) || e.test(error.name));
    },
    getMessage: () =>
      i18n.t(
        "Component.AppErrorBoundary.KnownError.Translation.Msg",
        "Der aufgetretene Fehler kann durch die Webseiten Übersetzungs von dem Browser verursacht worden. Sollten Sie diese Übersetzungsfunktion aktiviert haben, deaktivieren Sie dies bei der Benutzung von iberio, um diesen Fehler zu vermeiden."
      ),
  },
];

const getMessage = (error: Error) => {
  const knownError = KNOWN_ERRORS.find((knownError) => knownError.match(error));
  if (knownError) {
    return knownError.getMessage();
  } else {
    return i18n.t("Component.AppErrorBoundary.Msg");
  }
};
const ErrorBoundaryErrorPage = (props: {
  errorData: {
    error: Error;
    componentStack: string;
    eventId: string;
    resetError(): void;
  };
}) => {
  const { t } = useTranslation();
  const debug = useTypedSelector(
    (state) => state.uiConfig.general[DefaultUIConfigs.DEBUG]
  );
  const development = isDevelopment() || isTestserver() || debug;
  useEffect(() => {
    if (window.location.host.startsWith("localhost") || debug) {
      setTimeout(() => {
        DataBus.emit("NS-Celebration", { type: "sad", count: 100 });
      }, 100);
    }
  }, []);

  const errorMessage = getMessage(props.errorData.error);
  return (
    <div className="error-boundary">
      <div className="smiley">:(</div>
      <div className="msg">{errorMessage}</div>

      {development && (
        <div className={`error`}>
          <div className={`error_name`}>{props.errorData.error.message}</div>
          <div className={`error_stack`}>
            <WrappedText text={props.errorData?.componentStack} />
          </div>
        </div>
      )}
      <BFButton
        className="restart"
        appearance="primary"
        onClick={() => window.location.reload()}
      >
        {t("Component.AppErrorBoundary.RestartButton")}
      </BFButton>
      {/* {window.location.hostname === "localhost" || debug ? (
        <code>
          <pre>{this.state.hasError.stack.toString()}</pre>
        </code>
      ) : null} */}
      <NotSeriousContainer />
    </div>
  );
};
export default ErrorBoundaryErrorPage;

import classNames from "classnames";
import { Field } from "react-final-form";
import FormStruct from "../../../../components/Form/FormStruct/FormStruct";
import FormValidators from "../../../../components/Form/Validation/FormValidators";
import i18n from "../../../../i18n";
import BFCheckbox from "../../forms/checkbox/BFCheckbox";
import BFInput from "../../forms/input/BFInput";
import BFButton from "../../general/Button/BFButton";
import "./BFTableFilterCreate.scss";
import { FavoriteFilter, TableCustomization } from "./BFTableFilterModel";

interface BFTableFilterCreateProps {
  filter: FavoriteFilter;
  customizations?: TableCustomization;
  onClose: () => void;
  onFilterUpdate: (filter: FavoriteFilter) => void;
  onFilterRemove: () => void;
}
const BFTableFilterUpdate = (props: BFTableFilterCreateProps) => {
  return (
    <FormStruct<any>
      className={classNames(`bf-table-filter-modal bf-table-filter-update`)}
      title={i18n.t("BFTableFilter.ChangeFilter", "Filter bearbeiten")}
      onSubmit={async (values: {
        name: string;
        isDefault: boolean;
        updateCustomization: boolean;
        saveCustomization: boolean;
      }) => {
        const customizationsOrigin = props.filter.columnCustomization;
        let newCustomization = null;
        if (values.saveCustomization) {
          if (customizationsOrigin) {
            newCustomization = values.updateCustomization
              ? props.customizations
              : customizationsOrigin || undefined;
          } else {
            newCustomization = props.customizations || undefined;
          }
        }

        props.onFilterUpdate({
          ...props.filter,
          name: values.name,
          columnCustomization: newCustomization,
          isDefault: values.isDefault,
        });
        props.onClose();
      }}
      additionalActions={
        <BFButton
          className={"margin-right-20"}
          onClick={() => {
            props.onFilterRemove();
            props.onClose();
          }}
          appearance="outline"
        >
          {i18n.t("BFTableFilter.removeFilter", "Filter entfernen")}
        </BFButton>
      }
      onAbort={() => props.onClose()}
      initialValues={{
        name: props.filter.name,
        saveCustomization: props.filter.columnCustomization ? true : false,
        updateCustomization: false,
        isDefault: props.filter.isDefault,
      }}
      submitText={i18n.t("BFTableFilter.saveFilter", "Filter speichern")}
      render={(formProps) => {
        return (
          <>
            <div className={`__field`}>
              <Field name="name" validate={FormValidators.required()}>
                {({ input, meta }) => {
                  return (
                    <BFInput
                      label={i18n.t(
                        "BFTableFilter.FilterName",
                        "Name des Filters"
                      )}
                      focusOnMount
                      {...input}
                      {...FormValidators.getValidation(meta)}
                    />
                  );
                }}
              </Field>
            </div>
            <Field name="saveCustomization">
              {({ input, meta }) => {
                return (
                  <>
                    <div className={`__field`}>
                      <BFCheckbox
                        checked={input.value}
                        onChange={(_, checked) => input.onChange(checked)}
                      >
                        {i18n.t(
                          "BFTableFilter.SaveCustomization",
                          "Spaltenanpassungen im Favorit speichern"
                        )}
                      </BFCheckbox>
                      <div className={`hint`}>
                        {i18n.t(
                          "BFTableFilter.UpdateForm.SaveCustomizationHint",
                          "Es werden Spaltenanpassungen an der Tabelle im Favorit gespeichert. Sobald dieser Favoritenfilter ausgewählt wird, werden auch die Spaltenanpassungen auf die Tabelle angewendet."
                        )}
                      </div>
                    </div>
                    {input.value &&
                      props.customizations &&
                      props.filter.columnCustomization && (
                        <Field name="updateCustomization">
                          {({ input, meta }) => {
                            return (
                              <>
                                <BFCheckbox
                                  checked={input.value}
                                  onChange={(_, checked) =>
                                    input.onChange(checked)
                                  }
                                >
                                  {i18n.t(
                                    "BFTableFilter.UpdateForm.UpdateCustomization",
                                    "Spaltenanpassungen aktualisieren"
                                  )}
                                </BFCheckbox>
                                <div className={`hint`}>
                                  {i18n.t(
                                    "BFTableFilter.UpdateForm.UpdateCustomizations",
                                    "Die aktuellen Spaltenanpassungen werden in dem Favorit gespeichert. Vorher gespeicherte Spaltenanpassungen werden überschrieben."
                                  )}
                                </div>
                              </>
                            );
                          }}
                        </Field>
                      )}
                  </>
                );
              }}
            </Field>

            <div className={`__field`}>
              <Field name="isDefault">
                {({ input, meta }) => {
                  return (
                    <>
                      <BFCheckbox
                        checked={input.value}
                        onChange={(_, checked) => input.onChange(checked)}
                      >
                        {i18n.t(
                          "BFTableFilter.SetDefault",
                          "Als Standardfilter setzen"
                        )}
                      </BFCheckbox>
                      <div className={`hint`}>
                        {i18n.t(
                          "BFTableFilter.SetDefaultHint",
                          "Ein Standardfilter wird beim initialen Öffnen der Tabelle automatisch gesetzt. Es kann nur ein Filter als Standard markiert sein. Bereits gespeicherte Standard-Einstellungen für diese Tabelle werden überschrieben."
                        )}
                      </div>
                    </>
                  );
                }}
              </Field>
            </div>
          </>
        );
      }}
    />
  );
};

export default BFTableFilterUpdate;

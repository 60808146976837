import { InvoiceDirection } from "@/apps/tatar/invoiceApp/RAInterfaces";
import {
  ACInvoiceStructByUnit,
  ACInvoiceStructConfig,
  ACInvoiceType,
  InvoicePaymentType,
  InvoicePaymentTypeTags,
  InvoiceStructure,
} from "../../../../apps/AppConfigInterfaces";
import { HTTP } from "../../../../utils/Http";
import { StructType } from "../../../reducers/struct/StructInterface";
import {
  AbstractStructSelectors,
  DataByUnitType,
} from "../AbstractStructSelectors";

class InvoiceStructClass extends AbstractStructSelectors<ACInvoiceStructByUnit> {
  getStructType(): StructType {
    return "invoice";
  }
  loadStructData(
    units: string[]
  ): Promise<DataByUnitType<ACInvoiceStructByUnit>> {
    return new Promise(async (resolve, reject) => {
      try {
        const data = (await HTTP.get({
          url: `getInvoiceStruct`,
          target: "INVOICE",
          queryParams: {
            param: {
              types: units,
            },
          },
        })) as InvoiceStructure;

        const result: DataByUnitType<ACInvoiceStructByUnit> = {};
        units.forEach((unit) => {
          result[unit] = {
            invoiceConfig: data.invoiceConfig.find((e) => e.data.type === unit),
            invoiceTypes: data.invoiceTypes.filter((e) => e.data.type === unit),
          };
        });

        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  }

  getInvoiceConfigByUnit(unit: string): ACInvoiceStructConfig {
    return this.useCache<ACInvoiceStructConfig>(
      "getInvoiceConfigByUnit",
      arguments,
      () => this.getData(unit).invoiceConfig
    );
  }
  getInvoiceTypesByUnit(unit: string): ACInvoiceType[] {
    return this.useCache<ACInvoiceType[]>(
      "getInvoiceTypesByUnit",
      arguments,
      () => this.getData(unit).invoiceTypes
    );
  }

  getInvoiceTypesByUnitAndDirection(
    unit: string,
    direction: InvoiceDirection
  ): ACInvoiceType[] {
    return this.useCache<ACInvoiceType[]>(
      "getInvoiceTypesByUnit",
      arguments,
      () =>
        this.getData(unit).invoiceTypes.filter(
          (e) => e.data.direction === direction
        )
    );
  }
  getInvoiceTypeByUnit(unit: string, id: string): ACInvoiceType {
    return this.useCache<ACInvoiceType>("getInvoiceType", arguments, () =>
      this.getInvoiceTypesByUnit(unit).find((e) => e._id === id)
    );
  }

  getInvoiceType(id: string): ACInvoiceType {
    return this.useCache<ACInvoiceType>("getInvoiceType", arguments, () => {
      const allData = this.getAllData();
      for (const dataOfUnit of allData) {
        for (const invoiceType of dataOfUnit.invoiceTypes) {
          if (invoiceType._id === id) {
            return invoiceType;
          }
        }
      }
    });
  }
  getPaymentType(id: string): InvoicePaymentType {
    return this.useCache<InvoicePaymentType>(
      "getPaymentType",
      arguments,
      () => {
        const allData = this.getAllData();
        for (const dataOfUnit of allData) {
          for (const paymentType of dataOfUnit?.invoiceConfig?.data
            ?.paymentTypes || []) {
            if (paymentType.id === id) {
              return paymentType;
            }
          }
        }
      }
    );
  }

  checkPaymentTypeTag(
    paymentTypeId: string,
    tag: InvoicePaymentTypeTags
  ): boolean {
    return this.useCache<boolean>("checkPaymentTypeTag", arguments, () => {
      const paymentType = this.getPaymentType(paymentTypeId);
      return paymentType?.tags?.includes(tag);
    });
  }
}
const InvoiceStruct = new InvoiceStructClass();
(window as any).InvoiceStruct = InvoiceStruct;
export default InvoiceStruct;

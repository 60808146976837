import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import Collapse from "rsuite/esm/Animation/Collapse";
import i18n from "../../../../i18n";
import {
  Application,
  PermissionConfigBoolean,
  PermissionConfigSelect,
} from "../../../../model/db/Application";
import { AssetPermission } from "../../../../model/db/Permission";
import { hasValue } from "../../../../utils/Helpers";
import ExpressionHelper from "../../../generic-forms/util/ExpressionHelper";
import ActionSheetDrawer from "../../general/ActionSheetDrawer/ActionSheetDrawer";
import {
  ActionSheetEntries,
  ActionSheetEntry,
} from "../../general/ActionSheetDrawer/ActionSheetEntries";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import BFSelect from "../select/BFSelect";
import BFToggle from "../toggle/BFToggle";
import "./BFAppPermission.scss";
import BFObjectKindPermission, {
  ObjectKindPermission,
} from "./BFObjectKindPermission";
export interface AppPermission {
  APP: string;
  ASSET?: AssetPermission[];
  ROLES: {
    [keyOfPermission: string]: boolean | string[] | ObjectKindPermission[];
  };
}
interface BFAppPermissionProps {
  value: AppPermission[];
  onChange: (value: AppPermission[]) => void;
  mandatorApps: Application[];
  readonly?: boolean;
  units?: string[];
}
const BFAppPermission = (props: BFAppPermissionProps) => {
  const [expanded, setExpanded] = useState<string>(null);
  const [appSelectionActive, setAppSelectionActive] = useState(false);
  if (!props.mandatorApps) {
    return <div>Cache for apps not loaded</div>;
  }
  return (
    <div className={classNames(`bf-app-permission`)}>
      <AppPermissionSheetDrawer
        open={appSelectionActive}
        onHide={() => setAppSelectionActive(false)}
        mandatorApps={props.mandatorApps}
        value={props.value}
        onChange={props.onChange}
      />
      <div className={`app-header`}>
        <div className={`title`}>
          {i18n.t("BFAppPermission.header", "Zugewiesene Apps")}
        </div>
        {props.readonly !== true && (
          <div className={`actions`}>
            <BFButton
              appearance="link"
              onClick={() => setAppSelectionActive(true)}
            >
              {i18n.t("Global.Buttons.add")}
            </BFButton>
          </div>
        )}
      </div>
      <div className={`app-entries`}>
        {props.value.length === 0 && (
          <div className={`no-entries`}>
            {i18n.t("BFAppPermission.noEntries", "Keine Apps zugewiesen")}
          </div>
        )}
        {props.value?.map((appPermission, index) => (
          <BFAppPermissionEntry
            units={props.units}
            readonly={props.readonly}
            expanded={expanded === appPermission.APP}
            onExpanded={(appId) => setExpanded(appId)}
            appPermission={appPermission}
            app={props.mandatorApps.find((e) => e._id === appPermission.APP)}
            key={appPermission.APP}
            onChange={(value) => {
              props.onChange(
                props.value.map((e) => (e.APP === value.APP ? value : e))
              );
            }}
            onDelete={() => {
              props.onChange(
                props.value.filter((e) => e.APP !== appPermission.APP)
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default BFAppPermission;

const mergePermissions = (
  permission: AssetPermission[],
  newPermission: AssetPermission[]
) => {
  if (!newPermission) {
    return permission;
  }
  let newPermissions = [];

  newPermission.forEach((newPerm) => {
    const existingPerm = permission.find((e) => e.AssetID === newPerm.AssetID);
    if (existingPerm) {
      newPermissions.push(_.merge(existingPerm, newPerm));
    } else {
      newPermissions.push(newPerm);
    }
  });
  permission
    .filter((e) => !newPermissions.find((e2) => e2.AssetID === e.AssetID))
    .forEach((e) => newPermissions.push(e));

  return newPermissions as AssetPermission[];
};

interface BFAppPermissionEntryProps {
  appPermission: AppPermission;
  app: Application;
  expanded: boolean;
  onExpanded: (value: string) => void;
  onChange: (value: AppPermission) => void;
  onDelete: () => void;
  readonly?: boolean;
  units?: string[];
}
const BFAppPermissionEntry = (props: BFAppPermissionEntryProps) => {
  const permissionConfig = props.app?.permissionConfig || {};
  const hasPermissionConfig = Object.keys(permissionConfig).length > 0;

  const onChange = (permissionKey: string, value: any) => {
    const newRoles = {
      ...(props.appPermission?.ROLES || {}),
      [permissionKey]: value,
    };
    let assetPermissions = [];

    Object.entries(newRoles).forEach(([key, value]) => {
      if (hasValue(value)) {
        assetPermissions = mergePermissions(
          assetPermissions,
          permissionConfig[key]?.assetPermissions
        );
      }
    });

    props.onChange({
      ...(props.appPermission || {}),
      ROLES: newRoles,
      ASSET: hasValue(assetPermissions) ? assetPermissions : undefined,
    } as AppPermission);
  };
  return (
    <div className={`bf-app-permission-entry`}>
      <button
        type="button"
        onClick={() => props.onExpanded(props.expanded ? null : props.app._id)}
        className={`entry-header`}
      >
        <div
          className={"logo"}
          style={{
            backgroundImage: `url(${
              props.app.logo ? props.app.logo.drawer : ""
            })`,
          }}
        />
        <div className={`app-name`}>{props.app.displayName}</div>

        <div className={`indicator`}>
          <BfIcon
            data="arrow-down-1"
            type="light"
            size="xxs"
            style={{ transform: props.expanded ? "rotate(180deg)" : undefined }}
          />
        </div>
      </button>
      <Collapse in={props.expanded}>
        <div>
          <div className={`app-permissions`}>
            {!hasPermissionConfig && (
              <div className={`no-permissions-available`}>
                {i18n.t(
                  "BFAppPermission.noPermissionsAvailable",
                  "Keine Berechtigungen verfügbar"
                )}
              </div>
            )}
            {Object.entries(permissionConfig).map(
              ([permissionKey, permissionConfig]) =>
                permissionConfig.type === "select" ? (
                  <PermissionFieldSelect
                    readonly={props.readonly}
                    app={props.app}
                    config={permissionConfig}
                    value={
                      (props.appPermission.ROLES?.[
                        permissionKey
                      ] as string[]) || []
                    }
                    onChange={(value) => {
                      onChange(permissionKey, value);
                    }}
                  />
                ) : permissionConfig.type === "boolean" ? (
                  <PermissionFieldBoolean
                    readonly={props.readonly}
                    app={props.app}
                    config={permissionConfig}
                    value={
                      (props.appPermission.ROLES?.[permissionKey] as boolean) ||
                      null
                    }
                    onChange={(value) => {
                      onChange(permissionKey, value);
                    }}
                  />
                ) : permissionConfig.type === "objectKind" ? (
                  <BFObjectKindPermission
                    units={props.units}
                    readonly={props.readonly}
                    app={props.app}
                    config={permissionConfig}
                    value={
                      (props.appPermission.ROLES?.[
                        permissionKey
                      ] as ObjectKindPermission[]) || null
                    }
                    onChange={(value) => {
                      onChange(permissionKey, value);
                    }}
                  />
                ) : null
            )}

            {props.readonly !== true && (
              <div className={`delete-action`}>
                <BFButton appearance="link" onClick={props.onDelete}>
                  {i18n.t(
                    "BFAppPermission.removeApp",
                    "App-Zuweisung entfernen"
                  )}
                </BFButton>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

interface FieldSelectProps {
  config: PermissionConfigSelect;
  value: string[];
  onChange: (value: string[]) => void;
  app: Application;
  readonly?: boolean;
}
const PermissionFieldSelect = (props: FieldSelectProps) => {
  const options = ExpressionHelper.evaluateExpression(props.config._options, {
    application: props.app,
  });
  return (
    <div className={`permission-field permission-field-select`}>
      <div className={`field-label`}>{i18n.t(props.config.label)}</div>
      <div className={`field-value`}>
        {props.readonly === true ? (
          <div className={`readonly-values`}>
            {props.value
              ?.map((value) => options.find((e) => e.value === value)?.label)
              .join(", ") || "-"}
          </div>
        ) : (
          <BFSelect
            // disabled={props.readonly}
            // renderValue={(values) => values.join(", ")}
            type="multiple"
            data={options}
            value={props.value || []}
            onChange={(values: string[]) => {
              props.onChange(values);
            }}
          />
        )}
      </div>
    </div>
  );
};

interface FieldBooleanProps {
  config: PermissionConfigBoolean;
  value: boolean;
  onChange: (value: boolean) => void;
  app: Application;
  readonly?: boolean;
}
const PermissionFieldBoolean = (props: FieldBooleanProps) => {
  return (
    <div className={`permission-field permission-field-boolean`}>
      <div className={`field-label`}>{i18n.t(props.config.label)}</div>
      <div className={`field-value`}>
        {props.readonly === true ? (
          <div className={`readonly-values`}>
            {!!props.value ? i18n.t("Global.Labels.yes", "Ja") : "-"}
          </div>
        ) : (
          <BFToggle
            // disabled={props.readonly}
            // renderValue={(values) => values.join(", ")}
            checked={props.value}
            onChange={(checked) => {
              props.onChange(checked ? true : undefined);
            }}
          />
        )}
      </div>
    </div>
  );
};

interface AppPermissionDrawerProps {
  value: AppPermission[];
  mandatorApps: Application[];
  onHide: () => void;
  open: boolean;
  onChange: (value: AppPermission[]) => void;
}
const AppPermissionSheetDrawer = (props: AppPermissionDrawerProps) => {
  const data: ActionSheetEntry[] = [];
  props.mandatorApps
    .filter(
      (mandatorApp) =>
        props.value.find((valEntry) => valEntry.APP === mandatorApp._id) ===
        undefined
    )
    .forEach((app) => {
      const appConfig = props.mandatorApps.find(
        (appConfig) => appConfig._id === app._id
      );

      data.push({
        key: app._id,
        label: app.displayName,
        value: app,
        disabled: false,
        preRender: () => (
          <div
            className={"logo"}
            style={{
              backgroundImage: `url(${
                appConfig.logo ? appConfig.logo.drawer : ""
              })`,
            }}
          />
        ),
        // postRender?: () => React.ReactNode,
      });
    });

  return (
    <ActionSheetDrawer
      onHide={() => props.onHide()}
      active={props.open}
      title={i18n.t(
        "BFAppPermission.addApplications",
        "Anwendungen hinzufügen"
      )}
    >
      <ActionSheetEntries
        className={"permission-field-simple-appselection"}
        data={data}
        onClick={(appValue) => {
          //TODO change here
          props.onChange([
            ...props.value,
            {
              APP: appValue._id,
              ROLES: {},
            },
          ]);
          props.onHide();
        }}
      />
    </ActionSheetDrawer>
  );
};

import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators, {
  FV,
} from "@/components/Form/Validation/FormValidators";
import MultiplePDFViewer from "@/components/PDFViewer/MultiplePDFViewer";
import { PDFEditRect } from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { Contact } from "@/model/db/Contact";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFRadioGroup from "@/modules/abstract-ui/forms/radio-group/BFRadioGroup";
import BfRadio from "@/modules/abstract-ui/forms/radio/BfRadio";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import FileUtils from "@/utils/FileUtils";
import classNames from "classnames";
import { useState } from "react";
import { Field } from "react-final-form";
import { getRentPaymentTimingOption } from "../../../CBTenantsConst";
import { RentalUnit } from "../../../TenantsInterfaces";
import { AutomaticType } from "../agreement-parts/CBAgreementAutomatic";
import { ContractBaseDataType } from "../agreement-parts/CBAgreementContractBasedata";
import { CopaymentType } from "../agreement-parts/CBAgreementCopayment";
import { DepositType } from "../agreement-parts/CBAgreementDeposit";
import { GraduatedRentType } from "../agreement-parts/CBAgreementGraduatedRent";
import { IndexType } from "../agreement-parts/CBAgreementIndex";
import { OptionType } from "../agreement-parts/CBAgreementOption";
import { PaymentPositionType } from "../agreement-parts/CBAgreementPaymentInfo";
import { RentDurationType } from "../agreement-parts/CBAgreementRentDuration";
import CBRentalAgreementAutomaticForm, {
  CBRentalAgreementAutomaticFormButton,
} from "../form-components/CBRentalAgreementAutomaticForm";
import CBRentalAgreementCopaymentForm, {
  CBRentalAgreementCopaymentFormButton,
} from "../form-components/CBRentalAgreementCopaymentForm";
import CBRentalAgreementDepositForm, {
  CBRentalAgreementDepositButton,
} from "../form-components/CBRentalAgreementDepositForm";
import CBRentalAgreementGraduatedRentForm, {
  CBRentalAgreementGraduatedRentFormButton,
} from "../form-components/CBRentalAgreementGraduatedRentForm";
import CBRentalAgreementIndexForm, {
  CBRentalAgreementIndexFormButton,
} from "../form-components/CBRentalAgreementIndexForm";
import CBRentalAgreementOptionForm, {
  CBRentalAgreementOptionFormButton,
} from "../form-components/CBRentalAgreementOptionForm";
import CBRentalAgreementPaymentPositions from "../form-components/CBRentalAgreementPaymentPositions";
import { CBRentalAgreementFormWizardStep1Value } from "./CBRentalAgreementFormWizardStep1UploadContract";
import { CBRentalAgreementFormWizardStep2Value } from "./CBRentalAgreementFormWizardStep2ContractPartners";
import { CBRentalAgreementFormWizardStep3Value } from "./CBRentalAgreementFormWizardStep3RentalUnits";
import "./CBRentalAgreementFormWizardStep4ContractData.scss";

export type CBRentalAgreementFormWizardStep4Value = ContractBaseDataType &
  RentDurationType &
  PaymentPositionType &
  DepositType &
  OptionType &
  AutomaticType &
  IndexType &
  CopaymentType &
  GraduatedRentType & {
    marks?: {
      tenant?: PDFEditRect[];
      copayment?: PDFEditRect[];
      paymentInfo?: PDFEditRect[];
      deposit?: PDFEditRect[];
      rentDuration?: PDFEditRect[];
      option?: PDFEditRect[];
      automatic?: PDFEditRect[];
      index?: PDFEditRect[];
      graduatedRent?: PDFEditRect[];
    };
  };
interface CBRentalAgreementFormWizardStep4ContractDataProps {
  data: {
    step1: CBRentalAgreementFormWizardStep1Value;
    step2: CBRentalAgreementFormWizardStep2Value;
    step3: CBRentalAgreementFormWizardStep3Value;
  };
  onValueChange: (index: number, value: any) => void;
  initialValues?: CBRentalAgreementFormWizardStep4Value;
  onBack: () => void;
  onSuccess: (values: CBRentalAgreementFormWizardStep4Value) => Promise<void>;
  onAbort: () => void;
}
const CBRentalAgreementFormWizardStep4ContractData = (
  props: CBRentalAgreementFormWizardStep4ContractDataProps
) => {
  const [fileUrl, setFileUrl] = useState(
    FileUtils.fileToObjectUrl(props.data?.step1?.rentalAgreement?.[0]?.file)
  );

  const objectId = props.data.step2.objectId;
  return (
    <FormStruct<any>
      title={i18n.t(
        "cb:RentalAgreement.Form.Step4.title",
        "Vertragsdaten auswählen"
      )}
      description={i18n.t(
        "cb:RentalAgreement.Form.Step4.description",
        "Wählen Sie die Vertragsbestandteile aus."
      )}
      onSubmit={async (values) => {
        props.onSuccess(values);
      }}
      submitText={i18n.t("Global.Buttons.continue")}
      onAbort={(formProps) => {
        props.onValueChange(3, formProps.values);
        props.onBack();
      }}
      additionalActions={
        <BFButton onClick={props.onAbort} appearance="outline">
          {i18n.t("Global.Buttons.cancel")}
        </BFButton>
      }
      initialValues={props.initialValues}
      cancelText={i18n.t("Global.Buttons.back")}
      className={classNames(
        `cb-rental-agreement-form-wizard-step4-contract-data`
      )}
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <MultiplePDFViewer
            identifier="rental-agreement-pdf"
            height={"78vh"}
            label={{
              single: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreement",
                "Mietvertrag"
              ),
              multiple: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreements",
                "Mietverträge"
              ),
            }}
            pdfs={props.data.step1?.rentalAgreement.map((e) => ({
              type: "file",
              file: e.file,
            }))}
          />
        </div>
      )}
      render={(form) => (
        <div>
          <div className={`__flex`}>
            <div className={`__field`}>
              <Field name="contractType" validate={FV.required()}>
                {({ input, meta }) => (
                  <BFRadioGroup
                    inline
                    value={input.value}
                    onChange={(_value, checked) => input.onChange(checked)}
                    {...FV.getValidation(meta)}
                  >
                    <BfRadio value={"business"}>
                      {i18n.t("cb:RentalAgreement.Form.business", "Gewerblich")}
                    </BfRadio>
                    <BfRadio value={"private"}>
                      {i18n.t("cb:RentalAgreement.Form.private", "Privat")}
                    </BfRadio>
                  </BFRadioGroup>
                )}
              </Field>
            </div>
          </div>
          <div className="__flex">
            <div className={`__field __flex-1`}>
              <AssetLoader
                assetType={AssetTypes.Rental.RentalUnit}
                id={props.data.step3.rentalUnits[0].assetId}
                render={(rentalUnit: RentalUnit) => (
                  <Field
                    name="id"
                    validate={FormValidators.compose(FormValidators.required())}
                    initialValue={rentalUnit.data.id}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        label={`${i18n.t(
                          "cb:RentalAgreement.Form.Fields.ID",
                          "ID"
                        )}*`}
                        {...input}
                        {...FV.getValidation(meta)}
                        // placeholder={i18n.t(
                        //   "cb:RentalAgreement.Form.Fields.willBeGenerated",
                        //   "wird generiert"
                        // )}
                        // readonly
                      />
                    )}
                  </Field>
                )}
              />
            </div>
            <div className={`__field __flex-3`}>
              <AssetLoader
                assetType={AssetTypes.Contact}
                id={props.data.step2.tenant}
                render={(contact: Contact) => (
                  <Field
                    name="displayName"
                    validate={FormValidators.compose(
                      FormValidators.required(),
                      FormValidators.max(150)
                    )}
                    initialValue={contact.data.displayName}
                  >
                    {({ input, meta }) => (
                      <BFInput
                        label={`${i18n.t(
                          "cb:RentalAgreement.Form.Fields.displayName",
                          "Anzeigename"
                        )}*`}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                        {...input}
                      />
                    )}
                  </Field>
                )}
              />
            </div>
          </div>

          <Field
            name="note"
            validate={FormValidators.compose(FormValidators.max(400))}
          >
            {({ input, meta }) => (
              <div className={`__field __flex-1`}>
                <BFInput
                  label={i18n.t("cb:RentalAgreement.Form.Fields.note", "Notiz")}
                  type="textarea"
                  validation={
                    meta.error && meta.touched
                      ? {
                          level: "error",
                          message: meta.error,
                        }
                      : undefined
                  }
                  {...input}
                />
              </div>
            )}
          </Field>

          <Field
            name="marks.rentDuration"
            validate={FV.compose(FV.required(), FV.min(1))}
          >
            {({ input, meta }) => (
              <BFPDFMarkerSection
                marginBottom={20}
                title={i18n.t(
                  "cb:RentalAgreement.Form.Fields.rentDuration.title",
                  "Vertragszeitraum"
                )}
                {...input}
                {...FV.getValidation(meta)}
                editHint={i18n.t(
                  "cb:RentalAgreement.Form.Fields.rentDuration.editHint",
                  "Markieren Sie die Stellen, wo Sie die Informationen zum Mietzeitraum finden."
                )}
                viewHint={i18n.t(
                  "cb:RentalAgreement.Form.Fields.rentDuration.viewHint",
                  "Informationen zum Mietzeitraum"
                )}
                identifier="rentDuration"
                pdfViewerIdentifier="rental-agreement-pdf"
                value={input.value}
                onChange={input.onChange}
              >
                <>
                  <div className="__flex">
                    <div className={`__field __flex-1`}>
                      <Field
                        name="moveIn"
                        validate={FormValidators.compose(
                          FormValidators.required()
                        )}
                      >
                        {({ input, meta }) => (
                          <BFDatefield
                            label={`${i18n.t(
                              "cb:RentalAgreement.Form.Fields.moveIn",
                              "Mietbeginn"
                            )}*`}
                            {...FV.getValidation(meta)}
                            {...input}
                          />
                        )}
                      </Field>
                    </div>
                    <FormFieldValues names={["moveIn"]}>
                      {([moveIn]) => (
                        <div className={`__field __flex-1`}>
                          <Field
                            name="agreementExpiration"
                            validate={FormValidators.compose(
                              FormValidators.dateAfter(moveIn)
                            )}
                          >
                            {({ input, meta }) => (
                              <BFDatefield
                                label={i18n.t(
                                  "cb:RentalAgreement.Form.Fields.agreementExpiration",
                                  "Vertrag bis"
                                )}
                                {...FV.getValidation(meta)}
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                      )}
                    </FormFieldValues>
                    <FormFieldValues names={["moveIn"]}>
                      {([moveIn]) => (
                        <div className={`__field __flex-1`}>
                          <Field
                            name="moveOut"
                            validate={FormValidators.compose(
                              FormValidators.dateAfter(moveIn)
                            )}
                          >
                            {({ input, meta }) => (
                              <BFDatefield
                                label={i18n.t(
                                  "cb:RentalAgreement.Form.Fields.moveOut",
                                  "Auszug"
                                )}
                                {...FV.getValidation(meta)}
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                      )}
                    </FormFieldValues>
                  </div>
                </>
              </BFPDFMarkerSection>
            )}
          </Field>

          <Field
            name="marks.paymentInfo"
            validate={FormValidators.compose(
              FormValidators.required(),
              FormValidators.min(1)
            )}
          >
            {({ input, meta }) => (
              <BFPDFMarkerSection
                marginBottom={20}
                title={i18n.t(
                  "cb:RentalAgreement.Form.SectionTitle.paymentInfo.title",
                  "Zahldaten"
                )}
                {...input}
                {...FV.getValidation(meta)}
                editHint={i18n.t(
                  "cb:RentalAgreement.Form.Fields.paymentInfo.editHint",
                  "Markieren Sie die Stellen, wo Sie die Informationen zur Miete finden."
                )}
                viewHint={i18n.t(
                  "cb:RentalAgreement.Form.Fields.paymentInfo.viewHint",
                  "Informationen zur Miete"
                )}
                identifier="paymentInfo"
                pdfViewerIdentifier="rental-agreement-pdf"
                value={input.value}
                onChange={input.onChange}
              >
                <>
                  <div className={`__flex`}>
                    <div className={`__field`}>
                      <Field name="taxable" validate={FV.required()}>
                        {({ input, meta }) => (
                          <BFRadioGroup
                            inline
                            value={input.value}
                            onChange={(_value, checked) => {
                              input.onChange(checked);

                              form.form.mutators.setValue(
                                "paymentPositions",
                                []
                              );
                              form.form.mutators.setValue(
                                "graduatedRent",
                                null
                              );
                            }}
                            {...FV.getValidation(meta)}
                          >
                            <BfRadio value={"taxable"}>
                              {i18n.t(
                                "cb:RentalAgreement.Form.taxable",
                                "Steuerpflichtig"
                              )}
                            </BfRadio>
                            <BfRadio value={"notTaxable"}>
                              {i18n.t(
                                "cb:RentalAgreement.Form.notTaxable",
                                "Nicht steuerpflichtig"
                              )}
                            </BfRadio>
                          </BFRadioGroup>
                        )}
                      </Field>
                    </div>

                    <div className={`__field margin-left-20`}>
                      <Field
                        name="rentPaymentTimingOptions"
                        validate={FormValidators.required()}
                      >
                        {({ input, meta }) => (
                          <BFSelect
                            label={`${i18n.t(
                              "cb:RentalAgreement.Form.Fields.rentPaymentTimingOptions",
                              "Sollstellung"
                            )}`}
                            cleanable={false}
                            labelPosition={"left"}
                            ignoreDataSort
                            data={getRentPaymentTimingOption()}
                            {...FV.getValidation(meta)}
                            {...input}
                          />
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className={`__field`}>
                    <CBRentalAgreementPaymentPositions
                      objectId={objectId}
                      fieldName="paymentPositions"
                    />
                  </div>
                </>
              </BFPDFMarkerSection>
            )}
          </Field>

          <CBRentalAgreementDepositForm objectId={objectId} />
          <CBRentalAgreementIndexForm />
          <CBRentalAgreementGraduatedRentForm objectId={objectId} />
          <CBRentalAgreementAutomaticForm />
          <CBRentalAgreementOptionForm />
          <CBRentalAgreementCopaymentForm />

          <div className={`additional-actions`}>
            <CBRentalAgreementGraduatedRentFormButton />
            <CBRentalAgreementIndexFormButton />
            <CBRentalAgreementOptionFormButton />
            <CBRentalAgreementAutomaticFormButton />
            <CBRentalAgreementDepositButton />
            <CBRentalAgreementCopaymentFormButton />
          </div>
        </div>
      )}
    />
  );
};

export default CBRentalAgreementFormWizardStep4ContractData;

import classNames from "classnames";
import { ButtonGroup } from "rsuite";
import i18n from "../../../../i18n";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import { RSAssigneeFilterOption } from "../../RSInterfaces";
import "./RSFilterButtons.scss";

interface RSFilterButtonsProps {
  onClickFilter: (filter: RSAssigneeFilterOption) => void;
  selectedFilter: RSAssigneeFilterOption;
}

const RSFilterButtons = (props: RSFilterButtonsProps) => {
  const handleOnClickFilter = (filter: RSAssigneeFilterOption) => {
    if (props.onClickFilter) {
      props.onClickFilter(filter);
    }
  };

  return (
    <div className={classNames("rs-filter-buttons")}>
      <ButtonGroup>
        <BFButton
          className={classNames({ selected: props.selectedFilter === "all" })}
          onClick={() => handleOnClickFilter("all")}
        >
          <div>{i18n.t("Resubmission.FilterButtons.All", "Alle")}</div>
        </BFButton>
        <BFButton
          className={classNames({ selected: props.selectedFilter === "own" })}
          onClick={() => handleOnClickFilter("own")}
        >
          <div>{i18n.t("Resubmission.FilterButtons.Own", "Eigene")}</div>
        </BFButton>
        <BFButton
          className={classNames({ selected: props.selectedFilter === "done" })}
          onClick={() => handleOnClickFilter("done")}
        >
          <div>{i18n.t("Resubmission.FilterButtons.Done", "Erledigt")}</div>
        </BFButton>
        {/* TODO SJ will be implemented when teams are working */}
        {/* <BFButton
          className={classNames({ selected: props.selectedFilter === "team" })}
          onClick={() => handleOnClickFilter("team")}
        >
          <div>{i18n.t("Resubmission.FilterButtons.Team", "Team")}</div>
        </BFButton> */}
      </ButtonGroup>
    </div>
  );
};

export default RSFilterButtons;

import { FV } from "@/components/Form/Validation/FormValidators";
import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import LanguageService from "@/services/LanguageService";
import { FieldRenderProps } from "react-final-form";
import { Customfield_boolean } from "../CustomField.interface";
// import "./CFBoolean.scss";

interface CFBooleanProps {
  data: Customfield_boolean;
  field: FieldRenderProps<any, HTMLElement>;
}
const CFBoolean = (props: CFBooleanProps) => {
  return (
    <BFCheckbox
      checked={props.field.input.value}
      onChange={(value, checked) => {
        props.field.input.onChange(checked);
      }}
      {...FV.getValidation(props.field.meta)}
    >
      {LanguageService.translateLabel(props.data.displayName)}
    </BFCheckbox>
  );
};

export default CFBoolean;

import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { IComponent } from "../../configurable/layouts/IComponent";
import BFTooltip from "../../modules/abstract-ui/general/tooltip/BFTooltip";
import BFWhisper from "../../modules/abstract-ui/general/whisper/BFWhisper";

type Props = {
  component: IComponent;
  params: any;
} & WithTranslation;

type States = {};

// tooltipText?: string;
// tooltipTextKey?: string;
// tooltipComp?: IComponent;
// tooltipTrigger?: "click" | "focus" | "hover" | "active";
// tooltipDelayShow?: number;
// tooltipDelayHide?: number;
// tooltipPlacement?: PlacementAll;

class TooltipComponent extends Component<Props, States> {
  static defaultProps = {};
  readonly state: States = {};

  render() {
    const { component, children, i18n, params } = this.props;

    if (!component) {
      return null;
    }

    if (
      component.tooltipText ||
      component.tooltipTextKey ||
      component.tooltipComp
    ) {
      let speaker = null;
      if (component.tooltipText) {
        speaker = <BFTooltip>{component.tooltipText}</BFTooltip>;
      }
      if (!speaker && component.tooltipTextKey) {
        speaker = <BFTooltip>{i18n.t(component.tooltipTextKey)}</BFTooltip>;
      }
      if (!speaker && component.tooltipComp) {
        speaker = (window as any).ComponentsMapper.createElement(
          component.tooltipComp,
          params
        );
      }

      return (
        <BFWhisper
          speaker={speaker}
          delayHide={component.tooltipDelayHide}
          placement={component.tooltipPlacement}
          delayShow={component.tooltipDelayShow}
          delay={component.tooltipDelay}
          trigger={component.tooltipTrigger}
        >
          <span>{children}</span>
        </BFWhisper>
      );
    } else {
      return children;
    }
  }
}

export default withTranslation()(TooltipComponent);

import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import AssetLoader from "../../../../../../../components/AssetLoader/AssetLoader";
import i18n from "../../../../../../../i18n";
import { AssetTypes } from "../../../../../../../model/AssetTypes";
import BFDetailsButton from "../../../../../../../modules/abstract-ui/general/Button/BFDetailsButton";
import StringUtils from "../../../../../../../utils/StringUtils";
import { RentalAgreement } from "../../TenantsInterfaces";
import "./CBRentalAgreementEntry.scss";

interface CBRentalAgreementEntryProps {
  rentalAgreement: RentalAgreement;
}
const CBRentalAgreementEntry = (props: CBRentalAgreementEntryProps) => {
  return (
    <div className={classNames(`cb-rental-agreement-entry`)}>
      <div className={`first-row`}>
        <div className={`id`}>{props.rentalAgreement.data.id}</div>
        <div className={`name`}>{props.rentalAgreement.data.displayName}</div>
        <div className={`date`}>
          {moment(props.rentalAgreement.data.moveIn).format(
            i18n.t("Formats.dateFormat")
          )}
          {" - "}
          {props.rentalAgreement.data.moveOut
            ? moment(props.rentalAgreement.data.moveIn).format(
                i18n.t("Formats.dateFormat")
              )
            : i18n.t("cb:RentalAgreementEntry.present", "jetzt")}
        </div>
      </div>
      <div className={`data-row`}>
        <div className={`rent`}>
          <div className={`__h3`}>
            {i18n.t("cb:RentalAgreementEntry.rent", "Miete")}
          </div>
          <div className={`rent-data`}>
            <div className={`rent-entry`}>
              <div className={`label`}>
                {i18n.t("cb:RentalAgreementEntry.rentNet", "Miete Netto")}
              </div>
              <div className={`value`}>
                {StringUtils.formatCurrency(
                  props.rentalAgreement.data.rentNet,
                  true
                )}
              </div>
            </div>
            <div className={`rent-entry`}>
              <div className={`label`}>
                {i18n.t("cb:RentalAgreementEntry.rentGross", "Miete Brutto")}
              </div>
              <div className={`value`}>
                {StringUtils.formatCurrency(
                  props.rentalAgreement.data.rentGross,
                  true
                )}
              </div>
            </div>
            <div className={`rent-entry`}>
              <div className={`label`}>
                {i18n.t(
                  "cb:RentalAgreementEntry.operatingCostNet",
                  "Nebenkosten Netto"
                )}
              </div>
              <div className={`value`}>
                {StringUtils.formatCurrency(
                  props.rentalAgreement.data.operatingCostNet,
                  true
                )}
              </div>
            </div>
            <div className={`rent-entry`}>
              <div className={`label`}>
                {i18n.t(
                  "cb:RentalAgreementEntry.operatingCostGross",
                  "Nebenkosten Brutto"
                )}
              </div>
              <div className={`value`}>
                {StringUtils.formatCurrency(
                  props.rentalAgreement.data.operatingCostGross,
                  true
                )}
              </div>
            </div>
            <div className={`rent-entry`}>
              <div className={`label`}>
                {i18n.t("cb:RentalAgreementEntry.deposit", "Kaution")}
              </div>
              <div className={`value`}>
                {StringUtils.formatCurrency(
                  _.sum(
                    (props.rentalAgreement.data.deposit || []).map(
                      (deposit) => deposit.depositValue || 0
                    )
                  ),
                  true
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`rental-units`}>
          <div className={`__h3`}>
            {i18n.t("cb:RentalAgreementEntry.rentalUnits", "Mieteinheiten")}
          </div>
          {props.rentalAgreement.data.rentalUnits.map((rentalUnitId) => {
            return (
              <AssetLoader
                assetType={AssetTypes.Rental.RentalUnit}
                id={rentalUnitId}
                render={(rentalUnit) => (
                  <BFDetailsButton
                    appearance="link"
                    noPadding
                    data={{
                      assetType: AssetTypes.Rental.RentalUnit,
                      assetId: rentalUnitId,
                      type: rentalUnit.data.type,
                    }}
                  >
                    {rentalUnit.data.id}
                    {" - "}
                    {rentalUnit.data.displayName}
                  </BFDetailsButton>
                )}
              />
            );
          })}
        </div>
      </div>
      <div className={`actions`}>
        <BFDetailsButton
          noPadding
          appearance="link"
          data={{
            assetType: AssetTypes.Rental.RentalAgreement,
            assetId: props.rentalAgreement._id,
            type: props.rentalAgreement.data.type,
          }}
        >
          {i18n.t("cb:RentalAgreementEntry.view", "Mietvertrag anzeigen")}
        </BFDetailsButton>
      </div>
    </div>
  );
};

export default CBRentalAgreementEntry;

import classNames from "classnames";
import { Slider } from "rsuite";
import "./BFNumbericSlider.scss";

interface BFNumbericSliderProps {
  label?: string;
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  step: number;
  disabled?: boolean;
  formatter?: (value: number) => string;
  handleWidth?: number;
  className?: string;
}
const BFNumbericSlider = (props: BFNumbericSliderProps) => {
  return (
    <div className={classNames(`bf-numberic-slider`, props.className)}>
      <Slider
        className="slider-with-handle"
        min={props.min}
        max={props.max}
        value={props.value}
        handleStyle={{
          borderRadius: 10,
          color: "#fff",
          fontSize: 12,
          width: props.handleWidth || 40,
          height: 22,
        }}
        step={props.step}
        tooltip={false}
        handleTitle={
          <div className={`number-input`}>
            {props.formatter ? props.formatter(props.value) : props.value}
          </div>
        }
        onChange={props.onChange}
      />
    </div>
  );
};

export default BFNumbericSlider;

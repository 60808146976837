import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import classNames from "classnames";
import {
  CBPortfolioObject,
  CBPortfolioObjectAddress,
} from "../../../../../../apps/tatar/cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import i18n from "../../../../../../i18n";
import LanguageService from "../../../../../../services/LanguageService";
import LabeledInput from "../../../../../abstract-ui/forms/LabeledInput";
import { Resubmission } from "../../../../RSInterfaces";
import RSAssetDetails from "../../../asset-details/RSAssetDetails";
import RSSubAssetMapper from "../../../asset-details/sub-asset-mapper/RSSubAssetMapper";
import "./RSObjectPreview.scss";

interface RSObjectPreviewProps {
  resubmission: Resubmission;
}

const RSObjectPreview = (props: RSObjectPreviewProps) => {
  const { linkedAsset } = props.resubmission.data;

  const renderTypeInfoDetails = (object: CBPortfolioObject) => {
    return (
      <RSSubAssetMapper resubmission={props.resubmission} asset={object} />
    );
  };

  const renderAddressEntry = (address: CBPortfolioObjectAddress) => {
    return (
      <div className="address-entry">
        <div className="street">
          {address?.streetname || ""} {address?.streetnumber || ""}
        </div>
        <div className="city">
          {address?.postalcode || ""} {address?.city || ""}
        </div>
      </div>
    );
  };

  return (
    <div className={classNames("rs-preview-object")}>
      <LabeledInput
        type="sub"
        label={i18n.t("Resubmission.Dashboard.Object.Title", "Objekt Daten")}
      >
        <RSAssetDetails
          assetParams={linkedAsset}
          renderAssetData={(asset: CBPortfolioObject) => {
            return (
              <div className="object-container">
                <div className="object-row">
                  <div className="__flex-1">
                    <LabeledInput
                      type="sub"
                      label={i18n.t("Resubmission.Labels.Type", "Bereich")}
                    >
                      <div>
                        {LanguageService.translateLabel(
                          UnitStruct.getUnit(asset.data.type)?.data.label
                        )}
                      </div>
                    </LabeledInput>
                  </div>
                  <div className="__flex-1">
                    <LabeledInput
                      type="sub"
                      label={i18n.t("Resubmission.Labels.Address", "Adresse")}
                    >
                      {Object.values(asset.data?.address)
                        .sort((a, b) => (a.isMain ? -1 : 1))
                        .map((address, index) => renderAddressEntry(address))}
                    </LabeledInput>
                  </div>
                </div>
                {linkedAsset.subAsset ||
                  (linkedAsset.assetField && (
                    <div className={classNames("object-row", "type-info")}>
                      {renderTypeInfoDetails(asset)}
                    </div>
                  ))}
              </div>
            );
          }}
        />
      </LabeledInput>
    </div>
  );
};

export default RSObjectPreview;

import Toast from "@/modules/abstract-ui/notification/Toast";
import moment from "moment";
import { CSSProperties } from "react";

const BREAKS = {
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 576,
};

type CSSPropertiesExtended = {
  breakpoint?: number | "xs" | "sm" | "md" | "lg";
} & CSSProperties;
export type StyleConfiguration = CSSPropertiesExtended[] | CSSProperties;

class ToolsClass {
  evaluateObject(
    obj: { [key: string]: any },
    evalMethod: (condition: string) => any
  ) {
    const result = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (key.startsWith("_") && typeof value === "string") {
        result[key.substr(1)] = evalMethod(value);
      } else {
        result[key.substr(key.startsWith("_") ? 1 : 0)] = value;
      }
    });
    return result;
  }

  queryParams(searchParams?: string) {
    return new URLSearchParams(
      searchParams ? searchParams : window.location.search
    );
  }

  copy2Clipboard(str: string, txtToast?: string, parent?: HTMLElement) {
    const parentToUse = parent ? parent : document.body;
    const ta = document.createElement("textarea");
    ta.value = str;
    parentToUse.appendChild(ta);
    ta.select();

    document.execCommand("copy");
    parentToUse.removeChild(ta);
    if (txtToast) {
      Toast.info(txtToast);
    }
  }

  transformSantizedToCSS(obj) {
    if (!obj) {
      return null;
    }
    const transformed = {};
    Object.keys(obj).forEach((key) => {
      let useKey = key.replace("/class/", ".");
      if (typeof obj[key] === "object") {
        transformed[useKey] = this.transformSantizedToCSS(obj[key]);
      } else {
        transformed[useKey] = obj[key];
      }
    });

    return transformed;
  }

  getActiveBreakpointProperties(
    viewportWidth: number,
    styles: { breakpoint?: number; [key: string]: any }
  ): { breakpoint?: number; [key: string]: any } {
    let usedStyle = null;
    if (Array.isArray(styles)) {
      styles
        .sort((a, b) => this.getBreakpoint(b) - this.getBreakpoint(a))
        .forEach((style) => {
          let breakAt = this.getBreakpoint(style);

          if (viewportWidth <= breakAt) {
            // check if this is better than current
            if (!usedStyle) {
              usedStyle = { ...style };
            } else {
              usedStyle = { ...usedStyle, ...style };
            }
          }
        });
    } else {
      usedStyle = styles;
    }
    return usedStyle;
  }

  private getBreakpoint(style: CSSPropertiesExtended) {
    let breakAt = null;
    if (style.breakpoint) {
      if (typeof style.breakpoint === "string") {
        breakAt = BREAKS[style.breakpoint];
        if (!breakAt) {
          throw "not a valid break selector";
        }
      } else {
        breakAt = style.breakpoint;
      }
    } else {
      breakAt = Number.MAX_SAFE_INTEGER;
    }

    return breakAt;
  }

  momentFromObjectId(objectId: string) {
    return moment(this.timestampFromObjectId(objectId));
  }

  dateFromObjectId(objectId: string) {
    return new Date(this.timestampFromObjectId(objectId));
  }

  timestampFromObjectId(objectId: string) {
    const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000;
    return timestamp;
  }

  registerWindowObject(name: string, obj: any) {
    (window as any)[name] = obj;
  }
  isPromise(p) {
    return p && Object.prototype.toString.call(p) === "[object Promise]";
  }
}
const Tools = new ToolsClass();
export default Tools;

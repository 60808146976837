import { ImgHTMLAttributes, RefObject, useEffect, useState } from "react";
import "./PDFImagePreview.scss";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";

const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

type ImagePreviewProps = {
  file: File | string | null;
  imageRef: RefObject<HTMLImageElement>;
} & ImgHTMLAttributes<HTMLImageElement>;

const PDFImagePreview: React.FC<ImagePreviewProps> = ({
  file,
  imageRef,
  alt = "Preview",
  ...props
}) => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!file) {
      setUrl(null);
      return;
    }

    if (typeof file === "string") {
      setUrl(file);
      return;
    }

    convertFileToBase64(file).then((url) => setUrl(url));
  }, [file]);

  return (
    <div className="preview--container">
      {file ? (
        <img ref={imageRef} src={url} alt={alt} {...props} />
      ) : (
        <BfIcon type="light" data="upload-thick-bottom" />
      )}
    </div>
  );
};

export { PDFImagePreview };

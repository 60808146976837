import i18n from "../../../../i18n";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import { HTTP } from "../../../../utils/Http";
import StringUtils from "../../../../utils/StringUtils";

export const splitBooking = (
  bookingId,
  bookings: { category: string; usage: string; value: number }[]
) => {
  return new Promise((resolve, reject) => {
    HTTP.post({
      target: "EMPTY",
      url: `liquiplanservice/splitBooking`,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      bodyParams: {
        objectIdToSplit: bookingId,
        split: bookings.map((e) => ({
          ...e,
          value: StringUtils.normalizeDecimal(e.value),
        })),
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const splitBookingWithFile = async (bookingId: string, file: File) => {
  try {
    const fileContent = await file.text();
    const result = await HTTP.post({
      target: "EMPTY",
      url: `liquiplanservice/splitBookingWithFile`,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      bodyParams: {
        objectIdToSplit: bookingId,
        value: fileContent,
      },
    });
    DataBusDefaults.toast({
      type: "success",
      text: i18n.t(
        "cb:splitBookingWithFile.success",
        "Buchung wird in Kürze aufgeteilt"
      ),
      duration: 5000,
    });
    return result;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      switch (err.response.data.message) {
        case "INVALID_SUM_EXCEPTION":
          DataBusDefaults.toast({
            type: "error",
            text: i18n.t(
              "cb:splitBookingWithFile.INVALID_SUM_EXCEPTION",
              "Summe der SEPA-Datei passt nicht mit der Buchung überein."
            ),
            duration: 5000,
          });
          break;
        default:
          DataBusDefaults.toast({
            type: "error",
            text: i18n.t(
              "cb:splitBookingWithFile.error",
              "Fehler beim Aufteilen der Buchung - Überprüfen Sie die Datei"
            ),
            duration: 5000,
          });
          break;
      }
    } else {
      DataBusDefaults.toast({
        type: "error",
        text: i18n.t(
          "cb:splitBookingWithFile.error",
          "Fehler beim Aufteilen der Buchung - Überprüfen Sie die Datei"
        ),
        duration: 5000,
      });
    }
  }
};

import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { AddressData } from "@/model/db/Contact";
import BFEntries from "@/modules/abstract-ui/data/entries/BFEntries";
import Tools from "@/utils/Tools";
import classNames from "classnames";
import { useRef } from "react";

interface ContactBaseAddressProps {
  address: AddressData[];
}
const ContactBaseAddress = (props: ContactBaseAddressProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className={classNames(`contact-base-address`)} ref={ref}>
      <BFEntries
        emptyText={i18n.t(
          "ContactBaseDataView.noAddress",
          "Keine Adressen vorhanden"
        )}
        entries={props.address.map((address) => {
          const mapsHrefLinkParts = [
            address.street.split(" ").map(encodeURIComponent),
            address.zip.split(" ").map(encodeURIComponent),
            address.city.split(" ").map(encodeURIComponent),
          ]
            .flat()
            .filter(Boolean)
            .join("+");

          const addAddressToClipboard = () => {
            const textToCopy = [
              address.street,
              [address.zip, address.city].filter(Boolean).join(" "),
            ]
              .filter(Boolean)
              .join("\n");
            Tools.copy2Clipboard(
              textToCopy,
              i18n.t(
                "ContactBaseAddress.copiedToClipboard",
                "Adresse in Zwischenablage kopiert"
              ),
              ref.current
            );
          };

          return {
            content: (
              <>
                <DebugDataComponent data={address} />
                <div className={`street`}>{address.street}</div>
                <div className={`zip-city`}>
                  {[address.zip, address.city].filter(Boolean).join(" ")}
                </div>
                {address.additional && (
                  <div className={`additional`}>{address.additional}</div>
                )}
                {address.country && (
                  <div className={`country`}>{address.country}</div>
                )}
              </>
            ),
            actions: [
              {
                icon: "duplicate-file",
                onClick: addAddressToClipboard,
                tooltip: i18n.t(
                  "ContactBaseAddress.copyToClipboard",
                  "In Zwischenablage kopieren"
                ),
              },
              {
                icon: "maps",
                href: `https://www.google.com/maps/search/?api=1&query=${mapsHrefLinkParts}`,
                tooltip: i18n.t(
                  "ContactBaseAddress.showInMaps",
                  "In Maps öffnen"
                ),
              },
            ],
          };
        })}
      />
    </div>
  );
};

export default ContactBaseAddress;

import classNames from "classnames";
import { css } from "emotion";
import { CSSProperties } from "react";
import { connect } from "react-redux";
import { Loader } from "rsuite";
import Log from "../../debug/Log";
import BFPortal from "../../modules/abstract-ui/general/Portal/BFPortal";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import CDNService from "../../services/CDNService";
import DataBus from "../../services/DataBus";
import { DataBusSubKeys } from "../../utils/Constants";
import {
  AbstractStylableComponent,
  AbstractStylableProps,
  AbstractStylableStates,
} from "../../utils/abstracts/AbstractStylableComponent";
import "./CDNImage.scss";

export type CDNImageProps = {
  assetType: string;
  assetId: string;
  assetField: string;
  cdnId: string;
  filename: string;
  fileKey: string;
  hasFolderReadPermissions: boolean;
};
type Props = CDNImageProps & {
  imageType: "div" | "img";
  dimension?: "thumb" | "sl" | "lg" | "md" | "sm" | "xs";
  maximizeOnHover?: boolean;
  onUrlReceived?: (url: string) => void;
  renderContent?: React.ReactNode;
  withoutContainer?: boolean;
  ignoreContent?: boolean;
  onClick?: (ev: any) => void;
  shadow?: boolean;
} & AbstractStylableProps;

type States = {
  imageLink: string;
  loading: boolean;
  error: any;
  hover: boolean;
} & AbstractStylableStates;

class CDNImage extends AbstractStylableComponent<Props, States> {
  static defaultProps = {};
  readonly state: States = {
    imageLink: null,
    loading: true,
    error: null,
    hover: false,
  };
  imageRef: HTMLElement = null;
  portalContainer = null;
  cancelObj = {
    cancel: undefined,
  };

  componentDidMount() {
    DataBus.subscribe(DataBusSubKeys.PORTAL_MODAL, (container) => {
      this.portalContainer = container;
    });

    CDNService.fetchCDNLink({
      assetField: this.props.assetField,
      assetId: this.props.assetId,
      assetType: this.props.assetType,
      cdnId: this.props.cdnId,
      hasFolderReadPermissions: this.props.hasFolderReadPermissions,
      fileKey: this.props.fileKey,
      cancelObj: this.cancelObj,
    })
      .then((url) => {
        if (this.props.onUrlReceived) {
          this.props.onUrlReceived(url);
        }
        this.setState({
          loading: false,
          imageLink: url,
        });
      })
      .catch((err) =>
        this.setState({
          loading: false,
          error: err,
        })
      );
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.cancelObj.cancel) {
      this.cancelObj.cancel();
    }
  }

  renderImage() {
    const { loading, error, imageLink } = this.state;
    const { imageType, filename, dimension, renderContent, ignoreContent } =
      this.props;

    if (error) {
      Log.error("CDN image error", error);
      return <div className={`cdn-image-entry `}> ERR </div>;
    }

    if (imageType === "img") {
      return (
        <>
          <img
            onClick={this.props.onClick}
            className={classNames(`cdn-image-entry image-img`, {
              clickable: !!this.props.onClick,
            })}
            src={`${imageLink}${dimension ? `&d=${dimension}` : ""}`}
            alt={filename}
            ref={(ref) => (this.imageRef = ref)}
          />

          {!ignoreContent && renderContent ? (
            <div className={`image-description-container`}>
              <div className={`image-description`}>{renderContent}</div>
            </div>
          ) : null}
        </>
      );
    }
    if (imageType === "div") {
      return (
        <div
          onClick={this.props.onClick}
          className={classNames(`cdn-image-entry image-div `, {
            clickable: !!this.props.onClick,
          })}
          style={{
            backgroundImage: `url(${imageLink}${
              dimension ? `&d=${dimension}` : ""
            })`,
          }}
          ref={(ref) => (this.imageRef = ref)}
        >
          {!ignoreContent && renderContent ? renderContent : null}
        </div>
      );
    }

    return null;
  }

  render() {
    const { loading, error, imageLink, hover } = this.state;
    const { imageType, filename } = this.props;

    const styles: CSSProperties = {
      position: "absolute",
      display: "none",
      justifyContent: "center",
      alignItems: "center",
    };
    const scaleFactor = 4;
    if (this.imageRef) {
      const imageData = this.imageRef.getBoundingClientRect();

      styles.display = "flex";
      styles.left = Math.max(
        0,
        imageData.left +
          imageData.width / 2 -
          (imageData.width * scaleFactor) / 2
      );
      styles.top = Math.max(
        0,
        imageData.top +
          imageData.height / 2 -
          (imageData.height * scaleFactor) / 2
      );
      styles.width = imageData.width * scaleFactor;
      styles.height = imageData.height * scaleFactor;
    }

    if (this.props.imageType === "img" && this.props.withoutContainer) {
      return this.renderImage();
    }
    return (
      <>
        <div
          className={classNames(
            `cdn-image ${
              this.state.usedStyle ? css(this.state.usedStyle as any) : ""
            }`,
            {
              shadow: this.props.shadow,
            }
          )}
          onMouseEnter={() => {
            this.setState({ hover: true });
          }}
          onMouseLeave={() => {
            this.setState({ hover: false });
          }}
        >
          {loading ? <Loader /> : this.renderImage()}
        </div>

        {this.props.maximizeOnHover && hover ? (
          <BFPortal container={this.portalContainer}>
            <div style={styles} className={`cdn-image-hover`}>
              <img
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                className={`image-img`}
                src={`${imageLink}&d=xs`}
                alt={filename}
              />
            </div>
          </BFPortal>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  viewportWidth: Array.isArray(props.style)
    ? state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
    : null,
});

export default connect(mapStateToProps, {})(CDNImage) as any;

import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { usePDFConstructor } from "../context/PDFConstructorContext";

import React from "react";
import i18n from "@/i18n";
import { getAssetType } from "../common/PDFHelpers";
import BFAssetChooserSelect from "@/modules/abstract-ui/forms/chooser/BFAssetChooserSelect";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";

const PDFAssetInformation: React.FC = () => {
  const [{ asset }] = usePDFConstructor();

  const type = UnitStruct.getUnitLabel(asset.data.type);

  const objectKind = ObjectKindStruct.getKindLabel(asset.data.objectKindId);

  const assetType = getAssetType();

  return (
    <div className="pdf-constructor--asset-information">
      <BFInput
        label={i18n.t("Component.PDFConstructor.Asset.Type", "Typ")}
        value={type}
        disabled
      />

      <BFInput
        label={i18n.t("Component.PDFConstructor.Asset.AssetType", "Anlagetyp")}
        value={assetType.label}
        disabled
      />

      <BFInput
        label={i18n.t("Component.PDFConstructor.Asset.ObjectKind", "Objektart")}
        value={objectKind}
        disabled
      />

      <BFAssetChooserSelect
        label={i18n.t(
          "Component.PDFConstructor.Asset.ExampleObject",
          "Beispielobjekt"
        )}
        assetType={assetType.value}
        value={asset._id}
        onChange={() => {}}
        getOption={(node: OAObject) => ({
          label: node.data.displayName,
          value: node._id,
        })}
        disabled
      />
    </div>
  );
};

export { PDFAssetInformation };

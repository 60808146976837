import { hasValue } from "@/utils/Helpers";
import classNames from "classnames";
import { useState } from "react";
import Collapse from "rsuite/esm/Animation/Collapse";
import "./BFSection.scss";

interface BFSectionProps {
  children: JSX.Element;
  title: string;
  className?: string;
  headerAction?: React.ReactNode;
  small?: boolean;
  collapsible?:
    | boolean
    | {
        initialCollapsed: boolean;
      };
}
const BFSection = (props: BFSectionProps) => {
  const [collapsed, setCollapsed] = useState(
    typeof props.collapsible === "boolean"
      ? false
      : Boolean(props.collapsible?.initialCollapsed)
  );
  const useCollapseFeature = hasValue(props.collapsible);
  return (
    <div
      className={classNames(`bf-section`, props.className, {
        small: props.small,
        collapsible: useCollapseFeature,
      })}
    >
      <div className={classNames(`section-header`)}>
        <div className={`bf-section-title-wrapper`}>
          <div
            className={`section-title __h2`}
            onClick={() => setCollapsed(!collapsed)}
          >
            {useCollapseFeature && (
              <div
                className={classNames(`collapse-icon`, {
                  collapsed: collapsed,
                })}
              >
                ▲
              </div>
            )}
            <span className={`title-text`}>{props.title}</span>
          </div>
        </div>
        {Boolean(props.headerAction) && (
          <div className={`header-actions`}>{props.headerAction}</div>
        )}
      </div>
      {useCollapseFeature ? (
        <Collapse in={!collapsed} unmountOnExit={false}>
          <div>
            <div className={`section-content`}>{props.children}</div>
          </div>
        </Collapse>
      ) : (
        <div className={`section-content`}>{props.children}</div>
      )}
    </div>
  );
};

export default BFSection;

import classNames from "classnames";
import React from "react";
import { AssetTypes } from "../../../../model/AssetTypes";
import { Resubmission } from "../../RSInterfaces";
import RSDetailsComponent from "../details/RSDetailsComponent";
import "./RSPreviewComponent.scss";
import RSPreviewActivity from "./components/activity/RSPreviewActivity";
import RSObjectPreview from "./components/object/RSObjectPreview";
import RSRentalAgreementPreview from "./components/rental/RSRentalAgreementPreview";
import RSRentalUnitPreview from "./components/rental/RSRentalUnitPreview";
import RSTenantPreview from "./components/rental/RSTenantPreview";
import RSPreviewUnassignedMail from "./components/unassigned-mail/RSPreviewUnassignedMail";

interface RSPreviewComponentProps {
  resubmission: Resubmission;
  listIdentifier?: string;
  closeAction: () => void;
}

const RSPreviewComponent = (props: RSPreviewComponentProps) => {
  const detailsMap: Map<string, React.ReactNode> = new Map();
  detailsMap.set(
    AssetTypes.Portfolio.Object,
    <RSObjectPreview resubmission={props.resubmission} />
  );
  detailsMap.set(
    AssetTypes.Contact,
    <RSTenantPreview resubmission={props.resubmission} />
  );

  detailsMap.set(
    AssetTypes.Rental.RentalUnit,
    <RSRentalUnitPreview resubmission={props.resubmission} />
  );

  detailsMap.set(
    AssetTypes.Rental.RentalAgreement,
    <RSRentalAgreementPreview resubmission={props.resubmission} />
  );

  detailsMap.set(
    AssetTypes.Comment,
    <RSPreviewUnassignedMail resubmission={props.resubmission} />
  );

  detailsMap.set(
    AssetTypes.Activity.DamageClaim,
    <RSPreviewActivity resubmission={props.resubmission} />
  );

  detailsMap.set(
    AssetTypes.Activity.OrderingProcess,
    <RSPreviewActivity resubmission={props.resubmission} />
  );

  const renderAssetDetails = () => {
    if (!props.resubmission.data.linkedAsset) {
      return null;
    }

    const assetComponent = detailsMap.get(
      props.resubmission.data.linkedAsset.assetType
    );

    if (assetComponent) {
      return assetComponent;
    }

    return null;
  };

  return (
    <div className={classNames("rs-preview")}>
      <RSDetailsComponent
        resubmissionId={props.resubmission._id}
        renderAssetDetails={renderAssetDetails}
        preventEdit={true}
        listIdentifier={props.listIdentifier}
        closeResubmission={props.closeAction}
      />
    </div>
  );
};

export default RSPreviewComponent;

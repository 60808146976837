import ObjectIdService from "@/utils/ObjectIdUtils";
import { FormApi } from "final-form";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import FormFieldValues from "../../../components/Form/Fields/FormFieldValues";
import FormValidators from "../../../components/Form/Validation/FormValidators";
import i18n from "../../../i18n";
import FormUtils from "../../../utils/FormUtils";
import BFInput from "../../abstract-ui/forms/input/BFInput";
import BFSelect from "../../abstract-ui/forms/select/BFSelect";
import BFToggle from "../../abstract-ui/forms/toggle/BFToggle";
import BFButton from "../../abstract-ui/general/Button/BFButton";

export const AddressFormPart = (props: {
  form: FormApi;
  prefix?: string;
  disabled?: boolean;
}) => {
  return (
    <FieldArray name={(props.prefix || "") + "address"}>
      {({ fields, meta }) => (
        <div className={`section address-section`}>
          <div className={`section-title __h3 margin-bottom-0 margin-top-ss0`}>
            {i18n.t("Contact.Form.Sections.Adresses", "Adressen")}
            <BFButton
              className={`margin-left-10`}
              appearance="link"
              size="xs"
              disabled={props.disabled}
              onClick={() =>
                fields.push({
                  id: ObjectIdService.new(),
                  isMain: false,
                  street: "",
                  zip: "",
                  city: "",
                  country: "",
                  additional: "",
                })
              }
            >
              {i18n.t(
                "Contact.Form.Fields.Address.AddAnother",
                "Weitere Adresse hinzufügen"
              )}
            </BFButton>
          </div>
          <div className="addresses">
            {fields.map((name, index) => (
              <div className={`address-entry`} key={index}>
                <div className={`action-row`}>
                  <BFButton
                    appearance="link"
                    size="xs"
                    disabled={props.disabled}
                    onClick={() => {
                      if (fields.length > 1) {
                        fields.remove(index);
                      } else {
                        props.form.mutators.setValue(
                          (props.prefix || "") + "address",
                          null
                        );
                        props.form.mutators.setValue(
                          (props.prefix || "") + "helpers.showAddress",
                          false
                        );
                      }
                    }}
                  >
                    {i18n.t("Global.Buttons.remove")}
                  </BFButton>
                </div>
                <div className={`field-row`}>
                  <div className={`__field __flex-1`}>
                    <Field name={`${name}.isMain`}>
                      {({ input }) => (
                        <BFToggle
                          label={i18n.t(
                            "Contact.Form.Fields.Address.Main",
                            "Hauptadresse"
                          )}
                          onChange={(_, checked) => {
                            fields.forEach((otherField) => {
                              if (otherField !== name) {
                                props.form.mutators.setValue(
                                  `${otherField}.isMain`,
                                  false
                                );
                              }
                            });
                            input.onChange(checked);
                          }}
                          checked={input.value}
                          disabled={props.disabled}
                        ></BFToggle>
                      )}
                    </Field>
                  </div>
                </div>
                <div className={`field-row`}>
                  <div className={`__field __flex-1`}>
                    <Field
                      name={`${name}.street`}
                      validate={FormValidators.compose(
                        FormValidators.required(),
                        FormValidators.max(300)
                      )}
                    >
                      {({ input, meta }) => (
                        <BFInput
                          {...input}
                          disabled={props.disabled}
                          label={`${i18n.t(
                            "Contact.Form.Fields.Address.Street",
                            "Straße"
                          )}*`}
                          validation={
                            meta.error && meta.touched
                              ? {
                                  level: "error",
                                  message: meta.error,
                                }
                              : undefined
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className={`__field __flex-1`}>
                    <Field
                      name={`${name}.additional`}
                      validate={FormValidators.compose(FormValidators.max(300))}
                    >
                      {({ input, meta }) => (
                        <BFInput
                          {...input}
                          disabled={props.disabled}
                          label={`${i18n.t(
                            "Contact.Form.Fields.Address.Additional",
                            "Zusatz"
                          )}`}
                          validation={
                            meta.error && meta.touched
                              ? {
                                  level: "error",
                                  message: meta.error,
                                }
                              : undefined
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <div className={`field-row`}>
                  <div className={`__field __flex-1`}>
                    <Field
                      name={`${name}.country`}
                      // validate={FormValidators.compose(
                      //   FormValidators.required()
                      // )}
                    >
                      {({ input, meta }) => (
                        <BFSelect
                          disabled={props.disabled}
                          {...input}
                          label={`${i18n.t(
                            "Contact.Form.Fields.Address.Country",
                            "Land"
                          )}`}
                          data={FormUtils.getCountrySelectOptions([
                            "DE",
                            "US",
                            "AT",
                            "CH",
                          ])}
                          validation={
                            meta.error && meta.touched
                              ? {
                                  level: "error",
                                  message: meta.error,
                                }
                              : undefined
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className={`__field __flex-1`}>
                    <FormFieldValues names={[`${name}.country`]}>
                      {([country]) => (
                        <Field
                          name={`${name}.zip`}
                          validate={FormValidators.compose(
                            FormValidators.required(),
                            FormValidators.max(100)
                          )}
                        >
                          {({ input, meta }) => (
                            <BFInput
                              disabled={props.disabled}
                              {...input}
                              label={`${i18n.t(
                                "Contact.Form.Fields.Address.Zip",
                                "Postleitzahl"
                              )}*`}
                              validation={
                                meta.error && meta.touched
                                  ? {
                                      level: "error",
                                      message: meta.error,
                                    }
                                  : undefined
                              }
                            />
                          )}
                        </Field>
                      )}
                    </FormFieldValues>
                  </div>
                  <div className={`__field __flex-2`}>
                    <Field
                      name={`${name}.city`}
                      validate={FormValidators.compose(
                        FormValidators.required(),
                        FormValidators.max(300)
                      )}
                    >
                      {({ input, meta }) => (
                        <BFInput
                          disabled={props.disabled}
                          {...input}
                          label={`${i18n.t(
                            "Contact.Form.Fields.Address.City",
                            "Stadt"
                          )}*`}
                          validation={
                            meta.error && meta.touched
                              ? {
                                  level: "error",
                                  message: meta.error,
                                }
                              : undefined
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </FieldArray>
  );
};

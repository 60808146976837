import classNames from "classnames";
import { Field, Form } from "react-final-form";
import i18n from "../../../../i18n";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import { handleError } from "../../../../utils/ErrorCodes";
import { HTTP } from "../../../../utils/Http";
import BFInput from "../../../abstract-ui/forms/input/BFInput";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import { GFPromptBlock } from "../../../generic-forms-impl/layout-components/LayoutComponentsImpl";
import "./UserSecurity.scss";

interface UserSecurityProps {}

const getPasswordStrength = (password: string) => {
  const extraCharacterRegex = /^(?=.*?[#?!@$%^&*-._%$<>,/\\]).{8,}$/;
  const numberRegex = /^(?=.*?[0-9]).{8,}$/;
  const characterUsage = /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/;

  const matches = [
    extraCharacterRegex.test(password),
    numberRegex.test(password),
    characterUsage.test(password),
  ].filter((e) => e);

  if (!password || password.length < 8) {
    return ["", ""];
  }
  if (password.length > 14 || matches.length === 3) {
    return [
      "string",
      i18n.t(
        "UserProfile.Security.ChangePassword.PasswordStrength.Strong",
        "Stark"
      ),
    ];
  }
  if (matches.length === 2) {
    return [
      "medium",
      i18n.t(
        "UserProfile.Security.ChangePassword.PasswordStrength.Medium",
        "Mittel"
      ),
    ];
  }
  if (matches.length === 1) {
    return [
      "weak",
      i18n.t(
        "UserProfile.Security.ChangePassword.PasswordStrength.Weak",
        "Schwach"
      ),
    ];
  } else {
    return [
      "reallyWeak",
      i18n.t(
        "UserProfile.Security.ChangePassword.PasswordStrength.ReallyWeak",
        "Sehr Schwach"
      ),
    ];
  }
};
const UserSecurity = (props: UserSecurityProps) => {
  return (
    <div className={classNames(`user-security`)}>
      <div className="__h1 user-security__header">
        {i18n.t("UserProfile.Security.ChangePassword.Title", "Passwort ändern")}
      </div>
      <Form
        onSubmit={async (values) => {
          try {
            await HTTP.post({
              url: `/api/user/changePassword`,
              target: "EMPTY",
              bodyParams: {
                oldPassword: values.oldPassword,
                password: values.password,
              },
            });
            DataBusDefaults.toast({
              type: "success",
              text: i18n.t(
                "UserProfile.Security.ChangePassword.Success",
                "Passwort erfolgreich geändert"
              ),
            });
          } catch (err) {
            handleError(err);
            // DataBusDefaults.toast({
            //   type: "error",
            //   text: i18n.t(
            //     "UserProfile.Security.ChangePassword.Error",
            //     "Fehler beim Ändern des Passworts"
            //   ),
            // });
          }
        }}
        validate={(values) => {
          const errors = {};

          if (!values.oldPassword) {
            errors["oldPassword"] = i18n.t(
              "UserProfile.Security.ChangePassword.Error.OldPassword",
              "Bitte geben Sie Ihr aktuelles Passwort ein"
            );
          }
          if (!values.password) {
            errors["password"] = i18n.t(
              "UserProfile.Security.ChangePassword.Error.Password",
              "Bitte geben Sie ein neues Passwort ein"
            );
          }
          if (!values.retypePassword) {
            errors["retypePassword"] = i18n.t(
              "UserProfile.Security.ChangePassword.Error.TypeOldPassword",
              "Bitte wiederholen Sie Ihr neues Passwort"
            );
          }
          if (values.retypePassword !== values.password) {
            errors["retypePassword"] = i18n.t(
              "UserProfile.Security.ChangePassword.Error.RepeatNotMatching",
              "Die Passwörter stimmen nicht überein"
            );
          }
          if (values.password?.length > 64) {
            errors["password"] = i18n.t(
              "UserProfile.Security.ChangePassword.Error.MaxPasswordLength",
              "Das Passwort darf maximal 64 Zeichen lang sein"
            );
          }
          // password has to contain at least one number, one lowercase and one uppercase letter, one special character, and be at least 8 characters long
          if (values.password?.length < 8) {
            errors["password"] = i18n.t(
              "UserProfile.Security.ChangePassword.Error.MinPasswordLength",
              "Das Passwort muss mindestens 8 Zeichen lang sein."
            );
          }

          return errors;
        }}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <GFPromptBlock />
              <div className={`description`}>
                {i18n.t(
                  "UserProfile.Security.ChangePassword.Description",
                  "Um Ihr Passwort zu ändern, geben Sie bitte Ihr aktuelles Passwort ein und wählen Sie ein neues Passwort."
                )}
              </div>
              <div className={`form-fields`}>
                <div className={`__field`}>
                  <Field name="oldPassword">
                    {({ input, meta }) => (
                      <BFInput
                        type="password"
                        label={i18n.t(
                          "UserProfile.Security.ChangePassword.CurrentPassword",
                          "Aktuelles Passwort"
                        )}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                <Field name="password">
                  {({ input, meta }) => (
                    <>
                      <div className={`__field`}>
                        <BFInput
                          type="password"
                          label={i18n.t(
                            "UserProfile.Security.ChangePassword.NewPassword",
                            "Neues Passwort"
                          )}
                          validation={
                            meta.error && meta.touched
                              ? { level: "error", message: meta.error }
                              : undefined
                          }
                          {...input}
                        />
                      </div>
                      <div className={`password-hint-container`}>
                        <div className={`strength`}>
                          <span>
                            {i18n.t(
                              "UserProfile.Security.ChangePassword.PasswordStrength",
                              "Passwortstärke: "
                            )}
                          </span>
                          <span
                            className={`pw-strength ${
                              getPasswordStrength(input.value || "")[0]
                            }`}
                          >
                            {getPasswordStrength(input.value || "")[1]}
                          </span>
                        </div>
                        <div className={`pw-hint`}>
                          {i18n.t(
                            "UserProfile.Security.ChangePassword.PasswordHint",
                            "Sie müssen mindestens 8 Zeichen eingeben. Es wird empfohlen Groß- und Kleinschrebiung zu nutzen, sowie mindestens eine Zahl und ein Sonderzeichen."
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </Field>
                <div className={`__field`}>
                  <Field name="retypePassword">
                    {({ input, meta }) => (
                      <BFInput
                        type="password"
                        label={i18n.t(
                          "UserProfile.Security.ChangePassword.RetypeNewPassword",
                          "Neues Passwort wiederholen"
                        )}
                        validation={
                          meta.error && meta.touched
                            ? { level: "error", message: meta.error }
                            : undefined
                        }
                        {...input}
                      />
                    )}
                  </Field>
                </div>

                <div className={`action-row`}>
                  <BFButton type="submit" appearance="primary">
                    {i18n.t(
                      "UserProfile.Security.ChangePassword.SavePassword",
                      "Passwort speichern"
                    )}
                  </BFButton>
                </div>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default UserSecurity;

import _ from "lodash";
import StorageUtils from "../../../utils/StorageUtils";
import {
  CUSTOMIZATION_DRAFT_INIT,
  CUSTOMIZATION_DRAFT_REMOVE,
  CUSTOMIZATION_DRAFT_SET,
  CUSTOMIZATION_FAV_FILTER_ADD,
  CUSTOMIZATION_FAV_FILTER_REMOVE,
  CUSTOMIZATION_FAV_FILTER_UPDATE,
  CUSTOMIZATION_INFINITE_TABLE_ADD_PERSIST,
  CUSTOMIZATION_INFINITE_TABLE_REVERT,
  CUSTOMIZATION_INFINITE_TABLE_SET_COLUMNS,
  CUSTOMIZATION_SET_FLEX,
  CustomizationAction,
} from "../../actions/customization/customization-actions-types";
import { AppState } from "../../store";
import { CustomizationReducer } from "./CustomizationInterface";

// TODO : load customizations from local storage

export const LOCALSTORAGE_KEY_CUSTOMIZATIONS = "_CUSTOMIZATIONS";
export const LOCALSTORAGE_KEY_FAV_FILTERS = "_FAV_FILTERS";
export const LOCALSTORAGE_KEY_FLEX_VALUES = "_FLEX_VALUES";

const loadStorage = (): Partial<CustomizationReducer> => {
  const customizations = StorageUtils.LocalStorage.get(
    LOCALSTORAGE_KEY_CUSTOMIZATIONS
  ) as string;
  const filters = StorageUtils.LocalStorage.get(
    LOCALSTORAGE_KEY_FAV_FILTERS
  ) as string;
  const flexValues = StorageUtils.LocalStorage.get(
    LOCALSTORAGE_KEY_FLEX_VALUES
  ) as string;

  let customizationPart = {};
  if (customizations) {
    const customizationObj = JSON.parse(customizations);
    customizationPart = {
      infiniteTable: customizationObj,
      persistedInfiniteTables: Object.keys(customizationObj),
    };
  }

  let filtersPart = {};
  if (filters) {
    filtersPart = { favoriteFilters: JSON.parse(filters) };
  }
  let flexPart = {};
  if (flexValues) {
    flexPart = { flex: JSON.parse(flexValues) };
  }
  return {
    ...customizationPart,
    ...filtersPart,
    ...flexPart,
  };
};

export const initialState: CustomizationReducer = {
  flex: {},
  infiniteTable: {},
  persistedInfiniteTables: [],
  favoriteFilters: {},
  drafts: [],
  ...loadStorage(),
};

export default function (
  state = initialState,
  action: CustomizationAction,
  root: AppState
): CustomizationReducer {
  let stateNew: CustomizationReducer;

  switch (action.type) {
    case CUSTOMIZATION_SET_FLEX: {
      const { key, value } = action;
      return {
        ...state,
        flex: {
          ...state.flex,
          [key]: value,
        },
      };
    }
    case CUSTOMIZATION_DRAFT_INIT: {
      const { drafts } = action;
      return {
        ...state,
        drafts,
      };
    }
    case CUSTOMIZATION_DRAFT_SET: {
      const { id, draft } = action;
      const oldState = state.drafts;
      const newState = oldState.filter((e) => e.id !== id);
      newState.push({ id, draft, date: new Date().toISOString() });
      return {
        ...state,
        drafts: newState,
      };
    }
    case CUSTOMIZATION_DRAFT_REMOVE: {
      const { id } = action;
      const oldState = state.drafts;
      const newState = oldState.filter((e) => e.id !== id);
      return {
        ...state,
        drafts: newState,
      };
    }

    case CUSTOMIZATION_FAV_FILTER_UPDATE: {
      const { tableIdentifier, filter } = action;

      return {
        ...state,
        favoriteFilters: {
          ...state.favoriteFilters,
          [tableIdentifier]: [
            ...(state.favoriteFilters[tableIdentifier] || []).map((entry) => {
              if (entry.id === filter.id) {
                return filter;
              } else {
                // all other defaults have to be removed if necessary
                return filter.isDefault
                  ? { ...entry, isDefault: false }
                  : entry;
              }
            }),
          ],
        },
      };
    }
    case CUSTOMIZATION_FAV_FILTER_ADD: {
      const { tableIdentifier, filter } = action;

      return {
        ...state,
        favoriteFilters: {
          ...state.favoriteFilters,
          [tableIdentifier]: [
            // all other defaults have to be removed if necessary
            ...(state.favoriteFilters[tableIdentifier] || []).map((entry) =>
              filter.isDefault ? { ...entry, isDefault: false } : entry
            ),
            filter,
          ],
        },
      };
    }
    case CUSTOMIZATION_FAV_FILTER_REMOVE: {
      const { tableIdentifier, id } = action;
      return {
        ...state,
        favoriteFilters: {
          [tableIdentifier]: (
            state.favoriteFilters[tableIdentifier] || []
          ).filter((e) => e.id !== id),
        },
      };
    }

    case CUSTOMIZATION_INFINITE_TABLE_ADD_PERSIST: {
      const { tableIdentifier } = action;
      const oldState = state.persistedInfiniteTables;
      if (oldState.indexOf(tableIdentifier) !== -1) {
        return state;
      }
      return {
        ...state,
        persistedInfiniteTables: [...oldState, tableIdentifier],
      };
    }
    case CUSTOMIZATION_INFINITE_TABLE_SET_COLUMNS: {
      const { tableIdentifier, columns, overwrite } = action;
      const oldState = state.infiniteTable[tableIdentifier];
      return {
        ...state,
        infiniteTable: {
          ...state.infiniteTable,
          [tableIdentifier]: overwrite
            ? columns
            : _.merge({}, oldState, columns),
        },
      };
    }
    case CUSTOMIZATION_INFINITE_TABLE_REVERT: {
      const { tableIdentifier } = action;
      return {
        ...state,
        infiniteTable: {
          [tableIdentifier]: {},
        },
      };
    }
    default:
      return state;
  }
}

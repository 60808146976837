import classNames from "classnames";
import moment from "moment";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import DebugDataComponent from "../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import { AssetCell } from "../../../../../../modules/abstract-ui/data/table/TableUtils";
import BFDropzoneTablerow from "../../../../../../modules/abstract-ui/dropzone/BFDropzoneTablerow";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "../../../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import LanguageService from "../../../../../../services/LanguageService";
import ArrayUtils from "../../../../../../utils/ArrayUtils";
import StringUtils from "../../../../../../utils/StringUtils";
import { CBPortfolioTechnicalUnit } from "../../../../cashBudget/views/portfolio/interfaces/CBPortfolioAsset";
import {
  APContractActivity,
  APMaintenanceAppointment,
} from "./APActivityMainteanceInterfaces";
import APActivityMaintenanceAppointmentForm from "./APActivityMaintenanceAppointmentForm";
import APActivityMaintenanceCancelForm from "./APActivityMaintenanceCancelForm";
import APActivityMaintenanceFinishForm from "./APActivityMaintenanceFinishForm";
import "./APActivityMaintenanceNextAppointmentsList.scss";
import APActivityMaintenanceSetAppointmentForm from "./APActivityMaintenanceSetAppointmentForm";

interface APActivityMaintenanceNextAppointmentsListProps {
  appointments: APMaintenanceAppointment[];
  activity: APContractActivity;
  onAddAppointment: () => void;
}
const APActivityMaintenanceNextAppointmentsList = (
  props: APActivityMaintenanceNextAppointmentsListProps
) => {
  return (
    <div
      className={classNames(`ap-activity-maintenance-next-appointments-list`)}
    >
      <table className={`table-of-appointments`}>
        <tr>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.nextAppointments.title",
              "Fälligkeitsdatum"
            )}
          </th>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.nextAppointments.affectedTechnicalUnits",
              "Technische Einheit"
            )}
          </th>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.nextAppointments.note",
              "Beschreibung"
            )}
          </th>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.nextAppointments.appointment",
              "Termin"
            )}
          </th>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.nextAppointments.interval",
              "Intervall der Fálligkeit"
            )}
          </th>
          <th>
            {i18n.t(
              "apTemplate:Activity.subtitles.maintenance.nextAppointments.actions",
              "Aktion"
            )}
          </th>
        </tr>
        {ArrayUtils.sortData(props.appointments, {
          key: "dueDate",
          dir: "asc",
        }).map((appointment) => {
          const schedule = props.activity.data.schedulingRules.find(
            (e) => e.id === appointment.scheduleRuleId
          );
          return (
            <BFDropzoneTablerow
              onDrop={(accepted, declined) => {
                if (accepted.length === 1) {
                  ModalManager.show({
                    noPadding: true,
                    size: "md",
                    content: (state, setState, close) => (
                      <APActivityMaintenanceFinishForm
                        activity={props.activity}
                        appointment={appointment}
                        file={accepted[0]}
                        onClose={close}
                      />
                    ),
                  });
                }
              }}
              maxFiles={1}
              render={(open) => (
                <>
                  <td
                    className={classNames("due-date", {
                      soon: moment(moment().add(7, "day")).isAfter(
                        moment(appointment.dueDate)
                      ),
                      overdue: moment().isAfter(moment(appointment.dueDate)),
                    })}
                  >
                    <DebugDataComponent data={appointment} />
                    {StringUtils.formatDate(appointment.dueDate)}
                  </td>
                  <td>
                    {appointment.affectedTechnicalUnits.map(
                      (technicalunitId) => (
                        <AssetCell
                          assetType={AssetTypes.Portfolio.TechnicalUnit}
                          id={technicalunitId}
                          render={(asset: CBPortfolioTechnicalUnit) => (
                            <>
                              <span className={`technical-unit-id`}>
                                {asset.data.id}
                              </span>
                              <span className={`technical-unit-name`}>
                                {LanguageService.translateLabel(
                                  asset.data.displayName
                                )}
                              </span>
                            </>
                          )}
                        />
                      )
                    )}
                  </td>
                  <td>{appointment.note || "-"}</td>
                  <td className={`appointment-date`}>
                    {schedule.automaticAppointment ? (
                      <div className={`automatic-appointment`}>
                        {i18n.t(
                          "apTemplate:Activity.subtitles.maintenance.nextAppointments.automaticAppointemtn",
                          "Wird vom Vertragspartner verwaltet"
                        )}
                      </div>
                    ) : (
                      <div>
                        <BFButton
                          appearance="link"
                          noPadding
                          onClick={() => {
                            ModalManager.show({
                              size: "xs",
                              noPadding: true,
                              content: (state, setState, close) => (
                                <APActivityMaintenanceSetAppointmentForm
                                  appointment={appointment}
                                  activity={props.activity}
                                  onClose={close}
                                />
                              ),
                            });
                          }}
                        >
                          {appointment.appointmentDate
                            ? StringUtils.formatDate(
                                appointment.appointmentDate
                              )
                            : i18n.t(
                                "apTemplate:Activity.subtitles.maintenance.nextAppointments.setAppointment",
                                "Termin setzen"
                              )}
                        </BFButton>
                      </div>
                    )}
                  </td>
                  <td>
                    {schedule.type === "regular" ? (
                      i18n.t(
                        "apTemplate:Activity.subtitles.maintenance.nextAppointments.intervalMonths",
                        "{{count}} Monate",
                        {
                          count: schedule.typeData.numberOfMonths,
                        }
                      )
                    ) : (
                      <div>
                        {i18n.t(
                          "apTemplate:Activity.subtitles.maintenance.nextAppointments.nextAppointments",
                          "{{count}} Termine",
                          {
                            count: schedule.typeData.length,
                          }
                        )}
                      </div>
                    )}
                  </td>
                  <td className={`actions`}>
                    <div className={`action-container`}>
                      <BFDropdown
                        placement={"bottomEnd"}
                        label={i18n.t(
                          "apTemplate:Activity.subtitles.maintenance.nextAppointments.edit",
                          "Bearbeiten"
                        )}
                        toggleAs={(dropdownProps) => (
                          <BFButton
                            {...dropdownProps}
                            appearance="link"
                            noPadding
                          >
                            {i18n.t(
                              "apTemplate:Activity.subtitles.maintenance.nextAppointments.edit",
                              "Bearbeiten"
                            )}
                          </BFButton>
                        )}
                        items={[
                          {
                            type: "button",
                            text: i18n.t(
                              "apTemplate:Activity.subtitles.maintenance.nextAppointments.editDueData",
                              "Fälligkeitsdaten bearbeiten"
                            ),
                            onSelect: () => {
                              ModalManager.show({
                                size: "sm",
                                noPadding: true,
                                content: (state, setState, close) => (
                                  <APActivityMaintenanceAppointmentForm
                                    appointmentId={appointment.id}
                                    activity={props.activity}
                                    onClose={close}
                                  />
                                ),
                              });
                            },
                          },
                          {
                            type: "button",
                            text: i18n.t(
                              "apTemplate:Activity.subtitles.maintenance.nextAppointments.removeDueDate",
                              "Fälligkeit entfernen"
                            ),
                            onSelect: () => {
                              ModalManager.show({
                                size: "sm",
                                noPadding: true,
                                content: (state, setState, close) => (
                                  <APActivityMaintenanceCancelForm
                                    appointment={appointment}
                                    activity={props.activity}
                                    onClose={close}
                                  />
                                ),
                              });
                            },
                          },
                        ]}
                      />
                      <BFButton appearance="link" noPadding onClick={open}>
                        {i18n.t(
                          "apTemplate:Activity.subtitles.maintenance.nextAppointments.finishAppointment",
                          "Protokoll hochladen"
                        )}
                      </BFButton>
                    </div>
                  </td>
                </>
              )}
            />
          );
        })}
      </table>

      <div className={`add-action`}>
        <BFButton appearance="link" size="xs" onClick={props.onAddAppointment}>
          {i18n.t(
            "apTemplate:Activity.subtitles.maintenance.appointments.add",
            "Fälligkeit hinzufügen"
          )}
        </BFButton>
      </div>
    </div>
  );
};

export default APActivityMaintenanceNextAppointmentsList;

import "./PDFTextarea.scss";
import {
  TEXT_ALIGNMENTS,
  TEXT_SIZES,
} from "@/components/PDFConstructor/common/InputConstants";
import { TextConfig } from "@/components/PDFConstructor/services/types/pdfConfigBlockTypes";
import i18n from "@/i18n";
import AutosizeTextarea from "@/modules/abstract-ui/forms/autosize-textarea/AutosizeTextarea";
import { SelectPicker } from "rsuite";
import { PDFColorPicker } from "./PDFToolbar/PDFColorPicker";

import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import CSSUtils from "@/utils/CSSUtils";
import classNames from "classnames";
import { PDFCheckbox } from "./PDFToolbar/PDFCheckbox";

type ContentConfig = Omit<TextConfig, "id" | "type" | "isEmpty">;

type PDFTextareaProps = {
  form: ContentConfig;
  setFormValue: <T extends keyof ContentConfig>(
    key: T,
    value: ContentConfig[T]
  ) => void;
  className?: string;
};

const PDFTextarea: React.FC<PDFTextareaProps> = ({
  form,
  setFormValue,
  className,
}) => {
  return (
    <div
      className={classNames("pdf-constructor--text-input--textarea", className)}
    >
      <div className="pdf-constructor--text-input--textarea--toolbar">
        <PDFColorPicker
          value={form.color}
          onChange={(value) => {
            setFormValue("color", value.toString());
          }}
        >
          {(color) => (
            <button
              className="pdf-constructor--color-picker--trigger"
              type="button"
              style={{
                backgroundColor: color,
                color: CSSUtils.invertColor(color),
              }}
            >
              <BfIcon type="light" data="text-options" size="xs" />
            </button>
          )}
        </PDFColorPicker>

        <PDFColorPicker
          value={form.background}
          onChange={(value) => {
            setFormValue("background", value.toString());
          }}
        >
          {(color) => (
            <button
              className="pdf-constructor--color-picker--trigger"
              type="button"
              style={{
                backgroundColor: color,
                color: CSSUtils.invertColor(color),
              }}
            >
              <BfIcon type="light" data="content-brush-pen" size="xs" />
            </button>
          )}
        </PDFColorPicker>

        <SelectPicker
          data={TEXT_ALIGNMENTS.map((option) => ({
            ...option,
            label: option.label(),
          }))}
          cleanable={false}
          value={form.alignment}
          onChange={(value) =>
            setFormValue("alignment", value as TextConfig["alignment"])
          }
          searchable={false}
          className="col-span-2"
        />

        <SelectPicker
          data={TEXT_SIZES.map((option) => ({
            ...option,
            label: option.label(),
          }))}
          cleanable={false}
          value={form.size}
          onChange={(value) => setFormValue("size", value)}
          searchable={false}
        />

        <PDFCheckbox
          checked={form.bold}
          onCheckedChange={(value) => setFormValue("bold", value)}
          icon={<BfIcon type="light" data="text-bold" size="xxs" />}
        />

        <PDFCheckbox
          checked={form.italics}
          onCheckedChange={(value) => setFormValue("italics", value)}
          icon={<BfIcon type="light" data="text-italic" size="xxs" />}
        />

        <PDFCheckbox
          checked={form.underline}
          onCheckedChange={(value) => setFormValue("underline", value)}
          icon={<BfIcon type="light" data="text-underline" size="xxs" />}
        />
      </div>

      <AutosizeTextarea
        label={i18n.t("Component.PDFConstructor.Block.Content", "Inhalt")}
        className="col-span-2"
        value={form.content}
        placeholder={i18n.t("Component.PDFConstructor.Block.Content", "Inhalt")}
        onChange={(value) => {
          setFormValue("content", value.toString());
        }}
        maxHeight={300}
      />
    </div>
  );
};

export { PDFTextarea };

import classNames from "classnames";
import { useEffect, useState } from "react";
import Collapse from "rsuite/esm/Animation/Collapse";
import BFButton from "../../general/Button/BFButton";
import BfIcon from "../../icon/BfIcon";
import "./BFFormSection.scss";

interface BFFormSectionProps {
  children: React.ReactNode;
  title: React.ReactNode;
  initialOpen?: boolean;
  open?: boolean;
  onClosed?: () => void;
  marginBottom?: number;
  marginTop?: number;
  className?: string;
  disableCollapse?: boolean;
  color?: string;
  right?: React.ReactNode;
}
const BFFormSection = (props: BFFormSectionProps) => {
  const [open, setOpen] = useState(
    props.initialOpen || (props.disableCollapse ? true : false)
  );
  useEffect(() => {
    if (props.open !== undefined) {
      setOpen(props.open);
    }
  }, [props.open]);

  useEffect(() => {
    if (!open) {
      props.onClosed?.();
    }
  }, [open]);

  return (
    <div
      className={classNames(`bf-form-section`, props.className, { open: open })}
      style={{
        marginBottom: props.marginBottom,
        marginTop: props.marginTop,
        background: props.color,
      }}
    >
      <div className="section-title">
        <BFButton
          appearance="clear-on-white"
          onClick={(ev) => {
            if (ev.target.tagName === "INPUT") {
              ev.preventDefault();
              return;
            }
            setOpen(!open);
          }}
        >
          <div className="state-indicator">
            <BfIcon type="light" data="arrow-down-1" size="xs" />
          </div>
          <div className="title-text">{props.title}</div>
        </BFButton>
        {props.right && <div className={`right`}>{props.right}</div>}
      </div>
      <Collapse in={open}>
        <div className={`collapse-container`}>
          <div className="section-content">{props.children}</div>
        </div>
      </Collapse>
      <div className={`outer-border`} />
    </div>
  );
};

export default BFFormSection;

import i18n from "../../../../../i18n";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { MatchQuery } from "../../../../../services/DataService";
import LanguageService from "../../../../../services/LanguageService";
import { hasValue } from "../../../../../utils/Helpers";
import DSService from "../../../../document-store/DSService";
import { BfIconProps } from "../../../icon/BfIcon";
import { FilterOptionWithMatchQueryConverter } from "../BFTableFilterModel";
import OverlayMultipleSelectionWithNegation, {
  ListSelectionValueWithNegation,
} from "../overlays/OverlayMultipleSelectionWithNegation";

export const DocumentsFilterOption: (opts: {
  filterTypes: string[];
  assetType: string;
  assetFieldPath: string;
  onlyFieldsWithRequirement?: boolean;

  label: string;
  icon?: BfIconProps;
  allowNegation?: boolean;
  matchQueryConverter?: (
    value: ListSelectionValueWithNegation
  ) => MatchQuery | null;
}) => FilterOptionWithMatchQueryConverter = (opts) => {
  const { assetFieldPath, label, icon, filterTypes, assetType } = opts;

  const documentsByType = filterTypes.map((filterType) => ({
    type: filterType,
    directories: DSService.getFlattenDirectories(
      DSService.getDirectoryConfigurationForAsset(
        filterType,
        AssetTypes.Rental.RentalAgreement,
        "data.attachments"
      )
    ),
  }));
  const documents = documentsByType
    .map((entry) =>
      entry.directories.map((directory) => ({
        type: entry.type,
        id: directory.id,
        name: directory.name,
        requirements: directory.directoryRequirements,
      }))
    )
    .flat(1)
    .filter((e) =>
      opts.onlyFieldsWithRequirement ? hasValue(e.requirements) : true
    );

  if (!documents) {
    return null;
  }

  const matchQueryConverter =
    opts.matchQueryConverter ||
    ((value: ListSelectionValueWithNegation) =>
      value && value.value.length > 0
        ? {
            type: "op",
            op: value.negate ? "nin" : "in",
            name: `${assetFieldPath}.directoryId`,
            value: value.value,
          }
        : null);

  const data = documents.map((entry) => ({
    label: `${LanguageService.translateLabel(entry.name)}${
      filterTypes.length > 1 ? ` (${entry.type})` : ""
    }`,
    value: entry.id,
  }));
  return {
    matchQueryConverter: matchQueryConverter,
    key: assetFieldPath,
    label: label,
    icon: {
      type: icon?.type || "light",
      data: icon?.data || "archive-folder",
    },
    renderOverlay: (
      value: ListSelectionValueWithNegation,
      onChange: (value: ListSelectionValueWithNegation) => void
    ) => (
      <OverlayMultipleSelectionWithNegation
        title={label}
        entries={data}
        value={value}
        onChange={onChange}
        allowNegation={opts.allowNegation}
      />
    ),
    defaultValue: {
      negate: false,
      value: [],
    },
    renderValueData: (value: ListSelectionValueWithNegation) => ({
      typeLabel: label,
      valueLabel: value
        ? value.value.length === 1
          ? `${value.negate ? `${i18n.t("Global.Label.Not", "Nicht")}: ` : ""}${
              data.find((e) => e.value)?.label
            }`
          : `${value.negate ? `${i18n.t("Global.Label.Not", "Nicht")}: ` : ""}${
              value.value.length
            }/${data.length}`
        : null,
    }),
    getOptions: (currentValue: ListSelectionValueWithNegation, searchTerm) => {
      return data
        .filter((e) => (currentValue?.value || []).indexOf(e.value) === -1)
        .map((entry, index) => ({
          filterKey: assetFieldPath,
          typeLabel: label,
          label: entry.label,
          labelAsString: entry.label,
          value: entry.value,
          valueKey: entry.value,
          onSelect: (
            optionValue,
            currentValue: ListSelectionValueWithNegation,
            onChange
          ) => {
            onChange(
              currentValue
                ? {
                    ...currentValue,
                    value: [...currentValue.value, optionValue],
                  }
                : { megate: false, value: [optionValue] }
            );
          },
        }));
    },
  };
};

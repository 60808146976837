import { SET_UI_CONFIG } from "../redux/actions/ui-config/ui-config-actions-types";
import { DefaultUIConfigs } from "../redux/reducers/ui-config/UiConfig";
import { SESSION_STORAGE_KEYS } from "./Constants";
import StorageUtils from "./StorageUtils";

export const Properties = {
  dev: {
    context: "iber.io",
  },
  fallbackLanguage: "de",
  useHotReload: false,
  translateDebug: false,
  logLevels: {
    error:
      process.env.REACT_APP_LOG_ERROR !== undefined
        ? process.env.REACT_APP_LOG_ERROR === "true"
        : false,
    info:
      process.env.REACT_APP_LOG_INFO !== undefined
        ? process.env.REACT_APP_LOG_INFO === "true"
        : false,
    debug:
      process.env.REACT_APP_LOG_DEBUG !== undefined
        ? process.env.REACT_APP_LOG_DEBUG === "true"
        : false,
    warning:
      process.env.REACT_APP_LOG_WARNING !== undefined
        ? process.env.REACT_APP_LOG_WARNING === "true"
        : false,
    state:
      process.env.REACT_APP_LOG_STATES !== undefined
        ? process.env.REACT_APP_LOG_STATES === "true"
        : false,
  },

  // serverUrl: "https://api.iberio.de", //"localhost",
  debugMode: false, //process.env.REACT_APP_DEBUG_MODE !== undefined ? process.env.REACT_APP_DEBUG_MODE === "true" : false,
  globalConfigVersion: 1,
};

export const toggleTranslationDebug = () => {
  Properties.translateDebug = !Properties.translateDebug;
};
export const isDevelopment = () => {
  return window.location.hostname === "localhost";
};
export const isTestserver = () => {
  return window.location.hostname === "dev.iber.io";
};

export const devProp = (propNormal: any, propDev: any) => {
  if (isDevelopment()) {
    return propDev;
  }

  return propNormal;
};

(window as any).setLogLevel = (logLevel: string, value: boolean) => {
  Properties.logLevels[logLevel] = value;
};

(window as any).toggleTranslationDebug = toggleTranslationDebug;

(window as any).toggleDebugMode = () => {
  Properties.debugMode = !Properties.debugMode;
  StorageUtils.SessionStorage.save(
    SESSION_STORAGE_KEYS.SESSION_STORAGE_DEBUG,
    !Properties.debugMode
  );
  (window as any).store.dispatch({
    type: SET_UI_CONFIG,
    key: DefaultUIConfigs.DEBUG,
    value: !(window as any).store.getState().uiConfig.general[
      DefaultUIConfigs.DEBUG
    ],
  });
};

import moment from "moment";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import MQ from "../../../../../../utils/MatchQueryUtils";
import { AssetCashBudgetEntry } from "../../../model/CashBudgetEntry";
import CBIdlyConnect from "./CBIdlyConnect";
import CBIdlyConnectLoanSugggestion from "./CBIdlyConnectLoanSugggestion";
import CBIdlyConnectService, { LoanSuggestion } from "./CBIdlyConnectService";

interface CBIdlyConnectLoanProps {
  booking: AssetCashBudgetEntry;
  initialOpen?: boolean;
}
const CBIdlyConnectLoan = (props: CBIdlyConnectLoanProps) => {
  const releventMonths = [
    moment(props.booking.data.date).subtract(1, "month"),
    moment(props.booking.data.date),
    moment(props.booking.data.date).add(1, "month"),
  ];

  return (
    <CBIdlyConnect
      assetType={AssetTypes.Portfolio.Loan}
      mapSuggestion={CBIdlyConnectService.mapSuggestionLoan}
      matchQuery={MQ.and(MQ.eq("data.entity", props.booking.data.entity))}
      className="cb-idly-connect-loan"
      title={i18n.t("cb:CBIdlyConnect.loan.title", "Darlehen verknüpfen")}
      booking={props.booking}
      initialOpen={props.initialOpen}
      connectActionLabel={i18n.t(
        "cb:CBIdlyConnect.loan.connect",
        "Darlehen verknüpfen"
      )}
      isConnected={(suggestion: LoanSuggestion) =>
        props.booking.data.linkedAsset?.some(
          (e) => e.assetId === suggestion.asset._id
        )
      }
      disconnectActionLabel={i18n.t(
        "cb:CBIdlyConnect.loan.disconnect",
        "Verknüpfung aufheben"
      )}
      disconnectActionPromise={async (suggestion: LoanSuggestion) =>
        await CBIdlyConnectService.unlink(
          props.booking._id,
          AssetTypes.Portfolio.Loan,
          suggestion.asset._id
        )
      }
      connectActionPromise={async (suggestion: LoanSuggestion) =>
        await CBIdlyConnectService.link(
          props.booking._id,
          AssetTypes.Portfolio.Loan,
          suggestion.asset._id
        )
      }
      manualActionLabel={i18n.t(
        "cb:CBIdlyConnect.loan.manual",
        "Darlehen suchen"
      )}
      allowManualSearch
      // manualActionPromise={async () => {
      //   // open search booking action
      //   // DataBusDefaults.route({
      //   //   route: `__link-invoice-to-booking/${props.booking._id}`,
      //   //   append: true,
      //   // });
      // }}
      renderSuggestion={(suggestion: LoanSuggestion) => (
        <CBIdlyConnectLoanSugggestion
          suggestion={suggestion}
          booking={props.booking}
        />
      )}
      renderSuggestionHint={CBIdlyConnectService.getSuggestionHintLoan}
      ruleActionData={(suggestion: LoanSuggestion) => ({
        assetDescription: `${suggestion.asset.data.loanID} ${suggestion.asset.data.text}`,
        assetName: i18n.t("cb:CBIdlyConnect.loan.AssetName", "Darlehen"),
        assetType: AssetTypes.Portfolio.Loan,
      })}
      suggestionPromise={async (booking: AssetCashBudgetEntry) =>
        await CBIdlyConnectService.fetchSuggestionsForLoan(props.booking)
      }
    />
  );
};

export default CBIdlyConnectLoan;

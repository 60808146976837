import Color from "color";
import _ from "lodash";
import moment, { Moment } from "moment";
import React from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ButtonGroup } from "rsuite";
import i18n from "../../../../../i18n";
import BFDateDeprecated from "../../../../../modules/abstract-ui/forms/date/BFDate";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "../../../../../modules/abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../../../../../modules/abstract-ui/icon/BfIcon";
import CashBudgetTable, {
  CashBudgetColumnConfig,
  CashBudgetRowConfig,
  ValueData,
} from "../../../../../modules/cashbudget-table/CashBudgetTable";
import {
  setFlexCacheData,
  setFlexCacheDataMultiple,
} from "../../../../../redux/actions/application/application-actions";
import { DefaultUIConfigs } from "../../../../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../../../../redux/store";
import DataBus from "../../../../../services/DataBus";
import LanguageService from "../../../../../services/LanguageService";
import {
  AbstractComponent,
  AbstractProps,
  AbstractStates,
} from "../../../../../utils/abstracts/AbstractComponent";
import { DataBusSubKeys } from "../../../../../utils/Constants";
import { isDefined } from "../../../../../utils/Helpers";
import StringUtils from "../../../../../utils/StringUtils";
import CashBudgetUtils from "../../CashBudgetUtils";
import { CASHBUDGET_CATEGORY_DETAIL_TABLE } from "../../components/CashBudgetCategoryDetailEntry";
import CashBudgetCategoryDrawer from "../../components/CashBudgetCategoryDrawer";
import CashBudgetFilterHeader from "../../components/CashBudgetFilterHeader";
import {
  CashBudgetBalanceQuery,
  CashBudgetBalanceQueryCategory,
} from "../../model/CashBudgetBalanceQuery";
import { CashBudgetCategory } from "../../model/CashBudgetCofiguration";
import CashBudgetService, {
  CASH_BUDGET_DATA_PREFIX,
  CASH_BUDGET_PLAN_PREFIX,
  CashBudgetResultData,
} from "../../services/CashBudgetService";
import CashBudgetCellOverlay from "./components/CashBudgetCellOverlay";
import "./TatarCashBudgetTableComponent.scss";
const AVAILABLE_HINTS = [
  // {
  //   type: TAG_OPERATIVE_CASHFLOW,
  //   description: "Teil des operativen Cashflows",
  //   color: "#0c7ff7",
  // },
];
const TAG_OPERATIVE_CASHFLOW = "operativCashflow";
const FACTOR_OPACITY_UNIT_SUM = "20";
const FACTOR_OPACITY_UNIT_CATEGORY = "10";

type CashBudgetLoanData = {
  type: string;
  interest: number;
  repayment: number;
};
export interface CashBudgetSpan {
  balanceStart: number;
  balanceEnd: number;
  dateFrom: moment.Moment;
  dateTo: moment.Moment;
  categories: {
    [catIdentifier: string]: CashBudgetBalanceQueryCategory;
  };
  loans?: CashBudgetLoanData[];
}

interface AddDataprops {
  newColumnData: CashBudgetColumnConfig;
  cashBudgetSpan: CashBudgetSpan;
  comparisonCashBudgetSpan: CashBudgetSpan;
  text: string;
  opacity?: number;
  clickable: boolean;
  clickData: null | ((span: CashBudgetSpan, isComparison: boolean) => any);
  bold: boolean;
  selectorFc: (
    span: CashBudgetSpan,
    columnType: "is" | "should" | "budget"
  ) => number | null;
  cellBackground?: string;
  progress?: number;
  renderOnHover?: () => React.ReactNode;
  tags?: string[];
  subValues?: {
    base: AddDataprops;
    overrides?: Partial<AddDataprops>[];
    category?: CashBudgetCategory;
  }[];
  subValuesIdent?: string;
  columnType?: "is" | "should" | "budget";
  renderLeft?: React.ReactNode;
}

type VIEW_TYPE = "BOOKED" | "BUDGET";
const labelOfViewType = (viewType: VIEW_TYPE) => {
  switch (viewType) {
    case "BOOKED":
      return i18n.t("cb:BudgetTable.ViewType.BOOKED", "Gebucht");
    case "BUDGET":
      return i18n.t("cb:BudgetTable.ViewType.BUDGET", "Budget");
  }
};

type COMPARISON_TYPE = "Ist" | "Soll" | "Differenz";
const COMPARISON_FILTERS: COMPARISON_TYPE[] = ["Soll", "Ist", "Differenz"];
type Props = {
  unitType?: string;
  collapseInitial?: boolean;
  restrictUnits?: string[];
  restrictCategories?: string[];
  ignoreCalculations?: boolean;

  appTypes: string[];
  comparison: boolean;
  debugMode: boolean;
  setFlexCacheDataMultiple: (
    flexData: { category: string; identifier: string; data: any }[]
  ) => void;
  setFlexCacheData: (category: string, identifier: string, data: any) => void;
  viewportHeight: number;
  viewportWidth: number;
  displayType: "DAILY" | "MONTHLY";
  requestType: "starmoney" | "datev";
  cachedData: { [hash: string]: CashBudgetResultData };
  cachedComparisonData: { [hash: string]: CashBudgetResultData };
  selectedEntities: string[];
  selectedFilter: string;
  paintCategories?: boolean;
  hideHeader?: boolean;
} & AbstractProps &
  WithTranslation;

type States = {
  groupIds: string[];
  viewType: VIEW_TYPE;
  selectedHash: string;
  rowHeight: null | number;
  selectedYear: number;
  selectedMonth: number;
  entries: CashBudgetSpan[];
  entriesComparison: CashBudgetSpan[];
  scrollState: "start" | "stop";
  categoryDrawerOpen: boolean;
  mobileComparisonType: COMPARISON_TYPE;
  activeSpan?: {
    ignoreChildren?: boolean;
    span: CashBudgetSpan;
    spanType: "normal" | "comparisonShould" | "comparisonCurrent";
    category: CashBudgetCategory;
    type: "POSITIVE" | "NEGATIVE";
  };
  // rowConfiguration: CashBudgetRowConfig[];
  data: CashBudgetColumnConfig[];
  rowConfig: CashBudgetRowConfig[];
  nextFocusDate: Moment | null;
  selectedData: {
    max: number;
    min: number;
    sum: number;
    avg: number;
    count: number;
  } | null;
  operativeCashbudgetFocused: boolean;
} & AbstractStates;

class TatarCashBudgetTableComponent extends AbstractComponent<Props, States> {
  static defaultProps = {
    displayType: "DAILY",
    requestType: "datev",
  };
  readonly state: States = {
    groupIds: [],
    viewType: "BOOKED",
    categoryDrawerOpen: false,
    selectedHash: null,
    mobileComparisonType: COMPARISON_FILTERS[0],
    selectedYear: moment().year(),
    selectedMonth: moment().month(),
    rowHeight: null,
    entries: [],
    entriesComparison: [],
    scrollState: "stop",
    activeSpan: null,
    data: [],
    rowConfig: [],
    nextFocusDate: moment(),
    selectedData: null,
    operativeCashbudgetFocused: false,
  };

  _mounted = true;
  cardRef = React.createRef<any>();
  jumptoDate = moment();

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    // super.componentDidMount();
    this.evaluateRowData();
    this.updateHeight();
    this.updateEntries();
  }
  updateHeight() {
    this.setState({
      rowHeight: Math.min(
        32,
        Math.max(
          26,
          (this.cardRef.current.offsetHeight -
            /**scrollbar height  */ 16 -
            /**subheaders height  */ this.state.rowConfig.reduce(
              (prev, current) => prev + (current.isSubHead ? 10 : 0),
              0
            )) /
            (this.state.rowConfig.length + 3)
        )
      ),
    });
  }
  getTypes() {
    if (this.props.unitType) {
      return [this.props.unitType];
    }
    return this.props.appTypes.filter((e) =>
      isDefined(this.props.restrictUnits)
        ? this.props.restrictUnits.includes(e)
        : true
    );
  }
  updateEntries() {
    if (this.props.selectedEntities) {
      const from = moment(this.state.selectedYear, "YYYY")
        .startOf("year")
        .utc(true);
      if (this.props.displayType === "DAILY") {
        from.month(this.state.selectedMonth).utc(true);
      }
      const to = from
        .clone()
        .endOf(this.props.displayType === "DAILY" ? "month" : "year")
        .utc(true);

      const filter = CashBudgetService.convertFilterData(
        this.props.selectedFilter
      );
      const types = this.getTypes();
      const hashId = CashBudgetService.requestCompleteData(
        types,
        this.props.displayType,
        this.props.selectedEntities,
        filter.accounts,
        filter.objects,
        // ["10005"],
        from,
        to
      );
      if (this.props.comparison) {
        CashBudgetService.requestCompleteData(
          types,
          this.props.displayType,
          this.props.selectedEntities,
          filter.accounts,
          filter.objects,
          // ["10005"],
          from,
          to,
          true
        );
      }

      this.setState({
        selectedHash: hashId,
      });
    }
  }
  convertDataToSpan(comparison: boolean) {
    const data = comparison
      ? this.props.cachedComparisonData[this.state.selectedHash].data
      : this.props.cachedData[this.state.selectedHash].data;
    const cashBudgetSpans: CashBudgetSpan[] = [];
    const startOfYear = moment(this.state.selectedYear, "YYYY")
      .startOf("year")
      .utc(true);
    if (this.props.displayType === "DAILY") {
      startOfYear.month(this.state.selectedMonth);
    }
    let currentMoment = startOfYear.clone();

    const unitToIncrease = this.props.displayType === "DAILY" ? "day" : "month";
    let lastBalance = data ? data[0].balance : 0;
    do {
      const dateFrom = currentMoment.startOf(unitToIncrease).utc(true);

      if (unitToIncrease !== "day" || dateFrom.weekday() < 5) {
        const dataFound = data?.find((entry: CashBudgetBalanceQuery) => {
          switch (this.props.displayType) {
            case "DAILY":
              return (
                entry._id.year === dateFrom.year() &&
                entry._id.day === dateFrom.dayOfYear()
              );
            case "MONTHLY":
              return (
                entry._id.year === dateFrom.year() &&
                entry._id.month - 1 === dateFrom.month()
              );
            default:
              return false;
          }
        }) as CashBudgetBalanceQuery;
        const categories: CashBudgetBalanceQueryCategory[] = dataFound
          ? dataFound.categories
          : [];
        const balanceStart = dataFound ? dataFound.balance : lastBalance;
        const balanceEnd =
          balanceStart +
          categories.reduce((value, entry) => value + entry.displayValue, 0);
        lastBalance = balanceEnd;
        const dateTo = currentMoment.clone().endOf(unitToIncrease).utc(true);

        const spanData: CashBudgetSpan = {
          balanceStart: balanceStart,
          balanceEnd: balanceEnd,
          categories: Object.fromEntries(
            categories.map((e) => [e.category, e])
          ),
          loans: dataFound?.loan || [],
          dateFrom: dateFrom.clone().utc(true),
          dateTo: dateTo.clone().utc(true),
        };

        cashBudgetSpans.push(spanData);
      }
      currentMoment = currentMoment.clone().add(1, unitToIncrease);
    } while (
      currentMoment.isBefore(
        startOfYear
          .clone()
          .endOf(this.props.displayType === "DAILY" ? "month" : "year")
      )
    );

    return cashBudgetSpans;
  }

  getCategoryRows(categories: CashBudgetCategory[]) {
    return categories
      .filter((e) => !e.data.group)
      .map((category) => ({
        title: LanguageService.translateLabel(category.data.displayName),
        hints: AVAILABLE_HINTS.filter(
          (e) => (category.data.tags || []).indexOf(e.type) !== -1
        ),
        // add subcategories if necessary
        ...((category.data.tags || []).indexOf("group") !== -1
          ? {
              subRowIdent: category._id,
              subRows: [
                ...categories
                  .filter((e) => e.data.group === category._id)
                  .map((subCategory) => ({
                    title: LanguageService.translateLabel(
                      subCategory.data.displayName
                    ),
                    hints: AVAILABLE_HINTS.filter(
                      (e) =>
                        (subCategory.data.tags || []).indexOf(e.type) !== -1
                    ),
                  })),
                {
                  title: i18n.t("cb:LQ.NotAssigned", "Nicht zugewiesen"),
                },
              ],
            }
          : {}),
        ...((category.data.tags || []).indexOf("loan") !== -1
          ? {
              subRowIdent: category._id,
              subRows: [
                {
                  title: i18n.t("cb::Label.LoanInterest", "Zinsen"),
                },
                {
                  title: i18n.t("cb::Label.LoanRepayment", "Tilgung"),
                },
              ],
            }
          : {}),
      }));
  }

  evaluateRowData() {
    const categoriesRestrict = this.props.restrictCategories?.map((e) =>
      CashBudgetUtils.findCategory(e)
    );
    const allCategories = CashBudgetUtils.getAllCategories(
      this.getTypes()
    ).filter((cat) =>
      categoriesRestrict
        ? categoriesRestrict.some(
            (restrictCat) =>
              restrictCat._id === cat._id || restrictCat.data.group === cat._id
          )
        : true
    );
    const categoriesPositive = allCategories.filter(
      (cat) => cat.data.kind === "income"
    );
    const categoriesNegative = allCategories?.filter(
      (cat) => cat.data.kind === "expense"
    );
    const rowConfig: CashBudgetRowConfig[] = [];

    if (!this.props.ignoreCalculations) {
      rowConfig.push({
        title: i18n.t("cb:Label.StartBalance"), //"Anfang Kontostand",
        bold: true,
      });
    }
    if (categoriesPositive.length > 0) {
      rowConfig.push({
        title: i18n.t("cb:Label.CashInflow"), //"Einzahlungen/Geldflüsse",
        bold: true,
        isSubHead: true,
      });

      if (this.getTypes().length === 1) {
        this.getCategoryRows(categoriesPositive).forEach((row) =>
          rowConfig.push(row)
        );
      } else {
        this.getTypes().forEach((unit) => {
          rowConfig.push({
            title: CashBudgetUtils.getLabelOfUnit(unit),
            bold: true,
            subRowIdent: `positive-${unit}`,
            subRows: this.getCategoryRows(
              categoriesPositive.filter((e) => e.data.type === unit)
            ),
          });
        });
      }
      rowConfig.push({
        title: i18n.t("cb:Label.IncomeAll"), //"Einnahmen Gesamt",
        bold: true,
        borderTop: true,
      });
    }
    if (!this.props.ignoreCalculations) {
      rowConfig.push({
        title: i18n.t("cb:Label.OperativeIncome"), //"Operative Einnahmen",
        bold: true,
      });
      rowConfig.push({
        title: i18n.t("cb:Label.SumAvailableFunds"), //"Summe verfügbarer Mittel",
        bold: true,
      });
    }
    if (categoriesNegative.length > 0) {
      rowConfig.push({
        title: i18n.t("cb:Label.Payout"), //"Auszahlungen/Zahlungsausgänge",
        bold: true,
        isSubHead: true,
      });

      if (this.getTypes().length === 1) {
        this.getCategoryRows(categoriesNegative).forEach((row) =>
          rowConfig.push(row)
        );
      } else {
        this.getTypes().forEach((unit) => {
          rowConfig.push({
            title: CashBudgetUtils.getLabelOfUnit(unit),
            bold: true,
            subRowIdent: `negative-${unit}`,
            subRows: this.getCategoryRows(
              categoriesNegative.filter((e) => e.data.type === unit)
            ),
          });
        });
      }

      rowConfig.push({
        title: i18n.t("cb:Label.ExpenseAll"), //"Ausgaben Gesamt",
        bold: true,
        borderTop: true,
      });
    }
    if (!this.props.ignoreCalculations) {
      rowConfig.push({
        title: i18n.t("cb:Label.OperativeExpense"), //"Operative Ausgaben",
        bold: true,
      });
      rowConfig.push({
        title: i18n.t("cb:Label.Cashflow"), //"Cashflow",
        bold: true,
      });
      rowConfig.push({
        title: i18n.t("cb:Label.OperativeCashflow"), //"Operativer Cashflow",
        bold: true,
        actions: (
          <div style={{ paddingRight: 5 }}>
            <BFButton
              appearance={"clear-on-white"}
              icon={{
                type: "bf",
                data: this.state.operativeCashbudgetFocused
                  ? "view-off"
                  : "view-2",
              }}
              onClick={() => {
                this.setState(
                  {
                    operativeCashbudgetFocused:
                      !this.state.operativeCashbudgetFocused,
                  },
                  () => {
                    this.evaluateRowData();
                    this.reloadData();
                    // this.updateEntries();
                  }
                );
              }}
            />
          </div>
        ),
      });

      if (categoriesNegative.find((e) => e.data.tags.indexOf("loan") !== -1)) {
        rowConfig.push({
          title: i18n.t(
            "cb:Label.OperativeCashflowWithRepayment",
            "Operativer Cashflow + Tilgung"
          ), //"Operativer Cashflow",
          bold: true,
        });
      }

      rowConfig.push({
        title: i18n.t("cb:Label.LiquidResources"), //"Liquide Mittel (Kontostand)",
        bold: true,
      });
    }
    this.setState({
      rowConfig,
      groupIds: _.uniq(allCategories.map((e) => e.data.group)),
    });
  }
  evaluateData(
    cashBudgetSpans: CashBudgetSpan[],
    cashBudgetComparisonSpans: CashBudgetSpan[]
  ) {
    const data: CashBudgetColumnConfig[] = [];

    /**
     * TODO Create the configuration out of different
     * column-generators which defines their own logic
     * -> the view state should be defined by checkboxes
     */
    cashBudgetSpans.forEach((entry, index) => {
      const hasForecastEntries = moment().utc(true).isBefore(entry.dateTo);
      const isCurrentColumn = moment()
        .utc(true)
        .isSame(
          entry.dateFrom,
          this.props.displayType === "DAILY" ? "day" : "month"
        );

      const newColumnData: CashBudgetColumnConfig = {
        id: `ENTRY-${this.props.displayType}-${entry.dateFrom.format(
          this.props.displayType === "DAILY" ? "YYYYMMDD" : "YYYYMM"
        )}`,
        headerText: entry.dateFrom.format(
          this.props.displayType === "DAILY" ? "D MMM" : "MMMM"
        ),
        type:
          this.state.viewType === "BOOKED"
            ? cashBudgetComparisonSpans != null || isCurrentColumn
              ? "comparison"
              : "default"
            : this.state.viewType === "BUDGET"
            ? isCurrentColumn || !hasForecastEntries
              ? "comparison"
              : "default"
            : "default",

        allBold: false,
        highlight: isCurrentColumn
          ? "current"
          : hasForecastEntries
          ? "future"
          : undefined,
        subHeaders:
          this.state.viewType === "BOOKED"
            ? cashBudgetComparisonSpans != null
              ? [
                  i18n.t("cb:Label.Should"),
                  i18n.t("cb:Label.Is"),
                  i18n.t("cb:Label.Difference"),
                ]
              : isCurrentColumn
              ? [i18n.t("cb:Label.Is"), i18n.t("cb:Label.Should")]
              : undefined
            : this.state.viewType === "BUDGET"
            ? isCurrentColumn
              ? [
                  i18n.t("cb:Label.Is"),
                  i18n.t("cb:Label.Should"),
                  i18n.t("cb:Label.Budget"),
                ]
              : !hasForecastEntries
              ? [i18n.t("cb:Label.Is"), i18n.t("cb:Label.Budget")]
              : undefined
            : undefined,
        topHeaderTextCenter: hasForecastEntries
          ? i18n.t("cb:Label.Prediction")
          : undefined,
        topHeaderTextLeft:
          this.props.displayType === "DAILY"
            ? entry.dateFrom.format("dddd")
            : undefined,
        topHeaderTextRight:
          this.props.displayType === "DAILY"
            ? entry.dateFrom.format("[KW] w")
            : undefined,
        showEndDivider:
          this.props.displayType === "DAILY" && entry.dateTo.weekday() === 4,
        values: [],
      };
      this.addDataForColumn(
        newColumnData,
        entry,
        index,
        cashBudgetComparisonSpans,
        isCurrentColumn,
        false,
        entry.dateFrom.isAfter(moment().utc(true))
      );

      data.push(newColumnData);
    });

    // add sum column for not daily tables
    if (this.props.displayType !== "DAILY") {
      const hasForecastEntries = moment().isBefore(
        cashBudgetSpans[cashBudgetSpans.length - 1].balanceEnd
      );

      const sumColumnData: CashBudgetColumnConfig = {
        id: `#ENTRY-${this.props.displayType}-SUM`,
        headerText: i18n.t("Global.Labels.total", "Gesamt"),
        type: cashBudgetComparisonSpans != null ? "comparison" : "default",
        allBold: false,
        highlight: "sum",
        subHeaders:
          cashBudgetComparisonSpans != null
            ? [
                i18n.t("cb:Label.Should"),
                i18n.t("cb:Label.Is"),
                i18n.t("cb:Label.Difference"),
              ]
            : undefined,
        topHeaderTextCenter: hasForecastEntries ? "Prognose" : undefined,
        topHeaderTextLeft: undefined,
        topHeaderTextRight: undefined,
        showEndDivider: false,
        values: [],
      };

      // create cashBudgetSpan for the whole duration
      const cashBudgetSum: CashBudgetSpan = cashBudgetSpans.reduce(
        (prev, current) => ({
          ...current,
          loans: current.loans?.reduce((p, c) => {
            if (!p.some((e) => e.type === c.type)) {
              return [...p, c];
            }
            return p.map((e) =>
              e.type === c.type
                ? {
                    ...e,
                    interest: e.interest + c.interest,
                    repayment: e.repayment + c.repayment,
                  }
                : e
            );
          }, prev.loans),
          categories: {
            ...prev.categories,
            ...Object.fromEntries(
              Object.entries(current.categories).map(([ident, value]) => [
                ident,
                {
                  category: value.category,
                  actualValue:
                    value.actualValue +
                    (prev.categories[ident]?.actualValue || 0),
                  targetValue:
                    value.targetValue +
                    (prev.categories[ident]?.targetValue || 0),
                  displayValue:
                    value.displayValue +
                    (prev.categories[ident]?.displayValue || 0),
                  types: {},
                },
              ])
            ),
          },
        }),
        {
          loans: [],
          categories: {},
          balanceStart: cashBudgetSpans[0].balanceStart,
          balanceEnd: cashBudgetSpans[cashBudgetSpans.length - 1].balanceEnd,
          dateFrom: cashBudgetSpans[0].dateFrom,
          dateTo: cashBudgetSpans[cashBudgetSpans.length - 1].dateTo,
        }
      );
      const cashBudgetSumComparison: CashBudgetSpan =
        cashBudgetComparisonSpans != null
          ? cashBudgetComparisonSpans.reduce(
              (prev, current) => ({
                ...current,
                categories: {
                  ...prev.categories,
                  ...Object.fromEntries(
                    Object.entries(current).map(([ident, value]) => [
                      ident,
                      value + (prev.categories[ident] || 0),
                    ])
                  ),
                },
              }),
              {
                categories: {},
                balanceStart: cashBudgetComparisonSpans[0].balanceStart,
                balanceEnd:
                  cashBudgetComparisonSpans[
                    cashBudgetComparisonSpans.length - 1
                  ].balanceEnd,
                dateFrom: cashBudgetComparisonSpans[0].dateFrom,
                dateTo:
                  cashBudgetComparisonSpans[
                    cashBudgetComparisonSpans.length - 1
                  ].dateTo,
              }
            )
          : null;
      const comparisonSumAll = cashBudgetSumComparison
        ? [cashBudgetSumComparison]
        : null;

      this.addDataForColumn(
        sumColumnData,
        cashBudgetSum,
        0,
        comparisonSumAll,
        false,
        true,
        false
      );

      data.push(sumColumnData);
    }

    this.setState({ data }, () => {
      if (this.state.nextFocusDate) {
        setTimeout(() => {
          this.moveTo(this.state.nextFocusDate, false);
          this.setState({ nextFocusDate: null });
        }, 50);
      }
    });
  }

  private calcSpanValue(
    selector: "displayValue" | "actualValue" | "targetValue" | "budget",
    category: CashBudgetCategory,
    span: CashBudgetSpan,
    allCategories: CashBudgetCategory[],
    ignoreChildren = false
  ) {
    if (!category || !span) {
      return undefined;
    }
    const isGroup = ignoreChildren
      ? false
      : (category.data.tags || []).indexOf("group") !== -1;
    if (isGroup) {
      const children = allCategories.filter(
        (e) => e.data.group === category._id
      );
      const mathFC = category.data.kind === "income" ? Math.max : Math.min;
      return mathFC(
        children.reduce(
          (prev, current) =>
            prev +
            (span.categories[current._id]?.[selector] ||
              span.categories[current._id]?.types?.[selector] ||
              0),
          span.categories[category._id]?.[selector] ||
            span.categories[category._id]?.types?.[selector] ||
            0
        ),
        span.categories[category._id]?.[selector] ||
          span.categories[category._id]?.types?.[selector] ||
          0
      );
    } else {
      return (
        span.categories[category._id]?.[selector] ||
        span.categories[category._id]?.types?.[selector]
      );
    }
  }

  private getDataForLoans(
    type: string,
    newColumnData: CashBudgetColumnConfig,
    entry: CashBudgetSpan,
    isCurrentColumn: boolean,
    isSumColumn: boolean,
    isFutureColumn: boolean,
    ignoreOverrides: boolean
  ) {
    const relevantLoan = entry?.loans?.find((e) => e.type === type);

    return [
      { text: i18n.t("cb::Label.LoanInterest", "Zinsen"), key: "interest" },
      { text: i18n.t("cb::Label.LoanRepayment", "Tilgung"), key: "repayment" },
    ].map(({ key, text }) => {
      return {
        base: {
          newColumnData,
          cashBudgetSpan: entry,
          // comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: text,
          clickable: false,
          // clickData: (span, isComparison) => {
          //   return {
          //     spanType:
          //       cashBudgetComparisonSpans === null
          //         ? "normal"
          //         : isComparison
          //         ? "comparisonShould"
          //         : "comparisonCurrent",
          //     span: { ...span },
          //     category: category,
          //     type,
          //     ignoreChildren: isNotAssignedRow,
          //   };
          // },
          tags: [],
          bold: false,
          opacity: this.state.operativeCashbudgetFocused ? 0.5 : 1,
          selectorFc: (span, columnType) => {
            return -1 * relevantLoan?.[key] || 0;
          },
          renderLeft: <BfIcon type="light" data="hyperlink" size="xxs" />,
          progress: undefined,
          renderOnHover: () => (
            <div className={`table-tooltip`}>
              {i18n.t(
                "cb:Label.VirtualLoanValue.Description",
                "Geplanter Wert aus Darlehensplan"
              )}
            </div>
          ),
        } as AddDataprops,
      };
    });
  }

  private getDataForCategories(
    type: string,
    categories: CashBudgetCategory[],
    newColumnData: CashBudgetColumnConfig,
    entry: CashBudgetSpan,
    index,
    cashBudgetComparisonSpans: CashBudgetSpan[],
    isCurrentColumn: boolean,
    isSumColumn: boolean,
    isFutureColumn: boolean,
    showHoverOverlay: boolean,
    allCategories: CashBudgetCategory[],
    ignoreOverrides: boolean,
    overrideSelector?:
      | "displayValue"
      | "actualValue"
      | "targetValue"
      | "budget",
    ignoreProgress = false,
    addParentAsNotAssigned: CashBudgetCategory = null,
    columnType?: "is" | "should" | "budget"
  ): {
    base: AddDataprops;
    overrides?: Partial<AddDataprops>[];
    category: CashBudgetCategory;
  }[] {
    const categoriesToRender = [
      ...categories,
      ...(addParentAsNotAssigned ? [addParentAsNotAssigned] : []),
    ];

    return categoriesToRender.map((category, index) => {
      const isNotAssignedRow = addParentAsNotAssigned
        ? index === categoriesToRender.length - 1
        : false;
      const isGroup = isNotAssignedRow
        ? false
        : (category.data.tags || []).indexOf("group") !== -1;

      const isLoan = (category.data.tags || []).indexOf("loan") !== -1;

      return {
        base: {
          newColumnData,
          cashBudgetSpan: entry,
          comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: isNotAssignedRow
            ? i18n.t("cb:LQ.NotAssigned", "Nicht zugewiesen")
            : LanguageService.translateLabel(category.data.displayName),
          clickable: isSumColumn ? false : true,
          clickData: (span, isComparison) => {
            return {
              spanType:
                cashBudgetComparisonSpans === null
                  ? "normal"
                  : isComparison
                  ? "comparisonShould"
                  : "comparisonCurrent",
              span: { ...span },
              category: category,
              type,
              ignoreChildren: isNotAssignedRow,
            };
          },
          tags: category.data.tags,
          bold: false,
          opacity:
            this.state.operativeCashbudgetFocused &&
            category.data.tags.indexOf(TAG_OPERATIVE_CASHFLOW) === -1
              ? 0.5
              : 1,
          selectorFc: (span) => {
            if (isNotAssignedRow) {
            }
            return isNotAssignedRow &&
              (columnType === "budget" || columnType === "should")
              ? undefined
              : this.calcSpanValue(
                  overrideSelector || "displayValue",
                  category,
                  span,
                  allCategories,
                  isNotAssignedRow
                );
          },
          progress:
            // isNotAssignedRow && overrideSelector !== "actualValue"
            //   ? undefined
            //   :
            !ignoreProgress &&
            isCurrentColumn &&
            ((isGroup &&
              (typeof entry.categories[category._id]?.targetValue !==
                "number" ||
                entry.categories[category._id]?.targetValue === 0)) ||
              (typeof entry.categories[category._id]?.targetValue ===
                "number" &&
                entry.categories[category._id]?.targetValue !== 0))
              ? this.calcSpanValue(
                  "actualValue",
                  category,
                  entry,
                  allCategories
                ) /
                this.calcSpanValue(
                  "targetValue",
                  category,
                  entry,
                  allCategories
                )
              : undefined,
          renderOnHover: showHoverOverlay
            ? undefined
            : () => {
                return (
                  <CashBudgetCellOverlay
                    category={category}
                    actualValue={this.calcSpanValue(
                      "actualValue",
                      category,
                      entry,
                      allCategories
                    )}
                    targetValue={this.calcSpanValue(
                      "targetValue",
                      category,
                      entry,
                      allCategories
                    )}
                    types={entry.categories[category._id]?.types}
                  />
                );
              },
          ...(isGroup
            ? {
                subValuesIdent: category._id,
                subValues: [
                  ...this.getDataForCategories(
                    type,
                    allCategories.filter((e) => e.data.group === category._id),
                    newColumnData,
                    entry,
                    index,
                    cashBudgetComparisonSpans,
                    isCurrentColumn,
                    isSumColumn,
                    isFutureColumn,
                    showHoverOverlay,
                    allCategories,
                    true,
                    undefined,
                    undefined,
                    category,
                    "is"
                  ),
                ],
              }
            : {}),
          ...(isLoan
            ? {
                subValuesIdent: category._id,

                subValues: [
                  ...this.getDataForLoans(
                    category.data.type,
                    newColumnData,
                    entry,
                    isCurrentColumn,
                    isSumColumn,
                    isFutureColumn,
                    ignoreOverrides
                  ),
                ],
              }
            : {}),
        } as AddDataprops,
        overrides: ignoreOverrides
          ? undefined
          : this.state.viewType === "BOOKED"
          ? isCurrentColumn
            ? [
                {
                  columnType: "is",
                  progress: undefined,
                  selectorFc: (span) =>
                    this.calcSpanValue(
                      "actualValue",
                      category,
                      span,
                      allCategories
                    ),
                  ...(isGroup
                    ? {
                        subValuesIdent: category._id,
                        subValues: this.getDataForCategories(
                          type,
                          allCategories.filter(
                            (e) => e.data.group === category._id
                          ),
                          newColumnData,
                          entry,
                          index,
                          cashBudgetComparisonSpans,
                          isCurrentColumn,
                          isSumColumn,
                          isFutureColumn,
                          showHoverOverlay,
                          allCategories,
                          true,
                          "actualValue",
                          true,
                          category,
                          "is"
                        ),
                      }
                    : {}),
                },
                {
                  columnType: "should",
                  ...(isGroup
                    ? {
                        subValuesIdent: category._id,
                        subValues: this.getDataForCategories(
                          type,
                          allCategories.filter(
                            (e) => e.data.group === category._id
                          ),
                          newColumnData,
                          entry,
                          index,
                          cashBudgetComparisonSpans,
                          isCurrentColumn,
                          isSumColumn,
                          isFutureColumn,
                          showHoverOverlay,
                          allCategories,
                          true,
                          "displayValue",
                          true,
                          category,
                          "should"
                        ),
                      }
                    : {}),
                },
              ]
            : null
          : this.state.viewType === "BUDGET"
          ? isCurrentColumn
            ? [
                {
                  columnType: "is",
                  progress: undefined,
                  selectorFc: (span) =>
                    this.calcSpanValue(
                      "actualValue",
                      category,
                      span,
                      allCategories
                    ),
                  ...(isGroup
                    ? {
                        subValuesIdent: category._id,
                        subValues: this.getDataForCategories(
                          type,
                          allCategories.filter(
                            (e) => e.data.group === category._id
                          ),
                          newColumnData,
                          entry,
                          index,
                          cashBudgetComparisonSpans,
                          isCurrentColumn,
                          isSumColumn,
                          isFutureColumn,
                          showHoverOverlay,
                          allCategories,
                          true,
                          "actualValue",
                          true,
                          category,
                          "is"
                        ),
                      }
                    : {}),
                },
                {
                  columnType: "should",
                  ...(isGroup
                    ? {
                        subValuesIdent: category._id,
                        subValues: this.getDataForCategories(
                          type,
                          allCategories.filter(
                            (e) => e.data.group === category._id
                          ),
                          newColumnData,
                          entry,
                          index,
                          cashBudgetComparisonSpans,
                          isCurrentColumn,
                          isSumColumn,
                          isFutureColumn,
                          showHoverOverlay,
                          allCategories,
                          true,
                          "displayValue",
                          true,
                          category,
                          "should"
                        ),
                      }
                    : {}),
                },
                {
                  columnType: "budget",
                  progress:
                    (isGroup &&
                      (typeof entry.categories[category._id]?.types?.budget !==
                        "number" ||
                        entry.categories[category._id]?.types?.budget === 0)) ||
                    (typeof entry.categories[category._id]?.types?.budget ===
                      "number" &&
                      entry.categories[category._id]?.types?.budget !== 0)
                      ? this.calcSpanValue(
                          "actualValue",
                          category,
                          entry,
                          allCategories
                        ) /
                        this.calcSpanValue(
                          "budget",
                          category,
                          entry,
                          allCategories
                        )
                      : undefined,

                  selectorFc: (span) =>
                    this.calcSpanValue("budget", category, span, allCategories),
                  ...(isGroup
                    ? {
                        subValuesIdent: category._id,
                        subValues: this.getDataForCategories(
                          type,
                          allCategories.filter(
                            (e) => e.data.group === category._id
                          ),
                          newColumnData,
                          entry,
                          index,
                          cashBudgetComparisonSpans,
                          isCurrentColumn,
                          isSumColumn,
                          isFutureColumn,
                          showHoverOverlay,
                          allCategories,
                          true,
                          "budget",
                          true,
                          category,
                          "budget"
                        ),
                      }
                    : {}),
                },
              ]
            : !isSumColumn && !isFutureColumn
            ? [
                {
                  columnType: "is",
                },
                {
                  columnType: "budget",
                  progress:
                    (isGroup &&
                      (typeof entry.categories[category._id]?.types?.budget !==
                        "number" ||
                        entry.categories[category._id]?.types?.budget === 0)) ||
                    (typeof entry.categories[category._id]?.types?.budget ===
                      "number" &&
                      entry.categories[category._id]?.types?.budget !== 0)
                      ? this.calcSpanValue(
                          "actualValue",
                          category,
                          entry,
                          allCategories
                        ) /
                        this.calcSpanValue(
                          "budget",
                          category,
                          entry,
                          allCategories
                        )
                      : undefined,
                  selectorFc: (span) =>
                    this.calcSpanValue("budget", category, span, allCategories),
                  ...(isGroup
                    ? {
                        subValuesIdent: category._id,
                        subValues: this.getDataForCategories(
                          type,
                          allCategories.filter(
                            (e) => e.data.group === category._id
                          ),
                          newColumnData,
                          entry,
                          index,
                          cashBudgetComparisonSpans,
                          isCurrentColumn,
                          isSumColumn,
                          isFutureColumn,
                          showHoverOverlay,
                          allCategories,
                          true,
                          "budget",
                          true,
                          category,
                          "budget"
                        ),
                      }
                    : {}),
                },
              ]
            : null
          : (undefined as any),
        category: category,
      };
    });
  }

  private addDataForColumn(
    newColumnData: CashBudgetColumnConfig,
    entry: CashBudgetSpan,
    index,
    cashBudgetComparisonSpans: CashBudgetSpan[],
    isCurrentColumn: boolean,
    isSumColumn: boolean,
    isFutureColumn: boolean
  ) {
    const showHoverOverlay =
      isSumColumn || entry.dateTo.isAfter(moment()) ? false : true;

    const categoriesRestrict = this.props.restrictCategories?.map((e) =>
      CashBudgetUtils.findCategory(e)
    );
    const allCategories = CashBudgetUtils.getAllCategories(
      this.getTypes()
    ).filter((cat) =>
      categoriesRestrict
        ? categoriesRestrict.some(
            (restrictCat) =>
              restrictCat._id === cat._id || restrictCat.data.group === cat._id
          )
        : true
    );
    const categoriesPositive = allCategories.filter(
      (cat) => cat.data.kind === "income"
    );
    const categoriesNegative = allCategories?.filter(
      (cat) => cat.data.kind === "expense"
    );

    if (!this.props.ignoreCalculations) {
      this.addData(
        {
          newColumnData,
          cashBudgetSpan: entry,
          comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: i18n.t("cb:Label.StartBalance"), // "Anfang Kontostand",
          clickable: false,
          clickData: null,
          bold: true,
          selectorFc: (span) => span.balanceStart,
          columnType: "is",
        },
        this.state.viewType === "BOOKED"
          ? isCurrentColumn
            ? [{ columnType: "is" }, { columnType: "should" }]
            : null
          : this.state.viewType === "BUDGET"
          ? isCurrentColumn
            ? [
                { columnType: "is" },
                { columnType: "should" },
                { columnType: "budget" },
              ]
            : !isSumColumn && !isFutureColumn
            ? [{ columnType: "is" }, { columnType: "budget" }]
            : null
          : null
      );
    }
    if (categoriesPositive.length > 0) {
      this.addData(
        {
          newColumnData,
          cashBudgetSpan: entry,
          comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: i18n.t("cb:Label.CashInflow"), // "Einzahlungen/Geldflüsse",
          clickable: false,
          clickData: null,
          bold: false,
          selectorFc: (span) => null,
          columnType: "is",
        },
        this.state.viewType === "BOOKED"
          ? isCurrentColumn
            ? [{ columnType: "is" }, { columnType: "should" }]
            : null
          : this.state.viewType === "BUDGET"
          ? isCurrentColumn
            ? [
                { columnType: "is" },
                { columnType: "should" },
                { columnType: "budget" },
              ]
            : !isSumColumn && !isFutureColumn
            ? [{ columnType: "is" }, { columnType: "budget" }]
            : null
          : null
      );
      if (this.getTypes().length === 1) {
        this.getDataForCategories(
          "POSITIVE",
          categoriesPositive.filter((category) => !category.data.group),
          newColumnData,
          entry,
          index,
          cashBudgetComparisonSpans,
          isCurrentColumn,
          isSumColumn,
          isFutureColumn,
          showHoverOverlay,
          allCategories,
          false
        ).forEach((entry) => this.addData(entry.base, entry.overrides));
      } else {
        this.getTypes().forEach((unit) => {
          const unitValues = this.getDataForCategories(
            "POSITIVE",
            categoriesPositive
              .filter((e) => e.data.type === unit)
              .filter((category) => !category.data.group),
            newColumnData,
            entry,
            index,
            cashBudgetComparisonSpans,
            isCurrentColumn,
            isSumColumn,
            isFutureColumn,
            showHoverOverlay,
            allCategories,
            true
          );

          this.addData(
            {
              newColumnData,
              cashBudgetSpan: entry,
              cellBackground: this.props.paintCategories
                ? `${new Color(
                    CashBudgetUtils.getColorOfUnit(unit)
                  ).hex()}${FACTOR_OPACITY_UNIT_SUM}`
                : undefined,
              comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
              text: CashBudgetUtils.getLabelOfUnit(unit),
              clickable: false,
              clickData: null,
              bold: true,
              //todo calculate groups right
              selectorFc: (span) =>
                categoriesPositive
                  .filter((e) => e.data.type === unit)
                  .reduce(
                    (prev, current) =>
                      prev + (span.categories[current._id]?.displayValue || 0),
                    0
                  ),
              subValuesIdent: `positive-${unit}`,
              subValues: unitValues,
              columnType: "is",
            },
            this.state.viewType === "BOOKED"
              ? isCurrentColumn
                ? [
                    {
                      progress: undefined,
                      subValues: unitValues.map((e) => ({
                        base: {
                          ...e.base,
                          progress: undefined,
                          selectorFc: (span) =>
                            this.calcSpanValue(
                              "actualValue",
                              e.category,
                              span,
                              allCategories
                            ),
                          subValues: e.base.subValues
                            ? e.base.subValues.map((sub) => ({
                                base: {
                                  ...sub.base,
                                  progress: undefined,
                                  selectorFc: (span) =>
                                    this.calcSpanValue(
                                      "actualValue",
                                      sub.category,
                                      span,
                                      allCategories
                                    ),
                                  // span.categories[e.category._id]?.actualValue,
                                },
                                overrides: sub.overrides,
                              }))
                            : undefined,
                        },
                        overrides: e.overrides,
                        category: e.category,
                      })),
                      selectorFc: (span) =>
                        categoriesPositive
                          .filter((e) => e.data.type === unit)
                          .reduce(
                            (prev, current) =>
                              prev +
                              (this.calcSpanValue(
                                "actualValue",
                                current,
                                span,
                                allCategories
                              ) || 0),
                            0
                          ),
                      columnType: "is",
                    },
                    {
                      columnType: "should",
                    },
                  ]
                : null
              : this.state.viewType === "BUDGET"
              ? isCurrentColumn
                ? [
                    { columnType: "is" },
                    {
                      progress: undefined,
                      subValues: unitValues.map((e) => ({
                        base: {
                          ...e.base,
                          progress: undefined,
                          selectorFc: (span) =>
                            this.calcSpanValue(
                              "budget",
                              e.category,
                              span,
                              allCategories
                            ),
                          subValues: e.base.subValues
                            ? e.base.subValues.map((sub) => ({
                                base: {
                                  ...sub.base,
                                  progress: undefined,
                                  selectorFc: (span) =>
                                    this.calcSpanValue(
                                      "budget",
                                      sub.category,
                                      span,
                                      allCategories
                                    ),
                                  // span.categories[e.category._id]?.actualValue,
                                },
                                overrides: sub.overrides,
                              }))
                            : undefined,
                        },
                        overrides: e.overrides,
                        category: e.category,
                      })),
                      selectorFc: (span) =>
                        categoriesPositive
                          .filter((e) => e.data.type === unit)
                          .reduce(
                            (prev, current) =>
                              prev +
                              (this.calcSpanValue(
                                "budget",
                                current,
                                span,
                                allCategories
                              ) || 0),
                            0
                          ),
                      columnType: "should",
                    },
                    {
                      progress: undefined,
                      subValues: unitValues.map((e) => ({
                        base: {
                          ...e.base,
                          progress: undefined,
                          selectorFc: (span) =>
                            this.calcSpanValue(
                              "actualValue",
                              e.category,
                              span,
                              allCategories
                            ),
                          subValues: e.base.subValues
                            ? e.base.subValues.map((sub) => ({
                                base: {
                                  ...sub.base,
                                  progress: undefined,
                                  selectorFc: (span) =>
                                    this.calcSpanValue(
                                      "actualValue",
                                      sub.category,
                                      span,
                                      allCategories
                                    ),
                                  // span.categories[e.category._id]?.actualValue,
                                },
                                overrides: sub.overrides,
                              }))
                            : undefined,
                        },
                        overrides: e.overrides,
                        category: e.category,
                      })),
                      selectorFc: (span) =>
                        categoriesPositive
                          .filter((e) => e.data.type === unit)
                          .reduce(
                            (prev, current) =>
                              prev +
                              (this.calcSpanValue(
                                "actualValue",
                                current,
                                span,
                                allCategories
                              ) || 0),
                            0
                          ),
                      columnType: "budget",
                    },
                  ]
                : !isSumColumn && !isFutureColumn
                ? [
                    { columnType: "is" },
                    {
                      progress: undefined,
                      subValues: unitValues.map((e) => ({
                        base: {
                          ...e.base,
                          progress: undefined,
                          selectorFc: (span) =>
                            this.calcSpanValue(
                              "budget",
                              e.category,
                              span,
                              allCategories
                            ),
                          subValues: e.base.subValues
                            ? e.base.subValues.map((sub) => ({
                                base: {
                                  ...sub.base,
                                  progress: undefined,
                                  selectorFc: (span) =>
                                    this.calcSpanValue(
                                      "budget",
                                      sub.category,
                                      span,
                                      allCategories
                                    ),
                                  // span.categories[e.category._id]?.actualValue,
                                },
                                overrides: sub.overrides,
                              }))
                            : undefined,
                        },
                        overrides: e.overrides,
                        category: e.category,
                      })),
                      selectorFc: (span) =>
                        categoriesPositive
                          .filter((e) => e.data.type === unit)
                          .reduce(
                            (prev, current) =>
                              prev +
                              (this.calcSpanValue(
                                "budget",
                                current,
                                span,
                                allCategories
                              ) || 0),
                            0
                          ),
                      columnType: "budget",
                    },
                  ]
                : null
              : null
          );
        });
      }
      this.addData(
        {
          newColumnData,
          cashBudgetSpan: entry,
          comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: i18n.t("cb:Label.IncomeAll"), // "Einnahmen Gesamt",
          clickable: false,
          clickData: null,
          bold: true,
          selectorFc: (span) =>
            categoriesPositive.reduce(
              (prev, current) =>
                prev + (span.categories[current._id]?.displayValue || 0),
              0
            ),
          columnType: "is",
        },

        this.state.viewType === "BOOKED"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesPositive.reduce(
                      (prev, current) =>
                        prev + (span.categories[current._id]?.actualValue || 0),
                      0
                    ),
                  columnType: "is",
                },
                { columnType: "should" },
              ]
            : null
          : this.state.viewType === "BUDGET"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesPositive.reduce(
                      (prev, current) =>
                        prev + (span.categories[current._id]?.actualValue || 0),
                      0
                    ),
                  columnType: "is",
                },
                { columnType: "should" },
                {
                  selectorFc: (span) =>
                    categoriesPositive.reduce(
                      (prev, current) =>
                        prev +
                        (span.categories[current._id]?.types?.budget || 0),
                      0
                    ),
                  columnType: "budget",
                },
              ]
            : !isSumColumn && !isFutureColumn
            ? [
                { columnType: "is" },
                {
                  selectorFc: (span) =>
                    categoriesPositive.reduce(
                      (prev, current) =>
                        prev +
                        (span.categories[current._id]?.types?.budget || 0),
                      0
                    ),
                  columnType: "budget",
                },
              ]
            : null
          : null
      );

      if (!this.props.ignoreCalculations) {
        this.addData(
          {
            newColumnData,
            cashBudgetSpan: entry,
            comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
            text: i18n.t("cb:Label.OperativeIncome"), // "Operative Einnahmen",
            clickable: false,
            clickData: null,
            bold: true,
            selectorFc: (span) =>
              categoriesPositive
                .filter(
                  (category) =>
                    (category.data.tags || []).indexOf(
                      TAG_OPERATIVE_CASHFLOW
                    ) !== -1
                )
                .reduce(
                  (prev, current) =>
                    prev + (span.categories[current._id]?.displayValue || 0),
                  0
                ),
            columnType: "is",
          },

          this.state.viewType === "BOOKED"
            ? isCurrentColumn
              ? [
                  {
                    selectorFc: (span) =>
                      categoriesPositive
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.actualValue || 0),
                          0
                        ),
                    columnType: "is",
                  },
                  { columnType: "should" },
                ]
              : null
            : this.state.viewType === "BUDGET"
            ? isCurrentColumn
              ? [
                  {
                    selectorFc: (span) =>
                      categoriesPositive
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.actualValue || 0),
                          0
                        ),
                    columnType: "is",
                  },
                  { columnType: "should" },
                  {
                    selectorFc: (span) =>
                      categoriesPositive
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.types?.budget || 0),
                          0
                        ),
                    columnType: "budget",
                  },
                ]
              : !isSumColumn && !isFutureColumn
              ? [
                  { columnType: "is" },
                  {
                    selectorFc: (span) =>
                      categoriesPositive
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.types?.budget || 0),
                          0
                        ),
                    columnType: "budget",
                  },
                ]
              : null
            : null
        );
        this.addData(
          {
            newColumnData,
            cashBudgetSpan: entry,
            comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
            text: i18n.t("cb:Label.SumAvailableFunds"), //"Summe verfügbarer Mittel",
            clickable: false,
            clickData: null,
            bold: true,
            selectorFc: (span) =>
              span.balanceStart +
              categoriesPositive.reduce(
                (prev, current) =>
                  prev + (span.categories[current._id]?.displayValue || 0),
                0
              ),
            columnType: "is",
          },

          this.state.viewType === "BOOKED"
            ? isCurrentColumn
              ? [
                  {
                    columnType: "is",
                    selectorFc: (span) =>
                      span.balanceStart +
                      categoriesPositive.reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      ),
                  },
                  { columnType: "should" },
                ]
              : null
            : this.state.viewType === "BUDGET"
            ? isCurrentColumn
              ? [
                  {
                    columnType: "is",
                    selectorFc: (span) =>
                      span.balanceStart +
                      categoriesPositive.reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      ),
                  },
                  { columnType: "should" },
                  { columnType: "budget" },
                ]
              : !isSumColumn && !isFutureColumn
              ? [{ columnType: "is" }, { columnType: "budget" }]
              : null
            : null

          // isCurrentColumn
          //   ? [
          //       {
          //         selectorFc: (span) =>
          //           span.balanceStart +
          //           categoriesPositive.reduce(
          //             (prev, current) =>
          //               prev + (span.categories[current._id]?.actualValue || 0),
          //             0
          //           ),
          //       },
          //       {},
          //     ]
          //   : null
        );
      }
    }

    if (categoriesNegative.length > 0) {
      this.addData(
        {
          newColumnData,
          cashBudgetSpan: entry,
          comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: i18n.t("cb:Label.Payout"), //"Auszahlungen/Zahlungsausgänge",
          clickable: false,
          clickData: null,
          bold: false,
          selectorFc: (span) => null,
          columnType: "is",
        },

        this.state.viewType === "BOOKED"
          ? isCurrentColumn
            ? [{ columnType: "is" }, { columnType: "should" }]
            : null
          : this.state.viewType === "BUDGET"
          ? isCurrentColumn
            ? [
                { columnType: "is" },
                { columnType: "should" },
                { columnType: "budget" },
              ]
            : !isSumColumn && !isFutureColumn
            ? [{ columnType: "is" }, { columnType: "budget" }]
            : null
          : null
        // isCurrentColumn ? [{}, {}] : null
      );

      if (this.getTypes().length === 1) {
        this.getDataForCategories(
          "NEGATIVE",
          categoriesNegative.filter((category) => !category.data.group),
          newColumnData,
          entry,
          index,
          cashBudgetComparisonSpans,
          isCurrentColumn,
          isSumColumn,
          isFutureColumn,
          showHoverOverlay,
          allCategories,
          false
        ).forEach((entry) => this.addData(entry.base, entry.overrides));
      } else {
        this.getTypes().forEach((unit) => {
          const unitValues = this.getDataForCategories(
            "NEGATIVE",
            categoriesNegative
              .filter((e) => e.data.type === unit)
              .filter((category) => !category.data.group),
            newColumnData,
            entry,
            index,
            cashBudgetComparisonSpans,
            isCurrentColumn,
            isSumColumn,
            isFutureColumn,
            showHoverOverlay,
            allCategories,
            true
          );

          this.addData(
            {
              newColumnData,
              cashBudgetSpan: entry,
              cellBackground: this.props.paintCategories
                ? `${new Color(
                    CashBudgetUtils.getColorOfUnit(unit)
                  ).hex()}${FACTOR_OPACITY_UNIT_SUM}`
                : undefined,
              comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
              text: CashBudgetUtils.getLabelOfUnit(unit),
              clickable: false,
              clickData: null,
              bold: true,
              //todo calculate groups right
              selectorFc: (span) =>
                categoriesNegative
                  .filter((e) => e.data.type === unit)
                  .reduce(
                    (prev, current) =>
                      prev + (span.categories[current._id]?.displayValue || 0),
                    0
                  ),
              subValuesIdent: `negative-${unit}`,
              subValues: unitValues,
              columnType: "is",
            },

            this.state.viewType === "BOOKED"
              ? isCurrentColumn
                ? [
                    {
                      progress: undefined,
                      subValues: unitValues.map((e) => ({
                        base: {
                          ...e.base,
                          progress: undefined,
                          selectorFc: (span) =>
                            this.calcSpanValue(
                              "actualValue",
                              e.category,
                              span,
                              allCategories
                            ),
                          subValues: e.base.subValues
                            ? e.base.subValues.map((sub) => ({
                                base: {
                                  ...sub.base,
                                  progress: undefined,
                                  selectorFc: (span) =>
                                    this.calcSpanValue(
                                      "actualValue",
                                      sub.category,
                                      span,
                                      allCategories
                                    ),
                                  // span.categories[e.category._id]?.actualValue,
                                },
                                overrides: sub.overrides,
                              }))
                            : undefined,
                        },
                        overrides: e.overrides,
                        category: e.category,
                      })),
                      selectorFc: (span) =>
                        categoriesPositive
                          .filter((e) => e.data.type === unit)
                          .reduce(
                            (prev, current) =>
                              prev +
                              (this.calcSpanValue(
                                "actualValue",
                                current,
                                span,
                                allCategories
                              ) || 0),
                            0
                          ),
                      columnType: "is",
                    },
                    { columnType: "should" },
                  ]
                : null
              : this.state.viewType === "BUDGET"
              ? isCurrentColumn
                ? [
                    { columnType: "is" },
                    {
                      progress: undefined,
                      subValues: unitValues.map((e) => ({
                        base: {
                          ...e.base,
                          progress: undefined,
                          selectorFc: (span) =>
                            this.calcSpanValue(
                              "budget",
                              e.category,
                              span,
                              allCategories
                            ),
                          subValues: e.base.subValues
                            ? e.base.subValues.map((sub) => ({
                                base: {
                                  ...sub.base,
                                  progress: undefined,
                                  selectorFc: (span) =>
                                    this.calcSpanValue(
                                      "budget",
                                      sub.category,
                                      span,
                                      allCategories
                                    ),
                                  // span.categories[e.category._id]?.actualValue,
                                },
                                overrides: sub.overrides,
                              }))
                            : undefined,
                        },
                        overrides: e.overrides,
                        category: e.category,
                      })),
                      selectorFc: (span) =>
                        categoriesPositive
                          .filter((e) => e.data.type === unit)
                          .reduce(
                            (prev, current) =>
                              prev +
                              (this.calcSpanValue(
                                "budget",
                                current,
                                span,
                                allCategories
                              ) || 0),
                            0
                          ),
                      columnType: "should",
                    },
                    {
                      progress: undefined,
                      subValues: unitValues.map((e) => ({
                        base: {
                          ...e.base,
                          progress: undefined,
                          selectorFc: (span) =>
                            this.calcSpanValue(
                              "actualValue",
                              e.category,
                              span,
                              allCategories
                            ),
                          subValues: e.base.subValues
                            ? e.base.subValues.map((sub) => ({
                                base: {
                                  ...sub.base,
                                  progress: undefined,
                                  selectorFc: (span) =>
                                    this.calcSpanValue(
                                      "actualValue",
                                      sub.category,
                                      span,
                                      allCategories
                                    ),
                                  // span.categories[e.category._id]?.actualValue,
                                },
                                overrides: sub.overrides,
                              }))
                            : undefined,
                        },
                        overrides: e.overrides,
                        category: e.category,
                      })),
                      selectorFc: (span) =>
                        categoriesPositive
                          .filter((e) => e.data.type === unit)
                          .reduce(
                            (prev, current) =>
                              prev +
                              (this.calcSpanValue(
                                "actualValue",
                                current,
                                span,
                                allCategories
                              ) || 0),
                            0
                          ),
                      columnType: "budget",
                    },
                  ]
                : !isSumColumn && !isFutureColumn
                ? [
                    { columnType: "is" },
                    {
                      progress: undefined,
                      subValues: unitValues.map((e) => ({
                        base: {
                          ...e.base,
                          progress: undefined,
                          selectorFc: (span) =>
                            this.calcSpanValue(
                              "budget",
                              e.category,
                              span,
                              allCategories
                            ),
                          subValues: e.base.subValues
                            ? e.base.subValues.map((sub) => ({
                                base: {
                                  ...sub.base,
                                  progress: undefined,
                                  selectorFc: (span) =>
                                    this.calcSpanValue(
                                      "budget",
                                      sub.category,
                                      span,
                                      allCategories
                                    ),
                                  // span.categories[e.category._id]?.actualValue,
                                },
                                overrides: sub.overrides,
                              }))
                            : undefined,
                        },
                        overrides: e.overrides,
                        category: e.category,
                      })),
                      selectorFc: (span) =>
                        categoriesNegative
                          .filter((e) => e.data.type === unit)
                          .reduce(
                            (prev, current) =>
                              prev +
                              (this.calcSpanValue(
                                "budget",
                                current,
                                span,
                                allCategories
                              ) || 0),
                            0
                          ),
                      columnType: "budget",
                    },
                  ]
                : null
              : null

            // isCurrentColumn
            //   ? [
            //       {
            //         progress: undefined,
            //         subValues: unitValues.map((e) => ({
            //           base: {
            //             ...e.base,
            //             progress: undefined,
            //             selectorFc: (span) =>
            //               this.calcSpanValue(
            //                 "actualValue",
            //                 e.category,
            //                 span,
            //                 allCategories
            //               ),
            //             subValues: e.base.subValues
            //               ? e.base.subValues.map((sub) => ({
            //                   base: {
            //                     ...sub.base,
            //                     progress: undefined,
            //                     selectorFc: (span) =>
            //                       this.calcSpanValue(
            //                         "actualValue",
            //                         sub.category,
            //                         span,
            //                         allCategories
            //                       ),
            //                     // span.categories[e.category._id]?.actualValue,
            //                   },
            //                   overrides: sub.overrides,
            //                 }))
            //               : undefined,
            //           },
            //           overrides: e.overrides,
            //           category: e.category,
            //         })),
            //         selectorFc: (span) =>
            //           categoriesPositive
            //             .filter((e) => e.data.type === unit)
            //             .reduce(
            //               (prev, current) =>
            //                 prev +
            //                 (this.calcSpanValue(
            //                   "actualValue",
            //                   current,
            //                   span,
            //                   allCategories
            //                 ) || 0),
            //               0
            //             ),
            //       },
            //       {},
            //     ]
            //   : null
          );
        });
      }

      this.addData(
        {
          newColumnData,
          cashBudgetSpan: entry,
          comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: i18n.t("cb:Label.ExpenseAll"), //"Ausgaben Gesamt",
          clickable: false,
          clickData: null,
          bold: false,
          selectorFc: (span) =>
            categoriesNegative.reduce(
              (prev, current) =>
                prev + (span.categories[current._id]?.displayValue || 0),
              0
            ),
          columnType: "is",
        },

        this.state.viewType === "BOOKED"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesNegative.reduce(
                      (prev, current) =>
                        prev + (span.categories[current._id]?.actualValue || 0),
                      0
                    ),
                  columnType: "is",
                },
                { columnType: "should" },
              ]
            : null
          : this.state.viewType === "BUDGET"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesNegative.reduce(
                      (prev, current) =>
                        prev + (span.categories[current._id]?.actualValue || 0),
                      0
                    ),
                  columnType: "is",
                },
                { columnType: "should" },
                {
                  selectorFc: (span) =>
                    categoriesNegative.reduce(
                      (prev, current) =>
                        prev +
                        (span.categories[current._id]?.types?.budget || 0),
                      0
                    ),
                  columnType: "budget",
                },
              ]
            : !isSumColumn && !isFutureColumn
            ? [
                { columnType: "is" },
                {
                  selectorFc: (span) =>
                    categoriesNegative.reduce(
                      (prev, current) =>
                        prev +
                        (span.categories[current._id]?.types?.budget || 0),
                      0
                    ),
                  columnType: "budget",
                },
              ]
            : null
          : null
      );
    }

    if (!this.props.ignoreCalculations) {
      this.addData(
        {
          newColumnData,
          cashBudgetSpan: entry,
          comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: i18n.t("cb:Label.OperativeExpense"), // "Operative Ausgaben",
          clickable: false,
          clickData: null,
          bold: true,
          selectorFc: (span) =>
            categoriesNegative
              .filter(
                (category) =>
                  (category.data.tags || []).indexOf(TAG_OPERATIVE_CASHFLOW) !==
                  -1
              )
              .reduce(
                (prev, current) =>
                  prev + (span.categories[current._id]?.displayValue || 0),
                0
              ),
          columnType: "is",
        },

        this.state.viewType === "BOOKED"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesNegative
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      ),
                  columnType: "is",
                },
                { columnType: "should" },
              ]
            : null
          : this.state.viewType === "BUDGET"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesNegative
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      ),
                  columnType: "is",
                },
                { columnType: "should" },
                {
                  selectorFc: (span) =>
                    categoriesNegative
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.types?.budget || 0),
                        0
                      ),
                  columnType: "budget",
                },
              ]
            : !isSumColumn && !isFutureColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesNegative
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      ),
                  columnType: "is",
                },
                {
                  selectorFc: (span) =>
                    categoriesNegative
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.types?.budget || 0),
                        0
                      ),
                  columnType: "budget",
                },
              ]
            : null
          : null
      );

      this.addData(
        {
          newColumnData,
          cashBudgetSpan: entry,
          comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: i18n.t("cb:Label.Cashflow"), //"Cashflow",
          clickable: false,
          clickData: null,
          bold: false,
          selectorFc: (span) =>
            categoriesPositive.reduce(
              (prev, current) =>
                prev + (span.categories[current._id]?.displayValue || 0),
              0
            ) +
            categoriesNegative.reduce(
              (prev, current) =>
                prev + (span.categories[current._id]?.displayValue || 0),
              0
            ),
          columnType: "is",
        },

        this.state.viewType === "BOOKED"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesPositive.reduce(
                      (prev, current) =>
                        prev + (span.categories[current._id]?.actualValue || 0),
                      0
                    ) +
                    categoriesNegative.reduce(
                      (prev, current) =>
                        prev + (span.categories[current._id]?.actualValue || 0),
                      0
                    ),
                  columnType: "is",
                },
                { columnType: "should" },
              ]
            : null
          : this.state.viewType === "BUDGET"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesPositive.reduce(
                      (prev, current) =>
                        prev + (span.categories[current._id]?.actualValue || 0),
                      0
                    ) +
                    categoriesNegative.reduce(
                      (prev, current) =>
                        prev + (span.categories[current._id]?.actualValue || 0),
                      0
                    ),
                  columnType: "is",
                },
                { columnType: "should" },
                {
                  selectorFc: (span) =>
                    categoriesPositive.reduce(
                      (prev, current) =>
                        prev +
                        (span.categories[current._id]?.types?.budget || 0),
                      0
                    ) +
                    categoriesNegative.reduce(
                      (prev, current) =>
                        prev +
                        (span.categories[current._id]?.types?.budget || 0),
                      0
                    ),
                  columnType: "budget",
                },
              ]
            : !isSumColumn && !isFutureColumn
            ? [
                { columnType: "is" },
                {
                  selectorFc: (span) =>
                    categoriesPositive.reduce(
                      (prev, current) =>
                        prev +
                        (span.categories[current._id]?.types?.budget || 0),
                      0
                    ) +
                    categoriesNegative.reduce(
                      (prev, current) =>
                        prev +
                        (span.categories[current._id]?.types?.budget || 0),
                      0
                    ),
                  columnType: "budget",
                },
              ]
            : null
          : null
      );

      this.addData(
        {
          newColumnData,
          cashBudgetSpan: entry,
          comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: i18n.t("cb:Label.OperativeCashflow"), //"Operativer Cashflow",
          clickable: false,
          clickData: null,
          bold: false,
          cellBackground: this.state.operativeCashbudgetFocused
            ? "rgb(141 175 255 / 35%)"
            : undefined,
          selectorFc: (span) =>
            categoriesPositive
              .filter(
                (category) =>
                  (category.data.tags || []).indexOf(TAG_OPERATIVE_CASHFLOW) !==
                  -1
              )
              .reduce(
                (prev, current) =>
                  prev + (span.categories[current._id]?.displayValue || 0),
                0
              ) +
            categoriesNegative
              .filter(
                (category) =>
                  (category.data.tags || []).indexOf(TAG_OPERATIVE_CASHFLOW) !==
                  -1
              )
              .reduce(
                (prev, current) =>
                  prev + (span.categories[current._id]?.displayValue || 0),
                0
              ),
          columnType: "is",
        },

        this.state.viewType === "BOOKED"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesPositive
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      ) +
                    categoriesNegative
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      ),
                  columnType: "is",
                },
                { columnType: "should" },
              ]
            : null
          : this.state.viewType === "BUDGET"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    categoriesPositive
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      ) +
                    categoriesNegative
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      ),
                  columnType: "is",
                },
                { columnType: "should" },
                {
                  selectorFc: (span) =>
                    categoriesPositive
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.types?.budget || 0),
                        0
                      ) +
                    categoriesNegative
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.types?.budget || 0),
                        0
                      ),
                  columnType: "should",
                },
              ]
            : !isSumColumn && !isFutureColumn
            ? [
                { columnType: "is" },
                {
                  selectorFc: (span) =>
                    categoriesPositive
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.types?.budget || 0),
                        0
                      ) +
                    categoriesNegative
                      .filter(
                        (category) =>
                          (category.data.tags || []).indexOf(
                            TAG_OPERATIVE_CASHFLOW
                          ) !== -1
                      )
                      .reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.types?.budget || 0),
                        0
                      ),
                  columnType: "budget",
                },
              ]
            : null
          : null
      );

      if (categoriesNegative.find((e) => e.data.tags.indexOf("loan") !== -1)) {
        this.addData(
          {
            newColumnData,
            cashBudgetSpan: entry,
            comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
            text: i18n.t(
              "cb:Label.OperativeCashflowWithRepayment",
              "Operativer Cashflow + Tilgung"
            ), //"Operativer Cashflow",
            clickable: false,
            clickData: null,
            bold: false,
            cellBackground: this.state.operativeCashbudgetFocused
              ? "rgb(141 175 255 / 35%)"
              : undefined,
            selectorFc: (span) =>
              span.loans?.reduce(
                (prev, current) => prev + current.repayment,
                0
              ) +
              categoriesPositive
                .filter(
                  (category) =>
                    (category.data.tags || []).indexOf(
                      TAG_OPERATIVE_CASHFLOW
                    ) !== -1
                )
                .reduce(
                  (prev, current) =>
                    prev + (span.categories[current._id]?.displayValue || 0),
                  0
                ) +
              categoriesNegative
                .filter(
                  (category) =>
                    (category.data.tags || []).indexOf(
                      TAG_OPERATIVE_CASHFLOW
                    ) !== -1
                )
                .reduce(
                  (prev, current) =>
                    prev + (span.categories[current._id]?.displayValue || 0),
                  0
                ),
            columnType: "is",
          },

          this.state.viewType === "BOOKED"
            ? isCurrentColumn
              ? [
                  {
                    selectorFc: (span) =>
                      span.loans?.reduce(
                        (prev, current) => prev + current.repayment,
                        0
                      ) +
                      categoriesPositive
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.actualValue || 0),
                          0
                        ) +
                      categoriesNegative
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.actualValue || 0),
                          0
                        ),
                    columnType: "is",
                  },
                  { columnType: "should" },
                ]
              : null
            : this.state.viewType === "BUDGET"
            ? isCurrentColumn
              ? [
                  {
                    selectorFc: (span) =>
                      span.loans?.reduce(
                        (prev, current) => prev + current.repayment,
                        0
                      ) +
                      categoriesPositive
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.actualValue || 0),
                          0
                        ) +
                      categoriesNegative
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.actualValue || 0),
                          0
                        ),
                    columnType: "is",
                  },
                  { columnType: "should" },
                  {
                    selectorFc: (span) =>
                      span.loans?.reduce(
                        (prev, current) => prev + current.repayment,
                        0
                      ) +
                      categoriesPositive
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.types?.budget || 0),
                          0
                        ) +
                      categoriesNegative
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.types?.budget || 0),
                          0
                        ),
                    columnType: "should",
                  },
                ]
              : !isSumColumn && !isFutureColumn
              ? [
                  { columnType: "is" },
                  {
                    selectorFc: (span) =>
                      span.loans?.reduce(
                        (prev, current) => prev + current.repayment,
                        0
                      ) +
                      categoriesPositive
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.types?.budget || 0),
                          0
                        ) +
                      categoriesNegative
                        .filter(
                          (category) =>
                            (category.data.tags || []).indexOf(
                              TAG_OPERATIVE_CASHFLOW
                            ) !== -1
                        )
                        .reduce(
                          (prev, current) =>
                            prev +
                            (span.categories[current._id]?.types?.budget || 0),
                          0
                        ),
                    columnType: "budget",
                  },
                ]
              : null
            : null
        );
      }

      this.addData(
        {
          newColumnData,
          cashBudgetSpan: entry,
          comparisonCashBudgetSpan: cashBudgetComparisonSpans?.[index],
          text: i18n.t("cb:Label.LiquidResources"), //"Liquide Mittel (Kontostand)",
          clickable: false,
          clickData: null,
          bold: true,
          selectorFc: (span) => span.balanceEnd,
          columnType: "is",
        },

        this.state.viewType === "BOOKED"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    span.balanceStart +
                    (categoriesPositive.reduce(
                      (prev, current) =>
                        prev + (span.categories[current._id]?.actualValue || 0),
                      0
                    ) +
                      categoriesNegative.reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      )),
                  columnType: "is",
                },
                { columnType: "should" },
              ]
            : null
          : this.state.viewType === "BUDGET"
          ? isCurrentColumn
            ? [
                {
                  selectorFc: (span) =>
                    span.balanceStart +
                    (categoriesPositive.reduce(
                      (prev, current) =>
                        prev + (span.categories[current._id]?.actualValue || 0),
                      0
                    ) +
                      categoriesNegative.reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.actualValue || 0),
                        0
                      )),
                  columnType: "is",
                },
                { columnType: "should" },
                {
                  selectorFc: (span) =>
                    span.balanceStart +
                    (categoriesPositive.reduce(
                      (prev, current) =>
                        prev +
                        (span.categories[current._id]?.types?.budget || 0),
                      0
                    ) +
                      categoriesNegative.reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.types?.budget || 0),
                        0
                      )),
                  columnType: "budget",
                },
              ]
            : !isSumColumn && !isFutureColumn
            ? [
                { columnType: "is" },
                {
                  selectorFc: (span) =>
                    span.balanceStart +
                    (categoriesPositive.reduce(
                      (prev, current) =>
                        prev +
                        (span.categories[current._id]?.types?.budget || 0),
                      0
                    ) +
                      categoriesNegative.reduce(
                        (prev, current) =>
                          prev +
                          (span.categories[current._id]?.types?.budget || 0),
                        0
                      )),
                  columnType: "budget",
                },
              ]
            : null
          : null
      );
    }
  }

  private genData({
    newColumnData,
    cashBudgetSpan,
    comparisonCashBudgetSpan,
    text,
    clickable,
    clickData,
    bold,
    selectorFc,
    progress,
    renderOnHover,
    tags,
    opacity,
    subValues,
    subValuesIdent,
    cellBackground,
    columnType,
    renderLeft,
  }: AddDataprops): ValueData {
    const value = selectorFc(cashBudgetSpan, columnType);

    if (value !== null) {
      return {
        clickable,
        clickData: clickData ? clickData(cashBudgetSpan, true) : undefined,
        text: StringUtils.formatCurrency(value || 0, typeof value !== "number"),
        value: value || 0,
        bold,
        progress,
        renderOnHover,
        tags,
        opacity,
        subValues: subValues?.map((e) =>
          e.overrides
            ? e.overrides.map((override) =>
                this.genData({ ...e.base, ...override })
              )
            : this.genData(e.base)
        ) as any,
        subValuesIdent,
        cellBackground,
        renderLeft,
      };
    } else {
      return null;
    }
    // } else {
    //   const value = selectorFc(cashBudgetSpan);
    //   if (value != null) {
    //     const valueComparison = selectorFc(comparisonCashBudgetSpan);
    //     const diff = (value || 0) - (valueComparison || 0);

    //     return [
    //       {
    //         clickable,
    //         clickData: clickData
    //           ? clickData(comparisonCashBudgetSpan, true)
    //           : undefined,
    //         text: StringUtils.formatCurrency(valueComparison, true),
    //         value: valueComparison || 0,
    //         bold,
    //       },
    //       {
    //         clickable,
    //         clickData: clickData ? clickData(cashBudgetSpan, false) : undefined,
    //         text: StringUtils.formatCurrency(value || 0, true),
    //         value: value || 0,
    //         bold,
    //       },
    //       {
    //         clickable: false,
    //         clickData,
    //         text: StringUtils.formatCurrency(diff, true),
    //         value: diff,
    //         bold,
    //         className: diff > 0 ? "green-positive" : "",
    //       },
    //     ];
    //   } else {
    //     return [null, null, null];
    //   }
    // }
  }
  private addData(base: AddDataprops, props?: Partial<AddDataprops>[]) {
    if (props) {
      (base.newColumnData.values as ValueData[][]).push(
        props.map((prop) => this.genData({ ...base, ...prop }))
      );
    } else {
      (base.newColumnData.values as ValueData[]).push(this.genData(base));
    }
  }

  moveTo(date: Moment, smooth?: boolean) {
    const el = this.cardRef?.current?.querySelector(
      `#ENTRY-${this.props.displayType}-${date.format(
        this.props.displayType === "DAILY" ? "YYYYMMDD" : "YYYYMM"
      )}`
    );
    if (el) {
      el.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: smooth ? "smooth" : "auto",
      });
    }
    // let now = this.jumptoDate.clone();
    // if (now.day() === 0) {
    //   now = now.add(1, "day");
    // }
    // if (now.day() === 6) {
    //   now = now.subtract(1, "day");
    // }
    // const el = this.refScrollableDiv
    //   .getRef()
    //   .current.querySelector(
    //     `#ENTRY-${this.props.displayType}-${now.format(
    //       this.props.displayType === "DAILY" ? "YYYYMMDD" : "YYYYMM"
    //     )}`
    //   );
    // if (el) {
    //   el.scrollIntoView({
    //     block: "center",
    //     inline: "center",
    //     behavior: smooth ? "smooth" : "auto",
    //   });
    // } else {
    //   this.refScrollableDiv.getRef().current.scrollTo(40, 0);
    // }
    // this.jumptoDate = null;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this._mounted = false;
  }

  componentDidUpdate(prevProps: Props, prevState: States, snapshot) {
    const { comparison } = this.props;

    if (this.state.viewType !== prevState.viewType) {
      this.evaluateRowData();
      this.reloadData();
    }

    if (
      prevProps.viewportHeight !== this.props.viewportHeight ||
      prevState.rowConfig !== this.state.rowConfig
    ) {
      this.updateHeight();
    }
    //check for filter changes
    if (
      prevProps.selectedEntities !== this.props.selectedEntities ||
      prevProps.selectedFilter !== this.props.selectedFilter ||
      prevState.selectedMonth !== this.state.selectedMonth ||
      prevState.selectedYear !== this.state.selectedYear
    ) {
      this.updateEntries();
    }

    if (
      prevState.selectedHash !== this.state.selectedHash ||
      (prevProps.cachedData &&
        this.props.cachedData &&
        (comparison
          ? prevProps.cachedComparisonData && this.props.cachedComparisonData
          : true) &&
        (prevProps.cachedData[prevState.selectedHash] !==
          this.props.cachedData[this.state.selectedHash] ||
          (comparison
            ? prevProps.cachedComparisonData[prevState.selectedHash] !==
              this.props.cachedComparisonData[this.state.selectedHash]
            : false)))
    ) {
      if (
        !this.props.cachedData[this.state.selectedHash] ||
        (comparison
          ? !this.props.cachedComparisonData[this.state.selectedHash]
          : false)
      ) {
        this.updateEntries();
      } else if (
        this.props.cachedData[this.state.selectedHash].state === "success" &&
        (comparison
          ? this.props.cachedComparisonData[this.state.selectedHash].state ===
            "success"
          : true)
      ) {
        const cashBudgetSpans = this.convertDataToSpan(false);
        const cashBudgetComparisonSpans = comparison
          ? this.convertDataToSpan(true)
          : null;

        let activeSpan = null;
        if (this.state.activeSpan) {
          const spansToSearch =
            this.state.activeSpan.spanType === "comparisonShould"
              ? cashBudgetComparisonSpans
              : cashBudgetSpans;
          const foundSpan = spansToSearch.find(
            (e) =>
              e.dateFrom.isSame(this.state.activeSpan.span.dateFrom) &&
              e.dateTo.isSame(this.state.activeSpan.span.dateTo)
          );
          if (foundSpan) {
            activeSpan = {
              ...this.state.activeSpan,
              span: {
                ...foundSpan,
              },
            };
          }
        }

        this.evaluateData(cashBudgetSpans, cashBudgetComparisonSpans);
        this.setState(
          {
            entries: cashBudgetSpans,
            entriesComparison: cashBudgetComparisonSpans,
            activeSpan: activeSpan,
          },
          () => {
            setTimeout(() => {
              // id={`ENTRY-${this.props.displayType}-${cashBudgetSpan.dateFrom.format(this.props.displayType === "DAILY" ? "YYYYMMDD" : "YYYYMM")}`}
              if (this._mounted && this.jumptoDate) {
                // this.moveTo();
              }
            });
          }
        );

        //update selected span
      }
    }
  }
  reloadData() {
    this.evaluateData(this.state.entries, this.state.entriesComparison);
  }

  scrollTo() {}

  shouldComponentUpdate(nextProps: Props, nextState: States) {
    return super.shouldComponentUpdate(nextProps, nextState);
  }

  render() {
    const { comparison } = this.props;

    const TransMap = Trans;

    return (
      <div className={`tatar-cash-budget-table-view-root`}>
        {this.props.hideHeader !== true && (
          <div className={`filter-header`}>
            <CashBudgetFilterHeader
              requestType={this.props.requestType}
              useForComparison={comparison === true}
              // ignoreAccounts={comparison === true}
            />
            <div className={`fill`} />

            <div className="actions">
              <BFDateDeprecated
                format={
                  this.props.displayType === "DAILY" ? "yyyy-MM-DD" : "yyyy-MM"
                }
                // oneTap={true}
                oneTap={true}
                appearance="subtle"
                placeholder={i18n.t("cb:Label.GoToDate")} //"Springe zu Datum"
                style={{ width: 170 }}
                placement="bottomEnd"
                disabledDate={(date) =>
                  moment(date).isBefore(CashBudgetUtils.getEarliestStartDate())
                }
                value={null}
                onChange={(date) => {
                  const jumptoDate = moment(date);
                  if (
                    (this.props.displayType === "DAILY" &&
                      jumptoDate.month() === this.state.selectedMonth &&
                      jumptoDate.year() === this.state.selectedYear) ||
                    (this.props.displayType === "MONTHLY" &&
                      jumptoDate.year() === this.state.selectedYear)
                  ) {
                    this.moveTo(jumptoDate, true);
                  } else {
                    this.setState({
                      selectedMonth: jumptoDate.month(),
                      selectedYear: jumptoDate.year(),
                      nextFocusDate: jumptoDate,
                    });
                  }
                }}
              />
              {/* <BFButton
                appearance="primary"
                icon={{
                  type: "bf",
                  data: "file-csv-1",
                }}
                text={<span>{i18n.t("Label.ExportCSV")}</span>}
                onClick={() => {
                  CashBudgetService.generateCSVExport(
                    `${this.props.comparison ? "SOLL-IST_" : ""}${
                      this.props.displayType === "DAILY"
                        ? `${this.state.selectedMonth}-${this.state.selectedYear}`
                        : this.state.selectedYear
                    }_${
                      this.props.selectedEntities.length > 1
                        ? "all"
                        : this.props.selectedEntities[0]
                    }_${this.props.selectedFilter.replace("#", "_")}.csv`,
                    this.props.displayType,
                    this.state.entries,
                    this.state.entriesComparison,
                    this.props.configuration
                  );
                }}
              /> */}
            </div>
          </div>
        )}

        {this.props.comparison && (
          //
          // i18n.t("cb:Label.Comparison.Soll")
          // i18n.t("cb:Label.Comparison.Ist")
          // i18n.t("cb:Label.Comparison.Differenz")
          //
          <div className={`mobile-comparison-filter`}>
            <ButtonGroup className={`filter-type`}>
              {COMPARISON_FILTERS.map((filter, index) => (
                <BFButton
                  onClick={() =>
                    this.setState({ mobileComparisonType: filter })
                  }
                  className={`${
                    this.state.mobileComparisonType === filter ? "active" : ""
                  }`}
                >
                  <TransMap
                    i18nKey={`cb:Label.Comparison.${filter}`}
                  ></TransMap>
                </BFButton>
              ))}
            </ButtonGroup>
          </div>
        )}
        <div className={`content-card`} ref={this.cardRef}>
          <CashBudgetTable
            initialCollapsed={
              this.props.collapseInitial ? this.state.groupIds : null
            }
            headContent={
              <div className={`view-type-selection`}>
                <BFDropdown
                  label={labelOfViewType(this.state.viewType)}
                  items={[
                    {
                      type: "button",
                      text: labelOfViewType("BOOKED"),
                      onSelect: () => this.setState({ viewType: "BOOKED" }),
                    },
                    {
                      type: "button",
                      text: labelOfViewType("BUDGET"),
                      onSelect: () => this.setState({ viewType: "BUDGET" }),
                    },
                  ]}
                />
              </div>
            }
            viewportWidth={this.props.viewportWidth}
            onSelectionChange={(selected) =>
              this.setState({
                selectedData: {
                  max:
                    selected && selected.length > 0
                      ? selected.reduce(
                          (prev, current) => (current > prev ? current : prev),
                          selected[0]
                        )
                      : null,
                  min:
                    selected && selected.length > 0
                      ? selected.reduce(
                          (prev, current) => (current < prev ? current : prev),
                          selected[0]
                        )
                      : null,
                  count: selected.length,
                  avg: selected
                    ? selected.reduce((prev, current) => prev + current, 0) /
                      selected.length
                    : null,
                  sum: selected
                    ? selected.reduce((prev, current) => prev + current, 0)
                    : null,
                },
              })
            }
            activeMobileComparisonType={COMPARISON_FILTERS.indexOf(
              this.state.mobileComparisonType
            )}
            loading={
              this.props.cachedData?.[this.state.selectedHash]?.state ===
              "loading"
            }
            onCellDblClick={(index, itemData) => {
              // if (disableClick) {
              //       return;
              //     }
              //     if (this.state.scrollState === "stop") {
              //       this.setState({
              //         categoryDrawerOpen: true,
              //         activeSpan: {
              //           spanType: comparison
              //             ? isShouldColumn
              //               ? "comparisonShould"
              //               : "comparisonCurrent"
              //             : "normal",
              //           span: { ...cashBudgetSpan },
              //           category: category,
              //           type: type,
              //         },
              //       });
              //     }

              DataBus.emit(DataBusSubKeys.CLEAR_TABLE, {
                identifiers: [CASHBUDGET_CATEGORY_DETAIL_TABLE],
              });
              this.setState({
                categoryDrawerOpen: true,
                activeSpan: itemData,
              });
            }}
            rowHeight={this.state.rowHeight}
            onSelectedDateChange={(newDate) => {
              this.setState({
                selectedMonth: newDate.month,
                selectedYear: newDate.year,
                nextFocusDate: newDate.focus,
              });
            }}
            tableDropdownFirst={
              this.props.displayType === "DAILY"
                ? {
                    selectedText: moment()
                      .month(this.state.selectedMonth)
                      .format("MMM"),
                    values: Array.from({ length: 12 }).map((_val, index) => ({
                      text: moment().month(index).format("MMM"),
                      value: { month: index, year: this.state.selectedYear },
                    })),
                  }
                : undefined
            }
            // ÷}}
            tableDropdownSecond={{
              selectedText: this.state.selectedYear.toString(),
              values: CashBudgetService.getAvailableYears().map((val) => ({
                text: val,
                value: { month: 0, year: val },
              })),
            }}
            tableLeftButton={{
              disabled: moment()
                .year(this.state.selectedYear)
                .month(this.state.selectedMonth)
                .subtract(
                  1,
                  this.props.displayType === "DAILY" ? "month" : "year"
                )
                .startOf(this.props.displayType === "DAILY" ? "month" : "year")
                .isBefore(CashBudgetUtils.getEarliestStartDate()),
              text: moment()
                .year(this.state.selectedYear)
                .month(this.state.selectedMonth)
                .subtract(
                  1,
                  this.props.displayType === "DAILY" ? "month" : "year"
                )
                .endOf(this.props.displayType === "DAILY" ? "month" : "year")
                .format(
                  this.props.displayType === "DAILY" ? "MMM YYYY" : "YYYY"
                ),
              value: {
                year: moment()
                  .year(this.state.selectedYear)
                  .month(this.state.selectedMonth)
                  .subtract(
                    1,
                    this.props.displayType === "DAILY" ? "month" : "year"
                  )
                  .endOf(this.props.displayType === "DAILY" ? "month" : "year")
                  .year(),
                month: moment()
                  .year(this.state.selectedYear)
                  .month(this.state.selectedMonth)
                  .subtract(
                    1,
                    this.props.displayType === "DAILY" ? "month" : "year"
                  )
                  .endOf(this.props.displayType === "DAILY" ? "month" : "year")
                  .month(),

                focus: moment()
                  .year(this.state.selectedYear)
                  .month(this.state.selectedMonth)
                  .subtract(
                    1,
                    this.props.displayType === "DAILY" ? "month" : "year"
                  )
                  .endOf(this.props.displayType === "DAILY" ? "month" : "year"),
              },
            }}
            tableRightButton={{
              disabled: false,
              text: moment()
                .year(this.state.selectedYear)
                .month(this.state.selectedMonth)
                .add(1, this.props.displayType === "DAILY" ? "month" : "year")
                .startOf(this.props.displayType === "DAILY" ? "month" : "year")
                .format(
                  this.props.displayType === "DAILY" ? "MMM YYYY" : "YYYY"
                ),
              value: {
                year: moment()
                  .year(this.state.selectedYear)
                  .month(this.state.selectedMonth)
                  .add(1, this.props.displayType === "DAILY" ? "month" : "year")
                  .startOf(
                    this.props.displayType === "DAILY" ? "month" : "year"
                  )
                  .year(),
                month: moment()
                  .year(this.state.selectedYear)
                  .month(this.state.selectedMonth)
                  .add(1, this.props.displayType === "DAILY" ? "month" : "year")
                  .startOf(
                    this.props.displayType === "DAILY" ? "month" : "year"
                  )
                  .month(),
                focus: moment()
                  .year(this.state.selectedYear)
                  .month(this.state.selectedMonth)
                  .add(1, this.props.displayType === "DAILY" ? "month" : "year")
                  .startOf(
                    this.props.displayType === "DAILY" ? "month" : "year"
                  ),
              },
            }}
            rowConfiguration={this.state.rowConfig}
            data={this.state.data}
          />
        </div>
        {!this.props.comparison && (
          <div className={`status-bar`}>
            <div className="fill" />
            <div className="entry">
              <div className="label">{i18n.t("cb:LQ.Footer.count")}</div>
              <div className="value">
                {this.state.selectedData?.count || "0"}
              </div>
            </div>
            <div className="entry">
              <div className="label">{i18n.t("cb:LQ.Footer.min")}</div>
              <div className="value">
                {StringUtils.formatCurrency(this.state.selectedData?.min, true)}
              </div>
            </div>
            <div className="entry">
              <div className="label">{i18n.t("cb:LQ.Footer.max")}</div>
              <div className="value">
                {StringUtils.formatCurrency(this.state.selectedData?.max, true)}
              </div>
            </div>
            <div className="entry">
              <div className="label">
                {/* Ø */}
                {i18n.t("cb:LQ.Footer.avg")}
              </div>
              <div className="value">
                {StringUtils.formatCurrency(this.state.selectedData?.avg, true)}
              </div>
            </div>
            <div className="entry">
              <div className="label">
                {/* Σ */}
                {i18n.t("cb:LQ.Footer.sum")}
              </div>
              <div className="value">
                {StringUtils.formatCurrency(this.state.selectedData?.sum, true)}
              </div>
            </div>
          </div>
        )}
        <CashBudgetCategoryDrawer
          amount={this.calcSpanValue(
            "displayValue",
            this.state.activeSpan ? this.state.activeSpan?.category : null,
            this.state.activeSpan?.span,
            CashBudgetUtils.getAllCategories(),
            this.state.activeSpan?.ignoreChildren
          )}
          requestType={this.props.requestType}
          spanType={
            this.state.activeSpan ? this.state.activeSpan.spanType : null
          }
          filteredObject={this.props.selectedFilter}
          open={this.state.categoryDrawerOpen}
          onClose={() => this.setState({ categoryDrawerOpen: false })}
          category={
            this.state.activeSpan ? this.state.activeSpan.category : null
          }
          ignoreCategoryChildren={this.state.activeSpan?.ignoreChildren}
          displayType={this.props.displayType}
          filteredEntities={this.props.selectedEntities}
          span={this.state.activeSpan ? this.state.activeSpan.span : null}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => {
  return {
    debugMode: state.uiConfig.general[DefaultUIConfigs.DEBUG],
    viewportHeight: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_HEIGHT],
    viewportWidth: state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH],
    appTypes: state.uiConfig.activeApplication?.constants?.businessUnits || [],
    cachedData: state.application.cache.flex[CASH_BUDGET_DATA_PREFIX],
    cachedComparisonData: state.application.cache.flex[CASH_BUDGET_PLAN_PREFIX],
  };
};

export default withTranslation()(
  connect(mapStateToProps, { setFlexCacheData, setFlexCacheDataMultiple })(
    TatarCashBudgetTableComponent
  )
);

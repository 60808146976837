import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import FormStruct from "@/components/Form/FormStruct/FormStruct";
import FormValidators, {
  FV,
} from "@/components/Form/Validation/FormValidators";
import MultiplePDFViewer from "@/components/PDFViewer/MultiplePDFViewer";
import { PDFEditRect } from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import ContactSelect from "@/modules/contacts-module/ContactSelect";
import ContactUtils from "@/modules/contacts-module/ContactUtils";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import FileUtils from "@/utils/FileUtils";
import classNames from "classnames";
import { useState } from "react";
import { Field } from "react-final-form";
import { ContractorType } from "../agreement-parts/CBAgreementContractor";
import { TenantType } from "../agreement-parts/CBAgreementTenant";
import CBRentalAgreementContactForm from "../form-components/CBRentalAgreementContactForm";
import { CBRentalAgreementFormWizardStep1Value } from "./CBRentalAgreementFormWizardStep1UploadContract";
// import "./CBRentalAgreementFormWizardStep2ContractPartners.scss";

export type CBRentalAgreementFormWizardStep2Value = ContractorType &
  TenantType & {
    marks?: {
      entity?: PDFEditRect[];
      tenant?: PDFEditRect[];
    };
  };
interface CBRentalAgreementFormWizardStep2ContractPartnersProps {
  kind: ObjectKind;
  data: {
    step1: CBRentalAgreementFormWizardStep1Value;
  };
  initialValues?: CBRentalAgreementFormWizardStep2Value;
  onValueChange: (index: number, value: any) => void;
  onBack: () => void;
  onSuccess: (values: CBRentalAgreementFormWizardStep2Value) => Promise<void>;
  onAbort: () => void;
}
const CBRentalAgreementFormWizardStep2ContractPartners = (
  props: CBRentalAgreementFormWizardStep2ContractPartnersProps
) => {
  const [fileUrl, setFileUrl] = useState(
    FileUtils.fileToObjectUrl(props.data?.step1?.rentalAgreement?.[0]?.file)
  );
  return (
    <FormStruct<any>
      title={i18n.t(
        "cb:RentalAgreement.Form.Step2.title",
        "Vertragspartner auswählen"
      )}
      description={i18n.t(
        "cb:RentalAgreement.Form.Step2.description",
        "Wählen Sie die Vertragspartner aus, die mit dem Mietvertrag verbunden sind."
      )}
      onSubmit={async (values) => {
        props.onSuccess(values);
      }}
      submitText={i18n.t("Global.Buttons.continue")}
      onAbort={(formProps) => {
        props.onValueChange(1, formProps.values);
        props.onBack();
      }}
      initialValues={props.initialValues}
      cancelText={i18n.t("Global.Buttons.back")}
      className={classNames(
        `cb-rental-agreement-form-wizard-step2-contract-partners`
      )}
      additionalActions={
        <BFButton onClick={props.onAbort} appearance="outline">
          {i18n.t("Global.Buttons.cancel")}
        </BFButton>
      }
      renderRight={(form) => (
        <div
          className={`pdf-viewer`}
          style={{
            width: "40vw",
            maxWidth: 1000,
            padding: 10,
          }}
        >
          <MultiplePDFViewer
            identifier="rental-agreement-pdf"
            height={"78vh"}
            label={{
              single: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreement",
                "Mietvertrag"
              ),
              multiple: i18n.t(
                "cb:RentalAgreement.Form.Sections.agreements",
                "Mietverträge"
              ),
            }}
            pdfs={props.data.step1?.rentalAgreement.map((e) => ({
              type: "file",
              file: e.file,
            }))}
          />
          {/* <PDFViewer
            identifier="rental-agreement-pdf"
            border
            filename={props.data.step1?.rentalAgreement?.[0]?.file?.name}
            height={"80vh"}
            url={fileUrl}
          /> */}
        </div>
      )}
      render={(form) => (
        <div>
          {/* <BFFormSection
            initialOpen
            disableCollapse
            marginBottom={20}
            title={i18n.t(
              "cb:RentalAgreement.Form.Sections.contractor.title",
              "Vertragsgesellschaft"
            )}
          > */}
          <div>
            <Field name="marks.entity">
              {({ input, meta }) => (
                <BFPDFMarkerSection
                  marginBottom={20}
                  title={i18n.t(
                    "cb:RentalAgreement.Form.Sections.contractor.title",
                    "Vertragsgesellschaft"
                  )}
                  {...input}
                  {...FV.getValidation(meta)}
                  editHint={i18n.t(
                    "cb:RentalAgreement.Form.Fields.contractor.editHint",
                    "Markieren Sie die Stellen, wo Sie die Informationen zur Vertragsgesellschaft finden."
                  )}
                  viewHint={i18n.t(
                    "cb:RentalAgreement.Form.Fields.contractor.viewHint",
                    "Informationen zur Vertragsgesellschaft"
                  )}
                  identifier="contractor"
                  pdfViewerIdentifier="rental-agreement-pdf"
                  value={input.value}
                  onChange={input.onChange}
                >
                  <>
                    <div className={`__field`}>
                      <Field
                        name="entity"
                        validate={FormValidators.compose(
                          FormValidators.required()
                        )}
                      >
                        {({ input, meta }) => (
                          <BFSelect
                            {...input}
                            label={`${i18n.t(
                              "cb:RentalAgreement.Form.Fields.entity",
                              "Gesellschaft"
                            )}*`}
                            {...FV.getValidation(meta)}
                            data={OrgaStruct.getEntitySelectOptions(
                              props.kind.data.type
                            )}
                            onChange={(value) => {
                              input.onChange(value);
                              form.form.mutators.setValue("objectId", null);
                              // todo change to update form result of wizard step 3
                              form.form.mutators.setValue("rentalUnits", []);
                            }}
                          />
                        )}
                      </Field>
                    </div>

                    <div className={`__field`}>
                      <FormFieldValues names={["entity"]}>
                        {([entity]) => (
                          <Field
                            name="objectId"
                            validate={FormValidators.compose(
                              FormValidators.required()
                            )}
                          >
                            {({ input, meta }) => (
                              <BFSelect
                                {...input}
                                disabled={!entity}
                                label={`${i18n.t(
                                  "cb:RentalAgreement.Form.Fields.objectId",
                                  "Objekt"
                                )}*`}
                                {...FV.getValidation(meta)}
                                data={
                                  entity
                                    ? OrgaStruct.getObjectSelectOptions(
                                        entity,
                                        undefined,
                                        true
                                      )
                                    : []
                                }
                                onChange={(value) => {
                                  input.onChange(value);
                                  // todo change to update form result of wizard step 3
                                  form.form.mutators.setValue(
                                    "rentalUnits",
                                    []
                                  );
                                }}
                              />
                            )}
                          </Field>
                        )}
                      </FormFieldValues>
                    </div>
                  </>
                </BFPDFMarkerSection>
              )}
            </Field>
          </div>
          <div>
            <FormFieldValues names={["objectId"]}>
              {([objectId]) => (
                <Field name="marks.tenant">
                  {({ input, meta }) => (
                    <BFPDFMarkerSection
                      title={i18n.t(
                        "cb:RentalAgreement.Form.Sections.tenant.title",
                        "Vertragspartner"
                      )}
                      {...input}
                      editHint={i18n.t(
                        "cb:RentalAgreement.Form.Fields.tenant.editHint",
                        "Markieren Sie die Stellen, wo Sie die Informationen zum Mieter finden."
                      )}
                      viewHint={i18n.t(
                        "cb:RentalAgreement.Form.Fields.tenant.viewHint",
                        "Informationen zu Mieter"
                      )}
                      identifier="tenant"
                      pdfViewerIdentifier="rental-agreement-pdf"
                      value={input.value}
                      onChange={input.onChange}
                    >
                      <>
                        <Field
                          name="tenant"
                          validate={FormValidators.required()}
                        >
                          {({ input, meta }) => {
                            const renderContactSelect = (object?: OAObject) => {
                              return (
                                <>
                                  <div className={`__field`}>
                                    <ContactSelect
                                      creationModalHelpComponent={{
                                        type: "pdfs",
                                        pdfs: props.data.step1?.rentalAgreement.map(
                                          (e) => ({
                                            type: "file",
                                            file: e.file,
                                          })
                                        ),
                                      }}
                                      businessUnits={[props.kind.data.type]}
                                      contactTypes={["TENANT"]}
                                      onChange={(value, contact) => {
                                        setTimeout(() => {
                                          input.onChange(value);
                                        });
                                        if (value !== input.value) {
                                          form.form.mutators.setValue(
                                            "contactOption",
                                            {
                                              addressId:
                                                contact?.data.address?.find(
                                                  (e) => e.isMain
                                                )?.id,
                                            }
                                          );
                                        }
                                      }}
                                      value={input.value}
                                      {...FormValidators.getValidation(meta)}
                                      placeholder={i18n.t(
                                        "cb:RentalAgreement.Form.Fields.tenantPlaceholder",
                                        "Vertragspartner auswählen"
                                      )}
                                      addText={i18n.t(
                                        "cb:RentalAgreement.Form.Fields.createTenant",
                                        "Vertragspartner erstellen"
                                      )}
                                      initialCreateObj={
                                        {
                                          data: {
                                            // ...initialData,
                                            type: props.kind.data.type,
                                            contactType: ["TENANT"],
                                            address: object
                                              ? object.data.feature.address?.address?.map(
                                                  (e) =>
                                                    ContactUtils.mapObjectAdressToContactAdress(
                                                      e
                                                    )
                                                ) || []
                                              : [],
                                          },
                                        } as any
                                      }
                                      {...FormValidators.getValidation(meta)}
                                    />
                                  </div>
                                  {input.value && (
                                    <div>
                                      <CBRentalAgreementContactForm
                                        contactId={input.value}
                                      />
                                    </div>
                                  )}
                                </>
                              );
                            };

                            return objectId ? (
                              <AssetLoader
                                assetType={AssetTypes.Portfolio.Object}
                                id={objectId}
                                render={(object: OAObject) =>
                                  renderContactSelect(object)
                                }
                              />
                            ) : (
                              renderContactSelect()
                            );
                          }}
                        </Field>
                      </>
                    </BFPDFMarkerSection>
                  )}
                </Field>
              )}
            </FormFieldValues>
          </div>
        </div>
      )}
    />
  );
};

export default CBRentalAgreementFormWizardStep2ContractPartners;

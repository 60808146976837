import _ from "lodash";
import moment from "moment";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import i18n from "../../../../../../i18n";
import PlanTableCreate from "../../../../../../modules/plan-table/PlanTableCreate";
import { hasValue } from "../../../../../../utils/Helpers";
import ObjectIdService from "../../../../../../utils/ObjectIdUtils";
import { useActivityConstants } from "../../../ActivityHooks";
import { APActivity } from "../../../ActivityInterfaces";
import ActivityService from "../../../ActivityService";
import {
  TEMPLATE_TYPE_BUDGET_GROUP,
  TEMPLATE_TYPE_BUDGET_WHOLE_BUDGET,
} from "./APBugetUtils";
import "./APCreateProjectBudgetForm.scss";
import { APProjectBudget } from "./APProjectBudgetInterfaces";

interface APCreateBudgetFormProps {
  onClose: () => void;
  activity: APActivity;
  onSubmit: () => void;
}
const APCreateProjectBudgetForm = (props: APCreateBudgetFormProps) => {
  const constants = useActivityConstants();
  const hasCurrentBudget = hasValue(props.activity.data.currentActiveBudgetId);
  // if hasCurrentBudget -> load before form rendering to prevent position deletion & date corruption

  const renderForm = (budget: APProjectBudget) => {
    const disabledEntriesDeletion =
      budget?.data.groups
        .map((e) => [...e.children, e])
        .flat()
        .map((e) => e.id) || [];
    const datesWithValues = budget?.data.entries
      .filter((e) => e.values.filter((a) => a.value != 0).length > 0)
      .map((e) => e.date);
    const minDate = _.min(datesWithValues);
    const maxDate = _.max(datesWithValues);
    return (
      <PlanTableCreate
        templateData={{
          templateType: TEMPLATE_TYPE_BUDGET_GROUP,
          type: props.activity.data.type,
        }}
        creationTemplateData={{
          templateType: TEMPLATE_TYPE_BUDGET_WHOLE_BUDGET,
          type: props.activity.data.type,
        }}
        onClose={() => props.onClose()}
        onSubmit={async (values) => {
          const start = moment(values.from).utc(true);
          const end = moment(values.to).utc(true);
          const entriesToSave =
            budget?.data.entries.filter(
              (e) =>
                moment(e.date).utc(true).isBetween(start, end) ||
                moment(e.date).utc(true).isSame(start, "month") ||
                moment(e.date).utc(true).isSame(end, "month")
            ) || [];

          await ActivityService.saveProjectBudgetDraft(
            constants.serviceUrl,
            props.activity._id,
            entriesToSave,
            values.groups,
            values.from,
            values.to,
            budget
              ? budget.data.scheduleGroups
              : [
                  {
                    id: ObjectIdService.new(),
                    fromDate: values.from,
                    toDate: values.to,
                    name: i18n.t(
                      "apTemplate:Activity.Budget.ProjectFirstScheduledTask",
                      "Projekt"
                    ),
                  },
                ]
          );
          props.onSubmit?.();
          props.onClose();
        }}
        from={budget?.data.fromDate}
        to={budget?.data.toDate}
        warnMaxDate={maxDate}
        warnMinDate={minDate}
        disableEntriesDeletion={disabledEntriesDeletion}
        groups={budget?.data.groups || []}
      />
    );
  };
  if (hasCurrentBudget) {
    return (
      <AssetLoader
        assetType={constants.fields?.projectBudgetFeature?.budgetAssetType}
        id={props.activity.data.currentActiveBudgetId}
        render={(budget: APProjectBudget) => renderForm(budget)}
      />
    );
  } else {
    return renderForm(null);
  }
};

export default APCreateProjectBudgetForm;

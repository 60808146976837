import BFMessage from "@/modules/abstract-ui/general/Message/BFMessage";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import moment from "moment";
import { useState } from "react";
import Collapse from "rsuite/esm/Animation/Collapse";
import { PDFHighlight } from "../../../../components/PDFViewer/PDFViewer";
import StructLoader from "../../../../components/StructLoader/StructLoader";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import { Contact } from "../../../../model/db/Contact";
import BFFormSection from "../../../../modules/abstract-ui/data/form-section/BFFormSection";
import { LinkCell } from "../../../../modules/abstract-ui/data/table/TableUtils";
import BFButton from "../../../../modules/abstract-ui/general/Button/BFButton";
import BFUseTooltip from "../../../../modules/abstract-ui/general/tooltip/BFUseTooltip";
import BFOverlay from "../../../../modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "../../../../modules/abstract-ui/icon/BfIcon";
import { ActivitiesLabel } from "../../../../modules/activity-components/ActivityLabel/ActivityLabel";
import ContactLabel from "../../../../modules/contacts-module/ContactLabel";
import InvoiceStruct from "../../../../redux/actions/struct/implemented/InvoiceStruct";
import UnitStruct from "../../../../redux/actions/struct/implemented/UnitStruct";
import { useConstants } from "../../../../redux/hooks";
import LanguageService from "../../../../services/LanguageService";
import StringUtils from "../../../../utils/StringUtils";
import { InvoicePaymentTypeTags } from "../../../AppConfigInterfaces";
import {
  ExtractValue,
  RAConstants,
  RAIncomingInvoice,
  RAInvoice,
} from "../RAInterfaces";
import {
  RA_INVOICE_ADDITIONAL_INFOFIELDS,
  RA_INVOICE_MAIN_FIELDS,
  getInvoiceTypeLabel,
  getPaymentTypeLabel,
} from "./../RAUtils";
import RAInvoiceDirectionIndicator from "./InvoiceForm/RAInvoiceDirectionIndicator";
import RAInvoiceProcessHistory from "./InvoiceProcessHistory/RAInvoiceProcessHistory";
import InvoicePaymentPlan from "./ListInvoices/InvoicePaymentPlan";
import RAInvoiceEntryDependency from "./RAInvoiceEntryDependency";
import "./RAInvoiceEntryView.scss";
import RACostCenterView from "./UpdateInvoice/RACostCenterView";
import RARelationsView from "./UpdateInvoice/RARelationsView";
import { RAInvoiceChangeTags } from "./UpdateInvoice/RAUpdateInvoiceEntry";
import { UrgentBubble } from "./UrgentIndidactor/UrgentIndicator";

interface Props {
  invoice: RAInvoice;
  onHighlight?: (highlight?: PDFHighlight) => void;
  editable?: boolean;
  isSelected?: boolean;
  displayBeneath?: boolean;
  maxHistoryHeight?: number;
  ignoreHistory?: boolean;
  simpleView?: boolean;
  ignoreMoreInfos?: boolean;
  hideCostCenter?: boolean;
  hideRelations?: boolean;
  hideTags?: boolean;
  showBusinessUnit?: boolean;
  hideInfoFields?: boolean;
  showInvoiceDate?: boolean;
  showInvoiceIdHeader?: boolean;
  disableEdit?: boolean;
  showDependenices?: boolean;
  modalLinkIdentifier?: string;
  showInvoiceId?: boolean;
}
const RAInvoiceEntryView = (props: Props) => {
  const [open, setOpen] = useState(false);
  const constants = useConstants<RAConstants>();
  if (!props.invoice) {
    return (
      <div>
        <BFMessage
          type="warning"
          text={i18n.t(
            "ra:ApproveList.Entry.noPermissionToViewInvoice",
            "Sie haben keine Berechtigung diese Rechnung zu sehen."
          )}
        />
      </div>
    );
  }
  const moreContent = (
    <div className={`more-content`}>
      <div className="other-main-fields">
        <div className="invoiceId">
          <RAInvoiceViewField
            identifier={RA_INVOICE_MAIN_FIELDS().invoiceId.identifier}
            editable={props.editable}
            onHighlight={props.onHighlight}
            isSelected={props.isSelected}
            showLabel
            label={RA_INVOICE_MAIN_FIELDS().invoiceId.label}
            value={props.invoice?.data.invoice?.invoiceId}
            // extractValue={props.invoice?.data.invoice.fields?.invoiceId} // Todo Invoice - fix extract values
          />
        </div>

        <div className="invoiceType">
          <RAInvoiceViewField
            identifier={RA_INVOICE_MAIN_FIELDS().invoiceType.identifier}
            editable={props.editable}
            onHighlight={props.onHighlight}
            isSelected={props.isSelected}
            showLabel
            label={RA_INVOICE_MAIN_FIELDS().invoiceType.label}
            value={props.invoice?.data.invoice.invoiceType}
            // extractValue={props.invoice?.data.invoice.fields?.paymentType} // Todo Invoice - fix extract values
            formatter={(value: string) => getInvoiceTypeLabel(value)}
          />
        </div>

        {props.invoice?.data?.direction === "INCOMING" && (
          <div className="paymentType">
            <RAInvoiceViewField
              identifier={RA_INVOICE_MAIN_FIELDS().paymentType.identifier}
              editable={props.editable}
              onHighlight={props.onHighlight}
              isSelected={props.isSelected}
              showLabel
              label={RA_INVOICE_MAIN_FIELDS().paymentType.label}
              value={props.invoice?.data.invoice.paymentType}
              // extractValue={props.invoice?.data.invoice.fields?.paymentType} // Todo Invoice - fix extract values
              formatter={(value: string) => getPaymentTypeLabel(value)}
            />
          </div>
        )}

        <div className="documentDate ">
          <RAInvoiceViewField
            identifier={RA_INVOICE_MAIN_FIELDS().documentDate.identifier}
            editable={props.editable}
            onHighlight={props.onHighlight}
            isSelected={props.isSelected}
            showLabel
            label={RA_INVOICE_MAIN_FIELDS().documentDate.label}
            value={props.invoice?.data.invoice?.documentDate}
            // extractValue={props.invoice?.data.invoice?.fields?.documentDate} // Todo Invoice - fix extract values
            formatter={(value) =>
              moment(value).format(i18n.t("Formats.dateFormat"))
            }
          />
        </div>

        {props.invoice.data.payment.paymentPlan?.length > 0 && (
          <div className="paymentPlan ">
            <RAInvoiceViewField
              identifier={"paymentPlan"}
              editable={false}
              onHighlight={props.onHighlight}
              isSelected={props.isSelected}
              showLabel
              label={i18n.t("ra:Invoice.paymentPlan", "Zahlungsplan")}
              value={props.invoice?.data.payment?.paymentPlan}
              // extractValue={props.invoice?.data.invoice?.fields?.documentDate} // Todo Invoice - fix extract values
              formatter={(value) =>
                i18n.t("ra:Invoice.countPayments", "{{count}} Zahlungen", {
                  count: value.length,
                })
              }
              suffix={
                <BFUseTooltip
                  delay={0}
                  trigger="hover"
                  tooltip={
                    <div className={`ra-invoice-entry-suffix-payments`}>
                      <div>
                        {props.invoice.data.payment.paymentPlan.map(
                          (payment) => (
                            <div className={`payment-entry`}>
                              <div className={`payment-entry-date`}>
                                {StringUtils.formatDate(payment.date)}
                              </div>
                              <div className={`payment-entry-amount`}>
                                {StringUtils.formatCurrency(
                                  payment.amount,
                                  undefined,
                                  undefined,
                                  props.invoice.data.invoice.value.currency
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  }
                >
                  <div className={`ra-invoice-entry-amount-suffix`}>
                    <BfIcon type="light" data="information-circle" size="xxs" />
                  </div>
                </BFUseTooltip>
              }
            />
          </div>
        )}
        {props.invoice?.data?.direction === "OUTGOING" && (
          <>
            {props.invoice?.data.invoice.dueDate && (
              <div className="dueDate">
                <RAInvoiceViewField
                  identifier={
                    RA_INVOICE_MAIN_FIELDS().paymentDueDate.identifier
                  }
                  editable={props.editable}
                  onHighlight={props.onHighlight}
                  isSelected={props.isSelected}
                  showLabel
                  label={RA_INVOICE_MAIN_FIELDS().paymentDueDate.label}
                  value={props.invoice?.data.invoice.dueDate}
                  // extractValue={props.invoice?.data.invoice.paymentDueDate} // Todo Invoice - fix extract values
                  formatter={(value) =>
                    moment(value).format(i18n.t("Formats.dateFormat"))
                  }
                  color={
                    moment().isSameOrAfter(
                      moment(props.invoice?.data.invoice.dueDate)
                    )
                      ? "var(--color-negative)"
                      : undefined
                  }
                />
              </div>
            )}
          </>
        )}
        {props.invoice?.data?.direction === "INCOMING" && (
          <>
            {InvoiceStruct.checkPaymentTypeTag(
              props.invoice?.data.invoice.paymentType,
              InvoicePaymentTypeTags.SHOULD_BE_PAYED
            ) &&
              (props.invoice?.data.invoice.paymentTypeData as any)
                ?.paymentDueDate && (
                <div className="paymentDueDate">
                  <RAInvoiceViewField
                    identifier={
                      RA_INVOICE_MAIN_FIELDS().paymentDueDate.identifier
                    }
                    editable={props.editable}
                    onHighlight={props.onHighlight}
                    isSelected={props.isSelected}
                    showLabel
                    label={RA_INVOICE_MAIN_FIELDS().paymentDueDate.label}
                    value={
                      (props.invoice?.data.invoice.paymentTypeData as any)
                        ?.paymentDueDate
                    }
                    // extractValue={props.invoice?.data.invoice.paymentDueDate} // Todo Invoice - fix extract values
                    formatter={(value) =>
                      moment(value).format(i18n.t("Formats.dateFormat"))
                    }
                    color={
                      moment().isSameOrAfter(
                        moment(
                          (props.invoice?.data.invoice.paymentTypeData as any)
                            ?.paymentDueDate
                        )
                      )
                        ? "var(--color-negative)"
                        : undefined
                    }
                  />
                </div>
              )}
            {(props.invoice?.data.invoice?.paymentTypeData?.type ===
              InvoicePaymentTypeTags.PAYMENT_US_NATIONAL ||
              props.invoice?.data.invoice?.paymentTypeData?.type ===
                InvoicePaymentTypeTags.PAYMENT_US_INTERNATIONAL) && (
              <>
                <div className="accountNumber">
                  <RAInvoiceViewField
                    identifier={RA_INVOICE_MAIN_FIELDS().account.identifier}
                    editable={props.editable}
                    onHighlight={props.onHighlight}
                    isSelected={props.isSelected}
                    showLabel
                    label={RA_INVOICE_MAIN_FIELDS().account.label}
                    value={
                      props.invoice?.data.invoice?.paymentTypeData?.account
                    }
                    // extractValue={props.invoice?.data.invoice.fields?.iban} // Todo Invoice - fix extract values
                  />
                </div>
                <div className="routing">
                  <RAInvoiceViewField
                    identifier={RA_INVOICE_MAIN_FIELDS().routing.identifier}
                    editable={props.editable}
                    onHighlight={props.onHighlight}
                    isSelected={props.isSelected}
                    showLabel
                    label={RA_INVOICE_MAIN_FIELDS().routing.label}
                    value={
                      props.invoice?.data.invoice?.paymentTypeData?.routing
                    }
                    // extractValue={props.invoice?.data.invoice.fields?.bic}
                  />
                </div>
                <div className="swift">
                  <RAInvoiceViewField
                    identifier={RA_INVOICE_MAIN_FIELDS().swift.identifier}
                    editable={props.editable}
                    onHighlight={props.onHighlight}
                    isSelected={props.isSelected}
                    showLabel
                    label={RA_INVOICE_MAIN_FIELDS().swift.label}
                    value={props.invoice?.data.invoice?.paymentTypeData?.swift}
                    // extractValue={props.invoice?.data.invoice.fields?.bic}
                  />
                </div>
                <div className="usage">
                  <RAInvoiceViewField
                    identifier={RA_INVOICE_MAIN_FIELDS().usage.identifier}
                    editable={props.editable}
                    onHighlight={props.onHighlight}
                    isSelected={props.isSelected}
                    showLabel
                    label={RA_INVOICE_MAIN_FIELDS().usage.label}
                    value={props.invoice?.data.invoice.paymentTypeData?.usage}
                    // extractValue={props.invoice?.data.invoice.paymentData?.iban}
                  />
                </div>
              </>
            )}
            {props.invoice?.data.invoice?.paymentTypeData?.type ===
              "PAYMENT_IBAN" && (
              <>
                <div className="iban">
                  <RAInvoiceViewField
                    identifier={RA_INVOICE_MAIN_FIELDS().iban.identifier}
                    editable={props.editable}
                    onHighlight={props.onHighlight}
                    isSelected={props.isSelected}
                    showLabel
                    label={RA_INVOICE_MAIN_FIELDS().iban.label}
                    value={props.invoice?.data.invoice.paymentTypeData?.iban}
                    // extractValue={props.invoice?.data.invoice.paymentData?.iban}
                  />
                </div>
                <div className="bic">
                  <RAInvoiceViewField
                    identifier={RA_INVOICE_MAIN_FIELDS().bic.identifier}
                    editable={props.editable}
                    onHighlight={props.onHighlight}
                    isSelected={props.isSelected}
                    showLabel
                    label={RA_INVOICE_MAIN_FIELDS().bic.label}
                    value={props.invoice?.data.invoice.paymentTypeData?.bic}
                    // extractValue={props.invoice?.data.invoice.paymentData?.iban}
                  />
                </div>
                <div className="usage">
                  <RAInvoiceViewField
                    identifier={RA_INVOICE_MAIN_FIELDS().usage.identifier}
                    editable={props.editable}
                    onHighlight={props.onHighlight}
                    isSelected={props.isSelected}
                    showLabel
                    label={RA_INVOICE_MAIN_FIELDS().usage.label}
                    value={props.invoice?.data.invoice.paymentTypeData?.usage}
                    // extractValue={props.invoice?.data.invoice.paymentData?.iban}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>

      {props.hideInfoFields !== true && (
        <div className="info-fields">
          {(props.invoice?.data?.invoice?.custom || [])
            .filter((entry) => !!entry.value)
            .map((entry) => (
              <div className="info-field" key={entry.identifier}>
                <RAInvoiceViewField
                  identifier={entry.identifier}
                  editable={props.editable}
                  onHighlight={props.onHighlight}
                  isSelected={props.isSelected}
                  showLabel
                  label={
                    RA_INVOICE_ADDITIONAL_INFOFIELDS()[entry.identifier]
                      ?.label || entry.identifier
                  } // Todo Invoice - rework info fields
                  value={entry.value}
                  // extractValue={value} // Todo Invoice - fix extract values
                />
              </div>
            ))}
        </div>
      )}

      {props.hideTags !== true && (
        <RAInvoiceChangeTags asset={props.invoice} readonly />
      )}
      {props.hideCostCenter !== true &&
        props.invoice?.data?.direction === "INCOMING" && (
          <BFFormSection
            title={i18n.t("ra:UpdateInvoice.Section.CostCenters")}
            initialOpen={true}
            marginBottom={15}
            marginTop={15}
          >
            <RACostCenterView
              initialCollapseActive={true}
              disabled={true}
              invoice={props.invoice as RAIncomingInvoice}
            />
          </BFFormSection>
        )}

      {props.hideRelations !== true && (
        <BFFormSection
          title={i18n.t(
            "ra:UpdateInvoice.Section.Relations",
            "Zugeordnete Aktivitäten"
          )}
          initialOpen={true}
          marginBottom={15}
          marginTop={15}
        >
          <RARelationsView
            invoice={props.invoice}
            disableEdit={props.disableEdit}
          />
        </BFFormSection>
      )}
    </div>
  );
  const contact = (
    <div className="contact bigger">
      <ContactLabel
        contact={props.invoice.expand?.contact as Contact}
        contactId={props.invoice.data.invoice.contact}
      />
    </div>
  );
  const entity = (
    <>
      <div className="entity bigger">
        <RAInvoiceViewField
          identifier={RA_INVOICE_MAIN_FIELDS().entity.identifier}
          editable={props.editable}
          onHighlight={props.onHighlight}
          isSelected={props.isSelected}
          label={"Gesellschaft"}
          value={props.invoice?.data.entity}
          formatter={(value) => OrgaStruct.getEntity(value)?.displayName || "-"}
        />
      </div>
      <div className="object smaller">
        <RAInvoiceViewField
          identifier={RA_INVOICE_MAIN_FIELDS().objectId.identifier}
          editable={props.editable}
          onHighlight={props.onHighlight}
          isSelected={props.isSelected}
          label={RA_INVOICE_MAIN_FIELDS().objectId.label}
          value={props.invoice?.data.objectId}
          formatter={(value) =>
            !value
              ? i18n.t(
                  "ra:RAInvoiceEntryView.NotAssignedToObject",
                  "Keinem Objekt zugeordnet"
                )
              : `${OrgaStruct.getObject(value)?.id} - ${
                  OrgaStruct.getObject(value)?.displayName
                }` ||
                i18n.t(
                  "ra:RAInvoiceEntryView.ObjectNotFound",
                  "Objekt nicht gefunden"
                )
          }
        />
      </div>

      {props.invoice?.data.activity && (
        <div className="object smaller linked-activitis">
          <ActivitiesLabel withType activities={props.invoice?.data.activity} />
        </div>
      )}
    </>
  );
  return (
    <StructLoader
      structTypes={["orga", "invoice"]}
      unitType={props.invoice.data.type}
      render={() => (
        <div
          className={`ra-invoice-entry-view ${
            props.displayBeneath ? "display-beneath" : ""
          }`}
        >
          {props.ignoreHistory !== true && (
            <div className="left">
              <RAInvoiceProcessHistory
                maxHistoryHeight={props.maxHistoryHeight}
                invoice={props.invoice}
              />
            </div>
          )}
          <div className="right">
            <div className={`indicators-container`}>
              <div className={`fill`} />
              <div>
                <RAInvoiceDirectionIndicator
                  direction={props.invoice?.data?.direction}
                />
              </div>
            </div>
            {props.invoice.data.urgent?.isUrgent && (
              <UrgentBubble
                urgent={props.invoice.data.urgent}
                title={i18n.t(
                  "ra:Invoice.Indicator.Urgent",
                  "Dringende Rechnung"
                )}
              />
            )}
            {props.showDependenices && (
              <RAInvoiceEntryDependency
                invoice={props.invoice}
                modalLinkIdentifier={props.modalLinkIdentifier}
              />
            )}
            <div className="invoice-header">
              <div className="invoice-type">
                {props.showInvoiceId && (
                  <div className={`invoice-id`}>
                    <LinkCell
                      id={props.invoice._id}
                      text={props.invoice.id}
                      assetType={AssetTypes.Invoice}
                      type={props.invoice.data.type}
                    />
                  </div>
                )}
                {props.showInvoiceDate && (
                  <div className="invoice-date">
                    {StringUtils.formatDate(
                      props.invoice.data.invoice.documentDate
                    )}
                  </div>
                )}
                <div>
                  {props.showBusinessUnit &&
                    `${UnitStruct.getUnitLabel(props.invoice?.data.type)} - `}
                  {LanguageService.translateLabel(
                    InvoiceStruct.getInvoiceType(
                      props.invoice?.data.invoice.invoiceType
                    )?.data.displayName
                  )}
                </div>
                {props.showInvoiceIdHeader && (
                  <div className={`invoice-id`}>
                    {i18n.t(
                      "ra:RAInvoiceEntryView.InvoiceIdShort",
                      "Rechnungsnr:"
                    )}{" "}
                    {props.invoice?.data.invoice.invoiceId}
                  </div>
                )}
              </div>
              <div className="fill" />

              <div className="amount-to-pay">
                <RAInvoiceViewField
                  identifier={RA_INVOICE_MAIN_FIELDS().amountToPay.identifier}
                  editable={props.editable}
                  onHighlight={props.onHighlight}
                  isSelected={props.isSelected}
                  label={RA_INVOICE_MAIN_FIELDS().amountToPay.label}
                  value={props.invoice?.data.invoice?.value.amount}
                  // extractValue={props.invoice?.data.invoice} // Todo Invoice - fix extract values
                  formatter={(value) =>
                    StringUtils.formatCurrency(
                      value,
                      undefined,
                      undefined,
                      props.invoice?.data.invoice?.value.currency
                    )
                  }
                  suffix={
                    props.invoice?.data.invoice?.value.currency !==
                    props.invoice?.data.invoice?.value.converted?.currency ? (
                      <BFUseTooltip
                        delay={0}
                        trigger="hover"
                        tooltip={
                          <div className={`ra-invoice-entry-suffix-amount`}>
                            <div>
                              <div className={`amount`}>
                                {StringUtils.formatCurrency(
                                  props.invoice?.data.invoice?.value.converted
                                    ?.amount,
                                  undefined,
                                  undefined,
                                  props.invoice?.data.invoice?.value.converted
                                    ?.currency
                                )}
                              </div>
                              <div className={`info`}>
                                {i18n.t(
                                  "ra:RAInvoiceEntryView.Converted",
                                  "Umgerechnet am {{dateOfConversion}}",
                                  {
                                    dateOfConversion: StringUtils.formatDate(
                                      props.invoice?.data.invoice?.value
                                        .converted?.conversionDate
                                    ),
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div className={`ra-invoice-entry-amount-suffix`}>
                          <BfIcon
                            type="light"
                            data="currency-euro-dollar-exchange"
                            size="xxs"
                          />
                        </div>
                      </BFUseTooltip>
                    ) : undefined
                  }
                />
                <InvoicePaymentPlan asset={props.invoice} />

                {props.invoice?.data?.direction === "OUTGOING" &&
                  props.invoice.data.status === "approved" && (
                    <div className={`amount-subvalues`}>
                      <div className={`amount-payed amount-subvalue`}>
                        <div className={`label`}>
                          {i18n.t(
                            "ra:RAInvoiceEntryView.AmountPayed",
                            "Bezahlt"
                          )}
                        </div>
                        <div className={`value`}>
                          {StringUtils.formatCurrency(
                            props.invoice.data.payment.payedAmount
                          )}
                        </div>
                      </div>
                      <div className={`amount-payed amount-subvalue`}>
                        <div className={`label`}>
                          {i18n.t("ra:RAInvoiceEntryView.AmountOpen", "Offen")}
                        </div>
                        <div className={`value`}>
                          {StringUtils.formatCurrency(
                            props.invoice.data.payment.outstandingToPayed
                          )}
                        </div>
                      </div>
                      <div className={`amount-payed amount-subvalue`}>
                        <div className={`label`}>
                          {i18n.t("ra:RAInvoiceEntryView.AmountDue", "Fällig")}
                        </div>
                        <div className={`value`}>
                          {StringUtils.formatCurrency(
                            props.invoice.data.payment.outstandingPayment
                          )}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="main-data">
              <div className="invoice">
                <div className="small">
                  {i18n.t("ra:RAInvoiceEntryView.From", "Von")}
                </div>
                {props.invoice?.data?.direction === "INCOMING"
                  ? contact
                  : entity}
              </div>
              <div className="receiver">
                <div className="small">
                  {i18n.t("ra:RAInvoiceEntryView.To", "An")}
                </div>
                {props.invoice?.data?.direction === "INCOMING"
                  ? entity
                  : contact}
              </div>
            </div>

            {props.ignoreMoreInfos !== true && (
              <>
                {props.simpleView ? (
                  <div className={`simple-view-container`}>
                    <div className={`button-container`}>
                      <BFButton
                        onClick={() => setOpen(!open)}
                        appearance="link"
                        size="xs"
                      >
                        {open
                          ? i18n.t(
                              "Global.Buttons.showLess",
                              "Weniger anzeigen"
                            )
                          : i18n.t("Global.Buttons.showMore", "Mehr anzeigen")}
                      </BFButton>
                    </div>
                    <Collapse in={open}>{moreContent}</Collapse>
                  </div>
                ) : (
                  <>{moreContent}</>
                )}
              </>
            )}
          </div>
        </div>
      )}
    />
  );
};
export default RAInvoiceEntryView;

interface FieldProps {
  identifier: string;
  label?: string;
  value?: any;
  showLabel?: boolean;
  formatter?: (value: any) => string;
  color?: string;
  onHighlight?: (highlight?: PDFHighlight) => void;
  editable?: boolean;
  extractValue?: ExtractValue<any>;
  isSelected?: boolean;
  suffix?: React.ReactNode;
}
export const RAInvoiceViewField = (props: FieldProps) => {
  const highlightable =
    (props.isSelected === undefined || props.isSelected === true) &&
    props.onHighlight &&
    props.extractValue &&
    props.extractValue.box &&
    props.extractValue.value === props.extractValue.extractValue;

  const focus = (scroll: boolean) => {
    if (highlightable) {
      props.onHighlight({
        ...props.extractValue.box,
        stroke: "",
        scrollIntoView: scroll,
        padding: 10,
        id: props.label,
        value: props.extractValue.extractValue as any,
      });
    }
  };
  const onClick = () => {
    focus(true);
  };
  const onMouseEnter = () => {
    focus(false);
  };
  const onMouseLeave = () => {
    if (highlightable) {
      props.onHighlight(null);
    }
  };

  const value = (
    <div
      className="field-value text-selection-all"
      style={{ color: props.color }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {props.formatter ? props.formatter(props.value) : props.value}
    </div>
  );

  return (
    <div
      className={`ra-invoice-view-field ${
        props.onHighlight || props.editable ? "editable" : ""
      }`}
    >
      {props.showLabel && props.label && (
        <div className="field-label">{props.label}</div>
      )}

      {props.editable ? (
        <BFOverlay
          marginLeft={10}
          trigger="click"
          speaker={
            <div className="ra-invoice-view-field-edit-overlay">
              <BFButton
                // style={{ padding: 5 }}
                appearance="link"
                size="sm"
                // icon={{ ...Icon.EDIT, type: "light", size: "xs" }}
              >
                {i18n.t("Global.Buttons.edit")}
              </BFButton>
            </div>
          }
        >
          {value}
        </BFOverlay>
      ) : (
        value
      )}
      {props.suffix && <div className="suffix">{props.suffix}</div>}
    </div>
  );
};

import {
  DateConfig,
  PageCountConfig,
} from "@/components/PDFConstructor/services/types/pdfConfigBlockTypes";
import { withPDFBlock } from "../../PDFBlock";
import { PDFBlockFooter } from "../../PDFBlockFooter";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { useState } from "react";
import { useBlockChanges } from "../../PDFBlockHooks";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import { SelectPicker } from "rsuite";
import { TEXT_ALIGNMENTS } from "@/components/PDFConstructor/common/InputConstants";
import i18n from "@/i18n";
import { PDFCommonInput } from "../../Common/PDFCommonInput";

const PDFDateBlock = withPDFBlock<DateConfig>(
  ({ value, parentId }) => {
    const [_, { deleteBlock, saveBlock }] = usePDFConstructor();
    const [form, setForm] = useState(value);

    useBlockChanges(value, form);

    return (
      <div>
        <BFInput
          label={i18n.t(
            "Component.PDFConstructor.Block.TimeFormat",
            "Zeitformat"
          )}
          placeholder="Format"
          value={form.format}
          onChange={(value) =>
            setForm((form) => ({
              ...form,
              format: value.toString(),
            }))
          }
        />

        <SelectPicker
          label={i18n.t(
            "Component.PDFConstructor.Block.Alignment",
            "Ausrichtung auswählen"
          )}
          data={TEXT_ALIGNMENTS.map((option) => ({
            ...option,
            label: option.label(),
          }))}
          cleanable={false}
          value={form.alignment}
          onChange={(value) =>
            setForm((form) => ({
              ...form,
              alignment: value as PageCountConfig["alignment"],
            }))
          }
          searchable={false}
          className="col-span-2"
        />

        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t("Component.PDFConstructor.Block.Inputs.DateInput", "Datumeingabe")
);

export { PDFDateBlock };

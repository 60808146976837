import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import ModalManager from "@/components/ModalComponent/ModalManager";
import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import LanguageService from "@/services/LanguageService";
import { hasValue } from "@/utils/Helpers";
import StringUtils from "@/utils/StringUtils";
import { DepositPosition, RentalAgreement } from "../../../TenantsInterfaces";
import { CBRentalAgreementDepositUpdateForm } from "../form-components/CBRentalAgreementDepositForm";
// import "./CBAgreementDeposit.scss";

export type DepositType = {
  deposit: null | DepositPosition[];
};
interface CBAgreementDepositProps {
  data: DepositType;
  marks: PDFEditRect[] | PDFDocumentRect[];
  objectId: string;
  rentalAgreement?: RentalAgreement;

  hideIfEmpty?: boolean;
  editable?: boolean;
}
const CBAgreementDeposit = (props: CBAgreementDepositProps) => {
  if (props.hideIfEmpty && (props.data.deposit || []).length === 0) {
    return null;
  }
  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={props.marks}
      title={
        <>
          <span className={`padding-right-6`}>
            {i18n.t("cb:RentalAgreement.Form.Fields.deposit.title", "Kaution")}
          </span>
          {props.rentalAgreement && props.editable && (
            <BFButton
              noPadding
              inline
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  size: "fluid",
                  noPadding: true,
                  content: (state, setState, close) => {
                    return (
                      <CBRentalAgreementDepositUpdateForm
                        rentalAgreement={props.rentalAgreement}
                        onClose={close}
                      />
                    );
                  },
                });
              }}
              size="sm"
            >
              {i18n.t("Global.Buttons.Customize", "Anpassen")}
            </BFButton>
          )}
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.deposit.viewHint",
        "Informationen zur Kaution"
      )}
      identifier="deposit"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      {hasValue(props.data.deposit) ? (
        <div>
          <AssetLoader
            assetType={AssetTypes.Portfolio.Object}
            id={props.objectId}
            render={(object: OAObject) => {
              const depositPositions =
                object.data.feature.immo?.accounting.deposit;
              return (
                <>
                  {props.data.deposit.map((deposit) => (
                    <div className={`__flex-wrap`}>
                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.DepositValue",
                          "Kautionswert"
                        )}
                        value={deposit.depositValue}
                        formatter={(value) =>
                          StringUtils.formatCurrency(value, true)
                        }
                      />
                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.DepositType",
                          "Kautionsart"
                        )}
                        value={
                          depositPositions?.find((e) => e.id === deposit.id)
                            ?.displayName
                        }
                        formatter={LanguageService.translateLabel}
                      />
                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.DepositStart",
                          "Kautionsbeginn"
                        )}
                        value={deposit.depositStart}
                        formatter={StringUtils.formatDate}
                      />
                      <BFValueDisplay
                        label={i18n.t(
                          "cb:RentalAgreement.Labels.DepositStart",
                          "Kautionsbeginn"
                        )}
                        value={deposit.depositStart}
                        formatter={StringUtils.formatDate}
                      />
                    </div>
                  ))}
                </>
              );
            }}
          />
        </div>
      ) : (
        <div className={`__empty no-padding`}>
          {i18n.t(
            "cb:RentalAgreement.Labels.NoDeposit",
            "Keine Kaution hinterlegt"
          )}
        </div>
      )}
    </BFPDFMarkerSection>
  );
};

export default CBAgreementDeposit;

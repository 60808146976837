import LoadingAnimation from "@/components/LottieAnimations/Loading/LoadingAnimation";
import SendLetterAnimation from "@/components/LottieAnimations/SendLetter/SendLetterAnimation";
import i18n from "@/i18n";
import classNames from "classnames";
import { useEffect } from "react";
import "./LoadModal.scss";

const ANIMATION_STYLE_LOADER = {
  height: 200,
  width: 150,
};

export type LoadAnimationType = "letter" | "loading";
interface LoadModalProps {
  animationType: LoadAnimationType;
  loadingText?: React.ReactNode;

  title?: string;

  promise: () => Promise<any>;

  onFinish: (result?: any) => void;
  onAbort: () => void;
}
const LoadModal = (props: LoadModalProps) => {
  useEffect(() => {
    props
      .promise()
      .then((result) => {
        props.onFinish(result);
      })
      .catch((err) => {
        props.onAbort();
      });
  }, []);

  const animationType = props.animationType || "loading";

  return (
    <div className={classNames(`load-modal`)}>
      <div className={`animation-container`}>
        {animationType === "loading" && (
          <LoadingAnimation style={ANIMATION_STYLE_LOADER} />
        )}
        {animationType === "letter" && (
          <SendLetterAnimation style={ANIMATION_STYLE_LOADER} />
        )}
      </div>
      {props.title && <div className={`title`}>{props.title}</div>}
      <div className={`info`}>
        {props.loadingText ? (
          props.loadingText
        ) : (
          <>
            {i18n.t("LoadModal.info", "Bitte haben Sie einen Moment Geduld.")}
          </>
        )}
      </div>
    </div>
  );
};

export default LoadModal;

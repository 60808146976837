import { FC, memo } from "react";
import { ImageFieldNoEditViewProps } from "./types";
import { AssetTypes } from "@/model/AssetTypes";
import FilePreviewComponent from "@/components/FilePreviewComponent/FilePreviewComponent";
import styles from "./ImageFieldNoEditView.module.scss";
import { Text } from "rsuite";

const ImageFieldNoEditView: FC<ImageFieldNoEditViewProps> = ({
  floorPlanBase,
}) => {
  return (
    <div>
      <Text className={styles.preview_text}>Image</Text>

      <div className={styles.preview_wrapper}>
        <FilePreviewComponent
          assetType={AssetTypes.Portfolio.FloorPlan}
          assetField="data.images"
          hasFolderPermissions={true}
          asset={floorPlanBase}
          cdnID={floorPlanBase.data.images[0].linkToCdn}
          type="cdn"
          width={250}
          height={250}
        />
      </div>
    </div>
  );
};

export default memo(ImageFieldNoEditView);
